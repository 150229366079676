import { useContext, useEffect, useState } from 'react';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import SessionContainer from '../../../../components/SessionContainer/SessionContainer';
import { IntlContext } from '../../../../intl/index';
import style from '../Upload.module.scss';
import * as translations from './intl';
import moment from 'moment';
import StepClaim from '../../StepClaim/StepClaim';
import Button from '../../../../componentsV2/Button/Button';
import HeadingTitle from '../../../../components/HeadingTitle/HeadingTitle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import sanitize from '../../../../utils/sanitize';
import { emergency_medical_assistance } from '../../../../utils/claims_name';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { claimsServices } from '../../../../services';
import {
  ENABLE_UUP,
  NOTIFICATIONS_ENABLE,
  REACT_APP_UPLOAD_CONCLUDE_ENABLE,
} from '../../../../utils/environments_variables';
import { NotificationContext } from '../../../../components/NotificationContextParent/NotificationContextParent';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import UploadFiles from './ListFiles/UploadFiles';
import { ExpectedDocument } from '../../../../@types/APIs/ExpectedDocuments';
import { ClaimInfo } from '../../../../@types/APIs/claim';

const getDataPendingSend = ({ claimInfo: { statuses } }) => {
  const getStatusPending = statuses.filter(
    status => status.name === 'Pending Send'
  );
  return getStatusPending[getStatusPending.length - 1].created_at;
};

interface UploadPageProps extends RouteComponentProps {
  data: {
    expectedDocuments: ExpectedDocument[];
    claimInfo: ClaimInfo;
  };
  claimId: string;
}

const UploadPage = (props: UploadPageProps) => {
  const { data, claimId, history } = props;
  const [disableSubmit, setDisableSubmit] = useState(true);

  const { translate, idiom, country } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const isFromProfile =
    history &&
    history.location &&
    history.location.search &&
    history.location.search.includes('Profile');

  const theme = getGlobalTheme();
  const dateFormat = idiom === 'pt_BR' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const intl = translate(translations);
  const datePendingSend = getDataPendingSend(data);
  const date = moment(datePendingSend).add(90, 'days').format(dateFormat);
  const claimInfo = data.claimInfo;
  const attchments = data.claimInfo.attachments;
  const expectedDocuments = data.expectedDocuments;
  const benefit_external_reference = data.claimInfo.benefit_external_reference;

  const stepNames = [intl.TEXT_STEP_1, intl.TEXT_STEP_2, intl.TEXT_STEP_3];

  const textModalSubtitle = intl.BEEN_SENT_SUBTITLE_INCONVENIENCE;

  const checkAttchmentsUUP = () => {
    const documentsUUP = expectedDocuments.filter(
      document =>
        document.document_type_name == 'National ID' ||
        document.document_type_name == 'Proof of Residency'
    );

    const checkAttchmentsPersonalDocuments = attchments.filter(item1 => {
      return documentsUUP.some(item2 => {
        return item1.document_type_name === item2.document_type_name;
      });
    });

    const removeDuplicatesPersonalDocuments = Array.from(
      new Set(checkAttchmentsPersonalDocuments)
    );

    if (documentsUUP.length != removeDuplicatesPersonalDocuments.length) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="question"
          message={intl.TEXT_TITLE_PERSONAL_DOCUMENTS_ALERT}
          subtitle={intl.BTN_TEXT_SUBTITLE_PERSONAL_DOCUMENTS}
          textBtn={intl.BTN_YES_ALERT_UPDATE}
          textBtnDeny={intl.BTN_NOT_ALERT_UPDATE}
          clickBtn={() => {
            history.push('/profile/account#persoalDocuments');
            actions.modal.closeModal();
          }}
          clickBtnDeny={() => {
            actions.modal.closeModal();
          }}
        />,
        true
      );
    }

    return;
  };

  const redirect = () => {
    history.goBack();
  };
  const redirectHome = (history, actions) => {
    history.push('/dashboard');
    actions.modal.closeModal();
  };

  const redirectClaims = (history, actions) => {
    history.push('/profile/claims');
    actions.modal.closeModal();
  };

  const textDocuments =
    data &&
    data.claimInfo &&
    data.claimInfo.benefit_external_reference === emergency_medical_assistance
      ? intl.TEXT_REMEMBER_DOCUMENTS_IEMS
      : intl.TEXT_REMEMBER_DOCUMENTS_OTHERS;

  const concludeUpload = async () => {
    try {
      actions.modal.closeModal();
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.COMPLETING_YOUR_CLAIM
      );

      await claimsServices.postConcludeAttachment(claimId);

      trackEventUserAction(
        `#### CLAIM COMPLETED - CLAIM ${benefit_external_reference} - COUNTRY ${country} `
      );

      if (NOTIFICATIONS_ENABLE) {
        notificationActions.bellNotification.updateBellNotification();
      }
      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.HAS_BEEN_SENT}
          subtitle={textModalSubtitle}
          textBtn={intl.GO_HOME}
          clickBtn={() => redirectHome(history, actions)}
          textBtnDeny={intl.YOUR_CLAIMS}
          clickBtnDeny={() => redirectClaims(history, actions)}
        />,
        true
      );
    } catch (error) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true
      );
    }
  };

  useEffect(() => {
    if (ENABLE_UUP) {
      checkAttchmentsUUP();
    }
  }, []);

  return (
    <SessionContainer>
      <HeadingTitle
        title={claimInfo.claim_type_name}
        subtitle={intl.TEXT_UPLOAD_DOCUMENT}
      />
      <StepClaim currentStep={3} stepNames={stepNames} />
      <ShadowBox>
        <header id="mainContent">
          <h2 className={style.uploadTitle} data-testid="documentTitle">
            {intl.CLAIM_DOCUMENTATION}
          </h2>
        </header>
        <p className={style.textSubTitle} data-testid="documentUploadRequested">
          {intl.TEXT_UPLOAD_REQUESTED}
          <strong>{intl.DATE_UPLOAD_REQUESTED(date)}</strong>
        </p>
        <p
          className={style.textSubTitle}
          data-testid="documentUploadDisclaimer"
        >
          {intl.TEXT_UPLOAD_DISCLAIMER}
        </p>
        <p className={style.textSubTitle} data-testid="documentTextRemember">
          {textDocuments.map((text, index) => (
            <span
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitize(text) }}
            ></span>
          ))}
        </p>
        <UploadFiles
          data={data}
          claimId={claimId}
          isFromProfile={isFromProfile}
          setDisableSubmit={setDisableSubmit}
          concludeUpload={concludeUpload}
          textModalSubtitle={textModalSubtitle}
        />
        <div className={`${style.footer} ${style[theme]}`}>
          <div className={style.divButton}>
            <Button
              type="Secondary"
              onClick={redirect}
              label={intl.TEXT_BACK}
            />
          </div>
          {REACT_APP_UPLOAD_CONCLUDE_ENABLE && (
            <div className={style.divButton}>
              <Button
                type="Primary"
                onClick={concludeUpload}
                disabled={disableSubmit}
                label={intl.BTN_CONCLUDE}
              />
            </div>
          )}
        </div>
      </ShadowBox>
    </SessionContainer>
  );
};

export default withRouter(UploadPage);
