import { SVGProps } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

interface ArrowIconProps extends SVGProps<SVGSVGElement> {
  type: 'Up' | 'Down' | 'Right' | 'Left' | 'Out';
}

const ArrowIcon = (props: ArrowIconProps) => {
  const { getCurrentThemeColors } = useTheme();
  const { primary } = getCurrentThemeColors();
  const color = props.color ?? primary;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 17 16"
      fill="none"
      {...props}
    >
      {props.type === 'Up' && (
        <path
          stroke={color}
          strokeWidth={2}
          d="M8.5 6v9M1.5 9l5.586-5.586a2 2 0 0 1 2.828 0L15.5 9"
        />
      )}
      {props.type === 'Down' && (
        <path
          stroke={color}
          strokeWidth={2}
          d="M8.5 11V2M15.5 8l-5.586 5.586a2 2 0 0 1-2.828 0L1.5 8"
        />
      )}
      {props.type === 'Left' && (
        <path
          stroke={color}
          strokeWidth={2}
          d="M5.5 8h9M8.5 15 2.914 9.414a2 2 0 0 1 0-2.828L8.5 1"
        />
      )}
      {props.type === 'Right' && (
        <path
          stroke={color}
          strokeWidth={2}
          d="M11.5 8h-9M8.5 1l5.586 5.586a2 2 0 0 1 0 2.828L8.5 15"
        />
      )}
      {props.type === 'Out' && (
        <path
          stroke={color}
          strokeWidth={2}
          d="m10.5 6.85-6.293 6.292M3.5 3.95h7.9a2 2 0 0 1 2 2v7.9"
        />
      )}
    </svg>
  );
};

export default ArrowIcon;
