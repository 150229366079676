import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../utils/environments_variables';
import { removeNonNumeric } from '../../../../../../utils/stringUtils';

export const formatGeneralInformation = props => {
  const contract_holder = formatContractHolder(props);
  const product_criteria = formatProdutCriteria(props);
  const beneficiaries = formatBeneficiary(props);
  return { contract_holder, product_criteria, beneficiaries };
};

const formatContractHolder = props => {
  const {
    cn,
    values: { policy: infoCardHolder },
  } = props;

  const nationalities = [infoCardHolder.nationalities];
  return {
    customer_id: cn,
    person: { ...infoCardHolder.person, nationalities },
  };
};

const formatProdutCriteria = props => {
  const { bin } = props;
  return {
    bank_identification_number: bin,
  };
};

const formatBeneficiary = props => {
  const {
    values: { policy: values },
  } = props;
  const { person, address, phone, person_registrations, email, nationalities } =
    values;
  person.nationalities = [nationalities];

  // remove invalidCep
  delete address.invalidCep;
  if (!ALPHANUMERIC_COUNTRY_LIST.includes(address.country)) {
    address.postal_code = removeNonNumeric(address.postal_code);
  }

  let registrations = person_registrations.map(item => {
    const registration = { ...item };
    if (registration.registration_type === 'CPF') {
      registration.value = removeNonNumeric(registration.value);
    }
    return registration;
  });
  registrations = registrations.filter(item => item.value !== '');

  return [
    {
      person,
      person_registrations: registrations,
      address,
      phones: [phone],
      email,
    },
  ];
};
