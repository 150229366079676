import React, { ReactNode } from 'react';
import styles from './Bluebox.module.scss';

interface BlueboxSubContainerProps {
  children: ReactNode;
  center?: boolean;
  inline?: boolean;
}

const BlueboxSubContainer = ({
  children,
  center,
  inline,
}: BlueboxSubContainerProps) => {
  return (
    <div
      className={`${styles.group} ${center ? styles.center : ''} ${
        inline ? styles.groupInline : ''
      }`}
    >
      {children}
    </div>
  );
};

export default BlueboxSubContainer;
