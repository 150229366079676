import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const CertificatePlus = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.5 4.5H6.4v2h11.1v-2ZM17.5 8.2H6.4v2h11.1v-2ZM12.4 11.9h-6v2h6v-2Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M2.7.8v22.3H9l-2-2H4.7V2.8h14.6v11.8h2V.8H2.7Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="m10.1 21.7-3.3-3.4 1.4-1.4 1.9 1.9 4.5-4.5 1.5 1.4-6 6Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M21.3 19.2h-2v-1.9h-2v1.9h-1.9v2h1.9v2h2v-2h2v-2Z"
        fill={props.lightBackground ? config_icon.secondary : config_icon.white}
      />
    </svg>
  );
};

export default CertificatePlus;
