import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    parentValues,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
    setFieldValue('affected_person.person.birth_date', '');
    setFieldValue('affected_person.person.registrations.value', '');
  };

  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );
    setFieldValue(
      'affected_person.person.birth_date',
      parentValues.policy.person.birth_date
    );
    setFieldValue(
      'affected_person.person.registrations.value',
      parentValues.policy.person_registrations.value
    );
  };

  return (
    <div className={`row ${styles.personalArea}`}>
      <div className="col-12">
        <div
          className={`${styles.fontGray12} ${styles[themes]} ${styles.options} row`}
        >
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.PERSON_AFFECTED}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.PERSON_AFFECTED}
              defaultChecked={
                values.affected_person.relationship ===
                constants.CARD_HOLDER_VALUE
              }
              text={intl.TEXT_CH}
              name="affected_person.relationship"
              dataTestid="InputRelationshipCardHolder"
              value={constants.CARD_HOLDER_VALUE}
              onChange={e => {
                setEmptyPersonalInputFields();
                setCardHolderNameLastName();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.PERSON_AFFECTED}
              defaultChecked={
                values.affected_person.relationship === constants.SPOUSE_VALUE
              }
              text={intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
              name="affected_person.relationship"
              dataTestid="InputRelationshipSpouse"
              value={constants.SPOUSE_VALUE}
              onChange={e => {
                setEmptyPersonalInputFields();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.PERSON_AFFECTED}
              defaultChecked={
                values.affected_person.relationship ===
                constants.DEPENDENT_VALUE
              }
              text={intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
              name="affected_person.relationship"
              dataTestid="InputRelationshipChild"
              value={constants.DEPENDENT_VALUE}
              onChange={e => {
                setEmptyPersonalInputFields();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.PERSON_AFFECTED}
              defaultChecked={
                values.affected_person.relationship === constants.OTHER_VALUE
              }
              text={intl.TEXT_RELATIONSHIP_VALUE_OTHER}
              name="affected_person.relationship"
              dataTestid="InputRelationshipOther"
              value={constants.OTHER_VALUE}
              onChange={e => {
                setEmptyPersonalInputFields();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 ${
          values.affected_person.relationship !== constants.OTHER_VALUE
            ? styles.none
            : ''
        }`}
        data-testid="InputRelationshipComment"
      >
        <Input
          value={values.affected_person.comment}
          label={intl.TEXT_OTHER}
          name="affected_person.comment"
          type="text"
          disabled={
            values.affected_person.relationship !== constants.OTHER_VALUE
          }
          onChange={handleChange}
          touched={touched.affected_person && touched.affected_person.comment}
          onBlur={handleBlur}
          error={errors.affected_person && errors.affected_person.comment}
          maxLength="255"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          dataTestid="affectedPerson_firstName"
          value={values.affected_person.person.first_name}
          label={intl.LABEL_NAME_AFFECTED}
          name="affected_person.person.first_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          onChange={handleChange}
          touched={
            touched.affected_person &&
            touched.affected_person.person &&
            touched.affected_person.person.first_name
          }
          onBlur={handleBlur}
          error={
            errors.affected_person &&
            errors.affected_person.person &&
            errors.affected_person.person.first_name
          }
          maxLength="40"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          dataTestid="affectedPerson_lastName"
          value={values.affected_person.person.last_name}
          label={intl.LABEL_LAST_NAME_AFFECTED}
          name="affected_person.person.last_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          onChange={handleChange}
          touched={
            touched.affected_person &&
            touched.affected_person.person &&
            touched.affected_person.person.last_name
          }
          onBlur={handleBlur}
          error={
            errors.affected_person &&
            errors.affected_person.person &&
            errors.affected_person.person.last_name
          }
          maxLength="80"
        />
      </div>
    </div>
  );
};

export default PersonalArea;
