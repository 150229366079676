/* eslint-disable no-unused-vars */
export default (env, axios, errorHandler) => {
  return {
    updateBinWithTokenex: async (
      infoUser,
      effective_end_date,
      token,
      is_preferred = true
    ) => {
      const url = `/api/specific/visagateway/v1/customers/${infoUser.customer_id}/policies/${infoUser.policy_id}`;
      let data = {
        external_reference: infoUser.external_reference,
        credit_card_token: token,
        is_preferred,
      };
      if (effective_end_date) data = { ...data, effective_end_date };
      return errorHandler(axios.patch(url, data), 'updateBinWithTokenex', {
        url,
        data,
        infoUser,
        effective_end_date,
        token,
      });
    },

    getBinByPolicyId: async (
      customer_id,
      card_name,
      policy_id,
      bin_number,
      tokenex
    ) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/policies/${policy_id}`;
      return errorHandler(axios.get(url), 'updateBinWithTokenex', {
        url,
        customer_id,
        card_name,
        bin_number,
      });
    },
    getAuthenticationKey: async timeStamp => {
      const url = `/tokenex/authenticationkey/${timeStamp}`;
      return errorHandler(axios.get(url), 'tokenex', { url });
    },
    getAuthenticationKeyCVV: async (timeStamp, token) => {
      const url = `/tokenex/authenticationkeyCVV/${timeStamp}/${token}`;
      return errorHandler(axios.get(url), 'tokenex', { url });
    },

    generateToken: async (panEncrypted, cvvEncrypted = null) => {
      const url = `/tokenex/generateTokenV2`;
      let dataPan = {
        pan: panEncrypted,
      };
      if (cvvEncrypted) {
        dataPan.cvv = cvvEncrypted;
      }
      return errorHandler(axios.post(url, dataPan), 'generateToken', dataPan);
    },
    addCardWithPan: async (
      cardInfo,
      token,
      effective_end_date,
      isPrefered = false
    ) => {
      const url = `/api/specific/visagateway/v1/customers/${cardInfo.customer_id}/policies`;
      const data = {
        external_reference: cardInfo.external_reference,
        credit_card_token: token,
        is_preferred: isPrefered,
        // effective_end_date: effective_end_date,
        /**
         * * The Business team requested to comment the effective_end_date from the form
         * ? ISSUER:
         * https://dev.azure.com/AASolutions/americas_visa_benefits_portal/_sprints/taskboard/americas_visa_benefits_portal%20Team/americas_visa_benefits_portal/2023/Sprint23_15?workitem=713801
         */
      };

      return errorHandler(axios.post(url, data), 'addCardWithPan', data);
    },
  };
};
