import { removeNonNumeric } from './stringUtils';
import { generatePanEncryptedTokenWithCVV } from '../components/UpdateBinTokenex/InputTokenex/utils';

export const createPanTokenexEncrypted = async (
  pan: string,
  cvv: string,
  trackEventUser: () => void,
  keyTokenex: string
): Promise<string> => {
  if (!pan) return;

  try {
    const panNumber: string = removeNonNumeric(pan);
    const tokenEncrypted: string = await generatePanEncryptedTokenWithCVV(
      trackEventUser,
      panNumber,
      cvv,
      keyTokenex
    );
    return tokenEncrypted;
  } catch (error) {
    return 'ERROR_TOKENEX';
  }
};
