/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState, useEffect, useContext } from 'react';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import { getQueryVariable } from '../../utils/uri';
import { IntlContext } from '../../intl';
import { getImagesByTheme } from './imagesByTheme';
import objectTheme, { ThemeColorsObject, ThemeObject } from './themes';

export const AFLUENT = 'theme-afluent';
export const AXA = 'theme-axa';
export const MASTER = 'theme-master';
export const THEME_PORTO_BANK = 'theme-portoBank';
export type ThemeType =
  | typeof MASTER
  | typeof AFLUENT
  | typeof THEME_PORTO_BANK
  | typeof AXA;

interface IThemeContext {
  getImageByName: (imgName: string) => any;
  getGlobalTheme: () => ThemeType;
  isAfluent: () => boolean;
  getColors: () => ThemeObject;
  getCurrentThemeColors: () => ThemeColorsObject;
}

export const ThemeContext = createContext({} as IThemeContext);

const setThemeGlobal = (cardName, setTheme) => {
  const upperCaseName = cardName.toUpperCase();
  const isAfluent =
    upperCaseName.includes('INFINITE') ||
    upperCaseName.includes('SIGNATURE') ||
    upperCaseName.includes('PLATINUM');
  const theme = isAfluent ? AFLUENT : MASTER;
  setTheme(getTheme(theme));
};

const getTheme = themeDefault => {
  const theme = window.location.hostname
    ? window.location.hostname.split('.')[0]
    : themeDefault;

  if (theme.includes('portobank')) {
    return THEME_PORTO_BANK;
  } else {
    return themeDefault;
  }
};

const ThemeContextParent = props => {
  const [theme, setTheme] = useState<ThemeType>(
    props.initialValues || getTheme(AFLUENT)
  );
  const { utils } = useContext(StateContext);
  const { productActive } = useContext(PublicContext);
  const { country } = useContext(IntlContext);
  const card = utils.getSelectedCard();
  const isLogged = utils.isUserLogged();

  const getGlobalTheme = () => theme;

  const isAfluent = () => theme === AFLUENT;

  const getImageByName = (imgName: string) => {
    const themeWithUnderline = theme.replace('-', '_');
    return getImagesByTheme(themeWithUnderline, imgName, country);
  };

  const getColors = () => {
    return props.initialObjectColor || objectTheme;
  };

  const getCurrentThemeColors = () => {
    switch (theme) {
      case AFLUENT:
        return props.initialObjectColor || objectTheme.afluent;
      case AXA:
        return props.initialObjectColor || objectTheme.axa;
      case MASTER:
        return props.initialObjectColor || objectTheme.master;
      case THEME_PORTO_BANK:
        return props.initialObjectColor || objectTheme.portoBank;
      default:
        return props.initialObjectColor || objectTheme.afluent;
    }
  };

  useEffect(() => {
    if (isLogged) {
      if (card && card.name) {
        setThemeGlobal(card.name, setTheme);
      }
    } else {
      if (productActive && productActive.name) {
        setThemeGlobal(productActive.name, setTheme);
      }
      const isAffluentByQuery = getQueryVariable('isAfluent');

      if (isAffluentByQuery && isAffluentByQuery === 'true') {
        setTheme(AFLUENT);
      }
      if (isAffluentByQuery && isAffluentByQuery !== 'true') {
        setTheme(MASTER);
      }
    }
  }, [card, productActive]);

  useEffect(() => {
    if (props.initialValues) {
      setTheme(props.initialValues);
    }
  }, [props.initialValues]);

  const value = {
    getImageByName,
    getGlobalTheme,
    isAfluent,
    getColors,
    getCurrentThemeColors,
  };

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (isAxa?: boolean): IThemeContext => {
  const themeHelpers = useContext(ThemeContext);

  return isAxa
    ? {
        ...themeHelpers,
        isAfluent: () => false,
        getCurrentThemeColors: () =>
          objectTheme.axa || themeHelpers.getCurrentThemeColors(),
        getGlobalTheme: () => AXA,
      }
    : themeHelpers;
};

export default ThemeContextParent;
