import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './RentalPeriodArea.module.scss';

const RentalPeriodArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, errors, setFieldTouched } = props;

  return (
    <div className={`row ${styles.rentalPeriodlArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.car_rental.from}
          label={intl.LABEL_RENTAL_START}
          name="car_rental.from"
          onChange={handleChange}
          maxDate={new Date()}
          touched={touched.car_rental && touched.car_rental.from}
          error={errors.car_rental && errors.car_rental.from}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.car_rental.to}
          label={intl.LABEL_RENTAL_END}
          name="car_rental.to"
          onChange={handleChange}
          minDate={values.car_rental.from}
          touched={touched.car_rental && touched.car_rental.to}
          error={errors.car_rental && errors.car_rental.to}
          setFieldTouched={setFieldTouched}
        />
      </div>
    </div>
  );
};

export default RentalPeriodArea;
