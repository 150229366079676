import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const HelpMessengerIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 49 49"
      style={{
        enableBackground: 'new 0 0 49 49',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M31.9 3.7H17.1c-8.7 0-15.7 7.1-15.7 15.7s7.1 15.7 15.7 15.7h5.3l2-2h-7.3C9.6 33.1 3.4 27 3.4 19.4S9.6 5.7 17.1 5.7h14.7c7.6 0 13.7 6.2 13.7 13.7s-6.2 13.7-13.7 13.7h-4.6L16.4 43.9l1.4 1.4L28 35.1h3.8c8.7 0 15.7-7.1 15.7-15.7s-7-15.7-15.6-15.7z"
        style={{
          fill: config_icon.primary,
        }}
      />
      <path
        d="M27.1 26.8 21 20.7 9.8 24.5l12.3-12.2 6.2 6.2 11.1-4.4-12.3 12.7zm-5.6-8.4 5.6 5.6 4.3-4.4-3.5 1.4-5.8-5.8-4.6 4.6 4-1.4z"
        style={{
          fill: config_icon.secondary,
        }}
      />
    </svg>
  );
};

export default HelpMessengerIcon;
