import { pt_BR } from '../../../../../../../intl/idioms';

const formatBankInformation = (props, bankCountryView, initialValues) => {
  let object = {};

  object.name = props.reimbursement.beneficiary_account.bank.name || '';
  object.bank_account_number =
    props.reimbursement.beneficiary_account.bank.bank_account_number || '';

  object.bank_code =
    props.reimbursement.beneficiary_account.bank.bank_code || '';

  object.branch_code =
    props.reimbursement.beneficiary_account.bank.branch_code || '';

  if (props.reimbursement.beneficiary_account.bank_account_number) {
    object.bank_account_number =
      props.reimbursement.beneficiary_account.bank_account_number;
  }

  // Check if it is the same bank account with ***
  if (
    initialValues &&
    object.bank_account_number ===
      initialValues.reimbursement.BankAccount.bank.bank_account_number
  ) {
    object.bank_account_number =
      initialValues.reimbursement.BankAccount.bank_account_id;
  }

  //The logic about address was passed to the proxy
  // object.address =
  //   props.reimbursement.beneficiary_account.bank.address ||
  //   addressToAPI(bankCountryView);

  return { ...object };
};

const formatHolderInformation = props => {
  const data = {
    person: props?.reimbursement?.beneficiary_account?.holder?.person,
    address: {},
    registrations: [],
  };
  return data;
};

const addressToAPI = bankCountryView => {
  let object = {};

  let countryResult;
  switch (bankCountryView) {
    case 'Mexico':
      countryResult = 'MX';
      break;
    case 'united-states':
      countryResult = 'US';
      break;
    case 'other-countries':
      countryResult = '';
      break;
    default:
      countryResult = 'BR';
      break;
  }
  object.country = countryResult;

  return { ...object };
};

export const newFomartBankData = (
  props,
  bankCountryView,
  initialValues = null,
  isProfile
) => {
  const { idiom } = props;
  let bank = formatBankInformation(props, bankCountryView, initialValues);
  const holder = formatHolderInformation(props);

  const isBrazil = isProfile ? !bankCountryView : idiom === pt_BR;

  const account_codes = isBrazil
    ? []
    : props.reimbursement.beneficiary_account.wire_code;
  const isCurrentAc = props.reimbursement.beneficiary_account
    ? props.reimbursement.beneficiary_account.is_current_account
    : false;
  let formatedData = {
    bank,
    holder,
    is_current_account: isCurrentAc,
    account_type: props.reimbursement.beneficiary_account.account_type,
    account_codes,
    bank_identification_number:
      props.reimbursement.beneficiary_account.bank_identification_number,
  };
  return formatedData;
};
