import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../components/Input/Input';

const EmailArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;
  const disableEmailInput = !values.benefitiaryIsNotCardHolder;
  return <div className="row"></div>;
};

export default EmailArea;
