export const removeNonNumeric = txt => {
  if (txt) return txt.replace(/\D/g, '');
  return '';
};

export const boolToYesOrNo = value => {
  return value ? 'yes' : 'no';
};

export const applyDateMask = value => {
  const numbers = removeNonNumeric(value);
  const a = numbers.replace(/(\d{2})(\d{1})/, '$1/$2');
  const b = a.replace(/(\d{2})\/(\d{2})(\d{1})/, '$1/$2/$3');
  return b;
};

export const swapDayWithMonth = value => {
  return value.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
};

export const maskCertificateNumber = value => {
  // *****-****-**-191042813
  return '*****-****-**-' + value.substr(-9);
};

export const formatLinkWithIdiomAndCountry = (link, idiom, country) => {
  if (!link) return '';
  const linkFormated = link
    .replace('{{country_code}}', country)
    .replace('{{lang}}', idiom);
  return linkFormated;
};

export const validateCPF = cpf => {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0,
      r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r.toString() !== cpf.substring(j, j + 1)) result = false;
  });
  return result;
};

export const removeRequiredCharLabel = data => {
  return data && data.slice(-1) === '*'
    ? data.substring(0, data.length - 1)
    : data;
};

export const removeAllSpecialcharacters = string => {
  return string.replace(/[^a-zA-Z0-9]/g, '');
};

export const stringToBoolean = string => {
  try {
    return string.toString() === 'true' ? true : false;
  } catch (error) {
    return false;
  }
};

export const formatCPF = string =>
  `${string.substr(0, 3)}.${string.substr(3, 3)}.${string.substr(
    6,
    3
  )}-${string.substr(9)}`;

export const mask4FirstDigits = string => {
  if (!string) return '';
  return `****${string.substr(4)}`;
};

export const formatPostalCodeBR = string =>
  string && string.length > 0
    ? `${string.substr(0, 5)}-${string.substr(5)}`
    : '';

export const formatNumber = (number, idiom) => {
  const numberString = String(number.toFixed(2));
  if (idiom === 'pt_BR') {
    return numberString.split(/(?=(?:\d{3})+(?:,|$))/g).join('.');
  }
  return numberString.split(/(?=(?:\d{3})+(?:\.|$))/g).join(',');
};

export const textTransformFirstLetterToUpperCase = string => {
  const words = string.split(' ');
  return words
    .map(word => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

export const getAlignBackgroundByURL = url => {
  let parameters = url.split('?')[1];
  if (!parameters) {
    return 'center';
  }

  parameters = parameters.split('&');
  let align = parameters.find(parameter => parameter.split('=')[0] === 'align');

  if (align) {
    const value = align.split('=')[1];
    if (value === 'left' || value === 'right') {
      return value;
    }
  }
  return 'center';
};

export const getValignBackgroundByURL = url => {
  let parameters = url.split('?')[1];
  if (!parameters) {
    return 'center';
  }

  parameters = parameters.split('&');
  let align = parameters.find(
    parameter => parameter.split('=')[0] === 'valign'
  );

  if (align) {
    const value = align.split('=')[1];
    if (value === 'top' || value === 'bottom') {
      return value;
    }
  }
  return 'center';
};

export const getNoBlackCoverByURL = url => {
  let parameters = url.split('?')[1];
  if (!parameters) {
    return true;
  }

  parameters = parameters.split('&');

  let blackcover = parameters.find(parameter => parameter === 'noblackcover');
  return !blackcover;
};

export const stringContainsValue = (string, value) => {
  const regex = new RegExp(`.*${value}.*`, 'i');
  const matchString = string.match(regex);
  return matchString && matchString.length > 0;
};

export const stringCapitalize = string => {
  const names = String(string).split(' ');

  const namesCapitalized = names.map(name => {
    return `${String(String(name).charAt(0)).toUpperCase()}${String(
      String(name).slice(1)
    ).toLowerCase()}`;
  });

  return namesCapitalized.join().replace(/,/g, ' ');
};
