import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import * as translations from './intl';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../../intl';
import { en_US } from '../../../../../intl/idioms';
import {
  legalAdviceFormInitialValues,
  validationSchema,
} from './validationsAndValues';
import styles from './LegalAdviceAndConsent.module.scss';

const LegalAdviceAndConsent = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { updateParent, legalSubmitRef, loadedData = [] } = props;
  const initialValues = useMemo(
    () => legalAdviceFormInitialValues(loadedData),
    [loadedData]
  );
  const schema = useMemo(() => validationSchema(intl), [intl]);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let dateFormat = idiom === en_US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  let today = moment(new Date()).format(dateFormat);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched,
          handleSubmit,
        } = props;
        updateParent(values, schema);
        const setConfirm = async (index, value) => {
          setFieldValue(index, value);
          setFieldTouched(index);
        };

        return (
          <form onSubmit={handleSubmit}>
            <button
              ref={legalSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            />
            <div className={styles.legalAdviceAndConsent}>
              <div className={styles.content}>
                <h4 className={styles[themes]}>{intl.TEXT_OBLIGATION}</h4>
                <p className={styles[themes]}>{intl.TEXT_ADVISE}</p>
                <h5 className={styles[themes]}>
                  {intl.TEXT_DECLARE_FOLLOWING}
                </h5>
              </div>

              <div className={`${styles.checkbox} ${styles[themes]}`}>
                <div className={styles.itemCheckBox}>
                  <CheckBox
                    name="accept_terms_1"
                    value={values.accept_terms_1}
                    error={errors && errors.accept_terms_1}
                    touched={touched && touched.accept_terms_1}
                    onClick={check => setConfirm('accept_terms_1', check)}
                  >
                    {intl.CHECKBOX_TEXT_1}
                  </CheckBox>
                </div>

                <div className={styles.itemCheckBox}>
                  <CheckBox
                    name="accept_terms_2"
                    value={values.accept_terms_2}
                    error={errors && errors.accept_terms_2}
                    touched={touched && touched.accept_terms_2}
                    onClick={check => setConfirm('accept_terms_2', check)}
                    dataTestid=""
                  >
                    {intl.CHECKBOX_TEXT_2}
                  </CheckBox>
                </div>

                <div className={styles.itemCheckBox}>
                  <CheckBox
                    name="accept_terms_3"
                    value={values.accept_terms_3}
                    error={errors && errors.accept_terms_3}
                    touched={touched && touched.accept_terms_3}
                    onClick={check => setConfirm('accept_terms_3', check)}
                    dataTestid=""
                  >
                    {intl.CHECKBOX_TEXT_3}
                  </CheckBox>
                </div>
              </div>
              <div className="col-12">
                {intl.LABEL_DATE} {today}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LegalAdviceAndConsent;
