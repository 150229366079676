import { useContext } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../intl';
import styles from './CertificateCategory.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

export type CertificateCategoryType = 'all' | 'travel' | 'shopping';

interface CertificateCategoryProps {
  filterSelected: CertificateCategoryType;
  onClickFilter: (category: CertificateCategoryType) => void;
  enabledCategories?: Record<CertificateCategoryType, boolean>;
}

const CertificateCategory = ({
  filterSelected,
  onClickFilter,
  enabledCategories = {
    all: true,
    shopping: true,
    travel: true,
  },
}: CertificateCategoryProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.headerContainer}>
      <h2 className={`${styles.title} ${styles[themes]}`}>{intl.TEXT_TITLE}</h2>
      <div className={styles.headerActions}>
        {!!enabledCategories.all && (
          <span
            className={`${
              filterSelected === 'all' ? styles.filterSelected : ''
            } ${styles[themes]}`}
            onClick={() => onClickFilter('all')}
          >
            {intl.TEXT_ALL}
          </span>
        )}
        {!!enabledCategories.travel && (
          <span
            className={`${
              filterSelected === 'travel' ? styles.filterSelected : ''
            } ${styles[themes]}`}
            onClick={() => onClickFilter('travel')}
          >
            {intl.TEXT_TRAVEL}
          </span>
        )}
        {!!enabledCategories.shopping && (
          <span
            className={`${
              filterSelected === 'shopping' ? styles.filterSelected : ''
            } ${styles[themes]}`}
            onClick={() => onClickFilter('shopping')}
          >
            {intl.TEXT_SHOPPING}
          </span>
        )}
      </div>
    </div>
  );
};

export default CertificateCategory;
