import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { pt_BR } from '../../../../../../intl/idioms';
import * as translations from './intl';

const IncidentPeriodArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date, 'DD/MM/YYYY HH:mm').format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );
  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const incidentDescription =
    incident && incident.description ? incident.description : '-';

  const incidentDate =
    incident && incident.date
      ? formatDateAndHour(incident.date, 'DD/MM/YYYY HH:mm')
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.DESCRIPTION_INCIDENT}
          value={incidentDescription}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.INCIDENT_DATE} value={incidentDate} />
      </div>
    </>
  );
};

export default IncidentPeriodArea;
