import * as Yup from 'yup';
import { parse, isValid } from 'date-fns';

const validationSchema = intl =>
  Yup.object().shape({
    type: Yup.string()
      .oneOf(['VIDEO', 'PHONE'])
      .required(intl.TEXT_FIELD_REQUIRED),
    call_reason: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    files: Yup.array(),
    date: Yup.string().when('type', {
      is: 'VIDEO',
      then: Yup.string(intl.TEXT_FIELD_REQUIRED).required(
        intl.TEXT_FIELD_REQUIRED
      ),
    }),
    time: Yup.string().when('type', {
      is: 'VIDEO',
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError()
        .test('test-hour-valid', intl.INVALID_TIME, function (hour) {
          return isValid(parse(hour, 'HH:mm', new Date()));
        }),
    }),
    timezone: Yup.string().when('type', {
      is: 'VIDEO',
      then: Yup.string(intl.TEXT_FIELD_REQUIRED).required(
        intl.TEXT_FIELD_REQUIRED
      ),
    }),
  });

export default validationSchema;
