import { useState, useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../intl';
import { certificatesServices, travelService } from '../../../../../services';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { CertificateWithTravel, formatCertificates } from './utils';
import { LoadingStatus } from '../../../../../@types/LoadingStatus';
import ShadowBox from '../../../../../components/ShadowBox/ShadowBox';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import styles from './CertificateAreaTravelAssistance.module.scss';
import SectionCertificateList from './SectionCertificateList/SectionCertificateList';
import Loading from '../../../../../components/Loading/Loading';
import Button from '../../../../../componentsV2/Button/Button';

const CertificateAreaTravelAssistance = ({ setCertificate, setStep }) => {
  const { utils } = useContext(StateContext);
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const customer_id = utils.getCn();
  const card = utils.getBin();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.LOADING
  );
  const [travelCertificates, setTravelCertificates] =
    useState<CertificateWithTravel[]>();

  const getTravelCertificates = async () => {
    //if (info.status !== 'LOADED' || !card) return
    trackEventUserAction(
      `#### Trip Assistance - Form ### Requesting certificates`,
      {
        customer_id: customer_id,
        external_reference: card,
        idiom: idiomForApi(),
      }
    );
    const { data: userCertificates } =
      await certificatesServices.getCertificatesByBin(
        customer_id,
        card,
        idiomForApi()
      );
    trackEventUserAction(
      `#### Trip Assistance - Form ### Certificates loaded`,
      {
        data: userCertificates,
      }
    );

    return userCertificates;
  };

  const getTravels = async () => {
    //if (info.status !== 'LOADED' || !card) return

    trackEventUserAction(`#### Trip Assistance - Form ### Travels`, {
      customer_id: customer_id,
      external_reference: card,
      idiom: idiomForApi(),
    });

    const { data } = await travelService.getTravels(customer_id);

    trackEventUserAction(`#### Trip Assistance - Form ### Travels loaded`, {
      data,
    });

    return data;
  };

  const loadInformation = async () => {
    setLoadingStatus(LoadingStatus.LOADING);
    try {
      const [certificates, travels] = await Promise.all([
        getTravelCertificates(),
        getTravels(),
      ]);

      const handledCertificates = formatCertificates(certificates, travels);

      setTravelCertificates(handledCertificates);
      setLoadingStatus(LoadingStatus.LOADED);
    } catch (error) {
      setLoadingStatus(LoadingStatus.ERROR);
      trackEventUserAction(
        `#### Trip Assistance - Form ### Error requesting certificates`,
        { data: error?.response?.data || error }
      );
    }
  };

  useEffect(() => {
    const loadInformationCertificate = async () => {
      await loadInformation();
    };
    loadInformationCertificate();
  }, [card]);

  return (
    <ShadowBox>
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2 id="mainContent" className={styles[theme]}>
            {intl.CERTIFICATE_SELECTION}
          </h2>
        </div>
        {loadingStatus === LoadingStatus.LOADING && (
          <div className={styles.loadingContainer}>
            <Loading scale={1} checkBoxLoading={'checkBoxLoading'} text={''} />
          </div>
        )}
        {loadingStatus === LoadingStatus.LOADED && (
          <SectionCertificateList
            certificates={travelCertificates}
            setCertificate={setCertificate}
            setStep={setStep}
          />
        )}

        <div className={`${styles.footer} ${styles[theme]}`}>
          <Button
            type="Secondary"
            id="btnBackTermsAndConditions"
            className={styles.button}
            onClick={() => setStep(1)}
            testId="data-testid-btnBackTermsAndConditions"
            label={intl.BTN_BACK}
          />
        </div>
      </div>
    </ShadowBox>
  );
};

export default CertificateAreaTravelAssistance;
