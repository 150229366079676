export const LABEL_CAR_RENTAL_COMPANY = {
  en_US: 'Name of car rental company *',
  pt_BR: 'Nome da empresa de aluguel de automóvel *',
  es_ES: 'Nombre de la compañia de alquiler de automóviles *',
};

export const TITLE_PHONE_COMPANY = {
  en_US: 'Phone number of rental company',
  pt_BR: 'Número de telefone da empresa de aluguel de automóvel',
  es_ES: 'Número de teléfono de la compañia de alquiler de automóviles',
};
