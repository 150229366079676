export const getRefsCreateClaim = fn => {
  return {
    policy: fn(null),
    incident: fn(null),
    reimbursement: fn(null),
    legal: fn(null),
  };
};

export const getRefsUpdateClaim = fn => {
  return {
    policy: fn(null),
    incident: fn(null),
    legal: fn(null),
  };
};
