/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import HorizontalHalfBlueLayout from '../../../layouts/HorizontalHalfBlueLayout';
import {
  certificatesServices,
  productsAndBenefitsServices,
} from '../../../services';
import Form from './Form';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import StepClaim from '../../Claims/StepClaim/StepClaim';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './TripAssistanceGenerate.module.scss';
import Loading from '../../../components/Loading/Loading';
import LoadingError from '../../../components/LoadingError/LoadingError';
import CertificateAreaTravelAssistance from './ActivationForm/CertificateArea/CertificateAreaTravelAssistance';
import { LoadingStatus } from '../../../@types/LoadingStatus';
import { isTripAssistance } from '../../../utils/benefits';

const FlightDetails = ({ match }) => {
  const { certificate_id, benefit_id } = match.params;
  const [tripAssistanceData, setTripAssistanceData] = useState(null);
  const { idiomForApi, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const [certificate, setCertificate] = useState(null);
  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [step, setStep] = useState(1);
  const isTerms = step === 1;
  const isCertificateArea = step === 2;
  const isFORM = step === 3;
  const binOrPan = utils.getBin(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const isLoading = status === 'LOADING';
  const isError = status === 'ERROR';
  const isSuccess = !isLoading && !isError;

  useEffect(() => {
    if (certificate_id) {
      loadCertificateDetails();
    }

    loadBenefitDetails(benefit_id);
  }, []);

  const loadBenefitSummary = async () => {
    try {
      const { data } = await productsAndBenefitsServices.getBenefitsByProduct(
        binOrPan,
        idiomForApi(),
        false,
        false
      );

      const tripAssistanceBenefit =
        Array.isArray(data) && data?.find(isTripAssistance);
      return tripAssistanceBenefit.benefit_id;
    } catch (error) {
      setStatus(LoadingStatus.ERROR);
    }
  };

  const loadBenefitDetails = async (benefitId: string) => {
    try {
      setStatus(LoadingStatus.LOADING);

      benefitId ??= await loadBenefitSummary();

      const { data } =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          binOrPan,
          benefitId,
          idiomForApi(),
          false
        );

      setTripAssistanceData(data);
      setStatus(LoadingStatus.LOADED);
    } catch (error) {
      setStatus(LoadingStatus.ERROR);
    }
  };

  const loadCertificateDetails = async () => {
    try {
      const travelCertificateDetails =
        await certificatesServices.getCertificatesById(
          certificate_id,
          idiomForApi()
        );

      setCertificate(travelCertificateDetails.data);
      setStatus(LoadingStatus.LOADED);
      setStep(3);
    } catch (error) {
      setStatus(LoadingStatus.ERROR);
    }
  };

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading scale={1} checkBoxLoading={'checkBoxLoading'} text={''} />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" />
        </div>
      )}
      {isSuccess && (
        <HorizontalHalfBlueLayout status={status}>
          <SessionContainer>
            <HeadingTitle
              title={intl.TRIP_ASSISTANCE}
              subtitle={intl.SUBTITLE_MY_BENEFITS}
              backLink="/your-benefits"
            />
            <StepClaim
              currentStep={step}
              stepNames={[intl.TEXT_STEP_1, intl.TEXT_STEP_2, intl.TEXT_STEP_3]}
              axa={false}
            />
            {isTerms && (
              <TermsAndConditions
                benefit_id={benefit_id}
                setStep={setStep}
                tripAssistanceData={tripAssistanceData}
              />
            )}

            {isCertificateArea && (
              <CertificateAreaTravelAssistance
                setCertificate={setCertificate}
                setStep={setStep}
              />
            )}
            {isFORM && <Form setStep={setStep} certificate={certificate} />}
          </SessionContainer>
        </HorizontalHalfBlueLayout>
      )}
    </div>
  );
};

export default FlightDetails;
