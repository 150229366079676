export const getMenuNameByPathname = (subtract = 1) => {
  const path = window.location.pathname.split('/');
  const menuName = path[path.length - subtract];
  return menuName;
};

export const getMenuNameByPathnameExists = menuNames => {
  const path = window.location.pathname.split('/');
  return path.find(name => (menuNames.includes(name) ? name : false));
};

export const scrollToHash = ({ hash }) => {
  if (hash) {
    const id = hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      const getTopElement =
        element.getBoundingClientRect().top + window.pageYOffset;

      window.scrollTo({ top: getTopElement - 90, behavior: 'smooth' });
    }
  }
};
