/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import styles from './Pagination.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';

const Pagination = props => {
  const { data, amountItemsByPage, setPage, page, isMobile } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const themes = getGlobalTheme();
  const pagesList = Array.from(
    Array(Math.ceil(data.length / amountItemsByPage)).keys()
  );

  return (
    <nav
      aria-label={intl.PAGE_NAVIGATION_EXAMPLE}
      className={`${styles[themes]} ${styles.pagination}`}
    >
      <ul className="pagination">
        <li className="page-item" onClick={() => setPage(1)}>
          <a className="page-link" aria-label={intl.PREVIOUS}>
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        <li
          className={`page-item`}
          onClick={() => (page === 1 ? '' : setPage(page - 1))}
        >
          <a className="page-link" aria-label={intl.PREVIOUS}>
            <span aria-hidden="true">&lsaquo;</span>
            <span className="sr-only">Previous</span>
          </a>
        </li>
        {!isMobile &&
          pagesList.map(item => {
            const active = page === item + 1 ? 'active' : '';
            return (
              <li
                className={`page-item ${styles[themes]} ${
                  active ? styles.activepage : ''
                }`}
                key={item}
                onClick={() => setPage(item + 1)}
              >
                <a className={`page-link ${styles[themes]}`}>{item + 1}</a>
              </li>
            );
          })}
        <li
          className="page-item"
          onClick={() => (page === pagesList.length ? '' : setPage(page + 1))}
        >
          <a className="page-link" aria-label={intl.PREVIOUS}>
            <span aria-hidden="true">&rsaquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
        <li className="page-item" onClick={() => setPage(pagesList.length)}>
          <a className="page-link" aria-label={intl.NEXT}>
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
