export const LABEL_NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre(s) *',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellidos *',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const LABEL_AGE = {
  en_US: 'Age',
  pt_BR: 'Idade',
  es_ES: 'Edad',
};

export const LABEL_MOBILE_NUMBER = {
  en_US: 'Mobile number *',
  pt_BR: 'Celular *',
  es_ES: 'Celular *',
};

export const TITLE_PASSENGER = {
  en_US: index => `Passenger #${index}`,
  pt_BR: index => `Passageiro(a) #${index}`,
  es_ES: index => `Pasajero(a) #${index}`,
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATION_MESSAGE_DATE = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const TITLE_NO_ADDITIONAL_TRAVELERS = {
  en_US: 'You have no additional travellers.',
  pt_BR: 'Você não possui passageiros adicionais.',
  es_ES: 'No tienes viajeros adicionales.',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};
