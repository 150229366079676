export const LABEL_HOSPITAL_NAME = {
  en_US: 'Name of hospital or clinic responsible for medical care',
  pt_BR: 'Nome do hospital ou clínica responsável pelos cuidados médicos',
  es_ES: 'Nombre del hospital o clínica responsable para el cuidado médico',
};

export const LABEL_DOCTOR_NAME = {
  en_US: 'Doctor name',
  pt_BR: 'Nome do médico responsável pelo atendimento',
  es_ES: 'Nombre del médico responsable de la atención',
};

export const LABEL_DOCTOR_LAST_NAME = {
  en_US: 'Doctor last name',
  pt_BR: 'Sobrenome do médico responsável pelo atendimento médico',
  es_ES: 'Apellido del médico responsable de la atención',
};

export const LABEL_DOCTOR_EMAIL = {
  en_US: 'Doctor / Hospital e-mail',
  pt_BR: 'E-mail do médico / hospital responsável pelo atendimento',
  es_ES: 'Correo eletrónico del médico / hospital responsable de la atención',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};
