import styles from './InputNumber.module.scss';
import { safeGet } from '../../utils/object';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { removeRequiredCharLabel } from '../../utils/stringUtils';
import MaskedInput from 'react-text-mask';
import Button from '../../componentsV2/Button/Button';
import { joinClassNames } from '../../utils/classNames';

const InputNumber = props => {
  let {
    label,
    name,
    value,
    error,
    onChange,
    onBlur,
    touched,
    formikProps,
    onFocus,
    axa,
    decrease,
    increase,
    disableMin,
    disableMax,
  } = props;
  const { getGlobalTheme } = useTheme(axa);
  const theme = getGlobalTheme();

  if (formikProps) {
    onChange = formikProps.handleChange;
    onBlur = formikProps.handleBlur;
    error = safeGet(formikProps, 'errors.' + name);
    touched = safeGet(formikProps, 'touched.' + name);
    value = safeGet(formikProps, 'values.' + name);
  }

  let className = `form-control`;
  if (error && touched) className += ' ' + styles.error;
  if (value && !error) className += ' ' + styles.value;

  let labelClassName = `${styles.label} ${styles[theme]}`;
  const errorLabelClassName = `${styles.errorLabel}`;

  const formikError = error && '1';

  return (
    <div className={styles.Input}>
      <label
        className={`${labelClassName} ${styles[theme]} ${
          axa ? styles.axa : ''
        }`}
      >
        {removeRequiredCharLabel(label)}
      </label>

      <Button
        type="Secondary"
        axa={axa}
        className={`${styles.buttonChange} ${styles.minor} ${styles[theme]}`}
        onClick={decrease}
        disabled={disableMin}
        label="-"
      />

      <MaskedInput
        mask={[/[0-9]/, /[0-9]/]}
        formik-error={formikError}
        className={`${className} ${styles[theme]}`}
        autoComplete="off"
        type="text"
        placeholder={label}
        name={name}
        value={value}
        showMask={false}
        guide={false}
        onChange={onChange}
        onFocus={event => {
          onFocus && onFocus(event);
        }}
        onBlur={event => {
          onBlur && onBlur(event);
        }}
      />
      <Button
        type="Secondary"
        axa={axa}
        className={`${styles.buttonChange} ${styles.plus} ${styles[theme]}`}
        onClick={increase}
        disabled={disableMax}
        label="+"
      />
      {error && touched && (
        <label className={joinClassNames(errorLabelClassName, styles[theme])}>
          {error}
        </label>
      )}
    </div>
  );
};

export default InputNumber;
