import { AxiosInstance, AxiosResponse } from 'axios';
import {
  ExpectedDocument,
  GetExpectedDocumentQuery,
} from '../@types/APIs/ExpectedDocuments';

export default (_, axios: AxiosInstance, errorHandler) => {
  return {
    getExpectedDocuments: async (
      idiom: string,
      query?: GetExpectedDocumentQuery
    ): Promise<AxiosResponse<ExpectedDocument[]>> => {
      const url = `/expected_documents`;

      const headers = {
        'Accept-Language': idiom,
      };

      return errorHandler(
        axios.get(url, { params: query, headers }),
        'getExpectedDocuments',
        { query, idiom }
      );
    },
  };
};
