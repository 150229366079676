/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext, useState } from 'react';
import { claimsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import UploadPage from './UploadPage/UploadPage';
import LoadingError from '../../../components/LoadingError/LoadingError';
import { IntlContext } from '../../../intl/index';
import style from './Upload.module.scss';
import Loading from '../../../components/Loading/Loading';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { verifyPersonalDocuments } from '../../../utils/personalDocuments/verifyPersonalDocuments';
import * as translationsPersonalDocuments from '../../../utils/personalDocuments/intl';
import { ENABLE_UUP } from '../../../utils/environments_variables';
import { ExpectedDocument } from '../../../@types/APIs/ExpectedDocuments';
import { ClaimInfo } from '../../../@types/APIs/claim';

const setAdditionalToTop = documents => {
  const additional = documents.filter(
    document => document.document_type_name === 'Additional Documents'
  );

  const documentsList = documents.filter(
    document => !(document.document_type_name === 'Additional Documents')
  );

  return [...additional, ...documentsList];
};

const loadData = async (bin, claimId, setData, idiom, trackEventUserAction) => {
  try {
    trackEventUserAction(
      '#### (UPLOAD) CARREGANDO INFORMAÇÕES DA TELA DE UPLOAD ####',
      true
    );
    const response = await Promise.all([
      claimsServices.expectedDocuments(bin, idiom, claimId),
      claimsServices.getClaimInfoByClaimId(claimId, idiom),
    ]);

    trackEventUserAction(
      '#### (UPLOAD) INFORMAÇÕES DA TELA DE UPLOAD CARREGADAS - EXPECTED DOCUMENTS ####',
      response[0]
    );
    trackEventUserAction(
      '#### (UPLOAD) INFORMAÇÕES DA TELA DE UPLOAD CARREGADAS - CLAIM INFO ####',
      response[1]
    );

    setData({
      expectedDocuments: setAdditionalToTop(response[0].data),
      claimInfo: response[1].data,
    });
  } catch (error) {
    trackEventUserAction(
      '#### (UPLOAD) ERRO NO CARREGAMENTO DA TELA DE UPLOAD',
      error
    );
    setData('ERROR');
  }
};

interface UploadPropsByRoute {
  claim_id: string;
}

type Data =
  | {
      expectedDocuments: ExpectedDocument[];
      claimInfo: ClaimInfo;
    }
  | 'LOADING'
  | 'ERROR';

const Upload = (props: RouteComponentProps<UploadPropsByRoute>) => {
  const { idiomForApi, idiom, country, translate } = useContext(IntlContext);
  const { utils, actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [data, setData] = useState<Data>('LOADING');
  const { claim_id: claimId } = props.match.params;
  const { history } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const bin = utils.getBin();
  const intlPersonalDocuments = translate(translationsPersonalDocuments);

  useEffect(() => {
    loadData(bin, claimId, setData, idiomForApi(), trackEventUserAction);
    if (ENABLE_UUP) {
      verifyPersonalDocuments(
        utils,
        actions,
        intlPersonalDocuments,
        history,
        country
      );
    }
  }, [bin, claimId, idiom, country, idiomForApi]);

  const isLoading = data === 'LOADING';
  const isError = data === 'ERROR';
  const successLoad = !isLoading && !isError;
  return (
    <div className={`${style.wrapperUpload} ${style[theme]}`}>
      {isLoading && <Loading />}
      {isError && <LoadingError color="#FFF" />}
      {successLoad && <UploadPage data={data} claimId={claimId} />}
    </div>
  );
};

export default withRouter(Upload);
