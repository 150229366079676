/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import ModalResult from '../../components/ModalResult/ModalResult';
import ModalWithImage from '../../components/ModalWithImage/ModalWithImage';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import { DownloadService } from '../../services';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

import styles from './Download.module.scss';
import * as translation from './intl';

const Download = ({ match }) => {
  const { params } = match;
  const { file_id } = params;

  const { getGlobalTheme } = useContext(ThemeContext);
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const themes = getGlobalTheme();
  const intl = translate(translation);

  const downloadService = async () => {
    try {
      const downloadFile = await DownloadService.getDownloadFile(file_id);
      const { data } = downloadFile;
      const arr = new Uint8Array(data.data);
      const blob = new Blob([arr], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${file_id}.pdf`;
      link.click();
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        actions.modal.closeModal();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.CERTIFICATE_NOT_FOUND}
          />,
          true,
          false
        );
      } else {
        actions.alert.showError(intl.UNEXPECTED_ERROR_OCCURRED);
        actions.modal.showModal(
          false,
          true,
          <ModalWithImage type="error" title={intl.UNEXPECTED_ERROR_OCCURRED}>
            <ModalResult
              textConfirm={intl.TEXT_OK}
              clickConfirm={() => {
                actions.modal.closeModal();
              }}
            />
          </ModalWithImage>,
          true
        );
      }
    }
  };

  useEffect(() => {
    downloadService();
  }, []);

  return (
    <div className={`${styles.boxContainer} ${styles[themes]}`}>
      <div id="mainContent" className={styles.container}>
        <h1 data-testid="title" className={`${styles.title} ${styles[themes]}`}>
          {intl.TITLE}
        </h1>
        <span className={`${styles.subtitle} ${styles[themes]}`}>
          {intl.SUBTITLE}
        </span>
      </div>
    </div>
  );
};

export default withRouter(Download);
