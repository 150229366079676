import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const FIELD_START_DATE = {
  en_US: 'Event date *',
  pt_BR: 'Data do evento *',
  es_ES: 'Fecha del evento *',
};

export const FIELD_DESCRIPTION = {
  en_US: 'Event description',
  es_ES: 'Describa el evento',
  pt_BR: 'Descreva o incidente',
};

export const FIELD_COUNTRY = {
  en_US: 'Country',
  es_ES: 'País',
  pt_BR: 'País',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const FIELD_STATE = {
  en_US: 'State',
  es_ES: 'Estado',
  pt_BR: 'Estado',
};

export const FIELD_LOCALITY = {
  en_US: 'City',
  es_ES: 'Ciudad',
  pt_BR: 'Cidade',
};

export const TEXT_THIRD_PARTY = {
  en_US: 'Is there a third party involved?',
  es_ES: '¿Hay un tercero involucrado?',
  pt_BR: 'Existe um terceiro envolvido?',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'no',
};

export const FIELD_NAME = {
  en_US: 'Third party name',
  es_ES: 'Nombre de terceros',
  pt_BR: 'Nome do terceiro',
};

export const FIELD_LAST_NAME = {
  en_US: 'Third party last name',
  es_ES: 'Apellido de terceros',
  pt_BR: 'Sobrenome do terceiro',
};

export const FIELD_ADDRESS_EMAIL = {
  en_US: 'E-mail *',
  es_ES: 'Correo eletrónico *',
  pt_BR: 'E-mail *',
};

export const TITLE_EVENT_LOCATION = {
  en_US: 'Event location',
  es_ES: 'Lugar del evento',
  pt_BR: 'Local do evento',
};

export const DESCRIBE_THIRD_PARTY_INVOLVED = {
  en_US:
    'New fields will be displayed to fill in with the personal details of the other person affected',
  es_ES:
    'Aparecerán nuevos campos para rellenar con los datos personales de la otra persona afectada',
  pt_BR:
    'Novos campos serão exibidos para preencher com os dados pessoais da outra pessoa afetada',
};
