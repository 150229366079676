import { useState } from 'react';
import styles from './LinkMainContent.module.scss';
import * as translations from './intl';
import { useIntl } from '../../intl';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import NavHashLink from '../NavHashLink/NavHashLink';

const VISIBLE = 0;
const HIDDEN = -80;

const LinkMainContent = () => {
  const [display, setDisplay] = useState(HIDDEN);
  const { translate } = useIntl();
  const { getGlobalTheme } = useTheme();

  const intl = translate(translations);
  const theme = getGlobalTheme();

  return (
    <div
      className={`${styles.hiddenLinkContainer} ${styles[theme]}`}
      style={{ top: display }}
    >
      <NavHashLink
        to="#mainContent"
        className={`${styles.anchorLink} ${styles[theme]}`}
        onFocus={() => setDisplay(VISIBLE)}
        onBlur={() => setDisplay(HIDDEN)}
      >
        {intl.SKIP_TO_MAIN_CONTENT}
      </NavHashLink>
    </div>
  );
};

export default LinkMainContent;
