export const COMIENZO_TARJETA_VISA = '4';
export const COMIENZO_TARJETA_MASTERCARD = '5';
export const COMIENZO_TARJETA_AMEX_1 = '34';
export const COMIENZO_TARJETA_AMEX_2 = '37';
export const COMIENZO_TARJETA_DISCOVER = '6';

export const VISA_0 = 0;
export const MASTERCARD_1 = 1;
export const AMEX_2 = 2;
export const DINERS_CLUB_3 = 3;
export const ELO_4 = 4;

export const UPSELLING = 'upselling';
export const CROSSELLING = 'crosselling';
