import React, { useContext, useMemo } from 'react';
import * as translations from './intl';
import { Formik } from 'formik';
import { IntlContext } from '../../../../../../../intl';

import formInitialValues from './initialValues';
import schema from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import ItemsArea from './ItemsArea/ItemsArea';
import TotalArea from './TotalArea/TotalArea';
import OtherInsuranceArea from './OtherInsuranceArea/OtherInsuranceArea';
import AmountArea from './AmountArea/AmountArea';

const BagProtection = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(() => schema(intl), [intl]);
  const initialValues = useMemo(
    () => formInitialValues(parentValues.policy, idiom, loadedData),
    [parentValues.policy, idiom, loadedData]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit}>
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid={'submitButton'}
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <IncidentArea {...props} />
              <ItemsArea {...props} />
              <AmountArea {...props} />
              <OtherInsuranceArea {...props} />
              <TotalArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BagProtection;
