import { useContext, useEffect, useState } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import styles from './ChangeEmail.module.scss';
import { userServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import CardHolderEmailInfo from './CardHolderEmailInfo/CardHolderEmailInfo';
import Loading from '../../../../components/Loading/Loading';
import EditIcon from '../../../../assets/icons/EditIcon';
import ModalEditEmailCardHolder from './ModalEditEmailCardHolder/ModalEditEmailCardHolder';
import Button from '../../../../componentsV2/Button/Button';
import { joinClassNames } from '../../../../utils/classNames';

const loadContactDetails = async (cn, setUserInfo, setLoading) => {
  try {
    const { data } = await userServices.getCHInfo(cn);
    setUserInfo(data);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

const ChangeEmail = () => {
  const { utils, state, actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const [userInfo, setUserInfo] = useState('');
  const [loading, setLoading] = useState(true);
  const intl = translate(translations);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    loadContactDetails(cn, setUserInfo, setLoading);
  }, [cn, state.user.data.userInfo]);

  const editCardHolder = () => {
    actions.modal.showModal(
      intl.TEXT_EDIT_EMAIL,
      true,
      <ModalEditEmailCardHolder userInfo={userInfo} />,
      false,
      false,
      null,
      null,
      null,
      true
    );
  };

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <h2 className={styles[themes]}>{intl.CHANGE_EMAIL}</h2>
      <span className={joinClassNames(styles.text, styles[themes])}>
        {intl.BODY_TEXT}
      </span>
      {loading && <Loading />}
      {!loading && (
        <>
          <CardHolderEmailInfo userInfo={userInfo} />
          <div>
            <Button
              type="Tertiary"
              id="edit-contat-button"
              onClick={editCardHolder}
              leadingIcon={<EditIcon width={24} height={24} />}
              label={intl.BTN_EDIT}
              className={styles.button}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ChangeEmail;
