export const CERTIFICATE_SELECTION = {
  en_US: 'Selection of the associated certificate',
  es_ES: 'Selección de certificados asociados',
  pt_BR: 'Seleção do bilhete associado',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};
