import { pt_BR } from '../../../../../../../intl/idioms';
import { OTHER_VALUE } from '../../../../../../../utils/consts';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  stringToBoolean,
  removeNonNumeric,
} from '../../../../../../../utils/stringUtils';
import {
  VALUE_AR,
  VALUE_GT,
  VALUE_MX,
} from '../../PaymentInformation/constants';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../utils/environments_variables';

const CARDHOLDER = 'Cardholder';

export const formatReimbursementToApi = (props, create = true) => {
  let BankAccount = {
    bank: props.values.reimbursement.BankAccount.bank,
  };

  // check if bank changed
  let isBankChange = BankAccount.bank.bankInfoChange;

  // new bank original data
  let bankChange = {
    BankAccount: isBankChange
      ? props.values.reimbursement.BankChange.BankAccount
      : {},
  };

  // get bank_account_number from initialValues or bankChange
  let initialBankAccountNumber = !isBankChange
    ? props.initialValues.reimbursement.BankAccount.bank.bank_account_number
    : bankChange.BankAccount.bank.bank_account_number;

  // get bank_account_id from initialValues or bankChange
  let bankAccountId = !isBankChange
    ? props.initialValues.reimbursement.BankAccount.bank_account_id
    : bankChange.BankAccount.bank_account_id;

  // Check if the account number has changed to replace by ''(update) or bank_account_id (create)
  if (BankAccount.bank.bank_account_number === initialBankAccountNumber) {
    if (create) {
      BankAccount.bank.bank_account_number = bankAccountId;
    } else {
      //attention!!!
      BankAccount.bank.bank_account_number = '';
    }
  }

  let IntermediaryBankAccount = null;
  let isIntermediary = false;
  let isBrazil = props.isProfile
    ? props.values.reimbursement &&
      props.values.reimbursement.BankAccount &&
      props.values.reimbursement.BankAccount.bank &&
      props.values.reimbursement.BankAccount.bank.address &&
      props.values.reimbursement.BankAccount.bank.address.country === 'BR'
    : props.idiom === pt_BR;

  if (!isBrazil) {
    BankAccount = formatBankAccountToApi(props);
    isIntermediary =
      props.values.reimbursement.IntermediaryBankAccount &&
      props.values.reimbursement.IntermediaryBankAccount
        .bankIntermediaryView === 'yes';
    if (isIntermediary) {
      IntermediaryBankAccount = formatIntermediaryBankAccountToApi(
        props,
        BankAccount.bank.address.country
      );
    }
  } else {
    delete BankAccount.bank.address;
  }

  BankAccount.is_current_account =
    props.values.reimbursement.BankAccount.is_current_account;
  BankAccount.account_type =
    props.values.reimbursement.BankAccount.account_type;

  //Exception format for MX SWIFT field
  BankAccount.bank_identification_number =
    props.values.reimbursement.BankAccount.bank_identification_number &&
    props.values.reimbursement.BankAccount.bank_identification_number.replace(
      /-/g,
      ''
    );

  const BankAccountHolder = formatBankAccountHolderToApi(props);

  let reimbursement = {
    banking_information: {
      BankAccount,
      BankAccountHolder,
    },
  };

  if (isIntermediary && IntermediaryBankAccount !== null) {
    reimbursement.banking_information.IntermediaryBankAccount =
      IntermediaryBankAccount;
  }

  reimbursement.banking_information.total_amount_reimbursed = {
    description: '',
    value: 0,
    currency: 'EUR',
  };

  return reimbursement;
};

const formatBankAccountToApi = props => {
  let object = { bank: { address: {} } };
  const BankAccount = props.values.reimbursement.BankAccount;
  const country = BankAccount.bankCountryView;

  object.bank.name = BankAccount.bank.name;
  object.bank.bank_account_number = BankAccount.bank.bank_account_number;
  object.account_codes = accountCodes(BankAccount);
  object.bank.address = { country: country };

  return object;
};

const formatIntermediaryBankAccountToApi = (props, country) => {
  let object = { bank: {} };

  const IntermediaryBankAccount =
    props.values.reimbursement.IntermediaryBankAccount;

  object.bank.name = IntermediaryBankAccount.bank.name;
  object.bank.bank_account_number =
    IntermediaryBankAccount.bank.bank_account_number;
  object.account_codes = accountCodes(IntermediaryBankAccount);
  object.account_type = IntermediaryBankAccount.account_type;
  object.bank.address = { country };

  return object;
};

const formatBankAccountHolderToApi = props => {
  let object = {
    person: {},
    person_registration: {},
  };
  const BankAccountHolder = props.values.reimbursement.BankAccountHolder;

  // Add holder type to relationshio if other value
  object.relationship =
    BankAccountHolder.relationship === OTHER_VALUE
      ? BankAccountHolder.holder_type
      : BankAccountHolder.relationship;

  const isBrazil = props.isProfile
    ? props.values.reimbursement &&
      props.values.reimbursement.BankAccount &&
      props.values.reimbursement.BankAccount.bank &&
      props.values.reimbursement.BankAccount.bank.address &&
      props.values.reimbursement.BankAccount.bank.address.country === 'BR'
    : props.idiom === pt_BR;

  if (BankAccountHolder.relationship === CARDHOLDER) {
    const policy = props.values.policy;
    const person = policy.person;
    object.person.first_name = person.first_name;
    object.person.last_name = person.last_name;
    object.person.birth_date = moment(person.birth_date).format('YYYY-MM-DD');
    object.person.gender = person.gender;
    object.person.nationalities = [policy.nationalities];
    object.person.title = person.title;
    object.profession = policy.profession;

    object.person_registration.registration_type =
      policy.person_registrations[0].registration_type;

    object.person_registration.value = policy.person_registrations[0].value;
    policy.address.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
      policy.address.country
    )
      ? policy.address.postal_code
      : removeNonNumeric(policy.address.postal_code);

    object.address = policy.address;

    object.phone = policy.phone;

    if (isBrazil) {
      object.is_politically_exposed = stringToBoolean(policy.politicallyExposed)
        ? true
        : false;
      object.monthly_income = formatSalary(parseInt(policy.salary, 10));
    }
  } else {
    object.person = cloneDeep(BankAccountHolder.person);
    object.person.nationalities = [object.person.nationalities];
    object.person.birth_date = moment(object.person.birth_date).format(
      'YYYY-MM-DD'
    );

    if (Array.isArray(BankAccountHolder.person_registration)) {
      object.person_registration.registration_type =
        BankAccountHolder.person_registration[0].registration_type;
      object.person_registration.value =
        BankAccountHolder.person_registration[0].value;
    } else {
      object.person_registration.registration_type =
        BankAccountHolder.person_registration.registration_type;
      object.person_registration.value =
        BankAccountHolder.person_registration.value;
    }

    BankAccountHolder.address.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
      BankAccountHolder.address.country
    )
      ? BankAccountHolder.address.postal_code
      : removeNonNumeric(BankAccountHolder.address.postal_code);

    object.address = BankAccountHolder.address;

    object.phone = BankAccountHolder.phone;

    if (
      props.values.reimbursement.IntermediaryBankAccount &&
      props.values.reimbursement.IntermediaryBankAccount
        .bankIntermediaryView === 'yes'
    ) {
      //object.holder_type = 'Intermediary';
    }

    object.profession = BankAccountHolder.profession;
    if (isBrazil) {
      object.holder_type = 'beneficiary';
      object.is_politically_exposed = stringToBoolean(
        BankAccountHolder.is_politically_exposed
      );
      object.monthly_income = formatSalary(
        parseInt(BankAccountHolder.monthly_income, 10)
      );
    }
  }

  return object;
};

const formatSalary = salary => {
  let salaryConvert = {
    value: '',
    currency: 'BRL',
  };
  switch (salary) {
    case 1:
      salaryConvert.value = 1000;
      break;

    case 2:
      salaryConvert.value = 1500;
      break;

    case 3:
      salaryConvert.value = 3000;
      break;

    case 4:
      salaryConvert.value = 6000;
      break;

    default:
      salaryConvert.value = salary;
      break;
  }
  return salaryConvert;
};

// Bank account and intermediary account must be identical
const accountCodes = BankAccount => {
  let account_codes = [];

  if (BankAccount.bankCountryView === VALUE_AR) {
    if (BankAccount.CUIT && BankAccount.CUIT.value !== '') {
      const cuitObject = {
        wire_code_type: 'CUIT',
        value: BankAccount.CUIT.value,
      };
      account_codes.push(cuitObject);
    }
    if (BankAccount.FULL_KEY && BankAccount.FULL_KEY.value !== '') {
      const fullKeyObject = {
        wire_code_type: 'FULL_KEY',
        value: BankAccount.FULL_KEY.value,
      };
      account_codes.push(fullKeyObject);
    }
  } else if (BankAccount.bankCountryView === VALUE_GT) {
    const ibanObject = {
      wire_code_type: 'IBAN',
      value: BankAccount.IBAN.value ? BankAccount.IBAN.value : '',
    };

    account_codes.push(ibanObject);
  } else if (BankAccount.bankCountryView === VALUE_MX) {
    const fullKeyObject = {
      wire_code_type: 'FULL_KEY',
      value: BankAccount.FULL_KEY.value,
    };
    account_codes.push(fullKeyObject);
  } else {
    if (BankAccount.SWIFT && BankAccount.SWIFT.value !== '') {
      const swiftObject = {
        wire_code_type: 'SWIFT',
        value: BankAccount.SWIFT.value,
      };
      account_codes.push(swiftObject);
    } else if (BankAccount.ABA && BankAccount.ABA.value !== '') {
      const abaObject = {
        wire_code_type: 'ABA',
        value: BankAccount.ABA.value,
      };
      account_codes.push(abaObject);
    }
  }

  return account_codes;
};
