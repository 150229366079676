export const TEXT_CONTACT_PERSON = {
  en_US: 'Contact person',
  pt_BR: 'Pessoa para contato',
  es_ES: 'Persona de contacto',
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};
