export const TEXT_VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const TITLE_ORIGIN_TO_DESTINATION = {
  en_US: (origin: string, destination: string) => `${origin} to ${destination}`,
  es_ES: (origin: string, destination: string) => `${origin} a ${destination}`,
  pt_BR: (origin: string, destination: string) =>
    `${origin} para ${destination}`,
};

export const LABEL_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};
