import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PlayIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={16} height={18} viewBox="0 0 16 18" fill="none" {...props}>
      <path
        d="M14.9938 7.91719L1.94375 0.15C1.78438 0.0562499 1.62031 0 1.43281 0C0.921875 0 0.504687 0.421875 0.504687 0.9375H0.5V17.0625H0.504687C0.504687 17.5781 0.921875 18 1.43281 18C1.625 18 1.78437 17.9344 1.95781 17.8406L14.9938 10.0828C15.3031 9.825 15.5 9.43594 15.5 9C15.5 8.56406 15.3031 8.17969 14.9938 7.91719Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};
export default PlayIcon;
