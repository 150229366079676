import { useContext, useEffect } from 'react';
import moment from 'moment';
import styles from './Step2.module.scss';
import Select from '../../../../components/Select/Select';
import Input from '../../../../components/Input/Input';
import Button from '../../../../componentsV2/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../utils/sanitize';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { NOTIFICATIONS_ENABLE } from '../../../../utils/environments_variables';
import Calendar from '../../../../components/Calendar/Calendar';
import PhoneForm from '../../../../components/PhoneForm';
import GroupDivisor from '../../../../components/GroupDivisor';

const Step2 = props => {
  const {
    intl,
    backStep,
    nextStep,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let getYesterday = moment(new Date(), 'YYYY-MM-DD HH:ss').subtract(1, 'days');
  let yesterday = new Date(getYesterday);

  const registrationType =
    values.person_registrations.registration_type === 'ID_CARD'
      ? intl.LABEL_NUMBER_NATIONAL_ID + ' *'
      : intl.LABEL_NUMBER_PASSPORT + ' *';

  useEffect(() => {
    if (values.nationalities === 'BR') {
      setFieldValue('person_registrations.registration_type', 'CPF');
    }
    if (
      values.nationalities !== 'BR' &&
      values.person_registrations.registration_type === 'CPF'
    ) {
      setFieldValue('person_registrations.registration_type', 'PASSPORT');
    }
  }, [values.nationalities, values.person_registrations, setFieldValue]);

  return (
    <div className={styles.stepTwo}>
      <h2 className={styles[themes]}>{intl.PERSONAL_INFORMATION}</h2>
      <div className={styles.form_section}>
        <div className="row">
          <div className="col-12 col-sm-9 col-md-4">
            <Input
              disabled={values.disableName}
              value={values.name}
              name="name"
              type="text"
              label={intl.LABEL_FIRSTNAME}
              formControl
              onChange={handleChange}
              touched={touched.name}
              onBlur={handleBlur}
              error={errors.name}
              dataTestid="name"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <Input
              disabled={values.disableLastName}
              value={values.lastName}
              name="lastName"
              type="text"
              label={intl.LABEL_LASTNAME}
              formControl
              onChange={handleChange}
              touched={touched.lastName}
              onBlur={handleBlur}
              error={errors.lastName}
              dataTestid="lastName"
            />
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <Calendar
              value={values.birth_date}
              label={intl.LABEL_BIRTH_DATE}
              name="birth_date"
              dataTestid="InputBirthDateRegisterStep"
              maxDate={yesterday}
              onChange={handleChange}
              touched={touched.birth_date}
              onBlur={handleBlur}
              error={errors.birth_date}
              setFieldTouched={setFieldTouched}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <Input
              disabled
              value={values.email}
              name="email"
              type="text"
              label={intl.LABEL_EMAIL}
              formControl
              onChange={handleChange}
              touched={touched.email}
              onBlur={handleBlur}
              error={errors.email}
              dataTestid="email"
            />
          </div>
        </div>
      </div>

      <div className={`row ${styles.form_section} `}>
        <div className="col-12 col-sm-12 col-md-6">
          <Select
            label={intl.FORM_LABEL_COUNTRY}
            value={values.nationalities}
            name="nationalities"
            dataTestid="InputClaimCHNationalities"
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.FORM_LABEL_COUNTRY}</option>${intl.COUNTRY_OPTIONS}`
              ),
            }}
            touched={touched.nationalities}
            onBlur={handleBlur}
            error={errors.nationalities}
          />
        </div>
        {values.nationalities === 'BR' ? (
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              value={values.person_registrations.value}
              label={intl.LABEL_CPF_BR}
              name={`person_registrations.value`}
              tooltipText={intl.TEXT_ALERT_CPF}
              type="text"
              dataTestid="InputCHRegistrationValue"
              mask={'maskedCPF'}
              onChange={handleChange}
              touched={
                touched.person_registrations &&
                touched.person_registrations.value
              }
              onBlur={handleBlur}
              error={
                errors.person_registrations && errors.person_registrations.value
              }
            />
          </div>
        ) : (
          <div className="col-12 col-sm-12 col-md-6" style={{ padding: 0 }}>
            <div className="row" style={{ margin: 0 }}>
              <div className="col-4 col-sm-12 col-md-6">
                <Select
                  label={intl.LABEL_IDENTIFICATION}
                  value={values.person_registrations.registration_type}
                  name="person_registrations.registration_type"
                  onChange={handleChange}
                  touched={
                    touched.person_registrations &&
                    touched.person_registrations.registration_type
                  }
                  onBlur={handleBlur}
                  error={
                    errors.person_registrations &&
                    errors.person_registrations.registration_type
                  }
                >
                  <option value="">{intl.LABEL_IDENTIFICATION + ' *'}</option>
                  <option value="PASSPORT">{intl.LABEL_PASSPORT}</option>
                  <option value="ID_CARD">{intl.LABEL_NATIONAL_ID}</option>
                </Select>
              </div>
              <div className="col-8 col-sm-12 col-md-6">
                <Input
                  value={values.person_registrations.value}
                  label={registrationType}
                  mask={'maskedPassaport'}
                  name="person_registrations.value"
                  type="text"
                  dataTestid="InputCHRegistrationValue"
                  onChange={handleChange}
                  tooltipText={
                    values.person_registrations.registration_type === 'ID_CARD'
                      ? intl.TEXT_ALERT_NATIONAL_ID
                      : intl.TEXT_ALERT_PASSPORT
                  }
                  touched={
                    touched.person_registrations &&
                    touched.person_registrations.value
                  }
                  onBlur={handleBlur}
                  error={
                    errors.person_registrations &&
                    errors.person_registrations.value
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <PhoneForm
        formikProps={props}
        title={intl.CONTACT_PHONE_NUMBER}
        inputNames={{
          countryCode: 'international_prefix',
          phoneNumber: 'number',
          phoneType: 'phone_type',
        }}
      />
      {NOTIFICATIONS_ENABLE && (
        <div className={`row ${styles.notificationArea}`}>
          <div className="col-12">
            <GroupDivisor title={intl.TITLE_NOTIFICATION} />
          </div>
          <div
            className={`${styles.boxCheckBoxs} ${styles[themes]} col-12 col-sm-12 col-md-12`}
          >
            <CheckBox
              value={values.marketing_notification}
              name="marketing_notification"
              onClick={value => setFieldValue('marketing_notification', value)}
              dataTestid="CustomerNotification"
            >
              <span>{intl.TEXT_MARKETING_NOTIFICATION_TITLE}</span>
            </CheckBox>
            <div className={styles.itemDescription}>
              <span
                className={`${styles[themes]} ${
                  values.marketing_notification ? styles.active : ''
                }`}
              >
                {intl.TEXT_MARKETING_NOTIFICATION_DESCRIPTION}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className={`${styles.btnFooter} ${styles[themes]}`}>
        <div className={styles.divButton}>
          <Button
            type="Secondary"
            onClick={e => backStep(e, 1)}
            label={intl.BTN_BACK}
          />
        </div>
        <div className={styles.divButton}>
          <Button onClick={e => nextStep(e, 3)} label={intl.BTN_NEXT} />
        </div>
      </div>
    </div>
  );
};

export default Step2;
