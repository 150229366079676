import {
  CARD_INFORMATION,
  TEXT_SUBTITLE_REGISTER_STEP_3,
  BTN_BACK,
  BTN_CREATE_ACCOUNT,
  LABEL_EXPIRATION_DATE,
  TEXT_DISCLAIMER_REGISTER_STEP_3,
} from '../../intl';

export {
  CARD_INFORMATION,
  TEXT_SUBTITLE_REGISTER_STEP_3,
  BTN_BACK,
  BTN_CREATE_ACCOUNT,
  LABEL_EXPIRATION_DATE,
  TEXT_DISCLAIMER_REGISTER_STEP_3,
};

export const LABEL_CARD_NUMBER_16 = {
  en_US: 'Card number (16 digits) *',
  es_ES: 'Número de tarjeta (16 dígitos) *',
  pt_BR: 'Número do cartão (16 dígitos) *',
};

export const LABEL_CARD_NUMBER_10 = {
  en_US: 'CTA card number (10 digits) *',
  pt_BR: 'Número do cartão CTA (10 dígitos) *',
  es_ES: 'Número de tarjeta CTA (10 dígitos) *',
};

export const TEXT_DISCLAIMER_CARDS_REGISTER_STEP_3 = {
  en_US:
    'Only Visa cards issued in Latin America and the Caribbean are eligible.',
  pt_BR:
    'Apenas cartões Visa emitidos na América Latina e Caribe são elegíveis.',
  es_ES:
    'Solo son elegibles las tarjetas Visa emitidas en América Latina y el Caribe.',
};
