import { claimsServices, certificatesServices } from '../../../../../services';
import { NOT_REQUIRED } from '../../ClaimFillForm/Form/const';
import { filterNotExpired } from '../../../../../utils/certificate';

export const loadData = async (
  cn,
  bin,
  binOrPan,
  claimType,
  setCertificates,
  idiom,
  setCertificateType,
  incidentDate
) => {
  try {
    const idiomKey = idiom.replace('_', '-');
    const claimTypes = (await claimsServices.getClaimTypes(binOrPan)).data;

    const certificatesPromisse = certificatesServices.getCertificatesByBin(
      cn,
      bin,
      idiomKey
    );
    const { certificate_type_name } = claimTypes.find(
      ct => ct.external_reference === claimType
    );
    const lowerCertificateName = certificate_type_name.toLowerCase();
    setCertificateType(lowerCertificateName);
    if (!certificate_type_name) {
      setCertificates(NOT_REQUIRED);
      return;
    }
    const certificates = (await certificatesPromisse).data;

    const filtered = certificates.filter(
      c =>
        c.certificate_type.toLowerCase() === certificate_type_name.toLowerCase()
    );

    const filteredNotExpired = filterNotExpired(filtered, incidentDate);
    setCertificates(filteredNotExpired);
  } catch (e) {
    setCertificates('ERROR');
  }
};
