export const MODAL_CONFIRMATION_UPDATE_BANK_SUCCESS = {
  en_US:
    'Congratulations! Your bank information has been updated successfully.',
  pt_BR: 'Parabéns! Suas informações bancárias foram atualizadas com sucesso.',
  es_ES:
    '¡Felicidades! Tu información bancaria se ha actualizada correctamente.',
};

export const MODAL_CONFIRMATION_CREATE_BANK_SUCCESS = {
  en_US: 'Congratulations! New bank added successfully.',
  pt_BR: 'Parabéns! Novo banco adicionado com sucesso.',
  es_ES: '¡Felicidades! Nuevo banco agregado con éxito.',
};

export const SAVING_BANK_INFORMATION = {
  en_US: 'Saving bank information...',
  pt_BR: 'Salvando informações bancárias...',
  es_ES: 'Guardando información bancaria...',
};
