import React, { useContext } from 'react';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Generate from '../Generate/Generate';
import styles from './RecentCreated.module.scss';
import LineCertificate from './LineCertificate/LineCertificate';
import LineClaim from './LineClaim/LineClaim';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ClaimsIcon from '../../../../assets/icons/ClaimsIcon';
import CertificateIcon from '../../../../assets/icons/CertificatesIcon';

const typeCertificate = 'certificate';

const RecentCreated = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { type, data } = props;
  let title = intl.TEXT_RECENT_CERTIFICATES;
  const url = {
    certificate: '/profile/certificates#active',
    claim: '/profile/claims',
  };

  if (type !== typeCertificate) {
    title = intl.TEXT_RECENT_CLAIMS;
  }

  const recentContent = claims => {
    if (claims === '' || !claims || claims.length === 0) {
      return (
        <div className={`col-12 ${styles.emptyMessage}`}>{intl.TEXT_EMPTY}</div>
      );
    }

    const lastClaims = claims.slice(0, 2);

    return Object.values(lastClaims).map((value, index) => {
      return (
        index < 2 &&
        (type === typeCertificate ? (
          <LineCertificate
            key={`lineCertificate-${index}`}
            idiom={idiom}
            value={value}
            intl={intl}
            index={index}
            theme={theme}
            country={country}
          />
        ) : (
          <LineClaim
            key={`lineClaim-${index}`}
            idiom={idiom}
            value={value}
            intl={intl}
            index={index}
            theme={theme}
            country={country}
          />
        ))
      );
    });
  };

  return (
    <div className={`col-12`}>
      <div className={`${styles.recents} ${styles[theme]}`}>
        <Generate
          border
          icon={
            type === typeCertificate ? (
              <CertificateIcon width={30} height={30} />
            ) : (
              <ClaimsIcon width={30} height={30} />
            )
          }
          title={title}
          url={url[type]}
          GTM_type={
            type === typeCertificate ? 'Recent Certificates' : 'Recent Claim'
          }
          GTM_id={
            type === typeCertificate
              ? 'CTA View recent certificates'
              : 'CTA View recent claims'
          }
        >
          <div className="row no-gutters">{recentContent(data)}</div>
        </Generate>
      </div>
    </div>
  );
};

export default RecentCreated;
