import { useContext, useMemo } from 'react';
import styles from './index.module.scss';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import { formatScheduleToFlightInfo } from './utils';
import { ScheduleWithAirportInfo } from '../../../../../../../utils';
import FlightInfo from '../../../../../../../../../components/FlightInfo';

interface FlightListItemProps {
  schedule: ScheduleWithAirportInfo;
  legIndex?: number;
  fillFields?: (
    flightIndex: number,
    flightInfo: ScheduleWithAirportInfo
  ) => Promise<void>;
  disabled?: boolean;
}

const FlightListItem = ({
  schedule,
  legIndex,
  fillFields,
  disabled,
}: FlightListItemProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  let containerClassName = ` ${styles.container} ${styles[theme]}`;
  if (disabled) containerClassName += ` ${styles.disabled}`;

  const flightInfo = useMemo(
    () => formatScheduleToFlightInfo(schedule),
    [schedule]
  );

  return (
    <button
      className={containerClassName}
      onClick={() => fillFields(legIndex, schedule)}
      disabled={disabled}
    >
      <FlightInfo flightInfo={flightInfo}>
        <FlightInfo.Label />
        <FlightInfo.Cities />
        <FlightInfo.Airports />
        <FlightInfo.Dates />
      </FlightInfo>
    </button>
  );
};

export default FlightListItem;
