export const TEXT_STEP_1 = {
  en_US: 'Enter the number of passengers',
  pt_BR: 'Informe a quantidade de passageiros',
  es_ES: 'Ingresa la información de viaje',
};

export const TEXT_STEP_2 = {
  en_US: 'Select a plan',
  pt_BR: 'Selecione um plano',
  es_ES: 'Selecciona un plan',
};

export const TEXT_STEP_3 = {
  en_US: 'Acceptance of terms and conditions',
  pt_BR: 'Aceitação dos termos e condições',
  es_ES: 'Aceptación de términos y condiciones',
};

export const TEXT_STEP_4 = {
  en_US: 'Travel information',
  pt_BR: 'Informação de viagem',
  es_ES: 'Información general',
};

export const TEXT_STEP_5 = {
  en_US: 'Payment information',
  pt_BR: 'Informação de pagamento',
  es_ES: 'Información para pago',
};

export const QUOTE_UPSELL_TITLE = {
  en_US: 'Quote an upgrade coverage plan to your trip',
  pt_BR: 'Faça a cotação de um plano para aumento da cobertura da sua viagem',
  es_ES: 'Cotiza tu cobertura de viaje con Axa',
};

export const MODAL_QUOTE_IEMS = {
  en_US: '',
  pt_BR: '',
  es_ES:
    '¡Gracias por tu interés!  Aquí comienza el proceso de cotización de cobertura adicional que se puede agregar a tu beneficio existente.',
};

export const TITLE_INCREASE_COVERAGE = {
  en_US: 'Select a coverage increase option',
  pt_BR: 'Selecione uma opção de aumento de cobertura',
  es_ES: 'Selecciona una opción de cobertura',
};
