import React, { ReactNode, useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './Bluebox.module.scss';

interface BlueboxContainerProps {
  children: ReactNode;
}

const BlueboxContainer = ({ children }: BlueboxContainerProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>{children}</div>
  );
};

export default BlueboxContainer;
