import React, { useContext, useRef, useState } from 'react';
import { Formik } from 'formik';
import styles from './PaymentForm.module.scss';
import { formInitialValues } from './initialValues';
import { validationDataSchema } from './validationSchema';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import FormTokenexATSP from './FormTokenexATSP/FormTokenexATSP';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import FormTokenexOnlyCVVATSP from './FormTokenexOnlyCVVATSP/FormTokenexOnlyCVVATSP';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';
import { submit } from './submit';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  ENABLE_UPSELLING,
  B2B2C_TEST_WHITELIST,
} from '../../../../../utils/environments_variables';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const PaymentForm = props => {
  const {
    submitRef,
    benefitDetail,
    formType,
    updateParent,
    checkFormsValidation,
    backLink,
  } = props;
  const { idiom, translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils, actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { actions: actionsB2B2C, utils: utilsB2B2C } = useContext(B2B2CContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [onlyCvv, setOnlyCvv] = useState(true);
  const initialValues = formInitialValues();
  const theme = getGlobalTheme();
  const validationSchema = validationDataSchema(intl, idiom);
  const token = utils.getBinToken();
  const selectedCard = utils.getSelectedCard();
  const history = useHistory();
  const backLinkAdditionalCoverages = `${backLink}/${benefitDetail.external_reference}`;
  const travelersValues = {
    MPH: utilsB2B2C.getMPH(),
    Passengers: utilsB2B2C.getPassenger(),
  };
  const conditionToChangeCard = B2B2C_TEST_WHITELIST.includes(utils.getEmail());

  const submitEnroll = async values => {
    const formsOk = await checkFormsValidation();

    trackEventUserAction(`#### (B2B2C) INFORMAÇÕES DE PAGAMENTO FORM OK`, {
      formsOk,
    });

    if (formsOk) {
      submit(
        travelersValues,
        values,
        actions,
        utilsB2B2C,
        actionsB2B2C,
        utilsB2B2C.getQuote(),
        utilsB2B2C.getSelectedPlan(),
        benefitDetail,
        selectedCard.policy_id,
        intl,
        history,
        backLinkAdditionalCoverages,
        idiomForApi(),
        trackEventUserAction
      );
    }
  };

  const formRef = useRef();
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitEnroll}
      innerRef={formRef}
    >
      {props => {
        const { handleSubmit, setFieldValue, values } = props;
        updateParent(values, validationSchema);

        return (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                {conditionToChangeCard ? (
                  <CheckBox
                    name="payment.is_preferred"
                    value={values.payment.is_not_preferred}
                    onClick={value => {
                      setFieldValue('payment.is_not_preferred', value);
                      setOnlyCvv(!value);
                    }}
                    axa
                  >
                    {intl.ANOTHER_VISA_CARD}
                  </CheckBox>
                ) : (
                  <p className={`${styles.labelCard} ${styles[theme]}`}>
                    {intl.VISA_CARD_TO_PAYMENT}
                  </p>
                )}
              </div>
            </div>
            <div className={styles.marginBottom}>
              {conditionToChangeCard ? (
                <>
                  {!onlyCvv && (
                    <FormTokenexATSP
                      {...props}
                      submitRef={submitRef}
                      formType={formType}
                    />
                  )}
                  {onlyCvv && (
                    <FormTokenexOnlyCVVATSP
                      {...props}
                      token={token}
                      submitRef={submitRef}
                      formType={formType}
                    />
                  )}
                </>
              ) : (
                <FormTokenexOnlyCVVATSP
                  {...props}
                  token={token}
                  submitRef={submitRef}
                  formType={formType}
                />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
