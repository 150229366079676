export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const LABEL_ITEM_PURCHASED = {
  en_US: 'Item purchased',
  pt_BR: 'Produto comprado',
  es_ES: 'Artículo comprado',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const LABEL_MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};

export const LABEL_PURCHASED_PRICE = {
  en_US: 'Product value on the sales receipt',
  pt_BR: 'Valor do produto na nota fiscal',
  es_ES: 'Valor del producto en la factura de compra',
};

export const LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT = {
  en_US: 'Purchase value on the Visa invoice',
  pt_BR: 'Valor da compra na fatura Visa',
  es_ES: 'Valor de compra en el estado de cuenta',
};
