import * as translations from './intl';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import { vcesService } from '../../services';

const VCES_CREDIT_CARD_NOT_ELIGIBLE = 'VCES: Credit card not eligible';
const VCES_ERROR_VALIDATING_CREDIT_CARD_TOKEN =
  'VCES: Error validating credit card token';
const VCES_FORBIDDEN = 'VCES: Forbidden';
const VCES_TEMPORARY_DOWNTIME = 'VCES: Temporary downtime';

const sendEmail = async () => {
  try {
    await vcesService.vcesDownTime();
  } catch (error) {}
};

export const VCESValidation = async (error, actions, translate) => {
  const intl = translate(translations);
  let message = '';

  switch (error) {
    case VCES_CREDIT_CARD_NOT_ELIGIBLE:
      message = intl.TEXT_ERROR_CREDIT_CARD_NOT_ELIGIBLE;
      break;
    case VCES_ERROR_VALIDATING_CREDIT_CARD_TOKEN:
      message = intl.TEXT_ERROR_VALIDATING_CREDIT_TOKEN;
      break;
    case VCES_TEMPORARY_DOWNTIME:
      message = intl.TEXT_ERROR_VCES_TEMPORARY_DOWNTIME;
      await sendEmail();
      break;
    case VCES_FORBIDDEN:
      message = intl.TEXT_ERROR_VCES_FORBIDDEN;
      break;
    default:
      message = intl.TEXT_ERROR_VCES_FORBIDDEN;
      break;
  }

  return actions.modal.showModal(
    false,
    false,
    <ModalInformation type="error" message={message} />,
    true
  );
};
