import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const incidentDescription =
    incident && incident.description ? incident.description : '-';

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : {};

  const getChargedOnCard = (title, intl) => {
    switch (title) {
      case true:
        return intl.TEXT_OPTION_YES;
      case false:
        return intl.TEXT_OPTION_NO;
      default:
        return '';
    }
  };

  const isFullChargedOnCard =
    travelInformation && travelInformation.is_full_charged_on_card
      ? getChargedOnCard(travelInformation.is_full_charged_on_card, intl)
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_DESCRIPTION}
          value={incidentDescription}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_TOTAL_AMOUT_CREDIT_CARD}
          value={isFullChargedOnCard}
        />
      </div>
    </>
  );
};

export default IncidentArea;
