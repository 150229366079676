export const TITLE_TRIP_INFORMATION = {
  en_US: 'Trip information',
  pt_BR: 'Informações de viagem',
  es_ES: 'Información de viaje',
};

export const TITLE_PAYMENT = {
  en_US: 'Information for payment',
  pt_BR: 'Informações para pagamento',
  es_ES: 'Información para pago',
};

export const COMPLETE_PURCHASE = {
  en_US: 'Complete purchase',
  pt_BR: 'Completar compra',
  es_ES: 'Realizar pago',
};

export const TEXT_COVERAGE_UNTIL_UPSELL = {
  en_US:
    'If you decide to continue with this additional coverage today, your coverage will be valid for the coverage of multiple trips under this amount from today until +365 days.',
  pt_BR:
    'Se você decidir continuar com esta cobertura adicional hoje, sua cobertura será válida para cobertura de múltiplas viagens abaixo deste valor a partir de hoje até +365 dias.',
  es_ES:
    'Si decide continuar con esta cobertura adicional hoy, tu cobertura será válida para la cobertura de múltiples viajes por debajo de este monto desde hoy hasta +365 días.',
};

export const TEXT_COVERAGE_UNTIL_CROSSELL_ANNUAL = {
  en_US: ``,
  pt_BR: ``,
  es_ES: `Tu cobertura será válida para la cobertura de múltiples viajes por debajo de este monto desde a fecha de inicio del viaje hasta el final del plazo del plan elegido.`,
};
export const TEXT_COVERAGE_UNTIL_CROSSELL_SINGLE_TRIP = {
  en_US: ``,
  pt_BR: ``,
  es_ES: `Tu cobertura será válida por el período del viaje informado desde la fecha de inicio hasta el final del viaje.`,
};
export const TEXT_COVERAGE_UNTIL_CROSSELL_NON_SELECTED = {
  en_US: '',
  pt_BR: '',
  es_ES: 'La cobertura dependerá del plan seleccionado.',
};
