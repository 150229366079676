import { useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import FormBank from './FormBank/FormBank';
import AddIcon from '../../../../../assets/icons/AddIcon';
import BankInfoIcon from '../../../../../assets/icons/BankInfoIcon';
import Button from '../../../../../componentsV2/Button/Button';

const AddArea = props => {
  const [addText, setAddText] = useState('');
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const newBankForm = () => {
    actions.modal.showModal(
      props.title,
      true,
      <FormBank newBank {...props} />,
      false,
      false,
      null,
      null,
      <BankInfoIcon width={35} height={35} />,
      true,
      false,
      true
    );
  };

  const titleByWidthScreen = () => {
    if (window.innerWidth > 768) {
      setAddText(intl.ADD_LONG);
    } else {
      setAddText(intl.ADD_SHORT);
    }
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
  }, []);

  return (
    <Button
      id="addButtonIcon"
      type="Tertiary"
      onClick={() => {
        newBankForm();
      }}
      label={addText}
      leadingIcon={<AddIcon />}
    />
  );
};

export default AddArea;
