import { useContext } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import styles from './ChangePassword.module.scss';
import ModalCreditCard from '../../../../assets/images/modal/modal_image_credit_card.png';
import ModalWithImage from '../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../components/ModalResult/ModalResult';
import Button from '../../../../componentsV2/Button/Button';
import { userServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../../../utils/classNames';

const ChangePassword = () => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const openModalChangePassword = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="success"
        title={intl.TITLE_TEXT}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.CONFIRM_TEXT}
          textDeny={intl.DENY_TEXT}
          clickConfirm={() => handleChangePassword()}
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true,
      true
    );
  };

  const handleChangePassword = async () => {
    try {
      await userServices.changePassword(idiomForApi());
      openModalChangePasswordSucess();
    } catch (error) {
      openModalChangePasswordFailure();
    }
  };

  const openModalChangePasswordFailure = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.FAILURE_CHANGE_PASSWORD_TEXT}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.TRY_AGAIN_TEXT}
          textDeny={intl.TRY_LATER_TEXT}
          clickConfirm={() => handleChangePassword()}
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true,
      true
    );
  };

  const openModalChangePasswordSucess = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="success"
        title={intl.SUCCESS_CHANGE_PASSWORD_TEXT}
        image={ModalCreditCard}
      ></ModalWithImage>,
      true,
      true
    );
  };

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <h2 className={styles[themes]}>{intl.CHANGE_PASSWORD}</h2>
      <span className={joinClassNames(styles[themes], styles.text)}>
        {intl.BODY_TEXT}
      </span>
      <Button
        id="changePasswordButton"
        type="Secondary"
        onClick={openModalChangePassword}
        label={intl.CHANGE_PASSWORD}
        className={styles.button}
      />
    </div>
  );
};

export default ChangePassword;
