import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { formatCustomDate } from '../../../../utils/date';
import moment from 'moment';
import { pt_BR } from '../../../../intl/idioms';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const MissedConnectionDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date, 'DD/MM/YYYY HH:mm').format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : '-';

  const fromDate =
    travelInformation && travelInformation.from
      ? formatCustomDate(travelInformation.from, 'DD/MM/YYYY', idiom, country)
      : '-';

  const toDate =
    travelInformation && travelInformation.to
      ? formatCustomDate(travelInformation.to, 'DD/MM/YYYY', idiom, country)
      : '-';

  const programmedFlights =
    claimDetails && claimDetails.travel_information.programmed_flights[0]
      ? claimDetails.travel_information.programmed_flights[0]
      : {};
  const programmedFlights2 =
    claimDetails && claimDetails.travel_information.programmed_flights[1]
      ? claimDetails.travel_information.programmed_flights[1]
      : {};

  const departureDateAndTime =
    programmedFlights && programmedFlights.departure_time
      ? formatDateAndHour(programmedFlights.departure_time)
      : '-';
  const arrivalDateAndTime =
    programmedFlights && programmedFlights.arrival_time
      ? formatDateAndHour(programmedFlights.arrival_time)
      : '-';
  const departureProgrammedDateAndTime =
    programmedFlights2 && programmedFlights2.departure_programmed_time
      ? formatDateAndHour(programmedFlights2.departure_programmed_time)
      : '-';
  const arrivalProgrammedDateAndTime =
    programmedFlights2 && programmedFlights2.arrival_programmed_time
      ? formatDateAndHour(programmedFlights2.arrival_programmed_time)
      : '-';

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const incidentDate =
    incident && incident.date
      ? formatCustomDate(incident.date, 'YYYY-MM-DD HH:mm:ss', idiom, country)
      : '-';

  const incidentDescription =
    incident && incident.description ? incident.description : '-';

  const totalAmountClaimed =
    claimDetails && claimDetails.total_amount_claimed
      ? claimDetails.total_amount_claimed
      : {};

  const totalValue =
    totalAmountClaimed && totalAmountClaimed.value
      ? totalAmountClaimed.value
      : '-';

  const totalCurrency =
    totalAmountClaimed && totalAmountClaimed.currency
      ? totalAmountClaimed.currency
      : '-';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue
          label={intl.TRIP_PERIOD}
          value={`${fromDate} - ${toDate}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.PRIOR_MISSED}
          value={`${departureDateAndTime} - ${arrivalDateAndTime}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.PORTION_MISSED}
          value={`${departureProgrammedDateAndTime} - ${arrivalProgrammedDateAndTime}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.DATE_MISSED} value={incidentDate} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.DESCRIPTION} value={incidentDescription} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.TOTAL_AMOUNT}
          value={`${formatMonetaryMask(
            totalValue,
            idiomForApi()
          )} ${totalCurrency} `}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency} `}
          />
        </div>
      )}
    </div>
  );
};

export default MissedConnectionDetail;
