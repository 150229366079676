import { get } from 'lodash';

export const deepEqual = (x, y) => {
  return x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce(function (isEqual, key) {
          return isEqual && deepEqual(x[key], y[key]);
        }, true)
    : x === y;
};

export const safeGet = (target, key, defaultValue = '') => {
  if (!target) return undefined;
  return get(target, key, defaultValue);
};
