/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './PricePeriodArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import moment from 'moment';
import { isSameOrAfterDay } from '../../../../../../../../utils/date';

const PricePeriodArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    limitDate,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    if (values.purchased_item.purchase_date) {
      setFieldValue(
        'advertised_price.date_compare_from_purchase_date',
        values.purchased_item.purchase_date
      );
    }
  }, []);

  const setDateToCompare = e => {
    e &&
      e.target &&
      e.target.value &&
      setFieldValue(
        'advertised_price.date_compare_from_purchase_date',
        e.target.value
      );
    handleChange(e);
  };

  return (
    <div className={`row ${styles.rentalPeriodlArea}`}>
      <div className="col-12 col-md-6">
        <Calendar
          value={values.purchased_item.purchase_date}
          label={intl.TEXT_PURCHASE_DATE}
          name="purchased_item.purchase_date"
          onChange={setDateToCompare}
          maxDate={
            limitDate && !isSameOrAfterDay(limitDate, new Date())
              ? limitDate
              : new Date()
          }
          minDate={
            idiom === 'pt_BR'
              ? new Date(moment().subtract(30, 'days'))
              : undefined
          }
          touched={
            touched.purchased_item && touched.purchased_item.purchase_date
          }
          error={errors.purchased_item && errors.purchased_item.purchase_date}
          setFieldTouched={setFieldTouched}
          dataTestid="InputPurchaseDate"
          tooltipText={intl.COVERS_END_AFTER_30_DAYS}
        />
      </div>
      <div className="col-12 col-md-6">
        <Calendar
          value={values.advertised_price.discovery_date}
          label={intl.TEXT_ADVERTISEMENT_DATE}
          name="advertised_price.discovery_date"
          onChange={handleChange}
          maxDate={new Date()}
          touched={
            touched.advertised_price && touched.advertised_price.discovery_date
          }
          error={
            errors.advertised_price && errors.advertised_price.discovery_date
          }
          setFieldTouched={setFieldTouched}
          dataTestid="InputDiscoveryDate"
        />
      </div>
    </div>
  );
};

export default PricePeriodArea;
