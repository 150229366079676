export const HOW_TO_PURCHASE = {
  en_US: 'How to purchase?',
  es_ES: '¿Como comprar?',
  pt_BR: 'Como comprar?',
};

export const HOW_TO_ENJOY = {
  en_US: 'How to enjoy',
  es_ES: 'Como disfrutar',
  pt_BR: 'Como usufruir',
};

export const ENJOY_NOW = {
  en_US: 'Access your benefit',
  es_ES: 'Accede a tu beneficio',
  pt_BR: 'Utilize seu benefício',
};

export const HOW_TO_CLAIM = {
  en_US: 'Access your benefit',
  es_ES: 'Accede a tu beneficio',
  pt_BR: 'Utilize seu benefício',
};

export const NEW_TAB = {
  en_US: 'new tab',
  pt_BR: 'nova aba',
  es_ES: 'nueva pestaña',
};

export const BUTTON_LOG_IN = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};
