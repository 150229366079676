import React from 'react';
import IncidentArea from './IncidentArea/IncidentArea';
import ContactPersonArea from './ContactPersonArea/ContactPersonArea';
import FinancialArea from './FInancialArea/FinancialArea';
import PersonalArea from './PersonalArea/PersonalArea';
import AgencyArea from './AgencyArea/AgencyArea';
import CertificationArea from './CertificationArea/CertificationArea';

const CorporateLiabilityWaiver = props => {
  const { claimDetails } = props;
  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <ContactPersonArea claimDetails={claimDetails} />
      <FinancialArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <AgencyArea claimDetails={claimDetails} />
      <CertificationArea claimDetails={claimDetails} />
    </div>
  );
};

export default CorporateLiabilityWaiver;
