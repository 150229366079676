export const TEXT_TITLE_LOGIN = {
  en_US: 'Do you know the Benefits Portal Visa?',
  es_ES: '¿Conoces el Portal de Beneficios Visa?',
  pt_BR: 'Você conhece o Portal de Benefícios Visa?',
};

export const TEXT_TRAVEL = {
  en_US: 'Travel is safe and peaceful with Visa',
  es_ES: 'Viajar es seguro y tranquilo con Visa',
  pt_BR: 'Viajar é seguro e tranquilo com Visa',
};

export const EMPTY_BENEFITS = {
  en_US:
    'There is no benefit available for the Visa product selected, please contact the issuing bank for more information or learn more about Visa products.',
  es_ES:
    'No hay ningún beneficio disponible para el producto Visa seleccionado, comunícate con el banco emisor para obtener más información u obtener más información sobre los productos Visa.',
  pt_BR:
    'Não existe benefício disponível para o produto Visa selecionado, por favor contate o banco emissor para mais informações ou conheça mais produtos Visa.',
};

export const BUTTON_TEXT = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};

export const URL_LOGIN = {
  en_US: '/',
  es_ES: '/',
  pt_BR: '/',
};

export const LOAD_MORE = {
  en_US: 'Load more',
  es_ES: 'Carga más',
  pt_BR: 'Ver mais',
};

export const VIEW_MORE = {
  en_US: 'View more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_TITLE_UPSELLING = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Serviços Médicos de Emergência Internacional',
};

export const TEXT_SUBTITLE_UPSELLING = {
  en_US: 'Know and improve your coverage',
  es_ES: 'Conoce y mejora tu cobertura',
  pt_BR: 'Conheça e melhore sua cobertura',
};

export const TEXT_BUTTON_UPSELLING = {
  en_US: 'Improve your coverage with AXA',
  es_ES: 'Mejora tu cobertura con AXA',
  pt_BR: 'Melhorar sua cobertura com AXA',
};

export const TEXT_TITLE_CROSSELLING = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Serviços Médicos de Emergência Internacional',
};

export const TEXT_SUBTITLE_CROSSELLING = {
  en_US: 'Know and get this coverage',
  es_ES: 'Conoce y obtén esta cobertura',
  pt_BR: 'Conheça e obtenha esta cobertura',
};

export const TEXT_BUTTON_CROSSELLING = {
  en_US: 'Buy now with AXA',
  es_ES: 'Compra ahora con AXA',
  pt_BR: 'Adquira agora com a AXA',
};
