import React from 'react';

import PrivateCertificatesList from '../../../components/PrivateCertificatesList/PrivateCertificatesList';
import PrivateCertificatesNew from '../../../components/PrivateCertificatesNew/PrivateCertificatesNew';

const Certificates = () => {
  return (
    <>
      <PrivateCertificatesList />
      <PrivateCertificatesNew />
    </>
  );
};

export default Certificates;
