export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const INVOLVED_PEOPLE_INCIDENT = {
  en_US: 'Are there other people involved in the incident?',
  pt_BR: 'Existem outras pessoas envolvidas no incidente?',
  es_ES: '¿Hay otras personas involucradas en el incidente?',
};

export const TYPE = {
  en_US: 'Type *',
  pt_BR: 'Tipo *',
  es_ES: 'Tipo *',
};

export const WITNESS = {
  en_US: 'Witness',
  pt_BR: 'Testemunha',
  es_ES: 'Testigo',
};

export const DOCTOR = {
  en_US: 'Doctor',
  pt_BR: 'Médico',
  es_ES: 'Doctor',
};

export const KNOW_DOCTOR = {
  en_US: 'Known doctor',
  pt_BR: 'Médico conhecido',
  es_ES: 'Doctor conocido',
};

export const FIRST_NAME = {
  en_US: 'First name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const ADD = {
  en_US: 'Add',
  pt_BR: 'Adicionar',
  es_ES: 'Añadir',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const HELP_TYPE_PEOPLE_INVOLVED = {
  en_US: `Witness: Someone who witnessed the accident.
  Doctor: Surgeon and/or physician who attended the affected person with the injuries suffered.
  Known Doctor: Surgeon and/or physician who attended the deceased in the last 5 years.`,
  pt_BR: `Testemunha: Alguém que testemunhou o acidente.
  Médico: Cirurgião e/ou médico que atendeu a pessoa afetada com os ferimentos sofridos.
  Médico conhecido: Cirurgião e/ou médico que atendeu o falecido nos últimos 5 anos.`,
  es_ES: `Testigo: Alguien que presenció el accidente.
  Médico: cirujano y/o médico que atendió a la persona afectada con las lesiones sufridas.
  Médico conocido: cirujano y/o médico que atendió al fallecido en los últimos 5 años.`,
};

export const TEXT_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const TITLE_ITEM_INVOLVED_PERSON = {
  en_US: index => `Involved person #${index}`,
  pt_BR: index => `Pessoa envolvida #${index}`,
  es_ES: index => `Persona involucrada #${index}`,
};

export const ICON_ADD = {
  en_US: 'Icon Add',
  es_ES: 'Ícono Agregar',
  pt_BR: 'Ícone Adicionar',
};

export const ICON_DELETE = {
  en_US: 'Icon Delete',
  es_ES: 'Ícono Eliminar',
  pt_BR: 'Ícone Excluir',
};
