import { useIconColors } from '../../hooks/useIconColors';
import { SVGIconProps } from '../../@types/Icons';

const ExternalLinkIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fill={colors.secondary}
        d="M14 6h2V0h-6v2h2.54L6 8.543 7.457 10 14 3.464V6Z"
      />
      <path
        fill={colors.primary}
        fillRule="evenodd"
        d="M0 1h8v2H2v11h11V8h2v8H0V1Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default ExternalLinkIcon;
