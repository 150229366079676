/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useEffect, useState } from 'react';
import styles from './ReviewSelectedPlan.module.scss';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';
import moment from 'moment';
import { en_US } from '../../../../../intl/idioms';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import { getCurrencyByFormType } from '../../../../../utils/B2C/currencyISOCode';
import { Formik } from 'formik';
import { initialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import {
  getPrefixFromCountry,
  countriesExceptions,
} from '../../../../../utils/country';
import countries from '../../../../../intl/countryPrefix.json';
import DatesArea from './DatesArea/DatesArea';
import CountryArea from './CountryArea/CountryArea';

const ReviewSelectedPlan = props => {
  const {
    submitRef,
    formType,
    CONFIG_B2B2C,
    updateParent,
    type,
    personalData,
    setStep,
  } = props;
  const [filterCountries, setFilterCountries] = useState([]);
  const { utils } = useContext(B2B2CContext);
  const { idiom, translate } = useContext(IntlContext);
  const { utils: utilsState } = useContext(StateContext);
  const intl = translate(translations);
  const selectedPlan = utils.getSelectedPlan();
  const quote = utils.getQuote();
  const numberTraleversInput =
    quote && quote.Quote ? quote.Quote.numberTraleversInput : undefined;
  const Name = selectedPlan && selectedPlan.Name;
  const AmountWithoutPromotion =
    selectedPlan && selectedPlan.AmountWithoutPromotion;

  const formatDate = date =>
    moment(date).format(idiom === en_US ? 'MM/DD/YYYY' : 'DD/MM/YYYY');

  const valuesInitial = useMemo(
    () => initialValues(quote, type),
    [quote, type]
  );
  const schema = useMemo(() => validationSchema(intl, type), [intl, type]);

  const filterCountriesToOrigin = async () => {
    let countryCard = utilsState.getCountry();
    try {
      countryCard = countriesExceptions(getPrefixFromCountry(countryCard));

      const countryFiltered = countries.filter(
        country =>
          country.initials === countryCard.initials ||
          country.initials === personalData.user.address.country
      );
      setFilterCountries(countryFiltered);
    } catch (error) {
      const countryFilteredOnlyCard = countries.filter(
        country => country.initials === countryCard.initials
      );
      setFilterCountries(countryFilteredOnlyCard);
    }
  };

  useEffect(() => {
    filterCountriesToOrigin();
  }, []);

  return (
    <Formik
      initialValues={valuesInitial}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {props => {
        const { handleSubmit, values, setFieldTouched } = props;
        updateParent(values, schema);

        return (
          <form onSubmit={handleSubmit}>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              role="btnSubmit"
            ></button>
            <div className={styles.container}>
              <div className="row">
                {CONFIG_B2B2C.purchase.form.payment.issuance_date.show && (
                  <div className="col-12 col-md-4">
                    <InfoLabelValue
                      label={intl.ISSUANCE_DATE}
                      value={formatDate(moment())}
                      axa
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {CONFIG_B2B2C.purchase.form.payment.product_selected.show && (
                  <div className="col-12 col-md-4">
                    <InfoLabelValue
                      label={intl.PRODUCT_SELECTED}
                      value={Name}
                    />
                  </div>
                )}
                {CONFIG_B2B2C.purchase.form.payment.total_pay.show && (
                  <div className="col-12 col-md-4">
                    <InfoLabelValue
                      label={intl.TOTAL_TO_PAY}
                      value={`$${AmountWithoutPromotion}${getCurrencyByFormType(
                        formType
                      )}`}
                    />
                  </div>
                )}
              </div>
              <div className="row">
                <CountryArea
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  intl={intl}
                  filterCountries={filterCountries}
                  idiom={idiom}
                  {...props}
                />
              </div>
              <div className="row">
                <DatesArea
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  intl={intl}
                  setStep={setStep}
                  {...props}
                />
                {CONFIG_B2B2C.purchase.form.payment.passanger.show && (
                  <div className="col-12 col-md-4">
                    <InfoLabelValue
                      label={intl.PASSENGERS_NUMBER}
                      value={numberTraleversInput}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ReviewSelectedPlan;
