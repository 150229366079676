import React from 'react';

import ScheduleListButtonNAL from './scheduleListButton/ScheduleListButtonNAL';

const ScheduleListNAL = props => {
  const { schedule, setSchedule } = props;
  return (
    <ScheduleListButtonNAL
      {...props}
      setSchedule={setSchedule}
      schedule={schedule}
    />
  );
};

export default ScheduleListNAL;
