export { TRIP_ASSISTANCE } from '../../../intl/travels';

export const SUBTITLE_MY_BENEFITS = {
  en_US: 'My benefits',
  es_ES: 'Mis beneficios',
  pt_BR: 'Meus benefícios',
};

export const TITLE_PROVIDE_FLIGHT_DETAILS = {
  en_US: 'Provide flight details',
  es_ES: 'Proporcionar detalles de vuelo',
  pt_BR: 'Fornecer detalhes do voo',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Certificate selection',
  es_ES: 'Selección de certificado',
  pt_BR: 'Seleção de bilhete',
};

export const TEXT_STEP_3 = {
  en_US: 'Flight information',
  es_ES: 'Información de vuelo',
  pt_BR: 'Informação de voo',
};
