import React, { useContext } from 'react';
import styles from './TitleSectionProfile.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const TitleSectionProfile = ({
  title,
  number = null,
  subtitle = null,
  loading = false,
  showNumber = true,
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  return (
    <div className={styles.titleSection}>
      <h2 className={`${styles.title} ${styles[themes]}`}>
        {title} {!loading && number !== null && showNumber && `(${number})`}
      </h2>
      {subtitle && !loading && (
        <h4 className={`${styles.subtitle} ${styles[[themes]]}`}>{subtitle}</h4>
      )}
    </div>
  );
};

export default TitleSectionProfile;
