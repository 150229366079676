export const TOOLTIP_ICON = {
  en_US: 'Tooltip Icon',
  es_ES: 'Ícono de información',
  pt_BR: 'Ícone de dica',
};

export const DESCRIBE_INFO_CONTENT = {
  en_US: 'When you change this option:',
  pt_BR: 'Quando você muda essa opção:',
  es_ES: 'Cuando cambias esta opción:',
};
