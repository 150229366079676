import { useContext, useState, useEffect } from 'react';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './Claims.module.scss';
import RecentCreated from '../RecentCreated/RecentCreated';
import { claimsServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import TotalReimbursed from '../../../../components/TotalReimbursed/TotalReimbursed';
import { CLAIMS } from '../../../../utils/consts';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../../../utils/environments_variables';
import InProcess from '../../../../assets/icons/InProcess';
import ClaimsIcon from '../../../../assets/icons/ClaimsIcon';
import Loading from '../../../../components/Loading/Loading';
import Generate from '../Generate/Generate';
import ClaimsPlus from '../../../../assets/icons/ClaimsPlusIcon';
import Link from '../../../../componentsV2/Link/Link';

const getProcessClaims = claims => {
  if (!claims) return 0;
  const status = 'pending send';
  let processClaims = [];

  claims.map(claim => {
    if (claim.status.toLowerCase() === status) {
      processClaims.push(1);
    }
  });

  return processClaims.length;
};

const Claims = () => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const [generatedClaims, setGeneratedClaims] = useState([]);
  const [processClaims, setProcessClaims] = useState(0);
  const [loading, setLoading] = useState(true);
  const { utils } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const theme = getGlobalTheme();
  const [distinctClaims, setDistinctClaims] = useState(0);

  const getDistinctClaims = claims => {
    if (!claims) return 0;
    let uniqueClaims = [];

    claims.map(claim => {
      if (uniqueClaims.indexOf(claim.benefit_external_reference) === -1) {
        uniqueClaims.push(claim.benefit_external_reference);
      }
    });

    return uniqueClaims.length;
  };

  useEffect(() => {
    const getGenerated = async () => {
      try {
        setLoading(true);
        const userClaims = await claimsServices.getClaims(
          cn,
          binOrPan,
          idiomForApi()
        );
        setGeneratedClaims(userClaims.data);
        setProcessClaims(getProcessClaims(userClaims.data));
        const distinctClaims = getDistinctClaims(userClaims.data);
        setDistinctClaims(distinctClaims);
        setLoading(false);
      } catch (error) {
        setDistinctClaims(0);
        setGeneratedClaims(0);
        setLoading(false);
      }
    };

    getGenerated();
  }, [binOrPan]);

  return (
    <div className={`col-lg-6 col-md-12 ${styles.claims}`}>
      <div className={`row no-gutters ${styles[theme]} ${styles.counter}`}>
        <div className={`${styles.item}`}>
          <span className={styles.icon}>
            <InProcess width={35} height={35} />
          </span>
          <div className={styles.info}>
            {loading ? (
              <span>
                <Loading />
              </span>
            ) : (
              <span className={`${styles.number} ${styles[theme]}`}>
                {processClaims}
              </span>
            )}

            <div>
              <h6 className={styles[theme]}>{intl.TEXT_CLAIMS_IN_PROCESS}</h6>
            </div>
          </div>
        </div>
        <hr className={`${styles.divider} ${styles[theme]}`} />
        <div className={`${styles.item}`}>
          <span className={styles.icon}>
            <ClaimsIcon width={30} height={30} />
          </span>
          <div className={styles.info}>
            {loading ? (
              <span>
                <Loading />
              </span>
            ) : REACT_APP_TOTAL_REIMBURSEMENT ? (
              <span className={`${styles.number} ${styles[theme]}`}>
                {distinctClaims}
              </span>
            ) : (
              <span className={`${styles.number} ${styles[theme]}`}>
                {generatedClaims.length}
              </span>
            )}
            <div>
              <h6 className={styles[theme]}>{intl.TEXT_CLAIMS_OPENED}</h6>
              <Link
                onClick={() =>
                  dataLayerContentMenuTrack(
                    'CTA See my claims',
                    'Check history'
                  )
                }
                href="/profile/claims"
                icon={false}
                label={intl.TEXT_VIEW_YOUR_CLAIM}
                internal
              />
            </div>
          </div>
        </div>
      </div>
      {/* commented for refund rules review */}
      {REACT_APP_TOTAL_REIMBURSEMENT && (
        <TotalReimbursed
          type={CLAIMS}
          claimData={generatedClaims}
          shouldShowLinkToProfile
          shouldShowTooltip
          isDashboard
        />
      )}
      <div className={`row no-gutters`}>
        {REACT_APP_TOTAL_REIMBURSEMENT && (
          <div className={`col-lg-12 col-md-12 ${styles.generate}`}>
            <Generate
              blue
              icon={
                <ClaimsPlus width={30} height={30} data-testid="addClaim" />
              }
              title={intl.TEXT_OPEN_A_CLAIM}
              url="/claims"
              GTM_id="CTA Create a claim"
              type="claim"
              lightBackground={false}
            />
          </div>
        )}
        <RecentCreated data={generatedClaims} type="claim" />
      </div>
    </div>
  );
};

export default Claims;
