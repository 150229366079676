import { addDays, isSameDay, parseISO, subDays } from 'date-fns';
import { flightService } from '../../../../../../../services';
import { getUniqueArrayByKey } from '../../../../../../../utils/array';
import { zeroTime } from '../../../../../../../utils/date';
import { Schedule } from '../../../../../../../@types/APIs/AEROAPI/Schedule';
import { ScheduleWithAirportInfo } from '../../../../../utils';
import { FlightDetailsSchema, LegInfo } from '../../../validationSchema';
import { FormikTouched } from 'formik';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export interface SearchData {
  airline: string;
  flightNumber: string;
  flightDate: Date;
}

const getAirportList = (schedules: Schedule[]) => {
  const airportCodes = new Set<string>();

  schedules?.forEach(schedule => {
    airportCodes.add(schedule.origin);
    airportCodes.add(schedule.destination);
  });

  return Array.from(airportCodes);
};

export const loadAirports = async (schedules: Schedule[]) => {
  const airportCodeList = getAirportList(schedules);

  const airports: Record<string, Airport> = {};
  await Promise.all(
    airportCodeList.map(async airportCode => {
      const { data } = await flightService.getAirport(airportCode);
      airports[airportCode] = data;
    })
  );
  return airports;
};

export const formatSchedules = async (
  schedules: Schedule[],
  flightDate: Date
): Promise<ScheduleWithAirportInfo[]> => {
  let formattedSchedules = schedules.map(schedule => ({
    ...schedule,
    actual_ident: schedule.actual_ident || schedule.ident,
  }));

  formattedSchedules = getUniqueArrayByKey(formattedSchedules, 'scheduled_out');

  const airports = await loadAirports(formattedSchedules);

  let schedulesWithAirports = await Promise.all(
    formattedSchedules.map(schedule => ({
      ...schedule,
      originAirport: airports[schedule.origin],
      destinationAirport: airports[schedule.destination],
    }))
  );

  schedulesWithAirports = schedulesWithAirports.filter(schedule => {
    let departureDate = parseISO(schedule.scheduled_out);
    departureDate = utcToZonedTime(
      departureDate,
      schedule.originAirport.timezone
    );

    return isSameDay(departureDate, flightDate);
  });

  return schedulesWithAirports;
};

export const loadFlights = async (searchData: SearchData) => {
  const isAirlineValid = /[A-Za-z0-9]{2,3}/.test(searchData.airline);
  const isFlightNumberValid = Number(searchData.flightNumber) > 0;

  if (!isAirlineValid || !isFlightNumberValid) {
    return;
  }
  const { flightDate, airline, flightNumber } = searchData;

  // AEROAPI don't handle timezones when searching
  // We add search 1 day ahead and 1 day before and then filter
  // with the timezone
  const searchStart = subDays(flightDate, 1);
  const searchEnd = addDays(flightDate, 2);

  const { data } = await flightService.getSchedules(
    searchStart.toISOString(),
    searchEnd.toISOString(),
    {
      max_pages: 3,
      airline: airline,
      flight_number: Number(flightNumber),
    }
  );

  return formatSchedules(data.scheduled, flightDate);
};

export const formatSegment = (
  flightValues: LegInfo,
  flightInfo: ScheduleWithAirportInfo
) => {
  const { airline, flight_number, flight_date } = flightValues;
  const { originAirport, destinationAirport } = flightInfo;

  return {
    airline: airline.toUpperCase(),
    flight_number,
    flight_date,
    airport_origin: flightInfo.origin_iata || flightInfo.origin_lid,
    airport_origin_name: originAirport.name,
    airport_origin_timezone: originAirport.timezone,
    airport_destination:
      flightInfo.destination_iata || flightInfo.destination_lid,
    airport_destination_name: destinationAirport.name,
    airport_destination_timezone: destinationAirport.timezone,
    city_origin: originAirport.city,
    city_destination: destinationAirport.city,
    country_origin: originAirport.country_code,
    country_destination: destinationAirport.country_code,
    isValid: true,
    scheduled_in: flightInfo.scheduled_in,
    scheduled_out: flightInfo.scheduled_out,
  };
};

export const getFlightError = (index, isReturn, errors) => {
  const flightsErrors = isReturn
    ? errors.return && errors.return.segments
    : errors.outbound && errors.outbound.segments;
  const flightErrors = flightsErrors && flightsErrors[index];
  const error =
    typeof flightsErrors === 'string' && index === 0
      ? flightsErrors
      : flightErrors && flightErrors.isValid;

  return error;
};

export const getFlightTouched = (
  touched: FormikTouched<FlightDetailsSchema>,
  flightsKey: string,
  legIndex: number
) => touched?.[flightsKey]?.segments?.[legIndex]?.isValid;
