export const TEXT_TITLE = {
  en_US: 'Generate a new certificate',
  es_ES: 'Genera un nuevo certificado',
  pt_BR: 'Crie um novo bilhete',
};

export const TEXT_ALL = {
  en_US: 'All',
  es_ES: 'Todos',
  pt_BR: 'Todos',
};

export const TEXT_TRAVEL = {
  en_US: 'Travel',
  es_ES: 'Viaje',
  pt_BR: 'Viagem',
};

export const TEXT_SHOPPING = {
  en_US: 'Shopping',
  es_ES: 'Compras',
  pt_BR: 'Compras',
};
