import {
  addMinutes,
  differenceInMonths,
  differenceInCalendarDays,
  format,
  parse,
  parseISO,
} from 'date-fns';
import {
  formatInTimeZone,
  utcToZonedTime,
  format as formatTz,
} from 'date-fns-tz';
import moment from 'moment';
import { pt_BR, en_US, es_ES } from '../intl/idioms';
import { enUS, es, ptBR } from 'date-fns/locale';

export const legacyApiDateFormat = 'yyyy-MM-dd';
export const legacyApiDateFormatWithTime = 'yyyy-MM-dd HH:mm:ss';

const dateFormat = 'MM/DD/YYYY';
const dateFormatBR = 'DD/MM/YYYY';
const dateFormatWithTime = 'MM/dd/yyyy HH:mm';
const dateFormatBRWithTime = 'dd/MM/yyyy HH:mm';
const dateFormatFNS = 'MM/dd/yyyy';
const dateFormatBRFNS = 'dd/MM/yyyy';

export const getLocale = idiom => {
  if (idiom === es_ES) return es;
  if (idiom === pt_BR) return ptBR;
  return enUS;
};

export const getDateFormatByLangAndPlusDays = (
  date,
  plusDays = 0,
  idiom = pt_BR
) => {
  let dateFormated = '';
  if (date) {
    const value = moment(formateDateWithTime(date));
    if (idiom === en_US) {
      dateFormated = moment(value).add(plusDays, 'd').format(dateFormat);
    } else {
      dateFormated = moment(value).add(plusDays, 'd').format(dateFormatBR);
    }
  }

  return dateFormated;
};

function formateDateWithTime(date) {
  const dateFormat = date.length !== 19 ? date + ' 00:00:00' : date;
  return dateFormat;
}

export const addLeadingZero = (number, places = 2) => {
  var num = number.toString();
  return num.padStart(places, '0');
};

export const formatCustomDate = (date, inputFormat, idiom, country) => {
  const isUS = idiom === en_US;
  return moment(date, inputFormat).format(isUS ? dateFormat : dateFormatBR);
};

/*
  * This metod is called for fix the problem about the timezone. Some
  * formats to submit not is converting Date to String.

  * We need this, because:
  https://github.com/axios/axios/issues/2284

  * And one solution can be found in this link:
  https://stackoverflow.com/questions/70689305/customizing-date-serialization-in-axios
*/
export const overwriteToJSONMethodToDate = () => {
  Date.prototype.toJSON = function () {
    return moment(this).format('YYYY-MM-DD');
  };
};

export const isSameOrAfterDay = (date1, date2) => {
  return differenceInCalendarDays(date1, date2) >= 0;
};

export const isSameOrAfterMonth = (date1, date2) =>
  differenceInMonths(date1, date2) >= 0;

export const zeroTime = date => {
  try {
    return new Date(date.toISOString().substring(0, 10));
  } catch (error) {
    return null;
  }
};

export const formatDate = (date, idiom, includeTime = false, timeZone) => {
  try {
    let handledFormat;
    if (idiom === en_US)
      handledFormat = includeTime ? dateFormatWithTime : dateFormat;
    else handledFormat = includeTime ? dateFormatBRWithTime : dateFormatBR;

    return timeZone
      ? formatInTimeZone(date, timeZone, handledFormat)
      : format(date, handledFormat);
  } catch (error) {
    return null;
  }
};

export const formatDateFromString = (
  dateString,
  idiom,
  formatString = 'yyyy-MM-dd'
) => {
  try {
    const date = parse(dateString, formatString, new Date());
    return format(date, idiom === 'en_US' ? dateFormatFNS : dateFormatBRFNS);
  } catch (error) {
    return '';
  }
};

export const formatISOWithOffset = (isoDate, timeZone) => {
  try {
    const startAtDate = utcToZonedTime(parseISO(isoDate), timeZone);
    return formatTz(startAtDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
      timeZone,
    });
  } catch (error) {
    return null;
  }
};

export const formatDateWithOffset = (dateString, idiom, format = 'P') => {
  try {
    const offset = moment.parseZone(dateString).utcOffset();
    const date = parseISO(dateString);

    return formatInTimeZone(addMinutes(date, offset), 'UTC', format, {
      locale: getLocale(idiom),
    });
  } catch (error) {
    return null;
  }
};

export const convertTimeUS = time => {
  const hour = Number(String(time).trim().substring(0, 2));

  if (hour < 12) {
    return `${time}am`;
  } else if (hour === 12) {
    return `${time}pm`;
  } else {
    let _time = hour % 12;
    const minutes = String(time).trim().substring(3);
    return `${_time}:${minutes}pm`;
  }
};
