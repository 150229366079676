import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const TEXT_INCIDENT_AREA = {
  en_US: 'Incident place',
  es_ES: 'Lugar del incidente',
  pt_BR: 'Lugar do incidente',
};

export const TEXT_COUNTRY_INCIDENT = {
  en_US: 'Country of incident',
  es_ES: 'País del incidente',
  pt_BR: 'País do incidente ',
};

export const TEXT_STATE_INCIDENT = {
  en_US: 'State of incident',
  es_ES: 'Estado del incidente',
  pt_BR: 'Estado do incidente',
};

export const TEXT_CITY_INCIDENT = {
  en_US: 'City of incident',
  es_ES: 'Ciudad del incidente',
  pt_BR: 'Cidade do incidente',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const COUNTRY_LABEL = {
  en_US: 'Country',
  pt_BR: 'País',
  es_ES: 'País',
};

export const TEXT_DESCRIPTION_INCIDENT = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const TIP_BRIEF_DESCRIBE_INCIDENT = {
  en_US: 'Briefly describe the incident.',
  es_ES: 'Describe brevemente el incidente.',
  pt_BR: 'Descreve suscintamente o incidente.',
};
