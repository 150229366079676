export default (env, axios, errorHandler) => {
  return {
    getOffers: async () => {
      const url = '/offers/get_offers';
      return errorHandler(axios.get(url), 'getOffers', { url });
    },

    getOfferDetails: async id => {
      const url = `/offers/get_offer_details/${id}`;
      return errorHandler(axios.get(url), 'getOfferDetails', { url, id });
    },

    getFavorites: async userId => {
      const jwtToken = (
        await errorHandler(axios.post('/offerToken/get_jwt_token'))
      ).data;

      const url = '/offers/get_favorites';
      return errorHandler(
        axios.post(url, { jwtToken, userId }),
        'getFavorites',
        {
          url,
          data: [],
        }
      );
    },

    addFavorites: async (userId, offerId) => {
      const jwtToken = (
        await errorHandler(axios.post('/offerToken/get_jwt_token'))
      ).data;

      const url = '/offers/add_favorite';
      return errorHandler(
        axios.post(url, { jwtToken, userId, offerId }),
        'addFavorites',
        {
          url,
          data: [],
        }
      );
    },

    removeFavorites: async (userId, offerId) => {
      const jwtToken = (
        await errorHandler(axios.post('/offerToken/get_jwt_token'))
      ).data;

      const url = '/offers/remove_favorite';
      return errorHandler(
        axios.post(url, { jwtToken, userId, offerId }),
        'removeFavorites',
        {
          url,
          data: [],
        }
      );
    },
  };
};
