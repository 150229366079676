import styles from './Feedback.module.scss';
import Button from '../../../componentsV2/Button/Button';
import * as translations from './intl';
import { useIntl } from '../../../intl';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '../../../assets/icons/ChevronLeftIcon';
import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';

const Feedback = () => {
  const { translate } = useIntl();
  const { push } = useHistory();
  const intl = translate(translations);
  const { getCurrentThemeColors } = useTheme();
  const colors = getCurrentThemeColors();

  const handleRedirect = () => {
    push('/');
  };

  return (
    <div className={styles.container}>
      <Button
        onClick={handleRedirect}
        label={intl.LABEL_BUTTON_BACK_TO_HOME}
        leadingIcon={
          <ChevronLeftIcon
            color={colors['icon-inverse-secondary']}
            width={16}
          />
        }
      />
    </div>
  );
};

export default Feedback;
