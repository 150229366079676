import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { formatCustomDate } from '../../../../../../utils/date';
import {
  getFlightNumber,
  getIncidentDescription,
  getIncidentPlace,
  getIncidentStartDate,
  getTripPeriod,
} from '../../../../../../utils/claimsAPI/TripUtils';

const IncidentArea = props => {
  const { claimDetails } = props;

  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [tripInformation, setTripInformation] = useState({});
  const [incident, setIncident] = useState({});
  const [agency, setAgency] = useState({});

  useEffect(() => {
    const tripInformation =
      (claimDetails && claimDetails.trip_information) || {};
    setTripInformation(tripInformation);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const agency = (claimDetails && claimDetails.agency) || {};
    setAgency(agency);
  }, [claimDetails]);

  const cancellationDate =
    tripInformation && tripInformation.interruption_date
      ? tripInformation.interruption_date
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.TEXT_CANCELLATION_DATE}
          value={formatCustomDate(cancellationDate, null, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={getTripPeriod(tripInformation, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.TEXT_DATE_INCIDENT}
          value={getIncidentStartDate(incident, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_PLACE}
          value={getIncidentPlace(incident, idiom)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          value={getIncidentDescription(tripInformation)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.TEXT_FLIGHT_NUMBER}
          value={getFlightNumber(agency)}
        />
      </div>
    </>
  );
};

export default IncidentArea;
