import { es_mx_nationalities } from '../../../../../intl/es_mx_nationalities';

export const LABEL_SELECT = {
  en_US: 'Choose option',
  pt_BR: 'Escolha a opção',
  es_ES: 'Elija opción',
};

export const NATIONALITY_OPTIONS = {
  en_US: '',
  pt_BR: '',
  es_ES: es_mx_nationalities,
};

export const COUNTRY_ORIGIN = {
  en_US: 'Origin country',
  pt_BR: 'País de origem',
  es_ES: 'País de origen',
};

export const COUNTRY_DESTINATION = {
  en_US: 'Destination country',
  pt_BR: 'País de destino',
  es_ES: 'País de destino',
};

export const CURRENCY_LABEL = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

export const CURRENCY_MEXICAN_PESO = {
  en_US: `Mexican peso`,
  pt_BR: `Peso mexicano`,
  es_ES: `Peso mexicano`,
};
