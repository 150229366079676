/* eslint-disable no-unused-vars */
export const rideshareFormInitialValues = (data, idiom) => {
  let person = data && data.person ? data.person : '';
  return {
    travel_information: {
      from_date: '',
      to_date: '',
    },

    incident: {
      discovered_at: '',
      hour_discovered_at: '',
      description: '',
      location: {
        country: '',
        locality: '',
      },
      concerned_items: [
        {
          description: '',
          purchase_date: '',
          price: {
            value: '',
            currency: '',
          },
        },
      ],
    },
    affected_person: {
      relationship: 'Cardholder',
      comment: '',
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      },
    },

    other_insurance_covering: {
      is_internal: false,
      checked: 'false',
      description: 'OTHER',
      value: '',
      currency: '',
      rideshare: {
        is_internal: false,
        rideshare_checked: 'false',
        description: 'RIDESHARE',
        value: '',
        currency: '',
      },
    },
    other_company_covering: {
      is_internal: false,
      checked: false,
      description: 'TRANSPORTATION COMPANY',
      value: '',
      currency: '',
    },
    total_amount_claimed: {
      value: '',
      currency: '',
    },
    amount_estimation_damage: {
      value: '',
      currency: '',
    },
  };
};
