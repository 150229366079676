export const TEXT_EDIT_PROFILE = {
  en_US: 'My profile',
  pt_BR: 'Meu perfil',
  es_ES: 'Mi perfil',
};

export const TEXT_ADDITIONAL_ACCESSES = {
  en_US: 'Additional accesses',
  pt_BR: 'Acessos adicionais',
  es_ES: 'Accesos adicionales',
};

export const TEXT_LOG_OUT = {
  en_US: 'Log out',
  pt_BR: 'Sair',
  es_ES: 'Cerrar sesión',
};

export const PROFILE = {
  en_US: 'Profile',
  es_ES: 'Perfil',
  pt_BR: 'Perfil',
};

export const ADDITIONAL_ACCESS = {
  en_US: 'Additional Access',
  es_ES: 'Acceso adicional',
  pt_BR: 'Acesso adicional',
};

export const SIGN_OUT = {
  en_US: 'Sign Out',
  es_ES: 'Cerrar sesión',
  pt_BR: 'Sair',
};
