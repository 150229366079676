import moment from 'moment';
import { tokenexService } from '../../../../../../services';
const SCHEME = process.env.REACT_APP_TOKEN_EX_SCHEME;
const TOKENEX_ID = process.env.REACT_APP_TOKEN_EX_ID;
const ORIGIN = process.env.REACT_APP_TOKEN_EX_ORIGIN;
const CONFIG_TOKENEX = (
  authenticationKey,
  idiom,
  isAfluent,
  token,
  TIMESTAMP
) => {
  const subtitle = isAfluent ? '#54606c' : '#5c5c5c';
  const secondary = isAfluent ? '#C2A161' : '#FCC015';
  const configTokenex = {
    styles: {
      base: `
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid ${subtitle};
      font-size: 16px;
      font-weight: 300;
      line-height: 1;
      padding: 6px 0;
      border-radius: 0;
      letter-spacing: normal;
      height: 35px;
      color: ${subtitle};
      margin: 0;
      `,
      focus: `border: none; outline: none; border-bottom: 1px solid ${secondary}`,
      error: 'border-color: #e74c3c;',
      cvv: {
        base: `
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid ${subtitle};
      font-size: 16px;
      font-weight: 300;
      line-height: 1;
      padding: 6px 0;
      border-radius: 0;
      letter-spacing: normal;
      height: 35px;
      color: ${subtitle};
      margin: 0;
      `,
        focus: `border: none; outline: none; border-bottom: 1px solid ${secondary}`,
        error: 'border-color: #e74c3c;',
      },
    },
    tokenScheme: SCHEME,
    origin: ORIGIN,
    timestamp: TIMESTAMP,
    tokenExID: TOKENEX_ID,
    authenticationKey: authenticationKey,
    cvv: true,
    cvvOnly: true,
    cardType: 'visa',
    placeholder: 'CVC *',
    token: token,
  };
  return configTokenex;
};

const getAuthentication = async (token, TIMESTAMP) => {
  const { data } = await tokenexService.getAuthenticationKeyCVV(
    TIMESTAMP,
    token
  );
  return data;
};

export const createIframeTokenex = async (idiom, isAfluent, token) => {
  const TIMESTAMP = moment().utc().format('YYYYMMDDHHmmss');
  const authenticationKey = await getAuthentication(token, TIMESTAMP);
  const iframeConfig = CONFIG_TOKENEX(
    authenticationKey,
    idiom,
    isAfluent,
    token,
    TIMESTAMP
  );

  const iframe = new window.TokenEx.Iframe('tokenExIframeDivCVV', iframeConfig);
  return iframe;
};
