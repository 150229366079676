import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descreva o incidente',
  es_ES: 'Describa el incidente',
};

export const LABEL_INCIDENT_COUNTRY = {
  en_US: 'Country of incident *',
  pt_BR: 'País do incidente *',
  es_ES: 'País del incidente *',
};

export const LABEL_INCIDENT_STATE = {
  en_US: 'State of incident *',
  pt_BR: 'Estado do incidente *',
  es_ES: 'Estado del incidente *',
};

export const LABEL_INCIDENT_CITY = {
  en_US: 'City of incident *',
  pt_BR: 'Cidade do incidente *',
  es_ES: 'Ciudad incidente *',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Place of incident *',
  pt_BR: 'Local do incidente *',
  es_ES: 'Lugar del incidente *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const ITEM_PURCHASED = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const LABEL_PURCAHSE_DATE = {
  en_US: 'Purchase date *',
  pt_BR: 'Data da compra *',
  es_ES: 'Fecha de compra *',
};

export const TEXT_MAXIMUM_REIMBURSEMENT = {
  en_US:
    'The maximum reimbursement under the extended warranty benefit is the coverage limit set by your issuing Bank.',
  pt_BR:
    'O reembolso máximo sob o benefício de garantia estendida original é o cobertura estabelecida pelo seu Emissor.',
  es_ES:
    'El reembolso máximo en virtud de la cobertura de garantía extendida es el valor establecido por tu Banco.',
};

export const NAME_STORE_WEBSITE = {
  en_US: 'Name of store/website advertising the product *',
  pt_BR: 'Nome da loja/website que anuncia o produto *',
  es_ES: 'Nombre de la tienda que anuncia el producto *',
};

export const ADVERTISEMENT_LOCATION = {
  en_US:
    'Advertisement location (i.e. newspaper, online) and publication name *',
  pt_BR:
    'Localização do anúncio (por exemplo, loja ou website) e nome da publicação *',
  es_ES:
    'Ubicación del anuncio (es decir, periódico, en línea) y nombre de la publicación *',
};

export const ORIGINAL_PURCHASE_PRICE = {
  en_US: 'Original purchase price *',
  pt_BR: 'Preço de compra original *',
  es_ES: 'Precio de la compra original *',
};

export const NEW_ADVERTISE_PRICE = {
  en_US: 'New advertise price *',
  pt_BR: 'Novo preço anunciado *',
  es_ES: 'Nuevo precio anunciado *',
};
