import { useContext } from 'react';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import { Travel } from '../../../../@types/APIs/Travels';
import styles from './TripAssistanceCardItem.module.scss';
import Button from '../../../../componentsV2/Button/Button';
import TravelIcon from '../../../../assets/icons/TravelIcon';
import { getCountryFromPrefixTranslated } from '../../../../utils/country';
import { getTravelDates } from '../../utils';
import * as translations from './intl';
interface TripAssistanceCardItem {
  travel: Travel;
}

const TripAssistanceCardItem = ({ travel }: TripAssistanceCardItem) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const buttonViewMore = () => {
    return (
      <div className={`${styles.button} btnSelect`}>
        <Button
          link
          href={`/travel-kit/details/${travel.travel_id}`}
          type="Secondary"
          id="btnViewMoreTripAssistance"
          className={styles.button}
          testId="data-testid-btnViewMoreTripAssistance"
          label={intl.TEXT_VIEW_MORE}
        />
      </div>
    );
  };
  return (
    <li
      className={`${styles.recentClaimsItem} ${styles[themes]}
  `}
    >
      <div className={styles.left}>
        <div className={`${styles.icon} ${styles[themes]}`}>
          <TravelIcon width={40} height={40} />
        </div>
        <div className={`${styles.text} ${styles[themes]}`}>
          <span>
            <strong>
              {intl.TITLE_ORIGIN_TO_DESTINATION(
                getCountryFromPrefixTranslated(travel.country_origin, idiom),
                getCountryFromPrefixTranslated(
                  travel.country_destination,
                  idiom
                )
              )}
            </strong>
          </span>
        </div>
      </div>
      <div className={styles.center}>
        <span>
          <strong>{intl.LABEL_DATE}</strong>
        </span>
        <span>{getTravelDates(travel, idiom)}</span>
      </div>
      <div className={styles.right}>{buttonViewMore()}</div>
    </li>
  );
};

export default TripAssistanceCardItem;
