import React, { useState } from 'react';

import CertificatesAndClaims from './CertificatesAndClaims/CertificatesAndClaims';
import UpgradeMe from '../../components/UpgradeMe/UpgradeMe';
import styles from './Dashboard.module.scss';
import PrivateAllBinCards from '../../components/PrivateAllBinCards/PrivateAllBinCards';
import PrivateBenefitsBanner from '../../components/PrivateBenefitsBanner/PrivateBenefitsBanner';
import RelatedOffers from '../../components/RelatedOffers/RelatedOffers';
import RelatedSpecialBenefits from '../../components/RelatedSpecialBenefits/RelatedSpecialBenefits';
import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
} from '../../utils/environments_variables';
import TopBannerMarketing from '../../components/TopBannerMarketing/TopBannerMarketing';
import { checkAccessDownloadApp } from '../../utils/downloadAppAccess';
import { useContext } from 'react';
import { IntlContext } from '../../intl';

const Dashboard = () => {
  const { country } = useContext(IntlContext);
  const [showTopBannerMarkertingBanner, setShowTopBannerMarkertingBanner] =
    useState(true);

  return (
    <>
      {checkAccessDownloadApp(country) && showTopBannerMarkertingBanner ? (
        <TopBannerMarketing
          setShowTopBannerMarkertingBanner={setShowTopBannerMarkertingBanner}
        />
      ) : null}

      <PrivateAllBinCards />
      <div
        id="mainContent"
        data-testid="content"
        className={`contaner-fluid ${styles.dashboard}`}
      >
        <PrivateBenefitsBanner isSimpleBanner={true} />
        <CertificatesAndClaims />
        <UpgradeMe />
        {/* commented because offers is not ready for prod   */}
        {ENABLE_OFFERS && <RelatedOffers />}
        {ENABLE_SPECIAL_BENEFITS && <RelatedSpecialBenefits />}
      </div>
    </>
  );
};

export default Dashboard;
