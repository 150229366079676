import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ReadyHealthIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      width={24}
      height={31}
      viewBox="0 0 24 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.7801 7.5H10.2251V17.5H12.7801V7.5Z"
        fill={config_icon.secondary}
      />
      <path
        d="M12.7801 20H10.2251V22.5H12.7801V20Z"
        fill={config_icon.secondary}
      />
      <path
        d="M20.1433 2.5L19.8756 2.63333C17.2865 3.9 15.2814 3.26191 13.1644 0.5L12.7799 0H10.2249L9.84046 0.5C7.72345 3.26191 5.71837 3.9 3.12929 2.63333L2.85675 2.5H0L0.0194668 15C0.0048667 22.2619 3.41155 24.7952 6.41917 27.0286C6.78904 27.3 10.259 30.0048 10.259 30.0048H12.7459C12.7459 30.0048 16.2158 27.3048 16.5857 27.0286C19.5933 24.7952 23 22.2619 22.9854 15.0048L23.0049 2.50476H20.1481L20.1433 2.5ZM20.4304 15.0048C20.445 21.0238 17.8218 22.9714 15.043 25.0381C14.6585 25.3238 11.8504 27.5048 11.8504 27.5048H11.1545C11.1545 27.5048 8.34638 25.3238 7.96191 25.0381C5.18303 22.9762 2.55988 21.0238 2.57448 15.0048L2.55988 5.13333C5.8741 6.51429 8.87199 5.62857 11.4854 2.50476H11.5195C14.1329 5.62857 17.1259 6.51429 20.445 5.13333L20.4304 15.0095V15.0048Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};

export default ReadyHealthIcon;
