//PATH
export const TITLE_STEP_HOME = {
  en_US: 'At home*',
  es_ES: 'En casa*',
  pt_BR: 'Em casa*',
};

export const DESCRIPTION_STEP_HOME = {
  en_US:
    'We make sure that you always feel satisfied and safe with your purchases',
  es_ES:
    'Nos aseguramos de que siempre te sientas satisfecho y seguro con tus compras',
  pt_BR:
    'Garantimos que você sempre se sinta satisfeito e seguro com suas compras',
};

export const TITLE_STEP_BEFORE = {
  en_US: 'Before the trip*',
  es_ES: 'Antes de viajar*',
  pt_BR: 'Antes de viajar*',
};

export const DESCRIPTION_STEP_BEFORE = {
  en_US:
    'Travel with peace of mind, we protect you when paying with your Visa card',
  es_ES:
    'Viaja con tranquilidad, nosotros te protegemos al pagar con tu tarjeta Visa',
  pt_BR: 'Viaje tranquilo, nós protegemos você ao pagar com seu cartão Visa',
};

export const TITLE_STEP_READY = {
  en_US: 'Ready to fly with Travel Kit*',
  es_ES: 'Listo para viajar con Travel Kit*',
  pt_BR: 'Pronto para viajar com Travel Kit*',
};

export const DESCRIPTION_STEP_READY = {
  en_US: 'All documents and information ready for your next trip',
  es_ES: 'Todos los documentos e información listos para tu próximo viaje',
  pt_BR: 'Todos os documentos e informações prontos para sua próxima viagem',
};

export const TITLE_STEP_DURING = {
  en_US: 'During my trip*',
  es_ES: 'Durante mi viaje*',
  pt_BR: 'Durante minha viagem*',
};

export const DESCRIPTION_STEP_DURING = {
  en_US: `Don't let any mishap stop you, pay with your Visa card and we'll take care of the rest`,
  es_ES:
    'Que ningún percance te detenga, paga con tu tarjeta Visa y nosotros nos encargamos del resto',
  pt_BR:
    'Não deixe que nenhum contratempo o detenha, pague com o seu cartão Visa e nós tratamos do resto',
};

export const TITLE_STEP_POST = {
  en_US: 'Post trip*',
  es_ES: 'Después del viaje*',
  pt_BR: 'Depois da viagem*',
};

export const DESCRIPTION_STEP_POST = {
  en_US:
    'Request the necessary reimbursements of your trip from the comfort of your home',
  es_ES:
    'Solicita los reembolsos necesarios de tu viaje desde la comodidad de tu casa',
  pt_BR:
    'Solicite os reembolsos necessários da sua viagem no conforto da sua casa',
};

//BENEFITS
export const AT_HOME_PURCHASE_TITLE = {
  en_US: 'Purchase Protection',
  es_ES: 'Protección de compra',
  pt_BR: 'Proteção de compra',
};

export const AT_HOME_PURCHASE_DESCRIPTION = {
  en_US: 'My glasses that I bought a month ago has broken',
  es_ES: 'Mis lentes que compré hace un mes, se rompieron',
  pt_BR: 'Meus óculos que comprei há um mês, quebraram',
};

export const AT_HOME_CONCIERGE_TITLE = {
  en_US: 'Visa Digital Concierge',
  es_ES: 'Visa Digital Concierge',
  pt_BR: 'Visa Digital Concierge',
};

export const AT_HOME_CONCIERGE_DESCRIPTION = {
  en_US: `Need to find the perfect restaurant for partner’s birthday`,
  es_ES:
    'Necesito encontrar el restaurante perfecto para el cumpleaños de mi pareja',
  pt_BR:
    'Preciso encontrar o restaurante perfeito para o aniversário do meu parceiro',
};

export const AT_HOME_WARRANTY_TITLE = {
  en_US: 'Extended Warranty',
  es_ES: 'Garantía extendida',
  pt_BR: 'Garantia estendida',
};

export const AT_HOME_WARRANTY_DESCRIPTION = {
  en_US: `The refrigerator I bought more than a year ago becomes defective after the manufacturer's warranty, but I paid with my Visa and they covered me`,
  es_ES:
    'El refrigerador que compré hace más de un año se vuelve defectuoso después de la garantía del fabricante, pero pagué con mi Visa y me cubrieron',
  pt_BR:
    'A geladeira que comprei há mais de ano apresentou defeito após a garantia do fabricante, mas havia pago com meu Visa e me cobriram',
};

export const AT_HOME_PRICE_TITLE = {
  en_US: 'Price Protection',
  es_ES: 'Protección de precios',
  pt_BR: 'Proteção de preços',
};

export const AT_HOME_PRICE_DESCRIPTION = {
  en_US: `I bought a boot two weeks ago, and today it was on sale at another store!`,
  es_ES:
    'Compré una bota hace dos semanas, ¡y hoy estaba de oferta en otra tienda!',
  pt_BR:
    'Comprei uma bota há duas semanas, e hoje ela estava em promoção em outra loja!',
};

export const BEFORE_AUTO_TITLE = {
  en_US: 'Auto Rental Insurance',
  es_ES: 'Seguro alquiler de auto',
  pt_BR: 'Seguro de aluguel de carro',
};

export const BEFORE_AUTO_DESCRIPTION = {
  en_US:
    'I booked my rental car with my Visa card. I do not need to purchase another insurance',
  es_ES:
    'Reservé mi coche de alquiler con mi tarjeta Visa. No necesito comprar otro seguro',
  pt_BR:
    'Reservei meu carro alugado com meu cartão Visa. Eu não preciso comprar outro seguro',
};

export const BEFORE_CANCELLATION_TITLE = {
  en_US: 'Trip Cancellation',
  es_ES: 'Cancelación de viaje',
  pt_BR: 'Cancelamento de viagem',
};

export const BEFORE_CANCELLATION_DESCRIPTION = {
  en_US: 'I got covid. I already paid for hotel and accommodations for my trip',
  es_ES: 'Tengo covid. Ya pagué hotel y hospedaje para mi viaje',
  pt_BR: 'Eu tenho covid. Já paguei hotel e hospedagem para minha viagem',
};

export const BEFORE_TRAVEL_KIT_TITLE = {
  en_US: 'Travel Kit',
  es_ES: 'Travel Kit',
  pt_BR: 'Travel Kit',
};

export const BEFORE_TRAVEL_KIT_DESCRIPTION = {
  en_US: 'Required vouchers and exams ready for my trip',
  es_ES: 'Certificados y exámenes obligatorios listos para mi viaje',
  pt_BR: 'Comprovantes e exames obrigatórios prontos para minha viagem',
};

export const BEFORE_CERTIFICATES_TITLE = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Certificados',
};

export const BEFORE_CERTIFICATES_DESCRIPTION = {
  en_US: 'I want to make sure that I have my Travel Certificate',
  es_ES: 'Quiero asegurarme de que tengo mi certificado de viaje',
  pt_BR: 'Quero ter certeza de que tenho meu certificado de viagem',
};

export const READY_HEALTH_TITLE = {
  en_US: 'Health Requirements',
  es_ES: 'Requisitos de salud',
  pt_BR: 'Requisitos de saúde',
};

export const READY_HEALTH_DESCRIPTION = {
  en_US: 'I need to know what health requirements are needed during my trip',
  es_ES:
    'Necesito saber que requisitos de salud son necesarios durante mi viaje',
  pt_BR:
    'Preciso saber quais requisitos de saúde são necessários durante minha viagem',
};

export const READY_COVID_TITLE = {
  en_US: 'Locate Covid Center',
  es_ES: 'Localizar Centro Covid',
  pt_BR: 'Localize o Covid Center',
};

export const READY_COVID_DESCRIPTION = {
  en_US: 'The country where I travel requests a Covid test, where can I do it?',
  es_ES:
    'El país a donde viajo  pide una prueba Covid, ¿Dónde puedo realizarla? ',
  pt_BR: 'O País para onde viajo solicita um teste Covid, onde posso fazê-lo?',
};

export const READY_DOCUMENTS_TITLE = {
  en_US: 'Upload documents',
  es_ES: 'Subir documentos',
  pt_BR: 'Upload de documentos',
};

export const READY_DOCUMENTS_DESCRIPTION = {
  en_US:
    'When uploading my vaccination certificate, the airline has my documents in a simple and secure way',
  es_ES:
    'Al subir mi certificado de vacunación la aerolinea tiene mis documentos de forma sencilla y segura',
  pt_BR:
    'Ao fazer o upload do meu certificado de vacinação, a companhia aérea tem os meus documentos de forma simples e segura',
};

export const READY_FLY_TITLE = {
  en_US: 'Ready to fly',
  es_ES: 'Ready to fly',
  pt_BR: 'Ready to Fly',
};

export const READY_FLY_DESCRIPTION = {
  en_US:
    'With the QR code it is easy to show my "Ready to fly" status at the airport',
  es_ES:
    'Con el codigo QR es sencillo mostrar mi estatus "Ready to fly”  en el aeropuerto',
  pt_BR:
    'Com o código QR é fácil mostrar meu status "Pronto para voar" no aeroporto',
};

export const DURING_BAGGAGE_TITLE = {
  en_US: 'Baggage Delay',
  es_ES: 'Retraso de equipaje',
  pt_BR: 'Atraso de bagagem',
};

export const DURING_BAGGAGE_DESCRIPTION = {
  en_US: 'I arrived at my destination and my luggage did not arrive',
  es_ES: 'Llegué a mi destino y mi equipaje no llegó',
  pt_BR: 'Cheguei ao meu destino e minha bagagem não chegou',
};

export const DURING_AUTO_TITLE = {
  en_US: 'Auto Rental Insurance',
  es_ES: 'Seguro alquiler de auto',
  pt_BR: 'Seguro de Aluguel de carros',
};

export const DURING_AUTO_DESCRIPTION = {
  en_US: 'I was involved in a car accident with the rental car',
  es_ES: 'Estuve en un accidente automovilístico con el coche de alquiler.',
  pt_BR: 'Eu me envolvi em um acidente de carro com o carro alugado',
};

export const DURING_MEDICAL_TITLE = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Seguro de Emergência Médica Internacional',
};

export const DURING_MEDICAL_DESCRIPTION = {
  en_US: 'I had to go to the ER hospital in Spain',
  es_ES: 'Tuve que ir al hospital de urgencias en España',
  pt_BR: 'Eu tive que ir para o hospital de emergência na Espanha',
};

export const DURING_CONNECTION_TITLE = {
  en_US: 'Missed Connection',
  es_ES: 'Conexión perdida',
  pt_BR: 'Perda de Conexão',
};

export const DURING_CONNECTION_DESCRIPTION = {
  en_US: `I missed my connection flight and I don't want to sleep at the airport`,
  es_ES: 'Perdí mi vuelo de conexión y no quiero dormir en el aeropuerto',
  pt_BR: 'Perdi meu voo de conexão e não quero dormir no aeroporto',
};

export const POST_MEDICAL_TITLE = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Seguro de Emergência Médica Internacional',
};

export const POST_MEDICAL_DESCRIPTION = {
  en_US: 'Get the refund of my medical expenses',
  es_ES: 'Obtener el reembolso de mis gastos médicos',
  pt_BR: 'Obter o reembolso das minhas despesas médicas',
};

export const POST_CONCIERGE_TITLE = {
  en_US: 'Visa Digital Concierge',
  es_ES: 'Visa Digital Concierge',
  pt_BR: 'Visa Digital Concierge',
};

export const POST_CONCIERGE_DESCRIPTION = {
  en_US: 'I need to order some food. I am tired after the trip',
  es_ES: 'Necesito pedir  comida. Estoy cansado despues del viaje',
  pt_BR: 'Preciso pedir comida. Estou cansado depois da viagem',
};

export const POST_CONCIERGE_2_TITLE = {
  en_US: 'Visa Digital Concierge',
  es_ES: 'Visa Digital Concierge',
  pt_BR: 'Visa Digital Concierge',
};

export const POST_CONCIERGE_2_DESCRIPTION = {
  en_US: 'Need a transportation to my home',
  es_ES: 'Necesito un transporte a mi casa',
  pt_BR: 'Preciso de um transporte para minha casa',
};

export const POST_MEDICAL_2_TITLE = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Seguro de Emergência Médica Internacional',
};

export const POST_MEDICAL_2_DESCRIPTION = {
  en_US: `I need to get reimbursed for the doctor's visit I had`,
  es_ES: 'Necesito que me reembolsen la visita al médico que tuve',
  pt_BR: 'Preciso ser reembolsado pela consulta médica que tive',
};
