import { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './BenefitsCategories.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { distinct } from '../../../../utils/array';
import {
  ALL,
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  CONCIERGE,
} from '../../../../utils/benefitCategory';
import { BenefitDetail } from '../../../../@types/APIs/benefit';

type BenefitCategory =
  | typeof ALL
  | typeof TRAVEL_BENEFITS
  | typeof PURCHASE_PROTECTION
  | typeof LIFESTYLE
  | typeof CONCIERGE;

interface BenefitsCategoriesProps {
  filterBenefits: (category: BenefitCategory) => void;
  selected: BenefitCategory;
  isBenefitPage: boolean;
  disabledCategories?: {
    [TRAVEL_BENEFITS]: boolean;
    [PURCHASE_PROTECTION]: boolean;
    [LIFESTYLE]: boolean;
    [CONCIERGE]: boolean;
  };
  benefitList?: BenefitDetail[];
  isVDBP?: boolean;
}

const BenefitsCategories = ({
  filterBenefits,
  selected,
  isBenefitPage,
  disabledCategories = {
    [TRAVEL_BENEFITS]: false,
    [PURCHASE_PROTECTION]: false,
    [LIFESTYLE]: false,
    [CONCIERGE]: false,
  },
  benefitList,
  isVDBP,
}: BenefitsCategoriesProps) => {
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [distinctCategories, setDistinctCategories] = useState([]);

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const mountTheCategoriesArray = () => {
    let allCategories = [];
    try {
      benefitList.forEach(benefit => {
        allCategories = allCategories.concat(benefit.categories);
      });
      const categoriesFormated = allCategories.filter(distinct);
      setDistinctCategories(categoriesFormated);
    } catch (err) {
      setDistinctCategories([]);
    }
  };

  const hasBenefitWithCategory = category => {
    return benefitList
      ? benefitList.find(
          benefit =>
            benefit.categories === category &&
            !benefit.external_reference.includes('axa')
        )
      : true;
  };

  useEffect(() => {
    if (isVDBP) {
      mountTheCategoriesArray();
    }
  }, [idiom, benefitList]);

  useEffect(() => {
    if (disabledCategories[selected]) {
      filterBenefits(ALL);
    }
  }, [selected]);

  if (isVDBP) {
    return (
      <div
        className={`${styles.benefitsCategories} ${
          isBenefitPage ? styles.isBenefit : ''
        }`}
      >
        <ul>
          <li
            onClick={() => filterBenefits(ALL)}
            className={
              selected === ALL
                ? `${styles[theme]} ${styles.active}`
                : `${styles[theme]}`
            }
          >
            {intl.TITLE_BENEFITS_CATEGORY_ALL}
          </li>
          {distinctCategories.map(category => (
            <li
              key={category}
              onClick={() => filterBenefits(category)}
              className={
                selected === category
                  ? `${styles[theme]} ${styles.active}`
                  : `${styles[theme]}`
              }
            >
              {category}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div
      className={`${styles.benefitsCategories} ${
        isBenefitPage ? styles.isBenefit : ''
      }`}
    >
      <ul>
        <li
          onClick={() => filterBenefits(ALL)}
          className={
            selected === ALL
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
        >
          {intl.TITLE_BENEFITS_CATEGORY_ALL}
        </li>
        {!disabledCategories[TRAVEL_BENEFITS] &&
          hasBenefitWithCategory(TRAVEL_BENEFITS) && (
            <li
              onClick={() => filterBenefits(TRAVEL_BENEFITS)}
              className={
                selected === TRAVEL_BENEFITS
                  ? `${styles[theme]} ${styles.active}`
                  : `${styles[theme]}`
              }
            >
              {intl.TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION}
            </li>
          )}
        {!disabledCategories[PURCHASE_PROTECTION] &&
          hasBenefitWithCategory(PURCHASE_PROTECTION) && (
            <li
              onClick={() => filterBenefits(PURCHASE_PROTECTION)}
              className={
                selected === PURCHASE_PROTECTION
                  ? `${styles[theme]} ${styles.active}`
                  : `${styles[theme]}`
              }
            >
              {intl.TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION}
            </li>
          )}

        {!disabledCategories[LIFESTYLE] && hasBenefitWithCategory(LIFESTYLE) && (
          <li
            onClick={() => filterBenefits(LIFESTYLE)}
            className={
              selected === LIFESTYLE
                ? `${styles[theme]} ${styles.active}`
                : `${styles[theme]}`
            }
          >
            {intl.TITLE_BENEFITS_CATEGORY_LIFESTYLE}
          </li>
        )}
        {!disabledCategories[CONCIERGE] && hasBenefitWithCategory(CONCIERGE) && (
          <li
            onClick={() => filterBenefits(CONCIERGE)}
            className={
              selected === CONCIERGE
                ? `${styles[theme]} ${styles.active}`
                : `${styles[theme]}`
            }
          >
            {intl.TITLE_BENEFITS_CATEGORY_CONCIERGE}
          </li>
        )}
      </ul>
    </div>
  );
};

export default BenefitsCategories;
