import { FlightDetailsSchema, LegInfo } from './validationSchema';

export const getEmptySegment = (flightDate: Date): LegInfo => ({
  airline: '',
  flight_number: '',
  isValid: false,
  flight_date: flightDate || null,
  scheduled_in: null,
  scheduled_out: null,
  airport_destination: null,
  airport_destination_name: '',
  airport_destination_timezone: null,
  airport_origin: null,
  airport_origin_name: '',
  airport_origin_timezone: null,
  city_destination: null,
  city_origin: null,
  country_destination: null,
  country_origin: null,
});

export const getInitialValues = (
  outwardDate: Date,
  returnDate: Date
): FlightDetailsSchema => {
  return {
    outbound: {
      booking_company_name: '',
      booking_reference: '',
      segments: [getEmptySegment(outwardDate)],
    },
    return: {
      booking_company_name: '',
      booking_reference: '',
      segments: [getEmptySegment(returnDate)],
    },
  };
};
