import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descreva o incidente *',
  es_ES: 'Describa el incidente *',
};

export const LABEL_ITEM = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const LABEL_MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Date of purchase indicated on the visa invoice',
  pt_BR: 'Data em que a compra foi lançada na fatura do seu cartão',
  es_ES: 'Fecha de compra que figura en el estado de cuenta',
};

export const PAYMENT_METHOD = {
  en_US: 'Product payment method',
  pt_BR: 'Método de pagamento do produto',
  es_ES: 'Forma de pago del producto',
};

export const METHOD_FINANCING = {
  en_US: 'Installments',
  pt_BR: 'Parcelado',
  es_ES: 'En cuotas',
};

export const METHOD_CASH = {
  en_US: 'Single payment',
  pt_BR: 'Parcela única',
  es_ES: 'En un pago',
};

export const INSTALLMENTS_NUMBER = {
  en_US: 'Purchase installments number *',
  pt_BR: 'Número de parcelas da compra *',
  es_ES: 'Número de cuotas de compra *',
};

export const INSTALLMENTS_PRICE = {
  en_US: 'Installment/financing amount *',
  pt_BR: 'Valor da prestação/financiamento *',
  es_ES: 'Monto a plazos/financiamiento *',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Product value on the sales receipt',
  pt_BR: 'Valor do produto na nota fiscal',
  es_ES: 'Valor del producto en la factura de compra',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const LABEL_THEFT = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss: *',
  pt_BR: 'Tipo de perda: *',
  es_ES: 'Tipo de pérdida: *',
};

export const LABEL_ADDITIONAL_ITEMS = {
  en_US: 'Would you like to include additional items on this claim? *',
  pt_BR: 'Deseja incluir mais items neste mesmo sinistro? *',
  es_ES: 'Deseas incluir más un producto en este reclamo? *',
};

export const LABEL_DAMEGE_REPAIRED = {
  en_US: 'Can the damage be repaired?',
  pt_BR: 'O dano pode ser reparado?',
  es_ES: '¿El daño puede ser reparado?',
};

export const LABEL_DAMAGE_VISIBLE = {
  en_US: 'Is the damage to the item visible?',
  pt_BR: 'O dano causado ao artigo é visível?',
  es_ES: '¿El daño en el material es visible?',
};

export const LABEL_TOTAL_AMOUNT_CLAIM = {
  en_US: 'Total amount of claim *',
  pt_BR: 'Valor total do sinistro *',
  es_ES: 'Importe total de la reivindicación *',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const TEXT_ITEM = {
  en_US: 'Item #',
  pt_BR: 'Produto #',
  es_ES: 'Artículo #',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const BTN_ADD = {
  en_US: 'Add item',
  pt_BR: 'Adicionar produto',
  es_ES: 'Agregar artículo',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT = {
  en_US: 'Purchase value on the Visa invoice',
  pt_BR: 'Valor da compra na fatura Visa',
  es_ES: 'Valor de compra en el estado de cuenta',
};

export const SIXTEEN_OR_MORE_INSTALLMENTS = {
  en_US: '16 or more',
  pt_BR: '16 ou mais',
  es_ES: '16 o más',
};

export const TEXT_DESCRIBE_OPTION_YES = {
  en_US:
    'New fields will appear for you to enter the amount covered by the other insurance',
  pt_BR:
    'Novos campos vão aparecer para você informar o valor coberto pelo outro seguro',
  es_ES:
    'Aparecerán nuevos campos para que introduzca el importe cubierto por el otro seguro',
};

export const TEXT_DESCRIBE_OPTION_NO = {
  en_US: 'The value fields covered by other insurance are not necessary',
  pt_BR: 'Os campos de valor coberto por outro seguro não serão necessários',
  es_ES: 'Los campos de valor cubiertos por otros seguros no son necesarios',
};
