export const TEXT_RECENT_CERTIFICATES = {
  en_US: 'Recent certificates',
  es_ES: 'Certificados recientes',
  pt_BR: 'Bilhetes recentes',
};

export const TEXT_RECENT_CLAIMS = {
  en_US: 'Recent claims',
  es_ES: 'Reclamos recientes',
  pt_BR: 'Sinistros recentes',
};

export const TEXT_UNDER_REVIEW = {
  en_US: 'UPLOADED',
  es_ES: 'SUBIDO',
  pt_BR: 'ENVIADO',
};

export const TEXT_COMPLETE = {
  en_US: 'Completed',
  es_ES: 'Cerrado',
  pt_BR: 'Concluído',
};

export const TEXT_VALID_UNTIL = {
  en_US: 'Valid until',
  es_ES: 'Válido hasta',
  pt_BR: 'Válido até',
};

export const TEXT_CREATION_DATE = {
  en_US: 'Creation date',
  es_ES: 'Fecha de creación',
  pt_BR: 'Data de criação',
};

export const TEXT_OPEN_A_CLAIM = {
  en_US: 'Open a claim',
  es_ES: 'Crear un reclamo',
  pt_BR: 'Abrir um sinistro',
};

export const CERTIFICATE_TRAVEL = {
  en_US: 'Travel assistance certificate',
  pt_BR: 'Bilhete seguro viagem',
  es_ES: 'Certificado de asistencia en viaje',
};

export const CERTIFICATE_EXTENDED = {
  en_US: 'Extended warranty',
  pt_BR: 'Bilhete seguro de garantia estendida original',
  es_ES: 'Garantía extendida',
};

export const CERTIFICATE_PURCHASE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Bilhete de proteção de compra',
  es_ES: 'Certificado de compra',
};

export const CERTIFICATE_AUTO_RENTAL = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Bilhete seguro para veículos de locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};

export const TEXT_EMPTY = {
  en_US: 'No records found.',
  es_ES: 'No se encontraron registros.',
  pt_BR: 'Nenhum registro encontrado.',
};
