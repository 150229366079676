export const OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
};

export const OTHER_INSURANCE_AMOUNT_PAID = {
  en_US: 'Please indicate the amount paid by the insurance',
  es_ES: 'Indica el monto pagado por la compañía de seguros',
  pt_BR: 'Indique o valor pago pela companhia de seguros',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  es_ES: 'Moneda',
  pt_BR: 'Moeda',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'no',
};
