import { formatReimbursementToApi } from '../reimbursement';
import { pt_BR } from '../../../../../../../intl/idioms';

const blankIntermediary_account = {
  holder: {},
};

const formatIntermediary_account = IntermediaryBankAccount => {
  const { bank: _bank, account_codes: wire_code } = IntermediaryBankAccount;
  const bank = {
    name: _bank.name,
    bank_account_number: _bank.bank_account_number,
  };
  const intermediary_account = {
    holder: {},
    bank,
    account_type: IntermediaryBankAccount.account_type,
    wire_code,
  };

  return intermediary_account;
};

const formatBeneficiary_account = (reimbursement, idiom, props) => {
  const { BankAccount, BankAccountHolder } = reimbursement;
  let isBrazil = props.isProfile
    ? props.initialValues.reimbursement &&
      props.initialValues.reimbursement.BankAccount &&
      props.initialValues.reimbursement.BankAccount.bank &&
      props.initialValues.reimbursement.BankAccount.bank.address &&
      props.initialValues.reimbursement.BankAccount.bank.address.country ===
        'BR'
    : idiom === pt_BR;

  const { person } = BankAccountHolder;
  let {
    bank: _bank,
    account_codes: wire_code,
    is_current_account,
    account_type,
    bank_identification_number,
  } = BankAccount;

  //Exception format for MX SWIFT field
  if (bank_identification_number) {
    const formattedFullKey = (wire_code[0].value = wire_code[0].value
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(\d{11})(\d)/, '$1-$2')
      .replace(/(\d{1})/, '$1'));
    wire_code = [
      {
        wire_code_type: 'FULL_KEY',
        value: formattedFullKey,
      },
      {
        wire_code_type: 'SWIFT',
        value: bank_identification_number,
      },
    ];
  }
  const bank = {
    name: _bank.name,
    bank_account_number: _bank.bank_account_number,
  };
  const holder = {
    person,
  };

  if (isBrazil) {
    if (_bank.bank_code) {
      bank.bank_code = _bank.bank_code;
    }
    if (_bank.branch_code) {
      bank.branch_code = _bank.branch_code;
    }
  } else {
    bank.address = _bank.address;
  }

  const beneficiary_account = {
    wire_code,
    bank,
    holder,
    is_current_account,
    account_type,
  };

  return beneficiary_account;
};

export const formatData = (props, isProfile) => {
  const { cn: customer_id, idiom } = props;
  const { banking_information } = formatReimbursementToApi({
    ...props,
    isProfile,
  });
  const { IntermediaryBankAccount } = banking_information;
  const beneficiary_account = formatBeneficiary_account(
    banking_information,
    idiom,
    { ...props, isProfile }
  );
  const intermediary_account = IntermediaryBankAccount
    ? formatIntermediary_account(IntermediaryBankAccount)
    : blankIntermediary_account;
  const reimbursementFormated = { beneficiary_account, intermediary_account };

  const formatedData = {
    customer_id,
    reimbursement: reimbursementFormated,
    idiom,
  };
  return formatedData;
};
