import { useContext, useMemo } from 'react';
import styles from './PassengersTripAssistance.module.scss';
import { FullTravelInsuranceCertificateDetail } from '../../../../../../../../@types/APIs/Certificate';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

interface PassengersTripAssistanceProps {
  certificate: FullTravelInsuranceCertificateDetail;
}

const PassengersTripAssistance = ({
  certificate,
}: PassengersTripAssistanceProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const getPassengerNames = () => {
    const beneficiary = certificate.beneficiaries[0];
    const cardholder = `${beneficiary.person?.first_name} ${beneficiary.person?.last_name}`;

    const dependents = beneficiary.dependent_members?.map(
      dependent =>
        `${dependent.name_as_it_appears_in_passport} ${dependent.surname}`
    );

    return dependents ? [cardholder, ...dependents] : [cardholder];
  };

  const passengers = useMemo(() => getPassengerNames(), [certificate]);

  return (
    <div className={`${styles.container}  ${styles[theme]}`}>
      {passengers?.map((passenger, index) => (
        <p key={passenger + index}>{passenger}</p>
      ))}
    </div>
  );
};

export default PassengersTripAssistance;
