export const TEXT_BENEFITS = {
  en_US: 'Benefits',
  es_ES: 'Beneficios',
  pt_BR: 'Benefícios',
};

export const TEXT_EXPLORE = {
  en_US: 'Explore your benefits possibilities',
  es_ES: 'Descubre todas las posibilidades de tus beneficios',
  pt_BR: 'Explore todas as possibilidades dos seus benefícios',
};

export const TITLE_TRAVEL = {
  en_US: 'Travel',
  es_ES: 'Viaje',
  pt_BR: 'Viagem',
};

export const TITLE_PURCHASE_PROTECTION = {
  en_US: 'Purchase protection',
  es_ES: 'Protección de compra',
  pt_BR: 'Proteção de compra',
};

export const TITLE_LIFESTYLE = {
  en_US: 'Lifestyle',
  pt_BR: 'Estilo de Vida',
  es_ES: 'Estilo de Vida',
};

export const TITLE_CONCIERGE = {
  en_US: 'Concierge',
  es_ES: 'Concierge',
  pt_BR: 'Concierge',
};
