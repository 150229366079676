import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const ItemRemove = props => {
  const { values, setFieldValue, index, intl } = props;

  const removeItem = event => {
    event.preventDefault();

    const item = [...values.incident.concerned_items];
    item.splice(index, 1);
    setFieldValue('incident.concerned_items', item);
  };

  const removeButton = () => {
    if (index === 0) return;

    return (
      <Button
        type="Tertiary"
        dataTestid="BtnAddExpenseItem"
        onClick={removeItem}
        label={intl.BTN_REMOVE}
        leadingIcon={<TrashIcon width={24} height={24} />}
      />
    );
  };

  return <div className="col-2">{removeButton()}</div>;
};

export default ItemRemove;
