export const SAFER_TRIP = {
  en_US: '',
  es_ES: '¿Desea viajar más seguro que nunca?',
  pt_BR: '',
};

export const QUOTE_AXA = {
  en_US: '',
  es_ES: 'Cotizar con AXA',
  pt_BR: '',
};
