import React, { useContext, useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { certificatesServices } from '../../../services';
import { NOTIFICATIONS_ENABLE } from '../../../utils/environments_variables';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import Loading from '../../../components/Loading/Loading';
import ProfileAccount from '../ProfileAccount/ProfileAccount';
import ProfileSettings from '../ProfileSettings/ProfileSettings';
import ProfileClaims from '../ProfileClaims/ProfileClaims';
import ActiveCertificates from '../ActiveCertificates/ActiveCertificates';
import ProfileNotifications from '../ProfileNotifications/ProfileNotifications';
import Monetization from '../Monetization/Monetization';
import ProfileVouchers from '../ProfileVouchers/ProfileVouchers';

const ProfileRouter = () => {
  const { utils } = useContext(StateContext);
  const { idiomForApi } = useContext(IntlContext);
  const [userCertificates, setUserCertificates] = useState();
  const [loading, setLoading] = useState(true);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const loadCertificates = async () => {
    try {
      setLoading(true);
      const { data } = await certificatesServices.getCertificatesByBin(
        cn,
        binOrPan,
        idiomForApi()
      );
      setUserCertificates(data);
      setLoading(false);
    } catch (error) {
      setUserCertificates([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cn, binOrPan]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Switch>
          <Route path="/profile/account" component={ProfileAccount} />
          <Route path="/profile/settings" component={ProfileSettings} />
          <Route path="/profile/claims" component={ProfileClaims} />

          {NOTIFICATIONS_ENABLE && (
            <Route
              path="/profile/notifications"
              component={ProfileNotifications}
            />
          )}
          <Route
            path="/profile/certificates/search"
            render={props => (
              <ActiveCertificates
                {...props}
                advancedSearch={true}
                userCertificates={userCertificates}
                setUserCertificates={setUserCertificates}
              />
            )}
          />
          <Route
            path="/profile/certificates"
            render={props => (
              <ActiveCertificates
                {...props}
                advancedSearch={false}
                userCertificates={userCertificates}
                setUserCertificates={setUserCertificates}
              />
            )}
          />
          <Route
            path="/profile/monetization"
            render={props => (
              <Monetization
                {...props}
                advancedSearch={false}
                userCertificates={userCertificates}
                setUserCertificates={setUserCertificates}
              />
            )}
          />
          <Route
            path="/profile/vouchers"
            render={props => <ProfileVouchers {...props} />}
          />
        </Switch>
      )}
    </>
  );
};

export default ProfileRouter;
