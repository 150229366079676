export const en_currency = `
<option value="ARS"> Argentine Peso </option>
<option value="BSD"> Bahamian Dollar </option>
<option value="BBD"> Barbados Dollar </option>
<option value="BZD"> Belize Dollar </option>
<option value="BOB"> Bolivian Boliviano </option>
<option value="BRL"> Brazilian Real </option>
<option value="CLP"> Chilean Peso </option>
<option value="COP"> Colombian Peso </option>
<option value="CRC"> Costa Rica Colon </option>
<option value="CUC"> Cuban Convertible Peso </option>
<option value="CUP"> Cuban Peso </option>
<option value="DOP"> Dominican Peso </option>
<option value="XCD"> East Caribbean Dollar </option>
<option value="GTQ"> Guatemala Quetzal </option>
<option value="GYD"> Guyana Dollar </option>
<option value="HTG"> Haiti Gourde </option>
<option value="HNL"> Honduras Lempira </option>
<option value="JMD"> Jamaican Dollar </option>
<option value="MXN"> Mexican Peso </option>
<option value="NIO"> Nicaragua Cordoba </option>
<option value="PAB"> Panama Balboa </option>
<option value="PYG"> Paraguayan Guarani </option>
<option value="PEN"> Peruvian Nuevo Sol </option>
<option value="SRD"> Surinam Dollar </option>
<option value="TTD"> Trinidad&amp;Tobago Dollar </option>
<option value="UYU"> Uruguayan Peso </option>
<option value="VEF"> Venezuelan Bolivar Fuerte </option>

<option value="AUD"> Australian Dollar </option>
<option value="GBP"> British Pound </option>
<option value="CAD"> Canadian Dollar </option>
<option value="EUR"> Euro </option>
<option value="USD"> U.S. Dollar </option>

<option value="AFN"> Afghanistan Afghani (New) </option>
<option value="ALL"> Albanian Lek </option>
<option value="DZD"> Algerian Dinar </option>
<option value="AOA"> Angola Kwanza </option>
<option value="AMD"> Armenian Dram </option>
<option value="AWG"> Aruba Florin </option>
<option value="AZN"> Azerbaijanian New Manat </option>
<option value="BHD"> Bahraini Dinar </option>
<option value="BDT"> Bangladesh Taka </option>
<option value="BYR"> Belarussian Ruble </option>
<option value="BMD"> Bermuda Dollar </option>
<option value="BTN"> Bhutan Ngultrum </option>
<option value="BOV"> Bolivia Mvdol </option>
<option value="BWP"> Botswana Pula </option>
<option value="BND"> Brunei Dollar </option>
<option value="BGN"> Bulgarian Lev </option>
<option value="BIF"> Burundi Franc </option>
<option value="KHR"> Cambodia Riel </option>
<option value="CVE"> Cape Verde Escudo </option>
<option value="KYD"> Cayman Islands Dollar </option>
<option value="XOF"> CFA Franc (BCEAO) </option>
<option value="XAF"> CFA Franc (BEAC) </option>
<option value="CNY"> Chinese Yuan </option>
<option value="KMF"> Comoros Franc </option>
<option value="BAM"> Convertible Marks </option>
<option value="CZK"> Czech Koruna </option>
<option value="DKK"> Danish Krone </option>
<option value="DJF"> Dijibouti Franc </option>
<option value="EGP"> Egyptian Pound </option>
<option value="ERN"> Eritrea Nakfa </option>
<option value="EEK"> Estonian Kroon </option>
<option value="ETB"> Ethiopian Birr </option>
<option value="FKP"> Falkland Islands Pound </option>
<option value="FJD"> Fiji Dollar </option>
<option value="CDF"> Franc Congolais </option>
<option value="GMD"> Gambian Dalasi </option>
<option value="GEL"> Georgia Lari </option>
<option value="GHS"> Ghanaian Cedi </option>
<option value="GIP"> Gibraltar Pound </option>
<option value="GNF"> Guinea Franc </option>
<option value="HKD"> Hong Kong Dollar </option>
<option value="HUF"> Hungarian Forint </option>
<option value="ISK"> Iceland Krona </option>
<option value="INR"> Indian Rupee </option>
<option value="IDR"> Indonesian Rupiah </option>
<option value="IQD"> Iraqi Dinar </option>
<option value="ILS"> Israeli Shekel </option>
<option value="JPY"> Japanese Yen </option>
<option value="JOD"> Jordanian Dinar </option>
<option value="KZT"> Kazakhstan Tenge </option>
<option value="KES"> Kenyan Shilling </option>
<option value="KRW"> Korean Won </option>
<option value="HRK"> Kuna </option>
<option value="KWD"> Kuwaiti Dinar </option>
<option value="KGS"> Kyrgyzstan Som </option>
<option value="LAK"> Lao Kip </option>
<option value="LVL"> Latvian Lat </option>
<option value="LBP"> Lebanese Pound </option>
<option value="LSL"> Lesotho Loti </option>
<option value="LRD"> Liberian Dollar </option>
<option value="LYD"> Libyan Dinar </option>
<option value="LTL"> Lithuanian Lita </option>
<option value="MOP"> Macau Pataca </option>
<option value="MKD"> Macedonian Denar </option>
<option value="MGA"> Malagasy Ariary </option>
<option value="MWK"> Malawi Kwacha </option>
<option value="MYR"> Malaysian Ringgit </option>
<option value="MVR"> Maldives Rufiyaa </option>
<option value="MRO"> Mauritania Ougulya </option>
<option value="MUR"> Mauritius Rupee </option>
<option value="MXV"> Mexican Unidad de Inversion (UDI) </option>
<option value="MDL"> Moldovan Leu </option>
<option value="MNT"> Mongolian Tugrik </option>
<option value="MAD"> Moroccan Dirham </option>
<option value="MZN"> Mozambique New Metical </option>
<option value="MMK"> Myanmar Kyat </option>
<option value="NAD"> Namibian Dollar </option>
<option value="NPR"> Nepalese Rupee </option>
<option value="ANG"> Neth Antilles Guilder </option>
<option value="NZD"> New Zealand Dollar </option>
<option value="NGN"> Nigerian Naira </option>
<option value="NOK"> Norwegian Krone </option>
<option value="OMR"> Omani Rial </option>
<option value="XPF"> Pacific Franc </option>
<option value="PKR"> Pakistani Rupee </option>
<option value="PGK"> Papua New Guinea Kina </option>
<option value="PHP"> Philippine Peso </option>
<option value="PLN"> Polish Zloty </option>
<option value="QAR"> Qatar Rial </option>
<option value="RON"> Romanian Leu </option>
<option value="RUB"> Russian Rouble </option>
<option value="RWF"> Rwanda Franc </option>
<option value="WST"> Samoa Tala </option>
<option value="STD"> Sao Tome Dobra </option>
<option value="SAR"> Saudi Arabian Riyal </option>
<option value="RSD"> Serbian Dinar </option>
<option value="SCR"> Seychelles Rupee </option>
<option value="SLL"> Sierra Leone Leone </option>
<option value="SGD"> Singapore Dollar </option>
<option value="SBD"> Solomon Islands Dollar </option>
<option value="SOS"> Somali Shilling </option>
<option value="ZAR"> South African Rand </option>
<option value="LKR"> Sri Lanka Rupee </option>
<option value="SHP"> St Helena Pound </option>
<option value="SDG"> Sudanese Pound </option>
<option value="SZL"> Swaziland Lilageni </option>
<option value="SEK"> Swedish Krona </option>
<option value="CHF"> Swiss Franc </option>
<option value="TWD"> Taiwan Dollar </option>
<option value="TJS"> Tajik Somoni </option>
<option value="TZS"> Tanzanian Shilling </option>
<option value="THB"> Thai Baht </option>
<option value="TOP"> Tonga Pa'anga </option>
<option value="TND"> Tunisian Dinar </option>
<option value="TRY"> Turkish Lira (New) </option>
<option value="TMT"> Turkmenistan New Manat </option>
<option value="AED"> UAE Dirham </option>
<option value="UGX"> Ugandan Shilling </option>
<option value="UAH"> Ukraine Hryvnia </option>
<option value="UZS"> Uzbekistan Sum </option>
<option value="VUV"> Vanuatu Vatu </option>
<option value="VND"> Vietnam Dong </option>
<option value="YER"> Yemen Riyal </option>
<option value="ZMW"> Zambian Kwacha (New) </option>
<option value="ZWL"> Zimbabwe Dollar </option>`;
