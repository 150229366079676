import TooltipIcon from '../../assets/icons/TooltipIcon';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../utils/classNames';
import styles from './HighlightNote.module.scss';

const HighLightNote = ({ note }) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <div className={joinClassNames(styles.highlightContainer, styles[theme])}>
      <aside className={joinClassNames(styles.iconArea, styles[theme])}>
        <TooltipIcon color="#fff" width={20} height={20} />
      </aside>
      <span className={styles.highlightNote}>{note}</span>
    </div>
  );
};

export default HighLightNote;
