/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { carRentalFormInitialValues } from './initialValues';
import { carRentalSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import CompanyArea from './CompanyArea/CompanyArea';
import IncidentArea from './IncidentArea/IncidentArea';
import DescriptionArea from './DescriptionArea/DescriptionArea';
import RentalPeriodArea from './RentalPeriodArea/RentalPeriodArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';
import DriverArea from './DriverArea/DriverArea';
import {
  MARCH,
  JULY,
  APRIL,
  verifyCountryARI,
} from '../../../../../../../utils/detailsARI';
import ReimbursementArea from './ReimbursementArea/ReimbursementArea';
import PaymentArea from './PaymentArea/PaymentArea';

const limitDateJuly = new Date(2021, 5, 30);
const limitDateOctober = new Date(2021, 8, 30);
const limitDateMarch = new Date(2022, 1, 28);
const limitDateApril = new Date(2022, 2, 31);
const GOLD = 'Gold';
const PLATINUM = 'Platinum';

const CarRental = props => {
  const { translate, idiom, country } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();
  const intl = translate(translations);
  const {
    parentValues,
    updateParent,
    incidentSubmitRef,
    prefixOptions,
    loadedData,
    setDisabledReimbursement,
    setHideReimbursement,
  } = props;

  const month = verifyCountryARI(country);
  const exceptionCountry = !month;
  const limitedCards =
    selectedCard.name.includes(GOLD) || selectedCard.name.includes(PLATINUM);
  const limitDate =
    month && month === JULY
      ? limitDateJuly
      : month && month === MARCH
      ? limitDateMarch
      : month && month === APRIL
      ? limitDateApril
      : limitDateOctober;

  const validationSchema = useMemo(
    () => carRentalSchema(intl, limitDate, exceptionCountry, limitedCards),
    [intl]
  );
  const initialValues = useMemo(
    () => carRentalFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <DriverArea {...props} />
              <CompanyArea {...props} prefixOptions={prefixOptions} />
              <PaymentArea {...props} />
              <RentalPeriodArea {...props} />
              <DescriptionArea {...props} />
              <IncidentArea
                {...props}
                limitDate={limitDate}
                exceptionCountry={exceptionCountry}
                limitedCards={limitedCards}
              />
              <QuestionsArea
                {...props}
                setDisabledReimbursement={setDisabledReimbursement}
                setHideReimbursement={setHideReimbursement}
              />
              <ReimbursementArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CarRental;
