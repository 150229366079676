import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './DriverArea.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

const DriverArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.driverArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.incident.driver.allOf.first_name}
          label={intl.LABEL_DRIVER_NAME}
          maxLength="40"
          name="incident.driver.allOf.first_name"
          dataTestid="InputCarRentalDriverFirstName"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.driver &&
            touched.incident.driver.allOf &&
            touched.incident.driver.allOf.first_name
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.driver &&
            errors.incident.driver.allOf &&
            errors.incident.driver.allOf.first_name
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.incident.driver.allOf.last_name}
          label={intl.LABEL_DRIVER_LAST_NAME}
          maxLength="80"
          name="incident.driver.allOf.last_name"
          dataTestid="InputCarRentalDriverLastName"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.driver &&
            touched.incident.driver.allOf &&
            touched.incident.driver.allOf.last_name
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.driver &&
            errors.incident.driver.allOf &&
            errors.incident.driver.allOf.last_name
          }
          textTooBig
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.incident.driver.license_number}
          label={intl.LABEL_LICENSE_NUMBER}
          name="incident.driver.license_number"
          dataTestid="InputCarRentalDriverLicenseNumber"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.driver &&
            touched.incident.driver.license_number
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.driver &&
            errors.incident.driver.license_number
          }
          maxLength="18"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.LABEL_COUNTRY_LICENSE}
          value={values.incident.driver.license_issued_country}
          name="incident.driver.license_issued_country"
          dataTestid="InputCarRentalDriverLicenseCountry"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_COUNTRY_LICENSE}</option> ${intl.COUNTRY_OPTIONS}`
            ),
          }}
          touched={
            touched.incident &&
            touched.incident.driver &&
            touched.incident.driver.license_issued_country
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.driver &&
            errors.incident.driver.license_issued_country
          }
        />
      </div>
    </div>
  );
};

export default DriverArea;
