import * as Yup from 'yup';
import moment from 'moment';

export const initValidationSchema = intl =>
  Yup.object().shape({
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .test(
            'match',
            intl.VALIDATE_DATE_VALID_BIRTH_DATE,
            function (birth_date) {
              let yesterday = moment(new Date())
                .subtract(1, 'days')
                .format('YYYY-MM-DD HH:ss');
              return moment(birth_date).isSameOrBefore(yesterday);
            }
          ),
        gender: Yup.string()
          .test('gender', intl.TEXT_FIELD_REQUIRED, function (value) {
            if (value === 'ML' || value === 'FM' || value === 'UNKNOWN')
              return true;
            return false;
          })
          .required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    incident: Yup.object().shape({
      description: Yup.string().max(255, intl.TEXT_FIELD_VALIDATION_255),
      datetimeValue: Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .test('test-data-valid', intl.VALIDATE_DATE_INVALID, function (value) {
          return moment(value).isSameOrBefore(new Date());
        }),
    }),
    item_information: Yup.object().shape({
      label: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      model_number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      manufacturer: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      price: Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currencyValue: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      is_full_charged_on_card: Yup.boolean().required(intl.TEXT_FIELD_REQUIRED),
      purchase_date: Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .test('test-data-valid', intl.VALIDATE_DATE_INVALID, function (value) {
          return moment(value).isSameOrBefore(new Date());
        }),
      paymentMethod: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      installments_number: Yup.string().when(
        ['paymentMethod'],
        {
          is: paymentMethod => {
            return paymentMethod === 'installment';
          },
          then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        },
        ['paymentMethod']
      ),
      total_invoice_amount: Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    other_insurance_covering: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),
  });
