import PlayStoreEN from '../../../../assets/images/downloadApp/stores/ENG/download-android-eng.png';
import PlayStoreES from '../../../../assets/images/downloadApp/stores/SP/download-android-esp.png';
import PlayStorePT from '../../../../assets/images/downloadApp/stores/PT/download-android-pt.png';
import AppStoreEN from '../../../../assets/images/downloadApp/stores/ENG/download-ios-eng.png';
import AppStoreES from '../../../../assets/images/downloadApp/stores/SP/download-ios-esp.png';
import AppStorePT from '../../../../assets/images/downloadApp/stores/PT/download-ios-pt.png';

export const LABEL_RESERVATION = {
  en_US: '',
  es_ES: 'Realizar reserva',
  pt_BR: '',
};

export const TITLE_CABIFY_INFO = {
  en_US: '',
  es_ES: '¿Deseas utilizar la siguiente información en Cabify?',
  pt_BR: '',
};

export const DISCLAIMER_CABIFY = {
  en_US: '',
  es_ES: '*Tu cuenta Cabify debe utilizar esta información',
  pt_BR: '',
};

export const BUTTON_CONFIRM = {
  en_US: '',
  es_ES: 'Confirmar',
  pt_BR: '',
};

export const BUTTON_NO = {
  en_US: '',
  es_ES: 'No, gracias',
  pt_BR: '',
};

export const TEXT_FIRST_NAME = {
  en_US: 'First Name',
  pt_BR: 'Primeiro Nome',
  es_ES: 'Nombre',
};

export const TEXT_LAST_NAME = {
  en_US: 'Last Name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellidos',
};

export const TEXT_EMAIL = {
  en_US: 'Email',
  pt_BR: 'Email',
  es_ES: 'Correo electrónico',
};

export const BTN_SIGN_UP = {
  en_US: 'Sign up now',
  pt_BR: 'Increver-me agora',
  es_ES: 'Inscribirme ahora',
};

export const TEXT_CREATING_USER_CABIFY = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Creando usuario en Cabify...',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar ',
  es_ES: 'Volver',
};

export const TITLE_CABIFY_SUCCESS = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Descarga la aplicación',
};

export const SUBTITLE_CABIFY_SUCCESS = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Para realizar tu reserva es necesario descargar la aplicación de “Cabify” disponible en App Store y Google Play.',
};

export const IMAGE_APP_STORE = {
  en_US: AppStoreEN,
  es_ES: AppStoreES,
  pt_BR: AppStorePT,
};

export const IMAGE_PLAY_STORE = {
  en_US: PlayStoreEN,
  es_ES: PlayStoreES,
  pt_BR: PlayStorePT,
};

export const TEXT_EDIT_PROFILE = {
  en_US: 'Edit profile',
  pt_BR: 'Editar perfil',
  es_ES: 'Editar perfil',
};

export const TEXT_EDIT_MOB = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const TRY_AGAIN = {
  en_US: 'Try again',
  es_ES: 'Inténtalo de nuevo',
  pt_BR: 'Tente novamente',
};
