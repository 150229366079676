/* eslint-disable no-constant-condition */
import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const InsuranceArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const formalComplaint =
    claimDetails && claimDetails.formal_complaint
      ? claimDetails.formal_complaint
      : {};

  const isSubmitted =
    formalComplaint && formalComplaint.is_submitted
      ? formalComplaint.is_submitted
      : '-' || (formalComplaint && formalComplaint.is_submitted === false)
      ? formalComplaint.is_submitted
      : '-';

  const submissionReason =
    formalComplaint && formalComplaint.submission_reason
      ? formalComplaint.submission_reason
      : '-';

  const getSubmissionReasonLabel = (title, intl) => {
    switch (title) {
      case true:
        return intl.TEXT_PIR;
      case false:
        return intl.TEXT_SUBMITTED_COMPLAINT;
      default:
        return '-';
    }
  };

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={getSubmissionReasonLabel(isSubmitted, intl)}
          value={submissionReason}
        />
      </div>
    </>
  );
};

export default InsuranceArea;
