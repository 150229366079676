/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import * as Yup from 'yup';

export const dependentInformationSchema = (
  intl,
  isBrazil,
  showRelationship,
  card = {}
) =>
  Yup.object().shape({
    person: Yup.object().shape({
      birth_date: Yup.date()
        .max(new Date(), intl.VALIDATE_DATE_VALID)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .required(intl.TEXT_FIELD_REQUIRED),
      first_name: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .matches(/([a-zA-Z])/, intl.VALIDATION_NAME_LAST_NAME),
      gender: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .matches(/([a-zA-Z])/, intl.VALIDATION_NAME_LAST_NAME),
    }),
    person_registrations: Yup.array().of(
      Yup.lazy(valuesRegistration => {
        return Yup.object().shape({
          value: Yup.string()
            .test(
              'check if is CTA',
              intl.TEXT_FIELD_REQUIRED,
              function (value) {
                if (
                  card.name.includes('CTA') &&
                  valuesRegistration.registration_type === 'CPF'
                ) {
                  return true;
                } else if (
                  isBrazil &&
                  !card.name.includes('CTA') &&
                  (valuesRegistration.registration_type === 'PASSPORT' ||
                    valuesRegistration.registration_type === 'ID_CARD' ||
                    valuesRegistration.registration_type === '')
                ) {
                  return true;
                }
                return value !== '' ? true : false;
              }
            )
            .when(
              ['registration_type'],
              {
                is: registration_type => {
                  return registration_type === 'CPF';
                },
                then: Yup.string().test(
                  'test-data-valid',
                  intl.VALIDATE_CPF,
                  function (value) {
                    if (card.name.includes('CTA') && value === '') {
                      return true;
                    }
                    return validateCPF(value);
                  }
                ),
              },
              ['registration_type']
            ),
          registration_type: Yup.string().test(
            'required, except BR not CTA',
            intl.TEXT_FIELD_REQUIRED,
            function (value) {
              if (
                isBrazil &&
                !card.name.includes('CTA') &&
                (valuesRegistration.registration_type === 'PASSPORT' ||
                  valuesRegistration.registration_type === 'ID_CARD' ||
                  valuesRegistration.registration_type === '')
              ) {
                return true;
              }
              return value != '' ? true : false;
            }
          ),
        });
      })
    ),
    email: Yup.string()
      .email(intl.VALIDATE_EMAIL_VALID)
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED),
    nationalities: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED),
    relationship: showRelationship
      ? Yup.string()
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED)
      : Yup.string(),
  });

export const emptySchema = () => Yup.object().shape({});

export function validateCPF(cpf) {
  if (cpf === 'undefined') return false;
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0,
      r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r.toString() !== cpf.substring(j, j + 1)) result = false;
  });
  return result;
}
