/* eslint-disable no-unused-vars */
import { safeGet } from '../../../../../../../utils/object';
import { pt_BR } from '../../../../../../../intl/idioms';
import { VALUE_MX } from '../constants';

const bankData = (data, country) => {
  return {
    name: safeGet(data, 'bank.name') || '',
    branch_code: safeGet(data, 'bank.branch_code') || '',
    bank_code: safeGet(data, 'bank.bank_code') || '',
    bank_account_number: safeGet(data, 'bank.bank_account_number') || '',
    bankInfoChange: false,
    address: {
      country: safeGet(data, 'bank.address.country') || country,
    },
  };
};

const filterByElement = value => {
  return element => {
    return element.wire_code_type === value;
  };
};

const formatObject = element => {
  const object = { value: '' };
  if (element) {
    object.wire_code_type = element.wire_code_type;
    object.value = element.value || '';
  }
  return object;
};

const formatEmptyObject = () => {
  const object = { value: '' };
  return object;
};

const getCountryFromBank = data => {
  const bankCountry =
    data.bank && data.bank.address && data.bank.address.country
      ? data.bank.address.country
      : '';
  let countryResult;
  switch (bankCountry) {
    case 'MX':
      countryResult = 'Mexico';
      break;
    case 'US':
      countryResult = 'united-states';
      break;
    default:
      countryResult = 'other-countries';
      break;
  }
  return countryResult;
};

export const BankAccountData = (data, idiom, country) => {
  const bank = bankData(data || {}, country);
  let object = {};
  if (country !== 'BR') {
    object.account_codes = [];
    object.bankCountryView = country; //getCountryFromBank(data);
    object.SWIFT = data.account_codes
      ? formatObject(data.account_codes.find(filterByElement('SWIFT')))
      : formatEmptyObject();
    object.ABA = data.account_codes
      ? formatObject(data.account_codes.find(filterByElement('ABA')))
      : formatEmptyObject();
    object.FULL_KEY = data.account_codes
      ? formatObject(data.account_codes.find(filterByElement('FULL_KEY')))
      : formatEmptyObject();
    object.IBAN = data.account_codes
      ? formatObject(data.account_codes.find(filterByElement('IBAN')))
      : formatEmptyObject();
    object.CUIT = data.account_codes
      ? formatObject(data.account_codes.find(filterByElement('CUIT')))
      : formatEmptyObject();

    //Exception format for MX SWIFT field
    if (country === VALUE_MX) {
      object.bank_identification_number =
        data.bank_identification_number || object.SWIFT.value || '';
    }
  }
  object.is_current_account = data.is_current_account
    ? data.is_current_account
    : false;
  object.account_type = data.account_type || 'CHECKING';
  object.bank_account_id = data.bank_account_id;

  return {
    bank,
    ...object,
  };
};
