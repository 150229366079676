import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { getCountryByInitials } from '../../../../../../intl/utils';
import * as translations from './intl';

const IncidentLocation = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const location =
    claimDetails && claimDetails.incident.location
      ? claimDetails.incident.location
      : {};

  const country =
    location && location.country
      ? getCountryByInitials(location.country, idiom)
      : '-';

  const postalCode =
    location && location.postal_code ? location.postal_code : '-';

  const state = location && location.state ? location.state : '-';

  const city = location && location.locality ? location.locality : '-';

  const streetAddress =
    location && location.street_address ? location.street_address : '-';

  const subdivision =
    location && location.subdivision ? location.subdivision : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.COUNTRY_LABEL} value={country} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_POSTAL_CODE} value={postalCode} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_STATE} value={state} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_CITY} value={city} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_ADDRESS} value={streetAddress} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_SUBDIVISION} value={subdivision} />
      </div>
    </>
  );
};

export default IncidentLocation;
