import React, { useContext } from 'react';
import styles from './CardItem.module.scss';
import Cards from '../../../assets/images/cards_2.svg';
import { cardsServices } from '../../../services';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { withRouter } from 'react-router-dom';
import ModalWithImage from '../../ModalWithImage/ModalWithImage';
import ModalResult from '../../ModalResult/ModalResult';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ModalCreditCard from '../../../assets/images/modal/modal_image_credit_card.png';
import { NotificationContext } from '../../NotificationContextParent/NotificationContextParent';

const changePreferredCard = async (
  actions,
  infoCard,
  intl,
  mask_external_reference,
  props,
  noReload,
  notificationActions
) => {
  try {
    actions.modal.showLoading(
      intl.CHANGING_CARD(`${mask_external_reference} - ${infoCard.name}`)
    );
    const { cn, name, external_reference } = infoCard;
    const { data } = await cardsServices.changePreferredCard(
      cn,
      name,
      external_reference
    );

    await actions.cards.changeSelectedCardIndex(external_reference);
    notificationActions.toastNotification.setResetAppereanceToast();
    actions.modal.hideLoading();

    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="success"
        title={intl.TEXT_CONGRATULATIONS(
          `${mask_external_reference} - ${infoCard.name}`
        )}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.TEXT_OK}
          clickConfirm={() => {
            if (noReload) {
              actions.modal.closeModal();
            } else {
              props.history.push('/dashboard');
              window.location.reload();
            }
          }}
        />
      </ModalWithImage>,
      true,
      true
    );
  } catch (error) {
    actions.modal.hideLoading();
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.UNEXPECTED_ERROR_OCCURRED}
        image={ModalCreditCard}
      ></ModalWithImage>,
      true
    );
  }
};

const openModalSuccess = (
  actions,
  intl,
  infoCard,
  mask_external_reference,
  props,
  noReload,
  notificationActions
) => {
  return actions.modal.showModal(
    false,
    true,
    <ModalWithImage
      type="question"
      title={intl.TEXT_CARD_IS_PREFERRED}
      subtitle={intl.TEXT_CARD_MAIN_ONE(
        `${mask_external_reference} - ${infoCard.name}`
      )}
      image={ModalCreditCard}
    >
      <ModalResult
        textConfirm={intl.TEXT_YES}
        textDeny={intl.TEXT_NO}
        clickConfirm={() =>
          changePreferredCard(
            actions,
            infoCard,
            intl,
            mask_external_reference,
            props,
            noReload,
            notificationActions
          )
        }
        clickDeny={() => actions.modal.closeModal()}
      />
    </ModalWithImage>,
    true
  );
};

const CardItem = props => {
  const { card, setOpenSubMenu, index, noReload } = props;
  const { state, actions } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { user } = state;
  const { cn } = user.data.userInfo;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const is_preferred = card.is_preferred
    ? `${styles[theme]} ${styles.borderGold}`
    : '';
  const mask_external_reference = card.external_reference.replace(
    new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
    '$1.****.$3**.****'
  );
  const isFirst = index === 0;
  const infoCard = {
    external_reference: card.external_reference,
    name: card.name,
    cn,
  };
  const image = card.image && card.image.length > 0 ? card.image : Cards;
  return (
    <li>
      <button
        className={`${styles[theme]} ${styles.cardItem} ${is_preferred}`}
        aria-label={card.name}
        aria-describedby="infoCard"
        tabIndex={0}
        onClick={() => {
          if (!isFirst) {
            setOpenSubMenu(false);
            openModalSuccess(
              actions,
              intl,
              infoCard,
              mask_external_reference,
              props,
              noReload,
              notificationActions
            );
          }
        }}
      >
        <img src={image} alt="" />

        <div id="infoCard" className={styles.infoCard}>
          <div className={styles.bin}>
            <strong>
              <span>{card.name}</span> - {mask_external_reference}
            </strong>
          </div>

          <div className={styles.bank} aria-label={card.issuer_name}>
            {card.issuer_name}
          </div>
        </div>
      </button>
    </li>
  );
};

export default withRouter(CardItem);
