import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const TravelIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const config_icon = getThemeIcon(theme);

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <g clipPath="url(#clip0_500_289)">
        <path
          d="M11.2185 11.5719L6.24982 6.78956L9.08579 5.2813L15.9452 9.05816L19.7266 7.0471C20.402 6.68787 21.2217 6.72364 21.8769 7.1409C22.5321 7.55818 22.9232 8.29361 22.903 9.07023C22.8827 9.84673 22.4541 10.5464 21.7786 10.9056L6.65317 22.1901"
          stroke={config_icon.primary}
          strokeWidth={2}
        />
        <path
          d="M6.65343 14.7005L4.52074 12.6591L1.6846 14.1675L5.50002 19"
          stroke={config_icon.secondary}
          strokeWidth={2}
        />
      </g>
      <defs>
        <clipPath id="clip0_500_289">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TravelIcon;
