export const legal_protection_toApi = props => {
  let object = {};
  object.incident = incidentToApi(props);
  object.affected_person = props.values.incident.affected_person;
  if (
    props.values.incident.other_insurance_covering[0].amount.checked ===
      'true' ||
    props.values.incident.other_insurance_covering[1].amount.checked === 'true'
  ) {
    object.other_insurance_covering = otherInsuranceToApi(props);
  }
  object.total_amount_claimed = props.values.incident.total_amount_claimed;
  object.amount_estimation_damage =
    props.values.incident.amount_estimation_damage;
  object.contact_phones = props.values.incident.contact_phones;
  object.certificate_id = props.values.incident.certificate_id;

  return object;
};

export const otherInsuranceToApi = props => {
  const originalCopy = props.values.incident.other_insurance_covering;
  const currency = props.values.incident.incident.expenses[0].amount.currency;
  const response = [];
  let otherInsuranceA = {
    is_internal: originalCopy[0].amount.checked === 'true' ? true : false,
    description: originalCopy[0].description,
    amount: {
      value: originalCopy[0].amount.value,
      currency: originalCopy[0].amount.checked ? currency : '',
    },
  };
  let otherInsuranceB = {
    is_internal: originalCopy[1].amount.checked === 'true' ? true : false,
    description: originalCopy[1].description,
    amount: {
      value: originalCopy[1].amount.value,
      currency: originalCopy[1].amount.checked ? currency : '',
    },
  };

  if (otherInsuranceA.is_internal === true) {
    response.push(otherInsuranceA);
  }
  if (otherInsuranceB.is_internal === true) {
    response.push(otherInsuranceB);
  }
  return response;
};

export const incidentToApi = props => {
  const incident = {};
  const isThereInvolvedPerson =
    props.values.incident.incident.involved_person.checked === 'true';

  incident.description = props.values.incident.incident.description;
  incident.date = props.values.incident.incident.date;
  incident.location = props.values.incident.incident.location;
  incident.expenses = props.values.incident.incident.expenses;
  if (isThereInvolvedPerson) {
    incident.involved_person = props.values.incident.incident.involved_person;
    delete incident.involved_person.checked;
  }

  return incident;
};
