import React from 'react';
import styles from './DisclaimerDownloadApp.module.scss';

const DisclaimerDownloadApp = ({ textAlign, text }) => {
  return (
    <div
      className={styles.boxDisclaimer}
      style={{ textAlign: textAlign ? textAlign : 'center' }}
    >
      <p className={styles.textDisclaimer}>{text}</p>
    </div>
  );
};

export default DisclaimerDownloadApp;
