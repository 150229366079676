import { parseISO } from 'date-fns';
import { getCountryByInitials } from '../../../../../../../../../intl/utils';
import {
  formatDate,
  formatISOWithOffset,
} from '../../../../../../../../../utils/date';
import { ScheduleWithAirportInfo } from '../../../../../../../utils';
import { FlightInfoData } from '../../../../../../../../../components/FlightInfo/FlightInfoContext';

export const formatScheduleToFlightInfo = (
  schedule: ScheduleWithAirportInfo
): FlightInfoData => ({
  flightNumber: schedule.ident_iata || schedule.ident,
  startAt: formatISOWithOffset(
    schedule.scheduled_out,
    schedule.originAirport.timezone
  ),
  airportOrigin: schedule.origin_iata || schedule.origin_lid || schedule.origin,
  cityOrigin: schedule.originAirport.city,
  countryOrigin: schedule.originAirport.country_code,
  endAt: formatISOWithOffset(
    schedule.scheduled_in,
    schedule.destinationAirport.timezone
  ),
  airportDestination:
    schedule.destination_iata ||
    schedule.destination_lid ||
    schedule.destination,
  cityDestination: schedule.destinationAirport.city,
  countryDestination: schedule.destinationAirport.country_code,
});
