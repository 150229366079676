import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import CellPhoneArea from './CellPhoneArea/CellPhoneArea';
import EstimatedValueCellPhone from './EstimatedValueCellPhone/EstimatedValueCellPhone';
import OtherInsurance from './OtherInsurance/OtherInsurance';
import ReimbursedArea from './ReimbursedArea/ReimbursedArea';

import { cellPhoneProtectionFormInitialValues } from './initialValues';
import { cellPhoneProtectionFormValidation } from './validationSchema';
import FullAmountCard from './FullAmountCard/FullAmountCard';

const CellPhoneProtection = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(
    () => cellPhoneProtectionFormValidation(intl),
    [intl]
  );
  const initialValues = useMemo(
    () =>
      cellPhoneProtectionFormInitialValues(
        parentValues.policy,
        idiom,
        loadedData
      ),
    [idiom, parentValues.policy, loadedData]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit}>
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>

              <PersonalArea parentValues={parentValues} {...props} />
              <IncidentArea {...props} />
              <CellPhoneArea {...props} />
              {/* <FullAmountCard {...props} />   AWAITING API UPDATE */}
              <EstimatedValueCellPhone {...props} />
              <OtherInsurance {...props} />
              <ReimbursedArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CellPhoneProtection;
