import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../utils/environments_variables';
import { removeNonNumeric } from '../../../../../../../utils/stringUtils';

export const formatToApi = props => {
  const holder = formatHolderToApi(props);
  return { holder };
};

const formatHolderToApi = props => {
  const {
    cardholder,
    cardholder: { person: _person },
  } = props;

  const person = {
    title: _person.title,
    first_name: _person.first_name,
    last_name: _person.last_name,
    birth_date: _person.birth_date,
    gender: _person.gender,
    nationalities: [cardholder.nationalities],
  };

  if (!ALPHANUMERIC_COUNTRY_LIST.includes(cardholder.address.country)) {
    cardholder.address.postal_code = removeNonNumeric(
      cardholder.address.postal_code
    );
  }

  let isCpf = cardholder.person_registrations[0].registration_type === 'CPF';
  let registrations = [
    {
      registration_type: cardholder.person_registrations[0].registration_type,
      value: isCpf
        ? removeNonNumeric(cardholder.person_registrations[0].value)
        : cardholder.person_registrations[0].value,
    },
  ];

  if (
    cardholder.person_registrations[1] &&
    cardholder.person_registrations[1] !== null &&
    cardholder.person_registrations[1] !== undefined
  ) {
    registrations.push(cardholder.person_registrations[1]);
  }

  const holder = {
    email: cardholder.email,
    is_politically_exposed: getPoliticallyExposed(
      cardholder.politicallyExposed
    ),
    profession: cardholder.profession,
    person,
    address: cardholder.address,
    Phone: cardholder.phone,
    registrations: registrations,
  };

  if (cardholder.salary) {
    holder.monthly_income = {
      value: cardholder.salary,
      currency: 'BRL',
    };
  }

  return holder;
};

const getPoliticallyExposed = value => {
  return value === 'true' || value === true ? true : false;
};
