import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const AutoRentalIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          fill={config_icon.primary}
          d="M21.8,2l-0.2,0.1c-1.8,0.9-3.1,0.4-4.8-1.7L16.5,0h-3l-0.3,0.4c-1.7,2.1-3,2.6-4.8,1.7L8.2,2H6v2.8l1.8,1.8 c0.1,0,0.2,0,0.2,0V4.1c3,1.2,5.1-0.5,6.5-2.1h1.1c1,1.2,3.2,3.4,6.5,2.1V11c0,6.5-5.2,10.3-6.3,11h-1.4c-0.3-0.2-0.9-0.6-1.6-1.3 c-0.6,0.4-1.2,0.8-1.9,1c1.4,1.4,2.6,2.1,2.7,2.1l0.2,0.1h2.5l0.2-0.1C16.8,23.7,24,19.5,24,11V2H21.8z"
        />
        <g>
          <path
            fill={config_icon.secondary}
            d="M17.7,12.3C17.5,12.1,13.5,8.2,6.4,8l-4-4H0v2h1.6l2,2H0v2h5.6H6c5.3,0,8.7,2.4,9.9,3.3L15,16.8l1.9,0.5 l1.1-4.5L17.7,12.3z"
          />
          <path
            fill={config_icon.secondary}
            d="M9,12c-1.9,0-3.4,1.3-3.9,3H0v2h5.1c0.4,1.7,2,3,3.9,3c2.2,0,4-1.8,4-4S11.2,12,9,12z M9,18c-1.1,0-2-0.9-2-2 s0.9-2,2-2s2,0.9,2,2S10.1,18,9,18z"
          />
        </g>
      </g>
    </svg>
  );
};

export default AutoRentalIcon;
