import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const DownloadIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 13L12 3"
        stroke="#1434CB"
        style={{
          fill: 'none',
          stroke: config_icon.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M19 10L13.4142 15.5858C12.6332 16.3668 11.3668 16.3668 10.5858 15.5858L5 10"
        style={{
          fill: 'none',
          stroke: config_icon.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        d="M2 12V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V12"
        style={{
          fill: 'none',
          stroke: config_icon.secondary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default DownloadIcon;
