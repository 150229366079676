export const TEXT_BAGGAGE_CUSTODY = {
  en_US: 'The baggage was in the custody of',
  es_ES: 'El equipaje era responsabilidad de',
  pt_BR: 'A bagagem era responsabilidade de',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  es_ES: 'Otro (explicar)  *',
  pt_BR: 'Outro (explicar) *',
};

export const OPTIONS_AIRLINE = {
  en_US: 'Airline',
  es_ES: 'Aerolínea',
  pt_BR: 'Linha aérea',
};

export const OPTIONS_CRUISE_LINE = {
  en_US: 'Cruise line',
  es_ES: 'Compañía de cruceros',
  pt_BR: 'Linha de cruzeiro',
};

export const OPTIONS_RAILROAD = {
  en_US: 'Railroad company',
  es_ES: 'Compañía de ferrocarril',
  pt_BR: 'Companhia ferroviária',
};

export const OPTIONS_BUS = {
  en_US: 'Bus company',
  es_ES: 'Compañía de buses',
  pt_BR: 'Companhia de ônibus',
};

export const OPTIONS_OTHER = {
  en_US: 'Other',
  es_ES: 'Otro',
  pt_BR: 'Outro',
};
