export const LABEL_TOTAL_AMOUNT_REIMBURSERD = {
  en_US: 'Total amount to be reimbursed',
  pt_BR: 'Total a ser reembolsado',
  es_ES: 'Importe total a reembolsar',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};
