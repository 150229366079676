import React, { useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../components/Input/Input';
import Calendar from '../../../../../../components/Calendar/Calendar';
import { differenceInYears } from 'date-fns';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';

const PersonalArea = props => {
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    saveInSession,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  useEffect(() => {
    if (values.MPH.DateOfBirth) {
      const age = differenceInYears(new Date(), values.MPH.DateOfBirth);
      setFieldValue('MPH.Age', String(age));
    }
  }, [setFieldValue, values.MPH.DateOfBirth]);

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <Input
          value={values.MPH.Name}
          label={intl.LABEL_NAME}
          name="MPH.Name"
          type="text"
          onChange={handleChange}
          touched={touched.MPH && touched.MPH.Name}
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={errors.MPH && errors.MPH.Name}
          axa
          maxLength="40"
          mask="maskedOnlyLetters"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <Input
          value={values.MPH.LastName}
          label={intl.LABEL_LAST_NAME}
          name="MPH.LastName"
          type="text"
          onChange={handleChange}
          touched={touched.MPH && touched.MPH.LastName}
          onBlur={() => {
            saveInSession(values);
            handleBlur();
          }}
          error={errors.MPH && errors.MPH.LastName}
          axa
          maxLength="80"
          mask="maskedOnlyLetters"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Calendar
          label={intl.LABEL_BIRTH_DATE}
          name="MPH.DateOfBirth"
          formikProps={props}
          onChange={value => {
            handleChange(value);
            saveInSession(values);
          }}
          maxDate={yesterday}
          setFieldTouched={setFieldTouched}
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-1">
        <Input
          value={values.MPH.Age}
          label={intl.LABEL_AGE}
          name="MPH.Age"
          type="text"
          disabled={true}
          axa
        />
      </div>
    </div>
  );
};

export default PersonalArea;
