import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_AMOUNt_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const LABEL_TOTAL_AMOUNT_REIMBURSERD = {
  en_US: 'Total amount to be reimbursed *',
  pt_BR: 'Total a ser reembolsado *',
  es_ES: 'Importe total a reembolsar *',
};

export const TOOLTIP_AMOUNT_REQUESTED = {
  en_US:
    'Amount requested to be reimbursed - Amount paid by the other insurance.',
  pt_BR:
    'Importe total de reembolso solicitado - Importe pagado por el otro seguro.',
  es_ES:
    'Valor total de reembolsado solicitado - Valor pago pelo outro seguro.',
};
