import { TEXT_ADD_YOUR_CARD, TEXT_DIGITS } from '../../ModalAddCard/intl';

export { TEXT_ADD_YOUR_CARD, TEXT_DIGITS };

export const TEXT_INSERT_PAN = {
  en_US:
    'The selected card <strong>is currently not active</strong> so your Visa Benefits Portal experience will be restricted. To fully enjoy all features, please <strong>add a new card that provides all 16 digits</strong> ',
  pt_BR:
    'O cartão selecionado <strong>não está ativo no momento</strong> portanto sua experiência no Portal de Benefícios Visa será restrita.  Para usufruir integralmente de todos os recursos, por favor, <strong>adicione um novo cartão que forneça todos os 16 dígitos</strong> ',
  es_ES:
    'La tarjeta seleccionada <strong>actualmente no está activa</strong>, por lo que tu experiencia en el Portal de beneficios de Visa estará restringida. Para disfrutar plenamente de todas las funciones, <strong>agregue una nueva tarjeta que proporcione los 16 dígitos</strong> ',
};

export const CLICK_HERE = {
  en_US: '<strong><u>here</u></strong>.',
  pt_BR: '<strong><u>aqui</u></strong>.',
  es_ES: '<strong><u>aquí</u></strong>.',
};
