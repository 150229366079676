import React, { useContext } from 'react';
import styles from './AppointmenQuestions.module.scss';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

export const AppointmenQuestionsNAL = props => {
  const { questions, intl } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const mappedQuestions = questions.map((item, index) => {
    return (
      <li className={styles[themes]} key={`questionsItem-${index}`}>
        {item}
      </li>
    );
  });

  if (!questions) return <></>;

  return (
    <div className={styles.appointmenQuestions}>
      <ul>
        <li className={`${styles.active} ${styles[themes]}`}>
          {intl.TEXT_TYPE_QUESTIONS}
        </li>
        {mappedQuestions}
      </ul>
    </div>
  );
};

export default AppointmenQuestionsNAL;
