import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './IncidentArea.module.scss';
import { safeGet } from '../../../../../../../../utils/object';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const getInstallmentsPrice = () => {
    if (values.item_information.installments_number !== '0') {
      const priceValue =
        Number(values.item_information.total_invoice_amount.value) /
        (Number(values.item_information.installments_number) || 1);
      return priceValue.toFixed(2);
    }
    return '0';
  };

  return (
    <div>
      <div className={`${styles.elementRadio} ${styles[themes]} row`}>
        <div className={`col-lg-12 col-sm-12`}>
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.LABEL_FULL_CARD}
          </label>
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_FULL_CARD}
            defaultChecked={true}
            text={intl.TEXT_YES}
            name="item_information.is_full_charged_on_card"
            value={true}
            onChange={handleChange}
          />
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_FULL_CARD}
            defaultChecked={false}
            text={intl.TEXT_NO}
            name="item_information.is_full_charged_on_card"
            value={false}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className={`row ${styles.rentalPeriodlArea}`}>
        <div className="col-12 col-md-6">
          <Calendar
            value={values.incident.datetimeValue}
            label={intl.LABEL_INCIDENT_DATE}
            name="incident.datetimeValue"
            dataTestid="InputClaimDateTimeValueExtended"
            onChange={handleChange}
            maxDate={new Date()}
            touched={touched.incident && touched.incident.datetimeValue}
            error={errors.incident && errors.incident.datetimeValue}
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div className="col-12 col-md-6">
          <Calendar
            value={values.item_information.purchase_date}
            label={intl.LABEL_PURCAHSE_DATE}
            name="item_information.purchase_date"
            onChange={handleChange}
            maxDate={new Date()}
            touched={
              touched.item_information && touched.item_information.purchase_date
            }
            error={
              errors.item_information && errors.item_information.purchase_date
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
      </div>
      <div className={`row ${styles.incidentAreal}`}>
        <div className="col-12">
          <Textarea
            value={values.incident.description}
            label={intl.LABEL_INCIDENT_DESCRIPTION}
            name="incident.description"
            onChange={handleChange}
            touched={safeGet(touched, 'incident.description')}
            onBlur={handleBlur}
            error={safeGet(errors, 'incident.description')}
            maxLength="255"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.item_information.label}
            label={intl.ITEM_PURCHASED}
            name="item_information.label"
            type="text"
            onChange={handleChange}
            touched={safeGet(touched, 'item_information.label')}
            onBlur={handleBlur}
            error={safeGet(errors, 'item_information.label')}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.item_information.manufacturer}
            label={intl.MANUFACTURER}
            name="item_information.manufacturer"
            type="text"
            onChange={handleChange}
            touched={safeGet(touched, 'item_information.manufacturer')}
            onBlur={handleBlur}
            error={safeGet(errors, 'item_information.manufacturer')}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.item_information.model_number}
            label={intl.MODEL}
            name="item_information.model_number"
            type="text"
            onChange={handleChange}
            touched={safeGet(touched, 'item_information.model_number')}
            onBlur={handleBlur}
            error={safeGet(errors, 'item_information.model_number')}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.item_information.total_invoice_amount.value}
            label={intl.RETAILER_NAME}
            tooltipText={intl.TEXT_AMOUNT_DECIMAL}
            name="item_information.total_invoice_amount.value"
            type="text"
            maskedMoney
            error={safeGet(
              errors,
              'item_information.total_invoice_amount.value'
            )}
            touched={safeGet(
              touched,
              'item_information.total_invoice_amount.value'
            )}
            onBlur={handleBlur}
            onChange={handleChange}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <Select
            label={intl.PAYMENT_METHOD}
            name="item_information.paymentMethod"
            value={values.item_information.paymentMethod}
            dangerouslySetInnerHTML={{
              __html: `<option value="">${intl.PAYMENT_METHOD}</option>
              <option value="cash">${intl.METHOD_CASH}</option>
              <option value="installment">${intl.METHOD_FINANCING}</option>`,
            }}
            onChange={e => {
              setFieldValue('item_information.installments_number', '0');
              setFieldTouched('item_information.installments_number', false);
              handleChange(e);
            }}
            onBlur={handleBlur}
            touched={safeGet(touched, 'item_information.paymentMethod')}
            error={safeGet(errors, 'item_information.paymentMethod')}
          />
        </div>
        {values.item_information.paymentMethod === 'installment' && (
          <>
            <div className="col-12 col-sm-12 col-md-4">
              <Select
                label={intl.INSTALLMENTS_NUMBER}
                name="item_information.installments_number"
                value={values.item_information.installments_number}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={safeGet(
                  touched,
                  'item_information.installments_number'
                )}
                error={safeGet(errors, 'item_information.installments_number')}
              >
                <option value="">{intl.INSTALLMENTS_NUMBER}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">{intl.SIXTEEN_OR_MORE_INSTALLMENTS}</option>
              </Select>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <Input
                label={intl.INSTALLMENTS_PRICE}
                name="item_information.installment_price"
                value={getInstallmentsPrice()}
                maskedMoney
                type="text"
                disabled
                error={safeGet(errors, 'item_information.installment_price')}
                touched={safeGet(touched, 'item_information.installment_price')}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IncidentArea;
