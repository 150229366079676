export const TEXT_UNTIL = {
  en_US: 'Until',
  es_ES: 'Valido hasta',
  pt_BR: 'Válido até',
};

export const CARD_OFFER = {
  en_US: 'Card Offer',
  es_ES: 'Oferta de Tarjeta',
  pt_BR: 'Oferta de Cartão',
};

export const COMPANY_LOGO = {
  en_US: 'Company Logo',
  es_ES: 'Logo de la Empresa',
  pt_BR: 'Logotipo da Empresa',
};
