import LoginButton from '../../componentsV2/LoginButton/LoginButton';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import styles from './Bluebox.module.scss';

interface BlueboxLoginButtonProps {
  benefitId: string;
}

const BlueboxLoginButton = ({ benefitId }: BlueboxLoginButtonProps) => {
  const intl = useMemoTranslations(translations);

  return (
    <LoginButton
      redirect={`/your-benefits/${benefitId}`}
      type="Secondary"
      surface="Inverse"
      label={intl.BUTTON_LOG_IN}
      className={styles.loginButton}
    />
  );
};

export default BlueboxLoginButton;
