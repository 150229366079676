export const idiomFromApi = (idiom: string) =>
  idiom ? idiom.replace('-', '_') : idiom;

export enum Idiom {
  en_US = 'en_US',
  pt_BR = 'pt_BR',
  es_ES = 'es_ES',
}

export const listIdioms = [
  {
    initials: Idiom.en_US,
    en_US: 'English',
    pt_BR: 'Inglês',
    es_ES: 'Inglés',
  },
  {
    initials: Idiom.pt_BR,
    en_US: 'Portuguese',
    pt_BR: 'Português',
    es_ES: 'Portugués',
  },
  {
    initials: Idiom.es_ES,
    en_US: 'Spanish',
    pt_BR: 'Espanhol',
    es_ES: 'Español',
  },
];
