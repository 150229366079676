/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import { tripInformationSchema } from './validationSchema';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import DatesArea from './DatesArea/DatesArea';
import PlacesArea from './PlacesArea/PlacesArea';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import {
  verifyCountryARI,
  JULY,
  MARCH,
  APRIL,
} from '../../../../../../utils/detailsARI';

const limitDateJuly = new Date(2021, 5, 30);
const limitDateOctober = new Date(2021, 8, 30);
const limitDateMarch = new Date(2022, 1, 28);
const limitDateApril = new Date(2022, 2, 31);
const GOLD = 'Gold';
const PLATINUM = 'Platinum';

const TripInformation = props => {
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { initialValues, updateParent, tripSubmitRef } = props;
  const { utils } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();

  const month = verifyCountryARI(country);
  const exceptionCountry = !month;
  const limitedCards =
    selectedCard.name.includes(GOLD) || selectedCard.name.includes(PLATINUM);
  const limitDate =
    month && month === JULY
      ? limitDateJuly
      : month && month === MARCH
      ? limitDateMarch
      : month && month === APRIL
      ? limitDateApril
      : limitDateOctober;

  const validationSchema = useMemo(
    () =>
      tripInformationSchema(intl, limitDate, exceptionCountry, limitedCards),
    [intl]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);

        return (
          <form onSubmit={handleSubmit} data-testid="FormTripBenefits">
            <button
              ref={tripSubmitRef}
              type="submit"
              style={{ display: 'none' }}
              role="btnSubmit"
            ></button>
            <DatesArea
              {...props}
              limitDate={limitDate}
              exceptionCountry={exceptionCountry}
              limitedCards={limitedCards}
            />
            <PlacesArea {...props} />
          </form>
        );
      }}
    </Formik>
  );
};

export default TripInformation;
