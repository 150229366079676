import moment from 'moment';

//TODO: Initialize other_insurance_covering on the correct order by it's
// discription (TRANSPORT or OTHER)
export const tripFormInitialValues = (data, idiom, loadedData) => {
  const completeClaim = loadedData || {};

  let person = data && data.person ? data.person : '';
  const affectedPerson = completeClaim.affected_person || {};
  const tripInformation = completeClaim.trip_information || {};
  const incidentStartDate =
    completeClaim.incident && completeClaim.incident.start_date;
  const otherInsuranceCovering =
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[0];
  const otherInsuranceCovering2 =
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[1];
  let totalAmountClaimed = completeClaim && completeClaim.total_amount_claimed;

  const getPersonName = (cardholder, affectedPerson) => {
    const person =
      affectedPerson && affectedPerson.person
        ? affectedPerson.person
        : cardholder;
    return {
      first_name: person.first_name ? person.first_name : '',
      last_name: person.last_name ? person.last_name : '',
    };
  };

  const getAffectedPerson = (cardholder, affectedPerson) => ({
    relationship: 'Cardholder',
    relationshipCardholder: true,
    person: getPersonName(cardholder, affectedPerson),
  });

  const getPlanedTrip = tripInformation => ({
    departure_date: tripInformation.departure_date
      ? new Date(moment(tripInformation.departure_date, 'YYYY-MM-DD'))
      : '',
    arrival_date: tripInformation.arrival_date
      ? new Date(moment(tripInformation.arrival_date, 'YYYY-MM-DD'))
      : '',
  });

  const getTipInformation = tripInformation => ({
    planed_trip: getPlanedTrip(tripInformation.planed_trip || {}),
    is_cancellation: tripInformation.is_cancellation || false,
    reason: tripInformation.reason || '',
  });

  const getPrice = price => ({
    value: price && price.value ? price.value : '',
    currency: price && price.currency ? price.currency : '',
  });

  const getOtherInsuranceCovering = otherInsuranceCovering => ({
    is_internal:
      otherInsuranceCovering && otherInsuranceCovering.is_internal !== undefined
        ? String(!otherInsuranceCovering.is_internal)
        : 'false',
    date: '',
    description:
      otherInsuranceCovering && otherInsuranceCovering.description
        ? otherInsuranceCovering.description
        : '',
    ...getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
  });

  const getAdditional = completeClaim => {
    if (completeClaim && completeClaim.additional_passengers)
      return completeClaim.additional_passengers.map(additional => ({
        first_name: additional.first_name,
        last_name: additional.last_name,
        birth_date: new Date(moment(additional.birth_date, 'YYYY-MM-DD')),
        gender: additional.gender,
        nationalities: additional.nationalities[0],
        relationship: additional.relationship,
        comment: additional.comment === null ? '' : additional.comment,
      }));
    else
      return [
        {
          first_name: '',
          last_name: '',
          birth_date: '',
          gender: '',
          nationalities: '',
          relationship: '',
          comment: '',
        },
      ];
  };

  const getHasAdditional = completeClaim => {
    return completeClaim && completeClaim.additional_passengers
      ? 'true'
      : 'false';
  };
  return {
    affected_person: getAffectedPerson(person, affectedPerson),
    trip_information: getTipInformation(tripInformation),
    incident: {
      start_date: incidentStartDate
        ? new Date(moment(incidentStartDate, 'YYYY-MM-DD'))
        : '',
    },
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    other_insurance_covering2: getOtherInsuranceCovering(
      otherInsuranceCovering2
    ),
    additional_passengers: getAdditional(completeClaim),
    hasAdditional: getHasAdditional(completeClaim),
    total_amount_claimed: getPrice(totalAmountClaimed),
  };
};
