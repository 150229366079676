import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_AREA = {
  en_US:
    'The maximum reimbursement under the purchase protection benefit is the coverage limit set by your issuing Bank.',
  pt_BR:
    'O reembolso máximo sob a benefício de proteção de compras é o cobertura estabelecida pelo seu Emissor.',
  es_ES:
    'El reembolso máximo en virtud de la cobertura de protección de compra es el valor establecido por tu Banco.',
};

export const LABEL_EVENT_DATE = {
  en_US: 'Date of event *',
  pt_BR: 'Data do evento *',
  es_ES: 'Fecha del evento *',
};

export const LABEL_BANK_DATE = {
  en_US: 'Date reported to bank *',
  pt_BR: 'Data informada ao banco *',
  es_ES: 'Fecha reportada al banco *',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descreva o incidente *',
  es_ES: 'Describa el incidente *',
};

export const LABEL_DESCRIPTION_CHARGE = {
  en_US: 'Description *',
  pt_BR: 'Descrição *',
  es_ES: 'Descripción *',
};

export const LABEL_ITEM = {
  en_US: 'Charge *',
  pt_BR: 'Cobrança *',
  es_ES: 'Cargo *',
};

export const LABEL_CHARGE_DATE = {
  en_US: 'Date *',
  pt_BR: 'Data *',
  es_ES: 'Fecha *',
};

export const LABEL_AMOUNT = {
  en_US: 'Amount *',
  pt_BR: 'Valor *',
  es_ES: 'Importe *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_ADDITIONAL_ITEMS = {
  en_US: 'Would you like to include additional items on this claim? *',
  pt_BR: 'Deseja incluir mais items neste mesmo sinistro? *',
  es_ES: 'Deseas incluir más un producto en este reclamo? *',
};

export const LABEL_TOTAL_AMOUNT_CLAIM = {
  en_US: 'Total amount of claim *',
  pt_BR: 'Valor total do sinistro *',
  es_ES: 'Importe total de la reivindicación *',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const TEXT_ITEM = {
  en_US: 'Charge #',
  pt_BR: 'Cobrança #',
  es_ES: 'Cargo #',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const BTN_ADD = {
  en_US: 'Add charge',
  pt_BR: 'Adicionar cobrança',
  es_ES: 'Agregar cargos',
};

export const LABEL_RETURN_PERIOD_TYPE = {
  en_US: 'Return period type',
  pt_BR: 'Tipo de prazo de devolução',
  es_ES: 'Tipo de plazo de devolución',
};

export const RETURN_PERIOD_OPTIONS = {
  en_US: `<option value="D"> Days </option>
          <option value="M"> Months </option>`,
  pt_BR: `<option value="D"> Dias </option>
          <option value="M"> Meses </option>`,
  es_ES: `<option value="D"> Dias </option>
          <option value="M"> Meses </option>`,
};

export const TEXT_RETAILER_REFUSAL_REASON = {
  en_US: 'Store refuse reason',
  pt_BR: 'Motivo de recusa da loja',
  es_ES: 'Razón de rechazo de la tienda',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};
