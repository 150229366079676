/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { priceProtectionFormInitialValues } from './initialValues';
import { priceProtectionSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';
import PricePeriodArea from './PricePeriodArea/PricePeriodArea';
import ReimburserdArea from './ReimburserdArea/ReimburserdArea';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import {
  INFINITE,
  PLATINUM,
  SIGNATURE,
} from '../../../../../../../utils/consts';

const BENEFIT_AS_OF_DATE = process.env.REACT_APP_BENEFIT_AS_OF_DATE.toString();

const PriceProtection = props => {
  const { translate, idiom, country } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const isBR = country === 'BR';
  const selectedCard = utils.getSelectedCard();
  const limitedCards =
    selectedCard.name.includes(INFINITE) ||
    selectedCard.name.includes(PLATINUM) ||
    selectedCard.name.includes(SIGNATURE);
  const limitDate =
    limitedCards && isBR
      ? new Date(`${BENEFIT_AS_OF_DATE}T00:00:00`)
      : undefined;

  const validationSchema = useMemo(
    () => priceProtectionSchema(intl, idiom, limitDate),
    [intl, idiom]
  );
  const initialValues = useMemo(
    () =>
      priceProtectionFormInitialValues(parentValues.policy, idiom, loadedData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <PricePeriodArea {...props} limitDate={limitDate} />
              <IncidentArea {...props} />
              <QuestionsArea {...props} />
              <ReimburserdArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default PriceProtection;
