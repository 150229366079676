import * as Yup from 'yup';
import { CheckIsVisaNumber } from '../../utils/card';
import { removeNonNumeric } from '../../utils/stringUtils';
import { validateExpirationDate } from '../UpdateBinTokenex/InputTokenex/validationSchema';

export const validationBin = (cards, intl) =>
  Yup.object().shape({
    /**
     * * The Business team requested to comment the effective_end_date from the form
     * ? ISSUER:
     * https://dev.azure.com/AASolutions/americas_visa_benefits_portal/_sprints/taskboard/americas_visa_benefits_portal%20Team/americas_visa_benefits_portal/2023/Sprint23_15?workitem=713801
     */
    // effective_end_date: Yup.string()
    //   .required(intl.TEXT_REQUIRED_FORM)
    //   .min(5, intl.TEXT_REQUIRED_FORM)
    //   .test('valid-expiration-date', intl.TEXT_INVALID_DATE, value => {
    //     if (value && value.length === 5) {
    //       return validateExpirationDate(value);
    //     }
    //     return false;
    //   }),
    cardNumber: Yup.string()
      .required(intl.TEXT_REQUIRED_FORM)
      .test(
        'has-16-digits',
        intl.TEXT_FORMAT_INVALID,
        pan => removeNonNumeric(pan).length === 16
      )
      .test(
        'is-bin-duplicated',
        intl.TEXT_CARD_ALREADY_REGISTER,
        pan =>
          !cards.find(
            card =>
              card.external_reference === removeNonNumeric(pan).substring(0, 10)
          )
      )
      .test('is-visa-card', intl.TEXT_NOT_VISA_PRODUCT, pan =>
        CheckIsVisaNumber(removeNonNumeric(pan))
      ),
  });
