import {
  TEXT_ADD_YOUR_CARD_PAN,
  TEXT_16_DIGITS,
} from '../../../../components/ModalAddCard/intl';
import {
  TEXT_ADD_YOUR_CARD_BIN,
  TEXT_10_DIGITS,
} from '../../../../components/ModalAddCardBin/intl';

export const TEXT_BUTTON_ADVANCED_SEARCH = {
  en_US: 'Search',
  es_ES: 'Buscar',
  pt_BR: 'Pesquisar',
};

export const LABEL_ADD_SHORT = {
  en_US: 'Add this card',
  pt_BR: 'Adicione este cartão',
  es_ES: 'Agregar esta tarjeta',
};

export const TEXT_DELETED_CARD = {
  en_US: `This card is not associated in your profile currently.  To enjoy benefits related with it, it's necessary to add it in your profile again.`,
  pt_BR:
    'Este cartão não está associado ao seu perfil no momento. Para usufruir dos benefícios a ele relacionados, é necessário adicioná-lo novamente ao seu perfil.',
  es_ES:
    'Esta tarjeta no está asociada en tu perfil actualmente. Para disfrutar de los beneficios relacionados con él, es necesario volver a agregarlo a tu perfil.',
};

export const TEXT_ADD_NEW_CARD = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};

export {
  TEXT_ADD_YOUR_CARD_PAN,
  TEXT_16_DIGITS,
  TEXT_ADD_YOUR_CARD_BIN,
  TEXT_10_DIGITS,
};
