import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { enhancedPurchaseEcommerceInitialValues } from './initialValues';
import { enhancedPurchaseEcommerceschema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import ReimburserdArea from './ReimburserdArea/ReimburserdArea';

const EnhancedPurchaseEcommerce = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef } = props;

  const validationSchema = useMemo(
    () => enhancedPurchaseEcommerceschema(intl),
    [intl]
  );
  const initialValues = useMemo(
    () => enhancedPurchaseEcommerceInitialValues(parentValues.policy, idiom),
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="btnSubmitForm"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <IncidentArea {...props} />
              <ReimburserdArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default EnhancedPurchaseEcommerce;
