import React from 'react';
import styles from './SessionContainer.module.scss';

const SessionContainer = props => {
  return (
    <div
      role="dialog"
      aria-labelledby="stepped_flow_title"
      className={styles.SessionContainer}
    >
      {props.children}
    </div>
  );
};

export default SessionContainer;
