import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const PAY_LEGAL_EXPENSES = {
  en_US: 'Did you pay for legal expenses? *',
  es_ES: '¿Pagaste tú los gastos legales? *',
  pt_BR: 'Você pagou por despesas legais? *',
};

export const AMOUNT_PAID = {
  en_US: 'Indicate the amount paid for legal expenses *',
  es_ES: 'Indica el monto pagado en gastos legales *',
  pt_BR: 'Indique o valor gasto com despesas legais *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};
