export const MODAL_TITLE_ERROR = {
  en_US: '',
  pt_BR: '',
  es_ES: 'No logramos redimir tu beneficio',
};

export const MODAL_SUBTITLE_ERROR = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Contacta a nuestro equipo de soporte, ellos te apoyarán.',
};

export const MODAL_BTN_ERROR = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Habla con nosotros',
};

export const MODAL_BTN_DENY_ERROR = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Ir a Inicio',
};

export const MODAL_TITLE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Disfruta de tu beneficio Visa',
};

export const MODAL_SUBTITLE = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Ya estás listo para disfrutar de tu película, para hacer válida la promoción muestra el siguiente código al comprar tu boleto.',
};

export const MODAL_BTN = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Ver Mis Vouchers',
};

export const LABEL_EXPIRATION_DATE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Fecha de expiración',
};

export const LABEL_MONTH = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Mes',
};

export const LABEL_YEAR = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Año',
};

export const MODAL_BTN_CONTINUE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Continuar',
};

export const MODAL_BTN_BACK = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Volver',
};

export const BUTTON_CLOSE = {
  en_US: 'Ok',
  pt_BR: 'Ok',
  es_ES: 'Ok',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const TITLE_EXPIRATION_DATE_INSERT = {
  en_US: pan => `Agrega la fecha de expiración de la tarjeta: ${pan}`,
  pt_BR: pan => `Agrega la fecha de expiración de la tarjeta: ${pan}`,
  es_ES: pan => `Agrega la fecha de expiración de la tarjeta: ${pan}`,
};

export const TITLE_EXPIRATION_DATE_CHECK = {
  en_US: pan => `Confirma la fecha de expiración de la tarjeta: ${pan}`,
  pt_BR: pan => `Confirma la fecha de expiración de la tarjeta: ${pan}`,
  es_ES: pan => `Confirma la fecha de expiración de la tarjeta: ${pan}`,
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const REDEEM = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Redimir voucher',
};

export const TITLE_VOUCHERS_EXCEEDED = {
  en_US: `We're sorry!`,
  pt_BR: 'Sentimos muito!',
  es_ES: '¡Lo sentimos!',
};

export const SUBTITLE_VOUCHERS_EXCEEDED = {
  en_US: 'Your voucher limit for this benefit has exceeded.',
  pt_BR: 'Você atingiu o limite de vouchers para este benefício.',
  es_ES: 'Has excedido el límite de vouchers para este beneficio.',
};
