export const LABEL_RENTAL_START = {
  en_US: 'Rental period start',
  pt_BR: 'Início do período do aluguel',
  es_ES: 'Comienzo del período de alquiler',
};

export const LABEL_RENTAL_END = {
  en_US: 'Rental period end',
  pt_BR: 'Fim do período do aluguel',
  es_ES: 'Fin del periodo de alquiler',
};

export const TEXT_RENTAL_PERIOD = {
  en_US: 'Rental period',
  pt_BR: 'Período do aluguel',
  es_ES: 'Período de alquiler',
};

export const TEXT_MAXIMUM_REIMBURSEMENT = {
  en_US:
    'The maximum reimbursement under the price protection coverage is the paid price of the purchased item not exceeding the coverage limit set by your issuing bank',
  pt_BR:
    'O reembolso máximo sob a cobertura de proteção de preço é o preço pago do item comprado que não exceda o limite de cobertura estabelecido pelo seu banco emissor',
  es_ES:
    'El reembolso máximo en virtud de la cobertura de protección de precios es el precio pagado del artículo adquirido que no excede el límite de cobertura establecido por tu banco emisor',
};

export const TEXT_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const TEXT_ADVERTISEMENT_DATE = {
  en_US: 'Advertisement date',
  pt_BR: 'Data do anúncio',
  es_ES: 'Fecha del anuncio',
};

export const TEXT_START_DATE = {
  en_US: 'Trip start date',
  pt_BR: 'Data de início da viagem',
  es_ES: 'Fecha de inicio del viaje',
};

export const TEXT_END_DATE = {
  en_US: 'Trip end date',
  pt_BR: 'Data de fim da viagem',
  es_ES: 'Fecha de fin del viaje',
};

export const TEXT_DATE_OF_TRIP = {
  en_US: 'Date of trip',
  pt_BR: 'Data da viagem',
  es_ES: 'Fecha del viaje',
};
