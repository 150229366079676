import {
  pt_countries,
  pt_destinationCountryOptions,
} from '../../../../../../../intl/pt_countries';
import {
  en_countries,
  en_destinationCountryOptions,
} from '../../../../../../../intl/en_countries';
import {
  es_countries,
  es_destinationCountryOptions,
} from '../../../../../../../intl/es_countries';

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const COUNTRY_OPTIONS_DESTINATIONS = {
  en_US: () => en_destinationCountryOptions(),
  pt_BR: () => pt_destinationCountryOptions(),
  es_ES: () => es_destinationCountryOptions(),
};

export const FIELD_ORIGIN_COUNTRY = {
  en_US: 'Origin country *',
  pt_BR: 'País de origem',
  es_ES: 'País de origen *',
};

export const VALIDATE_ORIGIN_COUNTRY = {
  en_US: 'Select origin country *',
  pt_BR: 'Selecione o país de origem *',
  es_ES: 'Seleccionar país de origen *',
};

export const FIELD_DESTINATION_COUNTRY = {
  en_US: 'Destination country *',
  pt_BR: 'País de destino',
  es_ES: 'País de destino *',
};

export const VALIDATE_DESTINATION_COUNTRY = {
  en_US: 'Select destination country *',
  pt_BR: 'Selecione o país de destino *',
  es_ES: 'Seleccionar país de destino *',
};

export const FIELD_ORIGIN_CITY = {
  en_US: 'Origin city *',
  pt_BR: 'Cidade de origem *',
  es_ES: 'Ciudad de origen *',
};

export const VALIDATE_ORIGIN_CITY = {
  en_US: 'Select origin city *',
  pt_BR: 'Selecione a cidade de origem *',
  es_ES: 'Seleccionar cidad de origen *',
};

export const FIELD_DESTINATION_CITY = {
  en_US: 'Destination city *',
  pt_BR: 'Cidade de destino *',
  es_ES: 'Ciudad de destino *',
};

export const VALIDATE_DESTINATION_CITY = {
  en_US: 'Select destination City *',
  pt_BR: 'Selecione a cidade de destino *',
  es_ES: 'Seleccionar ciudad de destino *',
};

export const DESTINATION_COUNTRY_HELP_TEXT = {
  en_US: `Final destination country indicated on your Visa travel ticket. If you have more
  than one destination, you can enter one of your choice.`,
  pt_BR: `País de destino final indicado no seu bilhete de viagem com Visa. Se você tiver
  mais de um destino, poderá informar um de sua escolha.`,
  es_ES: `País de destino final indicado en tu boleto de viaje comprador con Visa. Si
  tienes más de un destino, puedes informar uno de tu elección.`,
};
