import { Dispatch, SetStateAction } from 'react';
import { useContext } from 'react';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import { IntlContext } from '../../../../../intl';
import { stringToBoolean } from '../../../../../utils/stringUtils';
import BookingInfoArea from './BookingInfoArea';
import FlightsArea from './FlightsArea';
import * as translations from './intl';
import { FormikProps } from 'formik';
import { FlightDetailsSchema } from '../validationSchema';
import { FullTravelInsuranceCertificateDetail } from '../../../../../@types/APIs/Certificate';

type FormProps = FormikProps<FlightDetailsSchema>;

interface AcordeonStatus {
  outbound: boolean;
  return: boolean;
}

interface TripForm {
  formikProps: FormProps;
  isReturn?: boolean;
  statusState: [AcordeonStatus, Dispatch<SetStateAction<AcordeonStatus>>];
  certificate: FullTravelInsuranceCertificateDetail;
  onSubmit: (formikProps: FormProps) => void;
  validateFirstStep?: (formikProps: FormProps) => void;
  setStep?: Dispatch<SetStateAction<number>>;
}

const TripForm = ({
  formikProps,
  isReturn = false,
  statusState,
  certificate,
  onSubmit,
  validateFirstStep,
  setStep,
}: TripForm) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const isOneWayTravel = stringToBoolean(
    certificate.travel_information?.is_one_way_travel
  );

  const [accordeonStatus, setAccordeonStatus] = statusState;
  const setCurrentStatus = (selected: keyof AcordeonStatus) => {
    const newStatus = { ...accordeonStatus };
    Object.keys(newStatus).forEach(key => {
      newStatus[key] = false;
    });
    newStatus[selected] = !accordeonStatus[selected];
    setAccordeonStatus(newStatus);
  };

  return (
    <AcordeonSession
      title={isReturn ? intl.TITLE_RETURN : intl.TITLE_OUTBOUND}
      status={isReturn ? accordeonStatus.return : accordeonStatus.outbound}
      back={() => (isReturn ? setCurrentStatus('outbound') : setStep?.(2))}
      setStatus={() => setCurrentStatus(isReturn ? 'return' : 'outbound')}
      notOpen={isOneWayTravel}
      next={
        isReturn || isOneWayTravel
          ? () => onSubmit(formikProps)
          : () => validateFirstStep(formikProps)
      }
      txtBtnNext={isReturn || isOneWayTravel ? intl.BTN_CONCLUDE : ''}
    >
      <>
        <BookingInfoArea
          formikProps={formikProps}
          isReturn={isReturn}
          certificate={certificate}
        />
        <FlightsArea
          formikProps={formikProps}
          isReturn={isReturn}
          certificate={certificate}
        />
      </>
    </AcordeonSession>
  );
};

export default TripForm;
