import { ChangeEvent, ReactElement, useRef, useState } from 'react';
import styles from './FileUploader.module.scss';
import FileCard, { UploadedFile } from './FileCard/FileCard';
import Button from '../../componentsV2/Button/Button';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { useIntl } from '../../intl';
import Badge from '../Badge/Badge';

export interface FileUploaderProps {
  title: string;
  description: string;
  callbackAddFile: (file: File) => Promise<void>;
  callbackDeleteFile: (index?: number) => Promise<boolean>;
  initialUploadedFilesList?: UploadedFile[];
  multiple?: boolean;
  maxFiles?: number;
  required?: boolean;
  hasLabel?: boolean;
  CustomChip?: () => ReactElement | ReactElement[];
  getFileToDownload?: () => Promise<File>;
}

export type FileStatus = 'SENT' | 'NOT SENT' | 'SENDING';

const FileUploader = ({
  title,
  description,
  callbackAddFile,
  callbackDeleteFile,
  initialUploadedFilesList,
  multiple,
  maxFiles = 5,
  required,
  hasLabel,
  CustomChip,
  getFileToDownload,
}: FileUploaderProps) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>(
    initialUploadedFilesList || []
  );
  const [filesStatus, setFileStatus] = useState<FileStatus[]>(
    initialUploadedFilesList
      ? new Array(initialUploadedFilesList.length).fill('SENT')
      : []
  );

  const { getGlobalTheme } = useTheme();
  const { translate } = useIntl();

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const handleUploadFile = () => {
    inputFileRef.current.click();
  };

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = inputFileRef.current.files[0];
      if (file) {
        setFileStatus(currentStatus => [...currentStatus, 'SENDING']);
        setUploadedFiles(currentFiles => [
          ...currentFiles,
          { name: file.name, id: new Date().getTime() } as UploadedFile,
        ]);

        await callbackAddFile(file);

        setFileStatus(currentStatus => {
          const clone = [...currentStatus];
          clone[clone.length - 1] = 'SENT';
          return clone;
        });
      }
    } catch (error) {
      setUploadedFiles(files => {
        const clone = [...files];
        clone.splice(files.length - 1, 1);
        return clone;
      });
      setFileStatus(status => {
        const clone = [...status];
        clone.splice(status.length - 1, 1);
        return clone;
      });
    }
  };

  const downloadFile = async index => {
    try {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENDING';
        return clone;
      });
      const file = await getFileToDownload();
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENT';
        return clone;
      });

      return file;
    } catch (error) {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENT';
        return clone;
      });
      return null;
    }
  };

  const removeFile = async (index: number, isError: boolean) => {
    try {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENDING';
        return clone;
      });
      if (!isError) {
        const result = await callbackDeleteFile(index);
        if (!result) {
          throw new Error('Keep the file');
        }
      }

      setUploadedFiles(files => {
        const clone = [...files];
        clone.splice(index, 1);
        return clone;
      });
      setFileStatus(status => {
        const clone = [...status];
        clone.splice(index, 1);
        return clone;
      });
    } catch (error) {
      setFileStatus(currentStatus => {
        const clone = [...currentStatus];
        clone[index] = 'SENT';
        return clone;
      });
    }
  };

  // const reloadFile = async (index: number) => {
  //   try {
  //     setFileStatus(currentStatus => {
  //       const clone = [...currentStatus];
  //       clone[index] = 'SENDING';
  //       return clone;
  //     });

  //     await callbackAddFile(uploadedFiles[index]);

  //     setFileStatus(currentStatus => {
  //       const clone = [...currentStatus];
  //       clone[index] = 'SENT';
  //       return clone;
  //     });
  //   } catch (error) {
  //     setFileStatus(currentStatus => {
  //       const clone = [...currentStatus];
  //       clone[index] = 'NOT SENT';
  //       return clone;
  //     });
  //   }
  // };
  const formatCustomChip = (index: number) => {
    const Chip = CustomChip();

    if (Array.isArray(Chip)) {
      return () => Chip[index];
    } else {
      return () => Chip;
    }
  };

  return (
    <div className={`${styles.fileUploaderContainer} ${styles[theme]}`}>
      {hasLabel && (
        <>
          <Badge
            className={styles.label}
            label={required ? intl.LABEL_REQUIRED : intl.LABEL_OPTIONAL}
            type={required ? 'Default' : 'Secondary'}
          />
        </>
      )}
      <div
        className={`${styles.fileUploaderBody} ${
          hasLabel ? '' : styles.borderNoLabel
        }`}
      >
        <header className={styles.bodyHeader}>
          <h5 className={styles[theme]}>{title}</h5>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </header>

        {uploadedFiles.length > 0 && (
          <div className={styles.content}>
            <ul>
              {uploadedFiles.map((file, index) => (
                <FileCard
                  key={file.id}
                  uploadedFile={file}
                  removeFile={(isError: boolean) => removeFile(index, isError)}
                  getFileToDownload={
                    getFileToDownload ? () => downloadFile(index) : undefined
                  }
                  // reloadFile={() => reloadFile(index)}
                  fileStatus={filesStatus[index]}
                  CustomChip={CustomChip ? formatCustomChip(index) : undefined}
                />
              ))}
            </ul>
          </div>
        )}

        {((multiple && uploadedFiles.length < maxFiles) ||
          (!multiple && uploadedFiles.length === 0)) && (
          <footer className={styles.actions}>
            <input
              type="file"
              ref={inputFileRef}
              onChange={uploadFile}
              style={{ display: 'none' }}
            />
            <div className={styles.wrapperButton}>
              <Button
                type="Secondary"
                onClick={handleUploadFile}
                label={intl.BUTTON_ADD_FILE}
              />
            </div>
          </footer>
        )}
      </div>
    </div>
  );
};

export default FileUploader;
