import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import BankAccount from './bankAccount/BankAccount';
import CheckBoxPaymentType from './OCTFlow/CheckBoxPaymentType/CheckBoxPaymentType';
import OCTForm from './OCTFlow/OCTForm/OCTForm';
import { TYPE_BANK, TYPE_OCT, showOCT } from './OCTFlow/oct-const';

const PaymentInformation = props => {
  const {
    bankType,
    reimbursementSubmitRef,
    updateParent,
    setBankType,
    country,
    claimType,
    isProfile,
  } = props;

  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const ENABLE_OCT = showOCT(country, claimType);

  useEffect(() => {
    if (ENABLE_OCT && !isProfile) {
      setBankType(TYPE_OCT);
    }
  }, [ENABLE_OCT]);

  if (isProfile) {
    return <BankAccount {...props} idiom={idiom} intl={intl}></BankAccount>;
  }

  return (
    <>
      {ENABLE_OCT && <CheckBoxPaymentType {...props} enableOCT={ENABLE_OCT} />}

      {bankType == TYPE_BANK && (
        <BankAccount {...props} idiom={idiom} intl={intl} />
      )}
      {bankType == TYPE_OCT && (
        <OCTForm
          updateParent={updateParent}
          reimbursementSubmitRef={reimbursementSubmitRef}
        />
      )}
    </>
  );
};

export default PaymentInformation;
