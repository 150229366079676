import * as Yup from 'yup';
import { bankSchema, bankSchemaBR } from './bank';
import { accountHolderSchema } from './accountHolder';
import { VALUE_AR, VALUE_CO, VALUE_GT, VALUE_MX } from '../constants';

const BankAccountSchema = intl => {
  return Yup.lazy(value => {
    const account_type = Yup.string().required(intl.VALIDATE_REQUIRED_FIELD);
    const wireCodes = getWireCodeValidation(value, intl);
    const bank = bankSchema(intl);
    const bankCountryView = Yup.string().required(intl.VALIDATE_REQUIRED_FIELD);

    return Yup.object().shape({
      bank,
      account_type,
      ...wireCodes,
      bankCountryView,
    });
  });
};

const InterBankAccountSchema = intl => {
  return Yup.lazy(value => {
    const account_type = Yup.string().required(intl.VALIDATE_REQUIRED_FIELD);
    const wireCodes = getWireCodeValidation(value, intl);
    const bankIntermediaryView = value.bankIntermediaryView;
    const bank = bankSchema(intl);

    if (bankIntermediaryView === 'yes') {
      return Yup.object().shape({
        bank,
        account_type,
        ...wireCodes,
      });
    } else {
      return Yup.object().shape({});
    }
  });
};

const BankAccountSchemaBR = intl => {
  let bank = bankSchemaBR(intl);
  return Yup.object().shape({
    bank,
  });
};

const BankAccountHolderSchema = (intl, isBrazil, claimType) => {
  return Yup.lazy(value => {
    return accountHolderSchema(intl, value, isBrazil, claimType);
  });
};

export const paymentInformationsSchema = (
  intl,
  isBrazil,
  claimType,
  thirdPartyPayment
) => {
  let object = {};

  if (!thirdPartyPayment) {
    if (isBrazil) {
      object.BankAccount = BankAccountSchemaBR(intl);
    } else {
      object.BankAccount = BankAccountSchema(intl);
      if (claimType !== 'EMC_Emergency_Medical_Assistance') {
        object.IntermediaryBankAccount = InterBankAccountSchema(intl);
      }
    }

    object.BankAccountHolder = BankAccountHolderSchema(
      intl,
      isBrazil,
      claimType
    );
  }

  return Yup.object().shape(object);
};

const getWireCodeValidation = (valueLazy, intl) => {
  const { bankCountryView } = valueLazy;
  let wireCodes = {};

  switch (bankCountryView) {
    case VALUE_MX:
      wireCodes.FULL_KEY = Yup.object().shape({
        value: Yup.string()
          .test(
            'test-Full-key',
            intl.INVALID_FORMAT_FULL_KEY_CODE,
            function (value) {
              return value.replace(/-/g, '').length === 18;
            }
          )
          .required(intl.VALIDATE_REQUIRED_FIELD),
      });
      wireCodes.bank_identification_number = Yup.string()
        .test('test-Swift', intl.INVALID_FORMAT_SWIFT, function (value) {
          return value.replace(/-/g, '').length === 11;
        })
        .required(intl.VALIDATE_REQUIRED_FIELD);
      break;

    case VALUE_AR:
      wireCodes.CUIT = Yup.object().shape({
        value: Yup.string()
          .test(
            'test-aba-length',
            intl.INVALID_FORMAT_CUIT_ES_AR,
            function (value) {
              return value.replace(/-/g, '').length === 11;
            }
          )
          .required(intl.VALIDATE_REQUIRED_FIELD),
      });
      wireCodes.FULL_KEY = Yup.object().shape({
        value: Yup.string()
          .test(
            'test-Full-key',
            intl.INVALID_FORMAT_CBU_ES_AR,
            function (value) {
              return value.replace(/-/g, '').length === 22;
            }
          )
          .required(intl.VALIDATE_REQUIRED_FIELD),
      });
      break;

    case VALUE_CO:
      break;

    case VALUE_GT:
      wireCodes.IBAN = Yup.object().shape({
        value: Yup.string()
          .test('test-IBAN', intl.INVALID_FORMAT_IBAN, function (value) {
            return value === '' || value.replace(/-/g, '').length === 29
              ? true
              : false;
          })
          .nullable(),
      });
      break;

    default:
      wireCodes.SWIFT = Yup.object().shape({
        value: Yup.string().test(
          'test-Swift',
          intl.INVALID_FORMAT_SWIFT,
          function (value) {
            if (valueLazy.ABA.value) {
              return true;
            }
            return value.replace(/-/g, '').length === 11;
          }
        ),
      });

      wireCodes.ABA = Yup.object().shape({
        value: Yup.string().test(
          'test-aba-length',
          intl.INVALID_FORMAT_ABA,
          function (value) {
            if (valueLazy.SWIFT.value) {
              return true;
            }
            return value.replace(/-/g, '').length === 9;
          }
        ),
      });
      break;
  }

  return wireCodes;
};
