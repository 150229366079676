export const CONFIG_ICON = {
  'theme-afluent': {
    primary: '#021E4C', //021e4c
    secondary: '#021E4C',
    white: '#fff',
    lightBackground: '#C2A161',
    inactive: '#808EA5',
    disabled: '#6a727b',
    alert: '#c64b60',
    success: '#40996B',
    error: '#c64b60',
    secondary_darkbackground: '#FFFFFFF',
    primary_darkbackground: '#FFFFFFF',
    hover: '#021E4C',
  },
  'theme-master': {
    primary: '#1434CB',
    secondary: '#FCC015',
    white: '#fff',
    lightBackground: '#1434CB',
    inactive: '#808EA5',
    disabled: '#6a727b',
    alert: '#c64b60',
    success: '#40996B',
    error: '#c64b60',
    secondary_darkbackground: '#FCC015',
    primary_darkbackground: '#FFF',
  },
  axa: {
    primary: '#00008f',
    secondary: '#00008f',
    error: '#c64b60',
  },
  'theme-portoBank': {
    primary: '#0A0047',
    secondary: '#546AC0',
    white: '#fff',
    lightBackground: '#0A0047',
    inactive: '#808EA5',
    disabled: '#6a727b',
    alert: '#c64b60',
    success: '#40996B',
    error: '#c64b60',
    secondary_darkbackground: '#546AC0',
    primary_darkbackground: '#546AC0',
  },
};
