export const LABEL_TITLE = {
  en_US: 'Title *',
  pt_BR: 'Título *',
  es_ES: 'Título *',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const LABEL_NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LABEL_NAME_AS_PASSAPORT = {
  en_US: 'Name as it appears in passport *',
  pt_BR: 'Nome como aparece no passaporte *',
  es_ES: 'Nombre como aparece em el pasaporte *',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const LABEL_GENDER = {
  en_US: 'Gender *',
  pt_BR: 'Gênero *',
  es_ES: 'Género *',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};
