import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './EstimatedValueCellPhone.module.scss';
import * as constants from '../consts';
import Select from '../../../../../../../../components/Select/Select';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import { safeGet } from '../../../../../../../../utils/object';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const EstimatedValueCellPhone = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue('purchase_protection_items.amount_claimed.value', '');
  };

  const setCurrency = currency => {
    setFieldValue('purchase_protection_items.price.currency', currency);
    setFieldTouched('purchase_protection_items.price.currency', true);
  };

  useEffect(() => {
    const currency = values.purchase_protection_items.price.currency;
    const getCurrency = getCurrencyByHomeCountry();
    if (!currency && getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.purchase_protection_items.price.currency]);

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchase_protection_items.price.value}
          label={intl.ESTIMATED_CELL_PHONE_VALUE}
          name="purchase_protection_items.price.value"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNt_DECIMAL}
          onChange={handleChange}
          touched={safeGet(touched, 'purchase_protection_items.price.value')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchase_protection_items.price.value')}
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items.price.currency}
          name="purchase_protection_items.price.currency"
          dataTestid="comboboxPriceCurrency"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onChange={handleChange}
          touched={safeGet(touched, 'purchase_protection_items.price.currency')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchase_protection_items.price.currency')}
        />
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_FULL_CARD}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_FULL_CARD}
              defaultChecked={
                values.purchase_protection_items.was_fully_charged_on_card ===
                'true'
              }
              text={intl.TEXT_YES}
              name="purchase_protection_items.was_fully_charged_on_card"
              value="true"
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_FULL_CARD}
              defaultChecked={
                values.purchase_protection_items.was_fully_charged_on_card ===
                'false'
              }
              text={intl.TEXT_NO}
              name="purchase_protection_items.was_fully_charged_on_card"
              value="false"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_TYPE_OF_LOSS}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_TYPE_OF_LOSS}
              describe={intl.TEXT_DESCRIBE_REPAIRABLE}
              defaultChecked={
                values.purchase_protection_items.status === constants.DAMAGE
              }
              text={intl.TEXT_DAMAGE}
              name="purchase_protection_items.status"
              value={constants.DAMAGE}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_TYPE_OF_LOSS}
              defaultChecked={
                values.purchase_protection_items.status === constants.STOLEN
              }
              text={intl.TEXT_STOLEN}
              name="purchase_protection_items.status"
              value={constants.STOLEN}
              onChange={e => {
                setFieldValue('purchase_protection_items.is_repairable', false);
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>

      {values.purchase_protection_items.status === constants.DAMAGE && (
        <div className="col-12">
          <div className={`${styles.elementRadio} ${styles[themes]} row`}>
            <div className={`col-lg-12 col-sm-12`}>
              <label className={`${styles.labelTitle} ${styles[themes]}`}>
                {intl.LABEL_CELL_PHONE_REPAIRABLE}
              </label>
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.LABEL_CELL_PHONE_REPAIRABLE}
                describe={intl.TEXT_DESCRIBE_REPAIRABLE_YES}
                defaultChecked={
                  values.purchase_protection_items.is_repairable === 'true'
                }
                text={intl.TEXT_YES}
                name="purchase_protection_items.is_repairable"
                dataTestid="radioIsRepairableYes"
                value={true}
                onChange={e => {
                  setEmptyOtherInsurance();
                  handleChange(e);
                }}
              />
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                label={intl.LABEL_CELL_PHONE_REPAIRABLE}
                describe={intl.TEXT_DESCRIBE_REPAIRABLE_NO}
                defaultChecked={
                  values.purchase_protection_items.is_repairable === 'false'
                }
                text={intl.TEXT_NO}
                name="purchase_protection_items.is_repairable"
                dataTestid="radioIsRepairableNo"
                value={false}
                onChange={e => {
                  setEmptyOtherInsurance();
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.purchase_protection_items.is_repairable !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivAmountClaimedValueField"
      >
        <Input
          value={values.purchase_protection_items.amount_claimed.value}
          label={intl.LABEL_REPAIR_VALUE}
          name="purchase_protection_items.amount_claimed.value"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNt_DECIMAL}
          disabled={values.purchase_protection_items.is_repairable !== 'true'}
          onChange={handleChange}
          touched={safeGet(
            touched,
            'purchase_protection_items.amount_claimed.value'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'purchase_protection_items.amount_claimed.value'
          )}
        />
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.purchase_protection_items.is_repairable !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivAmountClaimedCurrencyField"
      >
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items.price.currency}
          name="purchase_protection_items.amount_claimed.curency"
          disabled
          dataTestid="comboboxAmountClaimedCurrency"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onChange={handleChange}
          touched={safeGet(
            touched,
            'purchase_protection_items.amount_claimed.curency'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'purchase_protection_items.amount_claimed.curency'
          )}
        />
      </div>
    </div>
  );
};

export default EstimatedValueCellPhone;
