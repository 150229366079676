export const TITLE_CONNECTION_ERROR = {
  es_ES: 'Error al conectarse a la llamada.',
  en_US: 'Error connecting to call.',
  pt_BR: 'Erro ao conectar-se à chamada.',
};

export const TITLE_WAIT_FOR_DOCTOR = {
  es_ES: 'Por favor espere. Tu médico llegará en breve.',
  en_US: 'Please wait. Your doctor will arrive soon.',
  pt_BR: 'Por favor, espere. Seu médico chegará em breve.',
};
