import { IntlContext } from '../../../../../../../../../intl';
import { useContext, useEffect, useState } from 'react';
import * as translations from '../intl';
import * as translationsOCTProfile from '../../../../../../../../Profile/ProfileAccount/OCTDetails/intl';
import styles from './OCTChooseCard.module.scss';
import { StateContext } from '../../../../../../../../../components/StateContextParent/StateContextParent';
import { AppInsightTrackContext } from '../../../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { octService } from '../../../../../../../../../services';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import InfoLabelValue from '../../../../../../../../../components/InfoLabelValue/InfoLabelValue';
import SuccessIcon from '../../../../../../../../../assets/icons/SuccessIcon';
import { PaymentDetail } from '../OCTInitialValues/OCTInitialValues';
import { formatCustomDate } from '../../../../../../../../../utils/date';
import Loading from '../../../../../../../../../components/Loading/Loading';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import ModalInformation from '../../../../../../../../../components/ModalInformation/ModalInformation';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import { changeMainCardAddConfirmation } from '../../../../../../../../Profile/ProfileAccount/OCTDetails/OCTDetails';
import Badge from '../../../../../../../../../componentsV2/Badge/Badge';
import HighLightNote from '../../../../../../../../../components/HighlightNote/HighlightNote';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const WIDTH_HEIGHT_ICONS = 22;

const OCTChooseCard = ({
  paymentID,
  setPaymentID,
  isProfile = false,
  canChangePaymentMethod = false,
  setCanShowModalConfirmation = null,
}) => {
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { translate, idiom } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const intl = translate(translations);
  const intlOCTProfile = translate(translationsOCTProfile);
  const [loading, setLoading] = useState<boolean>(false);
  const [listPayments, setListPayments] = useState<PaymentDetail[]>([]);

  const hideLoading = () => {
    return isProfile
      ? actions.loadingFullScreen.hideLoadingFullScreen()
      : actions.modal.hideLoading();
  };

  const showLoading = () => {
    return isProfile
      ? actions.loadingFullScreen.showLoadingFullScreen(intl.SAVING)
      : actions.modal.showLoading(intl.SAVING);
  };

  const setMainCard = async payment_id => {
    if (isProfile) {
      actions.modal.closeModal();
    }

    try {
      showLoading();
      await octService.setMainCard(payment_id);
      setPaymentID(payment_id);
      hideLoading();
      trackEventUserAction('#### OCT - SET MAIN PAYMENT METHOD  ###', {
        payment_id,
      });
    } catch (error) {
      trackEventUserAction('#### OCT ERROR - SET MAIN PAYMENT METHOD  ###', {
        error,
        response: error && error.response,
        data: error && error.response && error.response.data,
      });
      hideLoading();
    }

    if (!isProfile) {
      actions.modal.closeModal();
    }
  };

  const deleteCard = async payment_id => {
    try {
      actions.modal.showLoading(intl.LOADING_REMOVING_CARD);
      await octService.deleteOctFlow(payment_id);

      trackEventUserAction('#### OCT - DELETE PAYMENT METHOD  ###', {
        payment_id,
      });

      const {
        data: { payments },
      } = await octService.getAccountDetailPalla();
      setListPayments(payments);

      trackEventUserAction('#### OCT - LIST PAYMENT METHODS UPDATED  ###', {
        payments,
      });

      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="success" message={intl.CONFIRM_DELETE_CARD} />,
        true,
        true
      );
    } catch (error) {
      trackEventUserAction('#### OCT ERROR - DELETE PAYMENT METHOD  ###', {
        data: error && error.response && error.response.data,
        response: error && error.response,
        error,
      });

      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.SOMETHING_WENT_WRONG} />,
        true,
        true
      );
    }
  };

  const deleteCardConfirmation = payment_id => {
    actions.modal.showModal(
      '',
      true,
      <ModalInformation
        type="error"
        message={intl.DELETE_CARD_TITLE}
        subtitle={intl.DELETE_CARD_SUBTITLE}
        clickBtn={async () => await deleteCard(payment_id)}
        textBtn={intl.BTN_REMOVE}
        buttonConfirmType="borderRed"
        textBtnDeny={intl.TEXT_NO_KEEP}
        clickBtnDeny={() => {
          actions.modal.closeModal();
        }}
      />
    );
  };

  const changeMainCardConfirmation = payment_id => {
    actions.modal.showModal(
      '',
      true,
      <ModalInformation
        type="question"
        message={intl.TITLE_CONFIRM_CHANGE_MAIN_CARD}
        subtitle={intl.SUBTITLE_CONFIRM_CHANGE_MAIN_CARD}
        clickBtn={() => setMainCard(payment_id)}
        textBtn={intl.BUTTON_CONFIRM_CHANGE_MAIN_CARD}
        textBtnDeny={intl.BUTTON_DENY_CONFIRM_CHANGE_MAIN_CARD}
        clickBtnDeny={() => {
          actions.modal.closeModal();
        }}
      />
    );
  };

  useEffect(() => {
    const getAccountDetailPalla = async () => {
      try {
        setLoading(true);
        const {
          data: { payments },
        } = await octService.getAccountDetailPalla();
        setListPayments(payments);

        if (setCanShowModalConfirmation) {
          setCanShowModalConfirmation(payments.length > 0);
        }

        trackEventUserAction('#### OCT - GET PAYMENTS  ###', {
          payments,
        });

        setLoading(false);
      } catch (error) {
        if (setCanShowModalConfirmation) {
          setCanShowModalConfirmation(false);
        }

        trackEventUserAction('#### OCT ERROR - GET ACCOUNT DETAIL  ###', {
          error,
          response: error && error.response,
          data: error && error.response && error.response.data,
        });

        setLoading(false);
      }
    };

    getAccountDetailPalla();
  }, [paymentID]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              {!isProfile && (
                <div className={styles.headerActions}>
                  <Button
                    id="addPaymentMethod-claim"
                    type="Tertiary"
                    className={styles.headerAddButton}
                    onClick={() =>
                      changeMainCardAddConfirmation(
                        actions,
                        intlOCTProfile,
                        setPaymentID
                      )
                    }
                    label={intlOCTProfile.ADD_OCT_PAYMENT_METHOD}
                    leadingIcon={<AddIcon />}
                  />
                </div>
              )}
              {!canChangePaymentMethod && (
                <div className={styles.wrapperLabel}>
                  <HighLightNote
                    note={intl.YOU_CANT_CHANGE_DIRECT_PAYMENT_NOW}
                  />
                </div>
              )}
              {listPayments &&
                listPayments.map(payment => (
                  <>
                    {payment.default && (
                      <div className={styles.mainCard}>
                        <Badge label={intl.MAIN_ACCOUNT} />
                      </div>
                    )}
                    <div
                      className={`${styles.cardItem} ${styles[themes]} ${
                        payment.default ? styles.selectedCard : ''
                      }`}
                      key={`oct-card-item-${payment.payment_id}`}
                    >
                      <div className={styles.cardInfoItem}>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <InfoLabelValue
                              label={intl.CARD}
                              value={`****.****.****.${payment.card}`}
                            />
                          </div>
                          <div className="col-12 col-md-4">
                            <InfoLabelValue
                              label={intl.EXPIRATION_DATE}
                              value={formatCustomDate(
                                `${payment.month}/${payment.year}`,
                                'MM/YYYY',
                                idiom
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      {!payment.default && (
                        <div className={styles.footer}>
                          <>
                            {canChangePaymentMethod && (
                              <>
                                {isProfile && (
                                  <Button
                                    type="Tertiary"
                                    id={`removeCardPalla-${payment.payment_id}`}
                                    onClick={() => {
                                      deleteCardConfirmation(
                                        payment.payment_id
                                      );
                                    }}
                                    leadingIcon={
                                      <TrashIcon
                                        width={WIDTH_HEIGHT_ICONS}
                                        height={WIDTH_HEIGHT_ICONS}
                                      />
                                    }
                                    label={intl.BTN_REMOVE}
                                  />
                                )}
                                <Button
                                  id={`setMainCardPalla-${payment.payment_id}`}
                                  type="Tertiary"
                                  onClick={() => {
                                    changeMainCardConfirmation(
                                      payment.payment_id
                                    );
                                  }}
                                  leadingIcon={
                                    <SuccessIcon
                                      width={WIDTH_HEIGHT_ICONS}
                                      height={WIDTH_HEIGHT_ICONS}
                                    />
                                  }
                                  label={intl.BTN_SET_MAIN_CARD}
                                />
                              </>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  </>
                ))}
              {!listPayments ||
                (listPayments && listPayments.length === 0 && (
                  <p className={`${styles.emptyMessage} ${styles[themes]}`}>
                    {intl.YOU_DONT_HAVE_CARD_PALLA}
                  </p>
                ))}
            </>
          )}{' '}
        </>
      </div>
    </div>
  );
};

export default OCTChooseCard;
