export const LABEL_WITHDRAWN_AMOUNT = {
  en_US: 'Withdrawn amount',
  es_ES: 'Cantidad retirada',
  pt_BR: 'Valor retirado',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  es_ES: 'Moneda',
  pt_BR: 'Moeda',
};

export const LABEL_STOLEN_AMOUNT = {
  en_US: 'Stolen amount',
  es_ES: 'Cantidad robada',
  pt_BR: 'Valor roubado',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal.',
};
