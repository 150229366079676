export const COMMON_TEST = {
  en_US: 'common [en_US]',
  pt_BR: 'common [pt_BR]',
  es_ES: 'common [es_ES]',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};
