import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const BankInfoIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="m.2 11.1 6.7 2h.3v-2L1.7 9.5V6.3h20.6v3.2l-5.5 1.6v2h.1l6.9-2V4.3H.2z"
        style={{
          fill: config_icon.primary,
        }}
      />
      <path
        style={{
          fill: config_icon.primary,
        }}
        d="m23.8 13.1-1.5.4v6.4H1.7v-6.4l-1.5-.4v8.3h23.6z"
      />
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M9 11.3h6v2H9z"
      />
    </svg>
  );
};

export default BankInfoIcon;
