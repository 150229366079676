/* eslint-disable no-constant-condition */
import React, { useContext, useState } from 'react';
import styles from './ModalAddCardBin.module.scss';
import { Formik } from 'formik';
import { validationBin } from './validation';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import { cardsServices, productsAndBenefitsServices } from '../../services';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import ModalWithImage from '../ModalWithImage/ModalWithImage';
import ModalResult from '../ModalResult/ModalResult';
import { showModalAddCard } from '../ModalAddCard/functionShowModal';
import CheckBox from '../CheckBox/CheckBox';
import {
  dataLayerCredcardEventTrack,
  CREDCARD_ADDED,
} from '../../utils/GTM_helper';
import NavHashLink from '../NavHashLink/NavHashLink';
import { useHistory } from 'react-router-dom';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';
import { VCESValidation } from '../../utils/vces/vcesValidation';
import ModalInformation from '../ModalInformation/ModalInformation';

const ModalAddCardBin = props => {
  const { section, bin = false, changeModal } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const { state, actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { cards, user } = state;
  const { cn } = user.data.userInfo;
  const intl = translate(translations);
  const [isDuplicated, setIsDuplicated] = useState(false);
  const initialValues = {
    cardNumber: bin ? bin : '',
    main_card: true,
  };
  const validation = validationBin(intl);
  const history = useHistory();

  const addCard = values => {
    const bin = values.cardNumber.replace(/[*]+/g, '').replace(/[.]+/g, '');
    const duplicated = verifyDuplicated(bin);
    const newCard = {
      bin,
      is_preferred: values.main_card,
    };
    if (duplicated) {
      setIsDuplicated(true);
      return;
    }
    setIsDuplicated(false);
    addNewCard(newCard);
  };

  const verifyDuplicated = bin => {
    return cards.data.find(card => card.external_reference === bin);
  };

  const tryAgainAddCard = () => {
    showModalAddCard(actions, intl, 'Header', false, 2, changeModal);
  };

  const openModalSuccess = () => {
    return actions.modal.showModal(
      false,
      false,
      <ModalWithImage
        type="success"
        title={intl.TEXT_SUCCESS}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.OK}
          clickConfirm={() => {
            history.push('/dashboard');
            window.location.reload();
          }}
        />
      </ModalWithImage>,
      true,
      true
    );
  };

  const openModalError = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error_credit_card"
        title={intl.TEXT_ERROR_MESSAGE}
        subtitle={intl.SUBTITLE_ERROR_MESSAGE}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.TEXT_ERROR_TRY_AGAIN}
          clickConfirm={() => tryAgainAddCard()}
          contentRedirect={redirectHelpLink}
        />
      </ModalWithImage>,
      true
    );
  };

  const redirectHelpLink = (
    <NavHashLink to="/help#bin">
      <Button type="blueWithoutBorder" onClick={actions.modal.closeModal}>
        {intl.TEXT_ERROR_NEED_HELP}
      </Button>
    </NavHashLink>
  );

  const addNewCard = async newCard => {
    try {
      actions.modal.showLoading(intl.LOADING_NEW_CARD);
      const { is_preferred } = newCard;
      const {
        data: { external_reference },
      } = await cardsServices.addCard(cn, newCard, is_preferred);
      await actions.cards.reloadLoggedUserCards(false);
      if (external_reference && external_reference.length > 0) {
        actions.modal.hideLoading();

        const {
          data: { name, country },
        } = await productsAndBenefitsServices.getProductDetailByBinCode(
          external_reference,
          idiomForApi()
        );

        dataLayerCredcardEventTrack(CREDCARD_ADDED, name, section);

        openModalSuccess();
        trackEventUserAction(
          `#### (ADD CARD) ADDED CARD - SUCCESS - COUNTRY ${country}`,
          country
        );
      }
    } catch (error) {
      actions.modal.hideLoading();
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description.includes('VCES')
      ) {
        return VCESValidation(
          error.response.data.error_description,
          actions,
          translate
        );
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ==
          'Please enter a valid 16-digit card number'
      ) {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error_credit_card"
            message={intl.ENTER_VALID_16_DIGITS}
            textBtn={intl.TEXT_ERROR_TRY_AGAIN}
            clickBtn={() => tryAgainAddCard()}
          />,
          true,
          true
        );
        return;
      }
      openModalError();
      if (error.message === 'BIN number not found') {
        trackEventUserAction(
          '#### (COMPLETE REGISTER NON VALID BIN) USER  ####',
          null
        );
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={addCard}
    >
      {props => {
        const {
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;

        return (
          <div className={styles.formContent}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={`row ${styles.section}`}>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className={styles.inputAddCard}>
                    <Input
                      autoFocus
                      dataTestid="inputFormAddCard"
                      errorLeftAlign
                      removeBottonBarColor
                      className={styles.inputField}
                      labelFlex
                      name="cardNumber"
                      formikProps={props}
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label={`${
                        values.cardNumber
                          ? intl.TEXT_LABEL_ADD_CARD
                          : '____.____.__**.****'
                      }`}
                      mask="maskedCreditCard"
                    />
                  </div>
                </div>
              </div>
              {isDuplicated && (
                <div className={styles.alert}>
                  <span className={styles.alertMessage}>
                    {intl.TEXT_CARD_ALREADY_REGISTER}
                  </span>
                </div>
              )}
              <div className={styles.checkbox}>
                <CheckBox
                  value={values.main_card}
                  name="main_card"
                  onClick={value => setFieldValue('main_card', value)}
                  dataTestid="MainCardCheckbox"
                >
                  {intl.TEXT_MAIN_CARD}
                </CheckBox>
              </div>

              <span className={styles.disclaimer}>
                {intl.TEXT_WARNING_VISA}
              </span>

              <div className={styles.container}>
                <div className={styles.submitButton}>
                  <Button type="borderBlue">{intl.BTN_ADD_CARD}</Button>
                </div>
                <div className={styles.submitButton}>
                  <Button
                    type="blueWithoutBorder"
                    onClick={event => {
                      event.stopPropagation();
                      changeModal(1);
                    }}
                  >
                    {intl.TEXT_ADD_YOUR_CARD_PAN}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ModalAddCardBin;
