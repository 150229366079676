import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './CertificationArea.module.scss';
import CheckBox from '../../../../../../../../components/CheckBox/CheckBox';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const CertificationArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors, setFieldValue, setFieldTouched } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setConfirm = (index, value) => {
    setFieldValue(index + '.is_amount_due_certified', value);
    setFieldTouched(index + '.is_amount_due_certified');
  };

  const setConfirm2 = (index, value) => {
    setFieldValue(index + '.is_information_certified', value);
    setFieldTouched(index + '.is_information_certified');
  };

  return (
    <div className={`row ${styles.certificationArea}`}>
      <div className={`col-12`}>
        <GroupDivisor title={intl.RECOVERY_FUNDS_CERTIFICATES} />
      </div>

      <div className={`col-12 ${styles.legalAdviceAndConsent} `}>
        <div className={`${styles.checkbox} ${styles[themes]}`}>
          <CheckBox
            type="checkbox"
            value={values.certification.is_amount_due_certified}
            name={'certification.is_amount_due_certified'}
            touched={
              touched.certification &&
              touched.certification.is_amount_due_certified
            }
            error={
              errors.certification &&
              errors.certification.is_amount_due_certified
            }
            dataTestid="CheckboxAcceptIsAmountDueCertified"
            onClick={value => setConfirm('certification', value)}
          >
            {intl.CERTIFY_ANY_AMOUNT_BE_RECOVERED}
          </CheckBox>
        </div>
      </div>

      <div className="col-12">
        <Input
          label={intl.FINANCIAL_INSTITUTION}
          name="certification.financial_institution"
          type="text"
          formikProps={props}
          maxLength="100"
        />
      </div>

      <div className="col-12">
        <label className={`${styles.labelTitle} ${styles[themes]}`}>
          {intl.FROM_COMPANY_WE_AGREE}
        </label>
      </div>

      <div className={`col-12 ${styles.legalAdviceAndConsent}`}>
        <div className={`${styles.checkbox} ${styles[themes]}`}>
          <CheckBox
            type="checkbox"
            value={values.certification.is_information_certified}
            name={'certification.is_information_certified'}
            touched={
              touched.certification &&
              touched.certification.is_information_certified
            }
            error={
              errors.certification &&
              errors.certification.is_information_certified
            }
            dataTestid="CheckboxAuthorizeTermsSubmit"
            onClick={value => setConfirm2('certification', value)}
          >
            {intl.CERTIFY_THAT_ABOVE_INFORMATION}
          </CheckBox>
        </div>
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.occured_at}
          label={intl.DATE}
          name="certification.recovery_date"
          formikProps={props}
          maxDate={new Date()}
          setFieldTouched={setFieldTouched}
        />
      </div>
    </div>
  );
};

export default CertificationArea;
