import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const newProps = { ...props, ...rest };
        return (
          <Layout {...props.match.params}>
            <Component {...newProps} />
          </Layout>
        );
      }}
    />
  );
};

export default LayoutRoute;
