import moment from 'moment';

export const minimumDateNAL = (date, config) => {
  const minumumStringToInt = parseInt(
    config.schedule_rules.advance_days_cooldown,
    10
  );
  return new Date(date.setDate(date.getDate() + minumumStringToInt));
};

export const maximumDateNAL = (minDate, config) => {
  const date = new Date(minDate);
  const dateLimit = moment().add(1, 'years');
  const maximumDateStringToInt = parseInt(
    config.schedule_rules.schedule_period_length,
    10
  );
  const maximumDate = new Date(
    date.setDate(date.getDate() + maximumDateStringToInt)
  );

  if (moment(maximumDate).isSameOrAfter(dateLimit)) {
    return new Date(dateLimit.toDate());
  }

  return maximumDate;
};
