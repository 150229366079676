import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const purchasedItem = (claimDetails && claimDetails.purchased_item) || {};

  const itemName = purchasedItem.label || '-';

  const itemModel = purchasedItem.model_number || '-';

  const itemManufacturer = purchasedItem.manufacturer || '-';

  const advertisedPrice = (claimDetails && claimDetails.advertised_price) || {};

  const retailName = (advertisedPrice && advertisedPrice.retail_name) || '-';

  const publishingLocation =
    (advertisedPrice && advertisedPrice.publishing_location) || '-';

  const originalPrice =
    purchasedItem && purchasedItem.price && purchasedItem.price.value
      ? purchasedItem.price.value
      : '-';

  const newAdvertisePrice =
    advertisedPrice && advertisedPrice.price && advertisedPrice.price.value
      ? advertisedPrice.price.value
      : '-';

  const totalEstimationDamage =
    (claimDetails && claimDetails.amount_estimation_damage) || {};

  const totalPriceDifference = totalEstimationDamage.value
    ? totalEstimationDamage.value
    : '-';

  const currency = totalEstimationDamage.currency
    ? getCurrencyNameByInitials(totalEstimationDamage.currency, idiom)
    : '-';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.ITEM_PURCHASED} value={itemName} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.MODEL} value={itemModel} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.MANUFACTURER} value={itemManufacturer} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.NAME_STORE_WEBSITE} value={retailName} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.ADVERTISEMENT_LOCATION}
          value={publishingLocation}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.ORIGINAL_PURCHASE_PRICE}
          value={originalPrice}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.NEW_ADVERTISE_PRICE}
          value={newAdvertisePrice}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.TOTAL_PRICE_DIFFERENCE}
          value={totalPriceDifference}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_CURRENCY} value={currency} />
      </div>
    </>
  );
};

export default IncidentArea;
