import React, { useContext, useEffect } from 'react';

import * as translations from './intl';
import styles from './PaymentArea.module.scss';
import { IntlContext } from '../../../../../../../../intl';
import Select from '../../../../../../../../components/Select/Select';
import Input from '../../../../../../../../components/Input/Input';
import sanitize from '../../../../../../../../utils/sanitize';
import { safeGet } from '../../../../../../../../utils/object';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import ReservationQuestionArea from './ReservationQuestionArea/ReservationQuestionArea';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';

const PaymentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    handleBlur,
  } = props;

  const getInstallmentsPrice = () => {
    if (values.car_rental.payment.number_of_installments !== '0') {
      const priceValue =
        Number(values.car_rental.payment.amount.value) /
        (Number(values.car_rental.payment.number_of_installments) || 1);
      return priceValue.toFixed(2);
    }
    return '0';
  };

  const setCurrency = currency => {
    setFieldValue('car_rental.payment.amount.currency', currency);
    setFieldTouched('car_rental.payment.amount.currency', true);
  };

  useEffect(() => {
    const currency = values.amount_estimation_damage.currency;
    const getCurrency = currency ? currency : getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={`row ${styles.paymentArea}`}>
      <>
        <div className={`col-12 col-sm-12 col-md-4`}>
          <Calendar
            value={values.car_rental.payment.date}
            label={intl.LABEL_PURCHASE_DATE}
            name="car_rental.payment.date"
            onChange={handleChange}
            maxDate={new Date()}
            touched={
              touched &&
              touched.car_rental &&
              touched.car_rental.payment &&
              touched.car_rental.payment.date
            }
            error={
              errors &&
              errors.car_rental &&
              errors.car_rental.payment &&
              errors.car_rental.payment.date
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <Input
            value={values.car_rental.payment.amount.value}
            label={intl.LABEL_TOTAL_AMOUNT_INVOICE}
            name="car_rental.payment.amount.value"
            type="text"
            maskedMoney
            tooltipText={intl.TEXT_AMOUNT_DECIMAL}
            onChange={handleChange}
            touched={
              touched.car_rental &&
              touched.car_rental.payment &&
              touched.car_rental.payment.amount &&
              touched.car_rental.payment.amount.value
            }
            onBlur={handleBlur}
            error={
              errors.car_rental &&
              errors.car_rental.payment &&
              errors.car_rental.payment.amount &&
              errors.car_rental.payment.amount.value
            }
          />
        </div>

        <div className={`col-12 col-sm-12 col-md-4`}>
          <Select
            label={intl.LABEL_CURRENCY}
            value={values.car_rental.payment.amount.currency}
            name="car_rental.payment.amount.currency"
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
            touched={
              touched.car_rental &&
              touched.car_rental.payment &&
              touched.car_rental.payment.amount &&
              touched.car_rental.payment.amount.currency
            }
            onBlur={handleBlur}
            error={
              errors.car_rental &&
              errors.car_rental.payment &&
              errors.car_rental.payment.amount &&
              errors.car_rental.payment.amount.currency
            }
          />
        </div>
      </>
      <div className="col-12 d-flex p-0">
        <div className="col-12 col-sm-12 col-md-4">
          <Select
            label={intl.PAYMENT_METHOD}
            dangerouslySetInnerHTML={{
              __html: `<option value="">${intl.PAYMENT_METHOD}</option>
              <option value="cash">${intl.METHOD_CASH}</option>
              <option value="installment">${intl.METHOD_FINANCING}</option>`,
            }}
            name="car_rental.payment.paymentMethod"
            value={values.car_rental.payment.paymentMethod}
            onChange={e => {
              setFieldValue('car_rental.payment.number_of_installments', '0');
              setFieldTouched(
                'car_rental.payment.number_of_installments',
                false
              );
              handleChange(e);
            }}
            onBlur={handleBlur}
            touched={safeGet(touched, 'car_rental.payment.paymentMethod')}
            error={safeGet(errors, 'car_rental.payment.paymentMethod')}
          />
        </div>
        {values.car_rental.payment.paymentMethod === 'installment' && (
          <>
            <div className="col-12 col-sm-12 col-md-4">
              <Select
                label={intl.INSTALLMENTS_NUMBER}
                value={values.car_rental.payment.number_of_installments}
                name="car_rental.payment.number_of_installments"
                onChange={handleChange}
                onBlur={handleBlur}
                touched={safeGet(
                  touched,
                  'car_rental.payment.number_of_installments'
                )}
                error={safeGet(
                  errors,
                  'car_rental.payment.number_of_installments'
                )}
              >
                <option value="">{intl.INSTALLMENTS_NUMBER}</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">{intl.SIXTEEN_OR_MORE_INSTALLMENTS}</option>
              </Select>
            </div>
            <div className="col-12 col-sm-12 col-md-4">
              <Input
                label={intl.INSTALLMENTS_PRICE}
                name="car_rental.payment.installment_price"
                value={getInstallmentsPrice()}
                maskedMoney
                type="text"
                disabled
                error={safeGet(errors, 'car_rental.payment.installment_price')}
                touched={safeGet(
                  touched,
                  'car_rental.payment.installment_price'
                )}
              />
            </div>
          </>
        )}
      </div>

      <ReservationQuestionArea {...props} />
    </div>
  );
};

export default PaymentArea;
