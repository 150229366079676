import moment from 'moment';

export const getPrice = price => ({
  value: price && price.value ? price.value : '',
  currency: price && price.currency ? price.currency : '',
});

export const getOtherInsuranceCovering = otherInsuranceCovering => ({
  is_internal:
    otherInsuranceCovering &&
    otherInsuranceCovering.is_internal !== undefined &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.value
      ? String(otherInsuranceCovering.is_internal)
      : 'true',
  amount: getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
});

const getPersonName = (cardholder, affectedPerson) => {
  const person =
    affectedPerson && affectedPerson.person
      ? affectedPerson.person
      : cardholder;
  return {
    first_name: person && person.first_name ? person.first_name : '',
    last_name: person && person.last_name ? person.last_name : '',
  };
};

export const getAffectedPerson = (cardholder, affectedPerson) => ({
  relationship: 'Cardholder',
  relationshipCardholder: true,
  person: getPersonName(cardholder, affectedPerson),
});

const defaultItems = [
  {
    label: '',
    manufacturer: '',
    model_number: '',
    purchase_date: '',
    is_the_damage_visible: false,
    is_repairable: false,
    status: 'Damage',
    price: {
      value: '',
      currency: '',
    },
    total_invoice_amount: {
      value: '',
      currency: '',
    },
    amount_claimed: {
      value: '',
      currency: '',
    },
    other_insurance_covering: {
      is_internal: 'true',
      amount: {
        value: '',
        currency: '',
      },
    },
    paymentMethod: '',
    number_of_installments: '',
    installment_price: '',
  },
];
const getDefaultItems = () => [...defaultItems];

export const purchaseProtectionFormInitialValues = (
  data,
  idiom,
  loadedData
) => {
  const person = data && data.person ? data.person : '';
  const purchaseProtectionItems =
    loadedData && loadedData.purchase_protection_items
      ? loadedData.purchase_protection_items
      : getDefaultItems();
  const incident =
    loadedData && loadedData.incident ? loadedData.incident : null;
  const affectedPerson = loadedData && loadedData.affected_person;
  const totalAmountClaimed = loadedData && loadedData.total_amount_claimed;

  const formatPurchaseProtectionItems = items =>
    items.map(item => ({
      label: item.label || '',
      manufacturer: item.manufacturer || '',
      model_number: item.model_number || '',
      purchase_date: item.purchase_date
        ? new Date(moment(item.purchase_date, 'YYYY-MM-DD HH:mm:ss'))
        : '',
      is_the_damage_visible: item.is_the_damage_visible
        ? item.is_the_damage_visible
        : false,
      is_repairable: item.is_repairable ? item.is_repairable : false,
      status: item.status || 'Damage',
      price: getPrice(item && item.price),
      total_invoice_amount: getPrice(item && item.total_invoice_amount),
      amount_claimed: getPrice(item && item.amount_claimed),
      other_insurance_covering: getOtherInsuranceCovering(
        (item.other_insurance_covering && item.other_insurance_covering[0]) ||
          ''
      ),
      paymentMethod:
        item && item.number_of_installments > 0
          ? 'installment'
          : item && item.number_of_installments === 0
          ? 'cash'
          : '',
      number_of_installments: (item && item.number_of_installments) || 0,
      installment_price: (item && item.installment_price) || '',
    }));

  const getPurchaseProtectionItems = items =>
    purchaseProtectionItems.length === 0
      ? getDefaultItems()
      : formatPurchaseProtectionItems(items);

  const getIncident = incident => ({
    dateValue:
      incident && incident.date
        ? new Date(moment(incident.date, 'DD/MM/YYYY HH:mm'))
        : '',
    description: incident && incident.description ? incident.description : '',
    purchase_date_view: '',
  });

  return {
    purchase_protection_items: getPurchaseProtectionItems(
      purchaseProtectionItems
    ),
    incident: getIncident(incident),
    affected_person: getAffectedPerson(person, affectedPerson),
    total_amount_claimed: getPrice(totalAmountClaimed),
  };
};
