import moment from 'moment';

export const hotel_burglary_toApi = props => {
  let object = {};
  object.affected_person = props.values.incident.affected_person;
  object.incident = incidentHotelBurglary(props);
  object.travel_information = travelHotelBurglary(props);
  object.other_insurance_covering = coveringHotelBurglary(props);
  object.total_amount_claimed = calculateTotalAmountClaimed(props);
  return object;
};

export const travelHotelBurglary = props => {
  const travel_information = props.values.incident.travel_information;

  const from_date = moment(travel_information.from_date).format('YYYY-MM-DD');
  const to_date = moment(travel_information.to_date).format('YYYY-MM-DD');

  return {
    from_date,
    to_date,
  };
};

const build_concerned_items = props => {
  const incident = props.values.incident.incident;
  const items = incident.concerned_items;
  return items.map(item => {
    return {
      description: item.description,
      purchased_date: item.purchase_date,
      price: {
        value: item.price.value,
        currency: item.price.currency,
      },
    };
  });
};

export const incidentHotelBurglary = props => {
  const incident = props.values.incident.incident;
  const discovered_at = moment(incident.discovered_at).format('YYYY-MM-DD');
  const discovered_at_hour = moment(
    discovered_at + ' ' + incident.hour_discovered_at
  ).format('YYYY-MM-DD HH:mm:ss');
  const concerned_items = build_concerned_items(props);

  return {
    discovered_at: discovered_at_hour,
    description: incident.description,
    location: {
      country: incident.location.country,
      locality: incident.location.locality,
      kind: 'HOTEL',
    },
    concerned_items: concerned_items,
    kind: 'LOSS',
  };
};

export const coveringHotelBurglary = props => {
  const currency =
    props.values.incident.incident.concerned_items[0].price.currency;
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const hotel_indemnification =
    props.values.incident.other_insurance_covering.hotel_indemnification;
  const otherInsurance = [];
  if (other_insurance_covering.checked === 'true') {
    otherInsurance.push(
      otherInsuranceObject(other_insurance_covering, currency)
    );
  }

  if (hotel_indemnification.hotel_checked === 'true') {
    otherInsurance.push(otherInsuranceObject(hotel_indemnification, currency));
  }
  return otherInsurance;
};

export const otherInsuranceObject = (props, currency) => {
  return {
    is_internal: props.is_internal || false,
    description: props.description,
    amount: {
      value: parseFloat(props.value),
      currency: currency,
    },
  };
};

export const calculateTotalAmountClaimed = props => {
  let result = 0;
  props.values.incident.incident.concerned_items.map(item => {
    if (item.price.value) {
      result += Number(item.price.value);
    }
  });
  result -= Number(props.values.incident.other_insurance_covering.value);
  result -= Number(
    props.values.incident.other_insurance_covering.hotel_indemnification.value
  );
  const total = result > 0 ? result.toFixed(2) : '0.00';
  return {
    value: total,
    currency: props.values.incident.incident.concerned_items[0].price.currency,
  };
};
