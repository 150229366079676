import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import moment from 'moment';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../intl/idioms';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const ATMDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date, 'DD/MM/YYYY HH:mm').format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const incidentDate =
    incident && incident.date ? formatDateAndHour(incident.date) : '-';

  const incidentDescription =
    incident && incident.description ? incident.description : '-';

  const incidentLocation =
    incident && incident.location ? incident.location : {};

  const claimCountry =
    incidentLocation && incidentLocation.country
      ? incidentLocation.country
      : '-';

  const state =
    incidentLocation && incidentLocation.state ? incidentLocation.state : '-';

  const city =
    incidentLocation && incidentLocation.locality
      ? incidentLocation.locality
      : '-';

  const withdrawnAmount =
    incident && incident.withdrawn_amount && incident.withdrawn_amount.value
      ? incident.withdrawn_amount.value
      : '-';

  const withdrawnCurrency =
    incident && incident.withdrawn_amount && incident.withdrawn_amount.currency
      ? incident.withdrawn_amount.currency
      : '-';

  const stolenAmount =
    incident && incident.stolen_amount && incident.stolen_amount.value
      ? incident.stolen_amount.value
      : '-';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue label={intl.INCIDENT_DATE} value={incidentDate} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.INCIDENT_DESCRIPTION}
          value={incidentDescription}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.INCIDENT_LOCATION}
          value={`${claimCountry} - ${state} - ${city}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.WITHDRAWN_AMOUNT}
          value={`${formatMonetaryMask(
            withdrawnAmount,
            idiomForApi()
          )} ${withdrawnCurrency}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.STOLEN_AMOUNT}
          value={`${formatMonetaryMask(
            stolenAmount,
            idiomForApi()
          )} ${withdrawnCurrency} `}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency} `}
          />
        </div>
      )}
    </div>
  );
};

export default ATMDetail;
