import React from 'react';
import { Redirect } from 'react-router-dom';
import { isAndroid, isIOS } from 'react-device-detect';
import { dataLayerAppRedirectTrack } from '../../utils/GTM_helper';

const DownloadFromStore = () => {
  dataLayerAppRedirectTrack();

  if (isAndroid) {
    window.location.replace(process.env.REACT_APP_ANDROID_STORE.toString());
    return null;
  }

  if (isIOS) {
    window.location.replace(process.env.REACT_APP_IOS_STORE.toString());
    return null;
  }

  return <Redirect to="/download-app" />;
};

export default DownloadFromStore;
