import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './IncidentArea.module.scss';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import RadioButtonError from '../../../../../../../../components/RadioButtonError/RadioButtonError';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, errors, setFieldTouched } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.QUESTION_RESULT_BANKRUPTY_OR_INSOLVENCY}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_RESULT_BANKRUPTY_OR_INSOLVENCY}
              defaultChecked={
                values.incident.question_2_view_is_dead === 'true'
              }
              text={intl.YES}
              name="incident.question_2_view_is_dead"
              value={true}
              onChange={handleChange}
              dataTestid="radioBankruptyYes"
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_RESULT_BANKRUPTY_OR_INSOLVENCY}
              defaultChecked={
                values.incident.question_2_view_is_dead === 'false'
              }
              text={intl.NO}
              name="incident.question_2_view_is_dead"
              value={false}
              onChange={handleChange}
              dataTestid="radioBankruptyNo"
            />
            <RadioButtonError
              touched={
                touched.incident && touched.incident.question_2_view_is_dead
              }
              error={errors.incident && errors.incident.question_2_view_is_dead}
            />
          </div>
        </div>
      </div>

      {values.incident.question_2_view_is_dead === 'true' && (
        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            label={intl.DATE_REPORTED}
            name="incident.bankruptcy_declaration_date"
            maxDate={new Date()}
            setFieldTouched={setFieldTouched}
            formikProps={props}
          />
        </div>
      )}

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.QUESTION_COMPANY_RESPONSIBLE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_COMPANY_RESPONSIBLE}
              defaultChecked={
                values.incident.is_the_company_responsible === 'true'
              }
              text={intl.YES}
              name="incident.is_the_company_responsible"
              value={true}
              onChange={handleChange}
              dataTestid="radioCompanyResponsibleYes"
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_COMPANY_RESPONSIBLE}
              defaultChecked={
                values.incident.is_the_company_responsible === 'false'
              }
              text={intl.NO}
              name="incident.is_the_company_responsible"
              value={false}
              onChange={handleChange}
              dataTestid="radioCompanyResponsibleNo"
            />
            <RadioButtonError
              touched={
                touched.incident && touched.incident.is_the_company_responsible
              }
              error={
                errors.incident && errors.incident.is_the_company_responsible
              }
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle}  ${styles[themes]}`}>
              {intl.QUESTION_CARDHOLDER_RESPONSIBLE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_CARDHOLDER_RESPONSIBLE}
              defaultChecked={
                values.incident.is_the_cardholder_responsible === 'true'
              }
              text={intl.YES}
              name="incident.is_the_cardholder_responsible"
              value={true}
              onChange={handleChange}
              dataTestid="radioCardholderResponsibleYes"
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_CARDHOLDER_RESPONSIBLE}
              defaultChecked={
                values.incident.is_the_cardholder_responsible === 'false'
              }
              text={intl.NO}
              name="incident.is_the_cardholder_responsible"
              value={false}
              onChange={handleChange}
              dataTestid="radioCardholderResponsibleNo"
            />
            <RadioButtonError
              touched={
                touched.incident &&
                touched.incident.is_the_cardholder_responsible
              }
              error={
                errors.incident && errors.incident.is_the_cardholder_responsible
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentArea;
