import { useState, useEffect, useContext } from 'react';
import { loadData } from './loadData';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import Form from './Form/Form';
import Loading from '../../../../components/Loading/Loading';
import styles from './TeleconsultationForm.module.scss';
import LoadingError from '../../../../components/LoadingError/LoadingError';

export const loading = (
  <div className={styles.loadingContainer}>
    <Loading surface="Inverse" />
  </div>
);

export const error = (
  <div className={styles.loadingContainer}>
    <LoadingError color="#fff" />
  </div>
);

const TeleconsultationFormFill = props => {
  const { setStep } = props;
  const [data, setData] = useState('LOADING');
  const { utils } = useContext(StateContext);
  const cn = utils.getCn();

  useEffect(() => {
    loadData(cn, setData);
  }, []);

  const isLoading = data === 'LOADING';
  const isError = data === 'ERROR';
  const successLoad = !isLoading && !isError;

  return (
    <div id="mainContent">
      {isLoading && loading}
      {isError && error}
      {successLoad && <Form loadedData={data} setStep={setStep} />}
    </div>
  );
};

export default TeleconsultationFormFill;
