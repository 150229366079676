export const COVERAGE_PRODUCTS_TITLE = {
  en_US: cardName => '',
  es_ES: cardName =>
    `Conozca nuestros planes de extensión para tu tarjeta Visa ${cardName}`,
  pt_BR: cardName => '',
};

export const BUTTON_COTIZAR = {
  en_US: '',
  es_ES: 'Cotizar cobertura extendida con AXA',
  pt_BR: '',
};
