export const SUPORT_TITLE = {
  en_US: 'Help and support',
  es_ES: 'Ayuda y soporte',
  pt_BR: 'Ajuda e suporte',
};

export const SUPORT_SUBTITLE = {
  en_US:
    'Need help? Browse these frequently asked questions or get in touch with us.',
  es_ES: '¿Necesita ayuda? Conoce las preguntas frecuentes o contáctanos.',
  pt_BR:
    'Precisa de ajuda? Navegue pelas perguntas frequentes ou entre em contato conosco.',
};

export const SUPORT_CHAT_US = {
  en_US: 'Chat to us',
  es_ES: 'Habla con nosotros',
  pt_BR: 'Converse conosco',
};

export const SUPORT_EMAIL = {
  en_US: 'E-mail',
  es_ES: 'E-mail',
  pt_BR: 'E-mail',
};

export const FACEBOOK_MESSENGER = {
  en_US: 'Facebook messenger',
  es_ES: 'Facebook messenger',
  pt_BR: 'Messenger do Facebook',
};
