import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/ExtendedWarranty/consts';
import { formatCustomDate } from '../../../../../../utils/date';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const affectedPerson =
    claimDetails && claimDetails.affected_person
      ? claimDetails.affected_person
      : {};

  const affectedPersonRelationship =
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';

  const person =
    affectedPerson && affectedPerson.person ? affectedPerson.person : {};

  const deceasedAt =
    affectedPerson && affectedPerson.deceased_at
      ? formatCustomDate(
          affectedPerson.deceased_at,
          'YYYY-MM-DD',
          idiom,
          country
        )
      : '-';

  const incidentKind =
    claimDetails && claimDetails.incident && claimDetails.incident.kind
      ? claimDetails.incident.kind
      : '-';

  const incidentTypeLabel =
    incidentKind === 'DEATH' ? intl.OPTION_DEATH : intl.OPTION_ASSAULT;

  const personFirstName = person && person.first_name ? person.first_name : '-';

  const personLastName = person && person.last_name ? person.last_name : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={incidentTypeLabel} value={incidentTypeLabel} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={affectedPersonRelationship}
        />
      </div>
      {incidentKind === 'DEATH' ? (
        <div className="col-sm-12 col-md-4">
          <InfoLabelValue label={intl.DEATH_DATE} value={deceasedAt} />
        </div>
      ) : (
        ''
      )}
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_NAME_AFFECTED}
          value={personFirstName}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME_AFFECTED}
          value={personLastName}
        />
      </div>
    </>
  );
};

export default PersonalArea;
