import React, { useContext, useState, useMemo, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import moment from 'moment';
import { nurseService, userServices } from '../../../../../services';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import { IntlContext } from '../../../../../intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import styles from './styles.module.scss';
import Calendar from '../../../../../components/Calendar/Calendar';
import Input from '../../../../../components/Input/Input';
import Select from '../../../../../components/Select/Select';
import RadioButtonError from '../../../../../components/RadioButtonError/RadioButtonError';
import * as translations from './intl';
import { phonePrefixToInitials } from '../../../../../intl/utils';

import LoadingCircle from '../../../../../components/Loading/Loading';
import { safeGet } from '../../../../../utils/object';
import { NALFormInitialValues } from './initialValuesNAL';
import { NurseLineSchema } from './validationSchema';
import { newFormatForm } from './submit/submitNAL';
import ScheduleListNAL from './scheduleList/ScheduleListNAL';
import { checkErrorsOnForm, checkErrorsOnSession } from './checkErrorsOnForm';
import Legend from './legend/Legend';
import ModalAppointmentSuccessNAL from './ModalAppointmentSuccess/ModalAppointmentSuccessNAL';
import { maximumDateNAL, minimumDateNAL } from './validationDateMinMaxNAL';
import { listTimeZones } from '../../../../../intl/timezones';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import QuestionsNAL from './questionsNAL/QuestionsNal';
import ScheduleInitializer from './ScheduleInitializer';
import PhoneForm from '../../../../../components/PhoneForm';

const ERROR_MANY_APPOINTMENTS_TO_SAME_HOUR_AND_DATE =
  'many appointments to same hour and date';
const loadSchedules = async (
  date,
  timezone,
  setSchedule,
  intl,
  alert,
  setLoading
) => {
  try {
    setLoading(true);
    const obj = {
      date: moment(date).format('YYYY-MM-DD'),
      timezone: timezone,
    };
    const schedules = await nurseService.getSchedule(obj);
    const schedulesWithSelected = schedules.data.times
      .map(time => {
        return { ...time, selected: false };
      })
      .sort((a, b) => (a.time > b.time ? 1 : -1));
    setSchedule(schedulesWithSelected);
  } catch (error) {
    alert.showError(intl.UNEXPECTED_ERROR_OCCURRED);
  }
  setLoading(false);
};

const FormNAL = props => {
  const { history, acceptChecked, loadedData, setShowForm, configAppointment } =
    props;
  const { actions, utils } = useContext(StateContext);
  const { alert } = actions;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const [schedule, setSchedule] = useState([]);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const [listAccordeon, setListAccordeon] = useState({
    pacient: false,
    appointment: true,
    questions: false,
  });
  const buttonRef = React.useRef();
  const validationSchema = useMemo(() => NurseLineSchema(intl), [intl]);
  const [schemas] = useState(validationSchema);
  const initialValues = useMemo(
    () =>
      NALFormInitialValues(
        idiom,
        country,
        acceptChecked,
        loadedData,
        intl,
        configAppointment
      ),
    [idiom]
  );
  const formRef = React.useRef(null);

  const accordeonRefs = {
    pacient: React.useRef(),
    appointment: React.useRef(),
    questions: React.useRef(),
  };

  const openTargetAcordeon = async (currentAcordeon, targetAcordeon) => {
    const areFormsOk = await checkErrorsOnSession(
      buttonRef,
      schemas,
      values,
      accordeonRefs,
      targetAcordeon,
      currentAcordeon,
      formRef.current
    );

    if (areFormsOk) {
      formRef.current.setErrors({ errors: [] });
      formRef.current.setTouched({}, false);
      setStatusAccordeon(targetAcordeon);
    }
  };

  const setStatusAccordeon = selected => {
    const newListAccordeon = { ...listAccordeon };
    Object.keys(newListAccordeon).map(key => {
      return (newListAccordeon[key] = false);
    });
    setListAccordeon({
      ...newListAccordeon,
      [selected]: !listAccordeon[selected],
    });
  };

  useEffect(() => {
    const maxDate = maximumDateNAL(
      minimumDateNAL(new Date(), configAppointment),
      configAppointment
    );

    if (new Date() >= maxDate) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.LIMIT_DATE}
          clickBtn={() => {
            history.push('/your-benefits');
            actions.modal.closeModal();
          }}
        />,
        true,
        false
      );
    }
  }, []);

  const getListSchedule = (date, timezone) => {
    if (date !== '') {
      const date_current = moment().startOf('day');
      const dateSelectedIsSameAfterDateCurrent =
        moment(date).isSameOrAfter(date_current);
      if (dateSelectedIsSameAfterDateCurrent) {
        loadSchedules(date, timezone, setSchedule, intl, alert, setLoading);
      } else {
        setSchedule([]);
      }
    }
  };

  const submitButtonForm = async () => {
    const areFormsOk = await checkErrorsOnForm(
      buttonRef,
      schemas,
      values,
      accordeonRefs
    );

    if (areFormsOk) {
      await submitForm(values);
    }
  };
  const submitForm = async values => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(intl.TEXT_CREATING);
      const userResponse = await userServices.getCHInfo(utils.getCn());
      const form = await newFormatForm(
        values,
        intl,
        userResponse.data,
        utils.getCountry()
      );
      await nurseService.postAppointment(form, idiomForApi());

      actions.loadingFullScreen.hideLoadingFullScreen();

      openModalSucess(
        form.appointment_details.date.substring(10, 16),
        form.appointment_details.date,
        form.appointment_details.timezone,
        idiom
      );
    } catch (error) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ===
          ERROR_MANY_APPOINTMENTS_TO_SAME_HOUR_AND_DATE
      ) {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_MANY_APPOINTMENTS_TO_SAME_HOUR}
          />,
          true
        );
      } else {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
          />,
          true
        );
      }
    }
  };

  const openModalSucess = (time, date, timezone, idiom) => {
    actions.modal.showModal(
      false,
      false,
      <ModalAppointmentSuccessNAL
        time={time}
        date={date}
        timezone={timezone}
        idiom={idiom}
        history={history}
        actions={actions}
      />,
      true,
      false
    );
  };

  return (
    <>
      <div
        className={styles.formNurseLine}
        data-testid="ClaimFillFormNurseLine"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
          ref={formRef}
        >
          {props => {
            const {
              values,
              handleSubmit,
              setFieldTouched,
              handleChange,
              touched,
              errors,
              handleBlur,
              setFieldValue,
            } = props;
            setValues(values);
            const renderSchedule = listSchedule => {
              return (
                <ScheduleListNAL
                  schedule={listSchedule}
                  setSchedule={setSchedule}
                  {...props}
                />
              );
            };

            const listSchedule = renderSchedule(schedule);

            const changePrefix = value => {
              const prefix = phonePrefixToInitials(value);
              if (prefix) {
                setFieldValue(
                  'pacient.phone_international_prefix',
                  `+${prefix}`
                );
                setFieldValue('pacient.phone_number', '');
              }
            };

            return (
              <form
                id="mainContent"
                onSubmit={handleSubmit}
                data-testid="FullFormClaimCovid"
              >
                <button
                  ref={buttonRef}
                  type="submit"
                  style={{ display: 'none' }}
                ></button>
                <ScheduleInitializer
                  formikProps={props}
                  getListSchedule={getListSchedule}
                />
                <AcordeonSession
                  number={1}
                  accordeon="appointment"
                  status={listAccordeon['appointment']}
                  setStatus={() => setStatusAccordeon('appointment')}
                  startsOpened
                  title={intl.TITLE_SCHEDULING}
                  openButtonRef={accordeonRefs.appointment}
                  next={async e => {
                    e.preventDefault();
                    openTargetAcordeon('appointment', 'pacient');
                  }}
                  back={() => setShowForm(false)}
                >
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <div className="col-sm-12">
                        <Calendar
                          minDate={minimumDateNAL(
                            new Date(),
                            configAppointment
                          )}
                          maxDate={maximumDateNAL(
                            minimumDateNAL(new Date(), configAppointment),
                            configAppointment
                          )}
                          value={values.appointment.date}
                          label={intl.SCHEDULING_DATE}
                          name="appointment.date"
                          dataTestid="InputDateAppointment"
                          onChange={event => {
                            const value =
                              event && event.target && event.target.value;
                            getListSchedule(value, values.appointment.timezone);
                            setFieldValue('appointment.time', '');
                            formRef.current.setErrors({ errors: [] });
                            formRef.current.setTouched({}, false);
                            handleChange(event);
                          }}
                          touched={safeGet(touched, 'appointment.date')}
                          onBlur={handleBlur}
                          error={safeGet(errors, 'appointment.date')}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>

                      <div className="col-sm-12">
                        <Select
                          label={intl.TIMEZONE}
                          value={values.appointment.timezone}
                          name="appointment.timezone"
                          dataTestid="InputCovidCHPhoneType2"
                          onChange={event => {
                            const value =
                              event && event.target && event.target.value;
                            if (values.appointment.date === '') {
                              setFieldTouched('appointment.date', true);
                            }
                            getListSchedule(values.appointment.date, value);
                            setFieldValue('appointment.time', '');
                            formRef.current.setErrors({ errors: [] });
                            formRef.current.setTouched({}, false);
                            handleChange(event);
                          }}
                          touched={safeGet(touched, 'appointment.timezone')}
                          onBlur={handleBlur}
                          error={safeGet(errors, 'appointment.timezone')}
                        >
                          <>
                            <option value="" key="empty">
                              {intl.TIMEZONE}
                            </option>
                            {listTimeZones.map((zone, index) => {
                              return (
                                <option
                                  data-testid={`optionsTimezone-${index}`}
                                  value={zone.timezone}
                                  key={index}
                                >
                                  {zone[idiom]}
                                </option>
                              );
                            })}
                          </>
                        </Select>
                      </div>
                    </div>

                    <div className="col-lg-6 col-sm-12">
                      <div className="col-12">
                        {!loading ? (
                          <div>
                            <label className={styles.labelTitle}>
                              {intl.AVAILABLE_TIMES}
                            </label>
                            <Legend />
                            <RadioButtonError
                              touched={
                                touched.appointment && touched.appointment.time
                              }
                              error={
                                errors.appointment && errors.appointment.time
                              }
                            />
                            <div
                              className={styles.availableTimes}
                              id={'divBoxSchedule'}
                            >
                              <div className={styles.ButtonSchedulelist}>
                                {listSchedule}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <LoadingCircle dataTestId="claimTermsAndConditionsLoading" />
                        )}
                      </div>
                    </div>
                  </div>
                </AcordeonSession>

                <AcordeonSession
                  number={2}
                  accordeon="pacient"
                  title={intl.CONTACT_INFO}
                  openButtonRef={accordeonRefs.pacient}
                  status={listAccordeon['pacient']}
                  setStatus={() => {
                    formRef.current.setErrors({ errors: [] });
                    formRef.current.setTouched({}, false);
                    setStatusAccordeon('pacient');
                  }}
                  next={async e => {
                    e.preventDefault();
                    openTargetAcordeon('pacient', 'questions');
                  }}
                  back={async e => {
                    e.preventDefault();
                    openTargetAcordeon('pacient', 'appointment');
                  }}
                >
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <Input
                        value={values.pacient.name}
                        label={intl.LABEL_NAME}
                        name="pacient.name"
                        dataTestid="InputCovidName"
                        type="text"
                        onChange={handleChange}
                        touched={safeGet(touched, 'pacient.name')}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'pacient.name')}
                        maxLength="40"
                      />
                    </div>

                    <div className="col-lg-6 col-sm-6">
                      <Input
                        value={values.pacient.last_name}
                        label={intl.LABEL_LAST_NAME}
                        name="pacient.last_name"
                        dataTestid="InputCovidLastName"
                        type="text"
                        onChange={handleChange}
                        touched={safeGet(touched, 'pacient.last_name')}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'pacient.last_name')}
                        maxLength="80"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <Input
                        value={values.pacient.email}
                        label={intl.LABEL_EMAIL}
                        name="pacient.email"
                        dataTestid="InputCovidEmail"
                        type="text"
                        onChange={handleChange}
                        touched={safeGet(touched, 'pacient.email')}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'pacient.email')}
                        maxLength="255"
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <Select
                        label={intl.COUNTRY_PHONE_LABEL}
                        name="appointment.country"
                        dataTestid="SelectCountryAppointment"
                        dangerouslySetInnerHTML={{
                          __html: `<option value="">${intl.COUNTRY_PHONE_LABEL}</option>${intl.COUNTRY_OPTIONS}`,
                        }}
                        value={values.appointment.country}
                        onChange={event => {
                          changePrefix(event.target.value);
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'appointment.country')}
                        touched={safeGet(touched, 'appointment.country')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <Input
                        value={values.appointment.state}
                        name="appointment.state"
                        label={intl.TEXT_NUMBER_STATE}
                        dataTestid="InputStateCovid"
                        type="text"
                        onChange={handleChange}
                        touched={safeGet(touched, 'appointment.state')}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'appointment.state')}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <Input
                        value={values.appointment.city}
                        name="appointment.city"
                        label={intl.TEXT_NUMBER_CITY}
                        dataTestid="InputCityCovid"
                        type="text"
                        onChange={handleChange}
                        touched={safeGet(touched, 'appointment.city')}
                        onBlur={handleBlur}
                        error={safeGet(errors, 'appointment.city')}
                      />
                    </div>
                  </div>
                  <PhoneForm
                    title={intl.TEXT_PHONE_NUMBER}
                    formikProps={props}
                    inputNames={{
                      phoneType: 'pacient.phone_type',
                      countryCode: 'pacient.phone_international_prefix',
                      phoneNumber: 'pacient.phone_number',
                    }}
                    testIds={{
                      phoneType: 'InputCovidCHPhoneType',
                      countryCode: 'InputPrefixCovid',
                      phoneNumber: 'InputClaiCHNumber',
                    }}
                  />
                </AcordeonSession>

                <AcordeonSession
                  number={3}
                  accordeon="questions"
                  title={intl.MOST_INTERESTED}
                  openButtonRef={accordeonRefs.questions}
                  status={listAccordeon['questions']}
                  setStatus={() => {
                    formRef.current.setErrors({ errors: [] });
                    formRef.current.setTouched({}, false);
                    setStatusAccordeon('questions');
                  }}
                  back={async e => {
                    e.preventDefault();
                    openTargetAcordeon('questions', 'pacient');
                  }}
                  next={async () => {
                    submitButtonForm();
                  }}
                  txtBtnNext={intl.BTN_CREATE_APPOINTMENT}
                >
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      <QuestionsNAL
                        values={values}
                        {...props}
                        questions={values.questions.questions}
                      />
                    </div>
                  </div>
                </AcordeonSession>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default withRouter(FormNAL);
