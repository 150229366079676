/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import * as constants from './consts';

export default intl => {
  let object = {};

  object.affected_person = affectedPersonSchema(intl);
  object.incident = incidentSchema(intl);
  object.amount_claimed = amountSchema(intl);
  object.total_amount_claimed = amountTotalScchema(intl);
  object.other_insurance_covering = otherInsuranceSchema(intl);
  object.purchase_protection_items = itensSchema(intl);

  return Yup.object().shape(object);
};

const itensSchema = intl => {
  const expenses = Yup.array().of(
    Yup.object().shape({
      label: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      price: Yup.object().shape({
        currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    })
  );

  return expenses;
};

const amountSchema = intl => {
  const amountDamage = Yup.object().shape({
    value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return amountDamage;
};

const amountTotalScchema = intl => {
  const amountDamage = Yup.object().shape({
    value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return amountDamage;
};

const incidentSchema = intl => {
  return Yup.lazy(value => {
    let incident = {};
    incident.dateValue = Yup.date()
      .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED);

    incident.description = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255);
    return Yup.object().shape({
      ...incident,
    });
  });
};

const otherInsuranceSchema = intl => {
  return Yup.lazy(values => {
    return Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      amount: Yup.object().shape({
        value: Yup.string().when(
          ['is_internal'],
          {
            is: is_internal => {
              return values.is_internal === 'true';
            },
            then: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          },
          ['is_internal']
        ),
        currency: Yup.string().when(
          ['is_internal'],
          {
            is: is_internal => {
              return values.is_internal === 'true';
            },
            then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          },
          ['is_internal']
        ),
      }),
    });
  });
};

const affectedPersonSchema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};
    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });

    affectedPerson.person = Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    });
    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};
