import React, { useContext } from 'react';

import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from '../BankArea.module.scss';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../../intl';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import IntermediaryFields from './IntermediaryFields/IntermediaryFields';

const IntermediaryArea = props => {
  const { values, handleChange } = props;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const intermediaryView =
    values.IntermediaryBankAccount &&
    values.IntermediaryBankAccount.bankIntermediaryView === 'yes';

  return (
    <>
      <div className="row">
        <div
          className={`${styles.labelOwnerCardHolder} ${styles[theme]} ${styles.titleRadio} col-lg-12 col-sm-12`}
        >
          {intl.TXT_INTERNATIONAL_TRANSFER}
          <Tooltip text={intl.TOOLTIP_INFO_BANK_INTERMEDIARY} />
        </div>

        <div className={`${styles.optionsArea} col-12`}>
          <div className={`${styles.fontGray12} row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                label={intl.TXT_INTERNATIONAL_TRANSFER}
                describe={intl.DESCRIBE_INTERNATIONAL_TRANSFER}
                text={intl.TXT_YES}
                name="IntermediaryBankAccount.bankIntermediaryView"
                value="yes"
                checked={intermediaryView}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={`${styles.fontGray12} row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                label={intl.TXT_INTERNATIONAL_TRANSFER}
                text={intl.TXT_NO}
                name="IntermediaryBankAccount.bankIntermediaryView"
                value="no"
                checked={!intermediaryView}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      {intermediaryView && <IntermediaryFields {...props} />}
    </>
  );
};

export default IntermediaryArea;
