export const INCIDENT_DATE = {
  en_US: 'Incident date',
  en_ES: 'Fecha del incidente',
  pt_BR: 'Data do incidente',
};

export const INCIDENT_DESCRIPTION = {
  en_US: 'Description of incident',
  en_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const INCIDENT_LOCATION = {
  en_US: 'Incident location',
  en_ES: 'Dirección del incidente',
  pt_BR: 'Endereço do incidente',
};

export const WITHDRAWN_AMOUNT = {
  en_US: 'Withdrawn amount',
  en_ES: 'Cantidad retirada',
  pt_BR: 'Valor retirado',
};

export const STOLEN_AMOUNT = {
  en_US: 'Stolen amount',
  en_ES: 'Cantidad robada',
  pt_BR: 'Valor roubado',
};
