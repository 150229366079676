import React, { useContext } from 'react';
import styles from '../../../IncidentArea.module.scss';
import RadioButton from '../../../../../../../../../../../components/RadioButton/RadioButton';
import Input from '../../../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../../../components/Select/Select';
import * as translations from '../../../intl';
import { IntlContext } from '../../../../../../../../../../../intl';
import { ThemeContext } from '../../../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../../../../utils/sanitize';

const ItemOtherCoveringArea = props => {
  const {
    index,
    handleChange,
    values,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const subTotal = (value, index) => {
    let total = value * -1;
    if (values.purchase_protection_items[index].price.value) {
      total = values.purchase_protection_items[index].price.value - value;
    }
    setFieldValue(
      `purchase_protection_items[${index}].amount_claimed.value`,
      total.toFixed(2)
    );
  };
  const setEmptyOtherInsurance = index => {
    if (
      values.purchase_protection_items[index].other_insurance_covering.amount
        .value
    ) {
      const valueOtherCovering = Number(
        values.purchase_protection_items[index].other_insurance_covering.amount
          .value
      );
      const amount = Number(
        values.purchase_protection_items[index].amount_claimed.value
      );
      setFieldValue(
        `purchase_protection_items[${index}].amount_claimed.value`,
        valueOtherCovering + amount
      );
    }
    setFieldValue(
      `purchase_protection_items[${index}].other_insurance_covering.amount.value`,
      ''
    );
    setFieldValue(
      `purchase_protection_items[${index}].other_insurance_covering.amount.currency`,
      ''
    );
  };
  return (
    <>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={false}
              text={intl.TEXT_YES}
              name={`purchase_protection_items[${index}].other_insurance_covering.is_internal`}
              dataTestid="InputOtherInsuranceCoveringYes"
              value={true}
              onChange={e => {
                setEmptyOtherInsurance(index);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={true}
              text={intl.TEXT_NO}
              name={`purchase_protection_items[${index}].other_insurance_covering.is_internal`}
              dataTestid="InputOtherInsuranceCoveringNo"
              value={false}
              onChange={e => {
                setEmptyOtherInsurance(index);
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.purchase_protection_items[index].other_insurance_covering &&
          values.purchase_protection_items[index].other_insurance_covering
            .is_internal !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivOtherInsuranceCoveringField"
      >
        <Input
          value={
            values.purchase_protection_items[index].other_insurance_covering &&
            values.purchase_protection_items[index].other_insurance_covering
              .amount.value
          }
          label={intl.LABEL_AMOUNT_PAID}
          name={`purchase_protection_items[${index}].other_insurance_covering.amount.value`}
          dataTestid="InputOtherInsuranceCoveringValue"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          onChange={event => {
            subTotal(event.target.value, index);
            handleChange(event);
          }}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].other_insurance_covering &&
            touched.purchase_protection_items[index].other_insurance_covering
              .amount &&
            touched.purchase_protection_items[index].other_insurance_covering
              .amount.value
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].other_insurance_covering &&
            errors.purchase_protection_items[index].other_insurance_covering
              .amount &&
            errors.purchase_protection_items[index].other_insurance_covering
              .amount.value
          }
        />
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.purchase_protection_items[index].other_insurance_covering &&
          values.purchase_protection_items[index].other_insurance_covering
            .is_internal !== 'true'
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items[index].price.currency}
          disabled
          name={`purchase_protection_items[${index}].other_insurance_covering.amount.currency`}
          dataTestid="InputOtherInsuranceCoveringCurrency"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].other_insurance_covering &&
            errors.purchase_protection_items[index].other_insurance_covering
              .amount &&
            errors.purchase_protection_items[index].other_insurance_covering
              .amount.currency
          }
        />
      </div>
    </>
  );
};
export default ItemOtherCoveringArea;
