export default (env, axios, errorHandler) => ({
  getDevices: async (customer_id, idiom = 'en-US') => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/communication_devices`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getDevices', {
      url,
      customer_id,
      idiom,
    });
  },
  createDevice: async (customer_id, data, idiom = 'en-US') => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/communication_devices`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.post(url, data, { headers }), 'createDevice', {
      url,
      data,
      customer_id,
      idiom,
    });
  },
  updateDevice: async (customer_id, communication_device_id, data) => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/notifications/${communication_device_id}`;
    return errorHandler(axios.patch(url, data), 'updateUserNotification', {
      customer_id,
      url,
      communication_device_id,
      data,
    });
  },
  deleteDevice: async (customer_id, communication_device_id) => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/communication_devices/${communication_device_id}`;
    return errorHandler(axios.delete(url), 'deleteDevice', {
      url,
      customer_id,
      communication_device_id,
    });
  },
});
