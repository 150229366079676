import React, { useState, useEffect, useContext } from 'react';
import styles from './ListBenefits.module.scss';
import { productsAndBenefitsServices } from '../../../services/index';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import Loading from '../../../components/Loading/Loading';
import BenefitItem from './BenefitItem/BenefitItem';
import { SPECIAL_BENEFIS } from '../../../utils/benefitCategory';

const ListBenefits = props => {
  const { cardSelected } = props;
  const [specialBenefits, setSpecialBenefits] = useState(null);
  const [loading, setLoading] = useState(true);
  const { idiomForApi, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  const isLogged = utils.isUserLogged();

  const productBinOrPan = isLogged
    ? utils.getBin(true)
    : cardSelected && cardSelected.product_id;

  const loadSpecialBenefits = async () => {
    setLoading(true);
    try {
      const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
        productBinOrPan,
        idiomForApi(),
        false,
        false
      );

      const specialbenefits =
        benefits &&
        benefits.data.filter(benefit => benefit.categories === SPECIAL_BENEFIS);

      const requestDetailSpecialBenefits = specialbenefits.map(
        async specialBenefit =>
          await productsAndBenefitsServices.getBenefitsDetailByProduct(
            productBinOrPan,
            specialBenefit.benefit_id,
            idiomForApi(),
            false
          )
      );

      let detailSpecialBenefits = await Promise.all(
        requestDetailSpecialBenefits
      );
      detailSpecialBenefits = detailSpecialBenefits.map(especialBenefit => {
        return especialBenefit.data;
      });
      setSpecialBenefits(detailSpecialBenefits);
    } catch (error) {
      setSpecialBenefits([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (productBinOrPan) {
      loadSpecialBenefits();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productBinOrPan, cardSelected]);

  if (!productBinOrPan || loading) {
    return (
      <div className={styles.center}>
        <Loading />
      </div>
    );
  }

  const list =
    specialBenefits &&
    specialBenefits.map(specialBenefit => (
      <BenefitItem
        specialBenefit={specialBenefit}
        key={specialBenefit.benefit_id}
      />
    ));

  return (
    <div className={styles.container}>
      {specialBenefits && specialBenefits.length > 0 ? (
        <div className={styles.list}>{list}</div>
      ) : (
        <div className={styles.center}>
          <h2 className={`${styles.emptyMessage} ${styles[theme]}`}>
            {isLogged
              ? intl.TEXT_EMPTY_SPECIAL_BENEFITS_PRIVATE
              : intl.TEXT_EMPTY_SPECIAL_BENEFITS}
          </h2>
        </div>
      )}
    </div>
  );
};

export default ListBenefits;
