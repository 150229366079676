import {
  TEXT_ADD_YOUR_CARD_PAN,
  TEXT_16_DIGITS,
} from '../../ModalAddCard/intl';

import {
  TEXT_ADD_YOUR_CARD_BIN,
  TEXT_10_DIGITS,
} from '../../ModalAddCardBin/intl';

export const TEXT_ADD_NEW_CARD = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};

export {
  TEXT_ADD_YOUR_CARD_PAN,
  TEXT_16_DIGITS,
  TEXT_ADD_YOUR_CARD_BIN,
  TEXT_10_DIGITS,
};

export const ADD_PAN = {
  en_US: 'Add PAN',
  es_ES: 'Agregar PAN',
  pt_BR: 'Adicionar PAN',
};

export const ADD_BIN = {
  en_US: 'Add BIN',
  es_ES: 'Agregar BIN',
  pt_BR: 'Adicionar BIN',
};
