export const checkAccess = async (
  card,
  CONFIG_B2B2C,
  benefit_id,
  history,
  email
) => {
  const accessPurchase = await CONFIG_B2B2C.verifyAcess(
    benefit_id,
    card,
    email
  );
  if (!accessPurchase) {
    history.push('/dashboard');
  }
};
