import { certificatesServices } from '../../../../../../services';

export const formatAppliance = (_data, props) => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- FORMAT APPLIANCE',
    _data
  );
  const {
    values: { productInformation },
  } = props;
  const data = { ..._data, ...productInformation };
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI CRIAR APPLIANCE',
    true
  );
  return certificatesServices.postAppliance(data, props.idiomForApi());
};
