export const LABEL_IDENTIFICATION_NUMBER = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Description',
  pt_BR: 'Descrição',
  es_ES: 'Descripción',
};

export const LABEL_CREATED_AT = {
  en_US: 'Created at',
  pt_BR: 'Criado em',
  es_ES: 'Creado en',
};

export const LABEL_SWIFT_CODE = {
  en_US: 'SWIFT code',
  pt_BR: 'Código SWIFT',
  es_ES: 'Código SWIFT',
};

export const LABEL_FULL_KEY = {
  en_US: 'Full key code',
  pt_BR: 'Código full key',
  es_ES: 'Código CLABE COMPLETA',
};

export const LABEL_ABA = {
  en_US: 'ABA / Routing number',
  pt_BR: 'Número ABA',
  es_ES: 'Número ABA',
};

export const LABEL_IBAN = {
  en_US: 'IBAN code',
  pt_BR: 'Código IBAN',
  es_ES: 'Código IBAN',
};

export const LABEL_ACCOUNT_TYPE_CHECKING = {
  en_US: 'Checking account',
  pt_BR: 'Conta corrente',
  es_ES: 'Cuenta corriente',
};

export const LABEL_ACCOUNT_TYPE_SAVING = {
  en_US: 'Saving',
  pt_BR: 'Poupança',
  es_ES: 'Ahorro',
};
