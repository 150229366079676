import React from 'react';
import ModalInformation from '../../ModalInformation/ModalInformation';

const ModalSuccess = ({ intl, actions, history }) => (
  <ModalInformation
    type="success"
    message={intl.TEXT_SUCCESS}
    clickBtn={() => {
      actions.modal.closeModal();
      history.push('/dashboard');
    }}
  />
);

const ModalError = ({ intl }) => (
  <ModalInformation type="error" message={intl.UNEXPECTED_ERROR_OCCURRED} />
);

const ModalErrorTokenDuplicated = ({
  intl,
  confirmCallback,
  card,
  formattedBin,
}) => (
  <ModalInformation
    type="error"
    message={intl.TOKEN_IS_ALREADY_ASSOCIATED}
    textBtn={intl.TEXT_OTHER_CARD}
    subtitle={intl.TOKEN_IS_ALREADY_ASSOCIATED_SUBTITLE(
      formattedBin,
      card.name,
      card.issuer_name
    )}
    textBtnDeny={intl.TRY_AGAIN}
    clickBtn={confirmCallback}
  />
);

const ModalConfirm = ({ intl, submit }) => (
  <ModalInformation
    type="question"
    message={intl.MAKE_SURE_CREDIT_NUMBER_IS_CORRECT}
    subtitle={intl.DO_YOU_WANT_SAVE_CREDIT_CARD_NUMBER}
    textBtn={intl.CONFIRM_SAVE_CREDIT_CARD_NUMBER}
    textBtnDeny={intl.NOT_SAVE_CREDIT_CARD_NUMBER}
    clickBtn={submit}
  />
);

export { ModalSuccess, ModalError, ModalErrorTokenDuplicated, ModalConfirm };
