import React, { useContext, useEffect, useRef, useMemo } from 'react';

import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import InfoLabelValue from '../../../../../../../../components/InfoLabelValue/InfoLabelValue';
import styles from '../BankArea.module.scss';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../../intl';
import { safeGet } from '../../../../../../../../utils/object';
import { getValidate } from '../../../../../../../../utils/banks';
import * as constants from '../../constants';
import sanitize from '../../../../../../../../utils/sanitize';
import { getCountryFromPrefix } from '../../../../../../../../utils/country';
import DashedContainer from '../../../../../../../../components/DashedContainer/DashedContainer';
import { removeRequiredCharLabel } from '../../../../../../../../utils/stringUtils';

const BankAreaBR = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    initialValues,
    isProfile,
  } = props;
  const selectRef = useRef(null);

  const isCarholder = values.BankAccountHolder.relationship === 'Cardholder';

  const checkSameAccountNumber = () => {
    if (!values.BankAccount.bank.bankInfoChange) {
      return (
        values.BankAccount.bank.bank_account_number ===
        initialValues.BankAccount.bank.bank_account_number
      );
    }

    return (
      values.BankAccount.bank.bank_account_number ===
      values.BankChange.BankAccount.bank.bank_account_number
    );
  };

  const getInicialAccountNumber = () => {
    if (!values.BankAccount.bank.bankInfoChange) {
      return initialValues.BankAccount.bank.bank_account_number;
    }
    return values.BankChange.BankAccount.bank.bank_account_number;
  };

  const countryName = () => {
    let countryName = null;
    if (
      values.BankAccount.bank &&
      values.BankAccount.bank.address &&
      values.BankAccount.bank.address.country
    )
      countryName = getCountryFromPrefix(
        values.BankAccount.bank.address.country
      );
    if (!countryName) return;
    return countryName[idiom];
  };

  const bankValidate = getValidate(values.BankAccount.bank.bank_code);
  const initialAccountNumber = getInicialAccountNumber();
  const isSameAccountNumber = checkSameAccountNumber();
  const fullCountryName = countryName();

  const checkFormatAccount = e => {
    const value = e.target.value;
    const accountLength = bankValidate.getAccountNumberLength();
    if (!accountLength || value.length <= 1) return;
    const isGeneric = bankValidate.isGenericValidation();

    if (accountLength > value.length) {
      let len = parseInt(accountLength) - parseInt(value.length);
      let sub = isGeneric ? 0 : 1;
      len = len - 1 === 0 ? 1 : len - sub;
      let account = '0'.repeat(len) + value.replace('-', '').toString();
      setFieldValue('BankAccount.bank.bank_account_number', account);
      values.BankAccount.bank.bank_account_number = account;
      setTimeout(function () {
        setFieldTouched('BankAccount.bank.bank_account_number', true);
        handleChange(e);
      }, 100);
    }
  };

  useEffect(() => {
    if (isCarholder) {
      setFieldValue('BankAccount.bank', initialValues.BankAccount.bank);
    } else {
      setFieldValue('BankAccount.bank.name', '');
      setFieldValue('BankAccount.bank.branch_code', '');
      setFieldValue('BankAccount.bank.bank_code', '');
      setFieldValue('BankAccount.bank.bank_account_number', '');
    }
  }, [isCarholder]);

  useEffect(() => {
    if (
      selectRef &&
      selectRef.current &&
      values.BankAccount.bank &&
      (values.BankAccount.bank.name === null ||
        values.BankAccount.bank.name === '')
    ) {
      const listBanksNames = [...selectRef.current.options].map(el => {
        return el.text;
      });
      for (let index = 0; index < listBanksNames.length; index++) {
        if (listBanksNames[index].includes(values.BankAccount.bank.bank_code)) {
          setFieldValue('BankAccount.bank.name', listBanksNames[index]);
          break;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRef]);

  const getLabelAcountType = useMemo(() => {
    if (constants.CHEKING) {
      return intl.LABEL_ACCOUNT_TYPE_CHECKING;
    } else if (constants.SAVING) {
      return intl.LABEL_ACCOUNT_TYPE_SAVING;
    } else {
      return intl.LABEL_ACCOUNT_TYPE_CHECKING;
    }
  }, []);

  return (
    <DashedContainer dashed={!isProfile}>
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-md-4">
          <InfoLabelValue
            label={intl.TXT_COUNTRY_REIMBURSEMENT}
            value={
              values.BankAccount.bank &&
              values.BankAccount.bank.address &&
              fullCountryName
            }
          />
        </div>
        <div className="col-12 col-md-5">
          <Select
            label={intl.FORM_LABEL_BANK_NAME}
            name="BankAccount.bank.bank_code"
            value={values.BankAccount.bank.bank_code}
            dataTestid="InputBankInfoBankCode"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.FORM_LABEL_BANK_NAME}</option> ${intl.BANKS_OPTIONS}`
              ),
            }}
            onChange={e => {
              setFieldValue(
                'BankAccount.bank.name',
                e.target.selectedOptions[0].text
              );
              setFieldValue('BankAccount.bank.branch_code', '');
              setFieldValue('BankAccount.bank.bank_account_number', '');
              handleChange(e);
            }}
            onBlur={handleBlur}
            touched={safeGet(touched, 'BankAccount.bank.bank_code')}
            error={safeGet(errors, 'BankAccount.bank.bank_code')}
            selectRef={selectRef}
          />
        </div>
        <div className="col-12 col-md-3">
          <InfoLabelValue
            label={removeRequiredCharLabel(intl.LABEL_ACCOUNT_TYPE)}
            value={getLabelAcountType}
          />
        </div>
      </div>
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-sm-4 col-md-4 col-lg-4">
          <Input
            value={values.BankAccount.bank.branch_code}
            label={intl.FORM_LABEL_AGENCY}
            name="BankAccount.bank.branch_code"
            dataTestid="InputBankInfoBranchCode"
            onChange={handleChange}
            type="text"
            tooltipText={intl.TOOLTIP_TEXT_ZEROS_AGENCY}
            mask={bankValidate.getMaskAgencyName()}
            touched={safeGet(touched, 'BankAccount.bank.branch_code')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.bank.branch_code')}
          />
        </div>
        <div className="col-12 col-sm-8 col-md-8 col-lg-4">
          <Input
            value={values.BankAccount.bank.bank_account_number}
            label={intl.FORM_LABEL_ACCOUNT_NUMBER}
            name="BankAccount.bank.bank_account_number"
            dataTestid="InputBankInfoBankAccountNumber"
            type="text"
            onBlur={e => {
              handleBlur(e);
              if (e.target.value !== '') {
                checkFormatAccount(e);
              } else {
                setFieldValue(
                  'BankAccount.bank.bank_account_number',
                  initialAccountNumber
                );
                setFieldTouched('BankAccount.bank.bank_account_number', true);
              }
            }}
            onFocus={e => {
              if (e.target.value === initialAccountNumber) {
                setFieldValue('BankAccount.bank.bank_account_number', '');
                setFieldTouched('BankAccount.bank.bank_account_number', true);
              }
            }}
            tooltipText={intl.TOOLTIP_FORM_LABEL_ACCOUNT_NUMBER}
            mask={
              !isSameAccountNumber
                ? bankValidate.getMaskAccountName()
                : 'bankAccountNumberDefault'
            }
            onChange={handleChange}
            touched={safeGet(touched, 'BankAccount.bank.bank_account_number')}
            error={safeGet(errors, 'BankAccount.bank.bank_account_number')}
          />
        </div>
      </div>
    </DashedContainer>
  );
};

export default BankAreaBR;
