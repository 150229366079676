import React from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const VoucherIcon = props => {
  const { colorPrimary, colorSecundary } = props;
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={24}
      height={19}
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_1970_406)">
        <path
          d="M0 6.03335C1.66225 6.03335 3.01667 7.38777 3.01667 9.05002C3.01667 10.7123 1.66225 12.0667 0 12.0667V18.1H24V12.0667C22.3378 12.0667 20.9833 10.7123 20.9833 9.05002C20.9833 7.38777 22.3378 6.03335 24 6.03335V0H0V6.03335ZM2.01112 2.01112H21.9889V4.44292C20.2138 5.21761 18.9722 6.99273 18.9722 9.05002C18.9722 11.1073 20.2138 12.8824 21.9889 13.6571V16.0889H2.01112V13.6571C3.78623 12.8824 5.02779 11.1073 5.02779 9.05002C5.02779 6.99273 3.78623 5.21761 2.01112 4.44292V2.01112Z"
          fill={colorSecundary || config_icon.secondary}
        />
        <path
          d="M17.0638 4.02222H15.0527V6.03333H17.0638V4.02222Z"
          fill={colorPrimary || config_icon.primary}
        />
        <path
          d="M17.0638 8.04443H15.0527V10.0555H17.0638V8.04443Z"
          fill={colorPrimary || config_icon.primary}
        />
        <path
          d="M17.0638 12.0667H15.0527V14.0778H17.0638V12.0667Z"
          fill={colorPrimary || config_icon.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1970_406">
          <rect width={24} height={18.1} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default VoucherIcon;
