export const LABEL_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_IS_FULL_CHARGED_ON_CARD = {
  en_US:
    'Was the item purchased and paid for in full with the selected credit card?',
  pt_BR:
    'O artigo foi comprado e pago em sua totalidade com o cartão de crédito selecionado?',
  es_ES:
    '¿Se compró y pagó el artículo en su totalidad con la tarjeta de crédito seleccionada?',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descrição do incidente',
  es_ES: 'Describa el incidente',
};

export const LABEL_PRODUCT_MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const LABEL_PURCHASED_ITEM = {
  en_US: 'Item purchased',
  pt_BR: 'Produto comprado',
  es_ES: 'Artículo comprado',
};

export const LABEL_PRICE = {
  en_US: 'Purchase price(including tax)',
  pt_BR: 'Preço de compra (incluindo impostos)',
  es_ES: 'Precio de compra (incluyendo impuestos)',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Amount paid by the insurance',
  pt_BR: 'Valor pago pela companhia de seguros',
  es_ES: 'El monto pagado por la compañia de seguros',
};

export const LABEL_NAME_AND_LAST_NAME = {
  en_US: 'Full name',
  pt_BR: 'Nome completo',
  es_ES: 'Nombre completo',
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const LABEL_GENDER = {
  en_US: 'Gender',
  pt_BR: 'Gênero',
  es_ES: 'Género',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship',
  pt_BR: 'Relação',
  es_ES: 'Relación',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};
