import React, { useContext, useEffect, useState } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../../../intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import * as TravelAccidentUtils from '../../../../../../utils/claimsAPI/TravelAccidentUtils';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const PeopleInvolvedArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const [peopleInvolved, setPeopleInvolved] = useState([]);

  useEffect(() => {
    const peopleInvolved = (claimDetails && claimDetails.people_involved) || {};
    setPeopleInvolved(peopleInvolved);
  }, [claimDetails]);

  return peopleInvolved.map((person, index) => (
    <React.Fragment key={index}>
      <div className="col-12">
        <GroupDivisor
          title={intl.LABEL_INVOLVED_PERSON(index + 1)}
          simpleLine
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TYPE}
          value={TravelAccidentUtils.getInvolvedPersonType(person, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={TravelAccidentUtils.getAffectedPersonName(person)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={TravelAccidentUtils.getAffectedPersonLastName(person)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NUMBER}
          value={TravelAccidentUtils.getInvolvedPersonPhone(person, intl)}
        />
      </div>
      <div className="col-sm-12">
        <InfoLabelValue
          label={intl.LABEL_ADDRESS}
          value={TravelAccidentUtils.getAffectedPersonAddress(person, idiom)}
        />
      </div>
    </React.Fragment>
  ));
};

export default PeopleInvolvedArea;
