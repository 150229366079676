export const TITLE_APPOINTMENT_PENDING = {
  es_ES: 'Pendiente',
  en_US: 'Pending',
  pt_BR: 'Pendente',
};
export const TITLE_APPOINTMENT_IN_PROGRESS = {
  es_ES: 'En curso',
  en_US: 'In progress',
  pt_BR: 'Em andamento',
};
export const TITLE_APPOINTMENT_DONE = {
  es_ES: 'Hecho',
  en_US: 'Done',
  pt_BR: 'Feito',
};
export const TITLE_APPOINTMENT_CANCELED = {
  es_ES: 'Cancelado',
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
};
