import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './IndemnityArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const CORPORATE_INFINITE_TYPE = 'Corporate Infinite';

const IndemityArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  const intl = translate(translations);
  const isCorporative = props.cardName === CORPORATE_INFINITE_TYPE;

  const indemnity = isCorporative ? (
    <label className={`${styles[theme]} ${styles.labelTitle}`}>
      {intl.DOCUMENT_PROVIDED_2000}
    </label>
  ) : (
    <label className={`${styles[theme]} ${styles.labelTitle}`}>
      {intl.DOCUMENT_PROVIDED_300}
    </label>
  );

  return (
    <div className={`row ${styles.IndemityArea}`} data-testid="DivIdemnityArea">
      <div className="col-12"></div>
      {indemnity}
    </div>
  );
};

export default IndemityArea;
