import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  getDateOfCertificationRecovery,
  getFinancialInstitution,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import styles from '../CorporateLiabilityWaiver.module.scss';
import * as translations from './intl';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const CertificationArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [certification, setCertification] = useState({});

  useEffect(() => {
    const certification = (claimDetails && claimDetails.certification) || {};
    setCertification(certification);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <GroupDivisor
          title={intl.TEXT_RECOVERY_FUNDS_CERTIFICATES}
          simpleLine
        />
      </div>
      <div className={`${styles.checkbox} ${styles[themes]}`}>
        <div className={styles.itemCheckBox}>
          <CheckBox
            type="checkbox"
            value={certification && certification.is_amount_due_certified}
            name={'certification.is_amount_due_certified'}
            disabled
            dataTestid="checkboxAmountCertified"
          >
            {intl.TEXT_CERTIFY_ANY_AMOUNT_BE_RECOVERED}
            <span className={styles.financialInstitution}>
              {` ${getFinancialInstitution(certification)} `}
            </span>
            {intl.TEXT_FROM_COMPANY_WE_AGREE}
          </CheckBox>
        </div>
        <div className={styles.itemCheckBox}>
          <CheckBox
            type="checkbox"
            value={certification && certification.is_information_certified}
            name={'certification.is_information_certified'}
            disabled
            dataTestid="checkboxInformationCertified"
          >
            {intl.TEXT_CERTIFY_THAT_ABOVE_INFORMATION}
          </CheckBox>
        </div>
      </div>
      <div className="col-sm-12">
        <InfoLabelValue
          label={intl.LABEL_DATE}
          value={getDateOfCertificationRecovery(certification, idiom)}
        />
      </div>
    </>
  );
};

export default CertificationArea;
