import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './AdditionalPassengers.module.scss';
import Passenger from './Passenger/Passenger';
import PassengerAdd from './Passenger/PassengerAdd';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import * as constants from '../consts';

const AdditionalPassengers = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors, handleChange, setFieldValue } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const mappedAdditional = values.additional_passengers.map(
    (currentValue, index) => {
      const _errors =
        errors.additional_passengers && errors.additional_passengers[index]
          ? errors.additional_passengers[index]
          : {};
      const _touched =
        touched.additional_passengers && touched.additional_passengers[index]
          ? touched.additional_passengers[index]
          : {};
      return (
        <Passenger
          key={`additional-${index}`}
          _touched={_touched}
          _errors={_errors}
          index={index}
          {...props}
        />
      );
    }
  );
  const emptyPassagers = () => {
    setFieldValue('additional_passengers', [constants.ADDITIONAL_OBJECT]);
  };

  return (
    <div className={`${styles.addicionalPassagers}`}>
      <div className="row">
        <div className="col-12">
          <div className={`${styles.elementRadio} ${styles[themes]} row`}>
            <div className={`col-lg-12 col-sm-12`}>
              <label className={`${styles.labelTitle} ${styles[themes]}`}>
                {intl.TITLE_QUESTION}
              </label>
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                describe={intl.DESCRIPTION_HAS_ADDITIONAL_YES}
                label={intl.TITLE_QUESTION}
                defaultChecked={values.hasAdditional === 'true'}
                text={intl.TEXT_OPTION_YES}
                name="hasAdditional"
                dataTestid="hasAdditionalFieldYes"
                value={true}
                onChange={handleChange}
              />
            </div>
            <div className={`${styles.option} col-12`}>
              <RadioButton
                describe={intl.DESCRIPTION_HAS_ADDITIONAL_NO}
                label={intl.TITLE_QUESTION}
                defaultChecked={values.hasAdditional === 'false'}
                text={intl.TEXT_OPTION_NO}
                name="hasAdditional"
                dataTestid="hasAdditionalFieldNo"
                value={false}
                onChange={e => {
                  emptyPassagers();
                  handleChange(e);
                }}
              />
            </div>
          </div>
        </div>

        <div
          role="contentAdditionalPassangers"
          className={`col-12 ${
            values.hasAdditional !== 'true' ? styles.none : ''
          }`}
        >
          <PassengerAdd {...props} />
          {mappedAdditional}
        </div>
      </div>
    </div>
  );
};

export default AdditionalPassengers;
