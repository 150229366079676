export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};
