import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_REIMBURSED_TRANSPORTATION = {
  en_US:
    'Total amount refunded by the transport company, hotel, or other insurance',
  es_ES:
    'Cantidad total reembolsado por la compañía de transporte, hotel, u otro seguro',
  pt_BR:
    'Valor total reembolsado pela empresa de transporte, hotel ou outro seguro',
};

export const TEXT_TOTAL_REIMBURSED = {
  en_US:
    'Difference between amount paid and reimbursed (Amount *Susceptible for analysis) *',
  es_ES:
    'Diferencia entre importe pagado y reembolsado (Importe *Susceptible de análisis) *',
  pt_BR:
    'Diferença entre valor pago e reembolsado (Valor *Suscetível de análise) *',
};

/*
export const TEXT_TOTAL_REIMBURSED = {
  en_US: 'Total amount to be reimbursed *',
  es_ES: 'Importe total a reembolsar *',
  pt_BR: 'Total a ser reembolsado *',
}; */
