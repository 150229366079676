import React, { useContext, useMemo, useEffect } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import PersonalArea from './PersonalArea/PersonalArea';
import TripArea from './TripArea/TripArea';
import IncidentArea from './IncidentArea/IncidentArea';
import ExpensesArea from './ExpensesArea/ExpensesArea';
import ExpensesCompanyArea from './ExpensesCompanyArea/ExpensesCompanyArea';
import ExpensesInsuranceArea from './ExpensesInsuranceArea/ExpensesInsuranceArea';
import ReimbursementArea from './ReimbursementArea/ReimbursementArea';
import AdditionalPassengers from './AdditionalPassengers/AdditionalPassengers';
import { tripCancellationFormInitialValues } from './initialValues';
import { tripCancellationSchema } from './validationSchema';

let accommodationPrice = 0;
let companyCovering = 0;
let otherInsurance = 0;

const TripCancellation = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;
  const validationSchema = useMemo(() => tripCancellationSchema(intl), [intl]);
  const initialValues = useMemo(
    () =>
      tripCancellationFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy, loadedData]
  );

  useEffect(() => {
    accommodationPrice =
      initialValues.additional_costs_accomodation.amount_claimed.value || 0;
    companyCovering = initialValues.other_company_covering.value || 0;
    otherInsurance = initialValues.other_insurance_covering.value || 0;
  }, [
    initialValues.additional_costs_accomodation.amount_claimed.value,
    initialValues.other_company_covering.value,
    initialValues.other_insurance_covering.value,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleChange, handleSubmit, setFieldValue } = props;
        updateParent(values, validationSchema);

        const sumAmountReimbursed = e => {
          accommodationPrice =
            values.additional_costs_accomodation.amount_claimed.checked ===
            'true'
              ? accommodationPrice
              : 0;
          companyCovering =
            values.other_company_covering.checked === 'true'
              ? companyCovering
              : 0;
          otherInsurance =
            values.other_insurance_covering.checked === 'true'
              ? otherInsurance
              : 0;

          if (
            e.target.name ===
            'additional_costs_accomodation.amount_claimed.value'
          ) {
            accommodationPrice = e.target.value;
          }
          if (e.target.name === 'other_company_covering.value') {
            companyCovering = e.target.value;
          }
          if (e.target.name === 'other_insurance_covering.value') {
            otherInsurance = e.target.value;
          }
          const calc = parseFloat(
            Number(accommodationPrice) +
              Number(companyCovering) +
              Number(otherInsurance)
          ).toFixed(2);
          setFieldValue('reimbursed_transportation_insurance.value', calc);
          const calcTotal =
            Number(values.additional_costs_accomodation.price.value) +
            Number(values.additional_costs_transport.price.value) -
            calc;
          setFieldValue('total_amount_claimed.value', calcTotal.toFixed(2));
          handleChange(e);
        };

        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FullFormClaimTripCancellation"
          >
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <TripArea parentValues={parentValues} {...props} />
              <IncidentArea parentValues={parentValues} {...props} />
              <ExpensesArea
                parentValues={parentValues}
                {...props}
                sumAmountReimbursed={sumAmountReimbursed}
              />
              <AdditionalPassengers parentValues={parentValues} {...props} />
              <ExpensesCompanyArea
                parentValues={parentValues}
                {...props}
                sumAmountReimbursed={sumAmountReimbursed}
              />
              <ExpensesInsuranceArea
                parentValues={parentValues}
                {...props}
                sumAmountReimbursed={sumAmountReimbursed}
              />
              <ReimbursementArea parentValues={parentValues} {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default TripCancellation;
