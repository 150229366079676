import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../../../../intl';
import Select from '../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../utils/sanitize';
import { ConsultationPhoneType } from '../../../../../../../utils/consultationCategory';
import { amosOthersService } from '../../../../../../../services';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import Loading from '../../../../../../../components/Loading/Loading';
import PhoneForm from '../../../../../../../components/PhoneForm';

const ContactArea = props => {
  const { values, cardHolder } = props;
  const { translate, twoDigitsIdiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const language = twoDigitsIdiom();

  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [countriesOptions, setCountriesOptions] = useState('');
  const [timezonesOptions, setTimezonesOptions] = useState('');

  useMemo(() => {
    let countriesOptionsString = ``;
    countries.forEach(item => {
      countriesOptionsString += `<option value=${item.key}> ${item.value} </option>\n`;
    });
    setCountriesOptions(countriesOptionsString);
  }, [countries]);

  useMemo(() => {
    let timezoneOptionsString = ``;
    timezones.forEach(item => {
      timezoneOptionsString += `<option value=${item.key}> ${item.value.replace(
        /_/g,
        ' '
      )} </option>\n`;
    });
    setTimezonesOptions(timezoneOptionsString);
  }, [timezones]);

  const loadFormFields = useCallback(async () => {
    try {
      setLoading(true);
      trackEventUserAction(
        '#### VMO ### Video - Carregando informações de formulário',
        true
      );
      const countries = await amosOthersService.getCountries(language);
      setCountries(countries.data.data.countries);
      const timezones = await amosOthersService.getTimezones(language);
      setTimezones(timezones.data.data.timezones);
      setLoading(false);
      trackEventUserAction(
        '#### VMO ### Video - Informações de formulário carregadas',
        true
      );
    } catch (error) {
      setLoading(false);
      trackEventUserAction(
        '#### VMO ### Video - Erro ao carregar informações de formulário',
        error
      );
    }
  }, [language, trackEventUserAction]);

  useEffect(() => {
    loadFormFields();
  }, [cardHolder, loadFormFields]);

  const intl = translate(translations);

  if (loading) return <Loading checkBoxLoading scale="0.5" />;
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        <Select
          label={intl.COUNTRY_LABEL}
          name="country"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${countriesOptions}`
            ),
          }}
          formikProps={props}
          dataTestid="inputLocationCountry"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
        {values.type === ConsultationPhoneType && (
          <Select
            label={intl.LABEL_TIMEZONE}
            name="timezone"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_TIMEZONE}</option>${timezonesOptions}`
              ),
            }}
            formikProps={props}
            dataTestid="inputLocationCountry"
          />
        )}
      </div>
      <div className="col-12">
        <PhoneForm
          formikProps={props}
          title={intl.TITLE_CONTACT_PHONE}
          inputNames={{
            countryCode: 'phone_prefix',
            phoneNumber: 'phone',
          }}
        />
      </div>
    </div>
  );
};

export default ContactArea;
