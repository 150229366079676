import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ExtendedWarrantyIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <rect x={4} y={11} fill={config_icon.primary} width={9} height={2} />
        <rect x={4} y={7} fill={config_icon.primary} width={9} height={2} />
        <rect x={4} y={15} fill={config_icon.primary} width={9} height={2} />
        <polygon
          fill={config_icon.primary}
          points="22,11 22,19 2,19 2,5 16,5 16,3 0,3 0,21 24,21 24,11  "
        />
      </g>
      <polygon
        fill={config_icon.secondary}
        points="18,3 18,5 20.6,5 15.6,10 17,11.4 22,6.4 22,9 24,9 24,3 "
      />
    </svg>
  );
};

export default ExtendedWarrantyIcon;
