import React, { useState, useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { travelFormInitialValues } from './initialValues';
import { travelSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import ComplementPersonalArea from './ComplementPersonalArea/ComplementPersonalArea';
import PeopleInvolved from './PeopleInvolved/PeopleInvolved';
import QuestionPeopleInvolved from './QuestionPeopleInvolved/QuestionPeopleInvolved';

const TravelAccidentInsurance = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;
  const [prefix, setPrefix] = useState();
  const validationSchema = useMemo(() => travelSchema(intl), [intl]);
  const initialValues = useMemo(
    () => travelFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, loadedData, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);

        const renderPeopleInvolved = () =>
          values.is_accidental_death === 'false' &&
          values.have_people_involved === 'true' && (
            <PeopleInvolved {...props} prefixOptions={{ prefix, setPrefix }} />
          );

        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PersonalArea
                parentValues={parentValues}
                setPrefix={setPrefix}
                prefix={prefix}
                {...props}
              />
              {values.is_accidental_death === 'false' && (
                <ComplementPersonalArea {...props} />
              )}
              <IncidentArea {...props} />
              {values.is_accidental_death === 'false' && (
                <QuestionPeopleInvolved {...props} />
              )}
              {renderPeopleInvolved()}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default TravelAccidentInsurance;
