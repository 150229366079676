import { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Button from '../../../../../componentsV2/Button/Button';
import styles from './ButtonProfile.module.scss';
import { removeAxaPrefixFromExternalReference } from '../../../../../utils/B2C/string';

const ButtonBenefits = props => {
  const { benefit } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <div className={styles.boxButtons}>
      <Button
        link
        type="Primary"
        href={`/your-benefits/${benefit.benefit_id}`}
        label={intl.VIEW_MORE}
        className={styles.buttonItem}
      />
      <Button
        link
        type="Primary"
        href={`/cross-sell-detail/${removeAxaPrefixFromExternalReference(
          benefit.external_reference
        )}`}
        label={intl.BUTTON_PURCHASE}
        className={styles.buttonItem}
      />
    </div>
  );
};

export default withRouter(ButtonBenefits);
