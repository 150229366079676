export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const MALE_VALUE_ABV = 'ml';
export const FEMALE_VALUE_ABV = 'fm';
export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const ADDITIONAL_OBJECT = {
  first_name: '',
  last_name: '',
  birth_date: '',
  gender: '',
  nationalities: [],
  relationship: 'Spouse',
  comment: '',
};
export const ITEMS_OBJECT = {
  description: '',
  price: {
    value: 0,
    currency: '',
  },
};
