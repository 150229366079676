export const kindBusinessExceptions = [
  {
    error_name: 'addCard',
    status: 404,
    error_type: 'bad_request',
    error_description: 'BIN_NUMBER_NOT_FOUND',
  },
  {
    error_name: 'getLoggedUserCards',
    status: 404,
    error_type: 'not_found',
    error_description: 'CUSTOMER_NOT_FOUND',
  },
  {
    error_name: 'getCHInfo',
    status: 404,
    error_type: 'not_found',
    error_description: 'CUSTOMER_NOT_FOUND',
  },
  {
    error_name: 'postApplianceAnual',
    status: 400,
    error_type: 'bad_request',
    error_description:
      'THE_TICKET_WAS_NOT_VALIDATED_BY_AIG._ERROR:_BILHETE_JA_EMITIDO_ANTERIORMENTE_(DUPLICIDADE)',
  },
  {
    error_name: 'createTravelCertificatePt',
    status: 400,
    error_type: 'bad_request',
    error_description:
      'THE_DEPENDENT_TICKET_WAS_NOT_VALIDATED_BY_AIG._ERROR:_BILHETE_JA_EMITIDO_ANTERIORMENTE_(DUPLICIDADE)',
  },
  {
    error_name: 'changePassword',
    status: 400,
    error_type: 'bad_request',
    error_description: 'THE_OLD_PASSWORD_IS_INVALID',
  },
  {
    error_name: 'createTravelCertificatePt',
    status: 400,
    error_type: 'bad_request',
    error_description: 'CEP_NAO_ENCONTRADO',
  },
  {
    error_name: 'getUserNotifications',
    status: 404,
    error_type: 'not_found',
    error_description: 'NOT_FOUND',
  },
  {
    error_name: 'getUserNotifications',
    status: 404,
    error_type: 'not_found',
    error_description:
      'THE_CUSTOMER_ID_IN_THE_ACCESS_TOKEN_IS_DIFFERENT_THAN_THE_CUSTOMER_ID_IN_THE_REQUEST.',
  },
];
