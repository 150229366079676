import * as Yup from 'yup';
import moment from 'moment';

const validateDate = (endDate, initialDate) => {
  const newEndDate = moment(endDate);
  const newInitialDate = moment(initialDate);
  const diffDate = newEndDate.diff(newInitialDate, 'days');

  return diffDate >= 1 && diffDate <= 60;
};

export const validationSchema = (intl, type) =>
  Yup.object().shape({
    Quote: Yup.object().shape({
      CountryOfResidenceIsoCode: Yup.string().required(
        intl.VALIDATION_REQUIRED
      ),
      DestinationCountryIsoCode: Yup.string().required(
        intl.VALIDATION_REQUIRED
      ),
      TripInitialDate: Yup.string()
        .required(intl.VALIDATION_REQUIRED)
        .nullable(intl.VALIDATION_REQUIRED)
        .test('match', intl.ERROR_INVALID_DATE, function (date) {
          return this.parent.TripInitialDate && type === 'crossell'
            ? moment(date).isAfter(new Date(moment().add(-1, 'day')))
            : true;
        }),
      TripEndDate: Yup.string()
        .required(intl.VALIDATION_REQUIRED)
        .nullable(intl.VALIDATION_REQUIRED)
        .test('match', intl.ERROR_COVERAGE_IS_LIMITED, function (date) {
          if (!date) {
            return;
          }

          return this.parent.TripInitialDate && type === 'crossell'
            ? validateDate(date, this.parent.TripInitialDate)
            : true;
        })
        .test('check invalid date', intl.ERROR_INVALID_DATE, function (date) {
          return this.parent.TripInitialDate && type === 'crossell'
            ? true
            : moment(date).isSameOrAfter(
                moment(this.parent.TripInitialDate),
                'day'
              );
        }),
    }),
  });
