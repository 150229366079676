export const SUBTITLE = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TITLE = {
  en_US: 'Cinepolis',
  es_ES: 'BanCoppel te invita al cine',
  pt_BR: '2x1 em Cinépolis',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Card information',
  es_ES: 'Información de la tarjeta',
  pt_BR: 'Informações do cartão',
};
