export const TEXT_CANCEL = {
  pt_BR: 'Cancelar',
  en_US: 'Cancel',
  es_ES: 'Cancelar',
};

export const TEXT_CONCLUDE = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};

export const TEXT_HELP_US = {
  en_US: 'Help us to improve!',
  pt_BR: 'Nos ajude a melhorar!',
  es_ES: 'Ayúdanos a mejorar!',
};

export const TEXT_SELECT_IMPROVEMENT = {
  en_US: 'Select the improvement options you consider most relevant:',
  pt_BR: 'Selecione as opções de melhoria que você considera mais relevantes:',
  es_ES: 'Seleccione las opciones de mejora que considere más relevantes:',
};

export const TEXT_FEEDBACK = {
  en_US: 'Thank you for helping us improve!',
  pt_BR: 'Obrigado por nos ajudar a melhorar!',
  es_ES: '¡Gracias por ayudarnos a mejorar!',
};

export const TEXT_ERROR = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtelo de nuevo más tarde.',
};
