import * as Yup from 'yup';
import moment from 'moment';
import { validateCPF } from './stringUtils';

export const yupCustomValidation = () => {
  Yup.addMethod(Yup.string, 'validateCPF', function (message) {
    return this.test('cpf', message || 'no messsage', value =>
      validateCPF(value)
    );
  });

  Yup.addMethod(Yup.string, 'required', function (message) {
    return this.test(
      'overwrite required',
      message || 'no message',
      function (value) {
        if (!value) {
          return false;
        }
        const trimValue = String(value).trim();
        if (trimValue) {
          return true;
        }

        return false;
      }
    );
  });

  Yup.addMethod(
    Yup.date,
    'validateDateIsSameOrBeforeCurrentDate',
    function (message) {
      return this.test('match', message || 'no message', function (date) {
        return moment(date).isSameOrBefore(new Date());
      });
    }
  );

  Yup.addMethod(
    Yup.date,
    'validateDateIsSameOrAfterParentDate',
    function (message, parent) {
      return this.test('match', message || 'no message', function (date) {
        if (moment(this.parent[parent]).isValid()) {
          return moment(date).isSameOrAfter(this.parent[parent]);
        }
        return true;
      });
    }
  );

  Yup.addMethod(
    Yup.string,
    'validatePassportByRegistrationType',
    function (msgValidateError, registration_type) {
      return this.test(
        'passportByType',
        msgValidateError || 'no message',
        function (value) {
          return registration_type === 'CPF' ? validateCPF(value) : true;
        }
      );
    }
  );
};
