export const BTN_NEXT = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const MANDATORY_FIELDS = {
  en_US: 'Fields marked with * are mandatory.',
  pt_BR: 'Os campos marcados com * são obrigatórios.',
  es_ES: 'Los campos marcados con * son obligatorios.',
};

export const BUTTON_CONTINUE = {
  en_US: 'Continue',
  pt_BR: 'Continuar',
  es_ES: 'Continuar',
};

export const BUTTON_CLOSE = {
  en_US: 'Close',
  pt_BR: 'Fechar',
  es_ES: 'Cerrar',
};

export const EXIT_TITLE = {
  en_US: 'Do you really want to leave?',
  pt_BR: 'Deseja realmente sair?',
  es_ES: '¿De verdad quieres irte?',
};
export const CHANGES_NOT_SAVED_MESSAGE = {
  en_US: 'Changes will not be saved.',
  pt_BR: 'As alterações não serão salvas.',
  es_ES: 'Los cambios no se guardarán.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtelo de nuevo más tarde.',
};
