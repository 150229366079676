import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const maskedCreditCard = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',

  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  '**.****',
];

export const maskedCreditCard16Digits = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const maskedDate = [/\d/, /\d/, '/', /\d/, /\d/];
export const maskedDateFullYear = [/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const maskedCVC = [/\d/, /\d/, /\d/];

export const maskedAge = [/[1-9]/, /\d/];
export const maskedMonth = [/[0-1]/, /\d/];
export const maskedYear = [/[2]/, /\d/, /\d/, /\d/];

const bankCodeMask = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  integerLimit: null,
});

const onlyNumber = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  integerLimit: null,
  allowLeadingZeroes: true,
});

const passportCharacters = (maxLength = 20) => {
  let passport = [];
  for (let i = 0; i < maxLength; i++) {
    passport.push(/[a-zA-Z0-9]/);
  }
  return passport;
};

const passportCharactersMaskFirst4 = (maxLength = 20) => {
  let passport = [];
  for (let i = 0; i < maxLength; i++) {
    if (i < 4) {
      passport.push('*');
    } else {
      passport.push(/[a-zA-Z0-9]/);
    }
  }
  return passport;
};

export const maskedCPF = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const maskedCPFWithoutFirst4 = [
  '*',
  '*',
  '*',
  '.',
  '*',
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const maskedCNPJ = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
export const maskedPassaport = passportCharacters();
export const maskedPassaportWithoutFirst4 = passportCharactersMaskFirst4();

export const maskedPrefixPhone = ['+', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskedPhone = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
// number, -, () and empty space - 17 chars
export const maskedPhoneCovid = [
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
  /[0-9-(-)+$|^$|^\s$]/,
];
export const maskedPostalCode = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskedPostalCodeBr = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
];
export const maskedPostalCodeAlphanumeric = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];
export const maskedDigitsBank = [/\d/, /\d/, /\d/, /\d/];
export const maskedBankCode = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskedCodeBank = bankCodeMask;
export const maskedOnlyNumber = onlyNumber;
export const maskLicenseNumber = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
export const maskedHour = [/[0-9]/, /\d/, ':', /[0-9]/, /\d/];
export const maskedMoney = createNumberMask({
  prefix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  //integerLimit: 5,
  requireDecimal: true,
});

// BANK
export const maskBBBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskBBAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  '-',
  /[a-zA-Z0-9]/,
];

export const maskBradescoBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskBradescoAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[a-zA-Z0-9]/,
];

export const maskItauBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskItauAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[a-zA-Z0-9]/,
];

export const maskCaixaBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskCaixaAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskSantanderBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskSantanderAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[a-zA-Z0-9]/,
];

export const maskBanrisulBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskBanrisulAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskCitibankBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskCitibankAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskHsbcranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskHsbcAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskNubankBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskNubankAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskMercadoPagoBranchCode = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
export const maskMercadoPagoAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskXPInvestimentosBranchCode = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const maskXPInvestimentosAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskGenericBranchCode = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
export const maskGenericAccountNumber = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const maskedExpirationDate = [/[0-1]/, /[0-9]/, '/', /\d/, /\d/];

export const maskedSwift = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  '-',
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  '-',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  '-',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];

export const maskedIban = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

export const maskedAba = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskedCuit = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskedCuit_es_AR = [
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskedFullKey = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskedCBU_es_AR = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
  '-',
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  '-',
  /[0-9]/,
];

export const maskedHolderType = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];

export const internationalBankAccountNumber = () => {
  let mask = [];
  for (let i = 0; i < 33; i++) {
    mask[i] = /[a-zA-Z0-9-(-)]/;
  }
  return mask;
};

export const bankAccountNumberDefault = () => {
  let mask = [];
  for (let i = 0; i < 33; i++) {
    mask[i] = /[a-zA-Z0-9-(-*)]/;
  }
  return mask;
};

//All characters excluding accentuation
export const maskedEmail = () => {
  let mask = [];
  for (let i = 0; i < 254; i++) {
    mask[i] = /[a-zA-Z0-9-\u0021-\u0040\u005F\u005B-\u005D\u007B-\u007D\u00BF]/;
  }
  return mask;
};

export const maskedOnlyLetters = () => {
  let mask = [];
  for (let i = 0; i < 99; i++) {
    mask[i] =
      /[a-zA-Z\sÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž]/;
  }
  return mask;
};

export const maskedAirlineCode = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/];

export const maskedFlightNumber = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
