import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';
import styles from './icons.module.scss';

const ArrowRightIcon = props => {
  const { color, rotate } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 17 17"
      rotate="210"
      style={{
        enableBackground: 'new 0 0 17 17',
        transform: rotate ? `rotate(${rotate}deg)` : 'rotate(180deg)',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: color ? color : config_icon.secondary,
        }}
        d="m7 14.2 1.4-1.4-3.3-3.3h10.6v-2H5.1l3.3-3.3L7 2.8 1.3 8.5z"
      />
    </svg>
  );
};

export default ArrowRightIcon;
