import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './IncidentArea.module.scss';
import { safeGet } from '../../../../../../../../utils/object';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12">
        <GroupDivisor title={intl.PRIOR_MISSED_CONNECTION} />
      </div>
      <div className="col-12 col-md-6 col-lg-4">
        <Calendar
          value={values.travel_information.programmed_flights.departure_date}
          label={intl.DEPARTURE_DATE}
          name="travel_information.programmed_flights.departure_date"
          onChange={handleChange}
          minDate={values.travel_information.from}
          maxDate={values.travel_information.to}
          setFieldTouched={setFieldTouched}
          touched={
            touched.travel_information &&
            touched.travel_information.programmed_flights &&
            touched.travel_information.programmed_flights.departure_date
          }
          error={
            errors.travel_information &&
            errors.travel_information.programmed_flights &&
            errors.travel_information.programmed_flights.departure_date
          }
          dataTestid="inputTravelDepartureDate"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-2">
        <Input
          value={values.travel_information.programmed_flights.departure_time}
          label={intl.DEPARTURE_TIME}
          name="travel_information.programmed_flights.departure_time"
          type="text"
          mask="maskedHour"
          onChange={handleChange}
          touched={safeGet(
            touched,
            'travel_information.programmed_flights.departure_time'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'travel_information.programmed_flights.departure_time'
          )}
          dataTestid="inputDeparturetime"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Calendar
          value={values.travel_information.programmed_flights.arrival_date}
          label={intl.ARRIVAL_DATE}
          name="travel_information.programmed_flights.arrival_date"
          onChange={handleChange}
          minDate={values.travel_information.programmed_flights.departure_date}
          setFieldTouched={setFieldTouched}
          touched={
            touched.travel_information &&
            touched.travel_information.programmed_flights &&
            touched.travel_information.programmed_flights.arrival_date
          }
          error={
            errors.travel_information &&
            errors.travel_information.programmed_flights &&
            errors.travel_information.programmed_flights.arrival_date
          }
          dataTestid="inputFlightsArrivalDate"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-2">
        <Input
          value={values.travel_information.programmed_flights.arrival_time}
          label={intl.ARRIVAL_TIME}
          name="travel_information.programmed_flights.arrival_time"
          type="text"
          mask="maskedHour"
          onChange={handleChange}
          touched={safeGet(
            touched,
            'travel_information.programmed_flights.arrival_time'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'travel_information.programmed_flights.arrival_time'
          )}
          dataTestid="inputFlightsArrivalTime"
        />
      </div>

      <div className="col-12">
        <GroupDivisor title={intl.MISSED_CONNECTION_PORTION} />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Calendar
          value={
            values.travel_information.programmed_flights2
              .departure_programmed_date
          }
          label={intl.DEPARTURE_DATE}
          name="travel_information.programmed_flights2.departure_programmed_date"
          onChange={handleChange}
          minDate={values.travel_information.from}
          maxDate={values.travel_information.to}
          setFieldTouched={setFieldTouched}
          touched={
            touched.travel_information &&
            touched.travel_information.programmed_flights2 &&
            touched.travel_information.programmed_flights2
              .departure_programmed_date
          }
          error={
            errors.travel_information &&
            errors.travel_information.programmed_flights2 &&
            errors.travel_information.programmed_flights2
              .departure_programmed_date
          }
          dataTestid="inputFlights2ProgrammedDate"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-2">
        <Input
          value={
            values.travel_information.programmed_flights2
              .departure_programmed_time
          }
          label={intl.DEPARTURE_TIME}
          name="travel_information.programmed_flights2.departure_programmed_time"
          type="text"
          mask="maskedHour"
          onChange={handleChange}
          touched={safeGet(
            touched,
            'travel_information.programmed_flights2.departure_programmed_time'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'travel_information.programmed_flights2.departure_programmed_time'
          )}
          dataTestid="inputFlights2DepartureProgrammedTime"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Calendar
          value={
            values.travel_information.programmed_flights2
              .arrival_programmed_date
          }
          label={intl.ARRIVAL_DATE}
          name="travel_information.programmed_flights2.arrival_programmed_date"
          onChange={handleChange}
          minDate={
            values.travel_information.programmed_flights2
              .departure_programmed_date
          }
          setFieldTouched={setFieldTouched}
          touched={
            touched.travel_information &&
            touched.travel_information.programmed_flights2 &&
            touched.travel_information.programmed_flights2
              .arrival_programmed_date
          }
          error={
            errors.travel_information &&
            errors.travel_information.programmed_flights2 &&
            errors.travel_information.programmed_flights2
              .arrival_programmed_date
          }
          dataTestid="inputFlights2ArrivalProgrammedDate"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-2">
        <Input
          value={
            values.travel_information.programmed_flights2
              .arrival_programmed_time
          }
          label={intl.ARRIVAL_TIME}
          name="travel_information.programmed_flights2.arrival_programmed_time"
          type="text"
          mask="maskedHour"
          onChange={handleChange}
          touched={safeGet(
            touched,
            'travel_information.programmed_flights2.arrival_programmed_time'
          )}
          onBlur={handleBlur}
          error={safeGet(
            errors,
            'travel_information.programmed_flights2.arrival_programmed_time'
          )}
          dataTestid="inputFlights2ArrivalProgrammedTime"
        />
      </div>

      <div className="col-12">
        <GroupDivisor title={intl.MISSED_CONNECTION} />
      </div>

      <div className="col-12">
        <Input
          value={values.travel_information.agency_information.name}
          label={intl.MISSED_CONNECTION_AIRLINE}
          name="travel_information.agency_information.name"
          type="text"
          onChange={handleChange}
          touched={safeGet(
            touched,
            'travel_information.agency_information.name'
          )}
          onBlur={handleBlur}
          error={safeGet(errors, 'travel_information.agency_information.name')}
          maxLength="100"
          dataTestid="inputTravelAgencyInformaton"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Calendar
          value={values.incident.date}
          label={intl.MISSED_CONNECTION_DATE}
          name="incident.date"
          onChange={handleChange}
          minDate={values.travel_information.from}
          maxDate={values.travel_information.to}
          touched={touched.incident && touched.incident.date}
          error={errors.incident && errors.incident.date}
          setFieldTouched={setFieldTouched}
          dataTestid="inputIncidentDate"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          value={values.incident.time}
          label={intl.MISSED_CONNECTION_TIME}
          name="incident.time"
          type="text"
          mask="maskedHour"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.time')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.time')}
          dataTestid="inputIncidentTime"
        />
      </div>

      <div className="col-12">
        <Textarea
          value={values.incident.description}
          label={intl.DESCRIBE_REASONS}
          name="incident.description"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.description')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.description')}
          maxLength="255"
          dataTestid="inputIncidentDescription"
        />
      </div>
    </div>
  );
};

export default IncidentArea;
