export const TRIP_START = {
  en_US: 'Departure date from origin country',
  pt_BR: 'Data de partida do país de origem',
  es_ES: 'Fecha de início del país de origen',
};

export const TRIP_END = {
  en_US: 'Return date to the origin country',
  pt_BR: 'Data de retorno ao país de origem',
  es_ES: 'Fecha de regreso al país de origen',
};
