import React, { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import * as translationsPErsonal from '../intlPersonal';
import { callCepService } from '../../../../../../../../utils/postalCode';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import Tooltip from '../../../../../../../../components/Tooltip/Tooltip';
import listCountryPrefix from '../../../../../../../../intl/countryPrefix.json';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';
import PhoneArea from '../PhoneArea/PhoneArea';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const fieldsPostalCode = {
  uf: 'affected_person.address.state',
  localidade: 'affected_person.address.locality',
  logradouro: 'affected_person.address.street_address',
  bairro: 'affected_person.address.subdivision',
};

const PersonalArea = props => {
  const [cepStatus, setCepStatus] = useState();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const intlPersonal = translate(translationsPErsonal);
  const {
    parentValues,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setPrefix,
    prefix,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
    setFieldValue('affected_person.person.nationalities', '');
  };
  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );

    setFieldValue(
      'affected_person.person.nationalities',
      parentValues.policy.nationalities
    );
  };

  const isCountryBr = values.affected_person.address.country === 'BR';
  const cepService = cep => {
    if (cep.length === 9) values.affected_person.address.invalidCep = true;
    callCepService(
      cep,
      setCepStatus,
      'affected_person.address.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  const handleCepBlur = e => {
    handleBlur(e);
    if (isCountryBr) cepService(e.target.value);
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let maskPostalCode = isCountryBr ? 'maskedPostalCodeBr' : 'maskedPostalCode';

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = intl.VALIDATING_CEP;
  } else if (cepStatus === 'INVALID') {
    cepError = intl.VALIDATE_CEP;
  } else {
    cepError =
      errors.affected_person &&
      errors.affected_person.address &&
      errors.affected_person.address.postal_code;
  }

  const changeCountryPrefix = initials => {
    const prefix = listCountryPrefix.find(
      country => initials === country.initials
    );
    if (prefix) setPrefix(prefix.prefix);
  };

  const changeRelationship = event => {
    setEmptyPersonalInputFields();
    if (event.target.value === constants.CARD_HOLDER_VALUE) {
      setCardHolderNameLastName();
    }
    handleChange(event);
  };
  useEffect(() => {
    if (values.affected_person.person.nationalities === 'BR') {
      setFieldValue(
        'affected_person.person_registration.registration_type',
        'CPF'
      );
    }
    if (
      values.affected_person.person.nationalities !== 'BR' &&
      values.affected_person.person_registration.registration_type === 'CPF'
    ) {
      setFieldValue(
        'affected_person.person_registration.registration_type',
        'PASSPORT'
      );
    }
  }, [
    values.affected_person.person.nationalities,
    values.affected_person.person_registration.registration_type,
    setFieldValue,
  ]);

  return (
    <div className={`row ${styles.personalArea}`}>
      <div className="col-12" style={{ marginBottom: '12px' }}>
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.ACCIDENT_REASON}
              <Tooltip text={intl.HELP_11} />
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.ACCIDENT_REASON}
              defaultChecked={values.is_accidental_death === 'false'}
              text={intl.ACCIDENTAL_DISMEMBERMENT}
              name="is_accidental_death"
              dataTestid="InputIsAccidentalDeathNo"
              value={false}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.ACCIDENT_REASON}
              defaultChecked={values.is_accidental_death === 'true'}
              text={intl.ACCIDENTAL_DEATH}
              name="is_accidental_death"
              dataTestid="InputIsAccidentalDeathYes"
              value={true}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`row`}>
          <div className="col-12 col-md-6">
            <Select
              label={intl.PERSON_AFFECTED}
              name="affected_person.relationship"
              value={values.affected_person.relationship}
              onBlur={handleBlur}
              touched={
                touched.affected_person && touched.affected_person.relationship
              }
              error={
                errors.affected_person && errors.affected_person.relationship
              }
              onChange={changeRelationship}
              dataTestid="relationshipSelect"
            >
              <option value={constants.CARD_HOLDER_VALUE}>
                {intl.TEXT_CH}
              </option>
              <option value={constants.SPOUSE_VALUE}>
                {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
              </option>
              <option value={constants.DEPENDENT_VALUE}>
                {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
              </option>
              <option value={constants.OTHER_VALUE}>
                {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
              </option>
            </Select>
          </div>
          <div
            role="contentRelationshipComment"
            className={`col-12 col-md-6 ${
              values.affected_person.relationship !== constants.OTHER_VALUE
                ? styles.none
                : ''
            }`}
          >
            <Input
              label={intl.TEXT_OTHER}
              name="affected_person.comment"
              dataTestid="InputRelationshipComment"
              type="text"
              disabled={
                values.affected_person.relationship !== constants.OTHER_VALUE
              }
              formikProps={props}
              maxLength="255"
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-10 col-md-6">
        <Input
          label={intl.LABEL_NAME_AFFECTED}
          name="affected_person.person.first_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          formikProps={props}
          maxLength="40"
          dataTestid="InputFirstName"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          label={intl.LABEL_LAST_NAME_AFFECTED}
          name="affected_person.person.last_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          formikProps={props}
          maxLength="80"
          dataTestid="InputLastName"
        />
      </div>

      {values.affected_person.relationship !== constants.CARD_HOLDER_VALUE && (
        <>
          {values.is_accidental_death === 'false' && (
            <div className="col-12">
              <div
                className={`${styles.fontGray12} ${styles[themes]} ${styles.options} row`}
              >
                {values.affected_person.relationship !==
                  constants.CARD_HOLDER_VALUE && (
                  <div className="col-12 col-md-6">
                    <Input
                      label={intl.LABEL_OCCUPATION}
                      name="affected_person.professional_situation.occupation"
                      type="text"
                      formikProps={props}
                      maxLength="100"
                      dataTestid="inputSituationOccupation"
                    />
                  </div>
                )}
                <div className="col-12 col-md-6">
                  <Input
                    label={intlPersonal.LABEL_EMAIL}
                    name="affected_person.email"
                    type="text"
                    dataTestid="InputClaiCHEmail"
                    formikProps={props}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <Select
                    label={intl.NATIONALITY_LABEL}
                    value={values.affected_person.person.nationalities}
                    name="affected_person.person.nationalities"
                    onChange={handleChange}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(intl.NATIONALITY_OPTIONS),
                    }}
                    touched={
                      touched.affected_person &&
                      touched.affected_person.person &&
                      touched.affected_person.person.nationalities
                    }
                    onBlur={handleBlur}
                    error={
                      errors.affected_person &&
                      errors.affected_person.person &&
                      errors.affected_person.person.nationalities
                    }
                  />
                </div>
                {values.affected_person.person.nationalities === 'BR' ? (
                  <div className="col-12 col-sm-12 col-md-6">
                    <Input
                      label={intlPersonal.LABEL_REGISTRATIONS_TYPE_CPF}
                      mask={'maskedCPF'}
                      name="affected_person.person_registration.value"
                      type="text"
                      tooltipText={intlPersonal.TEXT_ALERT_CPF}
                      formikProps={props}
                      dataTestid="InputPersonRegistration"
                    />
                  </div>
                ) : (
                  <div
                    className="col-12 col-sm-12 col-md-6"
                    style={{ padding: 0 }}
                  >
                    <div className="row" style={{ margin: 0 }}>
                      <div className="col-4 col-sm-12 col-md-6">
                        <Select
                          label={intlPersonal.LABEL_IDENTIFICATION}
                          value={
                            values.affected_person.person_registration
                              .registration_type
                          }
                          name="affected_person.person_registration.registration_type"
                          onChange={handleChange}
                          touched={
                            touched.affected_person &&
                            touched.affected_person.person_registration &&
                            touched.affected_person.person_registration
                              .registration_type
                          }
                          onBlur={handleBlur}
                          error={
                            errors.affected_person &&
                            errors.affected_person.person_registration &&
                            errors.affected_person.person_registration
                              .registration_type
                          }
                        >
                          <option value="">
                            {intlPersonal.LABEL_IDENTIFICATION + ' *'}
                          </option>
                          <option value="PASSPORT">
                            {intlPersonal.LABEL_PASSPORT}
                          </option>
                          <option value="ID_CARD">
                            {intlPersonal.LABEL_NATIONAL_ID}
                          </option>
                        </Select>
                      </div>
                      <div className="col-8 col-sm-12 col-md-6">
                        <Input
                          value={
                            values.affected_person.person_registration.value
                          }
                          label={
                            values.affected_person.person_registration
                              .registration_type === 'CPF'
                              ? intlPersonal.LABEL_CPF
                              : values.affected_person.person_registration
                                  .registration_type === 'ID_CARD'
                              ? intlPersonal.LABEL_NATIONAL_ID
                              : intlPersonal.LABEL_PASSPORT
                          }
                          mask={'maskedPassaport'}
                          name="affected_person.person_registration.value"
                          type="text"
                          dataTestid="InputCHRegistrationValue"
                          onChange={handleChange}
                          tooltipText={
                            values.affected_person.person_registration
                              .registration_type === 'ID_CARD'
                              ? intlPersonal.TEXT_ALERT_NATIONAL_ID
                              : intlPersonal.TEXT_ALERT_PASSPORT
                          }
                          touched={
                            touched.affected_person &&
                            touched.affected_person.person_registration &&
                            touched.affected_person.person_registration.value
                          }
                          onBlur={handleBlur}
                          error={
                            errors.affected_person &&
                            errors.affected_person.person_registration &&
                            errors.affected_person.person_registration.value
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {values.affected_person.relationship !==
            constants.CARD_HOLDER_VALUE && (
            <PhoneArea {...props} prefixOptions={{ prefix, setPrefix }} />
          )}

          <div className="col-12">
            <div
              className={`${styles.fontGray12} ${styles[themes]} ${styles.options} row`}
            >
              <div className="col-12">
                <GroupDivisor title={intl.TEXT_ADDRESS} />
              </div>
              <div className="col-12 col-sm-12 col-md-6  col-lg-4">
                <Select
                  label={intlPersonal.COUNTRY_LABEL}
                  name="affected_person.address.country"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      `<option value="">${intlPersonal.COUNTRY_LABEL}</option>${intlPersonal.COUNTRY_OPTIONS}`
                    ),
                  }}
                  value={values.affected_person.address.country}
                  onChange={event => {
                    changeCountryPrefix(event.target.value);
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  error={
                    errors &&
                    errors.affected_person &&
                    errors.affected_person.address &&
                    errors.affected_person.address.country
                  }
                  touched={
                    touched &&
                    touched.affected_person &&
                    touched.affected_person.address &&
                    touched.affected_person.address.country
                  }
                  maxLength="100"
                  dataTestid="comboboxAddressCountry"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-2">
                <Input
                  value={values.affected_person.address.postal_code}
                  label={intlPersonal.LABEL_POSTAL_CODE}
                  name="affected_person.address.postal_code"
                  type="text"
                  onChange={handleChange}
                  dataTestid="InputClaimCHPostalCode"
                  mask={maskPostalCode}
                  touched={
                    touched.affected_person &&
                    touched.affected_person.address &&
                    touched.affected_person.address.postal_code
                  }
                  onBlur={handleCepBlur}
                  error={cepError}
                  bottomLabel={cepWaiting}
                  onFocus={clearCepSearch}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <Input
                  label={intlPersonal.LABEL_STATE}
                  name="affected_person.address.state"
                  type="text"
                  formikProps={props}
                  maxLength="100"
                  dataTestid="InputAddressState"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <Input
                  label={intlPersonal.LABEL_CITY}
                  name="affected_person.address.locality"
                  type="text"
                  formikProps={props}
                  maxLength="100"
                  dataTestid="InputAddressLocality"
                />
              </div>
              <div className="col-12 col-lg-6">
                <Input
                  label={intlPersonal.LABEL_ADDRESS}
                  name="affected_person.address.street_address"
                  type="text"
                  formikProps={props}
                  maxLength="255"
                  dataTestid="InputAddressStreetAddress"
                />
              </div>
              <div className="col-12 col-lg-6">
                <Input
                  label={intlPersonal.LABEL_SUBDIVISION}
                  name="affected_person.address.subdivision"
                  type="text"
                  formikProps={props}
                  maxLength="100"
                  dataTestid="InputAddressSubdivision"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PersonalArea;
