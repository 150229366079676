import React, { useContext } from 'react';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './Support.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Item from './Item/Item';
import { clickChat } from '../../../utils/elements';
import HelpChatIcon from '../../../assets/icons/HelpChatIcon';
import HelpMessengerIcon from '../../../assets/icons/HelpMessengerIcon';
import HelpEmailIcon from '../../../assets/icons/HelpEmailIcon';
import { SHOW_MESSENGER } from '../../../utils/environments_variables';

const clickMessenger = () => {
  window.FB.CustomerChat.show(true);
};

const Support = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.container}>
      <div className={`${styles.banner} ${styles[themes]}`} />
      <h1 className={`${styles.title} ${styles[themes]}`}>
        {intl.SUPORT_TITLE}
      </h1>
      <h6 className={`${styles.subtitle} ${styles[themes]}`}>
        {intl.SUPORT_SUBTITLE}
      </h6>

      <div className={styles.contentSupport}>
        <Item
          title={intl.SUPORT_CHAT_US}
          icon={<HelpChatIcon />}
          click={clickChat}
          GTM_type="Chat bot Help"
          GTM_id="CTA Chat bot"
        />

        {SHOW_MESSENGER && (
          <Item
            title={intl.FACEBOOK_MESSENGER}
            icon={<HelpMessengerIcon />}
            click={clickMessenger}
            GTM_type="Facebook Help"
            GTM_id="CTA Facebook Messenger"
          />
        )}

        <Item
          title={intl.SUPORT_EMAIL}
          icon={<HelpEmailIcon />}
          info="benefits@ap-visa.com"
          email
          GTM_type="Email Help"
          GTM_id="CTA Email"
        />
      </div>
    </div>
  );
};

export default Support;
