/* eslint-disable no-unused-vars */
export const fraudulentChargesFormInitialValues = (
  data,
  idiom,
  completeClaim
) => {
  let person = data && data.person ? data.person : '';
  let otherInsuranceCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[0]
      : [{}];
  return {
    incident: {
      kind: 'FRAUDULENT_USE',
      date: '',
      bank_declaration_date: '',
      description: '',
      expenses: [
        {
          type: 'PURCHASE_UNAUTHORIZED',
          date: '',
          description: '',
          amount: {
            value: '',
            currency: '',
          },
        },
      ],
    },
    affected_person: {
      relationshipCardholder: true,
      relationship: 'Cardholder',
      comment: '',
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      },
    },
    total_amount_claimed: {
      value: '',
      currency: '',
    },
    amount_estimation_damage: {
      value: '',
      currency: '',
    },
    other_insurance_covering: {
      is_internal:
        otherInsuranceCovering && otherInsuranceCovering.is_internal
          ? otherInsuranceCovering.is_internal
          : 'false',
      checked:
        otherInsuranceCovering &&
        otherInsuranceCovering.is_internal !== undefined
          ? String(!otherInsuranceCovering.is_internal)
          : 'false',
      description:
        otherInsuranceCovering && otherInsuranceCovering.description
          ? otherInsuranceCovering.description
          : '',
      value:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.value
          ? otherInsuranceCovering.amount.value
          : '',
      currency:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.currency
          ? otherInsuranceCovering.amount.currency
          : '',
    },
  };
};
