import { pt_nationalities } from '../../../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../../../intl/es_nationalities';

export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};
export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};
export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const ACCIDENT_REASON = {
  en_US: 'Accident reason',
  pt_BR: 'Motivo do incidente',
  es_ES: 'Motivo del accidente',
};

export const ACCIDENTAL_DISMEMBERMENT = {
  en_US: 'Accidental dismemberment',
  pt_BR: 'Desmembramento acidental',
  es_ES: 'Desmembramiento accidental',
};

export const ACCIDENTAL_DEATH = {
  en_US: 'Accidental death',
  pt_BR: 'Morte acidental',
  es_ES: 'Muerte accidental',
};

export const HELP_11 = {
  en_US: `Accidental dismemberment means accidental bodily injury, causing dismemberment during travel.
  Accidental death means bodily injury by accident, which caused loss of life during the trip.`,
  pt_BR: `Desmembramento acidental significa lesão corporal acidental, causando desmembramento durante a viagem.
  Morte acidental significa lesão corporal por acidente que causou a perda de vidas durante a viagem.`,
  es_ES: `Desmembramiento accidental significa lesión corporal accidental, causando desmembramiento durante el viaje.
  Muerte accidental significa lesión corporal por accidente, lo que causó la pérdida de vidas durante el viaje.`,
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATING_CEP = {
  en_US: 'Validating postal Code...',
  pt_BR: 'Validando CEP...',
  es_ES: 'Validar código postal...',
};

export const LABEL_OCCUPATION = {
  en_US: 'Profession *',
  pt_BR: 'Profissão *',
  es_ES: 'Profesión *',
};

export const TEXT_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};
