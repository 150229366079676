export const STATUS_PENDING = {
  en_US: 'Pending',
  es_ES: 'Pendiente',
  pt_BR: 'Pendente',
};

export const TEXT_APPEAL = {
  en_US: 'Appeal',
  pt_BR: 'Apelar',
  es_ES: 'Apelar',
};

export const STATUS_REVIEW = {
  en_US: 'UPLOADED',
  es_ES: 'SUBIDO',
  pt_BR: 'ENVIADO',
};

export const STATUS_COMPLETE = {
  en_US: 'Completed',
  es_ES: 'Terminado',
  pt_BR: 'Concluído',
};

export const STATUS_REJECTED = {
  en_US: 'Rejected',
  es_ES: 'Rechazado',
  pt_BR: 'Rejeitado',
};

export const STATUS_CLOSED = {
  en_US: 'Closed',
  es_ES: 'Cerrado',
  pt_BR: 'Fechado',
};

export const TEXT_ALL_CLAIMS = {
  en_US: 'View all claims',
  es_ES: 'Ver todos los reclamos',
  pt_BR: 'Ver todos os sinistros',
};

export const TEXT_CREATED = {
  en_US: 'Creation date: ',
  es_ES: 'Fecha de creación: ',
  pt_BR: 'Data de criação: ',
};

export const TEXT_VIEW_PDF = {
  en_US: 'Send PDF',
  es_ES: 'Enviar PDF',
  pt_BR: 'Enviar PDF',
};

export const TEXT_CREATE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};

export const TITLE_GENERATE_PDF = {
  en_US: 'Receive the certificate PDF in your email',
  es_ES: 'Reciba el PDF del certificado en tu correo electrónico',
  pt_BR: 'Receba o PDF do certificado em seu e-mail',
};

export const SUBTITLE_GENERATE_PDF = {
  en_US: 'Do you want to receive your certificate by email?',
  es_ES: '¿Quieres recibir tu certificado por correo electrónico?',
  pt_BR: 'Deseja receber seu certificado por e-mail?',
};

export const TEXT_YES = {
  en_US: 'Yes, I do',
  es_ES: 'Sí, quiero',
  pt_BR: 'Sim, eu quero',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
export const GENERATE_PDF_SUCCESS = {
  en_US: 'Your certificate has been successfully sent to your email.',
  es_ES: 'Tu certificado ha sido enviado con éxito a tu correo electrónico.',
  pt_BR: 'Seu bilhete foi enviado para seu e-mail com sucesso.',
};

export const BTN_ADD_TO_WALLET = {
  es_ES: 'Añadir a la Cartera',
  en_US: 'Add to Wallet',
  pt_BR: 'Adicionar à Carteira',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const SENDING_YOUR_CERTIFICATE = {
  en_US: 'Sending your certificate PDF to your e-mail...',
  es_ES: 'Enviando tu certificado PDF a tu correo electrónico...',
  pt_BR: 'Enviando seu bilhete em PDF para o seu e-mail...',
};

export const TEXT_DETAIL = {
  en_US: 'Reason detail: ',
  pt_BR: 'Detalhe do motivo: ',
  es_ES: 'Detalle de la razón: ',
};

export const BTN_VIEW_CLAIM = {
  en_US: 'View claim',
  es_ES: 'Ver reclamo',
  pt_BR: 'Ver sinistro',
};

export const TITLE_CLAIM_VIEW = {
  en_US: benefitName => `View claim - ${benefitName}`,
  pt_BR: benefitName => `Visualizar sinistro - ${benefitName}`,
  es_ES: benefitName => `Ver reclamo - ${benefitName}`,
};

export const TITLE_CLAIM_HISTORY = {
  en_US: benefitName => `Claim historic - ${benefitName}`,
  pt_BR: benefitName => `Histórico do sinistro - ${benefitName}`,
  es_ES: benefitName => `Histórico del reclamo - ${benefitName}`,
};

export const TEXT_ATTACH_DOCUMENT = {
  en_US: 'Attach documents',
  pt_BR: 'Anexar documentos',
  es_ES: 'Adjuntar documentos',
};

export const TEXT_CASE_HISTORY = {
  en_US: 'Claim historic',
  pt_BR: 'Histórico do sinistro',
  es_ES: 'Histórico del reclamo',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};

export const BENEFIT_ALT = {
  en_US: name => `Benefit icon ${name}`,
  es_ES: name => `Ícono de beneficio ${name}`,
  pt_BR: name => `Ícone de beneficio ${name}`,
};

export const WARNING = {
  en_US: ' Your e-Card is not available at the moment. Please try again later.',
  es_ES:
    ' Tu tarjeta electrónica no está disponible en este momento. Por favor, inténtelo de nuevo más tarde.',
  pt_BR:
    ' Seu e-Card ainda não está disponível no momento. Por favor, tente novamente mais tarde.',
};
