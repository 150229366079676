import React, { useContext } from 'react';
import moment from 'moment';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import styles from './InformationArea.module.scss';
import { en_US } from '../../../../../../../intl/idioms';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const InformationArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let dateFormat = idiom === en_US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  let today = moment(new Date()).format(dateFormat);

  return (
    <div className={`row ${styles.informationArea} ${styles[themes]}`}>
      <div className="col-12">
        {intl.LABEL_DATE} {today}
      </div>
      <div className="col-12">
        {intl.LABEL_PHONE}
        {parentValues &&
          parentValues.policy &&
          parentValues.policy.phone &&
          '(' +
            parentValues.policy.phone.international_prefix +
            ') ' +
            parentValues.policy.phone.number}
      </div>
      <div className="col-12">
        {intl.LABEL_PLACE}
        {parentValues &&
          parentValues.policy &&
          parentValues.policy.address &&
          parentValues.policy.address.locality +
            ', ' +
            parentValues.policy.address.state +
            ', ' +
            parentValues.policy.address.country}
      </div>
    </div>
  );
};

export default InformationArea;
