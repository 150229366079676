export const ARROW_DOWN = {
  en_US: 'Arrow Down',
  es_ES: 'Flecha Abajo',
  pt_BR: 'Seta para Baixo',
};

export const ARROW_UP = {
  en_US: 'Arrow Up',
  es_ES: 'Flecha Arriba',
  pt_BR: 'Seta para Cima',
};
