import { useRef } from 'react';
import styles from './RadioButton.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { useIntl } from '../../intl';
import * as translations from './intl';
import Tooltip from '../Tooltip/Tooltip';

const RadioButton = props => {
  const {
    text,
    value,
    name,
    onChange,
    defaultChecked,
    checked,
    dataTestid,
    dangerouslySetInnerHTML,
    img,
    customStyle,
    axa,
    label,
    describe,
    tooltip,
    children,
  } = props;
  const radioButtonRef = useRef(null);
  const { getGlobalTheme } = useTheme(axa);
  const theme = getGlobalTheme();

  const { translate } = useIntl();
  const intl = translate(translations);

  const checkWithEnter = event => {
    if (event.key === 'Enter' && radioButtonRef.current) {
      radioButtonRef.current.click();
    }
  };

  const describeContent = describe
    ? `${intl.DESCRIBE_INFO_CONTENT} ${describe}`
    : '';

  return (
    <label
      tabIndex={0}
      className={`${styles.container} ${styles[theme]}`}
      onKeyDown={checkWithEnter}
    >
      {dangerouslySetInnerHTML && (
        <div
          htmlFor={`radiobutton-${name}`}
          className={`${
            checked ? `${styles[theme]} ${styles.colorBlue}` : ''
          } ${styles.flexInline}`}
        >
          <div
            className={styles.labelInnerHTML}
            dangerouslySetInnerHTML={dangerouslySetInnerHTML}
          />
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      {text && (
        <div
          htmlFor={`radiobutton-${name}`}
          className={`${checked ? `${styles[theme]} ${styles.colorBlue}` : ''}`}
        >
          {text}
          {tooltip && <Tooltip text={tooltip} />}
        </div>
      )}
      {children}
      <input
        id={`radiobutton-${name}`}
        aria-label={`${label} ${text} ${describeContent}`}
        // aria-describedby={`error-${name}`}
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        defaultChecked={defaultChecked}
        checked={checked}
        data-testid={dataTestid}
        className={customStyle}
        tabIndex={-1}
        ref={radioButtonRef}
      />
      <span className={`${styles[theme]} ${styles.checkmark}`} />
      {img && <img src={img} alt="" />}
    </label>
  );
};

export default RadioButton;
