import { ChangeEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './Auth0Layout.module.scss';
import Logo from '../../components/Logo/Logo';
import ChevronLeftIcon from '../../assets/icons/ChevronLeftIcon';
import Select from '../../components/Select/Select';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import { useIntl } from '../../intl';
import * as translations from './intl';
import { en_US, es_ES, pt_BR } from '../../intl/idioms';
import Link from '../../componentsV2/Link/Link';

const Auth0Layout = ({ children, thirdBlue }) => {
  const { getGlobalTheme } = useTheme();
  const { idiom, setIdiom, translate } = useIntl();

  const intl = translate(translations);
  const themes = getGlobalTheme();

  const handleChangeIdiom = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setIdiom(value);
  };

  return (
    <>
      <div
        className={`${styles.container} ${styles[themes]} ${
          thirdBlue ? styles.thirdBlue : ''
        }`}
      >
        <header className={styles.auth0Header}>
          <RouterLink to="/" className={styles.logoLink}>
            <ChevronLeftIcon />
            <Logo size="BIGGER" color="#3c4646" />
          </RouterLink>

          <div className={`${styles.selectWrapper} ${styles[themes]}`}>
            <Select value={idiom} onChange={handleChangeIdiom}>
              <option value={en_US}>{intl.OPTION_LANGUAGE_EN}</option>
              <option value={pt_BR}>{intl.OPTION_LANGUAGE_PT}</option>
              <option value={es_ES}>{intl.OPTION_LANGUAGE_ES}</option>
            </Select>
          </div>
        </header>

        <div className={styles.body}>{children}</div>
      </div>

      <footer className={`${styles[themes]} ${styles.footer}`}>
        <Logo size="MEDIUM" color="#3c4646" />

        <div className={styles.linkArea}>
          <Link
            href={intl.LINK_TERMS_OF_SERVICE}
            label={intl.TERMS_OF_SERVICE}
          />
          <Link href="https://visa.com/privacy" label={intl.PRIVACY_POLICY} />
        </div>

        <Link href="https://www.visa.com/" label="visa.com" />
      </footer>
    </>
  );
};

export default Auth0Layout;
