export const TEXT_BIN = {
  en_US: 'BIN is made by the first 10 digits on the front of your card. ',
  es_ES: 'BIN is made by the first 10 digits on the front of your card. ',
  pt_BR: 'BIN is made by the first 10 digits on the front of your card. ',
};

export const SUCCESS = {
  en_US: 'Success',
  es_ES: 'Éxito',
  pt_BR: 'Sucesso',
};

export const ERROR_CREDIT_CARD = {
  en_US: 'Error Credit Card',
  es_ES: 'Error en Tarjeta de Crédito',
  pt_BR: 'Erro no Cartão de Crédito',
};

export const QUESTION = {
  en_US: 'Question',
  es_ES: 'Pregunta',
  pt_BR: 'Pergunta',
};

export const ERROR = {
  en_US: 'Error',
  es_ES: 'Error',
  pt_BR: 'Erro',
};

export const SUBTITLE = {
  en_US: 'Subtitle',
  es_ES: 'Subtítulo',
  pt_BR: 'Subtítulo',
};
