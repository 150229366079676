const user = () => ({
  logged: false,
  data: {},
});

const maintenance = () => ({
  status: 0,
});

const alert = () => ({
  message: '',
  type: 'error',
  show: false,
});

const modal = () => ({
  title: '',
  show: false,
  icon: '',
  loadingMessage: '',
  error: null,
});

const cards = () => ({
  status: null,
  data: [],
  selectedCardIndex: 0,
});

const loadingFullScreen = () => ({
  show: false,
});

export const initialState = () => ({
  user: user(),
  cards: cards(),
  maintenance: maintenance(),
  alert: alert(),
  modal: modal(),
  loadingFullScreen: loadingFullScreen(),
});
