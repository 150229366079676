export const CLAIM_APPEAL = {
  en_US: 'Appeal information',
  es_ES: 'Información de apelación',
  pt_BR: 'Informações de apelação',
};

export const LABEL_APPEAL = {
  en_US: 'Appeal',
  es_ES: 'Apelar',
  pt_BR: 'Apelar',
};

export const LABEL_ATTACH_DOCUMENTS = {
  en_US: 'Attach documents',
  es_ES: 'Adjuntar documentos',
  pt_BR: 'Anexar documentos',
};

export const LABEL_YOUR_CLAIMS = {
  en_US: 'Your claims',
  pt_BR: 'Seus sinistros',
  es_ES: 'Tus reclamos',
};
export const BTN_FINISH = {
  en_US: 'Finish',
  es_ES: 'Terminar',
  pt_BR: 'Concluir',
};

export const SUBMITTING_APPEAL = {
  en_US: 'Registering appeal...',
  pt_BR: 'Registrando apelação... ',
  es_ES: 'Registrando apelación...',
};

export const TITLE_APPEAL_SUCCESS = {
  en_US: 'Your appeal is almost complete!',
  pt_BR: 'Sua apelação está quase completa!',
  es_ES: 'Tu apelación está casi completa!',
};

export const SUBTITLE_APPEAL_SUCCESS_REQUIRED_DOCUMENTS_ATTACHED = {
  en_US:
    'You can attach more documents using the optional field, otherwise click finish to conclude your request.',
  pt_BR:
    'Você pode anexar mais documentos utilizando o campo opcional, caso contrário, clique em concluir para finalizar seu pedido.',
  es_ES:
    'Tu puedes adjuntar más documentos utilizando el campo opcional, de lo contrario, haga clic en concluir para finalizar tu pedido.',
};
export const SUBTITLE_APPEAL_SUCCESS_REQUIRED_DOCUMENTS_MISSING = {
  en_US:
    'You must attach all required documents in order to complete your appeal.',
  pt_BR:
    'Você deve anexar todos os documentos obrigatórios para que seja possível concluir sua apelação.',
  es_ES:
    'Debe adjuntar todos los documentos requeridos para completar tu apelación.',
};

export const UNEXPECTED_ERROR = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const ALREADY_OPEN_ERROR = {
  en_US: 'Only a rejected claim can be appealed.',
  pt_BR: 'Apenas um sinistro rejeitado pode ser apelado.',
  es_ES: 'Solo se puede apelar un reclamo rechazado.',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_FIELD_VALIDATION_2000 = {
  en_US: 'Please enter a maximum of 2000 characters.',
  pt_BR: 'Por favor, insira no máximo 2000 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 2000 caracteres.',
};

export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const HAS_BEEN_SENT = {
  en_US:
    'Congratulations! Your appeal request has been successfully completed!',
  pt_BR: 'Parabéns! O seu pedido de apelação foi concluído com sucesso!',
  es_ES:
    '¡Felicitaciones! ¡Su solicitud de apelación se ha completado con éxito!',
};

export const HAS_BEEN_SENT_SUBTITLE = {
  en_US:
    'In the next 3 business days, a member of our team will send an update to the email address registered to your account.',
  pt_BR:
    'Nos próximos 03 dias úteis, um membro de nossa equipe enviará uma atualização para o e-mail registrado em sua conta.',
  es_ES:
    'En los próximos 3 días hábiles, un miembro de nuestro equipo le enviará una actualización a la dirección de correo electrónico registrada en su cuenta.',
};

export const BEEN_SENT_SUBTITLE_INCONVENIENCE = {
  en_US:
    'Dear, we are experiencing a high volume of claims, so there may be a delay in evaluating it. We are working to improve this experience. We regret the inconvenience.',
  pt_BR:
    'Prezado, estamos enfrentando um grande volume de sinistros, portanto, poderá haver algum atraso na resposta de avaliação do mesmo. Estamos trabalhando para melhorar esta experiência e pedimos desculpas pelo transtorno.',
  es_ES:
    'Estimado, estamos experimentado un alto volumen de reclamos, por lo que puede haber un atraso en la evaluación del mismo. Estamos trabajando para mejorar esta experiencia. Lamentamos el inconveniente.',
};

export const YOUR_CLAIMS = {
  en_US: 'Your claims',
  pt_BR: 'Seus sinistros',
  es_ES: 'Tus reclamos',
};

export const GO_HOME = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para a Página principal',
  es_ES: 'Ir a la Página de inicio',
};

export const COMPLETING_YOUR_CLAIM = {
  en_US: 'Completing',
  pt_BR: 'Concluindo',
  es_ES: 'Completando',
};
