import React, { useContext } from 'react';
import styles from './BannerAXAMarket.module.scss';
import * as translations from './intl';
import { useHistory } from 'react-router';
import { PublicContext } from '../../../components/PublicContextParent/PublicContextParent';
import { IntlContext } from '../../../intl';
import { enableUpselling } from '../../../utils/verifyAccess';
import { enableCrosselling } from '../../../utils/verifyAccessCrosselling';
import { CREATE_CONFIG_B2B2C } from '../../B2B2C/CONFIG_B2B2C';
import backgroundB2B2C from '../../../assets/images/B2B2C/AXABanner-Home.jpg';
import Button from '../../../componentsV2/Button/Button';
import { Link } from 'react-router-dom';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';

const BannerAXAMarket = props => {
  const { productActive } = useContext(PublicContext);
  const { utils } = useContext(StateContext);
  const { country, translate } = useContext(IntlContext);
  const history = useHistory();
  const intl = translate(translations);
  const selectedCard = utils.getSelectedCard();
  const isLogged = utils.isUserLogged();

  const B2B2C_Type = () => {
    let cardName = null;
    if (props.privateBenefits || isLogged) {
      cardName = selectedCard.name;
    } else {
      cardName = productActive.name;
    }
    if (!cardName) {
      return '';
    }
    if (enableUpselling(cardName, country, '')) {
      return 'upsell';
    } else if (enableCrosselling(cardName, country, '')) {
      return 'cross-sell';
    } else {
      return '';
    }
  };

  const CONFIG_B2B2C = CREATE_CONFIG_B2B2C({ path: B2B2C_Type() });
  const config =
    CONFIG_B2B2C && CONFIG_B2B2C.homeMarket && CONFIG_B2B2C.homeMarket(intl);

  if (!config) {
    return <></>;
  }

  return (
    <div
      className={
        props.privateBenefits ? styles.shortContainer : styles.container
      }
      style={{ backgroundImage: `url(${backgroundB2B2C})` }}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{config.title}</h2>
        <Button
          type="Primary"
          surface="Inverse"
          onClick={() => {
            history.push(config.link);
          }}
          label={config.button}
        />
      </div>
      {!props.privateBenefits && (
        <footer>
          <Link
            to={`/terms/${B2B2C_Type()}/EMC_Emergency_Medical_Assistance/MX`}
            target="_blank"
            rel="noopener noreferrer"
            dangerouslySetInnerHTML={{ __html: intl.TERMS_AND_CONDITIONS }}
            aria-label={intl.NEW_TAB}
            style={{ textDecoration: 'underline' }}
          />
        </footer>
      )}
    </div>
  );
};

export default BannerAXAMarket;
