import React from 'react';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import IncidentPeriodArea from './IncidentPeriodArea/IncidentPeriodArea';
import AirlineArea from './AirlineArea/AirlineArea';
import InsuranceArea from './InsuranceArea/InsuranceArea';

const BaggageDelay = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <IncidentPeriodArea claimDetails={claimDetails} />
      <AirlineArea claimDetails={claimDetails} />
      <InsuranceArea claimDetails={claimDetails} />
    </div>
  );
};

export default BaggageDelay;
