import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const MicrophoneDisabledIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...handledProps}
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Dribbble-Light-Preview"
          transform="translate(-260.000000, -4039.000000)"
          fill={colors.primary}
        >
          <g id="icons" transform="translate(56.000000, 160.000000)">
            <path d="M220.039326,3887.25993 C219.915931,3890.89763 217.143058,3892.64553 214.285915,3892.75024 L212.422953,3894.50597 C212.624599,3894.5412 212.826244,3894.51184 213.028892,3894.53435 L213.028892,3897.04267 L210.019262,3897.04267 L210.019262,3899 L218.044944,3899 L218.044944,3897.04267 L215.035313,3897.04267 L215.035313,3894.53435 C218.686998,3894.13408 222.057785,3891.17068 222.057785,3887.25602 C221.677568,3887.25602 220.417536,3887.25406 220.039326,3887.25993 L220.039326,3887.25993 Z M208.737159,3889.9816 C208.283708,3889.16344 208.012841,3888.23468 208.012841,3887.25602 L206.006421,3887.25602 C206.006421,3888.23468 206.473917,3890.38579 207.247392,3891.55725 L208.737159,3889.9816 Z M212.025682,3886.52887 L212.025682,3881.38403 L216.038523,3881.38403 L216.038523,3882.61421 L218.044944,3880.65688 L218.044944,3879.4267 L210.019262,3879.4267 C210.019262,3887.47426 209.915931,3887.22079 210.313202,3888.20043 L212.025682,3886.52887 Z M224,3880.38383 L205.418539,3898.51067 L204,3897.12683 L222.580457,3879 L224,3880.38383 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default MicrophoneDisabledIcon;
