import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';
import { pt_countries } from '../../../../../../../../intl/pt_countries';

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};

export const TEXT_START_DATE_TRIP = {
  en_US: 'Start date of trip *',
  es_ES: 'Fecha de inicio del viaje *',
  pt_BR: 'Data de início da viagem *',
};

export const TEXT_END_DATE_TRIP = {
  en_US: 'End date of trip *',
  es_ES: 'Fecha de finalización del viaje *',
  pt_BR: 'Data de fim da viagem *',
};

export const TEXT_DATE_LOSS = {
  en_US: 'Date of loss *',
  es_ES: 'Fecha de la pérdida *',
  pt_BR: 'Data da perda *',
};

export const TEXT_TIME_LOSS = {
  en_US: 'Time of loss *',
  es_ES: 'Hora de la pérdida *',
  pt_BR: 'Hora da perda *',
};

export const TEXT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Describa el incidente',
  pt_BR: 'Descreva o incidente',
};

export const TEXT_THIRD_PARTY_RESIDENCE_ADDRESS = {
  en_US: 'Third party home address',
  es_ES: 'Dirección de domicilio de terceros',
  pt_BR: 'Endereço residencial do terceiro',
};

export const FIELD_ADDRESS_COUNTRY = {
  en_US: 'Country *',
  es_ES: 'País *',
  pt_BR: 'País *',
};

export const FIELD_ADDRESS_STATE = {
  en_US: 'State *',
  es_ES: 'Estado *',
  pt_BR: 'Estado *',
};

export const FIELD_ADDRESS_CITY = {
  en_US: 'City *',
  es_ES: 'Ciudad *',
  pt_BR: 'Cidade *',
};

export const FIELD_ADDRESS_POSTAL_CODE = {
  en_US: 'Postal code *',
  es_ES: 'Código postal *',
  pt_BR: 'CEP *',
};

export const FIELD_ADDRESS_STREET = {
  en_US: 'Address *',
  es_ES: 'Calle *',
  pt_BR: 'Rua *',
};

export const FIELD_ADDRESS_NEIGHBORHOOD = {
  en_US: 'Neighborhood *',
  es_ES: 'Barrio/Colonia *',
  pt_BR: 'Bairro *',
};
