import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Input from '../../../../../../../components/Input/Input';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import RadioButton from '../../../../../../../components/RadioButton/RadioButton';
import styles from './AccountHolderArea.module.scss';
import Select from '../../../../../../../components/Select/Select';
import { IntlContext } from '../../../../../../../intl';
import { pt_BR } from '../../../../../../../intl/idioms';
import * as translations from '../intl';
import * as constants from '../constants';
import { safeGet } from '../../../../../../../utils/object';
import { callCepService } from '../../../../../../../utils/postalCode';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../utils/sanitize';
import PhoneForm from '../../../../../../../components/PhoneForm';
import GroupDivisor from '../../../../../../../components/GroupDivisor';

const fieldsPostalCode = {
  uf: 'BankAccountHolder.address.state',
  localidade: 'BankAccountHolder.address.locality',
  logradouro: 'BankAccountHolder.address.street_address',
  bairro: 'BankAccountHolder.address.subdivision',
};

const AccountHolderArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
  } = props;
  const validationPrefix = 'maskedPrefixPhone';
  const [cepStatus, setCepStatus] = useState();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let getYesterday = moment(new Date(), 'YYYY-MM-DD HH:ss').subtract(1, 'days');
  let yesterday = new Date(getYesterday);
  let maskPostalCode =
    values.BankAccountHolder.address.country === 'BR'
      ? 'maskedPostalCodeBr'
      : 'maskedPostalCode';
  const isCountryBr = values.BankAccountHolder.address.country === 'BR';

  useEffect(() => {
    if (
      values.BankAccountHolder &&
      values.BankAccountHolder.address &&
      values.BankAccountHolder.address.postal_code &&
      isCountryBr
    ) {
      cepService(values.BankAccountHolder.address.postal_code);
    }
  }, []); //eslint-disable-line

  const cepService = cep => {
    callCepService(
      cep,
      setCepStatus,
      'BankAccountHolder.address.invalidCep',
      setFieldValue,
      fieldsPostalCode
    );
  };

  const handleCepBlur = e => {
    handleBlur(e);
    if (isCountryBr) {
      if (e.target.value === 9) {
        values.BankAccountHolder.address.invalidCep = true;
      }
      cepService(e.target.value);
    }
  };

  const clearCepSearch = () => {
    setCepStatus();
  };

  let cepError, cepWaiting;
  if (cepStatus === 'LOADING') {
    cepWaiting = 'Validando CEP...';
  } else if (cepStatus === 'INVALID') {
    cepError = 'Por favor, insira um CEP válido.';
  } else {
    cepError =
      errors.BankAccountHolder &&
      errors.BankAccountHolder.address &&
      errors.BankAccountHolder.address.postal_code;
  }
  useEffect(() => {
    if (values.BankAccountHolder.person.nationalities === 'BR') {
      setFieldValue(
        'BankAccountHolder.person_registration.registration_type',
        'CPF'
      );
    }
    if (
      values.BankAccountHolder.person.nationalities !== 'BR' &&
      values.BankAccountHolder.person_registration.registration_type === 'CPF'
    ) {
      setFieldValue(
        'BankAccountHolder.person_registration.registration_type',
        'PASSPORT'
      );
    }
  }, [
    values.BankAccountHolder.person.nationalities,
    values.BankAccountHolder.person_registration.registration_type,
    setFieldValue,
  ]);

  return (
    <div data-testid="accountHolderArea">
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-sm-10 col-md-4 col-lg-4">
          <Input
            value={values.BankAccountHolder.person.first_name}
            label={intl.FORM_LABEL_ACCOUNT_HOLDER_NAME}
            name="BankAccountHolder.person.first_name"
            onChange={handleChange}
            type="text"
            touched={safeGet(touched, 'BankAccountHolder.person.first_name')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.person.first_name')}
            maxLength="40"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
          <Input
            value={values.BankAccountHolder.person.last_name}
            label={intl.FORM_LABEL_ACCOUNT_HOLDER_LASTNAME}
            name="BankAccountHolder.person.last_name"
            onChange={handleChange}
            type="text"
            touched={safeGet(touched, 'BanfkAccountHolder.person.last_name')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.person.last_name')}
            maxLength="80"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
          <Calendar
            label={intl.LABEL_BIRTH_DATE}
            name="BankAccountHolder.person.birth_date"
            dataTestid="InputClaimCHBirthdateBank"
            maxDate={yesterday}
            formikProps={props}
            setFieldTouched={setFieldTouched}
          />
        </div>
      </div>

      <div className={`row ${styles.section}`}>
        <div className="col-12 col-sm-2 col-md-2 col-lg-2">
          <Select
            label={intl.LABEL_GENDER}
            name="BankAccountHolder.person.gender"
            formikProps={props}
          >
            <option value="">{intl.LABEL_GENDER}</option>
            <option value={constants.MALE_VALUE}>{intl.LABEL_MALE}</option>
            <option value={constants.FEMALE_VALUE}>{intl.LABEL_FEMALE}</option>
            <option value={constants.UNKNOWN_VALUE}>
              {intl.LABEL_UNKNOWN}
            </option>
          </Select>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
          <Input
            value={values.BankAccountHolder.profession}
            label={intl.FORM_LABEL_PROFESSION}
            name="BankAccountHolder.profession"
            type="text"
            touched={safeGet(touched, 'BankAccountHolder.profession')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.profession')}
          />
        </div>
      </div>

      <div className={`row ${styles.section}`}>
        <div className="col-12 col-sm-12 col-md-6">
          <Select
            label={intl.NATIONALITY_LABEL}
            value={values.BankAccountHolder.person.nationalities}
            name="BankAccountHolder.person.nationalities"
            dataTestid="InputClaimCHNationalitiesBank"
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.NATIONALITY_OPTIONS),
            }}
            touched={safeGet(touched, 'BankAccountHolder.person.nationalities')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.person.nationalities')}
          />
        </div>
        {/* ADD Logic */}
        {values.BankAccountHolder.person.nationalities === 'BR' ? (
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              value={values.BankAccountHolder.person_registration.value}
              label={intl.LABEL_CPF}
              name="BankAccountHolder.person_registration.value"
              type="text"
              tooltipText={intl.TOOLTIP_FOR_MINORS}
              touched={safeGet(
                touched,
                'BankAccountHolder.person_registration.value'
              )}
              mask={'maskedCPF'}
              onChange={handleChange}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'BankAccountHolder.person_registration.value'
              )}
            />
          </div>
        ) : (
          <div className="col-12 col-sm-12 col-md-6" style={{ padding: 0 }}>
            <div className="row" style={{ margin: 0 }}>
              <div className="col-4 col-sm-12 col-md-6">
                <Select
                  label={intl.LABEL_IDENTIFICATION}
                  value={
                    values.BankAccountHolder.person_registration
                      .registration_type
                  }
                  name="BankAccountHolder.person_registration.registration_type"
                  onChange={handleChange}
                  touched={
                    touched.BankAccountHolder &&
                    touched.BankAccountHolder.person_registration &&
                    touched.BankAccountHolder.person_registration
                      .registration_type
                  }
                  onBlur={handleBlur}
                  error={
                    errors.BankAccountHolder &&
                    errors.BankAccountHolder.person_registration &&
                    errors.BankAccountHolder.person_registration
                      .registration_type
                  }
                >
                  <option value="">{intl.LABEL_IDENTIFICATION + ' *'}</option>
                  <option value="PASSPORT">{intl.LABEL_PASSPORT}</option>
                  <option value="ID_CARD">{intl.LABEL_NATIONAL_ID}</option>
                </Select>
              </div>
              <div className="col-8 col-sm-12 col-md-6">
                <Input
                  value={values.BankAccountHolder.person_registration.value}
                  label={
                    values.BankAccountHolder.person_registration
                      .registration_type === 'CPF'
                      ? intl.LABEL_CPF
                      : values.BankAccountHolder.person_registration
                          .registration_type === 'ID_CARD'
                      ? intl.LABEL_NATIONAL_ID
                      : intl.LABEL_PASSPORT
                  }
                  mask={'maskedPassaport'}
                  name="BankAccountHolder.person_registration.value"
                  type="text"
                  dataTestid="InputCHRegistrationValue"
                  onChange={handleChange}
                  tooltipText={
                    values.BankAccountHolder.person_registration
                      .registration_type === 'ID_CARD'
                      ? intl.TEXT_ALERT_NATIONAL_ID
                      : intl.TEXT_ALERT_PASSPORT
                  }
                  touched={
                    touched.BankAccountHolder &&
                    touched.BankAccountHolder.person_registration &&
                    touched.BankAccountHolder.person_registration.value
                  }
                  onBlur={handleBlur}
                  error={
                    errors.BankAccountHolder &&
                    errors.BankAccountHolder.person_registration &&
                    errors.BankAccountHolder.person_registration.value
                  }
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <PhoneForm
        formikProps={props}
        className={styles.section}
        inputNames={{
          phoneType: 'BankAccountHolder.phone.phone_type',
          countryCode: 'BankAccountHolder.phone.international_prefix',
          phoneNumber: 'BankAccountHolder.phone.number',
        }}
      />
      <div className={`row ${styles.section}`}>
        {idiom === pt_BR && (
          <>
            <div
              className={`${styles.labelOwnerCardHolder} col-12 col-sm-12 col-md-6`}
            >
              <div className={`${styles.fontGray12} ${styles[themes]} row`}>
                <div className={`col-lg-12 col-sm-12`}>
                  <label className={`${styles.labelTitle} ${styles[themes]}`}>
                    {intl.FORM_LABEL_EXPOSED_PERSON}
                  </label>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <RadioButton
                    label={intl.FORM_LABEL_EXPOSED_PERSON}
                    text={intl.TXT_YES}
                    name="BankAccountHolder.is_politically_exposed"
                    value={true}
                    defaultChecked={
                      values.BankAccountHolder.is_politically_exposed === true
                    }
                    onChange={handleChange}
                    error={safeGet(
                      errors,
                      'BankAccountHolder.is_politically_exposed'
                    )}
                  />
                </div>
                <div className="col-lg-3 col-sm-12">
                  <RadioButton
                    label={intl.FORM_LABEL_EXPOSED_PERSON}
                    text={intl.TXT_NO}
                    name="BankAccountHolder.is_politically_exposed"
                    value={false}
                    defaultChecked={
                      values.BankAccountHolder.is_politically_exposed === false
                    }
                    onChange={handleChange}
                    error={safeGet(
                      errors,
                      'BankAccountHolder.is_politically_exposed'
                    )}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <div className={`row ${styles.section}`}>
        <div className="col-12">
          <GroupDivisor title={intl.TEXT_ACCOUNT_HOLDER_ADDRESS} />
        </div>
        <div className="col-12 col-sm-12 col-md-6  col-lg-4">
          <Select
            label={intl.FORM_LABEL_COUNTRY}
            value={values.BankAccountHolder.address.country}
            name="BankAccountHolder.address.country"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.FORM_LABEL_COUNTRY}</option>` +
                  intl.COUNTRY_OPTIONS
              ),
            }}
            touched={safeGet(touched, 'BankAccountHolder.address.country')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.address.country')}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-2">
          <Input
            value={values.BankAccountHolder.address.postal_code}
            label={intl.FORM_LABEL_ZIP_CODE}
            name="BankAccountHolder.address.postal_code"
            type="text"
            onChange={handleChange}
            dataTestid="InputClaimCHPostalCodeBank"
            mask={maskPostalCode}
            touched={safeGet(touched, 'BankAccountHolder.address.postal_code')}
            onBlur={handleCepBlur}
            error={cepError}
            bottomLabel={cepWaiting}
            onFocus={clearCepSearch}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
          <Input
            value={values.BankAccountHolder.address.state}
            label={intl.FORM_LABEL_STATE}
            name="BankAccountHolder.address.state"
            type="text"
            touched={safeGet(touched, 'BankAccountHolder.address.state')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.address.state')}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
          <Input
            value={values.BankAccountHolder.address.locality}
            label={intl.FORM_LABEL_CITY}
            name="BankAccountHolder.address.locality"
            type="text"
            touched={safeGet(touched, 'BankAccountHolder.address.locality')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.address.locality')}
          />
        </div>
      </div>
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-lg-6">
          <Input
            value={values.BankAccountHolder.address.street_address}
            label={intl.FORM_LABEL_ADDRESS}
            name="BankAccountHolder.address.street_address"
            type="text"
            touched={safeGet(
              touched,
              'BankAccountHolder.address.street_address'
            )}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.address.street_address')}
          />
        </div>
        <div className="col-12 col-lg-6">
          <Input
            value={values.BankAccountHolder.address.subdivision}
            label={intl.FORM_LABEL_NEIGHBORHOOD}
            name="BankAccountHolder.address.subdivision"
            type="text"
            touched={safeGet(touched, 'BankAccountHolder.address.subdivision')}
            onChange={handleChange}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccountHolder.address.subdivision')}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountHolderArea;
