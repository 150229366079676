export const TEXT_CANCELLATION_DATE = {
  en_US: 'Cancellation date',
  es_ES: 'Fecha de cancelación',
  pt_BR: 'Data de cancelamento da viagem',
};

export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  es_ES: 'Período de viaje',
  pt_BR: 'Período da viagem',
};

export const TEXT_DATE_INCIDENT = {
  en_US: 'Incident date',
  es_ES: 'Fecha del incidente',
  pt_BR: 'Data do incidente',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const TEXT_FLIGHT_NUMBER = {
  en_US: 'Flight number or vessel name or registry code',
  es_ES: 'Número de vuelo o nombre del barco o código de registro',
  pt_BR: 'Número de vôo ou nome da embarcação ou código de registro',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Incident place',
  es_ES: 'Lugar del incidente',
  pt_BR: 'Lugar do incidente',
};
