import BankInformation from './BankInformation';
import OCTInformation from './OCTInformation/OCTInformation';
import { TRANSFERED_TYPE_OCT } from '../../Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/oct-const';

const BankIdentification = ({ payment }) => {
  if (payment && payment.preferred_transfer_type === TRANSFERED_TYPE_OCT) {
    return <OCTInformation paymentMethod={payment} />;
  }

  return <BankInformation bank={payment} />;
};

export default BankIdentification;
