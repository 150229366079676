import { geoIpService } from '../services';

export const loadGeoIp = async (
  setCountryFromGeoIp,
  _geoIpService = geoIpService
) => {
  try {
    const response = await _geoIpService.getIpInfo();
    const { country, test } = response.data;
    if (!test) setCountryFromGeoIp(country);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};
