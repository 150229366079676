import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './MenuCards.module.scss';
import MakeSubmenu from './Submenu';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { INDIVIDUAL, ENTERPRISE } from '../consts';
import BankInfoIcon from '../../../../assets/icons/BankInfoIcon';
import UserIcon from '../../../../assets/icons/UserIcon';
import Button from '../../../../componentsV2/Button/Button';

const MenuCards = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { setClientType, setSelected, clientType, selected } = props;

  return (
    <div className={`col-12 col-lg-3 ${styles.menuCards}`}>
      <div className={`${styles.menuList} ${styles[themes]}`}>
        <ul>
          <li>
            <Button
              className={clientType === INDIVIDUAL ? styles.menuActive : ''}
              size="Small"
              onClick={() => {
                setClientType(INDIVIDUAL);
                setSelected('ALL');
              }}
              label={intl.MENU_CONSUMERS}
              leadingIcon={<UserIcon alt="" />}
              type={clientType === INDIVIDUAL ? 'Secondary' : 'Tertiary'}
              autoFocus
            />
            {clientType === INDIVIDUAL && (
              <div className={styles.hideMobile}>
                <MakeSubmenu
                  selected={selected}
                  menuId="consumers"
                  {...props}
                />
              </div>
            )}
          </li>

          <li>
            <Button
              className={clientType === ENTERPRISE ? styles.menuActive : ''}
              size="Small"
              onClick={() => {
                setClientType(ENTERPRISE);
                setSelected('ALL');
              }}
              label={intl.MENU_CORPORATE}
              leadingIcon={<BankInfoIcon width={24} height={24} alt="" />}
              type={clientType === ENTERPRISE ? 'Secondary' : 'Tertiary'}
              autoFocus
            />
            {clientType === ENTERPRISE && (
              <div className={styles.hideMobile}>
                <MakeSubmenu
                  selected={selected}
                  menuId="corporate"
                  {...props}
                />
              </div>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuCards;
