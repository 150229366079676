import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import styles from './UpdateBinTokenex.module.scss';
import { IntlContext } from '../../intl/index';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import InputTokenex from './InputTokenex/InputTokenex';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import SessionContainer from '../SessionContainer/SessionContainer';
import { ALWAYS_ADD_PAN } from '../../utils/environments_variables';
import InputTokenexIframe from './InputTokenexIframe/InputTokenexIframe';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';

const UpdateBinTokenex = props => {
  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const card = utils.getSelectedCard();
  const bin = utils.getBin();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    trackEventUserAction(
      '#### (PAN) INFORMAÇÕES DO USUÁRIO NA TELA DE ATUALIZAÇÃO DO BIN',
      {
        card,
        bin,
      }
    );
  });

  return (
    <div className={`${styles.bodyContent} ${styles[themes]}`}>
      <SessionContainer>
        <h1 className={`${styles.title} ${styles[themes]}`}>
          {intl.TEXT_MORE_INFO}
        </h1>
        <div className={`${styles.infoBox} ${styles[themes]}`}>
          <div
            id="mainContent"
            className={`${styles.headerContainer} ${styles[themes]}`}
          >
            <h2 className={`${styles.header} ${styles[themes]}`}>
              {intl.TEXT_YOUR_CARD}
            </h2>
            <p>{intl.TEXT_BIN_NUMBER}</p>
          </div>
          {ALWAYS_ADD_PAN ? (
            <InputTokenex bin={bin} card={card} {...props} />
          ) : (
            <InputTokenexIframe bin={bin} card={card} {...props} />
          )}
        </div>
      </SessionContainer>
    </div>
  );
};

export default withRouter(UpdateBinTokenex);
