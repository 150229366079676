import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../components/Input/Input';
import Calendar from '../../../../../../components/Calendar/Calendar';
import * as constants from '../consts';
import Select from '../../../../../../components/Select/Select';
import RelationshipArea from '../RelationshipArea/RelationshipArea';
import styles from './PersonalArea.module.scss';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    touched,
    showRelationship = true,
  } = props;

  return (
    <>
      <div className={`row ${styles.section}`}>
        {showRelationship && (
          <div className="col-12 col-md-6 col-lg-2">
            <RelationshipArea {...props} />
          </div>
        )}
        <div className="col-12 col-md-6 ">
          <Input
            value={values.person.first_name}
            label={intl.LABEL_NAME}
            name={`person.first_name`}
            type="text"
            onChange={handleChange}
            touched={touched.person && touched.person.first_name}
            onBlur={handleBlur}
            error={errors.person && errors.person.first_name}
            dataTestid="personFirstName"
            maxLength="40"
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            value={values.person.last_name}
            label={intl.LABEL_LAST_NAME}
            name={`person.last_name`}
            type="text"
            onChange={handleChange}
            touched={touched.person && touched.person.last_name}
            onBlur={handleBlur}
            error={errors.person && errors.person.last_name}
            dataTestid="personLastName"
            maxLength="80"
          />
        </div>
      </div>
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-md-6 col-lg-3">
          <Calendar
            value={values.person.birth_date}
            label={intl.LABEL_BIRTH_DATE}
            name={`person.birth_date`}
            onChange={handleChange}
            maxDate={new Date()}
            touched={touched.person && touched.person.birth_date}
            onBlur={handleBlur}
            error={errors.person && errors.person.birth_date}
            setFieldTouched={setFieldTouched}
            dataTestid="personBirthDate"
          />
        </div>
        <div className="col-12 col-md-6 col-lg-3">
          <Select
            label={intl.LABEL_GENDER}
            value={values.person.gender}
            name={`person.gender`}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={touched.person && touched.person.gender}
            error={errors.person && errors.person.gender}
            dataTestid="personGender"
          >
            <option value="">{intl.LABEL_GENDER}</option>
            <option value={constants.MALE_VALUE}>{intl.LABEL_MALE}</option>
            <option value={constants.FEMALE_VALUE}>{intl.LABEL_FEMALE}</option>
            <option value={constants.UNKNOWN_VALUE}>
              {intl.LABEL_UNKNOWN}
            </option>
          </Select>
        </div>
        <div className="col-12 col-md-6 col-lg-6">
          <Input
            value={values.email}
            label={intl.LABEL_EMAIL}
            name={`email`}
            type="text"
            onChange={handleChange}
            touched={touched.email}
            onBlur={handleBlur}
            error={errors.email}
            dataTestid="personEmail"
          />
        </div>
      </div>
    </>
  );
};

export default PersonalArea;
