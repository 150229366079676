import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { getCountryByInitials } from '../../../../../../intl/utils';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const driver = claimDetails && claimDetails.incident.driver;

  const firstName =
    driver && driver.allOf.first_name ? driver.allOf.first_name : '-';

  const lastName =
    driver && driver.allOf.last_name ? driver.allOf.last_name : '-';

  const licenseNumber =
    driver && driver.license_number ? driver.license_number : '-';

  const licenseIssuedCountry =
    driver && driver.license_issued_country
      ? driver.license_issued_country
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_DRIVER_NAME}
          value={`${firstName} ${lastName}`}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_LICENSE_NUMBER}
          value={licenseNumber}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_COUNTRY_LICENSE}
          value={getCountryByInitials(licenseIssuedCountry, idiom)}
        />
      </div>
    </>
  );
};

export default PersonalArea;
