/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { formInitialValues } from './initialValues';
import { initValidationSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';
import ReimburserdArea from './ReimburserdArea/ReimburserdArea';

const ExtendedWarranty = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(() => initValidationSchema(intl), [intl]);
  const initialValues = useMemo(
    () => formInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FullFormCliamExtendedWarranty"
          >
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                data-testid="btnSubmitForm"
                style={{ display: 'none' }}
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <IncidentArea {...props} />
              <QuestionsArea {...props} />
              <ReimburserdArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ExtendedWarranty;
