import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { formatCustomDate } from '../../../../../../utils/date';

const PricePeriodArea = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const formatDate = date =>
    formatCustomDate(date, 'YYYY-MM-DD', idiom, country);

  const purchaseDate =
    claimDetails &&
    claimDetails.purchased_item &&
    claimDetails.purchased_item.purchase_date
      ? formatDate(claimDetails.purchased_item.purchase_date)
      : '-';

  const discoveryDate =
    claimDetails &&
    claimDetails.advertised_price &&
    claimDetails.advertised_price.discovery_date
      ? formatDate(claimDetails.advertised_price.discovery_date)
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_PURCHASE_DATE} value={purchaseDate} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ADVERTISEMENT_DATE}
          value={discoveryDate}
        />
      </div>
    </>
  );
};

export default PricePeriodArea;
