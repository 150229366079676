const step = () => 1;

const formDataCotizar = () => ({
  Quote: null,
  Plans: null,
  SelectedPlan: null,
  Terms: null,
  Travellers: {
    MPH: null,
    Passengers: null,
  },
});

const expire = () => '';

export const initialState = () => ({
  step: step(),
  formDataCotizar: formDataCotizar(),
  expire: expire(),
});
