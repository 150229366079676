import * as Yup from 'yup';
import * as constants from './consts';
import moment from 'moment';

export const satisfactionGuaranteeSchema = intl =>
  Yup.lazy(value =>
    Yup.object().shape({
      affected_person: Yup.object().shape({
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
        person: Yup.object().shape({
          first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      incident: Yup.object().shape({
        dateValue: Yup.date()
          .test('match', intl.CUSTOM_DATE_VALIDATION, function (dateValue) {
            let result = true;
            value.purchase_protection_items.map(item => {
              if (item.purchase_date) {
                if (
                  !moment(dateValue).isSameOrBefore(
                    moment(item.purchase_date).add(180, 'd')
                  )
                ) {
                  result = false;
                }
              }
            });
            return result;
          })
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID),
        description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      purchase_protection_items: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          manufacturer: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          model_number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          purchase_date: Yup.mixed().test(
            'match',
            intl.VALIDATE_DATE_VALID,
            function (purchase_date) {
              return moment(purchase_date).isSameOrBefore(new Date());
            }
          ),
          return_period: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          return_period_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          retailer_refusal_reason: Yup.string().required(
            intl.TEXT_FIELD_REQUIRED
          ),
          price: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          amount_claimed: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        })
      ),
    })
  );
