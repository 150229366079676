import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
} from 'react';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import NavHashLink from '../../components/NavHashLink/NavHashLink';
import ExternalLinkIcon from '../../assets/iconsV2/ExternalLinkIcon';
import { IconProps } from '../../@types/Icons';
import { Surface } from './Link.types';

interface ButtonImplementationBaseProps {
  id?: string;
  className: string;
  surface: Surface;
  icon: boolean;
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  axa?: boolean;
  testId?: string;
}

export interface AnchorButtonImplementationProps
  extends ButtonImplementationBaseProps {
  typeHtml?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface AnchorAnchorImplementationProps
  extends ButtonImplementationBaseProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  /**
   * Does the Link directs to an internal page (implements react-router-dom
   * Link), or external domain (implements a anchor(a) HTML tag).
   *
   * @defaultValue
   * true
   */
  internal?: boolean;
}

export const ButtonImplementation = ({
  id,
  label,
  surface,
  icon,
  className,
  ariaLabel,
  disabled,
  typeHtml = 'button',
  axa,
  testId,
  onClick,
}: AnchorButtonImplementationProps) => {
  return (
    <button
      id={id}
      className={className}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      type={typeHtml}
      data-testid={testId}
    >
      <LinkChildren
        label={label}
        surface={surface}
        icon={icon}
        disabled={disabled}
      />
    </button>
  );
};

export const AnchorImplementation = ({
  id,
  className,
  surface,
  icon,
  label,
  ariaLabel,
  disabled,
  href,
  internal = false,
  target = '_blank',
  axa,
  testId,
  onClick,
}: AnchorAnchorImplementationProps) => {
  const intl = useMemoTranslations(translations);

  type CommomProps = DetailedHTMLProps<
    AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > & { 'data-testid'?: string };

  const commomProps: CommomProps = {
    id,
    className,
    'aria-disabled': disabled,
    'aria-label': `${ariaLabel || label}${
      internal ? '' : ` - ${intl.NEW_TAB}`
    }`,
    rel: internal ? undefined : 'noopener noreferrer',
    target: internal ? undefined : target,
    onClick,
    'data-testid': testId,
  };

  const childrenProps = { label, surface, icon, disabled, axa };

  return internal ? (
    <NavHashLink {...commomProps} to={href || ''}>
      <LinkChildren {...childrenProps} />
    </NavHashLink>
  ) : (
    <a {...commomProps} href={href}>
      <LinkChildren {...childrenProps} />
    </a>
  );
};

const LinkChildren = ({
  label,
  surface,
  icon,
  disabled,
  axa,
}: {
  label: string;
  surface: IconProps['surface'];
  icon: boolean;
  disabled: boolean;
  axa?: boolean;
}) => (
  <>
    {label}
    {icon && (
      <ExternalLinkIcon
        surface={surface}
        color="Monotone"
        disabled={disabled}
        axa={axa}
      />
    )}
  </>
);
