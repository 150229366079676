import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PauseIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 17.4281V0.567187C4.5 0.253125 4.24688 0 3.92813 0H0.571875C0.253125 0 0 0.253125 0 0.567187V17.4281C0 17.7422 0.253125 18 0.571875 18H3.92813C4.24688 18 4.5 17.7469 4.5 17.4281Z"
        fill={config_icon.primary}
      />
      <path
        d="M11.4281 0H8.07188C7.75781 0 7.5 0.253125 7.5 0.567187V17.4281C7.5 17.7422 7.75313 18 8.07188 18H11.4281C11.7422 18 12 17.7469 12 17.4281V0.567187C12 0.253125 11.7469 0 11.4281 0Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};
export default PauseIcon;
