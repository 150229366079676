export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  es_ES: 'Período de viaje',
  pt_BR: 'Período da viagem',
};

export const LABEL_DATE_OF_DELAY = {
  en_US: 'Date of delay',
  es_ES: 'Fecha del retraso',
  pt_BR: 'Data de atraso',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const LABEL_REIMBURSED_BY_TRANSPORTATION_COMPANY = {
  en_US: 'Were you reimbursed for any amount by the transportation company?',
  es_ES: '¿Recibiste algún reembolso por parte de la compañía de transporte?',
  pt_BR: 'Você recebeu reembolso da empresa de transporte?',
};

export const LABEL_TOTAL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  es_ES: 'Importe total de la reivindicación',
  pt_BR: 'Valor total do sinistro',
};
