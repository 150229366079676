export const HOW_TO_CLAIM = {
  en_US: 'Access your benefit',
  es_ES: 'Accede a tu beneficio',
  pt_BR: 'Utilize seu benefício',
};

export const HOW_TO_ENJOY = {
  en_US: 'How to enjoy',
  es_ES: 'Como disfrutar',
  pt_BR: 'Como usufruir',
};

export const ACCESS_ADVANTAGE = {
  en_US: 'Access and take advantage:',
  es_ES: 'Accede y aprovecha:',
  pt_BR: 'Acesse e desfrute:',
};

export const SUBSCRIBE_EXECUTIVE_AIRPORT_TRANSPORTATION = {
  en_US: '',
  es_ES: 'Inscríbete al beneficio de transporte al aeropuerto:',
  pt_BR: '',
};

export const ACCESS_HERE = {
  en_US: 'To enjoy this benefit, access your exclusive area here:',
  es_ES: 'Para disfrutar de este beneficio, accede a tu área exclusiva aquí:',
  pt_BR: 'Para desfrutar este benefício, acesse sua área exclusiva aqui:',
};

export const SEE_MORE = {
  en_US: 'See more benefits in',
  es_ES: 'Vea más beneficios en',
  pt_BR: 'Veja mais benefícios em',
};

export const SEE_ALL_BENEFITS = {
  en_US: 'See all benefits',
  es_ES: 'Vea todos los beneficios',
  pt_BR: 'Veja todos os benefícios',
};

export const FOLLOW_STEPS = {
  en_US:
    'Redeem your benefit by opening a claim or creating a certificate. We’ve got you covered!',
  es_ES:
    'Accede a tu beneficio abriendo un reclamo o creando un certificado. ¡Estamos aquí para ayudarte!',
  pt_BR:
    'Utilize seu benefício criando um bilhete de seguro de viagem ou abrindo um sinistro. Estamos aqui para te ajudar!',
};

export const GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate ',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const OR = {
  en_US: 'or',
  es_ES: 'o',
  pt_BR: 'ou',
};

export const MAKE_CLAIM = {
  en_US: 'Open a Claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Generar un reclamo',
};

export const PREFER_CLAIM = {
  en_US: 'Or if you prefer,',
  es_ES: 'O si lo prefieres,',
  pt_BR: 'Ou se preferir,',
};

export const CHAT_TO_US = {
  en_US: 'Chat to us',
  es_ES: 'Habla con nosotros',
  pt_BR: 'Converse conosco',
};

export const YOU_CAN_SPEAK = {
  en_US:
    'You can speak to one of our consultants who will be happy to assist you. We are available from Mon-Fri 9 am-5pm via chat:',
  es_ES:
    'Puede hablar con uno de nuestros consultores que estará encantado de atenderte. Estamos disponibles de lunes a viernes de 9am a 5pm. A través del chat:',
  pt_BR:
    'Você pode falar com um de nossos consultores que ficará feliz em atendê-lo. Estamos disponíveis de Seg-Sex 9h-17h através do chat:',
};

export const HOW_TO_PURCHASE = {
  en_US: 'How to purchase?',
  es_ES: '¿Como comprar?',
  pt_BR: 'Como comprar?',
};

export const CLICK_BUTTON_BELOW = {
  en_US: 'Click the button below and get a quote:',
  es_ES: 'Haga clic en el botón de abajo y obtenga una cotización:',
  pt_BR: 'Clique no botão abaixo e faça uma cotação:',
};

export const BUY_IT = {
  en_US: 'Buy it',
  es_ES: 'Cómpralo',
  pt_BR: 'Comprar',
};

export const ACCESS_VISA_ONLINE_MEDIC = {
  en_US: 'Access Visa Online Medic ',
  pt_BR: 'Acessar Visa Médico Online ',
  es_ES: 'Acceder Visa Médico Online ',
};

export const INCREASE_COVERAGE = {
  en_US: 'Increase Coverage',
  es_ES: 'Mejora tu cobertura',
  pt_BR: '',
};

export const HERE_TO_HELP = {
  en_US: `We're Always Here to Help!`,
  es_ES: '¡Siempre estamos aquí para ayudarte!',
  pt_BR: 'Estamos Sempre Aqui para Ajudar!',
};

export const AVAIABLE_CUSTOMER_SERVICE = {
  en_US: `Our customer service team is available 24/7 to address any questions. Contact us via chat.`,
  es_ES:
    'Nuestro equipo de atención está disponible 24/7 para ayudarte sobre cualquier duda. Contáctanos a través del chat.',
  pt_BR:
    'Nossa equipe de atendimento está disponível 24/7 para esclarecer qualquer dúvida. Entre em contato através do chat.',
};

export const DIGITAL_CONCIERGE_PLATINUM_TITLE = {
  en_US: '',
  es_ES: 'Vive. Disfruta. Digital Concierge Platinum',
  pt_BR: '',
};

export const DIGITAL_CONCIERGE_PLATINUM_DESCRIPTION = {
  en_US: '',
  es_ES:
    'Digital Concierge Platinum, te acompaña las 24 horas, para que disfrutes al máximo de tu vida.',
  pt_BR: '',
};

export const IEMS_TITLE_PUBLIC_PAGE = {
  en_US: 'How to access?',
  es_ES: '¿Cómo acceder?',
  pt_BR: 'Como acessar?',
};

export const IEMS_TEXT_PUBLIC_PAGE = {
  en_US: 'Log in to access this benefit.',
  es_ES: 'Inicia sesión para acceder a este beneficio.',
  pt_BR: 'Faça login para utilizar este benefício.',
};

export const IEMS_TITLE_PRIVATE_PAGE = {
  en_US: 'How to access the benefit?',
  es_ES: '¿Cómo acceder al beneficio?',
  pt_BR: 'Como acessar o benefício?',
};

export const FOLLOW_STEPS_IEMS = {
  en_US: 'Follow the steps to file a claim or generate a certificate.',
  es_ES: 'Sigue los pasos para abrir un reclamo o generar un certificado.',
  pt_BR: 'Siga os passos para abrir um sinistro ou gerar um certificado.',
};
export const CHECK_RESTAURANTS = {
  en_US: 'Check restaurants',
  es_ES: 'Consultar restaurantes',
  pt_BR: 'Consultar restaurantes',
};
export const TABLE_TO_YOU = {
  en_US: '',
  es_ES: 'Con tu tarjeta Visa Infinite la mesa está puesta para ti',
  pt_BR: '',
};
export const PARTICIPATING_RESTAURANTS = {
  en_US: 'Check out the participating restaurants and enjoy!',
  es_ES: 'Consulta los restaurantes participantes y disfruta!',
  pt_BR: 'Confira os restaurantes participantes e aproveite!',
};
