export const TITLE_CLAIM_VIEW = {
  en_US: 'View claim details',
  pt_BR: 'Visualizar detalhes do sinistro',
  es_ES: 'Ver detalles del reclamo',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TITLE_GENERAL_INFORMATION = {
  en_US: 'General information',
  pt_BR: 'Informações gerais',
  es_ES: 'Información general',
};

export const TITLE_LEGAL_CONSENT = {
  en_US: 'Legal advice and consent',
  pt_BR: 'Declaração e consentimento',
  es_ES: 'Asesoramiento legal y consentimiento',
};

export const TITLE_INCIDENT_INFORMATION = {
  en_US: 'Information about the incident',
  pt_BR: 'Informações sobre o incidente',
  es_ES: 'Información sobre el incidente',
};

export const BUTTON_CLOSE = {
  en_US: 'Close',
  pt_BR: 'Fechar',
  es_ES: 'Cerrar',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtelo de nuevo más tarde.',
};

//Field labels

export const LABEL_TITLE = {
  en_US: 'Title',
  pt_BR: 'Título',
  es_ES: 'Título',
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const LABEL_GENDER = {
  en_US: 'Gender',
  pt_BR: 'Gênero',
  es_ES: 'Género',
};

export const LABEL_OCCUPATION = {
  en_US: 'Profession',
  pt_BR: 'Profissão',
  es_ES: 'Profesión',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo eletrónico',
};

export const LABEL_NATIONALITY = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport',
  pt_BR: 'CPF',
  es_ES: 'Pasaporte',
};

export const LABEL_PHONE_TYPE = {
  en_US: 'Type',
  pt_BR: 'Tipo',
  es_ES: 'Tipo',
};

export const LABEL_PREFIX = {
  en_US: 'Prefix',
  pt_BR: 'Prefixo',
  es_ES: 'Prefijo',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const LABEL_ADDRESS = {
  en_US: 'Home address',
  pt_BR: 'Endereço residencial',
  es_ES: 'Dirección',
};

export const LABEL_SUBDIVISION = {
  en_US: 'Neighborhood',
  pt_BR: 'Bairro',
  es_ES: 'Barrio/Colonia',
};

export const LABEL_POLITICALLY_EXPOSED = {
  en_US: 'Pessoa politicamente exposta?',
  pt_BR: 'Pessoa politicamente exposta?',
  es_ES: 'Pessoa politicamente exposta?',
};

export const LABEL_BANK = {
  en_US: 'Issuer name',
  pt_BR: 'Nome do emissor',
  es_ES: 'Nombre del emisor',
};

export const LABEL_BANK_4_DIGITS = {
  en_US: 'Last 4 numbers of your credit card',
  pt_BR: 'Últimos 4 números do cartão',
  es_ES: 'Últimos 4 números de tu tarjeta de crédito',
};

export const LABEL_DATE = {
  en_US: 'Date: ',
  pt_BR: 'Data: ',
  es_ES: 'Fecha: ',
};

export const LABEL_PHONE = {
  en_US: 'Phone number: ',
  pt_BR: 'Número de telefone: ',
  es_ES: 'Número de teléfono: ',
};

export const LABEL_PLACE = {
  en_US: 'Place: ',
  pt_BR: 'Lugar: ',
  es_ES: 'Lugar: ',
};

//Titles

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

//Genders

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

//Registration Type
export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

//Phone
export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

//Legal Consent terms
export const CHECKBOX_UNDERSTAND = {
  en_US:
    'I understand that this form must be completed and all requirements for insurance, police reports (if it is required) must be submitted before any benefit claimed can be processed and paid.',
  pt_BR:
    'Entendo que este formulário deve ser preenchido e que todos os requisitos de seguro, boletim de ocorrência policial (se necessário) devem ser apresentados antes que qualquer reivindicação possa ser processada e paga.',
  es_ES:
    'Entiendo que este formulario debe de ser completado y que todos los requisitos de seguros, reporte ante la autoridad (si es requerido), deben de ser presentados antes de que cualquier reclamo pueda ser procesado y pagado.',
};

export const CHECKBOX_AUTHORIZE = {
  en_US:
    'I authorize the issuing bank to provide AXA assistance S.A. all information about my account to process this claim. I continue giving AXA assistance S.A. authorization to obtain copies of any police, fire, or other investigation reports and information necessary to process my claim.',
  pt_BR:
    'Eu autorizo o banco emissor a fornecer para a AXA assistance todas as informações sobre a minha conta e a obter cópias de todos os relatórios de investigações necessários para processar esse pedido.',
  es_ES:
    'Yo autorizo ​​al banco emisor a proporcionar a AXA assistance toda la información sobre mi cuenta y obtener copias de todos los informes de investigación necesarios para procesar esta solicitud.',
};

export const CHECKBOX_ACCORD = {
  en_US:
    'International Emergency Medical Service is provided by AXA. In addition to this entity, other third parties are involved to deliver this service to the BENEFICIARY. By accessing the International Emergency Medical Service, the BENEFICIARY authorizes the sharing of his/her personal information with third parties, which may include cross border transfers. The personal information will be processed in order to deliver the service and provide the benefits hereunder.',
  pt_BR:
    'O Seguro de Emergência Médica Internacional é fornecido pela AXA. Além desta entidade, outros terceiros estão envolvidos na entrega deste serviço ao BENEFICIÁRIO. Ao acessar o Seguro de Emergência Médica Internacional, o BENEFICIÁRIO autoriza o compartilhamento de suas informações pessoais com terceiros, o que pode incluir transferências internacionais. As informações pessoais serão processadas para fornecer o serviço e proporcionar esses benefícios.',
  es_ES:
    'El Servicio de Emergencia Médica Internacional es provisto por AXA Partners. Además de esta entidad, otros terceros están involucrados para entregar este servicio al BENEFICIARIO. Al acceder al Servicio de Emergencia Médica Internacional, el BENEFICIARIO autoriza el intercambio de tu información personal con terceros, lo que puede incluir transferencias transfronterizas. La información personal se procesará para brindar el servicio y proporcionar dichos beneficios.',
};

export const CHECKBOX_ACCORD_2 = {
  en_US:
    'This service is provided by or through AXA. In addition to this entity, other third parties are involved in delivering this service to the beneficiary. By accessing this service, the beneficiary authorizes the sharing of his/her personal information with third parties, which may include cross border transfers. The personal information will be processed in order to deliver the service and provide the benefits hearunder.',
  pt_BR:
    'Este serviço é fornecido por AXA Partners. Além desta entidade, terceiros estão envolvidos na prestação deste serviço ao BENEFICIÁRIO. Ao utilizar este serviço, o beneficiário autoriza o compartilhamento dos seus dados pessoais com terceiros, o que pode incluir transferências interfronteiras. As informações pessoais serão processadas a fim de fornecer o serviço e fornecer estes benefícios.',
  es_ES:
    'Este servicio es brindado por AXA Partners. Además de esta entidad, otros terceros están involucrados para entregar este servicio al BENEFICIARIO. Al acceder al beneficio reclamado, el BENEFICIARIO autoriza el intercambio de tu información personal con terceros, lo que puede incluir transferencias transfronterizas. La información personal se procesará para brindar el servicio y proporcionar dichos beneficios.',
};
