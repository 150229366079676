/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';

import PersonalArea from './PersonalArea/PersonalArea';
import AddressArea from './AddressArea/AddressArea';
import ContactArea from './ContactArea/ContactArea';
import { validationSchema } from './schema';
import { IntlContext } from '../../../../../intl';
import { getInitialValues } from './initialValues';
import * as translations from './intl';
import IsCardHolderArea from './IsCardHolderArea/IsCardHolderArea';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';

const GeneralInformation = props => {
  const { submitRef, updateParent, user, prefixOptions, setIsCardHolder } =
    props;
  const { translate, idiom } = useContext(IntlContext);
  const { utils, actions } = useContext(B2B2CContext);
  const intl = translate(translations);

  const schema = useMemo(() => validationSchema(intl), [intl]);

  const initialValues = useMemo(
    () => getInitialValues(user, idiom, utils.getMPH()),
    [user]
  );

  const saveInSession = values => {
    const newValues = JSON.parse(JSON.stringify(values));
    actions.formDataCotizar.setMPH(newValues.MPH);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => {
        saveInSession(values);
      }}
    >
      {props => {
        const { values, handleSubmit } = props;

        updateParent(values, schema);

        return (
          <form onSubmit={handleSubmit}>
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            <IsCardHolderArea
              {...props}
              user={user}
              setIsCardHolder={setIsCardHolder}
              saveInSession={saveInSession}
            />
            <PersonalArea {...props} saveInSession={saveInSession} />
            <ContactArea
              {...props}
              prefixOptions={prefixOptions}
              saveInSession={saveInSession}
            />
            <AddressArea {...props} saveInSession={saveInSession} />
          </form>
        );
      }}
    </Formik>
  );
};

export default GeneralInformation;
