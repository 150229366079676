import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';

const AgenciesComplementArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { setFieldTouched } = props;

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Calendar
          label={intl.DATE_ASSIGMENT_MADE}
          name="collection_agencies.assignment_date"
          maxDate={new Date()}
          setFieldTouched={setFieldTouched}
          formikProps={props}
        />
      </div>

      <div className="col-12 col-md-6">
        <Calendar
          label={intl.DATE_RECEIVED_CANCELLATION}
          name="collection_agencies.received_affidavit_date"
          maxDate={new Date()}
          setFieldTouched={setFieldTouched}
          formikProps={props}
        />
      </div>

      <div className="col-12 col-md-6">
        <Calendar
          label={intl.DATE_ACCOUNT_CANCELLATION}
          name="collection_agencies.account_cancellation_date"
          maxDate={new Date()}
          setFieldTouched={setFieldTouched}
          formikProps={props}
        />
      </div>

      <div className="col-12 col-md-6">
        <Calendar
          label={intl.DATE_RECEIVED_AFFIDAVIT}
          name="collection_agencies.received_request_date"
          maxDate={new Date()}
          setFieldTouched={setFieldTouched}
          formikProps={props}
        />
      </div>
    </div>
  );
};

export default AgenciesComplementArea;
