import { useContext, useState, useRef } from 'react';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import styles from './EditCardHolderForm.module.scss';
import GeneralInformation from '../../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/GeneralInformation';
import { generalInformationFormInitialValues } from '../../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/initialValues';
import { IntlContext } from '../../../../../../intl';
import { useMemo } from 'react';
import Button from '../../../../../../components/Button/Button';
import { getRefs } from './submit/getRefs';
import { checkErrorsOnForm } from './submit/checkErrorsOnForm';
import { formSubmit } from './submit/index';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import * as translationsClaim from '../../../../../Claims/Generate/ClaimFillForm/Form/intl';
import * as translations from '../../intl';
import * as translationsUpdateEmail from '../../../../../../utils/verifyUpdateEmail/intl';
import { MSG_ERROR_CPF } from '../../../../../Claims/Generate/ClaimFillForm/Form/const';
import { verifyUpdateEmail } from '../../../../../../utils/verifyUpdateEmail/verifyUpdateEmail';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const EditCardHolderForm = ({ loadedProfile }) => {
  const { utils, state, actions } = useContext(StateContext);
  const { idiom, translate, idiomForApi } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl_claim = translate(translationsClaim);
  const intl = translate(translations);
  const intlUpdateEmail = translate(translationsUpdateEmail);
  const [schemas] = useState({});
  const [values] = useState({});
  const cn = utils.getCn();
  const submitRefs = getRefs(useRef);
  const [prefix, setPrefix] = useState('');
  const initialValues = useMemo(
    () => generalInformationFormInitialValues(loadedProfile, '', idiom),
    [idiom, loadedProfile]
  );

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const showModalError = msg => {
    return actions.modal.showModal(
      false,
      false,
      <ModalInformation type="error" message={msg} />,
      true,
      false
    );
  };

  const showModalSuccess = msg => {
    return actions.modal.showModal(
      false,
      false,
      <ModalInformation type="success" message={msg} />,
      true,
      false
    );
  };

  const submit = async () => {
    const areFormsOk = await checkErrorsOnForm(submitRefs, schemas, values);

    if (areFormsOk) {
      try {
        actions.modal.showLoading(intl.LOADING_SAVING_CONTACT_DETAILS);
        await formSubmit(values, cn, idiomForApi());
        await actions.user.updateFirstLastName(values);
        actions.modal.hideLoading();
        showModalSuccess(intl_claim.TEXT_CH_CHANGE_SUCCESS);
        verifyUpdateEmail(
          utils,
          actions,
          intlUpdateEmail,
          trackEventUserAction
        );
      } catch (e) {
        actions.modal.hideLoading();
        if (
          e.response &&
          e.response.data &&
          e.response.data.error_description &&
          e.response.data.error_description === MSG_ERROR_CPF
        ) {
          showModalError(intl_claim.ERROR_CUSTOMER_DUPLICATED);
        } else if (
          e.response &&
          e.response.status &&
          e.response.status === 504
        ) {
          actions.modal.showModal(
            false,
            false,
            <ModalInformation
              type="error"
              message={intl_claim.ERROR_TIME_OUT}
            />,
            true,
            false
          );
        } else {
          actions.modal.showModal(
            false,
            false,
            <ModalInformation type="error" message={intl_claim.ERROR} />,
            true,
            false
          );
        }
      }
    }
  };

  return (
    <div className={styles.editCardHolder}>
      <GeneralInformation
        updateParent={updateForm.bind(null, 'cardholder')}
        initialValues={initialValues}
        policySubmitRef={submitRefs.cardholder}
        showIsCardHolderArea={false}
        isEditProfile
        validateCep
        prefixOptions={{ prefix, setPrefix }}
      />
      <div className={`row ${styles.section}`}>
        <div className={`col-12 ${styles.footerBtns}`}>
          <Button
            id="editPersonalDetailsButton"
            type="borderBlue"
            onClick={submit}
            width="180px"
          >
            {intl.BTN_SAVE}
          </Button>
          <Button
            id="cancelEditPersonalDetailsButton"
            type="blueWithoutBorder"
            onClick={actions.modal.closeModal}
            width="180px"
          >
            {intl.BTN_CANCEL}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditCardHolderForm;
