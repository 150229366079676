export const LINK_EXPIRED = {
  en_US: 'Account confirmation link expired',
  pt_BR: 'Link de confirmação de conta expirado',
  es_ES: 'El enlace de verificación de la cuenta caducó',
};
export const MSG_TITLE = {
  en_US: 'You must reset your password as your access has expired.',
  pt_BR: 'Você deve redefinir sua senha, pois seu acesso está expirado.',
  es_ES: 'Debe restablecer tu contraseña ya que tu acceso ha expirado.',
};
export const MSG_FORGOT = {
  en_US: 'Enter the email address used to register to receive instructions.',
  pt_BR:
    'Informe o endereço de e-mail utilizado no cadastro para receber as instruções.',
  es_ES:
    'Ingresa la dirección de correo electrónico utilizada para registrarse para recibir instrucciones.',
};
export const CLICK_THE_BUTTON = {
  en_US: 'Click the button below to log in again.',
  pt_BR: 'Clique no botão abaixo para efetuar o login novamente.',
  es_ES: 'Haga clic en el botón de abajo para iniciar sesión nuevamente.',
};
export const BTN_ENTER = {
  en_US: 'Submit',
  pt_BR: 'Enviar',
  es_ES: 'Enviar',
};

export const BUTTON_VALUE = {
  en_US: 'Log in',
  pt_BR: 'Enviar',
  es_ES: 'Iniciar',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};

export const SUCCESS_PASSWORD_CHANGED = {
  en_US:
    'If the email address you have provided is registered, you will receive an email with instructions.',
  pt_BR:
    'Se o endereço de e-mail que você forneceu estiver registrado, você receberá um e-mail com instruções.',
  es_ES:
    'Si la dirección de correo electrónico que ha proporcionado está registrada, recibirás un correo electrónico con instrucciones.',
};

export const LINK_INVALID = {
  en_US:
    'Your account was not confirmed within 24 hours and has therefore been removed.',
  pt_BR: 'Sua conta não foi confirmada em 24h e por isso foi removida.',
  es_ES:
    'Tu cuenta no ha sido verificada en 24 horas y, por lo tanto, ha sido eliminada.',
};

export const LINK_INVALID_MSG_TITLE = {
  en_US: `Your account confirmation link is expired. When you create an account and don't confirm it in 24 hours, for safety reasons, it's automatically removed.`,
  pt_BR: `O link de confirmação da sua conta expirou. Quando você cria uma conta e não a confirma em 24 horas, por motivos de segurança, ela é removida automaticamente.`,
  es_ES: `El enlace de verificación de tu cuenta ha caducado. Cuando crea una cuenta y no la confirma dentro de las 24 horas, por razones de seguridad, se elimina automáticamente.`,
};

export const LINK_INVALID_MSG_TITLE_SUBTITLE = {
  en_US: `Please, take a look in your e-mail to confirm that your account was removed and sing up again. `,
  pt_BR: `Por favor, verfique em seu e-mail para confirmar que sua conta foi removida e cadastre-se novamente.`,
  es_ES: `Por favor revisa tu correo electrónico para confirmar que tu cuenta ha sido eliminada y regístrese nuevamente.`,
};

export const TEXT_LOG_IN = {
  en_US: 'Go to Log in page',
  pt_BR: 'Ir para a página de Entrar',
  es_ES: 'Ir a la página de Iniciar sesión',
};

export const LINK_USED_EXPIRED = {
  en_US: 'Expired link',
  pt_BR: 'Link expirado',
  es_ES: 'Enlace caducado',
};

export const MSG_USED_EXPIRED = {
  en_US: 'This link has already been used.',
  pt_BR: 'Esse link já foi utilizado.',
  es_ES: 'Este enlace ya se ha utilizado.',
};

export const SUCCESS_TITLE = {
  en_US: 'Your Visa Benefits Portal account has been activated',
  pt_BR: 'Sua conta no Visa Benefits Portal foi ativada',
  es_ES: 'Tu cuenta del Portal de Beneficios de Visa ha sido activada',
};

export const SUCCESS_DESCRIPTION = {
  en_US: 'You can now login to the portal',
  pt_BR: 'Você agora pode realizar o login no portal',
  es_ES: 'Ahora puedes iniciar sesión en el portal',
};

export const BTN_GO_PORTAL = {
  en_US: 'Follow to the portal',
  pt_BR: 'Seguir para o portal',
  es_ES: 'Seguir a la portal',
};
