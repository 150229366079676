import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import * as translations from '../../../intl/travels';
import { useFlightInfo } from '../FlightInfoContext';
import styles from './index.module.scss';
import InfoLabelValue from '../../InfoLabelValue/InfoLabelValue';

const EmbarkRow = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { flightInfo } = useFlightInfo();
  const { terminalOrigin, gateOrigin, terminalDestination, gateDestination } =
    flightInfo;

  return (
    <div className={styles.container}>
      {terminalOrigin && (
        <InfoLabelValue label={intl.TERMINAL} value={terminalOrigin} />
      )}
      {gateOrigin && <InfoLabelValue label={intl.GATE} value={gateOrigin} />}
      <div className={styles.spacer} />
      {terminalDestination && (
        <InfoLabelValue label={intl.TERMINAL} value={terminalDestination} />
      )}
      {gateDestination && (
        <InfoLabelValue label={intl.GATE} value={gateDestination} />
      )}
    </div>
  );
};

export default EmbarkRow;
