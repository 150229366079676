import {
  TEXT_ADD_YOUR_CARD,
  TEXT_DIGITS,
} from '../../components/ModalAddCard/intl';

export { TEXT_ADD_YOUR_CARD, TEXT_DIGITS };

export const TITLE_PAGE_NOT_FOUND = {
  en_US: 'Oops!',
  pt_BR: 'Ops!',
  es_ES: '¡Ups!',
};

export const DESCRIPTION_PAGE_NOT_FOUND = {
  en_US: 'We cannot find the page that you are looking for.',
  pt_BR: 'Não conseguimos encontrar a página que você está procurando.',
  es_ES: 'No podemos encontrar la página que busca.',
};

export const GO_HOMEPAGE = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para Homepage',
  es_ES: 'Ir a la Homepage',
};

export const GO_ACTIVE_CARD = {
  en_US: 'Add a new card',
  pt_BR: 'Adicionar um novo cartão',
  es_ES: 'Agregar una nueva tarjeta',
};

export const TEXT_INSERT_PAN = {
  en_US: 'The selected card is currently not active',
  pt_BR: 'O cartão selecionado não está ativo no momento',
  es_ES: 'La tarjeta seleccionada actualmente no está activa',
};

export const CLICK_HERE = {
  en_US:
    'Your experience on the Visa Benefits Portal using this card will be restricted, that is, some features will not be available. To fully enjoy its features, please add a new card providing the 16 digits.',
  pt_BR:
    'Sua experiência no Portal de Benefícios Visa utilizando esse cartão será restrita, ou seja, algumas funcionalidades não estarão disponíveis. Para usufruir integralmente dos seus recursos, por favor, adicione um novo cartão fornecendo os 16 dígitos.',
  es_ES:
    'Tu experiencia en el Portal de Beneficios de Visa utilizando esta tarjeta será restringida, es decir, algunas funciones no estarán disponibles. Para disfrutar plenamente de tus funciones, agregue una nueva tarjeta que proporcione los 16 dígitos.',
};

export const SESSION_EXPIRED = {
  en_US: 'Session Expired',
  es_ES: 'Sesión Caducada',
  pt_BR: 'Sessão Expirada',
};
