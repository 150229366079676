import React, { Component, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';
import { setObserver } from '../../services/errorHandler';
import { initialState } from './initialState';
import { IntlContext } from '../../intl';
import { isCoutryEnableToUsePAN } from '../../utils/environments_variables';

export const StateContext = React.createContext({
  actions,
  state: initialState(),
  utils: {
    getCn,
    getBin,
    getSelectedCard,
    getCountry,
    isUserLogged,
    getEmail,
    getIsTokenRequired,
    getBinToken,
    getCustomerInfo,
    getUserInfo,
    isUserPartner,
  },
});

const associateActions = (component, actions) => {
  const associatedActions = {};
  Object.keys(actions).forEach(key => {
    if (typeof actions[key] === 'function') {
      associatedActions[key] = actions[key].bind(component);
    }
    if (typeof actions[key] === 'object') {
      associatedActions[key] = associateActions(component, actions[key]);
    }
  });
  return associatedActions;
};

function getCn() {
  const { cn } = this.state.user.data.userInfo;
  return cn;
}

function isUserPartner() {
  if (
    this.state &&
    this.state.user &&
    this.state.user.data &&
    this.state.user.data.userInfo
  ) {
    return (
      this.state.user.data.userInfo['https://axa-partners.com/partner_id'] ||
      false
    );
  }
  return false;
}

function getCustomerInfo() {
  const customerInfo = this.state.user.data.userInfo;
  return customerInfo;
}

function getUserInfo() {
  return this.state.user.data.userInfo;
}

function getSelectedCard() {
  let cardSelected = null;
  if (this.state.cards.data.length > 0) {
    const { selectedCardIndex } = this.state.cards;
    cardSelected = this.state.cards.data[selectedCardIndex];
  }
  return cardSelected;
}

function getBin(canBePan = false) {
  let externalReference = null;
  if (this.state.cards.data.length > 0) {
    const { selectedCardIndex } = this.state.cards;
    const { external_reference, credit_card_token, country } =
      this.state.cards.data[selectedCardIndex];

    externalReference =
      canBePan &&
      credit_card_token &&
      credit_card_token.length === 16 &&
      isCoutryEnableToUsePAN(country.toUpperCase())
        ? credit_card_token
        : external_reference;
  }
  return externalReference;
}

function getIsTokenRequired() {
  if (this.state.cards.data.length > 0) {
    const { selectedCardIndex } = this.state.cards;
    const { is_token_required } = this.state.cards.data[selectedCardIndex];
    return is_token_required;
  }
  return false;
}

function getBinToken() {
  if (this.state.cards.data.length > 0) {
    const { selectedCardIndex } = this.state.cards;
    const { credit_card_token } = this.state.cards.data[selectedCardIndex];
    return credit_card_token;
  }
  return '';
}

function getCountry() {
  let cardCountry = null;
  if (this.state.cards.data.length > 0) {
    const { selectedCardIndex } = this.state.cards;
    const { country } = this.state.cards.data[selectedCardIndex];
    cardCountry = country;
  }
  return cardCountry;
}

function isUserLogged() {
  return this.state.user.logged;
}

function getEmail() {
  const { email } = this.state.user.data.userInfo;
  return email;
}

export class StateContextParent extends Component {
  constructor(props) {
    super(props);
    if (!this.props.initialState) this.state = initialState();
    else this.state = this.props.initialState;
    this.actions = associateActions(this, actions);
    this.state.modal.modalRef = React.createRef();
    this.utils = {
      getCn: getCn.bind(this),
      getBin: getBin.bind(this),
      getSelectedCard: getSelectedCard.bind(this),
      getCountry: getCountry.bind(this),
      isUserLogged: isUserLogged.bind(this),
      getEmail: getEmail.bind(this),
      getIsTokenRequired: getIsTokenRequired.bind(this),
      getBinToken: getBinToken.bind(this),
      getCustomerInfo: getCustomerInfo.bind(this),
      getUserInfo: getUserInfo.bind(this),
      isUserPartner: isUserPartner.bind(this),
    };
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    setObserver(this);
    if (!this.props.initialState) {
      this.actions.user.getUserFromCookie();
    } else {
      const { cards } = this.state;
      this.actions.cards.setIdiomByCard(cards);
    }
  }

  render() {
    const value = {
      state: this.state,
      actions: this.actions,
      utils: this.utils,
    };
    return (
      <StateContext.Provider value={value}>
        {this.props.children}
      </StateContext.Provider>
    );
  }
}

const IntlStateContextParent = props => {
  const intlContext = useContext(IntlContext);
  return <StateContextParent intlContext={intlContext} {...props} />;
};

export default withRouter(IntlStateContextParent);
