/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import moment from 'moment';

import { pt_BR } from '../../../../../../../intl/idioms';
import { validateCPF } from '../../GeneralInformation/validationSchema';

const checkboxClaims = [
  'Price_Protection',
  'Purchase_Protection',
  'Extended_Warranty',
  'Auto_Rental_Insurance',
  'Baggage_Delay',
  'Baggage_Loss',
  'Trip_Cancellation',
  'Trip_Delay',
  'Missed_Connection',
];

const personAccountHolder = intl => {
  return Yup.object().shape({
    first_name: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    last_name: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    birth_date: Yup.mixed().test(
      'match',
      intl.VALIDATE_INVALID_DATE,
      function (birth_date) {
        let yesterday = moment(new Date())
          .subtract(1, 'days')
          .format('YYYY-MM-DD');
        return moment(birth_date).isSameOrBefore(yesterday);
      }
    ),
    gender: Yup.string()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .typeError(intl.VALIDATE_REQUIRED_FIELD),
    nationalities: Yup.string()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .typeError(intl.VALIDATE_REQUIRED_FIELD),
  });
};

const addressAccountHolder = (intl, isBrazil) => {
  return Yup.object().shape({
    invalidCep: Yup.boolean().test(
      'invalid ajax cep',
      'invalid ajax cep',
      function (value) {
        if (this.parent.country !== 'BR') return true;
        if (value === true) return false;
        return true;
      }
    ),
    street_address: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    postal_code: Yup.string()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
        const cep = value.replace('-', '');
        if (this.parent.country !== 'BR') {
          return cep.length > 0 && cep.length <= 8;
        }
        return cep.length === 8;
      })
      .typeError(intl.VALIDATE_REQUIRED_FIELD),
    locality: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    country: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    subdivision: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    state: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
  });
};

const personRegistrationHolder = intl => {
  return Yup.object().shape({
    registration_type: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    value: Yup.string()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .when(
        ['registration_type'],
        {
          is: registration_type => {
            return registration_type === 'CPF';
          },
          then: Yup.string()
            .required(intl.VALIDATE_REQUIRED_FIELD)
            .test('test-data-valid', intl.VALIDATE_CPF, function (value) {
              return validateCPF(value);
            }),
        },
        ['registration_type']
      ),
  });
};

const phoneData = intl => {
  return Yup.object().shape({
    phone_type: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    international_prefix: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    number: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
  });
};

export const accountHolderSchema = (intl, value, isBrazil, claimType) => {
  const relationship = value.relationship;
  let object = {};
  if (checkboxClaims.includes(claimType)) {
    object.relationshipCardholder = Yup.bool().oneOf(
      [true],
      intl.VALIDATE_REQUIRED_FIELD
    );
  }

  if (relationship !== 'Cardholder') {
    object.person = personAccountHolder(intl);
    object.address = addressAccountHolder(intl, isBrazil);
    object.person_registration = personRegistrationHolder(intl);
    object.profession = Yup.string().required(intl.VALIDATE_REQUIRED_FIELD);
    object.phone = phoneData(intl);
    object.relationship = Yup.string().required(intl.VALIDATE_REQUIRED_FIELD);

    if (value.relationship === 'Other')
      object.holder_type = Yup.string()
        .min(1, intl.VALIDATE_INVALID_FIELD)
        .max(20, intl.VALIDATE_INVALID_FIELD)
        .required(intl.VALIDATE_REQUIRED_FIELD);

    if (isBrazil) {
      object.is_politically_exposed = Yup.string().required(
        intl.VALIDATE_REQUIRED_FIELD
      );
    }
  }

  return Yup.object().shape(object);
};
