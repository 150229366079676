import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from './AmountArea.module.scss';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const AmountArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue('other_insurance_covering.value', '');
  };

  return (
    <div className={`row ${styles.amountArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={false}
              text={intl.TEXT_YES}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherCompanyCoveringYes"
              value={true}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={true}
              text={intl.TEXT_NO}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherCompanyCoveringNo"
              value={false}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivOtherCompanyValue"
      >
        <Input
          value={values.other_insurance_covering.value}
          label={intl.LABEL_AMOUNT_PAID}
          name="other_insurance_covering.value"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNt_DECIMAL}
          onChange={handleChange}
          touched={
            touched.other_insurance_covering &&
            touched.other_insurance_covering.value
          }
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.value
          }
        />
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
      >
        <Select
          dataTestid="selectInsuranceCoveringCurrency"
          label={intl.LABEL_CURRENCY}
          value={values.expense_currency}
          disabled
          name="other_insurance_covering.currency"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.currency
          }
        />
      </div>
    </div>
  );
};

export default AmountArea;
