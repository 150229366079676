/* eslint-disable no-unused-vars */
import { pt_BR } from '../../../../../../../intl/idioms';
import { safeGet } from '../../../../../../../utils/object';
import {
  CARD_HOLDER_VALUE,
  SPOUSE_VALUE,
  DEPENDENT_CHILD_VALUE,
  OTHER_VALUE,
} from '../../../../../../../utils/consts';
import moment from 'moment';
import { inArray } from '../../../../../../../utils/array';
import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';

const relationshipValues = [
  CARD_HOLDER_VALUE,
  SPOUSE_VALUE,
  DEPENDENT_CHILD_VALUE,
];

const personAccountHolder = data => {
  const birth_date = safeGet(data, 'person.birth_date') || false;

  return {
    title: safeGet(data, 'person.title') || '',
    first_name: safeGet(data, 'person.first_name') || '',
    last_name: safeGet(data, 'person.last_name') || '',
    birth_date: birth_date
      ? new Date(moment(birth_date).format('YYYY/MM/DD'))
      : '',
    gender: safeGet(data, 'person.gender') || '',
    nationalities:
      data &&
      data.person &&
      data.person.nationalities &&
      data.person.nationalities[0]
        ? data.person.nationalities[0]
        : '',
  };
};

const addressAccountHolder = data => {
  if (data && data.address) return data.address;

  return {
    country: '',
    locality: '',
    postal_code: '',
    state: '',
    street_address: '',
    subdivision: '',
  };
};

const personRegistrationHolder = data => {
  if (data && data.registrations && data.registrations[0])
    return data.registrations[0];

  let object = { value: '' };
  object.registration_type = '';

  return object;
};

const holderTypeData = data => {
  let relationshipValue =
    data && data.relationship ? data.relationship : 'Cardholder';
  let arrayValues = relationshipValues;
  arrayValues.push(OTHER_VALUE);

  if (!inArray(arrayValues, relationshipValue)) return data.relationship;

  return '';
};

const isPoliticalyExposedData = data => {
  return data && data.is_politically_exposed
    ? data.is_politically_exposed
    : false;
};

const monthlyIncomeData = data => {
  return data && data.monthly_income ? data.monthly_income.value : '';
};

const professionData = data => {
  return data && data.profession ? data.profession : '';
};

const phoneData = data => {
  if (data && data.phone) return data.phone;

  return {
    phone_type: PHONE_TYPE_MOBILE,
    international_prefix: '',
    number: '',
  };
};

const relationshipData = data => {
  let relationshipValue =
    data && data.relationship ? data.relationship : 'Cardholder';

  if (!inArray(relationshipValues, relationshipValue))
    relationshipValue = OTHER_VALUE;

  return relationshipValue;
};

export const BankAccountHolderData = data => {
  const accountHolder = data.BankAccountHolder;
  const person = personAccountHolder(accountHolder);
  const address = addressAccountHolder(accountHolder);
  const person_registration = personRegistrationHolder(accountHolder);
  const holder_type = holderTypeData(accountHolder);
  const is_politically_exposed = isPoliticalyExposedData(accountHolder);
  const monthly_income = monthlyIncomeData(accountHolder);
  const profession = professionData(accountHolder);
  const phone = phoneData(accountHolder);
  const relationship = relationshipData(accountHolder);
  const relationship_2 =
    data.BankAccountHolder && data.BankAccountHolder.relationship_2
      ? data.BankAccountHolder.relationship_2
      : 'Cardholder';

  return {
    person,
    address,
    person_registration,
    holder_type,
    is_politically_exposed,
    monthly_income,
    profession,
    phone,
    relationship,
    relationship_2,
  };
};
