export const REQUIRED_FIELD = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_SUCCESS = {
  en_US: 'Thank you so much for improving your coverage with AXA!',
  pt_BR: 'Muito obrigado por melhorar sua cobertura com a AXA!',
  es_ES: '¡Muchas gracias! Actualmente estamos procesando tu pago.',
};

export const TEXT_SUBTITLE_SUCCESS = {
  en_US:
    'We are processing your payment. An email with basic information related to the coverage purchased has been sent to you. Once we finish processing your payment, an additional coverage certificate will be sent to your email.',
  pt_BR:
    'Estamos processando seu pagamento. Um e-mail com informações básicas relacionadas a cobertura adquirida foi encaminhado para você. Assim que finalizarmos o processamento do seu pagamento, um certificado de cobertura adicional será encaminhado para seu e-mail.',
  es_ES: `<strong>Por favor revisa tu correo electrónico para confirmar que tu compra ha sido autorizada por tu banco.</strong><br/>
    Se te ha enviado un primer correo electrónico con información básica relacionada con la cobertura contratada con AXA.<br/>
    Una vez que terminemos de procesar tu pago, se te enviará un certificado de cobertura adicional a tu correo electrónico.`,
};

export const TEXT_ERROR = {
  en_US: 'An error occurred while performing this operation.',
  pt_BR: 'Ocorreu um erro ao efetuar esta operação.',
  es_ES: 'Se produjo un error al realizar esta operación.',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const ANOTHER_VISA_CARD = {
  en_US: 'Use another Visa card for payment.',
  pt_BR: 'Usar outro cartão Visa para pagamento.',
  es_ES: 'Utilice otra tarjeta Visa para realizar el pago.',
};

export const INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const TEXT_ERROR_ANOTHER_CERTIFICATE_UNDER_VIGENCY = {
  en_US:
    'There is another additional coverage purchased that is under validity.',
  pt_BR: 'Existe outra cobertura adicional adquirida que está vigente.',
  es_ES: 'Hay otra cobertura adicional comprada que está vigente.',
};

export const BUTTON_MY_BENEFITS = {
  en_US: 'View my benefits',
  pt_BR: 'Ver meus benefícios',
  es_ES: 'Ver mis beneficios',
};

export const BUTTON_MY_ADDITIONAL_COVERAGES = {
  en_US: 'View my additional coverages',
  pt_BR: 'Ver minhas coberturas adicionais',
  es_ES: 'Ver mis coberturas adicionales',
};

export const VISA_CARD_TO_PAYMENT = {
  en_US: 'Visa card to make the payment.',
  pt_BR: 'Cartão Visa para efetuar o pagamento.',
  es_ES: 'Tarjeta Visa para realizar el pago.',
};
