import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import IncidentPeriodArea from './IncidentPeriodArea/IncidentPeriodArea';
import AirlineArea from './AirlineArea/AirlineArea';
import InsuranceArea from './InsuranceArea/InsuranceArea';
import AdditionalPassengers from './AdditionalPassengers/AdditionalPassengers';
import { baggageDelayFormInitialValues } from './initialValues';
import { baggageDelaySchema } from './validationSchema';
import ReimbursementArea from './ReimbursementArea/ReimbursementArea';

const BaggageDelay = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    parentValues,
    updateParent,
    incidentSubmitRef,
    prefixOptions,
    loadedData,
  } = props;

  const validationSchema = useMemo(() => baggageDelaySchema(intl), [intl]);
  const initialValues = useMemo(
    () => baggageDelayFormInitialValues(parentValues.policy, idiom, loadedData),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormClaimBaggageDelay">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <IncidentArea parentValues={parentValues} {...props} />
              <IncidentPeriodArea parentValues={parentValues} {...props} />
              <AirlineArea
                parentValues={parentValues}
                prefixOptions={prefixOptions}
                {...props}
              />
              <AdditionalPassengers parentValues={parentValues} {...props} />
              <InsuranceArea parentValues={parentValues} {...props} />
              <ReimbursementArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BaggageDelay;
