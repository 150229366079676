import moment from 'moment';
import { cloneDeep } from 'lodash';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';
export const formatTripCancellation_toApi = props => {
  let object = {};
  const currency =
    props.values.incident.additional_costs_transport.price.currencyValue;
  object.affected_person = formatAffectedPerson(props);
  object.trip_information = tripInformation(props);
  object.agency = agency(props);
  object.incident = incident(props);
  object.additional_costs = additionalCosts(props, currency);
  object.other_insurance_covering = coveringTripCancellation(props, currency);
  object.total_amount_claimed = totalAmount(props, currency);

  if (props.values.incident.hasAdditional === 'true') {
    const additional_passengers = cloneDeep(
      props.values.incident.additional_passengers
    );
    additional_passengers.map((value, index) => {
      const birthDate = value.birth_date;
      additional_passengers[index].nationalities = [value.nationalities];
      additional_passengers[index].birth_date =
        moment(birthDate).format('YYYY-MM-DD');
    });
    object.additional_passengers = additional_passengers;
  }

  return object;
};

export const tripInformation = props => {
  const tripInformation = props.values.incident.trip_information;

  return {
    planed_trip: {
      departure_date: moment(tripInformation.planed_trip.departure_date).format(
        'YYYY-MM-DD'
      ),
      arrival_date: moment(tripInformation.planed_trip.arrival_date).format(
        'YYYY-MM-DD'
      ),
    },

    interruption_date: moment(tripInformation.interruption_date).format(
      'YYYY-MM-DD'
    ),
    reason: tripInformation.reason,
    type: 'TRIP_CANCELLATION',
  };
};

export const agency = props => {
  return {
    name: props.values.incident.agency.name,
  };
};

export const incident = props => {
  const incident = props.values.incident.incident;

  return {
    start_date: moment(incident.start_date).format('YYYY-MM-DD'),
    location: {
      country: incident.location.country,
      state: incident.location.state,
      locality: incident.location.locality,
    },
  };
};

export const additionalCosts = (props, currency) => {
  const additionalCostsAccomodation = addionalCostsObject(
    props.values.incident.additional_costs_accomodation,
    currency
  );
  const additionalCostsTransport = addionalCostsObject(
    props.values.incident.additional_costs_transport,
    currency
  );

  const additionalCosts = [];
  additionalCosts.push(additionalCostsAccomodation, additionalCostsTransport);

  return additionalCosts;
};

const addionalCostsObject = (props, currency) => {
  let objAddionalCosts = {
    label: props.label,
    price: {
      value: props.price.value,
      currencyValue: currency,
    },
  };
  const emptyObj = {};
  if (
    Object.prototype.hasOwnProperty.call(props.amount_claimed, 'checked') &&
    stringToBoolean(props.amount_claimed.checked)
  ) {
    let amount_claimed = {
      value: props.amount_claimed.value,
      currencyValue: currency,
    };
    objAddionalCosts = { ...objAddionalCosts, amount_claimed };
  } else
    objAddionalCosts = {
      ...objAddionalCosts,
      amount_claimed: emptyObj,
    };
  return objAddionalCosts;
};

export const coveringTripCancellation = (props, currency) => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const other_company_covering = props.values.incident.other_company_covering;
  const otherInsurance = [];
  if (stringToBoolean(other_insurance_covering.checked)) {
    otherInsurance.push(
      otherInsuranceObject(other_insurance_covering, currency)
    );
  }

  if (stringToBoolean(other_company_covering.checked)) {
    otherInsurance.push(otherInsuranceObject(other_company_covering, currency));
  }

  return otherInsurance;
};

export const otherInsuranceObject = (props, currency) => {
  return {
    is_internal: props.is_internal,
    description: props.description,
    amount: {
      value: parseFloat(props.value),
      currency: currency,
    },
  };
};

export const totalAmount = (props, currency) => {
  const total = props.values.incident.total_amount_claimed;

  return {
    value: total.value,
    currencyValue: currency,
  };
};

const formatAffectedPerson = props => {
  let affected = props.values.incident.affected_person;
  delete affected.relationshipCardholder;
  return affected;
};
