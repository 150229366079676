export const TEXT_SPECIAL_BENEFITS = {
  en_US: 'Subscriptions',
  es_ES: 'Suscripciones',
  pt_BR: 'Assinaturas',
};

export const TEXT_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const TEXT_COUNTRY = {
  en_US: 'Country',
  es_ES: 'País',
  pt_BR: 'País',
};

export const TEXT_NORTH_AMERICA = {
  en_US: 'North America',
  es_ES: 'América del norte',
  pt_BR: 'América do Norte',
};

export const TEXT_SOUTH_AMERICA = {
  en_US: 'South America',
  es_ES: 'América del Sur',
  pt_BR: 'América do Sul',
};

export const TEXT_CENTRAL_AMERICA = {
  en_US: 'Central America',
  es_ES: 'América Central',
  pt_BR: 'América Central',
};

export const TEXT_CARIBBEAN = {
  en_US: 'Caribbean',
  es_ES: 'Caribe',
  pt_BR: 'Caribenho',
};

export const OPEN_MENU_MOBILE = {
  en_US: 'Open Menu Mobile',
  es_ES: 'Abrir Menú Móvil',
  pt_BR: 'Abrir Menu Móvel',
};

export const CLOSE_MENU_MOBILE = {
  en_US: 'Close Menu Mobile',
  es_ES: 'Cerrar Menú Móvil',
  pt_BR: 'Fechar Menu Móvel',
};

export const REDIRECT_TO_HELP_PAGE = {
  en_US: 'Redirect to Help Page',
  es_ES: 'Redirigir a la Página de Ayuda',
  pt_BR: 'Redirecionar para a Página de Ajuda',
};

export const OPEN_MENU_COUNTRIES = {
  en_US: 'Open Menu Countries',
  es_ES: 'Abrir Menú de Países',
  pt_BR: 'Abrir Menu de Países',
};

export const COUNTRY = {
  en_US: 'Country',
  es_ES: 'País',
  pt_BR: 'País',
};

export const ICON = {
  en_US: 'Icon',
  es_ES: 'Ícono',
  pt_BR: 'Ícone',
};

export const OPEN_COUNTRIES_SOUTH_AMERICA = {
  en_US: 'Open Countries South America',
  es_ES: 'Abrir Países Sudamérica',
  pt_BR: 'Abrir Países América do Sul',
};

export const OPEN_MENU_CENTRAL_AMERICA = {
  en_US: 'Open Menu Central America',
  es_ES: 'Abrir Menú América Central',
  pt_BR: 'Abrir Menu América Central',
};

export const OPEN_MENU_NORTH_AMERICA = {
  en_US: 'Open Menu North America',
  es_ES: 'Abrir Menú América del Norte',
  pt_BR: 'Abrir Menu América do Norte',
};
