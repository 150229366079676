import autoRentalSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-auto-afluent.svg';
import autoRentalSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-auto-master.svg';
import seguroCompraSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-compra-afluent.svg';
import seguroCompraSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-compra-master.svg';

import garantiaExtendidaSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-garantia-master.svg';
import garantiaExtendidaSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-garantia-afluent.svg';
import travelCertificateSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-viaje-master.svg';
import travelCertificateSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-viaje-afluent.svg';

export const CONFIG_CERTIFICATES = (intl, afluent) => {
  return {
    certificate_extended_warranty: {
      name: intl.TITLE_EXTENDED_WARRANTY_CERTIFICATE,
      icon: afluent ? garantiaExtendidaSvgAfluent : garantiaExtendidaSvgMaster,
    },
    travel_insurance: {
      name: intl.TITLE_TRAVEL_INSURANCE_CERTIFICATE,
      icon: afluent ? travelCertificateSvgAfluent : travelCertificateSvgMaster,
    },
    billete_auto_rental_insurance: {
      name: intl.TITLE_AUTO_RENTAL_CERTIFICATE,
      icon: afluent ? autoRentalSvgAfluent : autoRentalSvgMaster,
    },
    certificate_seguro_compra: {
      name: intl.TITLE_PURCHASE_CERTIFICATE,
      icon: afluent ? seguroCompraSvgAfluent : seguroCompraSvgMaster,
    },
  };
};
