import React, { useContext } from 'react';
import { IntlContext } from '../../../../../intl';
import styles from './index.module.scss';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { handleCity } from './utils';
import GroupDivisor from '../../../../GroupDivisor';
import InfoLabelValue from '../../../../InfoLabelValue/InfoLabelValue';
import { formatDateWithOffset } from '../../../../../utils/date';

const FlightDetail = ({ flight, index }) => {
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <>
      <GroupDivisor
        title={intl.FLIGHT_LEG(index + 1)}
        className={styles.divider}
      />
      <InfoLabelValue label={intl.FLIGHT_NUMBER} value={flight.flight_number} />
      <div className={`${styles.container} ${styles[theme]}`}>
        <div className={styles.leftContainer}>
          <div className={styles.airportContainer}>
            <h6 className={`${styles[theme]} ${styles.sideTitle}`}>
              {intl.DEPARTURE}
            </h6>
            <InfoLabelValue
              label={intl.DEPARTURE_TIME}
              value={formatDateWithOffset(
                flight.actual_start_at || flight.start_at,
                idiom,
                'P p'
              )}
            />
            <InfoLabelValue
              label={intl.ORIGIN_AIRPORT}
              value={flight.airport_origin}
            />
            <InfoLabelValue
              label={intl.ORIGIN_CITY}
              value={handleCity(
                flight.country_origin,
                flight.city_origin,
                idiom
              )}
            />
          </div>
        </div>
        <div className={`${styles.airportContainer} ${styles.rightContainer}`}>
          <h6 className={`${styles[theme]} ${styles.sideTitle}`}>
            {intl.ARRIVAL}
          </h6>
          <InfoLabelValue
            label={intl.ARRIVAL_TIME}
            value={formatDateWithOffset(
              flight.actual_end_at || flight.end_at,
              idiom,
              'P p'
            )}
          />
          <InfoLabelValue
            label={intl.DESTINATION_AIRPORT}
            value={flight.airport_destination}
          />
          <InfoLabelValue
            label={intl.DESTINATION_CITY}
            value={handleCity(
              flight.country_destination,
              flight.city_destination,
              idiom
            )}
          />
        </div>
      </div>
    </>
  );
};

export default FlightDetail;
