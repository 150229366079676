import React, { useContext } from 'react';

import Input from '../../../../../../../components/Input/Input';
import styles from './BankArea.module.scss';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import { safeGet } from '../../../../../../../utils/object';
import Select from '../../../../../../../components/Select/Select';
import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import { CHEKING, SAVING } from '../constants';
import IntermediaryArea from './IntermediaryArea/IntermediaryArea';
import BankFields from './BankFields/BankFields';
import { getCountryFromPrefix } from '../../../../../../../utils/country';
import DashedContainer from '../../../../../../../components/DashedContainer/DashedContainer';

const BankArea = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    showIntermediaryBankArea,
    errors,
    isProfile,
  } = props;

  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const countryName = () => {
    let countryName = getCountryFromPrefix(values.BankAccount.bankCountryView);
    if (!countryName) return;
    return countryName[idiom];
  };

  const fullCountryName = countryName();

  return (
    <DashedContainer dashed={!isProfile}>
      <div className={`row ${styles.section}`}>
        <div className="col-12 col-md-4">
          <InfoLabelValue
            label={intl.TXT_COUNTRY_REIMBURSEMENT}
            value={fullCountryName}
          />
        </div>

        <div className="col-12 col-md-5">
          <Input
            value={safeGet(values, 'BankAccount.bank.name')}
            label={intl.FORM_LABEL_BANK_NAME}
            name="BankAccount.bank.name"
            dataTestid="InputBankInfoBankName"
            onChange={handleChange}
            type="text"
            touched={safeGet(touched, 'BankAccount.bank.name')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.bank.name')}
            maxLength="80"
          />
        </div>

        <div className="col-12 col-md-3">
          <Select
            label={intl.LABEL_ACCOUNT_TYPE}
            value={safeGet(values, 'BankAccount.account_type')}
            dataTestid="InputBankInfoBankAccountType"
            onChange={handleChange}
            touched={safeGet(touched, 'BankAccount.account_type')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.account_type')}
            name="BankAccount.account_type"
          >
            <option
              className={styles[theme]}
              data-testid={`optionsAcTypeEmpty`}
              value=""
              key={0}
            >
              {intl.LABEL_ACCOUNT_TYPE}
            </option>
            <option
              className={styles[theme]}
              data-testid={`optionsAcTypeChecking`}
              value={CHEKING}
              key={1}
            >
              {intl.LABEL_ACCOUNT_TYPE_CHECKING}
            </option>
            <option
              className={styles[theme]}
              data-testid={`optionsAcTypeSaving`}
              value={SAVING}
              key={2}
            >
              {intl.LABEL_ACCOUNT_TYPE_SAVING}
            </option>
          </Select>
        </div>
      </div>

      <BankFields {...props} />

      {showIntermediaryBankArea && <IntermediaryArea {...props} />}
    </DashedContainer>
  );
};

export default BankArea;
