export default (env, axios, errorHandler) => ({
  getCertificates: async (customer_id, idiom = 'en-US') => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/certificates`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getCertificates', {
      url,
      customer_id,
      idiom,
    });
  },
  getCertificatesByBin: async (customer_id, bin_number, idiom = 'en-US') => {
    const url = `/api/specific/visagateway/v1/customers/${customer_id}/certificates?contract_id=${bin_number}`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getCertificatesByBin', {
      url,
      customer_id,
      bin_number,
      idiom,
    });
  },
  listCertificateTypesByBinNumber: async (bin_number, idiom) => {
    const binQueryParameter =
      bin_number.length === 16
        ? 'credit_card_token'
        : 'bank_identification_number';

    const url = `/api/specific/visagateway/v1/certificate_types?${binQueryParameter}=${bin_number}`;
    const headers = {
      'Accept-Language': idiom,
    };
    const cacheKey = url + idiom;
    return errorHandler(
      axios.cacheGet(cacheKey)(url, { headers }),
      'listCertificateTypesByBinNumber',
      { url, idiom, bin_number }
    );
  },
  createTravelCertificateInt: async (data, idiom = 'en-US') => {
    let version = 'v1';
    const certificateEnableToUseMLE =
      env.REACT_APP_JWE_CERTIFICATE_API_ENABLE.toString() === 'true';

    if (certificateEnableToUseMLE) {
      version = 'v1';
    }

    const url = certificateEnableToUseMLE
      ? `/internal/certificate/specific/visagateway/sales/individual/${version}/travel/certificates`
      : '/api/sales/v1/individual/travel/certificates';

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.post(url, data, { headers }),
      'createTravelCertificateInt',
      { url, data, idiom }
    );
  },
  postApplianceAnual: async (data, idiom = 'en-US') => {
    let version = 'v1';
    const certificateEnableToUseMLE =
      env.REACT_APP_JWE_CERTIFICATE_API_ENABLE.toString() === 'true';

    if (certificateEnableToUseMLE) {
      version = 'v1';
    }

    const url = certificateEnableToUseMLE
      ? `/internal/certificate/specific/visagateway/sales/individual/${version}/appliance/certificates`
      : '/api/sales/v1/individual/appliance/certificates';

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.post(url, data, { headers }),
      'postApplianceAnual',
      { url, data, idiom }
    );
  },
  postCarRental: (data, idiom = 'en-US') => {
    let version = 'v1';
    const certificateEnableToUseMLE =
      env.REACT_APP_JWE_CERTIFICATE_API_ENABLE.toString() === 'true';

    if (certificateEnableToUseMLE) {
      version = 'v1';
    }

    const url = certificateEnableToUseMLE
      ? `/internal/certificate/specific/visagateway/sales/individual/${version}/car_rental/certificates`
      : '/api/sales/v1/individual/car_rental/certificates';

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.post(url, data, { headers }), 'postCarRental', {
      url,
      data,
      idiom,
    });
  },
  postAppliance: (data, idiom = 'en-US') => {
    let version = 'v1';
    const certificateEnableToUseMLE =
      env.REACT_APP_JWE_CERTIFICATE_API_ENABLE.toString() === 'true';

    if (certificateEnableToUseMLE) {
      version = 'v1';
    }

    const url = certificateEnableToUseMLE
      ? `/internal/certificate/specific/visagateway/sales/individual/${version}/appliance/certificates`
      : '/api/sales/v1/individual/appliance/certificates';

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.post(url, data, { headers }, 'postAppliance', { url, data, idiom })
    );
  },
  postSendPDFEmail: async (cn, certificate_id, idiom) => {
    const url = `/api/specific/visagateway/v1/customers/${cn}/certificates/${certificate_id}/send`;
    const headers = {
      'Accept-Language': idiom,
    };
    const data = {
      channel: 'EMAIL',
    };
    return errorHandler(
      axios.post(url, data, { headers }, 'postSendPDFEmail', {
        url,
        data,
        idiom,
      })
    );
  },
  getCertificatesById: async (certificate_id, idiom = 'en-US') => {
    let version = 'v1';
    const certificateEnableToUseMLE =
      env.REACT_APP_JWE_CERTIFICATE_API_ENABLE.toString() === 'true';

    if (certificateEnableToUseMLE) {
      version = 'v1';
    }

    const url = certificateEnableToUseMLE
      ? `/internal/certificate/specific/visagateway/sales/individual/${version}/travel/certificates/${certificate_id}`
      : `/api/sales/v1/individual/travel/certificates/${certificate_id}`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getCertificatesById', {
      url,
      certificate_id,
      idiom,
    });
  },
});
