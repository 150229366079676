import React, { useContext, useEffect, useCallback } from 'react';
import styles from './modal.module.scss';
import { StateContext } from '../StateContextParent/StateContextParent';
import ModalLoading from './ModalLoading/ModalLoading';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ModalError from './ModalError/ModalError';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import CloseIcon from '../../assets/icons/CloseIcon';
import {
  trapFocusInsideDialog,
  removeTrapFocusInsideDialog,
} from './trapFocusModal';

const Modal = () => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { state, actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { modal } = state;
  const title = state.modal.title !== false ? state.modal.title : false;
  const icon = state.modal.icon;
  let css = state.modal.css;
  let className = '';
  let close = state.modal.close ? state.modal.close : false;
  const modalDuo = modal.duo ? styles.modalDuo : '';
  const loading = modal.loading;
  const error = modal.error;
  const filterBlur = modal.loading ? '5px' : '0px';
  const closeAndReload = modal.closeAndReload;
  const borderBottom = modal.borderBottomTitle;
  const axa = modal.axa;
  const iconSVG = modal.iconSVG;

  const closeModalWithEsc = useCallback(event => {
    if (event.key === 'Escape') {
      actions.modal.closeModal();
    }
  }, []);

  useEffect(() => {
    if (state.modal.show) {
      document.addEventListener('keyup', closeModalWithEsc);
    } else {
      document.removeEventListener('keyup', closeModalWithEsc);
    }
  }, [state.modal.show]);

  useEffect(() => {
    if (state.modal.show) {
      trapFocusInsideDialog();
    } else {
      removeTrapFocusInsideDialog();
    }
  }, [state.modal.show]);

  const ModalClose = (close, actions, closeAndReload) => {
    return close ? (
      <div>
        <button
          tabIndex={0}
          data-testid="modalSuperBtnClose"
          type="button"
          className={`close ${styles.close} ${axa ? styles.axa : ''}`}
          data-dismiss="modal"
          aria-label={intl.CLOSE}
          onClick={() =>
            closeAndReload
              ? window.location.reload()
              : actions.modal.closeModal()
          }
        >
          <span className={styles[theme]} aria-hidden="true">
            <CloseIcon width={25} height={25} />
          </span>
        </button>
      </div>
    ) : (
      ''
    );
  };

  const ModalTitle = (title, icon) => {
    return (
      <>
        {icon && (iconSVG ? icon : <img src={icon} alt={intl.ICON} />)}
        <h5
          id="dialog_label"
          className={`modal-title ${styles[theme]} ${styles.modalTitle}`}
        >
          {title ? title : ''}
        </h5>
      </>
    );
  };

  const ModalHeader = (
    title,
    close,
    actions,
    modalDuo,
    closeAndReload,
    icon,
    borderBottom,
    intl
  ) => {
    let style = {};
    if (!title) {
      style.border = 'none';
      style.paddingBottom = '0px';
    }
    return title !== false || close !== false ? (
      <>
        <div className={styles.containerModalTitle}>
          {!modalDuo && (
            <div
              className={`${styles.boxModalTitle} ${
                borderBottom ? `${styles[theme]} ${styles.borderBottom}` : ''
              }`}
            >
              {ModalTitle(title, icon)}
            </div>
          )}
          {ModalClose(close, actions, closeAndReload)}
        </div>
        {borderBottom && (
          <div className={styles.mandatoryMessage}>
            <div className={styles.wrappSpan}>
              <span>{intl.MANDATORY_FIELDS}</span>
            </div>
          </div>
        )}
      </>
    ) : (
      ''
    );
  };

  if (modal.show === true) {
    document.body.classList.add('modal-open');
    className += ' ' + styles.show;
  } else {
    document.body.classList.remove('modal-open');
    return null;
  }

  return (
    <>
      <div
        data-testid="modalSuper"
        className={`modal-backdrop fade ${
          modal.show ? `show ${styles.modal}` : styles.zindexNone
        }`}
      />
      <div
        data-testid="modal"
        className={`modal ${className} ${styles.modal}`}
        id="wrapperModal"
      >
        <div
          className={`modal-dialog modal-dialog-centered modal-lg ${styles.modalMobile} ${styles.noMargin}`}
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="dialog_label"
            aria-describedby="dialog_desc"
            className={`modal-content ${styles.borderHighLight} ${styles.noBorder} ${css} ${modalDuo} ${styles[theme]}`}
            ref={modal.modalRef}
          >
            {loading && <ModalLoading axa={axa ? 1 : 0} />}
            {error && <ModalError error={error} />}
            {ModalHeader(
              title,
              close,
              actions,
              modal.duo,
              closeAndReload,
              icon,
              borderBottom,
              intl
            )}
            <div
              className={`${styles.boxContent} ${
                error ? styles.modalErrorContent : ''
              }`}
              style={{ filter: `blur(${filterBlur})` }}
            >
              {modal.innerComponent}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
