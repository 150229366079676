import * as Yup from 'yup';
import moment from 'moment';
import { CheckIsVisaNumber } from '../../../../../../../../../utils/card';
import { validateExpirationDate } from '../../../../../../../../../components/UpdateBinTokenex/InputTokenex/validationSchema';
import { removeNonNumeric } from '../../../../../../../../../utils/stringUtils';

export const validationOctSchema = intl => {
  return Yup.lazy(value => {
    let octSchema = {};
    octSchema.name = Yup.object().shape({
      first: Yup.string().required(intl.TEXT_REQUIRED_FORM),
      last: Yup.string().required(intl.TEXT_REQUIRED_FORM),
    });

    octSchema.birth_date = Yup.mixed()
      .test('match', intl.VALIDATE_DATE_VALID, function (birth_date) {
        const dateForValidation = moment('1900-01-01').format('YYYY-MM-DD');
        const birthDateForValidation = moment(birth_date).format('YYYY-MM-DD');
        if (moment(birthDateForValidation).isSameOrBefore(dateForValidation)) {
          return false;
        }
        const yesterday = moment(new Date())
          .subtract(1, 'days')
          .format('YYYY-MM-DD HH:ss');
        return moment(birth_date).isSameOrBefore(yesterday);
      })
      .typeError(intl.TEXT_REQUIRED_FORM);
    if (!value.paymentIdOCT) {
      octSchema.card = Yup.object().shape({
        cvv: Yup.string().required(intl.TEXT_REQUIRED_FORM),
        expirationDate: Yup.string()
          .test('valid-expiration-date', intl.TEXT_INVALID_DATE, value => {
            if (value && value.length === 5) {
              return validateExpirationDate(value);
            }
            return false;
          })
          .min(5, intl.TEXT_REQUIRED_FORM)
          .required(intl.TEXT_REQUIRED_FORM),
        number: Yup.string()
          .test('is-visa-card', intl.TEXT_NOT_VISA_PRODUCT, pan =>
            CheckIsVisaNumber(removeNonNumeric(pan))
          )
          .test(
            'has-16-digits',
            intl.TEXT_FORMAT_INVALID,
            pan => removeNonNumeric(pan).length === 16
          )
          .required(intl.TEXT_REQUIRED_FORM),
      });
    }

    return Yup.object().shape({
      ...octSchema,
    });
  });
};
