import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../../utils/sanitize';

const TotalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;

  useEffect(() => {
    const otherInsurance =
      values.other_insurance_covering &&
      values.other_insurance_covering.is_internal &&
      values.other_insurance_covering.amount.value
        ? values.other_insurance_covering.amount.value
        : 0;

    const amounClaimed = values.amount_claimed.value
      ? values.amount_claimed.value
      : 0;
    const result = amounClaimed - otherInsurance;
    const diff = result !== 0 ? result.toFixed(2) : '0';
    setFieldValue('total_amount_claimed.value', diff);
  }, [
    values.other_insurance_covering.amount.value,
    values.amount_claimed.value,
    values.other_insurance_covering,
    setFieldValue,
  ]);

  return (
    <div className="row">
      <div className="col-12 col-sm-10 col-md-6" style={{ display: 'none' }}>
        <Input
          value={values.total_amount_claimed.value}
          label={intl.LABEL_TOTAL_AMOUNT_REIMBURSERD}
          name={`total_amount_claimed.value`}
          type="text"
          maskedMoney
          disabled
          onChange={handleChange}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched &&
            touched.total_amount_claimed &&
            touched.total_amount_claimed.value
          }
          onBlur={handleBlur}
          error={
            errors &&
            errors.total_amount_claimed &&
            errors.total_amount_claimed.value
          }
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6" style={{ display: 'none' }}>
        <Select
          dataTestid="selectAmountClaimedCurrency"
          label={intl.LABEL_CURRENCY}
          value={values.total_amount_claimed.currency}
          name={`total_amount_claimed.currency`}
          onChange={handleChange}
          disabled
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={
            touched &&
            touched.total_amount_claimed &&
            touched.total_amount_claimed.currency
          }
          onBlur={handleBlur}
          error={
            errors &&
            errors.total_amount_claimed &&
            errors.total_amount_claimed.currency
          }
        />
      </div>
    </div>
  );
};

export default TotalArea;
