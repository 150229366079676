import React from 'react';
import { withRouter } from 'react-router-dom';
import styles from './Notifications.module.scss';
import Notification from './Notification/Notification';

const Notifications = props => {
  const { data, limit, type, updateFn, setReadNotification, setNotifications } =
    props;

  const checkNotification = (id, status) => {
    let notifications = [...data];
    notifications.forEach(item => {
      if (item.notification_id === id) item.isChecked = status;
    });
    setNotifications(notifications);
  };

  const renderNotificationlist = () => {
    return data
      .slice(0, limit)
      .map((notification, index) => (
        <Notification
          data={notification}
          key={`${type}_${index}`}
          updateFn={updateFn}
          setReadNotification={setReadNotification}
          type={type}
          checkNotification={checkNotification}
        />
      ));
  };

  return (
    <div
      className={styles.notifications}
      data-testid={`notificationList-${type}`}
    >
      {data && <ul>{renderNotificationlist()}</ul>}
    </div>
  );
};

export default withRouter(Notifications);
