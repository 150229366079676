import { pt_countries } from '../../../../../intl/pt_countries';
import { en_countries } from '../../../../../intl/en_countries';
import { es_countries } from '../../../../../intl/es_countries';

export const TEXT_ERROR = {
  en_US: 'An error occurred while performing this operation.',
  pt_BR: 'Ocorreu um erro ao efetuar esta operação.',
  es_ES: 'Se ha producido un error al realizar esta operación.',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_CREATE_APPOINTMENT = {
  en_US: 'Create appointment',
  pt_BR: 'Criar agendamento',
  es_ES: 'Crear cita',
};

export const TITLE_DEPENDENTS = {
  en_US: 'Beneficiaries',
  pt_BR: 'Beneficiários',
  es_ES: 'Beneficiarios',
};

export const ERROR_TIME_OUT = {
  en_US: 'Performing this operation, a timeout happened. Please try again.',
  pt_BR:
    'Ao realizar essa operação, o tempo de processamento esgotou. Por favor, tente novamente.',
  es_ES:
    'Al realizar esta operación, ha transcurrido el tiempo de procesamiento. Por favor, intenta nuevamente.',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_DATE_APPOINTAMENT = {
  en_US: 'Select a date.',
  pt_BR: 'Select a date.',
  es_ES: 'Select a date.',
};

export const LABEL_NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const CONTACT_INFO = {
  en_US: 'Contact information',
  pt_BR: 'informações do contato',
  es_ES: 'Información del contacto ',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};

export const TEXT_PHONE_NUMBER = {
  en_US: 'Phone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const TEXT_NUMBER = {
  en_US: 'Area code - Number *',
  pt_BR: 'Código de área - Número *',
  es_ES: 'Código de área - Número *',
};

export const TEXT_NUMBER_STATE = {
  en_US: 'Phone state *',
  pt_BR: 'Estado do telefone *',
  es_ES: 'Estado del teléfono *',
};

export const TEXT_NUMBER_CITY = {
  en_US: 'Phone city *',
  pt_BR: 'Cidade do telefone *',
  es_ES: 'Ciudad del teléfono *',
};

export const LABEL_PREFIX = {
  en_US: 'Country prefix *',
  pt_BR: 'Prefixo do país *',
  es_ES: 'Prefijo del país *',
};

export const TEXT_ALERT_PREFIX = {
  en_US: 'Include your international country code.',
  pt_BR: 'Inclua seu código internacional do país.',
  es_ES: 'Incluye tu código de país internacional.',
};

export const LABEL_PHONE_TYPE = {
  en_US: 'Type *',
  pt_BR: 'Tipo *',
  es_ES: 'Tipo *',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const TEXT_NOTES = {
  en_US: 'Additional contact information',
  pt_BR: 'Informações adicionais de contato',
  es_ES: 'Información adicional del contacto',
};

export const TEXT_ADD_QUESTION = {
  en_US: 'Additional questions',
  pt_BR: '',
  es_ES: 'Preguntas adicionales',
};

export const MOST_INTERESTED = {
  en_US: 'Select the type of question you want to ask',
  pt_BR: 'Selecione o tipo de pergunta que quer fazer',
  es_ES: 'Selecciona el tipo de pregunta que quieres realizar',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Scheduling date unavailable.',
  pt_BR: 'Data de agendamento indisponível.',
  es_ES: 'Fecha de programación no disponible.',
};

export const TIMEZONE = {
  en_US: 'Timezone',
  pt_BR: 'Timezone',
  es_ES: 'Zona horaria',
};

export const AVAILABLE_TIMES = {
  en_US: 'Available times (0:00 - 23:30)',
  pt_BR: '',
  es_ES: 'Horarios disponibles (0:00 - 23:30)',
};

export const TITLE_SCHEDULING = {
  en_US: 'Select date, time and the time zone of your appointment',
  pt_BR: '',
  es_ES: 'Selecciona el día, la hora y la zona horaria de tu cita',
};

export const SCHEDULING_DATE = {
  en_US: 'Scheduling date *',
  pt_BR: '',
  es_ES: 'Fecha de programación *',
};

export const TEXT_QUESTIONS_NAL_1 = {
  en_US: 'Information about self-care techniques for common symptoms.',
  pt_BR: 'Informações sobre técnicas de autocuidado para sintomas comuns.',
  es_ES: 'Información sobre técnicas de autocuidado para síntomas comunes.',
};

export const TEXT_QUESTIONS_NAL_2 = {
  en_US: 'Health information and research about chronic conditions.',
  pt_BR: 'Informações de saúde e pesquisas sobre condições crônicas.',
  es_ES: 'Información de salud e investigación sobre condiciones crónicas.',
};

export const TEXT_QUESTIONS_NAL_3 = {
  en_US: 'Recently diagnosed medical condition.',
  pt_BR: 'Condição médica recentemente diagnosticada.',
  es_ES: 'Condición médica recientemente diagnosticada.',
};

export const TEXT_QUESTIONS_NAL_4 = {
  en_US: 'Information about prescription and over the counter medication.',
  pt_BR: 'Informações sobre medicamentos prescritos e de venda livre.',
  es_ES: 'Información sobre medicamentos recetados y de venta libre.',
};

export const TEXT_QUESTIONS_COVID_OTHER = {
  en_US: 'Other questions',
  pt_BR: 'Outras perguntas',
  es_ES: 'Otras preguntas',
};

export const TEXT_MANY_APPOINTMENTS_TO_SAME_HOUR = {
  en_US:
    'This schedule is no longer available. Please choose a different time option.',
  pt_BR:
    'Este horário não está mais disponível. Por favor escolha outra opção de horário.',
  es_ES:
    'Este horario ya no está disponible. Por favor, elija otra opción de tiempo.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_SUCCESS = {
  en_US: 'Appointments successfully generated.',
  pt_BR: 'Appointments gerado com sucesso.',
  es_ES: 'Appointments generado con éxito.',
};

export const TEXT_ONE_QUESTION_MUST_BE_SELECTED = {
  en_US: 'At least one question must be selected.',
  pt_BR: 'Pelo menos uma pergunta deve ser selecionada.',
  es_ES: 'Se debe seleccionar al menos una pregunta.',
};

export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};

export const COUNTRY_PHONE_LABEL = {
  en_US: 'Phone country *',
  pt_BR: 'País do telefone *',
  es_ES: 'País del teléfono *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};

export const TEXT_CREATING = {
  en_US: 'Please, wait while your appointment is created...',
  pt_BR: 'Seu agendamento está sendo criado...',
  es_ES: 'Por favor, espera mientras creamos tu cita...',
};

export const LIMIT_DATE = {
  en_US:
    'The deadline for scheduling has been reached. Therefore, there is no time availability.',
  pt_BR:
    'A data limite para agendamento foi atingida. Dessa forma, não existe disponibilidade de horário.',
  es_ES:
    'Se ha cumplido el plazo para la programación. Por lo tanto, no hay disponibilidad de tiempo.',
};
