export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const CUSTOM_DATE_VALIDATION = {
  en_US:
    'The Incident Date can only be a maximum of 180 days after the date of purchase.',
  pt_BR:
    'A data do incidente só pode ser no máximo 180 dias após a data de compra.',
  es_ES:
    'La fecha del incidente sólo puede ser como máximo 180 días después de la fecha de compra.',
};
