export const TTTLE_ACTIVED_VOUCHERS = {
  en_US: 'Active Vouchers',
  pt_BR: 'Vouchers ativos',
  es_ES: 'Vouchers activos',
};

export const TTTLE_EXPIRED_VOUCHERS = {
  en_US: 'Expired Vouchers',
  pt_BR: 'Vouchers expirados',
  es_ES: 'Vouchers vencidos',
};

export const TEXT_SEE_MORE = {
  en_US: 'See more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_EMPTY_VOUCHERS = {
  en_US: 'You do not currently have any voucher.',
  es_ES: 'Actualmente no tienes ningún voucher.',
  pt_BR: 'No momento, você não tem nenhum voucher.',
};

export const TEXT_EMPTY_VOUCHERS_EXPIRED = {
  en_US: 'You do not currently have any expired voucher.',
  es_ES: 'Actualmente no tienes ningún voucher vencido.',
  pt_BR: 'No momento, você não tem nenhum voucher expirado.',
};
