import React from 'react';

import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import ExpensesArea from './ExpensesArea/ExpensesArea';

const TripCancellation = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <ExpensesArea claimDetails={claimDetails} />
    </div>
  );
};

export default TripCancellation;
