/* eslint-disable no-unused-vars */
import { VALUE_AR } from '../pages/Claims/Generate/ClaimFillForm/Form/PaymentInformation/constants';
import {
  bancoBrasilValidate,
  bancoBradescoValidate,
  bancoItauValidate,
  bancoGenericValidate,
  bancoCaixaValidate,
  bancoCitibankValidate,
  bancoHsbcValidate,
  bancoStantanderValidate,
  bancoBanrisulValidate,
  bancoNubankValidate,
  mercadoPagoValidate,
  xpInvestimentosValidate,
} from './banksValidate';

const genericValidator = bancoGenericValidate;
const validators = {
  '001': bancoBrasilValidate,
  237: bancoBradescoValidate,
  341: bancoItauValidate,
  // 104: bancoCaixaValidate,
  745: bancoCitibankValidate,
  399: bancoHsbcValidate,
  '033': bancoStantanderValidate,
  '041': bancoBanrisulValidate,
  // 260: bancoNubankValidate,
  // 323: mercadoPagoValidate,
  // 102: xpInvestimentosValidate
};

export const getValidate = code => {
  if (validators[code]) {
    return validators[code];
  } else {
    return genericValidator;
  }
};

const OCT = 'OCT';
export const getBanksWithoutOCT = banks => {
  return banks.filter(bank => {
    const OCTreturn = bank.account_codes.find(
      wireCode => wireCode.wire_code_type === OCT
    );
    return OCTreturn ? false : true;
  });
};

export const FULL_KEY = 'FULL_KEY';
export const IBAN = 'IBAN';
export const SWIFT = 'SWIFT';
export const CLABE = 'CLABE';
export const ABA = 'ABA';
export const CUIT = 'CUIT';
export const NEW_SWIFT = 'SWIFT_BIC';
export const NEW_ABA = 'ABA_RTN';

/**
 * BMP
 * List of Issuer
 */

export const PORTOBANK = 'portobank';

export const identifyAccountCodes = (bank, country) => {
  const isAR = country === 'ARGENTINA';
  const isMX = country === 'MEXICO';

  if (isAR) {
    return [CUIT, FULL_KEY];
  } else if (isMX) {
    return [FULL_KEY, SWIFT];
  }

  if (bank.preferred_transfer_type && bank.preferred_transfer_type !== '') {
    switch (bank.preferred_transfer_type) {
      case CLABE:
        return [FULL_KEY];
      case IBAN:
        return [IBAN];
      case NEW_SWIFT:
        return [SWIFT];
      case NEW_ABA:
        return [ABA];
      default:
        return [];
    }
  } else {
    if (
      bank.bank_identification_number &&
      bank.bank_identification_number.length === 11
    ) {
      return [SWIFT];
    } else {
      return [];
    }
  }
};

export const isDomainByIssuer = issuer => {
  const domain = window.location.hostname
    ? window.location.hostname.split('.')[0]
    : false;
  return domain && domain.includes(issuer);
};

// Add other BMP issuers when available
export const isIssuerDomain = () => isDomainByIssuer(PORTOBANK);
