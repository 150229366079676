export const DOCUMENTATION_PROVIDED = {
  en_US:
    'If the documentation provided is complete and correct, the indemnity amount will be USD $600 for Visa Infinite/ Infinite Business/Infinite Corporate.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser indenizado será de USD $600 para Visa Infinite/ Infinite Business/Infinite Corporate.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, el monto a indemnizar será de USD $600 para Visa Infinite/ Infinite Business/Infinite Corporate.',
};

export const DOCUMENTATION_PROVIDED_SIGNATURE = {
  en_US:
    'If the documentation provided is complete and correct, the indemnity amount will be USD $500 for Visa Signature / Signature Business / Signature Corporate.',
  pt_BR:
    'Si la documentación proporcionada está completa y correcta, el monto a indemnizar será de USD $500 para Visa Signature / Signature Business / Signature Corporate.',
  es_ES:
    'Se a documentação fornecida estiver completa e correta, o valor a ser indenizado será de USD $500 para Visa Signature / Signature Business / Signature Corporate.',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder *',
  pt_BR: 'Relação com o titular do cartão *',
  es_ES: 'Relación con el titular de la tarjeta *',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const CARDHOLDER_DISCLAIMER = {
  en_US:
    'We remind you that the claim must be initiated by the cardholder used in the purchase of the product / tickets / car rental.',
  pt_BR:
    'Lembramos que o sinistro deve ser iniciado pelo titular do cartão utilizado na compra do produto / passagens / aluguel de veículo.',
  es_ES:
    'Te recordamos que la reclamación debe ser iniciada por el titular de la tarjeta utilizado en la compra del producto / boletos / alquiler de vehículo.',
};
