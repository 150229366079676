import moment from 'moment';

export const extendedWarranty_ProtectionToApi = props => {
  let object = {};
  object.affected_person = affected_personToApi(props);
  if (props.values.incident.other_insurance_covering.is_internal === 'true') {
    object.other_insurance_covering = other_insurance_coveringToApi(props);
  }
  object.item_information = item_informationToApi(props);
  object.incident = incidentToApi(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const item_informationToApi = props => {
  const item_information = props.values.incident.item_information;
  const purchase_date = moment(item_information.purchase_date).format(
    'YYYY-MM-DD'
  );

  return {
    purchase_date: purchase_date,
    label: item_information.label,
    model_number: item_information.model_number,
    manufacturer: item_information.manufacturer,
    price: {
      value: Number(item_information.price.value),
      currencyValue: item_information.price.currencyValue,
    },
    total_invoice_amount: {
      value: Number(item_information.total_invoice_amount.value),
      currency: item_information.price.currencyValue,
    },
    number_of_installments: +item_information.installments_number,
    is_full_charged_on_card:
      item_information.is_full_charged_on_card === 'true' ? true : false,
  };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;
  const datetimeValue = moment(incident.datetimeValue).format(
    'YYYY-MM-DD hh:mm:ss'
  );

  return {
    datetimeValue: datetimeValue,
    description: incident.description,
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
      birth_date: moment(affected_person.person.birth_date).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      gender: affected_person.person.gender,
    },
  };
};

const other_insurance_coveringToApi = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const item_information = props.values.incident.item_information;

  return [
    {
      is_internal: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      description: '',
      amount: {
        value: Number(other_insurance_covering.value),
        currency: item_information.price.currencyValue,
      },
    },
  ];
};

const total_amount_claimedToApi = props => {
  const total_amount_claimed =
    Number(props.values.incident.item_information.price.value) -
    Number(props.values.incident.other_insurance_covering.value);

  return {
    value: Number(total_amount_claimed),
    currency: props.values.incident.item_information.price.currencyValue,
  };
};
