import PurchaseProtection from './PurchaseProtection/PurchaseProtection';
import AutoRental from './AutoRental/AutoRental';
import Travel from './Travel/Travel';
import ExtendedWarranty from './ExtendedWarranty/ExtendedWarranty';

export const componentByCertificateType = {
  certificate_seguro_compra: PurchaseProtection,
  billete_auto_rental_insurance: AutoRental,
  travel_insurance: Travel,
  certificate_extended_warranty: ExtendedWarranty,
};
