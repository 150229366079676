/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import ContactArea from './ContactArea';
import TelecomunicationTypeSelect from './TelecomunicationTypeSelect/TelecomunicationTypeSelect';
import validationSchema from './validationSchema';
import * as translations from './../intl';
import * as translations_GeneralInfo from '../../../../Generate/ClaimFillForm/Form/intl';
import { IntlContext } from '../../../../../../intl';
import { teleconsutationFormTwoValues } from './../initialValues';

const ConsultArea = props => {
  const { updateParent, submitRef, cardHolder } = props;

  const { translate, twoDigitsIdiom } = useContext(IntlContext);
  const intl_teleconsultation = translate(translations);
  const intl_GeneralInfo = translate(translations_GeneralInfo);
  const intl = { ...intl_teleconsultation, ...intl_GeneralInfo };
  const language = twoDigitsIdiom();

  const validationSchemaData = useMemo(() => validationSchema(intl), [intl]);

  const initialValues = useMemo(
    () =>
      teleconsutationFormTwoValues({
        cardHolder,
        language,
      }),
    [language]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchemaData}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchemaData);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <button
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
              role="btnSubmit"
            ></button>
            <TelecomunicationTypeSelect {...props} />
            <ContactArea {...props} />
          </form>
        );
      }}
    </Formik>
  );
};

export default ConsultArea;
