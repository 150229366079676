/* eslint-disable no-console */
import TagManager from 'react-gtm-module';
import { textTransformFirstLetterToUpperCase } from './stringUtils';

export const CLAIM_STARTED = 'claim_started';
export const CLAIM_TERMS_OPENED = 'claim_termsandcond_opened';
export const CLAIM_COMPLETED_STEP = 'claim_completedStep';
export const CLAIM_FAILED = 'claim_failed';
export const CLAIM_CREATED = 'claim_created';
export const CLAIM_DOCUMENT_ATTACHED_INTENTION =
  'claim_document_attached_intention';
export const CLAIM_DOCUMENT_ATTACHED = 'claim_document_attached';
export const CLAIM_DOCUMENT_ATTACHED_FAILED = 'claim_document_attached_failed';

export const CERTIFICATE_STARTED = 'certificate_started';
export const CERTIFICATE_COMPLETED_STEP = 'certificate_completedStep';
export const CERTIFICATE_CREATED = 'certificate_created';

export const CREDCARD_ADDED = 'creditCard_added';

export const BENEFICIARY_ADDED = 'beneficiary_added';

export const CLAIM_SITE_SECTION = 'Claims';
export const PROFILE_SITE_SECTION = 'Profile';

const SING_UP = 'sign_up';

const CHAT_OPENED = 'chat_opened';

const EMAIL_CONFIRMATED = 'email_confirmated';

const LOGIN = 'login';

const PAGE_DATA_LAYER = 'dataLayer';

const SELECT_CONTENT = 'select_content';
export const SELECT_CONTENT_EVENT_NAME = 'Select_content';

const SELECTED_CARD = 'selected_card';

const HELP_REQUESTED = 'help_requested';

const FAQ_REQUESTED = 'faq_requested';

const SELECT_PLAN = 'select_plan';

const SELECT_ITEM = 'select_item';

const PAGE_VIEW = 'page view';

const formatPageSection = pageSection => {
  let pageSectionFirstPosition = pageSection.split('/')[1];
  let pageFormated = '';
  switch (pageSectionFirstPosition) {
    case 'home':
      pageFormated = 'Home';
      break;

    case 'benefits':
      pageFormated = 'Benefits';
      break;

    case 'subscription-benefits':
      pageFormated = 'Special Benefits';
      break;

    case 'dashboard':
      pageFormated = 'Dashboard';
      break;

    case 'login':
      pageFormated = 'Login';
      break;

    case 'certificate':
      pageFormated = 'Certificates';
      break;

    case 'claim-type':
      pageFormated = 'Claims';
      break;

    case 'profile':
      pageFormated = 'Profile';
      break;

    case 'Certificates':
      pageFormated = 'Certificates';
      break;

    case 'claims':
      pageFormated = 'Claims';
      break;

    case 'your-benefits':
      pageFormated = 'Benefits';
      break;

    case 'your-subscription-benefits':
      pageFormated = 'Special Benefits';
      break;

    case 'certificates':
      pageFormated = 'Certificates';
      break;

    case 'offers':
      pageFormated = 'Offers';
      break;

    case 'your-offers':
      pageFormated = 'Offers';
      break;

    case 'offer-details':
      pageFormated = 'Offers';
      break;

    case 'your-offer-details':
      pageFormated = 'Offers';
      break;

    case 'help':
      pageFormated = 'Help';
      break;

    case 'signout':
      pageFormated = 'Sign Out';
      break;

    case 'PageNotFound':
      pageFormated = 'Page Not Found';
      break;

    case 'verify_email_result':
      pageFormated = 'Verify Email Result';
      break;

    case 'session-expired':
      pageFormated = 'Session Expired';
      break;

    case 'visa-online-medic':
      pageFormated = 'Visa Online Medic';
      break;

    case 'covid':
      pageFormated = 'Covid';
      break;

    default:
      pageFormated = '';
      break;
  }

  return pageFormated;
};

const formatBenefit = benefit => {
  switch (benefit) {
    case 'travel_insurance':
      return 'Generate a travel certificate';

    case 'certificate_extended_warranty':
      return 'Generate a warranty certificate';

    case 'bloomberg_57':
      return 'Bloomberg';

    case 'amazon_prime_53':
      return 'Amazon';

    case 'rappi_prime_49':
      return 'Rappi';

    default:
      return 'Open a claim';
  }
};

const formatEnjoyBenefit = benefit => {
  switch (benefit) {
    case 'travel_insurance':
      return 'Generate travel certificate';

    case 'certificate_extended_warranty':
      return 'Generate warranty certificate';

    default:
      return 'Create a claim';
  }
};

export const dataLayerCrossSiteTrack = (pageSection, userIsLogged) => {
  try {
    let pageSectionFormated = formatPageSection(pageSection);
    const tagManagerArgs = {
      dataLayer: {
        event: 'trackPageview',
        site_section: pageSectionFormated,
        logged_in: userIsLogged,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerClaimEventTrack = (
  event,
  claimType,
  step = null,
  fields_error = null
) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
        claim_type: claimType.replace(/_/g, ' '),
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    if (step || step === 0) {
      tagManagerArgs.dataLayer.claim_step = step;
    }
    if (fields_error) {
      tagManagerArgs.dataLayer.fields_error = fields_error;
    }
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerClaimDocumentEventTrack = (
  event,
  claimType,
  siteSection = null,
  documentName = null
) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
        claim_type: claimType,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    if (siteSection) {
      tagManagerArgs.dataLayer.site_section = siteSection;
    }
    if (documentName) {
      tagManagerArgs.dataLayer.document_name = documentName;
    }
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerCertificateEventTrack = (
  event,
  certificateType,
  step = null
) => {
  try {
    const certificateTypeFormated = textTransformFirstLetterToUpperCase(
      certificateType
        .replace(/certificate_/g, '')
        .replace(/billete_/g, '')
        .replace(/_/g, ' ')
    );

    const tagManagerArgs = {
      dataLayer: {
        event,
        certificate_type:
          certificateTypeFormated === 'Seguro Compra'
            ? 'Purchase insurance'
            : certificateTypeFormated,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };

    if (step || step === 0) {
      tagManagerArgs.dataLayer.step_number = step;
    }
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    // console.log(error);
  }
};

export const dataLayerCredcardEventTrack = (
  event,
  cardClass,
  siteSection = 'Header'
) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
        card_class: cardClass,
        site_section: siteSection,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerBeneficiaryTrack = (event = BENEFICIARY_ADDED) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerChatTrack = (event = CHAT_OPENED) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerEmailConfirmedTrack = (event = EMAIL_CONFIRMATED) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerSignUpTrack = customerId => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SING_UP,
        user_id: customerId,
        sign_up_step: 'Confirm credentials and card number',
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerLoginSuccessTrack = (
  customerId,
  registeredCards,
  beneficiariesCount,
  userCountry
) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: LOGIN,
        idUser: customerId,
        loginFrom: 'Login Page',
        registered_cards: registeredCards,
        beneficiaries_count: beneficiariesCount,
        user_country: userCountry,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerContentMenuTrack = (
  itemId,
  content_type = 'Header Menu'
) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type: content_type,
        item_id: itemId,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };

    if (!content_type) {
      delete tagManagerArgs.dataLayer.content_type;
    }

    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerBenefitTrack = (
  itemId,
  content_type = 'Benefits card',
  categories
) => {
  try {
    const id =
      categories && categories === 'special_benefits'
        ? itemId
        : formatBenefit(itemId);
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type,
        item_id: `CTA ${id}`,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };

    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerEnjoyBenefitTrack = (
  itemId,
  content_type = 'Enjoy benefits'
) => {
  try {
    const id = formatEnjoyBenefit(itemId);
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type,
        item_id: `CTA ${id}`,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };

    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerCardTrack = card_type => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECTED_CARD,
        card_type,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerHelpTrack = (help_type, item_id) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: HELP_REQUESTED,
        help_type,
        item_id,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerHelpFAQTrack = (faq_category, question_id) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: FAQ_REQUESTED,
        faq_category,
        question_id,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };

    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2C = (type, eventName) => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type: type,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CQuote = quote => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_PLAN,
        content_type: 'Cotizar con AXA',
        ...quote,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CSelectPlan = quote => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_ITEM,
        content_type: 'Next Step',
        ecommerce: quote,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CTerms = () => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type: 'step_termandconditions_B2B2C',
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CTravel = travelData => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: 'travel_information',
        ecommerce: {
          ...travelData,
        },
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CThankYou = purchase => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: 'purchase',
        ecommerce: {
          ...purchase,
        },
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerB2B2CPurchase = purchase => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: 'add_payment_info',
        ecommerce: purchase,
      },
      dataLayerName: PAGE_DATA_LAYER,
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerAppSelectTrack = content_type => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: SELECT_CONTENT,
        content_type,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};

export const dataLayerAppRedirectTrack = () => {
  try {
    const tagManagerArgs = {
      dataLayer: {
        event: PAGE_VIEW,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  } catch (error) {
    console.log(error);
  }
};
