export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descrição do incidente',
  es_ES: 'Descripción del incidente',
};

export const LABEL_TOTAL_REIMBURSED = {
  en_US: 'Total amount requested to be reimbursed',
  pt_BR: 'Valor total de reembolsado solicitado',
  es_ES: 'Importe total de reembolso solicitado',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Amount paid by the insurance',
  pt_BR: 'Valor pago pela companhia de seguros',
  es_ES: 'Monto pagado por la compañía de seguros',
};
