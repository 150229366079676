export const TEXT_FIND_THE_BENEFITS = {
  en_US: 'Find the benefits of your Visa card',
  es_ES: 'Encuentra los beneficios de tu tarjeta Visa',
  pt_BR: 'Descubra as vantagens do seu cartão Visa',
};

export const TEXT_SEE_BENEFITS = {
  en_US: 'See the benefits',
  es_ES: 'Ver los beneficios',
  pt_BR: 'Veja os benefícios',
};

export const PAGINATION_DOTS = {
  en_US: product =>
    `Select this option and your Visa portal experience will be with a ${product} card`,
  es_ES: product =>
    `Seleccione esta opción y tu experiencia en el portal Visa será con una tarjeta ${product}`,
  pt_BR: product =>
    `Selecione essa opção e sua experiência do portal Visa vai ser com um cartão ${product}`,
};
