import React, { useEffect, useContext } from 'react';

import { StateContext } from '../StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import { userServices } from '../../services';

import { dataLayerLoginSuccessTrack } from '../../utils/GTM_helper';

const loadDataToTrack = async (cn, isUserLogged, cards, countryFullName) => {
  try {
    const dependents = (await userServices.getCHDependents(cn)).data;
    const containsCards = cards.length > 0;
    let cardsName = '';

    if (containsCards) {
      cardsName = cards.map(card => card.name).join(' - ');
    }

    dataLayerLoginSuccessTrack(
      cn,
      cardsName,
      dependents.length,
      countryFullName
    );
  } catch (error) {}
};

const UserInfoTrack = () => {
  const { state, utils } = useContext(StateContext);
  const { country, countryFullName } = useContext(IntlContext);
  const isUserLogged = utils.isUserLogged();
  const cards = state.cards.data;
  const mainCard = utils.getSelectedCard();

  useEffect(() => {
    if (isUserLogged && mainCard) {
      const cn = utils.getCn();
      loadDataToTrack(cn, isUserLogged, cards, countryFullName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLogged, mainCard]);

  return <></>;
};

export default UserInfoTrack;
