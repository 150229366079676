import React, { useContext } from 'react';
import StepClaim from '../../StepClaim/StepClaim';
import Form from '../../Generate/ClaimFillForm/Form/Form';
import * as translations from '../intl';
import { IntlContext } from '../../../../intl';

const UpdatePage = props => {
  const { claimType, loadedData, certificate } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const stepNames = [intl.TEXT_STEP_1, intl.TEXT_STEP_2, intl.TEXT_STEP_3];

  return (
    <>
      <StepClaim currentStep={2} stepNames={stepNames} />
      <Form
        claimType={claimType}
        loadedData={loadedData}
        certificate={certificate}
        previousPage="/update"
      />
    </>
  );
};

export default UpdatePage;
