import React, { useContext } from 'react';
import styles from './ProductCategory.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import { ALL, INDIVIDUAL, ENTERPRISE } from '../const_product_category';
import * as translations from './intl';

const ProductCategory = ({ setProductCategory, productCategory }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={styles.boxProductCategory}>
      <ul>
        <li
          className={
            productCategory === ALL
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
          onClick={() => setProductCategory(ALL)}
        >
          {intl.ALL}
        </li>
        <li
          className={
            productCategory === INDIVIDUAL
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
          onClick={() => setProductCategory(INDIVIDUAL)}
        >
          {intl.CONSUMER}
        </li>
        <li
          className={
            productCategory === ENTERPRISE
              ? `${styles[theme]} ${styles.active}`
              : `${styles[theme]}`
          }
          onClick={() => setProductCategory(ENTERPRISE)}
        >
          {intl.CORPORATE}
        </li>
      </ul>
    </div>
  );
};

export default ProductCategory;
