import {
  productsAndBenefitsServices,
  certificatesServices,
} from '../../../../services/index';

export const createListOnlyExternalReferences = (certificates, cards) => {
  return {
    external_certificates: () => {
      return certificates.map(value => value.bank_identification_number);
    },
    external_cards: () => {
      return cards.map(card => card.external_reference);
    },
  };
};

export const createObjectCardDelete = listCards => {
  try {
    const requests = listCards.map(async card => {
      const response =
        await productsAndBenefitsServices.getProductDetailByBinCode(card);
      const filterImage =
        (response.data.images &&
          response.data.images.find(image => image.type === 'PICTURE')) ||
        '';
      return {
        name: response.data.name,
        image: filterImage && filterImage.content_url,
        external_reference: card,
        issuer_name:
          response.data &&
          response.data.partner_reference &&
          response.data.partner_reference.issuer_name,
      };
    });
    return Promise.all(requests);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

export const listCardsWithCertificate = (certificates, cards) => {
  return cards.filter(card => certificates.includes(card.external_reference));
};

export const cardDeletedWithCertificate = async (
  bankInformation,
  externalReference
) => {
  const listBankInformation = bankInformation.filter(
    bank => !externalReference.includes(bank)
  );
  const removeDuplicated = [...new Set(listBankInformation)];
  const createObjectCards = await createObjectCardDelete(removeDuplicated);
  return createObjectCards;
};

export const loadListCardByCertificate = async (cn, cards) => {
  try {
    const { data } = await certificatesServices.getCertificates(cn);
    const { external_certificates, external_cards } =
      createListOnlyExternalReferences(data, cards.data);
    const filterCardWithCertificate = listCardsWithCertificate(
      external_certificates(),
      cards.data
    );
    const cardDeleted = await cardDeletedWithCertificate(
      external_certificates(),
      external_cards()
    );

    return {
      selectedCardIndex: 0,
      data: [...filterCardWithCertificate, ...cardDeleted],
      cardDeleted: cardDeleted,
    };
  } catch (error) {}
};
