import moment from 'moment';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';
export const formatCar_rentalToApi = props => {
  let object = {};

  object.car_rental = car_rentalToApi(props);
  object.incident = incidentToApi(props);
  object.affected_person = affected_personToApi(props);
  object.amount_estimation_damage = amount_estimation_damage(props);

  object.total_amount_claimed = total_amount_claimedToApi(props);

  if (props.values.incident.other_insurance_covering.is_internal === 'true') {
    object.other_insurance_covering = other_insurance_coveringToApi(props);
  }
  return object;
};

const car_rentalToApi = props => {
  const car_rental = props.values.incident.car_rental;

  const fromDate = moment(car_rental.from).format('YYYY-MM-DD HH:mm:ss');
  const toDate = moment(car_rental.to).format('YYYY-MM-DD HH:mm:ss');
  return {
    from: fromDate,
    to: toDate,
    agency: {
      name: car_rental.agency.name,
      phone: car_rental.agency.phone,
    },
    payment: {
      date: car_rental.payment.date,
      amount: {
        value: car_rental.payment.amount.value,
        currency: car_rental.payment.amount.currency,
      },
      number_of_installments:
        car_rental.payment.number_of_installments === '0'
          ? undefined
          : car_rental.payment.number_of_installments,
      reservation_information:
        car_rental.payment.reservation_information &&
        car_rental.payment.reservation_information.hasPreviousReservation ===
          'yes' &&
        car_rental.payment.reservation_information &&
        car_rental.payment.reservation_information
          .hasPreviousReservationPayment === 'yes'
          ? {
              date: car_rental.payment.reservation_information.date,
              amount: {
                value: car_rental.payment.reservation_information.amount.value,
                currency:
                  car_rental.payment.reservation_information.amount.currency,
              },
            }
          : undefined,
    },
  };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;

  const date = moment(incident.date).format('YYYY-MM-DD HH:mm:ss');
  const location = incident.location;
  const driver = incident.driver;

  return {
    date: date,
    description: incident.description,
    driver: {
      license_number: driver.license_number,
      license_issued_country: driver.license_issued_country,
      allOf: {
        first_name: incident.driver.allOf.first_name,
        last_name: incident.driver.allOf.last_name,
      },
    },
    collision_coverage: stringToBoolean(incident.collision_coverage),
    payment_beneficiary: incident.payment_beneficiary,
    location: {
      street_address: location.place,
      locality: location.locality,
      country: location.country,
      state: location.state,
    },
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const amount_estimation_damage = props => {
  const amount_estimation_damage =
    props.values.incident.amount_estimation_damage;

  return {
    value: amount_estimation_damage.value,
    currency: amount_estimation_damage.currency,
  };
};

const other_insurance_coveringToApi = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const amount_estimation_damage =
    props.values.incident.amount_estimation_damage;

  return [
    {
      is_internal: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      description: other_insurance_covering.description,
      amount: {
        value: other_insurance_covering.value,
        currency: amount_estimation_damage.currency,
      },
    },
  ];
};

const total_amount_claimedToApi = props => {
  const total_amount_claimed =
    Number(props.values.incident.amount_estimation_damage.value) -
    Number(props.values.incident.other_insurance_covering.value);

  return {
    value: Number(total_amount_claimed),
    currency: props.values.incident.amount_estimation_damage.currency,
  };
};
