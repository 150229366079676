export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const ACCIDENT_REASON = {
  en_US: 'Accident reason',
  pt_BR: 'Motivo do incidente',
  es_ES: 'Motivo del accidente',
};

export const ACCIDENTAL_DISMEMBERMENT = {
  en_US: 'Accidental dismemberment',
  pt_BR: 'Desmembramento acidental',
  es_ES: 'Desmembramiento accidental',
};

export const ACCIDENTAL_DEATH = {
  en_US: 'Accidental death',
  pt_BR: 'Morte acidental',
  es_ES: 'Muerte accidental',
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATING_CEP = {
  en_US: 'Validating postal Code...',
  pt_BR: 'Validando CEP...',
  es_ES: 'Validar código postal...',
};

export const TEXT_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};

export const PLEASE_REFER_COMPLETE_DESCRIPTION = {
  en_US:
    'Please refer to the complete description of program requirements in the Visa Corporate Liability Waiver Program brochure and Policy Outline',
  pt_BR:
    'Por favor, consulte a descrição completa de requisitos do programa no Visa Responsabilidade Empresarial folheto Programa de Isenção e Política de Contorno',
  es_ES:
    'Por favor refiérase a la descripción completa de los requisitos del programa en el folleto del Programa de Exención de Responsabilidad Corporativa y Visa Esquema Política',
};
