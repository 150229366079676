export const TEXT_TICKET_AMOUNT = {
  en_US: 'Ticket amount',
  es_ES: 'Monto del boleto',
  pt_BR: 'Valor da passagem',
};

export const TEXT_ACCOMMODATION = {
  en_US: 'Accommodation costs',
  es_ES: 'Gastos de alojamiento',
  pt_BR: 'Gastos com alojamento',
};

export const TEXT_AMOUNT_PAID_ACCOMMODATION = {
  pt_BR: 'Valor pago pelo alojamento',
  es_ES: 'Monto pagado por el alojamiento',
  en_US: 'Amount paid for the accommodation',
};

export const TEXT_AMOUNT_PAID_TRANSPORTATION = {
  en_US: 'Amount paid by the transportation company',
  es_ES: 'Monto pagado por la compañía de transporte',
  pt_BR: 'Valor pago pela empresa de transporte',
};

export const TEXT_AMOUNT_OTHER_INSURANCE = {
  en_US: 'Amount paid by the insurance',
  es_ES: 'Mnto pagado por la compañía de seguros',
  pt_BR: 'Valor pago pela companhia de seguros',
};

export const LABEL_TOTAL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  es_ES: 'Importe total de la reivindicación',
  pt_BR: 'Valor total do sinistro',
};
