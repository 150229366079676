export const TEXT_EMPTY_PRODUCTS = {
  en_US: 'No products available',
  pt_BR: 'Nenhum produto disponível',
  es_ES: 'No hay producto disponible',
};

export const CREDIT = {
  en_US: 'Credit',
  es_ES: 'Crédito',
  pt_BR: 'Crédito',
};

export const DEBIT = {
  en_US: 'Debit',
  es_ES: 'Débito',
  pt_BR: 'Débito',
};

export const PREPAID = {
  en_US: 'Prepaid',
  es_ES: 'Prepago',
  pt_BR: 'Pré-Pago',
};

export const TEXT_SET_SELECTED_CREDIT = {
  en_US: 'Credit',
  pt_BR: 'Crédito',
  es_ES: 'Credito',
};

export const TEXT_SET_SELECTED_DEBIT = {
  en_US: 'Debit',
  pt_BR: 'Débito',
  es_ES: 'Debito',
};

export const TEXT_SET_SELECTED_PREPAID = {
  en_US: 'Prepaid',
  pt_BR: 'Pré-Pago',
  es_ES: 'PrePago',
};
