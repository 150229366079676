import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './CellPhoneArea.module.scss';
import { safeGet } from '../../../../../../../../utils/object';

const CellPhoneArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div>
      <div className={`row ${styles.incidentAreal}`}>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.purchase_protection_items.manufacturer}
            label={intl.MANUFACTURER}
            name="purchase_protection_items.manufacturer"
            type="text"
            onChange={handleChange}
            touched={safeGet(touched, 'purchase_protection_items.manufacturer')}
            onBlur={handleBlur}
            error={safeGet(errors, 'purchase_protection_items.manufacturer')}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={values.purchase_protection_items.model_number}
            label={intl.MODEL}
            name="purchase_protection_items.model_number"
            type="text"
            onChange={handleChange}
            touched={safeGet(touched, 'purchase_protection_items.model_number')}
            onBlur={handleBlur}
            error={safeGet(errors, 'purchase_protection_items.model_number')}
            maxLength="100"
          />
        </div>
      </div>
    </div>
  );
};

export default CellPhoneArea;
