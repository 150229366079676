export const TITLE_CLAIM = {
  en_US: 'Submit a new claim',
  es_ES: 'Generar un nuevo reclamo',
  pt_BR: 'Abrir um novo sinistro',
};

export const TITLE_BENEFITS_CATEGORY_ALL = {
  en_US: 'All benefits',
  pt_BR: 'Todos os benefícios',
  es_ES: 'Todos los beneficios',
};

export const TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION = {
  en_US: 'Travel',
  pt_BR: 'Viagem',
  es_ES: 'viaje',
};

export const TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION = {
  en_US: 'Purchase protection',
  pt_BR: 'Proteção de compra',
  es_ES: 'Protección de compra',
};

export const TITLE_BENEFITS_CATEGORY_EMERGENCY_MEDICAL = {
  en_US: 'Emergency medical',
  pt_BR: 'Emergência médica',
  es_ES: 'Emergencia médica',
};

export const TITLE_BENEFITS_CATEGORY_CONCIERGE = {
  en_US: 'Concierge',
  pt_BR: 'Concierge',
  es_ES: 'Concierge',
};

export const TEXT_MAKE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};

export const TEXT_DESCRIPTION = {
  en_US:
    'To file this claim you will need to provide the following information:',
  pt_BR:
    'Para apresentar este sinistro, você precisará fornecer as seguintes informações:',
  es_ES:
    'Para presentar este reclamo, deberás proporcionar la siguiente información:',
};

export const TEXT_INFO_INCIDENT = {
  en_US: 'Information about your incident',
  pt_BR: 'Informações sobre seu incidente',
  es_ES: 'Información sobre tu incidente',
};

export const TEXT_PERSONAL_INFO = {
  en_US: 'Personal Information',
  pt_BR: 'Informação pessoal',
  es_ES: 'Informacion personal',
};

export const TEXT_VIEW_PDF = {
  en_US: 'View PDF',
  es_ES: 'Ver PDF',
  pt_BR: 'Ver resumo',
};

export const TITLE_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Resumo de benefícios para veículo de locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};

export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Resumo de benefícios de proteção de compra ampliada',
  es_ES: 'Certificado de compra',
};

export const TITLE_BENEFIT_DETAILS = {
  en_US: 'Benefit details',
  pt_BR: 'Detalhes do benefício',
  es_ES: 'Detalles del beneficio',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};

export const BENEFIT_DETAILS = {
  en_US: 'Benefit Details',
  es_ES: 'Detalles del Beneficio',
  pt_BR: 'Detalhes do Benefício',
};

export const MORE_DETAILS = {
  en_US: 'More Details',
  es_ES: 'Más Detalles',
  pt_BR: 'Mais Detalhes',
};

export const BENEFIT_ALT = {
  en_US: name => `Benefit icon ${name}`,
  es_ES: name => `Ícono de beneficio ${name}`,
  pt_BR: name => `Ícone de beneficio ${name}`,
};
