export const LINK_PREVIOUS = {
  en_US: 'Previous',
  es_ES: 'Anterior',
  pt_BR: 'Anterior',
};

export const LINK_NEXT = {
  en_US: 'Next',
  es_ES: 'Próximo',
  pt_BR: 'Próximo',
};

export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};
