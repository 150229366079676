import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const ExpenseItemRemove = props => {
  const { values, setFieldValue, index, intl } = props;

  const removeExpense = event => {
    event.preventDefault();

    const expenses = [...values.expenses];
    const priceFromExpenseRemoved = expenses[index].price.value
      ? expenses[index].price.value
      : 0;
    const diff =
      values.amount_estimation_damage.value - priceFromExpenseRemoved;
    setFieldValue('amount_estimation_damage.value', diff);
    expenses.splice(index, 1);
    setFieldValue('expenses', expenses);
  };

  const removeButton = () => {
    if (index === 0) return;
    return (
      <Button
        type="Tertiary"
        onClick={removeExpense}
        label={intl.BTN_REMOVE}
        leadingIcon={<TrashIcon width={24} height={24} />}
      />
    );
  };

  return <div className="col-2">{removeButton()}</div>;
};

export default ExpenseItemRemove;
