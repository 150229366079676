export const SUBTITLE_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TITLE_PROVIDE_FLIGHT_DETAILS = {
  en_US: 'Provide flight details',
  es_ES: 'Proporcionar detalles de vuelo',
  pt_BR: 'Fornecer detalhes do voo',
};

export const EXECUTIVE_TRANSPORTATION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Servicio de Transporte al Aeropuerto',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Verify user info',
  es_ES: 'Verificar información de usuario',
  pt_BR: 'Verificação de informações do usuário',
};
