/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../TripCancellation.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';

const ReimbursementArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;

  const showReimbursementInsurance =
    values.additional_costs_accomodation.amount_claimed.checked === 'true' ||
    values.other_company_covering.checked === 'true' ||
    values.other_insurance_covering.checked === 'true';

  useEffect(() => {
    setFieldValue(
      'total_amount_claimed.value',
      Number(
        Number(values.additional_costs_transport.price.value) +
          Number(values.additional_costs_accomodation.price.value) -
          Number(values.reimbursed_transportation_insurance.value)
      ).toFixed(2)
    );
  }, [
    values.reimbursed_transportation_insurance.value,
    values.additional_costs_accomodation.price.value,
    values.additional_costs_transport.price.value,
  ]);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={`${styles.incidentPeriodArea}  ${styles.fontGray12} row`}
        >
          {showReimbursementInsurance && (
            <>
              <div className={`col-12 col-sm-12 col-md-6 `}>
                <Input
                  label={intl.TEXT_REIMBURSED_TRANSPORTATION}
                  type="text"
                  maskedMoney
                  name="reimbursed_transportation_insurance.value"
                  disabled={true}
                  tooltipText={intl.TEXT_DECIMAL_SYMBOL}
                  value={values.reimbursed_transportation_insurance.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  textTooBig
                  dataTestid="InputReimburserd"
                />
              </div>

              <div className={`col-12 col-sm-12 col-md-6`}>
                <Select
                  label={intl.LABEL_CURRENCY}
                  disabled
                  value={values.additional_costs_transport.price.currencyValue}
                  name="reimbursed_transportation_insurance.currency"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.reimbursed_transportation_insurance &&
                    errors.reimbursed_transportation_insurance.currency
                  }
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                    ),
                  }}
                  dataTestid="comboboxCurrency"
                />
              </div>
            </>
          )}

          <div className={`col-12 col-sm-12 col-md-6 `}>
            <Input
              label={intl.LABEL_TOTAL_REIMBURSED}
              type="text"
              name="total_amount_claimed.value"
              tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
              disabled={true}
              value={values.total_amount_claimed.value}
              onChange={e => {
                handleChange(e);
              }}
              onBlur={handleBlur}
              touched={
                touched.total_amount_claimed &&
                touched.total_amount_claimed.value
              }
              error={
                errors.total_amount_claimed && errors.total_amount_claimed.value
              }
              dataTestid="inputAmountClaimed"
            />
          </div>

          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              label={intl.LABEL_CURRENCY}
              name="total_amount_claimed.currencyValue"
              disabled
              value={values.additional_costs_transport.price.currencyValue}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.total_amount_claimed &&
                errors.total_amount_claimed.currencyValue
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              dataTestid="comboboxCostsTransport"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReimbursementArea;
