import { pt_currency } from '../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../intl/currency/en_currency';

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_HOUR_VALID = {
  en_US: 'Please enter a value between 00:00 and 23:59.',
  pt_BR: 'Por favor, entre um valor entre 00:00 e 23:59.',
  es_ES: 'Por favor, introduzca un valor entre 00:00 y 23:59.',
};

export const VALIDATE_DATE_LOSS = {
  en_US: 'Please enter a date between Start date of trip and End date of trip.',
  pt_BR:
    'Por favor, insira uma data entre Data de início da viagem e Data de fim de viagem.',
  es_ES:
    'Por favor, introduzca una fecha entre la Fecha de inicio del viaje y la Fecha de finalización del viaje.',
};

export const TEXT_ITEM_DESCRIPTION = {
  en_US: 'Item description',
  pt_BR: 'Descrição do item',
  es_ES: 'Descripción del artículo',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Date of purchase *',
  pt_BR: 'Data da compra *',
  es_ES: 'Fecha de compra *',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Price *',
  pt_BR: 'Preço *',
  es_ES: 'Precio *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const BTN_ADD = {
  en_US: 'Add item',
  pt_BR: 'Adicionar produto',
  es_ES: 'Agregar artículo',
};

export const TEXT_ITEM = {
  en_US: index => `Item #${index}`,
  pt_BR: index => `Produto #${index}`,
  es_ES: index => `Artículo #${index}`,
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};
