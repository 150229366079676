import {
  focusOnErrorElement,
  focusElement,
} from '../../../../../utils/elements';

export const checkErrorsOnForm = async (
  submitRefs,
  accordeonRefs,
  schemas,
  values,
  statusAccordeon,
  hideReimbursement,
  trackEventUserAction
) => {
  if (submitRefs.policy) {
    submitRefs.policy.current.click();
    try {
      await schemas.policy.validate(values.policy);
    } catch (error) {
      trackEventUserAction('#### (CLAIM) VALIDANDO FORM - POLICY ####', error);
      if (!statusAccordeon.policy) {
        accordeonRefs.policy.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  if (submitRefs.incident) {
    submitRefs.incident.current.click();
    try {
      await schemas.incident.validate(values.incident);
    } catch (error) {
      trackEventUserAction(
        '#### (CLAIM) VALIDANDO FORM - INCIDENT ####',
        error
      );
      if (!statusAccordeon.incident) {
        accordeonRefs.incident.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }
  if (submitRefs.reimbursement && !hideReimbursement) {
    submitRefs.reimbursement.current.click();
    try {
      await schemas.reimbursement.validate(values.reimbursement);
    } catch (error) {
      trackEventUserAction(
        '#### (CLAIM) VALIDANDO FORM - REIMBURSEMENT ####',
        error
      );
      if (!statusAccordeon.reimbursement) {
        accordeonRefs.reimbursement.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  if (submitRefs.legal) {
    submitRefs.legal.current.click();
    try {
      await schemas.legal_consents.validate(values.legal_consents);
    } catch (error) {
      trackEventUserAction('#### (CLAIM) VALIDANDO FORM - LEGAL ####', error);
      if (!statusAccordeon.legal) {
        accordeonRefs.legal.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  return true;
};

export const checkErrorsOnSession = async (
  accordeonRefs,
  currentAcordeon,
  nextAcordeon,
  schemas,
  values,
  validation,
  submitRefs
) => {
  if (accordeonRefs[currentAcordeon]) {
    try {
      if (validation) {
        submitRefs[currentAcordeon].current.click();
        await schemas[currentAcordeon].validate(values[currentAcordeon]);
      }
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
      focusElement(nextAcordeon);
      return true;
    } catch (error) {
      console.log('Error', error);
      focusOnErrorElement(0);
      return false;
    }
  }
};
