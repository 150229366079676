import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './DatesArea.module.scss';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import CheckBox from '../../../../../../../components/CheckBox/CheckBox';
import Select from '../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../utils/sanitize';
import moment from 'moment';

const DatesArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;

  const maxReturnDate = values.travel_information.from
    ? new Date(moment(values.travel_information.from).add(60, 'days'))
    : undefined;

  return (
    <div className={styles.datesArea}>
      <div className="row">
        <div className="col-12 col-md-4 col-lg-4 col-xl-6">
          <Calendar
            value={values.travel_information.emission}
            label={intl.FIELD_ISSUANCE}
            name="travel_information.emission"
            type="text"
            disabled={true}
            onChange={handleChange}
            touched={
              touched.travel_information && touched.travel_information.emission
            }
            onBlur={handleBlur}
            error={
              errors.travel_information && errors.travel_information.emission
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-4 col-xl-3">
          <CheckBox
            type="checkbox"
            error={
              errors.travel_information &&
              errors.travel_information.is_one_way_travel
            }
            dataTestid="CheckboxIsOneWayTravel"
            value={values.travel_information.is_one_way_travel}
            touched={
              touched.travel_information &&
              touched.travel_information.is_one_way_travel
            }
            onClick={value =>
              setFieldValue('travel_information.is_one_way_travel', value)
            }
          >
            {intl.FIELD_ONEWAY}
          </CheckBox>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Calendar
            value={values.travel_information.from}
            label={
              values.travel_information.is_international_trip === 'true'
                ? intl.FIELD_START_DATE_COUNTRY
                : intl.FIELD_START_DATE_CITY
            }
            name="travel_information.from"
            id="travel_information.from"
            minDate={new Date()}
            onChange={handleChange}
            touched={
              touched.travel_information && touched.travel_information.from
            }
            onBlur={handleBlur}
            error={errors.travel_information && errors.travel_information.from}
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div
          className={`col-12 col-md-6 ${
            values.travel_information.is_one_way_travel ? styles.none : ''
          }`}
          data-testid="InputTIDateTo"
        >
          <Calendar
            value={values.travel_information.to}
            label={
              values.travel_information.is_international_trip === 'true'
                ? intl.FIELD_RETURN_DATE_COUNTRY
                : intl.FIELD_RETURN_DATE_CITY
            }
            name="travel_information.to"
            id="travel_information.to"
            onChange={handleChange}
            minDate={values.travel_information.from}
            maxDate={maxReturnDate}
            touched={
              touched.travel_information && touched.travel_information.to
            }
            onBlur={handleBlur}
            error={errors.travel_information && errors.travel_information.to}
            setFieldTouched={setFieldTouched}
          />
        </div>
      </div>
    </div>
  );
};

export default DatesArea;
