import React, { useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from './intl';
import PhoneForm from '../../../../../../../../../components/PhoneForm';

const PhoneArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { setFieldValue, prefixOptions } = props;

  useEffect(() => {
    if (prefixOptions.prefix) {
      setFieldValue(
        'travel_information.travel_agency.phone.international_prefix',
        prefixOptions.prefix
      );
    }
  }, [prefixOptions.prefix]);

  return (
    <PhoneForm
      formikProps={props}
      title={intl.TEXT_PHONE_AREA}
      inputNames={{
        countryCode:
          'travel_information.travel_agency.phone.international_prefix',
        phoneNumber: 'travel_information.travel_agency.phone.number',
        phoneType: 'travel_information.travel_agency.phone.phone_type',
      }}
      testIds={{
        countryCode: 'internationalPrefixAttending',
        phoneNumber: 'phoneNumberAttending',
        phoneType: 'selectAttendingPhoneType',
      }}
    />
  );
};

export default PhoneArea;
