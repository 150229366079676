/* eslint-disable no-async-promise-executor */
import axiosLib from 'axios';
import { errorHandler } from './errorHandler';
import { axiosToMock } from './mockAxios';
import geoIp from './geoIp';
import user from './user';
import cards from './cards';
import productsAndBenefits from './productsAndBenefits';
import certificates from './certificates';
import claims from './claims';
import bank from './bank';
import postalCode from './postalCode';
import reimbursment from './reimbursments';
import offers from './offers';
import tokenex from './tokenex';
import nurse from './nurseLine';
import phone from './phone';
import aig from './aig';
import notifications from './notifications';
import communicationDevice from './communicationDevice';
import cotizar from './cotizar';
import enroll from './enroll';
import download from './download';
import { overwriteToJSONMethodToDate } from '../utils/date';
import flight from './flight';
import travel from './travel';
import cmx from './cmx';
import amosConsultations from './amosConsultations';
import amosVideoConsultations from './amosVideoConsultations';
import amosOthers from './amosOthers';
import faq from './faq';
import expectedDocuments from './expectedDocuments';
import voucher from './voucher';
import cabify from './cabify';
import oct from './oct';
import vces from './vces';
import accountRecovery from './accountRecovery';

const checkreg = new RegExp('<[^>]*>', 'g');
const removeNoASCII = new RegExp('[^\\x00-\\xFF]', 'g');

overwriteToJSONMethodToDate();
export const controller = new AbortController();

const axios = axiosLib.create({
  signal: controller.signal,
});
axios.CancelToken = axiosLib.CancelToken;

//Add header to mapi calls ['X-Requested-With'] = 'XMLHttpRequest'
axios.interceptors.request.use(
  function (config) {
    if (
      config.url.startsWith('/mapi') ||
      config.url.startsWith('/api') ||
      config.url.startsWith('/internal') ||
      config.url.startsWith('/nurse-server') ||
      config.url.startsWith('/auth') ||
      config.url.startsWith('/offerToken') ||
      config.url.startsWith('/tokenex') ||
      config.url.startsWith('/phone') ||
      config.url.startsWith('/aig') ||
      config.url.startsWith('/geo-ip') ||
      config.url.startsWith('/cotizar') ||
      config.url.startsWith('/download') ||
      config.url.startsWith('/flights') ||
      config.url.startsWith('/amos') ||
      config.url.startsWith('/bank') ||
      config.url.startsWith('/vouchers') ||
      config.url.startsWith('/cabify') ||
      config.url.startsWith('/oct') ||
      config.url.startsWith('/vces') ||
      config.url.startsWith('/policies')
    )
      config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

//Add SQL injection treatment
axios.interceptors.request.use(
  function (config) {
    if (config && config.data) {
      let data = JSON.stringify(config.data);
      data = data.replace(checkreg, '').replace(removeNoASCII, '');
      config.data = JSON.parse(data);
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

const cacheGetData = {};

axios.cacheGet = key => {
  if (cacheGetData[key]) return () => cacheGetData[key];
  return (url, options) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(url, options);
        cacheGetData[key] = { ...response };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
};

const cacheGetDataPost = {};

axios.cachePost = key => {
  if (cacheGetDataPost[key]) return () => cacheGetDataPost[key];
  return (url, data, options) =>
    new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(url, data, options);
        cacheGetDataPost[key] = { ...response };
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
};

export const mockAxios = () => {
  axiosToMock(axios);
  return axios;
};

export const restoreAxios = () => {
  axiosToMock(axios);
};

const dependencies = [process.env, axios, errorHandler];

export const userServices = user(...dependencies);
export const geoIpService = geoIp(...dependencies);
export const productsAndBenefitsServices = productsAndBenefits(...dependencies);
export const cardsServices = cards(...dependencies);
export const certificatesServices = certificates(...dependencies);
export const claimsServices = claims(...dependencies);
export const bankServices = bank(...dependencies);
export const postalCodeService = postalCode(...dependencies);
export const reimbursmentService = reimbursment(...dependencies);
export const offersService = offers(...dependencies);
export const tokenexService = tokenex(...dependencies);
export const nurseService = nurse(...dependencies);
export const phoneService = phone(...dependencies);
export const aigService = aig(...dependencies);
export const notificationsService = notifications(...dependencies);
export const communicationDeviceService = communicationDevice(...dependencies);
export const cotizarService = cotizar(...dependencies);
export const enrollService = enroll(...dependencies);
export const DownloadService = download(...dependencies);
export const flightService = flight(...dependencies);
export const travelService = travel(...dependencies);
export const cmxService = cmx(...dependencies);
export const faqService = faq(...dependencies);
export const expectedDocumentsService = expectedDocuments(...dependencies);
export const amosConsultationsService = amosConsultations(...dependencies);
export const amosVideocallsService = amosVideoConsultations(...dependencies);
export const amosOthersService = amosOthers(...dependencies);
export const voucherService = voucher(...dependencies);
export const cabifyService = cabify(...dependencies);
export const octService = oct(...dependencies);
export const vcesService = vces(...dependencies);
export const accountRecoveryService = accountRecovery(...dependencies);
