export const TEXT_TITLE = {
  en_US: 'List of appointments',
  pt_BR: 'Lista de agendamentos',
  es_ES: 'Lista de citas',
};

export const TEXT_STATUS = {
  en_US: 'Status',
  pt_BR: 'Status',
  es_ES: 'Situación',
};

export const TEXT_PENDIND = {
  en_US: 'Pending',
  pt_BR: 'Pendente',
  es_ES: 'Pendiente',
};

export const TEXT_CANCELED = {
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
  es_ES: 'Cancelado',
};

export const TEXT_DONE = {
  en_US: 'Done',
  pt_BR: 'Realizado',
  es_ES: 'Hecho',
};

export const TEXT_NOT_DONE = {
  en_US: 'Not done',
  pt_BR: 'Não realizado',
  es_ES: 'No hecho',
};

export const TEXT_TIMEZONE = {
  en_US: 'Timezone',
  pt_BR: 'Fuso horário',
  es_ES: 'Timezone',
};

export const TEXT_CANCEL_APPOINTMENT = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo electrónico',
};

export const NAV_ITEM_TITLE_APPOINTMENTS = {
  en_US: 'Appointments',
  pt_BR: 'Agendamentos',
  es_ES: 'Citas',
};

export const SUBTITLE_NAL = {
  en_US: 'See your recent appointments below.',
  pt_BR: 'Veja abaixo seus agendamentos recentes.',
  es_ES: 'Revisa tus citas recientes a continuación.',
};

export const BTN_DETAIL = {
  en_US: 'Appointment detail',
  pt_BR: 'Detalhes do agendamento',
  es_ES: 'Detalle de cita',
};

export const BTN_EDIT = {
  en_US: 'View',
  pt_BR: 'Exibir',
  es_ES: 'Exhibir',
};

export const TEXT_DATE = {
  en_US: 'Date/time',
  pt_BR: 'Data/hora',
  es_ES: 'Fecha/hora',
};

export const TEXT_APPONTMENT_EMPTY_LIST = {
  en_US: 'You have no appointment created.',
  pt_BR: 'Você não tem nenhum agendamento criado.',
  es_ES: 'No hay citas creadas.',
};

export const MODAL_TITLE_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'Are you sure you want to cancel the selected appointment?',
  pt_BR: 'Tem certeza de que deseja cancelar o agendamento selecionado?',
  es_ES: '¿Está seguro de que desea cancelar la cita seleccionada?',
};

export const MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Esta ação não pode ser revertida.',
  es_ES: 'Esta acción no se puede revertir.',
};

export const TEXT_SUCCESS_CANCEL = {
  en_US: 'Your appointment was canceled successfully.',
  pt_BR: 'Seu compromisso foi cancelado com sucesso.',
  es_ES: 'Tu cita fue cancelada con éxito.',
};

export const TEXT_ERROR_CANCEL = {
  en_US: 'Appointment cannot be canceled.',
  pt_BR: 'O agendamento não pode ser cancelado.',
  es_ES: 'La cita no puede ser cancelada.',
};

//test

export const TEXT_DESCRIPTION_BENEFITS = {
  en_US:
    'As a valuable Visa cardholder, we are here to assist during this difficult time.',
  pt_BR:
    'Como um valioso titular do cartão Visa, estamos aqui para ajudá-lo neste momento difícil.',
  es_ES:
    'Como un valioso titular de la tarjeta Visa, estamos aquí para ayudarlo en este momento difícil.',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TEXT_SUBTITLE = {
  en_US: 'APPOINTMENT DETAIL',
  pt_BR: 'DETALHES DO AGENDAMENTO',
  es_ES: 'DETALLE DE LA CITA ',
};

export const TEXT_CONTACT_INFO = {
  en_US: 'Contact information',
  pt_BR: 'Informações de contato',
  es_ES: 'Información del contacto',
};

export const TEXT_CONFIRM_NAL = {
  en_US: (name, last_name) =>
    `<strong>${name} ${last_name}</strong>, your appointment was confirmed for:`,
  pt_BR: (name, last_name) =>
    `<strong>${name} ${last_name}</strong>, seu agendamento foi confirmado para:`,
  es_ES: (name, last_name) =>
    `<strong>${name} ${last_name}</strong>, tu cita fue confirmada para:`,
};

export const TEXT_TYPE_QUESTIONS = {
  en_US: 'Type of question you want to ask',
  pt_BR: 'Tipo de pergunta que você deseja fazer',
  es_ES: 'Tipo de pregunta que quieres realizar',
};

export const TEXT_LANDLINE = {
  en_US: 'Landline',
  pt_BR: 'Telefone fixo',
  es_ES: 'Fijo',
};

export const TEXT_MOBILE = {
  en_US: 'Mobile',
  pt_BR: 'Móvel',
  es_ES: 'Móvil',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BUTTON_CONFIRM = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const BUTTON_DENY = {
  en_US: 'No, keep the appointment',
  pt_BR: 'Não, manter o agendamento',
  es_ES: 'No, guarda la cita',
};

export const VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};

export const PHONE_ICON = {
  en_US: 'Phone Icon',
  es_ES: 'Ícono de Teléfono',
  pt_BR: 'Ícone de Telefone',
};

export const FORMAT_TEXT_DATE = {
  en_US: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${month} ${day}th ${year} at ${time}.`,
  pt_BR: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${month} ${day}, às ${time}`,
  es_ES: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${day} de ${month} de ${year} a las ${time}.`,
};
