import { useContext, useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import moment from 'moment';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import styles from './RecentItem.module.scss';
import Button from '../../componentsV2/Button/Button';
import ModalInformation from '../ModalInformation/ModalInformation';
import { StateContext } from '../StateContextParent/StateContextParent';
import { certificatesServices, claimsServices } from '../../services';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../utils/sanitize';
import ClaimView from '../../pages/Claims/ClaimView/ClaimView';
import Loading from '../Loading/Loading';
import DividingLine from '../DividingLine/DividingLine';
import ClaimHistory from '../ClaimHistory/ClaimHistory';
import CancelClaimButton from './CancelClaimButton';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import {
  ENABLE_CLAIM_PROOF_OF_PAYMENT,
  APPEAL_ENABLE,
  REACT_APP_YOURPASS_LINK,
} from '../../utils/environments_variables';
import { NotificationContext } from '../NotificationContextParent/NotificationContextParent';
import PaymentProofButton from './PaymentProofButton';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';
import {
  CLAIM_DOCUMENT_ATTACHED_INTENTION,
  dataLayerClaimDocumentEventTrack,
} from '../../utils/GTM_helper';
import {
  appealed,
  rejected,
} from '../../pages/Profile/ProfileClaims/StatusClaimBySubStatus';
import StatusClaim from '../StatusClaim/StatusClaim';
import { idiomFromApi } from '../../intl/idiom';

const RecentItem = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    benefitName,
    isClaim = false,
    image = '',
    date,
    number = '',
    textDate,
    htmlContent = '',
    moreInfo,
    hasContentStatus,
    contentStatus,
    contentStatusValue,
    showButtonClaim = true,
    certificate_id = '',
    customButton = false,
    showVerticalDivider = true,
    hasEditView = false,
    claimId,
    siteSection,
    claimStatus,
    isTripAssistance = false,
    createdAt,
    ecardUrl,
  } = props;

  const isUS = idiom === 'en_US';
  const dateFormat = isUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  const claimStatusPending = 'pending send';
  const [showInfo, setShowInfo] = useState(false);
  const [ecardClicked, setEcardClicked] = useState(false);
  const [claimStatusHistory, setClaimStatusHistory] = useState();
  const { actions, utils } = useContext(StateContext);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const {
    actions: {
      communicationPreferences: { updateCommunicationPrefences },
    },
  } = useContext(NotificationContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const [loadingClaimDetail, setLoadingClaimDetail] = useState(false);
  const themes = getGlobalTheme();
  const history = useHistory();
  const dateCreated = new Date(createdAt);
  const today = new Date();
  const myToday = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0,
    0,
    0
  );

  const differenceInMillis = dateCreated - myToday;
  const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);

  const isToday = Math.floor(differenceInDays) == -1;

  const claimIsPendingSend =
    claimStatus && claimStatus.toLowerCase().includes('pending send');

  const showCancelClaim =
    claimStatus &&
    (claimStatus.toLowerCase().includes('pending send') ||
      claimStatus.toLowerCase().includes('opened'));

  const getLastSubStatusCreatedAt = sub_statuses => {
    return (
      sub_statuses[sub_statuses.length - 1] &&
      sub_statuses[sub_statuses.length - 1].name ===
        'Claims - Approved Claim' &&
      sub_statuses[sub_statuses.length - 1].created_at
    );
  };

  const getLastStatusClosedCreatedDate = claimStatusHistory => {
    return (
      claimStatusHistory[claimStatusHistory.length - 1] &&
      claimStatusHistory[claimStatusHistory.length - 1].name &&
      claimStatusHistory[claimStatusHistory.length - 1].name
        .toLowerCase()
        .includes('closed') &&
      claimStatusHistory[claimStatusHistory.length - 1].sub_statuses &&
      getLastSubStatusCreatedAt(
        claimStatusHistory[claimStatusHistory.length - 1].sub_statuses
      )
    );
  };
  const hasClaimAlreadyBeenAppealed = claimStatusHistory => {
    return (
      claimStatusHistory &&
      claimStatusHistory.find(claimStatus => {
        return claimStatus.sub_statuses.find(sub_statuses =>
          appealed.includes(sub_statuses.name)
        );
      })
    );
  };

  const hasClaimBeenRejected =
    claimStatusHistory &&
    claimStatusHistory.some(claimStatus => {
      return claimStatus.sub_statuses.some(sub_statuses =>
        rejected.includes(sub_statuses.name)
      );
    });

  const isClaimClosed =
    claimStatusHistory &&
    claimStatusHistory.some(claimStatus => claimStatus.name === 'Closed');

  const shouldShowAppealButton =
    APPEAL_ENABLE &&
    isClaimClosed &&
    hasClaimBeenRejected &&
    !hasClaimAlreadyBeenAppealed(claimStatusHistory);

  const showPaymentProofButton =
    ENABLE_CLAIM_PROOF_OF_PAYMENT &&
    claimStatusHistory &&
    getLastStatusClosedCreatedDate(claimStatusHistory);

  const loadFullClaimStatus = async claimId => {
    try {
      const { data: dataClaim } = await claimsServices.getClaimInfoByClaimId(
        claimId,
        idiom
      );
      setClaimStatusHistory(dataClaim.statuses);
    } catch (error) {}
  };

  useEffect(() => {
    if (isClaim && claimId) loadFullClaimStatus(claimId);
    updateCommunicationPrefences();
  }, []);

  const sendEmail = async () => {
    try {
      actions.modal.showLoading(intl.SENDING_YOUR_CERTIFICATE);
      await certificatesServices.postSendPDFEmail(cn, certificate_id, idiom);
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="success" message={intl.GENERATE_PDF_SUCCESS} />,
        true,
        false
      );
    } catch (error) {
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    }
  };

  const showModalPDF = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={intl.TITLE_GENERATE_PDF}
        subtitle={intl.SUBTITLE_GENERATE_PDF}
        textBtn={intl.TEXT_YES}
        textBtnDeny={intl.TEXT_NO}
        clickBtn={async () => await sendEmail()}
      />,
      true,
      false
    );
  };

  const showModalAddWallet = () => {
    return actions.modal.showModal(
      false,
      true,
      <ModalInformation type="error" message={intl.WARNING} textBtn="Ok" />,
      true,
      false
    );
  };

  const handleClick = async (id, idiom) => {
    try {
      const { data } = await certificatesServices.getCertificatesById(
        id,
        idiom
      );
      if (data.your_pass_id) {
        let url = '';
        url = REACT_APP_YOURPASS_LINK + data.your_pass_id;
        window.open(url, '_blank');
      } else {
        showModalAddWallet();
      }
    } catch (error) {
      showModalAddWallet();
    }
    setEcardClicked(true);
  };

  const showButtons = (showArrow = true) => {
    const goToUploadDocuments = claim_data => {
      if (claim_data && claim_data.claim_id) {
        dataLayerClaimDocumentEventTrack(
          CLAIM_DOCUMENT_ATTACHED_INTENTION,
          claim_data.claim_type_name || '',
          siteSection
        );
        history.push(
          `/claim-type/${claim_data.claim_id}/upload?siteSection=${siteSection}`
        );
      }
    };

    const getClaimStatus = () => {
      const { status, statuses } = contentStatusValue;
      const sub_status = statuses ? statuses.sub_status : false;

      if (!status) return;

      if (status.toLowerCase() === claimStatusPending.toLowerCase()) {
        return (
          <div className={styles.containerButtons}>
            <div className={styles.button}>
              <Button
                type="Secondary"
                onClick={() => goToUploadDocuments(contentStatusValue)}
                id={`attach-${contentStatusValue.claim_id}`}
                label={intl.TEXT_ATTACH_DOCUMENT}
              />
            </div>
          </div>
        );
      }

      if (shouldShowAppealButton) {
        return (
          <div className={styles.containerButtons}>
            <Button
              type="Secondary"
              id={`appeal-${contentStatusValue.claim_id}`}
              className={styles.button}
              onClick={() =>
                history.push(
                  `/claim-type/${contentStatusValue.benefit_external_reference}/${contentStatusValue.claim_id}/appeal`
                )
              }
              label={intl.TEXT_APPEAL}
            />
          </div>
        );
      }

      return <StatusClaim claim={contentStatusValue} />;
    };
    return (
      <>
        {showInfo && !isClaim && (
          <div className={styles.buttonEcard}>
            <Button
              className={`${styles[themes]}  ${styles.linkEcard}`}
              id={`ecard-${number}`}
              type="Tertiary"
              trailingIcon="Chevron Right"
              link={!!ecardUrl}
              internal={false}
              href={ecardUrl ? ecardUrl : null}
              onClick={
                isToday && !ecardUrl
                  ? () => handleClick(certificate_id, idiomFromApi())
                  : null
              }
              label={intl.BTN_ADD_TO_WALLET}
            />
          </div>
        )}

        {!isClaim && !isTripAssistance && (
          <div className={`${styles.buttonPDF}`}>
            <Button
              className={`
                ${styles[themes]} ${
                showVerticalDivider && !showInfo ? styles.verticalDivider : null
              }
              `}
              type="Tertiary"
              onClick={() => showModalPDF()}
              id={`PDF-${number}`}
              label={intl.TEXT_VIEW_PDF}
              trailingIcon="Chevron Right"
            />
          </div>
        )}
        {isClaim && !showInfo && (
          <div className={styles.claimStatus}>
            {hasContentStatus ? contentStatus : getClaimStatus()}
          </div>
        )}
        {!isClaim && showButtonClaim && !customButton && (
          <div
            className={`${styles.button} ${styles.buttonCreateClaim} ${styles[themes]}`}
          >
            <Button
              type="Secondary"
              id={`createClaim-${number}`}
              className={styles.createClaim}
              onClick={() => history.push('/claims')}
              label={intl.TEXT_CREATE_CLAIM}
            />
          </div>
        )}
        {customButton && customButton}
        {showArrow && (
          <span
            className={`${styles.arrow} ${
              showInfo && !isClaim ? styles.showMoreActive : ''
            }`}
          >
            <span
              onClick={() => setShowInfo(!showInfo)}
              className={showInfo ? styles.rotateIcon : ''}
            >
              <ChevronGeneric aria-label={intl.IMAGE_ARROW} />
            </span>
          </span>
        )}
      </>
    );
  };

  const openModalDetail = async () => {
    setLoadingClaimDetail(true);
    try {
      const { data: dataClaim } = await claimsServices.getClaimInfoByClaimId(
        claimId,
        idiom
      );
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) CARREGOU AS INFORMAÇÕES DA CLAIM`,
        dataClaim
      );
      actions.modal.showModal(
        `${intl.TITLE_CLAIM_VIEW(benefitName)} - ${
          dataClaim.existing_case_number
        }`,
        true,
        <ClaimView
          claimId={claimId}
          claimType={dataClaim.benefit_external_reference}
        />
      );
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) ABRIU O MODAL CARREGOU E EXIBIU AS INFORMAÇÕES DA CLAIM`,
        dataClaim
      );
      setLoadingClaimDetail(false);
    } catch (error) {
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) ERRO AO CARREGAR AS INFORMAÇÕES DA CLAIM`,
        {
          error: error,
          error_response: error && error.response ? error.response : error,
        }
      );
      setLoadingClaimDetail(false);
    }
  };

  const openModalHistory = async () => {
    setLoadingClaimDetail(true);
    try {
      const { data: dataClaim } = await claimsServices.getClaimInfoByClaimId(
        claimId,
        idiom
      );

      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) CARREGOU AS INFORMAÇÕES DO HISTÓRICO DA CLAIM`,
        dataClaim
      );

      actions.modal.showModal(
        intl.TITLE_CLAIM_HISTORY(benefitName),
        true,
        <ClaimHistory claim={dataClaim} />
      );
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) ABRIU O MODAL CARREGOU E EXIBIU AS INFORMAÇÕES DO HISTÓRICO DA CLAIM`,
        dataClaim
      );
      setLoadingClaimDetail(false);
    } catch (error) {
      trackEventUserAction(
        `#### (EDIT/VIEW CLAIM) ERRO AO CARREGAR AS INFORMAÇÕES DO HISTÓRICO DA CLAIM`,
        {
          error: error,
          error_response: error && error.response ? error.response : error,
        }
      );
      setLoadingClaimDetail(false);
    }
  };

  return (
    <li
      className={`${styles.recentClaimsItem} ${styles[themes]}
      `}
    >
      <div className={styles.left}>
        <div className={`${styles.icon} ${styles[themes]}`}>
          <img src={image} alt={intl.BENEFIT_ALT(benefitName)} />
        </div>
        <div className={`${styles.text} ${styles[themes]}`}>
          <span>
            <strong>{benefitName}</strong>
          </span>
          {!isClaim && (
            <span
              className={styles.moreInfo}
              dangerouslySetInnerHTML={{ __html: sanitize(moreInfo) }}
            ></span>
          )}
        </div>
      </div>
      <div className={styles.center}>
        <span>
          <strong>{number}</strong>
        </span>
        <span>
          {textDate}
          {date ? moment(date).format(dateFormat) : '-'}
        </span>
      </div>
      <div
        className={
          showInfo && !isClaim
            ? `${styles.right} ${styles.hideButtons}`
            : styles.right
        }
      >
        {showButtons()}
      </div>
      {showInfo && !isClaim && (
        <div style={{ width: '100%' }}>
          {htmlContent}
          <div
            className={`${styles.boxButton} ${styles.buttonCreateClaim} ${styles[themes]}`}
          >
            {showButtons(false)}
          </div>
        </div>
      )}
      {showInfo && isClaim && (
        <>
          <div style={{ width: '100%' }}>{htmlContent}</div>
          <DividingLine />
          {loadingClaimDetail ? (
            <Loading />
          ) : (
            <>
              <>
                {claimIsPendingSend && (
                  <div className={styles.genericButton}>
                    <Button
                      type="Primary"
                      onClick={() =>
                        history.push(
                          `/claim-type/${claimId}/upload?siteSection=${siteSection}`
                        )
                      }
                      id={`attach-${number}`}
                      label={intl.TEXT_ATTACH_DOCUMENT}
                    />
                  </div>
                )}
              </>
              {hasEditView && (
                <div
                  className={`${styles.genericButton} ${styles.buttonLeftDivider} ${styles[themes]}`}
                >
                  <Button
                    type="Tertiary"
                    onClick={() => openModalDetail()}
                    id={`view-${number}`}
                    label={intl.BTN_VIEW_CLAIM}
                    trailingIcon="Chevron Right"
                  />
                </div>
              )}
              {showCancelClaim && (
                <div
                  className={`${styles.genericButton} ${styles.buttonLeftDivider} ${styles[themes]}`}
                >
                  <CancelClaimButton claimId={claimId} number={number} />
                </div>
              )}
              {showPaymentProofButton && (
                <div
                  className={`${styles.genericButton} ${styles.buttonLeftDivider} ${styles[themes]}`}
                >
                  <PaymentProofButton
                    claimId={claimId}
                    number={number}
                    claimApprovedDate={showPaymentProofButton}
                  />
                </div>
              )}
              <div
                className={`${styles.genericButton} ${
                  shouldShowAppealButton
                    ? `${styles.buttonLeftDivider} ${styles[themes]}`
                    : ''
                }`}
              >
                <Button
                  type="Tertiary"
                  onClick={() => openModalHistory()}
                  id={`history-${number}`}
                  label={intl.TEXT_CASE_HISTORY}
                  trailingIcon="Chevron Right"
                />
              </div>
              {shouldShowAppealButton && (
                <div className={styles.genericButton}>
                  <Button
                    type="Tertiary"
                    trailingIcon="Chevron Right"
                    id={`appeal-${contentStatusValue.claim_id}`}
                    onClick={() =>
                      history.push(
                        `/claim-type/${contentStatusValue.benefit_external_reference}/${contentStatusValue.claim_id}/appeal`
                      )
                    }
                    label={intl.TEXT_APPEAL}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </li>
  );
};

export default withRouter(RecentItem);
