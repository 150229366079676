export const LABEL_TYPE = {
  en_US: 'Type',
  pt_BR: 'Tipo',
  es_ES: 'Tipo',
};

export const LABEL_AMOUNT = {
  en_US: 'Amount',
  pt_BR: 'Valor',
  es_ES: 'Importe',
};

export const LABEL_FORM_PAYMENT = {
  en_US: 'Form of payment',
  pt_BR: 'Forma de pagamento',
  es_ES: 'Forma de pago',
};

export const LABEL_TOTAL_REIMBURSED = {
  en_US: 'Total amount requested to be reimbursed',
  pt_BR: 'Valor total de reembolsado solicitado',
  es_ES: 'Importe total de reembolso solicitado',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

//Expense type options
export const OPTION_VALUE_MEDICAL = {
  en_US: 'Medical',
  pt_BR: 'Médica',
  es_ES: 'Médico',
};

export const OPTION_VALUE_MEDICINE = {
  en_US: 'Medicine',
  pt_BR: 'Medicamento',
  es_ES: 'Medicina',
};

export const OPTION_VALUE_DENTAL = {
  en_US: 'Dental',
  pt_BR: 'Dentária',
  es_ES: 'Odontología',
};

export const OPTION_VALUE_TRANSPORT = {
  en_US: 'Transport',
  pt_BR: 'Transporte',
  es_ES: 'Transporte',
};

export const OPTION_VALUE_ACCOMMODATION = {
  en_US: 'Accommodation',
  pt_BR: 'Alojamento',
  es_ES: 'Alojamiento',
};

export const OPTION_VALUE_OTHERS = {
  en_US: 'Others',
  pt_BR: 'Outros',
  es_ES: 'Otro',
};

//Payment type options
export const PAYMENT_CREDIT_CARD = {
  en_US: 'Credit card',
  pt_BR: 'Cartão de crédito',
  es_ES: 'Tarjeta de crédito',
};

export const PAYMENT_DEBIT_CARD = {
  en_US: 'Debit card',
  pt_BR: 'Cartão de débito',
  es_ES: 'Tarjeta de débito',
};

export const PAYMENT_ELECTRONIC_TRANSFER = {
  en_US: 'Electronic transfer',
  pt_BR: 'Transferência eletrônica',
  es_ES: 'Transferencia electronica',
};

export const PAYMENT_VIRTUAL_CREDIT_CARD = {
  en_US: 'Virtual credit card',
  pt_BR: 'Cartão de crédito virtual',
  es_ES: 'Tarjeta de credito virtual',
};

export const PAYMENT_CASH = {
  en_US: 'Cash',
  pt_BR: 'Dinheiro',
  es_ES: 'Dinero',
};

export const PAYMENT_CHECK = {
  en_US: 'Check',
  pt_BR: 'Cheque',
  es_ES: 'Cheque',
};
