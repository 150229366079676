import { CountryListItem } from '../@types/Country';
import listCountryPrefixJson from '../intl/countryPrefix.json';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

const listCountryPrefix: CountryListItem[] = listCountryPrefixJson;

polyfillCountryFlagEmojis();

/**
 * Get country from internal code
 *
 * @param isoCountryCode - Internal (Salesforce) country code
 * @returns The country object
 */
export const getPrefixFromCountry = (isoCountryCode: string) =>
  listCountryPrefix.find(item => isoCountryCode === item.country.toUpperCase());

/**
 * Get country from ISO-2 country code
 *
 * @param countryCode - ISO-2 (ie. BR, US, CA) country code
 * @returns The country object
 */
export const getCountryFromPrefix = (countryCode: string) =>
  listCountryPrefix.find(country => countryCode === country.initials);

/**
 * Get country ISO-2 code from phone international prefix
 *
 * @param countryPrefix - Internacional dialing code (ie. +1 for US, +55 for BR)
 * @returns The country ISO-2 code
 */
export const countryCodeFromPhonePrefix = (value: string) => {
  const prefix = value.charAt(0) === '+' ? value.substring(1) : value;
  const row = listCountryPrefix.find(row => row.prefix === prefix);
  const country = row ? row.initials : '';

  return country;
};

export const getCorrectTranslateByCountry = country =>
  listCountryPrefix.find(
    item => item.country.toLowerCase() === country.toLowerCase()
  );

export const countriesExceptions = initials => {
  switch (initials) {
    case 'PI': //Puerto Rico English -> Puerto Rico
      return 'PR';
    default:
      return initials;
  }
};

export const getCountryByIdiom = (country, idiom) => {
  const findCountry = listCountryPrefix.find(
    item => item.country.toLowerCase() === country.toLowerCase()
  );
  return findCountry ? findCountry[idiom] : '';
};

export const getCountryFromPrefixTranslated = (prefix, idiom) => {
  try {
    const row = listCountryPrefix.find(country => prefix === country.initials);
    return idiom === 'pt_BR'
      ? row.pt_BR
      : idiom === 'en_US'
      ? row.en_US
      : row.es_ES;
  } catch {
    return '';
  }
};

export const isBR = country => country === 'BR';

const getFlagEmoji = countryCode => {
  try {
    return String.fromCodePoint(
      ...countryCode
        .toUpperCase()
        .split('')
        .map(char => 127397 + char.charCodeAt())
    );
  } catch (error) {
    return '';
  }
};

interface GetCountryPrefixSelectOptionsConfig {
  sort?: 'ALPHABETICALLY';
  showFlags?: boolean;
}
export const getCountryPrefixSelectOptions = (
  idiom: string,
  configs?: GetCountryPrefixSelectOptionsConfig
) => {
  let list = listCountryPrefix;

  if (configs.sort === 'ALPHABETICALLY') {
    list = list.sort((a, b) => a[idiom].localeCompare(b[idiom]));
  }

  const options = list.map(country => ({
    name: `${configs.showFlags ? `${getFlagEmoji(country.initials)} ` : ''}${
      country[idiom]
    } +${country.prefix}`,
    value: country.prefix,
  }));

  return options;
};
