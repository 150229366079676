import { CARD } from '../../../../../utils/consts';

export const formInitialValues = () => {
  const payment = {
    nombre: '',
    number: '',
    date: '',
    cvc: '',
    postalCode: '',
    tarjeta: CARD,
    isFatura: false,
    paypalPaymentId: null,
    payerID: null,
    installments: 1,
    recibirNoticias: false,
    is_not_preferred: false,
  };

  const recaptcha = {
    recaptcha: '',
  };

  return { payment, recaptcha };
};
