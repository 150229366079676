import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import Input from '../../../../../../../../components/Input/Input';
import { safeGet } from '../../../../../../../../utils/object';
import styles from '../IdTheftProtection.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';
import * as translations from './intl';

const TimeOffWorkArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, chnageCurrency } =
    props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.fontGray12} ${styles.options} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={styles.labelTitle}>
              {intl.TIME_OFF_EXPENSES}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TIME_OFF_EXPENSES}
              value={true}
              text={intl.TEXT_OPTION_YES}
              name="time_off.checked"
              dataTestid="InputTimeOffYes"
              onChange={handleChange}
              touched={safeGet(touched, 'time_off.checked')}
              onBlur={handleBlur}
              error={safeGet(errors, 'time_off.checked')}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TIME_OFF_EXPENSES}
              value={false}
              defaultChecked={true}
              text={intl.TEXT_OPTION_NO}
              name="time_off.checked"
              dataTestid="InputTimeOffNo"
              onChange={handleChange}
              touched={safeGet(touched, 'time_off.checked')}
              onBlur={handleBlur}
              error={safeGet(errors, 'time_off.checked')}
            />
          </div>

          {values.time_off.checked === 'true' && (
            <>
              <div className="col-12 col-sm-12 col-md-6">
                <Input
                  label={intl.AMOUNT_PAID}
                  name="time_off.amount.value"
                  dataTestid="InputTimeOffValue"
                  type="text"
                  formikProps={props}
                  maskedMoney
                  tooltipText={intl.TEXT_DECIMAL_SYMBOL}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6">
                <Select
                  label={intl.LABEL_CURRENCY}
                  name="time_off.amount.currency"
                  value={values.time_off.amount.currency}
                  dataTestid="InputTimeOffCurrency"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                    ),
                  }}
                  onChange={event => {
                    chnageCurrency(event);
                    handleChange(event);
                  }}
                  touched={touched.time_off && touched.time_off.amount.currency}
                  onBlur={handleBlur}
                  error={errors.time_off && errors.time_off.amount.currency}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeOffWorkArea;
