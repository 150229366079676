import { focusOnErrorElement } from '../../../../../utils/elements';

export const checkErrorsOnForm = async (
  submitRefs,
  schemas,
  values,
  accordeonRefs
) => {
  submitRefs.current.click();
  try {
    await schemas.fields.appointment.validate(values.appointment);
  } catch (error) {
    if (
      error.path === 'country' ||
      error.path === 'state' ||
      error.path === 'city'
    ) {
      accordeonRefs.pacient.current.click();
    } else {
      accordeonRefs.appointment.current.click();
    }
    focusOnErrorElement(0);
    return;
  }

  try {
    await schemas.fields.pacient.validate(values.pacient);
  } catch (error) {
    accordeonRefs.pacient.current.click();
    focusOnErrorElement(0);
    return;
  }

  try {
    await schemas.fields.questions.validate(values.questions);
  } catch (error) {
    // accordeonRefs.questions.current.click();
    focusOnErrorElement(0);
    return;
  }

  return true;
};

export const checkErrorsOnSession = async (
  submitRefs,
  schemas,
  values,
  accordeonRefs,
  nextAcordeon = null,
  currentAcordeon = null,
  formRef = null
) => {
  submitRefs.current.click();
  if (currentAcordeon === 'appointment' || currentAcordeon === null) {
    try {
      await schemas.fields.appointment.validate(values.appointment);
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
    } catch (error) {
      if (
        error.path === 'country' ||
        error.path === 'state' ||
        error.path === 'city'
      ) {
        if (error.value.time) {
          formRef.setErrors({ errors: [] });
          formRef.setTouched({}, false);
          return true;
        }
      }
      focusOnErrorElement(0);
      return false;
    }
  }

  if (currentAcordeon === 'pacient' || currentAcordeon === null) {
    try {
      await schemas.fields.appointment.validate(values.appointment);
      await schemas.fields.pacient.validate(values.pacient);
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
    } catch (error) {
      if (
        error.path === 'time' ||
        error.path === 'timezone' ||
        error.path === 'date'
      ) {
        accordeonRefs.appointment.current.click();
      }
      focusOnErrorElement(0);
      return false;
    }
  }

  if (currentAcordeon === 'questions' || currentAcordeon === null) {
    try {
      await schemas.fields.questions.validate(values.questions);
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
    } catch (error) {
      focusOnErrorElement(0);
      return false;
    }
  }

  return true;
};
