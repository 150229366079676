import moment from 'moment';
import { UPSELL } from '../../CONFIG_B2B2C';
import {
  LIST_ELIGIBLE_CARDS_CROSSELL,
  LIST_ELIGIBLE_CARDS_UPSELL,
} from '../const/ProductCode';
import { verifyProductCode } from '../FormQuote/initialValues';

export const mockQuote = (cardName, productType) => {
  const productCode =
    productType === UPSELL
      ? verifyProductCode(cardName, LIST_ELIGIBLE_CARDS_UPSELL)
      : verifyProductCode(cardName, LIST_ELIGIBLE_CARDS_CROSSELL);

  const tripEndDate =
    productType === UPSELL
      ? moment(new Date()).add(1, 'year')
      : moment(new Date()).add(1, 'month');

  return {
    formType: 'es_mx',
    values: {
      Quote: {
        Ages: [{ value: 69 }],
        AmountCurrencyISOCode: 'MXN',
        CountryOfResidenceIsoCode: 'MX',
        DestinationCountryIsoCode: '',
        IncludesEurope: false,
        ProductCode: productCode,
        ProductType: productType,
        TripEndDate: tripEndDate,
        TripInitialDate: new Date(),
        numberTraleversInput: 1,
        recaptcha: '',
      },
    },
  };
};
