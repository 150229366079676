import moment from 'moment';

export const initialValues = (quote, type) => {
  const diffTripDays =
    quote &&
    moment(quote.Quote.TripEndDate).diff(
      moment(quote.Quote.TripInitialDate),
      'days'
    );
  const maxTripDays = 60;

  return {
    Quote: {
      CountryOfResidenceIsoCode:
        quote && quote.Quote && quote.Quote.CountryOfResidenceIsoCode
          ? quote.Quote.CountryOfResidenceIsoCode
          : '',
      DestinationCountryIsoCode:
        quote && quote.Quote && quote.Quote.DestinationCountryIsoCode
          ? quote.Quote.DestinationCountryIsoCode
          : '',
      TripInitialDate:
        quote && quote.Quote && quote.Quote.TripInitialDate
          ? new Date(moment(quote.Quote.TripInitialDate))
          : '',
      TripEndDate:
        quote &&
        quote.Quote &&
        quote.Quote.TripEndDate &&
        diffTripDays &&
        diffTripDays <= maxTripDays
          ? new Date(moment(quote.Quote.TripEndDate))
          : '',
    },
  };
};
