export const GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  pt_BR: 'Gerar bilhete',
  es_ES: 'Generar un certificado',
};

export const TEXT_VIEW_ALL = {
  en_US: 'View all certificates',
  es_ES: 'Ver todos los certificados',
  pt_BR: 'Ver todos os bilhetes',
};

export const TEXT_SELECT_CERTIFICATE = {
  en_US: 'Select travel assistance certificate',
  pt_BR: 'Selecionar bilhete seguro viagem',
  es_ES: 'Seleccionar certificado de asistencia en viaje',
};

export const TEXT_NO_CERTIFICATE_FOUND = {
  en_US: 'No active certificate was found for this period',
  es_ES: 'No se encontró ningún certificado activo para este período.',
  pt_BR: 'Nenhum bilhete válido foi encontrado para esse período.',
};
