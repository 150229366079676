import * as Yup from 'yup';
import { parse, isValid } from 'date-fns';

const validationSchema = intl =>
  Yup.object().shape({
    phone_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    phone: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    surname: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    birth_date: Yup.date().required(intl.TEXT_FIELD_REQUIRED),
    age: Yup.number().positive().required(),
  });

export default validationSchema;
