import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const MenuIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      {...handledProps}
    >
      <g strokeWidth={2}>
        <path stroke={colors.primary} d="M3.5 5h18" />
        <path stroke={colors.secondary} d="M3.5 12h18" />
        <path stroke={colors.primary} d="M3.5 19h18" />
      </g>
    </svg>
  );
};

export default MenuIcon;
