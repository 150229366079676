import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Certificates from './Certificates/Certificates';
import Claims from './Claims/Claims';
import styles from './CertificatesAndClaims.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { REACT_APP_TOTAL_REIMBURSEMENT } from '../../../utils/environments_variables';
import CertificatePlus from '../../../assets/icons/CertificatesPlusIcon';
import ClaimsPlus from '../../../assets/icons/ClaimsPlusIcon';
import Generate from './Generate/Generate';
import BannerCinepolis from '../../../components/BannerCinepolis/BannerCinepolis';
import { hasCinepolis } from '../../../utils/benefits';
import { productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';

const CertificatesAndClaims = () => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);
  const [benefits, setBenefits] = useState([]);
  const [cinepolis, setCinepolis] = useState(false);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const binOrPan = utils.getBin(true);

  useEffect(() => {
    const getBenefits = async () => {
      try {
        const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
          binOrPan,
          idiomForApi(),
          false,
          false
        );

        setBenefits(benefits.data);
        setCinepolis(hasCinepolis(benefits.data));
      } catch (error) {
        setBenefits([]);
        setCinepolis(false);
      }
    };
    getBenefits();
  }, [binOrPan, idiomForApi]);

  return (
    <div className={`${styles.certificatesAndClaims}`}>
      {REACT_APP_TOTAL_REIMBURSEMENT ? (
        <div className={`${styles.titleContainer} ${styles[theme]}`}>
          {intl.TEXT_MY_DASHBOARD}
        </div>
      ) : (
        <div className="row">
          <div className={`col-lg-6 col-md-12 ${styles.certificates}`}>
            <Generate
              blue
              icon={
                <CertificatePlus
                  width={30}
                  height={30}
                  aria-label={intl.ADD_CERTIFICATE}
                  data-testid="addCertificate"
                />
              }
              title={intl.TEXT_GENERATE_CERTIFICATE}
              url="/certificates"
              GTM_id="CTA Generate a certificate"
              type="certificate"
              lightBackground={false}
            />
          </div>
          <div className={`col-lg-6 col-md-12 ${styles.certificates}`}>
            <Generate
              blue
              icon={
                <ClaimsPlus width={30} height={30} data-testid="addClaim" />
              }
              title={intl.TEXT_OPEN_A_CLAIM}
              url="/claims"
              GTM_id="CTA Create a claim"
              type="claim"
              lightBackground={false}
            />
          </div>
        </div>
      )}
      <div className={`row`}>
        <Certificates benefits={benefits} />
        <Claims />
      </div>
      {cinepolis && <BannerCinepolis cinepolisBenefit={cinepolis} />}
    </div>
  );
};

export default CertificatesAndClaims;
