import { focusOnErrorElement } from '../../../../utils/elements';

export const checkErrorsOnForms = async (
  submitRefs,
  accordeonRefs,
  schemas,
  values,
  statusAccordeon,
  trackEventUserAction
) => {
  if (submitRefs.payment) {
    try {
      await schemas.payment.validate(values.payment);
    } catch (error) {
      trackEventUserAction(
        '#### (B2B2C) VALIDANDO PAYMENT FORM - PAYMENT ####',
        error
      );
      if (!statusAccordeon.payment) {
        accordeonRefs.payment.current.click();
      }
      focusOnErrorElement(0);
      return false;
    }
  }

  return true;
};
