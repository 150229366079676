export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPT_TERMS = {
  en_US: 'I have read and accept the terms and conditions.',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const ACCEPT_TERMS_UNTIL_31_MAY = {
  en_US: 'I understand the benefits amount covered is: ',
  es_ES: 'Entiendo que el monto de los beneficios cubiertos es: ',
  pt_BR:
    'Estou ciente que para compras elegíveis realizadas até 31 de Maio de 2023 o valor do benefício precisa que o: ',
};

export const ACCEPT_TERMS_GOLD_UNTIL_31_MAY = {
  en_US: 'I understand the benefits amount covered is: ',
  es_ES: 'Entiendo que el monto de los beneficios cubiertos es: ',
  pt_BR:
    'Estou ciente que para compras elegíveis realizadas até 31 de Maio de 2023 o valor do benefício é de: ',
};

export const ACCEPT_TERMS_AFTER_31_MAY = {
  en_US: 'I understand the benefits amount covered is: ',
  es_ES: 'Entiendo que el monto de los beneficios cubiertos es: ',
  pt_BR:
    'Estou ciente que para os bilhetes de Seguros emitidos a partir de 1º de Junho de 2023 o valor do benefício é de: ',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const TEXT_INCLUDE_CERTIFICATE = {
  en_US: 'Continue',
  pt_BR: 'Incluir o bilhete de seguro',
  es_ES: 'Continúe',
};

export const MESSAGE_WITHOUT_CERTIFICATE = {
  en_US:
    'You are creating an International Emergency Medical Services claim without a Travel Certificate associated. Are you sure?',
  es_ES:
    'Está creando una reclamación de servicios médicos de emergencia internacional sin un certificado de viaje asociado. ¿Está seguro?',
  pt_BR: '',
};

export const MESSAGE_EFFECTIVE_OCTOBER = {
  en_US: 'Effective October 1 2021, a Travel Certificate must be generated.',
  es_ES:
    'A partir del 1 de octubre de 2021, se debe generar un Certificado de viaje.',
  pt_BR: '',
};

export const TEXT_YES = {
  en_US: "Yes, I'm sure",
  es_ES: 'Sí, estoy seguro',
  pt_BR: '',
};

export const TEXT_NO = {
  en_US: 'No, associate a certificate',
  es_ES: 'No, asociar un certificado',
  pt_BR: '',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_1 = {
  en_US:
    'I certify that the card selected for the opening of the claim was the same one I used to make the purchase of the claimed product and that I am the holder of this card.',
  es_ES:
    'Certifico que la tarjeta seleccionada para la apertura del reclamo fue la misma utilizada para realizar la compra del producto reclamado y que esta es de mi titularidad.',
  pt_BR:
    'Certifico que o cartão selecionado para abertura do sinistro foi o mesmo utilizado para realizar a compra do produto reclamado e que este é de minha titularidade.',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_2 = {
  en_US:
    'I declare that the claimed product is not intended for commercial, professional or resale use. It was acquired for my own use or as a gift. ',
  es_ES:
    'Declaro que el producto reclamado no es destinado a uso comercial, profesional o para reventa. El mismo fue adquirido para uso propio o para regalo.',
  pt_BR:
    'Declaro que o produto reclamado não é destinado a uso comercial, profissional ou revenda. O mesmo foi adquirido para uso próprio ou para presente.',
};

export const ACCEPT_TERMS_PRICE_PROTECTION_3 = {
  en_US:
    'I declare that I don’t have other open claims for the product claimed here.',
  es_ES:
    'Declaro que no poseo otros reclamos abiertos para el producto aquí reclamado.',
  pt_BR:
    'Declaro que não possuo outro(s) sinistro(s) abertos para o produto aqui reclamado.',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Certificate selection',
  es_ES: 'Selección de certificado',
  pt_BR: 'Seleção de bilhete',
};

export const TEXT_STEP_3 = {
  en_US: 'Claim form',
  es_ES: 'Formulario de reclamo',
  pt_BR: 'Formulário do sinistro',
};

export const TEXT_STEP_4 = {
  en_US: 'Sending documents',
  es_ES: 'Envío de documentos',
  pt_BR: 'Envio de documentos',
};

export const PRICE_PROTECTION_DISCLAIMER_BR = {
  en_US: '',
  es_ES: '',
  pt_BR: `A Visa não é provedora dos seguros. Este seguro e sua correspondente cobertura são oferecidos pela AIG Seguros Brasil S/A, CNPJ/MF n.º 33.040.981/0001-50, (a “AIG”) aos portadores de Cartões Visa elegíveis. As descrições deste
  benefício não são apólices de seguro. O seguro está sujeito aos termos e condições gerais, limitações e exclusões da apólice emitida pela AIG, sendo possíveis alterações ou cancelamento dos termos e condições gerais do seguro.`,
};

export const PRICE_PROTECTION_OLD_TERMS = {
  en_US: () => '',
  es_ES: () => '',
  pt_BR: url =>
    `<i>Para compras elegíveis realizadas antes de <strong>1º de junho de 2023</strong>, consulte os <a href="${url}" aria-label="Termos e Condições - nova aba" target="_blank" rel="noopener noreferrer">Termos e Condições</a> do período correspondente.</i>`,
};

export const IEMS_ARGENTINA_DISCLAIMER = {
  en_US: '',
  es_ES:
    'AXA no es responsable por las tasas cobradas eventualmente por el banco receptor/políticas gubernamentales sobre la transferencia internacional del reembolso. Todas las cantidades mencionadas se expresan en dólares de los EE. UU',
  pt_BR: '',
};

export const CLAIM_COVERAGE_TITLE = {
  en_US: 'Benefit amount',
  es_ES: 'Monto del beneficio',
  pt_BR: 'Valor do beneficio',
};

// PRICE PROTECTION
export const COVERAGE_PRICE_PROTECTION_CLASSIC = {
  en_US:
    'Up to USD 200 per event or per 12-month period with a maximum of 4 events per 12-month period.',
  es_ES: 'USD 200 por cuenta por un período de 12 meses.',
  pt_BR: '',
};

export const COVERAGE_PRICE_PROTECTION_GOLD = {
  en_US:
    'Up to USD 400 per event or per 12-month period with a maximum of 4 events per 12-month period.',
  es_ES: 'USD 400 por cuenta por un período de 12 meses.',
  pt_BR: 'Até R$1.320 por conta e por ano.',
};

export const COVERAGE_PRICE_PROTECTION_PLATINUM = {
  en_US:
    'Up to USD 500 per event - Maximum of USD 2,000 per 12-month period with a maximum of 4 events per 12-month period.',
  es_ES:
    'USD 500 por artículo - Máximo de USD 2,000 por cuenta por un período de 12 meses.',
  pt_BR: '',
};

export const COVERAGE_PRICE_PROTECTION_SIGNATURE = {
  en_US:
    'Up to USD 500 per event - Maximum of USD 2,000 per 12-month period with a maximum of 4 events per 12-month period.',
  es_ES:
    'USD 500 por artículo - Máximo de USD 2,000 por cuenta por un período de 12 meses.',
  pt_BR: '',
};

export const COVERAGE_PRICE_PROTECTION_INFINITE = {
  en_US:
    'Up to USD 4,000 per event or per 12-month period with a maximum of 4 events per 12-month period.',
  es_ES: 'USD 4,000 por cuenta por un período de 12 meses.',
  pt_BR: '',
};

// PURCHASE PROTECTION
export const COVERAGE_PURCHASE_PROTECTION_GOLD = {
  en_US:
    'Coverage is limited to the cost of the item up to USD 1,000 per account per 12 month period.',
  es_ES:
    'La cobertura está limitada al costo del artículo por hasta USD 1,000 por cuenta por un período de 12 meses.',
  pt_BR: 'Até o limite máximo de R$3.300,00 por evento e por ano.',
};

export const COVERAGE_PURCHASE_PROTECTION_PLATINUM = {
  en_US:
    'Coverage is limited to USD 5,000 per event and up to USD 10,000 per account per 12 month period.',
  es_ES:
    'La cobertura está limitada a USD 5,000 por evento y hasta USD 10,000 por cuenta por un período de 12 meses.',
  pt_BR:
    'Até um total de R$ 6.600,00 por evento e R$13.200,00 por conta de cartão, por período de 12(doze) meses.',
};

export const COVERAGE_PURCHASE_PROTECTION_SIGNATURE = {
  en_US:
    'Coverage is limited to USD 5,000 per event and up to USD 10,000 per account per 12 month period.',
  es_ES:
    'La cobertura está limitada a USD 5,000 por evento y hasta USD 10,000 por cuenta por un período de 12 meses.',
  pt_BR:
    'Até um total de R$ 16.500,00 por evento e R$66.000,00 por conta de cartão, por período de 12(doze) meses.',
};

export const COVERAGE_PURCHASE_PROTECTION_INFINITE = {
  en_US:
    'Coverage is limited to USD 10,000 per event and up to USD 20,000 per account per 12 month period.',
  es_ES:
    'La cobertura está limitada a USD 10,000 por evento y hasta USD 20,000 por cuenta por un período de 12 meses.',
  pt_BR:
    'Até um total de R$ 33.000,00 por evento e R$99.000,00 por conta de cartão, por período de 12(doze) meses.',
};

// EXTENDED WARRANTY
export const COVERAGE_EXTENDED_WARRANTY_CLASSIC_OR_GOLD = {
  en_US: 'USD 1,000 per incident/maximum of USD 5,000 per acct. per year.',
  es_ES: 'USD 1,000 por incident/máximo de USD 5,000 por cuenta(cta.) por año.',
  pt_BR:
    'Até o limite máximo de US$1.000 por evento e US$5.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const COVERAGE_EXTENDED_WARRANTY_PLATINUM = {
  en_US: 'USD 5,000 per incident/maximum of USD 10,000 per acct. per year',
  es_ES:
    'USD 5,000 por incident/máximo de USD 10,000 por cuenta(cta.) por año.',
  pt_BR:
    'Até o limite máximo de US$2.500 por evento e US$5.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const COVERAGE_EXTENDED_WARRANTY_SIGNATURE = {
  en_US: 'USD 5,000 per incident/maximum of USD 10,000 per acct. per year',
  es_ES:
    'USD 5,000 por incident/máximo de USD 10,000 por cuenta(cta.) por año.',
  pt_BR:
    'Até o limite máximo de US$3.000 por evento e US$6.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const COVERAGE_EXTENDED_WARRANTY_INFINITE = {
  en_US: 'USD 5,000 per incident/maximum of USD 25,000 per acct. per year',
  es_ES:
    'USD 5,000 por incident/máximo de USD 25,000 por cuenta(cta.) por año.',
  pt_BR:
    'Até o limite máximo de US$5.000 por evento e US$25.000 por acúmulo de incidentes por conta do portador por ano.',
};

// Purchase Protection Secondary Text
export const SECONDARY_COVERAGE_PURCHASE_PROTECTION_INFINITE = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Até um total de R$33.000,00 por evento e R$66.000,00 por conta de cartão, por período de 12 (doze) meses.',
};

export const SECONDARY_COVERAGE_PURCHASE_PROTECTION_PLATINUM = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Até um total de R$16.500,00 por evento e R$33.000,00 por conta de cartão, por período de 12 (doze) meses.',
};

export const SECONDARY_COVERAGE_PURCHASE_PROTECTION_SIGNATURE = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Até um total de R$16.500,00 por evento e R$33.000,00 por conta de cartão, por período de 12 (doze) meses.',
};

export const SECONDARY_COVERAGE_PURCHASE_PROTECTION_GOLD = {
  en_US: '',
  es_ES: '',
  pt_BR: 'Até o limite máximo de R$3.300,00 por evento e por ano.',
};

// Extended Warranty Secondary Text
export const SECONDARY_COVERAGE_EXTENDED_WARRANTY_INFINITE = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Valor da nota fiscal de aquisição do produto até US$5.000 por incidente por conta por portador/ até o limite de US$25.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const SECONDARY_COVERAGE_EXTENDED_WARRANTY_PLATINUM = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Valor da nota fiscal de aquisição do produto até US$5.000 por incidente por conta por portador/ até o limite de US$10.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const SECONDARY_COVERAGE_EXTENDED_WARRANTY_SIGNATURE = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Valor da nota fiscal de aquisição do produto até US$5.000 por incidente por conta por portador/ até o limite de US$10.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const SECONDARY_COVERAGE_EXTENDED_WARRANTY_GOLD = {
  en_US: '',
  es_ES: '',
  pt_BR:
    'Até o limite máximo de US$1.000 por evento e US$5.000 por acúmulo de incidentes por conta do portador por ano.',
};

export const BRAZIL_MAX_CONVERAGE_DATE_MAY = {
  en_US: '',
  es_ES: '',
  pt_BR: 'Para compras elegíveis realizadas até 31 de Maio de 2023:',
};

export const BRAZIL_MAX_CONVERAGE_DATE_JUNE = {
  en_US: '',
  es_ES: '',
  pt_BR: 'Para compras elegíveis realizadas a partir de 1 de Junho de 2023:',
};

export const WARNING = {
  en_US: 'Warning',
  es_ES: 'Advertencia',
  pt_BR: 'Aviso',
};
