import React, { useContext } from 'react';
import styles from './Textarea.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { sanitize } from 'dompurify';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import { IntlContext } from '../../intl';
import * as translations from './intl';

const removeRequiredCharLabel = data => {
  return data && data.slice(-1) === '*'
    ? data.substring(0, data.length - 1)
    : data;
};

const Textarea = ({
  label,
  name,
  value,
  error,
  onChange,
  onBlur,
  touched,
  defaultValue,
  disabled,
  errorLeftAlign,
  removeBottonBarColor,
  labelFlex,
  maxLength = '',
  className,
  dataTestid,
  rows,
  helpText,
  axa,
}) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  let inputClassName = `form-control ${styles[themes]}`;
  if (className) inputClassName += ` ${className}`;
  if (helpText) className += ' ' + styles.marginToIcon;
  if (error && touched) inputClassName += ' ' + styles.error;

  const errorLabelClassName = `${styles.errorLabel} ${
    errorLeftAlign ? styles.errorLeft : ''
  }`;
  const labelClassName = `${styles.label} ${labelFlex ? styles.labelFlex : ''}`;

  if (removeBottonBarColor) inputClassName += ' ' + styles.notColorful;
  const formikError = error && '1';
  const textarea = (
    <textarea
      id={`textarea-${name}`}
      aria-describedby={`error-${name}`}
      aria-invalid={error && touched}
      formik-error={formikError}
      autoComplete="off"
      className={inputClassName}
      placeholder={label}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={event => {
        const clean = sanitize(event.target.value, {
          ALLOWED_TAGS: ['visa-tag'],
        });
        event.target.value = clean;
        onChange(event);
      }}
      onBlur={onBlur}
      disabled={disabled}
      data-testid={dataTestid}
      maxLength={maxLength}
      rows={rows}
    />
  );

  return (
    <div className={`${styles.Textarea} ${styles[themes]}`}>
      {value && (
        <label
          htmlFor={`textarea-${name}`}
          className={`${labelClassName} ${styles[themes]}`}
        >
          {removeRequiredCharLabel(label)}
        </label>
      )}
      {textarea}
      {error && touched && (
        <label
          id={`error-${name}`}
          className={`${errorLabelClassName} ${styles[themes]}`}
          role="alert"
        >
          {error}
        </label>
      )}
      {helpText && (
        <div className="input-group-prepend">
          <span
            data-tooltip
            datatitle={helpText}
            className={`${styles.groupText} ${styles[themes]}`}
          >
            <TooltipIcon axa={axa ? 1 : 0} aria-label={intl.TOOLTIP_ICON} />
          </span>
        </div>
      )}
    </div>
  );
};

export default Textarea;
