import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/PriceProtection/consts';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const affectedPerson = (claimDetails && claimDetails.affected_person) || {};

  const cardholderRelationship =
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';

  const affectedPersonName =
    affectedPerson && affectedPerson.person && affectedPerson.person.first_name
      ? affectedPerson.person.first_name
      : '-';

  const affectedPersonLastName =
    affectedPerson && affectedPerson.person && affectedPerson.person.last_name
      ? affectedPerson.person.last_name
      : '-';

  const affectedPersonComment =
    (affectedPerson && affectedPerson.comment) || '';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={cardholderRelationship}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_NAME} value={affectedPersonName} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={affectedPersonLastName}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        {affectedPersonComment && (
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={affectedPersonComment}
          />
        )}
      </div>
    </>
  );
};

export default PersonalArea;
