import React from 'react';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentPeriodArea from './IncidentPeriodArea/IncidentPeriodArea';
import IncidentLocation from './IncidentLocation/IncidentLocation';
import IncidentAmountArea from './IncidentAmountArea/IncidentAmountArea';
import IncidentInsuranceArea from './IncidentInsuranceArea/IncidentInsuranceArea';

const ATM = props => {
  const { claimDetails } = props;

  const incidentKind =
    claimDetails &&
    claimDetails.incident &&
    claimDetails.incident.kind === 'LOSS';

  const otherInsuranceChecked =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering[0] &&
    claimDetails.other_insurance_covering[0].is_internal === false;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentPeriodArea claimDetails={claimDetails} />
      <IncidentLocation claimDetails={claimDetails} />
      {incidentKind && <IncidentAmountArea claimDetails={claimDetails} />}
      {otherInsuranceChecked && (
        <IncidentInsuranceArea claimDetails={claimDetails} />
      )}
    </div>
  );
};

export default ATM;
