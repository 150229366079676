import React, { useContext } from 'react';
import styles from './Item.module.scss';
import { Link } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import {
  dataLayerB2B2C,
  dataLayerBenefitTrack,
  dataLayerEnjoyBenefitTrack,
  SELECT_CONTENT_EVENT_NAME,
} from '../../../utils/GTM_helper';
import {
  getAlignBackgroundByURL,
  getValignBackgroundByURL,
} from '../../../utils/stringUtils';
import ArrowRightIcon from '../../../assets/icons/ArrowRight';
import Button from '../../../componentsV2/Button/Button';

const URL_UPSELLING = `upsell-detail/EMC_Emergency_Medical_Assistance`;
const URL_CROSSELLING = `cross-sell-detail/EMC_Emergency_Medical_Assistance`;
const UPSELLING = 'upselling';
const CROSSELLING = 'crosselling';

const getLinearGradient = (theme, isAfluent) => {
  if (theme === 'theme-portoBank') {
    return 'linear-gradient(45deg, #0A0047, #546AC0)';
  }

  return isAfluent()
    ? 'linear-gradient(45deg, #021E4C, #1a2655)'
    : 'linear-gradient(45deg, #1434CB, #122D98)';
};

const Item = ({ data, isSimpleBanner }) => {
  const { code, name, type, image, benefit_id } = data;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const dataLayer = () => {
    if (type === UPSELLING || type === CROSSELLING) {
      const url = type === UPSELLING ? URL_UPSELLING : URL_CROSSELLING;
      dataLayerB2B2C(url, SELECT_CONTENT_EVENT_NAME);
    } else if (isSimpleBanner) {
      dataLayerEnjoyBenefitTrack(code);
    } else {
      dataLayerBenefitTrack(code);
    }
  };

  const configPrivateBenefit = {
    upselling: {
      link: `/${URL_UPSELLING}`,
      linkName: intl.LINK_NAME_UPSELLING,
      image: image,
    },
    crosselling: {
      link: `/${URL_CROSSELLING}`,
      linkName: intl.LINK_NAME_CROSSELLING,
      image: image,
    },
    certificates: {
      link: `/certificate/${code}`,
      linkName: intl.TITLE_GENERATE_CERTIFICATE,
      image: image,
    },
    claims: {
      link: `/claim-type/${code}/generate`,
      linkName: intl.TITLE_MAKE_CLAIM,
      image: image,
    },
    benefit: {
      linkName: intl.TITLE_BUTTON_BENEFIT,
      link: `/your-benefits/${benefit_id}`,
      image: image,
    },
    special_benefit: {
      linkName: intl.TITLE_BUTTON_BENEFIT,
      link: `/your-subscription-benefits/${code}`,
      image: image,
    },
  };
  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div
        className={styles.imgBenefits}
        style={
          configPrivateBenefit[type].image
            ? {
                backgroundImage: `url(${configPrivateBenefit[type].image})`,
                backgroundPosition: `
                  ${image && getAlignBackgroundByURL(image)}
                  ${image && getValignBackgroundByURL(image)}
                `,
              }
            : {
                background: getLinearGradient(theme, isAfluent),
              }
        }
      >
        <div className={styles.blackCover}></div>
      </div>

      <div className={styles.contentInfo}>
        <h2 className={`${styles.title} ${styles[theme]}`}>{name}</h2>
        <Button
          link
          href={configPrivateBenefit[type].link}
          onClick={dataLayer}
          label={configPrivateBenefit[type].linkName}
          type="Tertiary"
          trailingIcon="Chevron Right"
          className={styles.link}
        />
      </div>
    </div>
  );
};

export default Item;
