import { BankAccountHolderData } from './bankAccountHolder';
import { BankAccountData } from './bankAccount';
import { IntermediaryBankAccountData } from './intermediaryBankAccount';
import { pt_BR } from '../../../../../../../intl/idioms';
import {
  countriesExceptions,
  getPrefixFromCountry,
} from '../../../../../../../utils/country';

export const paymentInformationsInitialValues = props => {
  const {
    data,
    idiom,
    country,
    isClaimUpdate,
    editBankInProfile,
    claimType,
    bankAccountHolder,
  } = props;
  const countryOfBank =
    data && data.bank && data.bank.address && data.bank.address.country;

  let countryOfCard = getPrefixFromCountry(country).initials;
  countryOfCard = countriesExceptions(countryOfCard);

  let isBrazil = idiom === pt_BR;
  let loadBank = {};
  let checkedCountry = countryOfCard;
  const isIems = claimType === 'EMC_Emergency_Medical_Assistance';

  if (isClaimUpdate) {
    loadBank = data || {};
  } else if (countryOfBank === countryOfCard) {
    loadBank = data;
  } else if (editBankInProfile) {
    loadBank = data;
    checkedCountry = countryOfBank;
    isBrazil = countryOfBank === 'BR';
  }

  let object = {};
  object.BankAccount = BankAccountData(loadBank, idiom, checkedCountry);
  object.BankAccountHolder =
    bankAccountHolder ?? BankAccountHolderData(loadBank);

  if (!isBrazil && !isIems) {
    object.IntermediaryBankAccount = IntermediaryBankAccountData(
      loadBank,
      checkedCountry
    );
  }
  return object;
};
