import React from 'react';
import { Route } from 'react-router-dom';

const LayoutRoutePrivate = ({
  IsLogged: IsLogged,
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      <IsLogged>
        <Layout>
          <Component {...props} />
        </Layout>
      </IsLogged>
    )}
  />
);

export default LayoutRoutePrivate;
