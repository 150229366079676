import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { getPhoneType } from '../../../../../../../utils/formatInfoProfile';

const PhoneArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const phone =
    claimDetails && claimDetails.travel_information.travel_agency.phone
      ? claimDetails.travel_information.travel_agency.phone
      : {};

  const phoneType =
    phone && phone.phone_type ? getPhoneType(phone.phone_type, intl) : '-';

  const phoneInternationalPrefix =
    phone && phone.international_prefix ? phone.international_prefix : '-';

  const phoneNumber = phone && phone.number ? phone.number : '-';

  return (
    <>
      <div>
        <InfoLabelValue
          label={intl.TEXT_PHONE_AREA}
          value={`${phoneType} - ${phoneInternationalPrefix} ${phoneNumber}`}
        />
      </div>
    </>
  );
};

export default PhoneArea;
