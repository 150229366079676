import React from 'react';
import PersonalArea from './PersonalArea/PersonalArea';
import CompanyArea from './CompanyArea/CompanyArea';
import DriverArea from './DriverArea/DriverArea';
import RentalPeriodArea from './RentalPeriodArea/RentalPeriodArea';
import DescriptionArea from './DescriptionArea/DescriptionArea';
import IncidentArea from './IncidentArea/IncidentArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';

const CarRental = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <CompanyArea claimDetails={claimDetails} />
      <DriverArea claimDetails={claimDetails} />
      <RentalPeriodArea claimDetails={claimDetails} />
      <DescriptionArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <QuestionsArea claimDetails={claimDetails} />
    </div>
  );
};

export default CarRental;
