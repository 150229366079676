import React, { useContext } from 'react';
import styles from './EmptyLayoutHalfBlue.module.scss';
import Logo from '../../components/Logo/Logo';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const EmptyLayoutHalfBlue = ({ children }) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <header>
        <Link to="/">
          <Logo size="BIGGER" color="#5c5c5c" />
        </Link>
      </header>
      <div className={styles.body}>{children}</div>
    </div>
  );
};

export default EmptyLayoutHalfBlue;
