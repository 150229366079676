import { useEffect } from 'react';
import { phonePrefixToInitials } from '../../../../../../../intl/utils';
import PhoneForm from '../../../../../../../components/PhoneForm';
import styles from './PhoneArea.module.scss';

const PhoneArea = props => {
  const { values, prefixOptions, disableProspectFields } = props;

  useEffect(() => {
    if (prefixOptions && values.address.country) {
      const prefix = phonePrefixToInitials(values.address.country);
      values.phone.international_prefix = prefix
        ? `+${prefix}`
        : values.phone.international_prefix;
      prefixOptions.setPrefix(values.phone.international_prefix);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.address.country]);

  return (
    <PhoneForm
      formikProps={props}
      className={styles.section}
      inputNames={{
        countryCode: 'phone.international_prefix',
        phoneNumber: 'phone.number',
        phoneType: 'phone.phone_type',
      }}
      disabled={disableProspectFields}
    />
  );
};

export default PhoneArea;
