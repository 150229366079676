import NATIONALITIES from '../intl/nationalities.json';
export const getRelationship = (relationship, intl) => {
  switch (relationship) {
    case 'Son':
      return intl.RELATIONSHIP_SON;
    case 'Daughter':
      return intl.RELATIONSHIP_DAU;
    case 'Spouse':
      return intl.RELATIONSHIP_SPOUSE;
    case 'Other':
      return intl.RELATIONSHIP_OTHER;
    default:
      return '';
  }
};

export const getTitle = (title, intl) => {
  switch (title) {
    case 'MR':
      return intl.LABEL_MISTER;
    case 'MS':
      return intl.LABEL_MS;
    case 'MISS':
      return intl.LABEL_MISS;
    case 'DR':
      return intl.LABEL_DR;
    case 'PR':
      return intl.LABEL_PR;
    default:
      return '';
  }
};

export const getGender = (title, intl) => {
  switch (title) {
    case 'FM':
      return intl.LABEL_FEMALE;
    case 'ML':
      return intl.LABEL_MALE;
    case 'UNKNOWN':
      return intl.LABEL_UNKNOWN;
    default:
      return '';
  }
};

export const getPhoneType = (title, intl) => {
  switch (title) {
    case 'LANDLINE':
      return intl.VALUE_PHONE_TYPE_LN;
    case 'MOBILE':
      return intl.VALUE_PHONE_TYPE_MB;
    default:
      return '';
  }
};

export const getPoliticallyExposed = (title, intl) => {
  switch (title) {
    case true:
      return intl.TEXT_YES;
    case false:
      return intl.TEXT_NO;
    default:
      return '';
  }
};

export const getNationality = (nationality, idiom) => {
  if (!nationality) return '-';
  const filterNationality = NATIONALITIES.filter(
    country => country.initials === nationality
  )[0];
  return filterNationality[idiom];
};
