export const LABEL_DESCRIPTION = {
  en_US:
    'Please give a detailed account of the facts, including what actions you have taken to resolve the matter of your identity theft and costs related to them *',
  pt_BR:
    'Forneça um relato detalhado dos fatos, incluindo as ações que você tomou para resolver o problema de roubo de identidade e os custos relacionados a eles *',
  es_ES:
    'Proporcione una descripción detallada de los hechos, incluidas las acciones que ha tomado para resolver el asunto del robo de identidad y los costos relacionados con ellos. *',
};

export const TITLE_DESCRIPTION = {
  en_US: 'Incident',
  pt_BR: 'Incidente',
  es_ES: 'Incidente',
};
