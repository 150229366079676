import { parseISO } from 'date-fns';
import { Flight } from '../../../@types/APIs/AEROAPI/Flight';

export type RouteStatus =
  | 'SCHEDULED'
  | 'DEPARTED_GATE'
  | 'ON_ROUTE'
  | 'LANDED'
  | 'ARRIVED_GATE';

export const getRouteStatus = (flight: Flight): RouteStatus => {
  if (!flight) return null;

  const { actual_out, actual_off, actual_on, actual_in } = flight;

  if (actual_in) return 'ARRIVED_GATE';
  if (actual_on) return 'LANDED';
  if (actual_off) return 'ON_ROUTE';
  if (actual_out) return 'DEPARTED_GATE';

  return 'SCHEDULED';
};

export const getStatusIntl = (status: RouteStatus, intl): string => {
  let translation = intl.STATUS_SCHEDULED;
  if (status === 'DEPARTED_GATE') translation = intl.STATUS_DEPARTED_GATE;
  if (status === 'ON_ROUTE') translation = intl.STATUS_ON_ROUTE;
  if (status === 'LANDED') translation = intl.STATUS_ON_LANDED;
  if (status === 'ARRIVED_GATE') translation = intl.STATUS_ARRIVED_GATE;

  return translation?.toUpperCase();
};

export const getStartDate = (flight: Flight) => {
  if (!flight) return null;
  return parseISO(flight.estimated_out || flight.scheduled_out);
};
