import { certificatesServices } from '../../../../../services';
import { filterNotExpired } from '../../../../../utils/certificate';

export const loadTeleconsultationData = async (
  cn,
  bin,
  setCertificates,
  idiom,
  setCertificateType,
  incidentDate
) => {
  try {
    const isBrazil = idiom === 'pt_BR';
    const idiomKey = idiom.replace('_', '-');
    setCertificateType('travel_insurance');
    const certificates = (
      await certificatesServices.getCertificatesByBin(cn, bin, idiomKey)
    ).data;
    const filtered = certificates.filter(
      c => c.certificate_type === 'Travel_Insurance'
    );
    if (isBrazil) {
      const filteredNotExpired = filterNotExpired(filtered, incidentDate);
      setCertificates(filteredNotExpired);
    } else {
      setCertificates(filtered);
    }
  } catch (e) {
    setCertificates('ERROR');
  }
};
