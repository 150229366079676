import React, { useEffect, useContext } from 'react';
import styles from './Login.module.scss';
import { IntlContext } from '../../intl';
import { userServices } from '../../services/index';
import Loading from '../Loading/Loading';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { useHistory } from 'react-router-dom';
import { pt_BR } from '../../intl/idioms';

const partnerFilters = ['disney', 'amazon', 'rappi'];

const Login = () => {
  const { twoDigitsIdiom, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const history = useHistory();
  const isBrazil = idiom === pt_BR;
  const isVaiDeVisa = window.location.hostname
    ? window.location.hostname.includes('vaidevisa')
    : false;

  const _redirect = partner => {
    let partnerParameter = '';
    if (partner) {
      partnerParameter = `&partner=${partner.toUpperCase()}`;
    }
    let countryParameter = '';
    if (isBrazil) {
      countryParameter = `&country=BR`;
    }
    const languagePortal = twoDigitsIdiom();
    const lang =
      languagePortal === 'pt' ? languagePortal + '-BR' : languagePortal;
    window.location.href = `/login?lang=${lang}&theme=${theme}${partnerParameter}${countryParameter}&ui_locales=${lang}${
      isVaiDeVisa ? '&connection=VisaGMAP' : ''
    }`;
  };

  const getUserInfoLogged = async () => {
    try {
      await userServices.getUserInfo();
      history.push('/dashboard');
    } catch (error) {
      const regex = new RegExp(partnerFilters.join('|'), 'i');
      let partner = null;

      let redirect = sessionStorage.getItem('redirectAfterLogin');

      if (redirect && regex.test(redirect)) {
        // sessionStorage.setItem('redirectAfterLogin', '');
        const regexValue = regex.exec(redirect);
        partner = regexValue[0];
      }

      if (!window.location.pathname.includes('verify_email_result')) {
        _redirect(partner);
      }
    }
  };

  useEffect(() => {
    getUserInfoLogged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.containerLogin} ${styles[theme]}`}>
      <Loading />
    </div>
  );
};

export default Login;
