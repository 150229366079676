import { IsClaimEnableToUseMLE } from '../utils/verifyAccess';

export default (env, axios, errorHandler) => ({
  getClaimTypes: async (bin_number, idiom = 'en-US') => {
    const binQueryParameter =
      bin_number.length === 16 ? 'credit_card_token' : 'contract_id';

    const url = `/api/specific/visagateway/v1/claim_types?${binQueryParameter}=${bin_number}`;
    const headers = {
      'Accept-Language': idiom,
    };
    const cacheKey = url + idiom;
    return errorHandler(
      axios.cacheGet(cacheKey)(url, { headers }),
      'getClaimTypes',
      { bin_number, url, idiom }
    );
  },
  getAllClaims: async (customer_id, idiom = 'en-US') => {
    const url = `/api/specific/visagateway/v1/claims?customer_id=${customer_id}`;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getAllClaims', {
      customer_id,
      url,
      idiom,
    });
  },
  getClaims: async (customer_id, bin_number, idiom = 'en-US', querie = '') => {
    const url =
      `/api/specific/visagateway/v1/claims?customer_id=${customer_id}&policy_id=${bin_number}` +
      querie;
    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getClaims', {
      customer_id,
      bin_number,
      url,
      idiom,
    });
  },
  getClaimInfoByClaimId: async (claim_id, idiom = 'en-US') => {
    const claimEnableToUseMLE =
      env.REACT_APP_JWE_CLAIMS_API_ENABLE.toString() === 'true';

    const url = claimEnableToUseMLE
      ? `/internal/claim/specific/visagateway/v2/claims/${claim_id}`
      : `/api/specific/visagateway/v1/claims/${claim_id}`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.get(url, { headers }), 'getClaimInfoByClaimId', {
      claim_id,
      url,
      idiom,
    });
  },
  expectedDocuments: async (bin_number, idiom = 'en-US', claim_id) => {
    const url = `/api/specific/visagateway/v1/claim_types/${claim_id}/expected_documents`;
    const headers = {
      'Accept-Language': idiom,
    };
    const cacheKey = url + idiom;
    return errorHandler(
      axios.cacheGet(cacheKey)(url, { headers }),
      'expectedDocuments',
      { claim_id, url, idiom }
    );
  },
  postAttachment: async (claim_id, data) => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/attachments`;
    return errorHandler(axios.post(url, data), 'postAttachment', {
      data,
      url,
      claim_id,
    });
  },
  postConcludeAttachment: async claim_id => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/complete`;
    return errorHandler(axios.post(url), 'postCompleteAttachment', {
      url,
      claim_id,
    });
  },
  deleteAttachment: async (claim_id, attachment_id) => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/attachments/${attachment_id}`;
    return errorHandler(axios.delete(url), 'deleteAttachment', {
      url,
      claim_id,
    });
  },
  postClaim: (claim_type, data, idiom = 'en-US') => {
    let version = 'v1';
    if (claim_type === 'legal_protection') {
      version = 'v2';
    }

    const claimEnableToUseMLE =
      env.REACT_APP_JWE_CLAIMS_API_ENABLE.toString() === 'true' &&
      IsClaimEnableToUseMLE(claim_type);

    if (claimEnableToUseMLE) {
      version = 'v2';
    }

    if (claim_type === 'trip') {
      version = 'v3';
    }

    const url = claimEnableToUseMLE
      ? `/internal/claim/specific/visagateway/${version}/${claim_type}/claims`
      : `/api/specific/visagateway/${version}/${claim_type}/claims`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(axios.post(url, data, { headers }), 'postClaim', {
      data,
      url,
      idiom,
    });
  },
  getCompleteInfoByClaimId: async (claim_id, claim_type, idiom = 'en-US') => {
    let version = 'v1';
    if (claim_type === 'legal_protection') {
      version = 'v2';
    }

    const claimEnableToUseMLE =
      env.REACT_APP_JWE_CLAIMS_API_ENABLE.toString() === 'true' &&
      IsClaimEnableToUseMLE(claim_type);

    if (claimEnableToUseMLE) {
      version = 'v2';
    }

    if (claim_type === 'trip') {
      version = 'v3';
    }

    const url = claimEnableToUseMLE
      ? `/internal/claim/specific/visagateway/${version}/${claim_type}/claims/${claim_id}`
      : `/api/specific/visagateway/${version}/${claim_type}/claims/${claim_id}`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.get(url, { headers }),
      'getCompleteInfoByClaimId',
      { claim_id, url, idiom }
    );
  },
  updateByClaimId: async (claim_id, claim_type, data, idiom = 'en-US') => {
    let version = 'v1';
    const claimEnableToUseMLE =
      env.REACT_APP_JWE_CLAIMS_API_ENABLE.toString() === 'true' &&
      IsClaimEnableToUseMLE(claim_type);

    if (claimEnableToUseMLE) {
      version = 'v2';
    }

    if (claim_type === 'trip') {
      version = 'v3';
    }

    const url = claimEnableToUseMLE
      ? `/internal/claim/specific/visagateway/${version}/${claim_type}/claims/${claim_id}`
      : `/api/specific/visagateway/${version}/${claim_type}/claims/${claim_id}`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.patch(url, data, { headers }),
      'updateByClaimId',
      { claim_id, url, idiom }
    );
  },
  cancelByClaimId: async (claim_id, data) => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/cancel`;
    return errorHandler(axios.post(url, data), 'cancelByClaimId', {
      url,
      claim_id,
    });
  },
  reopenByClaimId: async (claim_id, data) => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/reopen`;
    return errorHandler(axios.post(url, data), 'reopenByClaimId', {
      url,
      claim_id,
    });
  },
  requestProofOfPayment: async claim_id => {
    const url = `/api/specific/visagateway/v1/claims/${claim_id}/payment_proof_requests`;
    return errorHandler(axios.post(url), 'requestProofOfPayment', {
      url,
      claim_id,
    });
  },
});
