/* eslint-disable no-unused-vars */
import React from 'react';
import { checkGeneralInfoChange } from './checkGeneralInfoChange/checkGeneralInfoChange';
import { formatData } from './fomartData/fomartData';
import * as constants from './consts';
import { getQueryVariable } from '../../../../../utils/uri';
import { pt_BR } from '../../../../../intl/idioms';
import { confirmProfileAlert } from './confirmProfileAlert/confirmProfileAlert';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import modalSuccessCertificate from '../../../../../assets/images/modal/modalCertificateSuccess.jpg';
import { aigService } from '../../../../../services';
import {
  dataLayerCertificateEventTrack,
  CERTIFICATE_CREATED,
} from '../../../../../utils/GTM_helper';
import { getTripAssistanceBenefitId, loadBenefits } from './utils';
import { ENABLE_TRIP_ASSISTANCE } from '../../../../../utils/environments_variables';

export const submitCertificate = async props => {
  const { actions, history, intl, trackEventUserAction, idiomForApi } = props;
  let submit = true;

  try {
    const { certificateType } = props;

    await checkGeneralInfoChange(props);

    trackEventUserAction(
      '#### (CERTIFICATE) CLICOU NO SUBMIT -- VERIFICOU MUDANÇAS GENERAL',
      props
    );

    if (
      (certificateType === 'travel_insurance' ||
        certificateType === 'certificate_extended_warranty') &&
      props.idiom === pt_BR
    ) {
      submit = await confirmProfileAlert(props);
      if (!submit) {
        throw new Error();
      }
      trackEventUserAction(
        '#### (CERTIFICATE) CLICOU NO SUBMIT -- CONFIRMOU ALERT',
        props
      );
    }
    actions.loadingFullScreen.showLoadingFullScreen(
      intl.GENERATING_YOUR_CERTIFICATE
    );
    const { data } = await formatData(props);

    trackEventUserAction(
      '#### (CERTIFICATE) CLICOU NO SUBMIT -- CERTIFICADO CRIADO',
      true
    );

    dataLayerCertificateEventTrack(CERTIFICATE_CREATED, certificateType);

    const isTravel = certificateType === 'travel_insurance';
    let tripAssistanceId;
    if (isTravel && ENABLE_TRIP_ASSISTANCE) {
      const benefits = await loadBenefits(
        trackEventUserAction,
        props.binOrPan,
        idiomForApi
      );
      tripAssistanceId = getTripAssistanceBenefitId(benefits);
    }

    const returnToClaim = getQueryVariable('returnToClaim');
    const returnToTripAssistance = getQueryVariable('returnToTripAssistance');
    if (returnToClaim) {
      trackEventUserAction(
        '#### (CERTIFICATE) CLICOU NO SUBMIT -- RETORNANDO PARA CLAIM',
        true
      );
      history.push(`/claim-type/${returnToClaim}/generate`);
    } else if (returnToTripAssistance) {
      trackEventUserAction(
        '#### (CERTIFICATE) CLICOU NO SUBMIT -- SEGUINDO PARA TRIP ASSISTANCE',
        true
      );
      history.push(
        `/travel-kit/create/${tripAssistanceId}/${data.certificate_id}`
      );
    } else {
      trackEventUserAction(
        '#### (CERTIFICATE) CLICOU NO SUBMIT -- SEGUINDO PARA PREVIOUS CERTIFICATES',
        true
      );
    }
    actions.loadingFullScreen.hideLoadingFullScreen();

    const onPress = (goToTripAssistance = false) => {
      actions.modal.closeModal();
      if (goToTripAssistance) {
        history.push(
          `/travel-kit/create/${tripAssistanceId}/${data.certificate_id}`
        );
      }
    };

    const shouldDisplayTripAssistanceInfo = !!tripAssistanceId;

    if (shouldDisplayTripAssistanceInfo) {
      // If returnToTripAssistance is true, means that the user is already in the Trip Assistance flow
      const showRedirectToTripAssistance = !returnToTripAssistance;

      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.TEXT_SUCCESS}
          subtitle={intl.TEXT_SUCCESS_SUBTITLE}
          content={<p>{intl.TEXT_SUCCESS_SUBTITLE_TRIP_ASSISTANCE}</p>}
          img={modalSuccessCertificate}
          textBtn={intl.BUTTON_ACTIVATE_TRIP_ASSISTANCE}
          clickBtn={() => onPress(showRedirectToTripAssistance)}
          textBtnDeny={
            showRedirectToTripAssistance ? intl.BUTTON_DO_IT_LATER : null
          }
          clickBtnDeny={
            showRedirectToTripAssistance
              ? () => onPress(history.push(`/certificates`))
              : null
          }
        />,
        true
      );
    } else {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          textBtn={intl.TITLE_YOUR_CERTIFICATES}
          clickBtn={() => {
            actions.modal.closeModal();
            history.push(`/profile/certificates#active`);
          }}
          type="success"
          message={intl.TEXT_SUCCESS}
          subtitle={isTravel ? intl.TEXT_SUCCESS_SUBTITLE : undefined}
          img={modalSuccessCertificate}
        />,
        true
      );
    }
  } catch (error) {
    trackEventUserAction(
      '#### (CERTIFICATE) CLICOU NO SUBMIT -- ERRO NA CRIAÇÃO CERTIFICADO',
      {
        error,
        error_response: error.response,
        message: error.message,
        stack: JSON.stringify(error.stack),
      }
    );
    actions.loadingFullScreen.hideLoadingFullScreen();
    if (
      error.response.data.error_description === constants.CEP_ERROR ||
      error.response.data.error_description === constants.CEP_NOT_FOUND ||
      error.response.data.error_description ===
        constants.CEP_NOT_FOUND_MESSAGE_AIG
    ) {
      // Error cep
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={intl.TEXT_ERROR_CEP} />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description &&
      error.response.data.error_description ===
        constants.CEP_NOT_FOUND_MESSAGE_AIG_DEPENDENT
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_CEP_DEPENDENT}
        />
      );
    } else if (
      error.response.data.error_description.includes(
        constants.ERROR_DUPLICATED_CARDHOLDER
      ) ||
      error.response.data.error_description.includes(
        constants.ERROR_DUPLICATED_CARDHOLDER_WITHOU_ACCENT
      ) ||
      error.response.data.error_description.includes(
        constants.ERROR_DUPLICATED_CARDHOLDER_CERTIFICATE_HAS_ALREADY_BEEN_ISSUED
      )
    ) {
      // Duplicated cardholder
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_DUPLICATED_CARDHOLDER}
          subtitle={intl.SUBTITLE_ERROR_DUPLICATED_CARDHOLDER}
          textBtn={intl.TEXT_SEE_MY_CERTIFICATES}
          buttonConfirmType="borderBlue"
          clickBtn={() => {
            actions.modal.closeModal();
            history.push('/profile/certificates');
          }}
          textBtnDeny={intl.TEXT_CREATE_CERTIFICATE}
          clickBtnDeny={() => {
            actions.modal.closeModal();
            history.push('/certificates');
          }}
        />,
        true
      );
    } else if (
      error.response.data.error_description ===
        constants.ERROR_DUPLICATED_DEPENDENTS ||
      error.response.data.error_description ===
        constants.ERROR_DUPLICATED_DEPENDENT_CERTIFICATE_HAS_ALREADY_BEEN_ISSUED
    ) {
      // Duplicated dependent
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_DUPLICATED_DEPENDENTS}
          subtitle={intl.SUBTITLE_ERROR_DUPLICATED_DEPENDENTS}
        />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_AIG_NOT_AVAILABLE
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.ERROR_AIG_NOT_AVAILABLE}
          subtitle={intl.ERROR_SORRY}
        />
      );
      await aigService.aigSendEmail();
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_CPF_DEPENDENT_CERTIFICATE
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_CPF_DEPENDENT_CERTIFICATE}
          subtitle={intl.TEXT_SUBTITLE_ERROR_CPF_DEPENDENT_CERTIFICATE}
        />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_CUSTOMER_IS_NOT_COVERED_CERTIFICATE_TYPE
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={
            intl.NOT_POSSIBLE_TO_CREATE_CERTIFICATE_COVERED_CERTIFICATE_TYPE
          }
          subtitle={intl.CUSTOMER_IS_NOT_COVERED_CERTIFICATE_TYPE}
        />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_BIN_NUMBER_FROM_BRASIL_MUST_USE_CPF
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.NOT_POSSIBLE_TO_CREATE_CERTIFICATE_MUST_CPF_FOR_BRAZIL}
          subtitle={intl.BIN_FROM_BRASIL_NEED_CPF}
        />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_CPF_CAN_BE_USED_ONLY_BY_BRAZILIAN_BIN
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.NOT_POSSIBLE_TO_CREATE_CERTIFICATE}
          subtitle={intl.ERROR_CPF_CAN_BE_USED_ONLY_BY_BRAZILIAN_BIN}
        />
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        constants.ERROR_BRAZILIAN_DEPENDENT_MUST_USE_CPF
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={
            intl.NOT_POSSIBLE_TO_CREATE_CERTIFICATE_MUST_CPF_FOR_BRAZIL_DEPENDENT
          }
          subtitle={intl.ERROR_BRAZILIAN_DEPENDENT_MUST_USE_CPF}
        />
      );
    } else {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_CERTIFICATE}
          subtitle={intl.UNEXPECTED_ERROR}
          textBtn={intl.TRY_AGAIN}
          buttonConfirmType="blueBorder"
          textBtnDeny={intl.TRY_LATER}
          clickBtnDeny={() => {
            actions.modal.closeModal();
            history.push('/certificates');
          }}
        />,
        true
      );
    }
  }
};
