import React, { useContext } from 'react';
import styles from './NotificationDetail.module.scss';
import { IntlContext } from '../../intl';
import * as translations from './contentIntl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../utils/sanitize';

const NotificationDetail = props => {
  const { type } = props;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const intlObj = translate(translations);
  const text = intlObj[type];

  return (
    <div className={styles.container}>
      <h1 className={`${styles.title} ${styles[theme]}`}>{text.title}</h1>
      <div className={styles.body}>
        {text.content &&
          text.content.map(item => (
            <div key={item.id}>
              <h2
                className={`${styles.subtitle} ${styles[theme]}`}
                dangerouslySetInnerHTML={{ __html: sanitize(item.subtitle) }}
              />

              <p dangerouslySetInnerHTML={{ __html: sanitize(item.text) }} />
            </div>
          ))}
      </div>
      <footer dangerouslySetInnerHTML={{ __html: sanitize(text.footer) }} />
    </div>
  );
};

export default NotificationDetail;
