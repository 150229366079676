import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const OrderIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={12}
      fill="none"
      {...handledProps}
    >
      <path
        fill={colors.secondary}
        d="M0 12h6v-2H0v2ZM0 0v2h18V0H0Zm0 7h12V5H0v2Z"
      />
    </svg>
  );
};

export default OrderIcon;
