import { useState, useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../consts';
import styles from './ControlArea.module.scss';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import FormDependent from './FormDependent/FormDependent';
import AddIcon from '../../../../../../assets/icons/AddIcon';
import UserIcon from '../../../../../../assets/icons/UserIcon';
import Button from '../../../../../../componentsV2/Button/Button';

const AddArea = props => {
  const [addText, setAddText] = useState('');
  const { translate, idiom } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const intl = translate(translations);

  const newDependentForm = () => {
    actions.modal.showModal(
      intl.TITLE_ADD_DEPENDENT,
      true,
      <FormDependent
        {...props}
        initialValuesDependent={constants.emptyAdditional(idiom)}
      />,
      false,
      false,
      null,
      null,
      <UserIcon width={30} height={30} />,
      true,
      false,
      true
    );
  };

  const titleByWidthScreen = () => {
    if (window.screen.width > 768) {
      setAddText(intl.LABEL_ADD);
    } else {
      setAddText(intl.LABEL_ADD_SHORT);
    }
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
  }, []);

  return (
    <div className={`${styles.controlArea}`}>
      <Button
        type="Tertiary"
        id="btnAddDependent"
        onClick={() => {
          newDependentForm();
        }}
        label={addText}
        leadingIcon={<AddIcon />}
      />
    </div>
  );
};

export default AddArea;
