import React from 'react';
import Select from '../../../../../../components/Select/Select';
import { safeGet } from '../../../../../../utils/object';

const WarrantyArea = props => {
  const { values, handleChange, touched, handleBlur, errors, intl } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.ORIGINAL_WARRANTY_PERIOD}
            name="extended_warranty_information.original_period"
            formikProps={props}
            dataTestid="WarrantySelect"
          >
            <option value="">{intl.ORIGINAL_WARRANTY_PERIOD}</option>
            <option value="3">3 {intl.MONTHS}</option>
            <option value="4">4 {intl.MONTHS}</option>
            <option value="5">5 {intl.MONTHS}</option>
            <option value="6">6 {intl.MONTHS}</option>
            <option value="9">9 {intl.MONTHS}</option>
            <option value="12">12 {intl.MONTHS}</option>
            <option value="18">18 {intl.MONTHS}</option>
            <option value="24">24 {intl.MONTHS}</option>
            <option value="36">36 {intl.MONTHS}</option>
            <option value="48">48 {intl.MONTHS}</option>
            <option value="60">60 {intl.MONTHS}</option>
          </Select>
        </div>
      </div>
    </>
  );
};

export default WarrantyArea;
