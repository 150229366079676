export const TEXT_INFO = {
  en_US: 'It is our obligation to inform you of the following:',
  pt_BR: 'É nossa obrigação informá-lo sobre o seguinte:',
  es_ES: 'Es nuestra obligación informarte de lo siguiente:',
};

export const TEXT_ADVISE = {
  en_US:
    'Any person who, knowingly and with intent to defraud any insurance company or other persons, submits a claim report containing any materially false, incomplete or misleading information commits a fraudulent insurance act and is subject to criminal charges and civil penalties.',
  pt_BR:
    'Qualquer pessoa que, conscientemente e com a intenção de defraudar qualquer companhia de seguros ou outras pessoas, envie um relatório de reivindicação contendo qualquer informação materialmente falsa, incompleta ou enganosa que comete um ato de seguro fraudulento e esteja sujeito a cobranças criminais e penalidades civis.',
  es_ES:
    'Toda persona que, a sabiendas y con la intención de defraudar a cualquier compañía de seguros u otras personas, presente un informe de reclamo que contenga información materialmente falsa, incompleta o engañosa, comete una acción de seguro fraudulenta y está sujeta a cargos criminales y sanciones civiles.',
};

export const TEXT_INFO_TRUE = {
  en_US: 'The information provided is true and complete to my knowlege.',
  pt_BR:
    'A informação fornecida é verdadeira e completa para o meu conhecimento.',
  es_ES:
    'Declaro que la información proporcionada por medio de los formularios previamente completados, es verdadera y completa.',
};

export const CHECKBOX_UNDERSTAND = {
  en_US:
    'I understand that this form must be completed and all requirements for insurance, police reports (if it is required) must be submitted before any benefit claimed can be processed and paid. *',
  pt_BR:
    'Entendo que este formulário deve ser preenchido e que todos os requisitos de seguro, boletim de ocorrência policial (se necessário) devem ser apresentados antes que qualquer reivindicação possa ser processada e paga. *',
  es_ES:
    'Entiendo que este formulario debe de ser completado y que todos los requisitos de seguros, reporte ante la autoridad (si es requerido), deben de ser presentados antes de que cualquier reclamo pueda ser procesado y pagado. *',
};

export const CHECKBOX_AUTHORIZE = {
  en_US:
    'I authorize the issuing bank to provide AXA assistance S.A. all information about my account to process this claim. I continue giving AXA assistance S.A. authorization to obtain copies of any police, fire, or other investigation reports and information necessary to process my claim. *',
  pt_BR:
    ' Eu autorizo o banco emissor a fornecer para a AXA assistance todas as informações sobre a minha conta e a obter cópias de todos os relatórios de investigações necessários para processar esse pedido. *',
  es_ES:
    'Yo autorizo ​​al banco emisor a proporcionar a AXA assistance toda la información sobre mi cuenta y obtener copias de todos los informes de investigación necesarios para procesar esta solicitud. *',
};

export const CHECKBOX_ACCORD = {
  en_US:
    'International Emergency Medical Service is provided by AXA. In addition to this entity, other third parties are involved to deliver this service to the BENEFICIARY. By accessing the International Emergency Medical Service, the BENEFICIARY authorizes the sharing of his/her personal information with third parties, which may include cross border transfers. The personal information will be processed in order to deliver the service and provide the benefits hereunder. *',
  pt_BR:
    'O Seguro de Emergência Médica Internacional é fornecido pela AXA. Além desta entidade, outros terceiros estão envolvidos na entrega deste serviço ao BENEFICIÁRIO. Ao acessar o Seguro de Emergência Médica Internacional, o BENEFICIÁRIO autoriza o compartilhamento de suas informações pessoais com terceiros, o que pode incluir transferências internacionais. As informações pessoais serão processadas para fornecer o serviço e proporcionar esses benefícios. *',
  es_ES:
    'El Servicio de Emergencia Médica Internacional es provisto por AXA Partners. Además de esta entidad, otros terceros están involucrados para entregar este servicio al BENEFICIARIO. Al acceder al Servicio de Emergencia Médica Internacional, el BENEFICIARIO autoriza el intercambio de tu información personal con terceros, lo que puede incluir transferencias transfronterizas. La información personal se procesará para brindar el servicio y proporcionar dichos beneficios. *',
};

export const CHECKBOX_ACCORD_2 = {
  en_US:
    'This service is provided by or through AXA. In addition to this entity, other third parties are involved in delivering this service to the beneficiary. By accessing this service, the beneficiary authorizes the sharing of his/her personal information with third parties, which may include cross border transfers. The personal information will be processed in order to deliver the service and provide the benefits hearunder. *',
  pt_BR:
    'Este serviço é fornecido por AXA Partners. Além desta entidade, terceiros estão envolvidos na prestação deste serviço ao BENEFICIÁRIO. Ao utilizar este serviço, o beneficiário autoriza o compartilhamento dos seus dados pessoais com terceiros, o que pode incluir transferências interfronteiras. As informações pessoais serão processadas a fim de fornecer o serviço e fornecer estes benefícios. *',
  es_ES:
    'Este servicio es brindado por AXA Partners. Además de esta entidad, otros terceros están involucrados para entregar este servicio al BENEFICIARIO. Al acceder al beneficio reclamado, el BENEFICIARIO autoriza el intercambio de tu información personal con terceros, lo que puede incluir transferencias transfronterizas. La información personal se procesará para brindar el servicio y proporcionar dichos beneficios. *',
};

export const LABEL_DATE = {
  en_US: 'Date: ',
  pt_BR: 'Data: ',
  es_ES: 'Fecha: ',
};

export const LABEL_PHONE = {
  en_US: 'Phone number: ',
  pt_BR: 'Número de telefone: ',
  es_ES: 'Número de teléfono: ',
};

export const LABEL_PLACE = {
  en_US: 'Place: ',
  pt_BR: 'Lugar: ',
  es_ES: 'Lugar: ',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const PRICE_PROTECTION_DISCLAIMER_BR = {
  en_US: '',
  es_ES: '',
  pt_BR: `A Visa não é provedora dos seguros. Este seguro e sua correspondente cobertura são oferecidos pela AIG Seguros Brasil S/A, CNPJ/MF n.º 33.040.981/0001-50, (a “AIG”) aos portadores de Cartões Visa elegíveis. As descrições deste
  benefício não são apólices de seguro. O seguro está sujeito aos termos e condições gerais, limitações e exclusões da apólice emitida pela AIG, sendo possíveis alterações ou cancelamento dos termos e condições gerais do seguro.`,
};

export const TEXT_INFO_R_IEMS = {
  en_US: 'Statement and Consent',
  pt_BR: '',
  es_ES: 'Declaración y consentimiento',
};

export const TEXT_INFO_TWO_R_IEMS = {
  en_US:
    'Visa is not an insurance provider. These benefits are offered by AXA to eligible Visa cardholders. These benefits are not insurance policies.  AXA is not an insurance provider. These benefits are subject to the general terms and conditions, limitations, and exclusions as more specifically described herein.  The general terms and conditions of the benefits may change or be canceled without notice.',
  pt_BR: '',
  es_ES:
    'Visa no es un proveedor de seguros. Estos beneficios son ofrecidos por AXA a los titulares de tarjetas Visa elegibles. Estos beneficios no son pólizas de seguro. AXA no es un proveedor de seguros. Estos beneficios están sujetos a los términos y condiciones generales, limitaciones y exclusiones que se describen más específicamente aquí. Los términos y condiciones generales de los beneficios pueden cambiar o cancelarse sin previo aviso.',
};

export const TEXT_ADVISE_R_IEMS = {
  en_US:
    'Services may not be available in countries where the US Department of State has current travel warning issued or in countries where the US Department of the Treasury’s Office of Foreign Assets Control (OFAC) has active economic or trade sanctions or that are subject to other applicable trade or economic sanctions, laws and regulations. Additionally, no services will be available where the existing infrastructure is deemed inadequate by AXA to guarantee service.',
  pt_BR: '',
  es_ES:
    'Es posible que los servicios no estén disponibles en países donde el Departamento de Estado de EE. UU. haya emitido una advertencia de viaje actual o en países donde la Oficina de Control de Activos Extranjeros (OFAC) del Departamento del Tesoro de EE. UU. tenga sanciones económicas o comerciales activas o que estén sujetos a otras sanciones comerciales o económicas, leyes y regulaciones aplicables. Además, no habrá servicios disponibles en lugares donde AXA considere que la infraestructura existente es inadecuada para garantizar el servicio.',
};

export const TEXT_ADVISE_TWO_R_IEMS = {
  en_US:
    'It is our obligation to inform you that any person who knowingly and with the intent to defraud any insurance company or other person files a claim report containing materially false, incomplete, or misleading information commits a fraudulent insurance act and may be subject to criminal charges and civil penalties.',
  pt_BR: '',
  es_ES:
    'Es nuestra obligación informarle que cualquier persona que intencionalmente y con la intención de defraudar a una compañía de seguros u otra persona presente un informe de reclamación que contenga información falsa, incompleta o engañosa comete un acto de fraude de seguros y puede estar sujeta a cargos penales y sanciones civiles.',
};

export const TEXT_INFO_TRUE_R_IEMS = {
  en_US: 'I declare the following information to be true:',
  pt_BR: '',
  es_ES: 'Declaro que la siguiente información es verdadera:',
};

export const CHECKBOX_UNDERSTAND_R_IEMS = {
  en_US:
    'I understand that this form must be filled out and that all benefit requirements, including but not limited to the police report (if necessary), must be submitted before any claim can be processed and benefits paid. *',
  pt_BR:
    'Entendo que sou responsável por fornecer todas as informações e documentação necessárias solicitadas para que minha reclamação seja analisada. *',
  es_ES:
    'Entiendo que este formulario debe completarse y que todos los requisitos de los beneficios, incluido, entre otros, el informe policial (si es necesario), deben presentarse antes de que se pueda procesar cualquier reclamación y se paguen los beneficios. *',
};

export const CHECKBOX_AUTHORIZE_R_IEMS = {
  en_US:
    'I authorize the issuing bank to provide AXA with all information about my account and to obtain copies of all necessary investigation reports to process this claim. *',
  pt_BR: '',
  es_ES:
    'Autorizo al banco emisor a proporcionar a AXA toda la información sobre mi cuenta y a obtener copias de todos los informes de investigación necesarios para procesar esta reclamación. *',
};

export const CHECKBOX_ACCORD_R_IEMS = {
  en_US:
    'The International Emergency Medical services are provided by AXA. In addition to this entity, other third parties are involved in delivering this service to the cardholder. By accessing the International Emergency Medical services, the cardholder authorizes the sharing of their personal information with third parties, which may include international transfers. Personal information will be processed to provide the service and deliver these benefits.',
  pt_BR:
    'Entendo que Visa não é provedora de seguros. Este Seguro de Emergência Médica Internacional e suas coberturas correspondentes são oferecidas pela AIG Seguros Brasil S/A, CNPJ/MF n.º 33.040.981/0001-50, aos portadores de Cartões Visa elegíveis, onde a Visa tenha contratado essas coberturas. A assistência é prestada pela empresa AXA. Além dessas entidades, outros terceiros estão envolvidos na entrega deste serviço ao BENEFICIÁRIO. Ao acessar o Seguro de Emergência Médica Internacional, o BENEFICIÁRIO autoriza o compartilhamento de suas informações pessoais com terceiros, o que pode incluir transferências internacionais. As informações pessoais serão processadas para fornecer o serviço e proporcionar esses benefícios. *',
  es_ES:
    'Los Servicios de Emergencia Médica Internacional son proporcionados por AXA. Además de esta entidad, otros terceros están involucrados en la prestación de este servicio al titular de la tarjeta. Al acceder a los Servicios de Emergencia Médica Internacional, el titular de la tarjeta autoriza el intercambio de su información personal con terceros, lo cual puede incluir transferencias internacionales. La información personal se procesará para proporcionar el servicio y entregar estos beneficios.',
};
