export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder *',
  pt_BR: 'Relação com o titular do cartão *',
  es_ES: 'Relación con el titular de la tarjeta *',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const LABEL_DRIVER_NAME = {
  en_US: 'Driver name at time of incident *',
  pt_BR: 'Nome do condutor do veículo no momento do incidente *',
  es_ES: 'Nombre del conductor del vehículo al momento del incidente *',
};

export const LABEL_DRIVER_LAST_NAME = {
  en_US: 'Driver last name at time of incident *',
  pt_BR: 'Sobrenome do condutor do veículo no momento do incidente *',
  es_ES: 'Apellido del conductor del vehículo al momento del incidente *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outroooooo (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const LABEL_LICENSE_NUMBER = {
  en_US: 'Driving license number *',
  pt_BR: 'Número da carteira de habilitação *',
  es_ES: 'Número de la licencia de conducir *',
};

export const LABEL_COUNTRY_LICENSE = {
  en_US: 'Country where issued driver license *',
  pt_BR: 'País onde foi emitida a carteira de habilitação *',
  es_ES: 'País donde se emitió la licencia de conducir *',
};

export const LABEL_CAR_RENTAL_COMPANY = {
  en_US: 'Name of car rental company *',
  pt_BR: 'Nome da empresa de aluguel de automóvel *',
  es_ES: 'Nombre de la compañia de alquiler de automóviles *',
};

export const LABEL_PHONE_TYPE = {
  en_US: 'Phone type *',
  pt_BR: 'Tipo de telefone *',
  es_ES: 'Tipo de telefono *',
};

export const LABEL_PREFIX = {
  en_US: 'Prefix *',
  pt_BR: 'Prefixo *',
  es_ES: 'Prefijo *',
};

export const LABEL_PHONE_COMPANY = {
  en_US: 'Phone number of rental company *',
  pt_BR: 'Número de telefone da empresa de aluguel de automóvel *',
  es_ES: 'Número de teléfono de la compañia de alquiler de automóviles *',
};

export const LABEL_RENTAL_START = {
  en_US: 'Rental period start *',
  pt_BR: 'Início do período do aluguel *',
  es_ES: 'Comienzo del período de alquiler *',
};

export const LABEL_RENTAL_END = {
  en_US: 'Rental period end *',
  pt_BR: 'Fim do período do aluguel *',
  es_ES: 'Fin del periodo de alquiler *',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Description of incident *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_COUNTRY = {
  en_US: 'Country of incident *',
  pt_BR: 'País do incidente *',
  es_ES: 'País del incidente *',
};

export const LABEL_INCIDENT_STATE = {
  en_US: 'State of incident *',
  pt_BR: 'Estado do incidente *',
  es_ES: 'Estado del incidente *',
};

export const LABEL_INCIDENT_CITY = {
  en_US: 'City of incident *',
  pt_BR: 'Cidade do incidente *',
  es_ES: 'Ciudad incidente *',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Place of incident *',
  pt_BR: 'Local do incidente *',
  es_ES: 'Lugar del incidente *',
};

export const LABEL_PURCHASE_COLISION = {
  en_US: 'Did you purchased a collision insurance with car rental company? *',
  pt_BR:
    'Você contratou o seguro de colisão com a empresa de aluguel de automóvel? *',
  es_ES:
    '¿Contrató o adquirió un seguro de colisión de la compañía de alquiler de automóviles? *',
};

export const LABEL_BENEFITS_PAYABLE = {
  en_US: 'Benefits payable to *',
  pt_BR: 'Benefícios a pagar para *',
  es_ES: 'Beneficios a pagar para *',
};

export const LABEL_CARD_HOLDER = {
  en_US: 'Cardholder *',
  pt_BR: 'Titular do cartão *',
  es_ES: 'Titular de la tarjeta *',
};

export const LABEL_RENTAL_COMPANY = {
  en_US: 'Rental company *',
  pt_BR: 'Empresa de aluguel de automóvel *',
  es_ES: 'Compañia de alquiler de automóviles *',
};

export const LABEL_DAMEGE = {
  en_US: 'Estimated damages *',
  pt_BR: 'Danos estimados *',
  es_ES: 'Daños estimados *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit? *',
  pt_BR: 'Você tem outro seguro que cubra este incidente? *',
  es_ES: '¿Tienes otro seguro que cubra este incidente? *',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const VALIDATE_AMOUNT_ESTIMATION_DAMAGE = {
  en_US: 'The price difference must be greater than zero.',
  pt_BR: 'A diferença de preço deve ser maior que zero.',
  es_ES: 'La diferencia de precio debe ser mayor que cero.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_HOUR_VALID = {
  en_US: 'Please enter a value between 00:00 and 23:59.',
  pt_BR: 'Por favor, entre um valor entre 00:00 e 23:59.',
  es_ES: 'Por favor, introduzca un valor entre 00:00 y 23:59.',
};

export const VALIDATE_HOUR_BIGGER_DEPARTURE = {
  en_US: 'Please enter a value bigger than departure time.',
  pt_BR: 'Por favor, insira um valor maior que o horário da partida.',
  es_ES: 'Por favor, introduzca un valor mayor que la hora de salida.',
};

export const VALIDATE_DATE_BETWEEN_FROM_AND_TO = {
  en_US: 'Please enter a date between Start date of trip and End date of trip.',
  pt_BR:
    'Por favor, insira uma data entre Data de início da viagem e Data de fim de viagem.',
  es_ES:
    'Por favor, introduzca una fecha entre la Fecha de inicio del viaje y la Fecha de finalización del viaje.',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
