import { useContext } from 'react';
import Button, { ButtonProps } from '../Button/Button';
import * as translations from './intl';
import { IntlContext } from '../../intl/index';
import { useHistory } from 'react-router-dom';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';

interface LoginButtonProps {
  type?: ButtonProps['type'];
  size?: ButtonProps['size'];
  surface?: ButtonProps['surface'];
  label?: ButtonProps['label'];
  redirect?: string;
  track?: () => void;
  id?: ButtonProps['id'];
  className?: string;
}

const LoginButton = ({
  type,
  size,
  surface,
  label,
  redirect,
  track,
  id,
  className,
}: LoginButtonProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const history = useHistory();

  const _onClick = () => {
    if (track) {
      track();
    } else {
      dataLayerContentMenuTrack('CTA Log in');
    }

    history.push(redirect || `/login`);
  };

  return (
    <Button
      id={id}
      className={className}
      size={size}
      surface={surface}
      label={label ?? intl.TEXT_BTN_LOGIN}
      type={type}
      onClick={() => _onClick()}
    />
  );
};

export default LoginButton;
