import { pt_BR } from '../../intl/idioms';
import { getCountryByInitials } from '../../intl/utils';
import { formatCustomDate } from '../../utils/date';
import { getNationality, getPhoneType } from '../../utils/formatInfoProfile';
import { formatCPF, formatPostalCodeBR } from '../../utils/stringUtils';

const LIVED_TOGETHER_SINCE_DATE_FORMAT = 'YYYY-MM-DD';
const CARD_DATE_FORMAT = 'DD/MM/YYYY';

export const TRIP_DATE_FORMAT = 'DD/MM/YYYY';
export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

const formatPhone = (phone, intl) => {
  return phone
    ? `${getPhoneType(phone.phone_type, intl)}
    ${phone.international_prefix}
    ${phone.number}`
    : '-';
};

export const getAccidentReason = (isAccidentalDeath, intl) => {
  let reason = '-';
  if (typeof isAccidentalDeath === 'boolean') {
    reason = isAccidentalDeath
      ? intl.ACCIDENTAL_DEATH
      : intl.ACCIDENTAL_DISMEMBERMENT;
  }
  return reason;
};

const getRelationshipIntl = (relationship, intl) => {
  const relationships = {
    [CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  return relationships[relationship];
};

export const getCardholderRelationship = (affectedPerson, intl) =>
  affectedPerson && affectedPerson.relationship
    ? getRelationshipIntl(affectedPerson.relationship, intl)
    : '-';

export const getAffectedPersonName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.first_name
    ? affectedPerson.person.first_name
    : '-';

export const getAffectedPersonLastName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.last_name
    ? affectedPerson.person.last_name
    : '-';

export const getAffectedPersonComment = affectedPerson =>
  (affectedPerson && affectedPerson.comment) || '';

export const getAffectedPersonOccupation = affectedPerson =>
  (affectedPerson &&
    affectedPerson.professional_situation &&
    affectedPerson.professional_situation.occupation) ||
  '-';

export const getAffectedPersonEmail = affectedPerson =>
  (affectedPerson && affectedPerson.email) || '-';

export const getAffectedPersonNationality = (affectedPerson, idiom) => {
  const nationality =
    affectedPerson &&
    affectedPerson.person &&
    affectedPerson.person.nationalities
      ? affectedPerson.person.nationalities[0]
      : '';
  return getNationality(nationality, idiom);
};

export const getAffectedPersonRegistration = (affectedPerson, idiom) => {
  const registration =
    (affectedPerson &&
      affectedPerson.person_registration &&
      affectedPerson.person_registration[0]) ||
    {};
  const value = registration.value ? registration.value : '-';
  return idiom === pt_BR && registration !== '-' ? formatCPF(value) : value;
};

export const getAffectedPersonCountry = (affectedPerson, idiom) => {
  const country =
    affectedPerson && affectedPerson.address && affectedPerson.address.country;
  return country ? getCountryByInitials(country, idiom) : '-';
};

export const getAffectedPersonPostalCode = affectedPerson => {
  const country =
    affectedPerson && affectedPerson.address && affectedPerson.address.country;

  const postalCode =
    (affectedPerson &&
      affectedPerson.address &&
      affectedPerson.address.postal_code) ||
    '-';
  return country === 'BR' && postalCode !== '-'
    ? formatPostalCodeBR(postalCode)
    : postalCode;
};

export const getAffectedPersonState = affectedPerson =>
  (affectedPerson && affectedPerson.address && affectedPerson.address.state) ||
  '-';

export const getAffectedPersonCity = affectedPerson =>
  (affectedPerson &&
    affectedPerson.address &&
    affectedPerson.address.locality) ||
  '-';

export const getAffectedPersonStreet = affectedPerson =>
  (affectedPerson &&
    affectedPerson.address &&
    affectedPerson.address.street_address) ||
  '-';

export const getAffectedPersonSubdivision = affectedPerson =>
  (affectedPerson &&
    affectedPerson.address &&
    affectedPerson.address.subdivision) ||
  '-';

export const getAffectedPersonAddress = (affectedPerson, idiom) => {
  const street = getAffectedPersonStreet(affectedPerson);
  const subdivision = getAffectedPersonSubdivision(affectedPerson);
  const postalCode = getAffectedPersonPostalCode(affectedPerson);

  const addressHierarchy = [];
  const city = getAffectedPersonCity(affectedPerson);
  if (city !== '-') addressHierarchy.push(city);
  const state = getAffectedPersonState(affectedPerson);
  if (state !== '-') addressHierarchy.push(state);
  const country = getAffectedPersonCountry(affectedPerson, idiom);
  if (country !== '-') addressHierarchy.push(country);

  return `
  ${street !== '-' ? `${street}  <br/>` : ''}
  ${subdivision !== '-' ? `${subdivision}  <br/>` : ''}
  ${city !== '-' ? `${city} -` : ''}
  ${addressHierarchy.join(' - ')}
  ${postalCode !== '-' ? ` (${postalCode})` : ''}`;
};

export const getAffectedPersonPhone = (affectedPerson, intl) => {
  const phone =
    affectedPerson && affectedPerson.phones && affectedPerson.phones[0];
  return formatPhone(phone, intl);
};

export const getAffectedPersonMaritalStatus = (affectedPerson, intl) => {
  const status =
    affectedPerson &&
    affectedPerson.family_situation &&
    affectedPerson.family_situation.marital_status;

  let statusIntl = '-';
  if (status === 'SINGLE') statusIntl = intl.VALUE_STATUS_SINGLE;
  else if (status === 'MARRIED') statusIntl = intl.VALUE_STATUS_MARRIED;

  return statusIntl;
};

export const getAffectedPersonLivedTogetherSince = (
  affectedPerson,
  idiom,
  country
) => {
  const date =
    affectedPerson &&
    affectedPerson.family_situation &&
    affectedPerson.family_situation.lived_in_couple_since;
  return date
    ? formatCustomDate(date, LIVED_TOGETHER_SINCE_DATE_FORMAT, idiom, country)
    : '-';
};

export const getCardStatus = (cardInfo, intl) => {
  let statusIntl = '-';
  if (cardInfo.card_status === 'ACTIVE') statusIntl = intl.CARD_STATUS_ACTIVE;
  else if (cardInfo.card_status === 'INACTIVE')
    statusIntl = intl.CARD_STATUS_INACTIVE;

  return statusIntl;
};

export const getCardOpeningDate = (cardInfo, idiom) =>
  cardInfo.activation_date
    ? formatCustomDate(cardInfo.activation_date, CARD_DATE_FORMAT, idiom)
    : '-';

export const getCardExpirationDate = (cardInfo, idiom) =>
  cardInfo.expiration_date
    ? formatCustomDate(cardInfo.expiration_date, CARD_DATE_FORMAT, idiom)
    : '-';

export const getIncidentDate = (incident, idiom, country) =>
  incident.occured_at
    ? formatCustomDate(incident.occured_at, CARD_DATE_FORMAT, idiom, country)
    : '-';

export const getIncidentDescription = incident => incident.description || '-';

export const getIncidentLocation = (incident, idiom, excludeStreet = false) => {
  const location = incident.location ? incident.location : {};

  const placesHierarchy = [];
  if (location.country)
    placesHierarchy.push(getCountryByInitials(location.country, idiom));
  if (location.state) placesHierarchy.push(location.state);
  if (location.locality) placesHierarchy.push(location.locality);
  if (!excludeStreet && location.street_address)
    placesHierarchy.push(location.street_address);

  return placesHierarchy.length > 0 ? placesHierarchy.join(' - ') : '-';
};

export const getInvolvedPersonType = (person, intl) => {
  const type = person.type || '-';

  const types = {
    WITNESS: intl.WITNESS,
    DOCTOR: intl.DOCTOR,
    KNOWN_DOCTOR: intl.KNOW_DOCTOR,
  };

  return type !== '-' ? types[type] : type;
};

export const getInvolvedPersonPhone = (person, intl) => {
  const phone =
    person && person.phone && Object.keys(person.phone).length > 0
      ? person.phone
      : null;
  return formatPhone(phone, intl);
};

export const getHasInvolvedPeople = (people, intl) => {
  const length = (people && people.length) || -1;

  let hasInvolvedPeopleIntl = '-';
  if (length === 0) hasInvolvedPeopleIntl = intl.TEXT_NO;
  else if (length > 0) hasInvolvedPeopleIntl = intl.TEXT_YES(length);

  return hasInvolvedPeopleIntl;
};
