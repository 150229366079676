import React, { useContext } from 'react';
import { useState } from 'react';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import styles from './SearchCertificates.module.scss';
import CertificatesArea from './CertificatesArea/CertificatesArea';
import FormSearchCertificates from './FormSearchCertificates/FormSearchCertificates';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import StepClaim from '../../StepClaim/StepClaim';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import Button from '../../../../componentsV2/Button/Button';
import RadioButton from '../../../../components/RadioButton/RadioButton';
import { stringToBoolean } from '../../../../utils/stringUtils';
import { useEffect } from 'react';

const SearchCertificates = ({
  claimType,
  setCertificate,
  incidentDateState,
  setStep,
}) => {
  const { utils } = useContext(StateContext);

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const [incidentDate] = incidentDateState;
  const [selectCertificate, setSelectCertificate] = useState(true);

  const country = utils.getCountry();
  const isMexico = country === 'MEXICO';
  const isIEMS = claimType === 'EMC_Emergency_Medical_Assistance';
  const isCertificateOptional = isMexico && isIEMS;

  function setCertificateAndGoToForm(certificate) {
    setCertificate(certificate);
    setStep('FORM');
  }

  useEffect(() => {
    setCertificate(false);
  }, [setCertificate]);

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <StepClaim
        currentStep={2}
        stepNames={[
          intl.TEXT_STEP_1,
          intl.TEXT_STEP_2,
          intl.TEXT_STEP_3,
          intl.TEXT_STEP_4,
        ]}
      />
      <ShadowBox>
        <div id="mainContent" className={styles.titleContainer}>
          <h2 className={styles[theme]}>{intl.CERTIFICATE_SELECTION}</h2>
        </div>
        <div className={styles.subtitleContainer}>
          <h4 className={styles[theme]}>
            {isCertificateOptional
              ? intl.OPTIONAL_SELECT_CERTIFICATE
              : intl.REQUIRED_VALID_CERTIFICATE}
          </h4>
        </div>
        {isCertificateOptional ? (
          <>
            <div className="row">
              <div className={`col-lg-12 col-sm-12`}>
                <label className={`${styles.labelTitle} ${styles[theme]}`}>
                  {intl.LABEL_OPTIONAL_CERTIFICATE}
                </label>
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_OPTIONAL_CERTIFICATE}
                  defaultChecked={true}
                  text={intl.TEXT_YES}
                  name="selectCertificate"
                  value={true}
                  onChange={e => {
                    setSelectCertificate(e.target.value);
                  }}
                />
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_OPTIONAL_CERTIFICATE}
                  defaultChecked={false}
                  text={intl.TEXT_NO}
                  name="selectCertificate"
                  value={false}
                  onChange={e => {
                    setSelectCertificate(e.target.value);
                  }}
                />
              </div>
            </div>
            {stringToBoolean(selectCertificate) && (
              <>
                <FormSearchCertificates incidentDateState={incidentDateState} />
                {incidentDate && (
                  <CertificatesArea
                    claimType={claimType}
                    setCertificate={setCertificateAndGoToForm}
                    incidentDate={incidentDate}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <FormSearchCertificates incidentDateState={incidentDateState} />
            {incidentDate && (
              <CertificatesArea
                claimType={claimType}
                setCertificate={setCertificateAndGoToForm}
                incidentDate={incidentDate}
              />
            )}
          </>
        )}
        <div className={`${styles.footer} ${styles[theme]}`}>
          <div data-testid="btnBack" className={styles.divButton}>
            <Button
              type="Secondary"
              onClick={() => setStep('TERMS')}
              label={intl.BTN_BACK}
            />
          </div>
          {!stringToBoolean(selectCertificate) && (
            <div data-testid="btnNext" className={styles.divButton}>
              <Button
                type="Primary"
                onClick={() => setStep('FORM')}
                label={intl.BTN_NEXT_STEP}
              />
            </div>
          )}
        </div>
      </ShadowBox>
    </div>
  );
};

export default SearchCertificates;
