import { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { formatCustomDate } from '../../../../../../utils/date';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const PeriodArea = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : {};

  const from =
    travelInformation && travelInformation.from
      ? formatCustomDate(travelInformation.from, 'DD/MM/YYYY', idiom, country)
      : '-';

  const to =
    travelInformation && travelInformation.to
      ? formatCustomDate(travelInformation.to, 'DD/MM/YYYY', idiom, country)
      : '-';
  return (
    <>
      <div className="col-12" data-testid="periodAreaDataOfTrip">
        <GroupDivisor title={intl.TEXT_DATE_OF_TRIP} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_START_DATE} value={from} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_END_DATE} value={to} />
      </div>
    </>
  );
};

export default PeriodArea;
