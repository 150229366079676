import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_COUNTRY = {
  en_US: 'Country of incident *',
  pt_BR: 'País do incidente *',
  es_ES: 'País del incidente *',
};

export const LABEL_INCIDENT_STATE = {
  en_US: 'State of incident *',
  pt_BR: 'Estado do incidente *',
  es_ES: 'Estado del incidente *',
};

export const LABEL_INCIDENT_CITY = {
  en_US: 'City of incident *',
  pt_BR: 'Cidade do incidente *',
  es_ES: 'Ciudad incidente *',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Place of incident *',
  pt_BR: 'Local do incidente *',
  es_ES: 'Lugar del incidente *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const DISCLAIMER_INVOICE = {
  en_US: '',
  pt_BR:
    'Por favor, esteja ciente de que somente estarão cobertos os produtos cujo valor de compra seja igual ou superior a R$150,00 (cento e cinquenta reais). Por favor, leia os termos e condições para mais informações.',
  es_ES: '',
};

export const RULE_PRICE_BY_CURRENCY = {
  en_US:
    'Please be aware that your claim may be ineligible because only items whose original purchase price is USD25 or more will be covered. Please read the terms and conditions for more information.',
  pt_BR:
    'Por favor, esteja ciente de que somente serão cobertos os itens cujo preço original de compra seja igual ou superior a R$ 150,00 (cento e cinquenta reais) ou USD 25,00 (vinte e cinco dólares). Por favor, leia os termos e condições para mais informações.',
  es_ES:
    'Tenga en cuenta que solo estarán cubiertos los artículos cuyo precio de compra original sea de USD25 (veinticinco dólares) o más. Por favor, lee los términos y condiciones para obtener más información.',
};

export const ITEM_PURCHASED = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const NAME_STORE_WEBSITE = {
  en_US: 'Name of store/website advertising the product *',
  pt_BR: 'Nome da loja/website que anuncia o produto *',
  es_ES: 'Nombre de la tienda que anuncia el producto *',
};

export const ADVERTISEMENT_LOCATION = {
  en_US:
    'Advertisement location (i.e. newspaper, online) and publication name *',
  pt_BR:
    'Localização do anúncio (por exemplo, loja ou website) e nome da publicação *',
  es_ES:
    'Ubicación del anuncio (es decir, periódico, en línea) y nombre de la publicación *',
};

export const AMOUNT_INVOICE = {
  en_US: 'Product value on the sales receipt *',
  pt_BR: 'Valor do produto na nota fiscal *',
  es_ES: 'Valor del producto en la factura de compra *',
};

export const NEW_ADVERTISE_PRICE = {
  en_US: 'New advertise price *',
  pt_BR: 'Novo preço anunciado *',
  es_ES: 'Nuevo precio anunciado *',
};

export const PAYMENT_METHOD = {
  en_US: 'Product payment method *',
  pt_BR: 'Método de pagamento do produto *',
  es_ES: 'Forma de pago del producto *',
};

export const METHOD_FINANCING = {
  en_US: 'Installments',
  pt_BR: 'Parcelado',
  es_ES: 'En cuotas',
};

export const METHOD_CASH = {
  en_US: 'Single payment',
  pt_BR: 'Parcela única',
  es_ES: 'En un pago',
};

export const INSTALLMENTS_NUMBER = {
  en_US: 'Purchase installments number *',
  pt_BR: 'Número de parcelas da compra *',
  es_ES: 'Número de cuotas de compra *',
};

export const SIXTEEN_OR_MORE_INSTALLMENTS = {
  en_US: '16 or more',
  pt_BR: '16 ou mais',
  es_ES: '16 o más',
};

export const INSTALLMENTS_PRICE = {
  en_US: 'Installment/financing amount *',
  pt_BR: 'Valor da prestação/financiamento *',
  es_ES: 'Monto a plazos/financiamiento *',
};

export const RETAILER_NAME = {
  en_US: 'Store name that appears on the card statement *',
  pt_BR: 'Nome da loja que aparece no extrato do cartão *',
  es_ES: 'Nombre de la tienda que aparece en el extracto de la tarjeta *',
};

export const PRODUCTS_ELEGIBILITY = {
  en_US:
    'Please, be aware that the Item you selected it is not covered under Price Protection Benefits. Perfumes, Airline Tickets, beverages and other are no eligible. Please, read terms and conditions for more information.',
  pt_BR:
    'Por favor, esteja ciente de que perfumes, passagens aéreas, bebidas e outros produtos não são elegíveis para o Benefício de Proteção de Preço. Por favor, leia os termos e condições para mais informações.',
  es_ES:
    'Por favor, tenga en cuenta que el artículo que ha seleccionado no está cubierto por el Beneficios de Protección de Precios. Perfumes, boletos de avión, bebidas y otros no son elegibles. Lee los términos y condiciones para obtener más información.',
};

export const VISA_INVOICE_AMOUNT = {
  en_US: 'Purchase value on the Visa invoice *',
  pt_BR: 'Valor da compra na fatura Visa *',
  es_ES: 'Valor de compra en el estado de cuenta *',
};
