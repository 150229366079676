/* eslint-disable no-unused-vars */
import moment from 'moment';
import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';
export const baggageDelayFormInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let affectedPerson = completeClaim && completeClaim.affected_person;
  let incident = completeClaim && completeClaim.incident;
  let travelInformation = completeClaim && completeClaim.travel_information;
  let otherInsuranceCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[0]
      : [{}];
  let otherCompanyCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[1]
      : [{}];
  let formalComplaint = completeClaim && completeClaim.formal_complaint;
  let totalAmountClaimed = completeClaim && completeClaim.total_amount_claimed;

  const getAffectedPerson = affectedPerson => {
    return {
      person: getPerson(person),
      relationship: 'Cardholder',
      relationshipCardholder: true,
    };
  };

  const getPerson = person => {
    return {
      first_name: person && person.first_name ? person.first_name : '',
      last_name: person && person.last_name ? person.last_name : '',
    };
  };
  const getWasBaggageReceived = incident => {
    if (incident && incident.baggage_arrived_at === null) {
      return 'false';
    } else return 'true';
  };

  const getIncident = incident => {
    let discoveredAt =
      incident && incident.discovered_at
        ? incident.discovered_at.split(' ')
        : '';
    let baggageArrivedAt =
      incident && incident.baggage_arrived_at
        ? incident.baggage_arrived_at.split(' ')
        : '';

    return {
      wasBaggageReceived: getWasBaggageReceived(incident),
      description: incident && incident.description ? incident.description : '',
      kind: incident && incident.kind ? incident.kind : 'DELAY',
      discovered_at: discoveredAt ? new Date(moment(discoveredAt[0])) : '',
      hour_discovered_at: discoveredAt ? discoveredAt[1].substring(0, 5) : '',
      baggage_arrived_at: baggageArrivedAt
        ? new Date(moment(baggageArrivedAt[0]))
        : '',
      hour_baggage_arrived_at: baggageArrivedAt
        ? baggageArrivedAt[1].substring(0, 5)
        : '',
    };
  };
  const getTravelInfo = travelInformation => {
    return {
      is_full_charged_on_card:
        travelInformation && travelInformation.is_full_charged_on_card
          ? String(travelInformation.is_full_charged_on_card)
          : '',
      from_date:
        travelInformation && travelInformation.from_date
          ? new Date(moment(travelInformation.from_date))
          : '',
      to_date:
        travelInformation && travelInformation.to_date
          ? new Date(moment(travelInformation.to_date))
          : '',
      travel_agency: getTravelAgency(travelInformation),
    };
  };
  const getTravelAgency = travelInformation => {
    const agency =
      travelInformation && travelInformation.travel_agency
        ? travelInformation.travel_agency
        : {};
    return {
      name: agency && agency.name ? agency.name : '',
      phone: getPhone(travelInformation),
    };
  };

  const getPhone = travelInformation => {
    const phone =
      travelInformation && travelInformation.travel_agency.phone
        ? travelInformation.travel_agency.phone
        : {};
    return {
      phone_type:
        phone && phone.phone_type ? phone.phone_type : PHONE_TYPE_MOBILE,
      international_prefix:
        phone && phone.international_prefix ? phone.international_prefix : '',
      number: phone && phone.number ? phone.number : '',
    };
  };

  const getFormalComplaint = formalComplaint => {
    return {
      is_submitted:
        formalComplaint && formalComplaint.is_submitted !== undefined
          ? String(formalComplaint.is_submitted)
          : '',
      submission_reason:
        formalComplaint && formalComplaint.submission_reason
          ? formalComplaint.submission_reason
          : '',
    };
  };

  const getOtherInsuranceCovering = otherInsuranceCovering => {
    return {
      is_internal:
        otherInsuranceCovering && otherInsuranceCovering.is_internal
          ? otherInsuranceCovering.is_internal
          : false,
      checked:
        otherInsuranceCovering &&
        otherInsuranceCovering.is_internal !== undefined
          ? String(!otherInsuranceCovering.is_internal)
          : 'false',
      description:
        otherInsuranceCovering && otherInsuranceCovering.description
          ? otherInsuranceCovering.description
          : '',
      value:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.value
          ? otherInsuranceCovering.amount.value
          : '',
      currency:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.currency
          ? otherInsuranceCovering.amount.currency
          : 'USD',
    };
  };

  const getOtherInsuranceCovering2 = otherCompanyCovering => {
    return {
      is_internal:
        otherCompanyCovering && otherCompanyCovering.is_internal
          ? otherCompanyCovering.is_internal
          : false,
      checked:
        otherCompanyCovering && otherCompanyCovering.is_internal !== undefined
          ? String(!otherCompanyCovering.is_internal)
          : 'false',
      description:
        otherCompanyCovering && otherCompanyCovering.description
          ? otherCompanyCovering.description
          : '',
      value:
        otherCompanyCovering &&
        otherCompanyCovering.amount &&
        otherCompanyCovering.amount.value
          ? otherCompanyCovering.amount.value
          : '',
      currency:
        otherCompanyCovering &&
        otherCompanyCovering.amount &&
        otherCompanyCovering.amount.currency
          ? otherCompanyCovering.amount.currency
          : 'USD',
    };
  };

  const getAdditional = completeClaim => {
    if (completeClaim && completeClaim.additional_passengers)
      return completeClaim.additional_passengers.map(additional => ({
        first_name: additional.first_name,
        last_name: additional.last_name,
        birth_date: new Date(moment(additional.birth_date, 'YYYY-MM-DD')),
        gender: additional.gender,
        nationalities: additional.nationalities[0],
        relationship: additional.relationship,
        comment: additional.comment === null ? '' : additional.comment,
      }));
    else
      return [
        {
          first_name: '',
          last_name: '',
          birth_date: '',
          gender: '',
          nationalities: '',
          relationship: '',
          comment: '',
        },
      ];
  };
  const getHasAdditional = completeClaim => {
    return completeClaim && completeClaim.additional_passengers
      ? 'true'
      : 'false';
  };

  const getPrice = price => ({
    value: price && price.value ? price.value : '',
    currency: price && price.currency ? price.currency : 'USD',
  });

  return {
    travel_information: getTravelInfo(travelInformation),
    incident: getIncident(incident),
    affected_person: getAffectedPerson(affectedPerson),
    formal_complaint: getFormalComplaint(formalComplaint),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    other_company_covering: getOtherInsuranceCovering2(otherCompanyCovering),
    hasAdditional: getHasAdditional(completeClaim),
    additional_passengers: getAdditional(completeClaim),
    total_amount_claimed: getPrice(totalAmountClaimed),
  };
};
