/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useMemo } from 'react';

import { IntlContext } from '../../intl';
import CertificateCategory from './CertificateCategory/CertificateCategory';
import {
  certificatesServices,
  productsAndBenefitsServices,
} from '../../services';
import styles from './PrivateCertificatesNew.module.scss';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import {
  travel_insurance,
  certificate_seguro_compra,
  billete_auto_rental_insurance,
  certificate_extended_warranty,
} from '../../utils/certificates_name';

import autoRentalSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-auto-afluent.svg';
import autoRentalSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-auto-master.svg';
import seguroCompraSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-compra-afluent.svg';
import seguroCompraSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-compra-master.svg';

import garantiaExtendidaSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-garantia-master.svg';
import garantiaExtendidaSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-garantia-afluent.svg';
import travelCertificateSvgMaster from '../../assets/images/certificates/Visa_Icons_certificate-viaje-master.svg';
import travelCertificateSvgAfluent from '../../assets/images/certificates/Visa_Icons_certificate-viaje-afluent.svg';

import * as translations from './intl';
import ListCardBenefits from '../ListCardBenefits/ListCardBenefits';
import Loading from '../Loading/Loading';
import { getUrlPdf } from '../../utils/certificate';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';

function handleTypeCertificate(certificateType, intl) {
  switch (certificateType.toLowerCase()) {
    case travel_insurance:
    case billete_auto_rental_insurance:
      return intl.TEXT_TRAVEL;

    case certificate_seguro_compra:
    case certificate_extended_warranty:
      return intl.TEXT_SHOPPING;
  }
}

function handleMakeInfo(certificate, intl, themes) {
  let proccessSusep = '';
  let followingInfo = '';
  let personalInfo = '';

  switch (certificate.code.toLowerCase()) {
    case travel_insurance:
      proccessSusep = intl.TEXT_TRAVEL_INSURANCE_PROCESS_SUSEP;
      followingInfo = intl.TEXT_TRAVEL_INSURANCE_FOLLOWING_INFO;
      personalInfo = intl.TEXT_TRAVEL_INSURANCE_PERSONAL_INFO;
      break;
    case certificate_extended_warranty:
      proccessSusep = intl.TEXT_CERTIFICATE_EXTENDED_WARRANTY_PROCESS_SUSEP;
      followingInfo = intl.TEXT_CERTIFICATE_EXTENDED_WARRANTY_FOLLOWING_INFO;
      personalInfo = intl.TEXT_CERTIFICATE_EXTENDED_WARRANTY_PERSONAL_INFO;
      break;
    case billete_auto_rental_insurance:
      proccessSusep =
        intl.TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_PROCESS_SUSEP;
      followingInfo =
        intl.TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_FOLLOWING_INFO;
      personalInfo =
        intl.TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_PERSONAL_INFO;
      break;
    case certificate_seguro_compra:
      proccessSusep = intl.TEXT_CERTIFICATE_SEGURO_COMPRA_PROCESS_SUSEP;
      followingInfo = intl.TEXT_CERTIFICATE_SEGURO_COMPRA_FOLLOWING_INFO;
      personalInfo = intl.TEXT_CERTIFICATE_SEGURO_COMPRA_PERSONAL_INFO;
      break;
  }

  return (
    <>
      <div className={styles.containerSusep}>
        {proccessSusep.split('#').map((item, index) => (
          <h4 key={index} className={styles.contentSusep}>
            {item}
          </h4>
        ))}
      </div>
      <ul>
        {certificate.covered_benefits.map((item, index) => (
          <li className={styles[themes]} key={index}>
            {item}
          </li>
        ))}
      </ul>

      {followingInfo && (
        <h5 className={styles.contentFollowinfInfo}>{followingInfo}</h5>
      )}
      <ul>
        {personalInfo.split('-').map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </>
  );
}

const getClientTypeProduct = async (bin, idiom) => {
  try {
    const { client_type } = (
      await productsAndBenefitsServices.getProductDetailByBinCode(bin, idiom)
    ).data;
    return client_type;
  } catch (error) {}
};

const PrivateCertificatesNew = () => {
  const { utils } = useContext(StateContext);
  const { idiomForApi, translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const [filterSelected, setFilterSelected] = useState('all');
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const binOrPan = utils.getBin(true);

  const certificateImages = {
    billete_auto_rental_insurance: isAfluent()
      ? autoRentalSvgAfluent
      : autoRentalSvgMaster,
    certificate_extended_warranty: isAfluent()
      ? garantiaExtendidaSvgAfluent
      : garantiaExtendidaSvgMaster,
    certificate_seguro_compra: isAfluent()
      ? seguroCompraSvgAfluent
      : seguroCompraSvgMaster,
    travel_insurance: isAfluent()
      ? travelCertificateSvgAfluent
      : travelCertificateSvgMaster,
  };

  const certificatesByCategory = useMemo(
    () => ({
      all: certificates,
      travel: certificates.filter(
        item =>
          item.code.toLowerCase() === travel_insurance ||
          item.code.toLowerCase() === billete_auto_rental_insurance
      ),
      shopping: certificates.filter(
        item =>
          item.code.toLowerCase() === certificate_seguro_compra ||
          item.code.toLowerCase() === certificate_extended_warranty
      ),
    }),
    [certificates]
  );

  useEffect(() => {
    const getGenerated = async () => {
      try {
        trackEventUserAction(
          '#### (CERTIFICATES LIST) CHAMANDO REQUEST DE CERTIFICATES BY BIN NUMBER',
          {}
        );
        const userCertificates =
          await certificatesServices.listCertificateTypesByBinNumber(
            binOrPan,
            idiomForApi()
          );

        trackEventUserAction(
          '#### (CERTIFICATES LIST) CARREGOU A LISTA DE CERTIFICADOS',
          {
            data:
              userCertificates && userCertificates.data
                ? userCertificates.data
                : '',
          }
        );
        let clientType;
        if (idiom === 'pt_BR') {
          clientType = await getClientTypeProduct(binOrPan, idiomForApi());
        }

        const listBenefit = userCertificates.data.map(certificate => {
          return {
            ...certificate,
            category: handleTypeCertificate(certificate.code, intl),
            image: certificateImages[certificate.code],
            description: handleMakeInfo(certificate, intl, themes),
            urlPDF:
              idiom === 'pt_BR' ? getUrlPdf(certificate.code, clientType) : '',
          };
        });

        trackEventUserAction(
          '#### (CERTIFICATES LIST) FORMATOU OS CERTIFICADOS',
          listBenefit
        );

        setCertificates(listBenefit);
        setLoading(false);
      } catch (error) {
        trackEventUserAction(
          '#### (CERTIFICATES LIST) ERROR - NÃO FOI POSSÍVEL CARREGAR A LISTA DE CERTIFICADOS',
          {
            error,
            error_response: error && error.response ? error.response : '',
          }
        );
      }
    };
    getGenerated();
  }, [binOrPan]);

  return (
    <div id="mainContent" className={styles.container}>
      {loading ? (
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <Loading />
        </div>
      ) : (
        <>
          <CertificateCategory
            filterSelected={filterSelected}
            onClickFilter={filterSelected => setFilterSelected(filterSelected)}
            enabledCategories={{
              all: true,
              shopping: certificatesByCategory.shopping.length > 0,
              travel: certificatesByCategory.travel.length > 0,
            }}
          />
          {certificatesByCategory[filterSelected].length > 0 ? (
            <div className="row">
              <ListCardBenefits
                benefitToSplit={certificatesByCategory[filterSelected]}
                benefitList={certificates}
                type={'certificate'}
              />
            </div>
          ) : (
            <div className={styles.center}>
              <h2 className={`${styles.emptyMessage} ${styles[themes]}`}>
                {intl.NO_CERTIFICATE_TYPE_AVAILABLE}
              </h2>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PrivateCertificatesNew;
