import React from 'react';
import PersonalArea from './PersonalArea/PersonalArea';
import DoctorArea from './DoctorArea/DoctorArea';
import MedicalArea from './MedicalArea/MedicalArea';
import ExpenseArea from './ExpenseArea/ExpenseArea';
import OtherInsuranceArea from './OtherInsuranceArea/OtherInsuranceArea';
import ReimbursedArea from './ReimbursedArea/ReimbursedArea';

const Iems = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <DoctorArea claimDetails={claimDetails} />
      <MedicalArea claimDetails={claimDetails} />
      <ExpenseArea claimDetails={claimDetails} />
      <OtherInsuranceArea claimDetails={claimDetails} />
      <ReimbursedArea claimDetails={claimDetails} />
    </div>
  );
};

export default Iems;
