export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TEXT_APPEAL_CLAIM = {
  en_US: 'Appeal a claim',
  es_ES: 'Apelar un reclamo',
  pt_BR: 'Apelar um sinistro',
};
