import React, { useContext } from 'react';

import { IntlContext } from '../../intl/index';
import * as translations from './intl';

const LoadingError = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { color = '#fff', message = intl.UNEXPECTED_ERROR_OCCURRED } = props;

  return <h4 style={{ fontSize: '24px', color }}>{message}</h4>;
};

export default LoadingError;
