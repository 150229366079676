import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../../../intl/idioms';
import { formatCustomDate } from '../../../../../../utils/date';

const PeriodBaggageLossArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date).format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : {};

  const fromDate =
    travelInformation && travelInformation.from_date
      ? formatCustomDate(travelInformation.from_date, null, idiom, country)
      : '-';

  const toDate =
    travelInformation && travelInformation.to_date
      ? formatCustomDate(travelInformation.to_date, null, idiom, country)
      : '-';

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const discoveredAt =
    incident && incident.discovered_at
      ? formatDateAndHour(incident.discovered_at)
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_START_DATE_TRIP} value={fromDate} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_END_DATE_TRIP} value={toDate} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_DATE_LOSS} value={discoveredAt} />
      </div>
    </>
  );
};

export default PeriodBaggageLossArea;
