import React, { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import * as CorporateLiabilityUtils from '../../../../utils/claimsAPI/CorporateLiabilityUtils';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const CorporateLiabilityWaiverDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const [financialInfo, setFinancialInfo] = useState({});
  const [incident, setIncident] = useState({});

  useEffect(() => {
    const financialInfo =
      (claimDetails && claimDetails.financial_information) || {};
    setFinancialInfo(financialInfo);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);
  }, [claimDetails]);

  const formattedQuestionDate = (field, formattedDate) =>
    field
      ? `${intl.YES}${formattedDate !== '-' && ` (${formattedDate})`}`
      : intl.NO;

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_COMPANY_ACCOUNTS}
          value={CorporateLiabilityUtils.getValidAccountsNumber(financialInfo)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_CARDHOLDER_CREDIT_LIMIT}
          value={CorporateLiabilityUtils.getCreditLimit(
            financialInfo,
            idiomForApi()
          )}
        />
      </div>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_IS_PAYMENT_LAST_12_MONTHS}
          value={CorporateLiabilityUtils.getHadPaymentLast12Months(
            financialInfo,
            intl
          )}
        />
      </div>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_IS_RESULT_OF_STOLEN_CARD}
          value={formattedQuestionDate(
            incident.date_of_loss_or_stealth,
            CorporateLiabilityUtils.getDateOfLoss(incident, idiom)
          )}
        />
      </div>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_IS_RESULT_OF_BANKRUPTCY}
          value={formattedQuestionDate(
            incident.bankruptcy_declaration_date,
            CorporateLiabilityUtils.getDateOfBankrupcy(incident, idiom)
          )}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency} `}
          />
        </div>
      )}
    </div>
  );
};

export default CorporateLiabilityWaiverDetail;
