import { useEffect, useState, useContext } from 'react';
import { useIntl } from '../../../../intl';
import { useTheme } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './YourVoiceID.module.scss';
import * as translations from './intl';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import Loading from '../../../../components/Loading/Loading';
import { userServices } from '../../../../services';

const YourVoiceID = () => {
  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(true);
  const { getGlobalTheme } = useTheme();
  const { translate } = useIntl();
  const { utils } = useContext(StateContext);

  const cn = utils.getCn();
  const themes = getGlobalTheme();
  const intl = translate(translations);

  const usesOfVoiceID = [intl.EXAMPLE_OF_USAGE_1];

  const loadContactDetails = async () => {
    try {
      const { data } = await userServices.getCHInfo(cn);
      setCustomer(data);
      setLoading(false);
    } catch (error) {
      setCustomer(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    loadContactDetails();
  }, []);

  if (loading) {
    return <Loading />;
  } else if (!customer.external_voice_profile_id) {
    return <></>;
  }

  return (
    <div id="voiceID">
      <TitleSectionProfile
        title={intl.TITLE_YOUR_VOICE}
        subtitle={intl.VOICE_ID_CREATED}
      />

      <ul className={styles.listOfUsages}>
        {usesOfVoiceID.map(usage => (
          <li className={`${styles.useCase} ${styles[themes]}`} key={usage}>
            {usage}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default YourVoiceID;
