import { en_currency } from '../../../../../../../../intl/currency/en_currency';
import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';

export const SUBTITLE_AREA = {
  en_US: 'Concerned item',
  pt_BR: 'Item reclamado',
  es_ES: 'Artículo reclamado',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Description',
  pt_BR: 'Descrição',
  es_ES: 'Descripción',
};

export const LABEL_VALUE = {
  en_US: 'Amount',
  pt_BR: 'Valor',
  es_ES: 'Importe',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const BTN_ADD_ADDITIONAL = {
  en_US: 'Add item',
  pt_BR: 'Adicionar item',
  es_ES: 'Añadir artículo',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TOTAL_SUM = {
  en_US: 'Total amount of claim',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};

export const LABEL_TOTAL_REIMBURSED = {
  en_US: 'Total amount to be reimbursed *',
  pt_BR: 'Total a ser reembolsado *',
  es_ES: 'Importe total a reembolsar *',
};

export const TOOLTIP_TOTAL_REIMBURSED = {
  en_US: 'Amount susceptible to analysis.',
  es_ES: 'Importe susceptible de análisis.',
  pt_BR: 'Valor suscetível a análise.',
};
