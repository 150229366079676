import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const ThirdPartyPhone = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <PhoneForm
      formikProps={props}
      title={intl.TEXT_THIRD_PARTY_PHONE}
      inputNames={{
        phoneType: 'incident.involved_person.phone.phone_type',
        countryCode: 'incident.involved_person.phone.international_prefix',
        phoneNumber: 'incident.involved_person.phone.number',
      }}
      testIds={{
        phoneType: 'phoneType',
        countryCode: 'InputClaiCHPrefix',
        phoneNumber: 'inputThirdPartyPhoneNumber',
      }}
    />
  );
};

export default ThirdPartyPhone;
