export const NPS_LINK = {
  en_US: 'https://sat.eu.qualtrics.com/jfe/form/SV_03tbSL5lUtr60B0',
  pt_BR: 'https://sat.eu.qualtrics.com/jfe/form/SV_dciIbn31IG38qQS',
  es_ES: 'https://sat.eu.qualtrics.com/jfe/form/SV_6mzB9dOR2Br7MAm',
};

export const FEEDBACK_TEXT = {
  en_US: 'Give your opinion',
  pt_BR: 'Dê sua opinião',
  es_ES: 'Danos tu opinión',
};
