import * as Yup from 'yup';
import { TravelInformationCertificate } from '../../../../@types/APIs/Certificate';

export interface LegInfo {
  isValid: boolean;
  airline: string;
  flight_number: string;
  flight_date: Date;
  scheduled_in: string;
  scheduled_out: string;
  country_origin: string;
  city_origin: string;
  airport_origin: string;
  airport_origin_name: string;
  airport_origin_timezone: string;
  country_destination: string;
  city_destination: string;
  airport_destination: string;
  airport_destination_name: string;
  airport_destination_timezone: string;
}

export interface OneWayFlightSchema {
  booking_company_name?: string;
  booking_reference?: string;
  segments?: LegInfo[];
}

export interface FlightDetailsSchema {
  outbound: OneWayFlightSchema;
  return?: OneWayFlightSchema;
}

const segmentsValidation = (
  intl,
  travelInformation: TravelInformationCertificate,
  isReturn: boolean
) =>
  Yup.array()
    .of(
      Yup.object().shape({
        isValid: Yup.boolean().oneOf([true], intl.TEXT_FLIGHT_NUMBER),
        airline: Yup.string().required(intl.TEXT_FIELD_REQUIRED).max(3),
        flight_number: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(4)
          .test(
            'is-positive-number',
            intl.TEXT_ERROR_FLIGHT_NUMBER_NOT_NUMBER,
            value => Number(value) > 0
          ),
        flight_date: Yup.date()
          .typeError(intl.TEXT_INVALID_DATE)
          .required(intl.TEXT_FIELD_REQUIRED),
      })
    )
    .test({
      name: 'first-country',
      message: intl.TEXT_ERROR_ORIGIN_DONT_CORRESPOND,
      test: val => {
        const { country_origin, country_destination } = travelInformation;
        const country = isReturn ? country_destination : country_origin;
        if (!country) return true;

        return val[0].country_origin === country;
      },
    });

const tripValidation = (
  intl,
  travelInformation: TravelInformationCertificate,
  isReturn: boolean
) =>
  Yup.object().shape({
    booking_company_name: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(100, intl.MAXIMUM_CHARACTERS('100')),
    booking_reference: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(20, intl.MAXIMUM_CHARACTERS('100'))
      .min(5, intl.MINIMUM_CHARACTERS('5')),
    segments: segmentsValidation(intl, travelInformation, isReturn),
  });

export const getValidationSchema = (
  intl,
  isOneWayTravel: boolean,
  travelInformation: TravelInformationCertificate
) =>
  Yup.object().shape({
    outbound: tripValidation(intl, travelInformation, false),
    return: !isOneWayTravel && tripValidation(intl, travelInformation, true),
  });
