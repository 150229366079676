import * as React from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const NewMissedConnectionIcon = props => {
  const { getGlobalTheme } = React.useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width={29}
      height={30}
      viewBox="0 0 29 30"
      style={{
        enableBackground: 'new 0 0 29 30',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M11.6 4.9c-2 0-3.7 1.7-3.7 3.7s1.7 3.7 3.7 3.7 3.7-1.7 3.7-3.7-1.7-3.7-3.7-3.7zm0 5.2c-.9 0-1.6-.7-1.6-1.6S10.7 7 11.6 7c.9 0 1.6.7 1.6 1.6s-.7 1.5-1.6 1.5z"
        fill={config_icon.primary}
      />
      <path
        d="m12.3 19.5-.7 1.2-5.4-9.1c-1.7-2.8-.8-6.4 2-8.1.9-.6 2-.8 3-.8h.7c3.3 0 5.9 2.7 5.9 5.9 0 1.1-.3 2.1-.8 3l-.2.3 1.1 1.1 1.5-1.5c.4-1 .6-2 .6-3 0-4.4-3.6-8-8-8h-.7C9.7.6 8.3 1 7 1.8 3.2 4.1 2 9 4.3 12.8l6.3 10.6h1.8l1.4-2.3-1.5-1.6z"
        fill={config_icon.primary}
      />
      <path
        d="m20.9 16.1-1.5-1.4-1.5 1.4-1.5-1.4-1.5 1.4 1.5 1.5-1.5 1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5-1.5-1.5z"
        fill={config_icon.secondary}
      />
    </svg>
  );
};

export default NewMissedConnectionIcon;
