import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';
import { getThemeIcon } from '../../utils/configIcon';

const SuccessIcon = props => {
  const { modal_success = false, ...newProps } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  const secondaryColor = modal_success
    ? config_icon.success
    : config_icon.secondary;
  const primaryColor = modal_success
    ? config_icon.success
    : config_icon.primary;

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...newProps}
    >
      <path
        style={{
          fill: 'none',
          stroke: secondaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="m6.6 12 3.2 3.2 7.6-7.5"
      />
      <circle
        cx={12}
        cy={12}
        r={10.8}
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default SuccessIcon;
