import React, { useContext, useState, useRef, useEffect } from 'react';
import { StateContext } from '../StateContextParent/StateContextParent';
import CardItem from './CardItem/CardItem';

import Cards from '../../assets/images/cards_2.svg';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './ListCardStatic.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';

const sortCards = cards => {
  const selectedIndex = cards.selectedCardIndex;
  const endList = cards.data.filter(card => {
    const prop = card && card.policy_id ? 'policy_id' : 'external_reference';
    return card[prop] !== cards.data[selectedIndex][prop];
  });
  const listFinal = [cards.data[selectedIndex], ...endList];
  return listFinal;
};

const renderCards = (
  sortListCards,
  setOpenSubMenu,
  setSelectedCard,
  setListCards,
  listCards,
  setBinOrPan
) => {
  return sortListCards.map((card, index) => {
    return (
      <CardItem
        key={`${card.external_reference}`}
        card={card}
        setOpenSubMenu={setOpenSubMenu}
        index={index}
        setSelectedCard={setSelectedCard}
        setListCards={setListCards}
        listCards={listCards}
        setBinOrPan={setBinOrPan}
      />
    );
  });
};
const filterIsPreferredCard = (binOrPan, data) => {
  if (binOrPan.length === 16) {
    return data.filter(card => card.credit_card_token !== binOrPan);
  }
  return data.filter(card => card.external_reference !== binOrPan);
};
const removeIsPreferredCardFromList = (binOrPan, cards) => {
  const newListCards = {
    selectedCardIndex: cards.selectedCardIndex,
    data: filterIsPreferredCard(binOrPan, cards.data),
  };
  return newListCards;
};
const ListCardStatic = props => {
  const { setBinOrPan, removeFirstItem = false, list } = props;
  const { state, utils } = useContext(StateContext);
  const binOrPan = utils.getBin(true);
  const InitialListCard =
    list && list.data && list.data.length ? list : state.cards;
  const listWithoutFirstCard = removeIsPreferredCardFromList(
    binOrPan,
    InitialListCard
  );
  const newListCards = removeFirstItem
    ? listWithoutFirstCard.data.length > 0
      ? listWithoutFirstCard
      : InitialListCard
    : InitialListCard;
  const [listCards, setListCards] = useState(newListCards);
  const [selectedCard, setSelectedCard] = useState(0);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const sortListCards = sortCards(listCards);
  const renderListCard = renderCards(
    sortListCards,
    setOpenSubMenu,
    setSelectedCard,
    setListCards,
    listCards,
    setBinOrPan
  );
  const nameCardSelected = sortListCards[0];
  const image =
    nameCardSelected.image && nameCardSelected.image.length > 0
      ? nameCardSelected.image
      : Cards;

  const openMenu = e => {
    e.stopPropagation();
    setOpenSubMenu(true);
  };

  const closeMenu = event => {
    const containMenu =
      menuRef.current && menuRef.current.contains(event.target);
    const containButton =
      buttonRef.current && buttonRef.current.contains(event.target);
    if (!containMenu) {
      if (containButton) {
        event.stopPropagation();
      }
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    if (openSubMenu) document.body.addEventListener('click', closeMenu);
    else document.body.removeEventListener('click', closeMenu);

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [openSubMenu]);

  useEffect(() => {
    setBinOrPan(
      sortListCards[0].credit_card_token || sortListCards[0].external_reference
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.boxListCards}>
      <div className={styles.openListCards}>
        <button
          ref={buttonRef}
          onClick={openSubMenu ? closeMenu : openMenu}
          type="button"
          className={`${styles[theme]} ${styles.buttonCards}`}
        >
          <img src={image} alt={intl.TEXT_MY_CARDS} />
          <span className={styles.cardName}>{nameCardSelected.name}</span>
          <span className={styles.icon}>
            <ChevronGeneric
              rotate={!openSubMenu ? 0 : 180}
              width={20}
              height={20}
              data-testid="iconChevron"
              aria-label={intl.ICON_OPEN_MENU_COUNTRIES_LIST}
            />
          </span>
        </button>
        {openSubMenu && (
          <div
            ref={menuRef}
            aria-label={intl.LIST_CARDS}
            className={`${styles[theme]} ${styles.listCards}`}
          >
            <ul>{renderListCard}</ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListCardStatic;
