export const BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const GO_TO_HOMEPAGE = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para Homepage',
  es_ES: 'Ir a la Homepage',
};
