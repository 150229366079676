import { useContext } from 'react';
import {
  ThemeContext,
  useTheme,
} from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './index.module.scss';
import { joinClassNames } from '../../utils/classNames';

interface GroupDivisorProps {
  title: string;
  className?: string;
  id?: string;
  simpleLine?: boolean;
  axa?: boolean;
}

const GroupDivisor = ({
  title,
  className,
  id,
  simpleLine,
  axa,
}: GroupDivisorProps) => {
  const { getGlobalTheme } = useTheme(axa);
  const theme = getGlobalTheme();

  return (
    <h3
      className={joinClassNames(
        styles[theme],
        styles.base,
        simpleLine ? styles.simpleLine : styles.groupDivisor,
        className
      )}
      id={id}
    >
      {title}
    </h3>
  );
};

export default GroupDivisor;
