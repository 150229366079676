import moment from 'moment';
import { cloneDeep } from 'lodash';

export const formatBaggageLoss_toApi = props => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (BAGGAGE LOSS) INICIANDO FORMATAÇÃO PARA API',
    props.values
  );
  let object = {};
  object.travel_information = travelBaggageLoss(props);
  object.incident = incidentBaggageLoss(props);
  object.affected_person = formatAffectedPerson(props);
  object.other_insurance_covering = coveringBaggageLoss(props);
  object.formal_complaint = formalComplaint(props);
  object.total_amount_claimed = totalAmountClaimed(props);

  if (props.values.incident.hasAdditional === 'true') {
    const additional_passengers = cloneDeep(
      props.values.incident.additional_passengers
    );
    additional_passengers.map((value, index) => {
      const birthDate = value.birth_date;
      additional_passengers[index].nationalities = [value.nationalities];
      additional_passengers[index].birth_date =
        moment(birthDate).format('YYYY-MM-DD');
    });
    object.additional_passengers = additional_passengers;
  }

  return object;
};

export const totalAmountClaimed = props => {
  let totalAmount = props.values.incident.incident.concerned_items.reduce(
    (totalAmount, item) => {
      return totalAmount + parseFloat(item.price.value);
    },
    0
  );
  if (props.values.incident.other_company_covering.value) {
    totalAmount =
      totalAmount - props.values.incident.other_company_covering.value;
  }
  if (props.values.incident.other_insurance_covering.value) {
    totalAmount =
      totalAmount - props.values.incident.other_insurance_covering.value;
  }

  return {
    value: totalAmount,
    currency: props.values.incident.total_amount_claimed.currency,
  };
};

export const travelBaggageLoss = props => {
  const travel_information = props.values.incident.travel_information;

  const from_date = moment(travel_information.from_date).format('YYYY-MM-DD');
  const to_date = moment(travel_information.to_date).format('YYYY-MM-DD');
  let transportation_type = travel_information.transportation_type;

  return {
    was_the_baggage_in_the_charge_of_the_travel_company:
      travel_information.was_the_baggage_in_the_charge_of_the_travel_company,
    transportation_type: transportation_type,
    from_date,
    to_date,
  };
};

export const incidentBaggageLoss = props => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (BAGGAGE LOSS) FORMATAÇÃO DO INCIDENT PARA API',
    props.values.incident.incident
  );
  const incident = props.values.incident.incident;

  const discovered_at = moment(incident.discovered_at).format('YYYY-MM-DD');
  trackEventUserAction(
    '#### (BAGGAGE LOSS) FORMATAÇÃO DISCOVERED_AT SEM A HORA',
    {
      body: props.values.incident.incident,
      discovered_at: incident.discovered_at,
      discovered_at_formated: discovered_at,
    }
  );
  const discovered_at_hour = moment(
    discovered_at + ' ' + incident.hour_discovered_at
  ).format('YYYY-MM-DD HH:mm:ss');
  trackEventUserAction(
    '#### (BAGGAGE LOSS) FORMATAÇÃO DISCOVERED_AT COM A HORA',
    {
      body: props.values.incident.incident,
      discovered_at_hour: incident.hour_discovered_at,
      discovered_at_hour_formated: discovered_at_hour,
    }
  );

  let concerned_items = cloneDeep(
    props.values.incident.incident.concerned_items
  );
  const currency = props.values.incident.total_amount_claimed.currency;

  concerned_items = concerned_items.map(item => ({
    ...item,
    price: {
      value: item.price.value,
      currency: item.price.currency || currency,
    },
  }));
  return {
    concerned_items,
    discovered_at: discovered_at_hour,
    description: incident.description,
    kind: incident.kind,
    location: {
      country: incident.location.country,
      locality: incident.location.locality,
    },
  };
};

export const coveringBaggageLoss = props => {
  const currency = props.values.incident.total_amount_claimed.currency;
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const other_company_covering = props.values.incident.other_company_covering;
  const otherInsurance = [];

  if (other_insurance_covering.checked === 'true') {
    otherInsurance.push(
      otherInsuranceObject(other_insurance_covering, currency)
    );
  }

  if (other_company_covering.checked === 'true') {
    otherInsurance.push(otherInsuranceObject(other_company_covering, currency));
  }
  return otherInsurance;
};

const otherInsuranceObject = (props, currency) => {
  return {
    is_internal: props.is_internal,
    description: props.description,
    amount: {
      value: parseFloat(props.value),
      currency: currency,
    },
  };
};

const formalComplaint = props => {
  const formal = props.values.incident.formal_complaint;
  return {
    ...formal,
    is_submitted: formal.is_submitted === 'true',
  };
};

const formatAffectedPerson = props => {
  let affected = props.values.incident.affected_person;
  delete affected.relationshipCardholder;
  return affected;
};
