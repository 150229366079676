/* eslint-disable react/no-deprecated */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.css';
import { isIssuerDomain } from './utils/banks';

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

if (self === top) {
  document.documentElement.style.display = 'block';
} else {
  top.location = self.location;
}

document.title = isIssuerDomain()
  ? 'Benefits Management Portal'
  : 'Visa Benefits Portal';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
