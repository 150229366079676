export const joinClassNames = (...classNames: (string | undefined)[]) =>
  classNames
    // Non SASS module classes come first
    .sort((a, b) => b?.localeCompare(a ?? '') ?? 0)
    .reduce(
      (accumulator: string, current) =>
        current ? `${accumulator} ${current}` : accumulator,
      ''
    )
    .trim();
