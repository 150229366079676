import { differenceInHours, parseISO } from 'date-fns';
import { Flight } from '../../../@types/APIs/AEROAPI/Flight';
import {
  FlightStatus,
  Segment,
  TravelDetail,
} from '../../../@types/APIs/Travels';
import { flightService } from '../../../services';

export const getFlightNumberList = (travel: TravelDetail) => {
  const flightNumbers = new Set<string>();

  travel.outward_travel.flight_segments.forEach(segment => {
    flightNumbers.add(segment.flight_number);
  });

  travel.return_travel?.flight_segments?.forEach(segment => {
    flightNumbers.add(segment.flight_number);
  });

  return Array.from(flightNumbers);
};

export interface FlightDetails {
  [key: string]: Flight[];
}

export const getFlights = async (flight: string, trackEventUserAction) => {
  try {
    trackEventUserAction(`#### Trip Assistance - Details ### Flights`, {
      flight,
    });

    const {
      data: { flights },
    } = await flightService.getFlights(flight);

    trackEventUserAction(
      `#### Trip Assistance - Details ### Flights requested`,
      { data: flights }
    );

    return flights;
  } catch (error) {
    trackEventUserAction(
      `#### Trip Assistance - Details ### Error requesting flight`,
      { data: error?.response?.data || error }
    );

    return null;
  }
};

export interface HandledFlights {
  outwardFlights: Flight[];
  returnFlights?: Flight[];
}

export const formatFlight = (segment: Segment, flights: Flight[]): Flight => {
  const { start_at, actual_start_at, flight_number, airport_origin } = segment;

  const flight = flights?.find(flight => {
    const { scheduled_out, origin } = flight;

    const flightStartDate = parseISO(scheduled_out);
    const startHourDiferrence = differenceInHours(
      flightStartDate,
      parseISO(start_at)
    );

    const isOnTimeMargin = Math.abs(startHourDiferrence) <= 3;
    const isSameOrigin =
      origin.code_iata === airport_origin || origin.code_lid === airport_origin;

    return isOnTimeMargin && isSameOrigin;
  });

  if (flight) {
    return flight;
  }

  return null;
};

export const formatFlights = (
  travel: TravelDetail,
  flightDetails: FlightDetails
): HandledFlights => ({
  outwardFlights: travel.outward_travel.flight_segments.map(segment =>
    formatFlight(segment, flightDetails[segment.flight_number])
  ),
  returnFlights: travel.return_travel?.flight_segments?.map(segment =>
    formatFlight(segment, flightDetails[segment.flight_number])
  ),
});

const getIsDelayed = (delayInSecs: number): FlightStatus =>
  delayInSecs > 15 * 60 ? 'DELAYED' : 'ON_TIME';

export const getFlightStatus = (flight: Flight): FlightStatus => {
  if (!flight) return null;

  const { departure_delay, arrival_delay, actual_out, cancelled } = flight;

  if (cancelled) return 'CANCELLED';

  if (actual_out) {
    return getIsDelayed(arrival_delay);
  }

  return getIsDelayed(departure_delay);
};

export const getFlightNumbers = (travel: TravelDetail) => {
  const { is_one_way_travel, outward_travel, return_travel } = travel;

  return {
    outbound: outward_travel.flight_segments.map(
      flight => flight.flight_number
    ),
    return: is_one_way_travel
      ? []
      : return_travel.flight_segments.map(flight => flight.flight_number),
  };
};
