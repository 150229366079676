export const TEXT_AGE = {
  es_ES: 'Edad',
  en_US: 'Age',
  pt_BR: 'Idade',
};

export const TEXT_YEARS_OLD = {
  es_ES: ' años de edad',
  en_US: ' years old',
  pt_BR: ' anos',
};

export const CHECKBOX_LABEL_IS_SELECTED = {
  en_US: 'Selected',
  pt_BR: 'Selecionado',
  es_ES: 'Seleccionado',
};

export const CHECKBOX_LABEL_SELECT = {
  en_US: 'Select',
  pt_BR: 'Selecionar',
  es_ES: 'Selecciona',
};

export const LABEL_FIRST_NAME = {
  en_US: 'First name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};
