import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const SpecialBenefitsIcon = props => {
  const { enabled = true, bottombar } = props;

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M2 4C2 2.89543 2.89543 2 4 2H10V10H2V4Z"
        stroke={
          enabled
            ? bottombar
              ? config_icon.secondary
              : config_icon.primary
            : config_icon.inactive
        }
        strokeWidth={2}
      />
      <path
        d="M14 2H20C21.1046 2 22 2.89543 22 4V10H14V2Z"
        stroke={enabled ? config_icon.secondary : config_icon.inactive}
        strokeWidth={2}
      />
      <path
        d="M2 14H10V22H4C2.89543 22 2 21.1046 2 20V14Z"
        stroke={enabled ? config_icon.secondary : config_icon.inactive}
        strokeWidth={2}
      />
      <path
        d="M14 14H22V20C22 21.1046 21.1046 22 20 22H14V14Z"
        stroke={
          enabled
            ? bottombar
              ? config_icon.secondary
              : config_icon.primary
            : config_icon.inactive
        }
        strokeWidth={2}
      />
    </svg>
  );
};

export default SpecialBenefitsIcon;
