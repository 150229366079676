import { createContext, ReactNode, useContext } from 'react';

export interface FlightInfoData {
  flightNumber: string;
  startAt: string;
  endAt: string;
  airportOrigin: string;
  countryOrigin: string;
  countryDestination: string;
  airportDestination: string;
  cityOrigin?: string;
  cityDestination?: string;
  progressPercent?: number;
  routeDistance?: number;
  departureDelay?: number;
  arrivalDelay?: number;
  cancelled?: boolean;
  terminalOrigin?: string;
  gateOrigin?: string;
  terminalDestination?: string;
  gateDestination?: string;
}

export interface FlightInfoContextData {
  flightInfo: FlightInfoData;
}

interface FlightInfoProviderProps {
  children: ReactNode;
  flightInfo: FlightInfoData;
}

const FlightInfoContext = createContext({} as FlightInfoContextData);

const FlightInfoProvider = ({
  children,
  flightInfo,
}: FlightInfoProviderProps) => (
  <FlightInfoContext.Provider value={{ flightInfo }}>
    {children}
  </FlightInfoContext.Provider>
);

function useFlightInfo() {
  const context = useContext(FlightInfoContext);

  if (!context) {
    throw new Error(
      'FlightInfo.* component must be rendered as child of FlightInfo component'
    );
  }

  return context;
}

export { FlightInfoProvider, useFlightInfo };
