import { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../intl';
import styles from './FlightDetailItem.module.scss';
import * as translations from '../../../../../intl/travels';
import GroupDivisor from '../../../../../components/GroupDivisor';
import FlightInfo from '../../../../../components/FlightInfo';
import { Segment } from '../../../../../@types/APIs/Travels';
import { FlightInfoData } from '../../../../../components/FlightInfo/FlightInfoContext';
import { formatFlightInfo } from './utils';
import { Flight } from '../../../../../@types/APIs/AEROAPI/Flight';
import RealTimeTracking from '../../../RealTimeTracking';

interface FlightDetailList {
  trip: Segment;
  flight: Flight;
  index: number;
}

const FlightDetailItem = ({ trip, index, flight }: FlightDetailList) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const flightInfo: FlightInfoData = useMemo(
    () => formatFlightInfo(trip),
    [trip, flight]
  );

  return (
    <>
      <GroupDivisor
        title={intl.FLIGHT_LEG(index + 1)}
        className={styles.divider}
        id={`divider-${trip.flight_number}`}
      />
      {flight ? (
        <RealTimeTracking flightId={flight.fa_flight_id} />
      ) : (
        <FlightInfo
          flightInfo={flightInfo}
          header={
            <FlightInfo.StatusHeader
              flight={flight}
              displayRouteStatus={false}
              title={`${intl.FLIGHT} ${trip.flight_number}`}
            />
          }
        >
          <FlightInfo.Label />
          <FlightInfo.Cities />
          <FlightInfo.Airports />
          <FlightInfo.Dates />
        </FlightInfo>
      )}
    </>
  );
};

export default FlightDetailItem;
