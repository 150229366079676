import { userServices } from '../services';
import { PORTOBANK, isDomainByIssuer } from '../utils/banks';

let count = 0;

const convertToCH = async () => {
  try {
    count++;
    if (count <= 1) {
      await userServices.convertLeadToCH();
    } else {
      count = 0;
    }
  } catch (error) {
    console.log(error);
  }
};

export async function getUserFromCookie() {
  let userFromCookie = null;

  try {
    const { data } = await userServices.getUserInfo();
    userFromCookie = {
      userInfo: {
        ...data,
      },
    };
  } catch (error) {}

  if (!userFromCookie) return;

  const isPortoDomain = isDomainByIssuer(PORTOBANK);
  if (isPortoDomain) await convertToCH();

  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.customer_id
  ) {
    userFromCookie.userInfo.cn = userFromCookie.userInfo.customer_id;
  }

  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.sub
  ) {
    const uuid = userFromCookie.userInfo.sub.split('|').pop();
    userFromCookie.userInfo.cn = uuid;
  }
  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.given_name
  ) {
    userFromCookie.userInfo.firstName = userFromCookie.userInfo.given_name;
  }
  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.family_name
  ) {
    userFromCookie.userInfo.lastName = userFromCookie.userInfo.family_name;
  }
  const user = { logged: true, data: userFromCookie };
  await this.setState({ user });
  const userUpdated = { ...user };

  try {
    const generalInformatioRequest = await userServices.getCHInfo(
      user.data.userInfo.cn
    );
    if (generalInformatioRequest) {
      userUpdated.data.userInfo.firstName =
        generalInformatioRequest.data.person.first_name;
      userUpdated.data.userInfo.lastName =
        generalInformatioRequest.data.person.last_name;
      userUpdated.data.userInfo.gender =
        generalInformatioRequest.data.person.gender;
    }
  } catch (error) {}

  try {
    await this.setState({ user: userUpdated });
    this.actions.cards.getLoggedUserCards();
  } catch (error) {}
}

export async function updateFirstLastName(data) {
  let userFromCookie = null;
  try {
    const { data } = await userServices.getUserInfo();
    userFromCookie = {
      userInfo: {
        ...data,
      },
    };
  } catch (error) {}

  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.customer_id
  ) {
    userFromCookie.userInfo.cn = userFromCookie.userInfo.customer_id;
  }
  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.sub
  ) {
    const uuid = userFromCookie.userInfo.sub.split('|').pop();
    userFromCookie.userInfo.cn = uuid;
  }
  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.given_name
  ) {
    userFromCookie.userInfo.firstName = userFromCookie.userInfo.given_name;
  }
  if (
    userFromCookie &&
    userFromCookie.userInfo &&
    userFromCookie.userInfo.family_name
  ) {
    userFromCookie.userInfo.lastName = userFromCookie.userInfo.family_name;
  }

  const userFromCookieCloned = { ...userFromCookie };

  userFromCookieCloned.userInfo.firstName = data.cardholder.person.first_name;
  userFromCookieCloned.userInfo.lastName = data.cardholder.person.last_name;
  const user = { logged: true, data: userFromCookieCloned };
  try {
    await this.setState({ user });
  } catch (error) {}
}

export function logout() {
  this.props.history.push('/signout');
}

export function sessionExpired() {
  this.props.history.push('/session-expired');
}
