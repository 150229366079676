import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PriceProtectionIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          fill={config_icon.primary}
          d="M17,8h-2V5c0-1.7-1.3-3-3-3S9,3.3,9,5v3H7V5c0-2.8,2.2-5,5-5s5,2.2,5,5V8z"
        />
        <g>
          <path
            fill={config_icon.primary}
            d="M19,6.9v3.3c0.6,1.1,1,2.5,1,3.9c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-1.4,0.4-2.7,1-3.9V6.9C3.2,8.7,2,11.2,2,14 c0,5.5,4.5,10,10,10s10-4.5,10-10C22,11.2,20.8,8.7,19,6.9z"
          />
          <path
            fill={config_icon.secondary}
            d="M12,17c-0.6,0-1-0.4-1-1H9c0,1.3,0.8,2.4,2,2.8V20h2v-1.2c1.2-0.4,2-1.5,2-2.8c0-1.7-1.3-3-3-3 c-0.6,0-1-0.4-1-1s0.4-1,1-1s1,0.4,1,1h2c0-1.3-0.8-2.4-2-2.8V8h-2v1.2C9.8,9.6,9,10.7,9,12c0,1.7,1.3,3,3,3c0.6,0,1,0.4,1,1 S12.6,17,12,17z"
          />
        </g>
      </g>
    </svg>
  );
};

export default PriceProtectionIcon;
