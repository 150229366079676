import React, { useContext, useEffect } from 'react';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { IntlContext } from '../../../../../../../../intl';
import sanitize from '../../../../../../../../utils/sanitize';
import * as translations from './intl';

const ReimbursementArea = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    isCorporate,
  } = props;

  const cardholder = 1;
  const coverValuePerPerson = isCorporate ? 2000 : 300;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  useEffect(() => {
    const additionalTravellers =
      values.hasAdditional === 'true' ? values.additional_passengers.length : 0;
    const numberOfTravellers = additionalTravellers + cardholder;

    let totalAmount = coverValuePerPerson * numberOfTravellers;

    setFieldValue('total_amount_claimed.value', Number(totalAmount).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.additional_passengers, values.hasAdditional]);

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.total_amount_claimed.value}
          label={intl.LABEL_TOTAL_REIMBURSED}
          name="total_amount_claimed.value"
          type="text"
          maskedMoney
          disabled
          tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
          onChange={handleChange}
          touched={
            touched.total_amount_claimed && touched.total_amount_claimed.value
          }
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
          dataTestid="inputAmountClaimed"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.total_amount_claimed.currency}
          name="total_amount_claimed.currency"
          onChange={handleChange}
          disabled
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={
            touched.total_amount_claimed &&
            touched.total_amount_claimed.currency
          }
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
          dataTestid="comboAmountClaimed"
        />
      </div>
    </div>
  );
};

export default ReimbursementArea;
