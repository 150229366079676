import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const AllCardsIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: 'none',
          stroke: config_icon.secondary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M1.9 17.5h20.2M1.9 13.4h20.2M2.9 5.5h18.2v3.9H2.9z"
      />
    </svg>
  );
};

export default AllCardsIcon;
