import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const LABEL_LICENSE_NUMBER = {
  en_US: 'Driving license number *',
  pt_BR: 'Número da carteira de habilitação *',
  es_ES: 'Número de la licencia de conducir *',
};

export const LABEL_COUNTRY_LICENSE = {
  en_US: 'Country where issued driver license *',
  pt_BR: 'País onde foi emitida a carteira de habilitação *',
  es_ES: 'País donde se emitió la licencia de conducir *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_DRIVER_NAME = {
  en_US: 'Driver name at time of incident *',
  pt_BR: 'Nome do condutor do veículo no momento do incidente *',
  es_ES: 'Nombre del conductor del vehículo al momento del incidente *',
};

export const LABEL_DRIVER_LAST_NAME = {
  en_US: 'Driver last name at time of incident *',
  pt_BR: 'Sobrenome do condutor do veículo no momento do incidente *',
  es_ES: 'Apellido del conductor del vehículo al momento del incidente *',
};
