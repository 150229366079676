export const ERROR_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const VALIDATION_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TITLE_QUOTE_UPSELL = {
  en_US: 'Acquire your travel assistance online',
  pt_BR: 'Obtenha sua assistência em viagens online',
  es_ES: 'Indica el número de pasajeros para la cotización',
};

export const TITLE_QUOTE_CROSSELL = {
  en_US: 'Acquire your travel assistance online',
  pt_BR: 'Obtenha sua assistência em viagens online',
  es_ES: 'Adquiere tu asistencia de Viaje Online',
};

export const BTN_QUOTE = {
  en_US: 'Quote',
  pt_BR: 'Fazer cotação',
  es_ES: 'Cotizar con AXA',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const SUBTITLE_QUOTE_UPSELL = {
  en_US:
    () => `As a valued Visa member, you have International Medical Emergency Services included in your benefits, but should you want to, you now have the possibility to increase your international medical coverage.--

  To be eligible for these benefits and additional coverage, you must purchase the international travel ticket with your eligible Visa card and the country of origin must be the country of residence or the country in which the card was issued.--

  Please note that you should only continue with the form below if you are interested in receiving a quote for additional coverage.--

  If you are satisfied with your existing Visa coverage, please return to the main page of the Visa Benefits Portal and issue your travel certificate before you travel.`,
  pt_BR:
    () => `Como um membro valioso da Visa, você tem Serviços de Emergência Médica Internacional incluídos em seus benefícios, mas se desejar, agora você tem a possibilidade de aumentar sua cobertura médica internacional. --

  Para ter direito a esses benefícios e cobertura adicional, você deve comprar a passagem internacional com seu cartão Visa qualificado e o país de origem deve ser o país de residência ou o país em que seu cartão foi emitido. --

  Observe que você só deve continuar com o formulário abaixo se estiver interessado em receber um orçamento para cobertura adicional. --

  Se você estiver satisfeito com a sua cobertura vigente, retorne à página principal do Portal de Benefícios Visa e emita seu certificado de viagem antes de viajar.`,
  es_ES:
    () => `Al ser un valioso miembro para Visa, tú tienes los Servicios de Emergencia Médica Internacional incluidos en tus beneficios, pero en caso de que lo desee, ahora tienes la posibilidad de aumentar tu cobertura médica internacional, por medio de compañía AXA Assistance. --

    Para ser elegible para estos beneficios y a la cobertura adicional, debe comprar el boleto de viaje internacional para ti y tus acompañantes con tu tarjeta Visa elegible y el país de origen debe ser el país de residencia o el país en el que se emitió la tarjeta.  --

    Tenga en cuenta que solo debe continuar con el formulario a continuación, si está interesado en recibir una cotización para cobertura adicional.  --

    Si está satisfecho con tu cobertura Visa existente, vuelva a la página principal del Portal de beneficios de Visa y emite tu certificado de viaje antes de viajar.`,
};

export const SUBTITLE_QUOTE_CROSSELL = {
  en_US:
    () => `Now you can have access to the International Medical Emergency Services through your Visa Card.--

    Now, when you pay for your international travel ticket in full with your Visa card, you have the ability to access international medical coverage at a competitive rate. These services provide you with protection against accidents or medical emergencies that occur outside your country of residence or country of issuance of your card.--

    Below, you can find convenient plans that will protect you, your family, and friends.--

    Please note that you should only continue with the form below, if you are interested in receiving a quote for International Medical Emergency Services.`,
  pt_BR:
    () => `Agora você pode ter acesso aos Serviços de Emergência Médica Internacional através do seu cartão Visa.--

    Agora, quando você paga sua passagem internacional integralmente com seu cartão Visa, pode acessar a cobertura médica internacional a uma taxa competitiva. Esses serviços fornecem proteção contra acidentes ou emergências médicas que ocorram fora do seu país de residência ou país de emissão do seu cartão.--

    Abaixo, você pode encontrar planos convenientes que protegerão você, sua família e amigos.--

    Observe que você só precisa continuar com o formulário abaixo, se estiver interessado em receber uma cotação para Serviços de Emergência Médica Internacional.`,
  es_ES:
    () => `Ahora Tú puedes tener acceso a los Servicios de Emergencia Médica Internacional por medio de tu Tarjeta Visa.--

    Ahora, cuando pague en su totalidad tu boleto de viaje internacional con tu tarjeta Visa, tienes la posibilidad de acceder a la cobertura médica internacional por una tarifa competitiva. Estos servicios te brindan protección contra accidentes o emergencias médicas que ocurran fuera de tu país de residencia o país de emisión de tu tarjeta.--

    A continuación, puedes encontrar planes convenientes que te protegerán a ti, a tu familia y amigos.--

    Tenga en cuenta que solo debe continuar con el formulario a continuación, si está interesado en recibir una cotización para los Servicios de Emergencia Médica Internacional.`,
};

export const ERROR_COVERAGE_IS_LIMITED = {
  en_US: 'Coverage is limited to 60 consecutive days.',
  pt_BR: 'A cobertura é limitada a 60 dias consecutivos.',
  es_ES: 'La cobertura está limitada a 60 días consecutivos.',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const REQUIRED_SOME_TRAVELLERS = {
  en_US: 'Inform at least one passenger.',
  pt_BR: 'Informe pelo menos um passageiro.',
  es_ES: 'Informar al menos a un pasajero.',
};
