import knowBenefitsRightMaster from '../../assets/images/master/banner2MasterRight_06-10.jpg';
import knowBenefitsLeftMaster from '../../assets/images/master/bannerMasterLeft_06-10.jpg';
import offersHomeMaster from '../../assets/images/master/BannerI_master.png';

import knowBenefitsRightAfluent from '../../assets/images/afluent/banner2AfluentRight_06-10.jpg';
import knowBenefitsLeftAfluent from '../../assets/images/afluent/bannerAfluentLeft_06-10.jpg';
import offersHomeAfluent from '../../assets/images/afluent/BannerI_afluent.png';
import { listCountries, SOUTH_AMERICA } from '../../intl/countries';

/* AFFLUENT */
import JanMarchBannerAffluent from '../../assets/images/afluent/Banner_HOME/jan-mar/jan-banner-AFFLUENT.jpg';
import JanMarchBannerAffluent_RIGHT from '../../assets/images/afluent/Banner_HOME/jan-mar/jan-banner-2-AFFLUENT.jpg';

import AprilJuneBannerAffluent from '../../assets/images/afluent/Banner_HOME/april-jun/apr-banner-AFFLUENT.jpg';
import AprilJuneBannerAffluent_RIGHT from '../../assets/images/afluent/Banner_HOME/april-jun/apr-banner-2-AFFLUENT.jpg';

import JulySeptBannerAffluent from '../../assets/images/afluent/Banner_HOME/july-sept/jul-banner-AFFLUENT.png';
import JulySeptBannerAffluent_RIGHT from '../../assets/images/afluent/Banner_HOME/july-sept/jul-banner-2-AFFLUENT.png';

import OctoberDecemberBannerAffluent from '../../assets/images/afluent/Banner_HOME/oct-dec/oct-banner-AFFLUENT.jpg';
import OctoberDecemberBannerAffluent_RIGHT from '../../assets/images/afluent/Banner_HOME/oct-dec/oct-banner-2-AFFLUENT.jpg';

/* MASTER */
import JanMarchBannerMaster from '../../assets/images/master/Banner_HOME/jan-mar/jan-banner-MASTER.jpg';
import JanMarchBannerMaster_RIGHT from '../../assets/images/master/Banner_HOME/jan-mar/jan-banner-2-MASTER.jpg';
import AprilJuneBannerMaster from '../../assets/images/master/Banner_HOME/april-jun/apr-banner-MASTER.jpg';
import AprilJuneBannerMaster_RIGHT from '../../assets/images/master/Banner_HOME/april-jun/apr-banner-2-MASTER.jpg';
import JulySeptBannerMaster from '../../assets/images/master/Banner_HOME/july-sept/jul-banner-MASTER.png';
import JulySeptBannerMaster_RIGHT from '../../assets/images/master/Banner_HOME/july-sept/jul-banner-2-MASTER.png';
import OctoberDecemberBannerMaster from '../../assets/images/master/Banner_HOME/oct-dec/oct-banner-MASTER.png';
import OctoberDecemberBannerMaster_RIGHT from '../../assets/images/master/Banner_HOME/oct-dec/oct-banner-2-MASTER.png';

const AFFLUENT = 'AFFLUENT';
const MASTER = 'MASTER';

const getCorrectBannerByMonth = (theme, country = 'PRL') => {
  const currentMonth = new Date().getMonth();
  const checkIfCountryIsSulAmerica =
    listCountries.find(countries => countries.initials == country).continent ==
    SOUTH_AMERICA;

  const current_image = {
    jan: {
      AFFLUENT: [JanMarchBannerAffluent, JanMarchBannerAffluent_RIGHT],
      MASTER: [JanMarchBannerMaster, JanMarchBannerMaster_RIGHT],
    },
    april: {
      AFFLUENT: [AprilJuneBannerAffluent, AprilJuneBannerAffluent_RIGHT],
      MASTER: [AprilJuneBannerMaster, AprilJuneBannerMaster_RIGHT],
    },
    july: {
      AFFLUENT: [JulySeptBannerAffluent, JulySeptBannerAffluent_RIGHT],
      MASTER: [JulySeptBannerMaster, JulySeptBannerMaster_RIGHT],
    },
    oct: {
      AFFLUENT: [
        OctoberDecemberBannerAffluent,
        OctoberDecemberBannerAffluent_RIGHT,
      ],
      MASTER: [OctoberDecemberBannerMaster, OctoberDecemberBannerMaster_RIGHT],
    },
  };

  if (currentMonth <= 2) {
    if (checkIfCountryIsSulAmerica) {
      return current_image.april[theme];
    }
    return current_image.jan[theme];
  } else if (currentMonth <= 5) {
    return current_image.april[theme];
  } else if (currentMonth <= 8) {
    return current_image.july[theme];
  } else if (currentMonth <= 11) {
    return current_image.oct[theme];
  }
};

export const getImagesByTheme = (themeWithUnderline, imgName, country) => {
  const images = {
    theme_afluent: {
      knowBenefitsRight: knowBenefitsRightAfluent,
      knowBenefitsLeft: knowBenefitsLeftAfluent,
      offersHome: offersHomeAfluent,
      bannerHome: getCorrectBannerByMonth(AFFLUENT, country),
    },
    theme_master: {
      knowBenefitsRight: knowBenefitsRightMaster,
      knowBenefitsLeft: knowBenefitsLeftMaster,
      offersHome: offersHomeMaster,
      bannerHome: getCorrectBannerByMonth(MASTER, country),
    },
  };

  const imagesByTheme = images[themeWithUnderline]
    ? images[themeWithUnderline][imgName]
    : images['theme_master'][imgName];

  return imagesByTheme;
};
