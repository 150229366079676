export const SUBTITLE_GENERATE_CERTIFICATE = {
  en_US: 'Generate',
  es_ES: 'Generar',
  pt_BR: 'Criar',
};

export const SUBTITLE_MAKE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};

export const SUBTITLE_UPDATE_CLAIM = {
  en_US: 'Update claim',
  pt_BR: 'Atualizar sinistro',
  es_ES: 'Actualizar reclamo',
};

export const BUTTON_CONTINUE = {
  en_US: 'Continue',
  pt_BR: 'Continuar',
  es_ES: 'Continuar',
};

export const BUTTON_CLOSE = {
  en_US: 'Close',
  pt_BR: 'Fechar',
  es_ES: 'Cerrar',
};

export const EXIT_TITLE = {
  en_US: 'Do you really want to leave?',
  pt_BR: 'Deseja realmente sair?',
  es_ES: '¿De verdad quieres irte?',
};
export const CHANGES_NOT_SAVED_MESSAGE = {
  en_US: 'Changes will not be saved.',
  pt_BR: 'As alterações não serão salvas.',
  es_ES: 'Los cambios no se guardarán.',
};
