import React, { useContext, useState, useEffect } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../../intl';
import styles from './DependentAcordeon.module.scss';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../../../../assets/icons/ChevronGeneric';

const DependentAcordeon = props => {
  const { dependent, children } = props;
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [infoText, setInfoText] = useState('');
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const onClick = () => setShowMoreInfo(!showMoreInfo);

  const checkWidth = () => {
    if (window.screen.width > 576) {
      setInfoText(intl.MORE_INFO);
    } else {
      setInfoText(
        `${dependent.person.first_name} ${dependent.person.last_name}`
      );
    }
  };

  useEffect(() => {
    checkWidth();
    window.addEventListener('resize', checkWidth);
    return () => {
      window.removeEventListener('resize', checkWidth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={`${styles.accordionHeader} ${styles[theme]} ${
          showMoreInfo ? styles.border : ''
        }`}
      >
        <span className={`${styles.name} ${styles[theme]}`}>
          {dependent.person.first_name} {dependent.person.last_name}
        </span>
        <div
          className={`${styles[theme]} ${styles.moreInformation}`}
          onClick={onClick}
        >
          <span className={`${styles[theme]} ${styles.more_info}`}>
            {infoText}
          </span>
          {showMoreInfo ? (
            <span className={styles.icon}>
              <ChevronGeneric width={20} height={20} rotate={180} />
            </span>
          ) : (
            <span className={styles.icon}>
              <ChevronGeneric width={20} height={20} />
            </span>
          )}
        </div>
      </div>
      {showMoreInfo && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default DependentAcordeon;
