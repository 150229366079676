import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/ExtendedWarranty/consts';
import { formatCustomDate } from '../../../../../../utils/date';
import { getGender } from '../../../../../../utils/formatInfoProfile';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const affectedPerson = claimDetails && claimDetails.affected_person;

  const cardholderRelationship =
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';

  const firstName = affectedPerson && affectedPerson.person.first_name;

  const lastName = affectedPerson && affectedPerson.person.last_name;

  const birthDate = affectedPerson.person.birth_date
    ? formatCustomDate(
        affectedPerson.person.birth_date,
        'YYYY-MM-DD',
        idiom,
        country
      )
    : '-';

  const gender =
    affectedPerson &&
    affectedPerson.person.gender &&
    getGender(affectedPerson.person.gender, intl);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={cardholderRelationship}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_NAME} value={firstName} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_LAST_NAME} value={lastName} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_BIRTH_DATE} value={birthDate} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_GENDER} value={gender} />
      </div>
    </>
  );
};

export default PersonalArea;
