export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descrição do incidente',
  es_ES: 'Descripción del incidente',
};

export const LABEL_TOTAL_AMOUNT_CLAIMED = {
  en_US: 'Total amount of claim',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};
