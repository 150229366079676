import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const HeartIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={46}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 46 40"
      {...props}
    >
      <path
        d="M32.375.25c-2.62 0-5.139.78-7.292 2.16v5.53c1.775-2.21 4.451-3.523 7.292-3.523 5.17 0 9.375 4.205 9.375 9.375 0 8.143-8.851 15.02-16.667 20.346v5.023c9.047-6.038 20.834-14.216 20.834-25.37C45.917 6.326 39.842.25 32.375.25Z"
        fill={config_icon.secondary}
      />
      <path
        d="M.083 13.792c0 11.153 11.788 19.331 20.834 25.37v-5.024C13.102 28.812 4.25 21.935 4.25 13.792c0-5.17 4.205-9.375 9.375-9.375 2.84 0 5.516 1.314 7.292 3.523V2.41A13.507 13.507 0 0 0 13.625.25C6.158.25.083 6.325.083 13.792Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};

export default HeartIcon;
