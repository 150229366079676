/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../intl';
import {
  getCurrencyByFormType,
  getAmountInteger,
  getAmountDecimal,
} from '../../../../../utils/B2C/currencyISOCode';
import styles from './Plans.module.scss';
import * as translations from './intl';
import PlanBtnSelect from './PlansBtnSelect/PlanBtnSelect';
import {
  ThemeContext,
  useTheme,
} from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const renderListBenefits = async (setListBenefits, benefits, theme) => {
  const getFourBenefits = benefits.slice(0, 4);
  const newListBenefits = getFourBenefits.map((list, index) => (
    <li key={`Benefits-${index}`} className={styles[theme]}>
      <div className={styles.boxTitleBenefit}>
        <div className={`${styles.planTitleBenefit} ${styles[theme]}`}>
          <h2>{list.Benefit.Description}</h2>
        </div>
        <div className={`${styles.planSubtitleBenefit} ${styles[theme]}`}>
          <h3>{list.Benefit.Detail}</h3>
        </div>
      </div>
    </li>
  ));
  setListBenefits(newListBenefits);
};

const Plans = props => {
  const { plan, formType, currentPlan, selected } = props;
  const { Name, AmountWithoutPromotion } = plan;
  const [benefits, setBenefits] = useState([]);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useTheme(true);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const symbolAmount = '$';

  useEffect(() => {
    renderListBenefits(setBenefits, plan.BenefitVISA, theme);
  }, []);

  return (
    <li className={`col-12 col-md-6 col-lg-4 col-xl-3 ${styles.listItem}`}>
      <div
        className={`${styles.boxPlans} ${styles[theme]} ${
          selected ? styles.planSelected : ''
        }`}
      >
        <div className={`${styles.planName} ${styles[theme]}`}>
          <h2>{Name}</h2>
        </div>

        <ul className={`${styles.planListBenefit} ${styles[theme]}`}>
          {benefits}
        </ul>

        {!currentPlan && (
          <div className={`${styles.planValueBenefit} ${styles[theme]}`}>
            <div className={styles.planBoxFinalValue}>
              <span
                className={`${styles.planFinalValueInteger} ${styles[theme]}`}
              >
                {symbolAmount}
                {getAmountInteger(AmountWithoutPromotion)}
              </span>
              <span
                className={`${styles.planFinalValueDecimal} ${styles[theme]}`}
              >
                {getAmountDecimal(AmountWithoutPromotion)}
              </span>
              <span className={styles.planSymbolValue}>
                {getCurrencyByFormType(formType)}
              </span>
            </div>
            <p
              className={`${styles.textForAll} ${styles[theme]}`}
              dangerouslySetInnerHTML={{ __html: intl.TEXT_VALUE_IS_FOR_ALL }}
            ></p>
          </div>
        )}

        <PlanBtnSelect
          product={plan}
          intl={intl}
          currentPlan={currentPlan}
          selected={selected}
        />
      </div>
    </li>
  );
};

export default Plans;
