import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const VirtualAgentIcon = props => {
  const { enabled = true, invert_color, darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : invert_color
    ? config_icon.secondary
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : invert_color
    ? config_icon.primary
    : config_icon.secondary;

  return (
    <svg
      width={60}
      height={74}
      viewBox="0 0 60 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4378 46.2147C11.4378 42.497 9.77287 39.6916 8.15351 37.0003C6.3745 34.0353 4.70952 31.2527 4.70952 26.8964C4.70952 18.3663 9.97814 11.045 17.4363 8.01152V3.15344C7.42366 6.39216 0.147949 15.8118 0.147949 26.8964C0.147949 32.5072 2.3147 36.1336 4.23056 39.3495C5.71307 41.8128 6.87627 43.7514 6.87627 46.2147C6.87627 47.4007 6.62538 48.6095 6.30607 49.7271L10.4343 51.7798C11.0045 50.0692 11.4378 48.1534 11.4378 46.1919V46.2147Z"
        fill={enabled ? primary : config_icon.inactive}
      />
      <path
        d="M32.0334 67.6997L27.9508 65.6698L8.60971 55.9993L5.12011 54.2431L4.52711 55.4519L3.09021 58.3257L33.3334 73.4473L34.3598 71.4174L35.3633 69.3647L32.0334 67.6997Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
      <path
        d="M53.678 52.3271C51.9674 52.3271 50.4621 53.1026 49.4129 54.3114C48.9796 54.1289 48.455 53.8552 47.8848 53.5815L49.6866 46.4199L50.7814 46.1462C52.5148 45.7128 53.9061 44.5268 54.6131 42.8846C55.3202 41.2425 55.2061 39.4179 54.3166 37.8669L50.0972 30.4772V26.8735C50.0972 15.1731 42.0004 5.36571 31.1438 2.67438V7.3728C39.4687 9.95009 45.5356 17.7048 45.5356 26.8735V31.686L50.3481 40.1249C50.5761 40.5354 50.4849 40.9004 50.3937 41.0828C50.3252 41.2653 50.12 41.5846 49.6638 41.6986L45.9233 42.6338L43.7794 51.2095C40.974 49.3849 37.7581 46.8304 34.8159 43.3864C33.7667 44.504 32.5579 45.4847 31.1894 46.2374C34.7931 50.5025 38.7617 53.5359 42.0916 55.5886C42.0004 55.6114 41.9091 55.6799 41.8179 55.6799H35.4089C33.9036 55.6799 32.5351 56.5238 31.8737 57.8694L30.0262 61.5643L34.1089 63.5942L35.7966 60.2186H41.8407C43.6882 60.2186 45.3531 59.3291 46.3795 57.8922C47.0637 58.2115 47.6339 58.4624 48.1357 58.6677C48.5006 61.4274 50.8498 63.5486 53.7008 63.5486C56.8027 63.5486 59.3344 61.0169 59.3344 57.915C59.3344 54.8132 56.8027 52.2815 53.7008 52.2815L53.678 52.3271ZM53.678 59.0554C53.085 59.0554 52.606 58.5765 52.606 57.9835C52.606 57.3905 53.085 56.9115 53.678 56.9115C54.271 56.9115 54.75 57.3905 54.75 57.9835C54.75 58.5765 54.271 59.0554 53.678 59.0554Z"
        fill={enabled ? primary : config_icon.inactive}
      />
      <path
        d="M30.4823 26.0525C29.3419 25.1174 28.019 24.4104 26.5365 24.0683V0.849854H21.9749V24.0683C20.4924 24.4332 19.1696 25.1174 18.0292 26.0525C15.8396 27.8544 14.4027 30.5685 14.4027 33.6019C14.4027 39.0302 18.8274 43.4321 24.2329 43.4321C25.7154 43.4321 27.0839 43.09 28.3383 42.497C29.7524 41.8356 30.984 40.8549 31.942 39.6232C33.2648 37.9583 34.0631 35.8599 34.0631 33.5791C34.0631 30.5229 32.6262 27.8316 30.4366 26.0297L30.4823 26.0525ZM29.3419 34.9932C28.8857 36.6582 27.6541 37.9811 26.0575 38.5513C25.5101 38.7565 24.9171 38.8706 24.2785 38.8706C21.3591 38.8706 19.0099 36.4985 19.0099 33.6019C19.0099 30.7053 21.3819 28.3333 24.2785 28.3333C27.1751 28.3333 29.5471 30.7053 29.5471 33.6019C29.5471 34.0809 29.4559 34.5371 29.3419 34.9932Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
    </svg>
  );
};

export default VirtualAgentIcon;
