import { useState, useContext } from 'react';
import styles from './TermsAndConditions.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../componentsV2/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import Link from '../../../../componentsV2/Link/Link';

const TermsAndConditions = props => {
  const { setStep, pdfLink } = props;
  const [genericAccept, setGenericAccept] = useState(false);
  const [genericAccept2, setGenericAccept2] = useState(false);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const termsAndConditionsContent = () => {
    return (
      <>
        <div className={styles.termsArea}>
          <h2 className={styles[theme]}>
            {intl.ACCEPTING_TERMS_AND_CONDITIONS}
          </h2>

          <div className={styles.pdf}>
            <PDFIcon width={42} height={54} className={styles.pdfIcon} />
            <div className={styles.pdfInfo}>
              <Link href={pdfLink} label={intl.TERMS_AND_CONDITIONS} />
              <span>{intl.EXECUTIVE_TRANSPORTATION}</span>
            </div>
          </div>
          <div className={styles.maxCoverageArea}>
            <h3 className={styles[theme]}>
              {intl.TEXT_TERM_AND_CONDITIONS_CONTENT}
            </h3>
          </div>
        </div>

        <div className={`${styles[theme]} ${styles.acceptArea}`}>
          <CheckBox
            name="accept_terms"
            value={genericAccept}
            onClick={check => setGenericAccept(check)}
          >
            {intl.ACCEPT_TERMS}
          </CheckBox>
          <CheckBox
            name="accept_terms_2"
            value={genericAccept2}
            onClick={check => setGenericAccept2(check)}
          >
            {intl.ACCEPT_TERMS_2}
          </CheckBox>
        </div>
        <div className={styles.footer}>
          <Button
            disabled={!(genericAccept && genericAccept2)}
            onClick={() => setStep(2)}
            label={intl.BTN_NEXT_STEP}
          />
        </div>
      </>
    );
  };

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <ShadowBox>{termsAndConditionsContent()}</ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
