import StepNumberIcon from '../../../assets/icons/StepNumberIcon';
import CircleDoneIcon from '../../../assets/icons/CircleDoneIcon';
import styles from './StepCircle.module.scss';

interface StepCircleProps {
  number: number;
  concluded?: boolean;
}

const StepCircle = ({ number, concluded }: StepCircleProps) => {
  const step = number > 0 && number < 5 ? number : 1;

  return (
    <div className={styles.container}>
      {concluded ? (
        <CircleDoneIcon color="Duotone" surface="Inverse" />
      ) : (
        <StepNumberIcon color="Duotone" surface="Inverse" step={step} />
      )}
    </div>
  );
};

export default StepCircle;
