import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './ReimburserdArea.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

const ReimburserdArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, handleBlur, errors, setFieldTouched } = props;
  const amountClaimedValue = () => {
    let result = 0;
    values.incident.expenses.map(item => {
      if (item.amount.value) {
        result += Number(item.amount.value);
      }
    });
    result -= Number(values.other_insurance_covering.value);
    const value = result > 0 ? result.toFixed(2) : '0.00';
    return value;
  };

  return (
    <div className={`row ${styles.reimburserdArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={amountClaimedValue()}
          label={intl.LABEL_TOTAL_AMOUNT_REIMBURSERD}
          name="total_amount_claimed.value"
          type="text"
          disabled
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
          dataTestid="InputReimburserd"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.incident.expenses[0].amount.currency}
          name="total_amount_claimed.currency"
          disabled
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
          dataTestid="comboboxCurrency"
        />
      </div>
    </div>
  );
};

export default ReimburserdArea;
