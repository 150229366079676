import { useContext, MutableRefObject, ReactNode } from 'react';
import styles from './AcordeonSession.module.scss';
import Button from '../../componentsV2/Button/Button';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import ModalInformation from '../ModalInformation/ModalInformation';
import { StateContext } from '../StateContextParent/StateContextParent';
import { joinClassNames } from '../../utils/classNames';

interface AcordeonSessionProps {
  title: string | ReactNode;
  openButtonRef?: MutableRefObject<HTMLButtonElement>;
  notOpen?: boolean;
  next?: () => void;
  back?: () => void;
  status?: boolean;
  setStatus?: () => void;
  txtBtnNext?: string;
  id?: string;
  axa?: boolean;
  isForm?: boolean;
  hideBackBtn?: boolean;
  disableNextButton?: boolean;
  openModalExit?: boolean;
  hasFooter?: boolean;
  className?: string;
  children?: ReactNode;
}

const AcordeonSession = ({
  title,
  openButtonRef,
  notOpen,
  next,
  back,
  status,
  setStatus,
  txtBtnNext = '',
  id,
  axa,
  isForm = true,
  hideBackBtn,
  disableNextButton,
  openModalExit,
  hasFooter = true,
  className,
  children,
}: AcordeonSessionProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const childrenStyle = status ? {} : { display: 'none' };
  const txtNext =
    txtBtnNext && txtBtnNext.length > 0 ? txtBtnNext : intl.BTN_NEXT;
  const { getGlobalTheme } = useContext(ThemeContext);
  const { actions } = useContext(StateContext);
  const themes = getGlobalTheme();

  const backButtonDivClassName = hideBackBtn
    ? `${styles.divButton} ${styles.hiddenButton}`
    : styles.divButton;

  const handleOpenModalExit = () => {
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={intl.EXIT_TITLE}
        subtitle={intl.CHANGES_NOT_SAVED_MESSAGE}
        textBtn={intl.BUTTON_CONTINUE}
        textBtnDeny={intl.BUTTON_CLOSE}
        clickBtn={() => {
          back();
          actions.modal.closeModal();
        }}
        clickBtnDeny={() => {
          actions.modal.closeModal();
        }}
      />,
      false,
      false
    );
  };

  return (
    <div
      id={id}
      data-testid="accordeonSession"
      className={`${styles.acordeonSession} ${styles.contentForm} ${
        status ? styles.acordeonOpen : ''
      } ${styles[themes]}${className ? ` ${className}` : ''}`}
    >
      <button
        ref={openButtonRef}
        type="submit"
        style={{ display: 'none' }}
        onClick={() => {
          if (!notOpen) setStatus();
        }}
      ></button>
      <div
        className={styles.title}
        data-testid="btnOpen"
        onClick={() => {
          if (!notOpen) setStatus();
        }}
      >
        {typeof title === 'string' ? (
          <h6 className={`${styles[themes]} ${axa ? styles.axa : ''}`}>
            {title}
          </h6>
        ) : (
          title
        )}
        {!notOpen && (
          <span>
            {status ? (
              <span
                className={joinClassNames(
                  axa ? styles.iconAXA : styles.icon,
                  styles[themes]
                )}
              >
                <ChevronGeneric
                  rotate={180}
                  width={20}
                  height={20}
                  colorOverride={{ primary: 'inherit', secondary: 'inherit' }}
                />
              </span>
            ) : (
              <span
                className={joinClassNames(
                  axa ? styles.iconAXA : styles.icon,
                  styles[themes]
                )}
              >
                <ChevronGeneric
                  width={20}
                  height={20}
                  colorOverride={{ primary: 'inherit', secondary: 'inherit' }}
                />
              </span>
            )}
          </span>
        )}
      </div>
      <div
        className={`${styles.containerForm} ${styles[themes]}`}
        style={childrenStyle}
      >
        <div className={styles.mandatoryMessage}>
          {isForm && <span>{intl.MANDATORY_FIELDS}</span>}
        </div>
        {children}
        {hasFooter && (
          <div className={`${styles.footer} ${styles[themes]}`}>
            <div data-testid="btnBack" className={backButtonDivClassName}>
              <Button
                type="Secondary"
                axa={axa}
                onClick={openModalExit ? handleOpenModalExit : back}
                disabled={hideBackBtn}
                id={`btnBack-${id}`}
                label={intl.BTN_BACK}
              />
            </div>
            <div data-testid="btnNext" className={styles.divButton}>
              <Button
                type="Primary"
                axa={axa}
                onClick={next}
                id={`btnNext-${id}`}
                disabled={disableNextButton}
                label={txtNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcordeonSession;
