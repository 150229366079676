import { useContext } from 'react';
import * as translations from '../../intl';
import styles from './ItemProtection.module.scss';
import { IntlContext } from '../../../../../../../../../intl';
import Input from '../../../../../../../../../components/Input/Input';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import GroupDivisor from '../../../../../../../../../components/GroupDivisor';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const ItemProtection = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    _touched,
    handleBlur,
    _errors,
    setFieldValue,
    index,
  } = props;

  const removeItem = event => {
    event.preventDefault();

    const itens = [...values.purchase_protection_items];
    const priceFromItemRemoved = itens[index].price.value
      ? itens[index].price.value
      : 0;
    const diff = values.amount_claimed.value - priceFromItemRemoved;
    setFieldValue('amount_claimed.value', diff);
    itens.splice(index, 1);
    setFieldValue('purchase_protection_items', itens);
  };

  const sumValues = () => {
    const itemValue = values.purchase_protection_items[index].price.value;
    setFieldValue(
      'amount_claimed.value',
      values.amount_claimed.value + itemValue
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <GroupDivisor title={intl.ITEM_LABEL(index + 1)} />
        </div>
        <div className="col-12 col-md-6">
          <Input
            value={values.purchase_protection_items[index].label}
            label={intl.LABEL_ITEM}
            name={`purchase_protection_items[${index}].label`}
            type="text"
            onChange={handleChange}
            touched={_touched && _touched.label}
            onBlur={handleBlur}
            error={_errors && _errors.label}
            maxLength="100"
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            value={values.purchase_protection_items[index].price.value}
            label={intl.LABEL_ESTIMATED_VALUE}
            name={`purchase_protection_items[${index}].price.value`}
            type="text"
            maskedMoney
            onChange={e => {
              sumValues();
              handleChange(e);
            }}
            tooltipText={intl.TEXT_AMOUNT_DECIMAL}
            touched={_touched && _touched.price && _touched.price.value}
            onBlur={handleBlur}
            error={_errors && _errors.price && _errors.price.value}
          />
        </div>
        {index > 0 && (
          <div className={`col-2 ${styles.footer}`}>
            <Button
              type="Tertiary"
              data-testid={`BtnRemoveExpenseItem-${index}`}
              onClick={removeItem}
              label={intl.BTN_REMOVE}
              leadingIcon={
                <TrashIcon
                  width={24}
                  height={24}
                  aria-label={intl.ICON_DELETE}
                />
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemProtection;
