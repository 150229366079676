export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const TEXT_SEARCH_CERTIFICATES = {
  en_US: 'Search certificates',
  pt_BR: 'Pesquisar bilhetes',
  es_ES: 'Buscar certificados',
};

export const VALIDATION_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};
