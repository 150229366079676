import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/ExtendedWarranty/consts';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const affectedPerson =
    claimDetails && claimDetails.affected_person
      ? claimDetails.affected_person
      : {};

  const affectedPersonRelationship =
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';

  const person =
    affectedPerson && affectedPerson.person ? affectedPerson.person : {};

  const personFirstName = person && person.first_name ? person.first_name : '-';

  const personLastName = person && person.last_name ? person.last_name : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={affectedPersonRelationship}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_NAME_AFFECTED}
          value={personFirstName}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME_AFFECTED}
          value={personLastName}
        />
      </div>
    </>
  );
};

export default PersonalArea;
