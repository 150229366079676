/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../../intl';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from '../intl';
import styles from '../ConcernedItems.module.scss';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../../../utils/sanitize';
import GroupDivisor from '../../../../../../../../../components/GroupDivisor';

const TotalSum = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const {
    values,
    index,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
  } = props;

  useEffect(() => {
    let sum = 0;
    values.incident.concerned_items.forEach(({ price: { value } }) => {
      if (value) sum += parseFloat(value);
    });
    if (values.other_company_covering.value) {
      sum = sum - values.other_company_covering.value;
    }
    if (values.other_insurance_covering.value) {
      sum = sum - values.other_insurance_covering.value;
    }
    setFieldValue('total_amount_claimed.value', sum.toFixed(2));
  }, [
    values.incident.concerned_items,
    values.other_company_covering.value,
    values.other_insurance_covering.value,
  ]);

  return (
    <div className={styles.item}>
      <div className="row">
        <div className="col-12">
          <GroupDivisor title={intl.TOTAL_SUM} />
        </div>
        <div className="col-12 col-md-6">
          <Input
            value={values.total_amount_claimed.value}
            name="total_amount_claimed.value"
            dataTestid={`InputItemValue-${index}`}
            label={intl.LABEL_TOTAL_REIMBURSED}
            tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
            maskedMoney
            error={
              errors &&
              errors.total_amount_claimed &&
              errors.total_amount_claimed.value
            }
            touched={
              touched &&
              touched.total_amount_claimed &&
              touched.total_amount_claimed.value
            }
          />
        </div>
        <div className={`col-12 col-sm-12 col-md-6`}>
          <Select
            dataTestid="selectAmountCurrency"
            label={intl.LABEL_CURRENCY}
            name="total_amount_claimed.currency"
            value={values.total_amount_claimed.currency}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={
              touched.total_amount_claimed &&
              touched.total_amount_claimed.currency
            }
            error={
              errors.total_amount_claimed &&
              errors.total_amount_claimed.currency
            }
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TotalSum;
