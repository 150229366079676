import moment from 'moment';
import { getInitialsCountry } from '../../../../intl/utils';
import { getCurrencyByCountry } from '../../../../utils/currency';
import {
  LIST_ELIGIBLE_CARDS_UPSELL,
  PRODUCT_CODE,
  LIST_ELIGIBLE_CARDS_CROSSELL,
} from '../const/ProductCode';

export const verifyProductCode = (name, list_eligible_cards) => {
  const stringToArray = name && name.toUpperCase().split(' ');
  const checkEligibleCards = list_eligible_cards.find(card =>
    stringToArray.includes(card)
  );

  return PRODUCT_CODE[checkEligibleCards];
};

const createDateByType = (type, addOneYear = false) => {
  const date = addOneYear ? moment().add(365, 'days') : new Date();
  return type === 'upsell' ? date : '';
};

const valueInitialDate = (quote, type) => {
  const dateValueByType = createDateByType(type);
  return dateValueByType;
};

const valueEndDate = (quote, type) => {
  const dateValueByType = createDateByType(type, true);
  return dateValueByType;
};

const valueCountryResidence = (quote, card) => {
  const quoteValue = quote && quote.CountryOfResidenceIsoCode;
  const countryValue = quoteValue ? quoteValue : getInitialsCountry(card);

  return countryValue;
};

const valueAmountCurrency = (quote, card) => {
  const quoteValue = quote && quote.CountryOfResidenceIsoCode;
  const initialsCountry = getInitialsCountry(card);
  return quoteValue ? quoteValue : getCurrencyByCountry(initialsCountry);
};

export const formQuoteInitialValues = (quote, card, type) => {
  const Quote = quote && quote.Quote ? quote.Quote : null;

  return {
    Quote: {
      AmountCurrencyISOCode: valueAmountCurrency(quote, card),
      CountryOfResidenceIsoCode: valueCountryResidence(quote, card),
      DestinationCountryIsoCode:
        Quote && Quote.DestinationCountryIsoCode
          ? Quote.DestinationCountryIsoCode
          : '',
      TripInitialDate: valueInitialDate(quote, type),
      TripEndDate: valueEndDate(quote, type),
      Ages: Quote && Quote.Ages ? Quote.Ages : [],
      IncludesEurope:
        Quote && Quote.IncludesEurope ? Quote.IncludesEurope : false,
      numberTraleversInput:
        Quote && Quote.numberTraleversInput ? Quote.numberTraleversInput : 0,
      recaptcha: Quote && Quote.recaptcha ? Quote.recaptcha : '',
      ProductCode:
        type === 'upsell'
          ? verifyProductCode(card.name, LIST_ELIGIBLE_CARDS_UPSELL)
          : verifyProductCode(card.name, LIST_ELIGIBLE_CARDS_CROSSELL),
      ProductType: type,
    },
  };
};
