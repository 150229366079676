import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PosScreenIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20 1H4C2.3457 1 1 2.3457 1 4V16C1 17.6543 2.3457 19 4 19H7.41669C7.25128 19.2664 7.06311 19.5232 6.8291 19.7578L6 20.5859V23H18V20.5859L17.1719 19.7578C16.9374 19.5234 16.749 19.2665 16.5834 19H20C21.6543 19 23 17.6543 23 16V4C23 2.3457 21.6543 1 20 1ZM15.5918 21H8.40869C8.96533 20.4011 9.36969 19.725 9.63647 19H14.3635C14.6305 19.7253 15.035 20.4015 15.5918 21ZM21 16C21 16.5518 20.5518 17 20 17H4C3.44873 17 3 16.5518 3 16V4C3 3.44873 3.44873 3 4 3H20C20.5518 3 21 3.44873 21 4V16Z"
        fill={config_icon.primary}
      />
      <path d="M19 13H9V15H19V13Z" fill={config_icon.secondary} />
      <path d="M15 9H13V11H15V9Z" fill={config_icon.secondary} />
      <path d="M11 9H9V11H11V9Z" fill={config_icon.secondary} />
      <path d="M19 9H17V11H19V9Z" fill={config_icon.secondary} />
      <path d="M15 5H13V7H15V5Z" fill={config_icon.secondary} />
      <path d="M11 5H9V7H11V5Z" fill={config_icon.secondary} />
      <path d="M19 5H17V7H19V5Z" fill={config_icon.secondary} />
    </svg>
  );
};

export default PosScreenIcon;
