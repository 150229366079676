import * as Yup from 'yup';
import { pt_BR } from '../../../../../../intl/idioms';
import moment from 'moment';
import listCountries from '../../../../../../intl/countryPrefix.json';

const initialsCountriesOptions = listCountries.map(
  countryItem => countryItem.initials
);

export const generalInformationSchema = (
  intl,
  isCertificate,
  isBrazil,
  isEditProfile,
  card,
  isExtendedWarrantyCertificate,
  isTravelCertificate
) => {
  const isCTA = card.name.includes('CTA') && isCertificate;
  const isTravelOrExtendedWarranty =
    isTravelCertificate || isExtendedWarrantyCertificate;

  return Yup.lazy(values => {
    const schema = {
      person: Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .matches(/([a-zA-Z])/, intl.VALIDATION_NAME_LAST_NAME),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .matches(/([a-zA-Z])/, intl.VALIDATION_NAME_LAST_NAME),
        birth_date: Yup.mixed()
          .test('match', intl.VALIDATE_DATE_VALID, function (birth_date) {
            const dateForValidation = moment('1900-01-01').format('YYYY-MM-DD');
            const birthDateForValidation =
              moment(birth_date).format('YYYY-MM-DD');
            if (
              moment(birthDateForValidation).isSameOrBefore(dateForValidation)
            ) {
              return false;
            }
            let yesterday = moment(new Date())
              .subtract(1, 'days')
              .format('YYYY-MM-DD HH:ss');
            return moment(birth_date).isSameOrBefore(yesterday);
          })
          .typeError(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .test('gender', intl.TEXT_FIELD_REQUIRED, function (value) {
            if (value === 'ML' || value === 'FM' || value === 'UNKNOWN')
              return true;
            return false;
          })
          .required(intl.TEXT_FIELD_REQUIRED),
      }),
      address: Yup.object().shape({
        invalidCep: Yup.boolean().test(
          'invalid ajax cep',
          'invalid ajax cep',
          function (value) {
            if (this.parent.country !== 'BR') return true;
            if (value === true) return false;
            return true;
          }
        ),
        country: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .oneOf(initialsCountriesOptions, intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(30, intl.TEXT_FIELD_VALIDATION_30),
        postal_code: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
            let cep = value.replace('-', '');
            cep = cep.replace(' ', '');

            if (this.parent.country !== 'BR') {
              return cep.length > 0 && cep.length <= 8;
            }
            return cep.length === 8;
          })
          .typeError(intl.TEXT_FIELD_REQUIRED),
        state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        street_address: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
        subdivision:
          isBrazil && isCertificate && isTravelOrExtendedWarranty
            ? Yup.string().required(intl.TEXT_FIELD_REQUIRED)
            : Yup.string(),
      }),
      person_registrations: Yup.array().of(
        Yup.lazy(valuesRegistration => {
          return Yup.object().shape({
            value: Yup.string()
              .test(
                'check if is cardholder',
                intl.TEXT_FIELD_REQUIRED,
                function (value) {
                  if (isCTA && valuesRegistration.registration_type === 'CPF') {
                    return true;
                  } else if (
                    isBrazil &&
                    !isCTA &&
                    (valuesRegistration.registration_type === 'PASSPORT' ||
                      valuesRegistration.registration_type === 'ID_CARD' ||
                      valuesRegistration.registration_type === '')
                  ) {
                    return true;
                  }
                  return value !== '' ? true : false;
                }
              )
              .when(
                ['registration_type'],
                {
                  is: registration_type => {
                    return registration_type === 'CPF';
                  },
                  then: Yup.string().test(
                    'test-data-valid',
                    intl.VALIDATE_CPF,
                    function (value) {
                      if (isCTA && value === '') {
                        return true;
                      }
                      return validateCPF(value);
                    }
                  ),
                },
                ['registration_type']
              ),
            registration_type: Yup.string().test(
              'required, except BR not CTA',
              intl.TEXT_FIELD_REQUIRED,
              function (value) {
                if (
                  isBrazil &&
                  !isCTA &&
                  (valuesRegistration.registration_type === 'PASSPORT' ||
                    valuesRegistration.registration_type === 'ID_CARD' ||
                    valuesRegistration.registration_type === '')
                ) {
                  return true;
                }
                return value != '' ? true : false;
              }
            ),
          });
        })
      ),
      phone: Yup.object().shape({
        phone_type: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      bank:
        !isCertificate &&
        !isEditProfile &&
        Yup.object().shape({
          name: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          last_number: Yup.string()
            .length(4, intl.VALIDATE_4_NUMBERS)
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        }),
      email: Yup.string()
        .email(intl.VALIDATE_EMAIL_VALID)
        .required(intl.TEXT_FIELD_REQUIRED),
      nationalities: Yup.string()
        .length(2, intl.TEXT_FIELD_REQUIRED)
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
    };
    return Yup.object().shape(schema);
  });
};

export function validateCPF(cpf) {
  cpf = cpf.replace(/\D/g, '');
  if (cpf.toString().length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;
  var result = true;
  [9, 10].forEach(function (j) {
    var soma = 0,
      r;
    cpf
      .split(/(?=)/)
      .splice(0, j)
      .forEach(function (e, i) {
        soma += parseInt(e) * (j + 2 - (i + 1));
      });
    r = soma % 11;
    r = r < 2 ? 0 : 11 - r;
    if (r.toString() !== cpf.substring(j, j + 1)) result = false;
  });
  return result;
}
