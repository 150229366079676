import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './NotesArea.module.scss';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const NotesArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={`${styles.notes}`}>
      <label className={`${styles.labelNotes} ${styles[themes]}`}>
        {intl.NOTES_1}
      </label>
      {
        <label
          className={`${styles.labelNotes} ${styles.topMargin} ${styles[themes]}`}
        >
          {intl.NOTES_2}
        </label>
      }
    </div>
  );
};

export default NotesArea;
