import { focusOnErrorElement } from '../../../../../../../utils/elements';

export const checkErrorsOnForm = async (submitRefs, schemas, values) => {
  submitRefs.cardholder.current.click();
  try {
    await schemas.cardholder.validate(values.cardholder);
  } catch (error) {
    focusOnErrorElement(0);
    return;
  }

  return true;
};
