/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';

import Offers from './Offers/Offers';
import * as translations from './intl';
import Carousel from '../Carousel/Carousel';
import { IntlContext } from '../../intl/index';
import styles from './RelatedOffers.module.scss';
import Button from '../../components/Button/Button';
import { offersService } from '../../services';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 1600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 990,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        dots: false,
        centerMode: false,
      },
    },
  ],
};

const BoxCarousel = ({ isPublicArea = true, currentOfferId }) => {
  const [content, setContent] = useState([]);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const history = useHistory();
  const theme = getGlobalTheme();

  useEffect(() => {
    loadDataAndSetOffers();
  }, [currentOfferId]);

  const loadDataAndSetOffers = async () => {
    try {
      const { data } = await offersService.getOffers();
      const offersFiltered = data?.filter(offer => offer.id !== currentOfferId);
      setContent(offersFiltered);
    } catch (error) {}
  };

  function handleNavigateTo() {
    history.push(isPublicArea ? `/offers` : `/your-offers`);
  }

  return (
    <div className={styles.container}>
      <div className={`${styles.contentTitle} ${styles[theme]}`}>
        <h2 className={`${styles.title} ${styles[theme]}`}>
          {intl.TITLE_RELATED_OFFERS}
        </h2>
        <Button
          onClick={handleNavigateTo}
          type="borderBlue"
          width="180px"
          height="53px"
          id="viewOffers-viewAll"
        >
          {intl.BTN_VIEW_ALL_OFFERS}
        </Button>
      </div>
      <div className={styles.boxCarousel}>
        <Carousel settings={settings}>
          {content &&
            content.map(offer => (
              <Offers
                key={offer.id}
                offer={offer}
                isPublicArea={isPublicArea}
              />
            ))}
        </Carousel>
      </div>

      <div className={styles.contentButtonMobile}>
        <Button
          onClick={handleNavigateTo}
          type="borderBlue"
          width="180px"
          height="53px"
          id="viewOffers-viewAll-mobile"
        >
          {intl.BTN_VIEW_ALL_OFFERS}
        </Button>
      </div>
    </div>
  );
};

export default BoxCarousel;
