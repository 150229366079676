export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Certificate selection',
  es_ES: 'Selección de certificado',
  pt_BR: 'Seleção de bilhete',
};

export const TEXT_STEP_3 = {
  en_US: 'Claim form',
  es_ES: 'Formulario de reclamo',
  pt_BR: 'Formulário do sinistro',
};

export const TEXT_STEP_4 = {
  en_US: 'Sending documents',
  es_ES: 'Envío de documentos',
  pt_BR: 'Envio de documentos',
};

export const CERTIFICATE_SELECTION = {
  en_US: 'Selection of the associated certificate',
  es_ES: 'Selección de certificados asociados',
  pt_BR: 'Seleção do bilhete associado',
};

export const REQUIRED_VALID_CERTIFICATE = {
  en_US:
    'The opening of this claim requires a valid certificate in the incident date.',
  es_ES:
    'La apertura de este reclamo requiere un certificado válido en la fecha del incidente.',
  pt_BR:
    'A abertura deste sinistro requer um bilhete válido na data do incidente.',
};

export const OPTIONAL_SELECT_CERTIFICATE = {
  en_US: '',
  es_ES:
    'Para abrir este reclamo, es opcional seleccionar un certificado válido en la fecha del incidente.',
  pt_BR: '',
};

export const LABEL_OPTIONAL_CERTIFICATE = {
  en_US: '',
  es_ES: '¿Quieres seleccionar un certificado?',
  pt_BR: '',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};
