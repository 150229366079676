export const TEXT_CELLPHONE = {
  en_US: 'Cell phone',
  pt_BR: 'Celular',
  es_ES: 'Teléfono celular',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const LABEL_MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const LABEL_ESTIMATED_CELL_PHONE_VALUE = {
  en_US: 'Estimated value',
  pt_BR: 'Valor estimado',
  es_ES: 'Valor estimado',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss',
  pt_BR: 'Tipo de perda',
  es_ES: 'Tipo de pérdida',
};

export const LABEL_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const LABEL_THEFT = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_CELL_PHONE_REPAIRABLE = {
  en_US: 'Is repairable?',
  pt_BR: 'Pode ser consertado?',
  es_ES: 'Es reparable?',
};

export const LABEL_REPAIR_VALUE = {
  en_US: 'Repair value',
  pt_BR: 'Valor de reparo',
  es_ES: 'Valor de la reparación',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Amount paid by the insurance',
  pt_BR: 'Valor pago pela companhia de seguros',
  es_ES: 'Monto pagado por la compañía de seguros',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const LABEL_FULL_CARD = {
  en_US:
    'Was the item purchased and paid for in full with the selected credit card?',
  pt_BR:
    'O artigo foi comprado e pago em sua totalidade com o cartão de crédito selecionado?',
  es_ES:
    '¿Se compró y pagó el artículo en su totalidad con la tarjeta de crédito seleccionada?',
};
