export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  es_ES: 'Fecha del incidente',
  pt_BR: 'Data do incidente',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss',
  es_ES: 'Tipo de pérdida',
  pt_BR: 'Tipo de perda',
};

export const LABEL_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const LABEL_STOLEN = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};
export const LABEL_THEFT = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  es_ES: 'Fabricante',
  pt_BR: 'Fabricante',
};

export const LABEL_MODEL = {
  en_US: 'Model',
  es_ES: 'Modelo',
  pt_BR: 'Modelo',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Purchase price (including tax)',
  es_ES: 'Precio de compra (incluyendo impuestos)',
  pt_BR: 'Preço de compra (incluindo impostos)',
};

export const LABEL_REPAIR_VALUE = {
  en_US: 'Repair value',
  es_ES: 'Valor de la reparación',
  pt_BR: 'Valor de reparo',
};

export const LABEL_TOTAL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  es_ES: 'Importe total de la reivindicación',
  pt_BR: 'Valor total do sinistro',
};
