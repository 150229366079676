import { removeNonNumeric } from '../../../../../../utils/stringUtils';
import moment from 'moment';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../utils/environments_variables';

export const prepareDependents = values => {
  const data = values.map(value => {
    value.person.nationalities = [value.nationalities];
    // remove invalidCep
    delete value.address.invalidCep;
    // format postal code
    value.address.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
      value.address.country
    )
      ? value.address.postal_code
      : removeNonNumeric(value.address.postal_code);
    let isCpf = value.person_registrations.registration_type === 'CPF';

    return {
      person: value.person,
      person_registrations: [
        {
          registration_type: value.person_registrations.registration_type,
          value: isCpf
            ? removeNonNumeric(value.person_registrations.value)
            : value.person_registrations.value,
        },
      ],
      address: value.address,
      email: value.email,
    };
  });

  return data;
};

export const prepareTravelDependents = dependents => {
  return dependents.map(dependent => {
    let formatDependent = {
      Name_as_it_appears_in_passport: dependent.person.first_name,
      Surname: dependent.person.last_name,
      Birthdate: moment(dependent.person.birth_date).format('YYYY-MM-DD'),
      Relationship_of_the_dependent: dependent.relationship,
      Nationality: [dependent.nationalities],
      Barrio: dependent.address.subdivision,
      City: dependent.address.locality,
      Email: dependent.email,
      State: dependent.address.state,
      street_address: dependent.address.street_address,
      postal_code: ALPHANUMERIC_COUNTRY_LIST.includes(dependent.address.country)
        ? dependent.address.postal_code
        : removeNonNumeric(dependent.address.postal_code),
      gender: dependent.person.gender,
      country: dependent.address.country,
    };

    const indexCPF = dependent.person_registrations.findIndex(
      registration => registration.registration_type === 'CPF'
    );
    const indexPassport = dependent.person_registrations.findIndex(
      registration => registration.registration_type === 'PASSPORT'
    );
    const indexIdCard = dependent.person_registrations.findIndex(
      registration => registration.registration_type === 'ID_CARD'
    );

    if (
      indexCPF !== -1 &&
      dependent.person_registrations[indexCPF].value !== ''
    ) {
      formatDependent.CPF = removeNonNumeric(
        dependent.person_registrations[indexCPF].value
      );
    }

    if (
      indexPassport !== -1 &&
      dependent.person_registrations[indexPassport].value !== ''
    ) {
      formatDependent.PASSPORT =
        dependent.person_registrations[indexPassport].value;
    }

    if (
      indexIdCard !== -1 &&
      dependent.person_registrations[indexIdCard].value !== ''
    ) {
      formatDependent.ID_CARD =
        dependent.person_registrations[indexIdCard].value;
    }

    return formatDependent;
  });
};

export const createArrayObjectDependentsMembers = (
  beneficiaries,
  dependents
) => {
  return beneficiaries.map(beneficiary => {
    beneficiary.Dependent_members = [...dependents];
    return beneficiary;
  });
};
