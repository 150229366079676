import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import styles from './DeviceInfo.module.scss';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';

const DeviceInfo = props => {
  const { devicesInfo } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const deviceShortenToken =
    devicesInfo.external_communication_device_id.substring(0, 32) + '...';

  return (
    <div className={styles.bankInfoItem}>
      <div className="row">
        <div className="col-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_DESCRIPTION}
            value={devicesInfo.description}
          />
        </div>
        <div className="col-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_IDENTIFICATION_NUMBER}
            value={deviceShortenToken}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceInfo;
