/* eslint-disable no-console */
import React, { useContext } from 'react';
import PaymentInformation from '../../../../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/PaymentInformation';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../intl';
import { checkBankInfoChange } from '../../../../../Claims/Generate/ClaimFillForm/Form/submit/checkBankInfoChange/index';
import * as translations from './intl';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const FormBank = props => {
  const {
    initialValues,
    cardHolderData,
    isProfile,
    values,
    refreshData,
    setBankAccount,
  } = props;
  const { actions, utils } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  utils.getCountry();
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const cn = utils.getCn();

  const submitInProfile = async () => {
    try {
      const data = {
        initialValues: { reimbursement: initialValues },
        values: {
          reimbursement: values.reimbursement,
          policy: cardHolderData,
        },
        actions,
        intl: intl,
        idiom: idiom,
        cn,
      };
      trackEventUserAction('#### (BANK) NOVO BANCO SERÁ ADICIONADO ####', data);

      await checkBankInfoChange(
        trackEventUserAction,
        data,
        false,
        isProfile,
        refreshData,
        setBankAccount
      );
      trackEventUserAction('#### (BANK) ADICIONADO COM SUCESSO ####', data);
    } catch (error) {
      trackEventUserAction('#### (BANK) ERRO AO ADICIONAR UM NOVO BANCO ####', {
        error: error,
        error_response: error && error.response ? error.response : null,
      });
      console.log(error);
    }
  };

  return <PaymentInformation submitInProfile={submitInProfile} {...props} />;
};

export default FormBank;
