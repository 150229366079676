import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './FileCard.module.scss';
import Chip from '../../Chip/Chip';
import DeleteIcon from '../../../assets/iconsV2/Actions/DeleteIcon';
import DownloadIcon from '../../../assets/iconsV2/Actions/DownloadIcon';
import Loader from '../../Loader/Loader';
import ReloadIcon from '../../../assets/iconsV2/Actions/ReloadIcon';
import { FileStatus } from '../FileUploader';
import GenericDocumentIcon from '../../../assets/iconsV2/Documents/GenericDocumentIcon';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import { ReactElement } from 'react';

export interface UploadedFile {
  id: number | string;
  name: string;
}

export interface FileCardProps {
  uploadedFile: UploadedFile;
  fileStatus: FileStatus;
  removeFile: (isError: boolean) => Promise<void>;
  reloadFile?: () => Promise<void>;
  getFileToDownload?: () => Promise<File>;
  CustomChip?: () => ReactElement;
}

const FileCard = ({
  uploadedFile,
  fileStatus,
  removeFile,
  reloadFile,
  getFileToDownload,
  CustomChip,
}: FileCardProps) => {
  const { getGlobalTheme, getCurrentThemeColors, getColors } = useTheme();

  const { translate } = useIntl();
  const intl = translate(translations);

  const theme = getGlobalTheme();
  const { const_gray } = getCurrentThemeColors();
  const { genericColors } = getColors();

  const isLoading = fileStatus === 'SENDING';
  const isError = fileStatus === 'NOT SENT';
  const isSent = fileStatus === 'SENT';

  const chipLabel = isSent ? intl.UPLOADED : intl.ERROR;
  const chipStatus = isSent ? 'POSITIVE' : 'NEGATIVE';

  const handleDownloadFile = async () => {
    const file = await getFileToDownload();

    if (file) {
      const url = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = url;
      link.download = file.name;
      link.click();
    }
  };

  const handleDeleteFile = async () => {
    await removeFile(isError);
  };

  const handleReloadFile = async () => {
    await reloadFile();
  };

  return (
    <li className={styles.FileCardItem}>
      <div
        className={`${styles.content} ${styles[theme]}`}
        style={{ borderColor: isError ? genericColors.red_70 : const_gray }}
      >
        <GenericDocumentIcon />
        <span className={styles.fileName}>{uploadedFile?.name}</span>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={styles.actionsArea}>
              {isError ? (
                <>
                  {reloadFile !== undefined && (
                    <button onClick={handleReloadFile}>
                      <ReloadIcon />
                    </button>
                  )}
                </>
              ) : (
                <>
                  {getFileToDownload !== undefined && (
                    <button onClick={handleDownloadFile}>
                      <DownloadIcon />
                    </button>
                  )}
                </>
              )}
              <button onClick={handleDeleteFile}>
                <DeleteIcon />
              </button>
            </div>
            {CustomChip ? (
              <CustomChip />
            ) : (
              <Chip label={chipLabel} type={chipStatus} icon />
            )}
          </>
        )}
      </div>
      {isError && <span className={styles.error}>{intl.ERROR_TEXT}</span>}
    </li>
  );
};

export default FileCard;
