export const TITLE_MAINTENANCE = {
  en_US: 'Temporarily offline due to maintenance',
  pt_BR: 'Temporariamente off-line devido a manutenção',
  es_ES: 'Temporalmente fuera de línea debido a mantenimiento',
};

export const GO_HOMEPAGE = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para a Página inicial',
  es_ES: 'Ir a la Página de inicio',
};

export const PORTAL_OFFLINE = {
  en_US: `The Visa Benefits Portal is temporarily offline due to maintenance. Pleae try again in a few hours.`,
  pt_BR: `O Portal de Benefícios Visa está temporariamente off-line devido a manutenção. Por favor, tente novamente em algumas horas.`,
  es_ES: `El Portal de Beneficios de Visa está temporalmente fuera de línea debido a mantenimiento. Vuelve a intentarlo en unas horas.`,
};

export const MAINTENANCE = {
  en_US: 'Maintenance',
  es_ES: 'Mantenimiento',
  pt_BR: 'Manutenção',
};
