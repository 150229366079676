import { AxiosInstance, AxiosResponse } from 'axios';
import {
  CreateTravelResponse,
  Travel,
  TravelDetail,
  TravelDetailPost,
} from '../@types/APIs/Travels';

export default (env, axios: AxiosInstance, errorHandler) => {
  return {
    createTravel: async (
      cn: string,
      travel: TravelDetailPost
    ): Promise<AxiosResponse<CreateTravelResponse>> => {
      const url = `/api/specific/visagateway/v1/customers/${cn}/travels`;
      return errorHandler(axios.post(url, travel), 'createTravel', travel);
    },
    getTravels: async (cn: string): Promise<AxiosResponse<Travel[]>> => {
      const url = `/api/specific/visagateway/v1/customers/${cn}/travels`;
      return errorHandler(axios.get(url), 'getTravels', true);
    },
    getTravelDetail: async (
      cn: string,
      id: string
    ): Promise<AxiosResponse<TravelDetail>> => {
      const url = `/api/specific/visagateway/v1/customers/${cn}/travels/${id}`;
      return errorHandler(axios.get(url), 'getTravel', true);
    },
  };
};
