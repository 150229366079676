export const IDLE_SESSION = {
  en_US: 'You have been logged out because of inactivity.',
  pt_BR: 'Você foi desconectado por inatividade.',
  es_ES: 'Se ha cerrado la sesión por inactividad.',
};

export const SESSION_WILL_END = {
  en_US: 'You will be logged out for inactivity. Are you still there?',
  pt_BR: 'Você será desconectado por inatividade. Ainda está aí?',
  es_ES: 'Se cerrará su sesión por inactividad. ¿Todavía esta ahí?',
};

export const I_AM_HERE = {
  en_US: 'I am here',
  pt_BR: 'Eu estou aqui',
  es_ES: 'Yo estoy aquí',
};
