export const pt_banks = `
<option value="654"> 654 - Banco A.J.Renner S.A.</option>
<option value="246"> 246 - Banco ABC Brasil S.A.</option>
<option value="025"> 025 - Banco Alfa S.A.</option>
<option value="641"> 641 - Banco Alvorada S.A.</option>
<option value="213"> 213 - Banco Arbi S.A.</option>
<option value="019"> 019 - Banco Azteca do Brasil S.A.</option>
<option value="029"> 029 - Banco Banerj S.A.</option>
<option value="000"> 000 - Banco Bankpar S.A.</option>
<option value="740"> 740 - Banco Barclays S.A.</option>
<option value="107"> 107 - Banco BBM S.A.</option>
<option value="031"> 031 - Banco Beg S.A.</option>
<option value="739"> 739 - Banco BGN S.A.</option>
<option value="096"> 096 - Banco BM&F de Serviços de Liquidação e Custódia S.A</option>
<option value="318"> 318 - Banco BMG S.A.</option>
<option value="752"> 752 - Banco BNP Paribas Brasil S.A.</option>
<option value="248"> 248 - Banco Boavista Interatlântico S.A.</option>
<option value="218"> 218 - Banco Bonsucesso S.A.</option>
<option value="065"> 065 - Banco Bracce S.A.</option>
<option value="036"> 036 - Banco Bradesco BBI S.A.</option>
<option value="204"> 204 - Banco Bradesco Cartões S.A.</option>
<option value="394"> 394 - Banco Bradesco Financiamentos S.A.</option>
<option value="237"> 237 - Banco Bradesco S.A.</option>
<option value="225"> 225 - Banco Brascan S.A.</option>
<option value="M15"> M15 - Banco BRJ S.A.</option>
<option value="208"> 208 - Banco BTG Pactual S.A.</option>
<option value="044"> 044 - Banco BVA S.A.</option>
<option value="263"> 263 - Banco Cacique S.A.</option>
<option value="473"> 473 - Banco Caixa Geral - Brasil S.A.</option>
<option value="412"> 412 - Banco Capital S.A.</option>
<option value="040"> 040 - Banco Cargill S.A.</option>
<option value="266"> 266 - Banco Cédula S.A.</option>
<option value="745"> 745 - Banco Citibank S.A.</option>
<option value="M08"> M08 - Banco Citicard S.A.</option>
<option value="241"> 241 - Banco Clássico S.A.</option>
<option value="M19"> M19 - Banco CNH Capital S.A.</option>
<option value="215"> 215 - Banco Comercial e de Investimento Sudameris S.A.</option>
<option value="756"> 756 - Banco Cooperativo do Brasil S.A. - BANCOOB</option>
<option value="748"> 748 - Banco Cooperativo Sicredi S.A.</option>
<option value="075"> 075 - Banco CR2 S.A.</option>
<option value="721"> 721 - Banco Credibel S.A.</option>
<option value="222"> 222 - Banco Credit Agricole Brasil S.A.</option>
<option value="505"> 505 - Banco Credit Suisse (Brasil) S.A.</option>
<option value="229"> 229 - Banco Cruzeiro do Sul S.A.</option>
<option value="003"> 003 - Banco da Amazônia S.A.</option>
<option value="083-3"> 083-3 - Banco da China Brasil S.A.</option>
<option value="M21"> M21 - Banco Daimlerchrysler S.A.</option>
<option value="707"> 707 - Banco Daycoval S.A.</option>
<option value="300"> 300 - Banco de La Nacion Argentina</option>
<option value="495"> 495 - Banco de La Provincia de Buenos Aires</option>
<option value="494"> 494 - Banco de La Republica Oriental del Uruguay</option>
<option value="M06"> M06 - Banco de Lage Landen Brasil S.A.</option>
<option value="024"> 024 - Banco de Pernambuco S.A. - BANDEPE</option>
<option value="456"> 456 - Banco de Tokyo-Mitsubishi UFJ Brasil S.A.</option>
<option value="214"> 214 - Banco Dibens S.A.</option>
<option value="001"> 001 - Banco do Brasil S.A.</option>
<option value="047"> 047 - Banco do Estado de Sergipe S.A.</option>
<option value="037"> 037 - Banco do Estado do Pará S.A.</option>
<option value="039"> 039 - Banco do Estado do Piauí S.A. - BEP</option>
<option value="041"> 041 - Banco do Estado do Rio Grande do Sul S.A.</option>
<option value="004"> 004 - Banco do Nordeste do Brasil S.A.</option>
<option value="265"> 265 - Banco Fator S.A.</option>
<option value="M03"> M03 - Banco Fiat S.A.</option>
<option value="224"> 224 - Banco Fibra S.A.</option>
<option value="626"> 626 - Banco Ficsa S.A.</option>
<option value="M18"> M18 - Banco Ford S.A.</option>
<option value="233"> 233 - Banco GE Capital S.A.</option>
<option value="734"> 734 - Banco Gerdau S.A.</option>
<option value="M07"> M07 - Banco GMAC S.A.</option>
<option value="612"> 612 - Banco Guanabara S.A.</option>
<option value="M22"> M22 - Banco Honda S.A.</option>
<option value="063"> 063 - Banco Ibi S.A. Banco Múltiplo</option>
<option value="M11"> M11 - Banco IBM S.A.</option>
<option value="604"> 604 - Banco Industrial do Brasil S.A.</option>
<option value="320"> 320 - Banco Industrial e Comercial S.A.</option>
<option value="653"> 653 - Banco Indusval S.A.</option>
<option value="630"> 630 - Banco Intercap S.A.</option>
<option value="077-9"> 077-9 - Banco Intermedium S.A.</option>
<option value="249"> 249 - Banco Investcred Unibanco S.A.</option>
<option value="M09"> M09 - Banco Itaucred Financiamentos S.A.</option>
<option value="184"> 184 - Banco Itaú BBA S.A.</option>
<option value="479"> 479 - Banco ItaúBank S.A</option>
<option value="376"> 376 - Banco J. P. Morgan S.A.</option>
<option value="074"> 074 - Banco J. Safra S.A.</option>
<option value="217"> 217 - Banco John Deere S.A.</option>
<option value="076"> 076 - Banco KDB S.A.</option>
<option value="757"> 757 - Banco KEB do Brasil S.A.</option>
<option value="600"> 600 - Banco Luso Brasileiro S.A.</option>
<option value="212"> 212 - Banco Matone S.A.</option>
<option value="M12"> M12 - Banco Maxinvest S.A.</option>
<option value="389"> 389 - Banco Mercantil do Brasil S.A.</option>
<option value="746"> 746 - Banco Modal S.A.</option>
<option value="M10"> M10 - Banco Moneo S.A.</option>
<option value="738"> 738 - Banco Morada S.A.</option>
<option value="066"> 066 - Banco Morgan Stanley S.A.</option>
<option value="243"> 243 - Banco Máxima S.A.</option>
<option value="151"> 151 - Banco Nossa Caixa S.A.</option>
<option value="045"> 045 - Banco Opportunity S.A.</option>
<option value="M17"> M17 - Banco Ourinvest S.A.</option>
<option value="623"> 623 - Banco Panamericano S.A.</option>
<option value="611"> 611 - Banco Paulista S.A.</option>
<option value="613"> 613 - Banco Pecúnia S.A.</option>
<option value="094-2"> 094-2 - Banco Petra S.A.</option>
<option value="643"> 643 - Banco Pine S.A.</option>
<option value="724"> 724 - Banco Porto Seguro S.A.</option>
<option value="735"> 735 - Banco Pottencial S.A.</option>
<option value="638"> 638 - Banco Prosper S.A.</option>
<option value="M24"> M24 - Banco PSA Finance Brasil S.A.</option>
<option value="747"> 747 - Banco Rabobank International Brasil S.A.</option>
<option value="088-4"> 088-4 - Banco Randon S.A.</option>
<option value="356"> 356 - Banco Real S.A.</option>
<option value="633"> 633 - Banco Rendimento S.A.</option>
<option value="741"> 741 - Banco Ribeirão Preto S.A.</option>
<option value="M16"> M16 - Banco Rodobens S.A.</option>
<option value="072"> 072 - Banco Rural Mais S.A.</option>
<option value="453"> 453 - Banco Rural S.A.</option>
<option value="422"> 422 - Banco Safra S.A.</option>
<option value="033"> 033 - Banco Santander (Brasil) S.A.</option>
<option value="250"> 250 - Banco Schahin S.A.</option>
<option value="743"> 743 - Banco Semear S.A.</option>
<option value="749"> 749 - Banco Simples S.A.</option>
<option value="366"> 366 - Banco Société Générale Brasil S.A.</option>
<option value="637"> 637 - Banco Sofisa S.A.</option>
<option value="012"> 012 - Banco Standard de Investimentos S.A.</option>
<option value="464"> 464 - Banco Sumitomo Mitsui Brasileiro S.A.</option>
<option value="082-5"> 082-5 - Banco Topázio S.A.</option>
<option value="M20"> M20 - Banco Toyota do Brasil S.A.</option>
<option value="M13"> M13 - Banco Tricury S.A.</option>
<option value="634"> 634 - Banco Triângulo S.A.</option>
<option value="136"> 136 - Banco Unicred</option>
<option value="M14"> M14 - Banco Volkswagen S.A.</option>
<option value="M23"> M23 - Banco Volvo (Brasil) S.A.</option>
<option value="655"> 655 - Banco Votorantim S.A.</option>
<option value="610"> 610 - Banco VR S.A.</option>
<option value="370"> 370 - Banco WestLB do Brasil S.A.</option>
<option value="102"> 102 - Banco XP Investimentos</option>
<option value="021"> 021 - BANESTES S.A. Banco do Estado do Espírito Santo</option>
<option value="719"> 719 - Banif-Banco Internacional do Funchal (Brasil)S.A.</option>
<option value="755"> 755 - Bank of America Merrill Lynch Banco Múltiplo S.A.</option>
<option value="744"> 744 - BankBoston N.A.</option>
<option value="073"> 073 - BB Banco Popular do Brasil S.A.</option>
<option value="078"> 078 - BES Investimento do Brasil S.A.-Banco de Investimento</option>
<option value="069"> 069 - BPN Brasil Banco Múltiplo S.A.</option>
<option value="070"> 070 - BRB - Banco de Brasília S.A.</option>
<option value="092-2"> 092-2 - Brickell S.A. Crédito, financiamento e Investimento</option>
<option value="104"> 104 - Caixa Econômica Federal</option>
<option value="477"> 477 - Citibank N.A.</option>
<option value="081-7"> 081-7 - Concórdia Banco S.A.</option>
<option value="097-3"> 097-3 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda.</option>
<option value="085-x"> 085-x - Cooperativa Central de Crédito Urbano-CECRED</option>
<option value="099-x"> 099-x - Cooperativa Central de Economia e Credito Mutuo das Unicreds</option>
<option value="090-2"> 090-2 - Cooperativa Central de Economia e Crédito Mutuo das Unicreds</option>
<option value="089-2"> 089-2 - Cooperativa de Crédito Rural da Região de Mogiana</option>
<option value="087-6"> 087-6 - Cooperativa Unicred Central Santa Catarina</option>
<option value="098-1"> 098-1 - Credicorol Cooperativa de Crédito Rural</option>
<option value="487"> 487 - Deutsche Bank S.A. - Banco Alemão</option>
<option value="751"> 751 - Dresdner Bank Brasil S.A. - Banco Múltiplo</option>
<option value="064"> 064 - Goldman Sachs do Brasil Banco Múltiplo S.A.</option>
<option value="062"> 062 - Hipercard Banco Múltiplo S.A.</option>
<option value="399"> 399 - HSBC Bank Brasil S.A.</option>
<option value="168"> 168 - HSBC Finance (Brasil) S.A. - Banco Múltiplo</option>
<option value="492"> 492 - ING Bank N.V.</option>
<option value="652"> 652 - Itaú Unibanco Holding S.A.</option>
<option value="341"> 341 - Itaú Unibanco S.A.</option>
<option value="079"> 079 - JBS Banco S.A.</option>
<option value="488"> 488 - JPMorgan Chase Bank</option>
<option value="323"> 323 - Mercado Pago</option>
<option value="014"> 014 - Natixis Brasil S.A. Banco Múltiplo</option>
<option value="753"> 753 - NBC Bank Brasil S.A. - Banco Múltiplo</option>
<option value="260"> 260 - NuBank</option>
<option value="086-8"> 086-8 - OBOE Crédito Financiamento e Investimento S.A.</option>
<option value="254"> 254 - Paraná Banco S.A.</option>
<option value="409"> 409 - UNIBANCO - União de Bancos Brasileiros S.A.</option>
<option value="230"> 230 - Unicard Banco Múltiplo S.A.</option>
<option value="091-4"> 091-4 - Unicred Central do Rio Grande do Sul</option>
<option value="084"> 084 - Unicred Norte do Paraná</option>
`;
