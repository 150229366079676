import React, { useContext } from 'react';
import { FieldArray } from 'formik';
import CheckBox from '../../../../../../components/CheckBox/CheckBox';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import RadioButtonError from '../../../../../../components/RadioButtonError/RadioButtonError';

const QuestionsNAL = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    setFieldTouched,
    setFieldValue,
    handleChange,
    touched,
    errors,
    questions,
  } = props;

  const setConfirm = (index, value, name) => {
    if (name === intl.TEXT_QUESTIONS_COVID_OTHER) {
      setFieldValue('questions.questionCommentChecked', value);
    }
    setFieldValue(index, value);
    setFieldTouched(index);
  };

  const validationCheckboxs = (value, arrayQuestions) => {
    if (value) {
      arrayQuestions.push(value);
    } else {
      const index = values.questions.questionsCheckeds.indexOf(value);
      arrayQuestions.remove(index);
    }
    setFieldTouched(questions.questionsCheckeds);
  };

  return (
    <div>
      <RadioButtonError
        touched={touched.questions && touched.questions.questionsCheckeds}
        error={errors.questions && errors.questions.questionsCheckeds}
        messageError={intl.TEXT_ONE_QUESTION_MUST_BE_SELECTED}
      />
      <FieldArray
        name="questions.questionsCheckeds"
        render={arrayQuestions => (
          <div>
            {values.questions.questions.map((question, index) => {
              return (
                <div key={index}>
                  <CheckBox
                    type="checkbox"
                    value={values.questions.questions[index].value}
                    name={`questions.questions${[index]}`}
                    onChange={e => {
                      handleChange(e);
                    }}
                    onClick={value => {
                      setConfirm(
                        `questions.questions.${[index]}.value`,
                        value,
                        question.name,
                        arrayQuestions
                      );
                      validationCheckboxs(value, arrayQuestions);
                    }}
                  >
                    <p>{question.name}</p>
                  </CheckBox>
                </div>
              );
            })}
          </div>
        )}
      />
    </div>
  );
};

export default QuestionsNAL;
