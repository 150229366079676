export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_NUMBER_VALUE = {
  en_US: 'Please check the inserted values.',
  pt_BR: 'Por favor, revise os valores inseridos.',
  es_ES: 'Por favor, revisa los valores ingresados.',
};

export const VALIDATE_DATE_BEFORE_DEPARTURE_DATE = {
  en_US: 'Please enter a date greater than or equal to the Cancellation date.',
  pt_BR:
    'Por favor, insira uma data maior ou igual a Data de cancelamento da viagem.',
  es_ES:
    'Por favor, introduzca una fecha mayor o igual a la Fecha de cancelación.',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
