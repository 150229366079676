export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Certificate selection',
  es_ES: 'Selección de certificado',
  pt_BR: 'Seleção de bilhete',
};

export const TEXT_STEP_3 = {
  en_US: 'Claim form',
  es_ES: 'Formulario de reclamo',
  pt_BR: 'Formulário do sinistro',
};

export const TEXT_STEP_4 = {
  en_US: 'Sending documents',
  es_ES: 'Envío de documentos',
  pt_BR: 'Envio de documentos',
};
