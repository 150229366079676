import React, { useContext } from 'react';
import styles from './Maintenance.module.scss';
import img from '../../assets/images/image_sessionExpired.png';
import Button from '../../componentsV2/Button/Button';
import { useHistory } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const Maintenance = () => {
  const history = useHistory();
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <div className={styles.boxInfo}>
          <h1 className={styles[themes]}>{intl.TITLE_MAINTENANCE}</h1>
          <p className={styles[themes]}>{intl.PORTAL_OFFLINE}</p>
          <div className={styles.footer}>
            <Button
              type="Primary"
              onClick={() => history.push('/')}
              label={intl.GO_HOMEPAGE}
            />
          </div>
        </div>
      </div>
      <img src={img} alt={intl.MAINTENANCE} />
    </div>
  );
};

export default Maintenance;
