import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../LegalProtection.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const ThirdPartyAddress = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { values, handleChange, touched, handleBlur, errors } = props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_THIRD_PARTY_RESIDENCE_ADDRESS} />
      </div>

      <div className="col-12 col-sm-12 col-md-6  col-lg-4">
        <Select
          label={intl.FIELD_ADDRESS_COUNTRY}
          value={values.incident.involved_person.address.country}
          name="incident.involved_person.address.country"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          onBlur={handleBlur}
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.country
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.country
          }
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-2">
        <Input
          label={intl.FIELD_ADDRESS_POSTAL_CODE}
          name="incident.involved_person.address.postal_code"
          value={values.incident.involved_person.address.postal_code}
          type="text"
          mask="maskedPostalCode"
          formikProps={props}
          dataTestid="inputAddressPostalCode"
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.postal_code
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.postal_code
          }
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          label={intl.FIELD_ADDRESS_STATE}
          name="incident.involved_person.address.state"
          onChange={handleChange}
          datatestid
          onBlur={handleBlur}
          value={values.incident.involved_person.address.state}
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.state
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.state
          }
          maxLength="100"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-3">
        <Input
          label={intl.FIELD_ADDRESS_CITY}
          name="incident.involved_person.address.locality"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.incident.involved_person.address.locality}
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.locality
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.locality
          }
          maxLength="100"
        />
      </div>

      <div className="col-12 col-lg-6">
        <Input
          value={values.incident.involved_person.address.street_address}
          label={intl.FIELD_ADDRESS_STREET}
          name="incident.involved_person.address.street_address"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.street_address
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.street_address
          }
          onBlur={handleBlur}
          maxLength="255"
        />
      </div>

      <div className="col-12 col-lg-6">
        <Input
          label={intl.FIELD_ADDRESS_NEIGHBORHOOD}
          value={values.incident.involved_person.address.subdivision}
          name="incident.involved_person.address.subdivision"
          type="text"
          maxLength="100"
          onChange={handleChange}
          onBlur={handleBlur}
          dataTestid="inputAddressSubdivision"
          touched={
            touched.incident &&
            touched.incident.involved_person &&
            touched.incident.involved_person.address &&
            touched.incident.involved_person.address.subdivision
          }
          error={
            errors.incident &&
            errors.incident.involved_person &&
            errors.incident.involved_person.address &&
            errors.incident.involved_person.address.subdivision
          }
        />
      </div>
    </div>
  );
};

export default ThirdPartyAddress;
