/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useMemo } from 'react';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import { IntlContext } from '../../../../../intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import styles from './styles.module.scss';
import {
  handleSubmitPhoneForm,
  handleSubmitVideoForm,
} from './submit/submitTeleconsultation';
import * as translations from './intl';
import * as translations_GeneralInfo from '../../../Generate/ClaimFillForm/Form/intl';
import { checkErrorsOnSession, checkErrorsOnForm } from './checkErrorsOnForm';
import { useHistory, withRouter } from 'react-router';
import { AppInsightTrackContext } from '../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import PatientArea from './PatientArea/PatientArea';
import { Formik } from 'formik';
import { differenceInCalendarYears } from 'date-fns';
import ConsultArea from './ConsultArea/CountryArea';
import AppointmentArea from './AppointmentArea';
import { teleconsutationFormInitialValues } from './initialValues';
import validationGeneralFormSchema from './validationGeneralFormSchema';
import { ConsultationPhoneType } from '../../../../../utils/consultationCategory';

const Form = props => {
  const { loadedData, setStep } = props;
  const { idiom, translate, twoDigitsIdiom } = useContext(IntlContext);
  const intl_teleconsultation = translate(translations);
  const intl_GeneralInfo = translate(translations_GeneralInfo);
  const intl = { ...intl_teleconsultation, ...intl_GeneralInfo };

  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { utils, actions } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();
  const [initalValueCompare, setInitialValueCompare] = useState('');
  const [listAccordeon, setListAccordeon] = useState({
    patient: true,
    consultation: false,
    appointment: false,
  });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const language = twoDigitsIdiom();
  const history = useHistory();

  const cn = utils.getCn();

  const initialValues = useMemo(
    () =>
      teleconsutationFormInitialValues({
        cardHolder: loadedData.cardHolder,
        language,
        differenceInCalendarYears,
      }),
    [loadedData, language]
  );
  const validationSchema = useMemo(
    () => validationGeneralFormSchema(intl),
    [intl]
  );

  const [selectedDependent, setSelectedDependent] = useState(0);

  const [schemas] = useState({});
  const [values] = useState({});

  const submitRefs = {
    patient: React.useRef(),
    consultation: React.useRef(),
    appointment: React.useRef(),
  };
  const accordeonRefs = {
    patient: React.useRef(),
    consultation: React.useRef(),
    appointment: React.useRef(),
  };

  const setStatusAccordeon = selected => {
    const newListAccordeon = { ...listAccordeon };
    Object.keys(newListAccordeon).map(key => {
      return (newListAccordeon[key] = false);
    });
    setListAccordeon({
      ...newListAccordeon,
      [selected]: !listAccordeon[selected],
    });
  };
  const handleSubmit = async () => {
    const areFormsOk = await checkErrorsOnForm(
      submitRefs,
      accordeonRefs,
      schemas,
      values,
      listAccordeon,
      trackEventUserAction
    );

    if (areFormsOk) {
      const _props = {
        loadedData,
        values,
        cn,
        selectedCard,
        actions,
        initialValues,
        intl,
        idiom,
        history: props.history,
        selectedDependent,
        initalValueCompare,
        setInitialValueCompare,
        trackEventUserAction,
      };
      values.consultation.type === ConsultationPhoneType
        ? await handleSubmitPhoneForm(_props)
        : await handleSubmitVideoForm(_props);
    }
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true
  ) => {
    const res = await checkErrorsOnSession(
      accordeonRefs,
      currentAcordeon,
      targetAcordeon,
      schemas,
      values,
      validation,
      submitRefs
    );

    return res;
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  useEffect(() => {
    setInitialValueCompare(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={styles.formTeleconsultation}
      data-testid="ClaimFillFormTeleconsultation"
    >
      <AcordeonSession
        isForm={false}
        accordeon="patient"
        startsOpened
        title={intl.TEXT_SELECT_PATIENT}
        openButtonRef={accordeonRefs.patient}
        status={listAccordeon.patient}
        setStatus={() => setStatusAccordeon('patient')}
        next={async () => {
          await openTargetAcordeon('patient', 'consultation', false);
        }}
        back={() => setStep('TERMS')}
      >
        <PatientArea
          cardHolder={loadedData.cardHolder}
          dependents={loadedData.dependents}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          parentValues={values}
          updateParent={updateForm.bind(null, 'patient')}
          submitRef={submitRefs.patient}
        />
      </AcordeonSession>

      <AcordeonSession
        number={2}
        startsOpened
        title={intl.TITLE_CARD_HOLDER_INFORMATION}
        openButtonRef={accordeonRefs.consultation}
        status={listAccordeon.consultation}
        setStatus={() => setStatusAccordeon('consultation')}
        back={() => openTargetAcordeon('consultation', 'patient', false)}
        next={async () => {
          await openTargetAcordeon('consultation', 'appointment', true);
        }}
      >
        <ConsultArea
          cardHolder={loadedData.cardHolder}
          parentValues={values}
          updateParent={updateForm.bind(null, 'consultation')}
          submitRef={submitRefs.consultation}
        />
      </AcordeonSession>

      <AcordeonSession
        number={3}
        startsOpened
        title={intl.TITLE_APPOINTMENT_INFORMATION}
        openButtonRef={accordeonRefs.appointment}
        status={listAccordeon.appointment}
        setStatus={() => setStatusAccordeon('appointment')}
        back={() => openTargetAcordeon('appointment', 'consultation', false)}
        next={handleSubmit}
        txtBtnNext={intl.BTN_CONCLUDE}
      >
        <AppointmentArea
          parentValues={values}
          updateParent={updateForm.bind(null, 'appointment')}
          submitRef={submitRefs.appointment}
        />
      </AcordeonSession>
    </div>
  );
};

export default withRouter(Form);
