import { pt_nationalities } from '../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../intl/es_nationalities';
import { pt_countries } from '../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../intl/es_countries';
import { pt_banks } from '../../../../../../intl/bank/pt_banks';

export const LABEL_REGISTRATIONS_TYPE_PASSPORT = {
  en_US: 'Passport *',
  pt_BR: 'Passaporte *',
  es_ES: 'Pasaporte *',
};

export const LABEL_REGISTRATIONS_TYPE_CPF = {
  en_US: 'CPF *',
  pt_BR: 'CPF *',
  es_ES: 'CPF *',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com o passport de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const TEXT_ALERT_NATIONAL_ID = {
  en_US:
    'For minors, please complete with the national ID of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com a identidade nacional de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el Documento Nacional de Identidad de uno de los padres/tutores.',
};

export const TEXT_ALERT_CPF = {
  en_US: 'For minors, please complete with the CPF of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el CPF de uno de los padres/tutores.',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const INFORMATION = {
  en_US: 'Filling out the form does not guarantee immediate approval.',
  pt_BR: 'O preenchimento do formulário não garante aprovação imediata.',
  es_ES: 'Completar el formulario no garantiza la aprobación inmediata.',
};
export const INFORMATION_1 = {
  en_US:
    ' Claim payments will be made in local currency using the current exchange rate.',
  pt_BR:
    ' Os pagamentos de sinistros serão feitos na moeda local, utilizando a taxa de câmbio atual.',
  es_ES:
    ' Los pagos de reclamaciones se realizarán en la moneda local utilizando la tasa de cambio actual.',
};
export const INFORMATION_2 = {
  en_US: ' Please ensure accurate bank ownership and details.',
  pt_BR:
    ' Por favor, verifique a propriedade precisa do banco e os detalhes. São aceitas apenas contas correntes.',
  es_ES: ' Asegúrese de tener la propiedad precisa del banco y los detalles.',
};
export const FORM_TXT_ACCOUNT_HOLDER_RELATIONSHIP = {
  en_US: 'Bank account holder relationship',
  pt_BR: 'Relação com o titular da conta bancária/beneficiario',
  es_ES: 'Relación con el titular de la cuenta bancaria',
};
export const FORM_TXT_ACCOUNT_HOLDER = {
  en_US: 'Bank account holder',
  pt_BR: 'Titular da conta bancária',
  es_ES: 'Titular de la cuenta bancaria',
};
export const TOOLTIP_FORM_TXT_ACCOUNT_HOLDER = {
  en_US:
    "The bank account holder's data must match the information provided in the identification document.",
  pt_BR:
    'Os dados do titular da conta bancária devem coincidir com as informações fornecidas no documento de identificação.',
  es_ES:
    'Los datos del titular de la cuenta bancaria deben coincidir con la información proporcionada en el documento de identificación.',
};

export const LABEL_CARD_HOLDER = {
  en_US: 'Cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};
export const FORM_LABEL_RADIO_CARDHOLDER = {
  en_US: "I'm the cardholder",
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};
export const FORM_LABEL_RADIO_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};
export const FORM_LABEL_RADIO_DEPENDENT = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};
export const FORM_LABEL_RADIO_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otros',
};
export const FORM_LABEL_CARDHOLDER_RELATIONSHIP = {
  en_US: 'Explain what is your relationship with the cardholder *',
  pt_BR: 'Explique qual é o seu relacionamento com o titular do cartão *',
  es_ES: 'Explique cuál es tu relación com el titular de la tarjeta *',
};
export const FORM_LABEL_ACCOUNT_HOLDER_NAME = {
  en_US: 'Bank account holder name *',
  pt_BR: 'Nome do titular da conta *',
  es_ES: 'Nombre del titular de la cuenta *',
};
export const FORM_LABEL_ACCOUNT_HOLDER_LASTNAME = {
  en_US: 'Bank account holder last name *',
  pt_BR: 'Sobrenome do titular da conta *',
  es_ES: 'Apellido del titular de la cuenta *',
};
export const FORM_LABEL_PHONE_TYPE = {
  en_US: 'Type *',
  pt_BR: 'Tipo *',
  es_ES: 'Tipo *',
};
export const TXT_PHONE_TYPE_1 = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};
export const TXT_PHONE_TYPE_2 = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};
export const TXT_PHONE_TYPE_3 = {
  en_US: 'Office',
  pt_BR: 'Trabalho',
  es_ES: 'Oficina',
};
export const FORM_LABEL_PREFIX = {
  en_US: 'Prefix *',
  pt_BR: 'Prefixo *',
  es_ES: 'Prefijo *',
};
export const FORM_LABEL_ACCOUNT_HOLDER_PHONE = {
  en_US: 'Number *',
  pt_BR: 'Número *',
  es_ES: 'Número *',
};
export const FORM_LABEL_COUNTRY = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};
export const FORM_LABEL_STATE = {
  en_US: 'State *',
  pt_BR: 'Estado *',
  es_ES: 'Estado *',
};
export const FORM_LABEL_CITY = {
  en_US: 'City of residence *',
  pt_BR: 'Cidade de residência *',
  es_ES: 'Ciudad de residencia *',
};
export const FORM_LABEL_ZIP_CODE = {
  en_US: 'Postal code *',
  pt_BR: 'CEP *',
  es_ES: 'Código postal *',
};
export const FORM_LABEL_ADDRESS = {
  en_US: 'Address *',
  pt_BR: 'Rua *',
  es_ES: 'Calle *',
};
export const FORM_LABEL_NEIGHBORHOOD = {
  en_US: 'Neighborhood *',
  pt_BR: 'Bairro *',
  es_ES: 'Barrio/Colonia *',
};
export const FORM_LABEL_PROFESSION = {
  en_US: 'Profession *',
  pt_BR: 'Profissão *',
  es_ES: 'Profesión *',
};
export const TXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};
export const TXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};
export const FORM_LABEL_PASSPORT = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};
export const FORM_LABEL_BANK_NAME = {
  en_US: 'Bank name *',
  pt_BR: 'Nome do banco *',
  es_ES: 'Nombre del banco *',
};
export const FORM_LABEL_ACCOUNT_NUMBER = {
  en_US: 'Account number *',
  pt_BR: 'Número da conta *',
  es_ES: 'Número de la cuenta *',
};

export const TOOLTIP_FORM_LABEL_ACCOUNT_NUMBER = {
  en_US:
    'Please ensure that the bank account number is correct; if necessary, add 0 (zero) to the left (000nn.nnnn).',
  pt_BR:
    'Certifique-se de que o número da conta bancária esteja correto; se necessário, adicione 0 (zero) à esquerda (000nn.nnnn).',
  es_ES:
    'Asegúrese de que el número de cuenta bancaria sea correcto; si es necesario, agregue 0 (cero) a la izquierda (000nn.nnnn).',
};
export const FORM_LABEL_ACCOUNT_IDENTIFICATION = {
  en_US: 'Account Identification',
  pt_BR: 'Identificação de Conta',
  es_ES: 'Identificación de Cuenta',
};

//Brazil
export const INFORMATION_3 = {
  en_US:
    'Savings accounts or exclusive accounts for receiving INSS benefits are not accepted.',
  pt_BR:
    'Não são aceitas contas-poupança ou contas exclusivas para recebimento de beneficios do INSS.',
  es_ES:
    'No se aceptan cuentas de ahorro ni cuentas exclusivas para recibir beneficios del INSS.',
};
export const INFORMATION_4 = {
  en_US:
    'Kindly pay attention to the ownership of the bank account and check the bank details.',
  pt_BR:
    'Gentileza atentar-se a titularidade da conta bancária e conferir os dados bancários.',
  es_ES:
    'Por favor, preste atención a la titularidad de la cuenta bancaria y compruebe los datos bancarios.',
};
export const FORM_LABEL_BANK_CODE = {
  en_US: 'Bank code *',
  pt_BR: 'Código do banco *',
  es_ES: 'Codigo bancario *',
};
export const FORM_LABEL_AGENCY = {
  en_US: 'Agency *',
  pt_BR: 'Agência *',
  es_ES: 'Agencia *',
};
export const TOOLTIP_TEXT_ZEROS_AGENCY = {
  en_US: 'Complete with leading zeros if necessary.',
  pt_BR: 'Complete com zeros a esquerda se necessário.',
  es_ES: 'Completa con ceros a la izquierda si es necesario.',
};
export const TOOLTIP_TEXT_ZEROS = {
  en_US: (format = '') =>
    `Complete with leading zeros if necessary. (Format: ${format})`,
  pt_BR: (format = '') =>
    `Complete com zeros a esquerda se necessário. (Formato: ${format})`,
  es_ES: (format = '') =>
    `Completa con ceros a la izquierda si es necesario. (Formato: ${format})`,
};
export const FORM_LABEL_EXPOSED_PERSON = {
  en_US: '',
  pt_BR: 'Considera-se uma pessoa politicamente exposta?',
  es_ES: '',
};

export const LABEL_ACCOUNT_TYPE = {
  en_US: 'Account type *',
  pt_BR: 'Tipo de conta *',
  es_ES: 'Tipo de cuenta *',
};

export const LABEL_ACCOUNT_TYPE_CHECKING = {
  en_US: 'Checking account',
  pt_BR: 'Conta corrente',
  es_ES: 'Cuenta corriente',
};

export const LABEL_ACCOUNT_TYPE_SAVING = {
  en_US: 'Saving',
  pt_BR: 'Poupança',
  es_ES: 'Ahorro',
};

export const TXT_COUNTRY_REIMBURSEMENT = {
  en_US: 'Country where your reimbursement will be received',
  pt_BR: 'País onde seu reembolso será recebido',
  es_ES: 'País donde se recibirá tu reembolso',
};
export const FORM_LABEL_SWIFT_CODE = {
  en_US: 'SWIFT code *',
  pt_BR: 'Código SWIFT *',
  es_ES: 'Código SWIFT *',
};
export const LABEL_SWIFT_CODE = {
  en_US: 'SWIFT code',
  pt_BR: 'Código SWIFT',
  es_ES: 'Código SWIFT',
};
export const FORM_LABEL_FULL_KEY_CODE = {
  en_US: 'Full key code *',
  pt_BR: 'Código da chave completo *',
  es_ES: 'Código CLABE COMPLETA (Clave Bancaria Estandarizada) *',
};
export const FORM_LABEL_CLABE = {
  en_US: 'CLABE *',
  pt_BR: 'CLABE *',
  es_ES: 'CLABE *',
};

export const FORM_LABEL_CLAVE_BANCARIA = {
  en_US: 'CLABE *',
  pt_BR: 'Código Bancário Uniforme *',
  es_ES: 'Clave Bancaria Uniforme *',
};
export const FORM_LABEL_CUIT = {
  en_US: 'CUIT/CUIL *',
  pt_BR: 'CUIT/CUIL *',
  es_ES: 'CUIT/CUIL *',
};
export const LABEL_ROUTING_NUMBER = {
  en_US: 'ABA / Routing number',
  pt_BR: 'Número ABA',
  es_ES: 'Número ABA',
};
export const FORM_LABEL_ROUTING_NUMBER = {
  en_US: 'ABA / Routing number *',
  pt_BR: 'Número ABA *',
  es_ES: 'Número ABA *',
};
export const TXT_ACCOUNT_IDENTIFICATION = {
  en_US: 'Account identification',
  pt_BR: 'Identificação da conta',
  es_ES: 'Identificación de Cuenta',
};
export const OPTION_ACCOUNT_IDENTIFICATION_1 = {
  en_US: 'Account number',
  pt_BR: 'Número da conta',
  es_ES: 'Número de la cuenta',
};
export const OPTION_ACCOUNT_IDENTIFICATION_2 = {
  en_US: 'IBAN (see countries that require IBAN)',
  pt_BR: 'IBAN (Veja os países que exigem IBAN)',
  es_ES: 'IBAN (Vea los países que requieren IBAN)',
};
export const FORM_LABEL_IBAN_CODE = {
  en_US: 'IBAN code *',
  pt_BR: 'Código IBAN *',
  es_ES: 'Código IBAN *',
};
export const TXT_INTERNATIONAL_TRANSFER = {
  en_US:
    'Do you require an intermediary bank account to make an international transfer?',
  pt_BR:
    'Você precisa de uma conta bancária intermediária para fazer uma transferência internacional?',
  es_ES:
    '¿Necesita una cuenta bancaria intermedia para hacer una transferencia internacional?',
};

//ERRORS
export const VALIDATE_REQUIRED_FIELD = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};
export const VALIDATE_INVALID_FIELD = {
  en_US: 'Invalid field.',
  pt_BR: 'Campo inválido.',
  es_ES: 'Campo inválido.',
};
export const VALIDATE_INVALID_FIELD_AGENCY = {
  en_US: 'Invalid agency.',
  pt_BR: 'Agência inválida.',
  es_ES: 'Agencia no válida.',
};
export const VALIDATE_INVALID_FIELD_ACOUNT = {
  en_US: 'Invalid account number.',
  pt_BR: 'Número da conta inválida.',
  es_ES: 'Número de cuenta no válido.',
};
export const VALIDATE_INVALID_CPF = {
  en_US: '',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: '',
};
export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};
export const VALIDATE_INVALID_DATE = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};
export const VALIDATE_MORE_THAN_8_CARACTERS = {
  en_US: 'Should not be shorter than 8 characters.',
  pt_BR: 'Não deve ter menos de 8 caracteres.',
  es_ES: 'No debe tener menos de 8 caracteres.',
};

//MESSAGES
export const MSG_INFO_CHANGE = {
  en_US: 'Cardholder information has been changed. Do you want to save them?',
  pt_BR: 'Os dados do titular do cartão foram alterados. Deseja salvá-los?',
  es_ES:
    'La información del titular de la tarjeta ha sido modificada. ¿Deseas guardar los cambios?',
};
export const MSG_REPLACE_FIELDS = {
  en_US:
    'Replace the fields with previously saved cardholder bank information?',
  pt_BR:
    'Deseja substituir pelas informações bancárias do titular do cartão salvas em seu cadastro?',
  es_ES:
    '¿Desea reemplazar por la información bancaria del titular de la tarjeta guardada en tu registro?',
};
export const MSG_FILES_REQUIRED = {
  en_US:
    'Your claim has been created. Please, click continue to follow the process to upload the files required for this claim.',
  pt_BR:
    'Seu sinistro foi criado. Por favor, clique em continuar para seguir o processo e subir os arquivos necessários para esse sinistro.',
  es_ES:
    'Tu reclamo ha sido creado. Por favor, haga clic en continuar para seguir el proceso y cargar los archivos necesarios para este reclamo.',
};

///TOOLTIPS
export const TOOLTIP_FOR_MINORS = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com o CPF de um dos pais. ',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};
export const TOOLTIP_INTERNATIONAL_COUNTRY_CODE = {
  en_US: 'Include your international country code.',
  pt_BR: 'Inclua seu código internacional do país.',
  es_ES: 'Incluye tu código de país internacional.',
};
export const TOOLTIP_MAKE_SURE_CARD = {
  en_US:
    'Make sure card selected on the right top is the one you want to use to open this claim.',
  pt_BR:
    'Verifique se o cartão selecionado no canto superior direito corresponde ao cartão que você deseja usar para abrir a reivindicação.',
  es_ES:
    'Asegúrate que la tarjeta seleccionada en la esquina superior derecha corresponde a la tarjeta que quiere utilizar para abrir el reclamo.',
};
export const TOOLTIP_SWIFT_INFO = {
  en_US: `A SWIFT is an 8-11 character code that identifies your country, location, bank and branch needed for bank transfers.
    Expected format: ABCF-AB-XX-XXX (ABCD: Bank code A-Z | AB: Country code A-Z | XX: Location code A-Z 0-9 | XXX: Branch code A-Z 0-9).
    You can find your Bank’s SWIFT number by searching on websites such as https://transferwise.com/us/swift-codes/ or by contacting your bank.`,
  pt_BR: `Um código SWIFT é um código de 8 a 11 caracteres que identifica seu país, localização, banco e filial necessários para transferências eletrônicas.
  Formato esperado: ABCF-AB-XX-XXX (ABCD: Código do banco A-Z | AB: Código do país A-Z | XX: Código do local A-Z 0-9 | XXX: Código da filial A-Z 0-9).
  Você pode encontrar o número SWIFT do seu banco pesquisando em sites como https://transferwise.com/us/swift-codes/ ou entrando em contato com seu banco.`,
  es_ES: `Un Código SWIFT es un código de 8-11 caracteres que identifica tu país, localización, banco y rama necesarios para transferencias bancarias.
    Formato esperado: ABCF-AB-XX-XXX (ABCD: Código de banco A-Z | AB: Código de país A-Z | XX: Código de ubicación A-Z 0-9 | XXX: Código de sucursal A-Z 0-9).
    Puede encontrar el número SWIFT de tu banco buscando en sitios web como https://transferwise.com/us/swift-codes/ o contactando a tu banco.`,
};
export const TOOLTIP_ABA_INFO = {
  en_US: `An ABA (American Bankers Association) routing number is a 9 numeric digits code that identifies your bank account.
    Expected format: 9999-9999-9 (9999: central american bank number | 9999: institution identification number at ABA | 9: check digit).`,
  pt_BR: `Um número ABA (American Bankers Association) é um código de 9 dígitos que identifica sua conta bancária.
  Formato esperado: 9999-9999-9 (9999: número do banco centro-americano | 9999: número de identificação da instituição em ABA | 9: dígito verificador).`,
  es_ES: `Un Número ABA (American Bankers Association) es un código de 9 dígitos que identifica tu cuenta bancaria.
    Formato esperado: 9999-9999-9 (9999: número del banco centroamericano | 9999: número de identificación de la institución en ABA | 9: dígito de control).`,
};

export const TOOLTIP_FULL_KEY_INFO = {
  en_US: `A Full key code (CLABE - Clave Bancaria Estandarizada) is a 18 numeric code banking standard used to identify bank accounts in Mexico.
  Expected format: 999-999-99999999999-9 (999: Bank code | 999: Branch office code | 99999999999: Account number | 9: Control digit).`,
  pt_BR: `Um código de chave completo (CLABE - Clave Bancaria Estandarizada) é um padrão bancário de 18 códigos numéricos usados para identificar contas bancárias no México.
  Formato esperado: 999-999-99999999999-9 (999: código do banco | 999: código da agência | 99999999999: número da conta | 9: dígito verificador).`,
  es_ES: `Un código de clave completo (CLABE - Clave Bancaria Estandarizada) es un estándar bancario de 18 códigos numéricos que se utiliza para identificar cuentas bancarias en México.
  Formato esperado: 999-999-99999999999-9 (999: código de banco | 999: código de sucursal | 99999999999: número de cuenta | 9: dígito de control).`,
};
export const TOOLTIP_CLAVE_BANCARIA = {
  en_US: `A Full key code (CLABE - Clave Bancaria Estandarizada) is a 18 numeric code banking standard used to identify bank accounts in Mexico.
  Expected format: 999-999-99999999999-9 (999: Bank code | 999: Branch office code | 99999999999: Account number | 9: Control digit).`,
  pt_BR: `É o Código Bancário Uniforme. É composto por 22 números. É necessário fazer e receber transferências em sua conta bancária. Por favor, caso não tenha esta informação, contacte o seu banco.`,
  es_ES: `Es la Clave Bancaria Uniforme. Está formada por 22 números. Es necesaria para hacer y recibir transferencias en tu cuenta bancaria. Por favor, en caso de que no tener esa información, entre en contacto con tu banco.`,
};
export const TOOLTIP_IBAN_COUNTRIES = {
  en_US: `This code has two letters and 27 numbers ( XX 111111111111111111111111111 ).
  If your code has a smaller number of numbers, please add 0 (zeros) to the left to complete the format.`,
  pt_BR: `Esse código possui 2 letras e 27 números ( XX 111111111111111111111111111 ).
  Caso seu código possua uma quantidade menor de números, por favor acrescentar 0 (zeros) a esquerda para completar o formato.`,
  es_ES: `Ese código posee 2 letras y 27 números ( XX 111111111111111111111111111 ).
  Si tu código tiene una cantidad menor de números, por favor agregar 0 (ceros) a izquierda para completar el formato.`,
};
export const TOOLTIP_INFO_BANK_INTERMEDIARY = {
  en_US:
    "An intermediary bank is a bank that facilitates the transfer of funds between the sender's bank (the bank that sends the money) and the recipient's bank (the bank that receives the money), in an efficient and secure manner.",
  pt_BR:
    'Um banco intermediário é um banco que facilita a transferência de fundos entre o banco do remetente (o banco que envia o dinheiro) e o banco do destinatário (o banco que recebe o dinheiro), de maneira eficiente e segura.',
  es_ES:
    'Un banco intermediario es un banco que facilita la transferencia de fondos entre el banco del remitente (el banco que envía el dinero) y el banco del destinatario (el banco que recibe el dinero), de forma eficiente y segura.',
};

/*
 NATIONALITIES AND COUNTRIES
*/
export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};

export const TEXT_PHONE_NUMBER = {
  en_US: 'Account holder phone number',
  pt_BR: 'Número de telefone do titular da conta',
  es_ES: 'Número de teléfono del titular de la cuenta',
};

export const BANK_ACCOUNT = {
  en_US: 'Bank account (Receive the payment within 15 days)',
  pt_BR: 'Bank account',
  es_ES:
    "Cuenta bancaria <br /><span style='font-size: 13px'>(Reciba el pago en hasta 15 días, despues de aprobado el reclamo)</span>",
};

// BANKS
export const BANKS_OPTIONS = {
  en_US: pt_banks,
  pt_BR: pt_banks,
  es_ES: pt_banks,
};

export const LABEL_TITLE = {
  en_US: 'Title *',
  pt_BR: 'Título *',
  es_ES: 'Título *',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const LABEL_GENDER = {
  en_US: 'Gender *',
  pt_BR: 'Gênero *',
  es_ES: 'Género *',
};
export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_LOADING_BANK_INFORMATION = {
  en_US: 'Loading the chosen bank information.',
  pt_BR: 'Carregando as informações bancárias escolhidas.',
  es_ES: 'Cargando la información bancaria elegida.',
};

export const TEXT_CHANGE_BANK = {
  en_US: 'Choose another bank',
  pt_BR: 'Escolha outro banco',
  es_ES: 'Elija otro banco',
};

export const SAVING_BANK_INFORMATION = {
  en_US: 'Saving bank information...',
  pt_BR: 'Salvando informações bancárias...',
  es_ES: 'Guardando información bancaria...',
};

export const MODAL_CONFIRMATION_UPDATE_BANK = {
  en_US: 'Your bank information has been changed. Do you want to save it?',
  pt_BR: 'Suas informações bancárias foram alteradas. Deseja salvá-las?',
  es_ES: 'Se ha modificado tu información bancaria. ¿Quieres guardarlo?',
};

export const MODAL_CONFIRMATION_UPDATE_BANK_SUCCESS = {
  en_US: 'Your bank information has been updated successfully.',
  pt_BR: 'Suas informações bancárias foram atualizadas com sucesso.',
  es_ES: 'Tu información bancaria se ha actualizada correctamente.',
};

export const INVALID_FORMAT_SWIFT = {
  en_US: 'Invalid format: ABCD-AB-XX-XXX.',
  pt_BR: 'Formato inválido: ABCD-AB-XX-XXX.',
  es_ES: 'Formato no válido: ABCD-AB-XX-XXX.',
};

export const INVALID_FORMAT_FULL_KEY_CODE = {
  en_US: 'Invalid format: 999-999-99999999999-9.',
  pt_BR: 'Formato inválido: 999-999-99999999999-9.',
  es_ES: 'Formato no válido: 999-999-99999999999-9.',
};

export const INVALID_FORMAT_CBU_ES_AR = {
  en_US: 'Invalid format: 999-9999-9-9999999999999-9.',
  pt_BR: 'Formato inválido: 999-9999-9-9999999999999-9.',
  es_ES: 'Formato no válido: 999-9999-9-9999999999999-9.',
};

export const INVALID_FORMAT_ABA = {
  en_US: 'Invalid format: 9999-9999-9.',
  pt_BR: 'Formato inválido: 9999-9999-9.',
  es_ES: 'Formato no válido: 9999-9999-9.',
};

export const INVALID_FORMAT_CUIT_ES_AR = {
  en_US: 'Invalid format: 99-99999999-9.',
  pt_BR: 'Formato inválido: 99-99999999-9.',
  es_ES: 'Formato no válido: 99-99999999-9.',
};

export const INVALID_FORMAT_IBAN = {
  en_US:
    'Invalid format: AA-123456789012345678901234567. (Complete with left zeros if necessary: AA-000000089012345678901234567)',
  pt_BR:
    'Formato inválido: AA-123456789012345678901234567. (Complete com zeros à esquerda, se necessário: AA-000000089012345678901234567)',
  es_ES:
    'Formato no válido: AA-123456789012345678901234567. (Complete con ceros a la izquierda si es necesario: AA-000000089012345678901234567)',
};

export const TEXT_ACCOUNT_HOLDER_ADDRESS = {
  en_US: 'Account holder address',
  pt_BR: 'Endereço do titular da conta',
  es_ES: 'Dirección del titular de la cuenta',
};

export const TEXT_THIRD_PARTY_PAYMENT = {
  en_US: 'Payment to third parties',
  pt_BR: 'Pagamento para terceiros',
  es_ES: 'Pago a terceros',
};

export const FORM_TXT_ACCOUNT_HOLDER_RELATIONSHIP_2 = {
  en_US: 'Account ownership relationship with the cardholder:',
  pt_BR: 'Relação da titularidade da conta com o titular do cartão:',
  es_ES: 'Relación de propiedad de la cuenta con el titular de la tarjeta:',
};

export const DISCLAIMER_ACCOUNT_MUST_BE_FROM_CARDHOLDER = {
  en_US: 'The bank account listed below must always belong to the cardholder.',
  pt_BR:
    'A conta bancária informada abaixo deve sempre pertencer ao titular do cartão.',
  es_ES:
    'La cuenta bancaria que se indica a continuación debe pertenecer siempre al titular de la tarjeta',
};

export const TEXT_DESCRIBE_PREFERENCE = {
  en_US: 'The following input field will be defined by this selection',
  pt_BR: 'O campo de input a seguir será definido por essa seleção',
  es_ES: 'Esta selección definirá el siguiente campo de entrada',
};

export const DESCRIBE_INTERNATIONAL_TRANSFER = {
  en_US:
    'New fields will appear for you to enter the details of the intermediary account',
  pt_BR:
    'Novos campos aparecerão para você inserir os detalhes da conta intermediária',
  es_ES:
    'Aparecerán nuevos campos para que introduzca los datos de la cuenta del intermediario',
};
