export const LACLinkByCode = {
  AR: 'https://www.visa.com.ar',
  BO: 'https://www.visa.com.bo',
  BR: 'https://www.visa.com.br',
  CL: 'https://www.visa.cl',
  CO: 'https://www.visa.com.co',
  CR: 'https://www.visa.co.cr',
  EC: 'https://www.visa.com.ec',
  SV: 'https://www.visa.com.sv',
  GT: 'https://www.visa.com.gt',
  HT: 'https://caribbean.visa.com',
  HN: 'https://www.visa.com.hn',
  MX: 'https://www.visa.com.mx',
  NI: 'https://www.visa.co.ni',
  PA: 'https://www.visa.com.pa',
  PY: 'https://www.visa.com.py',
  PE: 'https://www.visa.com.pe',
  DO: 'https://www.visa.com.do',
  UY: 'https://www.visa.com.uy',
  VE: 'https://www.visa.co.ve',
  AI: 'https://caribbean.visa.com/',
  BS: 'https://caribbean.visa.com/',
  BB: 'https://caribbean.visa.com/',
  BZ: 'https://www.visa.com.bz/',
  GY: 'https://caribbean.visa.com/',
  JM: 'https://www.visa.com.jm/',
  SR: 'https://caribbean.visa.com/',
  TT: 'https://www.visa.com.tt/',
  AG: 'https://caribbean.visa.com/',
  AW: 'https://caribbean.visa.com/',
  BM: 'https://caribbean.visa.com/',
  BQ: 'https://caribbean.visa.com/',
  CW: 'https://caribbean.visa.com/',
  DM: 'https://caribbean.visa.com/',
  GD: 'https://caribbean.visa.com/',
  GP: 'https://www.visa.gp/',
  GF: 'https://caribbean.visa.com/',
  KY: 'https://caribbean.visa.com/',
  TC: 'https://caribbean.visa.com/',
  VG: 'https://caribbean.visa.com/',
  VI: 'https://caribbean.visa.com/',
  MQ: 'https://www.visa.mq/',
  MS: 'https://caribbean.visa.com/',
  PR: 'https://www.visa.com.pr/',
  PI: 'https://www.visa.com.pr/',
  KN: 'https://caribbean.visa.com/',
  VC: 'https://caribbean.visa.com/',
  LC: 'https://caribbean.visa.com/',
  MF: 'https://caribbean.visa.com/',
  SX: 'https://caribbean.visa.com/',
};
