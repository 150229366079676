export const TEXT_REQUIRED_FIELD = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_ORIGIN_DESTINATION = {
  en_US: 'Trip must have the origin different from the destination.',
  pt_BR: 'A viagem deve ter a origem diferente do destino.',
  es_ES: 'El viaje debe tener un origen diferente del destino.',
};

export const UNREQUIRERD_TRIP_INFORMATION_MESSAGE = {
  en_US: '',
  pt_BR:
    'As informações de viagem não irão interferir na validade do seu bilhete portanto o preenchimento dessa seção é opcional.',
  es_ES: '',
};

export const VALIDATE_MAX_60_DAYS = {
  en_US: 'The trip duration should not be over 60 days.',
  pt_BR: 'A duração da viagem não deve ser superior a 60 dias.',
  es_ES: 'La duración del viaje no debe superar los 60 días.',
};
