import listCountries from '../intl/countryVsIdiom';

export const JULY = 'JULY';
export const OCTOBER = 'OCTOBER';
export const MARCH = 'MARCH';
export const APRIL = 'APRIL';

export const verifyCountryARI = country => {
  const objfind = listCountries.find(item => item.initials === country);
  return objfind ? objfind.ARI_month : undefined;
};
