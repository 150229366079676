export const LABEL_BENEFICIARY_IS_CH = {
  en_US: 'Issue certificate to cardholder?',
  pt_BR: 'Emitir certificado para o titular do cartão?',
  es_ES: '¿Emitir certificado para el titular de la tarjeta?',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No, just beneficiaries.',
  pt_BR: 'Não, apenas beneficiários.',
  es_ES: 'No, solo beneficiarios.',
};

export const TEXT_DESCRIBE_YES = {
  en_US: `All the fields in the following personal data form will be filled in automatically with the logged-in user's data`,
  pt_BR:
    'Todos os campos do formulário de dados pessoais a seguir serão preenchidos automaticamente com os dados do usuário logado',
  es_ES:
    'Todos los campos del siguiente formulario de datos personales se rellenarán automáticamente con los datos del usuario conectado',
};

export const TEXT_DESCRIBE_NO = {
  en_US:
    'All the fields in the following personal data form will be cleared for you to fill in as you wish',
  pt_BR:
    'Todos os campos do formulário de dados pessoais a seguir serão limpos para você preencher como quiser',
  es_ES:
    'Todos los campos del siguiente formulario de datos personales quedarán libres para que usted los rellene como desee',
};
