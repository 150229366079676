import React from 'react';
import VoucherItem from './VoucherItem/VoucherItem';
import styles from './VoucherList.module.scss';

const VoucherList = ({ vouchers }) => {
  return (
    <ul className={styles.containerList}>
      {vouchers.map(voucher => (
        <VoucherItem voucher={voucher} key={voucher.voucher_id} />
      ))}
    </ul>
  );
};

export default VoucherList;
