export const FORM_OF_PAYMENT = {
  en_US: 'Form of payment (cash, vouchers for accommodation or food, other)',
  pt_BR:
    'Indique a forma de pagamento (dinheiro, vouchers para alojamento ou comida, outro)',
  es_ES: 'Indique forma de pago (dinero, vales estadía o alimentación, otro)',
};

export const TOTAL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total reivindicado',
  es_ES: 'Valor total reclamado',
};
