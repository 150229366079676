import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './PeriodArea.module.scss';

const PeriodArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, errors, setFieldTouched } = props;

  return (
    <div>
      <div className={`row ${styles.rentalPeriodlArea}`}>
        <div className="col-12 col-md-6 col-lg-4">
          <Calendar
            dataTestid="departureDateTripDelay"
            value={values.trip_information.planed_trip.departure_date}
            label={intl.LABEL_TRIP_START_DATE}
            name="trip_information.planed_trip.departure_date"
            onChange={handleChange}
            maxDate={new Date()}
            touched={
              touched.trip_information &&
              touched.trip_information.planed_trip &&
              touched.trip_information.planed_trip.departure_date
            }
            error={
              errors.trip_information &&
              errors.trip_information.planed_trip &&
              errors.trip_information.planed_trip.departure_date
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <Calendar
            dataTestid="arrivalDateTripDelay"
            value={values.trip_information.planed_trip.arrival_date}
            label={intl.LABEL_TRIP_END_DATE}
            name="trip_information.planed_trip.arrival_date"
            onChange={handleChange}
            minDate={values.trip_information.planed_trip.departure_date}
            touched={
              touched.trip_information &&
              touched.trip_information.planed_trip &&
              touched.trip_information.planed_trip.arrival_date
            }
            error={
              errors.trip_information &&
              errors.trip_information.planed_trip &&
              errors.trip_information.planed_trip.arrival_date
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div className="col-12 col-md-6 col-lg-4">
          <Calendar
            dataTestid="delayDateTripDelay"
            value={values.incident.start_date}
            label={intl.LABEL_TRIP_DELAY_DATE}
            name="incident.start_date"
            onChange={handleChange}
            minDate={values.trip_information.planed_trip.departure_date}
            maxDate={values.trip_information.planed_trip.arrival_date}
            touched={touched.incident && touched.incident.start_date}
            error={errors.incident && errors.incident.start_date}
            setFieldTouched={setFieldTouched}
          />
        </div>
      </div>
    </div>
  );
};

export default PeriodArea;
