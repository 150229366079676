export const LABEL_DEPARTURE_AIRPORT = {
  en_US: 'Departure Airport *',
  pt_BR: 'Aeroporto de partida *',
  es_ES: 'Aeropuerto de salida *',
};

export const LABEL_ARRIVAL_AIRPORT = {
  en_US: 'Arrival Airport *',
  pt_BR: 'Aeroporto de chegada *',
  es_ES: 'Aeropuerto de llegada *',
};

export const LABEL_ADD_LEG = {
  en_US: 'Add flight leg',
  pt_BR: 'Adicionar trecho de voo',
  es_ES: 'Agregar segmento de vuelo',
};
