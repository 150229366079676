export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor insira uma data válida.',
  es_ES: 'Por favor introduzca una fecha valida.',
};

export const DESCRIPTION_INCIDENT = {
  en_US: 'Description of incident *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};

export const INCIDENT_DATE = {
  en_US: 'Incident date *',
  es_ES: 'Fecha del incidente *',
  pt_BR: 'Data do incidente *',
};

export const INCIDENT_TIME = {
  en_US: 'Incident hour *',
  es_ES: 'Hora del incidente *',
  pt_BR: 'Hora do incidente *',
};
