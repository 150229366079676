import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ReadyToFlyIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      width={33}
      height={32}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0023 4.11047C9.44668 4.11047 4.11084 9.44548 4.11084 16.0001C4.11084 22.5546 9.44668 27.8896 16.0023 27.8896C22.5579 27.8896 27.8937 22.5546 27.8937 16.0001C27.8937 9.44548 22.5579 4.11047 16.0023 4.11047ZM25.6901 16.0001C25.6901 21.3401 21.3432 25.6864 16.0023 25.6864C10.6614 25.6864 6.31445 21.3401 6.31445 16.0001C6.31445 10.66 10.6564 6.31374 16.0023 6.31374C21.3482 6.31374 25.6901 10.66 25.6901 16.0001Z"
        fill={config_icon.secondary}
      />
      <path
        d="M16.0025 0C7.17804 0 0 7.17691 0 16C0 24.8231 7.17804 32 16.0025 32C24.827 32 32.005 24.8231 32.005 16C32.005 7.17691 24.827 0 16.0025 0ZM29.706 16C29.706 23.5583 23.557 29.7014 16.0025 29.7014C8.448 29.7014 2.29396 23.5533 2.29396 16C2.29396 8.44668 8.44298 2.2936 16.0025 2.2936C23.562 2.2936 29.706 8.44166 29.706 15.995V16Z"
        fill={config_icon.primary}
      />
      <path
        d="M20.0784 11.9097L14.346 18.2033L11.4347 15.2321L9.728 16.9134L14.4163 21.6964L21.8554 13.5157L20.0784 11.9097Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};

export default ReadyToFlyIcon;
