export default (env, axios, errorHandler) => ({
  getAllReimbursmentClaimV2: async claim_id => {
    const url =
      env.REACT_APP_JWE_REIMBURSMENT_API.toString() === 'true'
        ? `/internal/reimbursments/specific/visagateway/v3/claims/${claim_id}/reimbursements`
        : `/api/specific/visagateway/v2/claims/${claim_id}/reimbursements`;

    return errorHandler(axios.get(url), 'getAllReimbursmentClaimV2', {
      url,
      claim_id,
    });
  },
  postReimbursmentV2: async (claim_id, data) => {
    const url =
      env.REACT_APP_JWE_REIMBURSMENT_API.toString() === 'true'
        ? `/internal/reimbursments/specific/visagateway/v3/claims/${claim_id}/reimbursements`
        : `/api/specific/visagateway/v2/claims/${claim_id}/reimbursements`;

    return errorHandler(axios.post(url, data), 'postReimbursmentV2', {
      url,
      claim_id,
      data,
    });
  },
  getReimbursmentV2: async (claim_id, reimbursement_id) => {
    const url =
      env.REACT_APP_JWE_REIMBURSMENT_API.toString() === 'true'
        ? `/internal/reimbursments/specific/visagateway/v3/claims/${claim_id}/reimbursements/${reimbursement_id}`
        : `/api/specific/visagateway/v2/claims/${claim_id}/reimbursements/${reimbursement_id}`;

    return errorHandler(axios.get(url), 'getReimbursmentV2', {
      url,
      claim_id,
      reimbursement_id,
    });
  },
  updateReimbursmentV2: async (
    claim_id,
    reimbursement_id,
    data,
    idiom = 'en-US'
  ) => {
    const url =
      env.REACT_APP_JWE_REIMBURSMENT_API.toString() === 'true'
        ? `/internal/reimbursments/specific/visagateway/v3/claims/${claim_id}/reimbursements/${reimbursement_id}`
        : `/api/specific/visagateway/v2/claims/${claim_id}/reimbursements/${reimbursement_id}`;

    const headers = {
      'Accept-Language': idiom,
    };
    return errorHandler(
      axios.patch(url, data, { headers }),
      'updateReimbursmentV2',
      { url, claim_id, reimbursement_id, idiom }
    );
  },
  deleteReimbursmentV2: async (claim_id, reimbursement_id) => {
    const url = `/api/specific/visagateway/v2/claims/${claim_id}/reimbursements/${reimbursement_id}`;
    return errorHandler(axios.delete(url), 'deleteReimbursmentV2', {
      url,
      claim_id,
      reimbursement_id,
    });
  },
});
