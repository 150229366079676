import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import moment from 'moment';
import Select from '../../../../../../../../components/Select/Select';
import CheckBox from '../../../../../../../../components/CheckBox/CheckBox';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  let getYesterday = moment(new Date(), 'YYYY-MM-DD HH:ss').subtract(1, 'days');
  let yesterday = new Date(getYesterday);

  return (
    <div className={`${styles.personalArea}`}>
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {intl.TEXT_MAXIMUM_REIMBURSEMENT}
      </label>
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {intl.CARDHOLDER_DISCLAIMER}
      </label>

      <div className="row">
        <div className="col-12 col-sm-10 col-md-4 col-lg-4">
          <Input
            value={values.affected_person.person.first_name}
            label={intl.LABEL_NAME_AFFECTED}
            name="affected_person.person.first_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.first_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.first_name
            }
            maxLength="40"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
          <Input
            value={values.affected_person.person.last_name}
            label={intl.LABEL_LAST_NAME_AFFECTED}
            name="affected_person.person.last_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.last_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.last_name
            }
            maxLength="80"
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-2">
          <Calendar
            label={intl.LABEL_BIRTH_DATE}
            name="affected_person.person.birth_date"
            maxDate={yesterday}
            formikProps={props}
            setFieldTouched={setFieldTouched}
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
          />
        </div>
        <div className="col-12 col-sm-4 col-md-4 col-lg-2">
          <Select
            label={intl.LABEL_GENDER}
            name="affected_person.person.gender"
            formikProps={props}
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
          >
            <option value="">{intl.LABEL_GENDER}</option>
            <option value={constants.MALE_VALUE}>{intl.LABEL_MALE}</option>
            <option value={constants.FEMALE_VALUE}>{intl.LABEL_FEMALE}</option>
            <option value={constants.UNKNOWN_VALUE}>
              {intl.LABEL_UNKNOWN}
            </option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
