import { formatISOWithOffset } from '../../../../utils/date';
import { focusOnErrorElement } from '../../../../utils/elements';
import { stringToBoolean } from '../../../../utils/stringUtils';

export const checkErrorsOnForm = async (
  isOneWayTravel,
  validationSchema,
  values,
  setStatus
) => {
  try {
    await validationSchema.fields.outbound.validate(values.outbound);
  } catch (error) {
    setStatus({
      outbound: true,
      return: false,
    });
    focusOnErrorElement(0);
    return;
  }

  if (!isOneWayTravel) {
    try {
      await validationSchema.fields.return.validate(values.return);
    } catch (error) {
      setStatus({
        outbound: false,
        return: true,
      });
      focusOnErrorElement(0);
      return;
    }
  }

  return true;
};

export const setTouchedOutbound = async formikProps => {
  formikProps.setFieldTouched('outbound.booking_company_name', true);
  formikProps.setFieldTouched('outbound.booking_reference', true);
  formikProps.setFieldTouched(`outbound.segments[0].isValid`, true);
  formikProps.setFieldTouched(`outbound.segments[0].airline`, true);
  formikProps.setFieldTouched(`outbound.segments[0].flight_number`, true);
  formikProps.setFieldTouched(`outbound.segments[0].flight_date`, true);
  formikProps.setFieldTouched(`outbound.segments[0].country_origin`, true);
};

const formatFlightNumber = (airline, flight_number) =>
  `${airline}${flight_number}`;

const formatSegment = flightLeg => {
  const {
    airport_destination,
    airport_destination_timezone,
    airport_origin,
    airport_origin_timezone,
    city_destination,
    city_origin,
    country_destination,
    country_origin,
    flight_number,
    airline,
    scheduled_in,
    scheduled_out,
  } = flightLeg;

  return {
    airport_destination,
    airport_origin,
    city_destination,
    city_origin,
    country_destination,
    country_origin,
    flight_number: formatFlightNumber(airline, flight_number),
    start_at: formatISOWithOffset(scheduled_out, airport_origin_timezone),
    end_at: formatISOWithOffset(scheduled_in, airport_destination_timezone),
  };
};

const formatSegments = flightLegs =>
  flightLegs.map(segment => formatSegment(segment));

export const formatTravel = (certificate, flightDetails) => {
  const cost =
    certificate.travel_information.cost &&
    certificate.travel_information.cost.value
      ? certificate.travel_information.cost
      : undefined;

  return {
    has_requested_travel_notifications: true,
    has_requested_health_document_verification: false,
    certificate_id: certificate.certificate_id,
    is_one_way_travel: stringToBoolean(
      certificate.travel_information &&
        certificate.travel_information.is_one_way_travel
    ),
    outward_travel: {
      booking_company_name: flightDetails.outbound.booking_company_name,
      booking_reference: flightDetails.outbound.booking_reference,
      flight_segments: formatSegments(flightDetails.outbound.segments),
    },
    return_travel: stringToBoolean(
      certificate.travel_information.is_one_way_travel
    )
      ? undefined
      : {
          booking_company_name: flightDetails.return.booking_company_name,
          booking_reference: flightDetails.return.booking_reference,
          flight_segments: formatSegments(flightDetails.return.segments),
        },
    cost,
  };
};
