export default (env, axios, errorHandler) => ({
  getSchedules: async (dateStart, dateEnd, query, abortController) => {
    const url = `/flights/schedules/${dateStart}/${dateEnd}`;
    const params = query;

    const cacheKey = `${url}?max_pages=${query.max_pages}&airline=${query.airline}&flight_number=${query.flight_number}`;

    return errorHandler(
      axios.cacheGet(cacheKey)(url, {
        params,
        signal: abortController && abortController.signal,
      }),
      'getSchedules',
      true
    );
  },
  getAirport: async id => {
    const url = `/flights/airports/${id}`;
    return errorHandler(axios.cacheGet(url)(url), 'getAirport', true);
  },

  getFlights: async (ident, query = {}) => {
    const url = `/flights/${ident}`;

    const { end, start, cursor, ident_type, maxPages } = query;

    const config = {
      params: {
        cursor,
        maxPages,
        ident_type,
        start: start ? start.toISOString() : null,
        end: end ? end.toISOString() : null,
      },
    };

    return errorHandler(axios.get(url, config), 'getFlights', true);
  },
});
