import { en_currency } from '../../../../../../../../../../intl/currency/en_currency';
import { es_currency } from '../../../../../../../../../../intl/currency/es_currency';
import { pt_currency } from '../../../../../../../../../../intl/currency/pt_currency';

export const PAYMENT_METHOD = {
  en_US: 'Reservation payment method',
  pt_BR: 'Método de pagamento da reserva',
  es_ES: 'Metodo de pago de la reserva',
};
export const METHOD_FINANCING = {
  en_US: 'Installments',
  pt_BR: 'A prazo',
  es_ES: 'Cuotas',
};

export const METHOD_CASH = {
  en_US: 'Payment in full',
  pt_BR: 'À vista',
  es_ES: 'Un solo pago',
};

export const LABEL_TOTAL_AMOUNT_INVOICE = {
  en_US: 'Total reservation amount shown on Visa invoice',
  pt_BR: 'Valor total da reserva indicado na fatura Visa',
  es_ES: 'Importe total de la reserva indicado en el estado de cuenta Visa',
};
export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as a decimal symbol.',
  pt_BR: 'Utilize . como um símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const LABEL_INSTALLMENT_AMOUNT_INVOICE = {
  en_US: 'Amount of the reservation installment indicated on the Visa invoice',
  pt_BR: 'Valor da parcela da reserva indicado na fatura Visa',
  es_ES: 'Valor de la cuota de la reserva indicado en el estado de cuenta Visa',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Date of rent reservation payment indicated on the Visa invoice',
  pt_BR: 'Data do pagamento da reserva indicada na fatura Visa',
  es_ES: 'Fecha de pago de la reserva indicada en el estado de cuenta Visa',
};

export const INSTALLMENTS_NUMBER = {
  en_US: 'Purchase installments number',
  pt_BR: 'Número de parcelas da compra',
  es_ES: 'Número de cuotas de compra',
};

export const INSTALLMENTS_PRICE = {
  en_US: 'Installment/financing amount',
  pt_BR: 'Valor da prestação/financiamento',
  es_ES: 'Monto a plazos/financiamiento',
};

export const SIXTEEN_OR_MORE_INSTALLMENTS = {
  en_US: '16 or more',
  pt_BR: '16 ou mais',
  es_ES: '16 o más',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};
