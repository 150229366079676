export const TXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};
export const TXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TXT_QUESTION_PREVIOUS_RESERVATION = {
  en_US: 'Have you made a reservation before the rental?',
  pt_BR: 'Você realizou uma reserva prévia ao aluguel?',
  es_ES: '¿Has hecho una reserva antes del alquiler?',
};
export const TXT_QUESTION_PREVIOUS_RESERVATION_PAYMENTEN = {
  en_US: 'Did you make any payment in the rental reservation?',
  pt_BR: 'Você realizou algum pagamento na reserva do aluguel?',
  es_ES: '¿Hiciste algún pago en la reserva del alquiler?',
};

export const DESCRIBE_PREVIOUS_RESERVATION_YES = {
  en_US: 'A new field will appear for you to inform about the payment',
  pt_BR: 'Um novo campo vai aparecer para você informar sobre o pagamento',
  es_ES: 'Aparecerá un nuevo campo para que informe sobre el pago',
};
export const DESCRIBE_PREVIOUS_RESERVATION_NO = {
  en_US: `You won't need a new field to inform you about the payment`,
  pt_BR: 'Não será necessário um novo campo para informar sobre o pagamento',
  es_ES: 'No necesitará un nuevo campo para informarle del pago',
};

export const DESCRIBE_PREVIOUS_PAYMENT_YES = {
  en_US: 'New fields will appear for you to enter payment details',
  pt_BR:
    'Novos campos vão aparecer para você informar os detalhes do pagamento',
  es_ES: 'Aparecerán nuevos campos para que introduzca los datos de pago',
};
export const DESCRIBE_PREVIOUS_PAYMENT_NO = {
  en_US: 'No new field will be needed to enter payment details',
  pt_BR: 'Não será necessário novos campo para informar detalhes do pagamento',
  es_ES:
    'No se necesitará ningún campo nuevo para introducir los datos de pago',
};
