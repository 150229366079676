import React from 'react';
import Input from '../../../../../../components/Input/Input';
import Select from '../../../../../../components/Select/Select';
import sanitize from '../../../../../../utils/sanitize';

const ProductArea = props => {
  const { values, handleChange, touched, handleBlur, errors, intl } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-4">
          <Input
            tooltipText={intl.TOOLTIP_COVERED_PRODUCT}
            label={intl.COVERED_PRODUCT}
            name="appliance_information.appliance_description"
            type="text"
            formikProps={props}
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            label={intl.PRODUCT_BRAND}
            name="appliance_information.brand"
            type="text"
            formikProps={props}
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            tooltipText={intl.TOOLTIP_PRODUCT_MODEL}
            label={intl.PRODUCT_MODEL}
            name="appliance_information.model"
            type="text"
            formikProps={props}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <Input
            tooltipText={intl.TOOLTIP_TICKET_AMOUNT}
            label={intl.PRODUCT_VALUE}
            name="appliance_information.cost.value"
            type="text"
            formikProps={props}
            maskedMoney
          />
        </div>
        <div className="col-12 col-md-6">
          <Select
            label={intl.LABEL_CURRENCY}
            name="appliance_information.cost.currency"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
            formikProps={props}
          />
        </div>
      </div>
    </>
  );
};

export default ProductArea;
