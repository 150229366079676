import React, { useContext } from 'react';
import styles from './Path.module.scss';
import PathItem from './PathItem/PathItem';
import { IntlContext } from '../../../intl';
import * as translations from './intl';

import atHome from '../../../assets/images/downloadApp/path/AtHome/Visa-en casa-Img.png';
import beforeTrip from '../../../assets/images/downloadApp/path/BeforeTrip/Visa-before-Img.png';
import duringTrip from '../../../assets/images/downloadApp/path/DuringTrip/Visa-during-Img.png';
import postTrip from '../../../assets/images/downloadApp/path/PostTrip/Visa-after-Img.png';

import PATH_MASTER from '../../../assets/images/downloadApp/path/Path.png';
import PATH_AFLUENT from '../../../assets/images/downloadApp/path/Path_afluent.png';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import PurchaseIcon from '../../../assets/icons/PurchaseIcon';
import ConciergeIcon from '../../../assets/icons/ConciergeIcon';
import ExtendedWarrantyIcon from '../../../assets/icons/ExtendedWarrantyIcon';
import PriceProtectionIcon from '../../../assets/icons/PriceProtectionIcon';
import AutoRentalIcon from '../../../assets/icons/AutoRentalIcon';
import TripCancelationIcon from '../../../assets/icons/TripCancelationIcon';
import TravelKitIcon from '../../../assets/icons/TravelKitIcon';
import CertificateIcon from '../../../assets/icons/CertificatesIcon';
import CovidIcon from '../../../assets/icons/CovidIcon';
import ReadyHealthIcon from '../../../assets/icons/ReadyHealthIcon';
import UploadDocumentsIcon from '../../../assets/icons/UploadDocumentsIcon';
import ReadyToFlyIcon from '../../../assets/icons/ReadyToFlyIcon';
import BaggageLossIcon from '../../../assets/icons/BaggageLossIcon';
import IEMSIcon from '../../../assets/icons/IEMSIcon';
import MissedConnectionIcon from '../../../assets/icons/MissedConnectionIcon';
import HeartIcon from '../../../assets/icons/HeartIcon';

const WIDTH_HEIGHT_ICON = 30;

const Path = () => {
  const { isAfluent } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);

  const homeBenefits = [
    {
      benefitIcon: () => (
        <PurchaseIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.AT_HOME_PURCHASE_TITLE,
      benefitDescription: intl.AT_HOME_PURCHASE_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <ConciergeIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.AT_HOME_CONCIERGE_TITLE,
      benefitDescription: intl.AT_HOME_CONCIERGE_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <ExtendedWarrantyIcon
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.AT_HOME_WARRANTY_TITLE,
      benefitDescription: intl.AT_HOME_WARRANTY_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <PriceProtectionIcon
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.AT_HOME_PRICE_TITLE,
      benefitDescription: intl.AT_HOME_PRICE_DESCRIPTION,
    },
  ];

  const beforeBenefits = [
    {
      benefitIcon: () => (
        <AutoRentalIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.BEFORE_AUTO_TITLE,
      benefitDescription: intl.BEFORE_AUTO_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <TripCancelationIcon
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.BEFORE_CANCELLATION_TITLE,
      benefitDescription: intl.BEFORE_CANCELLATION_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <TravelKitIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.BEFORE_TRAVEL_KIT_TITLE,
      benefitDescription: intl.BEFORE_TRAVEL_KIT_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <CertificateIcon
          invert_color={1}
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.BEFORE_CERTIFICATES_TITLE,
      benefitDescription: intl.BEFORE_CERTIFICATES_DESCRIPTION,
    },
  ];

  const readyBenefits = [
    {
      benefitIcon: () => (
        <ReadyHealthIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.READY_HEALTH_TITLE,
      benefitDescription: intl.READY_HEALTH_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <HeartIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.READY_COVID_TITLE,
      benefitDescription: intl.READY_COVID_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <UploadDocumentsIcon
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.READY_DOCUMENTS_TITLE,
      benefitDescription: intl.READY_DOCUMENTS_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <ReadyToFlyIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.READY_FLY_TITLE,
      benefitDescription: intl.READY_FLY_DESCRIPTION,
    },
  ];

  const duringBenefits = [
    {
      benefitIcon: () => (
        <BaggageLossIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.DURING_BAGGAGE_TITLE,
      benefitDescription: intl.DURING_BAGGAGE_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <AutoRentalIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.DURING_AUTO_TITLE,
      benefitDescription: intl.DURING_AUTO_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <IEMSIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.DURING_MEDICAL_TITLE,
      benefitDescription: intl.DURING_MEDICAL_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <MissedConnectionIcon
          width={WIDTH_HEIGHT_ICON}
          height={WIDTH_HEIGHT_ICON}
        />
      ),
      benefitName: intl.DURING_CONNECTION_TITLE,
      benefitDescription: intl.DURING_CONNECTION_DESCRIPTION,
    },
  ];

  const postBenefits = [
    {
      benefitIcon: () => (
        <IEMSIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.POST_MEDICAL_TITLE,
      benefitDescription: intl.POST_MEDICAL_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <ConciergeIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.POST_CONCIERGE_TITLE,
      benefitDescription: intl.POST_CONCIERGE_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <ConciergeIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.POST_CONCIERGE_2_TITLE,
      benefitDescription: intl.POST_CONCIERGE_2_DESCRIPTION,
    },
    {
      benefitIcon: () => (
        <IEMSIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.POST_MEDICAL_2_TITLE,
      benefitDescription: intl.POST_MEDICAL_2_DESCRIPTION,
    },
  ];

  return (
    <div className={styles.container}>
      <div
        className={styles.path}
        style={{
          backgroundImage: `url(${isAfluent() ? PATH_AFLUENT : PATH_MASTER})`,
        }}
      >
        <div className={styles.pathItem}>
          <PathItem
            title={intl.TITLE_STEP_HOME}
            description={intl.DESCRIPTION_STEP_HOME}
            image={atHome}
            benefits={homeBenefits}
            headerStyle={{ paddingLeft: '195px' }}
          />
        </div>
        <div className={styles.pathItem}>
          <PathItem
            title={intl.TITLE_STEP_BEFORE}
            description={intl.DESCRIPTION_STEP_BEFORE}
            image={beforeTrip}
            benefits={beforeBenefits}
            headerStyle={{ paddingLeft: '320px' }}
          />
        </div>
        <div className={styles.pathItem}>
          <PathItem
            title={intl.TITLE_STEP_READY}
            description={intl.DESCRIPTION_STEP_READY}
            benefits={readyBenefits}
            headerStyle={{ paddingLeft: '35px' }}
          />
        </div>
        <div className={styles.pathItem}>
          <PathItem
            title={intl.TITLE_STEP_DURING}
            description={intl.DESCRIPTION_STEP_DURING}
            image={duringTrip}
            benefits={duringBenefits}
            headerStyle={{ paddingLeft: '170px' }}
          />
        </div>
        <div className={styles.pathItem}>
          <PathItem
            title={intl.TITLE_STEP_POST}
            description={intl.DESCRIPTION_STEP_POST}
            image={postTrip}
            benefits={postBenefits}
            headerStyle={{ paddingLeft: '125px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Path;
