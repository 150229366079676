import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import * as Yup from 'yup';

export const initialValuesExpirationDate = cardSelected => {
  let month = '';
  let year = '';

  const { effective_end_date } = cardSelected;
  if (effective_end_date) {
    year = String(effective_end_date).substring(0, 4);
    month = String(effective_end_date).substring(5, 7);
  }

  return {
    month,
    year,
  };
};

export const validationSchemaExpirationDate = intl =>
  Yup.lazy(values => {
    return Yup.object().shape({
      month: Yup.number()
        .min(1, intl.TEXT_INVALID_DATE)
        .max(12, intl.TEXT_INVALID_DATE)
        .required(intl.TEXT_FIELD_REQUIRED),
      year: Yup.number()
        .required(intl.TEXT_FIELD_REQUIRED)
        .test('invalid date', intl.TEXT_INVALID_DATE, function (value) {
          const now = new Date();
          const startOfCurrentMonth = new Date(
            now.getFullYear(),
            now.getMonth(),
            1
          );

          const tenYearsFromToday = new Date();
          tenYearsFromToday.setFullYear(startOfCurrentMonth.getFullYear() + 10);

          const dateInserted = new Date(
            parseISO(`${values.year}-${values.month}-01`)
          );
          return (
            (isAfter(dateInserted, startOfCurrentMonth) ||
              isEqual(dateInserted, startOfCurrentMonth)) &&
            (isBefore(dateInserted, tenYearsFromToday) ||
              isEqual(dateInserted, tenYearsFromToday))
          );
        }),
    });
  });
