/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';
import moment from 'moment';
import Calendar from '../../../../../../components/Calendar/Calendar';
import styles from './DatesArea.module.scss';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const DatesArea = props => {
  const { CONFIG_B2B2C, intl, setFieldTouched, setStep, values } = props;
  const { utils, actions } = useContext(B2B2CContext);
  const quote = utils.getQuote();
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const maxReturnDate = values.Quote.TripInitialDate
    ? new Date(moment(values.Quote.TripInitialDate).add(60, 'days'))
    : undefined;

  useEffect(() => {
    if (values.Quote.TripInitialDate && values.Quote.TripInitialDate !== '') {
      const newQuote = {
        ...quote,
      };
      newQuote.Quote.TripInitialDate = values.Quote.TripInitialDate;
      actions.formDataCotizar.setQuote(newQuote);
    }
  }, [values.Quote.TripInitialDate]);

  useEffect(() => {
    if (values.Quote.TripEndDate && values.Quote.TripEndDate !== '') {
      const newQuote = {
        ...quote,
      };
      newQuote.Quote.TripEndDate = values.Quote.TripEndDate;
      actions.formDataCotizar.setQuote(newQuote);
    }
  }, [values.Quote.TripEndDate]);

  return (
    <>
      {CONFIG_B2B2C.purchase.form.payment.initial_date.show && (
        <div className="col-12 col-md-6">
          <Calendar
            label={intl.DEPARTURE_DATE}
            name={`Quote.TripInitialDate`}
            minDate={new Date()}
            setFieldTouched={setFieldTouched}
            formikProps={props}
            axa
            disabled={!CONFIG_B2B2C.purchase.form.payment.initial_date.edit}
          />
        </div>
      )}
      {CONFIG_B2B2C.purchase.form.payment.end_date.show && (
        <div className="col-12 col-md-6">
          <Calendar
            label={intl.RETURN_DATE}
            name={`Quote.TripEndDate`}
            minDate={values.Quote.TripInitialDate}
            maxDate={maxReturnDate}
            setFieldTouched={setFieldTouched}
            formikProps={props}
            axa
            disabled={!CONFIG_B2B2C.purchase.form.payment.end_date.edit}
          />
        </div>
      )}
      {CONFIG_B2B2C.purchase.back_link === '/cross-sell-benefit' && (
        <div className="col-12 col-md-6">
          <button
            type="submit"
            className={`${styles.customButton} ${styles[theme]}`}
            onClick={() => setStep(1)}
          >
            <a className={styles[theme]} rel="noopener noreferrer" href="#">
              {intl.EDIT_DATES_TEXT}
            </a>
          </button>
        </div>
      )}
    </>
  );
};

export default DatesArea;
