export const TEXT_OBLIGATION = {
  en_US: 'It is our obligation to inform you of the following:',
  pt_BR: 'É nossa obrigação informá-lo sobre o seguinte:',
  es_ES: 'Es nuestra obligación informarte de lo siguiente:',
};

export const TEXT_ADVISE = {
  en_US:
    'Any person who, knowingly and with intent to defraud any insurance company or other person submits a form containing materially false, incomplete or misleading information commits a fraudulent insurance act and may be subject to criminal charges and civil penalties.',
  pt_BR:
    'Qualquer pessoa que, conscientemente e com a intenção de fraudar qualquer companhia de seguros ou outra pessoa, apresentar um formulario contendo informações materialmente falsas, incompletas ou enganosas comete um ato fraudulento de seguro e pode estar sujeita a acusações criminais e penalidades civis.',
  es_ES:
    'Toda persona que, a sabiendas y con intención de defraudar a cualquier compañía de seguros o a otra persona, presente un formulario que contenga información materialmente falsa, incompleta o engañosa, comete un acto fraudulento en materia de seguros y puede ser objeto de cargos penales y sanciones civiles.',
};

export const TEXT_DECLARE_FOLLOWING = {
  en_US: 'I declare that the following information is true:',
  pt_BR: 'Declaro que as informações a seguir são verdadeiras:',
  es_ES: 'Declaro que la siguiente información es verdadera:',
};

export const CHECKBOX_TEXT_1 = {
  en_US:
    'I understand that this form must be completed and that all benefit requirements, including but not limited to a police report (if required), must be submitted before any claim can be processed and benefits paid. *',
  pt_BR:
    'Entendo que este formulário deve ser preenchido e que todos os requisitos de benefícios, incluindo, entre outros, o relatório policial (se necessário), devem ser enviados antes que qualquer sinistro possa ser processado e os benefícios pagos. *',
  es_ES:
    'Entiendo que este formulario debe ser completado y que todos los requisitos de beneficios, incluyendo pero no limitado a un informe policial (si es necesario), deben ser presentados antes de que cualquier reclamación pueda ser procesada y los beneficios pagados. *',
};

export const CHECKBOX_TEXT_2 = {
  en_US:
    'International emergency medical services are provided by AXA. In addition to AXA, other third parties are involved in providing this service to the cardholder. By accessing International Emergency Medical services, the cardholder consents to the sharing of their personal information with third parties, which may include international transfers. The personal information will be processed in order to provide the service and offer these benefits. *',
  pt_BR:
    'Os serviços médicos de emergência internacionais são fornecidos pela AXA. Além dessa entidade, outros terceiros estão envolvidos na prestação desse serviço ao titular do cartão. Ao acessar os serviços do International Emergency Medical, o titular do cartão autoriza o compartilhamento de suas informações pessoais com terceiros, o que pode incluir transferências internacionais. As informações pessoais serão processadas para prestar o serviço e oferecer esses benefícios. *',
  es_ES:
    'Los servicios médicos de urgencia internacionales son proporcionados por AXA. Además de AXA, otros terceros participan en la prestación de este servicio al titular de la tarjeta. Al acceder a los servicios médicos de urgencia internacionales, el titular de la tarjeta autoriza a compartir su información personal con terceros, lo que puede incluir transferencias internacionales. La información personal se procesará con el fin de prestar el servicio y ofrecer estas prestaciones. *',
};

export const CHECKBOX_TEXT_3 = {
  en_US:
    'I declare that I have read the Terms and Conditions applicable to the coverage indicated in the certificate to be generated, and I meet the eligibility conditions for it as indicated in the mentioned Terms and Conditions (for example, having purchased the entire ticket with an Eligible Visa card in order to have travel assistance coverage, among others). *',
  pt_BR:
    'Declaro que li os Termos e Condições aplicáveis à cobertura indicada no Bilhete de Seguro a ser gerado, e cumpro com as condições de elegibilidade para o mesmo indicadas nos mencionados Termos e Condições (por exemplo, ter adquirido a totalidade da passagem com um cartão Visa Elegível a fim de contar com a cobertura de assistência em viagem, entre outros). *',
  es_ES:
    'Declaro que he leído los Términos y Condiciones aplicables a la cobertura indicada en el certificado a generarse, y cumplo con las condiciones de elegibilidad para el mismo indicadas en los mencionados Términos y Condiciones (por ejemplo, haber adquirido la totalidad del pasaje con una tarjeta Visa Elegible a fin de contar con la cobertura de asistencia en viaje, entre otros). *',
};

export const LABEL_DATE = {
  en_US: 'Date: ',
  pt_BR: 'Data: ',
  es_ES: 'Fecha: ',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};
