export const LABEL_INCIDENT = {
  en_US: 'Incident',
  pt_BR: 'Incidente',
  es_ES: 'Incidente',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_DEATH_DATE = {
  en_US: 'Death date',
  pt_BR: 'Data da morte',
  es_ES: 'Fecha de la muerte',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descrição do incidente',
  es_ES: 'Describa el incidente',
};

export const LABEL_INCIDENT_LOCATION = {
  en_US: 'Incident location',
  pt_BR: 'Endereço do incidente',
  es_ES: 'Dirección del incidente',
};
