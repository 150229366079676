import { Flight } from '../../@types/APIs/AEROAPI/Flight';
import { FlightInfoData } from '../FlightInfo/FlightInfoContext';
import { formatISOWithOffset } from '../../utils/date';

export const formatAeroapiFlightToFlightInfo = (
  flight: Flight,
  airportOrigin?: Airport,
  airportDestination?: Airport
): FlightInfoData => {
  const {
    actual_out,
    estimated_out,
    scheduled_out,
    origin,
    terminal_origin,
    gate_origin,
    actual_in,
    estimated_in,
    scheduled_in,
    destination,
    terminal_destination,
    gate_destination,
    ident_iata,
    ident,
    progress_percent,
    route_distance,
    departure_delay,
    arrival_delay,
    cancelled,
  } = flight;

  const startAtNoTimezone = actual_out || estimated_out || scheduled_out;
  const endAtNoTimezone = actual_in || estimated_in || scheduled_in;

  return {
    flightNumber: ident_iata || ident,
    startAt: formatISOWithOffset(startAtNoTimezone, origin.timezone),
    gateOrigin: gate_origin,
    terminalOrigin: terminal_origin,
    airportOrigin: origin.code_iata || origin.code_lid || origin.code,
    cityOrigin: origin.city,
    countryOrigin: airportOrigin?.country_code,
    endAt: formatISOWithOffset(endAtNoTimezone, destination.timezone),
    gateDestination: gate_destination,
    terminalDestination: terminal_destination,
    airportDestination:
      destination.code_iata || destination.code_lid || destination.code,
    cityDestination: destination.city,
    countryDestination: airportDestination?.country_code,
    progressPercent: progress_percent,
    routeDistance: route_distance,
    departureDelay: departure_delay,
    arrivalDelay: arrival_delay,
    cancelled,
  };
};
