export const LABEL_PHONE_CALL = {
  en_US: 'Phone call',
  es_ES: 'Llamada telefónica',
  pt_BR: 'Chamada telefônica',
};

export const LABEL_VIDEO_CALL = {
  en_US: 'Video appointment',
  es_ES: 'Cita por video',
  pt_BR: 'Vídeo chamada',
};
