export default (env, axios, errorHandler) => {
  return {
    getBankAccounts: async customer_id => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/bank_accounts`;
      return errorHandler(axios.get(url), 'getBankAccounts', {
        customer_id,
        url,
      });
    },
    updateBankInfo: async (customer_id, bank_account_id, data) => {
      const url = `/bank/specific/visagateway/v1/customers/${customer_id}/bank_accounts/${bank_account_id}`;
      return errorHandler(axios.patch(url, data), 'updateBankInfo', {
        data,
        bank_account_id,
        customer_id,
        url,
      });
    },
    getBankAccountDetails: async (customer_id, bank_account_id) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/bank_accounts/${bank_account_id}`;
      return errorHandler(axios.get(url), 'getBankAccountDetails', {
        bank_account_id,
        customer_id,
        url,
      });
    },
    createBankAccount: (customer_id, data) => {
      const url = `/bank/specific/visagateway/v1/customers/${customer_id}/bank_accounts`;
      return errorHandler(axios.post(url, data), 'createBankAccount', {
        data,
        customer_id,
        url,
      });
    },
    deleteBankAccount: (customer_id, bank_account_id) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/bank_accounts/${bank_account_id}`;
      return errorHandler(axios.delete(url), 'deleteBankAccount', {
        bank_account_id,
        customer_id,
        url,
      });
    },
  };
};
