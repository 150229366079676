import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { pt_BR } from '../../../../../../intl/idioms';
import { getCountryByInitials } from '../../../../../../intl/utils';
import { formatCustomDate } from '../../../../../../utils/date';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const incident = claimDetails && claimDetails.incident;

  const incidentLocation = incident && incident.location;

  const incidentDate = incident.date
    ? formatCustomDate(incident.date, 'YYYY-MM-DD', idiom, country)
    : '-';

  const incidentCountry =
    incidentLocation && incidentLocation.country
      ? incidentLocation.country
      : '-';

  const incidentLocality =
    incidentLocation && incidentLocation.locality
      ? incidentLocation.locality
      : '-';

  const incidentState =
    incidentLocation && incidentLocation.state ? incidentLocation.state : '-';

  const incidentStreetAddress =
    incidentLocation && incidentLocation.street_address
      ? incidentLocation.street_address
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_INCIDENT_DATE} value={incidentDate} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_COUNTRY}
          value={getCountryByInitials(incidentCountry, idiom)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_STATE}
          value={incidentState}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_CITY}
          value={incidentLocality}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_PLACE}
          value={incidentStreetAddress}
        />
      </div>
    </>
  );
};

export default PersonalArea;
