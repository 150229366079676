export const SUBTITLE_BENEFITS = {
  en_US: 'Benefits',
  pt_BR: 'Benefícios',
  es_ES: 'Beneficios',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Appointment information',
  es_ES: 'Información de cita',
  pt_BR: 'Informações do agendamento',
};
