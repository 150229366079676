import { useContext } from 'react';
import styles from './Item.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import arrowMaster from '../../../../assets/images/icon/arrow-right-master.svg';
import arrowAfluent from '../../../../assets/images/icon/arrow-right-afluent.svg';
import Button from '../../../../componentsV2/Button/Button';
import { dataLayerHelpTrack } from '../../../../utils/GTM_helper';
import Link from '../../../../componentsV2/Link/Link';

const Item = ({
  title,
  icon,
  info = '',
  email = false,
  click,
  GTM_type,
  GTM_id,
}) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const arrow = themes === 'theme-master' ? arrowMaster : arrowAfluent;

  const _click = () => {
    click();
    dataLayerHelpTrack(GTM_type, GTM_id);
  };

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <div className={`${styles[themes]} ${styles.icon}`}>{icon}</div>

      <div className={styles.contentInfo}>
        <h2 className={`${styles.title} ${styles[themes]}`}>{title}</h2>
        {!info ? (
          <Button
            onClick={_click}
            type="Tertiary"
            trailingIcon="Chevron Right"
            ariaLabel={intl.IMAGE_ARROW}
            className={styles.buttonLink}
            label={intl.OPEN_CHAT}
          />
        ) : (
          <Link
            onClick={() => dataLayerHelpTrack(GTM_type, GTM_id)}
            className={styles.link}
            href={`mailto:${info}`}
            label={info}
            icon={false}
          />
        )}
      </div>
    </div>
  );
};

export default Item;
