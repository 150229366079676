import { useContext, useMemo, useRef, useCallback } from 'react';
import { Formik } from 'formik';
import { useIntl } from '../../../../intl';
import { useTheme } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './index.module.scss';
import * as translations from './intl';
import AcordeonSession from '../../../../components/AcordeonSession/AcordeonSession';
import { getMaskedBinOrPan } from '../../../../utils/card';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { cardsServices, voucherService } from '../../../../services';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import cinepolisImageModal from '../../../../assets/images/modal/cinepolisModal.jpg';
import {
  initialValuesExpirationDate,
  validationSchemaExpirationDate,
} from './formUtils';
import Input from '../../../../components/Input/Input';
import { useHistory } from 'react-router-dom';

const Voucher = ({ number }) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();
  return (
    <div className={`${styles.voucher} ${styles[theme]}`}>
      <span className={styles.number}>{number}</span>
    </div>
  );
};

const Form = ({ setStep }) => {
  const { translate, idiomForApi } = useIntl();
  const { utils, actions } = useContext(StateContext);
  const buttonSubmitRef = useRef(null);

  const intl = translate(translations);
  const card = utils.getSelectedCard();
  const maskedBinOrPan = getMaskedBinOrPan(
    card.credit_card_token,
    card.external_reference
  );
  const title = card.effective_end_date
    ? intl.TITLE_EXPIRATION_DATE_CHECK
    : intl.TITLE_EXPIRATION_DATE_INSERT;
  const customer_id = utils.getCn();
  const idiomAPI = idiomForApi();
  const history = useHistory();

  const initialValues = useMemo(() => initialValuesExpirationDate(card), []);
  const validationSchema = useMemo(
    () => validationSchemaExpirationDate(intl),
    []
  );

  const submit = async values => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(intl.LOADING);

      const { month, year } = values;
      const effective_end_date = `${year}-${month}-01`;

      if (effective_end_date !== card.effective_end_date) {
        const updateCardData = {
          effective_end_date,
        };
        await cardsServices.updateCard(
          customer_id,
          utils.getBin(true),
          updateCardData
        );
        await actions.cards.reloadLoggedUserCards(false);
      }

      const dataToRedeem = {
        benefit_external_reference: 'CINEPOLIS_PASS',
        benefit_type: 'MOVIE',
      };

      const response = await voucherService.redeemVoucher(
        customer_id,
        dataToRedeem,
        idiomAPI
      );
      const { voucher_number } = response.data;

      getVouchers().then(vouchers => {
        if (vouchers && voucher_number) {
          const voucherCreated = vouchers.find(
            voucher => voucher.voucher_number === voucher_number
          );
          actions.modal.showModal(
            '',
            false,
            <ModalInformation
              type="success"
              message={intl.MODAL_TITLE}
              subtitle={intl.MODAL_SUBTITLE}
              textBtn={intl.MODAL_BTN}
              textBtnDeny={intl.BUTTON_CLOSE}
              clickBtn={() => {
                actions.modal.closeModal();
                history.push('/profile/vouchers#active');
              }}
              buttonConfirmType="blueWithoutBorder"
              buttonDenyType="gold"
              image={cinepolisImageModal}
              content={<Voucher number={voucherCreated.voucher_id} />}
              clickBtnDeny={() => {
                actions.modal.closeModal();
                history.push('/dashboard');
              }}
            />
          );
        } else {
          actions.modal.showModal(
            '',
            true,
            <ModalInformation
              type="error"
              message={intl.MODAL_TITLE_ERROR}
              subtitle={intl.MODAL_SUBTITLE_ERROR}
              image={cinepolisImageModal}
            />
          );
        }
      });
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description ===
          'Annual redemption limit exceeded'
      ) {
        actions.modal.showModal(
          '',
          true,
          <ModalInformation
            type="error"
            message={intl.TITLE_VOUCHERS_EXCEEDED}
            subtitle={intl.SUBTITLE_VOUCHERS_EXCEEDED}
            image={cinepolisImageModal}
          />
        );
      } else {
        actions.modal.showModal(
          '',
          true,
          <ModalInformation
            type="error"
            message={intl.MODAL_TITLE_ERROR}
            subtitle={intl.MODAL_SUBTITLE_ERROR}
            image={cinepolisImageModal}
          />
        );
      }
    }
    actions.loadingFullScreen.hideLoadingFullScreen();
  };

  const getVouchers = async () => {
    try {
      const { data } = await voucherService.getVouchers(
        customer_id,
        'CINEPOLIS',
        idiomAPI
      );
      return data;
    } catch (error) {
      return null;
    }
  };

  const submitFormRef = useCallback(() => {
    if (buttonSubmitRef.current) {
      buttonSubmitRef.current.click();
    }
  }, [buttonSubmitRef]);

  return (
    <AcordeonSession
      status={true}
      notOpen
      title={title(maskedBinOrPan)}
      next={submitFormRef}
      back={() => {
        setStep(1);
      }}
      txtBtnNext={intl.REDEEM}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {props => {
          const {
            values,
            handleChange,
            touched,
            handleBlur,
            errors,
            handleSubmit,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <button
                ref={buttonSubmitRef}
                type="submit"
                style={{ display: 'none' }}
              />

              <div className="row">
                <div className="col-12 col-sm-4 col-md-2 col-lg-1">
                  <Input
                    value={values.month}
                    label={intl.LABEL_MONTH}
                    name="month"
                    type="text"
                    mask="maskedMonth"
                    onChange={handleChange}
                    touched={touched.month}
                    onBlur={handleBlur}
                    error={errors.month}
                  />
                </div>
                <div className="col-12 col-sm-4 col-md-2 col-lg-1">
                  <Input
                    value={values.year}
                    label={intl.LABEL_YEAR}
                    name="year"
                    type="text"
                    mask="maskedYear"
                    onChange={handleChange}
                    touched={touched.year}
                    onBlur={handleBlur}
                    error={errors.year}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </AcordeonSession>
  );
};

export default Form;
