import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import RadioButton from '../../../../../../components/RadioButton/RadioButton';
import styles from './IsCardHolderArea.module.scss';
import { getInitialValues } from '../initialValues';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';

const IsCardHolderArea = props => {
  const { setValues, setIsCardHolder, values, saveInSession, user } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const themes = getGlobalTheme();

  const blankForm = () => {
    const newValues = getInitialValues();
    newValues.MPH.isCardHolder = false;

    setValues(newValues);
    setIsCardHolder(false);
    saveInSession(newValues);
  };

  const resetForm = () => {
    setValues(getInitialValues(user, idiom));
    setIsCardHolder(true);
    saveInSession(getInitialValues(user, idiom));
  };

  return (
    <div
      className={`row ${styles.isCardHolder}`}
      data-testid="InputClaimCHIsCHArea"
    >
      <div
        className={`${styles.fontGray12} ${styles.options} ${styles[themes]} row`}
      >
        <div className={`col-lg-12 col-sm-12`}>
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.LABEL_BENEFICIARY_IS_CH}
          </label>
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_BENEFICIARY_IS_CH}
            defaultChecked={values.MPH.isCardHolder === true}
            text={intl.TEXT_YES}
            name="MPH.isCardHolder"
            dataTestid="IscardHolderYes"
            value={true}
            onChange={resetForm}
            axa
          />
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_BENEFICIARY_IS_CH}
            defaultChecked={values.MPH.isCardHolder === false}
            text={intl.TEXT_NO}
            name="MPH.isCardHolder"
            value={false}
            onChange={blankForm}
            dataTestid="IscardHolderNo"
            axa
          />
        </div>
      </div>
    </div>
  );
};

export default IsCardHolderArea;
