import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const TEXT_PLACE_OF_LOSS = {
  en_US: 'Place of loss',
  es_ES: 'Lugar de la pérdida',
  pt_BR: 'Lugar da perda',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};
export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};

export const TEXT_CITY_INCIDENT = {
  en_US: 'City *',
  es_ES: 'Ciudad *',
  pt_BR: 'Cidade *',
};
