/* eslint-disable no-async-promise-executor */
import { cardsServices, productsAndBenefitsServices } from '../services';
import { countryToIdiom } from '../intl/utils';
import { isCoutryEnableToUsePAN } from '../utils/environments_variables';

export async function reloadLoggedUserCards(reload = true) {
  sessionStorage.removeItem('cards');
  await this.actions.cards.getLoggedUserCards(reload);
}

export function setLoadingCardsStatus() {
  const cards = { status: 'LOADING', data: [], selectedCardIndex: -1 };
  this.setState({ cards });
}

export async function loadUserCardsFromApi(reload = true) {
  const { user } = this.state;
  const { cn } = user.data.userInfo;
  const cards = { status: '', data: [], selectedCardIndex: -1 };

  if (reload) this.actions.cards.setLoadingCardsStatus();
  try {
    cards.data = (await cardsServices.getLoggedUserCards(cn)).data;
    await matchInfoCardName(cards.data);
    cards.selectedCardIndex = 0;
    // await this.setStateAsync({ cards });
    cards.status = 'OK';
    sessionStorage.setItem('cards', JSON.stringify(cards));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      cards.data = [];
      cards.status = 'OK';
    } else {
      cards.status = 'NOT_LOADED';
    }
  }
  const { data: cardsData, selectedCardIndex } = cards;
  const selectedCard = cardsData[selectedCardIndex];
  const country = selectedCard && selectedCard.country;
  this.props.intlContext.setCountryInitialsByFullName(country);
  return cards;
}

export function setIdiomByCard(cards) {
  const { data: cardsData, selectedCardIndex } = cards;
  const selectedCard = cardsData[selectedCardIndex];
  const country = selectedCard && selectedCard.country;
  if (country) {
    const idiom = countryToIdiom(country);
    this.props.intlContext.setIdiom(idiom);
    this.props.intlContext.setCountryInitialsByFullName(country, idiom);
  }
}

export async function getLoggedUserCards(reload = true) {
  let cards = await this.actions.cards.loadUserCardsFromApi(reload);
  this.actions.cards.setIdiomByCard(cards);
  this.setState({ cards });
}

export async function changeSelectedCardIndex(id) {
  const propTokenOrExternal =
    id && id.length === 16 ? 'credit_card_token' : 'external_reference';
  const cards = { ...this.state.cards };
  cards.selectedCardIndex = cards.data.findIndex(
    card => card[propTokenOrExternal] === id
  );
  this.actions.cards.setIdiomByCard(cards);

  let newListCards = cards.data.map(card => {
    card.is_preferred = false;
    if (card.external_reference === id) {
      card.is_preferred = true;
    }
    return card;
  });
  sessionStorage.setItem(
    'cards',
    JSON.stringify({ ...cards, data: newListCards })
  );
  await this.setStateAsync({ cards });
}

export async function removeCard(binNumber) {
  const propTokenOrExternal =
    binNumber && binNumber.length === 16
      ? 'credit_card_token'
      : 'external_reference';
  const newCards = { ...this.state.cards };
  const newData = [...newCards.data];
  const binNumberSelected =
    newData[newCards.selectedCardIndex][propTokenOrExternal];

  const data = newData.filter(card => {
    return card[propTokenOrExternal] !== binNumber;
  });

  let index = data.findIndex(
    element => element[propTokenOrExternal] === binNumberSelected
  );

  const cards = { ...this.state.cards, data: data, selectedCardIndex: index };
  await this.setStateAsync({ cards });
  sessionStorage.setItem('cards', JSON.stringify(cards));
}

const matchInfoCardName = async cards => {
  const resultado = await fetchCardInfo(cards);
  cards.forEach((card, index) => {
    card.name = resultado[index].name;
    card.image = resultado[index].image;
    card.type = resultado[index].type;
  });
};

const fetchCardInfo = async cards => {
  try {
    const requests = cards.map(card => {
      return new Promise(async resolve => {
        try {
          const external_reference =
            card.credit_card_token &&
            card.credit_card_token.length > 0 &&
            isCoutryEnableToUsePAN(card.country.toUpperCase())
              ? card.credit_card_token
              : card.external_reference;

          const country = card && card.country;
          const idiom = countryToIdiom(country);
          const idiomFormatted = idiom.replace('_', '-');
          const response =
            await productsAndBenefitsServices.getProductDetailByBinCode(
              external_reference,
              idiomFormatted
            );
          const filterImage =
            (response.data.images &&
              response.data.images.find(image => image.type === 'PICTURE')) ||
            '';
          resolve({
            name: response.data.name,
            image: filterImage && filterImage.content_url,
            type:
              response.data &&
              response.data.partner_reference &&
              response.data.partner_reference.card &&
              response.data.partner_reference.card.type,
          });
        } catch (error) {
          resolve({ name: card.name, image: '', type: '' });
        }
      });
    });
    return Promise.all(requests);
  } catch (error) {}
};
