/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../TripCancellation.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const ExpensesInsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    sumAmountReimbursed,
    setFieldValue,
  } = props;

  useEffect(() => {
    if (values.other_insurance_covering.checked === 'false') {
      setFieldValue(
        'reimbursed_transportation_insurance.value',
        values.reimbursed_transportation_insurance.value -
          values.other_insurance_covering.value
      );
      setFieldValue('other_insurance_covering.value', '');
    }
  }, [values.other_insurance_covering.checked]);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_RECEIVE_OTHER_INSURANCE}
            </label>
          </div>

          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={
                values.other_insurance_covering.checked === 'true'
              }
              text={intl.TEXT_OPTION_YES}
              name="other_insurance_covering.checked"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={
                values.other_insurance_covering.checked === 'false'
              }
              text={intl.TEXT_OPTION_NO}
              name="other_insurance_covering.checked"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`col-12 col-sm-12 col-md-6  ${
              values.other_insurance_covering.checked === 'true'
                ? ''
                : styles.none
            }`}
            role="contentAmountOtherInsurance"
          >
            <Input
              label={intl.TEXT_AMOUNT_OTHER_INSURANCE}
              type="text"
              maskedMoney
              name="other_insurance_covering.value"
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={values.other_insurance_covering.value}
              onChange={sumAmountReimbursed}
              onBlur={handleBlur}
              touched={
                touched.other_insurance_covering &&
                touched.other_insurance_covering.value
              }
              error={
                errors.other_insurance_covering &&
                errors.other_insurance_covering.value
              }
              dataTestid="inputOtherInsuranceCovering"
            />
          </div>

          <div
            className={`col-12 col-sm-12 col-md-6  ${
              values.other_insurance_covering.checked === 'true'
                ? ''
                : styles.none
            }`}
            role="contentAdditionalCost"
          >
            <Select
              label={intl.LABEL_CURRENCY}
              name="other_insurance_covering.currency"
              value={values.additional_costs_transport.price.currencyValue}
              disabled
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.other_insurance_covering &&
                errors.other_insurance_covering.currency
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              dataTestid="inputAdditionalCosts"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpensesInsuranceArea;
