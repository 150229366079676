import moment from 'moment';

export async function setForm1(data, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Quote = data;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setForm2(data, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Plans = data;

    if (data && !data.isEmpty) {
      localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));
    }

    return { ...state, formDataCotizar };
  });
}

export async function setForm3(data, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.SelectedPlan = data;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setTerms(terms, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Terms = terms;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setMPH(data, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Travellers.MPH = data;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setPassengers(data, setState) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Travellers.Passengers = data;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setDataForm(
  form1 = null,
  form2 = null,
  form3 = null,
  terms = null,
  travellers = { MPH: null, Passengers: null },
  setState
) {
  let formDataCotizar;
  setState(state => {
    formDataCotizar = { ...state.formDataCotizar };
    formDataCotizar.Quote = form1;
    formDataCotizar.Plans = form2;
    formDataCotizar.SelectedPlan = form3;
    formDataCotizar.Terms = terms;
    formDataCotizar.Travellers = travellers;

    localStorage.setItem('formDataCotizar', JSON.stringify(formDataCotizar));

    return { ...state, formDataCotizar };
  });
}

export async function setStep(stepValue, setState) {
  setState(state => {
    return { ...state, step: stepValue };
  });
  localStorage.setItem('step', JSON.stringify(stepValue));
}

export async function setExpire(setState) {
  const setValue = moment().add(1, 'hour').toString();
  setState(state => {
    return { ...state, expire: setValue };
  });
  localStorage.setItem('expire', JSON.stringify(setValue));
}
