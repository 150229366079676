import { ReactNode, useContext } from 'react';
import styles from './InfoLabelValue.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../utils/sanitize';

interface InfoLabelValueProps {
  label: string;
  value?: string;
  axa?: boolean;
  withSanitize?: boolean;
  status?: ReactNode;
  className?: string;
}

const InfoLabelValue = ({
  label,
  value,
  axa,
  withSanitize = true,
  status = null,
  className = '',
}: InfoLabelValueProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div
      className={`${styles.container}  ${styles[themes]} ${
        axa ? styles.axa : ''
      } ${className}`}
    >
      <label className={`${styles[themes]}`}>{label}</label>
      {value && (
        <p
          dangerouslySetInnerHTML={{
            __html: withSanitize ? sanitize(value) : value,
          }}
        />
      )}
      {status && status}
    </div>
  );
};

export default InfoLabelValue;
