import * as Yup from 'yup';
import moment from 'moment';

export const priceProtectionSchema = (intl, idiom, limitDate) => {
  let currency;
  return Yup.object().shape({
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    amount_estimation_damage: Yup.lazy(parentValue => {
      currency = parentValue.currency;
      return Yup.object().shape({
        value: Yup.mixed().test(
          'amount',
          intl.VALIDATE_AMOUNT_ESTIMATION_DAMAGE,
          function (value) {
            return value === '' || Number(value) > 0;
          }
        ),
        currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }),
    purchased_item: Yup.lazy(parentValue => {
      return Yup.object().shape({
        label: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .test('non-eligible', intl.RULE_ELEGIBILITY, function (value) {
            if (idiom !== 'pt_BR') {
              return true;
            }

            const nonEligibleProducts = [
              'perfume',
              'passagem',
              'hotel',
              'bebida',
              'tabaco',
              'combustível',
              'cosmético',
              'remédio',
              'janela',
              'porta',
              'maçaneta',
              'pia',
              'vaso sanitário',
              'armário',
              'cano',
              'fio',
              'espelho',
              'lâmpada',
              'lente',
              'óculos',
              'telescópio',
              'microscópio',
              'ticket',
              'entrada',
              'vinho',
              'bebida',
              'garrafa',
            ];

            let elegible = true;
            nonEligibleProducts.forEach(product => {
              if (value.toLocaleLowerCase().includes(product)) {
                elegible = false;
              }
            });
            return elegible;
          }),
        model_number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        manufacturer: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        price: Yup.object().shape({
          value: Yup.string()
            .test('minimum', intl.RULE_PRICE, function (value) {
              if (idiom !== 'pt_BR') {
                return true;
              }

              if (currency === 'BRL') {
                return Number(value).toFixed(2) >= 150.0;
              } else if (currency === 'USD') {
                return Number(value).toFixed(2) >= 25.0;
              } else {
                return true;
              }
            })
            .required(intl.TEXT_FIELD_REQUIRED),
        }),
        total_invoice_amount: Yup.object().shape({
          value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
        purchase_date: Yup.date()
          .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
          .test('rule 30 days', intl.RULE_30_DAYS, function (value) {
            if (idiom !== 'pt_BR') {
              return true;
            }
            return moment(value).isSameOrAfter(
              moment().subtract(30, 'days'),
              'date'
            );
          })
          .test('limitDate', intl.LIMIT_DATE, function (value) {
            if (idiom !== 'pt_BR' || !limitDate) {
              return true;
            }
            return moment(value).isSameOrBefore(moment(limitDate));
          })
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        paymentMethod: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        installments_number: Yup.string().when(
          ['paymentMethod'],
          {
            is: paymentMethod => {
              return paymentMethod === 'installment';
            },
            then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          },
          ['paymentMethod']
        ),
        retailer: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }),
    advertised_price: Yup.object().shape({
      publishing_location: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      retail_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      price: Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      discovery_date: Yup.mixed().test(
        'match',
        intl.VALIDATE_DATE_VALID2,
        function (discovery_date) {
          return moment(discovery_date).isSameOrBefore(
            moment(this.parent.date_compare_from_purchase_date).add(30, 'd')
          );
        }
      ),
    }),

    other_insurance_covering: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'false';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),
  });
};
