import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import styles from './index.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from '../../../intl/travels';

const LabelsRow = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={styles.container}>
      <p className={`${styles.label} ${styles[theme]}`}>{intl.DEPARTURE}</p>
      <p className={`${styles.label} ${styles[theme]}`}>{intl.ARRIVAL}</p>
    </div>
  );
};

export default LabelsRow;
