import styles from './Loading.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

export interface LoadingProps {
  scale?: number;
  surface?: 'OnSurface' | 'Inverse';
  text?: string;
  color?: string;
  checkBoxLoading?: boolean | string;
}

const getColorStyle = (surface: LoadingProps['surface'], color?: string) => {
  switch (color) {
    case 'blue':
      return styles.ldsSpinnerBlue;
    case 'blueAXA':
      return styles.ldsSpinnerBlueAXA;
    default:
      return surface === 'OnSurface'
        ? styles.ldsSpinnerSecondary
        : styles.ldsSpinnerSecondaryInverse;
  }
};

const Loading = ({
  scale = 1,
  surface = 'OnSurface',
  text = '',
  color,
  checkBoxLoading,
}: LoadingProps) => {
  const colorSpinner = getColorStyle(surface, color);
  const { getGlobalTheme } = useTheme();
  const themes = getGlobalTheme();

  return (
    <>
      <div
        className={`${checkBoxLoading && styles.checkBoxLoading} ${
          styles.ldsSpinner
        } ${colorSpinner} ${styles[themes]}`}
        style={{ transform: `scale(${scale})` }}
        role="loading"
      >
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
        <div className={styles[themes]}></div>
      </div>
      {text && text.length > 0 && (
        <div className={styles.loadingText}>
          <h2 className={styles[themes]}>{text}</h2>
        </div>
      )}
    </>
  );
};

export default Loading;
