import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from '../BaggageLoss.module.scss';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';

const PeriodBaggageLossArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={`${styles.incidentPeriodArea}  ${styles.fontGray12} row`}
        >
          <div className="col-12 col-md-6">
            <Calendar
              label={intl.TEXT_START_DATE_TRIP}
              name="travel_information.from_date"
              onChange={handleChange}
              value={values.travel_information.from_date}
              maxDate={new Date()}
              touched={
                touched.travel_information &&
                touched.travel_information.from_date
              }
              error={
                errors.travel_information && errors.travel_information.from_date
              }
              setFieldTouched={setFieldTouched}
            />
          </div>
          <div className="col-12 col-md-6">
            <Calendar
              label={intl.TEXT_END_DATE_TRIP}
              name="travel_information.to_date"
              value={values.travel_information.to_date}
              onChange={handleChange}
              minDate={values.travel_information.from_date}
              touched={
                touched.travel_information && touched.travel_information.to_date
              }
              error={
                errors.travel_information && errors.travel_information.to_date
              }
              setFieldTouched={setFieldTouched}
            />
          </div>

          <div className="col-12  col-md-6">
            <Calendar
              label={intl.TEXT_DATE_LOSS}
              name="incident.discovered_at"
              minDate={values.travel_information.from_date}
              maxDate={values.travel_information.to_date}
              value={values.incident.discovered_at}
              onChange={handleChange}
              touched={touched.incident && touched.incident.discovered_at}
              error={errors.incident && errors.incident.discovered_at}
              setFieldTouched={setFieldTouched}
            />
          </div>

          <div className={`col-12 col-md-6`}>
            <Input
              label={intl.TEXT_TIME_LOSS}
              type="text"
              mask="maskedHour"
              name="incident.hour_discovered_at"
              value={values.incident.hour_discovered_at}
              onChange={handleChange}
              touched={touched.incident && touched.incident.hour_discovered_at}
              error={errors.incident && errors.incident.hour_discovered_at}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeriodBaggageLossArea;
