export const reverseArr = input => {
  var ret = new Array();
  for (var i = input.length - 1; i >= 0; i--) {
    ret.push(input[i]);
  }
  return ret;
};

export const inArray = (array, target) => {
  return array.indexOf(target) > -1;
};

// Remove duplicated array entries based in the given key
export const getUniqueArrayByKey = (arr, key) =>
  arr.filter(
    (item, index) => index === arr.findIndex(obj => obj[key] === item[key])
  );

export const distinct = (value, index, array) => array.indexOf(value) === index;

export const removeDuplicates = arr =>
  arr.filter((item, index) => arr.indexOf(item) === index);
