import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from '../BaggageDelay.module.scss';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
const IncidentPeriodArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
  } = props;

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={`${styles.incidentPeriodArea}  ${styles.fontGray12} row`}
        >
          <div className="col-12 col-sm-12 col-md-6">
            <Calendar
              label={intl.TEXT_START_DATE_TRIP}
              name="travel_information.from_date"
              onChange={handleChange}
              value={values.travel_information.from_date}
              maxDate={new Date()}
              touched={
                touched.travel_information &&
                touched.travel_information.from_date
              }
              error={
                errors.travel_information && errors.travel_information.from_date
              }
              setFieldTouched={setFieldTouched}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <Calendar
              label={intl.TEXT_END_DATE_TRIP}
              name="travel_information.to_date"
              value={values.travel_information.to_date}
              onChange={handleChange}
              minDate={values.travel_information.from_date}
              touched={
                touched.travel_information && touched.travel_information.to_date
              }
              error={
                errors.travel_information && errors.travel_information.to_date
              }
              setFieldTouched={setFieldTouched}
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Calendar
              label={intl.TEXT_DATE_ARRIVAL_DESTINATION}
              name="incident.discovered_at"
              value={values.incident.discovered_at}
              onChange={handleChange}
              minDate={values.travel_information.from_date}
              maxDate={values.travel_information.to_date}
              touched={touched.incident && touched.incident.discovered_at}
              error={errors.incident && errors.incident.discovered_at}
              setFieldTouched={setFieldTouched}
            />
          </div>
          <div className={`col-12 col-md-6`}>
            <Input
              label={intl.TEXT_HOUR_ARRIVAL_DESTINATION}
              type="text"
              mask="maskedHour"
              name="incident.hour_discovered_at"
              value={values.incident.hour_discovered_at}
              onChange={handleChange}
              touched={touched.incident && touched.incident.hour_discovered_at}
              error={errors.incident && errors.incident.hour_discovered_at}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <div className={`${styles.elementRadio} ${styles[themes]} row`}>
              <div className={`col-lg-12 col-sm-12`}>
                <label className={`${styles.labelTitle} ${styles[themes]}`}>
                  {intl.WAS_BAGGAGE_RECEIVED}
                </label>
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.WAS_BAGGAGE_RECEIVED}
                  describe={intl.DESCRIBE_YES_BAGGAGE_RECEIVED}
                  defaultChecked={values.incident.wasBaggageReceived === 'true'}
                  text={intl.TEXT_OPTION_YES}
                  name="incident.wasBaggageReceived"
                  value={true}
                  onChange={e => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.WAS_BAGGAGE_RECEIVED}
                  describe={intl.DESCRIBE_NO_BAGGAGE_RECEIVED}
                  defaultChecked={
                    values.incident.wasBaggageReceived === 'false'
                  }
                  text={intl.TEXT_OPTION_NO}
                  name="incident.wasBaggageReceived"
                  value={false}
                  onChange={e => {
                    // setFieldValue('incident.baggage_arrived_at', null);
                    handleChange(e);
                  }}
                />
              </div>
            </div>
          </div>
          {values.incident.wasBaggageReceived === 'true' && (
            <>
              <div className="col-12 col-sm-12 col-md-6">
                <Calendar
                  label={intl.TEXT_DATE_RECEIPT_BAGGAGE}
                  name="incident.baggage_arrived_at"
                  value={values.incident.baggage_arrived_at}
                  onChange={handleChange}
                  minDate={values.travel_information.from_date}
                  touched={
                    touched.incident && touched.incident.baggage_arrived_at
                  }
                  error={errors.incident && errors.incident.baggage_arrived_at}
                  setFieldTouched={setFieldTouched}
                />
              </div>
              <div className={`col-12 col-md-6`}>
                <Input
                  label={intl.TEXT_HOUR_RECEIPT_BAGGAGE}
                  type="text"
                  mask="maskedHour"
                  name="incident.hour_baggage_arrived_at"
                  value={values.incident.hour_baggage_arrived_at}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={
                    touched.incident && touched.incident.hour_baggage_arrived_at
                  }
                  error={
                    errors.incident && errors.incident.hour_baggage_arrived_at
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default IncidentPeriodArea;
