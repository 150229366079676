import ChevronGeneric from '../../../assets/icons/ChevronGeneric';
import { TrailingIconProps } from './TrailingIcon.types';
import ArrowIcon from '../../../assets/iconsV2/ArrowIcon';

const TrailingIcon = ({ type }: TrailingIconProps) => {
  if (!type) return;

  if (type === 'Chevron Down')
    return <ChevronGeneric rotate="0" color="inherit" />;

  if (type === 'Chevron Left')
    return <ChevronGeneric rotate="90" color="inherit" />;

  if (type === 'Chevron Up')
    return <ChevronGeneric rotate="180" color="inherit" />;

  if (type === 'Chevron Right')
    return <ChevronGeneric rotate="270" color="inherit" />;

  if (type === 'Arrow Down') return <ArrowIcon type="Down" color="inherit" />;

  if (type === 'Arrow Left') return <ArrowIcon type="Left" color="inherit" />;

  if (type === 'Arrow Up') return <ArrowIcon type="Up" color="inherit" />;

  if (type === 'Arrow Right') return <ArrowIcon type="Right" color="inherit" />;

  if (type === 'Arrow Out') return <ArrowIcon type="Out" color="inherit" />;

  return null;
};

export default TrailingIcon;
export * from './TrailingIcon.types';
