import { useState } from 'react';
import ExpansiveMenu from '../../ExpansiveMenu/ExpansiveMenu';
import styles from './index.module.scss';
import sanitize from '../../../utils/sanitize';
import { BiLike, BiDislike, BiSolidLike, BiSolidDislike } from 'react-icons/bi';
import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';
import FeedbackButton from './FeedbackButton';
import { faqService } from '../../../services';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import '../../../pages/BenefitsDetail/BenefitContent/PrismicContent.scss';
import { ImprovementOptions } from '../../../@types/APIs/Faq';
import FaqImprovementItems from '../FaqImprovementItems';
import { highlightStringBySearch } from '../../../utils/search';
import { ENABLE_DISLIKE_FAQ_API } from '../../../utils/environments_variables';

export interface FaqListItem {
  id?: string;
  question: string;
  answer: string;
  category: string;
}

interface FaqItemProps {
  item: FaqListItem;
  showFeedback?: boolean;
  filterSelectedId?: string;
  faqImprovementOptions: ImprovementOptions[];
  searchBy?: string;
}

const FaqItem = ({
  item,
  showFeedback,
  filterSelectedId,
  faqImprovementOptions,
  searchBy,
}: FaqItemProps) => {
  const { id, question, answer } = item;

  const [wasHelpful, setWasHelpful] = useState<boolean>(null);

  const { translate } = useIntl();
  const intl = translate(translations);

  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  const checkUrlID = (item: FaqListItem) => {
    const hash = window.location.hash;
    return hash.includes(item.id);
  };

  const evaluate = async (wasHelpful: boolean) => {
    setWasHelpful(wasHelpful);
    try {
      const response = await faqService.postEvaluation(id, wasHelpful);
      if (response.status !== 204) setWasHelpful(null);
    } catch {
      setWasHelpful(null);
    }
  };

  const highlightedQuestion = searchBy
    ? highlightStringBySearch(question, searchBy, 'mark')
    : question;
  const highlightedAnswer = searchBy
    ? highlightStringBySearch(answer, searchBy, 'mark')
    : answer;

  return question && question !== '' && answer && answer !== '' ? (
    <ExpansiveMenu
      title={highlightedQuestion}
      id={id || question}
      defaultShow={() => checkUrlID(item)}
      filterSelectedId={filterSelectedId}
    >
      <span className={`${styles.faqText} ${styles[theme]} `}>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="theme ${theme}">${sanitize(
              highlightedAnswer
            )}</div>`,
          }}
        />
        {showFeedback && (
          <>
            <div className={styles.feedbackContainer}>
              <h3 className={styles.feedbackLabel}>
                {intl.LABEL_WAS_THIS_HELPFUL}
              </h3>
              {wasHelpful === null ? (
                <>
                  <FeedbackButton
                    Icon={BiDislike}
                    onClick={() => {
                      if (ENABLE_DISLIKE_FAQ_API) {
                        setWasHelpful(false);
                        return;
                      }
                      evaluate(false);
                    }}
                  />
                  <FeedbackButton
                    Icon={BiLike}
                    onClick={() => evaluate(true)}
                  />
                </>
              ) : (
                <>
                  <FeedbackButton
                    Icon={wasHelpful ? BiDislike : BiSolidDislike}
                    disabled
                  />
                  <FeedbackButton
                    Icon={wasHelpful ? BiSolidLike : BiLike}
                    disabled
                  />
                </>
              )}
            </div>
            {wasHelpful == false && ENABLE_DISLIKE_FAQ_API && (
              <div className={styles.wrapperFaqImprovement}>
                <FaqImprovementItems
                  setWasHelpful={setWasHelpful}
                  id={id}
                  items={faqImprovementOptions}
                />
              </div>
            )}
          </>
        )}
      </span>
    </ExpansiveMenu>
  ) : null;
};

export default FaqItem;
