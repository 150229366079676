/* GENDER */
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const MALE_VALUE_ABV = 'ml';
export const FEMALE_VALUE_ABV = 'fm';
export const UNKNOWN_VALUE = 'UNKNOWN';
export const UNKNOWN_VALUE_ABV = 'unknown';

/* TITLE */
export const TITLE_MR = 'MR';
export const TITLE_MS = 'MS';
export const TITLE_MISS = 'MISS';
export const TITLE_DR = 'DR';
export const TITLE_PR = 'PR';
