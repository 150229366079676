import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const ChevronUpIcon = props => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg width={16} height={16} fill="none" {...handledProps}>
      <path
        d="m1 11 5.586-5.586a2 2 0 0 1 2.828 0L15 11"
        stroke={handledProps.color ?? colors.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronUpIcon;
