export const LABEL_ACCIDENT_REASON = {
  en_US: 'Accident reason',
  es_ES: 'Motivo del accidente',
  pt_BR: 'Motivo do incidente',
};

export const ACCIDENTAL_DISMEMBERMENT = {
  en_US: 'Accidental dismemberment',
  pt_BR: 'Desmembramento acidental',
  es_ES: 'Desmembramiento accidental',
};

export const ACCIDENTAL_DEATH = {
  en_US: 'Accidental death',
  pt_BR: 'Morte acidental',
  es_ES: 'Muerte accidental',
};

export const LABEL_INCIDENT_LOCATION = {
  en_US: 'Incident location',
  es_ES: 'Dirección del incidente',
  pt_BR: 'Endereço do incidente',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const LABEL_DEATH_DATE = {
  en_US: 'Death date',
  es_ES: 'Fecha de la muerte',
  pt_BR: 'Data da morte',
};

export const LABEL_OTHER_PEOPLE_INVOLVED = {
  en_US: 'Are there other people involved in the incident?',
  es_ES: '¿Hay otras personas involucradas en el incidente?',
  pt_BR: 'Existem outras pessoas envolvidas no incidente?',
};

export const TEXT_YES = {
  en_US: count => `Yes (${count})`,
  es_ES: count => `Sí (${count})`,
  pt_BR: count => `Sim (${count})`,
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
