import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import * as translationsAnddress from '../intlPersonal';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './IncidentArea.module.scss';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import { safeGet } from '../../../../../../../../utils/object';
import sanitize from '../../../../../../../../utils/sanitize';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const intlPersonal = translate(translationsAnddress);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.occured_at}
          label={
            values.is_accidental_death === 'false'
              ? intl.LABEL_INCIDENT_DATE
              : intl.LABEL_DEATH_DATE
          }
          name="incident.occured_at"
          onChange={handleChange}
          maxDate={new Date()}
          touched={touched.incident && touched.incident.occured_at}
          error={errors.incident && errors.incident.occured_at}
          setFieldTouched={setFieldTouched}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-12">
        <Textarea
          value={values.incident.description}
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          name="incident.description"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.description')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.description')}
          maxLength="255"
        />
      </div>

      {values.is_accidental_death === 'true' && (
        <div className="col-12">
          <div className={`${styles.fontGray12} ${styles.options} row`}>
            <div className="col-12">
              <GroupDivisor title={intl.INCIDENT_LOCATION} />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <Select
                label={intlPersonal.COUNTRY_LABEL}
                name="incident.location.country"
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    `<option value="">${intlPersonal.COUNTRY_LABEL}</option>${intlPersonal.COUNTRY_OPTIONS}`
                  ),
                }}
                formikProps={props}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <Input
                label={intlPersonal.LABEL_STATE}
                name="incident.location.state"
                type="text"
                formikProps={props}
                maxLength="100"
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6">
              <Input
                label={intlPersonal.LABEL_CITY}
                name="incident.location.locality"
                type="text"
                formikProps={props}
                maxLength="100"
              />
            </div>
            <div className="col-12">
              <Input
                label={intlPersonal.LABEL_ADDRESS}
                name="incident.location.street_address"
                type="text"
                formikProps={props}
                maxLength="255"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IncidentArea;
