import moment from 'moment';
let genericCurrency = '';
export const formatPrice_ProtectionToApi = props => {
  let object = {};

  genericCurrency = props.values.incident.amount_estimation_damage.currency;

  object.affected_person = affected_personToApi(props);
  object.amount_estimation_damage = amount_estimation_damage(props);

  if (props.values.incident.other_insurance_covering.is_internal === 'false') {
    object.other_insurance_covering = other_insurance_coveringToApi(props);
  } else {
    object.other_insurance_covering = [];
  }

  object.purchased_item = purchased_itemToApi(props);

  object.advertised_price = advertised_priceToApi(props);

  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const purchased_itemToApi = props => {
  const purchased_item = props.values.incident.purchased_item;

  const purchase_date = moment(purchased_item.purchase_date).format(
    'YYYY-MM-DD'
  );

  return {
    purchase_date: purchase_date,
    label: purchased_item.label,
    model_number: purchased_item.model_number,
    manufacturer: purchased_item.manufacturer,
    price: {
      value: Number(purchased_item.price.value),
      currency: genericCurrency,
    },
    number_of_installments: purchased_item.installments_number,
    retailer: purchased_item.retailer,
    total_invoice_amount: {
      value: Number(purchased_item.total_invoice_amount.value),
      currency: genericCurrency,
    },
  };
};

const advertised_priceToApi = props => {
  const advertised_price = props.values.incident.advertised_price;

  const discovery_date = moment(advertised_price.discovery_date).format(
    'YYYY-MM-DD'
  );

  return {
    discovery_date: discovery_date,
    publishing_location: advertised_price.publishing_location,
    retail_name: advertised_price.retail_name,
    price: {
      value: Number(advertised_price.price.value),
      currency: genericCurrency,
    },
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const amount_estimation_damage = props => {
  const amount_estimation_damage =
    props.values.incident.amount_estimation_damage;

  return {
    value: Number(amount_estimation_damage.value),
    currency: amount_estimation_damage.currency,
  };
};

const other_insurance_coveringToApi = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const amount_estimation_damage =
    props.values.incident.amount_estimation_damage;

  return [
    {
      is_internal: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      description: '',
      amount: {
        value: Number(other_insurance_covering.value),
        currency: amount_estimation_damage.currency,
      },
    },
  ];
};

const total_amount_claimedToApi = props => {
  const total_amount_claimed =
    Number(props.values.incident.amount_estimation_damage.value) -
    Number(props.values.incident.other_insurance_covering.value);

  return {
    value: Number(total_amount_claimed),
    currency: props.values.incident.amount_estimation_damage.currency,
  };
};
