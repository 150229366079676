import { formatCustomDate } from '../../utils/date';
export const TEXT_CLAIM = {
  en_US: 'You have been reimbursed',
  es_ES: 'Te han reembolsado',
  pt_BR: 'Você obteve de reembolso',
};

export const TEXT_CLAIM_SUBTITLE = {
  en_US: 'in claims',
  es_ES: 'en reclamos',
  pt_BR: 'em sinistros',
};

export const TEXT_CERTIFICATE = {
  en_US: 'You have saved',
  es_ES: 'Has ahorrado',
  pt_BR: 'Você economizou',
};

export const TEXT_CERTIFICATE_SUBTITLE = {
  en_US: 'in certificates',
  es_ES: 'en certificados',
  pt_BR: 'em bilhetes',
};

export const TEXT_TOOLTIP_REIMBURSED = {
  en_US: 'This is a purely illustrative value, your card has not been charged',
  es_ES:
    'Este es una valor meramente ilustrativo, ningún cobro fue realizado en tu tarjeta',
  pt_BR:
    'Este é um valor meramente ilustrativo, não foi feita cobrança no seu cartão',
};

export const TEXT_TOOLTIP_REIMBURSED_WHEN_DATE_CLAIM = {
  en_US: (date, idiom, country) =>
    `The displayed amount on the dashboard represents the savings you have accumulated by reimbursements approved and paid by using your Visa benefits as of ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Please be aware that this sum has not been charged to your card.`,
  es_ES: (date, idiom, country) =>
    `La cantidad mostrada en el panel representa los ahorros que has acumulado mediante reembolsos aprobados y pagados al utilizar los beneficios de tu tarjeta Visa hasta a parti del ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Ten en cuenta que esta suma no se ha cargado a tu tarjeta.`,
  pt_BR: (date, idiom, country) =>
    `O valor exibido no painel representa as economias que você acumulou por meio de reembolsos aprovados e pagos ao usar os benefícios do seu cartão Visa a partir de ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Esteja ciente de que essa quantia não foi cobrada em seu cartão.`,
};

export const TEXT_TOOLTIP_REIMBURSED_WHEN_DATE_CERTIFICATE = {
  en_US: (date, idiom, country) =>
    `The displayed amount on the dashboard represents the savings you have accumulated by issuing certificates of benefits of your Visa Card as of ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Please be aware that this sum has not been charged to your card.`,
  es_ES: (date, idiom, country) =>
    `La cantidad mostrada en el panel representa los ahorros que has acumulado al emitir certificados de beneficios de tu Tarjeta Visa a partir del ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Ten en cuenta que esta suma no se ha cargado a tu tarjeta.`,
  pt_BR: (date, idiom, country) =>
    `O valor exibido no painel representa as economias que você acumulou ao emitir certificados de benefícios do seu Cartão Visa a partir de ${formatCustomDate(
      date,
      'MM/DD/YYYY',
      idiom,
      country
    )}. Esteja ciente de que essa quantia não foi cobrada em seu cartão.`,
};

export const BTN_VIEW_DETAILS = {
  en_US: 'View details',
  es_ES: 'Ver detalles',
  pt_BR: 'Ver detalhes',
};

export const LINK_DISCLAIMER = {
  en_US:
    'https://prod-axa.cdn.prismic.io/prod-axa/Zx_oo68jQArTz6fP_VisaBenefitsSavingsGuide.pdf',
  es_ES:
    'https://prod-axa.cdn.prismic.io/prod-axa/Zx_opK8jQArTz6fQ_Gu%C3%ADadeAhorrosconBeneficiosVisa.pdf',
  pt_BR:
    'https://prod-axa.cdn.prismic.io/prod-axa/Zx_opa8jQArTz6fR_GuiadeEconomiacomBenef%C3%ADciosVisa.pdf',
};

export const LINK_DISCLAIMER_TEXT = {
  en_US: 'Learn more',
  es_ES: 'Conoce más',
  pt_BR: 'Saiba mais',
};

export const BTN_VIEW_ALL = {
  en_US: 'View all savings',
  es_ES: 'Ver todos los ahorros',
  pt_BR: 'Ver todas as poupanças',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Bilhete seguro para veículos de locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};
export const TITLE_EXTENDED_WARRANTY_CERTIFICATE = {
  en_US: 'Extended warranty',
  pt_BR: 'Bilhete seguro de garantia estendida original',
  es_ES: 'Garantía extendida',
};
export const TITLE_TRAVEL_INSURANCE_CERTIFICATE = {
  en_US: 'Travel Assistance Certificate',
  pt_BR: 'Bilhete seguro viagem',
  es_ES: 'Certificado de Asistencia en Viaje',
};
export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Bilhete de proteção de compra',
  es_ES: 'Certificado de compra',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};
