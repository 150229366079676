import { useState } from 'react';
import styles from './RecoverAccount.module.scss';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import IdentityConfirmation from './IdentityConfirmation/IdentityConfirmation';
import Feedback from './Feedback/Feedback';
import { useIntl } from '../../intl';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import sanitize from '../../utils/sanitize';

const getTitle = (step, intl) => {
  switch (step) {
    case 1:
      return [intl.TITLE_1, intl.DESCRIPTION_1];
    case 2:
      return [intl.TITLE_2, intl.DESCRIPTION_2];
    case 3:
      return [intl.TITLE_3, intl.DESCRIPTION_3];
    default:
      return [intl.TITLE_1, intl.DESCRIPTION_1];
  }
};

const RecoverAccount = () => {
  const [step, setStep] = useState(1);
  const { getGlobalTheme } = useTheme();
  const { translate } = useIntl();

  const themes = getGlobalTheme();
  const intl = translate(translations);
  const [title, description] = getTitle(step, intl);
  const [formValues, setFormValues] = useState(null);

  return (
    <div className={styles.container}>
      <header className={styles.containerHeader}>
        <h1 className={styles[themes]}>{title}</h1>
        <p
          className={styles[themes]}
          dangerouslySetInnerHTML={{ __html: sanitize(description) }}
        />
      </header>

      {step === 1 && (
        <PersonalInformation setStep={setStep} setFormValues={setFormValues} />
      )}
      {step === 2 && (
        <IdentityConfirmation setStep={setStep} formValues={formValues} />
      )}
      {step === 3 && <Feedback />}
    </div>
  );
};

export default RecoverAccount;
