import React from 'react';

import styles from './Heading.module.scss';

const Heading = props => {
  const { white, blue, absoluteCenter } = props;
  let className = '';
  if (white) className += ' ' + styles.white;
  if (blue) className += ' ' + styles.blue;
  if (absoluteCenter) className += ' ' + styles.absoluteCenter;
  return <h2 className={className}>{props.children}</h2>;
};

export default Heading;
