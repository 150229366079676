import { PHONE_TYPE_MOBILE } from '../../../../../constants/phone';
import { pt_BR } from '../../../../../intl/idioms';
import moment from 'moment';

export const getInitialValues = (user, idiom, TravellerMPH) => {
  const MPH = TravellerMPH ? TravellerMPH : undefined;
  let phone = MPH ? MPH.phone : user && user.phone ? user.phone : '';
  let address = MPH ? MPH.address : user && user.address ? user.address : '';
  let person_registrations =
    user && user.registrations ? user.registrations : '';

  const registration_type = idiom === pt_BR ? 'CPF' : 'PASSPORT';

  return {
    MPH: {
      isCardHolder: MPH ? MPH.isCardHolder : true,
      Name: MPH
        ? MPH.Name
        : user && user.person && user.person.first_name
        ? user.person.first_name
        : '',
      LastName: MPH
        ? MPH.LastName
        : user && user.person && user.person.last_name
        ? user.person.last_name
        : '',
      DateOfBirth: MPH
        ? MPH.DateOfBirth !== ''
          ? new Date(moment(MPH.DateOfBirth).format('MM/DD/YYYY'))
          : ''
        : user && user.person && user.person.birth_date
        ? new Date(moment(user.person.birth_date).format('MM/DD/YYYY'))
        : '',
      Age: '',
      Email: MPH ? MPH.Email : user && user.email ? user.email : '',
      EmailConfirmation: MPH
        ? MPH.EmailConfirmation
        : user && user.email
        ? user.email
        : '',
      person_registrations: {
        registration_type: MPH
          ? MPH.person_registrations.registration_type
          : registration_type,
        value: MPH
          ? MPH.person_registrations.value
          : person_registrations &&
            person_registrations[0] &&
            person_registrations[0].value &&
            person_registrations[0].registration_type === registration_type
          ? person_registrations[0].value
          : '',
      },
      phone: {
        phone_type:
          phone && phone.phone_type ? phone.phone_type : PHONE_TYPE_MOBILE,
        number: phone && phone.number ? phone.number : '',
        international_prefix:
          phone && phone.international_prefix ? phone.international_prefix : '',
      },
      address: {
        locality: address && address.locality ? address.locality : '',
        postal_code: address && address.postal_code ? address.postal_code : '',
        state: address && address.state ? address.state : '',
        street_address:
          address && address.street_address ? address.street_address : '',
        subdivision: address && address.subdivision ? address.subdivision : '',
        country: address && address.country ? address.country : '',
      },
    },
  };
};
