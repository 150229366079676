import React, { useContext, useEffect, useState } from 'react';

import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import * as TravelAccidentUtils from '../../../../utils/claimsAPI/TravelAccidentUtils';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const InsuredJourneyAccidentalDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const [incident, setIncident] = useState({});
  const [involvedPeople, setInvolvedPeople] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const involvedPeople = (claimDetails && claimDetails.people_involved) || {};
    setInvolvedPeople(involvedPeople);
  }, [claimDetails]);

  const isAccidentalDeath = claimDetails && claimDetails.is_accidental_death;

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12">
        <InfoLabelValue
          label={intl.LABEL_ACCIDENT_REASON}
          value={TravelAccidentUtils.getAccidentReason(isAccidentalDeath, intl)}
        />
      </div>
      {isAccidentalDeath && (
        <div className="col-sm-12">
          <InfoLabelValue
            label={intl.LABEL_INCIDENT_LOCATION}
            value={TravelAccidentUtils.getIncidentLocation(
              incident,
              idiom,
              true
            )}
          />
        </div>
      )}
      <div className="col-sm-12">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          value={TravelAccidentUtils.getIncidentDescription(incident)}
        />
      </div>
      <div className="col-sm-12">
        {isAccidentalDeath ? (
          <InfoLabelValue
            label={intl.LABEL_DEATH_DATE}
            value={TravelAccidentUtils.getIncidentDate(
              incident,
              idiom,
              country
            )}
          />
        ) : (
          <InfoLabelValue
            label={intl.LABEL_OTHER_PEOPLE_INVOLVED}
            value={TravelAccidentUtils.getHasInvolvedPeople(
              involvedPeople,
              intl
            )}
          />
        )}
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default InsuredJourneyAccidentalDetail;
