/* eslint-disable react-hooks/exhaustive-deps */
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Formik } from 'formik';
import TripForm from './TripForm';
import { stringToBoolean } from '../../../../utils/stringUtils';
import { useMemo } from 'react';
import { parseISO } from 'date-fns';
import { getInitialValues } from './initialValues';
import { getValidationSchema } from './validationSchema';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { checkErrorsOnForm, formatTravel, setTouchedOutbound } from './utils';
import styles from './index.module.scss';
import { travelService } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import { useHistory } from 'react-router-dom';
import { focusOnErrorElement } from '../../../../utils/elements';
import { FullTravelInsuranceCertificateDetail } from '../../../../@types/APIs/Certificate';

interface FormProps {
  certificate: FullTravelInsuranceCertificateDetail;
  setStep: Dispatch<SetStateAction<number>>;
}

const Form = ({ certificate, setStep }: FormProps) => {
  const history = useHistory();

  const { utils, actions } = useContext(StateContext);
  const cn = utils.getCn();

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const [status, setStatus] = useState({
    outbound: true,
    return: false,
  });

  const travelInformation = certificate.travel_information;
  const isOneWayTravel = stringToBoolean(
    travelInformation && travelInformation.is_one_way_travel
  );

  const initialValues = useMemo(() => {
    const outwardDate = travelInformation.from
      ? parseISO(travelInformation.from)
      : new Date();
    const returnDate = travelInformation.to
      ? parseISO(travelInformation.to)
      : new Date();
    return getInitialValues(outwardDate, returnDate);
  }, []);

  const validationSchema = useMemo(
    () => getValidationSchema(intl, isOneWayTravel, travelInformation),
    []
  );

  const postTravel = async flightDetails => {
    try {
      actions.modal.showLoading(intl.SAVING_TRIP);

      const travel = formatTravel(certificate, flightDetails);
      await travelService.createTravel(cn, travel);

      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.TRIP_ASSISTANCE_ACTIVATED}
          subtitle={intl.RECEIVE_REAL_TIME_ALERT}
          textBtn={intl.GO_TO_HOMEPAGE}
          clickBtn={() => {
            history.push('/dashboard');
            actions.modal.closeModal();
          }}
          textBtnDeny={intl.MY_TRIP_ASSISTANCES}
          clickBtnDeny={() => {
            history.push('/travel-kit');
            actions.modal.closeModal();
          }}
        />,
        true
      );
    } catch (error) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />
      );
    }
  };

  const onSubmit = async formikProps => {
    formikProps.submitForm();

    const areFormsOk = await checkErrorsOnForm(
      isOneWayTravel,
      validationSchema,
      formikProps.values,
      setStatus
    );

    if (areFormsOk) {
      postTravel(formikProps.values);
    }
  };

  const validateFirstStep = async formikProps => {
    try {
      setTouchedOutbound(formikProps);
      await validationSchema.fields.outbound.validate(
        formikProps.values.outbound
      );
      setStatus({
        outbound: false,
        return: true,
      });
    } catch (error) {
      focusOnErrorElement(0);
      return;
    }
  };

  return (
    <div id="mainContent" className={styles.container}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={() => null}
      >
        {formikProps => (
          <>
            <TripForm
              formikProps={formikProps}
              statusState={[status, setStatus]}
              certificate={certificate}
              onSubmit={onSubmit}
              validateFirstStep={validateFirstStep}
              setStep={setStep}
            />
            {!isOneWayTravel && (
              <TripForm
                formikProps={formikProps}
                statusState={[status, setStatus]}
                certificate={certificate}
                onSubmit={onSubmit}
                isReturn
              />
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default Form;
