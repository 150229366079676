import { useContext } from 'react';
import DownloadIcon from '../../../../../../../assets/icons/DownloadIcon';
import ModalResult from '../../../../../../../components/ModalResult/ModalResult';
import ModalWithImage from '../../../../../../../components/ModalWithImage/ModalWithImage';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import styles from './FileList.module.scss';
import * as translation from './intl';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import CloseIcon from '../../../../../../../assets/icons/CloseIcon';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import Button from '../../../../../../../componentsV2/Button/Button';
import { joinClassNames } from '../../../../../../../utils/classNames';

const handleDownload = async (file, actions, intl, trackEventUserAction) => {
  try {
    var link = document.createElement('a');
    link.download = file.name;
    trackEventUserAction(
      '#### VMO ### FileList - Iniciando download de arquivo'
    );
    link.href = 'data:application/octet-stream;base64,' + file.file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    trackEventUserAction('#### VMO ### FileList - download realizado');
  } catch (error) {
    trackEventUserAction(
      '#### VMO ### FileList - Erro ao baixar arquivo',
      error
    );
    actions.alert.showError(intl.UNEXPECTED_ERROR_OCCURRED);
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage type="error" title={intl.UNEXPECTED_ERROR_OCCURRED}>
        <ModalResult
          textConfirm={intl.TEXT_OK}
          clickConfirm={() => {
            actions.modal.closeModal();
          }}
        />
      </ModalWithImage>,
      true
    );
  }
};

const FileList = ({ files, label, removeButton, handleRemove }) => {
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const theme = getGlobalTheme();
  const intl = translate(translation);

  return (
    <div>
      <>
        <div className={joinClassNames(styles.title, styles[theme])}>
          {label}
        </div>
        {files.map(fileObject => (
          <div className={styles.containerDownloadItem} key={fileObject.id}>
            <Button
              type="Icon"
              onClick={() =>
                handleDownload(fileObject, actions, intl, trackEventUserAction)
              }
              leadingIcon={<DownloadIcon />}
              testId="downloadButton"
              ariaLabel={intl.LABEL_DOWNLOAD}
            />
            <p className={styles.filename}>{fileObject.name}</p>
            {removeButton && (
              <Button
                type="Icon"
                onClick={() => handleRemove(fileObject.id)}
                leadingIcon={<CloseIcon width={20} height={20} />}
                testId="removeButton"
                ariaLabel={intl.LABEL_DELETE}
              />
            )}
          </div>
        ))}
      </>
    </div>
  );
};

export default FileList;
