import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const UploadDocumentsIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      width={21}
      height={27}
      viewBox="0 0 21 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 0V21.3158H2.8V2.84211H18.2V17.0526H11.2V24.1579H0V27H12.32C17.108 27 21 23.2058 21 18.5447V0H0ZM14 23.9305V19.8947H18.032C17.528 21.8274 15.988 23.3621 14 23.9305Z"
        fill={config_icon.primary}
      />
      <path
        d="M15.4001 5.6842H5.6001V8.52631H15.4001V5.6842Z"
        fill={config_icon.secondary}
      />
    </svg>
  );
};

export default UploadDocumentsIcon;
