import React from 'react';
import styles from '../RecentCreated.module.scss';
import StatusClaim from '../../../../../components/StatusClaim/StatusClaim';
import { formatCustomDate } from '../../../../../utils/date';

const LineClaim = props => {
  const { value, intl, idiom, index, theme, country } = props;

  return (
    <div
      className={`col-12 ${styles.line} ${styles[theme]} ${
        index === 1 ? styles.lastItem : ''
      }`}
    >
      <div className="row no-gutters">
        <div className="col-xl-6 col-lg-4 col-md-4 col-sm-12">
          <span>
            <strong>{value && value.claim_type_name}</strong>
          </span>
        </div>
        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
          <span>
            {intl.TEXT_CREATION_DATE}{' '}
            {formatCustomDate(value.created_at, 'YYYY-MM-DD', idiom, country)}
          </span>
        </div>
        <div
          className={`col-xl-3 col-lg-5 col-md-5 col-sm-12 ${styles.status}`}
        >
          <StatusClaim claim={value} />
          <div
            className={`${styles.lineBottom} ${
              index === 1 ? styles.lineNone : ''
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LineClaim;
