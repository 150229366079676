import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const AppMobileIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={37}
      height={63}
      viewBox="0 0 37 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.0714 63H7.92857C3.56786 63 0 59.4562 0 55.125V7.875C0 3.54375 3.56786 0 7.92857 0H29.0714C33.4321 0 37 3.54375 37 7.875V55.125C37 59.4562 33.4321 63 29.0714 63ZM5.28571 52.5V55.125C5.28571 56.5687 6.475 57.75 7.92857 57.75H29.0714C30.525 57.75 31.7143 56.5687 31.7143 55.125V52.5H5.28571ZM5.28571 47.25H31.7143V7.875C31.7143 6.43125 30.525 5.25 29.0714 5.25H7.92857C6.475 5.25 5.28571 6.43125 5.28571 7.875V47.25Z"
        fill="white"
      />
      <path
        d="M26.4284 10.5H10.5713V15.75H26.4284V10.5Z"
        fill={config_icon.white}
      />
    </svg>
  );
};

export default AppMobileIcon;
