export const getIntlByStatus = (statusName, intl) => {
  switch (statusName) {
    case 'Pending Send':
      return intl.PENDING_SEND;
    case 'Claims - Pending Check forms received':
      return intl.PENDING_CHECK_FORMS;
    case 'Claims - Waiting Customer Documentation':
      return intl.WAITING_CUSTOMER_DOCUMENTATION;
    case 'Claims - Waiting Customer Documentation 30 Days':
      return intl.WAITING_CUSTOMER_DOCUMENTATION_30_DAYS;
    case 'Claims - Pending Review forms and docs':
      return intl.PENDING_REVIEW_FORMS;
    case 'Transferred to Underwriter':
      // return intl.TRANSFERRED_TO_UNDERWRITER;
      return null;
    case 'Claims – Pending Underwriter':
      return intl.PENDING_UNDERWRITER;
    case 'Claims - Pending Underwriter Review':
      return intl.PENDING_UNDERWRITER_REVIEW;
    case 'Claims - Documentation reviewed by Underwriter. Pending for approval':
      return intl.REVIEW_BY_UNDERWRITER_PENDING_APPROVAL;
    case 'Claims - Rejected Claim':
      return intl.REJECTED_CLAIM;
    case 'Reopened':
      return intl.REOPENED;
    case 'Claims - Case appealed':
      return intl.CASE_APPELEAD;
    case 'Claims - Case Closed':
      return intl.CASE_CLOSED;
    case 'Claims - Cancelled':
      return intl.CASE_CANCELED;
    default:
      return null;
  }
};
