import { parse } from 'date-fns';
import * as Yup from 'yup';
import { CheckIsVisaNumber } from '../../../utils/card';
import { isSameOrAfterMonth } from '../../../utils/date';
import { removeNonNumeric } from '../../../utils/stringUtils';

export const validateExpirationDate = date => {
  const dateToday = new Date();
  const splitExpirationDate = date.split('/');
  const monthExpirationDate = splitExpirationDate[0];
  const yearExpirationDate = splitExpirationDate[1];

  const createExpirationDate = parse(
    `${monthExpirationDate}-01-20${yearExpirationDate}`,
    'MM-dd-yyyy',
    new Date()
  );

  if (parseInt(monthExpirationDate) === 0 || parseInt(monthExpirationDate) > 12)
    return false;

  return isSameOrAfterMonth(createExpirationDate, dateToday);
};

const getValidationSchema = (intl, hasExpirationDate) =>
  Yup.object().shape({
    effective_end_date:
      hasExpirationDate &&
      Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .min(5, intl.TEXT_FIELD_REQUIRED)
        .test('valid-expiration-date', intl.TEXT_INVALID_DATE, value => {
          if (value && value.length === 5) {
            return validateExpirationDate(value);
          }
          return false;
        }),
    pan: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .test(
        'has-16-digits',
        intl.TEXT_FORMAT_INVALID,
        pan => removeNonNumeric(pan).length === 16
      )
      .test(
        'same-10-digits',
        intl.TEXT_NOT_CORRESPOND_REGISTERED_CARD,
        function (pan) {
          return this.parent.bin === removeNonNumeric(pan).substring(0, 10);
        }
      )
      .test('is-visa-card', intl.TEXT_NOT_VISA_PRODUCT, pan =>
        CheckIsVisaNumber(removeNonNumeric(pan))
      ),
  });

export default getValidationSchema;
