import moment from 'moment';
import { cloneDeep } from 'lodash';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';

export const formatIdTheftProtection = props => {
  let object = {};
  const values = cloneDeep(props.values.incident);
  const { expenses, total_amount_claimed } = expensesToApi(values);

  object.affected_person = affectedPersonToApi(values.affected_person);
  object.incident = {
    date: moment(values.incident.dateValue).format('YYYY-MM-DD'),
    description: values.incident.description,
    expenses: expenses,
    kind: 'THEFT_OF_IDENTITY',
  };
  object.total_amount_claimed = total_amount_claimed;

  return object;
};

const expensesToApi = props => {
  const expenses = [];
  let total_amount_claimed = {
    value: 0,
    currency: 'EUR',
  };

  if (stringToBoolean(props.amount_paid.checked)) {
    expenses.push({
      type: 'ATM_WITHDRAWAL_UNAUTHORIZED',
      amount: {
        value: parseFloat(props.amount_paid.amount.value),
        currency: props.amount_paid.amount.currency,
      },
      description: '',
    });
    total_amount_claimed.value += parseFloat(props.amount_paid.amount.value);
    total_amount_claimed.currency = props.amount_paid.amount.currency;
  }

  if (stringToBoolean(props.time_off.checked)) {
    expenses.push({
      type: 'LEAVE_DAY',
      amount: {
        value: parseFloat(props.time_off.amount.value),
        currency: props.time_off.amount.currency,
      },
      description: '',
    });
    total_amount_claimed.value += parseFloat(props.time_off.amount.value);
    total_amount_claimed.currency = props.time_off.amount.currency;
  }

  if (stringToBoolean(props.falsely_opened.checked)) {
    expenses.push({
      type: 'POLICE',
      amount: {
        value: parseFloat(props.falsely_opened.amount.value),
        currency: props.falsely_opened.amount.currency,
      },
      description: '',
    });
    total_amount_claimed.value += parseFloat(props.falsely_opened.amount.value);
    total_amount_claimed.currency = props.falsely_opened.amount.currency;
  }

  if (stringToBoolean(props.other_expenses.checked)) {
    expenses.push({
      type: 'ANY_OTHER_REASON',
      amount: {
        value: parseFloat(props.other_expenses.amount.value),
        currency: props.other_expenses.amount.currency,
      },
      description: '',
    });
    total_amount_claimed.value += parseFloat(props.other_expenses.amount.value);
    total_amount_claimed.currency = props.other_expenses.amount.currency;
  }

  return { expenses, total_amount_claimed };
};

const affectedPersonToApi = props => {
  return {
    relationship: props.relationship,
    comment: props.comment,
    person: {
      first_name: props.person.first_name,
      last_name: props.person.last_name,
    },
  };
};
