import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import { legalProtectionFormInitialValues } from './initialValues';
import { legalProtectionSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import PhoneArea from './PhoneArea/PhoneArea';
import TotalAmountArea from './TotalAmountArea/TotalAmountArea';
import EventDetails from './EventDetails/EventDetails';
import LegalExpensesList from './LegalExpensesList/LegalExpensesList';
import OtherInsuranceArea from './OtherInsuranceArea/OtherInsuranceArea';

const LegalProtection = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef } = props;

  const validationSchema = useMemo(() => legalProtectionSchema(intl), [intl]);
  const initialValues = useMemo(
    () => legalProtectionFormInitialValues(parentValues.policy, idiom),
    [idiom, parentValues.policy]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormClaimRideshare">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <PhoneArea parentValues={parentValues} {...props} />
              <EventDetails parentValues={parentValues} {...props} />
              <LegalExpensesList parentValues={parentValues} {...props} />
              <OtherInsuranceArea parentValues={parentValues} {...props} />
              <TotalAmountArea parentValues={parentValues} {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default LegalProtection;
