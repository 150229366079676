import * as Yup from 'yup';
import { pt_BR } from '../../../intl/idioms';
import { CheckIsVisaNumber } from '../../../utils/card';
import { removeNonNumeric, validateCPF } from '../../../utils/stringUtils';
import moment from 'moment';
import { PHONE_TYPE_MOBILE } from '../../../constants/phone';

export const initialValues = (userInfo, cnInfo, idiom) => {
  const firstName = (userInfo && userInfo.firstName) || '';
  const lastName = (userInfo && userInfo.lastName) || '';
  return {
    birth_date:
      cnInfo && cnInfo.person && cnInfo.person.birth_date
        ? new Date(cnInfo.person.birth_date)
        : '',
    name: firstName,
    disableName: firstName.trim() !== '',
    lastName: lastName,
    disableLastName: lastName.trim() !== '',
    email: userInfo && userInfo.email,
    nationalities:
      cnInfo &&
      cnInfo.person &&
      cnInfo.person.nationalities &&
      cnInfo.person.nationalities[0]
        ? cnInfo.person.nationalities[0]
        : idiom === pt_BR
        ? 'BR'
        : '',
    person_registrations: {
      registration_type:
        cnInfo && cnInfo.nationalities && cnInfo.nationalities === 'BR'
          ? 'CPF'
          : 'PASSPORT',
      value:
        cnInfo && cnInfo.registrations[0] && cnInfo.registrations[0].value
          ? cnInfo.registrations[0].value
          : '',
    },
    phone_type:
      cnInfo && cnInfo.phone && cnInfo.phone.phone_type
        ? cnInfo.phone.phone_type
        : PHONE_TYPE_MOBILE,
    number:
      cnInfo && cnInfo.phone && cnInfo.phone.number ? cnInfo.phone.number : '',
    international_prefix:
      cnInfo && cnInfo.phone && cnInfo.phone.international_prefix
        ? `+${cnInfo.phone.international_prefix}`
        : '',
    creditCard: '',
    effective_end_date: '',
    AcceptTermsConditions: false,
    AceptPrivacyNotice: false,
    AceptSupplementaryPrivacyNotice: false,
    whatsapp_notification: true,
    marketing_notification: true,
  };
};

export const validationSchema = (intl, step, stepCard) => {
  const step1 = step === 1;
  const step2 = step === 2;
  const step3 = step === 3;

  return Yup.object().shape({
    name: step2 ? Yup.string().required(intl.EMPTY_LABEL) : Yup.string(),
    lastName: step2 ? Yup.string().required(intl.EMPTY_LABEL) : Yup.string(),
    birth_date: step2
      ? Yup.mixed()
          .test('match', intl.VALIDATE_DATE_VALID, function (birth_date) {
            const dateForValidation = moment('1900-01-01').format('YYYY-MM-DD');
            const birthDateForValidation =
              moment(birth_date).format('YYYY-MM-DD');
            if (
              moment(birthDateForValidation).isSameOrBefore(dateForValidation)
            ) {
              return false;
            }
            let yesterday = moment(new Date())
              .subtract(1, 'days')
              .format('YYYY-MM-DD HH:ss');
            return moment(birth_date).isSameOrBefore(yesterday);
          })
          .typeError(intl.EMPTY_LABEL)
      : Yup.string(),
    nationalities: step2
      ? Yup.string().required(intl.EMPTY_LABEL)
      : Yup.string(),
    person_registrations:
      step2 &&
      Yup.object().shape({
        value: Yup.string()
          .required(intl.EMPTY_LABEL)
          .when(
            ['registration_type'],
            {
              is: registration_type => {
                return registration_type === 'CPF';
              },
              then: Yup.string()
                .required(intl.EMPTY_LABEL)
                .test('test-data-valid', intl.VALIDATE_CPF, function (value) {
                  return validateCPF(value);
                }),
            },
            ['registration_type']
          ),
        registration_type: Yup.string().required(intl.EMPTY_LABEL),
      }),
    phone_type: step2 ? Yup.string().required(intl.EMPTY_LABEL) : Yup.string(),
    international_prefix: step2
      ? Yup.string().required(intl.EMPTY_LABEL)
      : Yup.string(),
    number: step2 ? Yup.string().required(intl.EMPTY_LABEL) : Yup.string(),
    creditCard:
      step3 &&
      (stepCard === 'P'
        ? Yup.string()
            .required(intl.EMPTY_LABEL)
            .test(
              'has-16-digits',
              intl.INVALID_CARD_NUMBER,
              pan => removeNonNumeric(pan).length === 16
            )
            .test('is-visa-card', intl.NOT_VISA_PRODUCT, pan =>
              CheckIsVisaNumber(removeNonNumeric(pan))
            )
        : Yup.string()
            .required(intl.EMPTY_CARD_NUMBER)
            .test('creditCard', intl.INVALID_CARD_NUMBER, val => {
              const noMask = removeNonNumeric(val);
              return String(noMask).length === 10;
            })),
    /**
     * * The Business team requested to comment the effective_end_date from the form
     * ? ISSUER:
     * https://dev.azure.com/AASolutions/americas_visa_benefits_portal/_sprints/taskboard/americas_visa_benefits_portal%20Team/americas_visa_benefits_portal/2023/Sprint23_15?workitem=713801
     */
    // effective_end_date:
    //   step3 &&
    //   ALWAYS_ADD_PAN &&
    //   Yup.string()
    //     .required(intl.EMPTY_LABEL)
    //     .min(5, intl.EMPTY_LABEL)
    //     .test('valid-expiration-date', intl.INVALID_DATE, value => {
    //       if (value && value.length === 5) {
    //         return validateExpirationDate(value);
    //       }
    //       return false;
    //     }),
    AcceptTermsConditions: step1
      ? Yup.bool().test('is-true', intl.EMPTY_LABEL, value => value === true)
      : Yup.bool(),
    AceptPrivacyNotice: step1
      ? Yup.bool().test('is-true', intl.EMPTY_LABEL, value => value === true)
      : Yup.bool(),
    AceptSupplementaryPrivacyNotice: step1
      ? Yup.bool().test('is-true', intl.EMPTY_LABEL, value => value === true)
      : Yup.bool(),
  });
};

/* TITLE */
export const TITLE_MR = 'MR';
export const TITLE_MS = 'MS';
export const TITLE_MISS = 'MISS';
export const TITLE_DR = 'DR';
export const TITLE_PR = 'PR';
