export { MY_TRIP_ASSISTANCES } from '../../../intl/travels';

export const SUBTITLE_TRIP_ASSISTANCE = {
  en_US: 'My benefits',
  es_ES: 'Mis beneficios',
  pt_BR: 'Meus benefícios',
};

export const DONT_HAVE_TRIP_ASSISTANCES = {
  en_US: 'You do not have any activated Travel Kits',
  es_ES: 'No tienes ningún Visa Travel Kit activado',
  pt_BR: 'Você não possui nenhum Travel Kit ativado',
};
