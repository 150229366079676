import React, { useContext, useState, useEffect } from 'react';

import { NavLink, withRouter, useHistory, useLocation } from 'react-router-dom';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import styles from './MenuMobile.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../../StateContextParent/StateContextParent';

import SVG_HOME_MASTER from '../../../assets/images/icon/master/svg_mobile_nav_home_master.svg';
import SVG_BENEFITS_MASTER from '../../../assets/images/icon/master/svg_mobile_nav_benefits_master.svg';

import SVG_CERTIFICATES_MASTER from '../../../assets/images/icon/master/svg_mobile_nav_certificates_master.svg';
import SVG_CLAIMS_MASTER from '../../../assets/images/icon/master/svg_mobile_nav_claims_master.svg';
import SVG_SPECIAL_BENEFITS_MASTER from '../../../assets/images/icon/master/special_benefits.svg';
import SVG_HELP_MASTER from '../../../assets/images/icon/master/svg_mobile_nav_headphone_master.svg';

import SVG_HOME from '../../../assets/images/icon/svg_mobile_nav_home.svg';
import SVG_BENEFITS from '../../../assets/images/icon/svg_mobile_nav_benefits.svg';

import SVG_SPECIAL_BENEFITS from '../../../assets/images/icon/afluent/special_benefits_blue.svg';
import SVG_CERTIFICATES from '../../../assets/images/icon/svg_mobile_nav_certificates.svg';
import SVG_CLAIMS from '../../../assets/images/icon/svg_mobile_nav_claims.svg';
import SVG_HELP from '../../../assets/images/icon/afluent/svg__mobile_nav_headphone_afluent.svg';

import SVG_SPECIAL_BENEFITS_AFLUENT from '../../../assets/images/icon/afluent/special_benefits.svg';
import SVG_HOME_AFLUENT from '../../../assets/images/icon/afluent/svg_mobile_nav_home_afluent.svg';
import SVG_BENEFITS_AFLUENT from '../../../assets/images/icon/afluent/svg_mobile_nav_benefits_afluent.svg';

import SVG_CERTIFICATES_AFLUENT from '../../../assets/images/icon/afluent/svg_mobile_nav_certificates_afluent.svg';
import SVG_CLAIMS_AFLUENT from '../../../assets/images/icon/afluent/svg_mobile_nav_claims_afluent.svg';
import SVG_HELP_AFLUENT from '../../../assets/images/icon/afluent/headphone.svg';
import CertificateIcon from '../../../assets/icons/CertificatesIcon';
import ClaimsIcon from '../../../assets/icons/ClaimsIcon';

import { ENABLE_SPECIAL_BENEFITS } from '../../../utils/environments_variables';
import { isBinNotActived } from '../../../utils/card';
import BenefitsIcon from '../../../assets/icons/BenefitsIcon';
import HomeIcon from '../../../assets/icons/HomeIcon';
import HeadPhoneIcon from '../../../assets/icons/HeadphoneIcon';
import SpecialBenefitsIcon from '../../../assets/icons/SpecialBenefitsIcon';
const navPrivate = [
  '/dashboard',
  '/your-benefits',
  '/your-subscription-benefits',
  '/certificates',
  '/claims',
];
const navPublic = ['/', '/benefits', '/subscription-benefits', '/help'];

const setBottomUSTBot = (botIsVisible, className) => {
  const getClassBotUST = document.getElementsByClassName(className);
  if (getClassBotUST[0]) {
    if (botIsVisible && window.innerWidth <= 992) {
      getClassBotUST[0].style.bottom = '50px';
    } else {
      getClassBotUST[0].setAttribute('style', 'bottom: 0px !important');
    }
  }
};

const setBottomUSTAlertChat = (botIsVisible, className) => {
  const getClassBotUST = document.getElementsByClassName(className);
  if (getClassBotUST[0]) {
    if (botIsVisible && window.innerWidth <= 992) {
      getClassBotUST[0].style.bottom = '120px';
    } else {
      getClassBotUST[0].setAttribute('style', 'bottom: 70px !important');
    }
  }
};

const Navigation = () => {
  const location = useLocation();
  const [show, setShow] = useState(true);
  const [scroll, setScroll] = useState(window.pageYOffset);
  const { state, utils } = useContext(StateContext);
  const isLogged = state.user.logged;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  let history = useHistory();
  const pathName = history.location.pathname;
  const links = isLogged ? navPrivate : navPublic;
  const binNotActived = isBinNotActived(utils.getSelectedCard());

  // MASTER OR AFLUENT
  let iconEhome = isAfluent() ? SVG_HOME_AFLUENT : SVG_HOME_MASTER;
  let iconEbenefits = isAfluent() ? SVG_BENEFITS_AFLUENT : SVG_BENEFITS_MASTER;
  let iconEspecialBenefits = isAfluent()
    ? SVG_SPECIAL_BENEFITS_AFLUENT
    : SVG_SPECIAL_BENEFITS_MASTER;
  let iconEcertificats = isAfluent()
    ? SVG_CERTIFICATES_AFLUENT
    : SVG_CERTIFICATES_MASTER;
  let iconEclaims = isAfluent() ? SVG_CLAIMS_AFLUENT : SVG_CLAIMS_MASTER;
  let iconEHelp = isAfluent() ? SVG_HELP_AFLUENT : SVG_HELP_MASTER;

  // ACTIVE THEME ICON
  let iconHome = pathName === links[0] ? iconEhome : SVG_HOME;
  let iconBenefits = pathName === links[1] ? iconEbenefits : SVG_BENEFITS;
  let iconSpecialBenefits =
    pathName === links[2] ? iconEspecialBenefits : SVG_SPECIAL_BENEFITS;
  let iconCertificates =
    pathName === links[3] ? iconEcertificats : SVG_CERTIFICATES;
  let iconClaims = pathName === links[4] ? iconEclaims : SVG_CLAIMS;
  let iconHelp = pathName === links[3] ? iconEHelp : SVG_HELP;
  const listenerScroll = () => {
    setScroll(oldScroll => {
      if (window.innerWidth > 992) {
        setBottomUSTBot(false, 'helpButton');
        setBottomUSTAlertChat(false, 'alertChat');
      }
      if (window.pageYOffset > oldScroll) {
        setShow(false);
        setBottomUSTBot(false, 'helpButton');
        setBottomUSTBot(false, 'dockableContainer');
        setBottomUSTAlertChat(false, 'alertChat');
      } else {
        setBottomUSTBot(true, 'helpButton');
        setBottomUSTBot(true, 'dockableContainer');
        setBottomUSTAlertChat(true, 'alertChat');
        setShow(true);
      }
      return window.pageYOffset;
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', listenerScroll);
    return () => {
      window.removeEventListener('scroll', listenerScroll);
    };
  }, []);

  useEffect(() => {
    const helpButton = document.getElementsByClassName('helpButton')[0];
    const chatOpened = document.getElementsByClassName('dockableContainer')[0];

    if (helpButton && window.innerWidth < 992) {
      setBottomUSTBot(show, 'helpButton');
    }
    if (chatOpened) {
      chatOpened.setAttribute('style', 'z-index: 99999');
    }
  }, [show]);

  if (!show) {
    return <></>;
  }

  return (
    <nav
      id="menuNavigationMobile"
      className={`${styles[theme]} ${styles.navigation}`}
    >
      <div className={`${styles.item}`}>
        <div className={`${styles.itemContent}`}>
          <NavLink
            aria-label={intl.TEXT_HOME}
            to={links[0]}
            className={`${styles[theme]}`}
            activeClassName={styles.active}
          >
            <HomeIcon
              width={28}
              height={28}
              enabled={
                location.pathname === '/' ||
                location.pathname.includes('/dashboard')
                  ? 1
                  : 0
              }
              bottombar={1}
            />
          </NavLink>
          <span className={`${styles[theme]}`}>{intl.TEXT_HOME}</span>
        </div>
      </div>
      {!binNotActived && (
        <div className={`${styles.item} ${styles[theme]}`}>
          <div
            id="benefits-headerOptionMobile"
            className={`${styles.itemContent}`}
          >
            <NavLink
              aria-label={intl.TEXT_BENEFITS}
              to={links[1]}
              className={`${styles[theme]}`}
              activeClassName={styles.active}
            >
              <BenefitsIcon
                width={30}
                height={30}
                enabled={
                  location.pathname.includes('/benefits') ||
                  location.pathname.includes('/your-benefits')
                    ? 1
                    : 0
                }
                bottombar={1}
              />
            </NavLink>
            <span className={`${styles[theme]}`}>{intl.TEXT_BENEFITS}</span>
          </div>
        </div>
      )}
      {ENABLE_SPECIAL_BENEFITS && !binNotActived && (
        <div className={`${styles.item} ${styles[theme]}`}>
          <div
            id="SubscriptionBenefits-headerOptionMobile"
            className={`${styles.itemContent}`}
          >
            <NavLink
              aria-label={intl.TEXT_SPECIAL_BENEFITS}
              to={links[2]}
              className={`${styles[theme]}`}
              activeClassName={styles.active}
            >
              <SpecialBenefitsIcon
                width={30}
                height={30}
                enabled={
                  location.pathname.includes('/subscription-benefits') ||
                  location.pathname.includes('/your-subscription-benefits')
                    ? 1
                    : 0
                }
                bottombar={1}
              />
            </NavLink>
            <span className={`${styles[theme]}`}>
              {intl.TEXT_SPECIAL_BENEFITS}
            </span>
          </div>
        </div>
      )}
      {!binNotActived && (
        <div className={`${styles.item} ${styles[theme]}`}>
          <div
            id="certificates-headerOptionMobile"
            className={`${styles.itemContent}`}
          >
            <NavLink
              aria-label={intl.TEXT_HELP}
              to={links[3]}
              className={`${styles[theme]}`}
              activeClassName={styles.active}
            >
              {isLogged ? (
                <CertificateIcon
                  enabled={location.pathname.includes('/certificates') ? 1 : 0}
                  bottombar={1}
                />
              ) : (
                <HeadPhoneIcon
                  width={30}
                  height={30}
                  enabled={location.pathname.includes('/help') ? 1 : 0}
                  bottombar={1}
                />
              )}
            </NavLink>
            {isLogged ? (
              <span className={`${styles[theme]}`}>
                {intl.TEXT_CERTIFICATES}
              </span>
            ) : (
              <span className={`${styles[theme]}`}>{intl.TEXT_HELP}</span>
            )}
          </div>
        </div>
      )}
      {isLogged && (
        <div className={`${styles.item} ${styles[theme]}`}>
          <div
            id="claims-headerOptionMobile"
            className={`${styles.itemContent}`}
          >
            <NavLink
              aria-label={intl.TEXT_CLAIMS}
              to={links[4]}
              className={`${styles[theme]}`}
              activeClassName={styles.active}
            >
              <ClaimsIcon
                enabled={location.pathname.includes('/claims') ? 1 : 0}
                bottombar={1}
              />
            </NavLink>
            <span className={`${styles[theme]}`}>{intl.TEXT_CLAIMS}</span>
          </div>
        </div>
      )}
    </nav>
  );
};

export default withRouter(Navigation);
