export const TEXT_SUBMITTED_COMPLAINT = {
  en_US: 'Formal complaint against the transport service',
  es_ES: 'Reclamación formal contra el servicio de transporte',
  pt_BR: 'Queixa formal contra o serviço de transporte',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_PLEASE_EXPLAIN = {
  en_US: 'Please explain why',
  pt_BR: 'Por favor, explique por que',
  es_ES: 'Por favor, explique por qué',
};

export const TEXT_PIR = {
  en_US: 'PIR',
  pt_BR: 'PIR',
  es_ES: 'PIR',
};

export const TEXT_AMOUNT_TRANSPORTATION = {
  en_US: 'Indicate the amount paid by the transportation company',
  pt_BR: 'Indique o valor pago pela empresa de transporte',
  es_ES: 'Indica el monto pagado por la compañia de transporte',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_INDEMNIFICATION_TRANSPORTATION = {
  en_US: 'Did you receive indemnification by the transportation company?',
  es_ES: '¿Recibiste una indemnización por parte de la compañía de transporte?',
  pt_BR: 'Você recebeu uma indenização do transportador?',
};

export const TEXT_INSURANCE_CLAIMED = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
};

export const TEXT_AMOUNT_PAID_INSURANCE = {
  en_US: 'Please indicate the amount paid by the insurance',
  es_ES: 'Indica el monto pagado por la compañía de seguros',
  pt_BR: 'Indique o valor pago pela companhia de seguros',
};
