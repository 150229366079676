export const INSSUANCE_DATE = {
  en_US: '',
  pt_BR: 'Data de emissão do bilhete de seguro',
  es_ES: '',
};

export const VALIDITY = {
  en_US: '',
  pt_BR: 'Data de vigência do bilhete de seguro',
  es_ES: '',
};
