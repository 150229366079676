import sassTheme from '../../styles/themes/index.module.scss';

export interface ThemeColorsObject {
  primary: string;
  secondary: string;
  tertiary: string;
  link: string;
  text_active_element: string;
  text_and_background: string;
  subtitle: string;
  const_gray: string;
  const_brown: string;
  const_axa_blue: string;
  const_light_gray: string;
  badge_download_app: string;

  // Colors
  'primary-primary': string;
  'primary-hover': string;
  'primary-pressed': string;
  'primary-inverse': string;
  'primary-inverse_hover': string;
  'secondary-secondary': string;
  'secondary-hover': string;
  'secondary-pressed': string;
  'secondary-inverse': string;
  'secondary-inverse_hover': string;
  'secondary-inverse_pressed': string;

  // Surface
  'surface-surface': string;
  'surface-hover': string;
  'surface-pressed': string;
  'surface-inverse': string;
  'surface-inverse_hover': string;
  'surface-inverse_pressed': string;

  // Disabled
  'disabled-graphics': string;
  'disabled-surface': string;
  'disabled-text': string;

  // Text
  'text-title': string;
  'text-title-secondary': string;
  'text-inverse_title': string;
  'text-paragraph': string;
  'text-paragraph_subtle': string;
  'text-inverse_paragraph': string;

  // Icons
  'icon-on_surface-primary': string;
  'icon-on_surface-secondary': string;
  'icon-inverse-primary': string;
  'icon-inverse-secondary': string;

  // Negatives
  'negative-surface': string;
  'negative-text': string;
  'negative-graphics': string;

  // Positives
  'positive-surface': string;
  'positive-graphics': string;
  'positive-text': string;

  // Warning
  'warning-text': string;
}

export interface ThemeColorsGeneric {
  'dark-blue': string;
  white: string;
  disabled: string;
  'error-red-alert': string;
  const_axa_blue: string;
  const_gray: string;
  const_light_gray: string;

  tea_green: string;
  green_70: string;
  green_100: string;
  pink: string;
  red_70: string;
  red_100: string;
  orange_70: string;
  sorbet_yellow: string;
  visa_yellow_100: string;
  light_blue: string;
  blue_70: string;
  blue_100: string;
  gray_10: string;
  gray_20: string;
  gray_60: string;
  gray_100: string;
  text_title_error: string;
}

export interface ThemeObject {
  afluent: ThemeColorsObject;
  axa: ThemeColorsObject;
  master: ThemeColorsObject;
  portoBank: ThemeColorsObject;
  genericColors: ThemeColorsGeneric;
}

const objectKeys = new Set(
  Object.keys(sassTheme).map(key => key.split('_')[0])
);

const objectThemeFormated = {};
objectKeys.forEach(keyTheme => {
  const keys = Object.keys(sassTheme).filter(keyPropertie =>
    keyPropertie.includes(keyTheme)
  );

  const keyAndValuesString = keys.map(key => {
    const nameOfKey = key.split('_').slice(1).join('_');
    const value = sassTheme[key];
    return `"${nameOfKey}": "${value}"`;
  });

  const formatedObject = JSON.parse(`{ ${keyAndValuesString.join(',')} }`);

  objectThemeFormated[keyTheme] = formatedObject;
});

export default objectThemeFormated as ThemeObject;
