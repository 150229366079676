import React from 'react';
import ButtonSortList from '../../../../components/ButtonSortList/ButtonSortList';

const OrderList = ({ list, initialOrder, setList }) => {
  const changeOrdemASC = () => {
    let listAsc = list.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );
    setList([...listAsc]);
  };
  const changeOrdemDESC = () => {
    let listDesc = list.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    setList([...listDesc]);
  };

  return (
    <ButtonSortList
      initialOrder={initialOrder}
      actionToSortASC={changeOrdemASC}
      actionToSortDESC={changeOrdemDESC}
    />
  );
};

export default OrderList;
