export const TEXT_TITLE_SELECT_COUNTRY = {
  en_US: 'Select your country',
  es_ES: 'Selecciona tu pais',
  pt_BR: 'Escolha o seu país',
};

export const TEXT_NORTH_AMERICA = {
  en_US: 'North America',
  es_ES: 'Norteamérica',
  pt_BR: 'América do Norte',
};

export const TEXT_SOUTH_AMERICA = {
  en_US: 'South America',
  es_ES: 'Sudamerica',
  pt_BR: 'América do Sul',
};

export const TEXT_CENTRAL_AMERICA = {
  en_US: 'Central America',
  es_ES: 'Centroamérica',
  pt_BR: 'América Central',
};

export const TEXT_CARIBBEAN = {
  en_US: 'Caribbean',
  es_ES: 'Caribe',
  pt_BR: 'Caribenho',
};

export const LIST_OF_COUNTRIES = {
  en_US: 'List of countries',
  es_ES: 'Lista de países',
  pt_BR: 'Lista de países',
};

export const IMAGE_OF_THE_TERRESTRIAL_GLOBE = {
  en_US: 'Image of the Terrestrial Globe',
  es_ES: 'Imagen del Globo Terrestre',
  pt_BR: 'Imagem do Globo Terrestre',
};

export const DESCRIBE_MENU_COUNTRY_LIST = {
  en_US:
    'If you select a country from the list, the Visa portal language will change to the language of the selected country',
  es_ES:
    'Si selecciona un país de la lista, el idioma del portal Visa cambiará al idioma del país seleccionado.',
  pt_BR:
    'Se você selecionar um país da lista, o idioma do portal Visa irá mudar para o idioma do país selecionado',
};
