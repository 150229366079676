import { Schedule } from '../../@types/APIs/AEROAPI/Schedule';
import { Travel, TravelDetail } from '../../@types/APIs/Travels';
import { Idiom } from '../../intl/idiom';
import { getCountryFromPrefixTranslated } from '../../utils/country';
import { formatDateWithOffset } from '../../utils/date';
import { getCountryList } from '../../utils/travels';

export type ScheduleWithAirportInfo = Schedule & {
  originAirport: Airport;
  destinationAirport: Airport;
};

export const getTravelDates = (travel: Travel, idiom) =>
  formatTravelDates(idiom, travel.outward_start_at, travel.return_start_at);

export const formatTravelDates = (
  idiom: Idiom,
  startDate: string,
  endDate?: string
) => {
  try {
    if (endDate) {
      return `${formatDateWithOffset(
        startDate,
        idiom
      )} - ${formatDateWithOffset(endDate, idiom)}`;
    } else if (startDate) {
      return formatDateWithOffset(startDate, idiom);
    }
  } catch (error) {
    return '';
  }
};

export const getRouteIntl = (intl, idiom: string, travel: TravelDetail) => {
  try {
    const countries = getCountryList(travel.outward_travel.flight_segments);

    return intl.ORIGIN_TO_DESTINATION(
      getCountryFromPrefixTranslated(countries.at(0), idiom),
      getCountryFromPrefixTranslated(countries.at(-1), idiom)
    );
  } catch (error) {
    return '';
  }
};
