export const LABEL_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};

export const TITLE_DESCRIPTION = {
  en_US: 'Incident',
  pt_BR: 'Incidente',
  es_ES: 'Incidente',
};
