import styles from './ModalResult.module.scss';
import Button from '../Button/Button';

const ModalResult = ({
  textConfirm = '',
  textDeny = '',
  textBtnThird = '',
  clickConfirm = undefined,
  clickDeny = undefined,
  clickBtnThird = undefined,
  buttonConfirmType = 'gold',
  buttonDenyType = 'blueWithoutBorder',
  contentRedirect = undefined,
}) => {
  return (
    <div className={styles.boxModalResult}>
      {textDeny && textDeny.length > 0 && (
        <div className={styles.boxButton}>
          <Button
            type={buttonDenyType}
            onClick={clickDeny}
            id="modalButtonCancel"
          >
            {textDeny}
          </Button>
        </div>
      )}
      {textBtnThird && textBtnThird.length > 0 && (
        <div className={styles.boxButton}>
          <Button type={buttonDenyType} onClick={clickBtnThird}>
            {textBtnThird}
          </Button>
        </div>
      )}
      <div className={styles.boxButton}>
        <Button
          autoFocus
          type={buttonConfirmType}
          onClick={clickConfirm}
          id="modalButtonConfirm"
        >
          {textConfirm}
        </Button>
      </div>
      {contentRedirect && (
        <div className={styles.boxButton}>{contentRedirect}</div>
      )}
    </div>
  );
};

export default ModalResult;
