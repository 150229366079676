import { useContext, useEffect, useState } from 'react';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { flightService } from '../../../services';
import { LoadingStatus } from '../../../@types/LoadingStatus';
import { Flight } from '../../../@types/APIs/AEROAPI/Flight';
import { format } from 'date-fns-tz';
import { getLocale } from '../../../utils/date';
import styles from './index.module.scss';
import FlightTracking from '../../../components/FlightTracking';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import FlightInfo from '../../../components/FlightInfo';
import Loading from '../../../components/Loading/Loading';

interface RealTimeTrackingProps {
  flightId: string;
}

// ! About commented code: This was for the "Share flight" feature, but the
// !  Web Share API is too limited and won't work in many cases (OSs and browsers),
// !  also, I encounted many problems generating the image to share, the html-to-image
// !  library would make several new requests to get the fonts, this is only solved by
// !  sending the font as base64 (see fontFaces.ts file), we need to rethink this feature.

const STATUS_REFRESH_TIME_MS = 1 * 60 * 1000;

const RealTimeTracking = ({ flightId }: RealTimeTrackingProps) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  // const acordeonRef = useRef<HTMLDivElement>();
  // const [shareData, setShareData] = useState<ShareData>(null);

  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [lastUpdated, setLastUpdated] = useState<Date>();
  const [flight, setFlight] = useState<Flight>();
  const [airportOrigin, setAirportOrigin] = useState<Airport>();
  const [airportDestination, setAirportDestination] = useState<Airport>();

  const { origin, destination } = flight || {};
  const originAirportCode = origin?.code_iata || origin?.code_lid;
  const destinationAirportCode =
    destination?.code_iata || destination?.code_lid;

  const fetchAirport = async (code: string, isOrigin: boolean) => {
    try {
      if (!code) return;
      if (isOrigin && code === origin?.code) return;
      if (!isOrigin && code === destination?.code) return;

      const { data } = await flightService.getAirport(code);

      isOrigin ? setAirportOrigin(data) : setAirportDestination(data);
    } catch (error) {}
  };

  const fetchFlight = async () => {
    try {
      const { data } = await flightService.getFlights(flightId, {
        ident_type: 'fa_flight_id',
      });
      const flight = data.flights[0];

      setFlight(flight);
      setStatus(LoadingStatus.LOADED);
      setLastUpdated(new Date());

      fetchAirport(flight.origin?.code, true);
      fetchAirport(flight.destination?.code, false);
    } catch (error) {
      if (!flight) {
        setStatus(LoadingStatus.ERROR);
      }
    }
  };

  // const updateShareData = async () => {
  //   if (!acordeonRef.current) return;

  //   const blob = await toBlob(acordeonRef.current, {
  //     style: { height: 'initial' },
  //     fontEmbedCSS: fontFaces,
  //   });

  //   setShareData({
  //     files: [
  //       new File([blob], `test.png`, {
  //         type: blob.type,
  //       }),
  //     ],
  //   });
  // };

  // useEffect(() => {
  //   updateShareData();
  // }, [flight]);

  // const shareFlight = async () => {
  //   if (!flight || !shareData) return;

  //   try {
  //     if (navigator.canShare?.(shareData)) {
  //       await navigator.share(shareData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    fetchFlight();
  }, []);

  useEffect(() => {
    const timer = setInterval(fetchFlight, STATUS_REFRESH_TIME_MS);
    return () => clearInterval(timer);
  }, [originAirportCode, destinationAirportCode]);

  if (status === LoadingStatus.LOADING)
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );

  return (
    <>
      <FlightInfo.StatusHeader flight={flight} />
      <FlightTracking
        flight={flight}
        airportDestination={airportDestination}
        airportOrigin={airportOrigin}
      />
      {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          > */}
      <p className={`${styles.lastUpdated} ${styles[theme]}`}>
        {intl.LAST_UPDATED}:{' '}
        {lastUpdated
          ? format(lastUpdated, 'pp', { locale: getLocale(idiom) })
          : '-'}
      </p>
      {/* <Button style={{ maxWidth: 240 }} onClick={shareFlight}>
              {intl.SHARE_MY_FLIGHT}
            </Button> */}
      {/* </div> */}
      {/* <div
            style={{ opacity: 0, height: 0, overflow: 'hidden' }}
            aria-hidden="true"
          >
            <div ref={acordeonRef} className={styles.test}>
              <FlightTracking
                flight={flight}
                airportDestination={airportDestination}
                airportOrigin={airportOrigin}
              />
            </div>
          </div> */}
    </>
  );
};

export default RealTimeTracking;
