import * as Yup from 'yup';
import moment from 'moment';

export const NurseLineSchema = intl =>
  Yup.object().shape({
    appointment: Yup.object().shape({
      date: Yup.string()
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID)
        .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (value) {
          const date = new Date(value);
          const dateMoment = moment(date).startOf('day');
          const current_date = moment().startOf('day');
          return (
            moment(dateMoment).isValid() &&
            moment(dateMoment).isSameOrAfter(current_date)
          );
        }),
      time: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      timezone: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      country: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      state: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      city: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
    }),
    pacient: Yup.object().shape({
      name: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
      last_name: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
      email: Yup.string()
        .email(intl.VALIDATE_EMAIL_VALID)
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
      phone_type: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      phone_international_prefix: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      phone_number: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
    }),
    questions: Yup.object().shape({
      questionsCheckeds: Yup.array()
        .test(
          'questions-checkeds',
          intl.TEXT_ONE_QUESTION_MUST_BE_SELECTED,
          function (array) {
            if (array.length > 0) {
              return true;
            }
            return false;
          }
        )
        .typeError(intl.TEXT_ONE_QUESTION_MUST_BE_SELECTED),
      questionCommentChecked: Yup.boolean(),
    }),
  });
