import React, { useContext } from 'react';
import * as translations from './intl';
import styles from './ConsultationCategoriesFilter.module.scss';
import { IntlContext } from '../../../../../intl';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  ConsultationPhoneType,
  ConsultationVideoType,
} from '../../../../../utils/consultationCategory';
const ConsultationCategoriesFilter = props => {
  const { setSelected, selected, numberOfPhoneCalls, numberOfVideoCalls } =
    props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles.benefitsCategories} ${styles.isBenefit}`}>
      <ul>
        {numberOfVideoCalls > 0 && (
          <li
            onClick={() => setSelected(ConsultationVideoType)}
            className={
              selected === ConsultationVideoType
                ? `${styles[theme]} ${styles.active}`
                : `${styles[theme]}`
            }
          >
            {intl.LABEL_VIDEO_CALL}
          </li>
        )}
        {numberOfPhoneCalls > 0 && (
          <li
            onClick={() => setSelected(ConsultationPhoneType)}
            className={
              selected === ConsultationPhoneType
                ? `${styles[theme]} ${styles.active}`
                : `${styles[theme]}`
            }
          >
            {intl.LABEL_PHONE_CALL}
          </li>
        )}
      </ul>
    </div>
  );
};

export default ConsultationCategoriesFilter;
