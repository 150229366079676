/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import styles from './Banner.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import {
  getAlignBackgroundByURL,
  getNoBlackCoverByURL,
  getValignBackgroundByURL,
} from '../../../utils/stringUtils';

const Banner = props => {
  const { benefitDetail: benefit } = props;
  const [background, setBackground] = useState();
  const [height, setHeight] = useState();
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const getImageMobile =
    benefit &&
    benefit.images &&
    Array.isArray(benefit.images) &&
    benefit.images.find(image => image.type === 'PICTURE_MOBILE');

  const getImage =
    benefit &&
    benefit.images &&
    Array.isArray(benefit.images) &&
    benefit.images.find(image => image.type === 'PICTURE');

  const getCorrectImage = () => {
    if (window.outerWidth < 992 && getImageMobile) {
      setBackground(getImageMobile);
      setHeight(window.innerWidth * 0.8);
    } else if (getImage) {
      setBackground(getImage);
      setHeight(window.innerWidth * 0.43);
    }
  };

  useEffect(() => {
    if (benefit) {
      window.addEventListener('resize', getCorrectImage);
    }
    return () => {
      window.removeEventListener('resize', getCorrectImage);
    };
  }, [benefit]);

  useEffect(() => {
    getCorrectImage();
  }, [getImage, getImageMobile]);

  return (
    <div className={styles.bannerContainer}>
      <div
        className={styles.banner}
        data-testid="banner"
        style={{
          backgroundImage: `url(${background && background.content_url})`,
          height,
          // backgroundPosition: `
          // ${
          //   background &&
          //   background.content_url &&
          //   getAlignBackgroundByURL(background.content_url)
          // } ${
          //   background &&
          //   background.content_url &&
          //   getValignBackgroundByURL(background.content_url)
          // }
          // `,
        }}
      >
        {background &&
          background.content_url &&
          getNoBlackCoverByURL(background.content_url) && (
            <div className={styles.blackCover} style={{ height }}></div>
          )}
      </div>
    </div>
  );
};

export default Banner;
