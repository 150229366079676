export const TITLE_DOCTOR_COMMENT = {
  es_ES: 'Comentarios del Doctor',
  en_US: "Doctor's comments",
  pt_BR: 'Comentários do Doutor',
};

export const DOCTOR_COMMENT = {
  es_ES: 'Comentarios',
  en_US: 'Comments',
  pt_BR: 'Comentários',
};

export const DOCTOR_UPLOAD_FILES = {
  es_ES: 'Archivos subidos',
  en_US: 'Uploaded files',
  pt_BR: 'Arquivos enviados',
};
