import styles from './NavigateBenefits.module.scss';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import { useTheme } from '../../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../../componentsV2/Button/Button';
import ChevronLeftIcon from '../../../assets/icons/ChevronLeftIcon';

const NavigateBenefits = props => {
  const { previousLink = '/', nextLink = '/', borderBottom } = props;
  const { translate } = useIntl();
  const { getGlobalTheme, getCurrentThemeColors } = useTheme();
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const colors = getCurrentThemeColors();

  return (
    <div
      className={`${styles.navigationContainer} ${styles[theme]} ${
        borderBottom ? styles.borderBottom : ''
      }`}
    >
      <div className={styles.navigateItem}>
        <Button
          link
          type="Tertiary"
          leadingIcon={<ChevronLeftIcon color={colors['primary-primary']} />}
          label={intl.LINK_PREVIOUS}
          href={previousLink}
        />
      </div>
      <div className={styles.navigateItem}>
        <Button
          link
          type="Tertiary"
          trailingIcon="Chevron Right"
          label={intl.LINK_NEXT}
          href={nextLink}
        />
      </div>
    </div>
  );
};

export default NavigateBenefits;
