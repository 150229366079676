import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './MenuCards.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../../../componentsV2/Button/Button';
import { joinClassNames } from '../../../../utils/classNames';

const MakeSubmenu = ({ menuId, selected, setSelected }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  switch (menuId) {
    case 'consumers':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_CREDIT)}
                label={intl.SUBMENU_CREDIT}
              />
            </li>
            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_DEBIT)}
                label={intl.SUBMENU_DEBIT}
              />
            </li>
            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_PREPAID)}
                label={intl.SUBMENU_PREPAID}
              />
            </li>
          </ul>
        </div>
      );
    case 'corporate':
      return (
        <div className={styles.submenuList}>
          <ul>
            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_CREDIT
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                label={intl.SUBMENU_CREDIT}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_CREDIT)}
              />
            </li>

            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_DEBIT
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                label={intl.SUBMENU_DEBIT}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_DEBIT)}
              />
            </li>
            <li>
              <Button
                type="Tertiary"
                trailingIcon={
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? 'Chevron Right'
                    : 'Chevron Down'
                }
                className={joinClassNames(
                  selected === intl.TEXT_SET_SELECTED_PREPAID
                    ? styles.submenuActive
                    : '',
                  styles[themes]
                )}
                label={intl.SUBMENU_PREPAID}
                onClick={() => setSelected(intl.TEXT_SET_SELECTED_PREPAID)}
              />
            </li>
          </ul>
        </div>
      );
    default:
      return <></>;
  }
};

export default withRouter(MakeSubmenu);
