import React, { useState, useEffect, useMemo } from 'react';
import styles from './InfoVideoArea.module.scss';
import { useContext } from 'react';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import Select from '../../../../../../../components/Select/Select';
import { IntlContext } from '../../../../../../../intl';
import sanitize from '../../../../../../../utils/sanitize';
import SlotList from '../ScheduleList';
import * as translations from './intl';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import {
  amosOthersService,
  amosVideocallsService,
} from '../../../../../../../services/index';
import { addDays, format } from 'date-fns';
import Loading from '../../../../../../../components/Loading/Loading';
import ModalInformation from '../../../../../../../components/ModalInformation/ModalInformation';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { useHistory } from 'react-router';

const DAYS_TO_ADD_CONSULTATION_WINDOW = 4;

const InfoVideoArea = props => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    touched,
    parentValues,
  } = props;

  const [timezonesOptions, setTimezonesOptions] = useState('');
  const [timezones, setTimezones] = useState([]);
  const [loadingTimezone, setLoadingTimezone] = useState(true);
  const [times, setTimes] = useState([]);
  const [availability, setAvailability] = useState([]);
  const [loadingTimes, setLoadingTimes] = useState(true);

  const history = useHistory();
  const { actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { translate, twoDigitsIdiom } = useContext(IntlContext);

  const intl = translate(translations);
  const language = twoDigitsIdiom();

  const country = parentValues?.consultation?.country;

  useMemo(() => {
    let timezoneOptionsString = ``;
    timezones.forEach(item => {
      timezoneOptionsString += `<option value=${item.key}> ${item.value.replace(
        /_/g,
        ' '
      )} </option>\n`;
    });
    setTimezonesOptions(timezoneOptionsString);
  }, [timezones]);

  const loadFormTimezones = async () => {
    try {
      setLoadingTimezone(true);
      trackEventUserAction(
        '#### VMO ### Video Area - Loading forms data ',
        true
      );
      const timezones = await amosOthersService.getTimezones(language);
      setTimezones(timezones.data.data.timezones);
      setLoadingTimezone(false);
      trackEventUserAction('#### VMO ### Video Area - Loaded forms data', true);
    } catch (error) {
      setLoadingTimezone(false);
      trackEventUserAction(
        '#### VMO ### Video Area - Error in load forms data',
        error
      );
    }
  };

  const loadFormTimes = async () => {
    setLoadingTimes(true);
    try {
      const dateFrom = format(new Date(), 'yyyy-MM-dd');
      const dateTo = format(
        addDays(new Date(), DAYS_TO_ADD_CONSULTATION_WINDOW),
        'yyyy-MM-dd'
      );

      trackEventUserAction('#### VMO ### Video - Loading times', {
        dateFrom,
        dateTo,
        language,
        timezone: values.timezone,
        country,
      });

      const response = await amosVideocallsService.getAvailabilityDates(
        dateFrom,
        dateTo,
        language,
        values.timezone,
        country
      );

      let availability = response.data.data.availability;
      trackEventUserAction('#### VMO ### Video - Loaded times', {
        availability: [...availability],
      });

      availability = availability.filter(
        value => value.day_slots.findIndex(slot => slot.availability) != -1
      );
      trackEventUserAction('#### VMO ### Video - Times formated', {
        availability: [...availability],
      });

      setAvailability(availability);
      setLoadingTimes(false);
    } catch (error) {
      setLoadingTimes(false);
    }
  };

  useEffect(() => {
    loadFormTimezones();
  }, []);

  useEffect(() => {
    if (values.timezone) {
      loadFormTimes();
    } else {
      setAvailability([]);
    }
  }, [values.timezone, language, country]);

  useEffect(() => {
    const { date } = values;
    if (!date || availability.length === 0) return;

    const availableTimes = availability.find(
      day =>
        format(values.date, 'yyyy-MM-dd') ===
        new Date(day.date).toISOString().substring(0, 10)
    );
    if (availableTimes) {
      const timesFiltered = availableTimes.day_slots.filter(
        slot => slot.availability
      );
      setTimes(timesFiltered);
      trackEventUserAction('#### VMO ### No videocall slot available', true);
    } else {
      setTimes([]);
    }
  }, [values.date]);

  useEffect(() => {
    if (!loadingTimes && availability.length === 0) {
      actions.modal.showModal(
        '',
        false,
        <ModalInformation
          type="error"
          message={intl.TITLE_NOT_AVAILABLE}
          subtitle={intl.TITLE_PLEASE_TRY_AGAIN_LATER}
          clickBtn={() => {
            actions.modal.closeModal();
            history.push('/your-benefits');
          }}
        />
      );
    }
  }, [loadingTimes, availability]);

  return (
    <div className="row">
      <div className="col-12 col-xl-6">
        {loadingTimezone ? (
          <Loading />
        ) : (
          <Select
            label={intl.LABEL_TIMEZONE}
            name="timezone"
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_TIMEZONE}</option>${timezonesOptions}`
              ),
            }}
            formikProps={props}
            dataTestid="inputLocationCountry"
          />
        )}
      </div>
      <div className="col-12 col-xl-6">
        {loadingTimes ? (
          <>{values.timezone && <Loading />}</>
        ) : (
          <>
            {values.timezone && (
              <>
                <Calendar
                  label={intl.LABEL_DATE}
                  name={`date`}
                  value={values && values.date ? new Date(values.date) : ''}
                  minDate={new Date()}
                  maxDate={addDays(new Date(), DAYS_TO_ADD_CONSULTATION_WINDOW)}
                  onChange={handleChange}
                  touched={touched && touched.date}
                  onBlur={handleBlur}
                  error={errors && errors.date}
                  setFieldTouched={setFieldTouched}
                />

                {values.date &&
                  (times.length > 0 ? (
                    <SlotList formikProps={props} slots={times} />
                  ) : (
                    <div className={styles.noTimesMessage}>
                      <span>{intl.DATE_NOT_AVAILABLE}</span>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InfoVideoArea;
