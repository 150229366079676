import React, { useContext } from 'react';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import moment from 'moment';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../intl/idioms';
import { formatCustomDate } from '../../../../utils/date';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const BaggageDelayDetail = props => {
  const { claimDetails } = props;
  const { idiom, translate, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';

  const formatDateAndHour = date =>
    moment(date).format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const getPhoneType = (phoneType, intl) => {
    switch (phoneType) {
      case 'LANDLINE':
        return intl.LABEL_PHONE_TYPE_LN;
      case 'MOBILE':
        return intl.LABEL_PHONE_TYPE_MB;
      default:
        return '-';
    }
  };

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : '-';

  const fromDate =
    travelInformation && travelInformation.from_date
      ? formatCustomDate(travelInformation.from_date, null, idiom)
      : '-';

  const toDate =
    travelInformation && travelInformation.to_date
      ? formatCustomDate(travelInformation.to_date, null, idiom)
      : '-';

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : '';

  const discoveredAt =
    incident && incident.discovered_at
      ? formatDateAndHour(incident.discovered_at)
      : '-';

  const baggageArrivedAt =
    incident && incident.baggage_arrived_at
      ? formatDateAndHour(incident.baggage_arrived_at)
      : '-';

  const travelAgency =
    travelInformation && travelInformation.travel_agency
      ? travelInformation.travel_agency
      : '';

  const agencyName =
    travelAgency && travelAgency.name ? travelAgency.name : '-';

  const travelAgencyPhone =
    travelAgency && travelAgency.phone ? travelAgency.phone : '';

  const internationalPrefix =
    travelAgencyPhone && travelAgencyPhone.international_prefix
      ? travelAgencyPhone.international_prefix
      : '-';

  const phoneType =
    travelAgencyPhone && travelAgencyPhone.phone_type
      ? travelAgencyPhone.phone_type
      : '-';

  const phoneNumber =
    travelAgencyPhone && travelAgencyPhone.number
      ? travelAgencyPhone.number
      : '-';

  const airlineNumber = `${getPhoneType(
    phoneType,
    intl
  )} - ${internationalPrefix} ${phoneNumber}`;

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={`${fromDate} - ${toDate}`}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_DATE_ARRIVAL} value={discoveredAt} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_RECEIVED_BAGGAGE}
          value={baggageArrivedAt}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_NAME_AGENCY} value={agencyName} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TRANSPORTATION_ID}
          value={airlineNumber}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default BaggageDelayDetail;
