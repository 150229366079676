export const TEXT_MY_CARDS = {
  en_US: 'My cards',
  es_ES: 'Mis tarjetas',
  pt_BR: 'Meus cartões',
};

export const ICON_OPEN_MENU_COUNTRIES_LIST = {
  en_US: 'Icon Open Menu Countries List',
  es_ES: 'Ícono para Abrir el Menú de Lista de Países',
  pt_BR: 'Ícone para Abrir o Menu da Lista de Países',
};

export const LIST_CARDS = {
  en_US:
    'Card List, if you select a card from the list the experience will be changed to the respective card',
  es_ES:
    'Lista de tarjetas, si selecciona una tarjeta de la lista, la experiencia se cambiará a la tarjeta respectiva',
  pt_BR:
    'Lista de Cartões, se você selecionar um cartão da lista a experiência será alterada para o respectivo cartão',
};
