export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain)*',
  pt_BR: 'Outro (explicar)*',
  es_ES: 'Otro (explicar)*',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Affected person date of birth *',
  pt_BR: 'Data de nascimento da pessoa afetada *',
  es_ES: 'Fecha de nacimiento de la persona afectada *',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};

export const HELP_REGISTRATION_TYPE = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};
