import { isEqual, cloneDeep } from 'lodash';

export const checkChanged = props => {
  const {
    values: { reimbursement: values },
    bankInfo,
    initalValueCompare: { reimbursement: initalValueCompare },
  } = props;

  const cloned = cloneDeep(values);

  const bankAccounChanged = !isEqual(
    bankInfo ? bankInfo.BankAccount : initalValueCompare.BankAccount,
    cloned.BankAccount
  );
  const isCardHolder = values.BankAccountHolder.relationship === 'Cardholder';

  const changed = isCardHolder && bankAccounChanged;

  return changed;
};
