import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let departure_date_view;
let arrival_date_view;

export const tripProtectionSchema = intl =>
  Yup.object().shape({
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    trip_information: Yup.object().shape({
      planed_trip: Yup.lazy(value => {
        departure_date_view = value.departure_date;
        arrival_date_view = value.arrival_date;
        return Yup.object().shape({
          departure_date: Yup.date()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID),
          arrival_date: Yup.date()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID),
        });
      }),
      reason: Yup.string().max(255, intl.TEXT_FIELD_VALIDATION_255),
    }),
    incident: Yup.object().shape({
      start_date: Yup.date()
        .test('match', intl.VALIDATE_DELAY_DATE, function (start_date) {
          return (
            moment(start_date).isSameOrAfter(departure_date_view, 'day') &&
            moment(start_date).isSameOrBefore(arrival_date_view, 'day')
          );
        })
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID),
    }),
    other_insurance_covering: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
      currency: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),

    additional_passengers: additionalShema(intl),

    other_insurance_covering2: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
      currency: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),
    total_amount_claimed: Yup.object().shape({
      value: Yup.string()
        .test('>= 0', intl.VALIDATE_NUMBER_VALUE, function (value) {
          return value ? Number(value) >= 0 : false;
        })
        .required(intl.TEXT_FIELD_REQUIRED),
      currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
  });

const additionalShema = intl => {
  const additional = Yup.array().when(['hasAdditional'], {
    is: hasAdditional => {
      return hasAdditional === 'true';
    },
    then: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .max(new Date(), intl.VALIDATE_DATE_VALID)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        nationalities: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
      })
    ),
  });

  return additional;
};
