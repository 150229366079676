import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './IndemnityArea.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IndemityArea = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div
      className={`${styles.IndemityArea}`}
      data-testid="DivTripDelayIndemityArea"
    >
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {intl.INDEMNITY_AMOUNT}
      </label>
    </div>
  );
};

export default IndemityArea;
