export const listCurrencies = [
  {
    initials: 'AUD',
    currencySymbol: '$',
    pt_BR: 'Dólar Australiano',
    es_ES: 'Dólar australiano',
    en_US: 'Australian Dollar',
  },
  {
    initials: 'USD',
    currencySymbol: '$',
    pt_BR: 'Dólar Americano',
    es_ES: 'Dólar de EE.UU.',
    en_US: 'U.S. Dollar',
  },
  {
    initials: 'EUR',
    currencySymbol: '€',
    pt_BR: 'Euro',
    es_ES: 'Euro',
    en_US: 'Euro',
  },
  {
    initials: 'GBP',
    currencySymbol: '£',
    pt_BR: 'Libra Esterlina (Reino Unido)',
    es_ES: 'Libra esterlina',
    en_US: 'British Pound',
  },
  {
    initials: 'CAD',
    currencySymbol: '$',
    pt_BR: 'Dólar Canadense',
    es_ES: 'Dólar canadiense',
    en_US: 'Canadian Dollar',
  },
  {
    initials: 'XCD',
    currencySymbol: '$',
    pt_BR: 'Dólar do Caraíbas Orientais',
    es_ES: 'Dólar del Caribe Oriental',
    en_US: 'East Caribbean Dollar',
  },
  {
    initials: 'ARS',
    currencySymbol: '$',
    pt_BR: 'Peso Argentino',
    es_ES: 'Peso argentino',
    en_US: 'Argentine Peso',
  },
  {
    initials: 'BSD',
    currencySymbol: '$',
    pt_BR: 'Dólar das Bahamas',
    es_ES: 'Dólar de las Bahamas',
    en_US: 'Bahamian Dollar',
  },
  {
    initials: 'BBD',
    currencySymbol: '$',
    pt_BR: 'Dólar de Barbados',
    es_ES: 'Dólar de Barbados',
    en_US: 'Barbados Dollar',
  },
  {
    initials: 'BZD',
    currencySymbol: 'BZ$',
    pt_BR: 'Dólar do Belize',
    es_ES: 'Dólar de Belice',
    en_US: 'Belize Dollar',
  },
  {
    initials: 'BOB',
    currencySymbol: '	$b',
    pt_BR: 'Boliviano (Bolívia)',
    es_ES: 'Boliviano de Bolivia',
    en_US: 'Bolivian Boliviano',
  },
  {
    initials: 'BRL',
    currencySymbol: 'R$',
    pt_BR: 'Real brasileiro',
    es_ES: 'Real brasileño',
    en_US: 'Brazilian Real',
  },
  {
    initials: 'CLP',
    currencySymbol: '$',
    pt_BR: 'Peso Chileno',
    es_ES: 'Peso chileno',
    en_US: 'Chilean Peso',
  },
  {
    initials: 'COP',
    currencySymbol: '$',
    pt_BR: 'Peso Colombiano',
    es_ES: 'Peso colombiano',
    en_US: 'Colombian Peso',
  },
  {
    initials: 'CRC',
    currencySymbol: '₡',
    pt_BR: 'Colón da Costa Rica',
    es_ES: 'Colón costarricense',
    en_US: 'Costa Rica Colon',
  },
  {
    initials: 'CUC',
    currencySymbol: '$',
    pt_BR: 'Cubano Convertible Peso',
    es_ES: 'Peso Convertible Cubano',
    en_US: 'Cuban Convertible Peso',
  },
  {
    initials: 'CUP',
    currencySymbol: '₱',
    pt_BR: 'Peso Cubano',
    es_ES: 'Peso Cubano',
    en_US: 'Cuban Peso',
  },
  {
    initials: 'GTQ',
    currencySymbol: 'Q',
    pt_BR: 'Quetzal Guatemalteco',
    es_ES: 'Quetzal guatemalteco',
    en_US: 'Guatemala Quetzal',
  },
  {
    initials: 'GYD',
    currencySymbol: '$',
    pt_BR: 'Dólar da Guiana',
    es_ES: 'Dólar de Guyana',
    en_US: 'Guyana Dollar',
  },
  {
    initials: 'HTG',
    currencySymbol: 'G',
    pt_BR: 'Gourde Haitiano',
    es_ES: 'Gourde haitiano',
    en_US: 'Haiti Gourde',
  },
  {
    initials: 'HNL',
    currencySymbol: 'L',
    pt_BR: 'Lempira Hondurenha',
    es_ES: 'Lempira hondureño',
    en_US: 'Honduras Lempira',
  },
  {
    initials: 'JMD',
    currencySymbol: 'J$',
    pt_BR: 'Dólar Jamaicano',
    es_ES: 'Dólar de Jamaica',
    en_US: 'Jamaican Dollar',
  },
  {
    initials: 'MXN',
    currencySymbol: '$',
    pt_BR: 'Peso Mexicano',
    es_ES: 'Peso mexicano',
    en_US: 'Mexican Peso',
  },
  {
    initials: 'NIO',
    currencySymbol: 'C$',
    pt_BR: 'Córdoba Nicaragüense',
    es_ES: 'Córdoba nicaragüense',
    en_US: 'Nicaragua Cordoba',
  },
  {
    initials: 'PAB',
    currencySymbol: 'B/.',
    pt_BR: 'Balboa Panamense',
    es_ES: 'Balboa panameño',
    en_US: 'Panama Balboa',
  },
  {
    initials: 'PYG',
    currencySymbol: 'Gs',
    pt_BR: 'Guarani Paraguaio',
    es_ES: 'Guaraní paraguayo',
    en_US: 'Paraguayan Guarani',
  },
  {
    initials: 'PEN',
    currencySymbol: 'S/.',
    pt_BR: 'Novo Sol Peruano',
    es_ES: 'Nuevo sol peruano',
    en_US: 'Peruvian Nuevo Sol',
  },
  {
    initials: 'DOP',
    currencySymbol: 'RD$',
    pt_BR: 'Peso Dominicano',
    es_ES: 'Peso dominicano',
    en_US: 'Dominican Peso',
  },
  {
    initials: 'SRD',
    currencySymbol: '$',
    pt_BR: 'Dólar do Suriname',
    es_ES: 'Dólar de Surinam',
    en_US: 'Surinam Dollar',
  },
  {
    initials: 'TTD',
    currencySymbol: 'TT$',
    pt_BR: 'Dólar de Trinidad e Tobago',
    es_ES: 'Dólar de Trinidad y Tobago',
    en_US: 'Trinidad&Tobago Dollar',
  },
  {
    initials: 'UYU',
    currencySymbol: '$U',
    pt_BR: 'Peso Uruguaio',
    es_ES: 'Peso uruguayo',
    en_US: 'Uruguayan Peso',
  },
  {
    initials: 'VEF',
    currencySymbol: 'Bs',
    pt_BR: 'Venezuelan Bolivar Fuerte',
    es_ES: 'Bolívar venezolano fuerte',
    en_US: 'Venezuelan Bolivar Fuerte',
  },
  {
    initials: 'AFN',
    currencySymbol: '؋',
    pt_BR: 'Afghani Afegão (Nova)',
    es_ES: 'Afgani de Afganistán (nuevo)',
    en_US: 'Afghanistan Afghani (New)',
  },
  {
    initials: 'ALL',
    currencySymbol: 'Lek',
    pt_BR: 'Lek Albanês',
    es_ES: 'Lek albanés',
    en_US: 'Albanian Lek',
  },
  {
    initials: 'DZD',
    currencySymbol: 'د.ج',
    pt_BR: 'Dinar Argelino',
    es_ES: 'Dinar argelino',
    en_US: 'Algerian Dinar',
  },
  {
    initials: 'AOA',
    currencySymbol: 'Kz',
    pt_BR: 'Kwanza Angolano',
    es_ES: 'Kwanza angoleño',
    en_US: 'Angola Kwanza',
  },
  {
    initials: 'AMD',
    currencySymbol: '֏',
    pt_BR: 'Dram Armênio',
    es_ES: 'Dram armenio',
    en_US: 'Armenian Dram',
  },
  {
    initials: 'AWG',
    currencySymbol: 'ƒ',
    pt_BR: 'Florim de Aruba',
    es_ES: 'Florín de Aruba',
    en_US: 'Aruba Florin',
  },
  {
    initials: 'AZN',
    currencySymbol: '$',
    pt_BR: 'Novo Manat Azerbaijanês',
    es_ES: 'Nuevo manat azerbaiyano',
    en_US: 'Azerbaijanian New Manat',
  },
  {
    initials: 'BHD',
    currencySymbol: 'BD',
    pt_BR: 'Dinar do Barém',
    es_ES: 'Dinar bahriní',
    en_US: 'Bahraini Dinar',
  },
  {
    initials: 'BDT',
    currencySymbol: '৳',
    pt_BR: 'Taka Bangladechiano',
    es_ES: 'Taka de Bangladesh',
    en_US: 'Bangladesh Taka',
  },
  {
    initials: 'BYR',
    currencySymbol: 'Br',
    pt_BR: 'Rublo Bielorrusso',
    es_ES: 'Rublo bielorruso',
    en_US: 'Belarussian Ruble',
  },
  {
    initials: 'BMD',
    currencySymbol: '$',
    pt_BR: 'Dólar das Bermudas',
    es_ES: 'Dólar de Bermudas',
    en_US: 'Bermuda Dollar',
  },
  {
    initials: 'BTN',
    currencySymbol: 'Nu',
    pt_BR: 'Ngultrum Butanês',
    es_ES: 'Ngultrum butanés',
    en_US: 'Bhutan Ngultrum',
  },
  {
    initials: 'BOV',
    currencySymbol: 'Bs',
    pt_BR: 'Mvdol Boliviano',
    es_ES: 'Mvdol boliviano',
    en_US: 'Bolivia Mvdol',
  },
  {
    initials: 'BWP',
    currencySymbol: 'P',
    pt_BR: 'Pula Botsuano',
    es_ES: 'Pula botsuanés',
    en_US: 'Botswana Pula',
  },
  {
    initials: 'BND',
    currencySymbol: '$',
    pt_BR: 'Dólar do Brunei',
    es_ES: 'Dólar de Brunéi',
    en_US: 'Brunei Dollar',
  },
  {
    initials: 'BGN',
    currencySymbol: 'лв',
    pt_BR: 'Lev búlgaro',
    es_ES: 'Lev búlgaro',
    en_US: 'Bulgarian Lev',
  },
  {
    initials: 'BIF',
    currencySymbol: 'FBu',
    pt_BR: 'Franco do Burundi',
    es_ES: 'Franco de Burundi',
    en_US: 'Burundi Franc',
  },
  {
    initials: 'KHR',
    currencySymbol: '	៛',
    pt_BR: 'Riel Cambojano',
    es_ES: 'Riel camboyano',
    en_US: 'Cambodia Riel',
  },
  {
    initials: 'CVE',
    currencySymbol: '$',
    pt_BR: 'Escudo de Cabo Verde',
    es_ES: 'Escudo de Cabo Verde',
    en_US: 'Cape Verde Escudo',
  },
  {
    initials: 'KYD',
    currencySymbol: '$',
    pt_BR: 'Dólar das Ilhas Caimão',
    es_ES: 'Dólar de las Islas Caimán',
    en_US: 'Cayman Islands Dollar',
  },
  {
    initials: 'XOF',
    currencySymbol: 'CFA',
    pt_BR: 'Franco CFA (BCEAO)',
    es_ES: 'Franco CFA (BCEAO)',
    en_US: 'CFA Franc (BCEAO)',
  },
  {
    initials: 'XAF',
    currencySymbol: 'FCFA',
    pt_BR: 'Franco CFA (BEAC)',
    es_ES: 'Franco CFA (BEAC)',
    en_US: 'CFA Franc (BEAC)',
  },
  {
    initials: 'CNY',
    currencySymbol: '¥',
    pt_BR: 'Iuane Renminbi Chinês',
    es_ES: 'Yuan renminbi chino',
    en_US: 'Chinese Yuan',
  },
  {
    initials: 'KMF',
    currencySymbol: 'CF',
    pt_BR: 'Franco de Comores',
    es_ES: 'Franco comorano',
    en_US: 'Comoros Franc',
  },
  {
    initials: 'BAM',
    currencySymbol: 'KM',
    pt_BR: 'Marcos Conversíveis',
    es_ES: 'Marcos convertibles',
    en_US: 'Convertible Marks',
  },
  {
    initials: 'CZK',
    currencySymbol: 'Kč',
    pt_BR: 'Coroa Tcheca',
    es_ES: 'Corona checa',
    en_US: 'Czech Koruna',
  },
  {
    initials: 'DKK',
    currencySymbol: 'kr',
    pt_BR: 'Coroa Dinamarquesa',
    es_ES: 'Corona danesa',
    en_US: 'Danish Krone',
  },
  {
    initials: 'DJF',
    currencySymbol: 'Fdj',
    pt_BR: 'Franco Djibuti',
    es_ES: 'Franco de Yibuti',
    en_US: 'Dijibouti Franc',
  },
  {
    initials: 'EGP',
    currencySymbol: '£',
    pt_BR: 'Libra Egípcia',
    es_ES: 'Libra egipcia',
    en_US: 'Egyptian Pound',
  },
  {
    initials: 'ERN',
    currencySymbol: 'Nfk',
    pt_BR: 'Nakfa Eritréia',
    es_ES: 'Nakfa eritreo',
    en_US: 'Eritrea Nakfa',
  },
  {
    initials: 'EEK',
    currencySymbol: 'ETB',
    pt_BR: 'Coroa Estoniana',
    es_ES: 'Corona estonia',
    en_US: 'Estonian Kroon',
  },
  {
    initials: 'ETB',
    currencySymbol: 'ETB',
    pt_BR: 'Birr Etíope',
    es_ES: 'Birr etíope',
    en_US: 'Ethiopian Birr',
  },
  {
    initials: 'FKP',
    currencySymbol: '£',
    pt_BR: 'Libra Falklandesa',
    es_ES: 'Libra de las Islas Malvinas',
    en_US: 'Falkland Islands Pound',
  },
  {
    initials: 'FJD',
    currencySymbol: '$',
    pt_BR: 'Dólar das Ilhas Fiji',
    es_ES: 'Dólar de Fiji',
    en_US: 'Fiji Dollar',
  },
  {
    initials: 'CDF',
    currencySymbol: 'FC',
    pt_BR: 'Franco Congolês',
    es_ES: 'Franco congoleño',
    en_US: 'Franc Congolais',
  },
  {
    initials: 'GMD',
    currencySymbol: 'D',
    pt_BR: 'Dalasi Gambiano',
    es_ES: 'Dalasi gambiano',
    en_US: 'Gambian Dalasi',
  },
  {
    initials: 'GEL',
    currencySymbol: 'ლ',
    pt_BR: 'Lari Georgiano',
    es_ES: 'Lari georgiano',
    en_US: 'Georgia Lari',
  },
  {
    initials: 'GHS',
    currencySymbol: '¢',
    pt_BR: 'Cedi Ganês',
    es_ES: 'Cedi ghanés',
    en_US: 'Ghanaian Cedi',
  },
  {
    initials: 'GIP',
    currencySymbol: '£',
    pt_BR: 'Libra Gibraltina',
    es_ES: 'Libra de Gibraltar',
    en_US: 'Gibraltar Pound',
  },
  {
    initials: 'GNF',
    currencySymbol: 'FG',
    pt_BR: 'Franco Guineense',
    es_ES: 'Franco guineo',
    en_US: 'Guinea Franc',
  },
  {
    initials: 'HKD',
    currencySymbol: '$',
    pt_BR: 'Dólar de Hong Kong',
    es_ES: 'Dólar de Hong Kong',
    en_US: 'Hong Kong Dollar',
  },
  {
    initials: 'HUF',
    currencySymbol: 'Ft',
    pt_BR: 'Forinte Húngaro',
    es_ES: 'Forint húngaro',
    en_US: 'Hungarian Forint',
  },
  {
    initials: 'ISK',
    currencySymbol: 'kr',
    pt_BR: 'Coroa Islandesa',
    es_ES: 'Corona islandesa',
    en_US: 'Iceland Krona',
  },
  {
    initials: 'INR',
    currencySymbol: '₹',
    pt_BR: 'Rúpia Indiana',
    es_ES: 'Rupia india',
    en_US: 'Indian Rupee',
  },
  {
    initials: 'IDR',
    currencySymbol: 'Rp',
    pt_BR: 'Rúpia Indonésia',
    es_ES: 'Rupia indonesia',
    en_US: 'Indonesian Rupiah',
  },
  {
    initials: 'IQD',
    currencySymbol: 'ع.د',
    pt_BR: 'Dinar Iraquiano',
    es_ES: 'Dinar iraquí',
    en_US: 'Iraqi Dinar',
  },
  {
    initials: 'ILS',
    currencySymbol: '₪',
    pt_BR: 'Novo Shekel Israelita',
    es_ES: 'Nuevo shekel israelí',
    en_US: 'Israeli Shekel',
  },
  {
    initials: 'JPY',
    currencySymbol: '¥',
    pt_BR: 'Iene Japonês',
    es_ES: 'Yen japonés',
    en_US: 'Japanese Yen',
  },
  {
    initials: 'JOD',
    currencySymbol: 'د.أ',
    pt_BR: 'Dinar Jordano',
    es_ES: 'Dinar jordano',
    en_US: 'Jordanian Dinar',
  },
  {
    initials: 'KZT',
    currencySymbol: 'лв',
    pt_BR: 'Tenge Cazaquistanês',
    es_ES: 'Tenge kazajo',
    en_US: 'Kazakhstan Tenge',
  },
  {
    initials: 'KES',
    currencySymbol: 'KSh',
    pt_BR: 'Xelim Queniano',
    es_ES: 'Chelín keniano',
    en_US: 'Kenyan Shilling',
  },
  {
    initials: 'KRW',
    currencySymbol: '₩',
    pt_BR: 'Won Coreano',
    es_ES: 'Won coreano',
    en_US: 'Korean Won',
  },
  {
    initials: 'HRK',
    currencySymbol: 'kn',
    pt_BR: 'Kuna',
    es_ES: 'Kuna',
    en_US: 'Kuna',
  },
  {
    initials: 'KWD',
    currencySymbol: 'د.ك',
    pt_BR: 'Dinar Kuwaitiano',
    es_ES: 'Dinar kuwaití',
    en_US: 'Kuwaiti Dinar',
  },
  {
    initials: 'KGS',
    currencySymbol: 'лв',
    pt_BR: 'Som Quirguizistanês',
    es_ES: 'Som kirguizo',
    en_US: 'Kyrgyzstan Som',
  },
  {
    initials: 'LAK',
    currencySymbol: '₭',
    pt_BR: 'Kip Laociano',
    es_ES: 'Kip laosiano',
    en_US: 'Lao Kip',
  },
  {
    initials: 'LVL',
    currencySymbol: '€',
    pt_BR: 'Lat Letoniano',
    es_ES: 'Lats letón',
    en_US: 'Latvian Lat',
  },
  {
    initials: 'LBP',
    currencySymbol: '£',
    pt_BR: 'Libra Libanesa',
    es_ES: 'Libra libanesa',
    en_US: 'Lebanese Pound',
  },
  {
    initials: 'LSL',
    currencySymbol: 'M',
    pt_BR: 'Loti Lesotiano',
    es_ES: 'Loti de Lesoto',
    en_US: 'Lesotho Loti',
  },
  {
    initials: 'LRD',
    currencySymbol: '$',
    pt_BR: 'Dólar Liberiano',
    es_ES: 'Dólar liberiano',
    en_US: 'Liberian Dollar',
  },
  {
    initials: 'LYD',
    currencySymbol: 'LD',
    pt_BR: 'Dinar Líbio',
    es_ES: 'Dinar libio',
    en_US: 'Libyan Dinar',
  },
  {
    initials: 'LTL',
    currencySymbol: '€',
    pt_BR: 'Lita Lituana',
    es_ES: 'Litas lituano',
    en_US: 'Lithuanian Lita',
  },
  {
    initials: 'MOP',
    currencySymbol: '$',
    pt_BR: 'Pataca Macaense',
    es_ES: 'Pataca de Macao',
    en_US: 'Macau Pataca',
  },
  {
    initials: 'MKD',
    currencySymbol: 'ден',
    pt_BR: 'Denar Macedônio',
    es_ES: 'Denar macedonio',
    en_US: 'Macedonian Denar',
  },
  {
    initials: 'MGA',
    currencySymbol: 'Ar',
    pt_BR: 'Ariary Malgaxe',
    es_ES: 'Ariary malgache',
    en_US: 'Malagasy Ariary',
  },
  {
    initials: 'MWK',
    currencySymbol: 'MK',
    pt_BR: 'Kwacha do Malauí',
    es_ES: 'Kwacha de Malawi',
    en_US: 'Malawi Kwacha',
  },
  {
    initials: 'MYR',
    currencySymbol: 'RM',
    pt_BR: 'Ringgit Malaio',
    es_ES: 'Ringgit malasio',
    en_US: 'Malaysian Ringgit',
  },
  {
    initials: 'MVR',
    currencySymbol: 'Rf',
    pt_BR: 'Rúpia das Maldivas',
    es_ES: 'Rupia de Maldivas',
    en_US: 'Maldives Rufiyaa',
  },
  {
    initials: 'MRO',
    currencySymbol: 'UM',
    pt_BR: 'Ougulya Mauritano',
    es_ES: 'Ouguiya mauritano',
    en_US: 'Mauritania Ougulya',
  },
  {
    initials: 'MUR',
    currencySymbol: '₨',
    pt_BR: 'Rúpia da Maurícia',
    es_ES: 'Rupia mauriciana',
    en_US: 'Mauritius Rupee',
  },
  {
    initials: 'MXV',
    currencySymbol: '$',
    pt_BR: 'Unidade de Conversão (UDI) Mexicana',
    es_ES: 'Unidad de Inversión (UDI) mexicana',
    en_US: 'Mexican Unidad de Inversion (UDI)',
  },
  {
    initials: 'MDL',
    currencySymbol: 'MDL',
    pt_BR: 'Leu Moldavo',
    es_ES: 'Leu moldavo',
    en_US: 'Moldovan Leu',
  },
  {
    initials: 'MNT',
    currencySymbol: '	₮',
    pt_BR: 'Tugrik Mongol',
    es_ES: 'Tugrik mongol',
    en_US: 'Mongolian Tugrik',
  },
  {
    initials: 'MAD',
    currencySymbol: 'DH',
    pt_BR: 'Dirham Marroquino',
    es_ES: 'Dirham marroquí',
    en_US: 'Moroccan Dirham',
  },
  {
    initials: 'MZN',
    currencySymbol: 'MT',
    pt_BR: 'Novo Metical Moçambicano',
    es_ES: 'Nuevo metical mozambiqueño',
    en_US: 'Mozambique New Metical',
  },
  {
    initials: 'MMK',
    currencySymbol: 'Ks',
    pt_BR: 'Kyat de Myanmar',
    es_ES: 'Kyat de Myanmar',
    en_US: 'Myanmar Kyat',
  },
  {
    initials: 'NAD',
    currencySymbol: '$',
    pt_BR: 'Dólar da Namíbia',
    es_ES: 'Dólar namibio',
    en_US: 'Namibian Dollar',
  },
  {
    initials: 'NPR',
    currencySymbol: '₨',
    pt_BR: 'Rúpia Nepalesa',
    es_ES: 'Rupia nepalesa',
    en_US: 'Nepalese Rupee',
  },
  {
    initials: 'ANG',
    currencySymbol: 'NAƒ',
    pt_BR: 'Florim das Antilhas Neerlandesas',
    es_ES: 'Florín de las Antillas Neerlandesas',
    en_US: 'Neth Antilles Guilder',
  },
  {
    initials: 'NZD',
    currencySymbol: '$',
    pt_BR: 'Dólar Neozelandês',
    es_ES: 'Dólar neozelandés',
    en_US: 'New Zealand Dollar',
  },
  {
    initials: 'NGN',
    currencySymbol: '₦',
    pt_BR: 'Naira Nigeriana',
    es_ES: 'Naira nigeriano',
    en_US: 'Nigerian Naira',
  },
  {
    initials: 'NOK',
    currencySymbol: 'kr',
    pt_BR: 'Coroa Norueguesa',
    es_ES: 'Corona noruega',
    en_US: 'Norwegian Krone',
  },
  {
    initials: 'OMR',
    currencySymbol: '﷼',
    pt_BR: 'Rial de Omã',
    es_ES: 'Rial omaní',
    en_US: 'Omani Rial',
  },
  {
    initials: 'XPF',
    currencySymbol: 'XPF',
    pt_BR: 'Franco do Pacífico',
    es_ES: 'Franco CFP',
    en_US: 'Pacific Franc',
  },
  {
    initials: 'PKR',
    currencySymbol: '₨',
    pt_BR: 'Rúpia Paquistanesa',
    es_ES: 'Rupia paquistaní',
    en_US: 'Pakistani Rupee',
  },
  {
    initials: 'PGK',
    currencySymbol: 't',
    pt_BR: 'Kina Papua',
    es_ES: 'Kina papú',
    en_US: 'Papua New Guinea Kina',
  },
  {
    initials: 'PHP',
    currencySymbol: '₱',
    pt_BR: 'Peso Filipino',
    es_ES: 'Peso filipino',
    en_US: 'Philippine Peso',
  },
  {
    initials: 'PLN',
    currencySymbol: 'zł',
    pt_BR: 'Zloti Polaco',
    es_ES: 'Zloty polaco',
    en_US: 'Polish Zloty',
  },
  {
    initials: 'QAR',
    currencySymbol: '﷼',
    pt_BR: 'Rial do Catar',
    es_ES: 'Rial de Qatar',
    en_US: 'Qatar Rial',
  },
  {
    initials: 'RON',
    currencySymbol: 'lei',
    pt_BR: 'Leu Romeno',
    es_ES: 'Leu rumano',
    en_US: 'Romanian Leu',
  },
  {
    initials: 'RUB',
    currencySymbol: '₽',
    pt_BR: 'Rublo Russo',
    es_ES: 'Rublo ruso',
    en_US: 'Russian Rouble',
  },
  {
    initials: 'RWF',
    currencySymbol: 'R₣',
    pt_BR: 'Franco Ruandês',
    es_ES: 'Franco ruandés',
    en_US: 'Rwanda Franc',
  },
  {
    initials: 'WST',
    currencySymbol: 'WS$',
    pt_BR: 'Tala Samoana',
    es_ES: 'Tala de Samoa',
    en_US: 'Samoa Tala',
  },
  {
    initials: 'STD',
    currencySymbol: 'Db',
    pt_BR: 'Dobra de São Tomé',
    es_ES: 'Dobra de Santo Tomé',
    en_US: 'Sao Tome Dobra',
  },
  {
    initials: 'SAR',
    currencySymbol: '﷼',
    pt_BR: 'Rial Saudita',
    es_ES: 'Rial saudí',
    en_US: 'Saudi Arabian Riyal',
  },
  {
    initials: 'RSD',
    currencySymbol: '	Дин.',
    pt_BR: 'Dinar Sérvio',
    es_ES: 'Dinar serbio',
    en_US: 'Serbian Dinar',
  },
  {
    initials: 'SCR',
    currencySymbol: '₨',
    pt_BR: 'Rúpia das Seicheles',
    es_ES: 'Rupia de las Seyshelles',
    en_US: 'Seychelles Rupee',
  },
  {
    initials: 'SLL',
    currencySymbol: 'Le',
    pt_BR: 'Leone Serra-Leonês',
    es_ES: 'Leone sierraleonés',
    en_US: 'Sierra Leone Leone',
  },
  {
    initials: 'SGD',
    currencySymbol: '$',
    pt_BR: 'Dólar de Cingapura',
    es_ES: 'Dólar singapurense',
    en_US: 'Singapore Dollar',
  },
  {
    initials: 'SBD',
    currencySymbol: '$',
    pt_BR: 'Dólar das Ilhas Salomão',
    es_ES: 'Dólar de Islas Salomón',
    en_US: 'Solomon Islands Dollar',
  },
  {
    initials: 'SOS',
    currencySymbol: 'Sh.So.',
    pt_BR: 'Xelim Somaliano',
    es_ES: 'Chelín somalí',
    en_US: 'Somali Shillingr',
  },
  {
    initials: 'ZAR',
    currencySymbol: 'R',
    pt_BR: 'Rand Sul-Africano',
    es_ES: 'Rand sudafricano',
    en_US: 'South African Rand',
  },
  {
    initials: 'LKR',
    currencySymbol: '₨',
    pt_BR: 'Rúpia Indiana',
    es_ES: 'Rupia de Sri Lanka',
    en_US: 'Sri Lanka Rupee',
  },
  {
    initials: 'SHP',
    currencySymbol: '£',
    pt_BR: 'Libra de Santa Helena',
    es_ES: 'Libra de Santa Helena',
    en_US: 'St Helena Pound',
  },
  {
    initials: 'SDG',
    currencySymbol: 'SD',
    pt_BR: 'Libra Sudanesa',
    es_ES: 'Libra sudanesa',
    en_US: 'Sudanese Pound',
  },
  {
    initials: 'SZL',
    currencySymbol: 'E',
    pt_BR: 'Lilangeni Suazilandês',
    es_ES: 'Lilangeni suazilandés',
    en_US: 'Swaziland Lilageni',
  },
  {
    initials: 'SEK',
    currencySymbol: 'kr',
    pt_BR: 'Coroa Sueca',
    es_ES: 'Corona sueca',
    en_US: 'Swedish Krona',
  },
  {
    initials: 'CHF',
    currencySymbol: 'Fr',
    pt_BR: 'Franco Suíço',
    es_ES: 'Franco suizo',
    en_US: 'Swiss Franc',
  },
  {
    initials: 'TWD',
    currencySymbol: 'NT$',
    pt_BR: 'Novo Dólar de Taiwan',
    es_ES: 'Dólar de Taiwán',
    en_US: 'Taiwan Dollar',
  },
  {
    initials: 'TJS',
    currencySymbol: 'SM',
    pt_BR: 'Somoni Tajiquistanês',
    es_ES: 'Somoni tayiko',
    en_US: 'Tajik Somoni',
  },
  {
    initials: 'TZS',
    currencySymbol: 'SM',
    pt_BR: 'Xelim Tanzaniano',
    es_ES: 'Chelín tanzano',
    en_US: 'Tanzanian Shilling',
  },
  {
    initials: 'THB',
    currencySymbol: '฿',
    pt_BR: 'Baht Tailandês',
    es_ES: 'Baht tailandés',
    en_US: 'Thai Baht',
  },
  {
    initials: 'TOP',
    currencySymbol: 'T$',
    pt_BR: "Pa'anga Tonganês",
    es_ES: "Pa'anga tongano",
    en_US: "Tonga Pa'anga",
  },
  {
    initials: 'TND',
    currencySymbol: 'د.ت',
    pt_BR: 'Dinar Tunisino',
    es_ES: 'Dinar tunecino',
    en_US: 'Tunisian Dinar',
  },
  {
    initials: 'TRY',
    currencySymbol: '₺',
    pt_BR: 'Lira Turca (Nova)',
    es_ES: 'Lira turca (Nueva)',
    en_US: 'Turkish Lira (New)',
  },
  {
    initials: 'TMT',
    currencySymbol: 'T',
    pt_BR: 'Novo Manat Turcomenistanês',
    es_ES: 'Nuevo manat turkmeno',
    en_US: 'Turkmenistan New Manat',
  },
  {
    initials: 'AED',
    currencySymbol: 'د.إ',
    pt_BR: 'Dirham EAU (Emirados Árabes Unidos)',
    es_ES: 'Dirham de los Emiratos Árabes Unidos',
    en_US: 'UAE Dirham',
  },
  {
    initials: 'UGX',
    currencySymbol: 'USh',
    pt_BR: 'Xelim Ugandês',
    es_ES: 'Chelín ugandés',
    en_US: 'Ugandan Shilling',
  },
  {
    initials: 'UAH',
    currencySymbol: '₴',
    pt_BR: 'Hryvnia Ucraniano',
    es_ES: 'Jrivnia ucraniano',
    en_US: 'Ukraine Hryvnia',
  },
  {
    initials: 'UZS',
    currencySymbol: 'лв',
    pt_BR: 'Sum Usbequistanês',
    es_ES: 'Sum uzbeko',
    en_US: 'Uzbekistan Sum',
  },
  {
    initials: 'VUV',
    currencySymbol: 'VT',
    pt_BR: 'Vatu Vanuatano',
    es_ES: 'Vatu de Vanuatu',
    en_US: 'Vanuatu Vatu',
  },
  {
    initials: 'VND',
    currencySymbol: '₫',
    pt_BR: 'Dong Vietnamita',
    es_ES: 'Dong vietnamita',
    en_US: 'Vietnam Dong',
  },
  {
    initials: 'YER',
    currencySymbol: '﷼',
    pt_BR: 'Rial Iemenita',
    es_ES: 'Rial yemení',
    en_US: 'Yemen Riyal',
  },
  {
    initials: 'ZMW',
    currencySymbol: 'ZK',
    pt_BR: 'Kwacha zambiano (novo)',
    es_ES: 'Kwacha de Zambia (Nuevo)',
    en_US: 'Zambian Kwacha (New)',
  },
  {
    initials: 'ZWL',
    currencySymbol: 'Z$',
    pt_BR: 'Dólar do Zimbabué',
    es_ES: 'Dólar de Zimbabue',
    en_US: 'Zimbabwe Dollar',
  },
];
