import React, { useContext, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import * as constants from './consts';
import ErrorFocus from '../../../components/ErrorFocus/ErrorFocus';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import Step1 from './step1/Step1';
import Step2 from './step2/Step2';
import Step3 from './step3/Step3';
import { pt_BR } from '../../../intl/idioms';
import { cardsServices, userServices } from '../../../services';

export const _onSubmit = (idiom, values, actions, props, alert) => {
  props.onSubmit(idiom, values, actions, props.history, alert);
};

const Form = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions, state } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { user } = state;
  const [stepCard, setStepCard] = useState('P');

  const {
    setStep,
    currentStep: step,
    chInfo,
    existingUser,
    setExistingUser,
    submitButtonRef,
    isUserPartner,
  } = props;
  const userInfo = user.data.userInfo && user.data.userInfo;

  const validationSchema = useMemo(
    () => constants.validationSchema(intl, step, stepCard),
    [intl, step, stepCard]
  );

  const onSubmit = (values, formActions) => {
    props.onSubmit(
      idiom,
      values,
      formActions,
      props.history,
      actions,
      userInfo.cn,
      intl,
      existingUser,
      setExistingUser,
      submitButtonRef,
      trackEventUserAction,
      userServices,
      cardsServices,
      isUserPartner,
      stepCard
    );
  };
  const step1 = step === 1;
  const step2 = step === 2;
  const step3 = step === 3;

  return (
    <Formik
      initialValues={constants.initialValues(userInfo, chInfo, idiom)}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {props => {
        const { handleSubmit, setTouched, setErrors, validateForm } = props;

        const submit = async event => {
          event.preventDefault();
          setErrors({});
          const execValidate = await validateForm();
          if (Object.keys(execValidate).length === 0) {
            await handleSubmit();
          } else {
            setTouched({
              creditCard: true,
              effective_end_date: true,
            });
          }
        };

        const nextStep = async (event, index) => {
          event.preventDefault();
          trackEventUserAction(
            `#### (REGISTRATION) COMPLETE REGISTRATION - CLICK ON NEXT STEP - ${index}`,
            null
          );
          const execValidate = await validateForm();
          if (Object.keys(execValidate).length === 0) {
            setStep(index);
          } else {
            const isBrasil = idiom === pt_BR;
            if (isBrasil) {
              setTouched({
                title: true,
                name: true,
                lastName: true,
                birth_date: true,
                phone_type: true,
                international_prefix: true,
                number: true,
                nationalities: true,
                person_registrations: {
                  value: true,
                },
              });
            } else {
              setTouched({
                title: true,
                name: true,
                lastName: true,
                birth_date: true,
                phone_type: true,
                international_prefix: true,
                number: true,
                nationalities: true,
                person_registrations: {
                  value: true,
                  registration_type: true,
                },
              });
            }
          }
        };

        const backStep = async (event, index) => {
          event.preventDefault();
          trackEventUserAction(
            `#### (REGISTRATION) COMPLETE REGISTRATION - CLICK ON BACK STEP - ${index}`,
            null
          );
          setStep(index);
        };

        return (
          <form onSubmit={submit}>
            {step1 && <Step1 intl={intl} nextStep={nextStep} {...props} />}

            {step2 && (
              <Step2
                intl={intl}
                idiom={idiom}
                nextStep={nextStep}
                backStep={backStep}
                {...props}
              />
            )}

            {step3 && (
              <Step3
                isUserPartner={isUserPartner}
                idiom={idiom}
                backStep={backStep}
                {...props}
                submitButtonRef={submitButtonRef}
                setStepCard={setStepCard}
                stepCard={stepCard}
              />
            )}
            <ErrorFocus />
          </form>
        );
      }}
    </Formik>
  );
};

export default withRouter(Form);
