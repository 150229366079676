import React, { useContext } from 'react';
import styles from '../../../IncidentArea.module.scss';
import RadioButton from '../../../../../../../../../../../components/RadioButton/RadioButton';
import * as translations from '../../../intl';
import { IntlContext } from '../../../../../../../../../../../intl';
import * as constants from '../../../../consts';
import { ThemeContext } from '../../../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import Select from '../../../../../../../../../../../components/Select/Select';

const ItemStatusArea = props => {
  const { index, handleChange, values, setFieldValue } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const setDamegeToNo = index => {
    setFieldValue(`purchase_protection_items[${index}].is_repairable`, false);
    setFieldValue(
      `purchase_protection_items[${index}].is_the_damage_visible`,
      false
    );
  };

  return (
    <>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className={`${styles.elementRadio} ${styles[themes]} row`}>
              <div className={`col-lg-12 col-sm-12`}>
                <label className={`${styles.labelTitle} ${styles[themes]}`}>
                  {intl.LABEL_TYPE_OF_LOSS}
                </label>
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_TYPE_OF_LOSS}
                  defaultChecked={true}
                  text={intl.LABEL_DAMAGE}
                  name={`purchase_protection_items[${index}].status`}
                  dataTestid="InputPurchaseProtectionDamage"
                  value={constants.DAMAGE_VALUE}
                  onChange={e => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_TYPE_OF_LOSS}
                  defaultChecked={false}
                  text={intl.LABEL_THEFT}
                  name={`purchase_protection_items[${index}].status`}
                  dataTestid="InputPurchaseProtectionTheft"
                  value={constants.THEFT_VALUE}
                  onChange={e => {
                    setDamegeToNo(index);
                    handleChange(e);
                  }}
                />
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_TYPE_OF_LOSS}
                  defaultChecked={false}
                  text={intl.LABEL_NOT_DELIVERED}
                  name={`purchase_protection_items[${index}].status`}
                  dataTestid="InputPurchaseProtectionNotDelivered"
                  value={constants.NOT_DELIVERED}
                  onChange={e => {
                    setDamegeToNo(index);
                    handleChange(e);
                  }}
                />
              </div>
              <div className={`${styles.option} col-12`}>
                <RadioButton
                  label={intl.LABEL_TYPE_OF_LOSS}
                  defaultChecked={false}
                  text={intl.LABEL_DAMEGE_DELIVERY}
                  name={`purchase_protection_items[${index}].status`}
                  dataTestid="InputPurchaseProtectionDamageDelivery"
                  value={constants.DAMAGE_DELIVERY}
                  onChange={e => {
                    setDamegeToNo(index);
                    handleChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: '12px' }}
        role="contentOtherOptions"
        className={`col-12 ${
          values.purchase_protection_items[index].status !==
          constants.DAMAGE_VALUE
            ? styles.none
            : ''
        }`}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Select
              label={intl.LABEL_DAMEGE_REPAIRED}
              name={`purchase_protection_items[${index}].is_repairable`}
              formikProps={props}
            >
              <option value={true}>{intl.TEXT_YES}</option>
              <option value={false}>{intl.TEXT_NO}</option>
            </Select>
          </div>
          <div className="col-12 col-md-6">
            <Select
              label={intl.LABEL_DAMAGE_VISIBLE}
              name={`purchase_protection_items[${index}].is_the_damage_visible`}
              formikProps={props}
            >
              <option value={true}>{intl.TEXT_YES}</option>
              <option value={false}>{intl.TEXT_NO}</option>
            </Select>
          </div>
        </div>
      </div>

      <div
        role="contentQuestionFields"
        className={`row col-12 ${
          values.purchase_protection_items[index].status !==
            constants.NOT_DELIVERED &&
          values.purchase_protection_items[index].status !==
            constants.DAMAGE_DELIVERY
            ? styles.none
            : ''
        }`}
      ></div>
    </>
  );
};
export default ItemStatusArea;
