import React from 'react';
import Support from './Support/Support';
import FrequentlyQuestions from './FrequentlyQuestions/FrequentlyQuestions';

const Help = () => {
  return (
    <>
      <Support />
      <FrequentlyQuestions />
    </>
  );
};

export default Help;
