export const TXT_TALK_TO_SOFIA = {
  en_US: 'Talk to Sofia',
  es_ES: 'Habla con Sofía',
  pt_BR: 'Falar com a Sofia',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const TEXT_FIRST_NAME = {
  en_US: 'First Name',
  pt_BR: 'Primeiro Nome',
  es_ES: 'Nombre',
};

export const TEXT_LAST_NAME = {
  en_US: 'Last Name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellidos',
};

export const TEXT_EMAIL = {
  en_US: 'Email',
  pt_BR: 'Email',
  es_ES: 'Correo electrónico',
};

export const TEXT_LANGUAGE = {
  en_US: 'Language',
  pt_BR: 'Idioma',
  es_ES: 'Idioma',
};

export const ALERT_TEXT_CHATBOT = {
  en_US: cardholder =>
    `Hello${
      cardholder && cardholder.firstName
        ? `, <strong>${cardholder.firstName}</strong>`
        : ''
    }. If you need anything, I'm here.`,
  es_ES: cardholder =>
    `Hola${
      cardholder && cardholder.firstName
        ? `, <strong>${cardholder.firstName}</strong>`
        : ''
    }. Si necesitas algo, estoy aquí.`,
  pt_BR: cardholder =>
    `Olá${
      cardholder && cardholder.firstName
        ? `, <strong>${cardholder.firstName}</strong>`
        : ''
    }. Se você precisar eu estou aqui.`,
};

export const BUTTON_CLOSE = {
  en_US: 'Close Chat',
  pt_BR: 'Fechar Chat',
  es_ES: 'Cerrar Chat',
};
