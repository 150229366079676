import React, { useContext } from 'react';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../intl';
import styles from './PurchaseSummary.module.scss';
import * as translations from './intl';
import moment from 'moment';
import { en_US } from '../../../../../intl/idioms';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  getAmountDecimal,
  getAmountInteger,
} from '../../../../../utils/B2C/currencyISOCode';

const PurchaseSummary = () => {
  const { utils } = useContext(B2B2CContext);
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const selectedPlan = utils.getSelectedPlan();
  const quote = utils.getQuote();
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const { Annual, ValidityDays } = selectedPlan;

  const initialVigencyDate = moment(new Date());
  const endVigencyDate = Annual
    ? moment(new Date()).add(1, 'year')
    : moment(new Date()).add(ValidityDays, 'days');

  const { numberTraleversInput, AmountCurrencyISOCode } = quote.Quote;

  const formatDate = date =>
    moment(date).format(idiom === en_US ? 'MM/DD/YYYY' : 'DD/MM/YYYY');

  return (
    <div className={styles.container}>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className={`row ${styles.benefitArea}`}>
            {selectedPlan.BenefitVISA.map(benefit => (
              <div
                className={`col-12 ${styles.benefit} ${styles[theme]}`}
                key={benefit.BenefitCode}
              >
                <InfoLabelValue
                  label={benefit.Benefit.Description}
                  value={benefit.Benefit.Detail}
                  axa
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="row">
            <div className="col-12">
              <InfoLabelValue
                label={intl.VALIDITY}
                value={`${formatDate(initialVigencyDate)} - ${formatDate(
                  endVigencyDate
                )}`}
                axa
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <InfoLabelValue
                label={intl.PASSENGERS_NUMBER}
                value={numberTraleversInput}
                axa
              />
            </div>
          </div>
          <div className={`row ${styles.amountArea}`}>
            <div className={`col-12`}>
              <span className={`${styles.amountInteger} ${styles[theme]}`}>
                {`$${getAmountInteger(selectedPlan.AmountWithoutPromotion)}`}
              </span>
              <span className={`${styles.amountDecimal} ${styles[theme]}`}>
                {`${getAmountDecimal(selectedPlan.AmountWithoutPromotion)}`}
              </span>
              <span className={`${styles.amountInteger} ${styles[theme]}`}>
                {` ${AmountCurrencyISOCode}`}
              </span>
            </div>
            <div className={`col-12 ${styles.marginRow}`}>
              <p className={`${styles.textForAll} ${styles[theme]}`}>
                {intl.TEXT_VALUE_IS_FOR_ALL}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSummary;
