import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_RECEIVE_REIMBURSEMENT_TRANSPORTATION = {
  en_US: 'Were you reimbursed for any amount by the transportation company? *',
  es_ES: '¿Recibiste algún reembolso por parte de la compañía de transporte? *',
  pt_BR: 'Você recebeu reembolso da empresa de transporte? *',
};

export const TEXT_AMOUNT_PAID_TRANSPORTATION = {
  en_US: 'Indicate the amount paid by the transportation company *',
  es_ES: 'Indica el monto pagado por la compañía de transporte *',
  pt_BR: 'Indique o valor pago pela empresa de transporte *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_DESCRIBE_OPTION_YES = {
  en_US:
    'New fields will appear for you to enter the amount covered by the other insurance',
  pt_BR:
    'Novos campos vão aparecer para você informar o valor coberto pelo outro seguro',
  es_ES:
    'Aparecerán nuevos campos para que introduzca el importe cubierto por el otro seguro',
};

export const TEXT_DESCRIBE_OPTION_NO = {
  en_US: 'The value fields covered by other insurance are not necessary',
  pt_BR: 'Os campos de valor coberto por outro seguro não serão necessários',
  es_ES: 'Los campos de valor cubiertos por otros seguros no son necesarios',
};
