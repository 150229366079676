export const DESCRIPTION_OFFERS = {
  en_US:
    'Enjoy exclusive benefits and promotions for Visa clients in entertainment, services and more!',
  es_ES:
    'Disfrute de benefícios y promociones exclusivas para clientes Visa en entretenimiento, servicios y mucho más!',
  pt_BR:
    'Desfrute de benefícios e promoções exclusivas para clientes Visa em entretenimento, serviços e muito mais!',
};

export const BTN_OFFERS = {
  en_US: 'Enjoy',
  es_ES: 'Disfrutar',
  pt_BR: 'Começar agora',
};

export const OFFERS = {
  en_US: 'Offers',
  es_ES: 'Ofertas',
  pt_BR: 'Ofertas',
};
