export const COUNTRY_LABEL = {
  en_US: 'Country of loss',
  pt_BR: 'País de perda',
  es_ES: 'País la pérdida',
};

export const TEXT_CITY_INCIDENT = {
  en_US: 'City of loss',
  es_ES: 'Ciudad la pérdida',
  pt_BR: 'Cidade de perda',
};
