import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const IncidentInsuranceArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const otherInsuranceCovering =
    claimDetails && claimDetails.other_insurance_covering
      ? claimDetails.other_insurance_covering[0]
      : [{}];

  const otherAmountValue =
    otherInsuranceCovering &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.value
      ? otherInsuranceCovering.amount.value
      : '-';

  const otherAmountCurrency =
    otherInsuranceCovering &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.currency
      ? otherInsuranceCovering.amount.currency
      : '-';
  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.OTHER_INSURANCE_AMOUNT_PAID}
          value={`${otherAmountValue} - ${otherAmountCurrency}`}
        />
      </div>
    </>
  );
};

export default IncidentInsuranceArea;
