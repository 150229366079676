export const LABEL_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship',
  pt_BR: 'Relação',
  es_ES: 'Relación',
};
