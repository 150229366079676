import moment from 'moment';
import { HANDBAG_OR_WALLET } from '../../../const';

export const bag_protection_ToApi = props => {
  let object = {};
  object.loss_code = HANDBAG_OR_WALLET;
  object.affected_person = affected_personToApi(props);
  object.incident = incidentToApi(props);
  object.purchase_protection_items = purchaseProtectionItems(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const incidentToApi = props => {
  const { incident } = props.values.incident;

  const date = moment(incident.dateValue).format('YYYY-MM-DD HH:mm:ss');

  return {
    dateValue: date,
    description: incident.description,
  };
};

const purchaseProtectionItems = props => {
  const { purchase_protection_items, other_insurance_covering } =
    props.values.incident;
  const is_internal =
    other_insurance_covering.is_internal === 'true' ? true : false;

  let list = purchase_protection_items.map((item, index) => {
    if (is_internal && index === 0) {
      return {
        label: item.label,
        price: {
          value: Number(item.price.value),
          currency: item.price.currency,
        },
        other_insurance_covering: [
          {
            is_internal: false,
            amount: {
              value: Number(other_insurance_covering.amount.value),
              currency: other_insurance_covering.amount.currency,
            },
          },
        ],
      };
    }
    return {
      label: item.label,
      price: {
        value: Number(item.price.value),
        currency: item.price.currency,
      },
    };
  });

  return list;
};

const total_amount_claimedToApi = props => {
  const { total_amount_claimed } = props.values.incident;

  return {
    value: Number(total_amount_claimed.value),
    currency: total_amount_claimed.currency,
  };
};
