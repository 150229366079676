/* eslint-disable no-unused-vars */
import React from 'react';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';

export const confirmProfileAlert = async props => {
  const { actions, intl = {}, certificateType } = props;

  const confirmMsg = new Promise((resolve, reject) => {
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="question"
        message={intl.MESSAGE_CONFIRM_CERTIFICATE}
        subtitle={intl.SUBTITLE_CONFIRM_CERTIFICATE}
        textBtn={intl.TEXT_YES_GENERATE_CERTIFICATE}
        clickBtn={() => {
          resolve(true);
          actions.modal.closeModal();
        }}
        textBtnDeny={intl.TEXT_NO_EDITING_CERTIFICATE}
        clickBtnDeny={() => {
          resolve(false);
          actions.modal.closeModal();
        }}
      />,
      true
    );
  });

  return confirmMsg;
};
