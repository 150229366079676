import React, { useState, useCallback, useContext } from 'react';
import Slider from 'react-slick';
import ProductItem from './productItem/ProductItem';
import styles from './ProductsCarousel.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../../../assets/icons/ChevronGeneric';
let refSlick = null;

const ProductsCarousel = props => {
  const { cards, setCardSelected, cardSelected, indexSelected } = props;
  const [dragging, setDragging] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  function ArrowSlider(props) {
    const { style, onClick, theme, rotation = 90, direction = 'left' } = props;

    const styleDirection =
      direction === 'right' ? styles.iconArrowRight : styles.iconArrowLeft;
    return (
      <div
        className={`${styles.iconArrow} ${styleDirection}`}
        style={{ ...style, display: 'block' }}
        onClick={onClick}
      >
        <span className={styles.iconColorTheme}>
          <ChevronGeneric rotate={rotation} width={20} height={20} />
        </span>
      </div>
    );
  }

  const settings = {
    initialSlide: indexSelected,
    dots: false,
    infinite: false,
    slidesToShow: 6.3,
    slidesToScroll: 6,
    centerMode: false,
    waitForAnimate: false,
    beforeChange: () => handleBeforeChange(),
    afterChange: () => handleAfterChange(),
    nextArrow: <ArrowSlider theme={theme} direction="right" rotation={270} />,
    prevArrow: <ArrowSlider theme={theme} />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToScroll: 4,
          slidesToShow: 5.4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToScroll: 3,
          slidesToShow: 3.4,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToScroll: 2,
          slidesToShow: 2.4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1.4,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 1.2,
        },
      },
    ],
  };

  return (
    <>
      {indexSelected !== 'finding' && (
        <Slider
          ref={slider => {
            refSlick = slider;
          }}
          {...settings}
        >
          {cards &&
            cards.map(card => {
              const selected =
                cardSelected && cardSelected.product_id === card.product_id;
              return (
                <ProductItem
                  key={card.name}
                  card={card}
                  setCardSelected={setCardSelected}
                  selected={selected}
                  dragging={dragging}
                />
              );
            })}
        </Slider>
      )}
    </>
  );
};

export default ProductsCarousel;
