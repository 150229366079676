/* eslint-disable no-console */
import { useContext, useEffect } from 'react';
import * as translations from './intl';
import styles from './Card.module.scss';
import { IntlContext } from '../../../intl/index';
import { StateContext } from '../../StateContextParent/StateContextParent';
import IMG_CARD from '../../../assets/images/cards_2.svg';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { getMaskedBinOrPan } from '../../../utils/card';
import { productsAndBenefitsServices } from '../../../services';
import { useState } from 'react';
import TrashIcon from '../../../assets/icons/TrashIcon';
import AddIcon from '../../../assets/icons/AddIcon';
import { isIssuerDomain } from '../../../utils/banks';
import Button from '../../../componentsV2/Button/Button';

const getImages = (cards, bin) => {
  const imageCard =
    cards &&
    cards.data &&
    cards.data.find(card => card.external_reference === bin);
  return imageCard && imageCard.image && imageCard.image.length > 0
    ? imageCard.image
    : IMG_CARD;
};

const Card = ({
  cardData,
  isAddNew,
  onPreferredCard,
  onDeleteCard,
  onShowModalAddCard,
  index,
  cardContext,
}) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    utils,
    state: { cards },
  } = useContext(StateContext);
  const cn = utils.getCn();
  const isFirst = index === 0;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const [cardTypeInitial, setCardTypeInitial] = useState('');

  // Can't change card in BMP
  const canChangeCard = !isIssuerDomain();

  const getCardTypeInitial = async (binOrPan, idiom) => {
    try {
      const { data } =
        await productsAndBenefitsServices.getProductDetailByBinCode(
          binOrPan,
          idiom
        );
      setCardTypeInitial(data.partner_reference.card.type);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (cardData) {
      const binOrPan =
        cardData.credit_card_token || cardData.external_reference;
      getCardTypeInitial(binOrPan, idiom);
    }
  }, []);

  function handleMakeCard(data) {
    const { name, external_reference, is_preferred, credit_card_token } = data;
    const maskedBinOrPan = getMaskedBinOrPan(
      credit_card_token,
      external_reference
    );

    return (
      <div className={styles.container}>
        <img
          src={getImages(cards, cardData.external_reference)}
          className={styles.image}
          alt={intl.CARD}
          aria-label={intl.CARD}
        />

        <div className={styles.contentInfo}>
          <div className={styles.cardInfo}>
            <div className={styles.action}>
              <h5
                className={`${is_preferred && styles.cardActive} ${
                  styles[themes]
                } ${styles.type} `}
              >
                {cardTypeInitial === 'C'
                  ? intl.CARD_TYPE_CREDIT
                  : cardTypeInitial === 'D'
                  ? intl.CARD_TYPE_DEBIT
                  : intl.CARD_TYPE_PREPAID}
              </h5>
              {!isFirst && (
                <Button
                  type="Icon"
                  onClick={() => onDeleteCard(cn, external_reference)}
                  leadingIcon={<TrashIcon />}
                  ariaLabel={intl.DELETE}
                />
              )}
            </div>
            <h5
              className={`${styles.cardName} ${styles.cardType} ${styles[themes]}`}
            >
              {cardContext && cardContext.name}
            </h5>
            <h5 className={`${styles.cardName} ${styles[themes]}`}>
              {credit_card_token && credit_card_token.length === 16
                ? `PAN: ${maskedBinOrPan}`
                : `BIN: ${maskedBinOrPan}`}
            </h5>
            <h5 className={`${styles.cardName} ${styles[themes]}`}>
              {cardContext && cardContext.issuer_name}
            </h5>
          </div>
          {canChangeCard &&
            (is_preferred ? (
              <span
                data-testid="mainCardText"
                className={`${styles.mainCard} ${styles[themes]}`}
              >
                {intl.MAIN_CARD}
              </span>
            ) : (
              <Button
                onClick={() => onPreferredCard(cn, name, external_reference)}
                label={intl.MAKE_CARD}
                type="Tertiary"
                trailingIcon="Chevron Right"
                className={styles.changeCardButton}
              />
            ))}
        </div>
      </div>
    );
  }

  function handleMakeCardAddNew() {
    return (
      <div
        data-testid="addNewCard"
        className={`${styles.containerAddNewCard} ${styles[themes]}`}
        onClick={onShowModalAddCard}
      >
        <h1>{intl.ADD_NEW_CARD}</h1>
        <span className={styles.icon} aria-label={intl.PLUS}>
          <AddIcon />
        </span>
      </div>
    );
  }

  return <>{isAddNew ? handleMakeCardAddNew() : handleMakeCard(cardData)}</>;
};

export default Card;
