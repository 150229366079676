import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const HelpChatIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 49 49"
      style={{
        enableBackground: 'new 0 0 49 49',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        className="st4"
        style={{
          fill: config_icon.secondary,
        }}
        d="M14 16.3h21v2H14zM14 12.1h21v2H14zM14 20.5h20.5v2H14zM14 24.7h11.6v2H14z"
      />
      <path
        d="M31.9 3.7H17.1c-8.7 0-15.7 7.1-15.7 15.7s7.1 15.7 15.7 15.7h5.3l2-2h-7.3C9.6 33.1 3.4 27 3.4 19.4S9.6 5.7 17.1 5.7h14.7c7.6 0 13.7 6.2 13.7 13.7s-6.2 13.7-13.7 13.7h-4.6L16.4 43.9l1.4 1.4L28 35.1h3.8c8.7 0 15.7-7.1 15.7-15.7s-7-15.7-15.6-15.7z"
        style={{
          fill: config_icon.primary,
        }}
      />
    </svg>
  );
};

export default HelpChatIcon;
