import moment from 'moment';
import { LABEL_16_DIGITS } from '../intl';

export const SCHEME = process.env.REACT_APP_TOKEN_EX_SCHEME;
export const TOKENEX_ID = process.env.REACT_APP_TOKEN_EX_ID;
export const ORIGIN = process.env.REACT_APP_TOKEN_EX_ORIGIN;
export const TIMESTAMP = moment().utc().format('YYYYMMDDHHmmss');

export const CONFIG_TOKENEX = (
  authenticationKey,
  idiom,
  isAfluent,
  trackEventUserAction
) => {
  const subtitle = isAfluent ? '#54606c' : '#5c5c5c';
  const secondary = isAfluent ? '#C2A161' : '#FCC015';

  const config_tokenex = {
    styles: {
      base: `
      width: 100%;
      background: none;
      border: none;
      border-bottom: 1px solid ${subtitle};
      font-size: 16px;
      font-weight: 300;
      line-height: 1;
      padding: 6px 0;
      border-radius: 0;
      letter-spacing: normal;
      height: 35px;
      color: ${subtitle};
      margin: 0;
      `,
      focus: `border: none; outline: none; border-bottom: 1px solid ${secondary}`,
      error: 'border-color: #e74c3c;',
    },
    inputType: 'text',
    enablePrettyFormat: true,
    debug: false,
    placeholder: LABEL_16_DIGITS[idiom],
    origin: ORIGIN,
    timestamp: TIMESTAMP,
    tokenExID: TOKENEX_ID,
    tokenScheme: SCHEME,
    authenticationKey: authenticationKey,
    pci: true,
    possibleCardType: 'visa',
  };

  trackEventUserAction('#### (PAN) CONFIGURAÇÃO DO PAN', { config_tokenex });

  return config_tokenex;
};
