import React, { useContext } from 'react';
import styles from './Banner.module.scss';
import * as translations from '../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../intl';

const Banner = () => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);

  return (
    <div className={`${styles.bannerContainer} ${styles[theme]}`}>
      <h1>{intl.TITLE}</h1>
      <p>{intl.DESCRIPTION}</p>
    </div>
  );
};

export default Banner;
