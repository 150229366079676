import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const QuestionsArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const otherInsuranceCovering =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering[0];

  const isInternal =
    otherInsuranceCovering && otherInsuranceCovering.is_internal;

  const amount =
    (otherInsuranceCovering && otherInsuranceCovering.amount) || {};
  const value = amount.value || '-';

  return (
    <>
      {isInternal === false && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue label={intl.LABEL_AMOUNT_PAID} value={value} />
        </div>
      )}
    </>
  );
};

export default QuestionsArea;
