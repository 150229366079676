import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const BaggageLossIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style type="text/css">
        {'\r\n\t.st0{fill:#FDC000;}\r\n\t.st1{fill:#1434CB;}\r\n'}
      </style>
      <g>
        <path
          fill={config_icon.secondary}
          d="M11,6H9V2C8.8,2,8.5,2,8.3,2H8H7.7C7.5,2,7.2,2,7,2v4H5V2c0-0.6,0.2-1.1,0.5-1.4C6.1,0,6.9,0,7.7,0H8h0.3 C9,0,9.9,0,10.5,0.6C10.8,0.9,11,1.4,11,2V6z"
        />
        <path
          fill={config_icon.secondary}
          d="M21.9,16.8c0,0.2,0.1,0.5,0.1,0.7c0,2.5-2,4.5-4.5,4.5S13,20,13,17.5c0-2.3,1.8-4.2,4.1-4.5l1-2 c-0.2,0-0.3,0-0.5,0c-3.6,0-6.5,2.9-6.5,6.5S14,24,17.6,24s6.5-2.9,6.5-6.5c0-1.2-0.3-2.3-0.9-3.2L21.9,16.8z"
        />
        <g>
          <rect x={4} y={8} fill={config_icon.primary} width={8} height={2} />
          <path
            fill={config_icon.primary}
            d="M9.8,21H3c-0.6,0-1-0.4-1-1V7c0-0.6,0.4-1,1-1V4C1.3,4,0,5.3,0,7v13c0,1.7,1.3,3,3,3l0,0v1h2v-1h6.1 C10.5,22.4,10.1,21.8,9.8,21z"
          />
          <path
            fill={config_icon.primary}
            d="M16,9.1V7c0-1.7-1.3-3-3-3v2c0.6,0,1,0.4,1,1v2.8C14.6,9.5,15.3,9.3,16,9.1z"
          />
          <polygon
            fill={config_icon.primary}
            points="18.9,13.6 17.4,17 15,17 15,19 18.7,19 20.8,14.4  "
          />
        </g>
      </g>
    </svg>
  );
};

export default BaggageLossIcon;
