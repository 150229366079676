import { AxiosInstance, AxiosResponse } from 'axios';
import {
  AccountRecoveryEmailNotAccessible,
  AccountRecoveryResponse,
} from '../@types/APIs/AccountRecovery';

export default (_, axios: AxiosInstance, errorHandler) => {
  return {
    createAccountRecoveryRequest: async (
      body: AccountRecoveryEmailNotAccessible
    ): Promise<AxiosResponse<AccountRecoveryResponse>> => {
      const url = `/account_recovery_requests`;

      return errorHandler(
        axios.post(url, body),
        'createAccountRecoveryEmailNotAccessible',
        true
      );
    },
  };
};
