import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import * as translations from './intl';
import styles from './Details.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import FlightDetail from './FlightDetail';

const Details = ({ travel, isReturn = false, disableTitle = false }) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const trip = isReturn ? travel.return_travel : travel.outward_travel;

  return (
    <>
      <h1 className={`${styles[theme]} ${styles.title}`}>
        {isReturn ? intl.TITLE_RETURN : intl.TITLE_OUTBOUND}
      </h1>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_BOOKING_COMPANY}
            value={trip.booking_company_name}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_RECORD_LOCATOR}
            value={trip.booking_reference}
          />
        </div>
      </div>
      {trip.flight_segments.map((flight, index) => (
        <FlightDetail key={index} flight={flight} index={index} />
      ))}
    </>
  );
};

export default Details;
