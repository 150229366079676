export const TEXT_SUCCESS_CANCEL = {
  en_US: 'Your appointment was canceled successfully.',
  pt_BR: 'Seu compromisso foi cancelado com sucesso.',
  es_ES: 'Tu cita fue cancelada con éxito.',
};

export const TEXT_ERROR_CANCEL = {
  en_US: 'Appointment cannot be canceled.',
  pt_BR: 'O agendamento não pode ser cancelado.',
  es_ES: 'La cita no puede ser cancelada.',
};

export const MODAL_TITLE_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'Are you sure you want to cancel the selected appointment?',
  pt_BR: 'Tem certeza de que deseja cancelar o agendamento selecionado?',
  es_ES: '¿Está seguro de que desea cancelar la cita seleccionada?',
};

export const MODAL_TEXT_CONFIRMATION_CANCEL_APPOINTMENT = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Esta ação não pode ser revertida.',
  es_ES: 'Esta acción no se puede revertir.',
};

export const BUTTON_CONFIRM = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const BUTTON_DENY = {
  en_US: 'No, keep the appointment',
  pt_BR: 'Não, manter o agendamento',
  es_ES: 'No, guarda la cita',
};

export const TEXT_CANCEL_APPOINTMENT = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo electrónico',
};

export const TEXT_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};

export const TEXT_DATE_TIME = {
  en_US: 'Date/time',
  pt_BR: 'Data/hora',
  es_ES: 'Fecha/hora',
};

export const LABEL_MODAL_MESSAGE_VIDEOCALL = {
  en_US: 'Are you sure that you want to cancel this video appointment?',
  es_ES: '¿Está seguro de que desea cancelar esta video cita?',
  pt_BR: 'Tem certeza de que deseja cancelar este agendamento?',
};
export const LABEL_MODAL_MESSAGE_PHONECALL = {
  en_US: 'Are you sure that you want to cancel this appointment?',
  es_ES: '¿Está seguro de que desea cancelar esta cita?',
  pt_BR: 'Tem certeza de que deseja cancelar este agendamento?',
};

export const LABEL_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const LABEL_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const CANCELING_MESSAGE = {
  en_US: 'Canceling...',
  es_ES: 'cancelando...',
  pt_BR: 'Cancelando...',
};

export const CANCEL_PHONECALL_MESSAGE_SUCCESS = {
  en_US: 'Phone call canceled successfully.',
  es_ES: 'Llamada telefónica cancelada con éxito.',
  pt_BR: 'Ligação cancelada com sucesso.',
};

export const CANCEL_VIDEOCALL_MESSAGE_ERROR = {
  en_US: 'Failed to cancel video call.',
  es_ES: 'No se pudo cancelar la videollamada.',
  pt_BR: 'Falha ao cancelar a video chamada.',
};

export const CANCEL_PHONECALL_MESSAGE_ERROR = {
  en_US: 'Failed to cancel phone call.',
  es_ES: 'No se pudo cancelar la llamada telefónica.',
  pt_BR: 'Falha ao cancelar a chamada telefônica.',
};

export const CANCEL_VIDEOCALL_MESSAGE_SUCCESS = {
  en_US: 'Video call canceled successfully.',
  es_ES: 'Videollamada cancelada con éxito.',
  pt_BR: 'Video chamada cancelada com sucesso.',
};

export const LABEL_JOIN = {
  es_ES: 'Iniciar',
  en_US: 'Join now',
  pt_BR: 'Entrar',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};
