export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};

export const INCIDENT_LAST_INVOICE_PAID = {
  en_US: 'Date of last invoice paid',
  pt_BR: 'Data da última fatura paga',
  es_ES: 'Fecha de la última factura pagada',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date *',
  es_ES: 'Fecha de compra *',
  pt_BR: 'Data da compra *',
};
