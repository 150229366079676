import {
  claimsServices,
  reimbursmentService,
} from '../../../../../../../services';
import {
  ENABLE_OCT,
  RESTRICTION_OCT_BY_COUNTRY,
} from '../../../../../../../utils/environments_variables';
import {
  payment,
  review,
} from '../../../../../../Profile/ProfileClaims/StatusClaimBySubStatus';

export const TYPE_OCT = 'OCT';
export const TYPE_BANK = 'BANK';
export const TRANSFERED_TYPE_OCT = 'OCT';

export const showOCT = (country, claim_type = null) => {
  const octByCountry = RESTRICTION_OCT_BY_COUNTRY.includes(country);

  if (claim_type) {
    const octByClaim =
      process.env.REACT_APP_RESTRICTION_OCT_BY_CLAIMTYPE === claim_type;
    return octByCountry && octByClaim && ENABLE_OCT;
  }

  return octByCountry && ENABLE_OCT;
};

export const checkClaimsToReplacePaymentMethod = async (
  cn,
  binOrPan,
  idiomAPI,
  setCanChangePaymentMethod,
  trackEventUserAction
) => {
  try {
    const { data: claims } = await claimsServices.getAllClaims(cn, idiomAPI);

    const hasOpenedIEMSClaim = claims.filter(claim => {
      if (
        claim.benefit_external_reference ===
          'EMC_Emergency_Medical_Assistance' &&
        claim.statuses.sub_status.name &&
        (review.includes(claim.statuses.sub_status.name) ||
          payment.includes(claim.statuses.sub_status.name))
      ) {
        return true;
      }
      return false;
    });

    trackEventUserAction('#### OCT - HAS OPENED IEMS CLAIM ###', {
      hasOpenedIEMSClaim,
    });

    if (hasOpenedIEMSClaim.length > 0) {
      const promisesGetReimbursements = hasOpenedIEMSClaim.map(claim =>
        reimbursmentService.getAllReimbursmentClaimV2(claim.claim_id)
      );

      const reimbursements = await Promise.all(promisesGetReimbursements);
      const promisesGetReimbursementsDetail = hasOpenedIEMSClaim.map(
        (claim, index) =>
          reimbursmentService.getReimbursmentV2(
            claim.claim_id,
            reimbursements[index].data[0].reimbursement_id
          )
      );

      const reimbursementsDetail = await Promise.all(
        promisesGetReimbursementsDetail
      );

      const hasOpenedOCTClaim = reimbursementsDetail.find(
        reimbursementDetail =>
          reimbursementDetail.data.beneficiary_account
            .preferred_transfer_type === TRANSFERED_TYPE_OCT
      );

      setCanChangePaymentMethod(!hasOpenedOCTClaim);
    } else {
      setCanChangePaymentMethod(true);
    }
  } catch (error) {
    trackEventUserAction('#### OCT ERROR - GET CLAIMS ###', {
      data: error && error.response && error.response.data,
      response: error && error.response,
      error,
    });
    setCanChangePaymentMethod(false);
  }
};
