import { useContext, useState, useEffect } from 'react';
import styles from './BlueBox.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

import Button from '../../../componentsV2/Button/Button';
import CountrySelect from '../../../components/CountrySelect/CountrySelect';
import { listCountriesPhones } from '../../../utils/phoneCountries';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { useHistory } from 'react-router-dom';

const BlueBox = () => {
  const [phone, setPhone] = useState('');
  const { translate, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const [countryPhone, setCountryPhone] = useState('');
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const history = useHistory();

  const changePhone = event => {
    const selectedInitials = event.target.value;
    const countryPhone = listCountriesPhones.find(
      c => c.initials === selectedInitials
    );
    if (countryPhone === undefined) {
      setPhone('');
    } else {
      setCountryPhone(countryPhone.initials);
      setPhone(countryPhone.phone);
    }
  };

  const initialPhone = country => {
    const countryPhone = listCountriesPhones.find(
      c => String(c.en_US).toUpperCase() === country
    );
    if (countryPhone === undefined) {
      setPhone('');
    } else {
      setCountryPhone(countryPhone.initials);
      setPhone(countryPhone.phone);
    }
  };

  useEffect(() => {
    initialPhone(utils.getCountry());
  }, []);

  return (
    <div className={`${styles.boxContainer} ${styles[theme]}`}>
      <span className={styles.darkenFilter} />
      <h1 className={styles.title}>{intl.MORE_INFORMATION}</h1>
      <p className={styles.description}>{intl.MORE_INFORMATION_CARD}</p>

      <p className={styles.buttonArea}>
        <Button
          link
          type="Secondary"
          surface="Inverse"
          href="/dashboard"
          label={intl.BUTTON_BENEFITS}
        />
      </p>

      <p className={styles.description}>{intl.PREFER_NUMBERS}</p>
      <CountrySelect
        onChange={changePhone}
        idiom={idiom}
        countrySelected={countryPhone}
      />
      <span>{phone}</span>
    </div>
  );
};

export default BlueBox;
