import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const AddCardIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      style={{
        enableBackground: 'new 0 0 48 48',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: 'none',
          stroke: '#e2e6e8',
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M5 13h38"
      />
      <path
        d="M45.5 39h-43c-.8 0-1.5-.7-1.5-1.6V10.6C1 9.7 1.7 9 2.5 9h43c.8 0 1.5.7 1.5 1.6v26.9c0 .8-.7 1.5-1.5 1.5z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: 'none',
          stroke: '#e2e6e8',
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default AddCardIcon;
