// @ts-nocheck
import { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../intl';
import { productsAndBenefitsServices } from '../../services';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import styles from './BenefitsFormFlow.module.scss';
import Loading from '../../components/Loading/Loading';
import HorizontalHalfBlueLayout from '../../layouts/HorizontalHalfBlueLayout';
import SessionContainer from '../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../components/HeadingTitle/HeadingTitle';
import StepClaim from '../Claims/StepClaim/StepClaim';

interface Props {
  benefitId: string;
  title: string;
  subtitle: string;
  steps: string[];
  TermsAndConditions: () => Element;
  Form: () => Element;
}

const BenefitsFormFlow = ({
  benefitId,
  title,
  subtitle,
  steps,
  TermsAndConditions,
  Form,
}: Props) => {
  const [step, setStep] = useState(1);
  const [pdfLink, setPdfLink] = useState('');
  const [loading, setLoading] = useState(true);

  const { idiomForApi } = useContext(IntlContext);
  const { utils } = useContext(StateContext);

  const isTerms = step === 1;
  const isForm = step === 2;
  const binOrPan = utils.getBin(true);

  const loadData = async (product_id, benefit_id, idiom, setLoading) => {
    setLoading(true);
    try {
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          product_id,
          benefit_id,
          idiom,
          false
        );
      const benefitDetail = response.data;

      setPdfLink(benefitDetail.documents[0].content_url);
    } catch (error) {
      setPdfLink('');
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData(binOrPan, benefitId, idiomForApi(), setLoading);
  }, [binOrPan]);

  return loading ? (
    <div className={styles.loadingContainer} role="loadingInfo">
      <Loading />
    </div>
  ) : (
    <HorizontalHalfBlueLayout>
      <SessionContainer>
        <HeadingTitle title={title} subtitle={subtitle} />
        <StepClaim currentStep={step} stepNames={steps} axa={false} />
        {isTerms && <TermsAndConditions setStep={setStep} pdfLink={pdfLink} />}
        {isForm && <Form setStep={setStep} />}
      </SessionContainer>
    </HorizontalHalfBlueLayout>
  );
};

export default BenefitsFormFlow;
