import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import {
  removeNonNumeric,
  stringToBoolean,
} from '../../../../../../utils/stringUtils';
import { userServices } from '../../../../../../services';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import ModalWithImage from '../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../components/ModalResult/ModalResult';
import ModalCreditCard from '../../../../../../assets/images/modal/modal_image_credit_card.png';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../utils/environments_variables';
import { isIssuerDomain } from '../../../../../../utils/banks';

const MSG_ERROR_CPF = 'Duplicated Customer Registration';

const cardHolderData = (cn, values) => {
  // Nationalities
  let nationalities = [values.nationalities];

  // Person
  let person = values.person;
  person.nationalities = nationalities;

  // Registrations
  let registrations = [];
  if (Array.isArray(values.person_registrations)) {
    registrations = values.person_registrations.map(registration => {
      let isCPF = registration.registration_type === 'CPF';

      return {
        ...registration,
        value: isCPF
          ? removeNonNumeric(registration.value)
          : registration.value,
      };
    });

    registrations = registrations.filter(
      registration => registration.value !== ''
    );
  } else {
    let isCpf = values.person_registrations.registration_type === 'CPF';
    let registrationValue = isCpf
      ? removeNonNumeric(values.person_registrations.value)
      : values.person_registrations.value;

    registrations = [
      {
        registration_type: values.person_registrations.registration_type,
        value: registrationValue,
      },
    ];
  }

  // Invalid cep
  delete values.address.invalidCep;

  // cep
  if (!ALPHANUMERIC_COUNTRY_LIST.includes(values.address.country)) {
    values.address.postal_code = removeNonNumeric(values.address.postal_code);
  }

  // Card holder
  let data = {
    address: values.address,
    customer_id: cn,
    is_politically_exposed: stringToBoolean(values.politicallyExposed)
      ? true
      : false,
    partner_customer_id: '001',
    person: person,
    phone: values.phone,
    registrations: registrations,
  };

  return data;
};

const checkChanged = (values, initialValues) => {
  if (values.benefitiaryIsNotCardHolder) return false;
  const cloned = cloneDeep(values);
  cloned.bank.last_number = '';
  delete initialValues.address.invalidCep;
  delete cloned.address.invalidCep;
  cloned.address.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
    cloned.address.country
  )
    ? cloned.address.postal_code
    : removeNonNumeric(cloned.address.postal_code);

  return !isEqual(initialValues, cloned);
};

export const checkGeneralInfoChange = async props => {
  // Don't ask to update customer in BMP
  if (isIssuerDomain()) return;

  const {
    initialValuesCompare: { policy: initialValuesCompare },
    values: { policy: values },
    actions,
    intl = {},
    cn,
    setInitialValuesCompare,
    history,
    trackEventUserAction,
  } = props;
  const changed = checkChanged(values, initialValuesCompare);
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- CHECK GENERAL INFO CHANGE',
    changed
  );
  if (changed) {
    const savePromisse = new Promise((resolve, reject) => {
      const updateCardHolder = async () => {
        const cardHolder = cardHolderData(cn, values);
        try {
          actions.modal.showLoading(intl.SAVING_PERSONAL_DETAILS);
          trackEventUserAction(
            '#### (CERTIFICATE) CLICOU NO SUBMIT -- SALVANDO NOVAS INFORMAÇÕES DO CARDHOLDER ####',
            values
          );
          await userServices.updateCHInfo(cn, cardHolder);
          trackEventUserAction(
            '#### (CERTIFICATE) CLICOU NO SUBMIT -- SALVOU AS INFORMAÇÕES do CARDHOLDER ####',
            cardHolder
          );

          initialValuesCompare.policy = values;
          setInitialValuesCompare(initialValuesCompare);

          actions.modal.hideLoading();
          actions.modal.showModal(
            false,
            true,
            <ModalInformation
              type="success"
              message={intl.TEXT_CH_CHANGE_SUCCESS}
            />,
            true,
            false
          );
        } catch (e) {
          trackEventUserAction(
            '#### (CERTIFICATE) CLICOU NO SUBMIT -- ERROR AO SALVAR AS INFORMAÇÕES DO CARDHOLDER ####',
            e
          );
          actions.modal.hideLoading();
          if (
            e.response &&
            e.response.data &&
            e.response.data.error_description === MSG_ERROR_CPF
          ) {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="error"
                message={intl.ERROR_CUSTOMER_DUPLICATED}
              />,
              true,
              false
            );
          } else if (e.response && e.response.status === 504) {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation type="error" message={intl.ERROR_TIME_OUT} />,
              true,
              false
            );
          } else {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="error"
                message={intl.ERROR_CUSTOMER_UPDATE}
                subtitle={intl.UNEXPECTED_ERROR}
                textBtn={intl.TRY_AGAIN}
                buttonConfirmType="blueBorder"
                textBtnDeny={intl.TRY_LATER}
                clickBtnDeny={() => {
                  actions.modal.closeModal();
                  history.push('/certificates');
                }}
              />,
              true,
              false
            );
          }
          reject();
        } finally {
          resolve();
        }
      };
      actions.modal.showModal(
        false,
        true,
        <ModalWithImage
          type="question"
          title={intl.TEXT_CH_CHANGE}
          image={ModalCreditCard}
        >
          <ModalResult
            textConfirm={intl.TEXT_YES_I_DO}
            clickConfirm={updateCardHolder}
            textDeny={intl.TEXT_NO}
            clickDeny={() => {
              resolve();
              actions.modal.closeModal();
            }}
          />
          ,
        </ModalWithImage>,
        true,
        false
      );
    });

    return savePromisse;
  }
};
