import { useContext } from 'react';

import styles from './Generate.module.scss';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import ChevronRightIcon from '../../../../assets/icons/ChevronRightIcon';
import { th } from 'date-fns/locale';

const Generate = props => {
  const { icon, url, title, blue, border, children, GTM_id, GTM_type } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const typeGTM = GTM_type ? GTM_type : null;

  return (
    <div className={` ${styles.generate} ${blue ? styles.blue : styles.white}`}>
      <Link
        onClick={() => dataLayerContentMenuTrack(GTM_id, typeGTM)}
        to={url}
        className={`${styles[theme]} ${blue ? styles.blue : styles.white}`}
      >
        <span className={styles.icon}>
          {icon}
          {title}
        </span>
        <span className={`${styles.iconArrow} ${styles[theme]}`}>
          <ChevronRightIcon color="inherit" />
        </span>
      </Link>
      <div
        className={`${styles[theme]} ${styles.separator} ${
          border ? styles.border : styles.none
        }`}
      ></div>
      {children}
    </div>
  );
};

export default Generate;
