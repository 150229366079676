import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

export const legalProtectionSchema = intl =>
  Yup.object().shape({
    affected_person: Yup.object().shape({
      relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      comment: Yup.string().when(['relationship'], {
        is: relationship => {
          return relationship === constants.OTHER_VALUE;
        },
        then: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
      }),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    incident: Yup.object().shape({
      description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      date: Yup.date()
        .test('match', intl.VALIDATE_DATE_LOSS, function (date) {
          return moment(date).isSameOrBefore(moment(new Date()));
        })
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID),
      location: Yup.object().shape({
        country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      involved_person: Yup.object().shape({
        checked: Yup.boolean().required(intl.TEXT_FIELD_REQUIRED),
        address: Yup.object().when(['checked'], {
          is: true,
          then: Yup.object().shape({
            street_address: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .max(255, intl.TEXT_FIELD_VALIDATION_255),
            postal_code: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            subdivision: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        }),
        phone: Yup.object().when(['checked'], {
          is: true,
          then: Yup.object().shape({
            phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            international_prefix: Yup.string().required(
              intl.TEXT_FIELD_REQUIRED
            ),
            number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        }),

        person: Yup.object().when(['checked'], {
          is: true,
          then: Yup.object().shape({
            first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        }),
      }),
      expenses: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          amount: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        })
      ),
    }),

    other_insurance_covering: Yup.array().of(
      Yup.object().shape({
        amount: Yup.object().shape({
          checked: Yup.boolean().required(intl.TEXT_FIELD_REQUIRED),
          value: Yup.string().when(['checked'], {
            is: true,
            then: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          }),
        }),
      })
    ),
    contact_phones: Yup.array().of(
      Yup.object().shape({
        phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      })
    ),
  });
