import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Select from '../../../../../../components/Select/Select';
import * as constants from '../consts';
import { BUSINESS, CORPORATE, CTA } from '../consts';

const checkCard = (cardType, intl) => {
  if (cardType === BUSINESS) {
    //ALL
  }
  if (cardType === CORPORATE) {
    return (
      <>
        <option value="">{intl.LABEL_RELATIONSHIP}</option>
        <option value={constants.VALUE_SON}>
          {intl.LABEL_RELATIONSHIP_VALUE_SON}
        </option>
        <option value={constants.VALUE_DAUGHTER}>
          {intl.LABEL_RELATIONSHIP_VALUE_DAU}
        </option>
        <option value={constants.VALUE_SPOUSE}>
          {intl.LABEL_RELATIONSHIP_VALUE_SPOUSE}
        </option>
      </>
    );
  }
  if (cardType === CTA) {
    return (
      <>
        <option value="">{intl.LABEL_RELATIONSHIP}</option>
        <option value={constants.VALUE_OTHER}>
          {intl.LABEL_RELATIONSHIP_VALUE_OTHER}
        </option>
      </>
    );
  }

  return (
    <>
      <option value="">{intl.LABEL_RELATIONSHIP}</option>
      <option value={constants.VALUE_SON}>
        {intl.LABEL_RELATIONSHIP_VALUE_SON}
      </option>
      <option value={constants.VALUE_DAUGHTER}>
        {intl.LABEL_RELATIONSHIP_VALUE_DAU}
      </option>
      <option value={constants.VALUE_SPOUSE}>
        {intl.LABEL_RELATIONSHIP_VALUE_SPOUSE}
      </option>
      <option value={constants.VALUE_OTHER}>
        {intl.LABEL_RELATIONSHIP_VALUE_OTHER}
      </option>
    </>
  );
};

const RelationshipArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, cardType } = props;
  return (
    <Select
      label={intl.LABEL_RELATIONSHIP}
      value={values.relationship}
      name={`relationship`}
      onChange={handleChange}
      onBlur={handleBlur}
      touched={touched.relationship}
      error={errors.relationship}
      dataTestid="personRelationship"
    >
      {checkCard(cardType, intl)}
    </Select>
  );
};

export default RelationshipArea;
