import * as Yup from 'yup';

const validationGeneralFormSchema = intl =>
  Yup.object().shape({
    type: Yup.string()
      .oneOf(['VIDEO', 'PHONE'])
      .required(intl.TEXT_FIELD_REQUIRED),
    country: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .min(2, intl.TEXT_ERROR_TWO_DIGITS)
      .max(2, intl.TEXT_ERROR_TWO_DIGITS),
    language: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .min(2, intl.TEXT_ERROR_TWO_DIGITS)
      .max(2, intl.TEXT_ERROR_TWO_DIGITS),
    timezone: Yup.string().when('type', {
      is: 'PHONE',
      then: Yup.string(intl.TEXT_FIELD_REQUIRED).required(
        intl.TEXT_FIELD_REQUIRED
      ),
    }),
  });

export default validationGeneralFormSchema;
