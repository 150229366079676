/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  useContext,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IntlContext } from '../../intl';
import { StateContext } from '../StateContextParent/StateContextParent';
import * as translations from './intl';
import { userServices } from '../../services/index';
import { getQueryVariable } from '../../utils/uri';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';
import styles from './ChatbotUST.module.scss';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import CloseIcon from '../../assets/icons/CloseIcon';
import { sanitize } from 'dompurify';
import {
  REACT_APP_CHATBOT_UST,
  SHOW_ALERT_CHATBOT,
} from '../../utils/environments_variables';
const languages = {
  en_US: 'en_US',
  pt_BR: 'pt_BR',
  es_ES: 'es',
};

const addA11yHooks = (isOpenChat, setShowAlert) => {
  window.embedded_svc.addEventHandler('afterMaximize', function (data) {
    setShowAlert(false);
    toggleInert(true);
  });
  window.embedded_svc.addEventHandler('afterDestroy', function (data) {
    toggleInert(false);
  });
  window.embedded_svc.addEventHandler('afterMinimize', function (data) {
    toggleInert(false);
  });

  window.embedded_svc.addEventHandler(
    'onSettingsCallCompleted',
    function (data) {
      if (isOpenChat) {
        setShowAlert(false);
        openChatBot();
      }
    }
  );
};

const toggleInert = disabled => {
  let bodyElem = document.getElementById('body-content');
  if (bodyElem) {
    bodyElem.inert = disabled;
  }
  console.log('#ADDA11Hooks disabled', disabled);
};

const formatIdiom = idiom => {
  let newIdiom = idiom;
  if (idiom.includes('en') && !idiom.includes('US')) {
    newIdiom = 'en_US';
  }
  const replaceIdiom = newIdiom.replace('-', '_');
  return idiom.includes('es') ? 'es' : newIdiom;
};

const formBotEmpty = (idiom, initialBotSetup) => {
  window.embedded_svc.settings.prepopulatedPrechatFields = {
    FirstName: initialBotSetup.first_name
      ? decodeURI(initialBotSetup.first_name)
      : '', // FirstName value
    LastName: initialBotSetup.last_name
      ? decodeURI(initialBotSetup.last_name)
      : '', // LastName value
    Email: initialBotSetup.email ? decodeURI(initialBotSetup.email) : '', //Email value
    Language__c: initialBotSetup.lang
      ? decodeURI(formatIdiom(initialBotSetup.lang))
      : languages[idiom], // Language__c valued
  };
};

const postMessageScript = () => {
  var inlineScript = document.createTextNode(`window.addEventListener(
    'message',
    event => {
      // event.source is window.opener
      // event.data is the content sent from child LWC
      window.embedded_svc.postMessage(
        'chasitor.sendMessage',
        event.data.message
      );

    },
    false
  );`);
  return inlineScript;
};

const createPostMessageScript = () => {
  let script = document.createElement('script');
  script.appendChild(postMessageScript());
  document.head.appendChild(script);
};

const openChatBot = () => {
  const buttonBot = document.getElementsByClassName('helpButtonEnabled')[0];

  if (buttonBot) {
    buttonBot.click();
  }
};

const initESW = (
  gslbBaseURL,
  idiom,
  intl,
  country,
  initialBotSetup,
  trackEventUserAction,
  setShowAlert
) => {
  createPostMessageScript();
  console.log('#CREATED THE POST MESSAGE SCRIPT');
  const configPreChat = [
    {
      label: 'LastName',
      transcriptFields: ['LastName__c'],
      displayToAgent: true,
    },
    {
      label: 'FirstName',
      transcriptFields: ['FirstName__c'],
      displayToAgent: true,
    },
    {
      label: 'Email',
      transcriptFields: ['Email__c'],
      displayToAgent: true,
    },
    {
      label: 'Language__c',
      transcriptFields: ['Language__c'],
      displayToAgent: true,
    },
    {
      label: 'Country',
      value: country,
      transcriptFields: ['Country__c'],
      displayToAgent: true,
    },
    {
      label: 'Source',
      value: initialBotSetup.source,
      transcriptFields: ['Source__c'],
      displayToAgent: true,
    },
    {
      label: 'Customer Identifier',
      value: initialBotSetup.customer_id,
      transcriptFields: ['Customer_Identifier__c'],
      displayToAgent: true,
    },
    {
      label: 'Bank__c',
      transcriptFields: ['Bank__c'],
      displayToAgent: true,
    },
    {
      label: 'BIN_Number__c',
      transcriptFields: ['BIN_Number__c'],
      displayToAgent: true,
    },
    {
      label: 'Country_of_Bank__c',
      transcriptFields: ['Country_of_Bank__c'],
      displayToAgent: true,
    },
  ];

  trackEventUserAction('#### EINSTEIN BOT  ####', {
    config_prechat: configPreChat,
    initialBotSetup: initialBotSetup,
    idiom,
    country,
  });

  console.log('#SET UP PRECHAT');

  window.embedded_svc.settings.displayHelpButton = true;
  window.embedded_svc.settings.language = languages[idiom];
  window.embedded_svc.settings.smallCompanyLogoImgURL =
    'https://images.prismic.io/prod-axa/63f9c13c-014a-4115-bff0-1ea3128dde25_Chatnegative24.png?auto=compress,format';
  window.embedded_svc.settings.avatarImgURL =
    'https://prod-axa.cdn.prismic.io/prod-axa/ZumGt7VsGrYSvcJH_Agent24x24.svg';
  window.embedded_svc.settings.chatbotAvatarImgURL =
    'https://prod-axa.cdn.prismic.io/prod-axa/ZumGuLVsGrYSvcJI_Chatbot24x24.svg';
  window.embedded_svc.settings.defaultMinimizedText = intl.TXT_TALK_TO_SOFIA;
  window.embedded_svc.settings.loadingText = intl.LOADING;

  window.embedded_svc.settings.extraPrechatFormDetails = configPreChat;

  window.embedded_svc.settings.extraPrechatInfo = [
    {
      entityName: 'Contact',
      showOnCreate: true,
      linkToEntityName: 'Case',
      linkToEntityField: 'ContactId',
      saveToTranscript: 'ContactId',
      entityFieldMaps: [
        {
          isExactMatch: true,
          fieldName: 'FirstName',
          doCreate: true,
          doFind: true,
          label: 'FirstName',
        },
        {
          isExactMatch: true,
          fieldName: 'LastName',
          doCreate: true,
          doFind: true,
          label: 'LastName',
        },
        {
          isExactMatch: true,
          fieldName: 'Email',
          doCreate: true,
          doFind: true,
          label: 'Email',
        },
        {
          isExactMatch: true,
          fieldName: 'BIN_Number__c',
          doCreate: true,
          doFind: false,
          label: 'BIN_Number__c',
        },
        {
          isExactMatch: true,
          fieldName: 'Language__c',
          doCreate: true,
          doFind: false,
          label: 'Language__c',
        },
        {
          isExactMatch: true,
          fieldName: 'Bank__c',
          doCreate: true,
          doFind: false,
          label: 'Bank__c',
        },
        {
          isExactMatch: true,
          fieldName: 'Country_of_Bank__c',
          doCreate: true,
          doFind: false,
          label: 'Country of Bank',
        },
      ],
    },
  ];

  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';

  console.log('#FILLED THE WINDOW EMBEDDED', window.embedded_svc);

  window.embedded_svc.init(
    process.env.REACT_APP_CHATBOT_UST_URL_VISA_GATEWAY,
    process.env.REACT_APP_CHATBOT_UST_URL_VISA_WEBPORTAL,
    gslbBaseURL,
    process.env.REACT_APP_CHATBOT_UST_ID,
    process.env.REACT_APP_CHATBOT_UST_CHAT_GROUP,
    {
      baseLiveAgentContentURL:
        process.env.REACT_APP_CHATBOT_UST_BASELIVE_AGENT_CONTENT_URL,
      deploymentId: process.env.REACT_APP_CHATBOT_UST_DEPLOYMENT_ID,
      buttonId: process.env.REACT_APP_CHATBOT_UST_BUTTON_ID,
      baseLiveAgentURL: process.env.REACT_APP_CHATBOT_UST_BASELIVE_AGENT_URL,
      eswLiveAgentDevName: process.env.REACT_APP_CHATBOT_UST_LIVE_AGENT_NAME,
      isOfflineSupportEnabled: true,
    }
  );

  console.log('#INIT WINDOW EMBEDDED', window.embedded_svc);

  addA11yHooks(initialBotSetup.isMobile, setShowAlert);

  if (window.embedded_svc.isDesktop()) {
    return;
  }
};

const ChatbotUST = () => {
  const { translate, country, idiom, definitiveIdiom } =
    useContext(IntlContext);
  const [showAlert, setShowAlert] = useState(false);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const timeout = useRef(null);
  const cardholder = utils.getCustomerInfo();
  const initialBotSetup = {
    isMobile: getQueryVariable('mobileApp') || '',
    first_name: getQueryVariable('first_name') || '',
    last_name: getQueryVariable('last_name') || '',
    lang: getQueryVariable('lang') || '',
    email: getQueryVariable('email') || '',
    customer_id: getQueryVariable('customer_id') || '',
    source: getQueryVariable('source') ? getQueryVariable('source') : 'Web',
  };

  useEffect(() => {
    const timeAlert = setTimeout(() => {
      if (!initialBotSetup.isMobile) {
        setShowAlert(true);
        trackEventUserAction(
          '#### EINSTEIN BOT - CALL TO ACTION APARECEU PARA O USUÁRIO ####',
          true
        );
      }
    }, 120000);
    return () => clearTimeout(timeAlert);
  }, []);

  useEffect(() => {
    const checkUserIsLogged = async () => {
      try {
        console.log('#CHECKING IF THE USER IS LOGGED IN');
        if (!utils.isUserLogged()) {
          await userServices.getUserInfo();
        }
        const cn = utils.getCn();
        const { data } = await userServices.getCHInfo(cn);
        console.log('#LOGGED USER');
        if (window.embedded_svc) {
          console.log('#LOAD WINDOW.EMBEDDED_SVC');

          initialBotSetup.customer_id = cn;
          initESW(
            'https://service.force.com',
            definitiveIdiom,
            intl,
            country,
            initialBotSetup,
            trackEventUserAction,
            setShowAlert
          );

          window.embedded_svc.settings.prepopulatedPrechatFields = {
            FirstName:
              data && data.person && data.person.first_name
                ? data.person.first_name
                : '',
            LastName:
              data && data.person && data.person.last_name
                ? data.person.last_name
                : '', // LastName value
            Email: data && data.email ? data.email : '',
            Language__c: languages[idiom],
          };
          console.log('#INIT PRECHAT');
        }
      } catch (error) {
        if (window.embedded_svc) {
          initESW(
            'https://service.force.com',
            definitiveIdiom,
            intl,
            country,
            initialBotSetup,
            trackEventUserAction,
            setShowAlert
          );
          formBotEmpty(definitiveIdiom, initialBotSetup);
        } else {
          let scriptVisaGateway = document.createElement('script');
          scriptVisaGateway.setAttribute(
            'src',
            `${process.env.REACT_APP_CHATBOT_UST_URL_VISA_GATEWAY}/embeddedservice/5.0/esw.min.js`
          );
          scriptVisaGateway.onload = function () {
            initESW(
              null,
              definitiveIdiom,
              intl,
              country,
              initialBotSetup,
              trackEventUserAction,
              setShowAlert
            );
          };
        }
      }
    };
    if (definitiveIdiom) {
      let idSalesforceScript = document.getElementById('scriptSalesforce');

      if (!idSalesforceScript) {
        let scriptForce = document.createElement('script');
        scriptForce.setAttribute(
          'src',
          'https://service.force.com/embeddedservice/5.0/esw.min.js'
        );
        scriptForce.setAttribute('id', 'scriptSalesforce');
        scriptForce.onload = function () {
          console.log(
            '#### LOADING EMBEDDED SVC WITH SUCCESS ####',
            window.embedded_svc
          );
          checkUserIsLogged();
        };
        document.body.appendChild(scriptForce);
      }
    }
  }, [definitiveIdiom]);

  const closeCallToAction = () => {
    trackEventUserAction(
      '#### EINSTEIN BOT - USUÁRIO FECHOU O CALL TO ACTION ####',
      true
    );
    setShowAlert(false);
  };

  const navigationMenuKeyboard = useCallback(event => {
    if (event.key === 'Escape') {
      setShowAlert(false);
    }
  }, []);

  useEffect(() => {
    if (showAlert) {
      const btnMenuCountryList = document.getElementById('btnCloseChatSofia');
      btnMenuCountryList.addEventListener('keyup', navigationMenuKeyboard);
    }
  }, [showAlert]);

  return (
    <>
      {showAlert && SHOW_ALERT_CHATBOT && REACT_APP_CHATBOT_UST ? (
        <div className={`${styles.alertChat} alertChat`}>
          <div className={styles.iconChat}>
            <img
              alt="Chat"
              src="https://images.prismic.io/prod-axa/63f9c13c-014a-4115-bff0-1ea3128dde25_Chatnegative24.png?auto=compress,format"
            />
          </div>
          <button
            tabIndex={0}
            aria-label={intl.BUTTON_CLOSE}
            className={styles.iconClose}
            onClick={() => closeCallToAction()}
            id="btnCloseChatSofia"
          >
            <CloseIcon width={15} height={15} />
          </button>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.ALERT_TEXT_CHATBOT(cardholder)),
            }}
          ></span>

          <span className={styles.rotateIcon}>
            <ArrowRightIcon width={15} height={15} />
          </span>
        </div>
      ) : null}
    </>
  );
};

export default ChatbotUST;
