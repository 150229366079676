// EXTERNAL REFERENCE
export const price_protection = 'Price_Protection';
export const purchase_protection = 'Purchase_Protection';
export const emergency_medical_assistance = 'EMC_Emergency_Medical_Assistance';
export const extended_warranty = 'Extended_Warranty';
export const trip_delay = 'Trip_Delay';
export const trip_cancellation = 'Trip_Cancellation';
export const baggage_loss = 'Baggage_Loss';
export const car_rental = 'Auto_Rental_Insurance';
export const missed_connection = 'Missed_Connection';
export const baggage_delay = 'Baggage_Delay';
export const travel_accident_insurance = 'Travel_Accident_Insurance';
export const insured_journey_accidental = 'Injured_Journay_Accidental_D_D';
export const cellphone_protection = 'Cellphone_Protection';
export const bag_protection = 'Bag_Protection';
export const corporate_liability_waiver = 'Corporate_Liability_Waiver';

export const allowedClaimReimbursmentUpdate = process.env
  .REACT_APP_CLAIMS_ENABLE_TO_EDIT_VIEW
  ? process.env.REACT_APP_CLAIMS_ENABLE_TO_EDIT_VIEW.split(',')
  : [];

export const isAllowedClaimReimbursmentUpdate = claimType => {
  return allowedClaimReimbursmentUpdate.indexOf(claimType) > -1;
};
