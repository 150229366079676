export const TEXT_REQUIRED_FIELD = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_DATE_FOR_COUNTRIES = {
  en_US: 'Rental date must be before July 1st 2021.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'La fecha de alquiler debe ser anterior al 1 de julio de 2021.',
};
