/* eslint-disable no-unused-vars */
import React from 'react';

import { bankServices } from '../../../../../../../services';
import { checkChanged } from './changed';
import { formatData } from './fomartData';
import { newFomartBankData } from './newFomartBankData';
import ModalWithImage from '../../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../../components/ModalResult/ModalResult';
import ModalCreditCard from '../../../../../../../assets/images/modal/modal_image_credit_card.png';
import ModalInformation from '../../../../../../../components/ModalInformation/ModalInformation';

export const checkBankInfoChange = async (
  trackEventUserAction,
  props,
  confirmToSave = true,
  isProfile,
  updateDataFields,
  setBankAccount
) => {
  const { actions, intl, cn, initalValueCompare, setInitialValueCompare } =
    props;

  const isDataChanged = isProfile ? true : checkChanged(props);
  trackEventUserAction(
    '#### (CLAIM) CLICOU NO SUBMIT -- CHECK BANK INFO CHANGE ####',
    isDataChanged
  );

  if (isDataChanged) {
    const savePromisse = new Promise((resolve, reject) => {
      const updateCardHolder = async () => {
        try {
          actions.modal.showLoading(intl.SAVING_BANK_INFORMATION);
          const formatedData = formatData(props, isProfile);

          const bankCountryView =
            props.values.reimbursement.BankAccount.bankCountryView;
          const newFormatedBankData = newFomartBankData(
            formatedData,
            bankCountryView,
            props.initialValues,
            isProfile
          );
          const bank_account_id =
            props.values.reimbursement.BankAccount.bank_account_id;
          trackEventUserAction(
            '#### (CLAIM) CLICOU NO SUBMIT -- SALVANDO NOVAS INFORMAÇÕES DO BANK INFO ####',
            { bank_account_id, newFormatedBankData }
          );

          if (initalValueCompare && setInitialValueCompare) {
            initalValueCompare.reimbursement = props.values.reimbursement;
            setInitialValueCompare(initalValueCompare);
          }

          if (bank_account_id) {
            await bankServices.updateBankInfo(
              cn,
              bank_account_id,
              newFormatedBankData
            );
            trackEventUserAction(
              '#### (CLAIM) CLICOU NO SUBMIT -- SALVOU BANK INFO SE TEM BANK_ACCOUNT_ID ####',
              { bank_account_id, newFormatedBankData }
            );
            actions.modal.hideLoading();
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="success"
                message={intl.MODAL_CONFIRMATION_UPDATE_BANK_SUCCESS}
              />,
              true,
              true
            );
          } else {
            const { data } = await bankServices.createBankAccount(
              cn,
              newFormatedBankData
            );
            if (setBankAccount) {
              setBankAccount(cn, data.bank_account_id);
            }
            trackEventUserAction(
              '#### (CLAIM) CLICOU NO SUBMIT -- SALVOU BANK INFO SE NÃO TEM BANCK_ACCOUNT_ID ####',
              { bank_account_id, newFormatedBankData }
            );
            actions.modal.hideLoading();
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="success"
                message={intl.MODAL_CONFIRMATION_CREATE_BANK_SUCCESS}
              />,
              true,
              true
            );
          }

          if (updateDataFields) {
            updateDataFields();
          }
        } catch (e) {
          trackEventUserAction(
            '#### (CLAIM) CLICOU NO SUBMIT -- ERRO AO SALVAR BANK INFO ####',
            e
          );
          actions.modal.hideLoading();
          actions.modal.showModal(
            false,
            true,
            <ModalInformation type="error" message={'Algo deu errado'} />,
            true,
            true
          );
        } finally {
          resolve();
        }
      };

      if (confirmToSave) {
        actions.modal.showModal(
          false,
          true,
          <ModalWithImage
            type="question"
            title={intl.MODAL_CONFIRMATION_UPDATE_BANK}
            image={ModalCreditCard}
          >
            <ModalResult
              textConfirm={intl.TEXT_YES_I_DO}
              clickConfirm={updateCardHolder}
              textDeny={intl.TEXT_NO}
              clickDeny={() => {
                resolve();
                actions.modal.closeModal();
              }}
            />
          </ModalWithImage>,
          true,
          true
        );
      } else {
        updateCardHolder();
      }
    });

    return savePromisse;
  }
};
