/* eslint-disable no-unused-vars */
export const IDTheftProtectionInitialValues = (data, idiom) => {
  let person = data && data.person ? data.person : '';

  return {
    incident: {
      date: '',
      hour: '',
      location: {
        street_address: '',
        postal_code: '',
        locality: '',
        country: '',
        subdivision: '',
        state: '',
      },
      description: '',
      kind: 'THEFT_OF_IDENTITY',
      withdrawn_amount: {
        value: '',
        currency: '',
      },
      stolen_amount: {
        value: '',
        currency: '',
      },
    },
    affected_person: {
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      },
      relationship: 'Cardholder',
      comment: '',
    },
    amount_paid: {
      is_internal: false,
      checked: false,
      amount: {
        value: '',
        currency: '',
      },
    },
    time_off: {
      is_internal: false,
      checked: false,
      amount: {
        value: '',
        currency: '',
      },
    },
    falsely_opened: {
      is_internal: false,
      checked: false,
      amount: {
        value: '',
        currency: '',
      },
    },
    other_expenses: {
      is_internal: false,
      checked: false,
      amount: {
        value: '',
        currency: '',
      },
    },
  };
};
