/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './FormTokenexATSP.module.scss';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../..//intl';
import * as translations from './intl';
import Input from '../../../../../../components/Input/Input';
import ModalWithImage from '../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../components/ModalResult/ModalResult';
import ModalCreditCard from '../../../../../../assets/images/modal/modal_image_credit_card.png';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import InformationArea from '../InformationArea/InformationArea';
import { createIframeTokenex } from './FormTokenexATSPConfig';
import Installments from '../Installments/Installments';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

let IS_VALID = false;

const openModalSuccess = (actions, intl) => {
  return actions.modal.showModal(
    false,
    false,
    <ModalWithImage
      type="success"
      title={intl.TEXT_SUCCESS}
      image={ModalCreditCard}
    >
      <ModalResult
        textConfirm={intl.OK}
        clickConfirm={() => window.location.reload()}
      />
    </ModalWithImage>,
    true,
    true
  );
};

const updateBinWithTokenex = async (
  token,
  values,
  infoUser,
  actions,
  history,
  intl,
  setTokenPan,
  iframe,
  setLoadingToken,
  handleSubmit,
  trackEventUserAction
) => {
  try {
    trackEventUserAction('#### (PAN) PAN SERÁ ATUALIZADO', true);
    setLoadingToken(false);
    values.payment.number = token;
    handleSubmit();
    trackEventUserAction('#### (PAN) PAN ATUALIZADO', token);
  } catch (error) {
    trackEventUserAction('#### (PAN) ERRO AO ATUALIZAR PAN', {
      error,
      error_response: error.response,
    });
    if (
      error.response &&
      error.response.data &&
      error.response.data.error_description === 'Duplicated Credit Card Token'
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.ALREADY_USED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.error_description === 'Invalid credit card token'
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={intl.LENGTH_16_DIGITS} />,
        true,
        false
      );
    } else {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true,
        false
      );
    }
    setTokenPan('');
    iframe.reset();
    setLoadingToken(false);
  }
};

const callEventTokenize = (
  data,
  values,
  setTokenPan,
  tokenPAN,
  infoUser,
  actions,
  history,
  intl,
  iframeToken,
  setLoadingToken,
  handleSubmit,
  tokenIsValid,
  trackEventUserAction
) => {
  const getToken = JSON.stringify(data.token);
  const token = getToken.slice(1, -1);
  setTokenPan(token);
  updateBinWithTokenex(
    token,
    values,
    infoUser,
    actions,
    history,
    intl,
    setTokenPan,
    iframeToken,
    setLoadingToken,
    handleSubmit,
    trackEventUserAction
  );
};

const loadingEventsTokenex = (
  iframe,
  infoUser,
  values,
  intl,
  setTokenPan,
  tokenPAN,
  setMessageTokenError,
  messageTokenError,
  actions,
  history,
  setLoadingToken,
  handleSubmit,
  setTokenIsValid,
  tokenIsValid,
  trackEventUserAction
) => {
  iframe.on('tokenize', function (data) {
    if (IS_VALID) {
      callEventTokenize(
        data,
        values,
        setTokenPan,
        tokenPAN,
        infoUser,
        actions,
        history,
        intl,
        iframe,
        setLoadingToken,
        handleSubmit,
        tokenIsValid,
        trackEventUserAction
      );
    }
  });
  iframe.on('validate', function (data) {
    callEventValidate(
      data,
      iframe,
      intl,
      setMessageTokenError,
      messageTokenError,
      setLoadingToken,
      setTokenIsValid,
      trackEventUserAction
    );
  });
};

const callEventValidate = (
  data,
  iframe,
  intl,
  setMessageTokenError,
  messageTokenError,
  setLoadingToken,
  setTokenIsValid,
  trackEventUserAction
) => {
  IS_VALID = false;
  trackEventUserAction('#### (PAN) PAN VAI SER VALIDADO', true);
  if (data && !data.isValid) {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_FORMAT_INVALID);
  } else if (data && data.cardType && data.cardType != 'visa') {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_NOT_VISA_PRODUCT);
  } else if (
    data &&
    data.cvvValidator &&
    data.cvvValidator == 'required' &&
    !data.isCvvValid
  ) {
    setLoadingToken(false);
    setMessageTokenError(intl.TEXT_FORMAT_INVALID);
  } else if (data && data.isValid) {
    IS_VALID = true;
    setTokenIsValid(true);
    setMessageTokenError('');
  }
  trackEventUserAction('#### (PAN) VALIDADO COM ÊXITO', true);
};

const FormTokenexATSP = props => {
  const { values, history, handleSubmit, submitRef, setFieldTouched, isValid } =
    props;

  const { actions, utils } = useContext(StateContext);
  const { translate, idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const [iframeToken, setIframeToken] = useState(null);
  const [tokenPAN, setTokenPan] = useState('');
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [messageTokenError, setMessageTokenError] = useState('');
  const cn = utils.getCn();
  const [loading, setLoading] = useState(true);
  const [statusLoadingToken, setLoadingToken] = useState(false);
  const { isAfluent } = useContext(ThemeContext);
  const infoUser = {
    customer_id: cn,
    policy_id: '',
    external_reference: '',
  };

  const _onsubmit = async values => {
    try {
      setFieldTouched('payment.nombre');
      setFieldTouched('payment.date');
      trackEventUserAction('#### (PAN) SUBMIT DO FORMULÁRIO', true);
      setMessageTokenError('');
      if (isValid) {
        loadingEventsTokenex(
          iframeToken,
          infoUser,
          values,
          intl,
          setTokenPan,
          tokenPAN,
          setMessageTokenError,
          messageTokenError,
          actions,
          history,
          setLoadingToken,
          handleSubmit,
          setTokenIsValid,
          tokenIsValid,
          trackEventUserAction
        );
        iframeToken.tokenize();
      }
    } catch (error) {
      trackEventUserAction('#### (PAN) ERROR NO SUBMIT DO FORMULÁRIO', error);
    }
  };

  useEffect(() => {
    let iframe;
    setLoading(true);
    async function createAndLoadingTokenex() {
      try {
        trackEventUserAction('#### (PAN) CRIANDO FRAME TOKENEX', true);
        iframe = await createIframeTokenex(idiom, isAfluent());
        iframe.load();
        setIframeToken(iframe);
        setLoading(false);
        trackEventUserAction('#### (PAN) FRAME TOKENEX CRIADO', true);
      } catch (error) {
        trackEventUserAction('#### (PAN) ERROR FRAME TOKENEX', {
          error,
          response: error.response,
        });
      }
    }
    createAndLoadingTokenex();

    return () => {
      iframe && iframe.remove();
    };
  }, []);

  useEffect(() => {
    if (!loading && messageTokenError.length > 0) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={messageTokenError} />,
        true,
        false
      );
    }
  }, [messageTokenError]);

  useEffect(() => {
    if (statusLoadingToken) {
      actions.loadingFullScreen.showLoadingFullScreen(intl.SAVING_YOUR_CARD);
    } else {
      actions.loadingFullScreen.hideLoadingFullScreen();
    }
  }, [statusLoadingToken]);

  return (
    <div className={`${styles.tokenex}`}>
      <div className="row">
        <div className="col-12">
          <Input
            name="payment.nombre"
            label={intl.FULL_NAME}
            formikProps={props}
            axa
          />
        </div>
      </div>

      <div className={`${styles.boxForm} row`}>
        <div className="col-12">
          <div id="tokenExIframeDiv" className={styles.tokenexIframe}></div>
        </div>

        <div className="col-12">
          <Input
            name="payment.date"
            label={intl.EXPIRATION_DATE}
            formikProps={props}
            mask="maskedExpirationDate"
            axa
          />
        </div>

        <div className="col-12">
          <div id="cvvTokenex" className={styles.tokenexIframe}></div>
        </div>
      </div>
      <Installments {...props} />

      <button
        ref={submitRef}
        onClick={() => _onsubmit(values)}
        type="button"
        style={{ display: 'none' }}
        data-testid="submitButton"
      ></button>
    </div>
  );
};

export default FormTokenexATSP;
