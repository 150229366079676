import { tokenexService } from '../../../services';
import { removeNonNumeric } from '../../../utils/stringUtils';

export const formatEffectiveEndDate = (date, trackEventUserAction) => {
  if (!date || date === '') {
    trackEventUserAction(
      `#### (ADD CARD MODAL) formatEffectiveEndDate NULL`,
      null
    );
    return null;
  }

  const formatEffectiveEndDate = date.split('/');

  trackEventUserAction(
    `#### (ADD CARD MODAL) formatEffectiveEndDate DATE SPLIT`,
    { formatEffectiveEndDate }
  );
  return `20${formatEffectiveEndDate[1]}-${formatEffectiveEndDate[0]}-01`;
};

export const tryGenerateToken = async (
  panEncrypted,
  trackEventUserAction,
  cvv = null
) => {
  try {
    const resultToken = await tokenexService.generateToken(panEncrypted, cvv);
    if (!resultToken.data.success || resultToken.data.error) {
      trackEventUserAction('#### tryGenerateToken ERROR ###', {
        error: resultToken.data.error,
      });
      throw new Error(resultToken.data.error || 'notGenerateToken');
    }
    trackEventUserAction('#### tryGenerateToken ###', {
      token: resultToken.data.token,
    });

    return resultToken.data.token;
  } catch (error) {
    trackEventUserAction('#### Error generateTokenWithPanEncrypted ###', {
      error,
      message: error && error.message,
      response: error && error.response,
      data: error && error.response && error.response.data,
    });
    throw new Error(error.message || 'notGenerateToken');
  }
};

export const resetCardsSessionStorage = async (actions, modalSuccess) => {
  try {
    await actions.cards.reloadLoggedUserCards();
    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(false, true, modalSuccess, false, true);
  } catch (error) {}
};

export const getCardDuplicated = (cards, token) =>
  cards &&
  cards.data.find(
    card => card.credit_card_token && card.credit_card_token === token
  );

export const generatePanToken = async (trackEventUserAction, pan, key) => {
  const handledKey = key || document.getElementById('TxEncryptionKey').value;
  const panEncrypted = window.TxEncrypt(handledKey, removeNonNumeric(pan));

  trackEventUserAction(`#### (generatePanToken) GERANDO TOKEN`, {
    handledKey,
    panEncrypted,
  });

  return await tryGenerateToken(panEncrypted, trackEventUserAction);
};

export const generatePanEncryptedTokenWithCVV = async (
  trackEventUserAction,
  pan,
  cvv,
  key
) => {
  const handledKey = key || document.getElementById('TxEncryptionKey').value;
  const panEncrypted = window.TxEncrypt(handledKey, removeNonNumeric(pan));
  const cvvEncrypted = window.TxEncrypt(handledKey, removeNonNumeric(cvv));
  return await tryGenerateToken(
    panEncrypted,
    trackEventUserAction,
    cvvEncrypted
  );
};
