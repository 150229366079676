import { useContext, useState } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../LegalProtection.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import { safeGet } from '../../../../../../../../utils/object';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import ThirdPartyPhone from '../ThirdPartyPhone/ThirdPartyPhone';
import ThirdPartyAddress from '../ThirdPartyAddress/ThirdPartyAddress';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const EventDetails = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;
  const [isThereInvolvedPerson, setIsThereInvolvedPerson] = useState(false);

  return (
    <div className={`row ${styles.phoneArea}`}>
      <div className="col-12">
        <GroupDivisor title={intl.TITLE_EVENT_LOCATION} />
      </div>

      <div className={`col-12 col-sm-6 col-md-6`}>
        <Calendar
          value={values.incident.date}
          label={intl.FIELD_START_DATE}
          name="incident.date"
          dataTestid="inputConsultationStartDate"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.date')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.date')}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-6 col-md-6">
        <Input
          value={values.incident.description}
          label={intl.FIELD_DESCRIPTION}
          name="incident.description"
          dataTestid="inputIncidentDescription"
          type="text"
          onChange={handleChange}
          touched={touched.incident && touched.incident.description}
          onBlur={handleBlur}
          error={errors.incident && errors.incident.description}
        />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Select
          label={intl.FIELD_COUNTRY}
          value={values.incident.location.country}
          name="incident.location.country"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.FIELD_COUNTRY}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          onBlur={handleBlur}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.country
          }
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.country
          }
        />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Input
          label={intl.FIELD_STATE}
          name="incident.location.state"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.incident.location.state}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.state
          }
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.state
          }
          maxLength="100"
        />
      </div>

      <div className="col-12 col-md-6 col-lg-4">
        <Input
          label={intl.FIELD_LOCALITY}
          name="incident.location.locality"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.incident.location.locality}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.locality
          }
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.locality
          }
          maxLength="100"
        />
      </div>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_THIRD_PARTY}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_THIRD_PARTY}
              description={intl.DESCRIBE_THIRD_PARTY_INVOLVED}
              defaultChecked={false}
              text={intl.TEXT_OPTION_YES}
              name={`incident.involved_person.checked`}
              value={true}
              onChange={e => {
                setIsThereInvolvedPerson(true);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_THIRD_PARTY}
              defaultChecked={true}
              text={intl.TEXT_OPTION_NO}
              name={`incident.involved_person.checked`}
              value={false}
              onChange={e => {
                setIsThereInvolvedPerson(false);
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        {isThereInvolvedPerson && (
          <Input
            label={intl.FIELD_NAME}
            dataTestid="involvedPersonFirstName"
            maxLength="40"
            name="incident.involved_person.person.first_name"
            value={values.incident.involved_person.person.first_name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={safeGet(
              touched,
              'incident.involved_person.person.first_name'
            )}
            error={safeGet(
              errors,
              'incident.involved_person.person.first_name'
            )}
          />
        )}
      </div>
      <div className={`col-12 col-sm-12 col-md-6 `}>
        {isThereInvolvedPerson && (
          <Input
            label={intl.FIELD_LAST_NAME}
            type="text"
            name="incident.involved_person.person.last_name"
            maxLength="80"
            dataTestid="involvedPersonLastName"
            value={values.incident.involved_person.person.last_name}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={safeGet(
              touched,
              'incident.involved_person.person.last_name'
            )}
            error={safeGet(errors, 'incident.involved_person.person.last_name')}
          />
        )}
      </div>
      {isThereInvolvedPerson && (
        <>
          <div className="col-12 ">
            <ThirdPartyPhone {...props} />
          </div>
          <div className="col-12 ">
            <ThirdPartyAddress {...props} />
          </div>
        </>
      )}
    </div>
  );
};

export default EventDetails;
