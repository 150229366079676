export const OUTBOUND = {
  en_US: 'Outbound',
  pt_BR: 'Saída',
  es_ES: 'Salida',
};

export const OUTBOUND_DATE = {
  en_US: 'Outbound date',
  pt_BR: 'Data de saída',
  es_ES: 'Fecha de salida',
};

export const RETURN = {
  en_US: 'Return',
  pt_BR: 'Retorno',
  es_ES: 'Regreso',
};

export const RETURN_DATE = {
  en_US: 'Return date',
  pt_BR: 'Data de retorno',
  es_ES: 'Fecha de regreso',
};

export const TRAVEL = {
  en_US: 'Travel',
  pt_BR: 'Viagem',
  es_ES: 'Viaje',
};

export const NUMBER_PERSON = {
  en_US: 'Passengers',
  pt_BR: 'Passageiros',
  es_ES: 'Pasajeros',
};

export const TEXT_CONFIRM = {
  en_US: 'Confirm',
  pt_BR: 'Confirmar',
  es_ES: 'Confirmar',
};

export const LABEL_INFORMATION_IS_CORRECT = {
  en_US: `The above information matches my trip.
If not, create another certificate.`,
  pt_BR: `As informações acima correspondem à minha viagem.
Caso contrário, crie outro certificado.`,
  es_ES: `La información anterior coincide con mi viaje.
Si no, cree otro certificado.`,
};

export const LABEL_CREATE_CERTIFICATE = {
  en_US: 'Generate a new certificate',
  es_ES: 'Genera un nuevo certificado',
  pt_BR: 'Crie um novo bilhete',
};
