import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';

/* eslint-disable no-unused-vars */
export const legalProtectionFormInitialValues = (data, idiom) => {
  let person = data && data.person ? data.person : '';
  return {
    incident: {
      date: '',
      location: {
        street_address: '',
        postal_code: '',
        locality: '',
        country: '',
        subdivision: '',
        state: '',
        complement: '',
      },
      description: '',
      expenses: [
        {
          type: 'ANY_OTHER_REASON',
          amount: {
            value: '',
            currency: '',
          },
          description: '',
        },
      ],
      involved_person: {
        checked: false,
        person: {
          first_name: '',
          last_name: '',
        },
        address: {
          street_address: '',
          postal_code: '',
          locality: '',
          country: '',
          subdivision: '',
          state: '',
        },
        phone: {
          phone_type: PHONE_TYPE_MOBILE,
          international_prefix: '',
          number: '',
        },
      },
    },
    affected_person: {
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
        birth_date: person && person.birth_date ? person.birth_date : '',
        gender: person && person.gender ? person.gender : '',
      },
      relationship: 'Cardholder',
    },
    other_insurance_covering: [
      {
        is_internal: false,
        amount: {
          value: '',
          currency: '',
          checked: false,
        },
        description: 'ALREADY_COMPENSATED',
      },
      {
        is_internal: false,
        amount: {
          value: '',
          currency: '',
          checked: false,
        },
        description: 'OTHER_INSURANCE',
      },
    ],
    total_amount_claimed: {
      value: '',
      currency: '',
    },
    amount_estimation_damage: {
      value: '',
      currency: '',
    },
    contact_phones: [
      {
        phone_type: PHONE_TYPE_MOBILE,
        international_prefix: '',
        number: '',
      },
    ],
  };
};
