import { useContext } from 'react';
import styles from './index.module.scss';
import { parseISO } from 'date-fns';
import AddIcon from '../../../../../../assets/icons/AddIcon';
import Input from '../../../../../../components/Input/Input';
import { IntlContext } from '../../../../../../intl';
import { removeRequiredCharLabel } from '../../../../../../utils/stringUtils';
import { getEmptySegment } from '../../initialValues';
import FlightLeg from './FlightLeg';
import * as translations from './intl';
import { FormikProps } from 'formik';
import { FlightDetailsSchema } from '../../validationSchema';
import { FullTravelInsuranceCertificateDetail } from '../../../../../../@types/APIs/Certificate';
import Button from '../../../../../../componentsV2/Button/Button';

interface FlightsAreaProps {
  formikProps: FormikProps<FlightDetailsSchema>;
  isReturn?: boolean;
  certificate: FullTravelInsuranceCertificateDetail;
}

const FlightsArea = ({
  formikProps,
  isReturn,
  certificate,
}: FlightsAreaProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const flightsKey = isReturn ? 'return' : 'outbound';
  const segments = formikProps.values[flightsKey].segments;

  const addNewLeg = () => {
    const travelInformation = certificate.travel_information;
    const outwardDate = parseISO(travelInformation.from);
    const returnDate = parseISO(travelInformation.to);

    const value = [...segments].concat([
      getEmptySegment(isReturn ? returnDate : outwardDate),
    ]);
    formikProps.setFieldValue(`${flightsKey}.segments`, value);
  };

  return (
    <div className="row">
      <div className={`col-12 ${styles.inputNoBackground}`}>
        <Input
          value={segments[0].airport_origin_name}
          label={removeRequiredCharLabel(intl.LABEL_DEPARTURE_AIRPORT)}
          name={isReturn ? 'return_airport_origin' : 'outbound_airport_origin'}
          disabled
          removeBottonBarColor
        />
      </div>
      {segments.map((_, index) => (
        <FlightLeg
          key={`${flightsKey}.segments[${index}]`}
          name={`${flightsKey}.segments[${index}]`}
          formikProps={formikProps}
          isReturn={isReturn}
          legIndex={index}
          certificate={certificate}
        />
      ))}
      <div className={`col-12 ${styles.addButtonContainer}`}>
        <Button
          type="Tertiary"
          onClick={addNewLeg}
          label={intl.LABEL_ADD_LEG}
          leadingIcon={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default FlightsArea;
