export const VALIDATION_MESSAGE_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATION_MESSAGE_NAME = {
  en_US: 'Please enter at least one letter (A-Z).',
  pt_BR: 'Por favor, insira pelo menos uma letra (A-Z).',
  es_ES: 'Por favor, introduzca al menos una letra (A-Z).',
};

export const VALIDATION_MESSAGE_DATE = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATION_MESSAGE_EMAIL = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un correo electrónico válido.',
};

export const VALIDATION_MESSAGE_SAME_EMAIL = {
  en_US: 'E-mails must match.',
  pt_BR: 'Os e-mails devem ser iguais.',
  es_ES: 'Correo electrónico debe coincidir.',
};

export const VALIDATION_MESSAGE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
