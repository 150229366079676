import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

const OpenApp = () => {
  useEffect(() => {
    if (isAndroid) {
      document.location = 'com.visa.lac.benefits://com.visa.lac.benefits/home';
      setTimeout(() => {
        document.location = process.env.REACT_APP_ANDROID_STORE.toString();
      }, 2000);
    } else if (isIOS) {
      document.location = 'com.visa.lac.benefits://home';
      setTimeout(() => {
        document.location = process.env.REACT_APP_IOS_STORE.toString();
      }, 2000);
    } else {
      document.location = '/';
    }
  }, []);

  return <></>;
};

export default OpenApp;
