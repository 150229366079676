import { userServices } from '../../../../services';

export const loadData = async (cn, setData) => {
  try {
    const responses = await userServices.getCHInfo(cn);
    const data = responses.data;
    setData(data);
  } catch (error) {
    setData('ERROR');
  }
};
