/* LANDING PAGE */
export const LANDING_PAGE_TITLE = {
  en_US: 'Download the app to continue with your medical assistance.',
  pt_BR: 'Baixe o app para continuar com sua assistência médica.',
  es_ES: 'Descarga el app para continuar con tu asistencia médica.',
};

export const LANDING_PAGE_SUBTITLE = {
  en_US: 'This functionality is available only to mobile.',
  pt_BR: 'Essa funcionalidade está disponível apenas para celular.',
  es_ES: 'Esta funcionalidad solo está disponible para dispositivos móviles.',
};

export const TITLE_APPOINTMENTS = {
  en_US: 'My appointments',
  es_ES: 'Mis citas',
  pt_BR: 'Meus agendamentos',
};

export const SUBTITLE = {
  en_US: 'Check your recent Visa Medic Online appointments below',
  pt_BR: 'Veja seus agendamentos recentes do Visa Médico Online abaixo',
  es_ES: 'Revisa tus citas recientes de Visa Médico Online a continuación',
};

export const TEXT_APPONTMENT_EMPTY_LIST = {
  en_US: 'You have no appointment created.',
  pt_BR: 'Você não tem nenhum agendamento criado.',
  es_ES: 'No hay citas creadas.',
};

export const BUTTON_REQUEST_APPOINTMENT = {
  en_US: 'Request an appointment',
  pt_BR: 'Solicite um agendamento',
  es_ES: 'Pedir una cita',
};
