import React, { useContext, useState, useEffect } from 'react';
import styles from './BankFields.module.scss';
import Input from '../../../../../../../../components/Input/Input';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../../intl';
import { safeGet } from '../../../../../../../../utils/object';
import { inArray } from '../../../../../../../../utils/array';
import { VALUE_AR, VALUE_CO, VALUE_GT, VALUE_MX } from '../../constants';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const SWIFT = 'SWIFT';
const ABA = 'ABA';

const BankFields = props => {
  const {
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    touched,
    handleBlur,
    errors,
    initialValues,
  } = props;
  const initialPreference =
    values.BankAccount &&
    values.BankAccount.SWIFT &&
    values.BankAccount.SWIFT.value
      ? SWIFT
      : values.BankAccount &&
        values.BankAccount.ABA &&
        values.BankAccount.ABA.value
      ? ABA
      : SWIFT;

  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [otherCountryPreference, setOtherCountryPreference] =
    useState(initialPreference);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const changePreference = preference => {
    if (preference === SWIFT) {
      setOtherCountryPreference(SWIFT);
      setFieldValue('BankAccount.ABA.value', '');
    } else {
      setOtherCountryPreference(ABA);
      setFieldValue('BankAccount.SWIFT.value', '');
    }
  };

  const checkSameAccountNumber = () => {
    if (!values.BankAccount.bank.bankInfoChange) {
      return (
        values.BankAccount.bank.bank_account_number ===
        initialValues.BankAccount.bank.bank_account_number
      );
    }

    return (
      values.BankAccount.bank.bank_account_number ===
      values.BankChange.BankAccount.bank.bank_account_number
    );
  };
  const getInicialAccountNumber = () => {
    if (!values.BankAccount.bank.bankInfoChange) {
      return initialValues.BankAccount.bank.bank_account_number;
    }
    return values.BankChange.BankAccount.bank.bank_account_number;
  };

  useEffect(() => {
    changePreference(initialPreference);
  }, [initialPreference]);

  const isSameAccountNumber = checkSameAccountNumber();
  const countryOptions = [VALUE_AR, VALUE_CO, VALUE_GT, VALUE_MX];
  const initialAccountNumber = getInicialAccountNumber();

  return (
    <div className={`row ${styles.section}`}>
      <div className="col-12 col-md-4">
        <Input
          value={safeGet(values, 'BankAccount.bank.bank_account_number') || ''}
          label={intl.FORM_LABEL_ACCOUNT_NUMBER}
          name="BankAccount.bank.bank_account_number"
          tooltipText={intl.TOOLTIP_FORM_LABEL_ACCOUNT_NUMBER}
          dataTestid="InputBankInfoAccountNumber"
          onChange={handleChange}
          type="text"
          maxLength="34"
          mask={
            isSameAccountNumber
              ? 'bankAccountNumberDefault'
              : 'internationalBankAccountNumber'
          }
          touched={safeGet(touched, 'BankAccount.bank.bank_account_number')}
          onBlur={e => {
            handleBlur(e);
            if (e.target.value === '') {
              setFieldValue(
                'BankAccount.bank.bank_account_number',
                initialAccountNumber
              );
              setFieldTouched('BankAccount.bank.bank_account_number', true);
            }
          }}
          error={safeGet(errors, 'BankAccount.bank.bank_account_number')}
          onFocus={e => {
            if (e.target.value === initialAccountNumber) {
              setFieldValue('BankAccount.bank.bank_account_number', '');
              setFieldTouched('BankAccount.bank.bank_account_number', true);
            }
          }}
        />
      </div>

      {values.BankAccount.bankCountryView === VALUE_AR && (
        <div className="col-12 col-md-4">
          <Input
            value={safeGet(values, 'BankAccount.CUIT.value') || ''}
            label={intl.FORM_LABEL_CUIT}
            name="BankAccount.CUIT.value"
            dataTestid="InputBankInfoCuitValue"
            onChange={handleChange}
            type="text"
            touched={safeGet(touched, 'BankAccount.CUIT.value')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.CUIT.value')}
            mask={idiom === 'es_ES' ? 'maskedCuit_es_AR' : 'maskedCuit'}
          />
        </div>
      )}

      {values.BankAccount.bankCountryView === VALUE_AR && (
        <div className="col-12 col-md-4">
          <Input
            value={safeGet(values, 'BankAccount.FULL_KEY.value') || ''}
            label={intl.FORM_LABEL_CLAVE_BANCARIA}
            name="BankAccount.FULL_KEY.value"
            dataTestid="InputBankInfoKeyValue"
            onChange={handleChange}
            mask={idiom === 'es_ES' ? 'maskedCBU_es_AR' : 'maskedFullKey'}
            type="text"
            tooltipText={intl.TOOLTIP_CLAVE_BANCARIA}
            touched={safeGet(touched, 'BankAccount.FULL_KEY.value')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.FULL_KEY.value')}
          />
        </div>
      )}

      {values.BankAccount.bankCountryView === VALUE_MX && (
        <>
          <div className="col-12 col-md-4">
            <Input
              value={safeGet(values, 'BankAccount.FULL_KEY.value') || ''}
              label={intl.FORM_LABEL_CLABE}
              name="BankAccount.FULL_KEY.value"
              dataTestid="InputBankInfoKeyValue"
              onChange={handleChange}
              mask="maskedFullKey"
              type="text"
              tooltipText={intl.TOOLTIP_FULL_KEY_INFO}
              touched={safeGet(touched, 'BankAccount.FULL_KEY.value')}
              onBlur={handleBlur}
              error={safeGet(errors, 'BankAccount.FULL_KEY.value')}
            />
          </div>
          <div className="col-12 col-md-4">
            <Input
              value={
                safeGet(values, 'BankAccount.bank_identification_number') || ''
              }
              label={intl.FORM_LABEL_SWIFT_CODE}
              name="BankAccount.bank_identification_number"
              dataTestid="InputBankInfoSwiftValue"
              onChange={handleChange}
              type="text"
              mask="maskedSwift"
              tooltipText={intl.TOOLTIP_SWIFT_INFO}
              touched={safeGet(
                touched,
                'BankAccount.bank_identification_number'
              )}
              onBlur={handleBlur}
              error={safeGet(errors, 'BankAccount.bank_identification_number')}
            />
          </div>
        </>
      )}

      {values.BankAccount.bankCountryView === VALUE_GT && (
        <div className="col-12 col-md-4">
          <Input
            value={safeGet(values, 'BankAccount.IBAN.value') || ''}
            label={intl.FORM_LABEL_IBAN_CODE}
            tooltipText={intl.TOOLTIP_IBAN_COUNTRIES}
            name="BankAccount.IBAN.value"
            dataTestid="InputBankInfoIbanValue"
            onChange={handleChange}
            type="text"
            mask="maskedIban"
            touched={safeGet(touched, 'BankAccount.IBAN.value')}
            onBlur={handleBlur}
            error={safeGet(errors, 'BankAccount.IBAN.value')}
          />
        </div>
      )}

      {!inArray(countryOptions, values.BankAccount.bankCountryView) && (
        <div className={`col-12 col-md-4 ${styles.options}`}>
          <div className={`row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                describe={intl.TEXT_DESCRIBE_PREFERENCE}
                text={intl.LABEL_SWIFT_CODE}
                value={SWIFT}
                checked={otherCountryPreference === SWIFT}
                onChange={() => changePreference(SWIFT)}
              />
            </div>
          </div>
          <div className={`row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                describe={intl.TEXT_DESCRIBE_PREFERENCE}
                text={intl.LABEL_ROUTING_NUMBER}
                value={ABA}
                dataTestid="RadioABA"
                checked={otherCountryPreference === ABA}
                onChange={() => changePreference(ABA)}
              />
            </div>
          </div>
        </div>
      )}

      {!inArray(countryOptions, values.BankAccount.bankCountryView) &&
        otherCountryPreference === SWIFT && (
          <div className="col-12 col-md-4">
            <Input
              value={safeGet(values, 'BankAccount.SWIFT.value') || ''}
              label={intl.FORM_LABEL_SWIFT_CODE}
              name="BankAccount.SWIFT.value"
              dataTestid="InputBankInfoSwiftValue"
              onChange={handleChange}
              type="text"
              mask="maskedSwift"
              tooltipText={intl.TOOLTIP_SWIFT_INFO}
              touched={safeGet(touched, 'BankAccount.SWIFT.value')}
              onBlur={handleBlur}
              error={safeGet(errors, 'BankAccount.SWIFT.value')}
            />
          </div>
        )}

      {!inArray(countryOptions, values.BankAccount.bankCountryView) &&
        otherCountryPreference === ABA && (
          <div className="col-12 col-md-4">
            <Input
              value={safeGet(values, 'BankAccount.ABA.value') || ''}
              label={intl.FORM_LABEL_ROUTING_NUMBER}
              name="BankAccount.ABA.value"
              dataTestid="InputBankInfoAbaValue"
              onChange={handleChange}
              mask="maskedAba"
              type="text"
              tooltipText={intl.TOOLTIP_ABA_INFO}
              touched={safeGet(touched, 'BankAccount.ABA.value')}
              onBlur={handleBlur}
              error={safeGet(errors, 'BankAccount.ABA.value')}
            />
          </div>
        )}
    </div>
  );
};

export default BankFields;
