import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import { useFlightInfo } from '../FlightInfoContext';
import styles from './index.module.scss';
import { formatDateWithOffset } from '../../../utils/date';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const DatesRow = () => {
  const { idiom } = useContext(IntlContext);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const { flightInfo } = useFlightInfo();
  const { startAt, endAt } = flightInfo;

  return (
    <div className={styles.container}>
      <p className={`${styles.date} ${styles[theme]}`}>
        {formatDateWithOffset(startAt, idiom)}
      </p>
      <p className={`${styles.date} ${styles[theme]}`}>
        {formatDateWithOffset(endAt, idiom)}
      </p>
    </div>
  );
};

export default DatesRow;
