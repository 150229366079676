import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { clickChat } from '../../../utils/elements';
import { removeAxaPrefixFromExternalReference } from '../../../utils/B2C/string';
import Bluebox from '../../../components/Bluebox';
import { CINEPOLIS_GOLD, isTripAssistance } from '../../../utils/benefits';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './BlueBoxDefault.module.scss';

const RenderBodyBlueBox = (
  intl,
  certificate,
  claim,
  benefitDetail,
  cardHasVMO,
  label,
  links,
  hasContainPdfUrlCertificate,
  history
) => {
  const { country } = useContext(IntlContext);
  const isMX = country === 'MX';
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const isAfluent = theme === 'theme-afluent';
  const isIEMS =
    benefitDetail.benefit_id === 'EMC_Emergency_Medical_Assistance_11';

  const isCinepolis = benefitDetail.benefit_id === CINEPOLIS_GOLD;

  const isExecutiveTransportation =
    benefitDetail.benefit_id === 'Executive_Transportation_70';

  const isDiningCollection =
    benefitDetail.external_reference === 'Visa_Dining_Collection';

  if (certificate || claim) {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title
            claim={claim}
            text={isIEMS ? intl.IEMS_TITLE_PRIVATE_PAGE : undefined}
          />
        </Bluebox.SubContainer>

        {links && links.length > 0 && label && (
          <Bluebox.SubContainer>
            <Bluebox.Text text={label.replace('<br>', '')} margin={false} />
          </Bluebox.SubContainer>
        )}

        <Bluebox.SubContainer>
          {links && links.length > 0 && (
            <>
              {links.map((link, index) => (
                <Bluebox.Link
                  label={link[0]}
                  key={index}
                  onClick={() => history.push(link[1])}
                />
              ))}
            </>
          )}
          {((hasContainPdfUrlCertificate && certificate) ||
            claim ||
            (cardHasVMO && isIEMS)) && (
            <Bluebox.Text
              text={isIEMS ? intl.FOLLOW_STEPS_IEMS : intl.FOLLOW_STEPS}
              className={links?.length > 0 ? styles.marginTop : undefined}
            />
          )}
          {hasContainPdfUrlCertificate && certificate && (
            <Bluebox.Link
              id="bluebox_link_certificate"
              label={intl.GENERATE_CERTIFICATE}
              type="Primary"
              onClick={() => history.push(`/certificate/${certificate.code}`)}
            />
          )}

          {claim && (
            <Bluebox.Link
              id="bluebox_link_claim"
              label={intl.MAKE_CLAIM}
              onClick={() =>
                history.push(`/claim-type/${claim.external_reference}/generate`)
              }
            />
          )}
          {cardHasVMO && isIEMS && (
            <>
              <Bluebox.Link
                id="bluebox_link_iems"
                label={intl.ACCESS_VISA_ONLINE_MEDIC}
                onClick={() => history.push(`/your-benefits/Teleconsultation`)}
              />
            </>
          )}
        </Bluebox.SubContainer>

        <Bluebox.SubContainer center>
          <Bluebox.Text text={intl.PREFER_CLAIM} margin={false} />
          <Bluebox.Anchor
            label={intl.CHAT_TO_US}
            onClick={clickChat}
            id="bluebox_link_contact_us"
            icon={false}
            button
          />
        </Bluebox.SubContainer>
      </>
    );
  } else if (
    (benefitDetail &&
      benefitDetail.redeem_info &&
      benefitDetail.redeem_info.redeem &&
      benefitDetail.redeem_info.redeem.additional_buttons) ||
    isCinepolis ||
    (benefitDetail &&
      benefitDetail.external_reference === 'Nurse_Advice_Line') ||
    (benefitDetail &&
      benefitDetail.external_reference === 'Teleconsultation') ||
    (benefitDetail && isTripAssistance(benefitDetail))
  ) {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title
            claim={claim}
            text={
              benefitDetail.benefit_id &&
              benefitDetail.benefit_id === 'Concierge_409'
                ? intl.DIGITAL_CONCIERGE_PLATINUM_TITLE
                : null
            }
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer>
          {links && links.length > 0 && label ? (
            <Bluebox.Text text={label.replace('<br>', '')} />
          ) : (
            <Bluebox.Text
              text={
                isExecutiveTransportation
                  ? intl.SUBSCRIBE_EXECUTIVE_AIRPORT_TRANSPORTATION
                  : benefitDetail.benefit_id &&
                    benefitDetail.benefit_id === 'Concierge_409'
                  ? intl.DIGITAL_CONCIERGE_PLATINUM_DESCRIPTION
                  : intl.ACCESS_ADVANTAGE
              }
            />
          )}
          <Bluebox.ButtonBenefits
            css="buttonsinBlueBoxDefault"
            benefitDetail={benefitDetail}
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer center>
          <Bluebox.Text text={intl.PREFER_CLAIM} margin={false} />
          <Bluebox.Anchor
            label={intl.CHAT_TO_US}
            onClick={clickChat}
            id="bluebox_link_chat_us"
            icon={false}
            button
          />
        </Bluebox.SubContainer>
      </>
    );
  } else if (
    benefitDetail &&
    benefitDetail.external_reference &&
    benefitDetail.external_reference.includes('axa_')
  ) {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title text={intl.HOW_TO_PURCHASE} />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer>
          <Bluebox.Text text={intl.CLICK_BUTTON_BELOW} />

          <Bluebox.Link
            label={intl.BUY_IT}
            onClick={() =>
              history.push(
                `/cross-sell-detail/${removeAxaPrefixFromExternalReference(
                  benefitDetail.external_reference
                )}`
              )
            }
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer center>
          <Bluebox.Text text={intl.PREFER_CLAIM} margin={false} />
          <Bluebox.Anchor
            label={intl.CHAT_TO_US}
            onClick={clickChat}
            id="bluebox_link_chat_us"
            icon={false}
            button
          />
        </Bluebox.SubContainer>
      </>
    );
  } else if (
    benefitDetail &&
    benefitDetail.external_reference &&
    benefitDetail.external_reference === 'Agrobusiness_Protection'
  ) {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title text={intl.HERE_TO_HELP} />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer center>
          <Bluebox.Text text={intl.AVAIABLE_CUSTOMER_SERVICE} />
          <Bluebox.Anchor
            label={intl.CHAT_TO_US}
            onClick={clickChat}
            id="bluebox_link_chat_us"
            icon={false}
            button
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer></Bluebox.SubContainer>
      </>
    );
  } else if (isDiningCollection) {
    return (
      <>
        <Bluebox.SubContainer></Bluebox.SubContainer>
        <Bluebox.SubContainer>
          <Bluebox.Title text={intl.TABLE_TO_YOU} />
        </Bluebox.SubContainer>
        <Bluebox.SubContainer>
          <Bluebox.Text text={intl.PARTICIPATING_RESTAURANTS} />
        </Bluebox.SubContainer>
        <Bluebox.SubContainer>
          <Bluebox.Link
            id="bluebox_link_dining_collection"
            label={intl.CHECK_RESTAURANTS}
            onClick={() => {
              window.open('https://www.opentable.com/visadining', '_blank');
            }}
          />
        </Bluebox.SubContainer>
        <Bluebox.SubContainer></Bluebox.SubContainer>
      </>
    );
  } else {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title claim={claim} />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer center>
          <Bluebox.Text text={intl.YOU_CAN_SPEAK} />
          <Bluebox.Anchor
            label={intl.CHAT_TO_US}
            onClick={clickChat}
            id="bluebox_link_chat_us"
            icon={false}
            button
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer></Bluebox.SubContainer>
      </>
    );
  }
};

const publicRenderBodyBlueBox = (intl, benefitDetail, claim) => {
  if (benefitDetail.benefit_id === 'Concierge_409') {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title
            claim={claim}
            text={intl.DIGITAL_CONCIERGE_PLATINUM_TITLE}
          />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer>
          <Bluebox.Text text={intl.DIGITAL_CONCIERGE_PLATINUM_DESCRIPTION} />

          {benefitDetail?.benefit_id && (
            <Bluebox.ButtonLogin benefitId={benefitDetail.benefit_id} />
          )}
        </Bluebox.SubContainer>

        <Bluebox.SubContainer></Bluebox.SubContainer>
      </>
    );
  }

  if (benefitDetail.external_reference === 'EMC_Emergency_Medical_Assistance') {
    return (
      <>
        <Bluebox.SubContainer>
          <Bluebox.Title text={intl.IEMS_TITLE_PUBLIC_PAGE} />
        </Bluebox.SubContainer>

        <Bluebox.SubContainer>
          <Bluebox.Text text={intl.IEMS_TEXT_PUBLIC_PAGE} />

          {benefitDetail?.benefit_id && (
            <Bluebox.ButtonLogin benefitId={benefitDetail.benefit_id} />
          )}
        </Bluebox.SubContainer>

        <Bluebox.SubContainer></Bluebox.SubContainer>
      </>
    );
  }

  return (
    <>
      <Bluebox.SubContainer>
        <Bluebox.Title claim={claim} />
      </Bluebox.SubContainer>

      <Bluebox.SubContainer>
        <Bluebox.Text text={intl.ACCESS_HERE} margin={false} />

        {benefitDetail?.benefit_id && (
          <Bluebox.ButtonLogin benefitId={benefitDetail.benefit_id} />
        )}
      </Bluebox.SubContainer>

      <Bluebox.SubContainer></Bluebox.SubContainer>
    </>
  );
};

const BlueBoxDefault = props => {
  const { isLogged, certificate, claim, benefitDetail, cardHasVMO } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const history = useHistory();

  const pdfUrlCertificate =
    benefitDetail.documents &&
    benefitDetail.documents.filter(doc => doc.name === 'pdf_url_certificate');
  const hasContainPdfUrlCertificate =
    pdfUrlCertificate && pdfUrlCertificate.length > 0;

  const label =
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.bluebox &&
    benefitDetail.redeem_info.bluebox.subtitle;
  const links =
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.redeem &&
    benefitDetail.redeem_info.redeem.additional_buttons &&
    benefitDetail.redeem_info.redeem.additional_buttons
      .split(';')
      .map(linksReddem => linksReddem.split(' - '));

  return (
    <Bluebox.Container>
      {isLogged
        ? RenderBodyBlueBox(
            intl,
            certificate,
            claim,
            benefitDetail,
            cardHasVMO,
            label,
            links,
            hasContainPdfUrlCertificate,
            history
          )
        : publicRenderBodyBlueBox(intl, benefitDetail, claim)}
    </Bluebox.Container>
  );
};

export default BlueBoxDefault;
