import { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as TravelAccidentUtils from '../../../../../../utils/claimsAPI/TravelAccidentUtils';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const CardArea = props => {
  const { claimDetails, isAccidentalDeath } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [incident, setIncident] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.LABEL_INCIDENT} simpleLine />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={
            isAccidentalDeath ? intl.LABEL_DEATH_DATE : intl.LABEL_INCIDENT_DATE
          }
          value={TravelAccidentUtils.getIncidentDate(incident, idiom, country)}
        />
      </div>

      {isAccidentalDeath && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_INCIDENT_LOCATION}
            value={TravelAccidentUtils.getIncidentLocation(incident, idiom)}
          />
        </div>
      )}
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          value={TravelAccidentUtils.getIncidentDescription(incident)}
        />
      </div>
    </>
  );
};

export default CardArea;
