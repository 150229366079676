import React from 'react';
import styles from './Bluebox.module.scss';

interface BlueboxNoticeProps {
  text: string;
  center?: boolean;
}

const BlueboxNotice = ({ text, center }: BlueboxNoticeProps) => {
  return (
    <p className={`${styles.footerText} ${center ? styles.center : ''}`}>
      {text}
    </p>
  );
};

export default BlueboxNotice;
