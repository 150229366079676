import * as Yup from 'yup';
import moment from 'moment';

export const carRentalSchema = (
  intl,
  limitDate,
  exceptionCountry,
  limitedCards
) =>
  Yup.object().shape({
    car_rental: Yup.object().shape({
      from: Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (value) {
          return moment(value).isSameOrBefore(new Date());
        }),
      to: Yup.mixed().test('match', intl.VALIDATE_DATE_VALID, function (to) {
        return moment(to).isSameOrAfter(this.parent.from);
      }),
      agency: Yup.object().shape({
        name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        phone: Yup.object().shape({
          phone_type: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      payment: Yup.object().shape({
        date: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (value) {
            return moment(value).isSameOrBefore(new Date());
          }),
        amount: Yup.object().shape({
          value: Yup.number()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
        paymentMethod: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        number_of_installments: Yup.number().when(
          ['paymentMethod'],
          {
            is: paymentMethod => {
              return paymentMethod === 'installment';
            },
            then: Yup.number()
              .positive(intl.TEXT_FIELD_REQUIRED)
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          },
          ['paymentMethod']
        ),
        reservation_information: Yup.object().shape({
          hasPreviousReservation: Yup.string(),
          hasPreviousReservationPayment: Yup.string(),
          paymentMethod: Yup.string().when(
            ['hasPreviousReservation', 'hasPreviousReservationPayment'],
            {
              is: (previousReservation, previousReservationPayment) => {
                return (
                  previousReservation === 'yes' &&
                  previousReservationPayment === 'yes'
                );
              },
              then: Yup.string()
                .required(intl.TEXT_FIELD_REQUIRED)
                .typeError(intl.TEXT_FIELD_REQUIRED),
            }
          ),
          date: Yup.string().when(
            ['hasPreviousReservation', 'hasPreviousReservationPayment'],
            {
              is: (previousReservation, previousReservationPayment) => {
                return (
                  previousReservation === 'yes' &&
                  previousReservationPayment === 'yes'
                );
              },
              then: Yup.string()
                .required(intl.TEXT_FIELD_REQUIRED)
                .typeError(intl.TEXT_FIELD_REQUIRED)
                .test(
                  'test-data-valid',
                  intl.VALIDATE_DATE_VALID,
                  function (value) {
                    return moment(value).isSameOrBefore(new Date());
                  }
                ),
            }
          ),
          amount: Yup.object().when(
            ['hasPreviousReservation', 'hasPreviousReservationPayment'],
            {
              is: (previousReservation, previousReservationPayment) => {
                return (
                  previousReservation === 'yes' &&
                  previousReservationPayment === 'yes'
                );
              },
              then: Yup.object().shape({
                value: Yup.number()
                  .required(intl.TEXT_FIELD_REQUIRED)
                  .typeError(intl.TEXT_FIELD_REQUIRED),
                currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
              }),
            }
          ),
          number_of_installments: Yup.number().when(
            ['paymentMethod'],
            {
              is: paymentMethod => {
                return paymentMethod === 'installment';
              },
              then: Yup.number()
                .positive(intl.TEXT_FIELD_REQUIRED)
                .required(intl.TEXT_FIELD_REQUIRED)
                .typeError(intl.TEXT_FIELD_REQUIRED),
            },
            ['paymentMethod']
          ),
        }),
      }),
    }),
    incident: Yup.object().shape({
      description: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      date: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED)
        .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (value) {
          return moment(value).isSameOrBefore(new Date());
        })
        .test(
          'limited data for country',
          intl.VALIDATE_DATE_FOR_COUNTRIES,
          function (value) {
            return limitedCards && !exceptionCountry
              ? moment(value).isSameOrBefore(moment(limitDate))
              : true;
          }
        ),
      collision_coverage: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      payment_beneficiary: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED),
      driver: Yup.object().shape({
        license_number: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        license_issued_country: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        allOf: Yup.object().shape({
          first_name: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          last_name: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      location: Yup.object().shape({
        country: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        place: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    amount_estimation_damage: Yup.object().shape({
      value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
    other_insurance_covering: Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string().when(
        ['is_internal'],
        {
          is: is_internal => {
            return is_internal === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_internal']
      ),
    }),
  });
