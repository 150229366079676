import React, { useContext } from 'react';
import { BenefitDetail } from '../../@types/APIs/benefit';
import { IntlContext } from '../../intl';
import ButtonBenefits from '../ListCardBenefits/ListCardItem/ButtonBenefits/ButtonBenefits';

interface BlueboxButtonsBenefitsProps {
  benefitDetail: BenefitDetail;
  linksinBlueBox?: boolean;
  css: 'buttonsinBlueBox' | 'buttonsinBlueBoxDefault';
}

const BlueboxButtonsBenefits = ({
  benefitDetail,
  linksinBlueBox,
  css,
}: BlueboxButtonsBenefitsProps) => {
  const { idiom, country } = useContext(IntlContext);

  return (
    <ButtonBenefits
      css={css}
      linksinBlueBox={linksinBlueBox}
      isDetail
      benefit={benefitDetail}
      idiom={idiom}
      country={country}
    />
  );
};

export default BlueboxButtonsBenefits;
