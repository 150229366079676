export const SELECT_FLIGHT = {
  en_US: 'Select your flight:',
  pt_BR: 'Selecione seu voo:',
  es_ES: 'Selecciona tu vuelo:',
};

export const TEXT_NO_FLIGHT_FOUND = {
  en_US: 'Flight not found for this airline company and flight number.',
  pt_BR: 'Voo não encontrado para esta companhia aérea e número do voo.',
  es_ES: 'Vuelo no encontrado para esta aerolínea y número de vuelo.',
};
