export const TEXT_HOME = {
  en_US: 'Home',
  es_ES: 'Home',
  pt_BR: 'Home',
};

export const TEXT_BENEFITS = {
  en_US: 'Benefits',
  es_ES: 'Beneficios',
  pt_BR: 'Benefícios',
};

export const TEXT_OFFERS_PROMOTIONS = {
  en_US: 'Offers and promotions',
  es_ES: 'Ofertas y promociones',
  pt_BR: 'Ofertas e promoções',
};

export const TEXT_OFFERS = {
  en_US: 'Offers',
  es_ES: 'Ofertas',
  pt_BR: 'Ofertas',
};

export const TEXT_SPECIAL_BENEFITS = {
  en_US: 'Subscriptions',
  es_ES: 'Suscripciones',
  pt_BR: 'Assinaturas',
};

export const TEXT_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};

export const TEXT_CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Bilhetes de seguro',
};

export const TEXT_CLAIMS = {
  en_US: 'Claims',
  es_ES: 'Reclamos',
  pt_BR: 'Sinistros',
};

export const EMPTY_RESEARCH_LIST_MESSAGE = {
  en_US: 'No Results Found',
  es_ES: 'No se encontraron resultados',
  pt_BR: 'Nenhum Resultado Encontrado',
};

export const ARIA_LABEL_SEARCH = {
  en_US: 'Search',
  es_ES: 'Buscar',
  pt_BR: 'Buscar',
};
