import { useContext, useEffect } from 'react';
import styles from './ProductList.module.scss';
import * as translations from '../intl';
import { IntlContext } from '../../../../../../../../intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import ProductItem from './ProductItem/ProductItem';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';
import Button from '../../../../../../../../componentsV2/Button/Button';

const ProductsList = props => {
  const { values, setFieldValue, setFieldTouched } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const addProduct = event => {
    if (event) event.preventDefault();
    const listProducts = [...values.incident.concerned_items];
    const newProduct = {
      description: '',
      purchase_date: '',
      price: {
        value: '',
        currency: values.incident.concerned_items[0].price.currency || '',
      },
      other_insurance_covering: {
        is_internal: false,
        amount: {
          value: '',
          currency: values.incident.concerned_items[0].price.currency || '',
        },
      },
    };
    listProducts.push(newProduct);
    setFieldValue('incident.concerned_items', listProducts);
  };

  const removeProduct = (event, index) => {
    event.preventDefault();
    const cloneProducts = [...values.incident.concerned_items];
    cloneProducts.splice(index, 1);
    setFieldValue('incident.concerned_items', cloneProducts);
    const newTotal =
      values.total_amount_claimed.value -
      values.incident.concerned_items[index].price.value;
    setFieldValue('total_amount_claimed.value', newTotal.toFixed(2));
  };

  const setCurrency = currency => {
    setFieldValue('incident.concerned_items[0].price.currency', currency);
    setFieldTouched('incident.concerned_items[0].price.currency', true);
  };
  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
  }, []);

  return (
    <div className={`col-12 ${styles.marginBottom}`}>
      <div className={styles.add}>
        <Button
          type="Tertiary"
          onClick={addProduct}
          label={intl.BTN_ADD}
          leadingIcon={<AddIcon />}
        />
      </div>
      {values.incident.concerned_items.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <GroupDivisor title={intl.TEXT_ITEM(index + 1)} />
          </div>
          <ProductItem {...props} index={index} />
          <div className={`col-2 ${styles.footer}`}>
            {index !== 0 && (
              <Button
                type="Tertiary"
                testId={`buttonRemove-${index}`}
                onClick={event => {
                  removeProduct(event, index);
                }}
                label={intl.BTN_REMOVE}
                leadingIcon={<TrashIcon width={24} height={24} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ProductsList;
