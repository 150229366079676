export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_DEATH_DATE = {
  en_US: 'Death date *',
  pt_BR: 'Data da morte *',
  es_ES: 'Fecha de la muerte *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descreva o incidente *',
  es_ES: 'Describa el incidente *',
};

export const INCIDENT_LOCATION = {
  en_US: 'Incident location',
  pt_BR: 'Endereço do incidente',
  es_ES: 'Dirección del incidente',
};

export const QUESTION_RESULT_BANKRUPTY_OR_INSOLVENCY = {
  en_US: 'Is this claim the result of a bankruptcy / insolvency? *',
  pt_BR: 'Esta reivindicação é o resultado de uma falência / insolvência? *',
  es_ES: '¿Es esta reclamación el resultado de una quiebra / insolvencia? * ',
};

export const YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const DATE_REPORTED = {
  en_US: 'Date declared',
  pt_BR: 'Data declarada',
  es_ES: 'Fecha declarada',
};

export const QUESTION_COMPANY_RESPONSIBLE = {
  en_US:
    'Is the company responsible for charges under the company agreement? *',
  pt_BR:
    'A empresa é responsável pelos encargos ao abrigo do contrato da empresa? *',
  es_ES:
    '¿La Compañía es responsable de los cargos bajo el Acuerdo de la Compañía? *',
};

export const QUESTION_CARDHOLDER_RESPONSIBLE = {
  en_US:
    'Is the cardholder responsible for charges under the cardholder agreement? *',
  pt_BR:
    'O titular do cartão é responsável pelos encargos ao abrigo do contrato do titular  do cartão? *',
  es_ES:
    '¿El titular de la tarjeta es responsable de los cargos bajo el Contrato de Tarjetahabiente? *',
};
