import { useContext, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as translations from './intl';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt-BR';
import styles from './Calendar.module.scss';
import './Calendar.scss';
import { applyDateMask } from '../../utils/stringUtils';
import { es_ES, en_US, pt_BR } from '../../intl/idioms';
import { IntlContext } from '../../intl';
import moment from 'moment';
import iconCalendar from '../../assets/images/icon-calendar.png';
import { safeGet } from '../../utils/object';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import TooltipIcon from '../../assets/icons/TooltipIcon';

registerLocale(es_ES, es);
registerLocale(en_US, en);
registerLocale(pt_BR, pt);

const onChangeRaw = (event, onChange, idiom, name) => {
  const { target } = event;

  target.value = applyDateMask(target.value);

  if (target.value.length > 10) {
    target.value = target.value.substring(0, 10);
  }
  if (target.value.length === 10) {
    const date = formatDate(target.value, idiom);

    if (date !== 'Invalid Date' && !isNaN(date.getTime())) {
      onChange({ target: { name, value: date } });
    } else {
      onChange('');
    }
  }
};

const formatDate = (date, idiom) => {
  let newDate = '';
  if (idiom === en_US) {
    newDate = `${date.substring(6)}-${date.substring(0, 2)}-${date.substring(
      3,
      5
    )}`;
  } else {
    newDate = date.split('/').reverse().join('-');
  }
  return new Date(`${newDate}T00:00:00`);
};

const removeRequiredCharLabel = data => {
  return data && data.slice(-1) === '*'
    ? data.substring(0, data.length - 1)
    : data;
};

const Calendar = props => {
  let {
    value,
    onChange,
    label,
    error,
    touched,
    name,
    maxDate,
    minDate = new Date(1900, 0),
    setFieldTouched,
    notShowIcon,
    formikProps,
    setFieldValue,
    axa,
    tooltipText,
  } = props;
  if (formikProps) {
    onChange = onChange || formikProps.handleChange;
    setFieldTouched = formikProps.setFieldTouched;
    error = safeGet(formikProps, 'errors.' + name);
    touched = safeGet(formikProps, 'touched.' + name);
    value = safeGet(formikProps, 'values.' + name);
    setFieldValue = formikProps.setFieldValue;
  }
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const dateFormat = idiom === en_US ? 'MM/dd/yyyy' : 'dd/MM/yyyy';
  const showIcon = notShowIcon ? false : true;
  const _onChangeRaw = e => {
    setFieldTouched && setFieldTouched(name, true);
    onChangeRaw(e, onChange, idiom, name);
  };
  const _onSelect = data => {
    const event = { target: { name, value: data } };
    setFieldTouched && setFieldTouched(name, true);
    onChange(event);
  };
  const openCalendar = event => {
    if (event && event.target && event.target.parentNode) {
      const parentCalendar = event.target.parentNode;
      parentCalendar.querySelector('input').click();
    }
  };
  const { getGlobalTheme } = useTheme(axa);
  const themes = getGlobalTheme();

  let className = styles.Calendar;
  if (error && touched) className += ' ' + styles.error;
  if (value && !error) className += ' ' + styles.value;

  const formikError = error && '1';
  let dateValue;
  try {
    if (value && value !== '') {
      const fomatedValue = moment(value).format('MM/DD/YYYY');
      dateValue = new Date(fomatedValue);
    } else {
      dateValue = value;
    }
  } catch (error) {
    dateValue = value;
  }

  useEffect(() => {
    const element = document.querySelector(`input[calendar="${name}"]`);
    element.addEventListener('blur', e => {
      const name = e.target.getAttribute('calendar');
      setFieldTouched && setFieldTouched(name, true);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`${className} ${
        tooltipText ? styles.inputGroup + ' input-group mb-3' : ''
      } ${styles[themes]}`}
      data-testid="calendarContainer"
    >
      {value && (
        <label
          htmlFor={`calendar-${name}`}
          className={`${styles.label} ${styles[themes]} ${
            axa ? styles.axa : ''
          }`}
        >
          {removeRequiredCharLabel(label)}
        </label>
      )}

      <DatePicker
        id={`calendar-${name}`}
        ariaDescribedBy={`error-${name}`}
        ariaInvalid={error && touched}
        disabled={props.disabled}
        utcOffset={0}
        customInput={
          <input
            formik-error={formikError}
            calendar-formik="true"
            auto-complete="new-password"
            calendar={name}
            data-testid={props.dataTestid}
            className={styles[themes]}
          />
        }
        onChangeRaw={_onChangeRaw}
        placeholderText={label}
        selected={dateValue}
        dateFormat={dateFormat}
        locale={idiom}
        onSelect={_onSelect}
        maxDate={maxDate}
        minDate={minDate}
        data-testid="picker"
      />
      {showIcon && (
        <img
          src={iconCalendar}
          className={`${styles.icon} ${tooltipText ? styles.realign : ''}`}
          alt=""
          onClick={openCalendar}
        />
      )}
      {tooltipText && (
        <div className="input-group-prepend">
          <span
            data-tooltip
            datatitle={tooltipText}
            className={`${styles.inputGroupText} ${styles[themes]}`}
          >
            <TooltipIcon axa={axa ? 1 : 0} aria-label={intl.TOOLTIP_ICON} />
          </span>
        </div>
      )}
      {error && touched && (
        <label id={`error-${name}`} className={styles.errorLabel} role="alert">
          {error}
        </label>
      )}
    </div>
  );
};

export default Calendar;
