import moment from 'moment';

export const filterNotExpired = (certificates, filterDate) => {
  return certificates.filter(certificate => {
    if (
      certificate.valid_until === undefined ||
      certificate.valid_until === null
    ) {
      return false;
    }
    return (
      moment(certificate.valid_until, 'YYYY-MM-DD') >=
        moment(filterDate, 'YYYY-MM-DD') &&
      moment(certificate.valid_from, 'YYYY-MM-DD') <=
        moment(filterDate, 'YYYY-MM-DD')
    );
  });
};

export const getUrlPdf = (code, clientType) => {
  let arrayPDF = [
    {
      code: 'billete_auto_rental_insurance',
      pdf:
        clientType === 'INDIVIDUAL'
          ? process.env.REACT_APP_RESUMO_VEICULOS_LOCADORA
          : process.env.REACT_APP_RESUMO_VEICULOS_LOCADORA_EMPRESARIAL,
    },
    {
      code: 'certificate_seguro_compra',
      pdf:
        clientType === 'INDIVIDUAL'
          ? process.env.REACT_APP_RESUMO_PROTECAO_COMPRA
          : process.env.REACT_APP_RESUMO_PROTECAO_COMPRA_EMPRESARIAL,
    },
  ];
  let filterByCode = arrayPDF.find(pdf => {
    return pdf.code === code;
  });
  return filterByCode ? filterByCode.pdf : '';
};
