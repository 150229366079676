import { useMemo, useState } from 'react';
import BenefitsCategories from '../../../components/Sections/AllBenefits/BenefitsCategories/BenefitsCategories';
import { useMemoTranslations } from '../../../intl';
import {
  ALL,
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  getCategory,
  CONCIERGE,
} from '../../../utils/benefitCategory';
import styles from './Claims.module.scss';
import ClaimsList from './ClaimsList/ClaimsList';
import * as translations from './intl';

const Claims = ({ benefits, mergeBenefitsClaims }) => {
  const [category, setCategory] = useState(getCategory());
  const [disabledCategories, setDisabledCategories] = useState();

  const intl = useMemoTranslations(translations);

  const filteredBenefits = useMemo(() => {
    const getFiltered = category =>
      mergeBenefitsClaims?.filter(
        benefit => benefit['info']?.[0]?.categories === category
      );

    const filteredBenefits = {
      [ALL]: mergeBenefitsClaims,
      [TRAVEL_BENEFITS]: getFiltered(TRAVEL_BENEFITS),
      [PURCHASE_PROTECTION]: getFiltered(PURCHASE_PROTECTION),
      [LIFESTYLE]: getFiltered(LIFESTYLE),
    };
    setDisabledCategories({
      [TRAVEL_BENEFITS]: filteredBenefits[TRAVEL_BENEFITS].length === 0,
      [PURCHASE_PROTECTION]: filteredBenefits[PURCHASE_PROTECTION].length === 0,
      [LIFESTYLE]: filteredBenefits[LIFESTYLE].length === 0,
      [CONCIERGE]: true,
    });
    return filteredBenefits;
  }, [mergeBenefitsClaims]);

  return (
    <div className={styles.claims}>
      <div className={styles.claimsHeader}>
        <div className={styles.title}>
          <h2>{intl.TITLE_CLAIM}</h2>
        </div>
        <BenefitsCategories
          filterBenefits={category => setCategory(category)}
          selected={category}
          benefitList={benefits}
          disabledCategories={disabledCategories}
        />
      </div>
      <ClaimsList
        benefits={filteredBenefits[category]}
        benefitsList={benefits}
      />
    </div>
  );
};

export default Claims;
