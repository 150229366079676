export const TITLE = {
  en_US: 'Welcome to your file download page.',
  es_ES: 'Bienvenido a la página de descarga de tus archivos.',
  pt_BR: 'Bem vindo a página de download do seu arquivo.',
};

export const SUBTITLE = {
  en_US: 'Your download will start in a moment.',
  es_ES: 'Tu descarga comenzará en un momento.',
  pt_BR: 'Seu download começará em instantes.',
};

export const CERTIFICATE_NOT_FOUND = {
  en_US: 'No certificate was found to start the download.',
  es_ES: 'No se encontró certificado para iniciar la descarga.',
  pt_BR: 'Nenhum certificado foi encontrado para iniciar o download.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};
