import moment from 'moment';
import { pt_BR } from '../../../../../../../intl/idioms';
import { CARD_HOLDER_VALUE } from '../../../../../../../utils/consts';
import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';

export const emptyInvolvedPerson = {
  type: '',
  person: {
    first_name: '',
    last_name: '',
  },
  address: {
    country: '',
    state: '',
    locality: '',
    postal_code: '',
    street_address: '',
    subdivision: '',
  },
  phone: {
    phone_type: PHONE_TYPE_MOBILE,
    international_prefix: '',
    number: '',
  },
};

export const getFamilySituation = situation => ({
  marital_status: situation.marital_status || 'SINGLE',
  lived_in_couple_since: situation.lived_in_couple_since
    ? new Date(situation.lived_in_couple_since)
    : '',
});

export const getPersonRegistration = (registration, idiom) => ({
  registration_type: registration.registration_type || '',
  value: registration.value || '',
});

export const getPhone = phone => ({
  phone_type: phone.phone_type || PHONE_TYPE_MOBILE,
  international_prefix: phone.international_prefix || '',
  number: phone.number || '',
});

export const getAddress = address => ({
  street_address: address.street_address || '',
  postal_code: address.postal_code || '',
  locality: address.locality || '',
  country: address.country || '',
  subdivision: address.subdivision || '',
  state: address.state || '',
  complement: address.complement || '',
});

export const getPerson = (data, affectedPerson) => {
  const cardHolder = data && data.person ? data.person : '';
  const person = affectedPerson.person ? affectedPerson.person : cardHolder;

  let nationalities = '';
  if (person.nationalities && person.nationalities[0]) {
    nationalities = person.nationalities[0];
  } else if (data.nationalities) {
    nationalities = data.nationalities;
  }

  return {
    first_name: person.first_name || '',
    last_name: person.last_name || '',
    nationalities,
  };
};

export const getAffectedPerson = (data, affectedPerson, idiom) => {
  const address = affectedPerson.address ? affectedPerson.address : {};
  const phone =
    affectedPerson.phones && affectedPerson.phones[0]
      ? affectedPerson.phones[0]
      : {};
  const personRegistration =
    affectedPerson.person_registration && affectedPerson.person_registration[0]
      ? affectedPerson.person_registration[0]
      : {};
  const familySituation = affectedPerson.family_situation
    ? affectedPerson.family_situation
    : {};

  const occupation =
    affectedPerson.professional_situation &&
    affectedPerson.professional_situation.occupation
      ? affectedPerson.professional_situation.occupation
      : '';

  return {
    person: getPerson(data, affectedPerson, idiom),
    relationship: affectedPerson.relationship
      ? affectedPerson.relationship
      : CARD_HOLDER_VALUE,
    comment: affectedPerson.comment ? affectedPerson.comment : '',
    address: getAddress(address),
    email: affectedPerson.email ? affectedPerson.email : '',
    phones: getPhone(phone),
    person_registration: getPersonRegistration(personRegistration),
    family_situation: getFamilySituation(familySituation),
    professional_situation: {
      occupation,
    },
  };
};

export const getPolicy = policy => {
  const cardInfo =
    policy.credit_card && policy.credit_card.additional_card_intormation
      ? policy.credit_card.additional_card_intormation
      : {};

  return {
    credit_card: {
      additional_card_intormation: {
        expiration_date: cardInfo.expiration_date
          ? new Date(moment(cardInfo.expiration_date, 'DD/MM/YYYY'))
          : '',
        card_status: cardInfo.card_status || 'ACTIVE',
        activation_date: cardInfo.activation_date
          ? new Date(moment(cardInfo.activation_date, 'DD/MM/YYYY'))
          : '',
      },
    },
  };
};

export const getIncident = incident => {
  const location = incident.location ? incident.location : {};

  return {
    occured_at: incident.occured_at
      ? new Date(moment(incident.occured_at, 'DD/MM/YYYY'))
      : '',
    description: incident.description || '',
    location: {
      country: location.country || '',
      state: location.state || '',
      locality: location.locality || '',
      street_address: location.street_address || '',
    },
  };
};

export const getPeopleInvolved = peopleInvolved =>
  peopleInvolved.map(personInvolded => {
    const address = getAddress(personInvolded.address || {});
    delete address.complement;

    const person = getPerson(
      {
        first_name: '',
        last_name: '',
      },
      personInvolded
    );
    delete person.nationalities;

    return {
      type: personInvolded.type || '',
      person,
      address,
      phone: getPhone(personInvolded.phone || {}),
    };
  });

export const travelFormInitialValues = (data, idiom, loadedData) => {
  const affectedPerson =
    loadedData && loadedData.affected_person ? loadedData.affected_person : {};
  const policy = loadedData && loadedData.policy ? loadedData.policy : {};
  const incident = loadedData && loadedData.incident ? loadedData.incident : {};
  const peopleInvolved =
    loadedData && loadedData.people_involved ? loadedData.people_involved : [];

  return {
    is_travel_accident: false,
    is_accidental_death:
      loadedData && loadedData.is_accidental_death
        ? String(loadedData.is_accidental_death)
        : 'false',
    have_people_involved: peopleInvolved.length > 0 ? 'true' : 'false',
    affected_person: getAffectedPerson(data, affectedPerson, idiom),
    policy: getPolicy(policy),
    incident: getIncident(incident),
    people_involved:
      peopleInvolved.length > 0
        ? getPeopleInvolved(peopleInvolved)
        : [emptyInvolvedPerson],
  };
};
