/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import styles from './TermsAndConditions.module.scss';
import StepClaim from '../../StepClaim/StepClaim';
import { pt_BR } from '../../../../intl/idioms';
import * as translations from './intl';
import { IntlContext } from '../../../../intl/index';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../componentsV2/Button/Button';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  CLAIM_TERMS_OPENED,
  dataLayerClaimEventTrack,
  CLAIM_COMPLETED_STEP,
} from '../../../../utils/GTM_helper';
import { emergency_medical_assistance } from '../../../../utils/claims_name';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { sanitize } from 'dompurify';
import ErrorAttentionIcon from '../../../../assets/icons/ErrorAttentionIcon';
import { ENABLE_NOTIFICATION_TO_PRICE_PROTECTION } from '../../../../utils/environments_variables';
import Link from '../../../../componentsV2/Link/Link';

const CLASSIC = 'Classic';
const GOLD = 'Gold';
const INFINITE = 'Infinite';
const PLATINUM = 'Platinum';
const SIGNATURE = 'Signature';

const TermsAndConditions = props => {
  const {
    claimDetails,
    setStep,
    claimType = '',
    showCertificateArea,
    disableStepClaim,
    disableShadowBox,
    disableNextButton,
    setTermsAccept,
    disableTitle,
    dataTestId,
  } = props;
  const [accept, setAccept] = useState(false);
  const [genericAccept, setGenericAccept] = useState(false);
  const [valueAccept, setValueAccept] = useState(false);
  const [valueAccept2, setValueAccept2] = useState(false);
  const [acceptIEMSArgentina, setAcceptIEMSArgentina] = useState(false);
  const [acceptPriceProtection1, setAcceptPriceProtection1] = useState(false);
  const [acceptPriceProtection2, setAcceptPriceProtection2] = useState(false);
  const [acceptPriceProtection3, setAcceptPriceProtection3] = useState(false);
  const { utils } = useContext(StateContext);
  const country = utils.getCountry();
  const card = utils.getSelectedCard();
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const isBrazil = idiom === pt_BR;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const isIEMS = claimType === emergency_medical_assistance;
  const isPriceProtection = claimType === 'Price_Protection';
  const isPurchaseProtection = claimType === 'Purchase_Protection';
  const isExtendedWarranty = claimType === 'Extended_Warranty';
  const isArgentina = country === 'ARGENTINA';

  const cardConditionAfluent =
    card &&
    (card.name.includes(INFINITE) ||
      card.name.includes(PLATINUM) ||
      card.name.includes(SIGNATURE));

  const stepNames = showCertificateArea
    ? [intl.TEXT_STEP_1, intl.TEXT_STEP_2, intl.TEXT_STEP_3, intl.TEXT_STEP_4]
    : [intl.TEXT_STEP_1, intl.TEXT_STEP_3, intl.TEXT_STEP_4];

  const next = () => {
    dataLayerClaimEventTrack(CLAIM_COMPLETED_STEP, claimType, 1);
    setStep(showCertificateArea ? 'SEARCH_CERTIFICATES' : 'FORM');
  };

  const setAcceptTerms = value => {
    setAccept(value);
    !!setTermsAccept && setTermsAccept(value);
  };

  useEffect(() => {
    trackEventUserAction(
      '#### (TERMS AND CONDITIONS) ENTROU NA PÁGINA DE TERMOS E CONDIÇÕES',
      { claim: claimType }
    );
  }, []);

  useEffect(() => {
    if (isPriceProtection) {
      if (
        acceptPriceProtection1 &&
        acceptPriceProtection2 &&
        acceptPriceProtection3 &&
        genericAccept &&
        valueAccept
      ) {
        if (
          !getSecondaryMaxCoverageText(claimType, card) ||
          (getSecondaryMaxCoverageText(claimType, card) && valueAccept2)
        ) {
          setAcceptTerms(true);
        } else {
          setAcceptTerms(false);
        }
      } else {
        setAcceptTerms(false);
      }
    } else if (isExtendedWarranty || isPurchaseProtection) {
      if (
        genericAccept &&
        valueAccept &&
        (!getSecondaryMaxCoverageText(claimType, card) ||
          (getSecondaryMaxCoverageText(claimType, card) && valueAccept2))
      ) {
        setAcceptTerms(true);
      } else {
        setAcceptTerms(false);
      }
    } else if (isIEMS && isArgentina) {
      if (genericAccept && acceptIEMSArgentina) {
        setAcceptTerms(true);
      } else {
        setAcceptTerms(false);
      }
    } else {
      if (genericAccept) {
        setAcceptTerms(true);
      } else {
        setAcceptTerms(false);
      }
    }
  }, [
    acceptPriceProtection1,
    acceptPriceProtection2,
    acceptPriceProtection3,
    acceptIEMSArgentina,
    genericAccept,
    valueAccept,
    valueAccept2,
    isPriceProtection,
  ]);

  const document =
    claimDetails.documents &&
    claimDetails.documents[0] &&
    claimDetails.documents[0].content_url;

  const claimPDFURL =
    claimDetails.documents &&
    claimDetails.documents[2] &&
    claimDetails.documents[2].content_url;

  const shouldShowMaxCoverageInfo =
    isPriceProtection || isPurchaseProtection || isExtendedWarranty;

  const getClaimMaxCoverageText = (claimType, card) => {
    switch (claimType) {
      case 'Price_Protection':
        if (card.name.includes(INFINITE))
          return intl.COVERAGE_PRICE_PROTECTION_INFINITE;
        if (card.name.includes(PLATINUM))
          return intl.COVERAGE_PRICE_PROTECTION_PLATINUM;
        if (card.name.includes(SIGNATURE))
          return intl.COVERAGE_PRICE_PROTECTION_SIGNATURE;
        if (card.name.includes(GOLD))
          return intl.COVERAGE_PRICE_PROTECTION_GOLD;
        if (card.name.includes(CLASSIC))
          return intl.COVERAGE_PRICE_PROTECTION_CLASSIC;
        break;
      case 'Purchase_Protection':
        if (card.name.includes(INFINITE))
          return intl.COVERAGE_PURCHASE_PROTECTION_INFINITE;
        if (card.name.includes(PLATINUM))
          return intl.COVERAGE_PURCHASE_PROTECTION_PLATINUM;
        if (card.name.includes(SIGNATURE))
          return intl.COVERAGE_PURCHASE_PROTECTION_SIGNATURE;
        if (card.name.includes(CLASSIC) || card.name.includes(GOLD))
          return intl.COVERAGE_PURCHASE_PROTECTION_GOLD;
        break;
      case 'Extended_Warranty':
        if (card.name.includes(INFINITE))
          return intl.COVERAGE_EXTENDED_WARRANTY_INFINITE;
        if (card.name.includes(PLATINUM))
          return intl.COVERAGE_EXTENDED_WARRANTY_PLATINUM;
        if (card.name.includes(SIGNATURE))
          return intl.COVERAGE_EXTENDED_WARRANTY_SIGNATURE;
        if (card.name.includes(CLASSIC) || card.name.includes(GOLD))
          return intl.COVERAGE_EXTENDED_WARRANTY_CLASSIC_OR_GOLD;
        break;
    }
  };

  const getSecondaryMaxCoverageText = (claimType, card) => {
    switch (claimType) {
      case 'Extended_Warranty':
        if (card.name.includes(INFINITE))
          return intl.SECONDARY_COVERAGE_EXTENDED_WARRANTY_INFINITE;
        if (card.name.includes(PLATINUM))
          return intl.SECONDARY_COVERAGE_EXTENDED_WARRANTY_PLATINUM;
        if (card.name.includes(SIGNATURE))
          return intl.SECONDARY_COVERAGE_EXTENDED_WARRANTY_SIGNATURE;
        if (card.name.includes(CLASSIC) || card.name.includes(GOLD))
          return intl.SECONDARY_COVERAGE_EXTENDED_WARRANTY_GOLD;
        break;
      case 'Purchase_Protection':
        if (card.name.includes(INFINITE))
          return intl.SECONDARY_COVERAGE_PURCHASE_PROTECTION_INFINITE;
        if (card.name.includes(PLATINUM))
          return intl.SECONDARY_COVERAGE_PURCHASE_PROTECTION_PLATINUM;
        if (card.name.includes(SIGNATURE))
          return intl.SECONDARY_COVERAGE_PURCHASE_PROTECTION_SIGNATURE;
        if (card.name.includes(CLASSIC) || card.name.includes(GOLD))
          return intl.SECONDARY_COVERAGE_PURCHASE_PROTECTION_GOLD;
        break;
    }
  };
  const termsAndConditionsContent = () => {
    return (
      <>
        <div
          id="mainContent"
          className={styles.termsArea}
          data-testid={dataTestId}
        >
          {!disableTitle && (
            <h2 className={styles[theme]}>
              {intl.ACCEPTING_TERMS_AND_CONDITIONS}
            </h2>
          )}

          <div className={styles.pdf}>
            <PDFIcon width={42} height={54} />
            <div
              className={styles.pdfInfo}
              onClick={() =>
                dataLayerClaimEventTrack(CLAIM_TERMS_OPENED, claimType)
              }
            >
              <Link href={document} label={intl.TERMS_AND_CONDITIONS} />
              <span>{claimDetails.name}</span>
            </div>
          </div>
          {isPriceProtection && isBrazil && (
            <p className={styles.disclaimer}>
              {intl.PRICE_PROTECTION_DISCLAIMER_BR}
            </p>
          )}
          {isPriceProtection &&
            isBrazil &&
            cardConditionAfluent &&
            ENABLE_NOTIFICATION_TO_PRICE_PROTECTION && (
              <p className={`${styles.disclaimer}`}>
                <ErrorAttentionIcon
                  width={18}
                  height={18}
                  aria-label={intl.WARNING}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      intl.PRICE_PROTECTION_OLD_TERMS(claimPDFURL)
                    ),
                  }}
                ></span>
              </p>
            )}
        </div>

        <div className={`${styles[theme]} ${styles.acceptArea}`}>
          {isPriceProtection && (
            <>
              <CheckBox
                name="accept_terms_1"
                value={acceptPriceProtection1}
                onClick={check => setAcceptPriceProtection1(check)}
              >
                {intl.ACCEPT_TERMS_PRICE_PROTECTION_1}
              </CheckBox>
              <CheckBox
                name="accept_terms_2"
                value={acceptPriceProtection2}
                onClick={check => setAcceptPriceProtection2(check)}
              >
                {intl.ACCEPT_TERMS_PRICE_PROTECTION_2}
              </CheckBox>
              <CheckBox
                name="accept_terms_3"
                value={acceptPriceProtection3}
                onClick={check => setAcceptPriceProtection3(check)}
              >
                {intl.ACCEPT_TERMS_PRICE_PROTECTION_3}
              </CheckBox>
            </>
          )}

          {isArgentina && isIEMS && (
            <>
              <CheckBox
                name="accept_terms_IEMS_AR"
                value={acceptIEMSArgentina}
                onClick={check => setAcceptIEMSArgentina(check)}
              >
                {intl.IEMS_ARGENTINA_DISCLAIMER}
              </CheckBox>
            </>
          )}
          {(isExtendedWarranty ||
            isPriceProtection ||
            isPurchaseProtection) && (
            <>
              <CheckBox
                name="value_accept_terms_1"
                value={valueAccept}
                onClick={check => setValueAccept(check)}
              >
                {intl.ACCEPT_TERMS_AFTER_31_MAY +
                  getClaimMaxCoverageText(claimType, card)}
              </CheckBox>
              {getSecondaryMaxCoverageText(claimType, card) && (
                <CheckBox
                  name="value_accept_terms_2"
                  value={valueAccept2}
                  onClick={check => setValueAccept2(check)}
                >
                  {card.name === 'Gold'
                    ? intl.ACCEPT_TERMS_GOLD_UNTIL_31_MAY
                    : intl.ACCEPT_TERMS_UNTIL_31_MAY}
                  {getSecondaryMaxCoverageText(claimType, card)}
                </CheckBox>
              )}
            </>
          )}
          <CheckBox
            name="accept_terms"
            value={genericAccept}
            onClick={check => setGenericAccept(check)}
          >
            {intl.ACCEPT_TERMS}
          </CheckBox>
        </div>
        {!disableNextButton && (
          <div className={styles.footer}>
            <div className={styles.buttonArea}>
              <Button
                disabled={!accept}
                onClick={next}
                label={intl.BTN_NEXT_STEP}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      {!disableStepClaim && <StepClaim currentStep={1} stepNames={stepNames} />}
      {!disableShadowBox ? (
        <ShadowBox>{termsAndConditionsContent()}</ShadowBox>
      ) : (
        termsAndConditionsContent()
      )}
    </div>
  );
};

export default TermsAndConditions;
