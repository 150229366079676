export default (env, axios, errorHandler) => {
  return {
    getDoctor: doctorID => {
      const url = `/amos/doctors/${doctorID}`;
      return errorHandler(axios.get(url), 'getDoctor', true);
    },
    getTime: timezone => {
      const url = `/amos/time?time_zone=${encodeURIComponent(timezone)}`;
      return errorHandler(axios.get(url), 'getTime', true);
    },
    getCountries: language => {
      const url = `/amos/countries/?language=${language}`;
      return errorHandler(axios.get(url), 'getCountries', true);
    },
    getTimezones: language => {
      const url = `/amos/timezones/?language=${language}`;
      return errorHandler(axios.get(url), 'getTimezones', true);
    },
  };
};
