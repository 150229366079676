export const TEXT_START_DATE_TRIP = {
  en_US: 'Start date of trip *',
  es_ES: 'Fecha de inicio del viaje *',
  pt_BR: 'Data de início da viagem *',
};

export const TEXT_END_DATE_TRIP = {
  en_US: 'End date of trip *',
  es_ES: 'Fecha de finalización del viaje *',
  pt_BR: 'Data de fim da viagem *',
};

export const TEXT_DATE_LOSS = {
  en_US: 'Date of loss *',
  es_ES: 'Fecha de la pérdida *',
  pt_BR: 'Data da perda *',
};

export const TEXT_TIME_LOSS = {
  en_US: 'Time of loss *',
  es_ES: 'Hora de la pérdida *',
  pt_BR: 'Hora da perda *',
};

export const TEXT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Describa el incidente',
  pt_BR: 'Descreva o incidente',
};
