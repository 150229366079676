export const TEXT_ESPECIAL_BENEFITS = {
  en_US: 'Subscriptions',
  es_ES: 'Suscripciones',
  pt_BR: 'Assinaturas',
};

export const VIEW_ALL = {
  en_US: 'See all',
  pt_BR: 'Ver todos',
  es_ES: 'Ver todos',
};
