import { useMemo } from 'react';
import { Flight } from '../../@types/APIs/AEROAPI/Flight';
import FlightInfo from '../FlightInfo';
import { formatAeroapiFlightToFlightInfo } from './utils';

interface FlightTrackingProps {
  flight: Flight;
  airportOrigin?: Airport;
  airportDestination?: Airport;
}

const FlightTracking = ({
  flight,
  airportOrigin,
  airportDestination,
}: FlightTrackingProps) => {
  const flightInfo = useMemo(
    () =>
      formatAeroapiFlightToFlightInfo(
        flight,
        airportOrigin,
        airportDestination
      ),
    [flight, airportOrigin, airportDestination]
  );

  return (
    <FlightInfo flightInfo={flightInfo}>
      <FlightInfo.Label />
      <FlightInfo.Cities />
      <FlightInfo.Airports showDistance />
      <FlightInfo.Delay />
      <FlightInfo.Dates />
      <FlightInfo.Embark />
    </FlightInfo>
  );
};

export default FlightTracking;
