export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_CHILD_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CERTIFICATES = 'CERTIFICATES';
export const CLAIMS = 'CLAIMS';

// Status claim
export const STATUS_TRANSFEREDUNDERWRITE = 'Transferred to Underwriter';
export const STATUS_CLOSED = 'Closed';

export const CARD = 'card';

export const CARD_STATUS_ACTIVE = 'ACTIVE';
export const CARD_STATUS_EXPIRED = 'EXPIRED';
export const CARD_STATUS_INVALID = 'INVALID';

export const INFINITE = 'Infinite';
export const PLATINUM = 'Platinum';
export const SIGNATURE = 'Signature';
