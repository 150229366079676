export const TEXT_RECOVERY_FUNDS_CERTIFICATES = {
  en_US: 'Recovery of funds certification',
  pt_BR: 'Recuperação de certificação de fundos',
  es_ES: 'Certificación de recuperación de fondos',
};

export const TEXT_CERTIFY_ANY_AMOUNT_BE_RECOVERED = {
  en_US: 'I certify that should any amount be recovered by',
  pt_BR: 'Certifico que qualquer quantia deve ser recuperada por',
  es_ES: 'Certifico que cualquier cantidad será recuperada por',
};

export const LABEL_FINANCIAL_INSTITUTION = {
  en_US: 'Financial institution',
  pt_BR: 'Instituição financeira',
  es_ES: 'Instituición financiera',
};

export const TEXT_FROM_COMPANY_WE_AGREE = {
  en_US:
    'from the company or any other source with respect to Waivable Charges, we agree to use these funds to reduce the Waivable Charges and/or the amount of any claim filed with the Visa Corporate Liability Waiver Program, or if the claim payment has previously been submitted to us, we shall return such amounts to the Program Underwriter for Visa claims',
  pt_BR:
    'Da empresa ou de qualquer outra fonte em relação aos Encargos de Remuneração, nós concordamos em usar esses fundos para reduzir os Encargos de Advertência e / ou o montante de qualquer reclamação arquivada no Programa de Isenção de Responsabilidade Corporativa da Visa ou se o pagamento do pedido já foi enviado Para nós, devemos devolver esses montantes ao Segurador do Programa para os pedidos de visto.',
  es_ES:
    'De la compañía o de cualquier otra fuente con respecto a Cargos Renunciables, acordamos usar estos fondos para reducir los Cargos Renovables y / o el importe de cualquier reclamación presentada con el Programa de Renuncia de Responsabilidad Civil de Visa, o si el pago de la reclamación ha sido previamente presentado A nosotros, devolveremos tales cantidades al Asegurador del Programa para reclamaciones de Visa.',
};

export const TEXT_CERTIFY_THAT_ABOVE_INFORMATION = {
  en_US: 'I CERTIFY THAT THE ABOVE INFORMATION IS ACCURATE',
  pt_BR: 'CERTIFICO QUE AS INFORMAÇÕES ACIMA ESTÃO PRECISAS',
  es_ES: 'CERTIFICO QUE LA INFORMACIÓN ANTERIOR ES EXACTA',
};

export const LABEL_DATE = {
  en_US: 'Date',
  pt_BR: 'Data',
  es_ES: 'Fecha',
};
