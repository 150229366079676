/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../intl';
import { productsAndBenefitsServices } from '../../services/index';
import { StateContext } from '../StateContextParent/StateContextParent';

export const PublicContext = createContext({
  productActive: {},
  setProductActive: () => {},
});

const PublicContextParent = props => {
  const [productActive, setProductActive] = useState({});
  const { idiomForApi, country } = useContext(IntlContext);
  const {
    utils: { isUserLogged },
  } = useContext(StateContext);

  const formatCountryByProductId = card => ({
    ...card,
    country:
      card &&
      card.product_id &&
      card.product_id.substring(card.product_id.length - 2),
  });

  const _setProductActive = card => {
    const cardFormated = formatCountryByProductId(card);
    setProductActive(cardFormated);
  };

  const value = {
    productActive,
    setProductActive: _setProductActive,
  };

  const loadMainProduct = async () => {
    try {
      const { data } = await productsAndBenefitsServices.getProductsByCountry(
        country,
        idiomForApi()
      );
      let cardFormated;
      if (window.location.pathname.includes('cross-sell')) {
        const goldCard = data.find(card => card.name === 'Gold');
        cardFormated = formatCountryByProductId(goldCard);
      } else {
        cardFormated = formatCountryByProductId(data[0]);
      }
      setProductActive(cardFormated || {});
    } catch (error) {
      setProductActive({});
    }
  };

  useEffect(() => {
    if (!isUserLogged()) {
      loadMainProduct();
    }
  }, [country]);

  return (
    <PublicContext.Provider value={value}>
      {props.children}
    </PublicContext.Provider>
  );
};

export default PublicContextParent;
