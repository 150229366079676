import { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../components/InfoLabelValue/InfoLabelValue';
import FlightDetailItem from './FlightDetailItem/FlightDetailItem';
import { TravelDetail } from '../../../../@types/APIs/Travels';
import { HandledFlights } from '../utilsDetails';

interface FlightDetailList {
  travel: TravelDetail;
  flights: HandledFlights;
  isReturn?: boolean;
}

const FlightDetailList = ({ travel, flights, isReturn }: FlightDetailList) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const trips = isReturn ? travel.return_travel : travel.outward_travel;
  const flight = isReturn ? flights.returnFlights : flights.outwardFlights;

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_BOOKING_COMPANY}
            value={trips.booking_company_name}
          />
        </div>
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_RECORD_LOCATOR}
            value={trips.booking_reference}
          />
        </div>
      </div>
      {trips.flight_segments.map((trip, index) => (
        <FlightDetailItem
          key={index}
          trip={trip}
          index={index}
          flight={flight[index]}
        />
      ))}
    </>
  );
};

export default FlightDetailList;
