export const TITLE = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Lo tenemos cubierto en todas partes',
};

export const DESCRIPTION = {
  en_US: '',
  pt_BR: '',
  es_ES:
    'Mejorar tu cobertura te dará más seguridad y más beneficios que tu tarjeta actual no puede brindarte.',
};

export const BENEFIT_MEDICAL_EMERGENCY = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Asistencia médica 24/7',
};

export const BAGGAGE_PROTECTION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Protección de Equipaje',
};

export const TRIP_CANCELATION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Cancelación de viaje',
};

export const DESTINATION_INFORMATION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Información de destinos',
};

export const HEALTH_REQUIREMENTS = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Requisitos de salud y vacunas',
};
