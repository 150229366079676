export const ADD_LONG = {
  en_US: 'Add a new bank',
  es_ES: 'Agregar un banco nuevo',
  pt_BR: 'Adicionar um novo banco',
};

export const ADD_SHORT = {
  en_US: 'Add',
  es_ES: 'Agregar',
  pt_BR: 'Adicionar',
};
