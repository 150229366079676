import { ReactNode, useContext, useEffect, useState } from 'react';
import styles from './AccordionTravelDetails.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../../../assets/icons/ChevronGeneric';
import Button from '../../../../componentsV2/Button/Button';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import DividingLine from '../../../../components/DividingLine/DividingLine';
import { joinClassNames } from '../../../../utils/classNames';
interface AccordionTravelDetailsProps {
  open?: boolean;
  initialFlightFocus?: string;
  title: string;
  children: ReactNode;
}

const AccordionTravelDetails = ({
  open = false,
  initialFlightFocus,
  title,
  children,
}: AccordionTravelDetailsProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);

  const [showMoreInfo, setShowMoreInfo] = useState(open);
  const theme = getGlobalTheme();
  const intl = translate(translations);

  const [hasFocused, setHasFocused] = useState(false);

  const onClick = () => setShowMoreInfo(!showMoreInfo);

  useEffect(() => {
    setShowMoreInfo(open);
  }, [open]);

  useEffect(() => {
    if (!hasFocused && initialFlightFocus) {
      document
        .getElementById(`divider-${initialFlightFocus}`)
        ?.scrollIntoView?.({ behavior: 'smooth', block: 'start' });
      setHasFocused(true);
    }
  }, [showMoreInfo]);

  return (
    <div className={joinClassNames(styles.container, styles[theme])}>
      <div
        className={`${styles.accordionHeader} ${styles[theme]} ${
          showMoreInfo ? styles.border : ''
        }`}
        onClick={() => onClick()}
      >
        <span className={`${styles.name} ${styles[theme]}`}>{title}</span>
        <div className={`${styles[theme]} ${styles.moreInformation}`}>
          {showMoreInfo ? (
            <span className={styles.icon}>
              <ChevronGeneric width={20} height={20} rotate={180} />
            </span>
          ) : (
            <span className={styles.icon}>
              <ChevronGeneric width={20} height={20} />
            </span>
          )}
        </div>
      </div>
      {showMoreInfo && (
        <div className={styles.content}>
          {children}
          <div className={styles.footer}>
            <DividingLine className={styles.divisor} />
            <Button
              link
              href="/claims#travel_benefits"
              type="Primary"
              id="btnOpenAClaimTravel"
              testId={`data-testid-btnOpenAClaimTravel`}
              label={intl.OPEN_CLAIM}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionTravelDetails;
