import React from 'react';
import styles from './BannerDownloadApp.module.scss';
import { sanitize } from 'dompurify';
const BannerDownloadApp = ({ title, icon, image }) => {
  return (
    <div
      className={styles.bannerContainer}
      style={{
        backgroundImage: `url(${image})`,
      }}
      data-testid={'bannerContainer'}
    >
      <div className={styles.content}>
        <h1>
          <span dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
          {icon}
        </h1>
      </div>
    </div>
  );
};

export default BannerDownloadApp;
