import styles from './Header.module.scss';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import ButtonSortList, {
  SORT_ASC,
} from '../../../../components/ButtonSortList/ButtonSortList';

const Header = ({
  title,
  subtitle,
  qtdCertificates,
  handleOrderCertificates,
  id,
  loading,
  messageEmpty,
}) => {
  return (
    <div className={styles.headerContainer} id={id}>
      <div className={styles.HeaderInfo}>
        <TitleSectionProfile
          title={title}
          number={qtdCertificates}
          subtitle={qtdCertificates > 0 ? subtitle : messageEmpty}
          loading={loading}
        />
      </div>
      {qtdCertificates > 0 && (
        <ButtonSortList
          initialOrder={SORT_ASC}
          actionToSortASC={() => handleOrderCertificates('past')}
          actionToSortDESC={() => handleOrderCertificates('recent')}
        />
      )}
    </div>
  );
};

export default Header;
