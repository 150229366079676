import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../LegalProtection.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

const TotalAmountArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const amountClaimedValue = values => {
    let result = 0;
    if (values.other_insurance_covering[0].amount.value > 0) {
      result -= Number(values.other_insurance_covering[0].amount.value);
    }
    if (values.other_insurance_covering[1].amount.value > 0) {
      result -= Number(values.other_insurance_covering[1].amount.value);
    }
    for (let i = 0; i < values.incident.expenses.length; i++) {
      if (Number(values.incident.expenses[i].amount.value) > 0)
        result += Number(values.incident.expenses[i].amount.value);
    }
    const value = result > 0 ? result.toFixed(2) : '0.00';
    return value;
  };

  useEffect(() => {
    setFieldValue('total_amount_claimed.value', amountClaimedValue(values));
    setFieldTouched('total_amount_claimed.value', true);
    setFieldValue(
      'total_amount_claimed.currency',
      values.incident.expenses[0].amount.currency
    );
    setFieldTouched('total_amount_claimed.currency', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={` ${styles.incidentPeriodArea} ${styles.fontGray12} ${styles.options} row`}
        >
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Input
              label={intl.TEXT_TOTAL_AMOUNT}
              type="text"
              maskedMoney
              name="total_amount_claimed.value"
              disabled
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={amountClaimedValue(values)}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.total_amount_claimed &&
                touched.total_amount_claimed.value
              }
              error={
                errors.total_amount_claimed && errors.total_amount_claimed.value
              }
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              dataTestid="selectAmountCurrency"
              label={intl.LABEL_CURRENCY}
              name="total_amount_claimed.currency"
              disabled
              value={values.incident.expenses[0].amount.currency}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.total_amount_claimed &&
                touched.total_amount_claimed.currency
              }
              error={
                errors.total_amount_claimed &&
                errors.total_amount_claimed.currency
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalAmountArea;
