import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';

const ConciergeIcon = props => {
  const { darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = CONFIG_ICON[theme];
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : config_icon.secondary;

  return (
    <svg
      width={59}
      height={67}
      viewBox="0 0 59 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.8455 26.8128V18.122H43.138V13.8929H30.3238V18.122H34.6164V26.8128C24.974 27.7221 15.395 34.7424 15.395 44.85V46.9646H58.088V44.85C58.088 34.7424 48.509 27.7221 38.8666 26.8128H38.8455ZM19.8144 42.7355C21.1678 35.5883 29.1819 30.9362 36.7309 30.9362C44.2799 30.9362 52.3152 35.5883 53.6474 42.7355H19.7933H19.8144Z"
        fill={secondary}
      />
      <path
        d="M39.0357 50.4959H34.8066L5.20278 50.517V7.88751C5.20278 5.96326 6.76755 4.39849 8.67066 4.39849L31.3599 4.48307C33.2207 4.48307 34.7643 5.94212 34.8278 7.76064V8.94479H39.0569C39.0569 8.94479 39.0569 7.73949 39.0569 7.63377C38.8877 3.48923 35.5044 0.253955 31.3599 0.253955L8.67066 0.169373C4.42039 0.169373 0.952515 3.63725 0.952515 7.88751V58.214C0.952515 62.4643 4.42039 65.9321 8.64951 65.9321L31.3387 66.0167C35.589 66.0167 39.0569 62.5489 39.0569 58.2986L39.0357 50.4747V50.4959ZM31.3599 61.7876L8.67066 61.703C6.74641 61.703 5.18163 60.1383 5.18163 58.214V54.725H34.8066V58.2774C34.8066 60.2017 33.263 61.7453 31.3387 61.7453L31.3599 61.7876Z"
        fill={primary}
      />
    </svg>
  );
};

export default ConciergeIcon;
