import PlayStoreEN from '../../assets/images/downloadApp/stores/ENG/download-android-eng.png';
import PlayStoreES from '../../assets/images/downloadApp/stores/SP/download-android-esp.png';
import PlayStorePT from '../../assets/images/downloadApp/stores/PT/download-android-pt.png';
import AppStoreEN from '../../assets/images/downloadApp/stores/ENG/download-ios-eng.png';
import AppStoreES from '../../assets/images/downloadApp/stores/SP/download-ios-esp.png';
import AppStorePT from '../../assets/images/downloadApp/stores/PT/download-ios-pt.png';

export const HOW_REDEEM = {
  en_US: 'How to redeem',
  pt_BR: 'Como resgatar',
  es_ES: 'Cómo canjear',
};

export const ENABLE_TO_ENJOY = {
  en_US:
    'With this Visa card currently selected you are enable to enjoy this subscription Benefit. Please click the button below and confirm your personal data to start your amazing experience.',
  pt_BR:
    'Com o cartão Visa selecionado no momento, você pode desfrutar deste beneficio de suscripción. Clique no botão abaixo e confirme suas informações pessoais para iniciar sua incrível experiência.',
  es_ES:
    'Con la tarjeta Visa seleccionada actualmente, puedes disfrutar de este benefício de assinatura. Haga clic en el botón de abajo y confirma tu información personal para comenzar tu increíble experiencia.',
};

export const REDEEM_NOW = {
  en_US: 'Redeem now',
  pt_BR: 'Resgatar agora',
  es_ES: 'Canjear ahora',
};

export const NOT_ADDITIONAL_CHARGES_ONE_LINE = {
  en_US: `Visa will not make any additional charges to your card. Your information is safe.`,
  pt_BR: `A Visa não fará nenhuma cobrança adicional no seu cartão. Suas informações estão seguras.`,
  es_ES: `Visa no hará ningún cargo adicional a tu tarjeta. Tu información está segura.`,
};

export const NOT_ADDITIONAL_CHARGES = {
  en_US: `Visa will not make any additional charges to your card.<br/>Your information is safe.`,
  pt_BR: `A Visa não fará nenhuma cobrança adicional no seu cartão.<br/>Suas informações estão seguras.`,
  es_ES: `Visa no hará ningún cargo adicional a tu tarjeta.<br/>Tu información está segura.`,
};

export const TO_REDEEM = {
  en_US:
    'To redeem this benefit you must complete the process from a mobile device or tablet.',
  pt_BR:
    'Para resgatar este benefício, você deve concluir o processo a partir de um dispositivo móvel ou tablet.',
  es_ES:
    'Para redimir este beneficio debes completar el proceso desde un dispositivo móvil o tablet.',
};

export const BUTTON_LOGIN = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};

export const TEXT_VIEW_PDF = {
  en_US: 'View PDF',
  es_ES: 'Ver PDF',
  pt_BR: 'Ver resumo',
};

export const MESSAGE_MODAL_BENEFIT_NOT_ELEGIBILITY = {
  en_US: benefit =>
    `The selected card does not have the ${
      benefit && benefit.name ? `${benefit.name} benefit.` : 'benefit.'
    }`,
  es_ES: benefit =>
    `La tarjeta seleccionada actualmente no tiene el beneficio${
      benefit && benefit.name ? ` ${benefit.name}.` : '.'
    }`,
  pt_BR: benefit =>
    `O cartão selecionado no momento não possui o benefício${
      benefit && benefit.name ? ` ${benefit.name}.` : '.'
    }`,
};

export const SUBTITLE_MODAL_NOT_ELEGIBILITY = {
  en_US:
    'Please check the benefits of the currently selected card or select another card to see if it has this benefit.',
  es_ES:
    'Por favor, compruebe los beneficios de la tarjeta seleccionada, o por favor elige otra tarjeta para ver si dispone de ese beneficio.',
  pt_BR:
    'Por favor, verifique os benefícios do cartão selecionado no momento ou selecione outro cartão para ver se ele dispõe desse benefício.',
};

export const BTN_VIEW_BENEFITS = {
  en_US: 'See card benefits',
  es_ES: 'Ver beneficios de la tarjeta',
  pt_BR: 'Ver benefícios do cartão',
};

export const BTN_VIEW_CARDS = {
  en_US: 'See my cards',
  es_ES: 'Ver mis tarjetas',
  pt_BR: 'Ver meus cartões',
};

export const REDEEM_STEP_1 = {
  en_US: 'Sign in to the Visa Benefits Portal',
  es_ES: 'Inicia sesión en el Portal de Beneficios de Visa',
  pt_BR: 'Faça o login no Portal de Benefícios Visa',
};

export const REDEEM_STEP_2 = {
  en_US: 'Click on "validate card"',
  es_ES: 'Da click en "validar tarjeta"',
  pt_BR: 'Clique em "validar cartão"',
};

export const REDEEM_STEP_3 = {
  en_US: 'You will be redirected for a security check',
  es_ES: 'Serás redirigido para un chequeo de seguridad',
  pt_BR: 'Você será redirecionado para uma verificação de segurança',
};

export const REDEEM_STEP_4 = {
  en_US: benefit => `Access ${benefit} and enjoy the benefits`,
  es_ES: benefit => `Ingressa en ${benefit} y disfruta de los beneficios`,
  pt_BR: benefit => `Acesse o ${benefit} e aproveite os benefícios`,
};

export const UNIQUE_CARDS_LIKE_YOU = {
  en_US: 'UNIQUE CARDS LIKE YOU',
  es_ES: 'TARJETAS ÚNICAS COMO TÚ',
  pt_BR: 'BENEFÍCIOS PARA PESSOAS ÚNICAS COMO VOCÊ',
};

export const UNIQUE_BENEFITS_LIKE_YOU = {
  en_US: 'UNIQUE BENEFITS LIKE YOU"',
  es_ES: 'BENEFICIOS ÚNICOS COMO TU',
  pt_BR: 'BENEFÍCIOS ÚNICOS COMO VOCÊ',
};

export const IMAGE_APP_STORE = {
  en_US: AppStoreEN,
  es_ES: AppStoreES,
  pt_BR: AppStorePT,
};

export const IMAGE_PLAY_STORE = {
  en_US: PlayStoreEN,
  es_ES: PlayStoreES,
  pt_BR: PlayStorePT,
};

export const DOWNLOAD_APP = {
  en_US: 'Download your Visa Benefits App',
  es_ES: 'Descarga tu App Visa Benefits',
  pt_BR: 'Baixe seu App Visa Benefits',
};

export const SUBTITLE_RAPPI = {
  en_US: 'and enjoy your Rappi benefit',
  es_ES: 'y disfruta tu beneficio Rappi',
  pt_BR: 'e aproveite seu benefício Rappi',
};

export const AVAILABLE_IN_STORES = {
  en_US: 'Available for free on the Google Play and App Store.',
  es_ES: 'Disponible gratis en las tiendas Google Play y App Store.',
  pt_BR: 'Disponível grátis nas lojas Google Play e App Store. ',
};
