export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss',
  pt_BR: 'Tipo de perda',
  es_ES: 'Tipo de pérdida',
};

export const LABEL_ITEM_PURCHASED = {
  en_US: 'Item purchased',
  pt_BR: 'Produto comprado',
  es_ES: 'Artículo comprado',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const LABEL_MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Product value on the sales receipt',
  pt_BR: 'Valor do produto na nota fiscal',
  es_ES: 'Valor del producto en la factura de compra',
};

export const TEXT_ITEM = {
  en_US: 'Item #',
  pt_BR: 'Produto #',
  es_ES: 'Artículo #',
};

export const LABEL_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const LABEL_THEFT = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT = {
  en_US: 'Purchase value on the Visa invoice',
  pt_BR: 'Valor da compra na fatura Visa',
  es_ES: 'Valor de compra en el estado de cuenta',
};

export const PAYMENT_METHOD = {
  en_US: 'Product payment method',
  pt_BR: 'Método de pagamento do produto',
  es_ES: 'Forma de pago del producto',
};

export const METHOD_FINANCING = {
  en_US: 'Installments',
  pt_BR: 'Parcelado',
  es_ES: 'En cuotas',
};

export const METHOD_CASH = {
  en_US: 'Single payment',
  pt_BR: 'Parcela única',
  es_ES: 'En un pago',
};

export const INSTALLMENTS_NUMBER = {
  en_US: 'Purchase installments number',
  pt_BR: 'Número de parcelas da compra',
  es_ES: 'Número de cuotas de compra',
};

export const INSTALLMENTS_PRICE = {
  en_US: 'Installment/financing amount',
  pt_BR: 'Valor da prestação/financiamento',
  es_ES: 'Monto a plazos/financiamiento',
};
