export const ERROR_TEXT = {
  en_US: 'Error uploading file. Please try again.',
  pt_BR: 'Erro ao enviar o arquivo. Por favor, tente novamente.',
  es_ES: 'Error al subir el archivo. Por favor, inténtelo de nuevo.',
};

export const UPLOADED = {
  en_US: 'Uploaded',
  es_ES: 'Subido',
  pt_BR: 'Enviado',
};
export const ERROR = {
  en_US: 'Error',
  es_ES: 'Erro',
  pt_BR: 'Erro',
};
