import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './ReimburserdArea.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

const ReimburserdArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, handleBlur, errors, setFieldTouched } = props;
  const amountClaimedValue = () => {
    let amountDamage =
      values.amount_estimation_damage && values.amount_estimation_damage.value
        ? values.amount_estimation_damage.value
        : 0;

    let amountPaid = 0;
    if (
      values.other_insurance_covering &&
      values.other_insurance_covering.is_internal
    ) {
      amountPaid = values.other_insurance_covering.value;
    }

    let result = amountDamage - amountPaid;

    return result !== 0 ? result.toFixed(2) : '0';
  };

  return (
    <div className={`row ${styles.reimburserdArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={amountClaimedValue()}
          label={intl.LABEL_TOTAL_AMOUNT_REIMBURSERD}
          name="total_amount_claimed.value"
          type="text"
          tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
          disabled
          onChange={handleChange}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          dataTestid="selectExpenseCurrency"
          label={intl.LABEL_CURRENCY}
          value={values.expense_currency}
          name="total_amount_claimed.currency"
          disabled
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          setFieldTouched={setFieldTouched}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
        />
      </div>
    </div>
  );
};

export default ReimburserdArea;
