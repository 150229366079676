import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppInsightTrackContext } from '../../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { IntlContext } from '../../../../../../../intl';
import { amosOthersService } from '../../../../../../../services';
import * as translations from './intl';
import styles from './DoneAppointmentDetail.module.scss';
import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';
import FileList from '../FileList/FileList';

const DoneAppointmentDetail = props => {
  const { consultation, isNullish } = props;
  const { translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const [doctor, setDoctor] = useState(null);

  const getDoctorInfo = useCallback(
    async doctorId => {
      try {
        trackEventUserAction('#### VMO ### Consultation - Carregando médico', {
          doctorId,
        });
        const { data } = await amosOthersService.getDoctor(doctorId);
        setDoctor(data.data);
        trackEventUserAction('#### VMO ### Consultation - Médico carregado', {
          data: data.data,
        });
      } catch (error) {
        trackEventUserAction(
          '#### VMO ### Consultation - Erro ao carregar médico',
          { error }
        );
      }
    },
    [trackEventUserAction]
  );

  useEffect(() => {
    getDoctorInfo(consultation.doctor_id);
  }, [consultation.doctor_id, getDoctorInfo]);
  let doctorFullName = '';
  if (doctor && doctor.name) doctorFullName = `${doctor.name} `;
  if (doctor && doctor.surname) doctorFullName += doctor.surname;
  return (
    <>
      <div className="col-12" data-testid="doneAppointmentDetailTitle">
        <span className={styles.title}>{intl.TITLE_DOCTOR_COMMENT}</span>
      </div>
      {doctor && doctor.photo && (
        <div
          className={`col-12 ${styles.containerDoctorInfo}`}
          data-testid="doctor-photo"
        >
          <img className={styles.doctorPhoto} src={doctor.photo} />
          {!!doctorFullName && (
            <span className={styles.doctorName}>{doctorFullName}</span>
          )}
        </div>
      )}

      {consultation && consultation.doctor_answer && (
        <div className="col-12 col-sm-12 col-md-6 col-lg-6">
          <InfoLabelValue
            label={intl.DOCTOR_COMMENT}
            value={isNullish(consultation.doctor_answer)}
          />
        </div>
      )}
      {consultation &&
        consultation.doctor_files &&
        consultation.doctor_files.length > 0 && (
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <FileList
              label={intl.DOCTOR_UPLOAD_FILES}
              files={consultation.doctor_files}
            />
          </div>
        )}
    </>
  );
};

export default DoneAppointmentDetail;
