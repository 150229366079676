export const TITLE_CLAIM = {
  en_US: 'Submit a new claim',
  es_ES: 'Generar un nuevo reclamo',
  pt_BR: 'Abrir um novo sinistro',
};

export const TITLE_BENEFITS_CATEGORY_ALL = {
  en_US: 'All benefits',
  pt_BR: 'Todos os benefícios',
  es_ES: 'Todos los beneficios',
};

export const TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION = {
  en_US: 'Travel',
  pt_BR: 'Viagem',
  es_ES: 'Viaje',
};

export const TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION = {
  en_US: 'Purchase protection',
  pt_BR: 'Proteção de compra',
  es_ES: 'Protección de compra',
};

export const TITLE_BENEFITS_CATEGORY_LIFESTYLE = {
  en_US: 'Lifestyle',
  pt_BR: 'Estilo de Vida',
  es_ES: 'Estilo de Vida',
};

export const TITLE_BENEFITS_CATEGORY_CONCIERGE = {
  en_US: 'Concierge',
  pt_BR: 'Concierge',
  es_ES: 'Concierge',
};

export const TEXT_MAKE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclam',
};

export const TEXT_DESCRIPTION = {
  en_US:
    'To file this claim you will need to provide the following information:',
  pt_BR:
    'Para apresentar este sinistro, você precisará fornecer as seguintes informações:',
  es_ES:
    'Para presentar este reclamo, deberás proporcionar la siguiente información:',
};

export const TEXT_INFO_INCIDENT = {
  en_US: 'Information about your incident',
  pt_BR: 'Informações sobre seu incidente',
  es_ES: 'Información sobre tu incidente',
};

export const TEXT_PERSONAL_INFO = {
  en_US: 'Personal Information',
  pt_BR: 'Informação pessoal',
  es_ES: 'Informacion personal',
};

export const TEXT_ALL_CLAIMS = {
  en_US: 'View all claims',
  es_ES: 'Ver todos los reclamos',
  pt_BR: 'Ver todos os sinistros',
};

export const NO_CLAIM_TYPE_AVAILABLE = {
  en_US: 'No claim type available.',
  pt_BR: 'Nenhum tipo de sinistro disponível.',
  es_ES: 'No hay ningún tipo de reclamo disponible.',
};
