import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from '../LegalProtection.module.scss';
import * as translations from './intl';
import * as constants from '../consts';
import { StateContext } from '../../../../../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import Select from '../../../../../../../../components/Select/Select';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const {
    parentValues,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
  } = props;
  const { utils } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();
  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
    setFieldValue('affected_person.person.gender', '');
  };
  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );
    setFieldValue(
      'affected_person.person.gender',
      parentValues.policy.person.gender
    );
  };

  const changeRelationship = event => {
    setEmptyPersonalInputFields();
    if (event.target.value === constants.CARD_HOLDER_VALUE) {
      setCardHolderNameLastName();
    }
    handleChange(event);
  };

  return (
    <div className={`${styles.incidentArea}`}>
      <div>
        {selectedCard.name === 'Infinite' ||
        selectedCard.name === 'Visa Infinite' ||
        selectedCard.name === 'Business Infinite' ||
        selectedCard.name === 'Corporate Infinite' ? (
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.DOCUMENTATION_PROVIDED_INFINITE}
          </label>
        ) : (
          ''
        )}

        {selectedCard.name === 'Signature' ||
        selectedCard.name === 'Visa Signature' ||
        selectedCard.name === 'Signature Business' ||
        selectedCard.name === 'Signature Corporate' ||
        selectedCard.name === 'Corporate Signature' ? (
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.DOCUMENTATION_PROVIDED_SIGNATURE}
          </label>
        ) : (
          ''
        )}

        {selectedCard.name === 'CTA Air (250)' ||
        selectedCard.name === 'Visa CTA Air (250)' ||
        selectedCard.name === 'Visa Platinum Corporate' ||
        selectedCard.name === 'Platinum Corporate' ||
        selectedCard.name === 'Visa Platinum Business' ? (
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.DOCUMENTATION_PROVIDED_PLATINUM}
          </label>
        ) : (
          ''
        )}
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.PERSON_AFFECTED}
            name="affected_person.relationship"
            value={values.affected_person.relationship}
            onBlur={handleBlur}
            touched={
              touched.affected_person && touched.affected_person.relationship
            }
            error={
              errors.affected_person && errors.affected_person.relationship
            }
            onChange={changeRelationship}
          >
            <option value={constants.CARD_HOLDER_VALUE}>{intl.TEXT_CH}</option>
            <option value={constants.SPOUSE_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
            </option>
            <option value={constants.DEPENDENT_VALUE}>
              {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
            </option>
            <option value={constants.OTHER_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
            </option>
          </Select>
        </div>

        <div
          className={`col-12 col-md-6 ${
            values.affected_person.relationship !== constants.OTHER_VALUE
              ? styles.none
              : ''
          }`}
        >
          <Input
            value={values.affected_person.comment}
            label={intl.TEXT_OTHER}
            name="affected_person.comment"
            dataTestid="InputRelationshipComment"
            type="text"
            disabled={
              values.affected_person.relationship !== constants.OTHER_VALUE
            }
            onChange={handleChange}
            touched={touched.affected_person && touched.affected_person.comment}
            onBlur={handleBlur}
            error={errors.affected_person && errors.affected_person.comment}
            maxLength="255"
          />
        </div>
      </div>
      <div className="row">
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_NAME_AFFECTED}
            type="text"
            name="affected_person.person.first_name"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            value={values.affected_person.person.first_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.first_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.first_name
            }
            maxLength="40"
          />
        </div>
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_LAST_NAME_AFFECTED}
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            name="affected_person.person.last_name"
            value={values.affected_person.person.last_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.last_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.last_name
            }
            maxLength="80"
          />
        </div>
      </div>
      <div className={`row `}>
        <div
          className={`col-12 col-md-6 ${
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
              ? styles.none
              : ''
          }`}
        >
          <Select
            label={intl.TEXT_GENDER}
            name="affected_person.person.gender"
            value={values.affected_person.person.gender}
            onBlur={handleBlur}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.gender
            }
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.gender
            }
            onChange={e => {
              setEmptyPersonalInputFields();
              handleChange(e);
            }}
          >
            <option value="">{intl.TEXT_GENDER}</option>
            <option value={constants.MALE}>{intl.TEXT_GENDER_MALE}</option>
            <option value={constants.FEMALE}>{intl.TEXT_GENDER_FEMALE}</option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
