export const TEXT_MY_CARDS = {
  en_US: 'My cards',
  es_ES: 'Mis tarjetas',
  pt_BR: 'Meus cartões',
};

export const LIST_CARDS = {
  en_US: 'List Cards',
  es_ES: 'Lista de Tarjetas',
  pt_BR: 'Lista de Cartões',
};

export const ICON_OPEN_MENU_COUNTRIES_LIST = {
  en_US: 'Icon Open Menu Countries List',
  es_ES: 'Ícono para Abrir el Menú de Lista de Países',
  pt_BR: 'Ícone para Abrir o Menu da Lista de Países',
};
