import { useContext, useEffect, useState } from 'react';
import styles from './SearchBar.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { SearchItems } from './SearchItems';
import { StateContext } from '../StateContextParent/StateContextParent';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import SearchIcon from '../../assets/icons/SearchIcon';
import CloseIcon from '../../assets/icons/CloseIcon';
import { highlightStringBySearch, searchInclusive } from '../../utils/search';
import sanitize from '../../utils/sanitize';
import Button from '../../componentsV2/Button/Button';

const SearchBar = ({ placeholder, customData, color, additionalData = [] }) => {
  const { state } = useContext(StateContext);
  const isLogged = state.user.logged;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const defaultData = SearchItems(intl, isLogged);
  const [data, setData] = useState(customData ? customData : defaultData);
  const [filteredData, setFilteredData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [showInput, setShowInput] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const surface = color === '#FFFFFF' ? 'Inverse' : 'OnSurface';

  const handleFilter = event => {
    const searchString = event.target.value;
    setSearchInput(searchString);
    const updatedFilteredData = data.filter(value => {
      return searchInclusive(value.title, searchString);
    });

    if (searchString === '') {
      setFilteredData([]);
    } else {
      if (updatedFilteredData.length === 0) {
        setFilteredData([{ title: intl.EMPTY_RESEARCH_LIST_MESSAGE }]);
      } else {
        setFilteredData(updatedFilteredData);
      }
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setSearchInput('');
    setShowInput(false);
  };

  const focusOnInput = async () => {
    await new Promise(r => setTimeout(r, 150));
    document.getElementById('searchBar') &&
      document.getElementById('searchBar').focus();
  };

  useEffect(() => {
    const wrapperSearch = document.getElementById('search-headerOption');
    const btnSearch = document.getElementById('btnSearch');
    wrapperSearch.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setShowInput(false);
        btnSearch.focus();
        clearInput();
      }
    });
  }, []);

  useEffect(() => {
    setData(customData ? customData : defaultData.concat(additionalData));
  }, [additionalData, customData]);

  return (
    <div
      className={`${styles.container} ${styles[theme]}`}
      id="search-container"
    >
      <div
        id="search-headerOption"
        className={`${styles[theme]} ${styles.searchInputs}`}
      >
        <Button
          id="btnSearch"
          type="Icon"
          ariaLabel={intl.ARIA_LABEL_SEARCH}
          leadingIcon={<SearchIcon aria-hidden={true} color={color} />}
          surface={surface}
          onClick={
            !showInput
              ? () => {
                  setShowInput(true);
                  focusOnInput();
                }
              : clearInput
          }
        />

        {showInput && (
          <div className={`${styles.innerMobileContainer} ${styles[theme]}`}>
            <input
              id="searchBar"
              type="text"
              maxLength={60}
              value={searchInput}
              placeholder={placeholder}
              onChange={handleFilter}
              autoComplete="off"
            />
            <div
              className={`${styles[theme]} ${styles.closeIcon}`}
              onClick={clearInput}
            >
              <CloseIcon width={20} height={20} />
            </div>
          </div>
        )}
      </div>

      {showInput && filteredData.length != 0 && (
        <div className={styles.mobileFiltered}>
          <div className={`${styles.filteredData} ${styles[theme]}`}>
            {filteredData.map((item, key) => {
              const titleHighlighted = highlightStringBySearch(
                item.title,
                searchInput
              );

              return item.link ? (
                <a
                  className={`${styles[theme]} ${styles.dataItem}`}
                  href={item.link}
                  key={key}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(titleHighlighted),
                    }}
                  ></span>
                </a>
              ) : (
                <div className={`${styles.emptyMessage} ${styles[theme]} `}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(titleHighlighted),
                    }}
                  ></span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
