import { useContext, useState } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import styles from './DocumentUploadItem.module.scss';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import {
  extensions,
  fileExtensions,
} from '../../../../../../utils/fileExtensionsValidade';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { cmxService, userServices } from '../../../../../../services';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { Attachment } from '../../../../../../@types/APIs/Customer';
import FileUploader from '../../../../../../componentsV2/FileUploader/FileUploader';
import { Buffer } from 'buffer';
import ModalWithImage from '../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../components/ModalResult/ModalResult';
import { UploadedFile } from '../../../../../../componentsV2/FileUploader/FileCard/FileCard';
import StatusChip from '../../../../../../componentsV2/Chip/StatusChip/StatusChip';
import { StatusPossibility } from '../../../../../../componentsV2/Chip/StatusChip/status';

interface DocumentItemProps {
  document: Attachment;
}

const DocumentItem = ({ document }: DocumentItemProps) => {
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { translate, idiomForApi } = useContext(IntlContext);
  const { actions, utils } = useContext(StateContext);

  const [attachmentID, setAttachmentID] = useState(document.attachment_id);
  const [isApproved, setIsApproved] = useState(
    document.document_status.includes('ACCEPTED')
  );
  const [status, setStatus] = useState<StatusPossibility>(
    document.document_status as StatusPossibility
  );

  const intl = translate(translations);
  const documentDescription =
    document.document_type_name === 'National ID'
      ? intl.DESCRIPTION_NATIONAL_ID
      : intl.DESCRIPTION_PROOF_OF_RESIDENCY;

  const [description, setDescription] = useState(`${documentDescription}
      ${
        isApproved
          ? `
          ${intl.TEXT_VALID_UNTIL} ${document.document_valid_until}`
          : ``
      }`);

  const { modal } = actions;
  const cn = utils.getCn();

  const initialUploadedFilesList = document.document_name
    ? ([{ name: document.document_name }] as UploadedFile[])
    : [];

  const documentTitle =
    document.document_type_name === 'National ID'
      ? intl.TITLE_NATIONAL_ID
      : intl.TITLE_PROOF_OF_RESIDENCY;

  const uploadFile = async (file: File) => {
    const { name: document_name, type: content_type, size } = file;
    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - INICIOU O UPLOAD DO ARQUIVO ',
      {
        document_name,
        content_type,
        size,
      }
    );
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise(resolve => {
      reader.onload = resolve;
    });
    const content_data = String(reader.result).substr(
      String(reader.result).indexOf(',') + 1
    );

    const sizeKbFile = Number(Number(size / 1024).toFixed(2));
    const sizeMBFile = Number(Number(sizeKbFile / 1024).toFixed(2));
    //check size minor or equall to 1.5MB

    if (sizeKbFile > 1500) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - TAMANHO DO ARQUIVO MAIOR DO QUE O PERMITIDO ',
        {
          document_name,
          content_type,
          sizeKbFile,
        }
      );

      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_SIZE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_SIZE_ERROR');
    }

    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - FORMATO DO ARQUIVO NÃO PERMITIDO ',
        {
          document_name,
          content_type,
        }
      );
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_TYPE_ERROR');
    }

    const fileData = {
      content_data,
      content_type,
    };

    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - ARQUIVO SERÁ ENVIADO PARA O CMX ',
      {
        fileData,
      }
    );

    try {
      const responseCMX = await cmxService.uploadDocument(fileData);
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ARQUIVO SALVO NO CMX ',
        {
          dataCMX: responseCMX && responseCMX.data ? responseCMX.data : null,
        }
      );
      const data = {
        document_type_name: document.document_type_name,
        document_name: document_name,
        document_description: document.document_type_name,
        external_attachment_id: responseCMX.data,
        content_type: content_type,
      };
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - INFORMAÇÃOS DO ARQUIVO SERÃO ENVIADAS PARA O SALESFORCE ',
        {
          data,
        }
      );
      const {
        data: { attachment_id },
      } = await cmxService.saveIdCMX(cn, data, idiomForApi());
      setStatus('PENDING');
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - INFORMAÇÕES SALVAS NO SALESFORCE ',
        {
          attachment_id,
        }
      );
      setAttachmentID(attachment_id);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description.startsWith(
          'ContentType_is_invalid_Please_verify'
        )
      ) {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR CONTENT TYPE INVALIDO ',
          {
            error,
            error_response: error.response,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_TYPE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else if (error.response && error.response.status === 413) {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR TAMANHO DO ARQUIVO INVALIDO ',
          {
            error,
            error_response: error.response,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_SIZE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else {
        trackEventUserAction(
          '#### (UUP FLOW - PROFILE) #### - ERROR NO UPLOAD DO ARQUIVO  ',
          {
            error,
            error_response: error && error.response ? error.response : null,
          }
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      }
      throw error;
    }
  };

  const getFileToDownload = async () => {
    try {
      const { data }: { data: any } = await userServices.downloadFile(
        cn,
        attachmentID
      );
      const { document_name, file } = data;

      const fileExtension = fileExtensions.find(obj =>
        String(document_name).includes(obj.Extension.toLowerCase())
      );

      const bufferFile = Buffer.from(file as string, 'base64');
      const fileObj = new File([bufferFile], document_name, {
        type: fileExtension.MIME_Type,
      });

      return fileObj;
    } catch (error) {
      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_DOWNLOAD}
        />
      );
    }
  };

  const removeFile = async () => {
    try {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - USUÁRIO VAI REMOVER O ARQUIVO  ',
        {
          attachmentID,
        }
      );
      actions.modal.closeModal();
      await cmxService.deleteDocument(cn, attachmentID, idiomForApi());
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ARQUIVO REMOVIDO',
        true
      );
      setAttachmentID('');
    } catch (error) {
      trackEventUserAction(
        '#### (UUP FLOW - PROFILE) #### - ERROR AO REMOVER AQUIVO',
        {
          error,
          error_response: error && error.response ? error.response : error,
        }
      );

      throw error;
    }
  };

  const confirmRemoveFile = async () => {
    trackEventUserAction(
      '#### (UUP FLOW - PROFILE) #### - ABRIU O MODAL PARA CONFIRMAR A REMOÇÃO DO ARQUIVO',
      true
    );

    return new Promise<boolean>((resolve, reject) => {
      actions.modal.showModal(
        false,
        false,
        <ModalWithImage
          type="error"
          title={intl.DELETE_DOCUMENT_TITLE}
          subtitle={intl.DELETE_DOCUMENT_SUBTITLE}
        >
          <ModalResult
            textConfirm={intl.BTN_REMOVE_DOCUMENT}
            clickConfirm={async () => {
              try {
                await removeFile();
                resolve(true);

                if (isApproved) {
                  setDescription(documentDescription);
                  setIsApproved(false);
                }
              } catch (error) {
                reject();
              }
            }}
            textDeny={intl.TEXT_NO_KEEP_DOCUMENT}
            clickDeny={() => {
              actions.modal.closeModal();
              resolve(false);
            }}
            buttonConfirmType="borderRed"
          />
        </ModalWithImage>,
        true,
        false
      );
    });
  };

  return (
    <div className={styles.containerItem}>
      <FileUploader
        title={documentTitle}
        description={description}
        callbackAddFile={uploadFile}
        callbackDeleteFile={confirmRemoveFile}
        initialUploadedFilesList={initialUploadedFilesList}
        CustomChip={() => <StatusChip context="UUP" status={status} />}
        getFileToDownload={getFileToDownload}
      />
    </div>
  );
};

export default DocumentItem;
