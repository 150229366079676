import { useIconColors } from '../../hooks/useIconColors';

const SelectCountry = props => {
  const colors = useIconColors(props);

  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      width={24}
      height={24}
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M8.4 2c-.2.2-.5.3-.7.4-.2.1-1 .7-1.2.9-.5.4-1 .8-1.4 1.3-3.2 3.8-3.4 9.4-.2 13.5 3.6 4.5 10.3 5.2 14.8 1.6.7-.5 1.2-1.1 1.7-1.8"
        style={{
          fill: 'none',
          stroke: colors.secondary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
      <path
        style={{
          fill: 'none',
          stroke: colors.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        fill="none"
        stroke={colors.primary}
        d="M11.5 12.6c2.1 2.1 4.5 3.2 5.2 2.4.8-.8-.3-3.1-2.4-5.2C12.1 7.7 9.8 6.6 9 7.3c-.7.8.3 3.2 2.5 5.3z"
      />
      <path
        style={{
          fill: 'none',
          stroke: colors.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        stroke={colors.primary}
        d="M12.4 17.9c3.5.4 6.7-2.2 7-5.7.4-3.5-2.2-6.7-5.7-7-3.5-.4-6.7 2.2-7 5.7-.3 3.5 2.2 6.6 5.7 7zM9.2 15.6l8.3-8.4"
      />
    </svg>
  );
};

export default SelectCountry;
