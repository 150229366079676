import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './DatesArea.module.scss';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import Input from '../../../../../../../components/Input/Input';
import moment from 'moment';

const DatesArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    limitDate,
    exceptionCountry,
    limitedCards,
  } = props;

  return (
    <div className={styles.datesArea}>
      <div className="row">
        <div className="col-12 col-md-4">
          <Calendar
            value={values.from}
            label={intl.FIELD_START_DATE}
            name="from"
            id="from"
            minDate={new Date()}
            maxDate={limitedCards && !exceptionCountry ? limitDate : undefined}
            onChange={handleChange}
            touched={touched && touched.from}
            onBlur={handleBlur}
            error={errors && errors.from}
            setFieldTouched={setFieldTouched}
            dataTestid="InputInternalTripFrom"
          />
        </div>
        <div
          className={`col-12 col-md-4 ${
            values.is_one_way_travel ? styles.none : ''
          }`}
        >
          <Calendar
            value={values.to}
            label={intl.FIELD_RETURN_DATE}
            name="to"
            onChange={handleChange}
            minDate={values.from}
            maxDate={new Date(moment(values.from).add(31, 'days'))}
            touched={touched && touched.to}
            onBlur={handleBlur}
            error={errors && errors.to}
            setFieldTouched={setFieldTouched}
            dataTestid="InputInternalTripTo"
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            value={values.rental_company}
            label={intl.RENTAL_COMPANY}
            name="rental_company"
            type="text"
            dataTestid="InputTICompany"
            onChange={handleChange}
            touched={touched && touched.rental_company}
            onBlur={handleBlur}
            error={errors && errors.rental_company}
          />
        </div>
      </div>
    </div>
  );
};

export default DatesArea;
