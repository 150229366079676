import React, { useContext, useEffect } from 'react';
import * as translations from './intl';
import styles from './TelecomunicationTypeSelect.module.scss';
import RadioButton from '../../../../../../../components/RadioButton/RadioButton';
import { IntlContext } from '../../../../../../../intl';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  ConsultationPhoneType,
  ConsultationVideoType,
} from '../../../../../../../utils/consultationCategory';
const TelecomunicationTypeSelect = props => {
  const { setFieldValue, values } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const themes = getGlobalTheme();

  const handleChange = event => {
    setFieldValue('type', event.target.value);
    setFieldValue('timezone', '');
  };

  return (
    <div
      className={`row ${styles.isCardHolder}`}
      data-testid="InputClaimCHIsCHArea"
    >
      <div
        className={`${styles.fontGray12} ${styles.options} ${styles[themes]} row`}
      >
        <div className={`col-lg-12 col-sm-12`}>
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.LABEL_CHOOSE_TELECOMUNICATION_TYPE}
          </label>
        </div>
        <div className={`col-12`}>
          <RadioButton
            label={intl.LABEL_CHOOSE_TELECOMUNICATION_TYPE}
            defaultChecked={values.type === ConsultationVideoType}
            text={intl.TEXT_VIDEO_CALL}
            name="type"
            value={ConsultationVideoType}
            onChange={handleChange}
          />
        </div>
        <div className={`col-12`}>
          <RadioButton
            label={intl.LABEL_CHOOSE_TELECOMUNICATION_TYPE}
            defaultChecked={values.type === ConsultationPhoneType}
            text={intl.TEXT_PHONE_CALL}
            name="type"
            value={ConsultationPhoneType}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TelecomunicationTypeSelect;
