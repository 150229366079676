import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const PhoneArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <PhoneForm
      formikProps={props}
      title={intl.TEXT_PHONE_AREA}
      inputNames={{
        phoneType: 'attending_physician.phone.phone_type',
        countryCode: 'attending_physician.phone.international_prefix',
        phoneNumber: 'attending_physician.phone.number',
      }}
      testIds={{
        phoneType: 'selectAttendingPhoneType',
        countryCode: 'internationalPrefixAttending',
        phoneNumber: 'phoneNumberAttending',
      }}
    />
  );
};

export default PhoneArea;
