/* eslint-disable no-unused-vars */
import moment from 'moment';
export const formInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let incident =
    completeClaim && completeClaim.incident ? completeClaim.incident : '';
  let itemInformation =
    completeClaim && completeClaim.item_information
      ? completeClaim.item_information
      : '';

  let otherInsuranceCovering =
    completeClaim &&
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[0]
      ? completeClaim.other_insurance_covering[0]
      : '';
  let affectedPerson =
    completeClaim && completeClaim.affected_person
      ? completeClaim.affected_person
      : '';

  let totalAmountClaimed = completeClaim && completeClaim.total_amount_claimed;

  const getIncident = incident => {
    return {
      datetimeValue:
        incident && incident.datetimeValue
          ? new Date(moment(incident.datetimeValue, 'YYYY-MM-DD HH:mm:ss'))
          : '',
      description: incident && incident.description ? incident.description : '',
    };
  };

  const getTotalInvoice = total_invoice_amount => ({
    value:
      total_invoice_amount && total_invoice_amount.value
        ? total_invoice_amount.value
        : '',
    currency:
      total_invoice_amount && total_invoice_amount.currency
        ? total_invoice_amount.currency
        : '',
  });

  const getItemInformation = itemInformation => {
    return {
      label:
        itemInformation && itemInformation.label ? itemInformation.label : '',
      manufacturer:
        itemInformation && itemInformation.manufacturer
          ? itemInformation.manufacturer
          : '',
      model_number:
        itemInformation && itemInformation.model_number
          ? itemInformation.model_number
          : '',
      price: getPrice(itemInformation),
      is_full_charged_on_card:
        itemInformation && itemInformation.is_full_charged_on_card
          ? itemInformation.is_full_charged_on_card
          : 'true',
      purchase_date:
        itemInformation && itemInformation.purchase_date
          ? new Date(moment(itemInformation.purchase_date, 'YYYY-MM-DD'))
          : '',
      retailer:
        itemInformation && itemInformation.retailer
          ? itemInformation.retailer
          : '',
      paymentMethod:
        itemInformation && itemInformation.paymentMethod
          ? itemInformation.paymentMethod
          : '',
      number_of_installments:
        itemInformation && itemInformation.installments_number
          ? itemInformation.installments_number
          : '',
      total_invoice_amount: getTotalInvoice(
        itemInformation && itemInformation.total_invoice_amount
      ),
    };
  };
  const getOtherInsuranceCovering = otherInsuranceCovering => {
    return {
      is_internal:
        otherInsuranceCovering &&
        otherInsuranceCovering.is_internal !== undefined
          ? String(!otherInsuranceCovering.is_internal)
          : 'false',
      date:
        otherInsuranceCovering && otherInsuranceCovering.date
          ? new Date(moment(otherInsuranceCovering.date, 'YYYY-MM-DD'))
          : '',
      description:
        otherInsuranceCovering && otherInsuranceCovering.description
          ? otherInsuranceCovering.description
          : '',
      value:
        otherInsuranceCovering && otherInsuranceCovering.amount.value
          ? otherInsuranceCovering.amount.value
          : '',
      currency:
        otherInsuranceCovering && otherInsuranceCovering.amount.currency
          ? otherInsuranceCovering.amount.currency
          : '',
    };
  };
  const getPrice = itemInformation => {
    const price =
      itemInformation && itemInformation.price ? itemInformation.price : {};
    return {
      value: price && price.value ? price.value : '',
      currencyValue: price && price.currencyValue ? price.currencyValue : '',
    };
  };

  const getTotalAmountClaimed = totalAmount => ({
    value: totalAmount && totalAmount.value ? totalAmount.value : '',
    currency: totalAmount && totalAmount.currency ? totalAmount.currency : '',
  });

  return {
    affected_person: {
      relationship: 'Cardholder',
      relationshipCardholder: true,
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
        birth_date:
          person && person.birth_date
            ? new Date(moment(person.birth_date, 'YYYY-MM-DD'))
            : '',
        gender: person && person.gender ? person.gender : '',
      },
    },
    incident: getIncident(incident),
    item_information: getItemInformation(itemInformation),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    total_amount_claimed: getTotalAmountClaimed(totalAmountClaimed),
  };
};
