import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import PersonalArea from './PersonalArea/PersonalArea';
import PeriodBaggageLossArea from './PeriodBaggageLossArea/PeriodBaggageLossArea';
import PlaceLossArea from './PlaceLossArea/PlaceLossArea';
import InsuranceArea from './InsuranceArea/InsuranceArea';
import TotalAmountArea from './TotalAmountArea/TotalAmountArea';
import { hotelBurglaryFormInitialValues } from './initialValues';
import { hotelBurglarySchema } from './validationSchema';
import ProductsList from './ProductList/ProductList';

const HotelBurglary = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef } = props;

  const validationSchema = useMemo(() => hotelBurglarySchema(intl), [intl]);
  const initialValues = useMemo(
    () => hotelBurglaryFormInitialValues(parentValues.policy, idiom),
    [idiom, parentValues.policy]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;

        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormClaimBaggageLoss">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <PeriodBaggageLossArea parentValues={parentValues} {...props} />
              <PlaceLossArea parentValues={parentValues} {...props} />
              <ProductsList parentValues={parentValues} {...props} />
              <InsuranceArea parentValues={parentValues} {...props} />
              <TotalAmountArea parentValues={parentValues} {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default HotelBurglary;
