/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { travelService } from '../../../services';
import { StateContext } from '../../StateContextParent/StateContextParent';
import ModalInformation from '../../ModalInformation/ModalInformation';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Loading from '../../Loading/Loading';
import styles from './FlightDetailsModal.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import Details from './Details/Details';
import DividingLine from '../../DividingLine/DividingLine';

const FlightDetailsModal = ({ travelId }) => {
  const { actions, utils } = useContext(StateContext);

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const [travel, setTravel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadTravel = async () => {
      try {
        setIsLoading(true);

        const { data } = await travelService.getTravelDetail(
          utils.getCn(),
          travelId
        );
        setTravel(data);
        setIsLoading(false);
      } catch (error) {
        actions.modal.showModal(
          '',
          true,
          <ModalInformation
            type="error"
            message={intl.TITLE_UNEXPECTED_ERROR_OCCURRED}
          />
        );
      }
    };

    loadTravel();
  }, []);

  if (isLoading) return <Loading text={intl.TITLE_LOADING_FLIGHT_DETAILS} />;

  return (
    <div className={styles.container}>
      <h1 className={`${styles[theme]} ${styles.title}`}>
        {intl.TITLE_FLIGHT_DETAILS}
      </h1>
      <Details travel={travel} />
      {!travel.is_one_way_travel && (
        <>
          <DividingLine />
          <Details travel={travel} isReturn />
        </>
      )}
    </div>
  );
};

export default FlightDetailsModal;
