import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const HelpEmailIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 49 49"
      style={{
        enableBackground: 'new 0 0 49 49',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M43.2 8.9c1.1 0 2.1.9 2.1 2.1v27c0 1.1-.9 2.1-2.1 2.1H5.8c-1.1 0-2.1-.9-2.1-2.1V11c0-1.1.9-2.1 2.1-2.1h37.4m0-2H5.8c-2.2 0-4.1 1.8-4.1 4.1v27c0 2.2 1.8 4.1 4.1 4.1h37.4c2.2 0 4.1-1.8 4.1-4.1V11c0-2.3-1.8-4.1-4.1-4.1z"
        style={{
          fill: config_icon.primary,
        }}
      />
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M24.5 28.8 6.4 18.7l1-1.7 17.1 9.5L41.6 17l1 1.7z"
      />
    </svg>
  );
};

export default HelpEmailIcon;
