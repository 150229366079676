import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './QuestionsArea.module.scss';
import Select from '../../../../../../../../components/Select/Select';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';
import { pt_BR } from '../../../../../../../../intl/idioms';

const QuestionsArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue('other_insurance_covering.value', '');
    setFieldValue('other_insurance_covering.currency', '');
  };

  const setCurrency = currency => {
    setFieldValue('amount_estimation_damage.currency', currency);
    setFieldTouched('amount_estimation_damage.currency', true);
  };

  useEffect(() => {
    if (!values.amount_estimation_damage.currency) {
      const currencyOhterInsurance = values.other_insurance_covering.currency;
      const getCurrency = currencyOhterInsurance || getCurrencyByHomeCountry();

      if (getCurrency) {
        setCurrency(getCurrency);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.amount_estimation_damage.value}
          label={intl.TOTAL_PRICE_DIFFERENCE}
          name="amount_estimation_damage.value"
          type="text"
          disabled={true}
          tooltipText={idiom !== pt_BR ? intl.TOTAL_VALUE_TOOLTIP : null}
          onChange={handleChange}
          touched={true}
          onBlur={handleBlur}
          error={
            errors.amount_estimation_damage &&
            errors.amount_estimation_damage.value
          }
          dataTestid="inputDamage"
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.amount_estimation_damage.currency}
          name="amount_estimation_damage.currency"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={
            touched.amount_estimation_damage &&
            touched.amount_estimation_damage.currency
          }
          onBlur={handleBlur}
          error={
            errors.amount_estimation_damage &&
            errors.amount_estimation_damage.currency
          }
          dataTestid="damageCurrency"
        />
      </div>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={
                values.other_insurance_covering.is_internal === 'false'
              }
              text={intl.TEXT_YES}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherInsuranceCoveringYes"
              value={false}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={
                values.other_insurance_covering.is_internal === 'true'
              }
              text={intl.TEXT_NO}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherInsuranceCoveringNo"
              value={true}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal === 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivOtherInsuranceCoveringField"
      >
        <Input
          value={values.other_insurance_covering.value}
          label={intl.LABEL_AMOUNT_PAID}
          name="other_insurance_covering.value"
          dataTestid="InputOtherInsuranceCoveringValue"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          disabled={values.other_insurance_covering.is_internal === 'true'}
          onChange={handleChange}
          touched={
            touched.other_insurance_covering &&
            touched.other_insurance_covering.value
          }
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.value
          }
        />
      </div>
      <div
        data-testid="DivCurrency"
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal === 'true'
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.amount_estimation_damage.currency}
          name="other_insurance_covering.currency"
          disabled
          dataTestid="InputOtherInsuranceCoveringCurrency"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.currency
          }
        />
      </div>
    </div>
  );
};

export default QuestionsArea;
