import { useContext } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../consts';
import styles from '../ConcernedItems.module.scss';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const ItemAdd = props => {
  const { values, setFieldValue } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const addItem = event => {
    event.preventDefault();
    const item = [...values.incident.concerned_items];
    item.push(constants.ITEM_OBJECT);
    setFieldValue('incident.concerned_items', item);
  };
  return (
    <div className="row">
      <div className={`col-12 ${styles.add}`}>
        <Button
          type="Tertiary"
          onClick={addItem}
          label={intl.BTN_ADD_ADDITIONAL}
          leadingIcon={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default ItemAdd;
