export const OPTION_DEFAULT_SELECTED_CARD = 'OPTION_DEFAULT_SELECTED_CARD';

/* GENDER */
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const MALE_VALUE_ABV = 'ml';
export const FEMALE_VALUE_ABV = 'fm';
export const UNKNOWN_VALUE = 'UNKNOWN';
export const UNKNOWN_VALUE_ABV = 'unknown';

/* TITLE */
export const TITLE_MR = 'MR';
export const TITLE_MS = 'MS';
export const TITLE_MISS = 'MISS';
export const TITLE_DR = 'DR';
export const TITLE_PR = 'PR';

/* ACCOUNT TYPE*/
export const CHEKING = 'CHECKING';
export const SAVING = 'SAVING';

/* BANK */
export const MEXICO = 'Mexico';
export const US = 'united-states';
export const OTHER_COUNTRY = 'other-countries';
export const ACCNUMBER = 'accnumber';
export const IBAN = 'IBAN';

/* COUNTRIES */
export const VALUE_MX = 'MX';
export const VALUE_AR = 'AR';
export const VALUE_CO = 'CO';
export const VALUE_GT = 'GT';
export const VALUE_OTHER = 'OTHER';
