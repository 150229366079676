export const AT = {
  en_US: 'at',
  pt_BR: 'às',
  es_ES: 'a las',
};

export const TEXT_CONTACT_INFO = {
  en_US: 'Contact information',
  pt_BR: 'Informações de contato',
  es_ES: 'Información del contacto',
};

export const TEXT_REMEMBER = {
  en_US: 'Please remember that the session will last 30 minutes.',
  pt_BR: 'Lembre-se de que a sessão durará 30 minutos.',
  es_ES: 'Recuerde que la sesión durará 30 minutos.',
};

export const LABEL_STATUS = {
  en_US: 'Status',
  es_ES: 'Estado',
  pt_BR: 'Situação',
};

export const LABEL_COUNTRY = {
  en_US: 'Country',
  es_ES: 'País',
  pt_BR: 'País',
};
export const LABEL_TIME_ZONE = {
  en_US: 'Time Zone',
  es_ES: 'Zona Horária',
  pt_BR: 'Fuso Horário',
};

export const LABEL_LANGUAGE = {
  en_US: 'Language',
  es_ES: 'Idioma',
  pt_BR: 'Idioma',
};

export const OPTION_LANGUAGE_EN = {
  es_ES: 'Inglés',
  en_US: 'English',
  pt_BR: 'Inglês',
};

export const OPTION_LANGUAGE_PT = {
  es_ES: 'Portugués',
  en_US: 'Portuguese',
  pt_BR: 'Português',
};

export const OPTION_LANGUAGE_ES = {
  es_ES: 'Español',
  en_US: 'Spanish',
  pt_BR: 'Espanhol',
};

export const LABEL_PHONE = {
  en_US: 'Phone Number',
  es_ES: 'Teléfono',
  pt_BR: 'Número de Telefone',
};

export const LABEL_COUNTRY_CODE = {
  en_US: 'Country code',
  es_ES: 'Código de Área',
  pt_BR: 'DDI',
};

export const LABEL_REASON_APPOINTMENT = {
  es_ES: 'Motivo de la cita',
  en_US: 'Reason for the appointment',
  pt_BR: 'Motivo do agendamento',
};

export const TEXT_CANCEL_APPOINTMENT = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
export const LABEL_TIME = {
  en_US: 'Appointment Date and Time',
  es_ES: 'Fecha y Hora de la Cita',
  pt_BR: 'Data e Horário do agendamento',
};

export const LABEL_FILES = {
  en_US: 'Your files',
  es_ES: 'Tus archivos',
  pt_BR: 'Seus arquivos',
};
export const LABEL_JOIN = {
  es_ES: 'Iniciar',
  en_US: 'Join now',
  pt_BR: 'Entrar',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const BUTTON_UPLOAD_FILES = {
  es_ES: 'Subir archivos opcionales',
  en_US: 'Upload optional files',
  pt_BR: 'Enviar arquivos opcionais',
};

export const MODAL_DELETE_TITLE = {
  en_US: 'Are you sure you want to remove the file?',
  es_ES: '¿Está seguro de que desea eliminar el archivo?',
  pt_BR: 'Você está certo de que deseja remover o arquivo?',
};

export const BUTTON_CONTINUE = {
  en_US: 'Continue',
  pt_BR: 'Continuar',
  es_ES: 'Continuar',
};

export const BUTTON_CLOSE = {
  en_US: 'Close',
  pt_BR: 'Fechar',
  es_ES: 'Cerrar',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const RESCHEDULE_TEXT = {
  en_US: 'Reschedule',
  pt_BR: 'Reagendar',
  es_ES: 'Reprogramar',
};

export const TITLE_SENDING_YOUR_APPOINTMENT = {
  es_ES: 'Tu cita está siendo reprogramada...',
  en_US: 'Your appointment is being rescheduled...',
  pt_BR: 'Seu agendamento está sendo remarcado...',
};

export const TITLE_SENDING_YOUR_ATTACHMENT = {
  es_ES: 'Enviando tu archivo adjunto...',
  en_US: 'Sending your attachment...',
  pt_BR: 'Enviando seu anexo...',
};

export const TITLE_SUCCESS_REQUEST = {
  es_ES: '¡Tu cita está programada!',
  en_US: 'Your appointment is booked!',
  pt_BR: 'Seu agendamento está marcado!',
};

export const HAS_BEEN_SENT = {
  en_US: 'Your attachment was successfully sent.',
  pt_BR: 'Seu anexo foi enviado com sucesso.',
  es_ES: 'Tu adjunto fue enviado exitosamente.',
};

export const TITLE_UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_ERROR_FILESIZE = {
  en_US: 'Total files size larger than allowed (maximum size 1.5MB).',
  pt_BR:
    'Tamanho total dos arquivos maior que o permitido (tamanho máximo 1.5MB).',
  es_ES:
    'Tamaño total de archivos mayor que el permitido (tamaño máximo 1.5MB).',
};

export const MESSAGE_NOT_AVAILABLE_DATE_TIME = {
  es_ES:
    'La fecha y hora seleccionadas ya no están disponibles para la cita. Por favor, elija una nueva fecha de cita y vuelva a intentarlo.',
  en_US:
    'The selected date and time are not available for appointment anymore. Please choose a new appointment date and try again.',
  pt_BR:
    'A data e hora selecionadas não estão mais disponíveis no momento. Por favor, escolha uma nova data para o agendamento e tente novamente.',
};

export const TITLE_UPLOAD_ERROR = {
  en_US: 'An error occurred while uploading this file.',
  pt_BR: 'Ocorreu um erro ao enviar este arquivo.',
  es_ES: 'Se ha producido un error al subir este archivo.',
};

export const REMOVING = {
  en_US: 'Removing',
  pt_BR: 'Removendo',
  es_ES: 'Quitando',
};

export const SOMETHING_WENT_WRONG = {
  en_US: 'Something went wrong',
  pt_BR: 'Algo deu errado',
  es_ES: 'Algo salió mal',
};
