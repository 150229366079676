export const OPEN_CHAT = {
  en_US: 'Open chat',
  es_ES: 'Abrir chat',
  pt_BR: 'Inicie uma conversa',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};
