export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const RENTAL_COMPANY_Q_VALUE = 'Rental company';

export const AIRLINE = 'AIRCRAFT';
export const CRUISE_LINE = 'SHIP';
export const RAILROAD_COMPANY = 'RAILWAY';
export const BUS_COMPANY = 'BUS';
export const OTHER = 'OTHER';
export const ITEM_OBJECT = {
  description: '',
  price: {
    value: '',
    currency: '',
  },
};

export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const UNKNOWN_VALUE = 'UNKNOWN';

export const ADDITIONAL_OBJECT = {
  first_name: '',
  last_name: '',
  birth_date: '',
  gender: '',
  nationalities: '',
  relationship: 'Spouse',
  comment: '',
};
