export const TITLE = {
  en_US: 'Visa Digital Concierge',
  pt_BR: 'Visa Digital Concierge',
  es_ES: 'Visa Digital Concierge',
};

export const DESCRIPTION = {
  en_US: `It's short for you to enjoy this benefit. For this, it is enough that it is eligible for the Visa card selected below.`,
  pt_BR:
    'Falta pouco para você usufruir desse benefício. Para isso, basta que ele seja elegível no cartão Visa selecionado abaixo.',
  es_ES:
    'Es breve para que disfrutes de este beneficio. Para ello, basta con que sea elegible para la tarjeta Visa seleccionada a continuación.',
};

export const MORE_INFORMATION = {
  en_US: 'More information',
  pt_BR: 'Mais informações',
  es_ES: 'Mas informaciones',
};

export const MORE_INFORMATION_CARD = {
  en_US: 'To know what are the benefits of your Visa card, access:',
  pt_BR: 'Para saber quais são os benefícios do seu cartão Visa, acesse: ',
  es_ES: 'Para saber cuáles son los beneficios de tu tarjeta Visa, acceda a:',
};

export const BUTTON_BENEFITS = {
  en_US: 'Benefits Portal',
  pt_BR: 'Portal de Benefícios',
  es_ES: 'Portal de Beneficios',
};

export const PREFER_NUMBERS = {
  en_US: 'Or if you prefer, use one of the following telephone numbers:',
  pt_BR: 'Ou se preferir, utilize um dos seguintes telefones:',
  es_ES: 'O si lo prefiere, utilice uno de los siguientes números de teléfono:',
};

export const NOT_ELEBIGILITY_TRY_ANOTHER_CARD = {
  en_US:
    'Benefit not eligible for selected Visa card. Please try another one or add a new card.',
  pt_BR:
    'Benefício não elegível para o cartão Visa selecionado. Por favor, tente outro ou adicione um novo cartão.',
  es_ES:
    'Beneficio no elegible para la tarjeta Visa seleccionada. Pruebe con otra o agregue una nueva tarjeta.',
};

export const ACCESS_CONCIERGE = {
  en_US: 'Acessar Visa Digital Concierge',
  pt_BR: 'Acessar Visa Digital Concierge',
  es_ES: 'Acessar Visa Digital Concierge',
};

export const YOUR_CARDS = {
  en_US: 'Your cards',
  pt_BR: 'Seus cartões',
  es_ES: 'Tus tarjetas',
};
