import * as Yup from 'yup';
import moment from 'moment';
import { validateCPF } from '../../../../../utils/stringUtils';

export const validationSchema = intl =>
  Yup.object().shape({
    Passenger: Yup.array().of(
      Yup.object().shape({
        Name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        LastName: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        DateOfBirth: Yup.mixed()
          .test('match', intl.VALIDATION_MESSAGE_DATE, function (birth_date) {
            let yesterday = moment(new Date())
              .subtract(1, 'days')
              .format('YYYY-MM-DD HH:ss');
            return moment(birth_date).isSameOrBefore(yesterday);
          })
          .typeError(intl.TEXT_FIELD_REQUIRED),
        person_registrations: Yup.object().shape({
          value: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .when(
              ['registration_type'],
              {
                is: registration_type => {
                  return registration_type === 'CPF';
                },
                then: Yup.string()
                  .required(intl.TEXT_FIELD_REQUIRED)
                  .test('test-data-valid', intl.VALIDATE_CPF, function (value) {
                    return validateCPF(value);
                  }),
              },
              ['registration_type']
            ),
        }),
        phone: Yup.object().shape({
          phone_type: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
      })
    ),
  });
