import React, { useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../../intl';
import { withRouter } from 'react-router-dom';
import UpdatePage from './UpdatePage/UpdatePage';
import {
  claimsServices,
  reimbursmentService,
  certificatesServices,
} from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import { benefitToClaimType } from '../Generate/ClaimFillForm/Form/submit/benefitToClaimType';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import { productsAndBenefitsServices } from '../../../services/index';
import styles from './Update.module.scss';
import Loading from '../../../components/Loading/Loading';
import LoadingError from '../../../components/LoadingError/LoadingError';
import { formatReimbursmentDataV2 } from './format';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { isAllowedClaimReimbursmentUpdate } from '../../../utils/claims_name';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const loadClaimToUpdate = async (
  claimId,
  idiom,
  setData,
  claimTypeBenefit,
  setCertificate,
  bin,
  cn,
  claimType,
  trackEventUserAction
) => {
  try {
    trackEventUserAction(
      `#### (FORM EDIT CLAIM) VAI CARREGAR AS INFORMAÇÕES DA CLAIM`,
      true
    );
    const response = await Promise.all([
      claimsServices.getCompleteInfoByClaimId(claimId, claimTypeBenefit, idiom),
      certificatesServices.getCertificatesByBin(cn, bin, idiom),
      reimbursmentService.getAllReimbursmentClaimV2(claimId),
    ]);
    trackEventUserAction(
      `#### (FORM EDIT CLAIM) CARREGOU INFORMAÇÕES DE CLAIM/CERTIFICADO/PAGAMENTO`,
      response
    );
    const reimbursmentData = response[2].data;
    const reimbursement_id =
      reimbursmentData &&
      reimbursmentData[0] &&
      reimbursmentData[0].reimbursement_id
        ? reimbursmentData[0].reimbursement_id
        : null;

    trackEventUserAction(
      `#### (FORM EDIT CLAIM) CARREGOU INFORMAÇÕES DE REIMBURSEMENT ID`,
      reimbursement_id
    );
    if (reimbursement_id === null && reimbursmentData.length > 0) {
      throw new Error();
    }

    const reimbursmentDetail = reimbursement_id
      ? await reimbursmentService.getReimbursmentV2(claimId, reimbursement_id)
      : {};

    let reimbursement =
      reimbursement_id && isAllowedClaimReimbursmentUpdate(claimType)
        ? formatReimbursmentDataV2(reimbursmentDetail.data)
        : { BankAccountHolder: { relationship_2: 'ThirdParty' } };

    const certificateId = response[0].data.certificate_id;
    const certificate =
      certificateId &&
      response[1].data.find(
        certificate => certificate.certificate_id === certificateId
      );

    if (certificate) {
      setCertificate(certificate);
    } else if (certificateId !== null && certificateId !== undefined) {
      throw new Error();
    }

    const generalInformation = response[0].data.policy.holder;
    const completeClaim = response[0].data;
    completeClaim.reimbursement_id = reimbursement_id;

    const legalConsents = response[0].data.legal_consents;
    const data = {
      generalInformation,
      completeClaim,
      reimbursement,
      legalConsents,
    };

    trackEventUserAction(
      `#### (FORM EDIT CLAIM) CARREGOU INFORMAÇÕES GENERAL INFORMATION/INCIDENT/REIMBURSEMENT/LEGAL`,
      data
    );
    setData(data);
  } catch (error) {
    setData('ERROR');
  }
};

const loadClaimInfoHeading = async (
  binOrPan,
  claimType,
  setClaimInfoHeading,
  idiom
) => {
  const benefit = await productsAndBenefitsServices.getBenefitsDetailByProduct(
    binOrPan,
    claimType,
    idiom
  );
  setClaimInfoHeading(benefit && benefit.data);
};

const Update = props => {
  const [data, setData] = useState('LOADING');
  const [claimInfoHeading, setClaimInfoHeading] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const { idiomForApi } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { claim_id: claimId, claim_type: claimType } = props.match.params;
  const bin = utils.getBin();
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const claimTypeBenefit = benefitToClaimType[claimType];
  const isLoading = data === 'LOADING';
  const isError = data === 'ERROR';
  const successLoad = !isLoading && !isError;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  useEffect(() => {
    loadClaimInfoHeading(
      binOrPan,
      claimType,
      setClaimInfoHeading,
      idiomForApi()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadClaimToUpdate(
      claimId,
      idiomForApi(),
      setData,
      claimTypeBenefit,
      setCertificate,
      bin,
      cn,
      claimType,
      trackEventUserAction
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#fff" />
        </div>
      )}
      {successLoad && (
        <SessionContainer>
          {claimInfoHeading && <HeadingTitle title={claimInfoHeading.name} />}

          <UpdatePage
            loadedData={data}
            claimType={claimType}
            certificate={certificate ? certificate.certificate_id : ''}
          />
        </SessionContainer>
      )}
    </div>
  );
};

export default withRouter(Update);
