import { LACLinkByCode } from '../../intl/lacLinkByCode';

export const BTN_UPGRADE_ME = {
  en_US: 'Learn more',
  es_ES: 'Saber más',
  pt_BR: 'Saiba mais',
};

export const TEXT_UPGRADE_ME = {
  en_US: 'Do you know all Visa products?',
  es_ES: '¿Conoces todos los productos Visa?',
  pt_BR: 'Você conhece todos os produtos Visa?',
};

export const CARD_BY_MODEL = {
  en_US: name => `${name} Card`,
  es_ES: name => `Tarjeta ${name}`,
  pt_BR: name => `Cartão ${name}`,
};

export const VISA_LINK = {
  en_US: code => {
    const url = LACLinkByCode[code]
      ? LACLinkByCode[code]
      : 'https://www.visa.com';

    return url;
  },
  es_ES: code => {
    const url = LACLinkByCode[code]
      ? LACLinkByCode[code]
      : 'https://www.visa.com.mx';

    return url;
  },
  pt_BR: code => 'https://www.visa.com.br',
};
