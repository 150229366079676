import { useCallback, useState } from 'react';
import { ImprovementOptions } from '../../../@types/APIs/Faq';
import { useIntl } from '../../../intl';
import Button from '../../../componentsV2/Button/Button';
import CheckBox from '../../CheckBox/CheckBox';
import styles from './faqImprovementItems.module.scss';
import * as translations from './intl';
import { faqService } from '../../../services';
import Loading from '../../Loading/Loading';

interface ImprovementCheckBoxItem {
  improvement_id: string;
  improvement_text: string;
  improvement_checked: boolean;
}

interface FaqImprovementItemsProps {
  items: ImprovementOptions[];
  id: string;
  setWasHelpful: (value: boolean | null) => void;
}

type statusImprovement = 'completed' | 'error' | 'loading' | '';

const createValueCheckbox = (items: ImprovementOptions[]) => {
  return items.map(item => {
    return {
      improvement_id: item.improvement_option_id,
      improvement_text: item.improvement_option_text,
      improvement_checked: false,
    };
  });
};

const FaqImprovementItems = ({
  items,
  id,
  setWasHelpful,
}: FaqImprovementItemsProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);
  const [improvementList, setImprovementList] = useState<
    ImprovementCheckBoxItem[]
  >(createValueCheckbox(items));
  const [status, setStatus] = useState<statusImprovement>('');

  const handleConcludeFeedback = async () => {
    try {
      setStatus('loading');
      const optionSelected = improvementList
        .filter(improvement => improvement.improvement_checked)
        .map(improvement => improvement.improvement_id);
      await faqService.postEvaluation(id, false, optionSelected);
      setStatus('completed');
    } catch (error) {
      setStatus('error');
    }
  };

  const handleCheckbox = useCallback((improvement_id: string) => {
    setImprovementList(prevState =>
      prevState.map(value =>
        value.improvement_id == improvement_id
          ? { ...value, improvement_checked: !value.improvement_checked }
          : value
      )
    );
  }, []);

  const showCheckbox = improvementList.map(item => (
    <CheckBox
      name="accept_terms"
      value={item.improvement_checked}
      onClick={() => handleCheckbox(item.improvement_id)}
      key={item.improvement_id}
    >
      {item.improvement_text}
    </CheckBox>
  ));

  const checkIfIsChecked = improvementList.some(
    item => item.improvement_checked
  );

  return (
    <>
      <div className={styles.wrapperImprovement}>
        {status == 'loading' ? (
          <Loading />
        ) : (
          <>
            {status == 'completed' ? (
              <p>
                <strong>{intl.TEXT_FEEDBACK}</strong>
              </p>
            ) : (
              <>
                <strong>{intl.TEXT_HELP_US}</strong>
                <p>{intl.TEXT_SELECT_IMPROVEMENT}</p>
                {showCheckbox}
                {status == 'error' && (
                  <p className={styles.alertError}>
                    <strong>{intl.TEXT_ERROR}</strong>
                  </p>
                )}
                <div className={styles.actionButtons}>
                  <Button
                    onClick={() => setWasHelpful(null)}
                    id="btn-Improvement-cancel"
                    type="Secondary"
                    label={intl.TEXT_CANCEL}
                  />
                  <Button
                    disabled={!checkIfIsChecked}
                    onClick={async () => {
                      await handleConcludeFeedback();
                    }}
                    id="btn-Improvement-completed"
                    label={intl.TEXT_CONCLUDE}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FaqImprovementItems;
