import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import styles from './Bluebox.module.scss';

interface BlueboxQRCodeProps {
  link: string;
}

const BlueboxQRCode = ({ link }: BlueboxQRCodeProps) => {
  return <QRCodeSVG value={link} className={styles.QRcode} includeMargin />;
};

export default BlueboxQRCode;
