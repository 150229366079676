export const TEXT_TITLE_ACTIVE = {
  en_US: 'Active certificates',
  es_ES: 'Certificados activos',
  pt_BR: 'Bilhetes válidos',
};

export const TEXT_SUBTITLE = {
  en_US:
    'Feel free to look through your certificates and check more information.',
  es_ES: 'No dude en revisar tus certificados y comprobar más información.',
  pt_BR:
    'Fique à vontade para detalhar seus bilhetes e verificar mais informações.',
};

export const TEXT_TITLE_PAST = {
  en_US: 'Expired certificates',
  es_ES: 'Certificados caducados',
  pt_BR: 'Bilhetes expirados',
};

export const TEXT_SEE_MORE = {
  en_US: 'See more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_SEE_LESS = {
  en_US: 'See less',
  es_ES: 'Ver menos',
  pt_BR: 'Veja menos',
};

export const TEXT_NO_HAVE_ACTIVE_CERTIFICATE = {
  en_US: 'You do not currently have any active certificate.',
  es_ES: 'Actualmente no tienes ningún certificado activo.',
  pt_BR: 'No momento, você não tem nenhum bilhete válido.',
};

export const TEXT_NO_HAVE_EXPIRED_CERTIFICATE = {
  en_US: 'You do not currently have any expired certificate.',
  es_ES: 'Actualmente no tienes ningún certificados caducado.',
  pt_BR: 'No momento, você não tem nenhum bilhete expirado.',
};

export const TEXT_YOUR_CERTIFICATES = {
  en_US: 'Your certificates',
  es_ES: 'Tus certificados',
  pt_BR: 'Seus bilhetes',
};

export const TEXT_PAGE_TITLE_ADVANCED_SEARCH = {
  en_US: 'Your certificates - Advanced search',
  es_ES: 'Tus certificados - Búsqueda avanzada',
  pt_BR: 'Seus bilhetes - Busca avançada',
};

export const TEXT_PAGE_SUBTITLE_ADVANCED_SEARCH = {
  en_US: 'See your certificates associated with other cards in your profile.',
  es_ES: 'Revisa tus certificados asociados con otras tarjetas en tu perfil.',
  pt_BR:
    'Consulte seus certificados associados a outros cartões existentes no seu perfil. ',
};

export const TEXT_BUTTON_ADVANCED_SEARCH = {
  en_US: 'Advanced search ',
  es_ES: 'Búsqueda avanzada',
  pt_BR: 'Busca avançada',
};
