import React, { useContext, useMemo } from 'react';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { tripFormInitialValues } from './initialValues';
import { tripProtectionSchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';
import PeriodArea from './PeriodArea/PeriodArea';
import IndemnityArea from './IndemnityArea/IndemnityArea';
import AdditionalPassengers from './AdditionalPassengers/AdditionalPassengers';
import ReimbursementArea from './ReimbursementArea/ReimbursementArea';

const TripDelay = props => {
  const { translate, idiom } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(() => tripProtectionSchema(intl), [intl]);
  const initialValues = useMemo(
    () => tripFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy, loadedData]
  );

  const card = utils.getSelectedCard();
  const showIndemnityArea =
    card.name === 'Infinite' ||
    card.name === 'Business Infinite' ||
    card.name === 'Corporate Infinite';

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              {showIndemnityArea && <IndemnityArea />}
              <PersonalArea parentValues={parentValues} {...props} />
              <PeriodArea {...props} />
              <IncidentArea {...props} />
              <AdditionalPassengers {...props} />
              <QuestionsArea {...props} />
              <ReimbursementArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default TripDelay;
