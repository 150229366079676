import React from 'react';
import AdditionalPassangers from './AdditionalPassangers/AdditionalPassangers';
import IncidentArea from './IncidentArea/IncidentArea';
import PeriodArea from './PeriodArea/PeriodArea';
import PersonalArea from './PersonalArea/PersonalArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';

const MissedConnection = props => {
  const { claimDetails } = props;
  const notShow =
    claimDetails.additional_passengers === null ||
    claimDetails.additional_passengers.length === 0;
  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      {notShow ? <></> : <AdditionalPassangers claimDetails={claimDetails} />}
      <PeriodArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <QuestionsArea claimDetails={claimDetails} />
    </div>
  );
};

export default MissedConnection;
