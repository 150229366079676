import { useContext } from 'react';
import styles from './CheckBoxPaymentType.module.scss';
import { IntlContext } from '../../../../../../../../intl';
import sanitize from '../../../../../../../../utils/sanitize';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import * as translations from './intl';
import { TYPE_BANK, TYPE_OCT } from '../oct-const';

interface CheckBoxPaymentTypeProps {
  enableOCT: boolean;
  setBankType?: (value: string) => void;
}

const CheckBoxPaymentType = ({
  enableOCT,
  setBankType,
}: CheckBoxPaymentTypeProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const setPaymentType = event => {
    setBankType(event.target.value);
  };

  return (
    <div className={`row`}>
      <div className={`${styles.radioOptionContainer} col-lg-6 col-sm-12`}>
        <RadioButton
          defaultChecked={enableOCT ? true : false}
          dataTestid="radioPaymentOCT"
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.DIRECT_PAYMENT_OCT),
          }}
          name="paymentType"
          value={TYPE_OCT}
          onChange={setPaymentType}
          tooltip={intl.PAYMENT_OCT_TOOLTIP}
        >
          <span
            className={styles.radioOptionInfo}
            dangerouslySetInnerHTML={{ __html: intl.DIRECT_PAYMENT_OCT_INFO }}
          ></span>
        </RadioButton>
      </div>
      <div className={`${styles.radioOptionContainer} col-lg-6 col-sm-12`}>
        <RadioButton
          defaultChecked={enableOCT ? false : true}
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.BANK_ACCOUNT),
          }}
          name="paymentType"
          value={TYPE_BANK}
          onChange={setPaymentType}
          dataTestid="radioBankAcount"
        >
          <span className={styles.radioOptionInfo}>
            {intl.BANK_ACCOUNT_INFO}
          </span>
        </RadioButton>
      </div>
    </div>
  );
};

export default CheckBoxPaymentType;
