export const AVAILABLE = {
  en_US: 'Available',
  pt_BR: '',
  es_ES: 'Disponible',
};

export const UNAVAILABLE = {
  en_US: 'Unavailable',
  pt_BR: '',
  es_ES: 'No disponible',
};

export const SELECTED = {
  en_US: 'Selected',
  pt_BR: '',
  es_ES: 'Seleccionado',
};
