export const EMAIL_CHANGE = {
  en_US:
    'Your email was changed and later confirmed by you in the authentication system.',
  es_ES:
    'Tu correo electrónico fue cambiado y luego confirmado por ti en el sistema de autenticación.',
  pt_BR:
    'Seu e-mail foi alterado e posteriormente confirmado por você no sistema de autenticação.',
};

export const EMAIL_UPDATE = {
  en_US: email =>
    `At this moment, we will automatically update your email address in your Visa Benefits Portal profile to: ${email}`,
  es_ES: email =>
    `En este momento, actualizaremos automáticamente tu dirección de correo electrónico en tu perfil del Portal de Beneficios de Visa a: ${email}`,
  pt_BR: email =>
    `Nesse momento, atualizaremos automaticamente seu e-mail no seu perfil no Portal de Benefícios Visa para: ${email}`,
};

export const OK = {
  en_US: 'Ok, I got it',
  es_ES: 'OK entendí',
  pt_BR: 'Ok, entendi',
};

export const ERROR_UPDATE = {
  en_US: 'Error updating your personal information. Please try again later.',
  pt_BR:
    'Erro ao atualizar suas informações pessoais. Por favor, tente novamente mais tarde.',
  es_ES:
    'Error al actualizar tu información personal. Por favor, inténtalo de nuevo más tarde.',
};
