import * as Yup from 'yup';
import moment from 'moment';

import { stringToBoolean } from '../../../../../../../utils/stringUtils';
export const purchaseProtectionSchema = intl =>
  Yup.lazy(value =>
    Yup.object().shape({
      affected_person: Yup.object().shape({
        relationshipCardholder: Yup.bool().oneOf(
          [true],
          intl.TEXT_FIELD_REQUIRED
        ),
        person: Yup.object().shape({
          first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      incident: Yup.object().shape({
        dateValue: Yup.date()
          .test('match', intl.CUSTOM_DATE_VALIDATION, function (dateValue) {
            let result = true;
            value.purchase_protection_items.map(item => {
              if (item.purchase_date) {
                if (
                  !moment(dateValue).isSameOrBefore(
                    moment(item.purchase_date).add(180, 'd')
                  )
                ) {
                  result = false;
                }
              }
            });
            return result;
          })
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID),
        description: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255),
      }),
      purchase_protection_items: Yup.array().of(
        Yup.object().shape({
          label: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          manufacturer: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          model_number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          purchase_date: Yup.mixed().test(
            'match',
            intl.VALIDATE_DATE_VALID,
            function (purchase_date) {
              return moment(purchase_date).isSameOrBefore(new Date());
            }
          ),
          status: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          price: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          total_invoice_amount: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          amount_claimed: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
          other_insurance_covering: Yup.lazy(value => {
            let is_internal = stringToBoolean(value.is_internal);
            let other_insurance_covering_detail = {};

            if (!is_internal) {
              other_insurance_covering_detail.is_internal =
                Yup.string().required(intl.TEXT_FIELD_REQUIRED);
              other_insurance_covering_detail.amount = Yup.object().shape({
                value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
              });
            }

            return Yup.object().shape({
              ...other_insurance_covering_detail,
            });
          }),
          paymentMethod: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          number_of_installments: Yup.number().when(
            ['paymentMethod'],
            {
              is: paymentMethod => {
                return paymentMethod === 'installment';
              },
              then: Yup.number()
                .positive(intl.TEXT_FIELD_REQUIRED)
                .required(intl.TEXT_FIELD_REQUIRED)
                .typeError(intl.TEXT_FIELD_REQUIRED),
            },
            ['paymentMethod']
          ),
        })
      ),
    })
  );
