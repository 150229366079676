import { useEffect, useState, useContext } from 'react';
import Card from './Card/Card';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import Loading from '../Loading/Loading';
import Carousel from '../Carousel/Carousel';
import styles from './PrivateYourCards.module.scss';
import { cardsServices } from '../../services';
import { StateContext } from '../StateContextParent/StateContextParent';
import ModalInformation from '../ModalInformation/ModalInformation';
import ModalWithImage from '../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../components/ModalResult/ModalResult';
import { showModalAddCard } from '../ModalAddCard/functionShowModal';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { NotificationContext } from '../NotificationContextParent/NotificationContextParent';
import AddIcon from '../../assets/icons/AddIcon';
import { isIssuerDomain } from '../../utils/banks';
import Button from '../../componentsV2/Button/Button';

const PrivateYourCards = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    utils,
    actions,
    state: { cards },
  } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const [cardsData, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const isBmp = isIssuerDomain();
  const canAddCard = !isBmp;

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3.6,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    onReInit: () => changePositionCards(),
    responsive: [
      {
        breakpoint: 1620,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1310,
        settings: {
          slidesToShow: 2.8,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1180,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1.9,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 2.4,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 590,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1.3,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };

  const getBmpCard = cards =>
    [cards.find(card => card.is_preferred)] ?? [cards[0]];

  const loadData = async customer_id => {
    try {
      setLoading(true);
      const { data } = await cardsServices.getLoggedUserCards(customer_id);
      const cards = isBmp ? getBmpCard(data) : data;
      setCards(cards);
    } catch (error) {}
    setLoading(false);
  };

  function changePositionCards() {
    try {
      document
        .getElementsByClassName('slick-track')[0]
        .classList.add(styles.changePositionCards);
    } catch (e) {}
  }

  useEffect(() => {
    if (cn) {
      loadData(cn);
    }
  }, [cn]);

  async function handleSetPreferredCard(customer_id, card_name, bin_number) {
    setLoading(true);
    try {
      await cardsServices.changePreferredCard(
        customer_id,
        card_name,
        bin_number
      );
      await actions.cards.changeSelectedCardIndex(bin_number);
      await actions.cards.getLoggedUserCards();
      notificationActions.toastNotification.setResetAppereanceToast();

      let { data } = JSON.parse(sessionStorage.getItem('cards'));
      if (data) setCards(data.sort((a, b) => b.is_preferred - a.is_preferred));
    } catch (error) {}
    setLoading(false);
  }

  async function handleDeleteCard(customer_id, bin_number) {
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.MODAL_DELETE_TITLE}
        subtitle={intl.MODAL_DELETE_SUBTITLE}
      >
        <ModalResult
          textConfirm={intl.MODAL_DELETE_YES}
          textDeny={intl.MODAL_DELETE_NO}
          buttonConfirmType="borderRed"
          clickConfirm={async () => {
            try {
              actions.modal.showLoading(intl.LOADING_REMOVING_CARD);
              await cardsServices.removeCard(customer_id, bin_number);
              await actions.cards.removeCard(bin_number);
              actions.modal.hideLoading();
              actions.modal.showModal(
                '',
                true,
                <ModalInformation
                  type="success"
                  message={intl.SUCCESS_CARD_REMOVED}
                  clickBtn={() => window.location.reload()}
                />,
                true
              );
            } catch (error) {
              actions.modal.hideLoading();
              actions.modal.showModal(
                false,
                false,
                <ModalInformation type="error" message={'Algo deu errado'} />,
                true,
                false
              );
            }
          }}
          clickDeny={() => {
            actions.modal.closeModal();
          }}
        />
      </ModalWithImage>,
      true
    );
  }

  return (
    <div id="cards" className={styles.container}>
      <div className={styles.contentTitle}>
        <h2 className={`${styles.title} ${styles[themes]}`}>
          {isBmp ? intl.YOUR_CARD : intl.YOUR_CARDS}
        </h2>

        {cardsData.length > 2 && canAddCard && (
          <Button
            id="addNewCardButton"
            type="Tertiary"
            onClick={() => showModalAddCard(actions, intl, 'Profile')}
            leadingIcon={<AddIcon />}
            label={
              <>
                <span className={styles.titleAddWeb}>
                  {intl.ADD_NEW_CARD_WEB}
                </span>
                <span className={styles.titleAddMobile}>
                  {intl.ADD_NEW_CARD_MOBILE}
                </span>
              </>
            }
          />
        )}
      </div>

      {loading ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <div id="carouselCard">
            <Carousel settings={settings}>
              {cardsData &&
                cardsData.map((card, index) => (
                  <Card
                    key={index}
                    cardData={card}
                    onPreferredCard={handleSetPreferredCard}
                    onDeleteCard={handleDeleteCard}
                    index={index}
                    cardContext={cards.data[index]}
                  />
                ))}
              {cardsData.length > 0 && cardsData.length < 3 && canAddCard && (
                <Card
                  isAddNew
                  onShowModalAddCard={() =>
                    showModalAddCard(actions, intl, 'Profile')
                  }
                />
              )}
            </Carousel>
          </div>
        </>
      )}
    </div>
  );
};

export default PrivateYourCards;
