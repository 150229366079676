export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const ACCIDENT_REASON = {
  en_US: 'Accident reason',
  pt_BR: 'Motivo do incidente',
  es_ES: 'Motivo del accidente',
};

export const ACCIDENTAL_DISMEMBERMENT = {
  en_US: 'Accidental dismemberment',
  pt_BR: 'Desmembramento acidental',
  es_ES: 'Desmembramiento accidental',
};

export const ACCIDENTAL_DEATH = {
  en_US: 'Accidental death',
  pt_BR: 'Morte acidental',
  es_ES: 'Muerte accidental',
};

export const DATE_ASSIGMENT_MADE = {
  en_US: 'Date assignment made *',
  pt_BR: 'Data de atribuição feita *',
  es_ES: 'Asignación de fecha hecha *',
};

export const DATE_RECEIVED_CANCELLATION = {
  en_US: 'Date received account cancellation request *',
  pt_BR: 'Pedido de cancelamento da conta recebida na data *',
  es_ES: 'Fecha de recepción de la solicitud de cancelación de cuenta *',
};

export const DATE_ACCOUNT_CANCELLATION = {
  en_US: 'Date of account cancellation on base I *',
  pt_BR: 'Data de cancelamento da conta na base I *',
  es_ES: 'Fecha de cancelación de la cuenta em la base I *',
};

export const DATE_RECEIVED_AFFIDAVIT = {
  en_US: 'Date received affidavit of waiver claim *',
  pt_BR: 'Data de recebimento da declaração de renúncia à renúncia *',
  es_ES: 'Fecha recebida declaración jurada de exención *',
};
