import React from 'react';
import { enrollService } from '../../../../../services';
import { formatEnroll } from './formatData';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import {
  dataLayerB2B2CPurchase,
  dataLayerB2B2CThankYou,
} from '../../../../../utils/GTM_helper';
import moment from 'moment';

export const submit = async (
  travelers,
  payment,
  actionsState,
  utilsB2B2C,
  actionsB2B2C,
  quote,
  selectedPlan,
  benefitDetail,
  policy_id,
  intl,
  history,
  backLinkAdditionalCoverages,
  idiomAPI,
  trackEventUserAction
) => {
  try {
    actionsState.loadingFullScreen.showLoadingFullScreen(intl.LOADING, true);

    const formatedData = formatEnroll(
      travelers,
      payment,
      quote,
      selectedPlan,
      benefitDetail,
      trackEventUserAction
    );

    const objDataLayerPurchase = {
      payment_type: 'VISA',
      currency: formatedData.payment_information.billing_currency,
      total_trip_cost: selectedPlan.Amount,
      number_travellers: formatedData.beneficiaries.length,
      departure_date: formatedData.travel_information.from,
      return_date: formatedData.travel_information.to,
      items: [
        {
          item_name: formatedData.product_criteria.external_product_name,
          item_id: formatedData.product_criteria.external_product_id,
          item_brand: 'AXA',
          price: selectedPlan.Amount,
        },
      ],
    };

    dataLayerB2B2CPurchase(objDataLayerPurchase);
    trackEventUserAction(`#### (B2B2C) SUBMITING ENROLL`, formatedData);
    const response = await enrollService.createEnroll(
      policy_id,
      formatedData,
      idiomAPI
    );

    actionsState.modal.showModal(
      false,
      false,
      <ModalInformation
        type="success"
        message={intl.TEXT_SUCCESS}
        subtitle={intl.TEXT_SUBTITLE_SUCCESS}
        textBtn={intl.BUTTON_MY_BENEFITS}
        clickBtn={() => {
          actionsState.modal.closeModal();
          history.push('/your-benefits');
          actionsB2B2C.formDataCotizar.setDataForm();
          utilsB2B2C.clearLocalStore();
        }}
        textBtnDeny={intl.BUTTON_MY_ADDITIONAL_COVERAGES}
        clickBtnDeny={() => {
          actionsState.modal.closeModal();
          history.push(backLinkAdditionalCoverages);
          actionsB2B2C.formDataCotizar.setDataForm();
          utilsB2B2C.clearLocalStore();
        }}
        buttonConfirmType="axaBlue"
      />,
      true,
      false,
      null,
      null,
      null,
      false,
      true
    );

    let under69 = Array.isArray(travelers.Passengers)
      ? travelers.Passengers.filter(passenger => passenger.Age < 70).length
      : 0;
    let over70 = Array.isArray(travelers.Passengers)
      ? travelers.Passengers.filter(passenger => passenger.Age >= 70).length
      : 0;

    if (Number(travelers.MPH.Age) < 70) {
      under69 = under69 + 1;
    } else {
      over70 = over70 + 1;
    }

    const purchase = {
      transaction_id: response.data.benefit_extension_id,
      value: selectedPlan.AmountWithoutPromotion,
      tax: selectedPlan.Rate,
      currency: 'USD',
      total_trip_cost: selectedPlan.Amount,
      number_travellers: Array.isArray(travelers.Passengers)
        ? travelers.Passengers.length + 1
        : 1,
      origin_country: quote.Quote.CountryOfResidenceIsoCode,
      destination_country: quote.Quote.DestinationCountryIsoCode,
      departure_date: formatedData.travel_information.from,
      return_date: formatedData.travel_information.to,
      total_days: moment(formatedData.travel_information.to).diff(
        moment(formatedData.travel_information.from),
        'days'
      ),
      travellers_under_69: under69,
      travellers_over_70: over70,
      items: [
        {
          item_name: selectedPlan.Name,
          item_id: selectedPlan.Id,
          item_brand: 'AXA',
          price: selectedPlan.Amount,
        },
      ],
    };

    dataLayerB2B2CThankYou(purchase);
  } catch (error) {
    trackEventUserAction(`#### (B2B2C) ERRO NO ENROLL`, {
      error,
      error_response: error.response,
    });
    if (
      error.response &&
      error.response.data &&
      error.response.data.error_description ===
        'There is another ATSP certificate under vigency at the moment'
    ) {
      actionsState.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR_ANOTHER_CERTIFICATE_UNDER_VIGENCY}
          buttonConfirmType="axaBlue"
        />,
        false,
        false,
        null,
        null,
        null,
        false,
        true
      );
    } else {
      actionsState.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.TEXT_ERROR}
          buttonConfirmType="axaBlue"
        />,
        false,
        false,
        null,
        null,
        null,
        false,
        true
      );
    }
  } finally {
    actionsState.loadingFullScreen.hideLoadingFullScreen();
  }
};
