import React, { useContext } from 'react';
import CloseIcon from '../../../../../../../assets/icons/CloseIcon';
import PDFIcon from '../../../../../../../assets/icons/PDFIcon';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './FileItem.module.scss';
import Button from '../../../../../../../componentsV2/Button/Button';
import { useMemoTranslations } from '../../../../../../../intl';
import * as translations from './intl';

const FileItem = ({ file, removeFile, index }) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = useMemoTranslations(translations);

  return (
    <div className={`${styles.fileContainer}`}>
      <div className={`${styles.infoContainer}`}>
        <PDFIcon width={28} height={40} />
        <span className={`${styles.name} ${styles[theme]}`}>{file.name}</span>
      </div>
      <Button
        type="Icon"
        testId="removeIcon"
        onClick={() => removeFile(index)}
        leadingIcon={<CloseIcon width={20} height={20} />}
        ariaLabel={intl.LABEL_DELETE}
      />
    </div>
  );
};

export default FileItem;
