const version = 'v1';

export default (env, axios, errorHandler) => {
  return {
    redeemVoucher: async (customer_id, data, idiom = 'en-US') => {
      const headers = {
        'accept-language': idiom,
      };
      const url = `/vouchers/specific/visagateway/${version}/customers/${customer_id}/vouchers/redeem_requests`;
      return errorHandler(
        axios.post(url, data, { headers }),
        'redeemCinepolisVoucher',
        {
          data,
          customer_id,
          url,
        }
      );
    },
    getVouchers: (
      customer_id,
      benefit_external_reference = 'CINEPOLIS',
      idiom = 'en-US'
    ) => {
      const headers = {
        'accept-language': idiom,
      };
      let url = `/vouchers/specific/visagateway/${version}/customers/${customer_id}/vouchers`;
      url = url + `?benefit_external_reference=${benefit_external_reference}`;
      return errorHandler(axios.get(url, { headers }), 'getVouchers', {
        customer_id,
        url,
        benefit_external_reference,
      });
    },
  };
};
