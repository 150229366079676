import { Segment } from '../@types/APIs/Travels';
import { removeDuplicates } from './array';

export type FlightStatus = 'DELAYED' | 'ON_TIME';

export type FlightNotificationType =
  | ''
  | 'ARRIVAL'
  | 'DELAYED_ARRIVAL'
  | 'DEPARTURE'
  | 'DELAYED_DEPARTURE';

export type FlightRegexKey = keyof typeof flightRegexes;

const flightRegexes = {
  title: {
    'en-US': new RegExp('Flight detail (.{1,7})'),
    'es-ES': new RegExp('Detalle del vuelo (.{1,7})'),
    'pt-BR': new RegExp('Detalhe do vôo (.{1,7})'),
  },
  arrival: {
    'en-US': new RegExp('.{1,7} arrived at .{1,4} from .{1,4}\\.'),
    'es-ES': new RegExp('.{1,7} llegó a .{1,4} desde .{1,4}\\.'),
    'pt-BR': new RegExp('.{1,7} chegou a .{1,4} vindo de .{1,4}\\.'),
  },
  delayedArrival: {
    'en-US': new RegExp('.{1,7} arrived delayed at .{1,4} from .{1,4}\\.'),
    'es-ES': new RegExp('.{1,7} llegó retrasado a .{1,4} desde .{1,4}\\.'),
    'pt-BR': new RegExp('.{1,7} chegou atrasado a .{1,4} vindo de .{1,4}\\.'),
  },
  departure: {
    'en-US': new RegExp('.{1,7} has departed from .{1,4} to .{1,4}\\.'),
    'es-ES': new RegExp('.{1,7} ha partido de .{1,4} a .{1,4}\\.'),
    'pt-BR': new RegExp('.{1,7} partiu de .{1,4} para .{1,4}\\.'),
  },
  delayedDeparture: {
    'en-US': new RegExp('.{1,7} has departed from .{1,4} to .{1,4} delayed\\.'),
    'es-ES': new RegExp('.{1,7} ha partido de .{1,4} a .{1,4} con retraso\\.'),
    'pt-BR': new RegExp('.{1,7} partiu de .{1,4} para .{1,4} com atraso\\.'),
  },
};

const matchAnyLanguages = (body: string, type: FlightRegexKey) =>
  Object.values(flightRegexes[type]).reduce(
    (value, regex) => body.match(regex) || value,
    false
  );

export const getIsFlightNotification = (
  body: string
): [boolean, FlightNotificationType] => {
  if (typeof body !== 'string') return [false, ''];

  if (matchAnyLanguages(body, 'arrival')) return [true, 'ARRIVAL'];
  if (matchAnyLanguages(body, 'delayedArrival'))
    return [true, 'DELAYED_ARRIVAL'];
  if (matchAnyLanguages(body, 'departure')) return [true, 'DEPARTURE'];
  if (matchAnyLanguages(body, 'delayedDeparture'))
    return [true, 'DELAYED_DEPARTURE'];

  return [false, ''];
};

export const getFlightStatus = (type: FlightStatus) => {
  if (!type) return '';
  return type.includes('DELAYED') ? 'DELAYED' : 'ON_TIME';
};

export const getFlightNumber = (notificationTitle: string) =>
  Object.values(flightRegexes.title).reduce(
    (value, regex) => regex.exec(notificationTitle)?.[1] || value,
    ''
  );

export const getCountryList = (segments: Segment[]): string[] => {
  const list = [segments[0].country_origin].concat(
    segments.map(segment => segment.country_destination)
  );
  return removeDuplicates(list);
};
