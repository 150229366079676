export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const LABEL_ADVERTISEMENT_DATE = {
  en_US: 'Advertisement date',
  pt_BR: 'Data do anúncio',
  es_ES: 'Fecha del anuncio',
};
