import React, { useContext } from 'react';
import moment from 'moment';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { pt_BR } from '../../../../../../intl/idioms';

const MedicalArea = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';
  const dateFormat = isPTBR || isMX ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  const dateLoad = prop => {
    return prop ? moment(prop, 'DD-MM-YYYY HH:mm:ss').format(dateFormat) : '-';
  };

  const medical_service = claimDetails.medical_service;

  const existing_case = claimDetails.existing_case.number;

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DESCRIPTION_EMERGENCY}
          value={safeLoad(medical_service.medical_service_description)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ASSISTANCE_START}
          value={dateLoad(medical_service.assistance_started_at)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ASSISTANCE_END}
          value={dateLoad(medical_service.assistance_finished_at)}
        />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DIAGNOSIS_DOCTOR}
          value={safeLoad(medical_service.diagnosis)}
        />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TREATMENT_DOCTOR}
          value={safeLoad(medical_service.medical_treatment_received)}
        />
      </div>

      {existing_case && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_CASE_NUMBER}
            value={safeLoad(existing_case)}
          />
        </div>
      )}
    </>
  );
};

export default MedicalArea;
