export const getCookie = name => {
  function escape(s) {
    return s.replace(/\/([.*+?/\\^${}()|/\\[\]/\\/\\])/g, '\\$1');
  }
  var match = document.cookie.match(
    RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)')
  );
  return match ? match[1] : null;
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  let secure = '';
  if (process.env.NODE_ENV !== 'test') {
    secure = '; secure';
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/' + secure;
};

export const parseCookie = name => {
  return JSON.parse(decodeURIComponent(getCookie(name)));
};

export const clearListCookies = () => {
  var cookies = document.cookie.split(';');
  for (var i = 0; i < cookies.length; i++) {
    var spcook = cookies[i].split('=');
    deleteCookie(spcook[0]);
  }
  function deleteCookie(cookiename) {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    var expires = ';expires=' + d;
    var name = cookiename;
    //alert(name);
    var value = '';
    document.cookie = name + '=' + value + expires + '; path=/acc/html';
  }
  window.location = ''; // TO REFRESH THE PAGE
};
