import React, { useContext, useEffect } from 'react';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { IntlContext } from '../../../../../../../../intl';
import sanitize from '../../../../../../../../utils/sanitize';
import * as translations from './intl';

const cardholder = 1;
const coverValuePerPerson = 600;

const ReimbursementArea = props => {
  const { values, handleChange, handleBlur, errors, setFieldValue } = props;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  useEffect(() => {
    const additionalTravellers =
      values.hasAdditional === 'true' ? values.additional_passengers.length : 0;
    const numberOfTravellers = additionalTravellers + cardholder;
    const indemnity =
      values.other_insurance_covering.value &&
      Number(values.other_insurance_covering.value);

    let totalAmount = coverValuePerPerson * numberOfTravellers;
    if (indemnity) {
      totalAmount = totalAmount - indemnity;
    }

    setFieldValue('total_amount_claimed.value', Number(totalAmount).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.additional_passengers,
    values.hasAdditional,
    values.other_insurance_covering.value,
  ]);

  return (
    <div className={`row`}>
      <div className={`col-12 col-sm-12 col-md-6 `}>
        <Input
          label={intl.LABEL_TOTAL_REIMBURSED}
          type="text"
          name="total_amount_claimed.value"
          tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
          disabled
          value={values.total_amount_claimed.value}
          onChange={e => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          touched
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
          dataTestid="inputAmountClaimed"
        />
      </div>

      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          name="total_amount_claimed.currency"
          disabled
          value={values.total_amount_claimed.currency}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid="comboboxTotalAmountCurrency"
        />
      </div>
    </div>
  );
};

export default ReimbursementArea;
