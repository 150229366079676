import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  pt_BR: 'Descreva o incidente',
  es_ES: 'Describa el incidente',
};

export const LABEL_INCIDENT_COUNTRY = {
  en_US: 'Country of incident *',
  pt_BR: 'País do incidente *',
  es_ES: 'País del incidente *',
};

export const LABEL_INCIDENT_STATE = {
  en_US: 'State of incident *',
  pt_BR: 'Estado do incidente *',
  es_ES: 'Estado del incidente *',
};

export const LABEL_INCIDENT_CITY = {
  en_US: 'City of incident *',
  pt_BR: 'Cidade do incidente *',
  es_ES: 'Ciudad incidente *',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Place of incident *',
  pt_BR: 'Local do incidente *',
  es_ES: 'Lugar del incidente *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const ITEM_PURCHASED = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const LABEL_PURCAHSE_DATE = {
  en_US: 'Date of purchase indicated on the Visa invoice *',
  pt_BR: 'Data de compra indicada na fatura Visa *',
  es_ES: 'Fecha de compra indicada en el estado de cuenta *',
};

export const NAME_STORE_WEBSITE = {
  en_US: 'Name of store/website advertising the product *',
  pt_BR: 'Nome da loja/website que anuncia o produto *',
  es_ES: 'Nombre de la tienda que anuncia el producto *',
};

export const ADVERTISEMENT_LOCATION = {
  en_US:
    'Advertisement location (i.e. newspaper, online) and publication name *',
  pt_BR:
    'Localização do anúncio (por exemplo, loja ou website) e nome da publicação *',
  es_ES:
    'Ubicación del anuncio (es decir, periódico, en línea) y nombre de la publicación *',
};

export const ORIGINAL_PURCHASE_PRICE = {
  en_US: 'Original purchase price *',
  pt_BR: 'Preço de compra original *',
  es_ES: 'Precio de la compra original *',
};

export const NEW_ADVERTISE_PRICE = {
  en_US: 'New advertise price *',
  pt_BR: 'Novo preço anunciado *',
  es_ES: 'Nuevo precio anunciado *',
};

export const LABEL_FULL_CARD = {
  en_US:
    'Was the item purchased and paid for in full with the selected credit card?',
  pt_BR:
    'O artigo foi comprado e pago em sua totalidade com o cartão de crédito selecionado?',
  es_ES:
    '¿Se compró y pagó el artículo en su totalidad con la tarjeta de crédito seleccionada?',
};

export const RETAILER_NAME = {
  en_US: 'Purchase value on the Visa invoice',
  pt_BR: 'Valor da compra na fatura Visa',
  es_ES: 'Valor de compra en el estado de cuenta',
};

export const PAYMENT_METHOD = {
  en_US: 'Product payment method *',
  pt_BR: 'Método de pagamento do produto *',
  es_ES: 'Forma de pago del producto *',
};

export const METHOD_FINANCING = {
  en_US: 'Installments',
  pt_BR: 'Parcelado',
  es_ES: 'En cuotas',
};

export const METHOD_CASH = {
  en_US: 'Single payment',
  pt_BR: 'Parcela única',
  es_ES: 'En un pago',
};

export const INSTALLMENTS_NUMBER = {
  en_US: 'Purchase installments number *',
  pt_BR: 'Número de parcelas da compra *',
  es_ES: 'Número de cuotas de compra *',
};

export const SIXTEEN_OR_MORE_INSTALLMENTS = {
  en_US: '16 or more',
  pt_BR: '16 ou mais',
  es_ES: '16 o más',
};

export const INSTALLMENTS_PRICE = {
  en_US: 'Installment/financing amount *',
  pt_BR: 'Valor da prestação/financiamento *',
  es_ES: 'Monto a plazos/financiamiento *',
};
