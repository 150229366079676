import Select from '../Select/Select';
import { listCountriesPhones } from '../../utils/phoneCountries';

const CountrySelect = props => {
  return (
    <Select
      value={props.countrySelected}
      defaultStyle
      onChange={props.onChange}
      hideArrow={true}
    >
      {listCountriesPhones.map(country => {
        return (
          <option value={country.initials} key={country.initials}>
            {country[props.idiom]}
          </option>
        );
      })}
    </Select>
  );
};

export default CountrySelect;
