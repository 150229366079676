export const es_currency = `
<option value="PAB"> Balboa Panameño </option>
<option value="BOB"> Boliviano de Bolivia </option>
<option value="VEF"> Bolívar Venezolano Fuerte </option>
<option value="CRC"> Colón Costarricense </option>
<option value="NIO"> Córdoba Nicaragüense </option>
<option value="BBD"> Dólar de Barbados </option>
<option value="BZD"> Dólar de Belice </option>
<option value="GYD"> Dólar de Guyana </option>
<option value="JMD"> Dólar de Jamaica </option>
<option value="SRD"> Dólar de Surinam </option>
<option value="TTD"> Dólar de Trinidad y Tobago </option>
<option value="BSD"> Dólar de las Bahamas </option>
<option value="XCD"> Dólar del Caribe Oriental </option>
<option value="HTG"> Gourde Haitiano </option>
<option value="PYG"> Guaraní Paraguayo </option>
<option value="HNL"> Lempira Hondureño </option>
<option value="PEN"> Nuevo Sol Peruano </option>
<option value="ARS"> Peso Argentino </option>
<option value="CLP"> Peso Chileno </option>
<option value="COP"> Peso Colombiano </option>
<option value="CUC"> Peso Convertible Cubano </option>
<option value="CUP"> Peso Cubano </option>
<option value="DOP"> Peso Dominicano </option>
<option value="MXN"> Peso Mexicano </option>
<option value="UYU"> Peso Uruguayo </option>
<option value="GTQ"> Quetzal Guatemalteco </option>
<option value="BRL"> Real Brasileño </option>


<option value="AUD"> Dólar Australiano </option>
<option value="CAD"> Dólar Canadiense </option>
<option value="USD"> Dólar de EE.UU. </option>
<option value="EUR"> Euro </option>
<option value="GBP"> Libra Esterlina </option>

<option value="AFN"> Afgani de Afganistán (nuevo) </option>
<option value="MGA"> Ariary malgache </option>
<option value="THB"> Baht tailandés </option>
<option value="ETB"> Birr etíope </option>
<option value="GHS"> Cedi ghanés </option>
<option value="KES"> Chelín keniano </option>
<option value="SOS"> Chelín somalí </option>
<option value="TZS"> Chelín tanzano </option>
<option value="UGX"> Chelín ugandés </option>
<option value="CZK"> Corona checa </option>
<option value="DKK"> Corona danesa </option>
<option value="EEK"> Corona estonia </option>
<option value="ISK"> Corona islandesa </option>
<option value="NOK"> Corona noruega </option>
<option value="SEK"> Corona sueca </option>
<option value="GMD"> Dalasi gambiano </option>
<option value="MKD"> Denar macedonio </option>
<option value="DZD"> Dinar argelino </option>
<option value="BHD"> Dinar bahriní </option>
<option value="IQD"> Dinar iraquí </option>
<option value="JOD"> Dinar jordano </option>
<option value="KWD"> Dinar kuwaití </option>
<option value="LYD"> Dinar libio </option>
<option value="RSD"> Dinar serbio </option>
<option value="TND"> Dinar tunecino </option>
<option value="AED"> Dirham de los Emiratos Árabes Unidos </option>
<option value="MAD"> Dirham marroquí </option>
<option value="STD"> Dobra de Santo Tomé </option>
<option value="VND"> Dong vietnamita </option>
<option value="AMD"> Dram armenio </option>
<option value="BMD"> Dólar de Bermudas </option>
<option value="BND"> Dólar de Brunéi </option>
<option value="FJD"> Dólar de Fiji </option>
<option value="HKD"> Dólar de Hong Kong </option>
<option value="SBD"> Dólar de Islas Salomón </option>
<option value="KYD"> Dólar de las Islas Caimán </option>
<option value="TWD"> Dólar de Taiwán </option>
<option value="ZWL"> Dólar de Zimbabue </option>
<option value="LRD"> Dólar liberiano </option>
<option value="NAD"> Dólar namibio </option>
<option value="NZD"> Dólar neozelandés </option>
<option value="SGD"> Dólar singapurense </option>
<option value="CVE"> Escudo de Cabo Verde </option>
<option value="AWG"> Florín de Aruba </option>
<option value="ANG"> Florín de las Antillas Neerlandesas </option>
<option value="HUF"> Forint húngaro </option>
<option value="XOF"> Franco CFA (BCEAO) </option>
<option value="XAF"> Franco CFA (BEAC) </option>
<option value="XPF"> Franco CFP </option>
<option value="KMF"> Franco comorano </option>
<option value="CDF"> Franco congoleño </option>
<option value="BIF"> Franco de Burundi </option>
<option value="DJF"> Franco de Yibuti </option>
<option value="GNF"> Franco guineo </option>
<option value="RWF"> Franco ruandés </option>
<option value="CHF"> Franco suizo </option>
<option value="HTG"> Gourde haitiano </option>
<option value="UAH"> Jrivnia ucraniano </option>
<option value="PGK"> Kina papú </option>
<option value="LAK"> Kip laosiano </option>
<option value="HRK"> Kuna </option>
<option value="MWK"> Kwacha de Malawi </option>
<option value="ZMW"> Kwacha de Zambia (Nuevo) </option>
<option value="AOA"> Kwanza angoleño </option>
<option value="MMK"> Kyat de Myanmar </option>
<option value="GEL"> Lari georgiano </option>
<option value="LVL"> Lats letón </option>
<option value="ALL"> Lek albanés </option>
<option value="SLL"> Leone sierraleonés </option>
<option value="MDL"> Leu moldavo </option>
<option value="RON"> Leu rumano </option>
<option value="BGN"> Lev búlgaro </option>
<option value="GIP"> Libra de Gibraltar </option>
<option value="FKP"> Libra de las Islas Malvinas </option>
<option value="SHP"> Libra de Santa Helena </option>
<option value="EGP"> Libra egipcia </option>
<option value="LBP"> Libra libanesa </option>
<option value="SDG"> Libra sudanesa </option>
<option value="SZL"> Lilangeni suazilandés </option>
<option value="TRY"> Lira turca (Nueva) </option>
<option value="LTL"> Litas lituano </option>
<option value="LSL"> Loti de Lesoto </option>
<option value="BAM"> Marcos convertibles </option>
<option value="BOV"> Mvdol boliviano </option>
<option value="NGN"> Naira nigeriano </option>
<option value="ERN"> Nakfa eritreo </option>
<option value="BTN"> Ngultrum butanés </option>
<option value="AZN"> Nuevo manat azerbaiyano </option>
<option value="TMT"> Nuevo manat turkmeno </option>
<option value="MZN"> Nuevo metical mozambiqueño </option>
<option value="ILS"> Nuevo shekel israelí </option>
<option value="MRO"> Ouguiya mauritano </option>
<option value="TOP"> Pa'anga tongano </option>
<option value="MOP"> Pataca de Macao </option>
<option value="PHP"> Peso filipino </option>
<option value="BWP"> Pula botsuanés </option>
<option value="ZAR"> Rand sudafricano </option>
<option value="QAR"> Rial de Qatar </option>
<option value="OMR"> Rial omaní </option>
<option value="SAR"> Rial saudí </option>
<option value="YER"> Rial yemení </option>
<option value="KHR"> Riel camboyano </option>
<option value="MYR"> Ringgit malasio </option>
<option value="BYR"> Rublo bielorruso </option>
<option value="RUB"> Rublo ruso </option>
<option value="SCR"> Rupia de las Seyshelles </option>
<option value="MVR"> Rupia de Maldivas </option>
<option value="LKR"> Rupia de Sri Lanka </option>
<option value="INR"> Rupia india </option>
<option value="IDR"> Rupia indonesia </option>
<option value="MUR"> Rupia mauriciana </option>
<option value="NPR"> Rupia nepalesa </option>
<option value="PKR"> Rupia paquistaní </option>
<option value="KGS"> Som kirguizo </option>
<option value="TJS"> Somoni tayiko </option>
<option value="UZS"> Sum uzbeko </option>
<option value="BDT"> Taka de Bangladesh </option>
<option value="WST"> Tala de Samoa </option>
<option value="KZT"> Tenge kazajo </option>
<option value="MNT"> Tugrik mongol </option>
<option value="MXV"> Unidad de Inversión (UDI) mexicana </option>
<option value="VUV"> Vatu de Vanuatu </option>
<option value="KRW"> Won coreano </option>
<option value="JPY"> Yen japonés </option>
<option value="CNY"> Yuan renminbi chino </option>
<option value="PLN"> Zloty polaco </option>`;
