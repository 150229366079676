/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './QuoteDetail.module.scss';
import { IntlContext } from '../../../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { withRouter, useHistory } from 'react-router-dom';
import { productsAndBenefitsServices } from '../../../services/index';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import Loading from '../../../components/Loading/Loading';
import {
  ENABLE_CROSS_SELLING,
  ENABLE_UPSELLING,
  ENABLE_SPECIAL_BENEFITS,
} from '../../../utils/environments_variables';
import RelatedSpecialBenefits from '../../../components/RelatedSpecialBenefits/RelatedSpecialBenefits';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import BenefitsExtensions from '../Quote/BenefitsExtensions/BenefitsExtensions';
import BannerAXA from '../../../components/BannerAXA/BannerAXA';
import {
  CREATE_CONFIG_B2B2C,
  getType,
  UPSELL,
  CROSSELL,
} from '../CONFIG_B2B2C';
import imageAXA from '../../../assets/images/B2B2C/familyAXA.jpg';
import FakeQuote from '../Quote/FakeQuote/FakeQuote';
import QuoteBanner from '../Quote/QuoteBanner/QuoteBanner';
import BenefitsList from '../Quote/BenefitsList/BenefitsList';
import { checkAccess } from '../utils';
import { PublicContext } from '../../../components/PublicContextParent/PublicContextParent';
import ListCards from '../../../components/PublicListCards/PublicListCards';
import {
  ELIGIBLE_CARDS_UPSELLING,
  enableUpsellingByCountry,
} from '../../../utils/verifyAccess';
import {
  ELIGIBLE_CARDS_CROSSELLING,
  enableCrossellingByCountry,
} from '../../../utils/verifyAccessCrosselling';

const QuoteDetail = props => {
  const { match } = props;
  const { benefit_id } = match.params;
  const GET_TYPE = getType(match.path);
  const CONFIG_B2B2C = CREATE_CONFIG_B2B2C(match);
  const { productActive, setProductActive } = useContext(PublicContext);
  const [benefitDetail, setBenefitDetail] = useState(null);
  const { translate, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);
  const history = useHistory();
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const isLogged = utils.isUserLogged();

  const configExplanationImage = {
    crosselImage: imageAXA,
    upsellImage: imageAXA,
  };
  const contentConfig = CONFIG_B2B2C.quoteDetail.getContent(
    configExplanationImage,
    intl
  );

  const loadData = async (product_id, benefit_id, idiom, setBenefitDetail) => {
    try {
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          product_id,
          benefit_id,
          idiom,
          false
        );
      setBenefitDetail(response.data);
    } catch (error) {
      setBenefitDetail({});
    }
  };

  useEffect(() => {
    if (isLogged) {
      loadData(utils.getBin(true), benefit_id, idiomForApi(), setBenefitDetail);
    } else {
      setBenefitDetail({ external_reference: benefit_id });
    }
  }, [benefit_id]);

  useEffect(() => {
    if (isLogged) {
      checkAccess(
        utils.getSelectedCard(),
        CONFIG_B2B2C,
        benefit_id,
        history,
        utils.getEmail()
      );
    }
  }, []);

  useEffect(() => {
    if (!isLogged) {
      if (
        (productActive.country &&
          !enableUpsellingByCountry(productActive.country) &&
          window.location.pathname.includes('upsell') &&
          !ELIGIBLE_CARDS_UPSELLING.includes(
            productActive.name && productActive.name.toUpperCase()
          )) ||
        (!ENABLE_UPSELLING && window.location.pathname.includes('upsell'))
      ) {
        history.push('/');
      }
      if (
        (productActive.country &&
          !enableCrossellingByCountry(productActive.country) &&
          window.location.pathname.includes('cross-sell') &&
          !ELIGIBLE_CARDS_CROSSELLING.includes(
            productActive.name && productActive.name.toUpperCase()
          )) ||
        (!ENABLE_CROSS_SELLING &&
          window.location.pathname.includes('cross-sell'))
      ) {
        history.push('/');
      }

      if (
        GET_TYPE === UPSELL &&
        productActive.name &&
        !ELIGIBLE_CARDS_UPSELLING.includes(productActive.name.toUpperCase())
      ) {
        history.push('/cross-sell-preview/EMC_Emergency_Medical_Assistance');
      } else if (
        GET_TYPE === CROSSELL &&
        productActive.name &&
        !ELIGIBLE_CARDS_CROSSELLING.includes(productActive.name.toUpperCase())
      ) {
        history.push('/upsell-preview/EMC_Emergency_Medical_Assistance');
      }
    }
  }, [productActive]);

  return (
    <>
      {benefitDetail ? (
        <BannerAXA CONFIG_B2B2C={CONFIG_B2B2C} benefitDetail={benefitDetail} />
      ) : (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}

      <div id="mainContent" className={`${styles.container} ${styles[theme]}`}>
        <div className={styles.content}>
          {!isLogged && (
            <div className={styles.wrapListCards}>
              <ListCards
                cardSelected={productActive}
                setCardSelected={setProductActive}
              />
            </div>
          )}

          <div className={styles.axaExplanation}>
            <img className={styles.image} src={contentConfig.image} alt="AXA" />
            <div className={styles.textArea}>
              <h2 className={styles.subtitle}>{contentConfig.subtitle}</h2>
              <p className={styles.description}>{contentConfig.description}</p>
            </div>
          </div>

          {(!isLogged || CONFIG_B2B2C.quoteDetail.fakeQuoteShow) && (
            <FakeQuote benefitDetail={benefitDetail} productType={GET_TYPE} />
          )}

          {CONFIG_B2B2C.quoteDetail.benefitsShow && <BenefitsList />}

          {CONFIG_B2B2C.quoteDetail.myExtensionPlansShow && (
            <BenefitsExtensions productType={GET_TYPE} />
          )}

          {CONFIG_B2B2C.quoteDetail.quoteBannerShow && (
            <QuoteBanner benefitDetail={benefitDetail} />
          )}
        </div>
        <SessionContainer>
          {ENABLE_SPECIAL_BENEFITS &&
            CONFIG_B2B2C.quoteDetail.subscriptionBenefitsShow && (
              <RelatedSpecialBenefits />
            )}
        </SessionContainer>
      </div>
    </>
  );
};

export default withRouter(QuoteDetail);
