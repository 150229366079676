import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const HomeIcon = props => {
  const { enabled = true, bottombar } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <mask id="path-1-inside-1_166_87" fill="white">
        <path d="M11.9999 3C14.3722 3 16.4695 4.18921 17.7362 6H20.0503C18.5742 3.04169 15.5248 1 11.9999 1C8.4754 1 5.4259 3.04169 3.94971 6H6.26355C7.53009 4.18921 9.62732 3 11.9999 3Z" />
      </mask>
      <path
        d="M17.7362 6L16.0974 7.14639L16.6945 8H17.7362V6ZM20.0503 6V8H23.2834L21.8399 5.10703L20.0503 6ZM3.94971 6L2.16014 5.10701L0.716539 8H3.94971V6ZM6.26355 6V8H7.30534L7.90244 7.14631L6.26355 6ZM11.9999 5C13.687 5 15.186 5.84347 16.0974 7.14639L19.375 4.85361C17.7531 2.53495 15.0575 1 11.9999 1V5ZM17.7362 8H20.0503V4H17.7362V8ZM21.8399 5.10703C20.0413 1.50249 16.3182 -1 11.9999 -1V3C14.7314 3 17.107 4.58089 18.2607 6.89297L21.8399 5.10703ZM11.9999 -1C7.68199 -1 3.95878 1.5025 2.16014 5.10701L5.73928 6.89299C6.89301 4.58088 9.2688 3 11.9999 3V-1ZM3.94971 8H6.26355V4H3.94971V8ZM7.90244 7.14631C8.81367 5.84351 10.3125 5 11.9999 5V1C8.94213 1 6.2465 2.53491 4.62465 4.85369L7.90244 7.14631Z"
        fill={
          enabled
            ? bottombar
              ? config_icon.secondary
              : config_icon.primary
            : config_icon.inactive
        }
        mask="url(#path-1-inside-1_166_87)"
      />
      <mask id="path-3-inside-2_166_87" fill="white">
        <path d="M23 8H1V10H23V8Z" />
      </mask>
      <path
        d="M1 8V6H-1V8H1ZM23 8H25V6H23V8ZM23 10V12H25V10H23ZM1 10H-1V12H1V10ZM1 10H23V6H1V10ZM21 8V10H25V8H21ZM23 8H1V12H23V8ZM3 10V8H-1V10H3Z"
        fill={enabled ? config_icon.secondary : config_icon.inactive}
        mask="url(#path-3-inside-2_166_87)"
      />
      <mask id="path-5-inside-3_166_87" fill="white">
        <path d="M21 12H19V21H17V12H15V21H13V12H11V21H9V12H7V21H5V12H3V21H1V23H23V21H21V12Z" />
      </mask>
      <path
        d="M21 12H23V10H21V12ZM19 12V10H17V12H19ZM19 21V23H21V21H19ZM17 21H15V23H17V21ZM17 12H19V10H17V12ZM15 12V10H13V12H15ZM15 21V23H17V21H15ZM13 21H11V23H13V21ZM13 12H15V10H13V12ZM11 12V10H9V12H11ZM11 21V23H13V21H11ZM9 21H7V23H9V21ZM9 12H11V10H9V12ZM7 12V10H5V12H7ZM7 21V23H9V21H7ZM5 21H3V23H5V21ZM5 12H7V10H5V12ZM3 12V10H1V12H3ZM3 21V23H5V21H3ZM1 21V19H-1V21H1ZM1 23H-1V25H1V23ZM23 23V25H25V23H23ZM23 21H25V19H23V21ZM21 21H19V23H21V21ZM21 10H19V14H21V10ZM17 12V21H21V12H17ZM19 19H17V23H19V19ZM19 21V12H15V21H19ZM17 10H15V14H17V10ZM13 12V21H17V12H13ZM15 19H13V23H15V19ZM15 21V12H11V21H15ZM13 10H11V14H13V10ZM9 12V21H13V12H9ZM11 19H9V23H11V19ZM11 21V12H7V21H11ZM9 10H7V14H9V10ZM5 12V21H9V12H5ZM7 19H5V23H7V19ZM7 21V12H3V21H7ZM5 10H3V14H5V10ZM1 12V21H5V12H1ZM3 19H1V23H3V19ZM-1 21V23H3V21H-1ZM1 25H23V21H1V25ZM25 23V21H21V23H25ZM23 19H21V23H23V19ZM23 21V12H19V21H23Z"
        fill={enabled ? config_icon.secondary : config_icon.inactive}
        mask="url(#path-5-inside-3_166_87)"
      />
    </svg>
  );
};

export default HomeIcon;
