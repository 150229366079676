import React, { useContext } from 'react';

import styles from './ReservationQuestionArea.module.scss';
import * as translations from './intl';
import { IntlContext } from '../../../../../../../../../intl';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import RadioButton from '../../../../../../../../../components/RadioButton/RadioButton';
import ReservationArea from './ReservationArea/ReservationArea';

const ReservationQuestionArea = props => {
  const { values, handleChange } = props;
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const hasPreviousReservation =
    values.car_rental &&
    values.car_rental.payment &&
    values.car_rental.payment.reservation_information &&
    values.car_rental.payment.reservation_information.hasPreviousReservation &&
    values.car_rental.payment.reservation_information.hasPreviousReservation ===
      'yes';

  const hasPreviousReservationPayment =
    values.car_rental &&
    values.car_rental.payment &&
    values.car_rental.payment.reservation_information &&
    values.car_rental.payment.reservation_information
      .hasPreviousReservationPayment &&
    values.car_rental.payment.reservation_information
      .hasPreviousReservationPayment === 'yes';

  return (
    <>
      <div className="row col-12">
        <div
          className={`${styles.labelOwnerCardHolder} ${styles[theme]} ${styles.titleRadio} col-lg-12 col-sm-12`}
        >
          {intl.TXT_QUESTION_PREVIOUS_RESERVATION}
        </div>

        <div className={`${styles.optionsArea} col-12`}>
          <div className={`${styles.fontGray12} row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                label={intl.TXT_QUESTION_PREVIOUS_RESERVATION}
                describe={intl.DESCRIBE_PREVIOUS_RESERVATION_YES}
                text={intl.TXT_YES}
                name="car_rental.payment.reservation_information.hasPreviousReservation"
                value="yes"
                checked={hasPreviousReservation}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={`${styles.fontGray12} row`}>
            <div
              className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
            >
              <RadioButton
                label={intl.TXT_QUESTION_PREVIOUS_RESERVATION}
                describe={intl.DESCRIBE_PREVIOUS_RESERVATION_NO}
                text={intl.TXT_NO}
                name="car_rental.payment.reservation_information.hasPreviousReservation"
                value="no"
                checked={!hasPreviousReservation}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      {hasPreviousReservation && (
        <div className="row col-12">
          <div
            className={`${styles.labelOwnerCardHolder} ${styles[theme]} ${styles.titleRadio} col-lg-12 col-sm-12`}
          >
            {intl.TXT_QUESTION_PREVIOUS_RESERVATION_PAYMENTEN}
          </div>

          <div className={`${styles.optionsArea} col-12`}>
            <div className={`${styles.fontGray12} row`}>
              <div
                className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
              >
                <RadioButton
                  label={intl.TXT_QUESTION_PREVIOUS_RESERVATION_PAYMENTEN}
                  describe={intl.DESCRIBE_PREVIOUS_PAYMENT_YES}
                  text={intl.TXT_YES}
                  name="car_rental.payment.reservation_information.hasPreviousReservationPayment"
                  value="yes"
                  checked={hasPreviousReservationPayment}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={`${styles.fontGray12} row`}>
              <div
                className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
              >
                <RadioButton
                  label={intl.TXT_QUESTION_PREVIOUS_RESERVATION_PAYMENTEN}
                  describe={intl.DESCRIBE_PREVIOUS_PAYMENT_NO}
                  text={intl.TXT_NO}
                  name="car_rental.payment.reservation_information.hasPreviousReservationPayment"
                  value="no"
                  checked={!hasPreviousReservationPayment}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {hasPreviousReservationPayment && <ReservationArea {...props} />}
        </div>
      )}
    </>
  );
};

export default ReservationQuestionArea;
