// @ts-nocheck
import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import Form from './Form';
import BenefitsFormFlow from '../BenefitsFormFlow';

const executiveTransportationBenefitId = 'Executive_Transportation_70';

const ExecutiveTransportationForm = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <BenefitsFormFlow
      benefitId={executiveTransportationBenefitId}
      title={intl.EXECUTIVE_TRANSPORTATION}
      subtitle={intl.SUBTITLE_BACK}
      steps={[intl.TEXT_STEP_1, intl.TEXT_STEP_2]}
      Form={Form}
      TermsAndConditions={TermsAndConditions}
    />
  );
};

export default ExecutiveTransportationForm;
