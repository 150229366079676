import React, { useContext, useState } from 'react';

import Input from '../../../../../../../../../components/Input/Input';
import styles from '../../BankArea.module.scss';
import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../../../intl';
import { safeGet } from '../../../../../../../../../utils/object';
import { inArray } from '../../../../../../../../../utils/array';
import Select from '../../../../../../../../../components/Select/Select';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import RadioButton from '../../../../../../../../../components/RadioButton/RadioButton';
import {
  CHEKING,
  SAVING,
  VALUE_AR,
  VALUE_CO,
  VALUE_GT,
  VALUE_MX,
} from '../../../constants';

const SWIFT = 'SWIFT';
const ABA = 'ABA';

const IntermediaryFields = props => {
  const { values, handleChange, touched, handleBlur, errors } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [otherCountryPreference, setOtherCountryPreference] = useState(SWIFT);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const countryOptions = [VALUE_AR, VALUE_CO, VALUE_GT, VALUE_MX];

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-8">
          <Input
            value={values.IntermediaryBankAccount.bank.name}
            label={intl.FORM_LABEL_BANK_NAME}
            name="IntermediaryBankAccount.bank.name"
            onChange={handleChange}
            type="text"
            touched={safeGet(touched, 'IntermediaryBankAccount.bank.name')}
            onBlur={handleBlur}
            error={safeGet(errors, 'IntermediaryBankAccount.bank.name')}
            maxLength="80"
          />
        </div>
        <div className="col-12 col-md-4">
          <Select
            label={intl.LABEL_ACCOUNT_TYPE}
            value={safeGet(values, 'IntermediaryBankAccount.account_type')}
            onChange={handleChange}
            touched={safeGet(touched, 'IntermediaryBankAccount.account_type')}
            onBlur={handleBlur}
            error={safeGet(errors, 'IntermediaryBankAccount.account_type')}
            name="IntermediaryBankAccount.account_type"
          >
            <option className={styles[theme]} value="" key={0}>
              {intl.LABEL_ACCOUNT_TYPE}
            </option>
            <option className={styles[theme]} value={CHEKING} key={1}>
              {intl.LABEL_ACCOUNT_TYPE_CHECKING}
            </option>
            <option className={styles[theme]} value={SAVING} key={2}>
              {intl.LABEL_ACCOUNT_TYPE_SAVING}
            </option>
          </Select>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4">
          <Input
            value={
              safeGet(
                values,
                'IntermediaryBankAccount.bank.bank_account_number'
              ) || ''
            }
            label={intl.FORM_LABEL_ACCOUNT_NUMBER}
            name="IntermediaryBankAccount.bank.bank_account_number"
            onChange={handleChange}
            type="text"
            maxLength="34"
            mask="internationalBankAccountNumber"
            touched={safeGet(
              touched,
              'IntermediaryBankAccount.bank.bank_account_number'
            )}
            onBlur={handleBlur}
            error={safeGet(
              errors,
              'IntermediaryBankAccount.bank.bank_account_number'
            )}
            tooltipText={intl.TOOLTIP_FORM_LABEL_ACCOUNT_NUMBER}
          />
        </div>

        {values.BankAccount.bankCountryView === VALUE_AR && (
          <div className="col-12 col-md-4">
            <Input
              value={
                safeGet(values, 'IntermediaryBankAccount.CUIT.value') || ''
              }
              label={intl.FORM_LABEL_CUIT}
              name="IntermediaryBankAccount.CUIT.value"
              onChange={handleChange}
              type="text"
              touched={safeGet(touched, 'IntermediaryBankAccount.CUIT.value')}
              onBlur={handleBlur}
              error={safeGet(errors, 'IntermediaryBankAccount.CUIT.value')}
              mask={idiom === 'es_ES' ? 'maskedCuit_es_AR' : 'maskedCuit'}
            />
          </div>
        )}

        {values.BankAccount.bankCountryView === VALUE_AR && (
          <div className="col-12 col-md-4">
            <Input
              value={
                safeGet(values, 'IntermediaryBankAccount.FULL_KEY.value') || ''
              }
              label={intl.FORM_LABEL_CLAVE_BANCARIA}
              name="IntermediaryBankAccount.FULL_KEY.value"
              onChange={handleChange}
              mask={idiom === 'es_ES' ? 'maskedCBU_es_AR' : 'maskedFullKey'}
              tooltipText={intl.TOOLTIP_FULL_KEY_INFO}
              type="text"
              touched={safeGet(
                touched,
                'IntermediaryBankAccount.FULL_KEY.value'
              )}
              onBlur={handleBlur}
              error={safeGet(errors, 'IntermediaryBankAccount.FULL_KEY.value')}
            />
          </div>
        )}

        {values.BankAccount.bankCountryView === VALUE_MX && (
          <>
            <div className="col-12 col-md-4">
              <Input
                value={
                  safeGet(values, 'IntermediaryBankAccount.FULL_KEY.value') ||
                  ''
                }
                label={intl.FORM_LABEL_CLABE}
                name="IntermediaryBankAccount.FULL_KEY.value"
                onChange={handleChange}
                mask="maskedFullKey"
                tooltipText={intl.TOOLTIP_FULL_KEY_INFO}
                type="text"
                touched={safeGet(
                  touched,
                  'IntermediaryBankAccount.FULL_KEY.value'
                )}
                onBlur={handleBlur}
                error={safeGet(
                  errors,
                  'IntermediaryBankAccount.FULL_KEY.value'
                )}
              />
            </div>
            <div className="col-12 col-md-4">
              <Input
                value={
                  safeGet(
                    values,
                    'IntermediaryBankAccount.bank_identification_number'
                  ) || ''
                }
                label={intl.FORM_LABEL_SWIFT_CODE}
                name="IntermediaryBankAccount.bank_identification_number"
                onChange={handleChange}
                type="text"
                mask="maskedSwift"
                tooltipText={intl.TOOLTIP_SWIFT_INFO}
                touched={safeGet(
                  touched,
                  'IntermediaryBankAccount.bank_identification_number'
                )}
                onBlur={handleBlur}
                error={safeGet(
                  errors,
                  'IntermediaryBankAccount.bank_identification_number'
                )}
              />
            </div>
          </>
        )}

        {values.BankAccount.bankCountryView === VALUE_GT && (
          <div className="col-12 col-md-4">
            <Input
              value={
                safeGet(values, 'IntermediaryBankAccount.IBAN.value') || ''
              }
              label={intl.FORM_LABEL_IBAN_CODE}
              name="IntermediaryBankAccount.IBAN.value"
              onChange={handleChange}
              type="text"
              mask="maskedIban"
              touched={safeGet(touched, 'IntermediaryBankAccount.IBAN.value')}
              onBlur={handleBlur}
              error={safeGet(errors, 'IntermediaryBankAccount.IBAN.value')}
            />
          </div>
        )}

        {!inArray(countryOptions, values.BankAccount.bankCountryView) && (
          <div className={`col-12 col-md-4 ${styles.options}`}>
            <div className={`row`}>
              <div
                className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
              >
                <RadioButton
                  describe={intl.TEXT_DESCRIBE_PREFERENCE}
                  text={intl.LABEL_SWIFT_CODE}
                  value={SWIFT}
                  checked={otherCountryPreference === SWIFT}
                  onChange={() => setOtherCountryPreference(SWIFT)}
                />
              </div>
            </div>
            <div className={`row`}>
              <div
                className={`${styles.option} ${styles[theme]} col-lg-4 col-sm-12`}
              >
                <RadioButton
                  describe={intl.TEXT_DESCRIBE_PREFERENCE}
                  text={intl.LABEL_ROUTING_NUMBER}
                  value={ABA}
                  checked={otherCountryPreference === ABA}
                  onChange={() => setOtherCountryPreference(ABA)}
                />
              </div>
            </div>
          </div>
        )}

        {!inArray(countryOptions, values.BankAccount.bankCountryView) &&
          otherCountryPreference === SWIFT && (
            <div className="col-12 col-md-4">
              <Input
                value={
                  safeGet(values, 'IntermediaryBankAccount.SWIFT.value') || ''
                }
                label={intl.FORM_LABEL_SWIFT_CODE}
                name="IntermediaryBankAccount.SWIFT.value"
                onChange={handleChange}
                type="text"
                tooltipText={intl.TOOLTIP_SWIFT_INFO}
                mask="maskedSwift"
                touched={safeGet(
                  touched,
                  'IntermediaryBankAccount.SWIFT.value'
                )}
                onBlur={handleBlur}
                error={safeGet(errors, 'IntermediaryBankAccount.SWIFT.value')}
              />
            </div>
          )}

        {!inArray(countryOptions, values.BankAccount.bankCountryView) &&
          otherCountryPreference === ABA && (
            <div className="col-12 col-md-4">
              <Input
                value={
                  safeGet(values, 'IntermediaryBankAccount.ABA.value') || ''
                }
                label={intl.FORM_LABEL_ROUTING_NUMBER}
                name="IntermediaryBankAccount.ABA.value"
                onChange={handleChange}
                type="text"
                tooltipText={intl.TOOLTIP_ABA_INFO}
                mask="maskedAba"
                touched={safeGet(touched, 'IntermediaryBankAccount.ABA.value')}
                onBlur={handleBlur}
                error={safeGet(errors, 'IntermediaryBankAccount.ABA.value')}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default IntermediaryFields;
