export const TEXT_DESCRIPTION_BENEFITS = {
  en_US:
    'As a valuable Visa cardholder, we are here to assist during this difficult time.',
  pt_BR: '',
  es_ES:
    'Como un valioso titular de la tarjeta Visa, estamos aquí para ayudarlo en este momento difícil.',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const TEXT_SUBTITLE = {
  en_US: 'Create an appointment',
  pt_BR: 'Criar um agendamento',
  es_ES: 'Crear una cita',
};
