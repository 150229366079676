import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './IncidentArea.module.scss';
import { safeGet } from '../../../../../../../../utils/object';
import Textarea from '../../../../../../../../components/Textarea/Textarea';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-12">
        <Textarea
          dataTestid="descriptionIncidentTripDelay"
          value={values.trip_information.reason}
          label={intl.INCIDENT_DESCRIPTION}
          name="trip_information.reason"
          onChange={handleChange}
          touched={safeGet(touched, 'trip_information.reason')}
          onBlur={handleBlur}
          error={safeGet(errors, 'trip_information.reason')}
          maxLength="255"
        />
      </div>
    </div>
  );
};

export default IncidentArea;
