import { useContext } from 'react';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './InfoReimburstmentByReference.module.scss';
import Tooltip from '../../Tooltip/Tooltip';
import { IntlContext } from '../../../intl';
import * as translations from './intl';

const InfoReimburstmentByReference = ({
  reference,
  totalSum,
  currencySymbol,
  currencyInitialsCode,
  type,
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const configTooltipMessage = {
    Billete_Auto_Rental_Insurance: intl.AUTO_RENTAL_TOOLTIP,
    Certificate_Extended_Warranty: intl.EXTENDED_WARRANTY_TOOLTIP,
    Certificate_Seguro_Compra: intl.PURCHASE_TOOLTIP,
    Travel_Insurance: intl.TRAVEL_INSURANCE_TOOLTIP,
  };

  const getTooltipMessageByType = type => configTooltipMessage[type] || '';

  return (
    <>
      <div className="row">
        <div className={styles.containerInfo}>
          <div className={`${styles[theme]} ${styles.ContainerTooltip}`}>
            <p className={`${styles.referenceText} ${styles[theme]}`}>
              {reference}
            </p>
            {type && (
              <div className={`${styles[theme]} ${styles.tooltip}`}>
                <Tooltip text={getTooltipMessageByType(type)} />
              </div>
            )}
          </div>
          <div className={styles.container}>
            <span className={`${styles.valueText} ${styles[theme]}`}>
              {currencySymbol}
              {totalSum.toFixed(2)}
              {currencyInitialsCode}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoReimburstmentByReference;
