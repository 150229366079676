export const COVERAGE_SUBTITLE_CROSSELL = {
  en_US: '',
  es_ES:
    'Viaje más seguro que nunca con un certificado de asistencia en viaje para ti y tus seres queridos.',
  pt_BR: '',
};

export const COVERAGE_SUBTITLE_UPSELL = {
  en_US: '',
  es_ES:
    'Extiende tu cobertura hasta 1 millón USD para ti y tus seres queridos.',
  pt_BR: '',
};

export const DESCRIPTION_COVERAGE_CROSSELL = {
  en_US: '',
  es_ES:
    'Al ser miembro de Visa puede adquirir los Servicios de Emergencia Médica Internacional, por medio de la compañía AXA Assistance, te brindará la tranquilidad que merece antes, durante y después de tu viaje.',
  pt_BR: '',
};

export const DESCRIPTION_COVERAGE_UPSELL = {
  en_US: '',
  es_ES:
    'Al ser un valioso miembro para Visa, tú tienes los Servicios de Emergencia Médica Internacional incluidos en tus beneficios, pero en caso de que lo desee, ahora tienes la posibilidad de aumentar tu cobertura médica internacional, por medio de compañía AXA Assistance.',
  pt_BR: '',
};
