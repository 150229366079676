export const TEXT_THANKS = {
  en_US: 'THANK YOU!',
  pt_BR: 'OBRIGADO!',
  es_ES: 'GRACIAS!',
};

export const SESSION_BEEN_SET = {
  en_US: 'Please remember that the session will last 30 minutes.',
  pt_BR: '',
  es_ES: 'Por favor recuerde que la sesion durara 30 minutos.',
};

export const STAY_HOME = {
  en_US: 'Stay Home. Save Lives',
  pt_BR: '',
  es_ES: '#quedatencasa',
};

export const AT = {
  en_US: 'at',
  pt_BR: '',
  es_ES: 'a las',
};

export const TEXT_YES = {
  en_US: 'Ok',
  pt_BR: 'Ok',
  es_ES: 'Ok',
};

export const BTN_HOME = {
  en_US: 'Go to Homepage',
  pt_BR: '',
  es_ES: 'Ir a la Homepage',
};

export const BTN_APPOINTMENTS = {
  en_US: 'Your appointments',
  pt_BR: '',
  es_ES: 'Tus citas',
};

export const TEXT_CREATED_NAL = {
  en_US:
    'Your appointment with Nurse Advice Line has been successfully created.',
  pt_BR: 'A sua consulta com a Nurse Advice Line foi criada com sucesso.',
  es_ES: 'Tu cita con Nurse Advice Line se ha creado correctamente.',
};

export const FORMAT_TEXT_DATE = {
  en_US: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${month} ${day}th ${year} at ${time}.`,
  pt_BR: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${month} ${day}, às ${time}`,
  es_ES: (month, day, dayOfWeek, time, year) =>
    `${dayOfWeek}, ${day} de ${month} de ${year} a las ${time}.`,
};

export const TEXT_REMEMBER_NAL = {
  en_US: 'Your appointment will last 30 minutes.',
  pt_BR: 'Lembre-se que a sessão dura 30 minutos.',
  es_ES: 'La duración de tu sesión es de 30 minutos.',
};
