import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './MedicalArea.module.scss';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import moment from 'moment';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const MedicalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    setFieldValue,
    certificate,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyCaseNumber = () => {
    setFieldValue('existing_case.number', '');
  };

  return (
    <div className={`row ${styles.medicalArea}`}>
      <div className="col-12">
        <Textarea
          dataTestid="descriptionMedicalEmergency"
          value={values.medical_service.medical_service_description}
          label={intl.LABEL_DESCRIPTION_EMERGENCY}
          name="medical_service.medical_service_description"
          type="text"
          onChange={handleChange}
          touched={
            touched.medical_service &&
            touched.medical_service.medical_service_description
          }
          onBlur={handleBlur}
          error={
            errors.medical_service &&
            errors.medical_service.medical_service_description
          }
          maxLength="32768"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          dataTestid="dateStartedService"
          value={values.medical_service.assistance_started_at}
          label={intl.LABEL_ASSISTANCE_START}
          name="medical_service.assistance_started_at"
          onChange={handleChange}
          minDate={
            certificate &&
            certificate.travel_details &&
            certificate.travel_details.start_date
              ? new Date(
                  moment(certificate.travel_details.start_date).add(1, 'hour')
                )
              : null
          }
          touched={
            touched.medical_service &&
            touched.medical_service.assistance_started_at
          }
          error={
            errors.medical_service &&
            errors.medical_service.assistance_started_at
          }
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          dataTestid="dateFinishedService"
          value={values.medical_service.assistance_finished_at}
          label={intl.LABEL_ASSISTANCE_END}
          name="medical_service.assistance_finished_at"
          onChange={handleChange}
          minDate={values.medical_service.assistance_started_at}
          touched={
            touched.medical_service &&
            touched.medical_service.assistance_finished_at
          }
          error={
            errors.medical_service &&
            errors.medical_service.assistance_finished_at
          }
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12">
        <Textarea
          dataTestid="medicalDiagnostic"
          value={values.medical_service.diagnosis}
          label={intl.LABEL_DIAGNOSIS_DOCTOR}
          name="medical_service.diagnosis"
          type="text"
          onChange={handleChange}
          touched={touched.medical_service && touched.medical_service.diagnosis}
          onBlur={handleBlur}
          error={errors.medical_service && errors.medical_service.diagnosis}
          maxLength="100"
        />
      </div>
      <div className="col-12">
        <Textarea
          dataTestid="treatmentDoctor"
          value={values.medical_service.medical_treatment_received}
          label={intl.LABEL_TREATMENT_DOCTOR}
          name="medical_service.medical_treatment_received"
          type="text"
          onChange={handleChange}
          touched={
            touched.medical_service &&
            touched.medical_service.medical_treatment_received
          }
          onBlur={handleBlur}
          error={
            errors.medical_service &&
            errors.medical_service.medical_treatment_received
          }
          maxLength="200"
        />
      </div>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_REPORTET_BY_PHONE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_REPORTET_BY_PHONE}
              describe={intl.DESCRIBE_REPORTED_BY_PHONE}
              defaultChecked={false}
              text={intl.TEXT_YES}
              name="existing_case.reported_agent_phone"
              dataTestid="InputExistingCaseAgentPhoneYes"
              value={true}
              onChange={e => {
                setEmptyCaseNumber();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_REPORTET_BY_PHONE}
              defaultChecked={true}
              text={intl.TEXT_NO}
              name="existing_case.reported_agent_phone"
              dataTestid="InputExistingCaseAgentPhoneNo"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.existing_case.reported_agent_phone !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivExistingCaseAgentPhone"
      >
        <Input
          value={values.existing_case.number}
          label={intl.LABEL_CASE_NUMBER}
          name="existing_case.number"
          dataTestid="InputExistingCaseAgentNumber"
          type="text"
          onChange={handleChange}
          touched={touched.existing_case && touched.existing_case.number}
          onBlur={handleBlur}
          error={errors.existing_case && errors.existing_case.number}
          maxLength="100"
        />
      </div>
    </div>
  );
};

export default MedicalArea;
