import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { pt_BR } from '../../../../../../intl/idioms';
import { formatCustomDate } from '../../../../../../utils/date';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const carRental = claimDetails && claimDetails.car_rental;

  const fromCarRental = carRental.from
    ? formatCustomDate(carRental.from, 'YYYY-MM-DD', idiom, country)
    : '-';
  const toCarRental = carRental.to
    ? formatCustomDate(carRental.to, 'YYYY-MM-DD', idiom, country)
    : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_RENTAL_START} value={fromCarRental} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_RENTAL_END} value={toCarRental} />
      </div>
    </>
  );
};

export default PersonalArea;
