import { PhoneNumberUtil } from 'google-libphonenumber';

const PhoneNumberUtilInstance = PhoneNumberUtil.getInstance();

export const formatPhoneNumberByCountry = (
  phoneNumber: string,
  country: string
) => {
  try {
    const parsedNumber = PhoneNumberUtilInstance.parseAndKeepRawInput(
      phoneNumber,
      country
    );
    const formatOriginal = PhoneNumberUtilInstance.formatInOriginalFormat(
      parsedNumber,
      country
    );
    return formatOriginal;
  } catch (error) {
    return phoneNumber;
  }
};
