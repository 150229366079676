export const TITLE_YOUR_BANK = {
  en_US: 'My bank information',
  es_ES: 'Mi información bancaria',
  pt_BR: 'Minhas informações bancárias',
};

export const LOADING_ALL_BANK = {
  en_US: 'Loading all your banks...',
  es_ES: 'Cargando todos tus bancos...',
  pt_BR: 'Carregando todos os seus bancos...',
};

export const EDIT_BANK = {
  en_US: 'Edit bank information',
  es_ES: 'Editar información bancaria',
  pt_BR: 'Editar informações bancárias',
};

export const ADD_BANK = {
  en_US: 'Add a new bank',
  es_ES: 'Agregar un banco nuevo',
  pt_BR: 'Adicionar um novo banco',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  es_ES: 'Editar',
  pt_BR: 'Editar',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const BTN_SET_MAIN = {
  en_US: 'Set as main account',
  pt_BR: 'Definir como conta principal',
  es_ES: 'Establecer como cuenta principal',
};

export const MAIN_ACCOUNT = {
  en_US: 'Main account',
  pt_BR: 'Conta principal',
  es_ES: 'Cuenta principal',
};

export const TEXT_NO_KEEP = {
  en_US: 'No, keep the bank',
  pt_BR: 'Não, manter o banco',
  es_ES: 'No, mantener banco',
};

export const DELETE_BANK_TITLE = {
  en_US: 'Are you sure you want to remove the selected bank? ',
  pt_BR: 'Você está certo de que deseja remover o banco selecionado?',
  es_ES: '¿Está seguro de que desea eliminar el banco seleccionado?',
};

export const DELETE_BANK_SUBTITLE = {
  en_US: 'This action cannot be reversed.',
  pt_BR: 'Essa ação não pode ser revertida.',
  es_ES: 'No se podrá revertir esta acción.',
};

export const CONFIRM_DELETE_BANK = {
  en_US: 'Bank removed successfully.',
  pt_BR: 'Banco removido com sucesso.',
  es_ES: 'Banco eliminado correctamente.',
};

export const YOU_DONT_HAVE_BANK = {
  en_US: `You don't have any bank information.`,
  pt_BR: `Você não tem nenhuma informação bancária.`,
  es_ES: `No tienes ninguna información bancaria.`,
};

export const REMOVING_BANK = {
  en_US: `Removing the bank...`,
  pt_BR: `Removendo o banco...`,
  es_ES: `Eliminando el banco...`,
};
