export const LABEL_FIRST_NAME = {
  en_US: 'First name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_ADDRESS = {
  en_US: 'Home address',
  pt_BR: 'Endereço residencial',
  es_ES: 'Dirección',
};

export const LABEL_TITLE = {
  en_US: 'Title',
  pt_BR: 'Título',
  es_ES: 'Título',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship',
  pt_BR: 'Parentesco',
  es_ES: 'Relación',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo eletrónico',
};

export const LABEL_NATIONALITY = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const RELATIONSHIP_SON = {
  en_US: 'Son',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const RELATIONSHIP_DAU = {
  en_US: 'Daughter',
  pt_BR: 'Filha',
  es_ES: 'Hija',
};

export const RELATIONSHIP_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const RELATIONSHIP_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};
