import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const LABEL_PURCHASE_COLISION = {
  en_US: 'Did you purchased a collision insurance with car rental company?',
  pt_BR:
    'Você contratou o seguro de colisão com a empresa de aluguel de automóvel?',
  es_ES:
    '¿Contrató o adquirió un seguro de colisión de la compañía de alquiler de automóviles?',
};

export const LABEL_BENEFITS_PAYABLE = {
  en_US: 'Benefits payable to',
  pt_BR: 'Benefícios a pagar para',
  es_ES: 'Beneficios a pagar para',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_CELL_PHONE_REPAIRABLE = {
  en_US: 'Is the cell phone repairable? *',
  pt_BR: 'O celular pode ser consertado? *',
  es_ES: 'El teléfono celular es reparable? *',
};

export const LABEL_REPAIR_VALUE = {
  en_US: 'Repair value *',
  pt_BR: 'Valor de reparo *',
  es_ES: 'Valor de la reparación *',
};

export const LABEL_CARD_HOLDER = {
  en_US: 'Cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};

export const LABEL_RENTAL_COMPANY = {
  en_US: 'Rental company',
  pt_BR: 'Empresa de aluguel de automóvel',
  es_ES: 'Compañia de alquiler de automóviles',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_AMOUNt_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const ESTIMATED_CELL_PHONE_VALUE = {
  en_US: 'Estimated cell phone value (including taxes) *',
  pt_BR: 'Valor estimado do telefone celular (incluindo impostos) *',
  es_ES: 'Valor estimado de teléfono celular (incluyendo impuestos) *',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss: *',
  pt_BR: 'Tipo de perda: *',
  es_ES: 'Tipo de pérdida: *',
};

export const TEXT_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const TEXT_STOLEN = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_FULL_CARD = {
  en_US:
    'Was the item purchased and paid for in full with the selected credit card?',
  pt_BR:
    'O artigo foi comprado e pago em sua totalidade com o cartão de crédito selecionado?',
  es_ES:
    '¿Se compró y pagó el artículo en su totalidad con la tarjeta de crédito seleccionada?',
};

export const TEXT_DESCRIBE_REPAIRABLE = {
  en_US:
    'A new field will appear for you to enter whether the damage is repairable',
  pt_BR: 'Novo campo vai aparecer para você informar se o dano é reparável',
  es_ES: 'Aparecerá un nuevo campo para que introduzca si el daño es reparable',
};

export const TEXT_DESCRIBE_REPAIRABLE_YES = {
  en_US: 'A new field will appear for you to enter the amount of the repair',
  pt_BR: 'Novo campo vai aparecer para você informar valor do reparo',
  es_ES:
    'Aparecerá un nuevo campo para que introduzca el valor de la reparación',
};

export const TEXT_DESCRIBE_REPAIRABLE_NO = {
  en_US: `You won't need any more fields about the repair`,
  pt_BR: 'Não vai ser necessário mais campos sobre o reparo',
  es_ES: 'No necesitará más campos sobre la reparación',
};
