import { postalCodeService } from '../services';

export const callCepService = async (
  cep,
  setCepStatus,
  invalidCep,
  setFieldValue = null,
  fields = null
) => {
  const cepValue = cep.replace('-', '');
  if (cep.length === 9 || cepValue.length === 8) {
    setCepStatus('LOADING');
    try {
      const response = await postalCodeService.getCepInfo(cep);
      if (response.data.cep) {
        const data = response.data;
        setFieldValue(`${invalidCep}`, false);
        if (fields) {
          for (var index in fields) {
            setFieldValue(`${fields[index]}`, data[index]);
          }
        }
        setCepStatus('OK');
      } else {
        setCepStatus('INVALID');
      }
    } catch (error) {
      setFieldValue(`${invalidCep}`, false);
      setCepStatus('ERROR');
    }
  } else setCepStatus();
};
