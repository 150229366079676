import React, { useContext } from 'react';
import styles from './GrayBox.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../../utils/GTM_helper';
import ArrowRightIcon from '../../../../assets/icons/ArrowRight';
import Button from '../../../../componentsV2/Button/Button';

const GrayBox = props => {
  const { title, benefits, link, to = '/', GTM_item_id } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const history = useHistory();

  const content =
    Array.isArray(benefits) &&
    benefits.map((benefit, index) => (
      <p className={styles.benefit} key={index}>
        {benefit}
      </p>
    ));

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <h2 className={`${styles[theme]} ${styles.title}`}>{title}</h2>
      <div className={`${styles[theme]} ${styles.content}`}>{content}</div>
      <div className={styles.footer}>
        <Button
          onClick={() => {
            dataLayerContentMenuTrack(GTM_item_id, 'Service access cards');
            history.push(to);
          }}
          label={link}
          type="Tertiary"
          trailingIcon="Chevron Right"
        />
      </div>
    </div>
  );
};

export default GrayBox;
