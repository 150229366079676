import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';
import {
  tripInformationSchema,
  emptyValidationSchema,
} from './validationSchema';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import DatesArea from './DatesArea/DatesArea';
import PlacesArea from './PlacesArea/PlacesArea';
import styles from './TripInformation.module.scss';

const TripInformation = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    initialValues,
    updateParent,
    tripSubmitRef,
    isBrazil,
    isCertificate = false,
  } = props;
  const validationSchema = useMemo(
    () =>
      isBrazil ? emptyValidationSchema(intl) : tripInformationSchema(intl),
    [intl, isBrazil]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);

        return (
          <form
            onSubmit={handleSubmit}
            data-testid="FormTripBenefits"
            autoComplete="false"
          >
            <button
              ref={tripSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            {isBrazil && (
              <div className={styles.unrequiredTabMessage}>
                <h3>{intl.UNREQUIRERD_TRIP_INFORMATION_MESSAGE}</h3>
              </div>
            )}
            <DatesArea {...props} />
            <PlacesArea {...props} isCertificate={isCertificate} />
          </form>
        );
      }}
    </Formik>
  );
};

export default TripInformation;
