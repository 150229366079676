import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const CircleDoneIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 38 38"
      fill="none"
      {...handledProps}
    >
      <path
        fill={colors.secondary}
        d="M32.436 5.564A19.006 19.006 0 1 0 38 18.957a18.957 18.957 0 0 0-5.564-13.393ZM18.129 27.48a2.558 2.558 0 0 1-3.652 0l-7.659-7.658 2.922-3.117 6.27 6.648 12.566-12.395 3.227 2.85L18.129 27.48Z"
      />
    </svg>
  );
};

export default CircleDoneIcon;
