import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './PlacesArea.module.scss';
import Input from '../../../../../../../components/Input/Input';
import Select from '../../../../../../../components/Select/Select';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../utils/sanitize';

const PlacesArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.placesArea}>
      <div className={`row`}>
        <div className="col-12 col-md-4">
          <Select
            label={intl.FIELD_VALIDITY}
            value={values.is_international_trip}
            name="is_international_trip"
            formikProps={props}
            dataTestid="internationalTrip"
          >
            <option value="true">
              {intl.FIELD_VALIDITY_VALUE_INTERNACIONAL}
            </option>
            <option value="false">{intl.FIELD_VALIDITY_VALUE_DOMESTIC}</option>
          </Select>
        </div>
        {values.is_international_trip === 'true' ? (
          <div className="col-12 col-md-4">
            <Select
              label={intl.FIELD_COUNTRY}
              value={values.country_origin}
              name="country_origin"
              dataTestid="InputTIOriginCountry"
              onChange={handleChange}
              onBlur={handleBlur}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.FIELD_COUNTRY}</option>` +
                    intl.COUNTRY_OPTIONS
                ),
              }}
              touched={touched && touched.country_origin}
              error={errors && errors.country_origin}
            />
          </div>
        ) : (
          <div className="col-12 col-md-4">
            <Input
              value={values.city_origin}
              label={intl.FIELD_CITY}
              name="city_origin"
              type="text"
              dataTestid="InputTICityOrigin"
              onChange={handleChange}
              touched={touched && touched.city_origin}
              onBlur={handleBlur}
              error={errors && errors.city_origin}
            />
          </div>
        )}
        <div className="col-12 col-md-4">
          <Input
            value={values.reservation_number}
            label={intl.BOOKING_NUMBER}
            name="reservation_number"
            type="text"
            dataTestid="InputTIReservationNumber"
            onChange={handleChange}
            touched={touched && touched.reservation_number}
            onBlur={handleBlur}
            error={errors && errors.reservation_number}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p className={`${styles.blueInfo} ${styles[themes]}`}>
            {intl.COVERAGE_DAYS}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PlacesArea;
