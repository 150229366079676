import React, { useContext, useState } from 'react';
import { pt_BR } from '../../../../intl/idioms';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import CheckTerms from './CheckTerms/CheckTerms';
import styles from './styles.module.scss';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../../componentsV2/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const getPdfLink = claimDetails => {
  const pdfDocument = claimDetails.documents.find(
    document => document.name === 'pdf_url'
  );
  return pdfDocument && pdfDocument.content_url;
};

const reduceAcceptChecked = (sum, checked) => (checked ? sum + 1 : sum);

const TermsAndConditions = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { claimDetails, setStep } = props;
  const [acceptChecked, setAcceptChecked] = useState([]);
  const isBrazil = idiom === pt_BR;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const acceptSum = acceptChecked.reduce(reduceAcceptChecked, 0);
  const acceptedAll = isBrazil ? acceptSum === 5 : acceptSum === 4;
  const pdfLink = claimDetails && getPdfLink(claimDetails);

  const check = index => {
    const _acceptChecked = [...acceptChecked];
    _acceptChecked[index] = !_acceptChecked[index];
    setAcceptChecked(_acceptChecked);
  };

  return (
    <div id="mainContent">
      <ShadowBox>
        <div className={styles.termsArea}>
          <h2 className={styles[themes]}>{intl.WELCOME_MEDIC}</h2>
          <p className={styles[themes]}>{intl.CONDITION_ELIGIBLE}</p>
          <p className={styles[themes]}>{intl.READ_CAREFUL}</p>
          <div className={`${styles.acceptArea} ${styles[themes]}`}>
            <CheckTerms
              isBrazil={isBrazil}
              acceptChecked={acceptChecked}
              check={check}
              pdfLink={pdfLink}
              styles={styles}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            type="Secondary"
            disabled={!acceptedAll}
            onClick={() => setStep('FORM')}
            label={intl.BTN_NEXT_STEP}
            className={styles.nextStepButton}
          />
        </div>
      </ShadowBox>
    </div>
  );
};

export default TermsAndConditions;
