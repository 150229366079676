/* eslint-disable indent */
import moment from 'moment';

export const priceProtectionFormInitialValues = (
  data,
  idiom,
  completeClaim
) => {
  let person = data && data.person ? data.person : '';
  let purchasedItem = completeClaim && completeClaim.purchased_item;
  let advertisedPrice = completeClaim && completeClaim.advertised_price;
  let affectedPerson = completeClaim && completeClaim.affected_person;
  let totalAmountClaimed = completeClaim && completeClaim.total_amount_claimed;
  let amountEstimationDamage =
    completeClaim && completeClaim.amount_estimation_damage;
  let otherInsuranceCovering =
    completeClaim &&
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[0];

  const getPrice = price => ({
    value: price && price.value ? price.value : '',
    currency: price && price.currency ? price.currency : '',
  });

  const getTotalInvoice = total_invoice_amount => ({
    value:
      total_invoice_amount && total_invoice_amount.value
        ? total_invoice_amount.value
        : '',
    currency:
      total_invoice_amount && total_invoice_amount.currency
        ? total_invoice_amount.currency
        : '',
  });

  const getPurchasedItem = purchasedItem => ({
    label: purchasedItem && purchasedItem.label ? purchasedItem.label : '',
    model_number:
      purchasedItem && purchasedItem.model_number
        ? purchasedItem.model_number
        : '',
    manufacturer:
      purchasedItem && purchasedItem.manufacturer
        ? purchasedItem.manufacturer
        : '',
    price: getPrice(purchasedItem && purchasedItem.price),
    is_full_charged_on_card:
      purchasedItem && purchasedItem.is_full_charged_on_card
        ? purchasedItem.is_full_charged_on_card
        : true,
    purchase_date:
      purchasedItem && purchasedItem.purchase_date
        ? new Date(moment(purchasedItem.purchase_date, 'YYYY-MM-DD'))
        : '',
    paymentMethod:
      purchasedItem && purchasedItem.number_of_installments > 0
        ? 'installment'
        : purchasedItem && purchasedItem.number_of_installments === 0
        ? 'cash'
        : '',
    installments_number:
      (purchasedItem && purchasedItem.number_of_installments) || 0,
    installment_price: (purchasedItem && purchasedItem.installment_price) || '',
    retailer: (purchasedItem && purchasedItem.retailer) || '',
    total_invoice_amount: getTotalInvoice(
      purchasedItem && purchasedItem.total_invoice_amount
    ),
  });

  const getAdvertisedPrice = advertisedPrice => ({
    discovery_date:
      advertisedPrice && advertisedPrice.discovery_date
        ? new Date(moment(advertisedPrice.discovery_date, 'YYYY-MM-DD'))
        : '',
    publishing_location:
      advertisedPrice && advertisedPrice.publishing_location
        ? advertisedPrice.publishing_location
        : '',
    retail_name:
      advertisedPrice && advertisedPrice.retail_name
        ? advertisedPrice.retail_name
        : '',
    price: getPrice(advertisedPrice && advertisedPrice.price),
    date_compare_from_purchase_date:
      advertisedPrice && advertisedPrice.discovery_date
        ? new Date(
            moment(advertisedPrice.discovery_date, 'YYYY-MM-DD').add(30, 'd')
          )
        : '',
  });

  const getPersonName = affectedPerson => {
    if (affectedPerson && affectedPerson.person) {
      const person = affectedPerson.person;
      return {
        first_name: person.first_name ? person.first_name : '',
        last_name: person.last_name ? person.last_name : '',
      };
    } else {
      return {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      };
    }
  };

  const getAffectedPerson = affectedPerson => ({
    relationship: 'Cardholder',
    relationshipCardholder: true,
    person: getPersonName(affectedPerson),
  });

  const getOtherInsuranceCovering = otherInsuranceCovering => ({
    is_internal:
      otherInsuranceCovering && otherInsuranceCovering.is_internal !== undefined
        ? String(otherInsuranceCovering.is_internal)
        : 'true',
    date: '',
    description:
      otherInsuranceCovering && otherInsuranceCovering.description
        ? otherInsuranceCovering.description
        : '',
    ...getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
  });

  return {
    purchased_item: getPurchasedItem(purchasedItem),
    advertised_price: getAdvertisedPrice(advertisedPrice),
    affected_person: getAffectedPerson(affectedPerson),
    total_amount_claimed: getPrice(totalAmountClaimed),
    amount_estimation_damage: getPrice(amountEstimationDamage),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
  };
};
