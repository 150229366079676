import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../intl';
import * as translations from '../../../../../intl/infoProfile';
import { pt_BR } from '../../../../../intl/idioms';
import {
  getGender,
  getPhoneType,
  getPoliticallyExposed,
  getTitle,
} from '../../../../../utils/formatInfoProfile';
import {
  formatCPF,
  formatPostalCodeBR,
  mask4FirstDigits,
} from '../../../../../utils/stringUtils';
import { formatCustomDate } from '../../../../../utils/date';

const CardHolderProfile = ({ userInfo }) => {
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);
  const isPTBR = idiom === pt_BR;

  const showCPF = isPTBR
    ? true
    : userInfo.person &&
      userInfo.person.nationalities &&
      userInfo.person.nationalities[0] === 'BR';
  const showRolacRegistration = !(
    !isPTBR &&
    userInfo.person &&
    userInfo.person.nationalities &&
    userInfo.person.nationalities[0] === 'BR'
  );

  const indexCPF =
    userInfo.registrations &&
    userInfo.registrations.findIndex(
      registration => registration.registration_type === 'CPF'
    );
  const indexNotCPF =
    userInfo.registrations &&
    userInfo.registrations.findIndex(
      registration =>
        registration.registration_type === 'ID_CARD' ||
        registration.registration_type === 'PASSPORT'
    );

  const birthDate = userInfo.person.birth_date
    ? formatCustomDate(userInfo.person.birth_date, 'YYYY-MM-DD', idiom, country)
    : '-';
  const isNull = prop => {
    return prop ? prop : '-';
  };

  const getAddress = prop => {
    if (!prop.country) return '-';

    return `
    ${userInfo.address.street_address} <br/>
    ${userInfo.address.subdivision || ''} ${
      userInfo.address.subdivision ? `<br/>` : ''
    }
    ${userInfo.address.locality} -
    ${userInfo.address.state} -
    ${userInfo.address.country}
    (${
      isPTBR
        ? formatPostalCodeBR(userInfo.address.postal_code)
        : userInfo.address.postal_code
    })`;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-3 col-lg-4">
          <InfoLabelValue
            label={intl.LABEL_FIRST_NAME}
            value={isNull(userInfo.person.first_name)}
          />
        </div>
        <div className="col-12 col-sm-5 col-lg-4">
          <InfoLabelValue
            label={intl.LABEL_LAST_NAME}
            value={isNull(userInfo.person.last_name)}
          />
        </div>
        <div className="col-12 col-sm-4 col-lg-4">
          <InfoLabelValue
            label={intl.LABEL_GENDER}
            value={isNull(getGender(userInfo.person.gender, intl))}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-3 col-lg-4">
          <InfoLabelValue label={intl.LABEL_BIRTH_DATE} value={birthDate} />
        </div>
        <div className="col-12 col-sm-5 col-lg-4">
          <InfoLabelValue label={intl.LABEL_EMAIL} value={userInfo.email} />
        </div>
      </div>
      <div className="row">
        {showCPF && (
          <div className="col-12 col-sm-4 col-lg-4">
            <InfoLabelValue
              label={intl.LABEL_CPF}
              value={
                userInfo.registrations &&
                userInfo.registrations[indexCPF] &&
                userInfo.registrations[indexCPF].value
                  ? formatCPF(
                      mask4FirstDigits(userInfo.registrations[indexCPF].value)
                    )
                  : '-'
              }
            />
          </div>
        )}
        {showRolacRegistration && (
          <div
            className={
              showCPF ? 'col-12 col-sm-3 col-lg-4' : 'col-12 col-sm-4 col-lg-4'
            }
          >
            <InfoLabelValue
              label={
                indexNotCPF < 0 ||
                userInfo.registrations[indexNotCPF].registration_type ===
                  'PASSPORT'
                  ? intl.LABEL_PASSPORT
                  : intl.LABEL_NATIONAL_ID
              }
              value={
                userInfo.registrations &&
                userInfo.registrations[indexNotCPF] &&
                userInfo.registrations[indexNotCPF].value
                  ? mask4FirstDigits(userInfo.registrations[indexNotCPF].value)
                  : '-'
              }
            />
          </div>
        )}
        <div className="col-12 col-sm-5 col-lg-4">
          <InfoLabelValue
            label={intl.TELEPHONE_NUMBER}
            value={`
                ${isNull(getPhoneType(userInfo.phone.phone_type, intl))}
                ${isNull(userInfo.phone.international_prefix)}
                ${isNull(userInfo.phone.number)}
              `}
          />
        </div>
        <div className="col-12 col-sm-4 col-lg-4">
          <InfoLabelValue
            label={intl.LABEL_ADDRESS}
            value={getAddress(userInfo.address)}
          />
        </div>
      </div>

      <div className="row">
        {isPTBR && (
          <>
            <div className="col-12 col-sm-5 col-lg-4">
              <InfoLabelValue
                label={intl.TEXT_POLITICALLY_EXPOSED}
                value={`
                ${getPoliticallyExposed(
                  userInfo.is_politically_exposed,
                  intl
                )}`}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CardHolderProfile;
