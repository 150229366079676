import {
  ENABLE_UPSELLING,
  B2B2C_TEST_WHITELIST,
  LIST_CLAIMS_API_ENABLE_TO_USE_MLE,
} from './environments_variables';

export const ELIGIBLE_CARDS_UPSELLING = ['PLATINUM', 'INFINITE', 'SIGNATURE'];
const ELIGIBLE_BENEFITS = ['EMC_Emergency_Medical_Assistance'];
const COUNTRIES = ['MX', 'MEXICO'];

export const existsInWhitelist = email => B2B2C_TEST_WHITELIST.includes(email);

export const enableUpselling = (cardName, country, email) => {
  if (ENABLE_UPSELLING) {
    return verifyEligibleCards(cardName) && enableUpsellingByCountry(country);
  } else {
    return (
      verifyEligibleCards(cardName) &&
      enableUpsellingByCountry(country) &&
      existsInWhitelist(email)
    );
  }
};

export const enableUpsellingByCountry = country => {
  return COUNTRIES.includes(country);
};

export const verifyEligibleCards = name => {
  const stringToArray = name && name.toUpperCase().split(' ');
  if (!stringToArray) {
    return false;
  }
  const checkEligibleCards = ELIGIBLE_CARDS_UPSELLING.find(card =>
    stringToArray.includes(card)
  );
  return checkEligibleCards;
};

const verifyEligibleBenefits = name => {
  return ELIGIBLE_BENEFITS.includes(name);
};

const checkAcess = async (benefit, card, email) => {
  const benefitName = benefit.external_reference
    ? benefit.external_reference
    : benefit;

  if (ENABLE_UPSELLING) {
    return (
      verifyEligibleCards(card.name) &&
      enableUpsellingByCountry(card.country) &&
      verifyEligibleBenefits(benefitName)
    );
  } else {
    return (
      verifyEligibleCards(card.name) &&
      enableUpsellingByCountry(card.country) &&
      verifyEligibleBenefits(benefitName) &&
      existsInWhitelist(email)
    );
  }
};

export const IsClaimEnableToUseMLE = claim_type => {
  return LIST_CLAIMS_API_ENABLE_TO_USE_MLE.find(item => item === claim_type);
};

export const verifyAccess = async (benefit, card, email) => {
  return await checkAcess(benefit, card, email);
};

export const verifyAcessRenderButton = async (benefit, card, email) => {
  return await checkAcess(benefit, card, email);
};
