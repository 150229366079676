export const LABEL_REIMBURSED_BY_TRANSPORTATION_COMPANY = {
  en_US: 'Were you reimbursed for any amount by the transportation company?',
  es_ES: '¿Recibiste algún reembolso por parte de la compañía de transporte?',
  pt_BR: 'Você recebeu reembolso da empresa de transporte?',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Amount paid by the insurance',
  pt_BR: 'Valor pago pela companhia de seguros',
  es_ES: 'Monto pagado por la compañía de seguros',
};
