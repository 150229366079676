import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/Iems/consts';
import { formatCustomDate } from '../../../../../../utils/date';
import { mask4FirstDigits } from '../../../../../../utils/stringUtils';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const affected_person = claimDetails.affected_person;

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const cardholderRelationship =
    affected_person && affected_person.relationship
      ? cardholderRelationshipLabel[affected_person.relationship]
      : '-';
  const birthDate =
    affected_person &&
    affected_person.person &&
    affected_person.person.birth_date
      ? formatCustomDate(
          affected_person.person.birth_date,
          undefined,
          idiom,
          country
        )
      : '-';
  const person_registration =
    affected_person &&
    affected_person.person_registration &&
    affected_person.person_registration[0] &&
    affected_person.person_registration[0].value &&
    mask4FirstDigits(affected_person.person_registration[0].value);

  const affectedPersonComment = affected_person && affected_person.comment;

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={cardholderRelationship}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NAME_AFFECTED}
          value={safeLoad(affected_person.person.first_name)}
        />
      </div>
      <div className="ccol-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME_AFFECTED}
          value={safeLoad(affected_person.person.last_name)}
        />
      </div>

      {affectedPersonComment && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={safeLoad(affectedPersonComment)}
          />
        </div>
      )}

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_BIRTH_DATE} value={birthDate} />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_REGISTRATIONS_TYPE}
          value={safeLoad(person_registration)}
        />
      </div>
    </>
  );
};

export default PersonalArea;
