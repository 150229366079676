import { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './index.module.scss';
import FlightListItem from './FlightListItem';
import Loading from '../../../../../../../../components/Loading/Loading';
import { ScheduleWithAirportInfo } from '../../../../../../utils';
import { joinClassNames } from '../../../../../../../../utils/classNames';
import { useTheme } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

interface FlightListProps {
  flights: ScheduleWithAirportInfo[];
  legIndex: number;
  fillFields: (
    flightIndex: number,
    flightInfo: ScheduleWithAirportInfo
  ) => Promise<void>;
  isLoading: boolean;
}

const FlightList = ({
  flights,
  legIndex,
  isLoading,
  fillFields,
}: FlightListProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  if (isLoading)
    return (
      <div className={`${styles.loadingContainer} col-12`}>
        <Loading />
      </div>
    );

  if (flights === null) return null;

  return (
    <>
      <h5 className={styles.selectFlightTitle}>{intl.SELECT_FLIGHT}</h5>
      {flights && flights.length > 0 ? (
        flights.map((flight, index) => (
          <FlightListItem
            schedule={flight}
            legIndex={legIndex}
            key={flight.origin + flight.scheduled_out + index}
            fillFields={fillFields}
          />
        ))
      ) : (
        <p className={joinClassNames(styles.noFlightsAlert, styles[theme])}>
          {intl.TEXT_NO_FLIGHT_FOUND}
        </p>
      )}
    </>
  );
};

export default FlightList;
