import { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import Select from '../../../../../../../../components/Select/Select';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    parentValues,
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    setAtmType,
    atmType,
  } = props;

  useEffect(() => {
    values.incident.kind === 'LOSS'
      ? setAtmType(constants.VALUE_ASSAULT)
      : setAtmType(constants.VALUE_DEATH);
  }, []);

  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
  };
  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );
  };
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setOptionAssaultDeath = e => {
    if (e.target.value === constants.VALUE_ASSAULT) {
      setFieldValue('incident.kind', constants.VALUE_ASSAULT);
    }
    if (e.target.value === constants.VALUE_DEATH) {
      setFieldValue('incident.kind', constants.VALUE_DEATH);
    }
    setAtmType(e.target.value);
  };

  const changeRelationship = event => {
    setEmptyPersonalInputFields();
    if (event.target.value === constants.CARD_HOLDER_VALUE) {
      setCardHolderNameLastName();
    }
    handleChange(event);
  };

  const isCardHolder =
    values.affected_person.relationship === constants.CARD_HOLDER_VALUE;

  return (
    <div className={`row ${styles.personalArea}`}>
      <div className="col-12" style={{ marginBottom: '12px' }}>
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.SELECT_OPTION_ASSAULT_DEATH}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.SELECT_OPTION_ASSAULT_DEATH}
              defaultChecked={values.incident.kind === 'LOSS'}
              text={intl.OPTION_ASSAULT}
              name="incident.kind"
              dataTestid="InputIncidentKindAssault"
              value={constants.VALUE_ASSAULT}
              onChange={e => {
                setOptionAssaultDeath(e);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.SELECT_OPTION_ASSAULT_DEATH}
              defaultChecked={values.incident.kind === 'DEATH'}
              text={intl.OPTION_DEATH}
              name="incident.kind"
              dataTestid="InputIncidentKindDeath"
              value={constants.VALUE_DEATH}
              onChange={e => {
                setOptionAssaultDeath(e);
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>

      {/* RELATIONSHIP  */}
      <div className="col-12">
        <div className="row">
          <div className="col-12 col-md-6">
            <Select
              label={intl.PERSON_AFFECTED}
              name="affected_person.relationship"
              value={values.affected_person.relationship}
              onBlur={handleBlur}
              touched={
                touched.affected_person && touched.affected_person.relationship
              }
              error={
                errors.affected_person && errors.affected_person.relationship
              }
              onChange={changeRelationship}
              describe={intl.DESCRIPTION_CHANGE}
            >
              <option value={constants.CARD_HOLDER_VALUE}>
                {intl.TEXT_CH}
              </option>
              <option value={constants.SPOUSE_VALUE}>
                {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
              </option>
              <option value={constants.DEPENDENT_VALUE}>
                {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
              </option>
              <option value={constants.OTHER_VALUE}>
                {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
              </option>
            </Select>
          </div>

          <div
            role="contentRelationshipComment"
            className={`col-12 col-md-6 ${
              values.affected_person.relationship !== constants.OTHER_VALUE
                ? styles.none
                : ''
            }`}
          >
            <Input
              value={values.affected_person.comment}
              label={intl.TEXT_OTHER}
              name="affected_person.comment"
              dataTestid="InputRelationshipComment"
              type="text"
              disabled={
                values.affected_person.relationship !== constants.OTHER_VALUE
              }
              onChange={handleChange}
              touched={
                touched.affected_person && touched.affected_person.comment
              }
              onBlur={handleBlur}
              error={errors.affected_person && errors.affected_person.comment}
              maxLength="255"
            />
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-10 col-md-6">
        <Input
          label={intl.LABEL_NAME_AFFECTED}
          name="affected_person.person.first_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          onChange={handleChange}
          formikProps={props}
          maxLength="40"
          dataTestid="InputFirstName"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          label={intl.LABEL_LAST_NAME_AFFECTED}
          name="affected_person.person.last_name"
          type="text"
          disabled={
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
          }
          formikProps={props}
          maxLength="80"
          dataTestid="InputLastName"
        />
      </div>

      {/* DATES */}
      <div
        className={`col-12 ${styles.personalArea} ${
          atmType === constants.VALUE_DEATH ? '' : styles.none
        }`}
      >
        <div className="row">
          {!isCardHolder ? (
            <>
              <div className="col-12 col-md-6">
                <Calendar
                  label={intl.DATE_BIRTH}
                  name="affected_person.person.birth_date"
                  maxDate={new Date()}
                  formikProps={props}
                  setFieldTouched={setFieldTouched}
                  dataTestid="inputPersonBirthDate"
                />
              </div>
              <div className="col-12 col-md-6">
                <Select
                  label={intl.GENDER}
                  name="affected_person.person.gender"
                  value={values.affected_person.person.gender}
                  onBlur={handleBlur}
                  touched={
                    touched.affected_person &&
                    touched.affected_person.person &&
                    touched.affected_person.person.gender
                  }
                  error={
                    errors.affected_person &&
                    errors.affected_person.person &&
                    errors.affected_person.person.gender
                  }
                  onChange={handleChange}
                >
                  <option value={constants.GENDER_MALE}>
                    {intl.GENDER_MALE}
                  </option>
                  <option value={constants.GENDER_FEMALE}>
                    {intl.GENDER_FEMALE}
                  </option>
                </Select>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="col-12 col-md-6">
            <Calendar
              label={intl.DEATH_DATE}
              name="affected_person.deceased_at"
              maxDate={new Date()}
              formikProps={props}
              setFieldTouched={setFieldTouched}
              dataTestid="inputPersonDeceasedAt"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
