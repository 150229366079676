import { useContext, useEffect } from 'react';
import styles from '../IncidentArea.module.scss';
import * as translations from '../intl';
import { IntlContext } from '../../../../../../../../../intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../../utils/currency';
import ProductItem from './ProductItem/ProductItem';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';
import GroupDivisor from '../../../../../../../../../components/GroupDivisor';

const ProductsList = props => {
  const { values, setFieldValue, setFieldTouched } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const addProduct = event => {
    if (event) event.preventDefault();
    const listProducts = [...values.purchase_protection_items];
    const newProduct = {
      label: '',
      manufacturer: '',
      model_number: '',
      purchase_date: '',
      is_the_damage_visible: false,
      is_repairable: false,
      status: 'Damage',
      price: {
        value: '',
        currency: values.purchase_protection_items[0].price.currency || '',
      },
      amount_claimed: {
        value: '',
        currency: values.purchase_protection_items[0].price.currency || '',
      },
      other_insurance_covering: {
        is_internal: false,
        amount: {
          value: '',
          currency: values.purchase_protection_items[0].price.currency || '',
        },
      },
    };
    listProducts.push(newProduct);
    setFieldValue('purchase_protection_items', listProducts);
  };
  const removeProduct = (event, index) => {
    event.preventDefault();

    const cloneProducts = [...values.purchase_protection_items];
    cloneProducts.splice(index, 1);
    setFieldValue('purchase_protection_items', cloneProducts);
    const newTotal =
      values.total_amount_claimed.value -
      values.purchase_protection_items[index].amount_claimed.value;
    setFieldValue('total_amount_claimed.value', newTotal.toFixed(2));
  };
  const setCurrency = currency => {
    setFieldValue('purchase_protection_items[0].price.currency', currency);
    setFieldTouched('purchase_protection_items[0].price.currency', true);
  };
  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
  }, []);

  return (
    <div className={`col-12 ${styles.marginBottom}`}>
      <div className={styles.buttonArea}>
        <Button
          type="Tertiary"
          onClick={addProduct}
          label={intl.BTN_ADD}
          leadingIcon={<AddIcon />}
        />
      </div>
      {values.purchase_protection_items.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <GroupDivisor title={`${intl.TEXT_ITEM}${index + 1}`} />
          </div>
          <ProductItem {...props} index={index} />
          <div className={`col-2 ${styles.footer}`}>
            {index !== 0 && (
              <Button
                type="Tertiary"
                testId={`buttonRemove-${index}`}
                onClick={event => removeProduct(event, index)}
                label={intl.BTN_REMOVE}
                leadingIcon={<TrashIcon width={24} height={24} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ProductsList;
