export function showLoadingFullScreen(title = '', axa) {
  const loadingFullScreen = {
    ...this.state.loadingFullScreen,
    show: true,
    title: title,
    axa: axa,
  };
  this.setState({ loadingFullScreen });
}

export function hideLoadingFullScreen() {
  const loadingFullScreen = {
    ...this.state.loadingFullScreen,
    show: false,
    title: '',
  };
  this.setState({ loadingFullScreen });
}
