export const TEXT_VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const EXTENDED_WARRANTY_TOOLTIP = {
  en_US:
    'The displayed amount considered a sum of U$140.81 per Extended Warranty Certificate.',
  pt_BR:
    'O valor exibido considera uma soma de U$140.81 por Certificado de Garantia Estendida.',
  es_ES:
    'El monto mostrado incluye una suma de U$140.81 por Certificado de Garantía Extendida.',
};

export const TRAVEL_INSURANCE_TOOLTIP = {
  en_US:
    'The displayed amount considered a sum of U$403.34 per beneficiary per Travel Assistance Certificate.',
  pt_BR:
    'O valor exibido considera uma soma de U$403.34 por beneficiário por Bilhete seguro viagem.',
  es_ES:
    'El monto mostrado incluye una suma de U$403.34 por beneficiario por Certificado de Asistencia en Viaje.',
};
export const AUTO_RENTAL_TOOLTIP = {
  en_US:
    'The displayed amount considered a sum of U$679.00 per Auto Rental Insurance Certificate.',
  pt_BR:
    'O valor exibido considera uma soma de U$679.00 por Certificado de seguro para veículos de locadora.',
  es_ES:
    'El monto mostrado incluye una suma de U$679.00 por Certificado de seguro de alquiler de auto.',
};
export const PURCHASE_TOOLTIP = {
  en_US:
    'The displayed amount considered a sum of U$350.00 per Certificate of Purchase.',
  pt_BR:
    'O valor exibido considera uma soma de U$350.00 por Certificado de Compra.',
  es_ES:
    'El monto mostrado incluye una suma de U$350.00 por Certificado de Compra.',
};
