import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from '../IdTheftProtection.module.scss';
import * as translations from './intl';

const IncidentDateArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { setFieldTouched } = props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-lg-6 col-sm-12">
        <Calendar
          label={intl.INCIDENT_DATE}
          name="incident.dateValue"
          maxDate={new Date()}
          formikProps={props}
          setFieldTouched={setFieldTouched}
          dataTestid="inputIncidentDate"
        />
      </div>
    </div>
  );
};

export default IncidentDateArea;
