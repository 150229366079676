export const OPTIONAL = {
  en_US: 'Optional',
  pt_BR: 'Opcional',
  es_ES: 'Opcional',
};

export const REQUIRED = {
  en_US: 'Required',
  pt_BR: 'Requerido',
  es_ES: 'Requerido',
};
