export const TITLE_CONTACT_PHONE_NUMBER = {
  en_US: 'Contact phone number',
  pt_BR: 'Telefone para contato',
  es_ES: 'Teléfono de contacto',
};

export const LABEL_PHONE_TYPE = {
  en_US: 'Phone type *',
  pt_BR: 'Tipo de telefone *',
  es_ES: 'Tipo de teléfono *',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const LABEL_PREFIX = {
  en_US: 'Country code *',
  pt_BR: 'Código do país *',
  es_ES: 'Código del país *',
};

export const ERROR_COUNTRY_NOT_FOUND = {
  en_US: 'Country not found',
  pt_BR: 'País não encontrado',
  es_ES: 'País no encontrado',
};

export const TEXT_ALERT_PREFIX = {
  en_US: 'International Direct Dialing Code.',
  pt_BR: 'DDI (Código de Discagem Direta Internacional).',
  es_ES: 'Código de Prefijo de País.',
};

export const LABEL_NUMBER = {
  en_US: 'Number *',
  pt_BR: 'Número *',
  es_ES: 'Número *',
};

export const TOOLTIP_NUMBER = {
  en_US: 'Include phone number with city code.',
  pt_BR:
    'Incluir número de telefone com código de área (DDD - Discagem Direta a Distância).',
  es_ES: 'Incluya el número de teléfono con el código de área.',
};
