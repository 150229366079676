/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import styles from './CommunicationDevice.module.scss';
import { userServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import DeviceInfo from './DeviceInfo/DeviceInfo';
import Loading from '../../../../components/Loading/Loading';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import ModalResult from '../../../../components/ModalResult/ModalResult';
import ModalWithImage from '../../../../components/ModalWithImage/ModalWithImage';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import TrashIcon from '../../../../assets/icons/TrashIcon';
import Button from '../../../../componentsV2/Button/Button';
import { joinClassNames } from '../../../../utils/classNames';
import Link from '../../../../componentsV2/Link/Link';

const CommunicationDevice = props => {
  const {
    communicationDevices,
    setCommunicationDevices,
    preferences,
    setPreferences,
  } = props;
  const [loading, setLoading] = useState(false);
  const { utils, actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const cn = utils.getCn();
  const COMMUNICATIONS = {
    MARKETING: 'MARKETING',
    PROMOTION: 'PROMOTION',
    TIPS: 'TIPS',
    CUSTOMER_NOTIFICATION: 'CUSTOMER_NOTIFICATION',
  };
  const CHANNEL_PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';

  const loadCommunicationsDevice = async customer_id => {
    setLoading(true);
    const { data } = await userServices.getCommunicationsDevice(customer_id);
    setCommunicationDevices(data);
    setLoading(false);
  };

  useEffect(() => {
    // Don't load communication devices when testing
    if (process.env.NODE_ENV === 'test') return;

    loadCommunicationsDevice(cn);
  }, [cn]);

  const redirectHelpLink = (
    <Link
      internal
      href="/help#notifications"
      onClick={actions.modal.closeModal}
      label={intl.SUBTITLE_COMPLEMENT}
      icon={false}
    />
  );

  const deleteDevice = async device => {
    const deleteFunction = async () => {
      try {
        actions.modal.showLoading(intl.REMOVING_DEVICE);
        if (preferences[COMMUNICATIONS.CUSTOMER_NOTIFICATION]) {
          const subscriptionId =
            preferences[COMMUNICATIONS.CUSTOMER_NOTIFICATION]
              .communication_subscription_id;
          await userServices.optOutCommunication(cn, subscriptionId);
        }
        await userServices.deleteCommunicationDevice(
          cn,
          device.communication_device_id
        );

        const { data } = await userServices.getCommunicationsDevice(cn);
        setCommunicationDevices(data);
        if (
          data &&
          data.length > 0 &&
          preferences[COMMUNICATIONS.CUSTOMER_NOTIFICATION]
        ) {
          await userServices.optInCommunication(
            cn,
            COMMUNICATIONS.CUSTOMER_NOTIFICATION,
            CHANNEL_PUSH_NOTIFICATION,
            communicationDevices.map(device => device.communication_device_id)
          );
        }
        if (
          data &&
          data.length === 0 &&
          preferences[COMMUNICATIONS.CUSTOMER_NOTIFICATION]
        ) {
          setPreferences({ ...preferences, CUSTOMER_NOTIFICATION: false });
        }
        actions.modal.hideLoading();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="success"
            message={intl.DEVICE_REMOVED_SUCCESSFULLY}
            subtitle={intl.SUBTITLE_DISABLE_BROWSER_PUSH}
            content={redirectHelpLink}
          />,
          true,
          false
        );
      } catch (error) {
        console.log(error);
        actions.modal.hideLoading();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
          />,
          true,
          true
        );
      }
    };

    actions.modal.showModal(
      false,
      true,
      <ModalWithImage
        type="error"
        title={intl.DELETE_DEVICE_TITLE}
        subtitle={intl.DELETE_DEVICE_SUBTITLE}
        content={redirectHelpLink}
      >
        <ModalResult
          textConfirm={intl.BTN_REMOVE}
          clickConfirm={deleteFunction}
          textDeny={intl.TEXT_NO_KEEP}
          clickDeny={() => {
            actions.modal.closeModal();
          }}
          buttonConfirmType="borderRed"
        />
      </ModalWithImage>,
      true,
      false
    );
  };

  return (
    <div id="bank" className={`${styles.container} ${styles[themes]}`}>
      <div className={`${styles.titleArea}`}>
        <h2 className={styles[themes]}>{intl.TITLE_COMMUNICATION_DEVICE}</h2>
      </div>
      <span className={joinClassNames(styles[themes], styles.text)}>
        {communicationDevices &&
          communicationDevices.length !== 0 &&
          intl.SUBTITLE_COMMUNICATION_DEVICE}
      </span>

      <div className={styles.content}>
        {loading ? (
          <Loading />
        ) : (
          <>
            {communicationDevices &&
              communicationDevices.map((device, index) => (
                <div
                  className={`${styles.reimbursementItem} ${styles[themes]}`}
                  key={index}
                >
                  <DeviceInfo devicesInfo={device} />
                  <div className={styles.footer}>
                    <Button
                      type="Tertiary"
                      data-testid="deleteButton"
                      id={`deleteButton-${device.communication_device_id}`}
                      onClick={() => deleteDevice(device)}
                      leadingIcon={<TrashIcon width={24} height={24} />}
                      label={intl.BTN_REMOVE}
                    />
                  </div>
                </div>
              ))}
            {communicationDevices && communicationDevices.length === 0 && (
              <p className={`${styles.emptyMessage} ${styles[themes]}`}>
                {intl.YOU_DONT_HAVE_DEVICES}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CommunicationDevice;
