import React, { useEffect, useState, useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { certificatesServices } from '../../services';
import styles from './PrivateCertificatesList.module.scss';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import ListCertificates from '../../components/ListCertificates/ListCertificate';
import Loading from '../../components/Loading/Loading';
import ViewAllBenefits from '../ViewAllBenefits/ViewAllBenefits';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const PrivateCertificatesList = () => {
  const { utils } = useContext(StateContext);
  const { translate, idiomForApi } = useContext(IntlContext);
  const [loading, setLoading] = useState(true);
  const intl = translate(translations);
  const [generatedCertificates, setGeneratedCertificates] = useState([]);
  const binOrPan = utils.getBin(true);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    const getGenerated = async () => {
      try {
        const userCertificates =
          await certificatesServices.getCertificatesByBin(
            cn,
            binOrPan,
            idiomForApi()
          );
        setGeneratedCertificates(userCertificates.data);
      } catch (error) {
        setGeneratedCertificates([]);
      }
    };

    const getPageData = async () => {
      setLoading(true);
      await getGenerated();
      setLoading(false);
    };

    getPageData();
  }, [binOrPan]);

  return (
    <>
      <div className={`${styles.boxContainer} ${styles[themes]}`}>
        <div className={styles.container}>
          <h1
            data-testid="title"
            className={`${styles.title} ${styles[themes]}`}
          >
            {intl.TITLE}
          </h1>
          {generatedCertificates && generatedCertificates.length > 0 ? (
            <span className={`${styles.subtitle} ${styles[themes]}`}>
              {intl.SUBTITLE}
            </span>
          ) : (
            <span
              className={`${styles.subtitle} ${styles.noHaveClaim} ${styles[themes]}`}
            >
              {intl.TEXT_NO_HAVE_CERTIFICATE}
            </span>
          )}
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Loading />
            </div>
          ) : (
            <div className={styles.content}>
              <ListCertificates
                certificates={generatedCertificates}
                numberToFilter={2}
              />
              {generatedCertificates && generatedCertificates.length > 0 && (
                <ViewAllBenefits
                  text={intl.TEXT_VIEW_ALL}
                  link="/profile/certificates#active"
                  disablePadding
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PrivateCertificatesList;
