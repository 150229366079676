import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const LABEL_PURCHASE_COLISION = {
  en_US: 'Did you purchased a collision insurance with car rental company?',
  pt_BR:
    'Você contratou o seguro de colisão com a empresa de aluguel de automóvel?',
  es_ES:
    '¿Contrató o adquirió un seguro de colisión de la compañía de alquiler de automóviles?',
};

export const LABEL_BENEFITS_PAYABLE = {
  en_US: 'Benefits payable to',
  pt_BR: 'Benefícios a pagar para',
  es_ES: 'Beneficios a pagar para',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const LABEL_AMOUNT_PAID_TRANSPORTATION = {
  en_US: 'Please indicate the amount paid by the transportation company *',
  pt_BR: 'Indique o valor pago pela empresa de transporte *',
  es_ES: 'Indica el monto pagado por la compañía de transporte *',
};

export const LABEL_AMOUNT_PAID_INSURANCE = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const LABEL_CARD_HOLDER = {
  en_US: 'Cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};

export const LABEL_RENTAL_COMPANY = {
  en_US: 'Rental company',
  pt_BR: 'Empresa de aluguel de automóvel',
  es_ES: 'Compañia de alquiler de automóviles',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const LABEL_DAMEGE = {
  en_US: 'Estimated damages *',
  pt_BR: 'Danos estimados *',
  es_ES: 'Daños estimados *',
};

export const TOTAL_PRICE_DIFFERENCE = {
  en_US: 'Total of the price difference *',
  pt_BR: 'Total da diferença de preço *',
  es_ES: 'Total de la diferencia de precio *',
};

export const TEXT_REIMBURSED_OF_TRANSPORTATION = {
  en_US: 'Were you reimbursed for any amount by the transportation company? *',
  pt_BR: 'Você recebeu reembolso da empresa de transporte? *',
  es_ES: '¿Recibiste algún reembolso por parte de la compañía de transporte? *',
};

export const TEXT_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit? *',
  pt_BR: 'Você tem outro seguro que cubra este incidente? *',
  es_ES: '¿Tienes otro seguro que cubra este incidente? *',
};

export const DESCRIBE_REIMBURSED_OF_TRANSPORTATION = {
  en_US: 'Novos campos irão surgir para detalhar o reembolso',
  pt_BR: 'Novos campos irão surgir para detalhar o reembolso',
  es_ES: 'Novos campos irão surgir para detalhar o reembolso',
};

export const TEXT_DESCRIBE_OPTION_YES = {
  en_US:
    'New fields will appear for you to enter the amount covered by the other insurance',
  pt_BR:
    'Novos campos vão aparecer para você informar o valor coberto pelo outro seguro',
  es_ES:
    'Aparecerán nuevos campos para que introduzca el importe cubierto por el otro seguro',
};

export const TEXT_DESCRIBE_OPTION_NO = {
  en_US: 'The value fields covered by other insurance are not necessary',
  pt_BR: 'Os campos de valor coberto por outro seguro não serão necessários',
  es_ES: 'Los campos de valor cubiertos por otros seguros no son necesarios',
};
