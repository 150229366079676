import React, { useEffect, useContext, useState } from 'react';
import { IntlContext } from '../../intl';
import { countryToIdiom } from '../../intl/utils';
import './MessengerChat.module.scss';
import { getCookie } from '../../utils/cookies';
import { dataLayerChatTrack } from '../../utils/GTM_helper';

const createScript = idiom => {
  window.fbAsyncInit = function () {
    window.FB.init({
      xfbml: true,
      version: 'v13.0',
    });
    window.FB.CustomerChat.hide();

    window.FB.Event.subscribe('customerchat.dialogShow', () =>
      dataLayerChatTrack()
    );
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src =
      'https://connect.facebook.net/' + idiom + '/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  })(document, 'script', 'facebook-jssdk');
};

const MessengerChat = () => {
  const [idiom, setIdiom] = useState('');
  const [showMessenger, setShowMessenger] = useState(false);
  const [country, setCountry] = useState('');
  const ID_FACEBOOK = process.env.REACT_APP_ID_FACEBOOK;
  const ATTRIBUTION_FACEBOOK = process.env.ATTRIBUTION_FACEBOOK;

  useEffect(() => {
    const getCountry = getCookie('homeCountry');
    setCountry(getCountry);
    if (country) {
      const idiomByCountry = countryToIdiom(country, 'initials');
      setIdiom(idiomByCountry);
      setShowMessenger(true);
    }
  }, [country, idiom]);

  return (
    <>
      {idiom && showMessenger && (
        <>
          <script type="text/javascript">{createScript(idiom)}</script>
          <div id="fb-root"></div>
          <div
            className="fb-customerchat"
            attribution={ATTRIBUTION_FACEBOOK}
            page_id={ID_FACEBOOK}
            greeting_dialog_display="hide"
          ></div>
        </>
      )}
    </>
  );
};

export default MessengerChat;
