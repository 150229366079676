export const TITLE = {
  en_US: 'Title',
  pt_BR: 'Title',
  es_ES: 'Title',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const MANDATORY_FIELDS = {
  en_US: 'Fields marked with * are mandatory.',
  pt_BR: 'Os campos marcados com * são obrigatórios.',
  es_ES: 'Los campos marcados con * son obligatorios.',
};

export const CLOSE = {
  en_US: 'Close',
  es_ES: 'Cerrar',
  pt_BR: 'Fechar',
};

export const ICON = {
  en_US: 'icon',
  es_ES: 'ícono',
  pt_BR: 'ícone',
};
