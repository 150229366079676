import { useContext, useRef } from 'react';
import styles from './AddPaymentMethod.module.scss';
import OCTForm from '../../../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/OCTForm/OCTForm';
import Button from '../../../../../components/Button/Button';
import { useIntl } from '../../../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';

const AddPaymentMethod = ({ setPaymentID }) => {
  const { actions } = useContext(StateContext);
  const { translate } = useIntl();

  const submitRef = useRef(null);
  const intl = translate(translations);

  const cancel = () => {
    actions.modal.closeModal();
  };
  const submit = () => {
    submitRef.current.click();
  };

  return (
    <>
      <OCTForm
        profileFlow
        reimbursementSubmitRef={submitRef}
        profileSetPaymentID={setPaymentID}
      />
      <hr />
      <div className={`${styles.footer} ${styles.section}`}>
        <Button
          type="borderBlue"
          id="borderBlue-saveDependent"
          onClick={submit}
        >
          {intl.BTN_SAVE}
        </Button>
        <Button type="blueWithoutBorder" onClick={cancel}>
          {intl.BTN_CANCEL}
        </Button>
      </div>
    </>
  );
};

export default AddPaymentMethod;
