const creditIntl = ['Credit', 'Crédito', 'Credito'];
const debitIntl = ['Debit', 'Débito', 'Debito'];
// const prepaidIntl = ['','Pré-pago','PrePago']

export const formatProductType = card => {
  const typeCard = card.type;
  if (!typeCard) {
    return null;
  }

  if (creditIntl.includes(typeCard)) {
    return 'C';
  } else if (debitIntl.includes(typeCard)) {
    return 'D';
  } else {
    return 'P';
  }
};
