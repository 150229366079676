export const TEXT_START_DATE_TRIP = {
  en_US: 'Check in date *',
  es_ES: 'Fecha de entrada *',
  pt_BR: 'Data de entrada *',
};

export const TEXT_END_DATE_TRIP = {
  en_US: 'Check out date *',
  es_ES: 'Fecha de salida *',
  pt_BR: 'Data de saída *',
};

export const TEXT_DATE_LOSS = {
  en_US: 'Date of theft *',
  es_ES: 'Fecha del robo *',
  pt_BR: 'Data do roubo *',
};

export const TEXT_TIME_LOSS = {
  en_US: 'Time of theft *',
  es_ES: 'Hora del robo *',
  pt_BR: 'Hora do roubo *',
};

export const TEXT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Describa el incidente',
  pt_BR: 'Descreva o incidente',
};
