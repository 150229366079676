import * as Yup from 'yup';

export const legalAdviceFormInitialValues = legalAdvice => {
  const is_confirm = legalAdvice[0];
  const is_authorized = legalAdvice[1];
  const is_accord = legalAdvice[2];

  return {
    is_confirm: {
      code: 'legal_consent_I_understand',
      is_confirmed: is_confirm ? is_confirm.is_confirmed : false,
      consented_at: is_confirm ? is_confirm.consented_at : null,
    },
    is_authorized: {
      code: 'legal_consent_I_authorize',
      is_confirmed: is_authorized ? is_authorized.is_confirmed : false,
      consented_at: is_authorized ? is_authorized.consented_at : null,
    },
    is_accord: {
      code: 'legal_consent_I_agree',
      is_confirmed: is_accord ? is_accord.is_confirmed : false,
      consented_at: is_accord ? is_accord.consented_at : null,
    },
  };
};

export const validationSchema = intl =>
  Yup.object().shape({
    is_authorized: Yup.object().shape({
      is_confirmed: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
    }),
    is_confirm: Yup.object().shape({
      is_confirmed: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
    }),
    is_accord: Yup.object().shape({
      is_confirmed: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
    }),
  });
