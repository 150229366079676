export const getSearchQueryLikeOp = (query: string) =>
  `%${query.split(' ').join('%')}%`;

const formatToSearch = (string: string) =>
  string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const searchString = (toBeSearched: string, search: string) => {
  const toBeSearchedFormatted = formatToSearch(toBeSearched);
  const searchFormatted = formatToSearch(search);
  return toBeSearchedFormatted.includes(searchFormatted);
};

export const searchInclusive = (toBeSearched: string, search: string) =>
  search.split(' ').every(word => searchString(toBeSearched, word));

export const highlightStringBySearch = (
  toBeSearched: string,
  search: string,
  htmlTagToHighlight = 'strong'
): string => {
  const toBeSearchedFormatted = formatToSearch(toBeSearched);
  const searchFormatted = formatToSearch(search);

  const indexStartMatch = toBeSearchedFormatted.search(searchFormatted);

  if (indexStartMatch === -1) {
    return toBeSearched;
  }

  const indexEndMatch = indexStartMatch + search.length;

  return [
    String(toBeSearched).slice(0, indexStartMatch),
    `<${htmlTagToHighlight}>`,
    String(toBeSearched).slice(indexStartMatch, indexEndMatch),
    `</${htmlTagToHighlight}>`,
    String(toBeSearched).slice(indexEndMatch),
  ].join('');
};
