import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';

import { corporateFormInitialValues } from './initialValues';
import { corporateLiabilitySchema } from './validationSchema';
import PersonalArea from './PersonalArea/PersonalArea';
import IncidentArea from './IncidentArea/IncidentArea';
import PhoneArea from './PhoneArea/PhoneArea';
import ComplementPersonalArea from './ComplementPersonalArea/ComplementPersonalArea';
import FinancialArea from './FinancialArea/FinancialArea';
import AgenciesArea from './AgenciesArea/AgenciesArea';
import AgenciesPhoneArea from './AgenciesPhoneArea/AgenciesPhoneArea';
import AgenciesComplementArea from './AgenciesComplementArea/AgenciesComplementArea';
import CertificationArea from './CertificationArea/CertificationArea';

const CorporateLiabilityWaiver = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    parentValues,
    updateParent,
    incidentSubmitRef,
    prefixOptions,
    loadedData,
  } = props;

  const validationSchema = useMemo(
    () => corporateLiabilitySchema(intl),
    [intl]
  );
  const initialValues = useMemo(
    () => corporateFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy, loadedData]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <ComplementPersonalArea {...props} />
              <PhoneArea {...props} prefixOptions={prefixOptions} />
              <FinancialArea {...props} />
              <IncidentArea {...props} />
              <AgenciesArea {...props} />
              <AgenciesPhoneArea {...props} prefixOptions={prefixOptions} />
              <AgenciesComplementArea {...props} />
              <CertificationArea {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default CorporateLiabilityWaiver;
