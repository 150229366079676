export default (env, axios, errorHandler) => ({
  createCotizar: async (data, formType = 'es_co') => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/cotizar/create';
    return errorHandler(axios.post(url, dataSubmit), 'postCreateCotizar', {
      url,
      data,
      formType,
    });
  },
  cotizarCoutasPayment: async (formType = 'es_ar', data) => {
    const dataSubmit = {
      data,
      formType,
    };
    const url = '/cotizar/coutas';
    return axios.post(url, dataSubmit);
  },
});
