/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './Products.module.scss';
import rectangle from '../../../assets/images/BlueRectangle.png';
import ProductsCarousel from './productsCarousel/ProductsCarousel';
import { IntlContext } from '../../../intl/index';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import Loading from '../../../components/Loading/Loading';
import { formatProductType } from './formatProductType';
import ProductCategory from './productCategory/ProductCategory';
import { ALL } from './const_product_category';
import ArrowRightIcon from '../../../assets/icons/ArrowRight';
import ALink from '../../../components/ALink';
import Link from '../../../componentsV2/Link/Link';

const getImage = images => {
  let findImage = images && images.find(image => image.type === 'PICTURE');
  return findImage && findImage.content_url.length > 0
    ? findImage.content_url
    : rectangle;
};

const filterBenefits = (cards, productCategory) => {
  return cards.filter(card => card.client_type === productCategory);
};

const Products = props => {
  const { cards, cardSelected, setCardSelected, loadingCards, indexSelected } =
    props;

  const [productCategory, setProductCategory] = useState('All');
  const [productsByCategory, setProductsByCategory] = useState('');
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const cardType = formatProductType(cardSelected || {});
  useEffect(() => {
    if (productCategory === ALL || (cards && cards.length === 0)) {
      return setProductsByCategory(cards);
    }
    const fitlerBenefitsByCategories = filterBenefits(cards, productCategory);
    setProductsByCategory(fitlerBenefitsByCategories);
    setCardSelected(fitlerBenefitsByCategories[0] || {});
  }, [productCategory, cards]);

  return (
    <div className={`${styles[theme]} ${styles.containerProducts}`}>
      {loadingCards ? (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      ) : (
        <>
          <div className={`${styles[theme]} ${styles.selectedProduct}`}>
            <img
              src={getImage(cardSelected && cardSelected.images)}
              alt={intl.SELECTED_CARD}
            />
            <div className={styles.productInfo}>
              <span className={`${styles[theme]} ${styles.subtitle}`}>
                {cardType === 'C'
                  ? intl.CREDIT_CARD
                  : cardType === 'D'
                  ? intl.DEBIT_CARD
                  : intl.PREPAID_CARD}
              </span>
              <h1 className={styles.title}>
                {intl.TITLE_CARD_NAME(
                  cardSelected && cardSelected.name ? cardSelected.name : ''
                )}
              </h1>
              <p className={`${styles[theme]} ${styles.description}`}>
                {cardSelected &&
                cardSelected.description &&
                cardSelected.description.length > 0
                  ? cardSelected.description
                  : intl.PRODUCT_DESCRIPTION}
              </p>
              <div className={`${styles.linkArea}`}>
                <Link
                  href={intl.LINK_GET_THIS_CARD}
                  label={intl.GET_THIS_CARD}
                />
              </div>
            </div>
          </div>

          <div className={styles.carouselArea}>
            <ProductCategory
              productCategory={productCategory}
              setProductCategory={setProductCategory}
            />
            {productsByCategory && productsByCategory.length > 0 && (
              <ProductsCarousel
                cards={productsByCategory}
                cardSelected={cardSelected}
                setCardSelected={setCardSelected}
                indexSelected={indexSelected}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Products;
