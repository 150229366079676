import React, { useContext, useMemo, useEffect } from 'react';
import { FieldArray, Formik } from 'formik';

import * as translations from './intl';
import { validationSchema } from './schema';
import { getInitialValues } from './initialValues';
import { IntlContext } from '../../../../../intl';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import Input from '../../../../../components/Input/Input';
import Calendar from '../../../../../components/Calendar/Calendar';
import PhoneForm from '../../../../../components/PhoneForm';
import { differenceInYears } from 'date-fns';
import { pt_BR } from '../../../../../intl/idioms';
import { mask4FirstDigits } from '../../../../../utils/stringUtils';
import GroupDivisor from '../../../../../components/GroupDivisor';

const AdditionalTravelers = props => {
  const { submitRef, updateParent, dependents, isCardHolder } = props;
  const { translate, idiom } = useContext(IntlContext);
  const { utils, actions } = useContext(B2B2CContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const themes = getGlobalTheme();

  let quote = utils.getQuote();
  const Quote = quote && quote.Quote ? quote.Quote : {};

  const schema = useMemo(() => validationSchema(intl), [intl]);
  const initialValues = useMemo(
    () =>
      getInitialValues(
        Quote,
        dependents,
        isCardHolder,
        idiom,
        utils.getPassenger()
      ),
    [dependents, isCardHolder]
  );

  useEffect(() => {
    if (isCardHolder === false) {
      actions.formDataCotizar.setPassengers(null);
    }
  }, [isCardHolder]);

  const saveInSession = values => {
    const newValues = JSON.parse(JSON.stringify(values));
    actions.formDataCotizar.setPassengers(newValues.Passenger);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={values => saveInSession(values)}
    >
      {props => {
        const {
          values,
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          initialValues,
          setFieldTouched,
          error,
          touched,
        } = props;
        updateParent(values, schema);

        const checkSameRegistrationValue = index => {
          if (
            values.Passenger[index].person_registrations.registration_type ===
              initialValues.Passenger[index].person_registrations
                .registration_type &&
            initialValues.Passenger[index].person_registrations.value !== ''
          ) {
            return (
              values.Passenger[index].person_registrations.value ===
              initialValues.Passenger[index].person_registrations.value
            );
          } else {
            return false;
          }
        };

        const getInitialRegistrationValue = index => {
          if (
            values.Passenger[index].person_registrations.registration_type ===
            initialValues.Passenger[index].person_registrations
              .registration_type
          ) {
            return initialValues.Passenger[index].person_registrations.value;
          } else {
            return '';
          }
        };

        return (
          <form onSubmit={handleSubmit}>
            <button ref={submitRef} type="submit" style={{ display: 'none' }} />
            {values.Passenger.length > 0 ? (
              <FieldArray
                name="Passenger"
                render={() => (
                  <div>
                    {values.Passenger.map((_, index) => {
                      const calculateDate = birthDate => {
                        if (birthDate) {
                          const age = differenceInYears(new Date(), birthDate);
                          setFieldValue(`Passenger[${index}].Age`, String(age));
                        }
                      };

                      return (
                        <div key={index} className="row">
                          <div className="col-12">
                            <GroupDivisor
                              title={intl.TITLE_PASSENGER(index + 1)}
                              axa
                            />
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <Input
                              formikProps={props}
                              label={intl.LABEL_NAME}
                              name={`Passenger[${[index]}].Name`}
                              type="text"
                              dataTestid="PassengerFirstName"
                              onBlur={() => {
                                saveInSession(values);
                                handleBlur();
                              }}
                              axa
                              maxLength="40"
                              mask="maskedOnlyLetters"
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <Input
                              formikProps={props}
                              label={intl.LABEL_LAST_NAME}
                              name={`Passenger[${[index]}].LastName`}
                              type="text"
                              dataTestid="PassengerLastName"
                              onBlur={() => {
                                saveInSession(values);
                                handleBlur();
                              }}
                              axa
                              maxLength="80"
                              mask="maskedOnlyLetters"
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                            <Input
                              label={intl.LABEL_REGISTRATIONS_TYPE}
                              name={`Passenger[${[
                                index,
                              ]}].person_registrations.value`}
                              value={
                                checkSameRegistrationValue(index)
                                  ? mask4FirstDigits(
                                      values.Passenger[index]
                                        .person_registrations.value
                                    )
                                  : values.Passenger[index].person_registrations
                                      .value
                              }
                              mask={
                                idiom === pt_BR
                                  ? checkSameRegistrationValue(index)
                                    ? 'maskedCPFWithoutFirst4'
                                    : 'maskedCPF'
                                  : checkSameRegistrationValue(index)
                                  ? 'maskedPassaportWithoutFirst4'
                                  : 'maskedPassaport'
                              }
                              type="text"
                              tooltipText={intl.TEXT_ALERT_PASSPORT}
                              onChange={handleChange}
                              onBlur={e => {
                                handleBlur();
                                saveInSession(values);

                                if (e.target.value === '') {
                                  setFieldValue(
                                    `Passenger[${index}].person_registrations.value`,
                                    getInitialRegistrationValue(index)
                                  );
                                  setFieldTouched(
                                    `Passenger[${index}].person_registrations.value`,
                                    true
                                  );
                                }
                              }}
                              onFocus={e => {
                                if (checkSameRegistrationValue(index)) {
                                  setFieldValue(
                                    `Passenger[${index}].person_registrations.value`,
                                    ''
                                  );
                                  setFieldTouched(
                                    `Passenger[${index}].person_registrations.value`,
                                    true
                                  );
                                }
                              }}
                              error={
                                error &&
                                error.Passenger &&
                                error.Passenger[index] &&
                                error.Passenger[index].person_registrations &&
                                error.Passenger[index].person_registrations
                                  .value
                              }
                              touched={
                                touched &&
                                touched.Passenger &&
                                touched.Passenger[index] &&
                                touched.Passenger[index].person_registrations &&
                                touched.Passenger[index].person_registrations
                                  .value
                              }
                              axa
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                            <Calendar
                              formikProps={props}
                              label={intl.LABEL_BIRTH_DATE}
                              name={`Passenger[${[index]}].DateOfBirth`}
                              maxDate={new Date()}
                              dataTestid="PassengerBirthDate"
                              onChange={event => {
                                if (event.target && event.target.value) {
                                  calculateDate(event.target.value);
                                }
                                handleChange(event);
                                saveInSession(values);
                              }}
                              axa
                            />
                          </div>
                          <div className="col-12 col-sm-12 col-md-2 col-lg-2">
                            <Input
                              value={values.Passenger[index].Age}
                              label={intl.LABEL_AGE}
                              name={`Passenger[${[index]}].Age`}
                              type="text"
                              disabled={true}
                              axa
                            />
                          </div>
                          <div className="col-12">
                            <PhoneForm
                              formikProps={props}
                              axa
                              inputNames={{
                                phoneType: `Passenger[${[
                                  index,
                                ]}].phone.phone_type`,
                                countryCode: `Passenger[${[
                                  index,
                                ]}].phone.international_prefix`,
                                phoneNumber: `Passenger[${[
                                  index,
                                ]}].phone.number`,
                              }}
                              testIds={{
                                phoneType: 'InputClaimCHPhoneType',
                                countryCode: 'InputClaiCHPrefix',
                                phoneNumber: 'InputClaiCHNumber',
                              }}
                              onFinishEditingField={() => {
                                saveInSession(values);
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              />
            ) : (
              <div className="col-12">
                <h3>{intl.TITLE_NO_ADDITIONAL_TRAVELERS}</h3>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default AdditionalTravelers;
