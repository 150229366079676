import React, { useContext } from 'react';
import styles from './QuoteBanner.module.scss';
import * as translations from './intl';
import Button from '../../../../components/Button/Button';
import doctor from '../../../../assets/images/B2B2C/AXADoctor.png';
import { IntlContext } from '../../../../intl';
import { useHistory } from 'react-router';

const QuoteBanner = props => {
  const { benefitDetail } = props;
  const { translate } = useContext(IntlContext);
  const history = useHistory();

  const intl = translate(translations);

  return (
    <div className={styles.container}>
      <div className={styles.textArea}>
        <h2>{intl.SAFER_TRIP}</h2>
        <div className={styles.wrapButton}>
          <Button
            type="axaWhiteBorder"
            onClick={() => {
              history.push(
                `/cross-sell-benefit/${benefitDetail.external_reference}`
              );
            }}
          >
            {intl.QUOTE_AXA}
          </Button>
        </div>
      </div>
      <div className={styles.imageArea}>
        <img src={doctor} alt="Doctor" />
      </div>
    </div>
  );
};

export default QuoteBanner;
