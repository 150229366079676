import { useContext } from 'react';
import Button from '../../../../../../componentsV2/Button/Button';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import styles from '../Plans.module.scss';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const PlanBtnSelect = props => {
  const { product, intl, closeModal, currentPlan, selected } = props;
  const { actions: actionsB2C } = useContext(B2B2CContext);
  const { actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const selectPlan = async () => {
    trackEventUserAction(`#### (B2B2C) SELECIONOU UM PLANO`, {
      product,
    });
    actionsB2C.formDataCotizar.setSelectedPlan({
      ...product,
    });

    if (closeModal) {
      actions.modal.closeModal();
    }
    trackEventUserAction(`#### (B2B2C) INDO PARA O STEP 3`, null);
  };

  return (
    <div className={styles.btnSelectedPlan}>
      {!currentPlan && (
        <Button
          type={selected ? 'Primary' : 'Secondary'}
          onClick={selectPlan}
          label={intl.BUY_NOW}
          axa
        />
      )}
    </div>
  );
};

export default PlanBtnSelect;
