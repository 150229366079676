import React from 'react';
import PersonalArea from './PersonalArea/PersonalArea';
import PeriodBaggageLossArea from './PeriodBaggageLossArea/PeriodBaggageLossArea';
import PlaceLossArea from './PlaceLossArea/PlaceLossArea';
import TransportationArea from './TransportationArea/TransportationArea';
import InsuranceArea from './InsuranceArea/InsuranceArea';
import TotalAmountArea from './TotalAmountArea/TotalAmountArea';

const BaggageLoss = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <PeriodBaggageLossArea claimDetails={claimDetails} />
      <PlaceLossArea claimDetails={claimDetails} />
      <TransportationArea claimDetails={claimDetails} />
      <InsuranceArea claimDetails={claimDetails} />
      <TotalAmountArea claimDetails={claimDetails} />
    </div>
  );
};

export default BaggageLoss;
