import {
  COUNTRY_WITHOUT_ACCESS_APP,
  ENABLE_DOWNLOAD_APP_PAGE,
} from './environments_variables';

export const checkAccessDownloadApp = country => {
  return (
    ENABLE_DOWNLOAD_APP_PAGE && !COUNTRY_WITHOUT_ACCESS_APP.includes(country)
  );
};
