/* eslint-disable no-unused-vars */
const moduleBB = sumSeq => {
  var result = 11 - (sumSeq % 11);
  if (result === 10) {
    return 'X';
  } else {
    if (result === 11) {
      return '0';
    } else {
      return result.toString();
    }
  }
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 10;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx-x
 * Account format: xxxxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (code.length !== accountNumberLength()) return false;
    let arrayCode = code.split('-');
    let numbers = arrayCode[0].split('');
    let dv = arrayCode[1];

    let sum = 0;
    let index = 9;
    numbers.forEach(n => {
      sum += parseInt(n) * index;
      index--;
    });
    const calculatedNumber = moduleBB(sum);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  /*agency: (code) => {
    if (code.length !== agencyNumberLength()) return false;
    let arrayCode = code.split("-");
    let numbers = arrayCode[0].split("");
    let dv = arrayCode[1];

    let sum = 0;
    let index = 5;
    numbers.forEach((n) => {
      sum += parseInt(n) * index;
      index--;
    });
    const calculatedNumber = moduleBB(sum);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },*/
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskBBBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskBBAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '00000000-0';
  },
});
