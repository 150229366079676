export const LABEL_CHOOSE_TELECOMUNICATION_TYPE = {
  en_US: 'Choose the type of service you want',
  pt_BR: 'Escolha o tipo de agendamento que deseja',
  es_ES: 'Elige el tipo de servicio que deseas',
};

export const TEXT_PHONE_CALL = {
  en_US: 'Phone call',
  es_ES: 'Llamada telefónica',
  pt_BR: 'Chamada telefônica',
};

export const TEXT_VIDEO_CALL = {
  en_US: 'Video call',
  es_ES: 'Videollamada',
  pt_BR: 'Video chamada',
};
