import React, { useState, useEffect, useContext } from 'react';
import StepClaim from '../../StepClaim/StepClaim';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import Heading from '../../../../components/Heading/Heading';
import LoadingError from '../../../../components/LoadingError/LoadingError';
import styles from './NurseLineFormFill.module.scss';
import FormNAL from './Form/FormNAL';
import LoadingCircle from '../../../../components/Loading/Loading';
import { loadData } from './loadData';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { loadConfigAppointmentNAL } from './configNAL';

const NurseLineFormFill = props => {
  const [data, setData] = useState('LOADING');
  const [configAppointment, setConfigAppointment] = useState('LOADING');
  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const cn = utils.getCn();
  const intl = translate(translations);

  const stepNames = [intl.TEXT_STEP_1, intl.TEXT_STEP_2];

  useEffect(() => {
    loadData(cn, setData);
  }, []); // eslint-disable-line

  useEffect(() => {
    loadConfigAppointmentNAL(setConfigAppointment);
  }, []); // eslint-disable-line

  const isLoading = data === 'LOADING' || configAppointment === 'LOADING';
  const isError = data === 'ERROR' || configAppointment === 'ERROR';
  const successLoad = !isLoading && !isError;
  return (
    <div>
      <StepClaim currentStep={2} stepNames={stepNames} />
      {isLoading && (
        <div className={styles.container}>
          <Heading>
            <LoadingCircle dataTestId="claimTermsAndConditionsLoading" />
          </Heading>
        </div>
      )}
      {isError && (
        <div className={styles.container}>
          <Heading>
            <LoadingError dataTestId="claimTermsAndConditionsError" />
          </Heading>
        </div>
      )}
      {successLoad && (
        <FormNAL
          configAppointment={configAppointment}
          loadedData={data}
          acceptChecked={props.acceptChecked}
          setShowForm={props.setShowForm}
        />
      )}
    </div>
  );
};

export default NurseLineFormFill;
