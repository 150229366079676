export const LABEL_BENEFICIARY_IS_CH = {
  en_US: 'Is the cardholder a traveler?',
  pt_BR: 'O titular do cartão é um dos viajantes?',
  es_ES: '¿El titular de la tarjeta es uno de los viajeros?',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
