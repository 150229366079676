import { useContext, useState } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../../../../intl';
import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as PurchaseProtectionUtils from '../../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import GroupDivisor from '../../../../../../../components/GroupDivisor';

const PurchasedItem = props => {
  const { item, itemNumber } = props;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [covering, setCovering] = useState({});

  useState(() => {
    const covering =
      item && item.other_insurance_covering && item.other_insurance_covering[0]
        ? item.other_insurance_covering[0]
        : {};
    setCovering(covering);
  });

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={`${intl.TEXT_ITEM}${itemNumber}`} simpleLine />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_DATE}
          value={PurchaseProtectionUtils.getItemPurchaseDate(
            item,
            idiom,
            country
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ITEM_PURCHASED}
          value={PurchaseProtectionUtils.getItemName(item)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={PurchaseProtectionUtils.getItemManufacturer(item)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_MODEL}
          value={PurchaseProtectionUtils.getItemModel(item)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_PRICE}
          value={PurchaseProtectionUtils.getItemPurchasePrice(
            item,
            idiomForApi()
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT}
          value={PurchaseProtectionUtils.getItemPurchaseTotalInvoiceAmount(
            item,
            idiomForApi()
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TYPE_OF_LOSS}
          value={PurchaseProtectionUtils.getItemTypeOfLoss(item, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PAYMENT_METHOD}
          value={PurchaseProtectionUtils.getItemPaymentMethod(item, intl)}
        />
      </div>
      {item && item.number_of_installments > 0 && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.INSTALLMENTS_NUMBER}
              value={PurchaseProtectionUtils.getItemInstallment(item, intl)}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.INSTALLMENTS_PRICE}
              value={PurchaseProtectionUtils.getItemInstallmentPrice(
                item,
                intl
              )}
            />
          </div>
        </>
      )}
      {item && item.status === 'Damage' && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_DAMAGE_REPAIRED}
              value={PurchaseProtectionUtils.getIsItemReparable(item, intl)}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_DAMAGE_VISIBLE}
              value={PurchaseProtectionUtils.getIsItemDamageVisible(item, intl)}
            />
          </div>
        </>
      )}
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_AMOUNT_PAID}
          value={PurchaseProtectionUtils.getOtherInsuranceCovering(
            covering,
            idiomForApi()
          )}
        />
      </div>
    </>
  );
};

export default PurchasedItem;
