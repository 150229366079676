import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const TravelKitIcon = props => {
  const { white, darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : white
    ? config_icon.white
    : config_icon.secondary;
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : white
    ? config_icon.white
    : config_icon.secondary;

  return (
    <svg
      width={67}
      height={67}
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.0861 58.8214C53.7328 58.8214 52.4852 58.039 51.872 56.8126L47.4737 47.8469L50.7513 46.2398L55.1496 55.2267L58.1734 52.0548C59.3787 50.8284 60.0553 49.2425 60.0553 47.5297C60.0553 46.7896 59.9285 46.0495 59.6747 45.3517L53.183 27.526C52.9081 26.7647 52.7601 25.9612 52.7601 25.1577C52.7601 23.6352 53.2676 22.1127 54.2192 20.9074L61.5144 11.4976C62.1276 10.7152 62.4659 9.72138 62.4659 8.72753C62.4659 6.23235 60.436 4.20238 57.9408 4.20238C56.9469 4.20238 55.9531 4.54071 55.1707 5.15393L45.7609 12.4492C43.9001 13.8871 41.3204 14.2888 39.1424 13.4853L32.4392 11.0324L33.6868 7.60682L40.39 10.0597C41.4261 10.4403 42.6525 10.25 43.5406 9.57336L52.9504 2.27813C54.3672 1.15741 56.1434 0.565338 57.9408 0.565338C62.4448 0.565338 66.103 4.22352 66.103 8.72753C66.103 10.5249 65.5109 12.3011 64.3902 13.739L57.095 23.1488C56.6298 23.7409 56.3972 24.4387 56.3972 25.1788C56.3972 25.5594 56.4606 25.94 56.5875 26.2995L63.0792 44.1252C63.4809 45.246 63.6712 46.3878 63.6712 47.5508C63.6712 50.2363 62.6351 52.7526 60.732 54.6557L57.5813 57.8064C56.9258 58.4831 56.0377 58.8426 55.0861 58.8426V58.8214Z"
        fill={secondary}
      />
      <path
        d="M25.1862 66.0956C23.0505 66.0956 21.232 64.6577 20.7668 62.5854L17.7218 49.4117C17.6584 49.2002 17.4892 49.0099 17.2566 48.9465L4.08292 45.9015C2.01065 45.4363 0.572754 43.5967 0.572754 41.4821C0.572754 39.9385 1.334 38.5217 2.64502 37.6759L4.54812 36.4495C5.24593 35.9843 6.04946 35.7516 6.85299 35.7516H17.172C17.3412 35.7516 17.4892 35.6882 17.6161 35.5613L29.0559 24.1216L9.85567 14.7541C8.62922 14.162 7.84684 12.8933 7.84684 11.5399C7.84684 10.5884 8.20631 9.70028 8.88297 9.04477L12.0337 5.89408C14.7614 3.1663 18.9271 2.23589 22.5853 3.56806L30.2823 6.38043L29.0347 9.80601L21.3377 6.99365C19.054 6.14782 16.3474 6.78219 14.6134 8.49498L11.4627 11.6457L31.8471 21.4361C32.7775 21.9013 33.3484 22.8105 33.3484 23.8678C33.3484 24.5868 33.0524 25.2423 32.566 25.7709L20.1747 38.1622C19.3923 38.9446 18.2716 39.4098 17.172 39.4098H6.83185C6.72612 39.4098 6.59925 39.4521 6.49352 39.4944L4.59042 40.742C4.33667 40.9112 4.1675 41.2072 4.1675 41.5033C4.1675 41.9262 4.46354 42.2856 4.86531 42.3702L18.039 45.4152C19.6038 45.7747 20.8514 47.0434 21.232 48.6082L24.277 61.7819C24.4461 62.5008 25.5245 62.67 25.9263 62.0779L27.1739 60.1748C27.1739 60.1748 27.2585 59.9633 27.2585 59.8576V49.5386C27.2585 48.4179 27.7237 47.3183 28.5061 46.5359L40.8974 34.1446C41.4049 33.6371 42.0815 33.3622 42.7793 33.3622C43.7943 33.3622 44.767 33.9543 45.2111 34.8635L49.2076 42.9834L45.93 44.5905L42.5256 37.6548L31.0647 49.1157C30.959 49.2214 30.8744 49.3906 30.8744 49.5597V59.8788C30.8744 60.7034 30.6418 61.507 30.1766 62.1836L28.929 64.0867C28.0832 65.3766 26.6664 66.1379 25.1228 66.1379L25.1862 66.0744V66.0956Z"
        fill={primary}
      />
    </svg>
  );
};

export default TravelKitIcon;
