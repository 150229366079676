export const LABEL_DRIVER_NAME = {
  en_US: 'Driver name / last name',
  pt_BR: 'Nome / sobrenome do condutor',
  es_ES: 'Nombre / apellido del conductor',
};

export const LABEL_AGENCY_NAME = {
  en_US: 'Car rental company',
  pt_BR: 'Empresa de aluguel de automóvel',
  es_ES: 'Empresa de la compañia de alquiler de automóviles',
};

export const LABEL_RENTAL_PERIOD = {
  en_US: 'Rental period',
  pt_BR: 'Período de aluguel',
  es_ES: 'Periodo de alquiler',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const LABEL_INCIDENT_LOCATION = {
  en_US: 'Incident location',
  pt_BR: 'Localização do incidente',
  es_ES: 'Lugar del incidente',
};

export const LABEL_BENEFITS_PAYABLE = {
  en_US: 'Benefits payable to',
  pt_BR: 'Benefícios a pagar para',
  es_ES: 'Beneficios a pagar para',
};

export const LABEL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};

export const LABEL_CARD_HOLDER = {
  en_US: 'Cardholder',
  pt_BR: 'Titular do cartão',
  es_ES: 'Titular de la tarjeta',
};

export const LABEL_RENTAL_COMPANY = {
  en_US: 'Rental company',
  pt_BR: 'Empresa de aluguel de automóvel',
  es_ES: 'Compañia de alquiler de automóviles',
};
