import moment from 'moment';

export const fraudulentChargesToApi = props => {
  let object = {};

  object.incident = incident_toApi(props);
  object.affected_person = affected_personToApi(props);
  object.other_insurance_covering = other_insurance_coveringToApi(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const incident_toApi = props => {
  const incident = props.values.incident.incident;
  const bankDate = incident.bank_declaration_date;
  return {
    date: incident.date,
    bank_declaration_date: moment(bankDate).format('YYYY-MM-DD'),
    kind: incident.kind,
    description: incident.description,
    expenses: expenses_toApi(props),
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const other_insurance_coveringToApi = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const charge_information = props.values.incident.incident.expenses[0];

  return [
    {
      is_internal: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      description: '',
      amount: {
        value: Number(other_insurance_covering.value),
        currency: charge_information.amount.currency,
      },
    },
  ];
};

const total_amount_claimedToApi = props => {
  let charges = props.values.incident.incident.expenses;
  let amountClaimed = 0;
  charges.map(item => {
    amountClaimed += Number(item.amount.value);
  });

  const currency = props.values.incident.incident.expenses[0].amount.currency;

  return {
    value: amountClaimed,
    currency,
  };
};

const expenses_toApi = props => {
  const expenses = props.values.incident.incident.expenses;
  expenses.map(item => {
    item.amount.currency =
      props.values.incident.incident.expenses[0].amount.currency;
    item.amount.value = Number(
      props.values.incident.incident.expenses[0].amount.value
    );

    delete item.date;
  });
  return expenses;
};
