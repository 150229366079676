import { pt_nationalities } from '../../../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../../../intl/es_nationalities';

export const TITLE_AREA = {
  en_US: 'Type of expenses for which you are requesting reimbursement',
  pt_BR: 'Tipo de gastos para os quais solicita reembolso',
  es_ES: 'Tipo de gastos por los que solicita reembolso',
};

export const LABEL_TYPE = {
  en_US: 'Type *',
  pt_BR: 'Tipo *',
  es_ES: 'Tipo *',
};

export const TITLE_QUESTION = {
  en_US: 'Do you have additional passengers to include on this same claim?',
  pt_BR:
    'Existe passageiros adicionais que gostaria de incluir nesse mesmo sinistro?',
  es_ES: 'Hay pasajeros adicionales que deseas incluir en este reclamo?',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const BTN_ADD_ADDITIONAL = {
  en_US: 'Add passenger',
  pt_BR: 'Adicionar passageiro',
  es_ES: 'Añadir otro pasajero',
};

export const SUBTITLE_AREA = {
  en_US: 'Passenger',
  pt_BR: 'Passageiro(a)',
  es_ES: 'Pasajero(a)',
};

export const LABEL_NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const LABEL_GENDER = {
  en_US: 'Gender',
  pt_BR: 'Gênero',
  es_ES: 'Género',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

/*
 NATIONALITIES AND COUTRIES
*/
export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder *',
  pt_BR: 'Relação com o titular do cartão *',
  es_ES: 'Relación con el titular de la tarjeta *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const DESCRIPTION_HAS_ADDITIONAL_YES = {
  en_US:
    'The personal data fields for each additional traveler will be entered on the form',
  pt_BR:
    'Os campos de dados pessoais de cada viajante adicional vão ser inseridos no formulário',
  es_ES:
    'Los campos de datos personales de cada viajero adicional se introducirán en el formulario',
};

export const DESCRIPTION_HAS_ADDITIONAL_NO = {
  en_US: `The additional travelers' personal data fields will not be necessary`,
  pt_BR:
    'Os campos de dados pessoais dos viajantes adicionais não serão necessários',
  es_ES:
    'Los campos adicionales de datos personales de los viajeros no son obligatorios',
};
