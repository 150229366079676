import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './IncidentArea.module.scss';
import { safeGet } from '../../../../../../../../utils/object';
import Select from '../../../../../../../../components/Select/Select';
import { pt_BR } from '../../../../../../../../intl/idioms';

let purchasePrice = 0;
let advertisedPrice = 0;

const IncidentArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;

  const sumAmountEstimationDamageItem = e => {
    if (e.target.name === 'purchased_item.price.value') {
      purchasePrice = e.target.value;
    }
    if (e.target.name === 'advertised_price.price.value') {
      advertisedPrice = e.target.value;
    }

    let result = Number(purchasePrice) - Number(advertisedPrice);
    if (result < 0) result = 0;
    result = result.toFixed(2);
    setFieldValue('amount_estimation_damage.value', result);
    handleChange(e);
  };

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-12">
        <Input
          value={values.purchased_item.label}
          label={intl.ITEM_PURCHASED}
          name="purchased_item.label"
          dataTestid="InputPurchasedItem"
          type="text"
          onChange={handleChange}
          touched={safeGet(touched, 'purchased_item.label')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchased_item.label')}
          maxLength="100"
          tooltipText={intl.PRODUCTS_ELEGIBILITY}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchased_item.manufacturer}
          label={intl.MANUFACTURER}
          name="purchased_item.manufacturer"
          dataTestid="InputPurchasedManufacture"
          type="text"
          onChange={handleChange}
          touched={safeGet(touched, 'purchased_item.manufacturer')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchased_item.manufacturer')}
          maxLength="100"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchased_item.model_number}
          label={intl.MODEL}
          name="purchased_item.model_number"
          dataTestid="InputPurchasedModel"
          type="text"
          onChange={handleChange}
          touched={safeGet(touched, 'purchased_item.model_number')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchased_item.model_number')}
          maxLength="50"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.advertised_price.retail_name}
          label={intl.NAME_STORE_WEBSITE}
          name="advertised_price.retail_name"
          dataTestid="InputAdvertiseRetailName"
          type="text"
          onChange={handleChange}
          touched={safeGet(touched, 'advertised_price.retail_name')}
          onBlur={handleBlur}
          error={safeGet(errors, 'advertised_price.retail_name')}
          maxLength="100"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.advertised_price.publishing_location}
          label={intl.ADVERTISEMENT_LOCATION}
          name="advertised_price.publishing_location"
          dataTestid="InputAdvertiseLocation"
          type="text"
          onChange={handleChange}
          touched={safeGet(touched, 'advertised_price.publishing_location')}
          onBlur={handleBlur}
          error={safeGet(errors, 'advertised_price.publishing_location')}
          textTooBig
          maxLength="20"
        />
      </div>

      <div className="col-12 col-sm-12">
        <Input
          value={values.purchased_item.retailer}
          label={intl.RETAILER_NAME}
          name="purchased_item.retailer"
          error={safeGet(errors, 'purchased_item.retailer')}
          touched={safeGet(touched, 'purchased_item.retailer')}
          onBlur={handleBlur}
          onChange={handleChange}
          maxLength="100"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.PAYMENT_METHOD}
          name="purchased_item.paymentMethod"
          value={values.purchased_item.paymentMethod}
          dangerouslySetInnerHTML={{
            __html: `<option value="">${intl.PAYMENT_METHOD}</option>
            <option value="cash">${intl.METHOD_CASH}</option>
            <option value="installment">${intl.METHOD_FINANCING}</option>`,
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          touched={safeGet(touched, 'purchased_item.paymentMethod')}
          error={safeGet(errors, 'purchased_item.paymentMethod')}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchased_item.total_invoice_amount.value}
          label={intl.VISA_INVOICE_AMOUNT}
          name="purchased_item.total_invoice_amount.value"
          dataTestid="InputPurchasedTotalInvoice"
          type="text"
          maskedMoney
          onChange={handleChange}
          touched={safeGet(
            touched,
            'purchased_item.total_invoice_amount.value'
          )}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchased_item.total_invoice_amount.value')}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchased_item.price.value}
          label={intl.AMOUNT_INVOICE}
          name="purchased_item.price.value"
          dataTestid="InputPurchasedValue"
          type="text"
          maskedMoney
          tooltipText={idiom === pt_BR ? intl.DISCLAIMER_INVOICE : null}
          onChange={sumAmountEstimationDamageItem}
          touched={safeGet(touched, 'purchased_item.price.value')}
          onBlur={handleBlur}
          error={safeGet(errors, 'purchased_item.price.value')}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.advertised_price.price.value}
          label={intl.NEW_ADVERTISE_PRICE}
          name="advertised_price.price.value"
          dataTestid="InputAdvertiseValue"
          type="text"
          maskedMoney
          onChange={sumAmountEstimationDamageItem}
          touched={safeGet(touched, 'advertised_price.price.value')}
          onBlur={handleBlur}
          error={safeGet(errors, 'advertised_price.price.value')}
        />
      </div>

      {values.purchased_item.paymentMethod === 'installment' && (
        <>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.INSTALLMENTS_NUMBER}
              name="purchased_item.installments_number"
              value={values.purchased_item.installments_number}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={safeGet(touched, 'purchased_item.installments_number')}
              error={safeGet(errors, 'purchased_item.installments_number')}
            >
              <option value="">{intl.INSTALLMENTS_NUMBER}</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">{intl.SIXTEEN_OR_MORE_INSTALLMENTS}</option>
            </Select>
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.INSTALLMENTS_PRICE}
              name="purchased_item.installment_price"
              value={
                (values.purchased_item.installments_number !== '0' &&
                  (
                    Number(values.purchased_item.total_invoice_amount.value) /
                    (Number(values.purchased_item.installments_number) || 1)
                  )
                    .toFixed(2)
                    .toString()) ||
                '0'
              }
              maskedMoney
              type="text"
              disabled
              error={safeGet(errors, 'purchased_item.installment_price')}
              touched={safeGet(touched, 'purchased_item.installment_price')}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default IncidentArea;
