import React, { useContext } from 'react';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import styles from './CheckTerms.module.scss';
import { IntlContext } from '../../../../../intl';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from '../intl';

const CheckTerms = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { acceptChecked, check } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <CheckBox
        onClick={() => check(0)}
        value={acceptChecked[0]}
        css={styles.checkbox}
        dataTestid={'covid-terms-1'}
        name={'covid-terms-1'}
      >
        {intl.TEXT_TERMS_CONDITIONS_CHECKBOX_1_NAL}
      </CheckBox>

      <CheckBox
        onClick={() => check(1)}
        value={acceptChecked[1]}
        css={styles.checkbox}
        dataTestid={'covid-terms-2'}
        name={'covid-terms-2'}
      >
        {intl.TEXT_TERMS_CONDITIONS_CHECKBOX_2_NAL}
      </CheckBox>

      <CheckBox
        onClick={() => check(2)}
        value={acceptChecked[2]}
        css={styles.checkbox}
        dataTestid={'covid-terms-3'}
        name={'covid-terms-3'}
      >
        {intl.TEXT_TERMS_CONDITIONS_CHECKBOX_3_NAL}
      </CheckBox>
    </div>
  );
};

export default CheckTerms;
