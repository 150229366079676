import React, { useContext, useRef, useState } from 'react';
import { useHistory, withRouter } from 'react-router';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import AcordeonSession from '../../../../components/AcordeonSession/AcordeonSession';
import ReasonArea from './ReasonArea/ReasonArea';
import { Formik } from 'formik';
import { getInitialValues } from './initialValues';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { submit } from './submit';
import TermsAndConditions from '../../Generate/TermsAndConditions/TermsAndConditions';
import {
  checkErrorsOnForm,
  openTargetAcordeon,
  setStatusAccordeon,
} from './utils';
import { NotificationContext } from '../../../../components/NotificationContextParent/NotificationContextParent';

const Form = ({ claim, claimType, benefit }) => {
  const history = useHistory();
  const { actions, utils } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const [termsAccept, setTermsAccept] = useState(false);
  const [schemas] = useState({});
  const [values] = useState({});
  const [listAccordeon, setListAccordeon] = useState({
    appealInformation: true,
    termsAndConditions: false,
  });

  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const bin = utils.getBin();

  const accordeonRefs = {
    appealInformation: useRef(null),
    termsAndConditions: useRef(null),
  };

  const submitRefs = {
    appealInformation: useRef(null),
    termsAndConditions: useRef(null),
  };

  return (
    <Formik
      initialValues={getInitialValues(claim)}
      onSubmit={async (values, actionsFormik) => {
        const areFormsOk = await checkErrorsOnForm(values, intl);
        if (areFormsOk) {
          submit(
            claim,
            values,
            intl,
            actions,
            history,
            bin,
            idiom,
            notificationActions
          );
        } else {
          openTargetAcordeon(
            'termsAndConditions',
            'appealInformation',
            false,
            accordeonRefs,
            schemas,
            values,
            submitRefs
          );
          actionsFormik.setFieldError('comment', intl.TEXT_FIELD_REQUIRED);
        }
      }}
    >
      {formikProps => (
        <div id="mainContent">
          <AcordeonSession
            title={intl.CLAIM_APPEAL}
            id="appealInformation"
            accordeon="appealInformation"
            next={() =>
              openTargetAcordeon(
                'appealInformation',
                'termsAndConditions',
                false,
                accordeonRefs,
                schemas,
                values,
                submitRefs
              )
            }
            openButtonRef={accordeonRefs.appealInformation}
            status={listAccordeon.appealInformation}
            setStatus={() =>
              setStatusAccordeon(
                'appealInformation',
                listAccordeon,
                setListAccordeon
              )
            }
            openModalExit
            back={() => history.push(`/claims`)}
          >
            <ReasonArea
              dataTestId="reasonAreaComponent"
              formikProps={formikProps}
            />
          </AcordeonSession>
          <AcordeonSession
            title={intl.ACCEPTING_TERMS_AND_CONDITIONS}
            id="termsAndConditions"
            accordeon="termsAndConditions"
            openButtonRef={accordeonRefs.termsAndConditions}
            status={listAccordeon.termsAndConditions}
            next={() => formikProps.handleSubmit(formikProps.values, {})}
            setStatus={() =>
              setStatusAccordeon(
                'termsAndConditions',
                listAccordeon,
                setListAccordeon
              )
            }
            txtBtnNext={intl.LABEL_APPEAL}
            disableNextButton={!termsAccept}
            back={() =>
              openTargetAcordeon(
                'termsAndConditions',
                'appealInformation',
                false,
                accordeonRefs,
                schemas,
                values,
                submitRefs
              )
            }
          >
            <TermsAndConditions
              claimDetails={benefit}
              claimType={claimType}
              setTermsAccept={setTermsAccept}
              disableStepClaim
              disableShadowBox
              disableNextButton
              disableTitle
              dataTestId="ReasonAreaTermsAndConditions"
            />
          </AcordeonSession>
        </div>
      )}
    </Formik>
  );
};

export default withRouter(Form);
