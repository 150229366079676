export const TAB_GENERAL_INFORMATION = {
  en_US: 'General information',
  pt_BR: 'Informações gerais',
  es_ES: 'Información general',
};

export const TAB_FREQUENTLY_QUESTIONS = {
  en_US: 'Frequently asked questions',
  es_ES: 'Preguntas frecuentes',
  pt_BR: 'Perguntas frequentes',
};

export const TAB_DOCUMENTATION_CHECKLIST = {
  en_US: 'Documentation checklist',
  es_ES: 'Listado de documentación',
  pt_BR: 'Listagem de documentação',
};

export const DOCUMENTS_CONTEXT = {
  en_US:
    'List of required documents required so that we can analyze your claim.',
  es_ES:
    'Listado de documentos requeridos para que podamos analizar tu reclamo.',
  pt_BR:
    'Lista de documentos requeridos para que possamos analisar seu sinistro.',
};
