import React, { useContext, useMemo } from 'react';
import { Formik } from 'formik';

import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { IDTheftProtectionInitialValues } from './initialValues';
import { idTheftSchema } from './validationSchema';
import { pt_BR } from '../../../../../../../intl/idioms';
import PersonalArea from './PersonalArea/PersonalArea';
import DescriptionArea from './DescriptionArea/DescriptionArea';
import IncidentDateArea from './IncidentDateArea/IncidentDateArea';
import PaylegalExpensesArea from './PaylegalExpensesArea/PaylegalExpensesArea';
import TimeOffWorkArea from './TimeOffWorkArea/TimeOffWorkArea';
import FalselyOpenedArea from './FalselyOpenedArea/FalselyOpenedArea';
import OtherExpensesArea from './OtherExpensesArea/OtherExpensesArea';

const IdTheftProtection = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef } = props;

  const isBrazil = idiom === pt_BR;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validationSchema = useMemo(() => idTheftSchema(intl, isBrazil), [intl]);
  const initialValues = useMemo(
    () => IDTheftProtectionInitialValues(parentValues.policy, idiom),
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit, setFieldValue } = props;
        updateParent(values, validationSchema);

        const chnageCurrency = event => {
          setFieldValue('amount_paid.amount.currency', event.target.value);
          setFieldValue('time_off.amount.currency', event.target.value);
          setFieldValue('falsely_opened.amount.currency', event.target.value);
          setFieldValue('other_expenses.amount.currency', event.target.value);
        };

        return (
          <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                data-testid="btnSubmitForm"
                style={{ display: 'none' }}
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <DescriptionArea parentValues={parentValues} {...props} />
              <IncidentDateArea parentValues={parentValues} {...props} />
              <PaylegalExpensesArea
                chnageCurrency={chnageCurrency}
                parentValues={parentValues}
                {...props}
              />
              <TimeOffWorkArea
                chnageCurrency={chnageCurrency}
                parentValues={parentValues}
                {...props}
              />
              <FalselyOpenedArea
                chnageCurrency={chnageCurrency}
                parentValues={parentValues}
                {...props}
              />
              <OtherExpensesArea
                chnageCurrency={chnageCurrency}
                parentValues={parentValues}
                {...props}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default IdTheftProtection;
