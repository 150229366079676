import { useContext, useState, useEffect } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import styles from './PersonArea.module.scss';
import InputNumber from '../../../../../components/InputNumber/InputNumber';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { B2B2CContext } from '../../../../../components/B2B2CStateParent/B2B2CStateParent';

const MAX_TRAVELLERS = 5;

const PersonAreaV2 = props => {
  const { touched, errors, setFieldValue, CONFIG_B2B2C } = props;

  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(B2B2CContext);

  const quote = utils.getQuote();
  const greather = quote && quote.Quote.Ages.filter(age => age.value >= 70);
  const less = quote && quote.Quote.Ages.filter(age => age.value <= 69);
  const [travellers, setTravellers] = useState({
    greather70: (greather && greather.length) || 0,
    less70: (less && less.length) || 0,
  });

  const intl = translate(translations);
  const theme = getGlobalTheme();

  const change = (valueString, field) => {
    const value = Number(valueString);
    if (field === 'under_70') {
      setTravellers(travellers => {
        if (
          value > MAX_TRAVELLERS ||
          value + travellers.greather70 > MAX_TRAVELLERS
        ) {
          return travellers;
        }
        return {
          ...travellers,
          less70: value,
        };
      });
    } else {
      setTravellers(travellers => {
        if (
          value > MAX_TRAVELLERS ||
          value + travellers.less70 > MAX_TRAVELLERS
        ) {
          return travellers;
        }
        return {
          ...travellers,
          greather70: value,
        };
      });
    }
  };

  const updateTravellersInForm = () => {
    const numberTravellers = travellers.greather70 + travellers.less70;
    setFieldValue('Quote.numberTraleversInput', numberTravellers);

    let ages = [];
    if (numberTravellers === 0) {
      setFieldValue('Quote.Ages', ages);
    } else {
      for (let index = 0; index < travellers.greather70; index++) {
        ages.push({ value: 70 });
      }
      for (let index = 0; index < travellers.less70; index++) {
        ages.push({ value: 69 });
      }
      setFieldValue('Quote.Ages', ages);
    }
  };

  useEffect(() => {
    updateTravellersInForm();
  }, [travellers]);

  return (
    <div className="row">
      {CONFIG_B2B2C.quote.form.passanger && (
        <>
          <div className="col-12">
            <label className={`${styles.labelTitle} ${styles[theme]}`}>
              {intl.HOW_MANY_PASSENGERS}
            </label>
          </div>
          <div className={styles.fields}>
            <div className={styles.wrapInput}>
              <InputNumber
                axa
                label={intl.LABEL_UNDER_70}
                value={travellers.less70}
                onChange={event => {
                  const { value } = event.target;
                  change(value, 'under_70');
                }}
                decrease={() => {
                  if (travellers.less70 > 0) {
                    setTravellers({
                      ...travellers,
                      less70: travellers.less70 - 1,
                    });
                  }
                }}
                increase={() => {
                  if (
                    travellers.less70 + 1 <= MAX_TRAVELLERS &&
                    travellers.less70 + 1 + travellers.greather70 <=
                      MAX_TRAVELLERS
                  ) {
                    setTravellers({
                      ...travellers,
                      less70: travellers.less70 + 1,
                    });
                  }
                }}
                disableMin={travellers.less70 === 0}
                disableMax={
                  travellers.less70 + 1 > MAX_TRAVELLERS ||
                  travellers.less70 + 1 + travellers.greather70 > MAX_TRAVELLERS
                }
              />
            </div>
            <div className={styles.wrapInput}>
              <InputNumber
                axa
                label={intl.LABEL_OVER_70}
                value={travellers.greather70}
                onChange={event => {
                  const { value } = event.target;
                  change(value, 'greather_70');
                }}
                decrease={() => {
                  if (travellers.greather70 > 0) {
                    setTravellers({
                      ...travellers,
                      greather70: travellers.greather70 - 1,
                    });
                  }
                }}
                increase={() => {
                  if (
                    travellers.greather70 + 1 <= MAX_TRAVELLERS &&
                    travellers.greather70 + 1 + travellers.less70 <=
                      MAX_TRAVELLERS
                  ) {
                    setTravellers({
                      ...travellers,
                      greather70: travellers.greather70 + 1,
                    });
                  }
                }}
                disableMin={travellers.greather70 === 0}
                disableMax={
                  travellers.greather70 + 1 > MAX_TRAVELLERS ||
                  travellers.greather70 + 1 + travellers.less70 > MAX_TRAVELLERS
                }
              />
            </div>
          </div>
          {errors &&
            errors.Quote &&
            errors.Quote.numberTraleversInput &&
            touched &&
            touched.Quote &&
            touched.Quote.numberTraleversInput && (
              <div className="col-12">
                <div className={styles.labelErrorGroup}>
                  <span>{errors.Quote.numberTraleversInput}</span>
                </div>
              </div>
            )}
        </>
      )}
    </div>
  );
};

export default PersonAreaV2;
