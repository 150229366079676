export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};

export const LABEL_EMAIL_CONFIRMATION = {
  en_US: 'E-mail confirmation *',
  pt_BR: 'Confirmação do e-mail *',
  es_ES: 'Confirmación del correo eletrónico *',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};
