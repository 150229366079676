import React from 'react';
import moment from 'moment';

import styles from '../RecentCreated.module.scss';
import { pt_BR } from '../../../../../intl/idioms';
import { Link } from 'react-router-dom';
import { dataLayerContentMenuTrack } from '../../../../../utils/GTM_helper';
import { formatCustomDate } from '../../../../../utils/date';

const LineCertificate = props => {
  const { value, intl, idiom, index, theme, country } = props;
  const dateFormat = idiom === pt_BR ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  const getCertificateName = value => {
    let data = {};

    switch (value.certificate_type) {
      case 'Travel_Insurance':
        data.certificate = intl.CERTIFICATE_TRAVEL;
        data.info =
          value &&
          value.travel_details &&
          value.travel_details.destination_country;
        break;
      case 'Certificate_Extended_Warranty':
        data.certificate = intl.CERTIFICATE_EXTENDED;
        data.info =
          value && value.appliance_details && value.appliance_details.brand;
        break;
      case 'Certificate_Seguro_Compra':
        data.certificate = intl.CERTIFICATE_PURCHASE;
        data.info =
          value && value.appliance_details && value.appliance_details.brand;
        break;
      case 'Billete_Auto_Rental_Insurance':
        data.certificate = intl.CERTIFICATE_AUTO_RENTAL;
        data.info =
          value && value.appliance_details && value.appliance_details.brand;
        break;
      default:
        data.certificate = value.certificate_type;
        break;
    }

    return data;
  };

  const certificateData = getCertificateName(value);
  return (
    <div
      className={`col-12 ${styles.line} ${styles[theme]} ${
        index === 1 ? styles.lastItem : ''
      }`}
    >
      <div className="row no-gutters">
        <div className="col-md-6 col-sm-12">
          <span>
            <strong>{certificateData.certificate}</strong>
          </span>
          <span>{certificateData.info}</span>
        </div>
        <div className="col-md-3 col-sm-12 d-none d-md-block d-lg-block">
          <span>{intl.TEXT_VALID_UNTIL}</span>
          <span>
            {formatCustomDate(value.valid_until, 'YYYY-MM-DD', idiom, country)}
          </span>
        </div>
        <div className="col-md-3 col-sm-12 d-md-none d-lg-none">
          <span>
            {intl.TEXT_VALID_UNTIL}{' '}
            {formatCustomDate(value.valid_until, 'YYYY-MM-DD', idiom, country)}
          </span>
        </div>
        <div className={`col-md-3 col-sm-12 ${styles.status}`}>
          <Link
            onClick={() =>
              dataLayerContentMenuTrack(
                'CTA Create a claim',
                'Recent Certificate'
              )
            }
            className={styles[theme]}
            to="/claims"
          >
            {intl.TEXT_OPEN_A_CLAIM}
          </Link>
          <div
            className={`${styles.lineBottom} ${
              index === 1 ? styles.lineNone : ''
            }`}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default LineCertificate;
