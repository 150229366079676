import React from 'react';
import IncidentArea from './IncidentArea/IncidentArea';
import PersonalArea from './PersonalArea/PersonalArea';
import PricePeriodArea from './PricePeriodArea/PricePeriodArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';

const PriceProtection = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <PricePeriodArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <QuestionsArea claimDetails={claimDetails} />
    </div>
  );
};

export default PriceProtection;
