import moment from 'moment';

const payment_information = (payment, quote, trackEventUserAction) => {
  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - PAYMENT INFORMATION`,
    null
  );
  const { Quote } = quote;
  const { payment: paymentInfo } = payment;
  const effective_date = paymentInfo.date.split('/');

  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - PAYMENT INFORMATION EFFECTTIVE DATE`,
    effective_date
  );
  return {
    credit_card_token: paymentInfo.number,
    effective_end_date: `20${effective_date[1]}-${effective_date[0]}-01`,
    billing_currency: Quote.AmountCurrencyISOCode,
    card_owner: paymentInfo.nombre,
    number_of_installments: paymentInfo.installments,
  };
};

const beneficiaries = (travelers, trackEventUserAction) => {
  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - BENEFICIARIES`,
    travelers
  );
  const cardholder = travelers.MPH;
  const { address, phone, person_registrations } = cardholder;

  const additionalTravellers =
    travelers && travelers.Passengers
      ? travelers.Passengers.map(passenger => {
          return {
            person: {
              title: undefined,
              first_name: passenger.Name,
              last_name: passenger.LastName,
              birth_date: moment(passenger.DateOfBirth).format('YYYY-MM-DD'),
              gender: undefined,
              nationalities: undefined,
            },
            relationship: undefined,
            comment: undefined,
            person_registrations: [
              {
                registration_type:
                  passenger.person_registrations.registration_type,
                value: passenger.person_registrations.value,
              },
            ],
            address: undefined,
            phones: [
              {
                international_prefix:
                  passenger.phone && passenger.phone.international_prefix,
                number: passenger.phone && passenger.phone.number,
                phone_type: passenger.phone && passenger.phone.phone_type,
              },
            ],
            email: undefined,
          };
        })
      : [];
  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - BENEFICIARIES ADDITIONAL TRAVELLERS`,
    additionalTravellers
  );
  return [
    {
      person: {
        title: undefined,
        first_name: cardholder.Name,
        last_name: cardholder.LastName,
        birth_date: moment(cardholder.DateOfBirth).format('YYYY-MM-DD'),
        gender: undefined,
        nationalities: undefined,
      },
      relationship: undefined,
      comment: undefined,
      person_registrations: [
        {
          registration_type: person_registrations.registration_type,
          value: person_registrations.value,
        },
      ],
      address: {
        street_address: address.street_address,
        postal_code: address.postal_code,
        locality: address.locality,
        country: address.country,
        subdivision: address.subdivision,
        state: address.state,
      },
      phones: [
        {
          international_prefix: phone.international_prefix,
          number: phone.number,
          phone_type: phone.phone_type,
        },
      ],
      email: cardholder.Email,
    },
    ...additionalTravellers,
  ];
};

const travel_information = (quote, trackEventUserAction) => {
  const { Quote } = quote;
  trackEventUserAction(`#### (B2B2C) FORMATING ENROLL - TRAVEL INFORMATION`, {
    from: Quote.TripInitialDate,
    to: Quote.TripEndDate,
  });
  return {
    from: moment(Quote.TripInitialDate).format('YYYY-MM-DD'),
    to: moment(Quote.TripEndDate).format('YYYY-MM-DD'),
    country_destination: Quote.DestinationCountryIsoCode,
    country_origin: Quote.CountryOfResidenceIsoCode,
  };
};

const product_criteria = (
  selectedPlan,
  benefitDetail,
  quote,
  trackEventUserAction
) => {
  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - PRODUCT CRITERIA`,
    null
  );
  const { Annual } = selectedPlan;
  const { Quote } = quote;

  let from = '';
  let to = '';
  trackEventUserAction(
    `#### (B2B2C) FORMATING ENROLL - PRODUCT CRITERIA FROM`,
    {
      from,
      to,
    }
  );

  if (Annual) {
    trackEventUserAction(
      `#### (B2B2C) FORMATING ENROLL - PRODUCT CRITERIA IS ANNUAL`,
      null
    );
    from = moment(new Date()).format('YYYY-MM-DD');
    to = moment(new Date()).add(1, 'years').format('YYYY-MM-DD');
  } else {
    from = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
    to = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  }
  trackEventUserAction(`#### (B2B2C) FORMATING ENROLL - PRODUCT CRITERIA TO`, {
    from,
    to,
  });

  return {
    external_product_id: selectedPlan.Id.toString(),
    external_product_name: selectedPlan.Name,
    benefit_id: benefitDetail.external_reference,
    benefit_extension_type:
      Quote.ProductType && String(Quote.ProductType).toUpperCase(),
    effective_period: {
      from: from,
      to: to,
    },
  };
};

export const formatEnroll = (
  travelers,
  payment,
  quote,
  selectedPlan,
  benefitDetail,
  trackEventUserAction
) => {
  trackEventUserAction(`#### (B2B2C) FORMATING ENROLL`, {
    travelers,
    payment,
    quote,
    selectedPlan,
    benefitDetail,
  });
  return {
    product_criteria: product_criteria(
      selectedPlan,
      benefitDetail,
      quote,
      trackEventUserAction
    ),
    travel_information: travel_information(quote, trackEventUserAction),
    beneficiaries: beneficiaries(travelers, trackEventUserAction),
    payment_information: payment_information(
      payment,
      quote,
      trackEventUserAction
    ),
  };
};
