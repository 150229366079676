import {
  NavHashLink as Link,
  NavHashLinkProps as LinkProps,
} from 'react-router-hash-link';

interface NavHashLinkProps extends LinkProps {
  subtract?: string;
}

const scrollWithOffset = (el: HTMLElement, subtract: string) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = subtract ? -(subtract + 90) : -90;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const NavHashLink = (props: NavHashLinkProps) => {
  const { subtract } = props;
  return (
    <Link {...props} scroll={el => scrollWithOffset(el, subtract)}>
      {props.children}
    </Link>
  );
};

export default NavHashLink;
