import { userServices } from '../../../../../../../services';
import { formatToApi } from './formatData';

export const formSubmit = async (values, cn, idiom = 'en-US') => {
  const { holder } = formatToApi(values);
  delete holder.address.invalidCep;
  delete holder.email;
  await userServices.updateCHInfo(cn, holder, idiom);

  return true;
};
