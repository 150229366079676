import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const location = incident && incident.location ? incident.location : {};

  const country = location && location.country ? location.country : '-';

  const locality = location && location.locality ? location.locality : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.COUNTRY_LABEL} value={country} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.TEXT_CITY_INCIDENT} value={locality} />
      </div>
    </>
  );
};

export default PersonalArea;
