import { formatCustomDate } from '../date';
import { toFixedOrDefault } from '../numbers';
import { safeGet } from '../object';
import { formatMonetaryMask } from '../currency';

export const PURCHASE_PROTECTION_DATE_FORMAT = 'DD/MM/YYYY';
export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';
export const DAMAGE_VALUE = 'Damage';
export const THEFT_VALUE = 'Theft';
export const STOLEN_VALUE = 'Stolen';

const getRelationshipIntl = (relationship, intl) => {
  const relationships = {
    [CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  return relationships[relationship];
};

const formatPrice = (value, currency, idiomForApi = 'en-US') =>
  value
    ? `${formatMonetaryMask(value, idiomForApi)} ${currency && `${currency}`} `
    : '-';

export const getCardholderRelationship = (affectedPerson, intl) =>
  affectedPerson && affectedPerson.relationship
    ? getRelationshipIntl(affectedPerson.relationship, intl)
    : '-';

export const getAffectedPersonName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.first_name
    ? affectedPerson.person.first_name
    : '-';

export const getAffectedPersonLastName = affectedPerson =>
  affectedPerson && affectedPerson.person && affectedPerson.person.last_name
    ? affectedPerson.person.last_name
    : '-';

export const getAffectedPersonComment = affectedPerson =>
  (affectedPerson && affectedPerson.comment) || '';

export const getItemsCount = items =>
  Array.isArray(items) ? items.length : '-';

export const getItemTypeOfLoss = (item, intl) => {
  const type = item && item.status ? item.status : '';
  switch (type) {
    case DAMAGE_VALUE:
      return intl.LABEL_DAMAGE;
    case THEFT_VALUE:
    case STOLEN_VALUE:
      return intl.LABEL_THEFT;
    default:
      return '-';
  }
};

export const getItemPurchaseDate = (item, idiom, country) =>
  item && item.purchase_date
    ? formatCustomDate(item.purchase_date, 'YYYY-MM-DD', idiom, country)
    : '-';
export const getItemName = item => (item && item.label) || '-';

export const getItemManufacturer = item => (item && item.manufacturer) || '-';

export const getItemModel = item => (item && item.model_number) || '-';

export const getItemPurchasePrice = (item, idiom) => {
  let value =
    item && item.price && item.price.value
      ? toFixedOrDefault(item.price.value, 2, '')
      : '';

  const currency =
    item && item.price && item.price.currency
      ? item.price.currency
      : item && item.price && item.price.currencyValue
      ? item.price.currencyValue
      : '';

  return formatPrice(value, currency, idiom);
};

export const getItemPurchaseTotalInvoiceAmount = (item, idiom) => {
  let value =
    item && item.total_invoice_amount && item.total_invoice_amount.value
      ? toFixedOrDefault(item.total_invoice_amount.value, 2, '')
      : '';

  const currency =
    item && item.total_invoice_amount && item.total_invoice_amount.currency
      ? item.total_invoice_amount.currency
      : '';

  return formatPrice(value, currency, idiom);
};

const getYesOrNo = (value, intl) => (value ? intl.TEXT_YES : intl.TEXT_NO);

export const getIsItemReparable = (item, intl) =>
  item && item.is_repairable !== undefined
    ? getYesOrNo(item.is_repairable, intl)
    : '-';

export const getIsFullyChargedOnCard = (item, intl) =>
  item && item.was_fully_charged_on_card !== undefined
    ? getYesOrNo(item.was_fully_charged_on_card, intl)
    : '-';

export const getIsItemDamageVisible = (item, intl) =>
  item && item.is_the_damage_visible !== undefined
    ? getYesOrNo(item.is_the_damage_visible, intl)
    : '-';

export const getItemAmountClaimed = (item, idiom) => {
  let value =
    item && item.amount_claimed && item.amount_claimed.value
      ? toFixedOrDefault(item.amount_claimed.value, 2, '')
      : '';

  const currency =
    item && item.amount_claimed && item.amount_claimed.currency
      ? item.amount_claimed.currency
      : '';

  return formatPrice(value, currency, idiom);
};

export const getIncidentDate = (incident, idiom, country) =>
  incident.date
    ? formatCustomDate(
        incident.date,
        PURCHASE_PROTECTION_DATE_FORMAT,
        idiom,
        country
      )
    : '-';

export const getIncidentDescription = incident =>
  incident.description ? incident.description : '-';

export const getAmountClaimed = (items, idiom) => {
  let amount = items.reduce(
    (sum, item) => sum + safeGet(item, 'price.value', 0),
    0
  );
  if (amount) amount = toFixedOrDefault(amount);

  const currency = safeGet(items, '[0].price.currency', '') || '';

  return formatPrice(amount, currency, idiom);
};

export const getTotalAmountClaimed = (totalAmountClaimed, idiom) => {
  let value = totalAmountClaimed.value
    ? toFixedOrDefault(totalAmountClaimed.value, 2, '')
    : '';

  const currency = totalAmountClaimed.currency
    ? totalAmountClaimed.currency
    : '';

  return formatPrice(value, currency, idiom);
};

export const getOtherInsuranceCovering = (covering, idiom) => {
  const value =
    covering.amount && covering.amount.value
      ? toFixedOrDefault(covering.amount.value, 2, '')
      : '';
  const currency =
    covering.amount && covering.amount.currency ? covering.amount.currency : '';

  return formatPrice(value, currency, idiom);
};

export const getItemPaymentMethod = (item, intl) => {
  const number_of_installments =
    item && item.number_of_installments !== undefined
      ? item.number_of_installments
      : -1;
  if (number_of_installments === 0) {
    return intl.METHOD_CASH;
  }

  if (number_of_installments > 0) {
    return intl.METHOD_FINANCING;
  }

  return '-';
};

export const getItemInstallment = item =>
  (item && item.number_of_installments) || '-';

export const getItemInstallmentPrice = (item, idiom) => {
  let value =
    item && item.total_invoice_amount && item.total_invoice_amount.value
      ? toFixedOrDefault(
          item.total_invoice_amount.value / item.number_of_installments,
          2,
          ''
        )
      : '';

  const currency =
    item && item.total_invoice_amount && item.total_invoice_amount.currency
      ? item.total_invoice_amount.currency
      : '';

  return formatPrice(value, currency, idiom);
};
