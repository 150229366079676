import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const TITLE_INCIDENT_LOCATION = {
  en_US: 'Incident location',
  es_ES: 'Dirección del incidente',
  pt_BR: 'Endereço do incidente',
};

export const LABEL_STATE = {
  en_US: 'State *',
  pt_BR: 'Estado *',
  es_ES: 'Estado *',
};
export const LABEL_CITY = {
  en_US: 'City *',
  pt_BR: 'Cidade *',
  es_ES: 'Ciudad *',
};
export const LABEL_POSTAL_CODE = {
  en_US: 'Postal code *',
  pt_BR: 'CEP *',
  es_ES: 'Código postal *',
};
export const LABEL_ADDRESS = {
  en_US: 'Address *',
  pt_BR: 'Rua *',
  es_ES: 'Calle *',
};
export const LABEL_SUBDIVISION = {
  en_US: 'Neighborhood *',
  pt_BR: 'Bairro *',
  es_ES: 'Barrio/Colonia *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};
export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};
export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATING_CEP = {
  en_US: 'Validating postal Code...',
  pt_BR: 'Validando CEP...',
  es_ES: 'Validar código postal...',
};
