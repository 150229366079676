import React, { useContext, useMemo } from 'react';
import styles from './BannerCinepolis.module.scss';
import logoCinepolis from '../../assets/images/cinepolis/Logo Visa Rappi.svg';
import ArrowRightIcon from '../../assets/icons/ArrowRight';
import { Link } from 'react-router-dom';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { useIntl } from '../../intl';
import { StateContext } from '../StateContextParent/StateContextParent';
import { PublicContext } from '../PublicContextParent/PublicContextParent';
import Button from '../../componentsV2/Button/Button';

const BannerCinepolis = ({ cinepolisBenefit }) => {
  const { translate } = useIntl();
  const { getGlobalTheme } = useTheme();
  const { utils } = useContext(StateContext);
  const { productActive } = useContext(PublicContext);

  const theme = getGlobalTheme();
  const intl = translate(translations);

  const redirectLink = useMemo(() => {
    const isLogged = utils.isUserLogged();
    if (isLogged) {
      return `/your-benefits/${cinepolisBenefit.benefit_id}`;
    } else {
      return `/benefits/${productActive.product_id}/${cinepolisBenefit.benefit_id}`;
    }
  }, [cinepolisBenefit, productActive]);

  return (
    <div className={styles.bannerCinepolis}>
      <div className={styles.content}>
        <h3 className={styles.title}>{intl.BRAND_TITLE}</h3>
        <Button
          surface="Inverse"
          link
          href={redirectLink}
          label={intl.BENEFIT_LINK}
          trailingIcon="Chevron Right"
        />
        <footer>
          <img alt="Cinepolis" src={logoCinepolis} />
        </footer>
      </div>
    </div>
  );
};

export default BannerCinepolis;
