import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from '../../intl';
import { dataLayerAppSelectTrack } from '../../../../utils/GTM_helper';
import Bluebox from '../../../../components/Bluebox';

const BlueBoxMarketing = ({ subtitle }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <Bluebox.Container>
      <Bluebox.SubContainer>
        <Bluebox.Title text={intl.DOWNLOAD_APP} />
        <Bluebox.Text text={subtitle} margin={false} center />
      </Bluebox.SubContainer>

      <Bluebox.SubContainer center>
        <Bluebox.QRCode
          link={`${window.location.origin}/download-app/store?utm_source=qr1&utm_medium=QR&utm_campaign=app`}
        />
      </Bluebox.SubContainer>

      <Bluebox.SubContainer inline>
        <Bluebox.ImageLink
          imageUrl={intl.IMAGE_APP_STORE}
          altImage="App Store"
          href={process.env.REACT_APP_IOS_STORE.toString()}
          onClick={() => dataLayerAppSelectTrack('Apple Store')}
        />
        <Bluebox.ImageLink
          imageUrl={intl.IMAGE_PLAY_STORE}
          altImage="Google Play"
          href={process.env.REACT_APP_ANDROID_STORE.toString()}
          onClick={() => dataLayerAppSelectTrack('Google Play')}
        />
      </Bluebox.SubContainer>

      <Bluebox.Notice text={intl.AVAILABLE_IN_STORES} center />
    </Bluebox.Container>
  );
};

export default BlueBoxMarketing;
