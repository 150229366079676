export default (env, axios, errorHandler) => {
  return {
    getSchedule: async data => {
      const url = `/nurse-server/times`;
      return errorHandler(
        axios.post(url, data),
        'Nurse Advanced Line - getSchedule',
        {
          data,
          url,
        }
      );
    },

    postAppointment: async (data, idiom) => {
      const url = `/nurse-server/create/appointment`;
      const headers = {
        'Accept-Language': idiom,
      };
      return errorHandler(
        axios.post(url, data, { headers }),
        'Nurse Advanced Line - postAppointment',
        {
          url,
          data,
        }
      );
    },

    getDataConfig: async () => {
      const url = `/nurse-server/config`;
      return errorHandler(
        axios.get(url),
        'Nurse Advanced Line - getDataConfig',
        { url }
      );
    },

    getUserReports: async page_index => {
      const url = `/nurse-server/user-reports?page_index=${page_index}`;
      return errorHandler(
        axios.get(url),
        'Nurse Advanced Line - getUserReports'
      );
    },

    getReportDetail: async id => {
      const url = `/nurse-server/report-detail/${id}`;
      return errorHandler(
        axios.get(url),
        'Nurse Advanced Line - getReportDetail'
      );
    },

    cancelUserAppointment: async id => {
      const url = `/nurse-server/user-cancel-appointment/${id}`;
      return errorHandler(
        axios.patch(url),
        'Nurse Advanced Line - cancelUserAppointment',
        {
          url,
        }
      );
    },
  };
};
