import { useEffect, useMemo, useState } from 'react';
import BenefitContent from '../BenefitContent/BenefitContent';
import {
  ENABLE_EXPECTED_DOCUMENTS_API,
  ENABLE_FAQ_API,
  ENABLE_DISLIKE_FAQ_API,
} from '../../../utils/environments_variables';
import TabBar from '../../../components/TabBar';
import { useIntl } from '../../../intl';
import * as translations from './intl';
import { expectedDocumentsService, faqService } from '../../../services';
import FaqList, { FaqListItem } from '../../../components/FaqList';
import { ExpectedDocument } from '../../../@types/APIs/ExpectedDocuments';
import ExpectedDocumentsList from '../../../components/ExpectedDocumentsList';
import { ImprovementOptions } from '../../../@types/APIs/Faq';

interface MainContentProps {
  benefitDetail: any;
  isNewVersionDetail: boolean;
}

type TabId = 'DETAIL' | 'FAQ' | 'EXPECTED_DOCUMENTS';

const MainContent = ({
  benefitDetail,
  isNewVersionDetail,
}: MainContentProps) => {
  const { translate, idiomForApi } = useIntl();
  const intl = translate(translations);

  const [selectedTab, setSelectedTab] = useState<TabId>('DETAIL');
  const [faq, setFaq] = useState<FaqListItem[]>(null);
  const [faqImprovement, setFaqImprovement] =
    useState<ImprovementOptions[]>(null);
  const [expectedDocuments, setExpectedDocuments] =
    useState<ExpectedDocument[]>(null);
  const [descriptionDivs, setDescriptionDivs] = useState<
    HTMLCollectionOf<HTMLDivElement> | []
  >([]);

  const tabs = useMemo(() => {
    const tabs = [
      {
        id: 'DETAIL' as TabId,
        label: intl.TAB_GENERAL_INFORMATION,
      },
    ];

    const hasFaq = Array.isArray(faq) && faq.length > 0;
    if (ENABLE_FAQ_API && hasFaq) {
      tabs.push({
        id: 'FAQ' as TabId,
        label: intl.TAB_FREQUENTLY_QUESTIONS,
      });
    }

    const hasExpectedDocuments =
      Array.isArray(expectedDocuments) && expectedDocuments.length > 0;
    if (ENABLE_EXPECTED_DOCUMENTS_API && hasExpectedDocuments) {
      tabs.push({
        id: 'EXPECTED_DOCUMENTS' as TabId,
        label: intl.TAB_DOCUMENTATION_CHECKLIST,
      });
    }

    return tabs;
  }, [intl, faq, expectedDocuments]);

  useEffect(() => {
    const auxElement = document.createElement('div');
    auxElement.innerHTML = benefitDetail && benefitDetail.description;
    setDescriptionDivs(auxElement.getElementsByTagName('div'));
  }, [benefitDetail]);

  const fetchFaq = async () => {
    try {
      const { data } = await faqService.getFaq(idiomForApi(), {
        benefit_code: benefitDetail.external_reference,
      });
      const formattedFaqs: FaqListItem[] = data.map(faq => ({
        id: faq.question_id,
        question: faq.question_text,
        answer: faq.answer_text,
        category: faq.category,
      }));
      setFaq(formattedFaqs);
    } catch (error) {
      // No-op: Faq tab won't be displayed
    }
  };

  const fetchExpectedDocuments = async () => {
    try {
      const { data } = await expectedDocumentsService.getExpectedDocuments(
        idiomForApi(),
        { benefit_code: benefitDetail.external_reference }
      );

      const mandatoryDocuments = [];
      const optionalDocuments = [];

      data.forEach(document => {
        if (document.is_mandatory) {
          mandatoryDocuments.push(document);
        } else {
          optionalDocuments.push(document);
        }
      });

      setExpectedDocuments([...mandatoryDocuments, ...optionalDocuments]);
    } catch (error) {
      // No-op: Expected documents tab won't be displayed
    }
  };

  const fetchFaqImprovementOptions = async () => {
    try {
      const { data } = await faqService.getImprovementOptions(idiomForApi());

      setFaqImprovement(data);
    } catch (error) {
      // No-op: Faq tab won't be displayed
    }
  };

  useEffect(() => {
    // Don't load FAQ and Documents if benefit detail is not loaded
    if (!benefitDetail.external_reference) return;

    if (ENABLE_FAQ_API) {
      fetchFaq();
    }
    if (ENABLE_FAQ_API && ENABLE_DISLIKE_FAQ_API) {
      fetchFaqImprovementOptions();
    }
    if (ENABLE_EXPECTED_DOCUMENTS_API) {
      fetchExpectedDocuments();
    }
  }, [benefitDetail?.external_reference]);

  if (!ENABLE_FAQ_API && !ENABLE_EXPECTED_DOCUMENTS_API) {
    return (
      <BenefitContent
        descriptionDivs={descriptionDivs}
        benefitDetail={benefitDetail}
        isNewVersionDetail={isNewVersionDetail}
        expectedDocuments={expectedDocuments}
        setSelectedTab={setSelectedTab}
      />
    );
  }

  return (
    <TabBar
      tabs={tabs}
      selectedTab={selectedTab}
      onClickTab={tabId => setSelectedTab(tabId)}
    >
      {selectedTab === 'DETAIL' && (
        <BenefitContent
          descriptionDivs={descriptionDivs}
          benefitDetail={benefitDetail}
          isNewVersionDetail={isNewVersionDetail}
          expectedDocuments={expectedDocuments}
          setSelectedTab={setSelectedTab}
        />
      )}
      {selectedTab === 'FAQ' && (
        <>
          <FaqList
            items={faq}
            showFeedback
            faqImprovementOptions={faqImprovement}
          />
        </>
      )}
      {selectedTab === 'EXPECTED_DOCUMENTS' && (
        <>
          <p>{intl.DOCUMENTS_CONTEXT}</p>
          <ExpectedDocumentsList expectedDocuments={expectedDocuments} />
        </>
      )}
    </TabBar>
  );
};

export default MainContent;
