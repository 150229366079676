export const BTN_CANCEL_CLAIM = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TITLE_CANCEL_CLAIM = {
  pt_BR: claim_number =>
    `Você está certo de que deseja cancelar o sinistro ${claim_number}?`,
  en_US: claim_number =>
    `Are you sure you want to cancel the ${claim_number} claim?`,
  es_ES: claim_number =>
    `¿Está seguro de que desea cancelar el reclamo de ${claim_number}?`,
};

export const SUBTITLE_CANCEL_CLAIM = {
  pt_BR: 'Essa ação não pode ser revertida.',
  en_US: 'This action cannot be reversed.',
  es_ES: 'Esta acción no se puede revertir.',
};

export const BTN_CONFIRM_CANCEL_CLAIM = {
  pt_BR: 'Cancelar',
  en_US: 'Cancel',
  es_ES: 'Cancelar',
};

export const BTN_KEEP_CLAIM = {
  pt_BR: 'Não, manter o sinistro',
  en_US: 'No, keep the claim',
  es_ES: 'No, conservar el reclamo',
};

export const LABEL_CONSENT = {
  pt_BR: claim_number =>
    `Declaro estar de acordo com o fechamento do sinistro número ${claim_number}.`,
  en_US: claim_number =>
    `I declare that I agree with the closing of claim number ${claim_number}.`,
  es_ES: claim_number =>
    `Declaro que estoy de acuerdo con el cierre del reclamo número ${claim_number}.`,
};

export const LABEL_REASON = {
  pt_BR: 'Motivo do cancelamento *',
  en_US: 'Cancellation reason *',
  es_ES: 'Razón de cancelación *',
};

export const OPTION_MISTAKEN_INAPPROPRIATE = {
  pt_BR: 'Sinistro criado indevidamente',
  en_US: 'Wrongly created claim',
  es_ES: 'Reclamo creado incorrectamente',
};

export const OPTION_DUPLICATE = {
  pt_BR: 'Sinistro criado duplicadamente',
  en_US: 'Claim created in duplicity',
  es_ES: 'Reclamo creado en duplicidad',
};

export const OPTION_REPENTANT_REGRETFULL = {
  pt_BR: 'Estou arrependido de ter criado o sinistro',
  en_US: "I'm sorry to have created the claim",
  es_ES: 'Lamento haber creado el reclamo',
};

export const VALIDATION_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_ERROR_ALREADY_CANCELLED = {
  en_US: 'It was not possible to cancel this claim.',
  pt_BR: 'Não foi possível cancelar este sinistro.',
  es_ES: 'No fue posible cancelar este reclamo.',
};

export const SUBTITLE_ERROR_ALREADY_CANCELLED = {
  en_US:
    'It has already been canceled or is in a situation where cancellation is not possible.',
  pt_BR:
    'Ele já foi cancelado ou está numa situação em que não possibilita o cancelamento.',
  es_ES:
    'Ya se ha cancelado o se encuentra en una situación en la que la cancelación no es posible.',
};

export const TITLE_CANCEL_CLAIM_SUCCESS = {
  en_US: 'Claim canceled successfully.',
  pt_BR: 'Sinistro cancelado com sucesso.',
  es_ES: 'Reclamo cancelado con éxito.',
};

export const TITLE_LOADING = {
  en_US: 'Canceling the claim...',
  pt_BR: 'Cancelando o sinistro...',
  es_ES: 'Cancelando el reclamo...',
};
