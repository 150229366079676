/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../../intl';
import { withRouter } from 'react-router-dom';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import * as translations from './intl';
import { loadBenefit, loadClaim } from './utils';
import Form from './Form/Form';
import HorizontalHalfBlueLayout from '../../../layouts/HorizontalHalfBlueLayout';

const Appeal = props => {
  const [claim, setClaim] = useState('LOADING');
  const [benefit, setBenefit] = useState('LOADING');
  const { idiomForApi, translate } = useContext(IntlContext);

  const { utils } = useContext(StateContext);
  const { claim_id: claimId, claim_type: claimType } = props.match.params;
  const intl = translate(translations);
  const binOrPan = utils.getBin(true);

  const getStatus = () => {
    if (claim === 'LOADING' || benefit === 'LOADING') return 'LOADING';
    if (claim === 'ERROR' || benefit === 'ERROR') return 'ERROR';
    return 'LOADED';
  };

  useEffect(() => {
    loadBenefit(binOrPan, claimType, setBenefit, idiomForApi());
    loadClaim(claimId, idiomForApi(), setClaim);
  }, []);

  return (
    <HorizontalHalfBlueLayout status={getStatus()}>
      <SessionContainer>
        {benefit && (
          <HeadingTitle
            showModalExit
            subtitle={intl.TEXT_APPEAL_CLAIM}
            title={benefit.name}
          />
        )}
        <Form claim={claim} claimType={claimType} benefit={benefit} />
      </SessionContainer>
    </HorizontalHalfBlueLayout>
  );
};

export default withRouter(Appeal);
