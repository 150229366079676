import React, { useContext } from 'react';
import { Claim } from '../../@types/APIs/claim';
import { IntlContext } from '../../intl';
import styles from './Bluebox.module.scss';
import * as translations from './intl';

interface BlueboxTitleProps {
  claim?: Claim;
  text?: string;
}

const titleByRule = (claim: Claim, intl) => {
  if (claim) {
    if (claim.external_reference.includes('axa_')) {
      return intl.HOW_TO_PURCHASE;
    } else if (
      claim.external_reference === 'EMC_Emergency_Medical_Assistance'
    ) {
      return intl.ENJOY_NOW;
    } else {
      return intl.HOW_TO_CLAIM;
    }
  } else {
    return intl.HOW_TO_ENJOY;
  }
};

const BlueboxTitle = ({ claim, text }: BlueboxTitleProps) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const title = text || titleByRule(claim, intl);

  return <h2 className={styles.title}>{title}</h2>;
};

export default BlueboxTitle;
