import React, { useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import ListCrossBenefits from './ListCrossBenefits/ListCrossBenefits';
import styles from './CrossSelling.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../StateContextParent/StateContextParent';

const CrossSelling = ({ benefitList }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { utils } = useContext(StateContext);
  const binOrPan = utils.getBin(true);
  const crossBenefitsList = benefitList.filter(benefit =>
    benefit.external_reference.includes('axa')
  );

  if (crossBenefitsList.length === 0) {
    return <></>;
  }

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div className={`${styles.contentTitle} ${styles[theme]}`}>
        <h2 className={`${styles[theme]}`}>{intl.TITLE_GET_MORE_BENEFITS}</h2>
      </div>

      <div className="row">
        <ListCrossBenefits
          benefitToSplit={crossBenefitsList}
          productBinOrPan={binOrPan}
        />
      </div>
    </div>
  );
};

export default CrossSelling;
