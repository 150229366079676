import { pt_BR } from '../../../../../intl/idioms';

/* GENDER */
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const MALE_VALUE_ABV = 'ml';
export const FEMALE_VALUE_ABV = 'fm';
export const UNKNOWN_VALUE = 'UNKNOWN';
export const UNKNOWN_VALUE_ABV = 'unknown';

/* TITLE */
export const TITLE_MR = 'MR';
export const TITLE_MS = 'MS';
export const TITLE_MISS = 'MISS';
export const TITLE_DR = 'DR';
export const TITLE_PR = 'PR';

/* RELATIONSHIP */
export const VALUE_SON = 'Son';
export const VALUE_DAUGHTER = 'Daughter';
export const VALUE_SPOUSE = 'Spouse';
export const VALUE_OTHER = 'Other';

/* REGISTRATION TYPES */
export const fieldValueRegistrationCPF = 'CPF';
export const fieldValueRegistrationPassp = 'PASSPORT';

export const emptyAdditional = idiom => {
  const person_registrations =
    idiom === pt_BR
      ? [
          {
            registration_type: 'CPF',
            value: '',
          },
          {
            registration_type: '',
            value: '',
          },
        ]
      : [
          {
            registration_type: '',
            value: '',
          },
        ];

  return {
    address: {
      country: '',
      locality: '',
      postal_code: '',
      state: '',
      street_address: '',
      subdivision: '',
    },
    person: {
      birth_date: '',
      first_name: '',
      gender: '',
      last_name: '',
    },
    person_registrations: person_registrations,
    email: '',
    nationalities: '',
    relationship: '',
    dependent_id: '',
    isSelected: true,
  };
};

//CARD TYPE
export const BUSINESS = 'Business';
export const CTA = 'CTA';
export const CORPORATE = 'Corporate';
