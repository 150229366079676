import { es_mx_nationalities } from '../../../../../intl/es_mx_nationalities';

export const PRODUCT_SELECTED = {
  en_US: 'Product',
  pt_BR: 'Produto',
  es_ES: 'Producto',
};

export const TOTAL_TO_PAY = {
  en_US: 'Total to pay',
  pt_BR: 'Total a pagar',
  es_ES: 'Total a pagar',
};

export const DEPARTURE_DATE = {
  en_US: 'Departure date from origin country *',
  pt_BR: 'Data de partida do país de origem *',
  es_ES: 'Fecha de salida de país del origen *',
};

export const RETURN_DATE = {
  en_US: 'Return date to the origin country *',
  pt_BR: 'Data de retorno ao país de origem *',
  es_ES: 'Fecha de regreso al país de origen *',
};

export const PASSENGERS_NUMBER = {
  en_US: 'Passengers number',
  pt_BR: 'Número de passageiros',
  es_ES: 'Número de pasajeros',
};

export const DESTINY = {
  en_US: 'Destination country',
  pt_BR: 'País de destino',
  es_ES: 'País de destino',
};

export const ORIGIN = {
  en_US: 'Origin country *',
  pt_BR: 'País de origem *',
  es_ES: 'País de origen *',
};

export const ISSUANCE_DATE = {
  en_US: 'Issuance date',
  pt_BR: 'Data de emissão',
  es_ES: 'Fecha de emisión',
};

export const COUNTRY_DESTINATION = {
  en_US: 'Destination country *',
  pt_BR: 'País de destino *',
  es_ES: 'País de destino *',
};

export const NATIONALITY_OPTIONS = {
  en_US: '',
  pt_BR: '',
  es_ES: es_mx_nationalities,
};

export const VALIDATION_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const ERROR_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const ERROR_COVERAGE_IS_LIMITED = {
  en_US: 'Coverage is limited to 60 consecutive days.',
  pt_BR: 'A cobertura é limitada a 60 dias consecutivos.',
  es_ES: 'La cobertura está limitada a 60 días consecutivos.',
};

export const EDIT_DATES_TEXT = {
  en_US: `If you need to change the dates, click here.`,
  pt_BR: `Caso precise alterar as datas, clique aqui.`,
  es_ES: `Si necesita cambiar las fechas, haga clic aquí.`,
};
