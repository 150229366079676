import { useContext } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../consts';
import styles from '../ExpenseArea.module.scss';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const ExpenseItemAdd = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, setFieldValue } = props;

  const addExpense = event => {
    event.preventDefault();

    let newObject = constants.EXPENSE_OBJECT;
    newObject.price.currency = values.expense_currency;
    newObject.price.value = '';

    const expense = [...values.expenses];
    expense.push(newObject);
    setFieldValue('expenses', expense);
  };
  return (
    <div className="row">
      <div className={`col-12 ${styles.add}`}>
        <Button
          type="Tertiary"
          dataTestid="BtnAddExpenseItem"
          onClick={addExpense}
          label={intl.BTN_ADD_EXPENSE}
          leadingIcon={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default ExpenseItemAdd;
