import { benefitToClaimType } from './benefitToClaimType';
import { formatReimbursementToApi } from './reimbursement';
import { formatIncidentToApi } from './incident';
import { formatPolicyToApi } from './policy';
import { formatLegal_concentsToApi } from './legal_consents';
import { merge } from 'lodash';

export const formatData = props => {
  const claimType = benefitToClaimType[props.claimType];
  const reimbursement = formatReimbursementToApi(props);
  const policy = formatPolicyToApi(props);
  const legal_consents = formatLegal_concentsToApi(props);
  const claimData = formatIncidentToApi(props);

  if (claimData.policy) merge(policy, claimData.policy);
  claimData.policy = policy;
  claimData.legal_consents = legal_consents;
  if (props.certificate_id) {
    claimData.certificate_id = props.certificate_id;
  }
  return { reimbursement, claimData, claimType };
};

export const formatDataPostClaim = props => {
  const claimType = benefitToClaimType[props.claimType];
  const policy = formatPolicyToApi(props, claimType);
  const legal_consents = formatLegal_concentsToApi(props);
  const claimData = formatIncidentToApi(props);

  if (claimData.policy) merge(policy, claimData.policy);
  claimData.policy = policy;
  claimData.legal_consents = legal_consents;
  if (props.certificate_id) {
    claimData.certificate_id = props.certificate_id || '';
  }
  return { claimData, claimType };
};

export const formatDataPostReimbursement = props => {
  const reimbursement = formatReimbursementToApi(props);
  return reimbursement;
};

export const formatDataToUpdate = props => {
  const claimType = benefitToClaimType[props.claimType];
  const claimTypeOriginal = props.claimType;
  const reimbursement = formatReimbursementToApi(props, true);
  const policy = formatPolicyToApi(props);
  const legal_consents = formatLegal_concentsToApi(props);
  const claimData = formatIncidentToApi(props);
  if (claimData.policy) merge(policy, claimData.policy);
  claimData.policy = policy;
  claimData.legal_consents = legal_consents;
  if (props.certificate_id) {
    claimData.certificate_id = props.certificate_id;
  }
  // return { reimbursement, claimData, claimType };
  return { claimData, claimType, reimbursement, claimTypeOriginal };
};
