import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';
import { pt_BR } from '../../../../../../../intl/idioms';
import moment from 'moment';

export const IemsFormInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let registration = data && data.person_registrations;

  const affected_person = completeClaim && completeClaim.affected_person;
  const medicalService = completeClaim && completeClaim.medical_service;
  const existingCase = completeClaim && completeClaim.existing_case;
  const attendingPhysician = completeClaim && completeClaim.attending_physician;
  const totalAmountClaimed =
    completeClaim && completeClaim.total_amount_claimed;
  const otherInsuranceCovering =
    completeClaim &&
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[0];
  const amountEstimationDamage =
    completeClaim && completeClaim.amount_estimation_damage;

  let dateFormat = 'YYYY/MM/DD';
  let dateFormatBr = 'DD/MM/YYYY';
  const getPerson = affected_person => {
    if (affected_person && affected_person.person) {
      const formatBirthDate = moment(
        affected_person.person.birth_date,
        dateFormat
      );

      return {
        first_name: affected_person.person.first_name,
        last_name: affected_person.person.last_name,
        birth_date: affected_person.person.birth_date
          ? new Date(formatBirthDate)
          : '',
        registrations: {
          registration_type:
            affected_person.registrations.length > 0
              ? affected_person.registrations[0].registration_type
              : idiom === pt_BR
              ? 'CPF'
              : 'PASSPORT',
          value:
            affected_person.registrations.length > 0
              ? affected_person.registrations[0].value
              : '',
        },
      };
    }
    return {
      first_name: person && person.first_name ? person.first_name : '',
      last_name: person && person.last_name ? person.last_name : '',
      birth_date: person && person.birth_date ? person.birth_date : '',
      registrations: {
        registration_type: idiom === pt_BR ? 'CPF' : 'PASSPORT',
        value: registration && registration.value ? registration.value : '',
      },
    };
  };

  const getExpenses = medicalService => {
    if (medicalService && medicalService.expenses)
      return medicalService.expenses.map(expense => ({
        type: expense.type.toUpperCase(),
        payment_mode: expense.payment_mode,
        price: {
          currency: expense.price.currency,
          value: expense.price.value,
        },
      }));
    return [
      {
        type: '',
        payment_mode: '',
        price: {
          currency: '',
          value: '',
        },
      },
    ];
  };

  return {
    affected_person: {
      relationship: affected_person
        ? affected_person.relationship
        : 'Cardholder',
      comment:
        affected_person && affected_person.comment !== null
          ? affected_person.comment
          : '',
      person: getPerson(affected_person),
    },
    medical_service: {
      medical_center_name: medicalService
        ? medicalService.medical_center_name
        : '',
      medical_service_description: medicalService
        ? medicalService.medical_service_description
        : '',
      assistance_started_at: medicalService
        ? new Date(moment(medicalService.assistance_started_at, dateFormatBr))
        : '',
      assistance_finished_at: medicalService
        ? new Date(moment(medicalService.assistance_finished_at, dateFormatBr))
        : '',
      diagnosis: medicalService ? medicalService.diagnosis : '',
      medical_treatment_received: medicalService
        ? medicalService.medical_treatment_received
        : '',
    },
    existing_case: {
      reported_agent_phone: false,
      number: existingCase && existingCase.number ? existingCase.number : '',
      date: existingCase ? existingCase.date : new Date(),
    },
    attending_physician: {
      email: attendingPhysician ? attendingPhysician.email : '',
      person: {
        first_name:
          attendingPhysician && attendingPhysician.person
            ? attendingPhysician.person.first_name
            : '',
        last_name:
          attendingPhysician && attendingPhysician.person
            ? attendingPhysician.person.last_name
            : '',
      },
      phone: {
        phone_type:
          attendingPhysician && attendingPhysician.phone
            ? attendingPhysician.phone.phone_type
            : PHONE_TYPE_MOBILE,
        international_prefix:
          attendingPhysician && attendingPhysician.phone
            ? attendingPhysician.phone.international_prefix
            : '',
        number:
          attendingPhysician && attendingPhysician.phone
            ? attendingPhysician.phone.number
            : '',
      },
    },
    total_amount_claimed: {
      value: totalAmountClaimed ? totalAmountClaimed.value : '',
      currency: totalAmountClaimed ? totalAmountClaimed.currency : '',
    },
    other_insurance_covering: {
      is_internal: otherInsuranceCovering
        ? otherInsuranceCovering.is_internal
        : false,
      value:
        otherInsuranceCovering && otherInsuranceCovering.amount.value !== null
          ? otherInsuranceCovering.amount.value
          : '',
      currency:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount.currency !== null
          ? otherInsuranceCovering.amount.currency
          : '',
    },
    expenses: getExpenses(medicalService),
    amount_estimation_damage: {
      value: amountEstimationDamage ? amountEstimationDamage.value : '',
      currency: amountEstimationDamage ? amountEstimationDamage.currency : '',
    },
    expense_currency: amountEstimationDamage
      ? amountEstimationDamage.currency
      : '',
  };
};
