export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  es_ES: 'Período de viaje',
  pt_BR: 'Período da viagem',
};

export const LABEL_DATE_OF_DELAY = {
  en_US: 'Date of delay',
  es_ES: 'Fecha del retraso',
  pt_BR: 'Data de atraso',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};
