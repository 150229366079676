export const TEXT_MY_CARDS = {
  en_US: 'My cards',
  es_ES: 'Mis tarjetas',
  pt_BR: 'Meus cartões',
};

export const TEXT_CARD_IS_PREFERRED = {
  en_US: 'Setting as main Visa card',
  es_ES: 'Establecer como tarjeta Visa principal',
  pt_BR: 'Definindo como cartão Visa principal',
};

export const TEXT_CARD_MAIN_ONE = {
  en_US: card =>
    `Do you want to make this card (${card}) the main one for your Visa Benefits Portal services?`,
  es_ES: card =>
    `¿Quiere que esta tarjeta (${card}) sea la principal para tus servicios del Portal de Beneficios Visa?`,
  pt_BR: card =>
    `Deseja tornar este cartão (${card}) como principal para os seus serviços do Portal de Benefícios Visa?`,
};

export const TEXT_YES = {
  en_US: 'Yes, I do',
  es_ES: 'Sí, quiero',
  pt_BR: 'Sim, eu quero',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const CHANGING_CARD = {
  en_US: card => `Setting your card (${card}) as the main one...`,
  es_ES: card => `Estableciendo tu tarjeta (${card}) como principal...`,
  pt_BR: card => `Definindo seu cartão (${card}) como principal...`,
};

export const TEXT_CONGRATULATIONS = {
  en_US: card => `Congratulations! Your card (${card}) is now your main card.`,
  es_ES: card =>
    `¡Felicidades! Tu tarjeta (${card}) es ahora tu tarjeta principal.`,
  pt_BR: card => `Parabéns! Seu cartão (${card}) agora é o principal.`,
};

export const BANK_NAME = {
  en_US: 'Bank Name',
  es_ES: 'Nombre del Banco',
  pt_BR: 'Nome do Banco',
};

export const CARD_NAME_ARIA = {
  en_US: card => `Credit card - ${card}`,
  es_ES: card => `Tarjeta de crédito - ${card}`,
  pt_BR: card => `Cartão de crédito - ${card}`,
};
