import moment from 'moment';
import { countryNameToInitials } from '../../../../../../../intl';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../utils/environments_variables';
import {
  stringToBoolean,
  removeNonNumeric,
} from '../../../../../../../utils/stringUtils';
import { IsClaimEnableToUseMLE } from '../../../../../../../utils/verifyAccess';

export const formatPolicyToApi = (props, claimType) => {
  const holder = formatHolderToApi(props);
  const credit_card = formatCardToApi(props, claimType);
  return { holder, credit_card };
};

const formatHolderToApi = props => {
  const {
    policy,
    policy: { person: _person },
  } = props.values;

  const person = {
    title: _person.title,
    first_name: _person.first_name,
    last_name: _person.last_name,
    birth_date: moment(_person.birth_date).format('YYYY-MM-DD'),
    gender: _person.gender,
    nationalities: [policy.nationalities],
  };

  delete policy.address.invalidCep;
  if (
    ALPHANUMERIC_COUNTRY_LIST &&
    !ALPHANUMERIC_COUNTRY_LIST.includes(policy.address.country)
  ) {
    policy.address.postal_code = removeNonNumeric(policy.address.postal_code);
  }

  const filteredRegistrations = policy.person_registrations.filter(
    registration =>
      registration.value !== '' && registration.registration_type !== ''
  );

  const formatedRegistrations = filteredRegistrations.map(registration => {
    const value =
      registration.registration_type === 'CPF'
        ? removeNonNumeric(registration.value)
        : registration.value;

    return {
      ...registration,
      value,
    };
  });

  const holder = {
    customer_id: props.cn,
    is_politically_exposed: stringToBoolean(policy.politicallyExposed),
    profession: policy.profession,
    person,
    address: policy.address,
    Phone: policy.phone,
    registrations: formatedRegistrations,
  };
  if (policy.salary) {
    holder.monthly_income = {
      value: policy.salary,
      currency: 'BRL',
    };
  }

  return holder;
};

const formatCardToApi = (props, claimType) => {
  const { selectedCard, values } = props;

  const claimEnableToUseMLE =
    process.env.REACT_APP_JWE_CLAIMS_API_ENABLE.toString() === 'true' &&
    IsClaimEnableToUseMLE(claimType);

  return {
    expedition_country: countryNameToInitials(selectedCard.country),
    issuer_name: selectedCard.issuer_name,
    first_10_digits: claimEnableToUseMLE ? undefined : props.bin,
    last_4_digits: values.policy.bank.last_number,
  };
};
