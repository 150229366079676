import { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as translations from './intl';
import styles from './Monetization.module.scss';
import { IntlContext } from '../../../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { formatDateFromString } from '../../../utils/date';
import TotalReimbursed from '../../../components/TotalReimbursed/TotalReimbursed';
import { CERTIFICATES } from '../../../utils/consts';
import ChevronGeneric from '../../../assets/icons/ChevronGeneric';
import Pagination from '../Monetization/Pagination/Pagination';
import Loading from '../../../components/Loading/Loading';
import TitleSectionProfile from '../../../components/TitleSectionProfile/TitleSectionProfile';
import StatusChip from '../../../componentsV2/Chip/StatusChip/StatusChip';

const sortFun = (a, b) => {
  if (a.info.date < b.info.date) {
    return 1;
  }
  if (a.info.date > b.info.date) {
    return -1;
  }

  // names must be equal
  return 0;
};

const MonetizationCertificates = props => {
  const { userCertificates } = props;

  const [isMobile, setIsMobile] = useState(false);
  const [lastShowIndex, setLastShowIndex] = useState(0);
  const [total, setTotal] = useState(() => parseFloat(0).toFixed(2));
  const [loadingCertificate, setLoadingCertificate] = useState(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [allTotalCertificate, setAllTotalCertificate] = useState([]);
  const [allTotal, setAllTotal] = useState([]);
  const [page, setpage] = useState(1);

  const { translate, idiom } = useContext(IntlContext);

  const themes = getGlobalTheme();
  const intl = translate(translations);
  const amountItemsByPage = 10;

  const checkWindowWidth = () => {
    if (window.screen.width > 600 && window.innerWidth > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const returnAllCertificate = certificates => {
    setLoadingCertificate(true);
    const temp = certificates.sort(sortFun);
    setAllTotalCertificate(temp);
    setLoadingCertificate(false);
  };

  const header = (
    <tr className={`row ${styles.header} ${styles[themes]}`}>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_PRODUCT}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_TICKET_NUMBER}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_STATUS}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.TEXT_DATE}
      </th>
      <th className={`col-2 ${styles.cell} ${styles[themes]}`}>
        {intl.PRODUCT_VALUE}
      </th>
    </tr>
  );

  const headerMobile = (
    <tr className={`row ${styles.header} ${styles[themes]}`}>
      <th
        className={`col-10 ${styles.cellMobile} ${styles[themes]} ${styles.cellMobilePaddingLeft}`}
      >
        {intl.TEXT_PRODUCT}
      </th>
    </tr>
  );
  const filterData = data => {
    const filteredData = data.filter(i => {
      return Number(i.value) > 0;
    });

    return filteredData;
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  useEffect(() => {
    const filteredDate = filterData([
      ...JSON.parse(JSON.stringify(allTotalCertificate)),
    ]);
    setAllTotal(filteredDate);
  }, [allTotalCertificate]);

  useEffect(() => {
    window.scrollTo({
      top: isMobile
        ? window.innerHeight - window.innerHeight * 0.1
        : window.innerHeight - window.innerHeight * 0.6,
      behavior: 'smooth',
    });
  }, [page]);

  const clickExpand = index => {
    const base = amountItemsByPage * (page - 1);
    const copy = [...JSON.parse(JSON.stringify(allTotal))];
    copy[index + base].expand = !copy[index + base].expand;
    if (lastShowIndex !== index + base && lastShowIndex < copy.length) {
      copy[lastShowIndex].expand = false;
    }
    setLastShowIndex(index + base);
    const filteredDate = filterData(copy);
    setAllTotal(filteredDate);
  };

  const content = allTotal
    .slice(amountItemsByPage * (page - 1), amountItemsByPage * page)
    .map((item, index) => {
      const formatDate = formatDateFromString(item.info.date, idiom);
      return (
        <tr className={`row ${styles.item} ${styles[themes]}`} key={index}>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.info.referenceName}
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.info.ticket_number.substring(14, 23)}
          </td>
          <td
            className={`col-2 ${styles.cell} ${styles.status} ${styles[themes]}`}
          >
            <StatusChip
              context="MONETIZATION"
              status={item.info.status?.toUpperCase()}
            />
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {formatDate}
          </td>
          <td className={`col-2 ${styles.cell} ${styles[themes]}`}>
            {item.currencyInitialsCode}
            {item.value.toFixed(2).split('.')[0]}
            <span className={`${styles.decimal}`}>
              {`.${item.value.toFixed(2).split('.')[1]}`}
            </span>
          </td>
        </tr>
      );
    });

  const contentMobile = allTotal
    .slice(amountItemsByPage * (page - 1), amountItemsByPage * page)
    .map((item, index) => {
      const formatDate = formatDateFromString(item.info.date, idiom);
      return (
        <>
          <tr
            className={`row ${styles.itemMobile} ${
              item.expand ? styles.NoBottomBorder : ''
            } ${styles[themes]}`}
            key={index}
          >
            <td className={`col-8 ${styles.cellMobile} ${styles[themes]}`}>
              {item.info.referenceName}
            </td>
            <td
              className={`col-2 ${styles.cellMobileEnd} ${styles[themes]} ${
                item.expand ? styles.rotateIcon : ''
              }`}
              onClick={_ => {
                clickExpand(index);
              }}
            >
              <ChevronGeneric aria-label={intl.IMAGE_ARROW} />
            </td>
          </tr>
          {item.expand && (
            <tr className={`row ${styles.itemMobile} ${styles[themes]}`}>
              <div className={`row ${styles.itemExpand}`}>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_TICKET_NUMBER_LABEL}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    {item.info.ticket_number.substring(14, 23)}
                  </div>
                </div>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_STATUS}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    <StatusChip
                      context="MONETIZATION"
                      status={item.info.status?.toUpperCase()}
                    />
                  </div>
                </div>
              </div>
              <div className={`row ${styles.itemExpand}`}>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.TEXT_DATE}
                  </div>
                  <div className={`${styles.contentRow}`}>{formatDate}</div>
                </div>
                <div className={`col-6`}>
                  <div className={`${styles[themes]} ${styles.label}`}>
                    {intl.PRODUCT_VALUE}
                  </div>
                  <div className={`${styles.contentRow}`}>
                    {item.currencyInitialsCode}
                    {item.value.toFixed(2)}
                  </div>
                </div>
              </div>
            </tr>
          )}
        </>
      );
    });

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <div className={`row ${styles.smallPaddingBottom} ${styles[themes]}`}>
        <div
          className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles[themes]}`}
          id="certificates"
        >
          <TitleSectionProfile
            title={intl.TITLE_CERTIFICATES_MONETIZATION}
            subtitle={intl.SUBTITLE_CERTIFICATES_MONETIZATION}
            loading={false}
          />
          <TotalReimbursed
            bottom
            type={CERTIFICATES}
            certificateData={userCertificates}
            shouldShowTooltip
            returnAllCertificate={returnAllCertificate}
            setTotalMonetization={setTotal}
          />
        </div>
      </div>
      {loadingCertificate && (
        <div className={`row ${styles.center}`}>
          <div
            className={`col-lg-3 col-md-3 col-sm-3 col-xs-3 ${styles.center} ${styles[themes]}`}
          >
            <Loading />
          </div>
        </div>
      )}
      {!loadingCertificate && Number(total) > 0 && (
        <div className={`row ${styles.center} ${styles[themes]}`}>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.center} ${styles[themes]}`}
          >
            <table className={styles.table}>
              <thead>{isMobile ? headerMobile : header}</thead>
              <tbody className={styles.body}>
                {isMobile ? contentMobile : content}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!loadingCertificate && Number(total) > 0 && (
        <div className={`row ${styles.center}`}>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.pagesLabel} ${styles[themes]}`}
          >
            {intl.TEXT_PAGE} {page} {intl.TEXT_PAGEOF}
            {` ${Array(Math.ceil(allTotal.length / amountItemsByPage)).length}`}
          </div>
          <div
            className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${styles.center} ${styles[themes]}`}
          >
            <Pagination
              data={allTotal}
              amountItemsByPage={amountItemsByPage}
              setPage={setpage}
              page={page}
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(MonetizationCertificates);
