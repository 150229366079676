import { nurseService } from '../../../../services';

export const loadConfigAppointmentNAL = async setConfigAppointment => {
  try {
    const responses = await nurseService.getDataConfig();
    const data = responses.data;
    setConfigAppointment(data);
  } catch (error) {
    setConfigAppointment('ERROR');
  }
};
