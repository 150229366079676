import { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { IntlContext } from '../../intl';
import styles from './MenuNavigation.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import {
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
} from '../../utils/environments_variables';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';
import SearchBar from '../SearchBar/SearchBar';
import { StateContext } from '../StateContextParent/StateContextParent';
import { isBinNotActived } from '../../utils/card';
import { productsAndBenefitsServices } from '../../services';
import { PublicContext } from '../PublicContextParent/PublicContextParent';
import { joinClassNames } from '../../utils/classNames';

const MenuNavigation = ({ color = '#FFFFFF', colorSearch, isLogged }) => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { productActive } = useContext(PublicContext);
  const {
    utils: { getSelectedCard, getBin },
  } = useContext(StateContext);
  const binOrPan = getBin(true);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const selectedCard = getSelectedCard();
  const binNotActived = isBinNotActived(selectedCard);
  const [benefitsList, setBenefitsList] = useState([]);
  const language = idiomForApi();

  const className = joinClassNames(
    styles[theme],
    color === '#FFFFFF' ? undefined : styles.inverse
  );
  const activeClassName = joinClassNames(styles[theme], styles.active);

  const loadBenefitData = async () => {
    const product = binOrPan || productActive.product_id;
    if (!product) return;
    try {
      const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
        product,
        language,
        false,
        false
      );
      let aux = [];
      benefits &&
        benefits.data &&
        benefits.data.forEach(benefit => {
          aux.push({
            title: benefit.name,
            key: 'benefit',
            link: isLogged
              ? `/your-benefits/${benefit.benefit_id}`
              : `/benefits/${productActive.product_id}/${benefit.benefit_id}`,
          });
        });
      setBenefitsList(aux);
    } catch (error) {
      setBenefitsList([]);
    }
  };

  useEffect(() => {
    loadBenefitData();
  }, [binOrPan, productActive, idiomForApi()]);

  return (
    <nav
      className={`${styles[theme]} ${styles.menuNavigation}`}
      style={{ color: color }}
    >
      <NavLink
        style={{ color: color }}
        onClick={() => dataLayerContentMenuTrack('CTA Home')}
        to={`${isLogged ? '/dashboard' : '/'}`}
        exact
        className={`${className} hideOnMobile`}
        activeClassName={activeClassName}
      >
        {intl.TEXT_HOME}
      </NavLink>
      {!binNotActived && (
        <NavLink
          style={{ color: color }}
          id="benefits-headerOption"
          onClick={() => dataLayerContentMenuTrack('CTA Benefits')}
          className={`${className} ${isLogged ? 'hideOnMobile' : ''}`}
          to={`${isLogged ? '/your-benefits' : '/benefits'}`}
          activeClassName={activeClassName}
        >
          {intl.TEXT_BENEFITS}
        </NavLink>
      )}
      {ENABLE_OFFERS && (
        <>
          {!isLogged && (
            <NavLink
              style={{ color: color }}
              onClick={() => dataLayerContentMenuTrack('CTA Offers')}
              to={'/offers'}
              className={`${styles.menuOffer} ${className} showOnMobile`}
              activeClassName={activeClassName}
            >
              {intl.TEXT_OFFERS}
            </NavLink>
          )}

          <NavLink
            style={{ color: color }}
            onClick={() => dataLayerContentMenuTrack('CTA Offers')}
            to={isLogged ? '/your-offers' : '/offers'}
            className={`${className} hideOnMobile`}
            activeClassName={activeClassName}
          >
            {intl.TEXT_OFFERS_PROMOTIONS}
          </NavLink>
        </>
      )}
      {ENABLE_SPECIAL_BENEFITS && !binNotActived && (
        <NavLink
          style={{ color: color }}
          id="SubscriptionBenefits-headerOption"
          onClick={() => dataLayerContentMenuTrack('CTA Special Benefits')}
          to={
            isLogged ? '/your-subscription-benefits' : '/subscription-benefits'
          }
          className={`${className} hideOnMobile`}
          activeClassName={activeClassName}
        >
          {intl.TEXT_SPECIAL_BENEFITS}
        </NavLink>
      )}
      {isLogged && (
        <>
          {!binNotActived && (
            <NavLink
              style={{ color: color }}
              id="certificates-headerOption"
              onClick={() => dataLayerContentMenuTrack('CTA Certificates')}
              className={`${className} ${isLogged ? 'hideOnMobile' : ''}`}
              to="/certificates"
              activeClassName={activeClassName}
            >
              {intl.TEXT_CERTIFICATES}
            </NavLink>
          )}
          <NavLink
            style={{ color: color }}
            id="claims-headerOption"
            onClick={() => dataLayerContentMenuTrack('CTA Claims')}
            className={`${className} ${isLogged ? 'hideOnMobile' : ''}`}
            to="/claims"
            activeClassName={activeClassName}
          >
            {intl.TEXT_CLAIMS}
          </NavLink>
        </>
      )}
      <NavLink
        style={{ color: color }}
        id="help-headerOption"
        onClick={() => dataLayerContentMenuTrack('CTA Help')}
        to="/help"
        className={`${className} ${styles.borderHelp} hideOnMobile`}
        activeClassName={activeClassName}
      >
        {intl.TEXT_HELP}
      </NavLink>
      <SearchBar
        placeholder={intl.PLACEHOLDER_SEARCH_BAR}
        color={colorSearch}
        additionalData={benefitsList}
      />
    </nav>
  );
};

export default MenuNavigation;
