import { useTheme } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../../../componentsV2/Button/Button';
import styles from './index.module.scss';
import { IconType } from 'react-icons';

interface FeedbackButtonProps {
  Icon: IconType;
  onClick?: () => void;
  disabled?: boolean;
}

const FeedbackButton = ({ Icon, onClick, disabled }: FeedbackButtonProps) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <Button
      type="Icon"
      onClick={onClick}
      disabled={disabled}
      leadingIcon={
        <Icon
          size={24}
          className={`${styles[theme]} ${
            disabled ? styles.feedbackIconDisabled : styles.feedbackIcon
          }`}
        />
      }
    />
  );
};

export default FeedbackButton;
