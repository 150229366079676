import React, { useContext } from 'react';
import styles from './CardItem.module.scss';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const CardItem = props => {
  const { card, index, onCardSelected, isSelected } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const cardImg = card.images.find(image => image.type === 'PICTURE');

  return (
    <li>
      <button
        className={`${styles.cardItem} ${styles[theme]}
        ${isSelected ? `${styles.borderGold} ${styles[theme]}` : ''}`}
        aria-label={`Credit Card - ${card.name}`}
        onClick={() => {
          onCardSelected(index);
        }}
      >
        <img src={cardImg && cardImg.content_url} alt={card.name} />
        <div className={styles.infoCard}>
          <div className={styles.bin}>
            <strong>
              <span>{card.name}</span>
            </strong>
          </div>
        </div>
      </button>
    </li>
  );
};

export default withRouter(CardItem);
