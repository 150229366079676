import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const LockIcon = (props: SVGIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={35}
      viewBox="0 0 30 35"
      fill="none"
      {...handledProps}
    >
      <path
        fill={colors.secondary}
        d="M28.443 14.592H25.91V9.4C25.911 4.217 21.02 0 15 0 8.981 0 4.089 4.22 4.089 9.407v5.185H1.585c-.867 0-1.585.593-1.585 1.34v15.684c0 1.864 1.776 3.379 3.941 3.379h22.146c2.165 0 3.913-1.515 3.913-3.379V15.932c0-.747-.69-1.34-1.557-1.34ZM7.227 9.407c0-3.696 3.486-6.703 7.773-6.703 4.287 0 7.773 3.004 7.773 6.696v5.192H7.227V9.407Zm19.635 22.21c0 .372-.342.677-.775.677H3.94c-.433 0-.803-.305-.803-.678V17.293h23.724v14.323Z"
      />
      <path
        fill={colors.secondary}
        d="M15 28.845c.867 0 1.569-.605 1.569-1.352v-4.868c0-.747-.702-1.352-1.569-1.352s-1.569.605-1.569 1.352v4.868c0 .747.702 1.352 1.569 1.352Z"
      />
    </svg>
  );
};
export default LockIcon;
