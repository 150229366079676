import { claimsServices, productsAndBenefitsServices } from '../../../services';

export const loadClaim = async (claimId, idiom, setClaim) => {
  try {
    const { data } = await claimsServices.getClaimInfoByClaimId(claimId, idiom);
    setClaim(data);
  } catch (error) {
    setClaim('ERROR');
  }
};

export const loadBenefit = async (binOrPan, claimType, setBenefit, idiom) => {
  try {
    const benefit =
      await productsAndBenefitsServices.getBenefitsDetailByProduct(
        binOrPan,
        claimType,
        idiom
      );
    setBenefit(benefit.data);
  } catch (error) {
    setBenefit('ERROR');
  }
};
