export const TEXT_TRIP_START_DATE = {
  en_US: 'Trip start date *',
  es_ES: 'Fecha de inicio del viaje *',
  pt_BR: 'Data de início da viagem * ',
};

export const TEXT_TRIP_END_DATE = {
  en_US: 'Trip end date *',
  es_ES: 'Fecha de fin del viaje *',
  pt_BR: 'Data de fim da viagem * ',
};

export const TEXT_FLIGHT_NUMBER = {
  en_US: 'Include flight number or vessel name or registry code',
  es_ES: 'Número de vuelo o nombre del barco o código de registro',
  pt_BR: 'Número de vôo ou nome da embarcação ou código de registro ',
};

export const TEXT_CANCELLATION_DATE = {
  en_US: 'Cancellation date *',
  es_ES: 'Fecha de cancelación *',
  pt_BR: 'Data de cancelamento da viagem * ',
};

export const TEXT_CANCELLATION_REASON = {
  en_US: 'Cancellation reason',
  es_ES: 'Motivo de la cancelación',
  pt_BR: 'Causa do cancelamento',
};

export const CANCELLATION_REASON_OPTIONS = {
  en_US: `
  <option value="TID"> Theft of identification documents </option>
  <option value="FRA"> Formal requirement to join the armed forces </option>
  <option value="SIA"> Serious illness, accidental injury or death </option>
  <option value="SLH"> Serious loss in home or business </option>
  <option value="CRP"> Complications related to pregnancy </option>
  <option value="SPW"> Summons as a party or witness before a court </option>
  <option value="DSD"> Demand for separation/divorce </option>
  <option value="LLE"> Loss or layoff of employment </option>
  <option value="SAI"> Sports accident or injury </option>
  <option value="TPC"> Total/partial closure of the airport or control tower </option>
  <option value="CCD"> Critical condition or death of the pet </option>
  <option value="HTR"> Hospitalization and travel restrictions for Psychological Disorders </option>`,
  pt_BR: `
  <option value="TID"> Roubo de documentos de identificação </option>
  <option value="FRA"> Requisito formal para ingresso nas forças armadas </option>
  <option value="SIA"> Doença grave, lesão acidental ou morte </option>
  <option value="SLH"> Perda grave em casa ou empresa </option>
  <option value="CRP"> Complicações relacionadas com a gravidez </option>
  <option value="SPW"> Citação como parte ou testemunha perante um tribunal </option>
  <option value="DSD"> Pedido de separação/divórcio </option>
  <option value="LLE"> Perda ou dispensa do emprego </option>
  <option value="SAI"> Acidente ou lesão esportiva </option>
  <option value="TPC"> Fechamento total/parcial do aeroporto ou torre de controle </option>
  <option value="CCD"> Condição crítica ou morte do animal de estimação </option>
  <option value="HTR"> Hospitalização e restrições de viagem por Distúrbios Psicológicos </option>`,
  es_ES: `
  <option value="TID"> Robo de documentos de identificación </option>
  <option value="FRA"> Exigencia formal de unirse a las fuerzas armadas </option>
  <option value="SIA"> Enfermedad grave, lesión accidental o muerte </option>
  <option value="SLH"> Pérdida grave en hogar o negocio </option>
  <option value="CRP"> Complicaciones relacionadas a embarazo </option>
  <option value="SPW"> Citación como parte o testigo ante un tribunal </option>
  <option value="DSD"> Demanda de separación/divorcio </option>
  <option value="LLE"> Pérdida o despido de empleo </option>
  <option value="SAI"> Accidente o lesión deportiva </option>
  <option value="TPC"> Cierre total/parcial del aeropuerto o torre de control </option>
  <option value="CCD"> Estado crítico o muerte de la mascota </option>
  <option value="HTR"> Hospitalización y restricciones de viaje por Trastornos Psicológicos </option>`,
};

export const TIP_BRIEF_DESCRIBE_INCIDENT = {
  en_US:
    'Keep in mind that this list shows the reasons for the cancellation of your trip that are covered under the insurance in a summarized way. Please refer to the terms and conditions for more details.',
  es_ES:
    'Tenga en cuenta que esta lista muestra los motivos de lá cancelación de tu viaje que se cubren bajo el seguro de forma resumida.  Por favor referirse a los términos y condiciones para más de detalles.',
  pt_BR:
    'Lembre-se de que esta lista mostra de forma resumida os motivos do cancelamento da sua viagem que estão cobertos pelo seguro. Por favor, consulte os termos e condições para mais detalhes.',
};

export const TEXT_DATE_INCIDENT = {
  en_US: 'Incident date *',
  es_ES: 'Fecha del incidente *',
  pt_BR: 'Data do incidente *',
};
