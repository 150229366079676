import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const EditIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        transform="matrix(1 -.00641 .00641 1 -.139 .078)"
        style={{
          fill: config_icon.secondary,
        }}
        d="M2 20.7h20v2H2z"
      />
      <path
        d="M6.2 18.7H2v-4.2L14.4 2.1c1.1-1.1 3.1-1.1 4.2 0 .6.6.9 1.3.9 2.1s-.3 1.6-.9 2.1L6.2 18.7zm-2.2-2h1.4L17.2 4.9c.2-.2.3-.4.3-.7 0-.3-.1-.5-.3-.7-.4-.4-1-.4-1.4 0L4 15.3v1.4z"
        style={{
          fill: config_icon.primary,
        }}
      />
    </svg>
  );
};

export default EditIcon;
