export const STATUS_UNREAD = 'UNREADED';
export const STATUS_READED = 'was_read';
export const STATUS_ARCHIVED = 'was_archived';
export const LOCATION_INBOX = 'INBOX';
export const LOCATION_HEADER = 'ALERT';
export const RELATED_CLAIMS = 'CLAIMS';
export const RELATED_CERTIFICATES = 'CERTIFICATES';

export const NOTIFICATION = 'NOTIFICATION';
export const ARCHIVED = 'ARCHIVED';
