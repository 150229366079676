import { joinClassNames } from '../../utils/classNames';
import { LinkProps } from './Link.types';
import styles from './Link.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { AnchorImplementation, ButtonImplementation } from './implementations';
import { MouseEventHandler } from 'react';

const Link = ({
  label,
  ariaLabel,
  button,
  className,
  disabled = false,
  href,
  icon = true,
  id,
  internal,
  onClick,
  surface = 'OnSurface',
  target,
  typeHtml,
  axa,
}: LinkProps) => {
  const { getGlobalTheme } = useTheme(axa);
  const theme = getGlobalTheme();

  const getClassname = () =>
    joinClassNames(
      styles.link,
      surface === 'OnSurface' ? styles.onSurface : styles.inverse,
      disabled ? styles.disabled : undefined,
      styles[theme],
      className
    );

  const commomProps = {
    className: getClassname(),
    icon,
    surface,
    ariaLabel,
    disabled,
    id,
    label,
    axa,
  };

  return button === true ? (
    <ButtonImplementation
      {...commomProps}
      onClick={onClick as unknown as MouseEventHandler<HTMLButtonElement>}
      typeHtml={typeHtml}
    />
  ) : (
    <AnchorImplementation
      {...commomProps}
      href={href}
      internal={internal}
      onClick={onClick as MouseEventHandler<HTMLAnchorElement>}
      target={target}
    />
  );
};

Link.displayName = 'Link';

export default Link;
export * from './Link.types';
