import React, { useContext } from 'react';
import styles from './CardItem.module.scss';
import Cards from '../../../assets/images/cards_2.svg';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const changeCard = (setListCards, listCards, binOrPan) => {
  let findIndexCard = 0;
  if (binOrPan.length === 16) {
    findIndexCard = listCards.data.findIndex(card => {
      return card.credit_card_token === binOrPan;
    });
  } else {
    findIndexCard = listCards.data.findIndex(card => {
      return card.external_reference === binOrPan;
    });
  }
  setListCards({ ...listCards, selectedCardIndex: findIndexCard });
};

const CardItem = props => {
  const { card, setOpenSubMenu, index, setListCards, listCards, setBinOrPan } =
    props;
  const { state, actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { user } = state;
  const { cn } = user.data.userInfo;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const is_preferred =
    index === 0 ? `${styles[theme]} ${styles.borderGold}` : '';
  const mask_external_reference = card.external_reference.replace(
    new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
    '$1.****.$3**.****'
  );
  const image = card.image && card.image.length > 0 ? card.image : Cards;

  return (
    <li>
      <div
        className={`${styles[theme]} ${styles.cardItem} ${is_preferred}`}
        aria-label={intl.CARD_NAME_ARIA(`${card.name}`)}
        onClick={() => {
          const binOrPan = card.credit_card_token || card.external_reference;
          setOpenSubMenu(false);
          changeCard(setListCards, listCards, binOrPan);
          setBinOrPan(binOrPan);
        }}
      >
        <img src={image} alt={card.name} />

        <div className={styles.infoCard}>
          <div className={styles.bin}>
            <strong>
              <span>{card.name}</span> - {mask_external_reference}
            </strong>
          </div>

          <div className={styles.bank} aria-label={intl.BANK_NAME}>
            {card.issuer_name}
          </div>
        </div>
      </div>
    </li>
  );
};

export default withRouter(CardItem);
