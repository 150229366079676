import LogoVisa from './LogoVisa/LogoVisa';
import LogoABCBank from './LogoABCBank/LogoABCBank';

const Logo = ({ color = '#FFF', size = 'BIGGER', className = undefined }) => {
  const domain = window.location.hostname
    ? window.location.hostname.split('.')[0]
    : false;

  return (
    <>
      {domain && domain.includes('portobank') ? (
        <LogoABCBank size={size} className={className} />
      ) : (
        <LogoVisa color={color} size={size} className={className} />
      )}
    </>
  );
};

export default Logo;
