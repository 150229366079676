import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { pt_BR } from '../../../../../../../intl/idioms';
import { userServices } from '../../../../../../../services';
import { removeNonNumeric } from '../../../../../../../utils/stringUtils';
import ModalInformation from '../../../../../../../components/ModalInformation/ModalInformation';
import moment from 'moment';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../utils/environments_variables';
import { isIssuerDomain } from '../../../../../../../utils/banks';

const MSG_ERROR_CPF = 'Duplicated Customer Registration';

const cardHolderData = (cn, values) => {
  // Nationalities
  let nationalities = [values.nationalities];

  // Person
  let person = values.person;
  person.birth_date = moment(person.birth_date).format('YYYY-MM-DD');
  person.nationalities = nationalities;

  // Registrations
  let registrations = [];
  if (Array.isArray(values.person_registrations)) {
    const filteredRegistrations = values.person_registrations.filter(
      registration =>
        registration.registration_type !== '' && registration.value !== ''
    );
    registrations = filteredRegistrations.map(registration => {
      let isCPF = registration.registration_type === 'CPF';

      return {
        ...registration,
        value: isCPF
          ? removeNonNumeric(registration.value)
          : registration.value,
      };
    });
  } else {
    let isCpf = values.person_registrations.registration_type === 'CPF';
    let registrationValue = isCpf
      ? removeNonNumeric(values.person_registrations.value)
      : values.person_registrations.value;

    registrations = [
      {
        registration_type: values.person_registrations.registration_type,
        value: registrationValue,
      },
    ];
  }

  delete values.address.invalidCep;
  if (
    ALPHANUMERIC_COUNTRY_LIST &&
    !ALPHANUMERIC_COUNTRY_LIST.includes(values.address.country)
  ) {
    values.address.postal_code = removeNonNumeric(values.address.postal_code);
  }

  // Card holder
  let data = {
    address: values.address,
    customer_id: cn,
    is_politically_exposed: values.politicallyExposed === 'true' ? true : false,
    partner_customer_id: '001',
    person: person,
    phone: values.phone,
    registrations: registrations,
  };

  return data;
};

export const checkGeneralInfoChange = async props => {
  // Don't ask to update customer in BMP
  if (isIssuerDomain()) return;

  const {
    initalValueCompare: { policy: initalValueCompare, reimbursement },
    values: { policy: values },
    actions,
    intl,
    cn,
    setInitialValueCompare,
    trackEventUserAction,
  } = props;

  delete values.address.invalidCep;
  delete initalValueCompare.address.invalidCep;

  values.phone.international_prefix = removeNonNumeric(
    values.phone.international_prefix
  );
  initalValueCompare.phone.international_prefix = removeNonNumeric(
    initalValueCompare.phone.international_prefix
  );

  const cloned = cloneDeep(values);

  if (
    ALPHANUMERIC_COUNTRY_LIST &&
    !ALPHANUMERIC_COUNTRY_LIST.includes(cloned.address.country)
  ) {
    cloned.address.postal_code = removeNonNumeric(cloned.address.postal_code);
  }
  const changed = !isEqual(initalValueCompare, cloned);

  trackEventUserAction(
    '#### (CLAIM) CLICOU NO SUBMIT -- CHECK GENERAL INFO CHANGE ####',
    { initalValueCompare, cloned, changed: changed }
  );

  if (changed) {
    const savePromisse = new Promise((resolve, reject) => {
      const updateCardHolder = async () => {
        const cardHolder = cardHolderData(cn, values);
        try {
          actions.modal.showLoading(intl.SAVING_PERSONAL_DETAILS);
          trackEventUserAction(
            '#### (CLAIM) CLICOU NO SUBMIT -- SALVANDO NOVAS INFORMAÇÕES DO CARDHOLDER ####',
            cardHolder
          );
          await userServices.updateCHInfo(cn, cardHolder);
          trackEventUserAction(
            '#### (CLAIM) CLICOU NO SUBMIT -- SALVOU AS INFORMAÇÕES do CARDHOLDER ####',
            cardHolder
          );
          initalValueCompare.policy = values;
          initalValueCompare.reimbursement = reimbursement;
          setInitialValueCompare(initalValueCompare);
          actions.modal.hideLoading();
          actions.modal.showModal(
            false,
            true,
            <ModalInformation
              type="success"
              message={intl.TEXT_CH_CHANGE_SUCCESS}
            />,
            true,
            false
          );
        } catch (e) {
          trackEventUserAction(
            '#### (CLAIM) CLICOU NO SUBMIT -- ERROR AO SALVAR AS INFORMAÇÕES DO CARDHOLDER ####',
            { error: e, error_response: e && e.response ? e.response : '' }
          );
          actions.modal.hideLoading();
          if (e.response.data.error_description === MSG_ERROR_CPF) {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="error"
                message={intl.ERROR_CUSTOMER_DUPLICATED}
              />,
              true,
              false
            );
          } else if (e.response.status === 504) {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation type="error" message={intl.ERROR_TIME_OUT} />,
              true,
              false
            );
          } else {
            actions.modal.showModal(
              false,
              true,
              <ModalInformation
                type="error"
                message={intl.ERROR_CUSTOMER_UPDATE}
              />,
              true,
              false
            );
          }
          reject();
        } finally {
          resolve();
        }
      };

      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="question"
          message={intl.TEXT_CH_CHANGE}
          textBtn={intl.TEXT_YES_I_DO}
          textBtnDeny={intl.TEXT_NO}
          clickBtn={updateCardHolder}
          clickBtnDeny={() => {
            resolve();
            actions.modal.closeModal();
          }}
        />,
        true,
        false
      );
    });

    return savePromisse;
  }
};
