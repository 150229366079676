import React from 'react';
import { cotizarService } from '../../../../services/index';
import { formatQuoteToApi } from '../../Quote/FormQuote/submit/format/formatQuote';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import PlanVerification from './PlanVerification/PlanVerification';
import { dataLayerB2B2CTravel } from '../../../../utils/GTM_helper';
import moment from 'moment';

const verifyChangedAge = (quote, values) => {
  const { Quote } = quote;
  const Passenger =
    values.additionalTravelers && values.additionalTravelers.Passenger
      ? values.additionalTravelers.Passenger
      : [];
  const { MPH } = values.generalInformation;

  const valuesStep2 = [{ ...MPH }, ...Passenger];

  let numberOfGreather70Quote = 0;
  let numberOfLess70Quote = 0;
  for (let index = 0; index < Quote.Ages.length; index++) {
    if (Number(Quote.Ages[index].value) >= 70) {
      numberOfGreather70Quote++;
    } else {
      numberOfLess70Quote++;
    }
  }

  let numberOfGreather70Values = 0;
  let numberOfLess70Values = 0;
  for (let index = 0; index < valuesStep2.length; index++) {
    if (Number(valuesStep2[index].Age) >= 70) {
      numberOfGreather70Values++;
    } else {
      numberOfLess70Values++;
    }
  }

  if (
    numberOfGreather70Quote === numberOfGreather70Values &&
    numberOfLess70Quote === numberOfLess70Values
  ) {
    return false;
  } else {
    return true;
  }
};

const createNewQuote = async (quote, formType) => {
  const props = {
    formType,
    values: { ...quote },
  };
  const formatedQuote = formatQuoteToApi(props);
  const response = await cotizarService.createCotizar(
    formatedQuote,
    props.formType
  );
  return response.data;
};

export const submitStep2 = async props => {
  const {
    quote,
    values,
    actionsB2B2C,
    utilsB2B2C,
    actions,
    nextStep,
    selectedPlan,
    intl,
    formType,
    setStep,
    openTargetAcordeon,
    trackEventUserAction,
  } = props;
  try {
    const { trip } = values;
    const passengersValues = { ...values };

    const travelData = {
      origin_country: trip.Quote.CountryOfResidenceIsoCode,
      destination_country: trip.Quote.DestinationCountryIsoCode,
      departure_date: trip.Quote.TripInitialDate,
      return_date: trip.Quote.TripEndDate,
      total_days: moment(trip.Quote.TripEndDate).diff(
        moment(trip.Quote.TripInitialDate),
        'days'
      ),
      items: [
        {
          item_name: selectedPlan.Name,
          item_id: selectedPlan.Id,
          item_brand: 'AXA',
        },
      ],
    };

    dataLayerB2B2CTravel(travelData);

    const ageChanged = verifyChangedAge(quote, values);

    if (ageChanged) {
      trackEventUserAction(
        `#### (B2B2C) INFORMAÇÕES DE VIAGEM TROCA DE IDADE`,
        { ageChanged }
      );
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.SAVING_INFORMATIONS,
        true
      );
      const newQuote = JSON.parse(JSON.stringify({ ...quote }));

      const formatedAgeMPH = {
        value: passengersValues.generalInformation.MPH.Age,
        index: 0,
      };
      const formatedAgeAdditionalTravelers =
        passengersValues.additionalTravelers
          ? passengersValues.additionalTravelers.Passenger.map(
              (passenger, index) => ({ value: passenger.Age, index: index + 1 })
            )
          : [];

      const newAges = [formatedAgeMPH, ...formatedAgeAdditionalTravelers];
      newQuote.Quote.Ages = newAges;
      const plansWithNewQuote = await createNewQuote(newQuote, formType);

      const planSelectedWithNewQuote =
        plansWithNewQuote.QuoteVISA.ProductVISA.find(
          product => product.Product.Name === selectedPlan.Name
        );

      trackEventUserAction(
        `#### (B2B2C) INFORMAÇÕES DE VIAGEM TROCA DE IDADE - NEW QUOTE`,
        { newAges, planSelectedWithNewQuote }
      );

      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          message={intl.CHANGE_VALUE_OF_THE_PLAN(planSelectedWithNewQuote)}
          subtitle={intl.CHANGE_AGE}
          textBtn={intl.YES_I_WANT}
          clickBtn={() => {
            actionsB2B2C.expire.setExpire();
            actionsB2B2C.formDataCotizar.setQuote({ ...newQuote });
            actionsB2B2C.formDataCotizar.setPlans(
              plansWithNewQuote.QuoteVISA || plansWithNewQuote.QuoteUS
            );
            actionsB2B2C.formDataCotizar.setSelectedPlan({
              ...planSelectedWithNewQuote.Product,
              Document: planSelectedWithNewQuote.Document,
              BenefitVISA: planSelectedWithNewQuote.BenefitVISA,
            });

            trackEventUserAction(`#### (B2B2C) INDO PARA O STEP 5`, null);
            nextStep();
            actions.modal.closeModal();
          }}
          buttonConfirmType="axaBlue"
          textBtnDeny={intl.BACK_TO_QUOTE}
          clickBtnDeny={() => {
            actionsB2B2C.formDataCotizar.setDataForm();
            utilsB2B2C.clearLocalStore();
            setStep(1);
            actions.modal.closeModal();
          }}
          buttonDenyType="axaBlueWithoutBorder"
          textBtnThird={intl.BTN_EDIT}
          clickBtnThird={() => {
            openTargetAcordeon('trip', 'generalInformation', false);
            actions.modal.closeModal();
          }}
          content={
            <PlanVerification
              oldQuote={quote}
              selectedPlan={selectedPlan}
              newQuote={newQuote}
              planSelectedWithNewQuote={planSelectedWithNewQuote}
              formType={formType}
            />
          }
        ></ModalInformation>,
        true,
        false,
        null,
        null,
        null,
        false,
        true
      );
    } else {
      trackEventUserAction(`#### (B2B2C) INDO PARA O STEP 5`, null);
      nextStep();
    }
  } catch (error) {
    trackEventUserAction(`#### (B2B2C) ERRO INFORMAÇÕES DE VIAGEM SUBMIT`, {
      error,
      error_response: error.response,
    });
    actions.loadingFullScreen.hideLoadingFullScreen();
    throw error;
  }
};
