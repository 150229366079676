export const LABEL_HOSPITAL_NAME = {
  en_US: 'Name of hospital or clinic responsible for medical care',
  pt_BR: 'Nome do hospital ou clínica responsável pelos cuidados médicos',
  es_ES: 'Nombre del hospital o clínica responsable para el cuidado médico',
};

export const LABEL_MEDICAL_CARE_PERIOD = {
  en_US: 'Medical care period',
  pt_BR: 'Período da assistência médica',
  es_ES: 'Periodo de asistencia médica',
};

export const LABEL_DOCTOR_NAME = {
  en_US: 'Doctor name / last name',
  pt_BR: 'Nome / sobrenome do médico responsável pelos cuidados médicos',
  es_ES: 'Nombre / apellido del médico responsable de la atención',
};

export const LABEL_EMERGENCY_DESCRIPTION = {
  en_US: 'Medical emergency description',
  pt_BR: 'Descrição da emergência médica',
  es_ES: 'Descripción de la emergencia médica',
};

export const LABEL_DIAGNOSIS = {
  en_US: 'Diagnosis',
  pt_BR: 'Diagnóstico',
  es_ES: 'Diagnóstico',
};

export const LABEL_TREATMENT_INDICATED = {
  en_US: 'Treatment indicated ',
  pt_BR: 'Tratamento indicado',
  es_ES: 'Tratamiento indicado',
};

export const LABEL_TOTAL_CLAIMED = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};
