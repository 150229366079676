import React, { useContext } from 'react';
import Button from '../Button/Button';
import * as translations from './intl';
import { IntlContext } from '../../intl/index';
import { withRouter } from 'react-router-dom';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';

const ButtonLogin = props => {
  const {
    type,
    width,
    height,
    value,
    style,
    redirect,
    history,
    track,
    dataTestid,
    id,
  } = props;
  const { translate, twoDigitsIdiom } = useContext(IntlContext);
  const intl = translate(translations);
  const language = twoDigitsIdiom();

  const _onClick = () => {
    if (track) {
      track();
    } else {
      dataLayerContentMenuTrack('CTA Log in');
    }

    let url = `/login`;
    if (redirect) {
      url = redirect;
    }
    history.push(url);
  };

  return (
    <Button
      id={id}
      type={type}
      width={width}
      height={height}
      onClick={() => _onClick(language)}
      style={style}
      dataTestid={dataTestid}
    >
      {value ? value : intl.TEXT_BTN_LOGIN}
    </Button>
  );
};

export default withRouter(ButtonLogin);
