import React from 'react';
import IncidentArea from './IncidentArea/IncidentArea';
import PersonalArea from './PersonalArea/PersonalArea';
import CellphoneArea from './CellphoneArea/CellphoneArea';
import { safeGet } from '../../../../../utils/object';

const CellphoneProtection = props => {
  const { claimDetails } = props;

  const cellphone = safeGet(claimDetails, 'purchase_protection_items[0]', {});

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <CellphoneArea cellphone={cellphone} />
    </div>
  );
};

export default CellphoneProtection;
