import React, { useContext } from 'react';
import styles from './HighLightBox.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const HighLightBox = ({ icon, text }) => {
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  return (
    <div className={`${styles.containerHighlight} ${styles[theme]}`}>
      <div className={styles.iconHighlight}>{icon}</div>
      <div className={styles.textHighlight}>{text}</div>
    </div>
  );
};

export default HighLightBox;
