import { FormikProps } from 'formik';
import { useEffect } from 'react';

// * This useEffect cannot be added inside the form since its not a separate component
// ! Original Exception: React Hook "useEffect" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function.
// TODO: Refactor FormNAL

interface ScheduleInitializerProps {
  getListSchedule: (date: Date, timezone: string) => void;
  formikProps: FormikProps<any>;
}

const ScheduleInitializer = ({
  getListSchedule,
  formikProps,
}: ScheduleInitializerProps) => {
  useEffect(() => {
    const { date, timezone } = formikProps.values.appointment;
    if (date && timezone) {
      getListSchedule(date, timezone);
    }
  }, []);

  return null;
};

export default ScheduleInitializer;
