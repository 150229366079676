/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useContext } from 'react';
import { Formik } from 'formik';
import styles from './FormSearchCertificates.module.scss';
import Calendar from '../../../../../components/Calendar/Calendar';
import Button from '../../../../../componentsV2/Button/Button';
import { formInitialValues } from './initialValues';
import { schema } from './schema';
import * as translations from './intl';
import { IntlContext } from '../../../../../intl';

const FormSearchCertificates = ({ incidentDateState }) => {
  const [incidentDate, setIncidentDate] = incidentDateState;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const changeIncidentDate = ({ date }) => {
    if (date) {
      setIncidentDate(date);
    }
  };

  const validationSchema = useMemo(() => schema(intl), [intl]);
  const initialValues = useMemo(() => formInitialValues(incidentDate), []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => changeIncidentDate(values)}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          setFieldTouched,
        } = props;

        return (
          <form onSubmit={handleSubmit}>
            <div className={`row ${styles.form}`}>
              <div className={`col-12 col-sm-12 col-md-6`}>
                <Calendar
                  value={values.date}
                  label={intl.LABEL_INCIDENT_DATE}
                  name="date"
                  onChange={e => {
                    if (e && e.target && e.target.value) {
                      setIncidentDate(null);
                      handleChange(e);
                    }
                  }}
                  maxDate={new Date()}
                  error={errors && errors.date}
                  touched={touched && touched.date}
                  setFieldTouched={setFieldTouched}
                />
              </div>
              <div className={`col-6 ${styles.buttonArea}`}>
                <Button
                  id="borderBlue-searchCertificates"
                  type="Secondary"
                  onClick={handleSubmit}
                  label={intl.TEXT_SEARCH_CERTIFICATES}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormSearchCertificates;
