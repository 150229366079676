import { toDate } from 'date-fns-tz';
import {
  CertificateDetail,
  CertificateType,
  TravelInsuranceCertificateDetail,
} from '../../../../../@types/APIs/Certificate';
import { Travel } from '../../../../../@types/APIs/Travels';
import { isSameOrAfterDay } from '../../../../../utils/date';

export interface CertificateWithTravel
  extends TravelInsuranceCertificateDetail {
  travel?: Travel;
}

export const formatCertificates = (
  certificates: CertificateDetail[],
  travels: Travel[]
) => {
  const handledCertificates = (certificates as CertificateWithTravel[])
    ?.filter(
      certificate =>
        certificate.certificate_type === CertificateType.TravelInsurance
    )
    .filter(
      certificate =>
        !travels.find(
          travel => travel.certificate_id === certificate.certificate_id
        )
    )
    .filter(certificate =>
      isSameOrAfterDay(toDate(certificate.valid_until), new Date())
    )
    .filter(certificate => {
      const details = certificate?.travel_details;
      const isOnewayTravel = !details?.end_date;

      const today = new Date();
      const filterDate = isOnewayTravel ? details.start_date : details.end_date;

      return isSameOrAfterDay(toDate(filterDate), today);
    });

  return handledCertificates;
};
