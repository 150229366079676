import { useContext } from 'react';
import { IntlContext } from '../../../intl';
import { useFlightInfo } from '../FlightInfoContext';
import { getCountryFromPrefixTranslated } from '../../../utils/country';
import styles from './index.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

interface CitiesRow {
  className?: string;
}

const CitiesRow = ({ className }: CitiesRow) => {
  const { idiom } = useContext(IntlContext);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  let handledClassname = styles.container;
  if (className) handledClassname += ` ${className}`;

  const { flightInfo } = useFlightInfo();

  const cityOrigin = flightInfo.cityOrigin || '';
  const originCountry = getCountryFromPrefixTranslated(
    flightInfo.countryOrigin,
    idiom
  );
  const cityDestination = flightInfo.cityDestination || '';
  const destinationCountry = getCountryFromPrefixTranslated(
    flightInfo.countryDestination,
    idiom
  );

  return (
    <div className={handledClassname}>
      <p className={`${styles.city} ${styles[theme]}`}>
        {cityOrigin}
        {originCountry ? `, ${originCountry}` : ''}
      </p>
      <p className={`${styles.city} ${styles[theme]}`}>
        {cityDestination}
        {destinationCountry ? `, ${destinationCountry}` : destinationCountry}
      </p>
    </div>
  );
};

export default CitiesRow;
