import { useContext, Dispatch, SetStateAction } from 'react';
import styles from './SectionCertificateList.module.scss';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { CertificateWithTravel } from '../utils';
import { FullTravelInsuranceCertificateDetail } from '../../../../../../@types/APIs/Certificate';
import ListCertificates from '../../../../../../components/ListCertificates/ListCertificate';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import { certificatesServices } from '../../../../../../services';
import ModalCertificateInformation from './ModalCertificateInformation/ModalCertificateInformation';
import Button from '../../../../../../componentsV2/Button/Button';
import { travel_insurance } from '../../../../../../utils/certificates_name';

interface SectionCertificateListProps {
  certificates: CertificateWithTravel[];
  setCertificate: Dispatch<
    SetStateAction<FullTravelInsuranceCertificateDetail>
  >;
  setStep: (step: number) => void;
}

const SectionCertificateList = ({
  certificates,
  setCertificate,
  setStep,
}: SectionCertificateListProps) => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { actions } = useContext(StateContext);

  const handleItemPress = async certificate_id => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.LOADING_CERTIFICATE_INFORMATION
      );
      const { data: fullCertificate } =
        await certificatesServices.getCertificatesById(
          certificate_id,
          idiomForApi()
        );
      actions.loadingFullScreen.hideLoadingFullScreen();
      actions.modal.showModal(
        intl.CONFIRM_TRAVEL_INFORMATION,
        true,
        <ModalCertificateInformation
          setStep={setStep}
          certificate={fullCertificate}
          setCertificate={setCertificate}
          actions={actions}
        />,
        false,
        false,
        null,
        null,
        null,
        false
      );
    } catch (error) {}
  };

  return (
    <div className={styles.certificatesArea}>
      <div className={styles.header}>
        <h4 className={styles.certificateText}>
          {certificates.length && certificates.length > 0
            ? intl.TEXT_SELECT_CERTIFICATE
            : intl.TEXT_NO_CERTIFICATE_FOUND}
        </h4>
        <Button
          link
          type="Secondary"
          className={styles.generateButton}
          href={`/certificate/${travel_insurance}`}
          id="BtnGenerateCertificate"
          label={intl.LABEL_CREATE_CERTIFICATE}
        />
      </div>

      <ListCertificates
        certificates={certificates}
        numberToFilter={1000}
        isToSelectCertificate={true}
        setCertificate={setCertificate}
        disableButtonSelect={false}
        isGenerateClaim
        isTripAssistance
        confirmTripAssistanceCertificate={handleItemPress}
      />
    </div>
  );
};

export default SectionCertificateList;
