import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const VisaMedicOnlineIcon = props => {
  const { darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : config_icon.secondary;

  return (
    <svg
      width={39}
      height={67}
      viewBox="0 0 39 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.5618 0.497314H8.02056C3.74915 0.497314 0.260132 3.98634 0.260132 8.25775V59.0072C0.260132 63.2786 3.72801 66.7676 8.02056 66.7676H30.5829C34.8543 66.7676 38.3433 63.2786 38.3433 59.0072V8.25775C38.3433 3.98634 34.8755 0.497314 30.5829 0.497314H30.5618ZM7.99942 4.72643H30.5618C32.5072 4.72643 34.0931 6.31235 34.0931 8.25775V48.3498L4.48925 48.4132V8.25775C4.48925 6.31235 6.07517 4.72643 8.02056 4.72643H7.99942ZM30.5618 62.5173H8.02056C6.07517 62.5173 4.48925 60.9314 4.48925 58.986V52.6423L34.0931 52.5789V59.0072C34.0931 60.9526 32.5072 62.5385 30.5618 62.5385V62.5173Z"
        fill={primary}
      />
      <path
        d="M27.7494 23.0597H21.4057V16.716H17.1766V23.0597H10.8329V27.2888H17.1766V33.6325H21.4057V27.2888H27.7494V23.0597Z"
        fill={secondary}
      />
    </svg>
  );
};

export default VisaMedicOnlineIcon;
