import DOMPurify from 'dompurify';

// Commented code to test PBI #866377
// Allows iFrames, but only with the following srcs
// const iframeAllowedSrcs = [
//   'https://www.youtube-nocookie.com/embed/',
//   'https://www.youtube.com/embed/',
// ];

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  if ('target' in node) {
    const targetValue = node.getAttribute('target')
      ? node.getAttribute('target')
      : '_blank';
    node.setAttribute('target', targetValue);
    node.setAttribute('rel', 'noopener');
  }

  // if (node && node.tagName && node.tagName.toLowerCase() === 'iframe') {
  //   const src = node.getAttribute('src') || '';
  //   if (!iframeAllowedSrcs.some(iframeSrc => src.startsWith(iframeSrc))) {
  //     return node.parentNode?.removeChild(node);
  //   }
  // }
});

export default dirty =>
  DOMPurify.sanitize(dirty, {
    // ADD_TAGS: ['iframe'],
    ADD_ATTR: ['target'],
  });
