export const UPSELL_BUTTON = {
  en_US: '',
  es_ES: 'Cotizar cobertura extendida con AXA',
  pt_BR: '',
};

export const CROSSELL_BUTTON = {
  en_US: '',
  es_ES: 'Cotizar con AXA',
  pt_BR: '',
};

export const UPSELL_TITLE = {
  en_US: '',
  es_ES:
    'Extienda la cobertura de tu Servicio de Emergencia Médica Internacional con AXA',
  pt_BR: '',
};

export const CROSSELL_TITLE = {
  en_US: '',
  es_ES: 'Servicio de Emergencia Médica Internacional con AXA',
  pt_BR: '',
};
