import { useContext, useEffect, useState } from 'react';
import { IntlContext } from '../../intl';
import styles from './Partner.module.scss';
import * as translations from './intl.js';
import Banner from './Banner/Banner';
import BlueBox from './BlueBox/BlueBox';
import ListCards from '../../components/ListCards/ListCards';
import { productsAndBenefitsServices } from '../../services/index';
import {
  formatLinkWithIdiomAndCountry,
  stringContainsValue,
} from '../../utils/stringUtils';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import Loading from '../../components/Loading/Loading';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import Button from '../../componentsV2/Button/Button';
import { useHistory } from 'react-router-dom';
import { joinClassNames } from '../../utils/classNames';

const LOADING = 'LOADING';
const ERROR = 'ERROR';

const loadData = async (
  binOrPan,
  partner_name,
  setUrl,
  idiom,
  country,
  idiomForApi
) => {
  try {
    setUrl(LOADING);

    const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
      binOrPan,
      idiomForApi(),
      false,
      false
    );

    const findBenefit =
      benefits &&
      benefits.data &&
      benefits.data.find(
        benefit =>
          benefit.external_reference.toLowerCase() ===
          partner_name.toLowerCase()
      );

    if (findBenefit) {
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          binOrPan,
          findBenefit.benefit_id,
          idiomForApi()
        );

      const { data } = response;

      let redeemData = data.redeem_info && data.redeem_info.redeem;

      let redeem = formatLinkWithIdiomAndCountry(
        redeemData.additional_buttons,
        idiom,
        country
      );

      redeem =
        redeem !== '' &&
        redeem.split(';').map(linksReddem => linksReddem.split(' - '));
      setUrl(redeem.length > 0 ? redeem[0][1] : '');
    } else {
      setUrl(ERROR);
    }
  } catch (e) {
    setUrl(ERROR);
  }
};

const Partner = props => {
  const [url, setUrl] = useState(LOADING);
  const { utils } = useContext(StateContext);
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme } = useTheme();
  const intl = translate(translations);
  const binOrPan = utils.getBin(true);
  const { partner_name } = props.match.params;
  const history = useHistory();
  const theme = getGlobalTheme();
  const isConciergePartner = stringContainsValue(partner_name, 'concierge');

  useEffect(() => {
    if (partner_name && !isConciergePartner) {
      history.push('/dashboard');
    }
    loadData(
      binOrPan,
      isConciergePartner ? 'concierge' : partner_name,
      setUrl,
      idiom,
      country,
      idiomForApi
    );
  }, [binOrPan]);

  const isLoading = url === LOADING;
  const isError = url === ERROR;
  const successLoad = !isLoading && !isError;

  return (
    <div className={styles.container}>
      <Banner />
      <div className={styles.boxContainer}>
        <BlueBox />

        <div className={styles.infoContainer}>
          <h1 id="mainContent" className={styles[theme]}>
            {intl.YOUR_CARDS}
          </h1>

          <ListCards noReload />
          {isError && (
            <p
              className={joinClassNames(
                styles.messengerElegibility,
                styles[theme]
              )}
            >
              {intl.NOT_ELEBIGILITY_TRY_ANOTHER_CARD}
            </p>
          )}

          <div className={styles.info}>
            {isLoading && <Loading />}
            {successLoad && (
              <Button
                link
                href={url}
                internal={false}
                target="_self"
                type="Secondary"
                className={styles.linkDecoration}
                label={intl.ACCESS_CONCIERGE}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
