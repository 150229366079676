import { focusElement, focusOnErrorElement } from '../../../../utils/elements';
import { getValidationSchema } from './validationSchema';

export const setStatusAccordeon = (
  selected,
  listAccordeon,
  setListAccordeon
) => {
  const newListAccordeon = { ...listAccordeon };
  Object.keys(newListAccordeon).map(key => {
    return (newListAccordeon[key] = false);
  });
  setListAccordeon({
    ...newListAccordeon,
    [selected]: !listAccordeon[selected],
  });
};

export const checkErrorsOnForm = async (values, intl) => {
  try {
    const schema = getValidationSchema(intl);
    await schema.validate(values);
    return true;
  } catch (error) {
    focusOnErrorElement(0);
    return;
  }
};

export const checkErrorsOnSession = async (
  accordeonRefs,
  currentAcordeon,
  nextAcordeon,
  schemas,
  values,
  validation,
  submitRefs
) => {
  if (accordeonRefs[currentAcordeon]) {
    try {
      if (validation) {
        submitRefs[currentAcordeon].current.click();
        await schemas[currentAcordeon].validate(values[currentAcordeon]);
      }
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
      focusElement(nextAcordeon);
      return true;
    } catch (error) {
      focusOnErrorElement(0);
      return false;
    }
  }
};

export const openTargetAcordeon = async (
  currentAcordeon,
  targetAcordeon,
  validation = true,
  accordeonRefs,
  schemas,
  values,
  submitRefs
) => {
  return await checkErrorsOnSession(
    accordeonRefs,
    currentAcordeon,
    targetAcordeon,
    schemas,
    values,
    validation,
    submitRefs
  );
};
