/* eslint-disable indent */

import { VALUE_MX } from '../constants';

/* eslint-disable no-unused-vars */
const bankData = data => {
  return {
    name: data && data.name ? data.name : '',
    bank_account_number:
      data && data.bank_account_number ? data.bank_account_number : '',
  };
};

const filterByElement = value => {
  return element => {
    return element.wire_code_type === value;
  };
};

const formatObject = element => {
  const object = { value: '' };
  if (element) {
    object.wire_code_type = element.wire_code_type;
    object.value = element.value;
  }
  return object;
};

const formatEmptyObject = () => {
  const object = { value: '' };
  return object;
};

const getCountryFromBank = data => {
  const bankCountry =
    data.bank && data.bank.address && data.bank.address.country
      ? data.bank.address.country
      : '';
  let countryResult;
  switch (bankCountry) {
    case 'MX':
      countryResult = 'Mexico';
      break;
    case 'US':
      countryResult = 'united-states';
      break;
    default:
      countryResult = 'other-countries';
      break;
  }
  return countryResult;
};

export const IntermediaryBankAccountData = (data, country) => {
  const bank = bankData(data.IntermediaryBankAccount);

  //Exception format for MX SWIFT field
  const swiftValueMX =
    (data &&
      data.IntermediaryBankAccount &&
      data.IntermediaryBankAccount.bank_identification_number) ||
    (data.IntermediaryBankAccount &&
      data.IntermediaryBankAccount.account_codes &&
      data.IntermediaryBankAccount.account_codes.find(
        filterByElement('SWIFT')
      )) ||
    '';

  return {
    bank,
    account_type: data.IntermediaryBankAccount
      ? data.IntermediaryBankAccount.account_type
      : 'CHECKING',
    bankIntermediaryView:
      data.IntermediaryBankAccount &&
      data.IntermediaryBankAccount.bank_account_number
        ? 'yes'
        : 'no',
    account_codes: [],
    bankCountryView: country,
    SWIFT:
      data.IntermediaryBankAccount && data.IntermediaryBankAccount.account_codes
        ? formatObject(
            data.IntermediaryBankAccount.account_codes.find(
              filterByElement('SWIFT')
            )
          )
        : formatEmptyObject(),
    ABA:
      data.IntermediaryBankAccount && data.IntermediaryBankAccount.account_codes
        ? formatObject(
            data.IntermediaryBankAccount.account_codes.find(
              filterByElement('ABA')
            )
          )
        : formatEmptyObject(),
    FULL_KEY:
      data.IntermediaryBankAccount && data.IntermediaryBankAccount.account_codes
        ? formatObject(
            data.IntermediaryBankAccount.account_codes.find(
              filterByElement('FULL_KEY')
            )
          )
        : formatEmptyObject(),
    IBAN:
      data.IntermediaryBankAccount && data.IntermediaryBankAccount.account_codes
        ? formatObject(
            data.IntermediaryBankAccount.account_codes.find(
              filterByElement('IBAN')
            )
          )
        : formatEmptyObject(),
    CUIT:
      data.IntermediaryBankAccount && data.IntermediaryBankAccount.account_codes
        ? formatObject(
            data.IntermediaryBankAccount.account_codes.find(
              filterByElement('CUIT')
            )
          )
        : formatEmptyObject(),
    bank_identification_number: swiftValueMX,
  };
};
