import { useContext } from 'react';
import styles from './ConcernedItems.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

import Item from './Items/Item';
import ItemAdd from './Items/ItemAdd';
import TotalSum from './Items/TotalSum';

const ConcernedItem = props => {
  const { values, touched, errors } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const mappedItems = values.incident.concerned_items.map(
    (currentValue, index) => {
      const _errors =
        errors.incident &&
        errors.incident.concerned_items &&
        errors.incident.concerned_items[index]
          ? errors.incident.concerned_items[index]
          : {};
      const _touched =
        touched.incident &&
        touched.incident.concerned_items &&
        touched.incident.concerned_items[index]
          ? touched.incident.concerned_items[index]
          : {};
      return (
        <Item
          key={`item-${index}`}
          _touched={_touched}
          _errors={_errors}
          index={index}
          {...props}
        />
      );
    }
  );
  return (
    <div className={`${styles.concernedItem}`}>
      <div className="row">
        <div className="col-12">
          <div className={`${styles.elementRadio} ${styles[themes]} row`}></div>
        </div>

        <div role="contentAdditionalPassangers" className={`col-12 `}>
          <ItemAdd {...props} />
          {mappedItems}
          <TotalSum {...props} />
        </div>
      </div>
    </div>
  );
};

export default ConcernedItem;
