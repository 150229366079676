import { ObjectOCTInitialValues } from '../OCTInitialValues/OCTInitialValues';

export interface OCTPallaAccountData {
  legalName: {
    first: string;
    last: string;
  };
  dob: {
    day: number;
    month: number;
    year: number;
  };
  expirationDate?: string | null;
  token?: string;
}

const formatExpirationDate = date => {
  const splitDate = date.split('/');
  return `20${splitDate[1]}${splitDate[0]}`;
};

export const formatDataPallaAccount = (
  values: ObjectOCTInitialValues,
  panEncrypted: string
): OCTPallaAccountData => {
  const birthDate = new Date(`${values.birth_date}T00:00:00`);
  const formatData: OCTPallaAccountData = {
    legalName: {
      first: values.name.first,
      last: values.name.last,
    },
    dob: {
      day: birthDate.getDate(),
      month: birthDate.getMonth() + 1,
      year: birthDate.getFullYear(),
    },
  };
  if (panEncrypted) {
    formatData.expirationDate = values.card.expirationDate
      ? formatExpirationDate(values.card.expirationDate)
      : null;
    formatData.token = panEncrypted;
  }
  return formatData;
};
