import moment from 'moment';
export const formInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let affectedPerson =
    completeClaim && completeClaim.affected_person
      ? completeClaim.affected_person
      : {};
  let incident =
    completeClaim && completeClaim.incident ? completeClaim.incident : {};
  let otherInsuranceCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering
      : [{}];
  let totalAmountClaimed =
    completeClaim && completeClaim.total_amount_claimed
      ? completeClaim.total_amount_claimed
      : {};
  let travelInformation =
    completeClaim && completeClaim.travel_information
      ? completeClaim.travel_information
      : {};

  const getAdditional = completeClaim => {
    if (completeClaim && completeClaim.additional_passengers)
      return completeClaim.additional_passengers.map(additional => ({
        first_name: additional.first_name,
        last_name: additional.last_name,
        birth_date: new Date(moment(additional.birth_date, 'YYYY-MM-DD')),
        gender: additional.gender,
        nationalities: additional.nationalities[0],
        relationship: additional.relationship,
        comment: additional.comment === null ? '' : additional.comment,
      }));
    else
      return [
        {
          first_name: '',
          last_name: '',
          birth_date: '',
          gender: '',
          nationalities: '',
          relationship: '',
          comment: '',
        },
      ];
  };

  const getHasAdditional = completeClaim => {
    return completeClaim && completeClaim.additional_passengers
      ? 'true'
      : 'false';
  };
  const getAffectedPerson = () => {
    return {
      relationship: 'Cardholder',
      relationshipCardholder: true,
      person: getPerson(person),
    };
  };

  const getPerson = person => {
    return {
      first_name: person && person.first_name ? person.first_name : '',
      last_name: person && person.last_name ? person.last_name : '',
    };
  };

  const getIncident = incident => {
    let incidentDate =
      incident && incident.date ? incident.date.split(' ') : '';

    return {
      date: incidentDate ? new Date(moment(incidentDate[0], 'YYYY-MM-DD')) : '',
      description: incident && incident.description ? incident.description : '',
      time: incidentDate ? incidentDate[1].substring(0, 5) : '',
    };
  };

  const getOtherInsuranceCovering = otherInsuranceCovering => {
    const otherInsuranceCovering1 =
      otherInsuranceCovering && otherInsuranceCovering[0]
        ? otherInsuranceCovering[0]
        : [{}];
    return {
      is_internal:
        otherInsuranceCovering1 &&
        otherInsuranceCovering1.is_internal !== undefined
          ? String(!otherInsuranceCovering1.is_internal)
          : 'false',
      description:
        otherInsuranceCovering1 && otherInsuranceCovering1.description
          ? otherInsuranceCovering1.description
          : '',
      value:
        otherInsuranceCovering1 &&
        otherInsuranceCovering1.amount &&
        otherInsuranceCovering1.amount.value
          ? otherInsuranceCovering1.amount.value
          : '',
      currency:
        otherInsuranceCovering1 &&
        otherInsuranceCovering1.amount &&
        otherInsuranceCovering1.amount.currency
          ? otherInsuranceCovering1.amount.currency
          : 'USD',
    };
  };

  const getOtherInsuranceCovering2 = otherInsuranceCovering => {
    const otherInsuranceCovering2 =
      otherInsuranceCovering && otherInsuranceCovering[1]
        ? otherInsuranceCovering[1]
        : [{}];
    return {
      is_internal:
        otherInsuranceCovering2 &&
        otherInsuranceCovering2.is_internal !== undefined
          ? String(!otherInsuranceCovering2.is_internal)
          : 'false',
      description:
        otherInsuranceCovering2 && otherInsuranceCovering2.description
          ? otherInsuranceCovering2.description
          : '',
      value:
        otherInsuranceCovering2 &&
        otherInsuranceCovering2.amount &&
        otherInsuranceCovering2.amount.value
          ? otherInsuranceCovering2.amount.value
          : '',
      currency:
        otherInsuranceCovering2 &&
        otherInsuranceCovering2.amount &&
        otherInsuranceCovering2.amount.currency
          ? otherInsuranceCovering2.amount.currency
          : 'USD',
    };
  };

  const getTotalAmountClaimed = totalAmountClaimed => {
    return {
      value:
        totalAmountClaimed && totalAmountClaimed.value
          ? totalAmountClaimed.value
          : '',
      currency:
        totalAmountClaimed && totalAmountClaimed.currency
          ? totalAmountClaimed.currency
          : 'USD',
    };
  };

  const getTravelInformation = travelInformation => {
    return {
      form_of_payment:
        travelInformation && travelInformation.form_of_payment
          ? travelInformation.form_of_payment
          : '',
      from:
        travelInformation && travelInformation.from
          ? new Date(moment(travelInformation.from, 'DD/MM/YYYY'))
          : '',
      is_airline_compensation:
        travelInformation && travelInformation.is_airline_compensation
          ? travelInformation.is_airline_compensation
          : '',
      to:
        travelInformation && travelInformation.to
          ? new Date(moment(travelInformation.to, 'DD/MM/YYYY'))
          : '',
      agency_information: getAgencyInformation(travelInformation),
      programmed_flights: getProgrammedFlights(travelInformation),
      programmed_flights2: getProgrammedFlights2(travelInformation),
    };
  };

  const getAgencyInformation = travelInformation => {
    const agencyInformation =
      travelInformation && travelInformation.agency_information
        ? travelInformation.agency_information
        : {};
    return {
      name:
        agencyInformation && agencyInformation.name
          ? agencyInformation.name
          : '',
    };
  };
  const getProgrammedFlights2 = travelInformation => {
    const programmedFlights =
      travelInformation && travelInformation.programmed_flights
        ? travelInformation.programmed_flights
        : [{}];
    let departureProgrammedDate =
      programmedFlights[1] && programmedFlights[1].departure_programmed_time
        ? programmedFlights[1].departure_programmed_time.split(' ')
        : '';
    let arrivalProgrammedDate =
      programmedFlights[1] && programmedFlights[1].arrival_programmed_time
        ? programmedFlights[1].arrival_programmed_time.split(' ')
        : '';
    return {
      departure_programmed_date: departureProgrammedDate
        ? new Date(moment(departureProgrammedDate[0], 'DD/MM/YYYY'))
        : '',

      departure_programmed_time: departureProgrammedDate
        ? departureProgrammedDate[1]
        : '',
      arrival_programmed_date: arrivalProgrammedDate
        ? new Date(moment(arrivalProgrammedDate[0], 'DD/MM/YYYY'))
        : '',
      arrival_programmed_time: arrivalProgrammedDate
        ? arrivalProgrammedDate[1]
        : '',
    };
  };
  const getProgrammedFlights = travelInformation => {
    const programmedFlights =
      travelInformation && travelInformation.programmed_flights
        ? travelInformation.programmed_flights
        : [{}];
    let departureDate =
      programmedFlights[0] && programmedFlights[0].departure_time
        ? programmedFlights[0].departure_time.split(' ')
        : '';
    let arrivalDate =
      programmedFlights[0] && programmedFlights[0].arrival_time
        ? programmedFlights[0].arrival_time.split(' ')
        : '';

    return {
      departure_date: departureDate
        ? new Date(moment(departureDate, 'DD/MM/YYYY HH:mm'))
        : '',

      departure_time: departureDate ? departureDate[1] : '',
      arrival_date: arrivalDate
        ? new Date(moment(arrivalDate, 'DD/MM/YYYY HH:mm'))
        : '',
      arrival_time: arrivalDate ? arrivalDate[1] : '',
    };
  };

  return {
    affected_person: getAffectedPerson(affectedPerson),
    travel_information: getTravelInformation(travelInformation),
    incident: getIncident(incident),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    other_insurance_covering2: getOtherInsuranceCovering2(
      otherInsuranceCovering
    ),
    total_amount_claimed: getTotalAmountClaimed(totalAmountClaimed),
    hasAdditional: getHasAdditional(completeClaim),
    additional_passengers: getAdditional(completeClaim),
  };
};
