import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

interface StepNumberIconProps extends SVGIconProps {
  step: number;
}

const StepNumberIcon = (props: StepNumberIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="0 0 38 38"
      fill="none"
      {...handledProps}
    >
      <g fill={colors.secondary} clipPath="url(#a)">
        {props.step === 1 && (
          <>
            <path d="M13.595 28.469V25.34h3.527V13.698c-.292.136-.598.24-.913.308-.451.112-.909.2-1.37.262a11.42 11.42 0 0 1-1.495.103v-2.42a8.08 8.08 0 0 0 2.934-.696 3.105 3.105 0 0 0 1.712-1.712h3.013V25.34h3.573v3.128h-10.98Z" />
            <path d="M19.006 38A19.005 19.005 0 1 1 38 19.006 19.017 19.017 0 0 1 19.006 38Zm0-35.717a16.723 16.723 0 1 0 16.711 16.723A16.734 16.734 0 0 0 19.006 2.283Z" />
          </>
        )}
        {props.step === 2 && (
          <>
            <path d="M17.362 12.556a6.679 6.679 0 0 0-2.066.377 6.974 6.974 0 0 0-2.078 1.142h-.262l.183-3.277c.47-.316.98-.57 1.518-.753.577-.198 1.168-.35 1.769-.457.562-.1 1.13-.153 1.7-.16a8.22 8.22 0 0 1 4.91 1.302 5.035 5.035 0 0 1 1.803 4.326 7.088 7.088 0 0 1-.411 2.523 7.993 7.993 0 0 1-1.142 1.963 19.34 19.34 0 0 1-1.404 1.621c-.434.445-1.084 1.039-1.94 1.792a44.692 44.692 0 0 1-2.831 2.283h7.99l-.228 3.31H12.819v-2.842l2.614-2.214c.73-.617 1.358-1.142 1.906-1.69A18.35 18.35 0 0 0 18.8 20.25a9.061 9.061 0 0 0 1.427-2.157c.353-.782.532-1.631.525-2.489a2.82 2.82 0 0 0-.879-2.374 4.11 4.11 0 0 0-2.511-.674Z" />
            <path d="M19.006 38A19.005 19.005 0 1 1 38 19.006 19.017 19.017 0 0 1 19.006 38Zm0-35.717a16.723 16.723 0 1 0 16.711 16.723A16.734 16.734 0 0 0 19.006 2.283Z" />
          </>
        )}
        {props.step === 3 && (
          <>
            <path d="M25.215 22.83a5.993 5.993 0 0 1-.936 3.538 5.148 5.148 0 0 1-2.5 1.86 10.276 10.276 0 0 1-3.515.537 9.89 9.89 0 0 1-1.884-.228c-.62-.115-1.23-.275-1.826-.48a5.376 5.376 0 0 1-1.313-.65l-.445-3.31h.206c.691.56 1.504.951 2.374 1.141.776.201 1.572.313 2.374.331a4.178 4.178 0 0 0 2.466-.673 2.772 2.772 0 0 0 .924-2.409 2.283 2.283 0 0 0-.924-2.123 4.794 4.794 0 0 0-2.432-.548h-1.45l-.262-2.443h1.393a3.356 3.356 0 0 0 2.226-.707 2.524 2.524 0 0 0 .856-1.94 2.191 2.191 0 0 0-.685-1.816 3.526 3.526 0 0 0-2.203-.548 6.688 6.688 0 0 0-2.557.445 5.707 5.707 0 0 0-1.804 1.142h-.205l.445-3.288a6.575 6.575 0 0 1 2.18-1.038 9.806 9.806 0 0 1 2.671-.377 7.796 7.796 0 0 1 4.498 1.141 4.04 4.04 0 0 1 1.62 3.573c.015.71-.171 1.41-.536 2.02a4.28 4.28 0 0 1-1.381 1.37 6.53 6.53 0 0 1-1.78.788v.126c.766.125 1.51.367 2.202.719.652.332 1.21.822 1.621 1.427.436.729.639 1.573.582 2.42Z" />
            <path d="M19.006 38A19.005 19.005 0 1 1 38 19.006 19.017 19.017 0 0 1 19.006 38Zm0-35.717a16.723 16.723 0 1 0 16.711 16.723A16.734 16.734 0 0 0 19.006 2.283Z" />
          </>
        )}
        {props.step === 4 && (
          <>
            <path d="m11.826 21.072 7.271-11.415h3.904v11.232h3.185v2.763H23v4.725h-3.676v-4.725h-7.5v-2.58Zm3.173-.183h4.326v-7.088h-.068l-4.258 7.088Z" />
            <path d="M19.006 38A19.005 19.005 0 1 1 38 19.006 19.017 19.017 0 0 1 19.006 38Zm0-35.717a16.723 16.723 0 1 0 16.711 16.723A16.734 16.734 0 0 0 19.006 2.283Z" />
          </>
        )}
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h38v38H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StepNumberIcon;
