import React from 'react';
import ModalWithImage from '../ModalWithImage/ModalWithImage';
import ModalAddCard from './ModalAddCard';
import imageBin from '../../assets/images/imageBin.png';
import ModalAddCardBin from '../ModalAddCardBin/ModalAddCardBin';

export const showModalAddCard = (
  actions,
  intl,
  section = 'Header',
  bin = false,
  showModal = 1,
  changeModal = null
) => {
  return actions.modal.showModal(
    false,
    true,
    <ModalWithImage
      title={
        showModal === 1
          ? intl.TEXT_ADD_YOUR_CARD_PAN
          : intl.TEXT_ADD_YOUR_CARD_BIN
      }
      subtitle={showModal === 1 ? intl.TEXT_16_DIGITS : intl.TEXT_10_DIGITS}
      image={imageBin}
    >
      {showModal === 1 ? (
        <ModalAddCard section={section} bin={bin} changeModal={changeModal} />
      ) : (
        <ModalAddCardBin
          section={section}
          bin={bin}
          changeModal={changeModal}
        />
      )}
    </ModalWithImage>,
    true
  );
};
