import React, { useContext } from 'react';
import moment from 'moment';
import 'moment/min/locales.min';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { stringCapitalize } from '../../../../../../utils/stringUtils';
import { convertTimeUS } from '../../../../../../utils/date';

export const redirect = (history, actions) => {
  history.push('/dashboard');
  actions.modal.closeModal();
};

const ModalAppointmentSuccessNAL = props => {
  const { time, date, idiom, actions, history } = props;
  const { translate } = useContext(IntlContext);
  const getLocaleByIdiom = idiom.split('-')[0];
  const intl = translate(translations);
  const dateByIdiom = moment(date.substring(0, 10)).locale(getLocaleByIdiom);
  const dayOfWeek = stringCapitalize(dateByIdiom.format('dddd'));
  const month = stringCapitalize(dateByIdiom.format('MMMM'));
  const day = dateByIdiom.format('D');
  const year = dateByIdiom.format('yyyy');
  const _time = idiom === 'en_US' ? convertTimeUS(time) : time;

  return (
    <ModalInformation
      type="success"
      message={intl.TEXT_CREATED_NAL}
      subtitle={intl.FORMAT_TEXT_DATE(month, day, dayOfWeek, _time, year)}
      content={<p>{intl.TEXT_REMEMBER_NAL}</p>}
      textBtn={intl.BTN_HOME}
      clickBtn={() => {
        redirect(history, actions);
      }}
      textBtnDeny={intl.BTN_APPOINTMENTS}
      clickBtnDeny={() => {
        actions.modal.closeModal();
        history.push('/nurse-advice-line/previous-appointments');
      }}
    />
  );
};

export default ModalAppointmentSuccessNAL;
