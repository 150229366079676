export const pt_currency = `
<option value="PAB"> Balboa Panamense </option>
<option value="BOB"> Boliviano (Bolívia) </option>
<option value="CRC"> Colón da Costa Rica </option>
<option value="CUC"> Cubano Convertible Peso </option>
<option value="NIO"> Córdoba Nicaragüense </option>
<option value="JMD"> Dólar Jamaicano </option>
<option value="GYD"> Dólar da Guiana </option>
<option value="BSD"> Dólar das Bahamas </option>
<option value="BBD"> Dólar de Barbados </option>
<option value="TTD"> Dólar de Trinidad e Tobago </option>
<option value="BZD"> Dólar do Belize </option>
<option value="XCD"> Dólar do Caraíbas Orientais </option>
<option value="SRD"> Dólar do Suriname </option>
<option value="HTG"> Gourde Haitiano </option>
<option value="PYG"> Guarani Paraguaio </option>
<option value="HNL"> Lempira Hondurenha </option>
<option value="PEN"> Novo Sol Peruano </option>
<option value="ARS"> Peso Argentino </option>
<option value="CLP"> Peso Chileno </option>
<option value="COP"> Peso Colombiano </option>
<option value="CUP"> Peso Cubano </option>
<option value="DOP"> Peso Dominicano </option>
<option value="MXN"> Peso Mexicano </option>
<option value="UYU"> Peso Uruguaio </option>
<option value="GTQ"> Quetzal Guatemalteco </option>
<option value="BRL"> Real Brasileiro </option>
<option value="VEF"> Venezuelan Bolivar Fuerte </option>


<option value="USD"> Dólar Americano </option>
<option value="AUD"> Dólar Australiano </option>
<option value="CAD"> Dólar Canadense </option>
<option value="EUR"> Euro </option>
<option value="GBP"> Libra Esterlina (Reino Unido) </option>

<option value="AFN"> Afghani Afegão (Nova) </option>
<option value="MGA"> Ariary Malgaxe </option>
<option value="THB"> Baht Tailandês </option>
<option value="ETB"> Birr Etíope </option>
<option value="GHS"> Cedi Ganês </option>
<option value="DKK"> Coroa Dinamarquesa </option>
<option value="EEK"> Coroa Estoniana </option>
<option value="ISK"> Coroa Islandesa </option>
<option value="NOK"> Coroa Norueguesa </option>
<option value="SEK"> Coroa Sueca </option>
<option value="CZK"> Coroa Tcheca </option>
<option value="GMD"> Dalasi Gambiano </option>
<option value="MKD"> Denar Macedônio </option>
<option value="DZD"> Dinar Argelino </option>
<option value="BHD"> Dinar do Barém </option>
<option value="IQD"> Dinar Iraquiano </option>
<option value="JOD"> Dinar Jordano </option>
<option value="KWD"> Dinar Kuwaitiano </option>
<option value="LYD"> Dinar Líbio </option>
<option value="RSD"> Dinar Sérvio </option>
<option value="TND"> Dinar Tunisino </option>
<option value="AED"> Dirham EAU (Emirados Árabes Unidos) </option>
<option value="MAD"> Dirham Marroquino </option>
<option value="STD"> Dobra de São Tomé </option>
<option value="VND"> Dong Vietnamita </option>
<option value="AMD"> Dram Armênio </option>
<option value="NAD"> Dólar da Namíbia </option>
<option value="BMD"> Dólar das Bermudas </option>
<option value="KYD"> Dólar das Ilhas Caimão </option>
<option value="FJD"> Dólar das Ilhas Fiji </option>
<option value="SBD"> Dólar das Ilhas Salomão </option>
<option value="SGD"> Dólar de Cingapura </option>
<option value="HKD"> Dólar de Hong Kong </option>
<option value="BND"> Dólar do Brunei </option>
<option value="ZWL"> Dólar do Zimbabué </option>
<option value="LRD"> Dólar Liberiano </option>
<option value="NZD"> Dólar Neozelandês </option>
<option value="CVE"> Escudo de Cabo Verde </option>
<option value="ANG"> Florim das Antilhas Neerlandesas </option>
<option value="AWG"> Florim de Aruba </option>
<option value="HUF"> Forinte Húngaro </option>
<option value="XOF"> Franco CFA (BCEAO) </option>
<option value="XAF"> Franco CFA (BEAC) </option>
<option value="CDF"> Franco Congolês </option>
<option value="KMF"> Franco de Comores </option>
<option value="DJF"> Franco Djibuti </option>
<option value="BIF"> Franco do Burundi </option>
<option value="XPF"> Franco do Pacífico </option>
<option value="GNF"> Franco Guineense </option>
<option value="RWF"> Franco Ruandês </option>
<option value="CHF"> Franco Suíço </option>
<option value="UAH"> Hryvnia Ucraniano </option>
<option value="JPY"> Iene Japonês </option>
<option value="CNY"> Iuane Renminbi Chinês </option>
<option value="PGK"> Kina Papua </option>
<option value="LAK"> Kip Laociano </option>
<option value="HRK"> Kuna </option>
<option value="MWK"> Kwacha do Malauí </option>
<option value="ZMW"> Kwacha zambiano (novo) </option>
<option value="AOA"> Kwanza Angolano </option>
<option value="MMK"> Kyat de Myanmar </option>
<option value="GEL"> Lari Georgiano </option>
<option value="LVL"> Lat Letoniano </option>
<option value="ALL"> Lek Albanês </option>
<option value="SLL"> Leone Serra-Leonês </option>
<option value="MDL"> Leu Moldavo </option>
<option value="RON"> Leu Romeno </option>
<option value="BGN"> Lev búlgaro </option>
<option value="SHP"> Libra de Santa Helena </option>
<option value="EGP"> Libra Egípcia </option>
<option value="FKP"> Libra Falklandesa </option>
<option value="GIP"> Libra Gibraltina </option>
<option value="LBP"> Libra Libanesa </option>
<option value="SDG"> Libra Sudanesa </option>
<option value="SZL"> Lilangeni Suazilandês </option>
<option value="TRY"> Lira Turca (Nova) </option>
<option value="LTL"> Lita Lituana </option>
<option value="LSL"> Loti Lesotiano </option>
<option value="BAM"> Marcos Conversíveis </option>
<option value="BOV"> Mvdol Boliviano </option>
<option value="NGN"> Naira Nigeriana </option>
<option value="ERN"> Nakfa Eritréia </option>
<option value="BTN"> Ngultrum Butanês </option>
<option value="TWD"> Novo Dólar de Taiwan </option>
<option value="AZN"> Novo Manat Azerbaijanês </option>
<option value="TMT"> Novo Manat Turcomenistanês </option>
<option value="MZN"> Novo Metical Moçambicano </option>
<option value="ILS"> Novo Shekel Israelita </option>
<option value="MRO"> Ougulya Mauritano </option>
<option value="TOP"> Pa'anga Tonganês </option>
<option value="MOP"> Pataca Macaense </option>
<option value="PHP"> Peso Filipino </option>
<option value="BWP"> Pula Botsuano </option>
<option value="ZAR"> Rand Sul-Africano </option>
<option value="OMR"> Rial de Omã </option>
<option value="QAR"> Rial do Catar </option>
<option value="YER"> Rial Iemenita </option>
<option value="SAR"> Rial Saudita </option>
<option value="KHR"> Riel Cambojano </option>
<option value="MYR"> Ringgit Malaio </option>
<option value="BYR"> Rublo Bielorrusso </option>
<option value="RUB"> Rublo Russo </option>
<option value="MUR"> Rúpia da Maurícia </option>
<option value="MVR"> Rúpia das Maldivas </option>
<option value="SCR"> Rúpia das Seicheles </option>
<option value="LKR"> Rúpia do Sri Lanca </option>
<option value="INR"> Rúpia Indiana </option>
<option value="IDR"> Rúpia Indonésia </option>
<option value="NPR"> Rúpia Nepalesa </option>
<option value="PKR"> Rúpia Paquistanesa </option>
<option value="KGS"> Som Quirguizistanês </option>
<option value="TJS"> Somoni Tajiquistanês </option>
<option value="UZS"> Sum Usbequistanês </option>
<option value="BDT"> Taka Bangladechiano </option>
<option value="WST"> Tala Samoana </option>
<option value="KZT"> Tenge Cazaquistanês </option>
<option value="MNT"> Tugrik Mongol </option>
<option value="MXV"> Unidade de Conversão (UDI) Mexicana </option>
<option value="VUV"> Vatu Vanuatano </option>
<option value="KRW"> Won Coreano </option>
<option value="KES"> Xelim Queniano </option>
<option value="SOS"> Xelim Somaliano </option>
<option value="TZS"> Xelim Tanzaniano </option>
<option value="UGX"> Xelim Ugandês </option>
<option value="PLN"> Zloti Polaco </option>`;
