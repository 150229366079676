import moment from 'moment';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';
import { MOBILE_PHONE } from '../../../const';

export const cell_phone_protection_ToApi = props => {
  let object = {};

  object.loss_code = MOBILE_PHONE;
  object.affected_person = affected_personToApi(props);
  object.incident = incidentToApi(props);
  object.purchase_protection_items = purchaseProtectionItems(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;

  const date = moment(incident.dateValue).format('YYYY-MM-DD HH:mm:ss');
  // const occured_at = moment(incident.occured_at).format("YYYY-MM-DD HH:mm:ss");

  return {
    dateValue: date,
    description: incident.description,
  };
};

const purchaseProtectionItems = props => {
  const item = props.values.incident.purchase_protection_items;
  const is_repairable = item.is_repairable === 'true' ? true : false;
  const was_fully_charged_on_card =
    item.was_fully_charged_on_card === 'true' ? true : false;
  const is_internal =
    item.other_insurance_covering.is_internal === 'true' ? true : false;
  // const is_full_charged_on_card = item.is_full_charged_on_card === 'true';  AWAITING API UPDATE
  let obj = [
    {
      manufacturer: item.manufacturer,
      model_number: item.model_number,
      is_repairable: is_repairable,
      was_fully_charged_on_card,
      status: item.status,
      purchase_date: moment(item.purchase_date).format('YYYY-MM-DD HH:mm:ss'),
      // is_full_charged_on_card: item.is_full_charged_on_card,  AWAITING API UPDATE
      price: {
        value: item.price.value.length > 0 ? Number(item.price.value) : '',
        currency: item.price.currency,
      },
      amount_claimed: {
        value: item.price.value.length > 0 ? Number(item.price.value) : 0,
        currency: item.price.currency,
      },
      other_insurance_covering: [
        {
          is_internal: false,
          amount: {
            value:
              item.other_insurance_covering.amount.value.length > 0
                ? Number(item.other_insurance_covering.amount.value)
                : '',
            currency: item.price.currency,
          },
        },
      ],
    },
  ];
  if (!is_internal) {
    delete obj[0].other_insurance_covering;
  }

  return obj;
};

const total_amount_claimedToApi = props => {
  let total_amount;
  let item = props.values.incident.purchase_protection_items.price;
  const is_repairable = stringToBoolean(
    props.values.incident.purchase_protection_items.is_repairable
  );
  const other_insurance = stringToBoolean(
    props.values.incident.purchase_protection_items.other_insurance_covering
      .is_internal
  );
  const value_repairable =
    props.values.incident.purchase_protection_items.amount_claimed.value;
  const value_other_insurance =
    props.values.incident.purchase_protection_items.other_insurance_covering
      .amount.value;
  const cell_phone_price =
    props.values.incident.purchase_protection_items.price.value;

  if (is_repairable && other_insurance) {
    total_amount =
      parseFloat(value_repairable) - parseFloat(value_other_insurance);
  } else if (is_repairable && !other_insurance) {
    total_amount = parseFloat(value_repairable);
  } else if (!is_repairable && other_insurance) {
    total_amount =
      parseFloat(cell_phone_price) - parseFloat(value_other_insurance);
  } else if (!is_repairable && !other_insurance) {
    total_amount = parseFloat(cell_phone_price);
  }

  return {
    value: Number(total_amount.toFixed(2)),
    currency: item.currency,
  };
};
