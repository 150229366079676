import React, { useContext } from 'react';
import ContactDetails from './ContactDetails/ContactDetails';
import YourDependents from './YourDependents/YourDependents';
import DividingLine from '../../../components/DividingLine/DividingLine';
import BankInformation from './BankInformation/BankInformation';
import PrivateYourCards from '../../../components/PrivateYourCards/PrivateYourCards';
import PersonalDocuments from './PersonalDocuments/PersonalDocuments';
import { ENABLE_UUP } from '../../../utils/environments_variables';
import YourVoiceID from './YourVoiceID/YourVoiceID';
import OCTDetails from './OCTDetails/OCTDetails';
import { IntlContext } from '../../../intl';
import { showOCT } from '../../Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/oct-const';

const ProfileAccount = () => {
  const { country } = useContext(IntlContext);
  const ENABLE_OCT = showOCT(country);
  return (
    <div>
      <div>
        <ContactDetails />
      </div>
      <DividingLine />

      {ENABLE_UUP && (
        <>
          <div>
            <PersonalDocuments />
          </div>
          <DividingLine />
        </>
      )}

      <div>
        <PrivateYourCards />
      </div>
      <DividingLine />
      <div>
        <BankInformation isProfile />
      </div>
      <DividingLine />
      {ENABLE_OCT && (
        <>
          <div>
            <OCTDetails />
          </div>

          <DividingLine />
        </>
      )}
      <div>
        <YourDependents />
      </div>
      <DividingLine />
      <div>
        <YourVoiceID />
      </div>
    </div>
  );
};

export default ProfileAccount;
