export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting Terms and Conditions',
  es_ES: 'Aceptación de Términos y Condiciones',
  pt_BR: 'Aceitação dos Termos e Condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const TEXT_INCLUDE_CERTIFICATE = {
  en_US: 'Continue',
  pt_BR: 'Incluir o bilhete de seguro',
  es_ES: 'Continúe',
};

export const TEXT_SELECT_CERTIFICATE = {
  en_US: 'Select travel assistance certificate',
  pt_BR: 'Selecionar bilhete seguro viagem',
  es_ES: 'Seleccionar certificado de asistencia en viaje',
};

export const TEXT_SEARCH_CERTIFICATES = {
  en_US: 'Search certificates',
  pt_BR: 'Pesquisar bilhetes',
  es_ES: 'Buscar certificados',
};

export const MESSAGE_WITHOUT_CERTIFICATE = {
  en_US:
    'You are creating an International Emergency Medical Services claim without a Travel Certificate associated. Are you sure?',
  es_ES:
    'Está creando una reclamación de servicios médicos de emergencia internacional sin un certificado de viaje asociado. ¿Está seguro?',
  pt_BR: '',
};

export const MESSAGE_EFFECTIVE_OCTOBER = {
  en_US: 'Effective October 1 2021, a Travel Certificate must be generated.',
  es_ES:
    'A partir del 1 de octubre de 2021, se debe generar un Certificado de viaje.',
  pt_BR: '',
};

export const TEXT_YES = {
  en_US: "Yes, I'm sure",
  es_ES: 'Sí, estoy seguro',
  pt_BR: '',
};

export const TEXT_NO = {
  en_US: 'No, associate a certificate',
  es_ES: 'No, asociar un certificado',
  pt_BR: '',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date*',
  pt_BR: 'Data do incidente*',
  es_ES: 'Fecha del incidente*',
};

export const GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  pt_BR: 'Gerar bilhete',
  es_ES: 'Generar un certificado',
};

export const PURCHASE_SUBTITLE_TERMS_CROSSELL = {
  en_US: 'Coverage plan for your trip',
  pt_BR: 'Plano de cobertura da sua viagem',
  es_ES: 'Plan de cobertura para tu viaje',
};

export const PURCHASE_SUBTITLE_TERMS_UPSELL = {
  en_US: 'Upgrade coverage plan to your trip',
  pt_BR: 'Plano para aumento da cobertura da sua viagem',
  es_ES: 'Plan para aumentar tu cobertura de viaje',
};

export const TERMS_CONDITIONS_1_CROSSELL = {
  en_US: 'I have read and accept the terms and conditions.',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const TERMS_CONDITIONS_2_CROSSELL = {
  en_US:
    "I understand that an international ticket needs to be purchase with the same Visa card I'm using to purchase this coverage.",
  pt_BR:
    'Eu entendo que um bilhete internacional deve ser comprado com o mesmo cartão Visa que estou usando para comprar esta cobertura.',
  es_ES:
    'Entiendo que un boleto internacional debe comprarse con la misma tarjeta Visa que estoy usando para comprar esta cobertura.',
};

export const TERMS_CONDITIONS_3_CROSSELL = {
  en_US:
    'I understand that the coverage is limited to 60 consecutive days from departure of country of residence or country where card was issued (country of residence from all passengers).',
  pt_BR:
    'Eu entendo que a cobertura está limitada a 60 dias consecutivos a partir da saída do país de residência ou país onde o cartão foi emitido (país de residência de todos os passageiros).',
  es_ES:
    'Entiendo que la cobertura se limita a 60 días consecutivos desde la salida del país de residencia o país donde se emitió la tarjeta (país de residencia de todos los pasajeros).',
};

export const TERMS_CONDITIONS_1_UPSELL = {
  en_US: 'I have read and accept the terms and conditions.',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const TERMS_CONDITIONS_2_UPSELL = {
  en_US:
    "I understand that an international ticket needs to be purchase with the same Visa card I'm using to purchase this additional coverage.",
  pt_BR:
    'Eu entendo que um bilhete internacional deve ser comprado com o mesmo cartão Visa que estou usando para comprar esta cobertura adicional.',
  es_ES:
    'Entiendo que un boleto internacional debe comprarse con la misma tarjeta Visa que estoy usando para comprar esta cobertura adicional.',
};

export const TERMS_CONDITIONS_3_UPSELL = {
  en_US:
    'I understand that the coverage is limited to 60 consecutive days from departure of country of residence or country where card was issued (country of residence from all passengers).',
  pt_BR:
    'Eu entendo que a cobertura está limitada a 60 dias consecutivos a partir da saída do país de residência ou país onde o cartão foi emitido (país de residência de todos os passageiros).',
  es_ES:
    'Entiendo que la cobertura se limita a 60 días consecutivos desde la salida del país de residencia o país donde se emitió la tarjeta (país de residencia de todos los pasajeros).',
};

export const TERMS_CONDITIONS_4_ANNUAL = {
  en_US:
    'I understand that for each trip, I must visit the Visa Benefits Portal and generate a new travel certificate with all trip details.',
  pt_BR:
    'Eu entendo que, para cada viagem, devo visitar o Portal de Benefícios Visa e gerar um novo certificado de viagem informando os detalhes da viagem.',
  es_ES:
    'Entiendo que para cada viaje, debo visitar el Portal de beneficios de Visa y generar un nuevo certificado de viaje que informe los detalles del viaje.',
};

export const BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};
