import { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getAffectedPersonComment,
  getLastName,
  getName,
  getCardholderRelationship,
  getAddress,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { OTHER_VALUE } from '../../../../../../utils/consts';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [affectedPerson, setAffectedPerson] = useState({});

  useEffect(() => {
    const affectedPerson = (claimDetails && claimDetails.affected_person) || {};
    setAffectedPerson(affectedPerson);
  }, [claimDetails]);

  const isRelatioshipOther = affectedPerson.relationship === OTHER_VALUE;

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_AFFECTED_PERSON} simpleLine />
      </div>
      <div className={`col-sm-12 col-md-${isRelatioshipOther ? '3' : '4'}`}>
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={getCardholderRelationship(affectedPerson, intl)}
        />
      </div>
      <div className={`col-sm-12 col-md-${isRelatioshipOther ? '3' : '4'}`}>
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={getLastName(affectedPerson)}
        />
      </div>
      <div className={`col-sm-12 col-md-${isRelatioshipOther ? '3' : '4'}`}>
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={getName(affectedPerson)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        {isRelatioshipOther && (
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={getAffectedPersonComment(affectedPerson)}
          />
        )}
      </div>
      <div className="col-sm-12 col-md-12">
        <InfoLabelValue
          label={intl.LABEL_ADDRESS}
          value={getAddress(
            (affectedPerson && affectedPerson.address) || {},
            idiom
          )}
        />
      </div>
    </>
  );
};

export default PersonalArea;
