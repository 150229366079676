export const LABEL_TRIP = {
  en_US: 'Trip information',
  pt_BR: 'Informações de viagem',
  es_ES: 'Información de viaje',
};

export const FIELD_VALIDITY = {
  en_US: 'Validity',
  pt_BR: 'Validade',
  es_ES: 'Validez',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const FIELD_VALIDITY_VALUE_INTERNACIONAL = {
  en_US: 'International trip',
  pt_BR: 'Viajem internacional',
  es_ES: 'Viaje internacional',
};

export const FIELD_VALIDITY_VALUE_DOMESTIC = {
  en_US: 'Domestic trip',
  pt_BR: 'Viagem nacional',
  es_ES: 'Viaje nacional',
};

export const FIELD_ISSUANCE = {
  en_US: 'Issuance date *',
  pt_BR: 'Data de emissão *',
  es_ES: 'Fecha de emisión *',
};

export const FIELD_ONEWAY = {
  en_US: 'One-way trip',
  pt_BR: 'Viagem só de ida',
  es_ES: 'Viaje solo de ida',
};

export const FIELD_START_DATE_COUNTRY = {
  en_US: 'Departure date from origin country',
  pt_BR: 'Data de saída do país de origem',
  es_ES: 'Fecha de salida del país de origen',
};

export const FIELD_RETURN_DATE_COUNTRY = {
  en_US: 'Return date to the origin country',
  pt_BR: 'Data de retorno do país de origem',
  es_ES: 'Fecha de regreso al país de origen',
};

export const FIELD_START_DATE_CITY = {
  en_US: 'Departure date *',
  pt_BR: 'Data de saída',
  es_ES: 'Fecha de salida *',
};

export const FIELD_RETURN_DATE_CITY = {
  en_US: 'Return date *',
  pt_BR: 'Data de retorno',
  es_ES: 'Fecha de regreso *',
};

export const VALIDATE_START_DATE_COUNTRY = {
  en_US: 'Departure date',
  pt_BR: 'Data de saída',
  es_ES: 'Fecha de início',
};

export const VALIDATE_RETURN_DATE_COUNTRY = {
  en_US: 'Return date *',
  pt_BR: 'Data de retorno *',
  es_ES: 'Fecha de regreso *',
};
