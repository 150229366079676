import React, { useEffect } from 'react';
import styles from '../scheduleList.module.scss';
import Button from '../../../../../../../componentsV2/Button/Button';

const setSelectedSchedule = (schedule, hour) => {
  return schedule.map(list => {
    list.selected = false;
    if (list.time === hour) {
      list.selected = true;
    }
    return list;
  });
};

const ScheduleListButtonNAL = props => {
  const { schedule, setSchedule, setFieldValue, setFieldTouched } = props;

  const getScrollPosition = schedule.find(item => {
    return item.is_available;
  });
  const getIdtoScrollPosition = (timeList, timeEnable) => {
    if (!timeEnable) return 'timeList';
    return timeList === timeEnable.time ? 'getPositionScroll' : '';
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById('getPositionScroll')) {
        const buttonTop =
          document.getElementById('getPositionScroll').offsetTop;
        const divBoxTop = document.getElementById('divBoxSchedule').offsetTop;
        document.getElementById('divBoxSchedule').scrollTop =
          buttonTop - divBoxTop;
      }
    }, 100);
  }, []);
  return (
    <>
      {schedule.map((list, index) => (
        <Button
          key={`scheduleListKey-${index}`}
          className={styles.buttonSchedule}
          type={list.selected ? 'Primary' : 'Secondary'}
          testId={`buttonScheduleList-${index}`}
          id={getIdtoScrollPosition(list.time, getScrollPosition)}
          disabled={!list.is_available}
          onClick={() => {
            const newListSchedule = setSelectedSchedule(schedule, list.time);
            setSchedule(newListSchedule);
            setFieldTouched('appointment.time');
            setFieldValue('appointment.time', list.time);
            setFieldValue('appointment.validTime', list.server_time);
          }}
          label={list.time.replace(/:[^:]*$/, '')}
        />
      ))}
    </>
  );
};

export default ScheduleListButtonNAL;
