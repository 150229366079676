export const ACCIDENT_REASON = {
  en_US: 'Accident reason',
  pt_BR: 'Motivo do incidente',
  es_ES: 'Motivo del accidente',
};

export const ACCIDENTAL_DISMEMBERMENT = {
  en_US: 'Accidental dismemberment',
  pt_BR: 'Desmembramento acidental',
  es_ES: 'Desmembramiento accidental',
};

export const ACCIDENTAL_DEATH = {
  en_US: 'Accidental death',
  pt_BR: 'Morte acidental',
  es_ES: 'Muerte accidental',
};

export const LABEL_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship',
  pt_BR: 'Relação',
  es_ES: 'Relación',
};

export const LABEL_OCCUPATION = {
  en_US: 'Profession',
  pt_BR: 'Profissão',
  es_ES: 'Profesión',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail',
  pt_BR: 'E-mail',
  es_ES: 'Correo eletrónico',
};

export const LABEL_NATIONALITY = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const LABEL_REGISTRATION_TYPE = {
  en_US: 'Passport',
  pt_BR: 'CPF',
  es_ES: 'Pasaporte',
};

export const LABEL_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

//Phone
export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const LABEL_MARITAL_STATUS = {
  en_US: 'Marital status',
  pt_BR: 'Estado civil',
  es_ES: 'Estado civil',
};

export const VALUE_STATUS_MARRIED = {
  en_US: 'Married',
  pt_BR: 'Casado',
  es_ES: 'Casado',
};

export const VALUE_STATUS_SINGLE = {
  en_US: 'Single',
  pt_BR: 'Solteiro',
  es_ES: 'Soltero',
};

export const LABEL_DATE_LIVE_TOGETHER = {
  en_US: 'Date from which live together',
  pt_BR: 'Data desde a qual vivem juntos',
  es_ES: 'Fecha desde la cual viven juntos',
};
