import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from '../BaggageDelay.module.scss';
import * as translations from './intl';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();
  const { values, handleChange, touched, handleBlur, errors } = props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className={`col-12`}>
        <Input
          label={intl.TEXT_DESCRIBE_CAUSES}
          type="text"
          value={values.incident.description}
          name="incident.description"
          onChange={handleChange}
          touched={touched.incident && touched.incident.description}
          onBlur={handleBlur}
          error={errors.incident && errors.incident.description}
          maxLength="255"
        />
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_TOTAL_AMOUT_CREDIT_CARD}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_TOTAL_AMOUT_CREDIT_CARD}
              defaultChecked={
                values.travel_information.is_full_charged_on_card === 'true'
              }
              value={true}
              text={intl.TEXT_OPTION_YES}
              name="travel_information.is_full_charged_on_card"
              onChange={handleChange}
              touched={
                touched.travel_information &&
                touched.travel_information.is_full_charged_on_card
              }
              onBlur={handleBlur}
              error={
                errors.travel_information &&
                errors.travel_information.is_full_charged_on_card
              }
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_TOTAL_AMOUT_CREDIT_CARD}
              defaultChecked={
                values.travel_information.is_full_charged_on_card === 'false'
              }
              value={false}
              text={intl.TEXT_OPTION_NO}
              name="travel_information.is_full_charged_on_card"
              onChange={handleChange}
              touched={
                touched.travel_information &&
                touched.travel_information.is_full_charged_on_card
              }
              onBlur={handleBlur}
              error={
                errors.travel_information &&
                errors.travel_information.is_full_charged_on_card
              }
            />
            {touched.travel_information &&
            touched.travel_information.is_full_charged_on_card &&
            errors.travel_information &&
            errors.travel_information.is_full_charged_on_card ? (
              <div className={styles.radioButtonError}>
                <label className={styles.errorLabel}>
                  {errors.travel_information.is_full_charged_on_card}
                </label>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentArea;
