import { useIntl } from '../../../intl';
import Chip, { ChipProps } from '../Chip';
import * as translations from './intl';
import * as config from './status';

type ContextType = keyof typeof config;

export interface StatusChipProps {
  context?: ContextType;
  status: config.StatusPossibility;
}

const StatusChip = ({ context = 'GENERICS', status }: StatusChipProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const matchConfig = config[context];
  const configObject = matchConfig(intl);

  const chipProps: ChipProps =
    configObject[status as keyof typeof configObject];

  return <Chip label={chipProps?.label} type={chipProps?.type} />;
};

export default StatusChip;
