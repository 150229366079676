import React, { useContext, useEffect } from 'react';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { IntlContext } from '../../../../../../../../intl';
import sanitize from '../../../../../../../../utils/sanitize';
import * as translations from './intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import { safeGet } from '../../../../../../../../utils/object';

/* const cardholder = 1;
const coverValuePerPerson = 300; */

const ReimbursementArea = props => {
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    touched,
  } = props;

  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const setCurrency = currency => {
    setFieldValue('total_amount_claimed.currency', currency);
    setFieldTouched('total_amount_claimed.currency', true);

    setFieldValue('other_insurance_covering.currency', currency);
    setFieldTouched('other_insurance_covering.currency', true);

    setFieldValue('other_insurance_covering2.currency', currency);
    setFieldTouched('other_insurance_covering2.currency', true);
  };

  useEffect(() => {
    const currency = values.total_amount_claimed.currency;
    const getCurrency = getCurrencyByHomeCountry();

    if (!currency && getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* useEffect(() => {
    const additionalTravellers =
      values.hasAdditional === 'true' ? values.additional_passengers.length : 0;
    const numberOfTravellers = additionalTravellers + cardholder;

    let totalAmount = coverValuePerPerson * numberOfTravellers;

    setFieldValue('total_amount_claimed.value', Number(totalAmount).toFixed(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.additional_passengers, values.hasAdditional]); */
  return (
    <div className={`row`}>
      <div className={`col-12 col-sm-12 col-md-6 `}>
        <Input
          label={intl.LABEL_TOTAL_REIMBURSED}
          type="text"
          maskedMoney
          textTooBig
          name="total_amount_claimed.value"
          tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
          value={values.total_amount_claimed.value}
          onChange={e => {
            handleChange(e);
          }}
          touched={safeGet(touched, 'total_amount_claimed.value')}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
          dataTestid="inputAmountClaimed"
        />
      </div>

      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          name="total_amount_claimed.currency"
          value={values.total_amount_claimed.currency}
          onChange={event => {
            setCurrency(event.target.value);
            handleChange();
          }}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid="comboboxTotalAmountCurrency"
        />
      </div>
    </div>
  );
};

export default ReimbursementArea;
