/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../ProtectedVacations.module.scss';
import * as translations from './intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

let ticketAmout = 0;
let accommodationCosts = 0;

const ExpensesArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    sumAmountReimbursed,
  } = props;

  useEffect(() => {
    ticketAmout = values.additional_costs_accomodation.price.value || 0;
    accommodationCosts = values.additional_costs_transport.price.value || 0;
  }, [
    values.additional_costs_accomodation.price.value,
    values.additional_costs_transport.price.value,
  ]);

  const total = values.reimbursed_transportation_insurance.value;
  const sumAmountCosts = e => {
    if (e.target.name === 'additional_costs_accomodation.price.value') {
      ticketAmout = e.target.value;
    }
    if (e.target.name === 'additional_costs_transport.price.value') {
      accommodationCosts = e.target.value;
    }
    const calc = parseFloat(
      Number(ticketAmout) + Number(accommodationCosts) - Number(total)
    ).toFixed(2);
    setFieldValue('total_amount_claimed.value', calc);
    handleChange(e);
  };

  const setCurrency = currency => {
    setFieldValue('additional_costs_transport.price.currencyValue', currency);
    setFieldTouched('additional_costs_transport.price.currencyValue', true);
  };

  useEffect(() => {
    const currency = values.additional_costs_transport.price.currencyValue;
    const getCurrency = getCurrencyByHomeCountry();

    if (!currency && getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.additional_costs_transport.price.currencyValue]);

  useEffect(() => {
    if (
      values.additional_costs_accomodation.amount_claimed.checked === 'false'
    ) {
      setFieldValue(
        'reimbursed_transportation_insurance.value',
        values.reimbursed_transportation_insurance.value -
          values.additional_costs_accomodation.amount_claimed.value
      );
      setFieldValue('additional_costs_accomodation.amount_claimed.value', 0);
    }
  }, [values.additional_costs_accomodation.amount_claimed.checked]);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.incidentPeriodArea} row`}>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.TEXT_TICKET_AMOUNT}
              type="text"
              maskedMoney
              possibleZero
              name="additional_costs_transport.price.value"
              dataTestid="InputAdditionalCostTranspPriceValue"
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={values.additional_costs_transport.price.value}
              onChange={sumAmountCosts}
              onBlur={handleBlur}
              touched={
                touched.additional_costs_transport &&
                touched.additional_costs_transport.price &&
                touched.additional_costs_transport.price.value
              }
              error={
                errors.additional_costs_transport &&
                errors.additional_costs_transport.price &&
                errors.additional_costs_transport.price.value
              }
            />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.LABEL_CURRENCY}
              name="additional_costs_transport.price.currencyValue"
              value={values.additional_costs_transport.price.currencyValue}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.additional_costs_transport &&
                touched.additional_costs_transport.price &&
                touched.additional_costs_transport.price.currencyValue
              }
              error={
                errors.additional_costs_transport &&
                errors.additional_costs_transport.price &&
                errors.additional_costs_transport.price.currencyValue
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              dataTestid="currencyPrice"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.TEXT_ACCOMMODATION}
              type="text"
              maskedMoney
              possibleZero
              name="additional_costs_accomodation.price.value"
              dataTestid="InputAdditionalCostAccomodPriceValue"
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={values.additional_costs_accomodation.price.value}
              onChange={sumAmountCosts}
              onBlur={handleBlur}
              touched={
                touched.additional_costs_accomodation &&
                touched.additional_costs_accomodation.price &&
                touched.additional_costs_accomodation.price.value
              }
              error={
                errors.additional_costs_accomodation &&
                errors.additional_costs_accomodation.price &&
                errors.additional_costs_accomodation.price.value
              }
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.LABEL_CURRENCY}
              name="additional_costs_accomodation.price.currencyValue"
              value={values.additional_costs_transport.price.currencyValue}
              disabled
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.additional_costs_accomodation &&
                errors.additional_costs_accomodation.price &&
                errors.additional_costs_accomodation.price.currencyValue
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              dataTestid="accomodationPrice"
            />
          </div>
        </div>

        <div className={`${styles.elementRadio} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_RECEIVE_ACCOMMODATION}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_ACCOMMODATION}
              describe={intl.DESCRIBE_RECEIVE_ACCOMMODATION}
              defaultChecked={
                values.additional_costs_accomodation.amount_claimed.checked ===
                'true'
              }
              text={intl.TEXT_OPTION_YES}
              name="additional_costs_accomodation.amount_claimed.checked"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_ACCOMMODATION}
              defaultChecked={
                values.additional_costs_accomodation.amount_claimed.checked ===
                'false'
              }
              text={intl.TEXT_OPTION_NO}
              name="additional_costs_accomodation.amount_claimed.checked"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`col-12 col-sm-12 col-md-6  ${
              values.additional_costs_accomodation.amount_claimed.checked ===
              'true'
                ? ''
                : styles.none
            }`}
            role="contentAccomodationAmount"
          >
            <Input
              label={intl.TEXT_AMOUNT_PAID_ACCOMMODATION}
              type="text"
              maskedMoney
              name="additional_costs_accomodation.amount_claimed.value"
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={values.additional_costs_accomodation.amount_claimed.value}
              onChange={sumAmountReimbursed}
              onBlur={handleBlur}
              touched={
                touched.additional_costs_accomodation &&
                touched.additional_costs_accomodation.amount_claimed &&
                touched.additional_costs_accomodation.amount_claimed.value
              }
              error={
                errors.additional_costs_accomodation &&
                errors.additional_costs_accomodation.amount_claimed &&
                errors.additional_costs_accomodation.amount_claimed.value
              }
              dataTestid="inputAdditionalCostAccomodationAmount"
            />
          </div>

          <div
            className={`col-12 col-sm-12 col-md-6  ${
              values.additional_costs_accomodation.amount_claimed.checked ===
              'true'
                ? ''
                : styles.none
            }`}
          >
            <Select
              label={intl.LABEL_CURRENCY}
              name="additional_costs_accomodation.amount_claimed.currencyValue"
              disabled
              value={values.additional_costs_transport.price.currencyValue}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                errors.additional_costs_accomodation &&
                errors.additional_costs_accomodation.amount_claimed &&
                errors.additional_costs_accomodation.amount_claimed
                  .currencyValue
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              dataTestid="selectAdditionalCostAccomodationAmount"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpensesArea;
