import React, { useEffect, useContext } from 'react';

import styles from './styles.module.scss';
import { getQueryVariable } from '../../utils/uri';
import { clearListCookies, setCookie, getCookie } from '../../utils/cookies';
import Loading from '../../components/Loading/Loading';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { controller } from '../../services';

export const _redirect = () => {
  window.location.href = getUrlRefererParameter()
    ? getUrlRefererParameter()
    : '/';
};

const getUrlRefererParameter = () => {
  const refererParameter = getQueryVariable('referer');
  let LOGOUT_REFERER = process.env.REACT_APP_LOGOUT_REFERER;
  if (!refererParameter) return null;
  let urlRedirect;
  LOGOUT_REFERER = LOGOUT_REFERER.split(' ');
  LOGOUT_REFERER.map(referer => {
    if (referer === refererParameter) {
      urlRedirect = referer;
    }
  });
  return urlRedirect;
};

const Logout = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  useEffect(() => {
    controller.abort();
    localStorage.removeItem('hideAlertModal');
    sessionStorage.clear();
    const refererParameter = getQueryVariable('referer');
    let LOGOUT_REFERER = process.env.REACT_APP_LOGOUT_REFERER;

    if (refererParameter) {
      let urlRedirect;
      LOGOUT_REFERER = LOGOUT_REFERER.split(' ');
      LOGOUT_REFERER.map(referer => {
        if (referer === refererParameter) {
          urlRedirect = referer;
        }
      });
      if (urlRedirect) {
        sessionStorage.setItem('redirectRefererAfterLogout', urlRedirect);
      }
    }

    setTimeout(() => {
      window.location.href = '/logout';
    }, 1000);

    return () => {
      const homeCountry = getCookie('homeCountry');
      setCookie('homeCountry', homeCountry);
    };
  }, []);

  return (
    <div className={styles.main}>
      <div>
        <Loading scale="0.8" text={intl.LOGGING_OUT} />
      </div>
    </div>
  );
};

export default Logout;
