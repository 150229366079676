/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ConcernedItems from '../ConcernedItems/ConcernedItems';
import styles from '../BaggageLoss.module.scss';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';

const TotalAmountArea = props => {
  const { setFieldValue, setFieldTouched } = props;

  const setCurrency = currency => {
    setFieldValue('total_amount_claimed.currency', currency);
    setFieldTouched('total_amount_claimed.currency', true);
  };

  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <ConcernedItems {...props} />
      </div>
    </div>
  );
};

export default TotalAmountArea;
