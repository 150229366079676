import { useContext } from 'react';
import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import * as constants from '../../consts';
import sanitize from '../../../../../../../../../utils/sanitize';
import PassengerRemove from './PassengerRemove';
import Calendar from '../../../../../../../../../components/Calendar/Calendar';
import styles from '../AdditionalPassengers.module.scss';
import GroupDivisor from '../../../../../../../../../components/GroupDivisor';

const Passenger = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    index,
    handleChange,
    handleBlur,
    _touched,
    _errors,
    setFieldTouched,
  } = props;

  return (
    <div key={`additionalItem-${index + 1}`} className={styles.itemPassenger}>
      <div className="row">
        <div className="col-12">
          <GroupDivisor title={`${intl.SUBTITLE_AREA} #${index + 1}`} />
        </div>
        <div className="col-12 col-md-4">
          <Input
            value={values.additional_passengers[index].first_name}
            name={`additional_passengers[${index}].first_name`}
            dataTestid={`InputAdditionalName-${index}`}
            label={intl.LABEL_NAME}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={_touched && _touched.first_name}
            error={_errors && _errors.first_name}
            maxLength="40"
          />
        </div>
        <div className="col-12 col-md-4">
          <Input
            value={values.additional_passengers[index].last_name}
            name={`additional_passengers[${index}].last_name`}
            dataTestid={`InputAdditionalLastName-${index}`}
            label={intl.LABEL_LAST_NAME}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            touched={_touched && _touched.last_name}
            error={_errors && _errors.last_name}
            maxLength="80"
          />
        </div>
        <div className="col-12 col-md-4">
          <Calendar
            value={values.additional_passengers[index].birth_date}
            label={intl.LABEL_BIRTH_DATE}
            name={`additional_passengers[${index}].birth_date`}
            onChange={handleChange}
            maxDate={new Date()}
            touched={_touched && _touched.birth_date}
            onBlur={handleBlur}
            error={_errors && _errors.birth_date}
            setFieldTouched={setFieldTouched}
            dataTestid="additionalBirthDate"
          />
        </div>
        <div className="col-12 col-md-4">
          <Select
            label={intl.LABEL_GENDER}
            value={values.additional_passengers[index].gender}
            name={`additional_passengers[${index}].gender`}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={_touched && _touched.gender}
            error={_errors && _errors.gender}
            dataTestid="additionalGender"
          >
            <option value="">{intl.LABEL_GENDER}</option>
            <option value={constants.MALE_VALUE}>{intl.LABEL_MALE}</option>
            <option value={constants.FEMALE_VALUE}>{intl.LABEL_FEMALE}</option>
            <option value={constants.UNKNOWN_VALUE}>
              {intl.LABEL_UNKNOWN}
            </option>
          </Select>
        </div>
        <div className="col-12 col-md-4">
          <Select
            label={intl.NATIONALITY_LABEL}
            value={values.additional_passengers[index].nationalities}
            name={`additional_passengers[${index}].nationalities`}
            touched={_touched && _touched.nationalities}
            error={_errors && _errors.nationalities}
            onBlur={handleBlur}
            onChange={handleChange}
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.NATIONALITY_OPTIONS),
            }}
            dataTestid="nationalities"
          />
        </div>
        <div className={`col-12 col-sm-4`}>
          <Select
            label={intl.TEXT_RELATIONSHIP_CARD_HOLDER}
            value={values.additional_passengers[index].relationship}
            name={`additional_passengers[${index}].relationship`}
            onChange={handleChange}
            touched={_touched && _touched.relationship}
            onBlur={handleBlur}
            error={_errors && _errors.relationship}
          >
            <option value="">{intl.TEXT_RELATIONSHIP_CARD_HOLDER}</option>
            <option value={constants.SPOUSE_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
            </option>
            <option value={constants.DEPENDENT_VALUE}>
              {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
            </option>
            <option value={constants.OTHER_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
            </option>
          </Select>
        </div>
        <div
          className={`col-12 ${
            values.additional_passengers[index].relationship !==
            constants.OTHER_VALUE
              ? styles.none
              : ''
          }`}
        >
          <Input
            value={values.additional_passengers[index].comment}
            label={intl.TEXT_OTHER}
            name={`additional_passengers[${index}].comment`}
            dataTestid="InputRelationshipComment"
            type="text"
            disabled={
              values.additional_passengers[index].relationship !==
              constants.OTHER_VALUE
            }
            onChange={handleChange}
            touched={_touched && _touched.comment}
            onBlur={handleBlur}
            error={_errors && _errors.comment}
            maxLength="255"
          />
        </div>
      </div>
      <div className={`row ${styles.footer}`}>
        <PassengerRemove {...props} intl={intl} />
      </div>
    </div>
  );
};

export default Passenger;
