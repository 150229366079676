import React, { useContext } from 'react';
import styles from './TitlePageMarketing.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { sanitize } from 'dompurify';

const TitlePageMarketing = ({
  title,
  subtitle,
  textAlign = 'left',
  fontSize,
}) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  return (
    <div className={styles.visaBenefits}>
      <h2
        id="mainContent"
        style={{
          textAlign: textAlign,
          fontSize: fontSize ? fontSize : '52px',
        }}
        className={styles[theme]}
        dangerouslySetInnerHTML={{ __html: sanitize(title) }}
      ></h2>
      {subtitle && (
        <p
          style={{ textAlign: textAlign }}
          data-testid="paragraph"
          dangerouslySetInnerHTML={{ __html: sanitize(subtitle) }}
        ></p>
      )}
    </div>
  );
};

export default TitlePageMarketing;
