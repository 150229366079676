import { getCHDependents } from './getCHDependents';
import { userServices } from '../../../../services';

export const loadData = async (cn, setData) => {
  try {
    const cardHolderPromise = userServices.getCHInfo(cn);
    const dependetsPromise = getCHDependents(cn);
    const responses = await Promise.all([cardHolderPromise, dependetsPromise]);
    const data = {
      cardHolder: responses[0].data,
      dependents: responses[1],
    };
    setData(data);
  } catch (error) {
    setData('ERROR');
  }
};
