import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';

const DoctorArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row`}>
      <div className="col-12">
        <Input
          dataTestid="medicalCenterName"
          value={values.medical_service.medical_center_name}
          label={intl.LABEL_HOSPITAL_NAME}
          name="medical_service.medical_center_name"
          type="text"
          onChange={handleChange}
          touched={
            touched.medical_service &&
            touched.medical_service.medical_center_name
          }
          onBlur={handleBlur}
          error={
            errors.medical_service && errors.medical_service.medical_center_name
          }
          textTooBig
          maxLength="100"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          dataTestid="medicalPersonName"
          value={values.attending_physician.person.first_name}
          label={intl.LABEL_DOCTOR_NAME}
          name="attending_physician.person.first_name"
          type="text"
          onChange={handleChange}
          touched={
            touched.attending_physician &&
            touched.attending_physician.person &&
            touched.attending_physician.person.first_name
          }
          onBlur={handleBlur}
          error={
            errors.attending_physician &&
            errors.attending_physician.person &&
            errors.attending_physician.person.first_name
          }
          maxLength="40"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          dataTestid="medicalPersonLastName"
          value={values.attending_physician.person.last_name}
          label={intl.LABEL_DOCTOR_LAST_NAME}
          name="attending_physician.person.last_name"
          type="text"
          onChange={handleChange}
          touched={
            touched.attending_physician &&
            touched.attending_physician.person &&
            touched.attending_physician.person.last_name
          }
          onBlur={handleBlur}
          error={
            errors.attending_physician &&
            errors.attending_physician.person &&
            errors.attending_physician.person.last_name
          }
          textTooBig
          maxLength="80"
        />
      </div>
      <div className="col-12">
        <Input
          dataTestid="doctorEmail"
          value={values.attending_physician.email}
          label={intl.LABEL_DOCTOR_EMAIL}
          name="attending_physician.email"
          type="text"
          mask="maskedEmail"
          onChange={handleChange}
          touched={
            touched.attending_physician && touched.attending_physician.email
          }
          onBlur={handleBlur}
          error={errors.attending_physician && errors.attending_physician.email}
        />
      </div>
    </div>
  );
};

export default DoctorArea;
