const getLastElement = allElements => {
  const listElements = Array.prototype.slice
    .call(allElements)
    .filter(element => element.id !== 'TxEncryptionKey');

  return listElements[listElements.length - 1];
};

let firstElementModalFocus, lastElementModalFocus;

const listener = function (event) {
  let isTab = event.key === 'Tab';
  if (!isTab) {
    return;
  }

  if (event.shiftKey) {
    if (document.activeElement === firstElementModalFocus) {
      if (lastElementModalFocus) {
        lastElementModalFocus.focus();
        event.stopPropagation();
      }
    }
  } else {
    if (document.activeElement === lastElementModalFocus) {
      if (firstElementModalFocus) {
        firstElementModalFocus.focus();
        event.stopPropagation();
      }
    }
  }
};

export const trapFocusInsideDialog = () => {
  const focusableElements =
    'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

  const modal = document.querySelector('#wrapperModal');

  if (!modal) return;
  firstElementModalFocus = modal.querySelectorAll(focusableElements)[0];
  const allElementModalFocus = modal.querySelectorAll(focusableElements);

  lastElementModalFocus = getLastElement(allElementModalFocus);

  document.addEventListener('keydown', listener);

  if (firstElementModalFocus) {
    firstElementModalFocus.focus();
  }
};

export const removeTrapFocusInsideDialog = () => {
  document.removeEventListener('keydown', listener);
};
