import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';
import { safeGet } from '../../../../../../../../utils/object';
import sanitize from '../../../../../../../../utils/sanitize';

const ReimbursedArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  const amountClaimedValue = () => {
    let total_amount;
    const is_repairable = stringToBoolean(
      values.purchase_protection_items.is_repairable
    );
    const other_insurance = stringToBoolean(
      values.purchase_protection_items.other_insurance_covering.is_internal
    );

    const cell_phone_price =
      values.purchase_protection_items.price.value.length === 0
        ? '0.00'
        : values.purchase_protection_items.price.value;
    const value_repairable =
      values.purchase_protection_items.amount_claimed.value.length === 0
        ? '0.00'
        : values.purchase_protection_items.amount_claimed.value;
    const value_other_insurance =
      values.purchase_protection_items.other_insurance_covering.amount.value
        .length === 0
        ? '0.00'
        : values.purchase_protection_items.other_insurance_covering.amount
            .value;

    if (is_repairable && other_insurance) {
      total_amount =
        value_repairable.length > 0 && value_other_insurance.length > 0
          ? (
              parseFloat(value_repairable) - parseFloat(value_other_insurance)
            ).toFixed(2)
          : '0.00';
    } else if (is_repairable && !other_insurance) {
      total_amount =
        value_repairable.length > 0
          ? parseFloat(value_repairable).toFixed(2)
          : '0.00';
    } else if (!is_repairable && other_insurance) {
      total_amount =
        cell_phone_price.length > 0 && value_other_insurance.length > 0
          ? (
              parseFloat(cell_phone_price) - parseFloat(value_other_insurance)
            ).toFixed(2)
          : '0.00';
    } else if (!is_repairable && !other_insurance) {
      total_amount =
        cell_phone_price.length > 0
          ? parseFloat(cell_phone_price).toFixed(2)
          : '0.00';
    }

    return total_amount !== 0 ? total_amount : '0.00';
  };

  return (
    <div className="row">
      <div className={`col-12 col-md-6`}>
        <Input
          value={amountClaimedValue()}
          label={intl.LABEL_TOTAL_AMOUNT_REIMBURSERD}
          name="total_amount_claimed.value"
          type="text"
          maskedMoney
          tooltipText={intl.TOOLTIP_AMOUNT_REQUESTED}
          disabled
          onChange={handleChange}
          touched={safeGet(touched, 'total_amount_claimed.value')}
          onBlur={handleBlur}
          error={safeGet(errors, 'total_amount_claimed.value')}
        />
      </div>
      <div className={`col-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items.price.currency}
          name="other_insurance_covering.currency"
          disabled
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onChange={handleChange}
          touched={safeGet(touched, 'total_amount_claimed.currency')}
          onBlur={handleBlur}
          error={safeGet(errors, 'total_amount_claimed.currency')}
        />
      </div>
    </div>
  );
};

export default ReimbursedArea;
