import {
  DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE,
  removeCountryFromList,
} from './countries';

export const es_countries = `
<option value="AF"> Afganistán </option>
<option value="AL"> Albania </option>
<option value="DE"> Alemania </option>
<option value="AD"> Andorra </option>
<option value="AO"> Angola </option>
<option value="AI"> Anguila </option>
<option value="AG"> Antigua y Barbuda </option>
<option value="TF"> Antillas Francesas </option>
<option value="AN"> Antillas Neerlandesas </option>
<option value="AQ"> Antártida </option>
<option value="SA"> Arabia Saudita </option>
<option value="DZ"> Argelia </option>
<option value="AR"> Argentina </option>
<option value="AM"> Armenia </option>
<option value="AW"> Aruba </option>
<option value="AC"> Ascension </option>
<option value="AU"> Australia </option>
<option value="AT"> Austria </option>
<option value="AZ"> Azerbaiyán </option>
<option value="BS"> Bahamas </option>
<option value="BH"> Bahrein </option>
<option value="BD"> Bangladesh </option>
<option value="BB"> Barbados </option>
<option value="BY"> Belarús </option>
<option value="BZ"> Belice </option>
<option value="BJ"> Benín </option>
<option value="BM"> Bermudas </option>
<option value="BO"> Bolivia </option>
<option value="BA"> Bosnia y Herzegovina </option>
<option value="BW"> Botsuana </option>
<option value="BR"> Brasil </option>
<option value="BN"> Brunei </option>
<option value="BG"> Bulgaria </option>
<option value="BF"> Burkina Faso </option>
<option value="BI"> Burundi </option>
<option value="BT"> Bután </option>
<option value="BE"> Bélgica </option>
<option value="CV"> Cabo Verde </option>
<option value="KH"> Camboya </option>
<option value="CM"> Camerún </option>
<option value="CA"> Canada </option>
<option value="QA"> Catar </option>
<option value="TD"> Chad </option>
<option value="CL"> Chile </option>
<option value="CN"> China </option>
<option value="CY"> Chipre </option>
<option value="CO"> Colombia </option>
<option value="KM"> Comoras </option>
<option value="CG"> Congo </option>
<option value="KR"> Corea del Sur </option>
<option value="CR"> Costa Rica </option>
<option value="CI"> Costa de marfil </option>
<option value="HR"> Croacia </option>
<option value="CU"> Cuba </option>
<option value="CW"> Curazao </option>
<option value="DK"> Dinamarca </option>
<option value="DJ"> Djibouti </option>
<option value="DM"> Dominica </option>
<option value="EC"> Ecuador </option>
<option value="EG"> Egipto </option>
<option value="SV"> El Salvador </option>
<option value="AE"> Emiratos Árabes Unidos </option>
<option value="ER"> Eritrea </option>
<option value="SK"> Eslovaquia </option>
<option value="SI"> Eslovenia </option>
<option value="ES"> España </option>
<option value="FM"> Estados Federados de Micronésia </option>
<option value="US"> Estados Unidos </option>
<option value="EE"> Estonia </option>
<option value="ET"> Etiopía </option>
<option value="PH"> Filipinas </option>
<option value="FI"> Finlandia </option>
<option value="FJ"> Fiyi </option>
<option value="FR"> Francia </option>
<option value="GA"> Gabón </option>
<option value="GM"> Gambia </option>
<option value="GE"> Georgia </option>
<option value="GH"> Ghana </option>
<option value="GI"> Gibraltar </option>
<option value="GD"> Granada </option>
<option value="GR"> Grecia </option>
<option value="GL"> Groenlandia </option>
<option value="GP"> Guadeloupe </option>
<option value="GU"> Guam </option>
<option value="GT"> Guatemala </option>
<option value="GF"> Guayana Francesa </option>
<option value="GY"> Guiana </option>
<option value="GQ"> Guinea Ecuatorial </option>
<option value="GW"> Guinea-Bisáu </option>
<option value="HT"> Haiti </option>
<option value="HN"> Honduras </option>
<option value="HK"> Hong Kong </option>
<option value="HU"> Hungría </option>
<option value="IN"> India </option>
<option value="ID"> Indonesia </option>
<option value="IQ"> Irak </option>
<option value="IE"> Irlanda </option>
<option value="IM"> Isla de Man </option>
<option value="CX"> Isla de Navidad </option>
<option value="EI"> Isla de Pascua </option>
<option value="YT"> Isla Mayotte </option>
<option value="NF"> Isla Norfolk </option>
<option value="RE"> Isla Reunion </option>
<option value="UM"> Isla Wake </option>
<option value="WF"> Isla Wallis Y Futuna </option>
<option value="IS"> Islandia </option>
<option value="KY"> Islas Caimán </option>
<option value="CC"> Islas Coco </option>
<option value="CK"> Islas Cook </option>
<option value="FK"> Islas Falkland </option>
<option value="FO"> Islas Feroe </option>
<option value="MP"> Islas Mariana </option>
<option value="MH"> Islas Marshall </option>
<option value="UM"> Islas Midway </option>
<option value="SB"> Islas Salomón </option>
<option value="TC"> Islas Turcas y Caicos </option>
<option value="VG"> Islas Vírgenes Británicas </option>
<option value="VI"> Islas Vírgenes de los Estados Unidos </option>
<option value="IL"> Israel </option>
<option value="IT"> Italia </option>
<option value="JM"> Jamaica </option>
<option value="JP"> Japón </option>
<option value="JO"> Jordania </option>
<option value="KZ"> Kazajistán </option>
<option value="KE"> Kenia </option>
<option value="KG"> Kirguistán </option>
<option value="KI"> Kiribati </option>
<option value="XK"> Kosovo </option>
<option value="KW"> Kuwait </option>
<option value="LA"> Laos </option>
<option value="LS"> Lesoto </option>
<option value="LV"> Letonia </option>
<option value="LR"> Liberia </option>
<option value="LY"> Libia </option>
<option value="LI"> Liechtenstein </option>
<option value="LT"> Lituania </option>
<option value="LU"> Luxemburgo </option>
<option value="LB"> Líbano </option>
<option value="MO"> Macau </option>
<option value="MK"> Macedonia </option>
<option value="MG"> Madagascar </option>
<option value="MY"> Malasia </option>
<option value="MW"> Malaui </option>
<option value="MV"> Maldivas </option>
<option value="MT"> Malta </option>
<option value="ML"> Malí </option>
<option value="MA"> Marruecos </option>
<option value="MQ"> Martinica </option>
<option value="MU"> Mauricio </option>
<option value="MR"> Mauritania </option>
<option value="MD"> Moldavia </option>
<option value="MN"> Mongolia </option>
<option value="MS"> Montserrat </option>
<option value="MZ"> Mozambique </option>
<option value="MM"> Myanmar </option>
<option value="MX"> México </option>
<option value="MC"> Mónaco </option>
<option value="NA"> Namibia </option>
<option value="NR"> Nauru </option>
<option value="NP"> Nepal </option>
<option value="NI"> Nicaragua </option>
<option value="NG"> Nigeria </option>
<option value="NU"> Niue </option>
<option value="NO"> Noruega </option>
<option value="NC"> Nueva Caledonia </option>
<option value="NZ"> Nueva Zelanda </option>
<option value="NE"> Níger </option>
<option value="OM"> Omán </option>
<option value="PK"> Pakistán </option>
<option value="PW"> Palau </option>
<option value="PA"> Panamá </option>
<option value="PG"> Papúa Nueva Guinea </option>
<option value="PY"> Paraguay </option>
<option value="NL"> Países Bajos </option>
<option value="PE"> Perú </option>
<option value="PF"> Polinesia Francesa </option>
<option value="PL"> Polonia </option>
<option value="PT"> Portugal </option>
<option value="PR"> Puerto Rico </option>
<option value="GB"> Reino Unido </option>
<option value="GN"> Rep Guinea </option>
<option value="CF"> República Centroafricana </option>
<option value="CZ"> República Checa </option>
<option value="DO"> República Dominicana </option>
<option value="RW"> Ruanda </option>
<option value="RO"> Rumania </option>
<option value="RU"> Rusia </option>
<option value="SW"> Samoa </option>
<option value="AS"> Samoa Americana </option>
<option value="KN"> San Cristóbal y Nieves </option>
<option value="SM"> San Marino </option>
<option value="MF"> San Martín </option>
<option value="PM"> San Pedro y Miquelón </option>
<option value="VC"> San Vicente y las Granadinas </option>
<option value="ST"> Santo Tomé y Príncipe </option>
<option value="SN"> Senegal </option>
<option value="RS"> Serbia </option>
<option value="SC"> Seychelles </option>
<option value="SL"> Sierra Leona </option>
<option value="SG"> Singapur </option>
<option value="VN"> Soc Republic of Vietnam </option>
<option value="SO"> Somalia </option>
<option value="LK"> Sri Lanka </option>
<option value="SH"> St Helena </option>
<option value="LC"> St Lucia </option>
<option value="ZA"> Sudáfrica </option>
<option value="SE"> Suecia </option>
<option value="CH"> Suiza </option>
<option value="SR"> Surinam </option>
<option value="SZ"> Swazilandia </option>
<option value="TH"> Tailandia </option>
<option value="TW"> Taiwán </option>
<option value="TZ"> Tanzania </option>
<option value="TJ"> Tayikistán </option>
<option value="TG"> Togo </option>
<option value="TK"> Tokelau </option>
<option value="TO"> Tonga </option>
<option value="TT"> Trinidad y Tobago </option>
<option value="TM"> Turkmenistán </option>
<option value="TR"> Turquía </option>
<option value="TV"> Tuvalu </option>
<option value="TN"> Túnez </option>
<option value="UA"> Ucrania </option>
<option value="UG"> Uganda </option>
<option value="UY"> Uruguay </option>
<option value="UZ"> Uzbekistán </option>
<option value="VU"> Vanuatu </option>
<option value="VA"> Vaticano </option>
<option value="VE"> Venezuela </option>
<option value="YE"> Yemen </option>
<option value="YU"> Yugoslavia </option>
<option value="ZR"> Zaire </option>
<option value="ZM"> Zambia </option>
<option value="ZW"> Zimbabwe </option>`;

export const es_destinationCountryOptions = () =>
  removeCountryFromList(
    es_countries,
    DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE
  );
