/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import * as constants from './consts';

let is_accidental_death = false;

export const corporateLiabilitySchema = intl => {
  let object = {};
  object.affected_person = affectedPersonSchema(intl);
  object.contacts = contactsSchema(intl);

  object.financial_information = financial_informationSchema(intl);
  object.incident = incidentSchema(intl);
  object.collection_agencies = collection_agenciesSchema(intl);

  object.certification = certificationSchema(intl);

  return Yup.object().shape(object);
};

const affectedPersonSchema = intl => {
  let affectedPerson = {};

  affectedPerson.relationship = Yup.string().required(intl.TEXT_FIELD_REQUIRED);

  affectedPerson.comment = Yup.string().when(['relationship'], {
    is: relationship => {
      return relationship === constants.OTHER_VALUE;
    },
    then: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
  });
  affectedPerson.person = Yup.object().shape({
    first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });
  affectedPerson.address = Yup.object().shape({
    invalidCep: Yup.boolean().test(
      'invalid ajax cep',
      'invalid ajax cep',
      function (value) {
        if (this.parent.country !== 'BR') return true;
        if (value === true) return false;
        return true;
      }
    ),
    street_address: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    postal_code: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
        const cep = value.replace('-', '');
        if (this.parent.country !== 'BR') {
          return cep.length > 0 && cep.length <= 8;
        }
        return cep.length === 8;
      })
      .typeError(intl.TEXT_FIELD_REQUIRED),
    locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    subdivision: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return Yup.object().shape({
    ...affectedPerson,
  });
};

const contactsSchema = intl => {
  let contacts = {};

  contacts.person = Yup.object().shape({
    first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  contacts.phones = Yup.object().shape({
    phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return Yup.object().shape({
    ...contacts,
  });
};

const financial_informationSchema = intl => {
  let financial_information = {};

  financial_information.valid_account_number = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );
  financial_information.credit_limit = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );
  financial_information.currency = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );
  financial_information.charge_submitted_by_company = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );
  financial_information.recovered_to_date = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );
  financial_information.is_payment_made_within_last_12_months =
    Yup.string().required(intl.TEXT_FIELD_REQUIRED);

  return Yup.object().shape({
    ...financial_information,
  });
};

const incidentSchema = intl => {
  return Yup.lazy(value => {
    let incident = {};

    incident.question_1_view_cardIs_lost = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    incident.question_2_view_is_dead = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    if (value.question_1_view_cardIs_lost === 'true') {
      incident.date_of_loss_or_stealth = Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED);
    }
    if (value.question_2_view_is_dead === 'true') {
      incident.bankruptcy_declaration_date = Yup.date()
        .required(intl.TEXT_FIELD_REQUIRED)
        .typeError(intl.TEXT_FIELD_REQUIRED);
    }
    incident.is_the_company_responsible = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    incident.is_the_cardholder_responsible = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    return Yup.object().shape({
      ...incident,
    });
  });
};

const collection_agenciesSchema = intl => {
  let collection_agencies = {};

  collection_agencies.address = Yup.object().shape({
    invalidCep: Yup.boolean().test(
      'invalid ajax cep',
      'invalid ajax cep',
      function (value) {
        if (this.parent.country !== 'BR') return true;
        if (value === true) return false;
        return true;
      }
    ),
    country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    state: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    street_address: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255),
    subdivision: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    postal_code: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .test('test-cep-br-valid', intl.VALIDATE_CEP, function (value) {
        const cep = value.replace('-', '');
        if (this.parent.country !== 'BR') {
          return cep.length > 0 && cep.length <= 8;
        }
        return cep.length === 8;
      })
      .typeError(intl.TEXT_FIELD_REQUIRED),
  });
  collection_agencies.contacts = Yup.object().shape({
    person: Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
    phones: Yup.object().shape({
      phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
  });
  collection_agencies.assignment_date = Yup.date()
    .required(intl.TEXT_FIELD_REQUIRED)
    .typeError(intl.TEXT_FIELD_REQUIRED);
  collection_agencies.received_affidavit_date = Yup.date()
    .required(intl.TEXT_FIELD_REQUIRED)
    .typeError(intl.TEXT_FIELD_REQUIRED);
  collection_agencies.account_cancellation_date = Yup.date()
    .required(intl.TEXT_FIELD_REQUIRED)
    .typeError(intl.TEXT_FIELD_REQUIRED);
  collection_agencies.received_request_date = Yup.date()
    .required(intl.TEXT_FIELD_REQUIRED)
    .typeError(intl.TEXT_FIELD_REQUIRED);

  return Yup.object().shape({
    ...collection_agencies,
  });
};

const certificationSchema = intl => {
  let certification = {};

  certification.is_amount_due_certified = Yup.bool().oneOf(
    [true],
    intl.TEXT_FIELD_REQUIRED
  );

  certification.is_information_certified = Yup.bool().oneOf(
    [true],
    intl.TEXT_FIELD_REQUIRED
  );

  certification.financial_institution = Yup.string().required(
    intl.TEXT_FIELD_REQUIRED
  );

  certification.recovery_date = Yup.date()
    .required(intl.TEXT_FIELD_REQUIRED)
    .typeError(intl.TEXT_FIELD_REQUIRED);

  return Yup.object().shape({
    ...certification,
  });
};
