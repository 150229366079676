import React, { useEffect, useState, useRef, useContext } from 'react';
import OT from '@opentok/client';
import styles from './VideoChat.module.scss';
import { useHistory } from 'react-router-dom';
import Loading from '../../../../../components/Loading/Loading';
import MicrophoneEnableIcon from '../../../../../assets/icons/MicrophoneEnableIcon';
import MicrophoneDisabledIcon from '../../../../../assets/icons/MicrophoneDisabledIcon';
import CameraDisabledIcon from '../../../../../assets/icons/CameraDisabledIcon';
import CameraEnableIcon from '../../../../../assets/icons/CameraEnableIcon';
import CloseIcon from '../../../../../assets/icons/CloseIcon';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';

const VideoChat = ({ credentials, endVideocall }) => {
  const [publisher, setPublisher] = useState(null);
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const history = useHistory();
  const [publisherVideoActivate, setPublisherVideoActivate] = useState(false);
  const [publisherAudioActivate, setPublisherAudioActivate] = useState(false);
  const [subscriber, setSubscriber] = useState(null);
  const [loading, setLoading] = useState(false);
  const publisherRef = useRef(null);
  const subscriberRef = useRef(null);

  const handleDisableVideo = () => {
    publisher.publishVideo(false);
    setPublisherVideoActivate(false);
  };
  const handleEnableVideo = () => {
    publisher.publishVideo(true);
    setPublisherVideoActivate(true);
  };

  const handleDisableMicrophone = () => {
    publisher.publishAudio(false);
    setPublisherAudioActivate(false);
  };
  const handleEnableMicrophone = () => {
    publisher.publishAudio(true);
    setPublisherAudioActivate(true);
  };

  const handleVideoToggle = () => {
    publisherVideoActivate ? handleDisableVideo() : handleEnableVideo();
  };
  const handleAudioToggle = () => {
    publisherAudioActivate
      ? handleDisableMicrophone()
      : handleEnableMicrophone();
  };

  const handleError = actions => {
    actions.modal.showModal(
      false,
      true,
      <ModalInformation type="error" message={intl.TITLE_CONNECTION_ERROR} />,
      true
    );
    history.goBack();
  };

  useEffect(() => {
    if (credentials !== null) {
      setLoading(true);
      const session = OT.initSession(
        credentials.api_key,
        credentials.session_id
      );

      session.on('streamCreated', event => {
        if (!subscriber && subscriberRef.current) {
          const sessionSubscriber = session.subscribe(
            event.stream,
            subscriberRef.current,
            {
              width: '100%',
              height: '100%',
              style: {
                audioLevelDisplayMode: 'off',
                buttonDisplayMode: 'off',
              },
              publishAudio: true,
              publishVideo: true,
            },
            error => {
              if (error) {
                handleError(actions);
              } else {
                setSubscriber(sessionSubscriber);
              }
            }
          );
        }
      });
      session.connect(credentials.token, error => {
        if (error) {
          handleError(actions);
        } else {
          const initedPublisher = OT.initPublisher(publisherRef.current, {
            width: '100%',
            height: '100%',
            style: {
              audioLevelDisplayMode: 'off',
              buttonDisplayMode: 'off',
            },
            publishAudio: false,
            publishVideo: false,
          });

          session.publish(initedPublisher, error => {
            if (error) {
              handleError(actions);
            }
          });
          setPublisher(initedPublisher);
        }
      });

      session.on('streamDestroyed', event => {
        event.preventDefault();
        setSubscriber(null);
      });

      setLoading(false);

      return () => {
        if (session) {
          session.disconnect();
          publisher && publisher.destroy();
        }
      };
    }
  }, [credentials]);

  if (loading)
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  return (
    <div className={styles.container}>
      {(!credentials || !subscriber) && (
        <div className={styles.noDoctorNotice}>
          <Loading />
          <span>{intl.TITLE_WAIT_FOR_DOCTOR}</span>
        </div>
      )}
      <div id="mainContent">
        <div ref={subscriberRef} className={styles.videoCall}></div>
      </div>

      <div
        className={`${styles.boxButtons} ${!subscriber ? styles.notShow : ''}`}
      >
        {publisherVideoActivate ? (
          <CameraEnableIcon
            className={styles.button}
            onClick={handleVideoToggle}
          />
        ) : (
          <CameraDisabledIcon
            className={styles.button}
            onClick={handleVideoToggle}
          />
        )}

        {publisherAudioActivate ? (
          <MicrophoneEnableIcon
            className={styles.button}
            onClick={handleAudioToggle}
          />
        ) : (
          <MicrophoneDisabledIcon
            className={styles.button}
            onClick={handleAudioToggle}
          />
        )}
        <CloseIcon className={styles.button} onClick={endVideocall} />
      </div>

      <div
        className={`${styles.patientCameraContainer} ${
          !subscriber ? styles.notShow : ''
        }`}
      >
        <div ref={publisherRef} className={styles.videoCallPatient}></div>
      </div>
    </div>
  );
};

export default VideoChat;
