import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const WhatsappIcon = props => {
  const { color = null, width, height } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={width || 30}
      height={height || 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.5 29.0742L2.58808 21.2942C1.41155 19.1637 0.786184 16.7789 0.786184 14.3622C0.786184 6.44445 7.22003 0 15.1378 0C23.0556 0 29.5 6.44445 29.5 14.3622C29.5 22.28 23.0556 28.7244 15.1378 28.7244C12.7635 28.7244 10.4105 28.1202 8.31177 26.9861L0.5 29.0848L0.5 29.0742ZM8.70395 24.0713L9.19152 24.3681C10.9934 25.4492 13.0497 26.011 15.1378 26.011C21.561 26.011 26.7865 20.7855 26.7865 14.3622C26.7865 7.93896 21.561 2.71345 15.1378 2.71345C8.71455 2.71345 3.49963 7.93896 3.49963 14.3622C3.49963 16.4821 4.0932 18.5702 5.20614 20.4039L5.50292 20.902L4.33699 25.2478L8.70395 24.0819V24.0713Z"
        fill={color || config_icon.white}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.48 16.3549C19.8864 16.0051 19.1233 15.6023 18.4237 15.8885C17.8937 16.1111 17.544 16.9484 17.2048 17.3724C17.0246 17.595 16.8126 17.6268 16.5476 17.5208C14.5443 16.7259 13.0074 15.3797 11.9051 13.5354C11.7143 13.2492 11.7461 13.0267 11.9793 12.7617C12.3184 12.3695 12.7318 11.9243 12.8272 11.3944C12.9226 10.8644 12.6682 10.239 12.4456 9.77264C12.1701 9.16848 11.8521 8.30992 11.2479 7.97074C10.6967 7.65276 9.96538 7.83295 9.4672 8.23573C8.60865 8.93529 8.19527 10.0164 8.21647 11.0976C8.21647 11.405 8.25887 11.7123 8.33307 12.0091C8.50266 12.7193 8.83124 13.387 9.20222 14.023C9.4778 14.5 9.78519 14.9664 10.1138 15.4115C11.1843 16.8636 12.5198 18.1356 14.0674 19.0789C14.8411 19.5559 15.6679 19.9693 16.5264 20.2555C17.491 20.5734 18.3495 20.902 19.3989 20.7112C20.4906 20.4993 21.5717 19.8315 21.9957 18.7715C22.1229 18.4642 22.1865 18.1144 22.1123 17.7858C21.9639 17.1074 21.0418 16.7047 20.4906 16.3761L20.48 16.3549Z"
        fill={color || config_icon.white}
      />
    </svg>
  );
};

export default WhatsappIcon;
