/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { HTMLTag, en_US } from './idioms';
import * as commom from './commom';
import { listCountries } from './countries';
import countryVsIdiom from './countryVsIdiom';
import { getCookie, setCookie } from '../utils/cookies';
import { countryByBrowser } from './countryByBrowser';
import { loadGeoIp } from './loadGeoIp';

import { getQueryVariable } from '../utils/uri';
import { toast } from 'react-toastify';
import { Translation, Translations } from '../@types/Translations';

export const countryNameToInitials = country => {
  const row = countryVsIdiom.find(row => row.country === country);
  return row.initials || 'BR';
};

export const IntlContext = React.createContext({
  translate: translations => {
    return translations;
  },
  setIdiom: idiom => {
    return idiom;
  },
  setCountry: initials => {
    return initials;
  },
  idiomForApi: () => {
    return '' as string;
  },
  twoDigitsIdiom: () => {
    return '' as string;
  },
  setCountryByAuth0: initials => {
    return initials;
  },
  setCountryInitialsByFullName: (country, idiom) => {},
  idiom: '',
  country: '',
  definitiveIdiom: '',
  countryFullName: '',
});

export const IntlParent = props => {
  const [idiom, _setIdiom] = useState(props.initialState?.idiom || en_US);
  const [country, _setCountry] = useState('BS');
  const [definitiveIdiom, setDefinitiveIdiom] = useState('');
  const [countryFromGeoIp, setCountryFromGeoIp] = useState();
  const [countryFullName, setCountryFullName] = useState('');
  const [countryByNavigator, setCountryByNavigator] = useState(null);
  const setIdiom = idiom => {
    _setIdiom(idiom);
  };

  const setIdiomAndCountry = (country, initials, idiom) => {
    toast.dismiss();
    setCookie('homeCountry', initials);
    setIdiom(idiom);
    _setCountry(initials);
    setCountryFullName(country[idiom]);
    document.documentElement.setAttribute('lang', HTMLTag[idiom]);
  };

  const setCountry = initials => {
    const country = listCountries.find(
      country => country.initials === initials
    );
    if (country) {
      const { idiom } = country;
      setIdiomAndCountry(country, initials, idiom);
      setDefinitiveIdiom(idiom);
    }
  };
  const setCountryByAuth0 = initials => {
    const country = listCountries.find(
      country => country.initials === initials
    );
    if (country) {
      const { idiom } = country;
      setIdiomAndCountry(country, initials, idiom);
    }
  };

  const setCountryInitialsByFullName = (country, idiom = null) => {
    const row = countryVsIdiom.find(row => row.country === country);
    if (!row) return;
    const countries = listCountries.find(
      country => country.initials === row.initials
    );
    const { initials } = row || {};
    if (initials) {
      setCookie('homeCountry', initials);
      _setCountry(initials);
      if (idiom) setCountryFullName(countries[idiom]);
    }
  };

  const idiomForApi = () => {
    return idiom ? idiom.replace('_', '-') : idiom;
  };
  const twoDigitsIdiom = () => {
    return idiom.substring(0, 2);
  };
  const translate = translations => {
    const translated = {};
    const commomKeys = Object.keys(commom);
    commomKeys.forEach(key => {
      translated[key] = commom[key][idiom];
    });
    const keys = Object.keys(translations);
    keys.forEach(key => {
      translated[key] = translations[key][idiom];
    });
    return translated;
  };

  useEffect(() => {
    if (!props.initialState) {
      const contryFrommCookie = getCookie('homeCountry');
      const isMobileApp = getQueryVariable('mobileApp');
      !(contryFrommCookie || countryFromGeoIp) &&
        loadGeoIp(setCountryFromGeoIp);

      const countryByNavigatorBrowser = countryByBrowser();
      setCountryByNavigator(countryByNavigatorBrowser);

      const country =
        isMobileApp && isMobileApp === 'true'
          ? countryByNavigatorBrowser
          : countryFromGeoIp || contryFrommCookie || countryByNavigatorBrowser;

      setCountry(country);
    }
  }, [countryFromGeoIp, countryByNavigator]); //eslint-disable-line

  useEffect(() => {
    const idiom = props.initialState?.idiom;
    if (idiom) _setIdiom(idiom);
  }, [props.initialState]);

  return (
    <IntlContext.Provider
      value={{
        translate,
        setIdiom,
        idiom,
        idiomForApi,
        country,
        setCountry,
        twoDigitsIdiom,
        setCountryInitialsByFullName,
        definitiveIdiom,
        countryFullName,
        setCountryByAuth0,
      }}
    >
      {props.children}
    </IntlContext.Provider>
  );
};

export const useIntl = () => useContext(IntlContext);

export const useMemoTranslations = (
  translations: Translations<Translation>
): Translations => {
  const { idiom, translate } = useIntl();

  return useMemo(() => translate(translations), [translations, idiom]);
};
