import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import * as translations from './intl';
import { IntlContext } from '../../../../../intl';
import styles from './CertificatesArea.module.scss';
import { loadData } from './loadData';
import { loadTeleconsultationData } from './loadTeleconsultationData';
import ListCertificates from '../../../../../components/ListCertificates/ListCertificate';
import Loading from '../../../../../components/Loading/Loading';
import LoadingError from '../../../../../components/LoadingError/LoadingError';
import ViewAllBenefits from '../../../../../components/ViewAllBenefits/ViewAllBenefits';

const ERROR = 'ERROR';

const CertificatesArea = ({
  claimType,
  acceptChecked,
  setCertificate,
  isTeleconsultation,
  incidentDate,
  history,
}) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const [certificates, setCertificates] = useState('LOADING');
  const [certificateType, setCertificateType] = useState('');

  const { utils } = useContext(StateContext);
  const cn = utils.getCn();
  const bin = utils.getBin();
  const binOrPan = utils.getBin(true);

  useEffect(() => {
    setCertificates('LOADING');
    if (isTeleconsultation)
      loadTeleconsultationData(
        cn,
        bin,
        setCertificates,
        idiom,
        setCertificateType,
        incidentDate
      );
    else
      loadData(
        cn,
        bin,
        binOrPan,
        claimType,
        setCertificates,
        idiom,
        setCertificateType,
        incidentDate
      );
    // eslint-disable-next-line
  }, [cn, incidentDate]);

  const isLoading = certificates === 'LOADING';
  const isError = certificates === ERROR;
  const successLoad = !isLoading && !isError;

  const noCertificates = () => {
    return (
      <>
        <div className={styles.noClaimMsg}>
          <ViewAllBenefits
            text={intl.TEXT_VIEW_ALL}
            link="/profile/certificates#active"
            isNatural
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.certificatesArea}>
      {!isLoading && (
        <h4 className={styles.certificateText}>
          {certificates.length && certificates.length > 0
            ? intl.TEXT_SELECT_CERTIFICATE
            : intl.TEXT_NO_CERTIFICATE_FOUND}
        </h4>
      )}
      {isLoading && <Loading dataTestId="claimTermsAndConditionsLoading" />}
      {isError && (
        <LoadingError dataTestId="claimTermsAndConditionsError" blue />
      )}
      {successLoad && certificates.length > 0 ? (
        <div className={styles.certificateAreaItens}>
          <ListCertificates
            certificates={certificates}
            numberToFilter={1000}
            isToSelectCertificate={true}
            setCertificate={setCertificate}
            disableButtonSelect={acceptChecked}
            isGenerateClaim
          />
        </div>
      ) : (
        successLoad && noCertificates()
      )}
    </div>
  );
};

export default withRouter(CertificatesArea);
