import { AxiosInstance } from 'axios';
import { OCTPallaAccountData } from '../pages/Claims/Generate/ClaimFillForm/Form/PaymentInformation/OCTFlow/OCTForm/OCTFunctions/OCTFunctions';
import { AccountDetail } from '../@types/APIs/Palla/Account';

export default (_, axios: AxiosInstance, errorHandler) => {
  return {
    createOctFlow: async (OCTPallaAccountData: OCTPallaAccountData) => {
      const url = `/oct/create`;

      return errorHandler(
        axios.post(url, OCTPallaAccountData),
        'createOctFlow',
        {
          url,
          OCTPallaAccountData,
        }
      );
    },
    deleteOctFlow: async (paymentMethodId: string) => {
      const url = `/oct/delete`;
      const paymentIdMethod = {
        paymentMethodId: paymentMethodId,
      };

      return errorHandler(
        axios.delete(url, { data: paymentIdMethod }),
        'deleteOctFlow',
        {
          url,
          paymentMethodId,
        }
      );
    },
    getAccountDetailPalla: async () => {
      const url = `/oct/account-detail`;

      return errorHandler(axios.get<AccountDetail>(url), 'getListPaymentId', {
        url,
      });
    },
    editAccountOCT: async (OCTPallaAccountData: OCTPallaAccountData) => {
      const url = `/oct/edit-account-detail`;

      return errorHandler(
        axios.post(url, OCTPallaAccountData),
        'editAccountOCT',
        {
          url,
          OCTPallaAccountData,
        }
      );
    },
    setMainCard: async (paymentID: string) => {
      const url = `/oct/main-card`;
      const paymentIdMethod = {
        paymentID: paymentID,
      };
      return errorHandler(axios.put(url, paymentIdMethod), 'setMainAccount', {
        url,
        paymentID,
      });
    },
  };
};
