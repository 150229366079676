import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';

const HeadPhoneIcon = props => {
  const { enabled = true, bottombar } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = CONFIG_ICON[theme];
  const primaryColor = enabled ? config_icon.primary : config_icon.inactive;
  const secondaryColor = enabled ? config_icon.secondary : config_icon.inactive;
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: 'none',
          stroke: bottombar ? secondaryColor : primaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M1 16v-5C1 5.5 5.5 1 11 1h2c5.5 0 10 4.5 10 10v5M1.5 9H3c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2H1.5"
      />
      <path
        style={{
          fill: 'none',
          stroke: bottombar ? secondaryColor : primaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M22.5 9H21c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h1.5"
      />
      <path
        d="M2 18v1c0 1.1.9 2 2 2h4m1-2h7v4H9v-4z"
        style={{
          fill: 'none',
          stroke: secondaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default HeadPhoneIcon;
