export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  pt_BR: 'Período da viagem',
  es_ES: 'Período de viaje',
};

export const TEXT_DATE_ARRIVAL_DESTINATION = {
  en_US: 'Date of arrival at destination',
  es_ES: 'Fecha de llegada',
  pt_BR: 'Data de chegada',
};

export const TEXT_HOUR_ARRIVAL_DESTINATION = {
  en_US: 'Hour of arrival at destination',
  es_ES: 'Hora de llegada',
  pt_BR: 'Hora de chegada',
};

export const TEXT_DATE_RECEIPT_BAGGAGE = {
  en_US: 'Date of receipt of delayed luggage',
  es_ES: 'Fecha de recepción de la demora de equipaje',
  pt_BR: 'Data da recepção da bagagem atrasada',
};

export const TEXT_HOUR_RECEIPT_BAGGAGE = {
  en_US: 'Hour of receipt of delayed luggage',
  es_ES: 'Hora de recepción de la demora de equipaje',
  pt_BR: 'Hora da recepção da bagagem atrasada',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal.',
};
