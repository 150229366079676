import moment from 'moment';
import { toFixedOrDefault } from '../../../../../../../utils/numbers';
import { safeGet } from '../../../../../../../utils/object';
import { DATE_FORMAT } from './consts';
import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';

/* eslint-disable no-unused-vars */
const getPersonName = (cardholder, affectedPerson) => {
  const person =
    affectedPerson && affectedPerson.person
      ? affectedPerson.person
      : cardholder;
  return {
    first_name: person && person.first_name ? person.first_name : '',
    last_name: person && person.last_name ? person.last_name : '',
  };
};

const getAddress = address => ({
  street_address: (address && address.street_address) || '',
  postal_code: (address && address.postal_code) || '',
  locality: (address && address.locality) || '',
  country: (address && address.country) || '',
  subdivision: (address && address.subdivision) || '',
  state: (address && address.state) || '',
});

const getAffectedPerson = (cardholder, affectedPerson) => ({
  relationship: (affectedPerson && affectedPerson.relationship) || 'Cardholder',
  comment: (affectedPerson && affectedPerson.comment) || '',
  person: getPersonName(cardholder, affectedPerson),
  address: getAddress((affectedPerson && affectedPerson.address) || {}),
});

export const getPhone = phone => ({
  phone_type: (phone && phone.phone_type) || PHONE_TYPE_MOBILE,
  international_prefix: (phone && phone.international_prefix) || '',
  number: (phone && phone.number) || '',
});

const getContact = contact => ({
  person: getPersonName(contact && contact.person),
  phones: getPhone(contact && contact.phones && contact.phones[0]),
});

const getFinancialInfo = info => ({
  valid_account_number: (info && info.valid_account_number) || '',
  credit_limit:
    info && info.credit_limit ? toFixedOrDefault(info.credit_limit, 2, '') : '',
  currency: (info && info.currency) || '',
  charge_submitted_by_company:
    info && info.charge_submitted_by_company
      ? toFixedOrDefault(info.charge_submitted_by_company, 0, '')
      : '',
  recovered_to_date:
    info && info.recovered_to_date
      ? toFixedOrDefault(info && info.recovered_to_date, 0, '')
      : '',
  is_payment_made_within_last_12_months:
    info && info.is_payment_made_within_last_12_months !== undefined
      ? String(info.is_payment_made_within_last_12_months)
      : '',
});

const getIncident = incident => {
  const dateOfLoss =
    incident && incident.date_of_loss_or_stealth
      ? new Date(moment(incident.date_of_loss_or_stealth, DATE_FORMAT))
      : '';
  const isCardLost = incident ? String(Boolean(dateOfLoss)) : '';

  const dateOfbankruptcy =
    incident && incident.bankruptcy_declaration_date
      ? new Date(moment(incident.bankruptcy_declaration_date, DATE_FORMAT))
      : '';
  const isBankrupt = incident ? String(Boolean(dateOfbankruptcy)) : '';

  return {
    //view
    question_1_view_cardIs_lost: isCardLost,
    question_2_view_is_dead: isBankrupt,
    //object
    date_of_loss_or_stealth: dateOfLoss,
    bankruptcy_declaration_date: dateOfbankruptcy,
    is_the_company_responsible:
      incident && incident.is_the_company_responsible !== undefined
        ? String(incident.is_the_company_responsible)
        : '',
    is_the_cardholder_responsible:
      incident && incident.is_the_cardholder_responsible !== undefined
        ? String(incident.is_the_cardholder_responsible)
        : '',
  };
};

const getCollectionAgency = agency => {
  const contact = safeGet(agency, 'contacts[0]');

  return {
    name: (agency && agency.name) || '',
    address: getAddress(agency && agency.address),
    contacts: getContact(contact),
    assignment_date:
      agency && agency.assignment_date
        ? new Date(moment(agency.assignment_date, DATE_FORMAT))
        : '',
    received_affidavit_date:
      agency && agency.received_affidavit_date
        ? new Date(moment(agency.received_affidavit_date, DATE_FORMAT))
        : '',
    account_cancellation_date:
      agency && agency.account_cancellation_date
        ? new Date(moment(agency.account_cancellation_date, DATE_FORMAT))
        : '',
    received_request_date:
      agency && agency.received_request_date
        ? new Date(moment(agency.received_request_date, DATE_FORMAT))
        : '',
  };
};

const getCertification = certification => ({
  is_amount_due_certified:
    certification && certification.is_amount_due_certified !== undefined
      ? certification.is_amount_due_certified
      : false,
  financial_institution:
    (certification && certification.financial_institution) || '',
  is_information_certified:
    certification && certification.is_information_certified !== undefined
      ? certification.is_information_certified
      : false,
  recovery_date:
    certification && certification.recovery_date
      ? new Date(moment(certification.recovery_date, DATE_FORMAT))
      : '',
});

export const corporateFormInitialValues = (data, idiom, loadedData) => {
  let person = data && data.person ? data.person : '';
  const affectedPerson = safeGet(loadedData, 'affected_person');
  const contact = safeGet(loadedData, 'contacts[0]');
  const financialInfo = safeGet(loadedData, 'financial_information');
  const incident = safeGet(loadedData, 'incident');
  const collectionAgency = safeGet(loadedData, 'collection_agencies[0]');
  const certification = safeGet(loadedData, 'certification');

  return {
    affected_person: getAffectedPerson(person, affectedPerson),
    contacts: getContact(contact),
    financial_information: getFinancialInfo(financialInfo),
    incident: getIncident(incident),
    collection_agencies: getCollectionAgency(collectionAgency),
    certification: getCertification(certification),
  };
};
