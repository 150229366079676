import { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../components/Input/Input';
import PhoneForm from '../../../../../../components/PhoneForm';
import { phonePrefixToInitials } from '../../../../../../intl/utils';
import { pt_BR } from '../../../../../../intl/idioms';
import { mask4FirstDigits } from '../../../../../../utils/stringUtils';

const ContactArea = props => {
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    prefixOptions,
    setFieldValue,
    saveInSession,
    initialValues,
    setFieldTouched,
  } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const checkSameRegistrationValue = () => {
    if (
      values.MPH.person_registrations.registration_type ===
        initialValues.MPH.person_registrations.registration_type &&
      initialValues.MPH.person_registrations.value !== ''
    ) {
      return (
        values.MPH.person_registrations.value ===
        initialValues.MPH.person_registrations.value
      );
    } else {
      return false;
    }
  };

  const getInitialRegistrationValue = () => {
    if (
      values.MPH.person_registrations.registration_type ===
      initialValues.MPH.person_registrations.registration_type
    ) {
      return initialValues.MPH.person_registrations.value;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (
      prefixOptions &&
      values.MPH &&
      values.MPH.address &&
      values.MPH.address.country
    ) {
      let prefix = phonePrefixToInitials(values.MPH.address.country);
      prefix = prefix ? prefix : values.MPH.phone.international_prefix;
      prefixOptions.setPrefix(values.MPH.phone.international_prefix);
      setFieldValue('MPH.phone.international_prefix', `+${prefix}`);
    }
  }, [values.MPH.address.country]);

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
        <Input
          value={values.MPH.Email}
          label={intl.LABEL_EMAIL}
          name="MPH.Email"
          type="text"
          onChange={handleChange}
          touched={touched.MPH && touched.MPH.Email}
          onBlur={() => {
            handleBlur();
            saveInSession(values);
          }}
          error={errors.MPH && errors.MPH.Email}
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
        <Input
          value={values.MPH.EmailConfirmation}
          label={intl.LABEL_EMAIL_CONFIRMATION}
          name="MPH.EmailConfirmation"
          type="text"
          onChange={handleChange}
          touched={touched.MPH && touched.MPH.EmailConfirmation}
          onBlur={() => {
            handleBlur();
            saveInSession(values);
          }}
          error={errors.MPH && errors.MPH.EmailConfirmation}
          axa
        />
      </div>
      <div className="col-12 col-sm-12 col-md-4 col-lg-4">
        <Input
          value={
            checkSameRegistrationValue()
              ? mask4FirstDigits(values.MPH.person_registrations.value)
              : values.MPH.person_registrations.value
          }
          label={intl.LABEL_REGISTRATIONS_TYPE}
          mask={
            idiom === pt_BR
              ? checkSameRegistrationValue()
                ? 'maskedCPFWithoutFirst4'
                : 'maskedCPF'
              : checkSameRegistrationValue()
              ? 'maskedPassaportWithoutFirst4'
              : 'maskedPassaport'
          }
          name="MPH.person_registrations.value"
          type="text"
          onChange={handleChange}
          tooltipText={intl.TEXT_ALERT_PASSPORT}
          touched={
            touched.MPH &&
            touched.MPH.person_registrations &&
            touched.MPH.person_registrations.value
          }
          onBlur={e => {
            handleBlur();
            saveInSession(values);
            if (e.target.value === '') {
              setFieldValue(
                'MPH.person_registrations.value',
                getInitialRegistrationValue()
              );
              setFieldTouched('MPH.person_registrations.value', true);
            }
          }}
          onFocus={e => {
            if (checkSameRegistrationValue()) {
              setFieldValue('MPH.person_registrations.value', '');
              setFieldTouched('MPH.person_registrations.value', true);
            }
          }}
          error={
            errors.MPH &&
            errors.MPH.person_registrations &&
            errors.MPH.person_registrations.value
          }
          axa
        />
      </div>
      <div className="col-12">
        <PhoneForm
          formikProps={props}
          axa
          inputNames={{
            phoneType: 'MPH.phone.phone_type',
            countryCode: 'MPH.phone.international_prefix',
            phoneNumber: 'MPH.phone.number',
          }}
          testIds={{
            phoneType: 'InputClaimCHPhoneType',
            countryCode: 'InputClaiCHPrefix',
            phoneNumber: 'InputClaiCHNumber',
          }}
          onFinishEditingField={() => {
            saveInSession(values);
          }}
        />
      </div>
    </div>
  );
};

export default ContactArea;
