export const TEXT_FINANCIAL_INFORMATION = {
  en_US: 'Financial information',
  pt_BR: 'Informação financeira',
  es_ES: 'Información financiera',
};

export const TEXT_VALID_ACCOUNTS = {
  en_US: 'Valid company accounts',
  pt_BR: 'Contas de empresas válidas',
  es_ES: 'Cuentas de empresa válidas',
};

export const CARDHOLDER_CREDIT_LIMIT = {
  en_US: "Cardholder's credit limit",
  pt_BR: 'Limite de crédito do titular do cartão',
  es_ES: 'Límite de crédito del titular de la tarjeta',
};

export const TEXT_CHANGES_SUBMITTED = {
  en_US: 'Charges submitted by the company',
  pt_BR: 'Encargos apresentados pela empresa',
  es_ES: 'Cargos presentados por la compañia',
};

export const AMOUNT_RECOVERED = {
  en_US: 'Amount recovered to date',
  pt_BR: 'Valor recuperado até a data',
  es_ES: 'Cantidad recuperada hasta la fecha',
};

export const QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS = {
  en_US:
    'Has the cardholder made any payment on this account within the last 12 months which was not collectible?',
  pt_BR:
    'O titular do cartão fez qualquer pagamento nessa conta nos últimos 12 meses, que não foi cobrado?',
  es_ES:
    '¿Ha hecho el Titular de la Tarjeta algún pago en esta cuenta dentro de los últimos 12 meses que no fue coleccionable?',
};

export const YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const QUESTION_LOST_OR_STOLEN_CARD = {
  en_US: 'Is this claim the result of a lost / stolen card?',
  pt_BR: 'Esta reivindicação é o resultado de um cartão perdido / roubado?',
  es_ES: '¿Es esta reclamación el resultado de una tarjeta perdida / robada?',
};

export const DATE_REPORTED = {
  en_US: 'Date reported',
  pt_BR: 'Data relatada',
  es_ES: 'Fecha de notificación',
};
