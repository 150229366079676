import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './TermsAndConditions.module.scss';
import {
  ThemeContext,
  useTheme,
} from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl/index';
import * as translations from './intl';
import ShadowBox from '../../../../components/ShadowBox/ShadowBox';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../componentsV2/Button/Button';
import { B2B2CContext } from '../../../../components/B2B2CStateParent/B2B2CStateParent';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import { dataLayerB2B2CTerms } from '../../../../utils/GTM_helper';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import Link from '../../../../componentsV2/Link/Link';

const verifyIfChecboxIsChecked = (state, isAnnualQuote, isMX) => {
  if (!state) return false;

  if (isMX) {
    return state.checkbox_1 && state.checkbox_2 && state.checkbox_3;
  }

  if (isAnnualQuote) {
    return (
      state.checkbox_1 &&
      state.checkbox_2 &&
      state.checkbox_3 &&
      state.checkbox_4
    );
  }
  return state.checkbox_1 && state.checkbox_2 && state.checkbox_3;
};

const TermsAndConditions = props => {
  const { nextStep, CONFIG_B2B2C, backStep, type, axa } = props;
  const isAnnualQuote = type === 'upsell' ? true : false;
  const { getGlobalTheme } = useTheme(true);
  const { utils, actions } = useContext(B2B2CContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const terms = utils.getTerms();
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const CONFIG_B2B2C_TEXT =
    CONFIG_B2B2C.purchase.text.terms_and_conditions(intl);
  const theme = getGlobalTheme();
  const selectedPlan = utils.getSelectedPlan();
  const document =
    selectedPlan && selectedPlan.Document && selectedPlan.Document[0];
  const isMX = country === 'MX';

  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      <ShadowBox>
        <div id="mainContent" className={styles.termsArea}>
          <h2 className={styles[theme]}>
            {intl.ACCEPTING_TERMS_AND_CONDITIONS}
          </h2>
          <div className={styles.pdf}>
            <PDFIcon
              aria-label="axa"
              axa
              width={42}
              height={54}
              classname={styles.pdfIcon}
            />

            <div className={styles.pdfInfo} onClick={() => {}}>
              <Link
                className={styles[theme]}
                href={document && document.url}
                label={intl.TERMS_AND_CONDITIONS}
                axa
              />

              <span>{CONFIG_B2B2C_TEXT.subtitle}</span>
            </div>
          </div>
          <div className={`${styles[theme]} ${styles.acceptArea}`}>
            <CheckBox
              name="accept_terms"
              value={(terms && terms.checkbox_1) || false}
              onClick={check =>
                actions.formDataCotizar.setTerms({
                  ...terms,
                  checkbox_1: check,
                })
              }
              axa
            >
              {CONFIG_B2B2C_TEXT.checkbox_1}
            </CheckBox>
            <CheckBox
              name="accept_terms"
              value={(terms && terms.checkbox_2) || false}
              onClick={check =>
                actions.formDataCotizar.setTerms({
                  ...terms,
                  checkbox_2: check,
                })
              }
              axa
            >
              {CONFIG_B2B2C_TEXT.checkbox_2}
            </CheckBox>

            <CheckBox
              name="accept_terms"
              value={(terms && terms.checkbox_3) || false}
              onClick={check =>
                actions.formDataCotizar.setTerms({
                  ...terms,
                  checkbox_3: check,
                })
              }
              axa
            >
              {CONFIG_B2B2C_TEXT.checkbox_3}
            </CheckBox>
            {isAnnualQuote && !isMX && (
              <CheckBox
                name="accept_terms"
                value={(terms && terms.checkbox_4) || false}
                onClick={check =>
                  actions.formDataCotizar.setTerms({
                    ...terms,
                    checkbox_4: check,
                  })
                }
                axa
              >
                {CONFIG_B2B2C_TEXT.checkbox_4}
              </CheckBox>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.buttonArea}>
              <Button
                type="Secondary"
                onClick={backStep}
                label={intl.BACK}
                axa
              />
            </div>
            <div className={styles.buttonArea}>
              <Button
                type="Primary"
                axa
                disabled={!verifyIfChecboxIsChecked(terms, isAnnualQuote, isMX)}
                onClick={() => {
                  trackEventUserAction(`#### (B2B2C) INDO PARA O STEP 4`, null);
                  dataLayerB2B2CTerms();
                  nextStep();
                }}
                label={intl.BTN_NEXT_STEP}
              />
            </div>
          </div>
        </div>
      </ShadowBox>
    </div>
  );
};

export default withRouter(TermsAndConditions);
