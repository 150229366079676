export const SearchItems = (intl, isLogged) => {
  return [
    {
      title: intl.TEXT_BENEFITS,
      key: 'benefits',
      link: isLogged ? '/your-benefits' : '/benefits',
    },
    {
      title: intl.TEXT_SPECIAL_BENEFITS,
      key: 'special_benefits',
      link: isLogged ? '/your-subscription-benefits' : '/subscription-benefits',
    },
    {
      title: intl.TEXT_HELP,
      key: 'help',
      link: '/help',
    },
    {
      title: intl.TEXT_CLAIMS,
      key: 'claims',
      link: '/claims',
    },
    {
      title: intl.TEXT_CERTIFICATES,
      key: 'certificates',
      link: '/certificates',
    },
  ];
};
