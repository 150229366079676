import React, { createRef, useContext, useState } from 'react';
import { withRouter } from 'react-router';
import { componentByCertificateType } from './componentByCertificateType';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { checkErrorsOnForm, checkErrorsOnSession } from './checkErrorsOnForm';
import { submitCertificate } from './submit/submit';
import styles from './CertificateFormFill.module.scss';
import { checkGeneralInfoChange } from './submit/checkGeneralInfoChange/checkGeneralInfoChange';
import {
  dataLayerCertificateEventTrack,
  CERTIFICATE_COMPLETED_STEP,
} from '../../../../utils/GTM_helper';
import { IntlContext } from '../../../../intl';

const CertificateFormFill = props => {
  const { certificateType } = props;
  const { idiomForApi } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const CertificateFormComponent = componentByCertificateType[certificateType];
  const formRef = createRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [prefix, setPrefix] = useState('');
  const [initialValuesCompare, setInitialValuesCompare] = useState({});

  let values = {};
  let schemas = {};

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const submit = async () => {
    setIsSubmitting(true);
    const areFormsOk = await checkErrorsOnForm(
      values,
      schemas,
      formRef.current,
      trackEventUserAction
    );
    trackEventUserAction(
      '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI VALIDAR O FORMULÁRIO',
      areFormsOk
    );
    if (areFormsOk) {
      const _props = {
        ...props,
        values: values,
        formRef: formRef.current,
        history: props.history,
        state: { isSubmitting, prefix, initialValuesCompare },
        initialValuesCompare: initialValuesCompare,
        setInitialValuesCompare: setInitialValuesCompare,
        trackEventUserAction,
        idiomForApi: idiomForApi,
      };
      try {
        trackEventUserAction(
          '#### (CERTIFICATE) CLICOU NO SUBMIT -- ENTROU NO SUBMIT APÓS VALIDAÇÃO',
          _props
        );
        await submitCertificate(_props);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
    setIsSubmitting(false);
  };

  const generalInformationCheck = () => {
    const _props = {
      ...props,
      values: values,
      formRef: formRef.current,
      history: props.history,
      state: { isSubmitting, prefix, initialValuesCompare },
      initialValuesCompare: initialValuesCompare,
      setInitialValuesCompare: setInitialValuesCompare,
      trackEventUserAction,
    };
    checkGeneralInfoChange(_props);
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true
  ) => {
    const checkErrorOnSession = await checkErrorsOnSession(
      currentAcordeon,
      targetAcordeon,
      schemas,
      values,
      validation,
      formRef.current
    );

    if (checkErrorOnSession) {
      dataLayerCertificateEventTrack(
        CERTIFICATE_COMPLETED_STEP,
        certificateType,
        getStepByTarget(currentAcordeon)
      );
    }

    return checkErrorOnSession;
  };

  const getStepByTarget = target => {
    let step = '';
    switch (target) {
      case 'dependents':
        step = 2;
        break;

      case 'tripInformation':
        step = 3;
        break;

      default:
        step = 1;
        break;
    }
    return step;
  };
  return (
    <div className={`${styles.certificateFormFill} `}>
      <CertificateFormComponent
        {...props}
        ref={formRef}
        updateForm={updateForm}
        prefixOptions={{ prefix, setPrefix }}
        submit={submit}
        openTargetAcordeon={openTargetAcordeon}
        generalInformationCheck={generalInformationCheck}
        setInitialValuesCompare={setInitialValuesCompare}
      />
    </div>
  );
};

export default withRouter(CertificateFormFill);
