import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let from_date_view;
let to_date_view;

export const hotelBurglarySchema = intl =>
  Yup.object().shape({
    affected_person: Yup.object().shape({
      relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      comment: Yup.string().when(['relationship'], {
        is: relationship => {
          return relationship === constants.OTHER_VALUE;
        },
        then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
    travel_information: Yup.lazy(value => {
      from_date_view = value.from_date;
      to_date_view = value.to_date;
      return Yup.object().shape({
        from_date: Yup.string()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isSameOrBefore(new Date(), 'day');
          }),
        to_date: Yup.mixed()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('match', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isSameOrAfter(this.parent.from_date);
          }),
      });
    }),
    incident: Yup.object().shape({
      description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      discovered_at: Yup.date()
        .test('match', intl.VALIDATE_DATE_LOSS, function (discovered_at) {
          return (
            moment(discovered_at).isSameOrAfter(from_date_view) &&
            moment(discovered_at).isSameOrBefore(to_date_view)
          );
        })
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID),

      hour_discovered_at: Yup.string()
        .required(intl.VALIDATE_HOUR_VALID)
        .typeError(intl.VALIDATE_HOUR_VALID)
        .length(5, intl.VALIDATE_HOUR_VALID)
        .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
          return moment(hour, 'hh:mm').isValid();
        }),
      location: Yup.object().shape({
        country: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        locality: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      concerned_items: Yup.array().of(
        Yup.object().shape({
          description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          purchase_date: Yup.mixed().test(
            'match',
            intl.VALIDATE_DATE_VALID,
            function (purchase_date) {
              return moment(purchase_date).isSameOrBefore(new Date());
            }
          ),
          price: Yup.object().shape({
            value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        })
      ),
    }),

    other_insurance_covering: Yup.object().shape({
      hotel_indemnification: Yup.object().shape({
        hotel_checked: Yup.boolean(),
        value: Yup.string().when(['hotel_checked'], {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      checked: Yup.boolean(),
      value: Yup.string().when(['checked'], {
        is: true,
        then: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      }),
    }),
  });
