export const VIEW_ALL = {
  en_US: `View all your notifications`,
  es_ES: `Ver todas tus notificaciones`,
  pt_BR: `Ver todas suas notificações`,
};

export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};

export const NOTIFICATIONS = {
  en_US: `Notifications`,
  es_ES: `Notificaciones`,
  pt_BR: `Notificações`,
};
