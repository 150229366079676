import React from 'react';
import ErrorPage from './ErrorPage';
import EmptyLayoutHalfBlue from '../../layouts/EmptyLayoutHalfBlue/EmptyLayoutHalfBlue';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';

class ErrorBoundary extends React.Component {
  static contextType = AppInsightTrackContext;
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Registra o erro em um serviço de relatórios de erro
    this.context.trackEventUserAction('#### ERROR BOUNDARY ####', {
      error,
      message: error.message,
      stack: JSON.stringify(error.stack),
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <EmptyLayoutHalfBlue>
          <ErrorPage />
        </EmptyLayoutHalfBlue>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
