import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const CircledUserIcon = props => {
  const gender = props.gender;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg width={67} height={67} viewBox="0 0 67 67" fill="none" {...props}>
      <circle cx={33.5} cy={33.5} r={30.5} fill="white" />
      {(gender === 'UNKNOWN' || (gender != 'ML' && gender != 'FM')) && (
        <path
          d="M50.5586 34.2119L48 29.7344V27.5C48 19.5049 41.4951 13 33.5 13C25.5049 13 19 19.5049 19 27.5C19 30.7446 20.2642 32.8589 21.3794 34.7246C22.249 36.1787 23 37.4346 23 39C23 40.1497 22.6587 41.3204 22.2708 42.2809L24.0639 43.1775C24.5523 42.0008 25 40.5142 25 39C25 36.8828 24.0322 35.2637 23.0962 33.6982C22.0654 31.9746 21 30.1924 21 27.5C21 20.6074 26.6074 15 33.5 15C40.3926 15 46 20.6074 46 27.5V30.2656L48.8223 35.2041C48.9785 35.4785 48.9971 35.8018 48.8731 36.0928C48.749 36.3838 48.5029 36.5938 48.1963 36.6709L46.1758 37.1748L44.4092 44.2422C44.2969 44.6885 43.8984 45 43.4385 45H39.6182C38.8545 45 38.1689 45.4238 37.8291 46.1055L36.2473 49.2692L38.0363 50.1637L39.6182 47H43.4385C44.8174 47 46.0146 46.0654 46.3486 44.7275L47.8242 38.8252L48.6807 38.6104C49.6006 38.3809 50.3408 37.75 50.7129 36.8779C51.085 36.0068 51.0283 35.0352 50.5586 34.2119Z"
          fill={config_icon.primary}
        />
      )}

      {gender === 'MALE' ||
        (gender === 'ML' && (
          <>
            <path
              d="M50.5586 34.2119L48 29.7344V27.5C48 25.1626 47.6445 23.2305 46.9131 21.5923L46.6484 21H34.7373C33.6289 21 32.6152 21.6069 32.0923 22.5845C31.5688 23.5615 31.626 24.7417 32.2407 25.6641L33.7134 27.8726L32.5859 29H30C27.7944 29 26 30.7944 26 33C26 35.206 27.7944 37 30 37V35C28.897 35 28 34.1025 28 33C28 31.897 28.897 31 30 31H33.4141L36.2871 28.1274L33.9048 24.5547C33.605 24.105 33.7705 23.687 33.8555 23.5283C33.9404 23.3691 34.1963 23 34.7373 23H45.3262C45.7842 24.2593 46 25.7085 46 27.5V30.2656L48.8223 35.2041C48.9785 35.4785 48.9971 35.8018 48.8731 36.0928C48.749 36.3838 48.5029 36.5938 48.1963 36.6709L46.1758 37.1748L44.4092 44.2422C44.2969 44.6885 43.8984 45 43.4385 45H39.6182C38.8545 45 38.1689 45.4238 37.8291 46.1055L36.2473 49.2692L38.0363 50.1637L39.6182 47H43.4385C44.8174 47 46.0146 46.0654 46.3486 44.7275L47.8242 38.8252L48.6807 38.6104C49.6006 38.3809 50.3408 37.75 50.7129 36.8779C51.085 36.0068 51.0283 35.0352 50.5586 34.2119Z"
              fill={config_icon.primary}
            />
            <path
              d="M25 39C25 36.8828 24.0322 35.2637 23.0962 33.6982C22.0654 31.9746 21 30.1924 21 27.5C21 20.6074 26.6074 15 33.5 15C38.1065 15 42.9521 16.3398 47.5146 18.874L48.4854 17.126C43.627 14.4268 38.4453 13 33.5 13C25.5049 13 19 19.5049 19 27.5C19 30.7446 20.2642 32.8589 21.3794 34.7246C22.249 36.1787 23 37.4346 23 39C23 40.1497 22.6587 41.3204 22.2708 42.2809L24.0639 43.1775C24.5523 42.0008 25 40.5142 25 39Z"
              fill={config_icon.primary}
            />
          </>
        ))}

      {gender === 'FEMALE' ||
        (gender === 'FM' && (
          <path
            d="M50.5586 34.2119L48 29.7344V27.5C48 19.5049 41.4951 13 33.5 13C25.6401 13 19 19.6401 19 27.5V33C19 38.7412 17.9258 39.7832 14.7373 42.876C14.2925 43.3076 13.8115 43.7744 13.293 44.293L14.707 45.707C15.2178 45.1963 15.6919 44.7363 16.1299 44.3115C19.4736 41.0684 21 39.5879 21 33V27.5C21 20.7241 26.7241 15 33.5 15C36.7839 15 39.767 16.2827 42 18.361V20C42 24.9624 37.9629 29 33 29H32V30C32 34.9629 27.9624 39 23 39V41C28.7427 41 33.4717 36.5771 33.9585 30.9585C39.3501 30.4913 43.6289 26.115 43.967 20.6881C45.2481 22.6498 46 24.9872 46 27.5V30.2656L48.8223 35.2041C48.9785 35.4785 48.9971 35.8018 48.8731 36.0928C48.749 36.3838 48.5029 36.5938 48.1963 36.6709L46.1758 37.1748L44.4092 44.2422C44.2969 44.6885 43.8984 45 43.4385 45H39.6182C38.8545 45 38.169 45.4238 37.8291 46.1055L36.2473 49.2692L38.0363 50.1637L39.6182 47H43.4385C44.8174 47 46.0146 46.0654 46.3486 44.7275L47.8242 38.8252L48.6807 38.6104C49.6006 38.3809 50.3408 37.75 50.7129 36.8779C51.085 36.0068 51.0283 35.0352 50.5586 34.2119Z"
            fill={config_icon.primary}
          />
        ))}
      <path
        d="M21.4473 44.1053L20.5527 45.8944L38.5528 54.8943L39.4473 53.1052L21.4473 44.1053Z"
        fill={config_icon.secondary}
      />
      <circle
        cx={33.5}
        cy={33.5}
        r={32.5}
        stroke={config_icon.secondary}
        strokeWidth={4}
      />
    </svg>
  );
};

export default CircledUserIcon;
