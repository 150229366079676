import React, { useContext } from 'react';
import styles from './ProductCard.module.scss';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const renderListBenefits = (benefits, theme) => {
  return benefits.slice(0, 4).map((list, index) => (
    <li key={`Benefits-${index}`} className={styles[theme]}>
      <div className={styles.boxTitleBenefit}>
        <div className={`${styles.planTitleBenefit} ${styles[theme]}`}>
          <h3>{list.Benefit.Description}</h3>
        </div>
        <div className={`${styles.planSubtitleBenefit} ${styles[theme]}`}>
          <h3>{list.Benefit.Detail}</h3>
        </div>
      </div>
    </li>
  ));
};

const ProductCard = props => {
  const { plan } = props;
  const { Name, BenefitVISA } = plan;

  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();

  return (
    <div className={styles.cardContainer}>
      <h3 className={styles.planName}>{Name}</h3>

      <ul className={styles.listBenefits}>
        {renderListBenefits(BenefitVISA, theme)}
      </ul>
    </div>
  );
};

export default ProductCard;
