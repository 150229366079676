import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_DEATH_DATE = {
  en_US: 'Death date *',
  pt_BR: 'Data da morte *',
  es_ES: 'Fecha de la muerte *',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descreva o incidente *',
  es_ES: 'Describa el incidente *',
};

export const INCIDENT_LOCATION = {
  en_US: 'Incident location',
  pt_BR: 'Endereço do incidente',
  es_ES: 'Dirección del incidente',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Purchase price (including tax)',
  pt_BR: 'Preço de compra (incluindo impostos)',
  es_ES: 'Precio de compra (incluyendo impuestos)',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TOTAL_NUMBER_VALID_ACCOUNTS = {
  en_US: 'Total number of valid company accounts *',
  pt_BR: 'Número total de contas de empresas válidas *',
  es_ES: 'Número total de cuentas de empresa válidas *',
};

export const CARDHOLDER_CREDIT_LIMIT = {
  en_US: "Cardholder's credit limit *",
  pt_BR: 'Limite de crédito do titular do cartão *',
  es_ES: 'Límite de crédito del titular de la tarjeta *',
};

export const AMOUNT_CHANGES_SUBMITTED = {
  en_US: 'Amount of charges submitted by the company *',
  pt_BR: 'Quantidade de encargos apresentados pela empresa *',
  es_ES: 'Importe de los cargos presentados por la compañia *',
};

export const AMOUNT_RECOVERED = {
  en_US: 'Amount recovered to date *',
  pt_BR: 'Valor recuperado até a data *',
  es_ES: 'Cantidad recuperada hasta la fecha *',
};

export const QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS = {
  en_US:
    'Has the cardholder made any payment on this account within the last 12 months which was not collectible? *',
  pt_BR:
    'O titular do cartão fez qualquer pagamento nessa conta nos últimos 12 meses, que não foi cobrado? *',
  es_ES:
    '¿Ha hecho el Titular de la Tarjeta algún pago en esta cuenta dentro de los últimos 12 meses que no fue coleccionable? *',
};

export const YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const QUESTION_LOST_OR_STOLEN_CARD = {
  en_US: 'Is this claim the result of a lost / stolen card? *',
  pt_BR: 'Esta reivindicação é o resultado de um cartão perdido / roubado? *',
  es_ES: '¿Es esta reclamación el resultado de una tarjeta perdida / robada? *',
};

export const DATE_REPORTED = {
  en_US: 'Date reported',
  pt_BR: 'Data relatada',
  es_ES: 'Fecha de notificación',
};

export const DESCRIBE_STOLEN_CARD = {
  en_US: 'A new field will appear to enter the date of the incident',
  pt_BR: 'Surgirá um novo campo para informar a data do incidente',
  es_ES: 'Aparecerá un nuevo campo para introducir la fecha del incidente',
};
