import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/ExtendedWarranty/consts';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const affectedPerson =
    claimDetails && claimDetails.affected_person
      ? claimDetails.affected_person
      : {};

  const relationship =
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';
  const comment =
    affectedPerson && affectedPerson.comment ? affectedPerson.comment : '-';

  const firstName =
    affectedPerson && affectedPerson.person.first_name
      ? affectedPerson.person.first_name
      : '-';

  const lastName =
    affectedPerson && affectedPerson.person.last_name
      ? affectedPerson.person.last_name
      : '-';

  const name = firstName + ' ' + lastName;

  return (
    <>
      <div className="col-sm-12 col-md-4" data-testid="personalAreaName">
        <InfoLabelValue label={intl.LABEL_NAME_AFFECTED} value={name} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_RELATIONSHIP_CARD_HOLDER}
          value={relationship}
        />
      </div>
      {relationship === 'Other' && (
        <div className="col-sm-12 col-md-4">
          <InfoLabelValue label={intl.TEXT_OTHER} value={comment} />
        </div>
      )}
    </>
  );
};

export default PersonalArea;
