import React, { useContext } from 'react';
import { IntlContext } from '../../../intl';
import styles from './VisaCoveredBenefits.module.scss';
import * as translations from '../intl';

import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import BenefitsIcon from '../../../assets/icons/BenefitsIcon';
import CertificateIcon from '../../../assets/icons/CertificatesIcon';
import VisaAirportCompanion from '../../../assets/icons/VisaAirportCompanionIcon';
import VisaMedicOnlineIcon from '../../../assets/icons/VisaMedicOnlineIcon';
import ConciergeIcon from '../../../assets/icons/ConciergeIcon';
import TravelKitIcon from '../../../assets/icons/TravelKitIcon';
import DisclaimerDownloadApp from '../Components/DisclaimerDownloadApp/DisclaimerDownloadApp';
import VirtualAgentIcon from '../../../assets/icons/VirtualAgent';

const VisaCoveredBenefits = () => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  const intl = translate(translations);

  return (
    <div className={`${styles.benefitsContainer} ${styles[theme]}`}>
      <h3
        className={styles[theme]}
        dangerouslySetInnerHTML={{
          __html: intl.DISCOVER_BENEFITS,
        }}
      />
      <ul>
        <li className={styles[theme]}>
          <VirtualAgentIcon width={50} height={50} invert_color={1} />
          <p>{intl.VIRTUAL_AGENT}</p>
        </li>
        <li className={styles[theme]}>
          <ConciergeIcon width={55} height={55} />
          <p>{intl.BENEFIT_CONCIERGE}</p>
        </li>
        <li className={styles[theme]}>
          <VisaAirportCompanion width={55} height={55} />
          <p>{intl.BENEFIT_AIRPORT_COMPANION}</p>
        </li>
        <li className={styles[theme]}>
          <TravelKitIcon width={55} height={55} />
          <p>{intl.BENEFIT_TRAVEL_KIT}</p>
        </li>
        <li className={styles[theme]}>
          <VisaMedicOnlineIcon width={55} height={55} />
          <p>{intl.BENEFIT_MEDIC}</p>
        </li>
        <li className={styles[theme]}>
          <BenefitsIcon width={50} height={50} />
          <p>{intl.BENEFIT_INFO}</p>
        </li>
        <li className={styles[theme]}>
          <CertificateIcon invert_color={1} width={50} height={50} />
          <p>{intl.BENEFIT_CERTIFICATES}</p>
        </li>
      </ul>
      <DisclaimerDownloadApp text={intl.TEXT_DISCLAIMER_DOWNLOAD_APP} />
    </div>
  );
};

export default VisaCoveredBenefits;
