import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import styles from './ProfessionalArea.module.scss';
import RadioButton from '../../../../../../../components/RadioButton/RadioButton';
import { pt_BR } from '../../../../../../../intl/idioms';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import GroupDivisor from '../../../../../../../components/GroupDivisor';

const ProfessionalArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={`row ${styles.professionalArea} ${styles.section}`}>
      {idiom === pt_BR ? (
        <>
          <div className="col-12">
            <GroupDivisor title={intl.TEXT_BANK} />
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <div className={`${styles.fontGray12} ${styles[theme]} row`}>
              <div className={`col-lg-12 col-sm-12`}>
                <label className={`${styles.labelTitle} ${styles[theme]}`}>
                  {intl.TEXT_POLITICALLY_EXPOSED}
                </label>
              </div>
              <div className="col-lg-3 col-sm-12">
                <RadioButton
                  label={intl.TEXT_POLITICALLY_EXPOSED}
                  defaultChecked={values.politicallyExposed}
                  text={intl.TEXT_YES}
                  name="politicallyExposed"
                  dataTestid="InputClaimCHPoliticallyExposedYes"
                  value={true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3 col-sm-12">
                <RadioButton
                  label={intl.TEXT_POLITICALLY_EXPOSED}
                  defaultChecked={!values.politicallyExposed}
                  text={intl.TEXT_NO}
                  name="politicallyExposed"
                  dataTestid="InputClaimCHPoliticallyExposedNo"
                  value={false}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProfessionalArea;
