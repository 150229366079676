import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MemoryRouter } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import { IntlParent } from './intl';
import StateContextParent from './components/StateContextParent/StateContextParent';
import Routes from './routes/Routes';
import Modal from './components/Modal/Modal';
import LoadingFullScreen from './components/LoadingFullScreen/LoadingFullScreen';
import './App.css';
import './assets/bootstrap/bootstrap.min.css';
import './styles/style.scss';
import { runScripts } from './utils/runScripts';
import TagManager from 'react-gtm-module';
import { stringToBoolean } from './utils/stringUtils';
import MessengerChat from './components/MessengerChat/MessengerChat';
import ThemeContextParent from './themes/ThemeContextParent/ThemeContextParent';
import PublicContextParent from './components/PublicContextParent/PublicContextParent';
import ScriptTokenex from './components/ScriptTokenex/ScriptTokenex';
import PushNotificationPermission from './components/PushNotificationPermission/PushNotificationPermission';
import UserInfoTrack from './components/UserInfoTrack/UserInfoTrack';
import { NOTIFICATIONS_ENABLE } from './utils/environments_variables';
import NotificationContextParent from './components/NotificationContextParent/NotificationContextParent';
import B2B2CStateParent from './components/B2B2CStateParent/B2B2CStateParent';
import ChatbotUST from './components/ChatBotUST/ChatbotUST';
import IdleTime from './components/IdleTime/IdleTime';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import AppInsightTrackContextParent from './components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import FeedbackNPS from './components/FeedbackNPS/FeedbackNPS';

const GTM = process.env.REACT_APP_GOOGLE_TAG_MANAGER;

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
};

firebase.initializeApp(config);

const tagManagerArgs = {
  gtmId: GTM,
  dataLayerName: 'dataLayer',
};
TagManager.initialize(tagManagerArgs);
runScripts();
function App(props) {
  const { initialState, initialRoute } = props;
  const showMessenger = stringToBoolean(process.env.REACT_APP_MESSENGER_CHAT);
  const showChatBotUST = stringToBoolean(process.env.REACT_APP_CHATBOT_UST);

  const routes = initialRoute ? (
    <MemoryRouter initialEntries={[initialRoute]} initialIndex={0}>
      <Routes />
    </MemoryRouter>
  ) : (
    <Routes />
  );
  return (
    <Router>
      <AppInsightTrackContextParent>
        <IntlParent initialState={initialState}>
          <StateContextParent initialState={initialState}>
            <NotificationContextParent>
              <PublicContextParent>
                <B2B2CStateParent>
                  <ThemeContextParent>
                    <ErrorBoundary>
                      <ScriptTokenex />
                      <UserInfoTrack />
                      <Route />
                      <Modal />
                      <LoadingFullScreen />
                      <IdleTime />
                      <FeedbackNPS />
                      {NOTIFICATIONS_ENABLE && <PushNotificationPermission />}
                      {showMessenger && <MessengerChat />}
                      <div className="App">{routes}</div>
                      {showChatBotUST && <ChatbotUST />}
                    </ErrorBoundary>
                  </ThemeContextParent>
                </B2B2CStateParent>
              </PublicContextParent>
            </NotificationContextParent>
          </StateContextParent>
        </IntlParent>
      </AppInsightTrackContextParent>
    </Router>
  );
}

export default App;
