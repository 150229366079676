import React, { useContext, useEffect } from 'react';
import Loading from '../Loading/Loading';
import styles from './LoadingFullScreen.module.scss';
import { StateContext } from '../StateContextParent/StateContextParent';
const LoadingFullScreen = () => {
  const { state } = useContext(StateContext);
  const {
    loadingFullScreen: { show, title = '', axa },
  } = state;
  useEffect(() => {
    if (show) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    }
    return () =>
      document.getElementsByTagName('body')[0].style.removeProperty('overflow');
  }, [show]);
  return (
    <>
      {show && (
        <div className={styles.boxLoading}>
          <div className={styles.background}></div>
          <div className={styles.boxLoading}>
            <div className={styles.loading}>
              <Loading
                scale="0.8"
                text={title}
                color={axa ? 'blueAXA' : undefined}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingFullScreen;
