export default (env, axios, errorHandler) => {
  const customerToAmosUser = customer => {
    const formatGender = person => {
      if (person.gender === 'ML') return 'male';
      else if (person.gender === 'FM') return 'female';
      else return '';
    };

    return {
      client_code: customer.customer_id,
      name: customer.person.first_name,
      surname: customer.person.last_name,
      gender: formatGender(customer.person),
      birth_date: customer.person.birth_date,
      country: customer.address.country,
      email: customer.email,
      phone_prefix: customer.phone.international_prefix,
      phone: customer.phone.number,
    };
  };

  const phoneBodyFormated = (customer, patient) => {
    return {
      customer,
      patient,
    };
  };

  return {
    create: (customer, requestBody) => {
      const url = `/amos/omc/consultations/create`;
      const customerFormated = customerToAmosUser(customer);
      const body = phoneBodyFormated(customerFormated, requestBody);
      return errorHandler(axios.post(url, body), 'createConsultation', {
        body,
      });
    },
    getAppointmentListPhoneCalls: (customer, params) => {
      const url = `/amos/omc/consultations`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated, { params }),
        'getAppointmentListVideoCalls',
        customerFormated
      );
    },
    cancelAppointmentPhoneCall: (customer, phoneCallID) => {
      const url = `/amos/omc/consultations/${phoneCallID}/cancel`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated),
        'cancelAppointmentPhoneCall',
        customerFormated
      );
    },
    getPhoneCallById: (customer, phoneCallID, files) => {
      const url = `/amos/omc/consultations/${phoneCallID}`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated, {
          params: { files },
        }),
        'getPhoneCallById',
        customerFormated
      );
    },
  };
};
