export const LABEL_INVOLVED_PERSON = {
  en_US: index => `Involved person #${index}`,
  pt_BR: index => `Pessoa envolvida #${index}`,
  es_ES: index => `Persona involucrada #${index}`,
};

export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_TYPE = {
  en_US: 'Type',
  pt_BR: 'Tipo',
  es_ES: 'Tipo',
};

export const WITNESS = {
  en_US: 'Witness',
  pt_BR: 'Testemunha',
  es_ES: 'Testigo',
};

export const DOCTOR = {
  en_US: 'Doctor',
  pt_BR: 'Médico',
  es_ES: 'Doctor',
};

export const KNOW_DOCTOR = {
  en_US: 'Known doctor',
  pt_BR: 'Médico conhecido',
  es_ES: 'Doctor conocido',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

//Phone
export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const LABEL_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};
