import { en_currency } from '../../../../../intl/currency/en_currency';
import { pt_currency } from '../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../intl/currency/es_currency';

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const INSSUANCE_DATE = {
  en_US: 'Issuance date *',
  pt_BR: 'Data de emissão *',
  es_ES: 'Fecha de emisión *',
};

export const PURCHASE_DATE = {
  en_US: 'Purchase date *',
  pt_BR: 'Data da nota fiscal do produto *',
  es_ES: 'Fecha de compra *',
};

export const PURCHASE_DATE_INFO = {
  en_US: "This date must be earlier than today's date.",
  pt_BR: 'Esta data deve ser anterior à data de hoje.',
  es_ES: 'Esta fecha debe ser anterior a la fecha de hoy.',
};

export const COVERED_PRODUCT = {
  en_US: 'Covered product *',
  pt_BR: 'Produto coberto *',
  es_ES: 'Producto cubierto *',
};

export const PRODUCT_BRAND = {
  en_US: 'Product brand *',
  pt_BR: 'Marca do produto *',
  es_ES: 'Marca del producto *',
};

export const PRODUCT_MODEL = {
  en_US: 'Product model *',
  pt_BR: 'Modelo do produto *',
  es_ES: 'Modelo del producto *',
};

export const PRODUCT_VALUE = {
  en_US: 'Product value *',
  pt_BR: 'Valor do produto adquirido *',
  es_ES: 'Valor del producto adquirido *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};

export const MAXIMUM_PERIOD = {
  en_US:
    'The maximum period of coverage under this insurance is 12 months after the expiration of the original warranty period.',
  pt_BR:
    'O período máximo de cobertura sob este seguro é de 12 meses após a expiração do período de garantia original.',
  es_ES:
    'El período máximo de cobertura bajo este seguro es de 12 meses después de la expiración del período de garantía original.',
};

export const REQUIRED_FIELD = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TOOLTIP_COVERED_PRODUCT = {
  en_US:
    'Examples: products such as home appliances, cell phones, handbags, etc.',
  pt_BR:
    'Exemplos: produtos como eletrodomésticos, telefones celulares, bolsas, etc.',
  es_ES:
    'Ejemplos: productos como electrodomésticos, teléfonos celulares, bolsos de mano, etc.',
};

export const TOOLTIP_PRODUCT_MODEL = {
  en_US:
    'Information available in purchase receipt or original product guarantee – code of letters and numbers.',
  pt_BR:
    'Informações disponíveis no recibo de compra ou garantia original do produto - código de letras e números.',
  es_ES:
    'Información disponible en el recibo de compra o en la garantía del producto original - código de letras y números.',
};

export const TOOLTIP_TICKET_AMOUNT = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const ORIGINAL_WARRANTY_PERIOD = {
  en_US: 'Original warranty period *',
  pt_BR: 'Período de garantia estendida original *',
  es_ES: 'Período de garantía extendida original *',
};

export const MONTHS = {
  en_US: 'Months',
  pt_BR: 'Meses',
  es_ES: 'Meses',
};

export const VALIDATE_INVALID_DATE = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};
