export const LABEL_RENTAL_START = {
  en_US: 'Rental period start*',
  pt_BR: 'Início do período do aluguel*',
  es_ES: 'Comienzo del período de alquiler*',
};

export const LABEL_RENTAL_END = {
  en_US: 'Rental period end*',
  pt_BR: 'Fim do período do aluguel*',
  es_ES: 'Fin del periodo de alquiler*',
};

export const TEXT_RENTAL_PERIOD = {
  en_US: 'Rental period',
  pt_BR: 'Período do aluguel',
  es_ES: 'Período de alquiler',
};
