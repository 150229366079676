import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from './PersonalArea.module.scss';
import * as constants from '../consts';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import { pt_BR } from '../../../../../../../../intl/idioms';
import Select from '../../../../../../../../components/Select/Select';
import { mask4FirstDigits } from '../../../../../../../../utils/stringUtils';

const PersonalArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    parentValues,
    values,
    initialValues,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;

  const setEmptyPersonalInputFields = () => {
    setFieldValue('affected_person.comment', '');
    setFieldValue('affected_person.person.first_name', '');
    setFieldValue('affected_person.person.last_name', '');
    setFieldValue('affected_person.person.birth_date', '');
    setFieldValue('affected_person.person.registrations.value', '');
  };

  const setCardHolderNameLastName = () => {
    setFieldValue(
      'affected_person.person.first_name',
      parentValues.policy.person.first_name
    );
    setFieldValue(
      'affected_person.person.last_name',
      parentValues.policy.person.last_name
    );
    setFieldValue(
      'affected_person.person.birth_date',
      parentValues.policy.person.birth_date
    );
    setFieldValue(
      'affected_person.person.registrations.value',
      parentValues.policy.person_registrations.value
    );
  };

  const changeRelationship = event => {
    setEmptyPersonalInputFields();
    if (event.target.value === constants.CARD_HOLDER_VALUE) {
      setCardHolderNameLastName();
    }
    handleChange(event);
  };

  const checkSameRegistrationValue = () => {
    if (
      values.affected_person.person.registrations.registration_type ===
        initialValues.affected_person.person.registrations.registration_type &&
      initialValues.affected_person.person.registrations.value !== ''
    ) {
      return (
        values.affected_person.person.registrations.value ===
        initialValues.affected_person.person.registrations.value
      );
    } else {
      return false;
    }
  };

  const getInitialRegistrationValue = () => {
    if (
      values.affected_person.person.registrations.registration_type ===
      initialValues.affected_person.person.registrations.registration_type
    ) {
      return initialValues.affected_person.person.registrations.value;
    } else {
      return '';
    }
  };

  return (
    <div className={`${styles.personalArea}`}>
      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.PERSON_AFFECTED}
            name="affected_person.relationship"
            value={values.affected_person.relationship}
            onBlur={handleBlur}
            touched={
              touched.affected_person && touched.affected_person.relationship
            }
            error={
              errors.affected_person && errors.affected_person.relationship
            }
            onChange={changeRelationship}
          >
            <option value={constants.CARD_HOLDER_VALUE}>{intl.TEXT_CH}</option>
            <option value={constants.SPOUSE_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_SPOUSE}
            </option>
            <option value={constants.DEPENDENT_VALUE}>
              {intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD}
            </option>
            <option value={constants.OTHER_VALUE}>
              {intl.TEXT_RELATIONSHIP_VALUE_OTHER}
            </option>
          </Select>
        </div>

        <div
          className={`col-12 col-md-6 ${
            values.affected_person.relationship !== constants.OTHER_VALUE
              ? styles.none
              : ''
          }`}
          data-testid="InputRelationshipComment"
        >
          <Input
            value={values.affected_person.comment}
            label={intl.TEXT_OTHER}
            name="affected_person.comment"
            type="text"
            disabled={
              values.affected_person.relationship !== constants.OTHER_VALUE
            }
            onChange={handleChange}
            touched={touched.affected_person && touched.affected_person.comment}
            onBlur={handleBlur}
            error={errors.affected_person && errors.affected_person.comment}
            maxLength="255"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-10 col-md-6">
          <Input
            dataTestid="affectedPerson_firstName"
            value={values.affected_person.person.first_name}
            label={intl.LABEL_NAME_AFFECTED}
            name="affected_person.person.first_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.first_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.first_name
            }
            maxLength="40"
          />
        </div>
        <div className="col-12 col-sm-10 col-md-6">
          <Input
            dataTestid="affectedPerson_lastName"
            value={values.affected_person.person.last_name}
            label={intl.LABEL_LAST_NAME_AFFECTED}
            name="affected_person.person.last_name"
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.last_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.last_name
            }
            maxLength="80"
          />
        </div>
      </div>

      <div className="row">
        <div
          className={`col-12 col-sm-12 col-md-6 ${
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
              ? styles.none
              : ''
          }`}
        >
          <Calendar
            dataTestid="affectedPerson_birthday"
            value={values.affected_person.person.birth_date}
            label={intl.LABEL_BIRTH_DATE}
            name="affected_person.person.birth_date"
            maxDate={new Date()}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.birth_date
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.birth_date
            }
            setFieldTouched={setFieldTouched}
          />
        </div>
        <div
          className={`col-12 col-sm-12 col-md-6 ${
            values.affected_person.relationship === constants.CARD_HOLDER_VALUE
              ? styles.none
              : ''
          }`}
        >
          <Input
            dataTestid="affectedPerson_passport"
            value={
              checkSameRegistrationValue()
                ? mask4FirstDigits(
                    values.affected_person.person.registrations.value
                  )
                : values.affected_person.person.registrations.value
            }
            label={intl.LABEL_REGISTRATIONS_TYPE}
            mask={
              idiom === pt_BR
                ? checkSameRegistrationValue()
                  ? 'maskedCPFWithoutFirst4'
                  : 'maskedCPF'
                : checkSameRegistrationValue()
                ? 'maskedPassaportWithoutFirst4'
                : 'maskedPassaport'
            }
            name="affected_person.person.registrations.value"
            type="text"
            onChange={handleChange}
            tooltipText={intl.HELP_REGISTRATION_TYPE}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.registrations &&
              touched.affected_person.person.registrations.value
            }
            onBlur={e => {
              handleBlur(e);
              if (e.target.value === '') {
                setFieldValue(
                  `affected_person.person.registrations.value`,
                  getInitialRegistrationValue()
                );
                setFieldTouched(
                  `affected_person.person.registrations.value`,
                  true
                );
              }
            }}
            onFocus={e => {
              if (checkSameRegistrationValue()) {
                setFieldValue(`affected_person.person.registrations.value`, '');
                setFieldTouched(
                  `affected_person.person.registrations.value`,
                  true
                );
              }
            }}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.registrations &&
              errors.affected_person.person.registrations.value
            }
          />
          <Input
            dataTestid="affectedPerson_registrations"
            value={
              values.affected_person.person.registrations.registration_type
            }
            name="affected_person.person.registrations.registration_type"
            type="hidden"
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.registrations &&
              touched.affected_person.person.registrations.registration_type
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.registrations &&
              errors.affected_person.person.registrations.registration_type
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
