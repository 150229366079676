import * as Yup from 'yup';

import { getValidate } from '../../../../../../../utils/banks';

export const bankSchema = intl => {
  const object = {
    name: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    bank_account_number: Yup.string()
      .min(8, intl.VALIDATE_MORE_THAN_8_CARACTERS)
      .required(intl.VALIDATE_REQUIRED_FIELD),
  };

  return Yup.object().shape(object);
};

export const bankSchemaBR = intl => {
  const object = {
    name: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    bank_code: Yup.string().required(intl.VALIDATE_REQUIRED_FIELD),
    branch_code: Yup.mixed()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .test(
        'test-agency-is-valid',
        intl.VALIDATE_INVALID_FIELD_AGENCY,
        function (value) {
          const validate = getValidate(this.parent.bank_code);
          return validate.agency(value);
        }
      )
      .test(
        'test-agency-length',
        intl.VALIDATE_INVALID_FIELD_AGENCY,
        function (value) {
          const validate = getValidate(this.parent.bank_code);
          return validate.agencyNumberLength(value);
        }
      ),
    bank_account_number: Yup.string()
      .required(intl.VALIDATE_REQUIRED_FIELD)
      .test(
        'test-account-is-valid',
        intl.VALIDATE_INVALID_FIELD_ACOUNT,
        function (value) {
          if (value && value.includes('*')) return true;
          const validate = getValidate(this.parent.bank_code);
          return validate.account(value, this.parent.branch_code);
        }
      )
      .test(
        'test-account-length',
        intl.VALIDATE_INVALID_FIELD_ACOUNT,
        function (value) {
          const validate = getValidate(this.parent.bank_code);
          return validate.accountNumberLength(value);
        }
      ),
  };
  return Yup.object().shape(object);
};
