export const TITLE_RELATED_OFFERS = {
  en_US: 'Related offers',
  es_ES: 'Ofertas relacionadas',
  pt_BR: 'Ofertas relacionadas',
};

export const BTN_VIEW_ALL_OFFERS = {
  en_US: 'View all offers',
  es_ES: 'Ver todas las ofertas',
  pt_BR: 'Ver todas as ofertas',
};
