import styles from './Button.module.scss';
import { joinClassNames } from '../../utils/classNames';
import { ButtonProps } from './Button.types';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { checkForDesignErrors, getTypeClassname } from './Button.utils';
import { ForwardedRef, forwardRef, MouseEventHandler } from 'react';
import { AnchorImplementation, ButtonImplementation } from './implementations';

const Button = forwardRef(
  (
    {
      id,
      className,
      axa = false,
      label,
      ariaLabel,
      ariaExpanded,
      surface = 'OnSurface',
      size = 'Large',
      type = 'Primary',
      leadingIcon,
      trailingIcon,
      disabled,
      testId,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { getGlobalTheme } = useTheme(axa);
    const theme = getGlobalTheme();

    // Tertiary and Icon types are small
    if (type === 'Tertiary' || type === 'Icon') size = 'Small';

    checkForDesignErrors({ type, leadingIcon, trailingIcon });

    const getClassname = () =>
      joinClassNames(
        styles.button,
        surface === 'OnSurface' ? styles.onSurface : styles.inverse,
        size === 'Large' ? styles.large : styles.small,
        styles[getTypeClassname(type)],
        styles[theme],
        className
      );

    const ImplementationComponent =
      props.link === true ? AnchorImplementation : ButtonImplementation;

    return (
      <ImplementationComponent
        id={id}
        ref={ref as ForwardedRef<any>}
        className={getClassname()}
        label={label}
        ariaLabel={ariaLabel}
        ariaExpanded={ariaExpanded}
        type={type}
        leadingIcon={leadingIcon}
        trailingIcon={trailingIcon}
        disabled={disabled}
        theme={theme}
        testId={testId}
        href={props.href}
        target={props.target}
        onClick={props.onClick as MouseEventHandler<unknown>}
        internal={props.internal}
        typeHtml={props.typeHtml}
      />
    );
  }
);

Button.displayName = 'Button';

export default Button;
export * from './Button.types';
export { getButtonIconProps } from './Button.utils';
