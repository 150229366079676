import React, { useContext } from 'react';
import Carousel from '../../../components/Carousel/Carousel';
import GrayBox from './grayBox/GrayBox';
import { IntlContext } from '../../../intl/index';
import * as translations from '../intl';
import { enableUpselling } from '../../../utils/verifyAccess';
import { enableCrosselling } from '../../../utils/verifyAccessCrosselling';

const createObjectCotizar = (productName = '', country, intl, email) => {
  if (enableUpselling(productName, country, email)) {
    return {
      title: intl.TITLE_GRAY_BOX_UPSELLING,
      label_link: intl.LINK_GRAY_BOX_UPSELLING,
      redirect: '/upsell-detail/EMC_Emergency_Medical_Assistance',
      GTM: 'CTA Generate certificate',
    };
  }
  if (enableCrosselling(productName, country, email)) {
    return {
      title: intl.TITLE_GRAY_BOX_CROSSELLING,
      label_link: intl.LINK_GRAY_BOX_CROSSELLING,
      redirect: '/cross-sell-detail/EMC_Emergency_Medical_Assistance',
      GTM: 'CTA Generate certificate',
    };
  }
  return false;
};

const createFirstGrayBox = (intl, country, productActive, email) => {
  const obj_certificate = {
    title: intl.TITLE_EXPLORE_WORLD,
    label_link: intl.LINK_ISSUE_INSURANCE,
    redirect: '/certificates',
    GTM: 'CTA Generate certificate',
  };

  const obj_cotizar = createObjectCotizar(
    productActive && productActive.name,
    country,
    intl,
    email
  );

  return obj_cotizar ? obj_cotizar : obj_certificate;
};

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  arrows: false,
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: 2.4,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1.5,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 560,
      settings: {
        slidesToShow: 1.4,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 430,
      settings: {
        slidesToShow: 1.3,
        dots: false,
        centerMode: false,
      },
    },
    {
      breakpoint: 375,
      settings: {
        slidesToShow: 1.2,
        dots: false,
        centerMode: false,
      },
    },
  ],
};

const BoxCarousel = ({ productActive }) => {
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const firstGrayBox = createFirstGrayBox(intl, country, productActive);
  return (
    <Carousel settings={settings}>
      <GrayBox
        title={firstGrayBox.title}
        link={firstGrayBox.label_link}
        to={firstGrayBox.redirect}
        GTM_item_id={firstGrayBox.GTM}
      />
      <GrayBox
        title={intl.TITLE_YOU_COVERED}
        link={intl.LINK_OPEN_CLAIM}
        to={'/claims'}
        GTM_item_id="CTA Open a claim"
      />
      <GrayBox
        title={intl.TITLE_TRACK_REQUESTS}
        link={intl.LINK_TRACK_REQUEST}
        to="/profile/claims"
        GTM_item_id="CTA Track request"
      />
    </Carousel>
  );
};

export default BoxCarousel;
