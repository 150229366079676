import React from 'react';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import { userServices } from '../../services';
import moment from 'moment';
import { isBR } from '../country';

const isDocumentAttached = (attachments, type) => {
  return attachments.some(document => document.document_type_name == type);
};
const isAnyDocumentExpired = attachments => {
  const today = moment(new Date()).format('YYYY-MM-DD');
  return attachments.find(document =>
    moment(today).isAfter(document.document_valid_until)
  );
};

export const verifyPersonalDocuments = async (
  utils,
  actions,
  intl,
  history,
  country
) => {
  try {
    const {
      data: { attachments },
    } = await userServices.getCHInfo(utils.getCn());

    const isClaimUpload = history.location.pathname.includes('upload');
    const hideAlertModal = localStorage.getItem('hideAlertModal');
    const nationalIdCondition =
      !isDocumentAttached(attachments, 'National ID') && !hideAlertModal;
    const proofOfResidencyCondition =
      isBR(country) &&
      !isDocumentAttached(attachments, 'Proof of Residency') &&
      !hideAlertModal;
    const expiredCondition =
      isAnyDocumentExpired(attachments) && !hideAlertModal;

    if (
      (nationalIdCondition || proofOfResidencyCondition || expiredCondition) &&
      !isClaimUpload
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="question"
          message={
            isAnyDocumentExpired(attachments)
              ? intl.TITLE_EXPIRED_DOCUMENTS
              : isClaimUpload
              ? intl.CLAIM_WELCOME_PERSONAL_DOCUMENTS
              : intl.WELCOME_PERSONAL_DOCUMENTS
          }
          subtitle={
            isAnyDocumentExpired(attachments)
              ? intl.WARNING_EXPIRED_DOCUMENTS
              : isClaimUpload
              ? intl.CLAIM_WARNING_PERSONAL_DOCUMENTS
              : intl.WARNING_PERSONAL_DOCUMENTS
          }
          textBtn={intl.TEXT_BUTTON_PERSONAL_DOCUMENTS}
          textBtnDeny={intl.TEXT_BUTTON_UPDATE_LATER}
          clickBtn={() => {
            localStorage.setItem('hideAlertModal', true);
            history.push('/profile/account#personalDocuments');
            actions.modal.closeModal();
          }}
          clickBtnDeny={() => {
            localStorage.setItem('hideAlertModal', true);
            actions.modal.closeModal();
            if (isClaimUpload) history.push('/dashboard');
          }}
        />,
        true
      );
    }
  } catch (error) {}
};
