/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import * as translations from './intl.js';
import sanitize from '../../../../../../../../utils/sanitize';

const ReimbursementArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;

  useEffect(() => {
    setFieldValue(
      'total_amount_claimed.value',
      Number(
        Number(values.amount_estimation_damage.value) -
          Number(values.other_insurance_covering.value)
      ).toFixed(2)
    );
  }, [
    values.amount_estimation_damage.value,
    values.other_insurance_covering.value,
  ]);

  return (
    <div className={`row`}>
      <div className={`col-12 col-sm-12 col-md-6 `}>
        <Input
          label={intl.LABEL_TOTAL_REIMBURSED}
          type="text"
          name="total_amount_claimed.value"
          tooltipText={intl.TOOLTIP_TOTAL_REIMBURSED}
          disabled={true}
          value={values.total_amount_claimed.value}
          onChange={e => {
            handleChange(e);
          }}
          onBlur={handleBlur}
          touched={
            touched.total_amount_claimed && touched.total_amount_claimed.value
          }
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.value
          }
          dataTestid="inputAmountClaimed"
        />
      </div>

      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          name="total_amount_claimed.currency"
          disabled
          value={values.amount_estimation_damage.currency}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.total_amount_claimed && errors.total_amount_claimed.currency
          }
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid="comboboxTotalAmountCurrency"
        />
      </div>
    </div>
  );
};

export default ReimbursementArea;
