import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const InsuranceArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const formalComplaint =
    claimDetails && claimDetails.formal_complaint
      ? claimDetails.formal_complaint
      : {};

  const submissionReason =
    formalComplaint && formalComplaint.submission_reason
      ? formalComplaint.submission_reason
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_SUBMITTED_COMPLAINT}
          value={submissionReason}
        />
      </div>
    </>
  );
};

export default InsuranceArea;
