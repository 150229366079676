import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import PhoneArea from './PhoneArea/PhoneArea';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const AirlineArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const travelAgency =
    claimDetails && claimDetails.travel_information.travel_agency
      ? claimDetails.travel_information.travel_agency
      : {};

  const travelAgencyName =
    travelAgency && travelAgency.name ? travelAgency.name : '-';

  const otherInsuranceCovering =
    claimDetails && claimDetails.other_insurance_covering
      ? claimDetails.other_insurance_covering
      : {};

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_NAME_AIRLINE}
          value={travelAgencyName}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <PhoneArea claimDetails={claimDetails} />
      </div>
    </>
  );
};

export default AirlineArea;
