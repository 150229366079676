export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

// export const ACCIDENT_REASON = {
//   en_US: "Accident reason",
//   pt_BR: "Motivo do incidente",
//   es_ES: "Motivo del accidente"
// };

// export const ACCIDENTAL_DISMEMBERMENT = {
//   en_US: "Accidental dismemberment",
//   pt_BR: "Desmembramento acidental",
//   es_ES: "Desmembramiento accidental"
// };

// export const ACCIDENTAL_DEATH = {
//   en_US: "Accidental death",
//   pt_BR: "Morte acidental",
//   es_ES: "Muerte accidental"
// };

export const SELECT_OPTION_ASSAULT_DEATH = {
  en_US: 'Select an option (ATM assault, Death)',
  es_ES: 'Selecciona una opcion (Asalto en cajero automático, Muerte)',
  pt_BR: 'Selecione uma opção (Assalto em caixa eletrônico, Morte)',
};

export const OPTION_ASSAULT = {
  en_US: 'Assault',
  es_ES: 'Asalto',
  pt_BR: 'Assalto',
};

export const OPTION_DEATH = {
  en_US: 'Death',
  es_ES: 'Muerte',
  pt_BR: 'Morte',
};

export const DATE_BIRTH = {
  en_US: 'Date of birth',
  es_ES: 'Fecha de nacimiento',
  pt_BR: 'Data de nascimento',
};

export const GENDER = {
  en_US: 'Gender',
  es_ES: 'Género',
  pt_BR: 'Gênero',
};

export const GENDER_MALE = {
  en_US: 'Male',
  es_ES: 'Masculino',
  pt_BR: 'Masculino',
};

export const GENDER_FEMALE = {
  en_US: 'Female',
  es_ES: 'Femenino',
  pt_BR: 'Feminino',
};

export const DEATH_DATE = {
  en_US: 'Death date',
  es_ES: 'Fecha de la muerte',
  pt_BR: 'Data da morte',
};

export const DESCRIPTION_CHANGE = {
  en_US: `The following personal data fields will either be filled in with the cardholder's details or cleared for you to fill in yourself`,
  es_ES:
    'Los siguientes campos de datos personales se rellenarán con los datos del titular de la tarjeta o se borrarán para que los rellene usted mismo',
  pt_BR:
    'Os campos de dados pessoais a seguir vão ser preenchidos com os dados do portador do cartão ou limpos para serem preenchidos por você',
};
