import { SVGIconProps } from '../../@types/Icons';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getIconThemeColors } from '../../utils/icons';

export const useIconColors = ({
  surface = 'OnSurface',
  color = 'Monotone',
  colorOverride,
  disabled,
  axa,
}: SVGIconProps) => {
  const { getCurrentThemeColors } = useTheme(axa);
  const themeColors = getCurrentThemeColors();

  if (colorOverride) return colorOverride;

  return getIconThemeColors(themeColors, surface, color, disabled);
};
