import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const VisaAirportCompanion = props => {
  const { darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : config_icon.secondary;

  return (
    <svg
      width={81}
      height={61}
      viewBox="0 0 81 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.6981 52.719L20.1817 50.2026L29.6127 40.7717H17.3059C13.7323 40.7717 10.3913 38.9955 8.40364 36.0139L0.347168 23.9398L3.30755 21.9733L11.364 34.0474C12.6962 36.0351 14.9165 37.2192 17.2848 37.2192H38.1978L22.6769 52.7401L22.6981 52.719Z"
        fill={primary}
      />
      <path
        d="M32.869 19.3723L20.9006 7.42505L18.3843 9.94137L27.8152 19.3723H32.869Z"
        fill={primary}
      />
      <path
        d="M78.1416 28.5917C74.5046 24.9547 69.6623 22.9459 64.5027 22.9459H16.8194L6.64841 12.7748L4.13208 15.2912L15.3392 26.4983H64.5027C68.7107 26.4983 72.6649 28.1265 75.6253 31.1081C76.302 31.7847 76.6614 32.6728 76.6614 33.6244C76.6614 34.5759 76.2808 35.4641 75.6253 36.1407C74.9698 36.8174 74.0605 37.1768 73.109 37.1768H52.9572L23.9878 57.096L25.9966 60.0352L54.0568 40.7505H73.109C75.0121 40.7505 76.8095 40.0104 78.1416 38.657C79.4949 37.3037 80.235 35.5275 80.235 33.6244C80.235 31.7213 79.4949 29.9239 78.1416 28.5917Z"
        fill={primary}
      />
      <path
        d="M49.4471 19.3723L24.2838 0.129822L22.127 2.96333L43.5897 19.3723H49.4471Z"
        fill={primary}
      />
      <path
        d="M58.8571 30.0719H16.0796V33.6455H58.8571V30.0719Z"
        fill={secondary}
      />
    </svg>
  );
};

export default VisaAirportCompanion;
