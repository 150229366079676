export const SEE_PDF = {
  en_US: 'See Terms and Conditions for more information',
  es_ES: 'Ver Términos y Condiciones para más información',
  pt_BR: 'Veja os Termos e Condições para mais informações',
};

export const MESSAGE_MODAL_BENEFIT_NOT_ELEGIBILITY = {
  en_US: benefit =>
    `The selected card does not have the ${
      benefit.name ? `${benefit.name} benefit.` : 'benefit.'
    }`,
  es_ES: benefit =>
    `La tarjeta seleccionada actualmente no tiene el beneficio${
      benefit.name ? ` ${benefit.name}.` : '.'
    }`,
  pt_BR: benefit =>
    `O cartão selecionado no momento não possui o benefício${
      benefit.name ? ` ${benefit.name}.` : '.'
    }`,
};

export const SUBTITLE_MODAL_NOT_ELEGIBILITY = {
  en_US:
    'Please check the benefits of the currently selected card or select another card to see if it has this benefit.',
  es_ES:
    'Por favor, compruebe los beneficios de la tarjeta seleccionada, o por favor elige otra tarjeta para ver si dispone de ese beneficio.',
  pt_BR:
    'Por favor, verifique os benefícios do cartão selecionado no momento ou selecione outro cartão para ver se ele dispõe desse benefício.',
};

export const BTN_VIEW_BENEFITS = {
  en_US: 'See card benefits',
  es_ES: 'Ver beneficios de la tarjeta',
  pt_BR: 'Ver benefícios do cartão',
};

export const BTN_VIEW_CARDS = {
  en_US: 'See my cards',
  es_ES: 'Ver mis tarjetas',
  pt_BR: 'Ver meus cartões',
};

export const ACTIVE_DOWNLOAD_BENEFIT = {
  en_US: benefitName => `and enjoy the ${benefitName}`,
  es_ES: benefitName => `y activa tu ${benefitName}`,
  pt_BR: benefitName => `e ative seu ${benefitName}`,
};
