import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { IntlContext } from '../../intl';
import { notificationsService, userServices } from '../../services';
import { StateContext } from '../StateContextParent/StateContextParent';

const initialStateToastNotification = {
  ARI: false,
  PriceProtection: false,
  VacacionesProtegidas: false,
  IEMS: false,
  IEMSCertificate: false,
  PAN: false,
  privacyBR: false,
  AirportTransportationMX: false,
  santanderChile: false,
  MobileUpdate: false,
};

const initialStateShowFeedback = {
  NPS: true,
};

const initialCommunicationPreferences = [];
const initialRecentFiveNotificationsDetail = [];

export const NotificationContext = createContext({
  state: {
    bellNotification: {
      count: 0,
      recentFiveNotificationsDetail: [...initialRecentFiveNotificationsDetail],
    },
    communicationPreferences: [...initialCommunicationPreferences],
    toastNotification: {
      ...initialStateToastNotification,
    },
    showFeedback: {
      ...initialStateShowFeedback,
    },
  },
  actions: {
    toastNotification: {
      setOnlyOnceAppereanceToast: toastName => {},
      setResetAppereanceToast: () => {},
    },
    communicationPreferences: {
      updateCommunicationPrefences: () => {},
    },
    bellNotification: {
      updateBellNotification: () => {},
    },
    feedback: {
      setShowFeedbackNPS: value => {},
    },
  },
});

const NotificationContextParent = props => {
  const [bellNotification, setBellNotification] = useState({
    count: 0,
    recentFiveNotificationsDetail: [],
  });
  const [communicationPreferences, setCommunicationPreferences] = useState(
    initialCommunicationPreferences
  );
  const [toastNotification, setToastNotification] = useState(
    initialStateToastNotification
  );
  const [showFeedback, setShowFeedback] = useState(initialStateShowFeedback);
  const { utils, state } = useContext(StateContext);
  const { idiom } = useContext(IntlContext);
  const isCanceled = useRef(null);

  const setOnlyOnceAppereanceToast = toastName => {
    setToastNotification(state => {
      state[toastName] = true;
      return { ...state };
    });
  };

  const setShowFeedbackNPS = value => {
    setShowFeedback(state => {
      state.NPS = value;
      return { ...state };
    });
  };

  const setResetAppereanceToast = () => {
    setToastNotification(() => ({
      ARI: false,
      PriceProtection: false,
      VacacionesProtegidas: false,
      IEMS: false,
      PAN: false,
      privacyBR: false,
      AirportTransportationMX: false,
      santanderChile: false,
      MobileUpdate: false,
    }));
  };

  const updateBellNotification = async () => {
    const cn = utils.getCn();

    try {
      const [recentFiveNotifications, countableNotifications] =
        await Promise.all([
          notificationsService.getUserNotifications(
            cn,
            idiom,
            '?was_archived=false&max_results=5'
          ),
          notificationsService.getUserNotifications(
            cn,
            idiom,
            '?was_archived=false&was_read=false'
          ),
        ]);

      const recentFiveDetails = await Promise.all(
        recentFiveNotifications.data.map(async notitication => {
          const detail = await notificationsService.getUserNotificationDetail(
            cn,
            notitication.notification_id,
            idiom
          );
          return detail.data;
        })
      );

      if (!isCanceled.current) {
        setBellNotification(state => {
          state.count = countableNotifications.data.length;
          state.recentFiveNotificationsDetail = recentFiveDetails;
          return { ...state };
        });
      }
    } catch (error) {
      if (!isCanceled.current) {
        setBellNotification(state => {
          state.recentFiveNotificationsDetail = [];
          return { ...state };
        });
      }
    }
  };

  const updateCommunicationPrefences = async () => {
    const customerId = utils.getCn();
    try {
      const { data } = await userServices.getPreferences(customerId);
      setCommunicationPreferences(data);
    } catch (error) {}
  };

  useEffect(() => {
    isCanceled.current = false;

    if (state.user.logged) {
      updateBellNotification();
      updateCommunicationPrefences();
    }

    return () => {
      isCanceled.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.logged]);

  useEffect(() => {
    const status = JSON.parse(sessionStorage.getItem('feedback-VisaBenefits'));
    if (status !== null) {
      setShowFeedback({ ...status });
    }
  }, []);

  const value = {
    state: {
      bellNotification,
      communicationPreferences,
      toastNotification,
      showFeedback,
    },
    actions: {
      toastNotification: {
        setOnlyOnceAppereanceToast,
        setResetAppereanceToast,
      },
      communicationPreferences: {
        updateCommunicationPrefences,
      },
      bellNotification: {
        updateBellNotification,
      },
      feedback: {
        setShowFeedbackNPS,
      },
    },
  };

  return (
    <NotificationContext.Provider value={value}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextParent;
