import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const QuestionsArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const itemInformation = claimDetails && claimDetails.item_information;

  const itemPrice = itemInformation.price.value;

  const currency = itemInformation.price.currency;

  const otherInsuranceCovering =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering !== null &&
    claimDetails.other_insurance_covering[0]
      ? claimDetails.other_insurance_covering[0]
      : [];

  const isInternal =
    otherInsuranceCovering && otherInsuranceCovering.is_internal;

  const otherInsurancePaid =
    otherInsuranceCovering &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.value
      ? otherInsuranceCovering.amount.value
      : '-';
  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PRICE}
          value={`${itemPrice} - ${currency}`}
        />
      </div>
      {isInternal === false && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_AMOUNT_PAID}
              value={`${otherInsurancePaid} - ${currency}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default QuestionsArea;
