export const ALL = 'all';
export const TRAVEL_BENEFITS = 'travel_benefits';
export const PURCHASE_PROTECTION = 'purchase_protection';
export const LIFESTYLE = 'lifestyle';
export const CONCIERGE = 'concierge';
export const SPECIAL_BENEFIS = 'special_benefits';
export const HIDDEN = 'hidden';

export const getIconByTheme = (list, isAfluent) => {
  let image = list.find(image =>
    isAfluent ? image.type === 'ICON_AFFLUENT' : image.type === 'ICON_MASTER'
  );

  if (image) {
    return image.content_url;
  }

  image = list.find(image => image.type === 'ICON');
  return image && image.content_url ? image.content_url : '';
};

export const getCategory = () => {
  const urlCategory = window.location.href.split('#')[1];
  if (urlCategory === TRAVEL_BENEFITS) {
    return TRAVEL_BENEFITS;
  } else if (urlCategory === CONCIERGE) {
    return CONCIERGE;
  } else if (urlCategory === PURCHASE_PROTECTION) {
    return PURCHASE_PROTECTION;
  } else if (urlCategory === LIFESTYLE) {
    return LIFESTYLE;
  } else {
    return ALL;
  }
};

export const CATEGORY_TAG_ALL = [ALL, 'todos', 'todos'];
export const CATEGORY_TAG_PURCHASE = ['Purchasing', 'Compras', 'Compras'];
export const CATEGORY_TAG_TRAVEL = ['Travel', 'Viaje', 'Viagem'];
export const CATEGORY_TAG_SUBSCRIPTION = [
  'Subscription',
  'Suscripción',
  'Assinatura',
];
export const CATEGORY_TAG_HOME = ['Home', 'Casa', 'Casa'];
export const CATEGORY_TAG_EDUCATION = ['Education', 'Educación', 'Educação'];
export const CATEGORY_TAG_DINING = ['Dining', 'Restaurante', 'Restaurante'];
export const CATEGORY_TAG_ENTERTAINMENT = [
  'Entertainment',
  'Entretenimiento',
  'Entretenimento',
];
export const CATEGORY_TAG_WORK = [
  'Work related',
  'Relacionado a trabajo',
  'Relacionado ao Trabalho',
];
export const CATEGORY_TAG_PET = ['Pet related', 'Mascotas', 'Assistência Pet'];
export const CATEGORY_TAG_TRANSPORTATION = [
  'Transportation related',
  'Transporte',
  'Transporte',
];
export const CATEGORY_TAG_OFFERS = ['Offers', 'Ofertas', 'Ofertas'];
export const CATEGORY_TAG_LOCAL = [
  'Local Benefits',
  'Beneficios Locales',
  'Benefícios Locais',
];
export const CATEGORY_TAG_LIFESTYLE = [
  'Lyfestyle',
  'Estilo de vida',
  'Estilo de vida',
];
export const CATEGORY_TAG_WELLNESS = ['Wellness', 'Bienestar', 'Bem Estar'];
export const CATEGORY_TAG_PROTECTION = [
  'Protection and Security',
  'Proteccion y Seguridad',
  'Proteção e Segurança',
];
export const CATEGORY_TAG_CONCIERGE = ['Concierge', 'Concierge', 'Concierge'];
