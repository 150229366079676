import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../components/Input/Input';

const ProductArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { productName } = props;

  return (
    <div className="row">
      <div className="col-12">
        <Input
          dataTestid="InputClaimCHProduct"
          value={productName}
          label={intl.LABEL_PRODUCT}
          disabled
        />
      </div>
    </div>
  );
};

export default ProductArea;
