export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const TEXT_NO_DEPENDENTS = {
  en_US: 'You don’t have any beneficiary.',
  pt_BR: 'Você não tem nenhum beneficiário.',
  es_ES: 'No tienes ningún beneficiario.',
};

export const VALIDATION_NAME_LAST_NAME = {
  en_US: 'Please enter at least one letter [A-Za-z].',
  pt_BR: 'Por favor, insira pelo menos uma letra [A-Za-z].',
  es_ES: 'Por favor, introduzca al menos una letra [A-Za-z].',
};

export const YOUR_DEPENDENTS = {
  en_US: 'My beneficiaries',
  pt_BR: 'Meus beneficiários',
  es_ES: 'Mis beneficiarios',
};

export const SAVE_DEPENDENT = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TITLE_EDIT_DEPENDENT = {
  en_US: 'Edit beneficiary information',
  pt_BR: 'Editar dados do beneficiário',
  es_ES: 'Editar datos de beneficiario',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const SUCCESS_UPDATE_DEPENDENT = {
  en_US:
    'Congratulations! Beneficiary information has been updated successfully.',
  pt_BR: 'Parabéns! Os dados do beneficiário foram atualizados com sucesso.',
  es_ES:
    '¡Felicidades! Los datos del beneficiario se han actualizados correctamente.',
};

export const SUCCESS_ADD_DEPENDENT = {
  en_US: 'Congratulations! New beneficiary added successfully.',
  pt_BR: 'Parabéns! Novo beneficiário adicionado com sucesso.',
  es_ES: '¡Felicidades! Nuevo beneficiario agregado con éxito.',
};

export const TITLE_DELETE_DEPENDENT = {
  en_US: 'Are you sure you want to remove the selected beneficiary? ',
  pt_BR: 'Você está certo de que deseja remover o beneficiário selecionado?',
  es_ES: '¿Está seguro de que desea eliminar al beneficiario seleccionado?',
};

export const SUBTITLE_DELETE_DEPENDENT = {
  en_US: 'This action cannot be reversed.',
  pt_BR: `Essa ação não pode ser revertida.`,
  es_ES: 'No se podrá revertir esta acción.',
};

export const TEXT_YES_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const TEXT_NO_KEEP_DEPENDENT = {
  en_US: 'No, keep the beneficiary',
  pt_BR: 'Não, manter o beneficiário',
  es_ES: 'No, mantener beneficiario',
};

export const SUCCESS_DELETE_DEPENDENT = {
  en_US: 'Beneficiary successfully removed.',
  pt_BR: 'Beneficiário removido com sucesso.',
  es_ES: 'Beneficiario eliminado con éxito.',
};

export const SAVING_DEPENDENT = {
  en_US: 'Saving beneficiary information...',
  pt_BR: 'Salvando os dados do beneficiário...',
  es_ES: 'Guardando datos beneficiario...',
};

export const REMOVING_DEPENDENT = {
  en_US: 'Removing the beneficiary...',
  pt_BR: 'Removendo o beneficiário...',
  es_ES: 'Eliminando al beneficiario...',
};

export const CHECKBOX_LABEL_IS_SELECTED = {
  en_US: 'Selected',
  pt_BR: 'Selecionado',
  es_ES: 'Seleccionado',
};

export const CHECKBOX_LABEL_SELECT = {
  en_US: 'Select',
  pt_BR: 'Selecionar',
  es_ES: 'Selecciona',
};

export const TEXT_FIELD_VALIDATION_30 = {
  en_US: 'Please enter a maximum of 30 characters.',
  pt_BR: 'Por favor, insira no máximo 30 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 30 caracteres.',
};
