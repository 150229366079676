import { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getItemName,
  getItemPurchasePrice,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const items =
      (claimDetails && claimDetails.purchase_protection_items) || [];
    setItems(items);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_ITEM} simpleLine />
      </div>
      {items.map((item, index) => (
        <div key={index} className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={getItemName(item)}
            value={getItemPurchasePrice(item, idiomForApi())}
          />
        </div>
      ))}
    </>
  );
};

export default PersonalArea;
