import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const InProcess = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {'.st40,.st5{fill:none;stroke-width:2;stroke-miterlimit:10}'}
      </style>
      <path
        style={{
          fill: 'none',
          stroke: config_icon.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M4.5 9.3c-.3.8-.5 1.8-.5 2.7 0 4.4 3.6 8 8 8 2.4 0 4.5-1.1 6-2.7"
      />
      <path
        style={{
          fill: 'none',
          stroke: config_icon.secondary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M19.5 14.9c.3-.9.5-1.9.5-2.9 0-4.4-3.6-8-8-8-2.4 0-4.5 1-6 2.7"
      />
      <path
        style={{
          fill: 'none',
          stroke: config_icon.secondary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="m16 11.5 3.5 3.5 3.5-3.5"
      />
      <path
        style={{
          fill: 'none',
          stroke: config_icon.primary,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="M1 12.5 4.5 9 8 12.5"
      />
    </svg>
  );
};

export default InProcess;
