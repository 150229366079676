import React, { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './PlacesArea.module.scss';
import Input from '../../../../../../../components/Input/Input';
import Select from '../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../utils/sanitize';

const PlacesArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    isCertificate = false,
  } = props;

  const COUNTRY_OPTIONS_DESTINATIONS = useMemo(
    () => intl.COUNTRY_OPTIONS_DESTINATIONS(),
    [intl]
  );

  return (
    <div className={styles.placesArea}>
      <div
        className={`row ${
          values.travel_information.is_international_trip === false
            ? styles.none
            : ''
        }`}
        data-testid="DivInputCountryOrigin"
      >
        <div className="col-12 col-md-6">
          <Select
            label={intl.FIELD_ORIGIN_COUNTRY}
            value={values.travel_information.country_origin}
            name="travel_information.country_origin"
            dataTestid="InputTIOriginCountry"
            onChange={handleChange}
            onBlur={handleBlur}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.FIELD_ORIGIN_COUNTRY}</option>` +
                  intl.COUNTRY_OPTIONS
              ),
            }}
            touched={
              touched.travel_information &&
              touched.travel_information.country_origin
            }
            error={
              errors.travel_information &&
              errors.travel_information.country_origin
            }
          />
        </div>
        <div className="col-12 col-md-6">
          <Select
            label={intl.FIELD_DESTINATION_COUNTRY}
            value={values.travel_information.country_destination}
            name="travel_information.country_destination"
            dataTestid="InputTIDestCountry"
            onChange={handleChange}
            onBlur={handleBlur}
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.FIELD_DESTINATION_COUNTRY}</option>` +
                  COUNTRY_OPTIONS_DESTINATIONS
              ),
            }}
            touched={
              touched.travel_information &&
              touched.travel_information.country_destination
            }
            error={
              errors.travel_information &&
              errors.travel_information.country_destination
            }
            helpText={intl.DESTINATION_COUNTRY_HELP_TEXT}
          />
        </div>
      </div>
      <div
        className={`row ${
          values.travel_information.is_international_trip === true
            ? styles.none
            : ''
        }`}
        data-testid="DivInputCityOrigin"
      >
        <div className="col-12 col-md-6">
          <Input
            value={values.travel_information.city_origin}
            label={intl.FIELD_ORIGIN_CITY}
            name="travel_information.city_origin"
            type="text"
            dataTestid="InputTICityOrigin"
            onChange={handleChange}
            touched={
              touched.travel_information &&
              touched.travel_information.city_origin
            }
            onBlur={handleBlur}
            error={
              errors.travel_information && errors.travel_information.city_origin
            }
          />
        </div>
        <div className="col-12 col-md-6">
          <Input
            value={values.travel_information.city_destination}
            label={intl.FIELD_DESTINATION_CITY}
            name="travel_information.city_destination"
            type="text"
            dataTestid="InputTICityDest"
            onChange={handleChange}
            touched={
              touched.travel_information &&
              touched.travel_information.city_destination
            }
            onBlur={handleBlur}
            error={
              errors.travel_information &&
              errors.travel_information.city_destination
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PlacesArea;
