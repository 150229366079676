export const TEXT_BAGGAGE_CUSTODY = {
  en_US:
    'Was the baggage in the custody of an airline company, a railroad company, a cruise line or other transport service at the time of loss? *',
  es_ES:
    '¿El equipaje era responsabilidad de una compañia aerea, una compañía ferroviaria, una línea de cruceros u otro servicio de transporte en el momento de la pérdida?  *',
  pt_BR:
    'A bagagem era responsabilidade de uma companhia aérea, uma empresa ferroviária, uma linha de cruzeiro ou outro serviço de transporte no momento da perda? *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  es_ES: 'Otro (explicar)  *',
  pt_BR: 'Outro (explicar) *',
};

export const OPTIONS_AIRLINE = {
  en_US: 'Airline',
  es_ES: 'Aerolínea',
  pt_BR: 'Linha aérea',
};

export const OPTIONS_CRUISE_LINE = {
  en_US: 'Cruise line',
  es_ES: 'Compañía de cruceros',
  pt_BR: 'Linha de cruzeiro',
};

export const OPTIONS_RAILROAD = {
  en_US: 'Railroad company',
  es_ES: 'Compañía de ferrocarril',
  pt_BR: 'Companhia ferroviária',
};

export const OPTIONS_BUS = {
  en_US: 'Bus company',
  es_ES: 'Compañía de buses',
  pt_BR: 'Companhia de ônibus',
};

export const OPTIONS_OTHER = {
  en_US: 'Other',
  es_ES: 'Otro',
  pt_BR: 'Outro',
};
