import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import styles from './BankInfo.module.scss';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';

const CHECKING = 'CHECKING';
const SAVING = 'SAVING';

const BankInfo = props => {
  const { bank } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const isBr =
    bank &&
    bank.bank &&
    bank.bank.address &&
    bank.bank.address.country === 'BR';

  const isAR =
    bank &&
    bank.bank &&
    bank.bank.address &&
    bank.bank.address.country === 'AR';

  const getCodes = code => {
    const { account_codes } = bank;
    const response =
      account_codes && account_codes.find(item => item.wire_code_type === code);
    return response ? response.value : '-';
  };

  const getAccountType = type => {
    switch (type) {
      case CHECKING:
        return intl.LABEL_ACCOUNT_TYPE_CHECKING;
      case SAVING:
        return intl.LABEL_ACCOUNT_TYPE_SAVING;
      default:
        return '-';
    }
  };

  return (
    <div className={styles.bankInfoItem}>
      <div className="row">
        <div className="col-12 col-md-6">
          <InfoLabelValue
            label={`${intl.LABEL_BANK_COUNTRY} / ${intl.LABEL_BANK_NAME}`}
            value={`
            ${bank.bank && bank.bank.address && bank.bank.address.country}
            /
            ${bank.bank && bank.bank.name}
            `}
          />
        </div>
        <div className="col-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_ACCOUNT_TYPE}
            value={getAccountType(bank.account_type)}
          />
        </div>
        <div className="col-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_ACCOUNT_NUMBER}
            value={bank.bank && bank.bank.bank_account_number}
          />
        </div>
      </div>
      {!isBr && (
        <div className="row">
          {getCodes('SWIFT') !== '-' && (
            <div className="col-12 col-md-4">
              <InfoLabelValue
                label={intl.LABEL_SWIFT_CODE}
                value={getCodes('SWIFT')}
              />
            </div>
          )}

          {getCodes('FULL_KEY') !== '-' && (
            <div className="col-12 col-md-4">
              <InfoLabelValue
                label={isAR ? intl.LABEL_CLAVE_BANCARIA : intl.LABEL_FULL_KEY}
                value={getCodes('FULL_KEY')}
              />
            </div>
          )}

          {getCodes('ABA') !== '-' && (
            <div className="col-12 col-md-4">
              <InfoLabelValue label={intl.LABEL_ABA} value={getCodes('ABA')} />
            </div>
          )}

          {getCodes('IBAN') !== '-' && (
            <div className="col-12 col-md-4">
              <InfoLabelValue
                label={intl.LABEL_IBAN}
                value={getCodes('IBAN')}
              />
            </div>
          )}

          {getCodes('CUIT') !== '-' && (
            <div className="col-12 col-md-4">
              <InfoLabelValue
                label={intl.LABEL_CUIT}
                value={getCodes('CUIT')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BankInfo;
