import * as consultationINTL from '../intl/consultation';
export const STATUS_CANCELED = 'Canceled';
export const STATUS_DONE = 'Done';
export const STATUS_NOT_DONE = 'Not Done';
export const STATUS_PENDING = 'Pending';
export const STATUS_IN_PROGRESS = 'In-Progress';

export const getStatus = status => {
  let text = '';
  switch (status) {
    case STATUS_CANCELED:
      text = 'CANCELED';
      break;
    case STATUS_DONE:
      text = 'DONE';
      break;
    case STATUS_NOT_DONE:
      text = 'NOT_DONE';
      break;
    case STATUS_PENDING:
      text = 'PENDING';
      break;
  }

  return text;
};

export const getConsultationStatusIntl = (status, language) => {
  switch (status) {
    case STATUS_DONE.toLowerCase():
      return consultationINTL.TITLE_APPOINTMENT_DONE[language];
    case STATUS_PENDING.toLowerCase():
      return consultationINTL.TITLE_APPOINTMENT_PENDING[language];
    case STATUS_CANCELED.toLowerCase():
      return consultationINTL.TITLE_APPOINTMENT_CANCELED[language];
    case STATUS_IN_PROGRESS.toLowerCase():
      return consultationINTL.TITLE_APPOINTMENT_IN_PROGRESS[language];
    default:
      return '';
  }
};
export const getConsultationStatus = status => {
  let text = '';
  switch (status) {
    case STATUS_CANCELED.toLowerCase():
      text = 'CANCELED';
      break;
    case STATUS_DONE.toLowerCase():
      text = 'DONE';
      break;
    case STATUS_IN_PROGRESS.toLowerCase():
      text = 'NOT_DONE';
      break;
    case STATUS_PENDING.toLowerCase():
      text = 'PENDING';
      break;
  }

  return text;
};
