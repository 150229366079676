export const TEXT_REQUEST_PROOF_PAYMENT_BUTTON = {
  en_US: 'Payment not received',
  pt_BR: 'Não recebi o pagamento',
  es_ES: 'No recibí mi pago',
};

export const TEXT_YES_PROOF_PAYMENT = {
  en_US: `Yes, I'm sure`,
  pt_BR: `Sim, tenho certeza`,
  es_ES: `Sí, estoy seguro`,
};

export const TEXT_NO_PROOF_PAYMENT = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};

export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};

export const SENDING_YOUR_PROOF_PAYMENT = {
  en_US: 'Requesting your proof of payment...',
  es_ES: 'Solicitando tu comprobante de pago...',
  pt_BR: 'Solicitando o seu comprovante de pagamento...',
};

export const TEXT_PROOF_PAYMENT_SUCCESS = {
  en_US:
    'We want to inform you that your complaint has been received correctly, and we apologize for any inconvenience related to your payment.',
  es_ES:
    'Queremos informarle que hemos recibido su reclamación correctamente y lamentamos cualquier inconveniente relacionado con su pago.',
  pt_BR:
    'Informamos que a sua reclamação foi recebida corretamente e lamentamos qualquer transtorno relacionado ao seu pagamento.',
};

export const SUBTITLE_PROOF_PAYMENT_SUCCESS = {
  en_US:
    'Our team will review the situation within a maximum of 15 (fifteen) business days. Please await contact by email from one of our representatives.',
  es_ES:
    'Nuestro equipo revisará la situación en un plazo máximo de 15 (quince) días hábiles. Le pedimos que espere el contacto por correo electrónico de uno de nuestros representantes.',
  pt_BR:
    'Nossa equipe revisará a situação em até 15 (quinze) dias úteis. Pedimos que aguarde o contato por e-mail de um dos nossos representantes.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_WITHIN_15_DAYS = {
  en_US: claimApprovedDate =>
    `We inform you that the deadline for crediting the payment of your claim is 15 business days after approval. Your claim was approved on ${claimApprovedDate}.`,
  es_ES: claimApprovedDate =>
    `Te informamos que el plazo para acreditar el pago de tu reclamo es de 15 días hábiles después de la aprobación. Tu reclamo fue aprobado el ${claimApprovedDate}.`,
  pt_BR: claimApprovedDate =>
    `Informamos que o prazo para crédito do pagamento do seu sinistro é de 15 dias úteis após a aprovação. O seu sinistro foi aprovado em ${claimApprovedDate}.`,
};

export const SUBTITLE_WITHIN_15_DAYS = {
  en_US: `Please wait for the specified period and check your bank statement.`,
  es_ES: `Espere el período especificado y verifica tu extracto bancario.`,
  pt_BR: `Pedimos que por favor aguarde o prazo informado e verifique seu extrato bancário.`,
};

export const TITLE_AFTER_15_DAYS = {
  en_US: claimNumber =>
    `We would like to inform you that payment for the claim ${claimNumber} should have already been made in your local currency and into the bank account you provided. Please check your bank statement. If you can't find the payment, do you want us to check its status?`,
  es_ES: claimNumber =>
    `Nos gustaría informarte que el pago del reclamo ${claimNumber} ya debería haberse realizado en tu moneda local y en la cuenta bancaria que proporcionó. Por favor revisa tu extracto bancario. Si no puedes encontrar el pago, ¿quieres que verifiquemos su estado?`,
  pt_BR: claimNumber =>
    `Gostaríamos de informar que o pagamento do sinistro ${claimNumber} já deveria ter sido efetuado em sua moeda local e na conta bancária que você forneceu. Por favor, verifique seu extrato bancário. Caso não encontre o pagamento, você quer que verifiquemos qual a situação dele?`,
};

export const SUBTITLE_AFTER_15_DAYS = {
  en_US:
    'This action generates an internal task and you will receive your proof of payment via email.',
  pt_BR:
    'Esta ação gera uma tarefa interna e você receberá seu comprovante de pagamento por e-mail.',
  es_ES:
    'Esta acción genera una tarea interna y recibirás tu comprobante de pago por correo electrónico.',
};
