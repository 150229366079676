import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const FALSELY_OPENED_EXPENSES = {
  en_US:
    'There were credits or other obligations falsely opened under your name? *',
  es_ES:
    '¿Hubo créditos u otras obligaciones abiertas falsamente a tu nombre? *',
  pt_BR:
    'Houve créditos ou outras obrigações falsamente abertas em seu nome? *',
};

export const AMOUNT_PAID = {
  en_US: 'Indicate the amount opened under your name *',
  es_ES: 'Indica el monto abiertos a tu nombre *',
  pt_BR: 'Indique o valor aberto em seu nome *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};
