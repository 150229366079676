import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import styles from '../BaggageDelay.module.scss';
import * as translations from './intl';
import * as constants from '../consts';
import { StateContext } from '../../../../../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import CheckBox from '../../../../../../../../components/CheckBox/CheckBox';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const { utils } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();

  return (
    <div className={`${styles.incidentArea}`}>
      {selectedCard.name === 'Infinite' ||
      selectedCard.name === 'Visa Infinite' ||
      selectedCard.name === 'Business Infinite' ||
      selectedCard.name === 'Corporate Infinite' ? (
        <label className={`${styles.labelTitle} ${styles[themes]}`}>
          {intl.DOCUMENTATION_PROVIDED}
        </label>
      ) : (
        ''
      )}

      {selectedCard.name === 'Signature' ||
      selectedCard.name === 'Visa Signature' ||
      selectedCard.name === 'Signature Business' ||
      selectedCard.name === 'Signature Corporate' ? (
        <label className={`${styles.labelTitle} ${styles[themes]}`}>
          {intl.DOCUMENTATION_PROVIDED_SIGNATURE}
        </label>
      ) : (
        ''
      )}
      <label className={`${styles.labelTitle} ${styles[themes]}`}>
        {intl.CARDHOLDER_DISCLAIMER}
      </label>

      <div className="row">
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_NAME_AFFECTED}
            type="text"
            name="affected_person.person.first_name"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            value={values.affected_person.person.first_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.first_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.first_name
            }
            maxLength="40"
          />
        </div>
        <div className={`col-12 col-sm-10 col-md-6`}>
          <Input
            label={intl.LABEL_LAST_NAME_AFFECTED}
            type="text"
            disabled={
              values.affected_person.relationship ===
              constants.CARD_HOLDER_VALUE
            }
            name="affected_person.person.last_name"
            value={values.affected_person.person.last_name}
            onChange={handleChange}
            touched={
              touched.affected_person &&
              touched.affected_person.person &&
              touched.affected_person.person.last_name
            }
            onBlur={handleBlur}
            error={
              errors.affected_person &&
              errors.affected_person.person &&
              errors.affected_person.person.last_name
            }
            maxLength="80"
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalArea;
