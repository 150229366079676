export const NUMBER_PERSON = {
  en_US: 'Passengers',
  pt_BR: 'Passageiros',
  es_ES: 'Pasajeros',
};

export const AGE_PERSON = {
  en_US: 'Passengers age',
  pt_BR: 'Idade dos passageiros',
  es_ES: 'Edad de las pasajeros',
};

export const LABEL_UNDER_70 = {
  en_US: 'Hasta 69 años',
  pt_BR: 'Hasta 69 años',
  es_ES: 'Hasta 69 años',
};

export const LABEL_OVER_70 = {
  en_US: '70 años o más',
  pt_BR: '70 años o más',
  es_ES: '70 años o más',
};

export const HOW_MANY_PASSENGERS = {
  en_US: 'How many passengers?',
  pt_BR: '¿Cuántos pasajeros?',
  es_ES: 'Número de pasajeros',
};
