export const listTimeZones = [
  {
    en_US: '(GMT-12:00) International Date Line West',
    es_ES: '(GMT-12:00) International Date Line West',
    timezone: 'Etc%2fGMT%2B12',
  },
  {
    en_US: '(GMT-11:00) Midway Island, Samoa',
    es_ES: '(GMT-11:00) Midway Island, Samoa',
    timezone: 'Pacific/Midway',
  },
  {
    en_US: '(GMT-10:00) Hawaii',
    es_ES: '(GMT-10:00) Hawaii',
    timezone: 'Pacific/Honolulu',
  },
  {
    en_US: '(GMT-09:00) Alaska',
    es_ES: '(GMT-09:00) Alaska',
    timezone: 'US/Alaska',
  },
  {
    en_US: '(GMT-08:00) Pacific Time (US & Canada)',
    es_ES: '(GMT-08:00) Pacific Time (US & Canada)',
    timezone: 'America/Los_Angeles',
  },
  {
    en_US: '(GMT-08:00) Tijuana, Baja California',
    es_ES: '(GMT-08:00) Tijuana, Baja California',
    timezone: 'America/Tijuana',
  },
  {
    en_US: '(GMT-07:00) Arizona',
    es_ES: '(GMT-07:00) Arizona',
    timezone: 'US/Arizona',
  },
  {
    en_US: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    es_ES: '(GMT-07:00) Chihuahua, La Paz, Mazatlan',
    timezone: 'America/Chihuahua',
  },
  {
    en_US: '(GMT-07:00) Mountain Time (US & Canada)',
    es_ES: '(GMT-07:00) Mountain Time (US & Canada)',
    timezone: 'US/Mountain',
  },
  {
    en_US: '(GMT-06:00) Central America',
    es_ES: '(GMT-06:00) Central America',
    timezone: 'America/Managua',
  },
  {
    en_US: '(GMT-06:00) Central Time (US & Canada)',
    es_ES: '(GMT-06:00) Central Time (US & Canada)',
    timezone: 'US/Central',
  },
  {
    en_US: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    es_ES: '(GMT-06:00) Guadalajara, Mexico City, Monterrey',
    timezone: 'America/Mexico_City',
  },
  {
    en_US: '(GMT-06:00) Saskatchewan',
    es_ES: '(GMT-06:00) Saskatchewan',
    timezone: 'Canada/Saskatchewan',
  },
  {
    en_US: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    es_ES: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco',
    timezone: 'America/Bogota',
  },
  {
    en_US: '(GMT-05:00) Eastern Time (US & Canada)',
    es_ES: '(GMT-05:00) Eastern Time (US & Canada)',
    timezone: 'US/Eastern',
  },
  {
    en_US: '(GMT-05:00) Indiana (East)',
    es_ES: '(GMT-05:00) Indiana (East)',
    timezone: 'US/East-Indiana',
  },
  {
    en_US: '(GMT-04:00) Atlantic Time (Canada)',
    es_ES: '(GMT-04:00) Atlantic Time (Canada)',
    timezone: 'Canada/Atlantic',
  },
  {
    en_US: '(GMT-04:00) Caracas, La Paz',
    es_ES: '(GMT-04:00) Caracas, La Paz',
    timezone: 'America/Caracas',
  },
  {
    en_US: '(GMT-04:00) Manaus',
    es_ES: '(GMT-04:00) Manaus',
    timezone: 'America/Manaus',
  },
  {
    en_US: '(GMT-04:00) Santiago',
    es_ES: '(GMT-04:00) Santiago',
    timezone: 'America/Santiago',
  },
  {
    en_US: '(GMT-03:30) Newfoundland',
    es_ES: '(GMT-03:30) Newfoundland',
    timezone: 'Canada/Newfoundland',
  },
  {
    en_US: '(GMT-03:00) Brasilia',
    es_ES: '(GMT-03:00) Brasilia',
    timezone: 'America/Sao_Paulo',
  },
  {
    en_US: '(GMT-03:00) Buenos Aires, Georgetown',
    es_ES: '(GMT-03:00) Buenos Aires, Georgetown',
    timezone: 'America/Argentina/Buenos_Aires',
  },
  {
    en_US: '(GMT-03:00) Greenland',
    es_ES: '(GMT-03:00) Greenland',
    timezone: 'America/Godthab',
  },
  {
    en_US: '(GMT-03:00) Montevideo',
    es_ES: '(GMT-03:00) Montevideo',
    timezone: 'America/Montevideo',
  },
  {
    en_US: '(GMT-02:00) Mid-Atlantic',
    es_ES: '(GMT-02:00) Mid-Atlantic',
    timezone: 'America/Noronha',
  },
  {
    en_US: '(GMT-01:00) Cape Verde Is.',
    es_ES: '(GMT-01:00) Cape Verde Is.',
    timezone: 'Atlantic/Cape_Verde',
  },
  {
    en_US: '(GMT-01:00) Azores',
    es_ES: '(GMT-01:00) Azores',
    timezone: 'Atlantic/Azores',
  },
  {
    en_US: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    es_ES: '(GMT+00:00) Casablanca, Monrovia, Reykjavik',
    timezone: 'Africa/Casablanca',
  },
  {
    en_US:
      '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    es_ES:
      '(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London',
    timezone: 'Etc/Greenwich',
  },
  {
    en_US: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    es_ES: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
    timezone: 'Europe/Amsterdam',
  },
  {
    en_US: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    es_ES: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
    timezone: 'Europe/Belgrade',
  },
  {
    en_US: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    es_ES: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris',
    timezone: 'Europe/Brussels',
  },
  {
    en_US: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    es_ES: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
    timezone: 'Europe/Sarajevo',
  },
  {
    en_US: '(GMT+01:00) West Central Africa',
    es_ES: '(GMT+01:00) West Central Africa',
    timezone: 'Africa/Lagos',
  },
  {
    en_US: '(GMT+02:00) Amman',
    es_ES: '(GMT+02:00) Amman',
    timezone: 'Asia/Amman',
  },
  {
    en_US: '(GMT+02:00) Athens, Bucharest, Istanbul',
    es_ES: '(GMT+02:00) Athens, Bucharest, Istanbul',
    timezone: 'Europe/Athens',
  },
  {
    en_US: '(GMT+02:00) Beirut',
    es_ES: '(GMT+02:00) Beirut',
    timezone: 'Asia/Beirut',
  },
  {
    en_US: '(GMT+02:00) Cairo',
    es_ES: '(GMT+02:00) Cairo',
    timezone: 'Africa/Cairo',
  },
  {
    en_US: '(GMT+02:00) Harare, Pretoria',
    es_ES: '(GMT+02:00) Harare, Pretoria',
    timezone: 'Africa/Harare',
  },
  {
    en_US: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    es_ES: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
    timezone: 'Europe/Helsinki',
  },
  {
    en_US: '(GMT+02:00) Jerusalem',
    es_ES: '(GMT+02:00) Jerusalem',
    timezone: 'Asia/Jerusalem',
  },
  {
    en_US: '(GMT+02:00) Minsk',
    es_ES: '(GMT+02:00) Minsk',
    timezone: 'Europe/Minsk',
  },
  {
    en_US: '(GMT+02:00) Windhoek',
    es_ES: '(GMT+02:00) Windhoek',
    timezone: 'Africa/Windhoek',
  },
  {
    en_US: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
    es_ES: '(GMT+03:00) Kuwait, Riyadh, Baghdad',
    timezone: 'Asia/Kuwait',
  },
  {
    en_US: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    es_ES: '(GMT+03:00) Moscow, St. Petersburg, Volgograd',
    timezone: 'Europe/Moscow',
  },
  {
    en_US: '(GMT+03:00) Nairobi',
    es_ES: '(GMT+03:00) Nairobi',
    timezone: 'Africa/Nairobi',
  },
  {
    en_US: '(GMT+03:00) Tbilisi',
    es_ES: '(GMT+03:00) Tbilisi',
    timezone: 'Asia/Tbilisi',
  },
  {
    en_US: '(GMT+03:30) Tehran',
    es_ES: '(GMT+03:30) Tehran',
    timezone: 'Asia/Tehran',
  },
  {
    en_US: '(GMT+04:00) Abu Dhabi, Muscat',
    es_ES: '(GMT+04:00) Abu Dhabi, Muscat',
    timezone: 'Asia/Muscat',
  },
  {
    en_US: '(GMT+04:00) Baku',
    es_ES: '(GMT+04:00) Baku',
    timezone: 'Asia/Baku',
  },
  {
    en_US: '(GMT+04:00) Yerevan',
    es_ES: '(GMT+04:00) Yerevan',
    timezone: 'Asia/Yerevan',
  },
  {
    en_US: '(GMT+04:30) Kabul',
    es_ES: '(GMT+04:30) Kabul',
    timezone: 'Asia/Kabul',
  },
  {
    en_US: '(GMT+05:00) Yekaterinburg',
    es_ES: '(GMT+05:00) Yekaterinburg',
    timezone: 'Asia/Yekaterinburg',
  },
  {
    en_US: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    es_ES: '(GMT+05:00) Islamabad, Karachi, Tashkent',
    timezone: 'Asia/Karachi',
  },
  {
    en_US: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    es_ES: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi',
    timezone: 'Asia/Calcutta',
  },
  {
    en_US: '(GMT+05:30) Sri Jayawardenapura',
    es_ES: '(GMT+05:30) Sri Jayawardenapura',
    timezone: 'Asia/Calcutta',
  },
  {
    en_US: '(GMT+05:45) Kathmandu',
    es_ES: '(GMT+05:45) Kathmandu',
    timezone: 'Asia/Katmandu',
  },
  {
    en_US: '(GMT+06:00) Almaty, Novosibirsk',
    es_ES: '(GMT+06:00) Almaty, Novosibirsk',
    timezone: 'Asia/Almaty',
  },
  {
    en_US: '(GMT+06:00) Astana, Dhaka',
    es_ES: '(GMT+06:00) Astana, Dhaka',
    timezone: 'Asia/Dhaka',
  },
  {
    en_US: '(GMT+06:30) Yangon (Rangoon)',
    es_ES: '(GMT+06:30) Yangon (Rangoon)',
    timezone: 'Asia/Rangoon',
  },
  {
    en_US: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    es_ES: '(GMT+07:00) Bangkok, Hanoi, Jakarta',
    timezone: 'Asia/Bangkok',
  },
  {
    en_US: '(GMT+07:00) Krasnoyarsk',
    es_ES: '(GMT+07:00) Krasnoyarsk',
    timezone: 'Asia/Krasnoyarsk',
  },
  {
    en_US: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    es_ES: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
    timezone: 'Asia/Hong_Kong',
  },
  {
    en_US: '(GMT+08:00) Kuala Lumpur, Singapore',
    es_ES: '(GMT+08:00) Kuala Lumpur, Singapore',
    timezone: 'Asia/Kuala_Lumpur',
  },
  {
    en_US: '(GMT+08:00) Irkutsk, Ulaan Bataar',
    es_ES: '(GMT+08:00) Irkutsk, Ulaan Bataar',
    timezone: 'Asia/Irkutsk',
  },
  {
    en_US: '(GMT+08:00) Perth',
    es_ES: '(GMT+08:00) Perth',
    timezone: 'Australia/Perth',
  },
  {
    en_US: '(GMT+08:00) Taipei',
    es_ES: '(GMT+08:00) Taipei',
    timezone: 'Asia/Taipei',
  },
  {
    en_US: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    es_ES: '(GMT+09:00) Osaka, Sapporo, Tokyo',
    timezone: 'Asia/Tokyo',
  },
  {
    en_US: '(GMT+09:00) Seoul',
    es_ES: '(GMT+09:00) Seoul',
    timezone: 'Asia/Seoul',
  },
  {
    en_US: '(GMT+09:00) Yakutsk',
    es_ES: '(GMT+09:00) Yakutsk',
    timezone: 'Asia/Yakutsk',
  },
  {
    en_US: '(GMT+09:30) Adelaide',
    es_ES: '(GMT+09:30) Adelaide',
    timezone: 'Australia/Adelaide',
  },
  {
    en_US: '(GMT+09:30) Darwin',
    es_ES: '(GMT+09:30) Darwin',
    timezone: 'Australia/Darwin',
  },
  {
    en_US: '(GMT+10:00) Brisbane',
    es_ES: '(GMT+10:00) Brisbane',
    timezone: 'Australia/Brisbane',
  },
  {
    en_US: '(GMT+10:00) Canberra, Melbourne, Sydney',
    es_ES: '(GMT+10:00) Canberra, Melbourne, Sydney',
    timezone: 'Australia/Canberra',
  },
  {
    en_US: '(GMT+10:00) Hobart',
    es_ES: '(GMT+10:00) Hobart',
    timezone: 'Australia/Hobart',
  },
  {
    en_US: '(GMT+10:00) Guam, Port Moresby',
    es_ES: '(GMT+10:00) Guam, Port Moresby',
    timezone: 'Pacific/Guam',
  },
  {
    en_US: '(GMT+10:00) Vladivostok',
    es_ES: '(GMT+10:00) Vladivostok',
    timezone: 'Asia/Vladivostok',
  },
  {
    en_US: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    es_ES: '(GMT+11:00) Magadan, Solomon Is., New Caledonia',
    timezone: 'Asia/Magadan',
  },
  {
    en_US: '(GMT+12:00) Auckland, Wellington',
    es_ES: '(GMT+12:00) Auckland, Wellington',
    timezone: 'Pacific/Auckland',
  },
  {
    en_US: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    es_ES: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.',
    timezone: 'Pacific/Fiji',
  },
  {
    en_US: "(GMT+13:00) Nuku'alofa",
    es_ES: "(GMT+13:00) Nuku'alofa",
    timezone: 'Pacific/Tongatapu',
  },
];
