import {
  DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE,
  removeCountryFromList,
} from './countries';

export const pt_countries = `
<option value="AF"> Afeganistão </option>
<option value="ZA"> África do Sul </option>
<option value="AL"> Albânia </option>
<option value="DE"> Alemanha </option>
<option value="AD"> Andorra </option>
<option value="AO"> Angola </option>
<option value="AI"> Anguila </option>
<option value="TF"> Antilhas Francesas </option>
<option value="AN"> Antilhas Holandesas </option>
<option value="AQ"> Antártica </option>
<option value="AG"> Antígua e Barbuda </option>
<option value="AR"> Argentina </option>
<option value="DZ"> Argélia </option>
<option value="AM"> Armênia </option>
<option value="AW"> Aruba </option>
<option value="SA"> Arábia Saudita </option>
<option value="AC"> Ascension </option>
<option value="AU"> Austrália </option>
<option value="AT"> Áustria </option>
<option value="AZ"> Azerbaijão </option>
<option value="BS"> Bahamas </option>
<option value="BH"> Bahrein </option>
<option value="BD"> Bangladesh </option>
<option value="BB"> Barbados </option>
<option value="BZ"> Belize </option>
<option value="BJ"> Benim </option>
<option value="BM"> Bermudas </option>
<option value="BY"> Bielorrússia </option>
<option value="BO"> Bolívia </option>
<option value="BW"> Botsuana </option>
<option value="BR"> Brasil </option>
<option value="BN"> Brunei </option>
<option value="BG"> Bulgária </option>
<option value="BF"> Burkina Faso </option>
<option value="BI"> Burundi </option>
<option value="BT"> Butão </option>
<option value="BE"> Bélgica </option>
<option value="BA"> Bósnia e Herzegovina </option>
<option value="CV"> Cabo Verde </option>
<option value="CM"> Camarões </option>
<option value="KH"> Camboja </option>
<option value="CA"> Canadá </option>
<option value="QA"> Catar </option>
<option value="KZ"> Cazaquistão </option>
<option value="TD"> Chade </option>
<option value="CL"> Chile </option>
<option value="CN"> China </option>
<option value="CY"> Chipre </option>
<option value="CO"> Colômbia </option>
<option value="CC"> Ilhas Cocos </option>
<option value="KM"> Comores </option>
<option value="CG"> Congo </option>
<option value="KR"> Coréia do Sul </option>
<option value="CR"> Costa Rica </option>
<option value="CI"> Costa do Marfim </option>
<option value="HR"> Croácia </option>
<option value="CU"> Cuba </option>
<option value="CW"> Curação </option>
<option value="DK"> Dinamarca </option>
<option value="DJ"> Djubouti </option>
<option value="DM"> Dominica </option>
<option value="EG"> Egito </option>
<option value="SV"> El Salvador </option>
<option value="AE"> Emirados Árabes Unidos </option>
<option value="EC"> Equador </option>
<option value="ER"> Eritréia </option>
<option value="SK"> Eslováquia </option>
<option value="SI"> Eslovênia </option>
<option value="ES"> Espanha </option>
<option value="FM"> Estados Federados da Micronésia </option>
<option value="US"> Estados Unidos </option>
<option value="EE"> Estônia </option>
<option value="ET"> Etiópia </option>
<option value="FJ"> Fiji </option>
<option value="PH"> Filipinas </option>
<option value="FI"> Finlândia </option>
<option value="FR"> França </option>
<option value="GA"> Gabão </option>
<option value="GH"> Gana </option>
<option value="GE"> Geórgia </option>
<option value="GI"> Gibraltar </option>
<option value="GD"> Granada </option>
<option value="GL"> Groenlândia </option>
<option value="GR"> Grécia </option>
<option value="GP"> Guadalupe </option>
<option value="GU"> Guam </option>
<option value="GT"> Guatemala </option>
<option value="GY"> Guiana </option>
<option value="GF"> Guiana Francesa </option>
<option value="GN"> Guiné </option>
<option value="GQ"> Guiné Equatorial </option>
<option value="GW"> Guiné-Bissau </option>
<option value="GM"> Gâmbia </option>
<option value="HT"> Haiti </option>
<option value="NL"> Holanda </option>
<option value="HN"> Honduras </option>
<option value="HK"> Hong Kong </option>
<option value="HU"> Hungria </option>
<option value="IM"> Ilha de Man </option>
<option value="CX"> Ilha de Natal </option>
<option value="EI"> Ilha de Páscoa </option>
<option value="UM"> Ilha Midway </option>
<option value="NF"> Ilha Norfolk </option>
<option value="RE"> Ilha Reunião </option>
<option value="UM"> Ilha Wake </option>
<option value="KY"> Ilhas Cayman </option>
<option value="CK"> Ilhas Cook </option>
<option value="FK"> Ilhas Falkland </option>
<option value="FO"> Ilhas Feroe </option>
<option value="MP"> Ilhas Mariana </option>
<option value="MH"> Ilhas Marshall </option>
<option value="YT"> Ilhas Mayotte </option>
<option value="SB"> Ilhas Salomão </option>
<option value="TC"> Ilhas Turks e Caicos </option>
<option value="VI"> Ilhas Virgens Americanas </option>
<option value="VG"> Ilhas Virgens Britânicas </option>
<option value="WF"> Ilhas Wallis e Futuna </option>
<option value="IN"> Índia </option>
<option value="ID"> Indonésia </option>
<option value="IQ"> Iraque </option>
<option value="IE"> Irlanda </option>
<option value="IS"> Islândia </option>
<option value="IL"> Israel </option>
<option value="IT"> Itália </option>
<option value="YE"> Iêmen </option>
<option value="JM"> Jamaica </option>
<option value="JP"> Japão </option>
<option value="JO"> Jordânia </option>
<option value="KI"> Kitibati </option>
<option value="XK"> Kosovo </option>
<option value="KW"> Kuwait </option>
<option value="LA"> Laos </option>
<option value="LS"> Lesoto </option>
<option value="LV"> Letônia </option>
<option value="LR"> Libéria </option>
<option value="LI"> Liechtenstein </option>
<option value="LT"> Lituânia </option>
<option value="LU"> Luxemburgo </option>
<option value="LB"> Líbano </option>
<option value="LY"> Líbia </option>
<option value="MO"> Macau </option>
<option value="MK"> Macedônia </option>
<option value="MG"> Madagascar </option>
<option value="MV"> Maldivas </option>
<option value="ML"> Mali </option>
<option value="MT"> Malta </option>
<option value="MY"> Malásia </option>
<option value="MW"> Maláui </option>
<option value="MA"> Marrocos </option>
<option value="MQ"> Martinica </option>
<option value="MR"> Mauritânia </option>
<option value="MU"> Maurício </option>
<option value="MD"> Moldávia </option>
<option value="MN"> Mongólia </option>
<option value="MS"> Montserrat </option>
<option value="MZ"> Moçambique </option>
<option value="MM"> Myanmar </option>
<option value="MX"> México </option>
<option value="MC"> Mônaco </option>
<option value="NA"> Namíbia </option>
<option value="NR"> Nauru </option>
<option value="NP"> Nepal </option>
<option value="NI"> Nicarágua </option>
<option value="NE"> Niger </option>
<option value="NG"> Nigéria </option>
<option value="NU"> Niue </option>
<option value="NO"> Noruega </option>
<option value="NC"> Nova Caledônia </option>
<option value="NZ"> Nova Zelândia </option>
<option value="OM"> Omã </option>
<option value="PW"> Palau </option>
<option value="PA"> Panamá </option>
<option value="PG"> Papua-Nova Guiné </option>
<option value="PK"> Paquistão </option>
<option value="PY"> Paraguai </option>
<option value="PE"> Peru </option>
<option value="PF"> Polinésia Francesa </option>
<option value="PL"> Polônia </option>
<option value="PR"> Porto Rico </option>
<option value="PT"> Portugal </option>
<option value="KG"> Quirguistão </option>
<option value="KE"> Quênia </option>
<option value="GB"> Reino Unido </option>
<option value="CF"> República Centro-Africana </option>
<option value="DO"> República Dominicana </option>
<option value="CZ"> República Tcheca </option>
<option value="RO"> Romênia </option>
<option value="RW"> Ruanda </option>
<option value="RU"> Rússia </option>
<option value="SW"> Samoa </option>
<option value="AS"> Samoa americana </option>
<option value="SM"> San Marino </option>
<option value="SH"> Santa Helena </option>
<option value="LC"> Santa Lúcia </option>
<option value="SN"> Senegal </option>
<option value="SL"> Serra Leoa </option>
<option value="SC"> Seychelles </option>
<option value="SG"> Singapura </option>
<option value="SO"> Somália </option>
<option value="LK"> Sri Lanka </option>
<option value="SZ"> Suazilândia </option>
<option value="SR"> Suriname </option>
<option value="SE"> Suécia </option>
<option value="CH"> Suíça </option>
<option value="KN"> São Cristóvão e Nevis </option>
<option value="MF"> São Martinho </option>
<option value="PM"> São Pedro e Miquelão </option>
<option value="ST"> São Tomé e Príncipe </option>
<option value="VC"> São Vicente e Granadinas </option>
<option value="RS"> Sérvia </option>
<option value="TH"> Tailândia </option>
<option value="TW"> Taiwan </option>
<option value="TJ"> Tajiquistão </option>
<option value="TZ"> Tanzânia </option>
<option value="TG"> Togo </option>
<option value="TO"> Tonga </option>
<option value="TK"> Toquelau </option>
<option value="TT"> Trindade e Tobago </option>
<option value="TN"> Tunísia </option>
<option value="TM"> Turcomenistão </option>
<option value="TR"> Turquia </option>
<option value="TV"> Tuvalu </option>
<option value="UA"> Ucrania </option>
<option value="UG"> Uganda </option>
<option value="UY"> Uruguai </option>
<option value="UZ"> Uzbequistão </option>
<option value="VU"> Vanuatu </option>
<option value="VA"> Vaticano </option>
<option value="VE"> Venezuela </option>
<option value="VN"> Vietnã </option>
<option value="YU"> Yugoslavia </option>
<option value="ZR"> Zaire </option>
<option value="ZW"> Zimbabwe </option>
<option value="ZM"> Zâmbia </option>`;

export const pt_destinationCountryOptions = () =>
  removeCountryFromList(
    pt_countries,
    DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE
  );
