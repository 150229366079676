export const TEXT_CONTACT_DETAILS = {
  en_US: 'Personal details',
  es_ES: 'Información personal',
  pt_BR: 'Informações pessoais',
};

export const BTN_SAVE = {
  en_US: 'Save',
  pt_BR: 'Salvar',
  es_ES: 'Guardar',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const TEXT_EDIT_CONTACT_DETAILS = {
  en_US: 'Edit personal details',
  pt_BR: 'Editar informações pessoais',
  es_ES: 'Editar información personal',
};

export const LOADING_SAVING_CONTACT_DETAILS = {
  en_US: 'Saving personal details...',
  pt_BR: 'Salvando informações pessoais...',
  es_ES: 'Guardando información personal...',
};
