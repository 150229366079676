export const en_US = 'en_US';
export const pt_BR = 'pt_BR';
export const es_ES = 'es_ES';

export const HTMLTag = {
  en_US: 'en-US',
  pt_BR: 'pt-BR',
  es_ES: 'es-ES',
};

export const en_US_HTML_TAG = 'en-US';
export const pt_BR_HTML_TAG = 'pt-BR';
export const es_ES_HTML_TAG = 'es-ES';

export const listIdioms = [
  {
    initials: 'en_US',
    en_US: 'English',
    pt_BR: 'Inglês',
    es_ES: 'Inglés',
  },
  {
    initials: 'pt_BR',
    en_US: 'Portuguese',
    pt_BR: 'Português',
    es_ES: 'Portugués',
  },
  {
    initials: 'es_ES',
    en_US: 'Spanish',
    pt_BR: 'Espanhol',
    es_ES: 'Español',
  },
];
