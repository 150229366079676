import React from 'react';
import { checkGeneralInfoChange } from '../../../../Generate/ClaimFillForm/Form/submit/checkGeneralInfoChange';
import { redirect } from './redirect';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import VisaOnlineMedic from '../../../VisaOnlineMedic/VisaOnlineMedic';
import {
  amosConsultationsService,
  amosVideocallsService,
} from '../../../../../../services';
import {
  ConsultationPhoneType,
  ConsultationVideoType,
} from '../../../../../../utils/consultationCategory';

export const createConsultationRequestBody = async formValues => {
  const patient_files =
    formValues.appointment.files.length > 0 ? formValues.appointment.files : [];

  return {
    channel: 'phone',
    language: formValues.consultation.language,
    specialty: 'general',
    time_zone:
      formValues.appointment.type === ConsultationPhoneType
        ? formValues.consultation.timezone
        : formValues.appointment.timezone,
    country: formValues.consultation.country,
    patient: {
      name: formValues.patient.name,
      surname: formValues.patient.surname,
      birth_date: formValues.patient.birth_date,
      age: formValues.patient.age,
      phone_prefix: formValues.consultation.phone_prefix,
      phone: formValues.consultation.phone,
    },
    patient_files,
    consultation: formValues.appointment.call_reason,
  };
};

export const createVideocallRequestBody = async formValues => {
  const patient_files =
    formValues.appointment.files.length > 0 ? formValues.appointment.files : [];

  return {
    language: formValues.consultation.language,
    specialty: 'general',
    date: formValues.appointment.date,
    time: formValues.appointment.time,
    time_zone:
      formValues.appointment.type === ConsultationPhoneType
        ? formValues.consultation.timezone
        : formValues.appointment.timezone,
    country: formValues.consultation.country,
    patient: {
      name: formValues.patient.name,
      surname: formValues.patient.surname,
      birth_date: formValues.patient.birth_date,
      age: formValues.patient.age,
      phone_prefix: formValues.consultation.phone_prefix,
      phone: formValues.consultation.phone,
    },
    patient_files,
    call_reason: formValues.appointment.call_reason,
    scheduled: true,
    on_demand: false,
  };
};

export const submitTeleconsultation = async props => {
  const { actions, intl } = props;
  try {
    await checkGeneralInfoChange(props);
    actions.modal.showModal(false, true, <VisaOnlineMedic />, true, false);
    redirect(props);
  } catch (error) {
    if (error) {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.TEXT_ERROR} />,
        true,
        false
      );
    }
  }
};

const showErrorModal = (message, actions) => {
  actions.modal.showModal(
    '',
    true,
    <ModalInformation
      type="error"
      message={message}
      clickBtn={actions.modal.closeModal}
    />
  );
};

export const handleSubmitPhoneForm = async props => {
  const { values, loadedData, intl, actions, trackEventUserAction, history } =
    props;
  try {
    trackEventUserAction('#### VMO ### Phone - Submit do formulário', values);
    actions.loadingFullScreen.showLoadingFullScreen(
      intl.TITLE_SENDING_YOUR_APPOINTMENT
    );

    const body = await createConsultationRequestBody(values);
    const customer = loadedData.cardHolder;
    trackEventUserAction('#### VMO ### Phone - Criando chamada telefônica', {
      customer,
      body,
    });

    await amosConsultationsService.create(customer, body);

    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      '',
      false,
      <ModalInformation
        type="success"
        message={intl.TITLE_SUCCESS_REQUEST}
        clickBtn={() => {
          actions.modal.closeModal();
          history.push(
            `/visa-online-medic/previous-appointments?filter=${ConsultationPhoneType}`
          );
        }}
      />
    );
    trackEventUserAction(
      '#### VMO ### Phone - Chamada telefônica criada',
      true
    );
  } catch (error) {
    trackEventUserAction(
      '#### VMO ### Phone - Erro ao criar chamada telefônica',
      error
    );

    actions.loadingFullScreen.hideLoadingFullScreen();
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.data &&
      error.response.data.data.code === 150
    ) {
      showErrorModal(intl.MESSAGE_NOT_AVAILABLE_DATE_TIME, actions);
      return true;
    } else if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 413
    ) {
      showErrorModal(intl.TITLE_ERROR_FILESIZE, actions);
      return false;
    } else {
      showErrorModal(intl.TITLE_UNEXPECTED_ERROR_OCCURRED, actions);
      return false;
    }
  }
};

export const handleSubmitVideoForm = async props => {
  const { values, loadedData, intl, actions, trackEventUserAction, history } =
    props;
  try {
    trackEventUserAction('#### VMO ### Video - Submit do formulário', values);

    actions.loadingFullScreen.showLoadingFullScreen(
      intl.TITLE_SENDING_YOUR_APPOINTMENT
    );

    const body = await createVideocallRequestBody(values);

    const customer = loadedData.cardHolder;
    trackEventUserAction('#### VMO ### Video - Criando videochamada', {
      customer,
      body,
    });

    await amosVideocallsService.create(customer, body);

    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      '',
      false,
      <ModalInformation
        type="success"
        message={intl.TITLE_SUCCESS_REQUEST}
        clickBtn={() => {
          actions.modal.closeModal();
          history.push(
            `/visa-online-medic/previous-appointments?filter=${ConsultationVideoType}`
          );
        }}
      />
    );

    trackEventUserAction('#### VMO ### Video - Videochamada criada', true);
    return false;
  } catch (error) {
    trackEventUserAction('#### VMO ### Video - Erro ao criar videochamada', {
      error,
      data: error?.response?.data,
      status: error?.response?.status,
    });

    actions.loadingFullScreen.hideLoadingFullScreen();
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.data.code === 150
    ) {
      showErrorModal(intl.MESSAGE_NOT_AVAILABLE_DATE_TIME, actions);
      return true;
    } else if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 413
    ) {
      showErrorModal(intl.TITLE_ERROR_FILESIZE, actions);
      return false;
    } else {
      showErrorModal(intl.TITLE_UNEXPECTED_ERROR_OCCURRED, actions);
      return false;
    }
  }
};
//https://visa.avizia.com/axa/sso?language=pt-BR&patient=1&id=ZTVmNjgzZDctZTA2Zi00MDM1LWE2MTYtMjRjNGNkMTViZTdiLTAwMQ==
