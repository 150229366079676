import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import * as constants from '../../../../Generate/ClaimFillForm/Form/IncidentInformation/PurchaseProtection/consts';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [affectedPerson, setAffectedPerson] = useState({});

  useEffect(() => {
    const affectedPerson = (claimDetails && claimDetails.affected_person) || {};
    setAffectedPerson(affectedPerson);
  }, [claimDetails]);

  const cardholderRelationshipLabel = {
    [constants.CARD_HOLDER_VALUE]: intl.TEXT_CH,
    [constants.SPOUSE_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_SPOUSE,
    [constants.DEPENDENT_VALUE]: intl.TEXT_RELATIONSHIP_DEPENDENT_CHILD,
    [constants.OTHER_VALUE]: intl.TEXT_RELATIONSHIP_VALUE_OTHER,
  };

  const getCardholderRelationship = () =>
    affectedPerson && affectedPerson.relationship
      ? cardholderRelationshipLabel[affectedPerson.relationship]
      : '-';

  const getAffectedPersonName = () =>
    affectedPerson && affectedPerson.person && affectedPerson.person.first_name
      ? affectedPerson.person.first_name
      : '-';

  const getAffectedPersonLastName = () =>
    affectedPerson && affectedPerson.person && affectedPerson.person.last_name
      ? affectedPerson.person.last_name
      : '-';

  const getAffectedPersonComment = () =>
    (affectedPerson && affectedPerson.comment) || '-';

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={getCardholderRelationship()}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={getAffectedPersonName()}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={getAffectedPersonLastName()}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        {affectedPerson.relationship === constants.OTHER_VALUE && (
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={getAffectedPersonComment()}
          />
        )}
      </div>
    </>
  );
};

export default PersonalArea;
