export const PRODUCT_CODE = {
  PLATINUM: 'N',
  SIGNATURE: 'C',
  INFINITE: 'I',
  GOLD: 'P',
  CLASSIC: 'F',
};

export const LIST_ELIGIBLE_CARDS_CROSSELL = ['CLASSIC', 'GOLD'];

export const LIST_ELIGIBLE_CARDS_UPSELL = ['PLATINUM', 'INFINITE', 'SIGNATURE'];
