export default (env, axios, errorHandler) => {
  return {
    getUserNotifications: async (customer_id, idiom, querie = '') => {
      const url =
        `/api/specific/visagateway/v1/customers/${customer_id}/notifications` +
        querie;
      const headers = {
        'Accept-Language': idiom,
      };
      return errorHandler(axios.get(url, { headers }), 'getUserNotifications', {
        customer_id,
        url,
      });
    },
    getUserNotificationDetail: async (customer_id, id, idiom) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/notifications/${id}`;
      const headers = {
        'Accept-Language': idiom,
      };
      return errorHandler(axios.get(url, { headers }), 'getUserNotifications', {
        customer_id,
        url,
      });
    },
    updateUserNotification: async (customer_id, id, data) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/notifications/${id}`;
      return errorHandler(axios.patch(url, data), 'updateUserNotification', {
        customer_id,
        url,
        id,
        data,
      });
    },
    deleteUserNotification: async (customer_id, id) => {
      const url = `/api/specific/visagateway/v1/customers/${customer_id}/notifications/${id}`;
      return errorHandler(axios.delete(url), 'deleteUserNotification', {
        customer_id,
        url,
        id,
      });
    },
  };
};
