export {
  CREATE_TRIP_ASSISTANCE,
  CONSULT_TRIP_ASSISTANCE,
} from '../../../../intl/travels';

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const ACCESS = {
  en_US: 'Access',
  pt_BR: 'Acessar',
  es_ES: 'Acceder',
};

export const GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  pt_BR: 'Gerar bilhete',
  es_ES: 'Generar un certificado',
};

export const VIEW_RESUME = {
  en_US: '',
  pt_BR: 'Ver resumo',
  es_ES: '',
};

export const VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const SUBSCRIBE_EXECUTIVE_AIRPORT_TRANSPORTATION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Inscríbete al beneficio',
};

export const ACCESS_VISA_ONLINE_MEDIC = {
  en_US: 'Access Visa Online Medic ',
  pt_BR: 'Acessar Visa Médico Online ',
  es_ES: 'Acceder Visa Médico Online ',
};

export const ACCESS_VISA_NAL = {
  en_US: 'Create an appointment',
  pt_BR: 'Criar um agendamento',
  es_ES: 'Crear una cita',
};

export const NAL_CONSULT_APPOINTMENT = {
  en_US: 'Consult appointments',
  pt_BR: 'Consultar agendamentos',
  es_ES: 'Consultar citas',
};

export const GENERATE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};
export const TEXT_EMPTY_BENEFITS = {
  en_US: 'No benefits available',
  pt_BR: 'Nenhum benefício disponível',
  es_ES: 'No hay benefício disponible',
};

export const OR = {
  en_US: ' or ',
  es_ES: ' o ',
  pt_BR: ' ou ',
};

export const UPDATE_BENEFIT = {
  en_US: 'Upgrade your coverage with AXA',
  pt_BR: 'Aumente sua cobertura com AXA',
  es_ES: 'Mejora tu cobertura con AXA',
};

export const BUTTON_PURCHASE = {
  en_US: 'Purchase it',
  pt_BR: 'Compre',
  es_ES: 'Cómpralo',
};

export const TITLE_DESCRIPTION_UPSELL = {
  en_US: 'Why improve your coverage?',
  pt_BR: 'Por que melhorar sua cobertura?',
  es_ES: '¿Porque mejorar tu cobertura?',
};

export const CONTENT_DESCRIPTION_UPSELL = {
  en_US:
    'Improving your coverage will give you more security and more benefits that your current card cannot provide.',
  pt_BR:
    'Melhorar sua cobertura vai te dar mais segurança e mais vantagens que seu cartão atual não pode providenciar.',
  es_ES:
    'Mejorar tu cobertura te dará más seguridad y más beneficios que tu tarjeta actual no puede brindarte.',
};

export const LABEL_APPOINTMENTS = {
  en_US: 'Your appointments',
  es_ES: 'Tus citas',
  pt_BR: 'Seus agendamentos',
};

export const LABEL_CONSULTATION = {
  en_US: 'Request a consultation',
  es_ES: 'Solicita una consulta',
  pt_BR: 'Solicite uma consulta',
};

export const LABEL_VIDEO_CONSULTATION = {
  en_US: 'Request a video consultation',
  es_ES: 'Solicita una consulta por video',
  pt_BR: 'Solicite uma consulta por vídeo',
};

export const LABEL_PHONE_CALL = {
  en_US: 'Request a phone call',
  es_ES: 'Solicita una llamada telefónica',
  pt_BR: 'Solicite uma ligação',
};

export const NEW_TAB = {
  en_US: 'new tab',
  pt_BR: 'nova aba',
  es_ES: 'nueva pestaña',
};
