import React, { useContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import styles from './Banner.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

/* AFFLUENT */
import BANNER_MARKETING_PT_AFFLUENT from '../../../assets/images/marketing/banner_marketing/1920-850/affluent/banner-pt.png';
import BANNER_MARKETING_ES_AFFLUENT from '../../../assets/images/marketing/banner_marketing/1920-850/affluent/banner-es.png';
import BANNER_MARKETING_EN_AFFLUENT from '../../../assets/images/marketing/banner_marketing/1920-850/affluent/banner-eng.png';
import BANNER_MARKETING_PT_AFFLUENT_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/affluent/banner-pt.png';
import BANNER_MARKETING_ES_AFFLUENT_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/affluent/banner-es.png';
import BANNER_MARKETING_EN_AFFLUENT_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/affluent/banner-eng.png';
import BANNER_MARKETING_PT_AFFLUENT_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/affluent/banner-pt.png';
import BANNER_MARKETING_ES_AFFLUENT_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/affluent/banner-es.png';
import BANNER_MARKETING_EN_AFFLUENT_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/affluent/banner-eng.png';
import BANNER_MARKETING_PT_AFFLUENT_600 from '../../../assets/images/marketing/banner_marketing/600-820/affluent/banner-pt.png';
import BANNER_MARKETING_ES_AFFLUENT_600 from '../../../assets/images/marketing/banner_marketing/600-820/affluent/banner-es.png';
import BANNER_MARKETING_EN_AFFLUENT_600 from '../../../assets/images/marketing/banner_marketing/600-820/affluent/banner-eng.png';
import BANNER_MARKETING_PT_AFFLUENT_400 from '../../../assets/images/marketing/banner_marketing/400-600/affluent/banner-pt.png';
import BANNER_MARKETING_ES_AFFLUENT_400 from '../../../assets/images/marketing/banner_marketing/400-600/affluent/banner-es.png';
import BANNER_MARKETING_EN_AFFLUENT_400 from '../../../assets/images/marketing/banner_marketing/400-600/affluent/banner-eng.png';
/* MASTER */
import BANNER_MARKETING_PT_MASTER from '../../../assets/images/marketing/banner_marketing/1920-850/master/banner-pt.png';
import BANNER_MARKETING_ES_MASTER from '../../../assets/images/marketing/banner_marketing/1920-850/master/banner-es.png';
import BANNER_MARKETING_EN_MASTER from '../../../assets/images/marketing/banner_marketing/1920-850/master/banner-eng.png';
import BANNER_MARKETING_PT_MASTER_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/master/banner-pt.png';
import BANNER_MARKETING_ES_MASTER_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/master/banner-es.png';
import BANNER_MARKETING_EN_MASTER_1440 from '../../../assets/images/marketing/banner_marketing/1440-824/master/banner-eng.png';
import BANNER_MARKETING_PT_MASTER_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/master/banner-pt.png';
import BANNER_MARKETING_ES_MASTER_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/master/banner-es.png';
import BANNER_MARKETING_EN_MASTER_1000 from '../../../assets/images/marketing/banner_marketing/1000-820/master/banner-eng.png';
import BANNER_MARKETING_PT_MASTER_600 from '../../../assets/images/marketing/banner_marketing/600-820/master/banner-pt.png';
import BANNER_MARKETING_ES_MASTER_600 from '../../../assets/images/marketing/banner_marketing/600-820/master/banner-es.png';
import BANNER_MARKETING_EN_MASTER_600 from '../../../assets/images/marketing/banner_marketing/600-820/master/banner-eng.png';
import BANNER_MARKETING_PT_MASTER_400 from '../../../assets/images/marketing/banner_marketing/400-600/master/banner-pt.png';
import BANNER_MARKETING_ES_MASTER_400 from '../../../assets/images/marketing/banner_marketing/400-600/master/banner-es.png';
import BANNER_MARKETING_EN_MASTER_400 from '../../../assets/images/marketing/banner_marketing/400-600/master/banner-eng.png';

import Slider from 'react-slick';
import { checkAccessDownloadApp } from '../../../utils/downloadAppAccess';
import PlayIcon from '../../../assets/icons/PlayIcon';
import PauseIcon from '../../../assets/icons/PauseIcon';

let carouselRef = '';

const Banner = props => {
  const { translate, idiom, country } = useContext(IntlContext);
  const { getGlobalTheme, getImageByName } = useContext(ThemeContext);
  const [dragging, setDragging] = useState(false);
  const [play, setPlay] = useState(true);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const content = getImageByName('bannerHome');

  const handleBeforeChange = () => {
    setDragging(true);
  };
  const handleAfterChange = () => {
    setDragging(false);
  };

  const handlePlay = () => {
    setPlay(() => {
      carouselRef.slickPlay();
      return true;
    });
  };

  const handlePause = () => {
    setPlay(() => {
      carouselRef.slickPause();
      return false;
    });
  };

  const image_marketing_mobile = {
    'theme-afluent': {
      1920: {
        pt_BR: BANNER_MARKETING_PT_AFFLUENT,
        es_ES: BANNER_MARKETING_ES_AFFLUENT,
        en_US: BANNER_MARKETING_EN_AFFLUENT,
      },
      1440: {
        pt_BR: BANNER_MARKETING_PT_AFFLUENT_1440,
        es_ES: BANNER_MARKETING_ES_AFFLUENT_1440,
        en_US: BANNER_MARKETING_EN_AFFLUENT_1440,
      },
      1000: {
        pt_BR: BANNER_MARKETING_PT_AFFLUENT_1000,
        es_ES: BANNER_MARKETING_ES_AFFLUENT_1000,
        en_US: BANNER_MARKETING_EN_AFFLUENT_1000,
      },
      600: {
        pt_BR: BANNER_MARKETING_PT_AFFLUENT_600,
        es_ES: BANNER_MARKETING_ES_AFFLUENT_600,
        en_US: BANNER_MARKETING_EN_AFFLUENT_600,
      },
      400: {
        pt_BR: BANNER_MARKETING_PT_AFFLUENT_400,
        es_ES: BANNER_MARKETING_ES_AFFLUENT_400,
        en_US: BANNER_MARKETING_EN_AFFLUENT_400,
      },
    },
    'theme-master': {
      1920: {
        pt_BR: BANNER_MARKETING_PT_MASTER,
        es_ES: BANNER_MARKETING_ES_MASTER,
        en_US: BANNER_MARKETING_EN_MASTER,
      },
      1440: {
        pt_BR: BANNER_MARKETING_PT_MASTER_1440,
        es_ES: BANNER_MARKETING_ES_MASTER_1440,
        en_US: BANNER_MARKETING_EN_MASTER_1440,
      },
      1000: {
        pt_BR: BANNER_MARKETING_PT_MASTER_1000,
        es_ES: BANNER_MARKETING_ES_MASTER_1000,
        en_US: BANNER_MARKETING_EN_MASTER_1000,
      },
      600: {
        pt_BR: BANNER_MARKETING_PT_MASTER_600,
        es_ES: BANNER_MARKETING_ES_MASTER_600,
        en_US: BANNER_MARKETING_EN_MASTER_600,
      },
      400: {
        pt_BR: BANNER_MARKETING_PT_MASTER_400,
        es_ES: BANNER_MARKETING_ES_MASTER_400,
        en_US: BANNER_MARKETING_EN_MASTER_400,
      },
    },
  };

  const getImageBanner = () => {
    return image_marketing_mobile[theme]
      ? image_marketing_mobile[theme][1920][idiom]
      : image_marketing_mobile['theme-afluent'][1920][idiom];
  };

  const [imageBanner, setImgBanner] = useState(getImageBanner());

  const images =
    content &&
    content.map((img, index) => (
      <div
        key={index}
        style={{ backgroundImage: `url(${img})` }}
        className={styles.bannerImg}
        role="img"
        aria-label={intl.SESSION_BANNER}
      ></div>
    ));

  const settings = {
    accessibility: true,
    className: styles.sliderMarketing,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    focusOnSelect: true,
    pauseOnFocus: true,
    customPaging: i => (
      <div className={styles.boxDots}>
        <button className={`${styles[theme]} ${styles.dots}`}></button>
      </div>
    ),
  };

  const checkWindowWidth = () => {
    if (window.innerWidth > 1440) {
      const image_1920 = image_marketing_mobile[theme]
        ? image_marketing_mobile[theme][1920][idiom]
        : image_marketing_mobile['theme-afluent'][1920][idiom];
      setImgBanner(image_1920);
    }
    if (window.innerWidth > 1000 && window.innerWidth < 1440) {
      const image_1440 = image_marketing_mobile[theme]
        ? image_marketing_mobile[theme][1440][idiom]
        : image_marketing_mobile['theme-afluent'][1440][idiom];
      setImgBanner(image_1440);
    }
    if (window.innerWidth > 800 && window.innerWidth < 1000) {
      const image_1000 = image_marketing_mobile[theme]
        ? image_marketing_mobile[theme][1000][idiom]
        : image_marketing_mobile['theme-afluent'][1000][idiom];
      setImgBanner(image_1000);
    }
    if (window.innerWidth < 800) {
      const image_600 = image_marketing_mobile[theme]
        ? image_marketing_mobile[theme][600][idiom]
        : image_marketing_mobile['theme-afluent'][600][idiom];

      setImgBanner(image_600);
    }
    if (window.innerWidth < 500) {
      const image_400 = image_marketing_mobile[theme]
        ? image_marketing_mobile[theme][400][idiom]
        : image_marketing_mobile['theme-afluent'][400][idiom];

      setImgBanner(image_400);
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, [idiom]);

  const redirectToStore = () => {
    if (dragging) return;
    props.history.push('/download-app');
  };

  return (
    <>
      <div className={`${styles.cointainerSlideMarketing} ${styles[theme]}`}>
        {checkAccessDownloadApp(country) && (
          <div className={styles.controlsArea}>
            <button
              className={styles.playButton}
              onClick={play ? handlePause : handlePlay}
              aria-label={intl.PLAY_OR_PAUSE_BUTTON}
            >
              {play ? (
                <PauseIcon width={22} height={20} />
              ) : (
                <PlayIcon width={22} height={20} />
              )}
            </button>
            <div className={`${styles.dividingLine} ${styles[theme]}`}></div>
          </div>
        )}

        <Slider
          {...settings}
          ref={slider => (carouselRef = slider)}
          beforeChange={handleBeforeChange}
          afterChange={handleAfterChange}
        >
          <div className={styles.container}>
            <div className={styles.blackCover}></div>
            <div className={styles.banner}>{images}</div>
            <h1 className={`${styles.title} ${styles[theme]}`}>
              {intl.TITLE_BANNER}
            </h1>
          </div>

          {checkAccessDownloadApp(country) ? (
            <button
              className={`${styles.container} ${styles.cursorPointer}`}
              onClick={redirectToStore}
              tabIndex={0}
            >
              <div className={styles.banner}>
                <div
                  style={{
                    backgroundImage: `url(${imageBanner})`,
                  }}
                  className={styles.bannerImg}
                  role="img"
                  aria-label={intl.SESSION_BANNER_MARKETING_MOBILE}
                ></div>
              </div>
            </button>
          ) : null}
        </Slider>

        <div className={styles.childrenArea}>{props.children}</div>
      </div>
    </>
  );
};

export default withRouter(Banner);
