import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
  HTMLAttributeAnchorTarget,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { ButtonType } from './Button.types';
import { TrailingIconType } from './TrailingIcon/TrailingIcon.types';
import { ThemeType } from '../../themes/ThemeContextParent/ThemeContextParent';
import { joinClassNames } from '../../utils/classNames';
import styles from './Button.module.scss';
import TrailingIcon from './TrailingIcon/TrailingIcon';
import { useMemoTranslations } from '../../intl';
import * as translations from './intl';
import NavHashLink from '../../components/NavHashLink/NavHashLink';

interface ButtonImplementationBaseProps {
  id?: string;
  type: ButtonType;
  className: string;
  label?: string;
  ariaLabel?: string;
  ariaExpanded?: boolean;
  disabled?: boolean;
  leadingIcon?: ReactNode;
  trailingIcon?: TrailingIconType;
  theme: ThemeType;
  testId?: string;
  tabIndex?: number;
}

export interface ButtonButtonImplementationProps
  extends ButtonImplementationBaseProps {
  typeHtml?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export interface ButtonAnchorImplementationProps
  extends ButtonImplementationBaseProps {
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  /**
   * Does the Link directs to an internal page (implements react-router-dom
   * Link), or external domain (implements a anchor(a) HTML tag).
   *
   * @defaultValue
   * true
   */
  internal?: boolean;
}

export const ButtonImplementation = forwardRef<
  HTMLButtonElement,
  ButtonButtonImplementationProps
>(
  (
    {
      id,
      className,
      label,
      ariaLabel,
      ariaExpanded,
      disabled,
      leadingIcon,
      trailingIcon,
      type,
      typeHtml = 'button',
      theme,
      tabIndex,
      testId,
      onClick,
    }: ButtonButtonImplementationProps,
    ref
  ) => (
    <button
      id={id}
      ref={ref}
      className={className}
      disabled={disabled}
      onClick={onClick}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      type={typeHtml}
      data-testid={testId}
      tabIndex={tabIndex}
    >
      {leadingIcon}
      {type != 'Icon' && <span>{label}</span>}
      <TrailingIconWrapper
        type={type}
        trailingIcon={trailingIcon}
        theme={theme}
      />
    </button>
  )
);
ButtonImplementation.displayName = 'ButtonImplementation';

export const AnchorImplementation = forwardRef<
  HTMLAnchorElement,
  ButtonAnchorImplementationProps
>(
  (
    {
      id,
      className,
      label,
      ariaLabel,
      ariaExpanded,
      disabled,
      leadingIcon,
      trailingIcon,
      type,
      theme,
      href,
      internal = true,
      target = internal ? undefined : '_blank',
      tabIndex,
      testId,
      onClick,
    }: ButtonAnchorImplementationProps,
    ref
  ) => {
    const intl = useMemoTranslations(translations);

    type CommomProps = DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    > & { 'data-testid'?: string };
    const commomProps: CommomProps = {
      id,
      ref,
      className,
      'aria-disabled': disabled,
      'aria-label': `${ariaLabel || label}${
        internal || target === '_self' ? '' : ` - ${intl.NEW_TAB}`
      }`,
      'aria-expanded': ariaExpanded,
      rel: internal ? undefined : 'noopener noreferrer',
      target: internal ? undefined : target,
      'data-testid': testId,
      tabIndex,
      onClick,
    };

    return internal ? (
      <NavHashLink {...commomProps} to={href || ''}>
        {leadingIcon}
        {type != 'Icon' && <span>{label}</span>}
        <TrailingIconWrapper
          type={type}
          trailingIcon={trailingIcon}
          theme={theme}
        />
      </NavHashLink>
    ) : (
      <a {...commomProps} href={href}>
        {leadingIcon}
        {type != 'Icon' && <span>{label}</span>}
        <TrailingIconWrapper
          type={type}
          trailingIcon={trailingIcon}
          theme={theme}
        />
      </a>
    );
  }
);
AnchorImplementation.displayName = 'AnchorImplementation';

const TrailingIconWrapper = ({
  type,
  trailingIcon,
  theme,
}: {
  type: ButtonType;
  trailingIcon: TrailingIconType;
  theme: ThemeType;
}) =>
  type != 'Icon' &&
  !!trailingIcon && (
    <span
      className={joinClassNames(styles.trailingIconContainer, styles[theme])}
    >
      <TrailingIcon type={trailingIcon} />
    </span>
  );
