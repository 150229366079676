import React, { useState, useEffect, useContext } from 'react';
import { IntlContext } from '../../../intl';
import {
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  CONCIERGE,
} from '../../../utils/benefitCategory';
import * as translations from './intl';
import ListCrossItem from './ListCrossItem/ListCrossItem';

const getCategoryName = (category, intl) => {
  let name = '';

  switch (category) {
    case TRAVEL_BENEFITS:
      name = intl.TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION;
      break;
    case PURCHASE_PROTECTION:
      name = intl.TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION;
      break;
    case LIFESTYLE:
      name = intl.TITLE_BENEFITS_CATEGORY_LIFESTYLE;
      break;
    case CONCIERGE:
      name = intl.TITLE_BENEFITS_CATEGORY_CONCIERGE;
      break;
    default:
      break;
  }

  return name;
};

const ListCrossBenefits = props => {
  const { benefitToSplit = '', productBinOrPan = '' } = props;
  const [column, setColumn] = useState(3);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const titleByWidthScreen = () => {
    if (window.outerWidth > 992) {
      setColumn(3);
    } else if (window.outerWidth >= 768 && window.outerWidth <= 992) {
      setColumn(2);
    } else {
      setColumn(1);
    }
  };

  const splitArrayColumns = array => {
    return array.reduce(
      (acc, current, index) => (
        (acc[index % column] = acc[index % column] || []).push(current), acc
      ),
      []
    );
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
  }, [column]);

  const createNewGrid = () => {
    let newList = splitArrayColumns(benefitToSplit);
    return newList.map((itens, index) => {
      return (
        <div
          className={`col-lg-4 col-md-6  col-12 no-gutters`}
          key={`${itens.name}_${index}`}
          data-testid="listCardContent"
        >
          {itens.map((item, index) => {
            return (
              <ListCrossItem
                key={`${item.code}_${index}`}
                benefit={item}
                name={item.name}
                images={item.images}
                category={getCategoryName(item.categories, intl)}
                description={item.description}
                type="benefits"
                productBinOrPan={productBinOrPan}
              />
            );
          })}
        </div>
      );
    });
  };

  return <>{createNewGrid()}</>;
};

export default ListCrossBenefits;
