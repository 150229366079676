import React, { useContext } from 'react';
import { IntlContext } from '../../intl';
import styles from './BinPartnerExecption.module.scss';
import * as translations from './intl.js';
import Banner from './Banner/Banner';
import ListCards from '../../components/ListCards/ListCards';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

const BinPartnerExecption = () => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);

  const theme = getGlobalTheme();

  return (
    <div className={styles.container}>
      <Banner />
      <div className={styles.boxContainer}>
        <div className={styles.infoContainer}>
          <h1 className={styles[theme]}>{intl.YOUR_CARDS}</h1>

          <ListCards />
        </div>
      </div>
    </div>
  );
};

export default BinPartnerExecption;
