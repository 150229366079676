import { ReactElement } from 'react';
import { GENDER, TITLE } from '../../utils/consts';
import { Address } from './Address';
import { Registration } from './Customer';
import { person } from './Person';
import { Phone } from './Phone';
import { Price } from './Price';

export interface Certificate {
  code: string;
  covered_benefits: string[];
  covered_benefits_references: string[];
  external_registrations: ExternalRegistration[];
  name: string;
}

export interface CertificateFormated {
  category: string;
  code: string;
  covered_benefits: string[];
  covered_benefits_references: string[];
  external_registrations: ExternalRegistration[];
  image: string;
  name: string;
  urlPDF: string;
  description?: ReactElement;
}

export interface ExternalRegistration {
  registration_type: string;
  value: string;
}

export enum CertificateRoutes {
  GENERATE_CERTIFICATE = 'GenerateCertificateList',
  GENERATE_CERTIFICATE_FORM = 'GenerateCertificateForm',
  GENERATE_CERTIFICATE_INCIDENT = 'GenerateCertificateIncident',
  ADDITIONAL_TRAVELERS = 'GenerateCertificateAdditionalTravelers',
  TOTAL_AMOUNT_DETAILS = 'TotalAmountDetails',
}

type BaseCertificateDetail = {
  bank_identification_number: string;
  card_type: string;
  certificate_id: string;
  certificate_url: string;
  created_at: string;
  valid_from: string;
  valid_until: string;
  ticket_number: string;
};

export interface ApplianceDetails {
  brand?: string;
  cost?: {
    currencyValue: string;
    value: number;
    currency: string;
  };
  purchase_date: string;
}

export interface CarRentalDetails {
  vehicle_type: null;
  rental_start_date: string;
  rental_end_date: string;
  country_origin: string;
  country_destination: string;
  city_origin: string;
  city_destination: string;
  cost: {
    value?: number;
    currency: string;
  };
}

export interface TravelDetails {
  start_date: string;
  end_date: string;
  destination_country: string;
  cost: {
    value?: number;
    currencyValue: string;
    currency: string;
  };
  city_destination?: string;
}

export type PurchaseCertificateDetail = {
  certificate_type: CertificateType.Purchase;
  appliance_details: ApplianceDetails;
  car_rental_details: null;
  travel_details: null;
} & BaseCertificateDetail;

export type AutoRentalCertificateDetail = {
  certificate_type: CertificateType.AutoRentalInsurance;
  appliance_details: null;
  car_rental_details: CarRentalDetails;
  travel_details: null;
} & BaseCertificateDetail;

export type ExtendedWarrantyCertificateDetail = {
  certificate_type: CertificateType.ExtendedWarranty;
  appliance_details: ApplianceDetails;
  car_rental_details: null;
  travel_details: null;
} & BaseCertificateDetail;

export type TravelInsuranceCertificateDetail = {
  certificate_type: CertificateType.TravelInsurance;
  appliance_details: null;
  car_rental_details: null;
  travel_details: TravelDetails;
} & BaseCertificateDetail;

export interface Beneficiary {
  phones: Partial<Phone>[];
  person_registrations: Registration[];
  person: Partial<person>;
  email: string;
  dependent_members?: DependentMember[];
  address: Partial<Address>;
}
export type DependentMember = {
  surname: string;
  street_address?: string;
  state: string;
  postal_code?: string;
  passport?: string;
  cpf?: string;
  nationality: string[];
  name_as_it_appears_in_passport: string;
  gender?: string;
  email: string;
  country?: string;
  city: string;
  birthdate: string;
  barrio: string;
};

export interface TravelInformationCertificate {
  to: string;
  is_one_way_travel: string;
  is_international_trip: string;
  from: string;
  country_origin: string;
  country_destination: string;
  cost: Price;
}

export type FullTravelInsuranceCertificateDetail = {
  travel_information: TravelInformationCertificate;
  product_criteria: {
    card_type: string;
    bank_identification_number: string;
  };
  contract_holder: {
    person: {
      title: TITLE;
      nationalities: string[];
      last_name: string;
      gender: GENDER;
      first_name: string;
      birth_date: string;
    };
    email: string;
    customer_id: string;
  };
  beneficiaries: Beneficiary[];
  created_at: string;
  certificate_type_name: 'travel';
  certificate_id: string;
};

export type CertificateDetail =
  | TravelInsuranceCertificateDetail
  | PurchaseCertificateDetail
  | AutoRentalCertificateDetail
  | ExtendedWarrantyCertificateDetail;

export enum CertificateType {
  TravelInsurance = 'Travel_Insurance',
  ExtendedWarranty = 'Certificate_Extended_Warranty',
  AutoRentalInsurance = 'Billete_Auto_Rental_Insurance',
  Purchase = 'Certificate_Seguro_Compra',
}

export enum CertificateCategory {
  ALL = 'All',
  TRAVEL = 'Travel',
  SHOPPING = 'Shopping',
}

export enum CertificateStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
}

export interface CertificateForm {
  certificateType?: string;
  certificateName: string;
  isWarranty?: boolean;
}

export interface CertificateAutoRentalValue {
  is_international_trip: string;
  from: Date | string;
  to: Date | string;
  country_origin: string;
  city_origin: string;
  country_destination: string;
  city_destination: string;
  rental_company: string;
  reservation_number: string;
}

export interface CertificatePurchaseValue {
  appliance_information: {
    appliance_description: string;
    brand: string;
    model: string;
    purchase_date: Date;
    cost: {
      value: string;
      currency: string;
    };
  };

  extended_warranty_information?: {
    original_period: string;
  };
}
