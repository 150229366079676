import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../components/Input/Input';
import styles from './BankArea.module.scss';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';

const BankArea = props => {
  const [showDigitsField, setShowDigitsField] = useState(false);
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const selectedCard = utils.getSelectedCard();
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, initialValues } =
    props;

  useEffect(() => {
    if (initialValues.bank.last_number) {
      setShowDigitsField(false);
    } else {
      setShowDigitsField(true);
    }
  }, [initialValues.bank.last_number]);

  const getColGridByParams = (isTokenRequired, showDigitsField) =>
    isTokenRequired && !showDigitsField
      ? 'col-md-12 col-lg-12'
      : 'col-md-6 col-lg-8';

  return (
    <div className={`row ${styles.bankArea} ${styles.section}`}>
      <div
        className={`col-12 col-sm-12 ${getColGridByParams(
          selectedCard.is_token_required,
          showDigitsField
        )} `}
      >
        <Input
          value={values.bank.name}
          label={intl.LABEL_BANK}
          name="bank.name"
          dataTestid="InputClaimCHBankName"
          type="text"
          disabled
          onChange={handleChange}
          touched={touched.bank && touched.bank.name}
          onBlur={handleBlur}
          error={errors.bank && errors.bank.name}
        />
      </div>
      {showDigitsField && (
        <div className="col-12 col-sm-12 col-md-6 col-lg-4">
          <Input
            value={values.bank.last_number}
            label={intl.LABEL_BANK_4_DIGITS}
            tooltipText={intl.TEXT_ALERT_4_DIGITS}
            name="bank.last_number"
            dataTestid="InputClaimCHBankNumer"
            type="text"
            mask="maskedDigitsBank"
            onChange={handleChange}
            touched={touched.bank && touched.bank.last_number}
            onBlur={handleBlur}
            error={errors.bank && errors.bank.last_number}
            textTooBig
          />
        </div>
      )}
    </div>
  );
};

export default BankArea;
