import { PHONE_TYPE_MOBILE } from '../../../../../../constants/phone';
import { pt_BR } from '../../../../../../intl/idioms';
import moment from 'moment';

export const generalInformationFormInitialValues = (
  data,
  selectedCard,
  idiom,
  isCertificate
) => {
  let person = data && data.person ? data.person : '';
  let address = data && data.address ? data.address : '';
  let phone = data && data.phone ? data.phone : '';
  let registrations = data && data.registrations ? data.registrations : '';
  let date = new Date();
  let dateFormat = idiom === pt_BR ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
  let today = moment(date).format(dateFormat);
  let validity =
    'De ' +
    moment(date).format(dateFormat) +
    ' até ' +
    moment(today, dateFormat).add(364, 'days').format(dateFormat);
  const fomartBirthDate = moment(person.birth_date).format('YYYY/MM/DD');
  const nationality =
    data &&
    data.person &&
    data.person.nationalities &&
    data.person.nationalities[0]
      ? data.person.nationalities[0]
      : '';

  const indexCPF =
    registrations &&
    registrations.findIndex(
      registration => registration.registration_type === 'CPF'
    );
  const indexNotCPF =
    registrations &&
    registrations.findIndex(
      registration =>
        registration.registration_type === 'ID_CARD' ||
        registration.registration_type === 'PASSPORT'
    );

  const person_registrations =
    idiom === pt_BR
      ? [
          {
            registration_type:
              registrations &&
              indexCPF !== -1 &&
              registrations[indexCPF] &&
              registrations[indexCPF].registration_type &&
              registrations[indexCPF].registration_type !== ''
                ? registrations[indexCPF].registration_type
                : 'CPF',
            value:
              registrations &&
              indexCPF !== -1 &&
              registrations[indexCPF] &&
              registrations[indexCPF].value
                ? registrations[indexCPF].value
                : '',
          },
          {
            registration_type:
              registrations &&
              indexNotCPF !== -1 &&
              registrations[indexNotCPF] &&
              registrations[indexNotCPF].registration_type &&
              registrations[indexNotCPF].registration_type !== ''
                ? registrations[indexNotCPF].registration_type
                : 'PASSPORT',
            value:
              registrations &&
              indexNotCPF !== -1 &&
              registrations[indexNotCPF] &&
              registrations[indexNotCPF].value
                ? registrations[indexNotCPF].value
                : '',
          },
        ]
      : nationality && nationality === 'BR'
      ? [
          {
            registration_type:
              registrations &&
              indexCPF !== -1 &&
              registrations[indexCPF] &&
              registrations[indexCPF].registration_type &&
              registrations[indexCPF].registration_type !== ''
                ? registrations[indexCPF].registration_type
                : 'CPF',
            value:
              registrations &&
              indexCPF !== -1 &&
              registrations[indexCPF] &&
              registrations[indexCPF].value
                ? registrations[indexCPF].value
                : '',
          },
        ]
      : [
          {
            registration_type:
              registrations &&
              indexNotCPF !== -1 &&
              registrations[indexNotCPF] &&
              registrations[indexNotCPF].registration_type &&
              registrations[indexNotCPF].registration_type !== ''
                ? registrations[indexNotCPF].registration_type
                : 'PASSPORT',
            value:
              registrations &&
              indexNotCPF !== -1 &&
              registrations[indexNotCPF] &&
              registrations[indexNotCPF].value
                ? registrations[indexNotCPF].value
                : '',
          },
        ];

  return {
    idiom: idiom,
    travel_information: {
      emission: today,
      validity: validity,
    },
    person: {
      first_name: person && person.first_name ? person.first_name : '',
      birth_date: person && person.birth_date ? new Date(fomartBirthDate) : '',
      last_name: person && person.last_name ? person.last_name : '',
      gender: person.gender ? person.gender : '',
    },
    address: {
      locality: address && address.locality ? address.locality : '',
      postal_code: address && address.postal_code ? address.postal_code : '',
      state: address && address.state ? address.state : '',
      street_address:
        address && address.street_address ? address.street_address : '',
      subdivision: address && address.subdivision ? address.subdivision : '',
      country: address && address.country ? address.country : '',
    },
    phone: {
      phone_type:
        phone && phone.phone_type ? phone.phone_type : PHONE_TYPE_MOBILE,
      number: phone && phone.number ? phone.number : '',
      international_prefix:
        phone && phone.international_prefix ? phone.international_prefix : '',
    },
    person_registrations: person_registrations,
    bank: {
      name: selectedCard.issuer_name || '',
      last_number:
        data && data.bank && data.bank.last_number
          ? data.bank.last_number
          : selectedCard.credit_card_token
          ? selectedCard.credit_card_token.slice(-4)
          : '',
    },
    email: (data && data.email) || '',
    nationalities: nationality,
    politicallyExposed:
      data && data.is_politically_exposed ? data.is_politically_exposed : false,
  };
};
