/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  ButtonHTMLAttributes,
  CSSProperties,
  MutableRefObject,
  ReactNode,
  useContext,
} from 'react';
import styles from './Button.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';

export type ButtonType =
  | 'borderGold'
  | 'gold'
  | 'white'
  | 'whiteWhitoutBorder'
  | 'goldWithoutBorder'
  | 'blueWithoutBorder'
  | 'blue'
  | 'borderBlue'
  | 'borderRed'
  | 'axaBlue'
  | 'axaBlueBorder'
  | 'axaWhiteBorder'
  | 'axaBlueWithoutBorder'
  | 'whatsapp';

export interface ButtonProps {
  type?: ButtonType;
  width?: number | string;
  height?: number | string;
  onClick?: () => void;
  border?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  typeHtml?: ButtonHTMLAttributes<HTMLButtonElement>['type'] | '';
  css?: string;
  dataTestid?: string;
  id?: string;
  buttonRef?: MutableRefObject<HTMLButtonElement>;
  children?: ReactNode;
  autoFocus?: boolean;
  tabIndex?: number;
  ariaLabel?: string;
  className?: string;
}

const Button = ({
  type,
  width,
  height,
  onClick,
  border = true,
  disabled,
  style: styleInline,
  typeHtml = '',
  css,
  dataTestid,
  id,
  buttonRef,
  children,
  autoFocus,
  tabIndex,
  ariaLabel,
  className: classProps,
}: ButtonProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  let className = '';
  let idType = '';
  const noBorder = !border ? styles.noBorder : '';
  const styleDisabled = disabled ? styles.disabled : '';

  switch (type) {
    case 'borderGold': {
      className = styles.borderGold;
      idType = 'borderGold';
      break;
    }
    case 'gold': {
      className = styles.gold;
      idType = 'gold';
      break;
    }
    case 'white': {
      className = styles.white;
      idType = 'white';
      break;
    }
    case 'whiteWhitoutBorder': {
      className = styles.whiteWhitoutBorder;
      idType = 'whiteWhitoutBorder';
      break;
    }
    case 'goldWithoutBorder': {
      className = styles.goldWithoutBorder;
      idType = 'goldWithoutBorder';
      break;
    }
    case 'blueWithoutBorder': {
      className = styles.blueWithoutBorder;
      idType = 'blueWithoutBorder';
      break;
    }
    case 'blue': {
      className = styles.blue;
      idType = 'blue';
      break;
    }
    case 'borderBlue': {
      className = styles.borderBlue;
      idType = 'borderBlue';
      break;
    }
    case 'borderRed': {
      className = styles.borderRed;
      idType = 'borderRed';
      break;
    }
    case 'axaBlue': {
      className = styles.axaBlue;
      idType = 'axaBlue';
      break;
    }
    case 'axaBlueBorder': {
      className = styles.axaBlueBorder;
      idType = 'axaBlueBorder';
      break;
    }
    case 'axaWhiteBorder': {
      className = styles.axaWhiteBorder;
      idType = 'axaWhiteBorder';
      break;
    }
    case 'axaBlueWithoutBorder': {
      className = styles.axaBlueWithoutBorder;
      idType = 'axaBlueWithoutBorder';
      break;
    }
    case 'whatsapp': {
      className = styles.whatsappButton;
      idType = 'whatsappButton';
      break;
    }
    default: {
      className = styles.borderGold;
      idType = 'borderGold';
    }
  }

  const style = {
    width,
    height,
    ...styleInline,
  };

  return (
    <button
      autoFocus={autoFocus}
      data-testid={dataTestid}
      style={style}
      className={`${styles[theme]} ${styles.button} ${className} ${noBorder} ${css} ${styleDisabled} ${classProps}`}
      onClick={onClick}
      disabled={disabled}
      // @ts-ignore
      type={typeHtml}
      id={id || idType}
      ref={buttonRef}
      tabIndex={tabIndex}
      aria-label={ariaLabel}
    >
      {children}
    </button>
  );
};

export default Button;
