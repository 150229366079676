export const TITLE_UNEXPECTED_ERROR_OCCURRED = {
  en_US: 'An error occurred while loading your trip. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao carregar sua viagem. Por favor, tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al cargar tu viaje. Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_LOADING_FLIGHT_DETAILS = {
  en_US: 'Loading flight details...',
  pt_BR: 'Carregando detalhes de voo...',
  es_ES: 'Cargando detalles de vuelo...',
};

export const TITLE_FLIGHT_DETAILS = {
  en_US: 'Flight details',
  pt_BR: 'Detalhes de voo',
  es_ES: 'Detalles de vuelo',
};
