import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const incident = claimDetails && claimDetails.incident;

  const description =
    incident && incident.description ? incident.description : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue label={intl.LABEL_DESCRIPTION} value={description} />
      </div>
    </>
  );
};

export default PersonalArea;
