import {
  createArrayObjectDependentsMembers,
  prepareTravelDependents,
} from './prepareDependents';
import { certificatesServices } from '../../../../../../services';

export const formatTravelInformation = (data, props) => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- FORMAT TRAVEL',
    data
  );
  const {
    values: {
      dependents: { dependents: dependentsValues },
    },
  } = props;

  const dependentsFiltered = dependentsValues.filter(
    dependent => dependent.isSelected
  );
  const dependents = prepareTravelDependents(dependentsFiltered);
  data.beneficiaries = createArrayObjectDependentsMembers(
    [...data.beneficiaries],
    dependents
  );

  const travel_information = props.values.tripInformation.travel_information;
  const travel_information_clone = {
    city_destination:
      travel_information.is_international_trip === true ||
      travel_information.is_international_trip === 'true'
        ? undefined
        : travel_information.city_destination,
    city_origin:
      travel_information.is_international_trip === true ||
      travel_information.is_international_trip === 'true'
        ? undefined
        : travel_information.city_origin,
    country_destination:
      travel_information.is_international_trip === true ||
      travel_information.is_international_trip === 'true'
        ? travel_information.country_destination
        : undefined,
    country_origin:
      travel_information.is_international_trip === true ||
      travel_information.is_international_trip === 'true'
        ? travel_information.country_origin
        : undefined,
    from: travel_information.from || null,
    is_international_trip: travel_information.is_international_trip,
    is_one_way_travel: travel_information.is_one_way_travel,
    to: travel_information.to || null,
  };

  data.travel_information = travel_information_clone;
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI CRIAR TRAVEL',
    true
  );
  return certificatesServices.createTravelCertificateInt(
    data,
    props.idiomForApi()
  );
};
