import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './IncidentArea.module.scss';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import { safeGet } from '../../../../../../../../utils/object';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.dateValue}
          label={intl.LABEL_INCIDENT_DATE}
          name="incident.dateValue"
          onChange={handleChange}
          maxDate={new Date()}
          touched={touched.incident && touched.incident.dateValue}
          error={errors.incident && errors.incident.dateValue}
          setFieldTouched={setFieldTouched}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.purchase_protection_items.purchase_date}
          label={intl.LABEL_PURCHASE_DATE}
          name="purchase_protection_items.purchase_date"
          onChange={handleChange}
          maxDate={new Date()}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items.purchase_date
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items.purchase_date
          }
          setFieldTouched={setFieldTouched}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-12">
        <Textarea
          value={values.incident.description}
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          name="incident.description"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.description')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.description')}
          maxLength="255"
        />
      </div>
    </div>
  );
};

export default IncidentArea;
