import React, { useContext } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './DescriptionArea.module.scss';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import { safeGet } from '../../../../../../../../utils/object';

const DescriptionArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.descriptionArea}`}>
      <div className="col-12">
        <Textarea
          value={values.incident.description}
          label={intl.LABEL_DESCRIPTION}
          name="incident.description"
          dataTestid="InputCarRentalDescription"
          onChange={handleChange}
          touched={safeGet(touched, 'incident.description')}
          onBlur={handleBlur}
          error={safeGet(errors, 'incident.description')}
          maxLength="255"
        />
      </div>
    </div>
  );
};

export default DescriptionArea;
