export const TEXT_BANNER_IEMS = {
  en_US: product_id =>
    `From now, the issuance of the <strong>Travel Certificate</strong> to make use of the <strong>International Emergency Medical Service</strong> is optional. </br><strong><a href="/benefits/${product_id}/EMC_Emergency_Medical_Assistance" target="_blank">Learn more</a></strong>`,
  pt_BR: product_id => ``,
  es_ES: product_id =>
    `A partir de ahora, la emisión del <strong>Certificado de Viaje</strong> para el <strong>Servicio de Emergencia Médica Internacional</strong> es opcional. </br><strong><a href="/benefits/${product_id}/EMC_Emergency_Medical_Assistance" target="_blank">Conoce más</a></strong>`,
};

export const TITLE_BANNER = {
  en_US: '',
  pt_BR: '',
  es_ES: '<p><strong>Ahora el Certificado de Viaje es opcional</strong></p>',
};
