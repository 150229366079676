export const FLIGHT_LEG = {
  en_US: leg => `Flight leg ${leg}`,
  pt_BR: leg => `Trecho de voo ${leg}`,
  es_ES: leg => `Segmento de vuelo ${leg}`,
};

export const TITLE_OUTBOUND = {
  en_US: 'Outbound flight',
  pt_BR: 'Voo de ida',
  es_ES: 'Vuelo de ida',
};

export const TITLE_RETURN = {
  en_US: 'Return flight',
  pt_BR: 'Voo de volta',
  es_ES: 'Vuelo de vuelta',
};

export const LABEL_BOOKING_COMPANY = {
  en_US: 'Booking company / Airline',
  pt_BR: 'Empresa de reserva / Companhia aérea',
  es_ES: 'Compañía de reserva / Aerolínea',
};

export const LABEL_RECORD_LOCATOR = {
  en_US: 'Record locator',
  pt_BR: 'Localizador de registro',
  es_ES: 'Localizador de registro',
};
