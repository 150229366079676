import { AxiosInstance, AxiosResponse } from 'axios';
import { FaqItem, GetFaqQuery, ImprovementOptions } from '../@types/APIs/Faq';

export default (_, axios: AxiosInstance, errorHandler) => {
  return {
    getFaq: async (
      idiom: string,
      query?: GetFaqQuery
    ): Promise<AxiosResponse<FaqItem[]>> => {
      const url = `/frequently_asked_questions`;

      const headers = {
        'Accept-Language': idiom,
      };

      return errorHandler(
        axios.get(url, { params: query, headers }),
        'getFaq',
        { query, idiom }
      );
    },
    postEvaluation: async (
      questionId: string,
      wasHelpful: boolean,
      improvement_option_ids?: string[]
    ): Promise<AxiosResponse<FaqItem[]>> => {
      const url = `/frequently_asked_questions/${questionId}/evaluate`;

      return errorHandler(
        axios.post(url, { was_helpful: wasHelpful, improvement_option_ids }),
        'postEvaluation',
        { was_helpful: wasHelpful }
      );
    },
    getImprovementOptions: async (
      idiom: string
    ): Promise<AxiosResponse<ImprovementOptions[]>> => {
      const apiVersion = process.env.REACT_APP_DISLIKE_FAQ_API_VERSION;
      const url = `/frequently_asked_questions/${apiVersion}/improvement_options`;
      const headers = {
        'Accept-Language': idiom,
      };
      return errorHandler(
        axios.get(url, { headers }),
        'getImprovementOptions',
        {
          url,
          idiom,
        }
      );
    },
  };
};
