export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Appointment',
  es_ES: 'Cita',
  pt_BR: 'Agendamento',
};
