export const TEXT_TITLE_ACTIVE = {
  en_US: 'Active certificates',
  es_ES: 'Certificados activos',
  pt_BR: 'Bilhetes válidos',
};

export const TEXT_SUBTITLE = {
  en_US:
    'Feel free to look through your certificates and check more information.',
  es_ES: 'No dude en revisar tus certificados y comprobar más información.',
  pt_BR:
    'Fique à vontade para detalhar seus bilhetes e verificar mais informações.',
};

export const TEXT_TITLE_PAST = {
  en_US: 'Expired certificates',
  es_ES: 'Certificados caducados',
  pt_BR: 'Bilhetes expirados',
};

export const TEXT_SEE_MORE = {
  en_US: 'See more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_SEE_LESS = {
  en_US: 'See less',
  es_ES: 'Ver menos',
  pt_BR: 'Veja menos',
};

export const TEXT_NO_HAVE_ACTIVE_CERTIFICATE = {
  en_US: 'You do not currently have any active certificate.',
  es_ES: 'Actualmente no tienes ningún certificado activo.',
  pt_BR: 'No momento, você não tem nenhum bilhete válido.',
};

export const TEXT_NO_HAVE_EXPIRED_CERTIFICATE = {
  en_US: 'You do not currently have any expired certificate.',
  es_ES: 'Actualmente no tienes ningún certificados caducado.',
  pt_BR: 'No momento, você não tem nenhum bilhete expirado.',
};

export const TEXT_YOUR_CERTIFICATES = {
  en_US: 'Your certificates',
  es_ES: 'Tus certificados',
  pt_BR: 'Seus bilhetes',
};

export const TEXT_PAGE_TITLE_ADVANCED_SEARCH = {
  en_US: 'Your certificates - Advanced search',
  es_ES: 'Tus certificados - Búsqueda avanzada',
  pt_BR: 'Seus bilhetes - Busca avançada',
};

export const TEXT_PAGE_SUBTITLE_ADVANCED_SEARCH = {
  en_US: 'See your certificates associated with other cards in your profile.',
  es_ES: 'Revisa tus certificados asociados con otras tarjetas en tu perfil.',
  pt_BR:
    'Consulte seus certificados associados a outros cartões existentes no seu perfil. ',
};

export const TEXT_BUTTON_ADVANCED_SEARCH = {
  en_US: 'Advanced search ',
  es_ES: 'Búsqueda avanzada',
  pt_BR: 'Busca avançada',
};

export const NAV_ALL = {
  en_US: 'All',
  es_ES: 'Todos',
  pt_BR: 'Tudo',
};

export const NAV_CERTIFICATE = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Bilhetes de seguro',
};

export const NAV_CLAIM = {
  en_US: 'Claims',
  es_ES: 'Reclamos',
  pt_BR: 'Sinistros',
};

export const TEXT_DATE = {
  en_US: 'Creation date',
  es_ES: 'Fecha de creación',
  pt_BR: 'Data de criação',
};

export const TEXT_PRODUCT = {
  en_US: 'Product',
  pt_BR: 'Produto',
  es_ES: 'Producto',
};

export const TEXT_TICKET_NUMBER = {
  en_US: 'Ticket number',
  es_ES: 'Numero de ticket',
  pt_BR: 'Número do bilhete',
};

export const TEXT_STATUS = {
  en_US: 'Status',
  pt_BR: 'Status',
  es_ES: 'Situación',
};

export const PRODUCT_VALUE = {
  en_US: 'Product value',
  pt_BR: 'Valor do produto',
  es_ES: 'Valor del producto',
};

export const TEXT_LANGUAGE = {
  en_US: 'Language',
  pt_BR: 'Idioma',
  es_ES: 'Idioma',
};

export const TEXT_PENDING = {
  en_US: 'Pending',
  pt_BR: 'Pendente',
  es_ES: 'Pendiente',
};

export const TEXT_CANCELED = {
  en_US: 'Canceled',
  pt_BR: 'Cancelado',
  es_ES: 'Cancelado',
};

export const TEXT_DONE = {
  en_US: 'Done',
  pt_BR: 'Realizado',
  es_ES: 'Hecho',
};

export const TEXT_NOT_DONE = {
  en_US: 'Not done',
  pt_BR: 'Não realizado',
  es_ES: 'No hecho',
};

export const TEXT_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};

export const TEXT_EXPIRED = {
  en_US: 'Expired',
  pt_BR: 'Expirado',
  es_ES: 'Expirado',
};

export const TEXT_ACTIVE = {
  en_US: 'Active',
  pt_BR: 'Ativo',
  es_ES: 'Activo',
};

export const TEXT_CLOSE = {
  en_US: 'Closed',
  es_ES: 'Cerrado',
  pt_BR: 'Fechado',
};

export const TEXT_STATUS_LABEL = {
  en_US: 'Status',
  pt_BR: 'Status',
  es_ES: 'Estado',
};

export const TEXT_PRODUCT_LABEL = {
  en_US: 'Product',
  pt_BR: 'Produto',
  es_ES: 'Producto',
};

export const TEXT_TICKET_NUMBER_LABEL = {
  en_US: 'Ticket number',
  es_ES: 'Numero de ticket',
  pt_BR: 'Número do bilhete',
};

export const LABEL_DATE_FROM = {
  en_US: 'Date from',
  pt_BR: 'Data desde',
  es_ES: 'Fecha desde',
};

export const LABEL_DATE_TO = {
  en_US: 'Date To',
  pt_BR: 'Data até',
  es_ES: 'Fecha hasta',
};

export const TEXT_PRINT = {
  en_US: 'Generate Report',
  pt_BR: 'Generar Reporte',
  es_ES: 'Generar Reporte',
};

export const TEXT_PAGE = {
  en_US: 'Page',
  es_ES: 'Página',
  pt_BR: 'Página',
};

export const TEXT_PAGEOF = {
  en_US: 'of',
  es_ES: 'de',
  pt_BR: 'de',
};
export const PRODUCT_SELECT = {
  en_US: 'Product Select',
  es_ES: 'Seleccionar producto',
  pt_BR: 'Seleção de produto',
};
export const STATUS_SELECT = {
  en_US: 'Status Select',
  es_ES: 'Seleccionar estado',
  pt_BR: 'Seleção de estado',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};

export const TITLE_CLAIMS_MONETIZATION = {
  en_US: 'Amount reimbursed by claims',
  es_ES: 'Importe reembolsado en reclamos',
  pt_BR: 'Valor reembolsado em sinistros',
};

export const SUBTITLE_CLAIMS_MONETIZATION = {
  en_US:
    'The values displayed reflect the total amount of reimbursements received by the Visa cardholder as a result of the approved claims.',
  es_ES:
    'Los valores mostrados reflejan la cantidad total de reembolsos recibidos por el titular de la tarjeta Visa como resultado de los reclamos aprobados.',
  pt_BR:
    'Os valores apresentados refletem o montante total dos reembolsos recebidos pelo titular do cartão Visa, resultantes dos sinistros aprovados.',
};
