export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const RENTAL_COMPANY_Q_VALUE = 'Rental company';

export const VALUE_ASSAULT = 'LOSS';
export const VALUE_DEATH = 'DEATH';

export const GENDER_MALE = 'ML';
export const GENDER_FEMALE = 'FM';
