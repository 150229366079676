import { useHistory } from 'react-router-dom';
import styles from './ViewAllBenefits.module.scss';
import Button from '../../componentsV2/Button/Button';

const ViewAllBenefits = ({
  text,
  link,
  isNatural = false,
  disablePadding = false,
}) => {
  const history = useHistory();

  return (
    <div
      className={`${
        isNatural ? styles.naturalContainerLink : styles.containerLink
      } ${disablePadding ? styles.disablePadding : ''}`}
    >
      <Button
        label={text}
        onClick={() => history.push(link)}
        type="Tertiary"
        trailingIcon="Chevron Right"
      />
    </div>
  );
};

export default ViewAllBenefits;
