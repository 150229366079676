import { useContext, useEffect } from 'react';
import { sanitize } from 'dompurify';
import styles from '../BenefitsDetail.module.scss';
import './PrismicContent.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { clickChat } from '../../../utils/elements';
import BenefitBlueRetangle from '../benefitBueRectangle/BenefitBlueRetangle';
import Link from '../../../componentsV2/Link/Link';

const BenefitContent = props => {
  const {
    descriptionDivs,
    benefitDetail,
    isNewVersionDetail,
    setSelectedTab,
    expectedDocuments,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const isMX = country === 'MX';
  const isAfluent = theme === 'theme-afluent';
  const isIEMS =
    benefitDetail.benefit_id === 'EMC_Emergency_Medical_Assistance_11';

  useEffect(() => {
    const openChatItems = Array.from(
      document.getElementsByClassName('openChat')
    );
    if (openChatItems && openChatItems.length > 0) {
      openChatItems.map(item => {
        item.addEventListener('click', clickChat);
      });
    }
  }, [document]);

  useEffect(() => {
    const openExpectedDocumentsItem = document.getElementById(
      'openExpectedDocuments'
    );
    const expectedDocumentsSection = document.getElementById('tabSection');
    if (
      openExpectedDocumentsItem &&
      expectedDocuments &&
      setSelectedTab &&
      expectedDocumentsSection
    ) {
      openExpectedDocumentsItem.addEventListener('click', () => {
        setSelectedTab('EXPECTED_DOCUMENTS');

        expectedDocumentsSection.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      });
    }
  }, [document, expectedDocuments]);

  const bodyContent = (
    <>
      <article
        className={` ${styles.description} ${styles[theme]}`}
        dangerouslySetInnerHTML={{
          __html:
            (descriptionDivs &&
              sanitize(
                descriptionDivs && descriptionDivs[0] && descriptionDivs[0]
              )) ||
            (benefitDetail && sanitize(benefitDetail.description)),
        }}
      ></article>
      {descriptionDivs && descriptionDivs[1] && (
        <div
          className={`${styles.terms} ${styles[theme]}`}
          dangerouslySetInnerHTML={{
            __html: (descriptionDivs && sanitize(descriptionDivs[1])) || <></>,
          }}
        />
      )}

      {benefitDetail &&
        benefitDetail.documents &&
        benefitDetail.documents[0] &&
        benefitDetail.documents[0].content_url !== '' &&
        benefitDetail.documents[0].content_url !== undefined && (
          <div className={styles.linkArea}>
            <Link
              href={benefitDetail.documents[0].content_url}
              label={intl.SEE_PDF}
            />
          </div>
        )}
    </>
  );

  if (isNewVersionDetail) {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="theme ${theme}">${sanitize(
              benefitDetail.description
            )}</div>`,
          }}
        ></div>
        {isMX && isAfluent && isIEMS && <BenefitBlueRetangle />}
        {benefitDetail &&
          benefitDetail.documents &&
          benefitDetail.documents[0] &&
          benefitDetail.documents[0].content_url !== '' &&
          benefitDetail.documents[0].content_url !== undefined && (
            <div className={styles.linkArea}>
              <Link
                href={benefitDetail.documents[0].content_url}
                label={intl.SEE_PDF}
              />
            </div>
          )}
      </>
    );
  }

  return <>{bodyContent}</>;
};

export default BenefitContent;
