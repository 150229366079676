import { en_currency } from '../../../../../../../intl/currency/en_currency';
import { pt_currency } from '../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../intl/currency/es_currency';

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const ITEM_LABEL = {
  en_US: index => `Item #${index}`,
  es_ES: index => `Articulo #${index}`,
  pt_BR: index => `Item *${index}`,
};

export const LABEL_ITEM = {
  en_US: 'Stolen / damaged item *',
  es_ES: 'Articulo robado / dañado *',
  pt_BR: 'Item roubado / danificado *',
};

export const LABEL_ESTIMATED_VALUE = {
  en_US: 'Estimated value *',
  es_ES: 'Valor estimado *',
  pt_BR: 'Valor estimado *',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const LABEL_TOTAL_AMOUNT_REIMBURSEMENT = {
  en_US: 'Total amount requested to be reimbursed *',
  pt_BR: 'Valor total de reembolsado solicitado *',
  es_ES: 'Importe total de reembolso solicitado *',
};

export const DO_YOU_HAVE_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const INDICATE_AMOUNT_PAID_INSURANCE = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const BTN_ADD = {
  en_US: 'Add item',
  pt_BR: 'Adicionar item',
  es_ES: 'Agregar artículo',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const LABEL_TOTAL_REIMBURSED = {
  en_US: 'Total amount requested to be reimbursed *',
  pt_BR: 'Valor total de reembolsado solicitado *',
  es_ES: 'Importe total de reembolso solicitado *',
};

export const LABEL_TOTAL_AMOUNT_REIMBURSERD = {
  en_US: 'Total amount to be reimbursed *',
  pt_BR: 'Total a ser reembolsado *',
  es_ES: 'Importe total a reembolsar *',
};

export const TITLE_DETAILED_LIST = {
  en_US: 'Detailed list of all items stolen or accidentally damaged',
  pt_BR:
    'Lista detalhada de todos os itens roubados ou danificados acidentalmente',
  es_ES:
    'Lista detallada de todos los artículos robados o dañado accidentalmente',
};

export const DESCRIPTION_DETAILED_LIST = {
  en_US: `Please open a single claim per incident. That is, if there were several items in the bag in the incident, you must add them all in a single claim by clicking the button "Add item" .`,
  pt_BR: `Por favor, abra um único sinistro por incidente. Ou seja, caso no incidente existiam vários itens dentro da bolsa, você deve adicionar todos eles num único sinistro clicando no botão "Adicionar item".`,
  es_ES: `Abra un solo reclamo por incidente. Es decir, si hubiera varios artículos en la bolsa en el incidente, debe agregarlos todos en un solo reclamo haciendo clic en el botón "Agregar artículo".`,
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};

export const ICON_ADD_ITEM = {
  en_US: 'Icon Add Item',
  es_ES: 'Ícono Agregar Ítem',
  pt_BR: 'Ícone Adicionar Item',
};
export const ICON_DELETE = {
  en_US: 'Icon Delete',
  es_ES: 'Ícono Eliminar',
  pt_BR: 'Ícone Excluir',
};

export const TEXT_DESCRIBE_OPTION_YES = {
  en_US:
    'New fields will appear for you to enter the amount covered by the other insurance',
  pt_BR:
    'Novos campos vão aparecer para você informar o valor coberto pelo outro seguro',
  es_ES:
    'Aparecerán nuevos campos para que introduzca el importe cubierto por el otro seguro',
};

export const TEXT_DESCRIBE_OPTION_NO = {
  en_US: 'The value fields covered by other insurance are not necessary',
  pt_BR: 'Os campos de valor coberto por outro seguro não serão necessários',
  es_ES: 'Los campos de valor cubiertos por otros seguros no son necesarios',
};
