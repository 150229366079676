// @ts-nocheck
import { useIntl } from '../../../intl';
import { CINEPOLIS_GOLD } from '../../../utils/benefits';
import BenefitsFormFlow from '../BenefitsFormFlow';
import Form from './Form';
import TermsAndConditions from './TermsAndConditions';
import * as translations from './intl';

const CinepolisPassForm = () => {
  const { translate } = useIntl();
  const intl = translate(translations);

  return (
    <BenefitsFormFlow
      benefitId={CINEPOLIS_GOLD}
      title={intl.TITLE}
      subtitle={intl.SUBTITLE}
      steps={[intl.TEXT_STEP_1, intl.TEXT_STEP_2]}
      Form={Form}
      TermsAndConditions={TermsAndConditions}
    />
  );
};

export default CinepolisPassForm;
