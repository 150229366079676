import React, { createContext, useEffect } from 'react';
import { appInsights } from './AppInsights';
import { userServices } from '../../services';
import { setAppInsightObserver } from '../../services/errorHandler';
import CryptoES from 'crypto-es';

let user;

export const AppInsightTrackContext = createContext({
  trackExceptionMonitor: (name, error, bodyRequest) => {},
  trackDependecyMonitor: (data, url) => {},
  trackEventUserAction: (action, body) => {},
});

const AppInsightTrackContextParent = props => {
  const { children } = props;
  const NOT_REGISTER_LOG = ['/api/user/v1/identities/me/change_password'];

  const obfData = (email, cn, data) => {
    const obfResponse = CryptoES.AES.encrypt(
      JSON.stringify(data),
      String(`${cn}:${email}`)
    ).toString();
    return obfResponse;
  };

  const getEmailAndCnUserLogged = async () => {
    try {
      if (!user && user !== 'USER_IS_NOT_LOGGED') {
        const { data } = await userServices.getUserInfo();
        const cn = data && data.sub && data.sub.split('|').pop();
        user = {
          email: data && data.email,
          customer_id: cn,
          family_name: data && data.family_name,
          given_name: data && data.given_name,
        };
      }
    } catch (error) {
      user = 'USER_IS_NOT_LOGGED';
    }
  };
  const trackExceptionMonitor = async (name, error, bodyRequest) => {
    if (!appInsights) return;
    await getEmailAndCnUserLogged();

    let response = {
      status: error && error.response ? error.response.status : null,
      error_description:
        error && error.response && error.response.data
          ? error.response.data.error_description
          : null,
    };

    const obfBody =
      !!user.email &&
      !!bodyRequest &&
      obfData(user.email, user.customer_id, bodyRequest);

    return appInsights.trackException({
      exception: new Error(`${name}`),
      properties: {
        cn: user.customer_id,
        response: response,
        body: obfBody || bodyRequest,
      },
    });
  };
  const trackDependecyMonitor = (data, url) => {
    let bodyRequest = data;
    if (NOT_REGISTER_LOG.includes(url)) {
      bodyRequest = [];
    }
    if (!appInsights) return;
    return appInsights.addTelemetryInitializer(telemetry => {
      if (data) {
        telemetry.data.data = { body: bodyRequest };
        telemetry.Properties.data = { body: bodyRequest };
      }
    });
  };
  const trackEventUserAction = async (action, body) => {
    if (!appInsights) return;
    await getEmailAndCnUserLogged();
    const obfBody =
      !!user.email && !!body && obfData(user.email, user.customer_id, body);

    return appInsights.trackEvent({
      name: `${action}`,
      properties: { cn: user.customer_id, body: obfBody || body },
    });
  };

  const value = {
    trackExceptionMonitor,
    trackDependecyMonitor,
    trackEventUserAction,
  };

  useEffect(() => {
    setAppInsightObserver(value);
  });

  return (
    <AppInsightTrackContext.Provider value={value}>
      {children}
    </AppInsightTrackContext.Provider>
  );
};

export default AppInsightTrackContextParent;
