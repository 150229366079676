/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './TravelKitMarketing.module.scss';
import BannerDownloadApp from '../Components/BannerDownloadApp/BannerDownloadApp';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import TravelKitIcon from '../../../assets/icons/TravelKitIcon';
import BlueBoxMarketing from '../Components/BlueBoxMarketing/BlueBoxMarketing';
import TitlePageMarketing from '../Components/TitlePageMarketing/TitlePageMarketing';
import HighLightBox from '../Components/HighLightBox/HighLightBox';
import SuccessIcon from '../../../assets/icons/SuccessIcon';
import SearchIcon from '../../../assets/icons/SearchIcon';
import HeartIcon from '../../../assets/icons/HeartIcon';
import ReadyHealthIcon from '../../../assets/icons/ReadyHealthIcon';
import UploadDocumentsIcon from '../../../assets/icons/UploadDocumentsIcon';
import InfoTravelKitMarketing from './InfoTravelKitMarketing/InfoTravelKitMarketing';
import Footer from '../Footer/Footer';
import DisclaimerDownloadApp from '../Components/DisclaimerDownloadApp/DisclaimerDownloadApp';
import { checkAccessDownloadApp } from '../../../utils/downloadAppAccess';
import { withRouter } from 'react-router-dom';

const ICON_SIZE = 45;

const TravelKitMarketing = props => {
  const [access, setAccess] = useState(false);
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  useEffect(() => {
    const checkAccess = checkAccessDownloadApp(country);
    if (!checkAccess) {
      props.history.push('/');
      return;
    }
    setAccess(true);
  }, [country]);

  return (
    <>
      {access ? (
        <div className={styles.container}>
          <BannerDownloadApp
            title={intl.BANNER_TITLE_TRAVEL_KIT}
            icon={<TravelKitIcon white={1} width={60} height={60} />}
            image={intl.IMAGEM_TRAVEL_KIT}
          />
          <div className={styles.content}>
            <div className={styles.infoContent}>
              <TitlePageMarketing
                title={intl.TITLE_PAGE_TRAVEL_KIT}
                subtitle={intl.SUBTITLE_PAGE_TRAVEL_KIT}
                textAlign={'center'}
              />

              <div className={`${styles.containerHightLight}`}>
                <div className={`${styles.boxHightlight} col-12 col-md-6`}>
                  <HighLightBox
                    icon={<SuccessIcon width={ICON_SIZE} height={ICON_SIZE} />}
                    text={intl.TEXT_HIGHLIGHT_1}
                  />
                </div>
                <div className={`${styles.boxHightlight} col-12 col-md-6`}>
                  <HighLightBox
                    icon={<SearchIcon width={ICON_SIZE} height={ICON_SIZE} />}
                    text={intl.TEXT_HIGHLIGHT_2}
                  />
                </div>
              </div>
              <DisclaimerDownloadApp text={intl.TEXT_DISCLAIMER_TRAVEL_KIT} />
              <div className={styles.boxInfo}>
                <InfoTravelKitMarketing />
              </div>
              <Footer />
            </div>

            <div className={styles.blueBoxContent}>
              <BlueBoxMarketing subtitle={intl.ACTIVE_TRAVEL_KIT} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withRouter(TravelKitMarketing);
