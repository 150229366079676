import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { getPhoneType } from '../../../../../../utils/formatInfoProfile';

const DoctorArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  const medical_service = claimDetails.medical_service;
  const attending_physician = claimDetails.attending_physician;

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_HOSPITAL_NAME}
          value={safeLoad(medical_service.medical_center_name)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DOCTOR_NAME}
          value={safeLoad(attending_physician.person.first_name)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DOCTOR_LAST_NAME}
          value={safeLoad(attending_physician.person.last_name)}
        />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_DOCTOR_EMAIL}
          value={safeLoad(attending_physician.email)}
        />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NUMBER}
          value={`
          ${getPhoneType(attending_physician.phone.phone_type, intl)}
          ${attending_physician.phone.international_prefix}
          ${attending_physician.phone.number}
        `}
        />
      </div>
    </>
  );
};

export default DoctorArea;
