import { stringToBoolean } from '../../../../../../../utils/stringUtils';
import { getCurrencyByHomeCountry } from '../../../../../../../utils/currency';
import { cloneDeep } from 'lodash';
import { formatReimbursementToOCT } from './formatReimbursementToOCT';
const FULL_KEY = 'FULL_KEY';
const IBAN = 'IBAN';
const SWIFT = 'SWIFT';
const CLABE = 'CLABE';
const ABA = 'ABA';
const CUIT = 'CUIT';
const NEW_SWIFT = 'SWIFT_BIC';
const NEW_ABA = 'ABA_RTN';
const Colombia = 'CO';
const Mexico = 'MX';

let object_account_codes = {
  preferred_transfer_type: '',
  routing_number: '',
  bank_identification_number: undefined,
};

export const formatReimbursementToNewAPIV2 = (reimbursement, idiom, values) => {
  if (values.reimbursement.paymentIdOCT) {
    return formatReimbursementToOCT(values);
  }
  const bank_account = cloneDeep(reimbursement.banking_information.BankAccount);
  const country =
    bank_account.bank.address && bank_account.bank.address.country
      ? bank_account.bank.address.country
      : '';
  const bank = {
    name: bank_account.bank.name,
    bank_code: idiom === 'pt_BR' ? bank_account.bank.bank_code : '',
    branch_code: idiom === 'pt_BR' ? bank_account.bank.branch_code : '',
  };

  const bank_account_holder = cloneDeep(
    reimbursement.banking_information.BankAccountHolder
  );

  const bank_account_holder_person = bank_account_holder.person;
  const bank_account_holder_address = bank_account_holder.address;
  const bank_account_holder_person_registration =
    bank_account_holder.person_registration;
  const bank_account_holder_phone = bank_account_holder.phone;
  const bank_account_is_politically_exposed =
    idiom === 'pt_BR'
      ? stringToBoolean(bank_account_holder.is_politically_exposed)
      : false;
  let { preferred_transfer_type, routing_number, bank_identification_number } =
    formatAccountCodes(bank_account, country);

  let reimbursementFormated = {
    beneficiary_account: {
      bank,
      holder: {
        person: bank_account_holder_person,
        address: bank_account_holder_address,
        phone: bank_account_holder_phone,
        registrations: Array.isArray(bank_account_holder_person_registration)
          ? bank_account_holder_person_registration
          : [bank_account_holder_person_registration],
        relationship: bank_account_holder.relationship,
        is_politically_exposed: bank_account_is_politically_exposed,
      },
      is_current_account: bank_account.is_current_account
        ? bank_account.is_current_account
        : false,
      account_type: bank_account.account_type,
      account_number: bank_account.bank.bank_account_number,
      currency: getCurrencyByHomeCountry(),
      preferred_transfer_type,
      routing_number,
      bank_identification_number,
    },
  };

  reimbursementFormated.total_amount_reimbursed = {
    value: 0,
    currency: 'EUR',
  };

  // Intermediary Bank Account
  const IntermediaryBankAccount = reimbursement.banking_information
    .IntermediaryBankAccount
    ? reimbursement.banking_information.IntermediaryBankAccount
    : false;

  if (IntermediaryBankAccount) {
    let {
      preferred_transfer_type,
      routing_number,
      bank_identification_number,
    } = formatAccountCodes(IntermediaryBankAccount, country);

    const IntermediaryBank = {
      name: IntermediaryBankAccount.bank.name,
      bank_code: '',
      branch_code: '',
    };
    const intermediary_account = {
      bank: IntermediaryBank,
      holder: {},
      account_number: IntermediaryBankAccount.bank.bank_account_number,
      currency: getCurrencyByHomeCountry(),
      preferred_transfer_type,
      routing_number,
      bank_identification_number,
    };

    reimbursementFormated.intermediary_account = intermediary_account;
  }

  delete reimbursementFormated.beneficiary_account.holder.address.invalidCep;
  if (idiom !== 'pt_BR')
    reimbursementFormated.beneficiary_account.bank.address = { country };

  if (idiom !== 'pt_BR') {
    delete reimbursementFormated.beneficiary_account.holder
      .is_politicaly_exposed;
  } else {
    reimbursementFormated.beneficiary_account.preferred_transfer_type = CLABE;
  }

  return reimbursementFormated;
};

const formatAccountCodes = (bank_account, country) => {
  let object = object_account_codes;
  const list = bank_account.account_codes;
  list &&
    list.forEach(element => {
      const value = element.value ? element.value.replace(/-/g, '') : '';

      if (element.wire_code_type === FULL_KEY) {
        object.routing_number = value;
        object.preferred_transfer_type = CLABE;
      } else if (element.wire_code_type === CUIT) {
        object.bank_identification_number = value.toString().toUpperCase();
      } else if (element.wire_code_type === IBAN) {
        object.routing_number = value.toString().toUpperCase();
        object.preferred_transfer_type = IBAN;
      } else if (element.wire_code_type === SWIFT) {
        object.bank_identification_number = value.toString().toUpperCase();
        if (country === Colombia) object.preferred_transfer_type = NEW_SWIFT;
      } else if (element.wire_code_type === ABA) {
        object.routing_number = value;
        object.preferred_transfer_type = NEW_ABA;
      }
    });

  //Exception format for MX SWIFT field
  if (country === Mexico) {
    object.bank_identification_number = bank_account.bank_identification_number;
  }
  if (object.preferred_transfer_type === '') {
    delete object.preferred_transfer_type;
  }
  if (object.routing_number === '') {
    delete object.routing_number;
  }

  return object;
};
