export const TEXT_TRIP_START_DATE = {
  en_US: 'Trip start date *',
  es_ES: 'Fecha de inicio del viaje *',
  pt_BR: 'Data de início da viagem * ',
};

export const TEXT_TRIP_END_DATE = {
  en_US: 'Trip end date *',
  es_ES: 'Fecha de fin del viaje *',
  pt_BR: 'Data de fim da viagem * ',
};

export const TEXT_FLIGHT_NUMBER = {
  en_US: 'Include flight number or vessel name or registry code',
  es_ES: 'Número de vuelo o nombre del barco o código de registro',
  pt_BR: 'Número de vôo ou nome da embarcação ou código de registro ',
};

export const TEXT_CANCELLATION_DATE = {
  en_US: 'Cancellation date *',
  es_ES: 'Fecha de cancelación *',
  pt_BR: 'Data de cancelamento da viagem * ',
};

export const TEXT_CANCELLATION_REASON = {
  en_US: 'Cancellation reason',
  es_ES: 'Motivo de la cancelación',
  pt_BR: 'Causa do cancelamento',
};

export const TEXT_DATE_INCIDENT = {
  en_US: 'Incident date *',
  es_ES: 'Fecha del incidente *',
  pt_BR: 'Data do incidente *',
};
