import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './IncidentArea.module.scss';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import ChargesList from './ChargesList/ChargesList';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.date}
          label={intl.LABEL_EVENT_DATE}
          name="incident.date"
          onChange={handleChange}
          maxDate={new Date()}
          touched={touched.incident && touched.incident.date}
          error={errors.incident && errors.incident.date}
          setFieldTouched={setFieldTouched}
          dataTestid="eventDate"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.bank_declaration_date}
          label={intl.LABEL_BANK_DATE}
          name="incident.bank_declaration_date"
          onChange={handleChange}
          maxDate={new Date()}
          touched={touched.incident && touched.incident.bank_declaration_date}
          error={errors.incident && errors.incident.bank_declaration_date}
          setFieldTouched={setFieldTouched}
          dataTestid="incident_bank_declaration_date"
        />
      </div>
      <div className="col-12">
        <Textarea
          label={intl.LABEL_DESCRIPTION}
          value={values.incident.description}
          name="incident.description"
          onChange={handleChange}
          touched={touched.incident && touched.incident.description}
          onBlur={handleBlur}
          error={errors.incident && errors.incident.description}
          maxLength="2000"
          dataTestid="incidentDescription"
        />
      </div>
      <ChargesList {...props} />
    </div>
  );
};

export default IncidentArea;
