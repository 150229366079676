import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../BaggageDelay.module.scss';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import * as translations from './intl';
import PhoneArea from './PhoneArea/PhoneArea';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const AirlineArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    prefixOptions,
  } = props;

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.fontGray12} ${styles.options} row`}>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.TEXT_NAME_AIRLINE}
              type="text"
              name="travel_information.travel_agency.name"
              value={values.travel_information.travel_agency.name}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.travel_information &&
                touched.travel_information.travel_agency &&
                touched.travel_information.travel_agency.name
              }
              error={
                errors.travel_information &&
                errors.travel_information.travel_agency &&
                errors.travel_information.travel_agency.name
              }
              maxLength="100"
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`${styles.fontGray12} ${styles.options} row`}>
          <div className="col-12">
            <PhoneArea {...props} prefixOptions={prefixOptions} />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_RECEIVE_INDEMNIFICATION}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_INDEMNIFICATION}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={
                values.other_insurance_covering.checked === 'true'
              }
              text={intl.TEXT_OPTION_YES}
              name="other_insurance_covering.checked"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_RECEIVE_INDEMNIFICATION}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={
                values.other_insurance_covering.checked === 'false'
              }
              text={intl.TEXT_OPTION_NO}
              name="other_insurance_covering.checked"
              value={false}
              onChange={event => {
                setFieldValue('other_insurance_covering.value', '');
                handleChange(event);
              }}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`col-12 col-sm-12 col-md-6 ${
              values.other_insurance_covering.checked === 'true'
                ? ''
                : styles.none
            }`}
          >
            <Input
              label={intl.TEXT_INDICATE_AMOUNT_PAID}
              type="text"
              maskedMoney
              name="other_insurance_covering.value"
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={values.other_insurance_covering.value}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.other_insurance_covering &&
                touched.other_insurance_covering.value
              }
              error={
                errors.other_insurance_covering &&
                errors.other_insurance_covering.value
              }
            />
          </div>

          <div
            className={`col-12 col-sm-12 col-md-6 ${
              values.other_insurance_covering.checked === 'true'
                ? ''
                : styles.none
            }`}
          >
            <Select
              dataTestid="selectCoveringCurrency"
              label={intl.LABEL_CURRENCY}
              name="other_insurance_covering.currency"
              value={values.other_insurance_covering.currency}
              disabled
              onChange={e => {
                setFieldValue(
                  'other_company_covering.currency',
                  e.target.value
                );
                handleChange(e);
              }}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              onBlur={handleBlur}
              touched={
                touched.other_insurance_covering &&
                touched.other_insurance_covering.currency
              }
              error={
                errors.other_insurance_covering &&
                errors.other_insurance_covering.currency
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirlineArea;
