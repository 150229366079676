export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const RENTAL_COMPANY_Q_VALUE = 'Rental company';

/* GENDER */
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const UNKNOWN_VALUE = 'UNKNOWN';
