import React, { useContext } from 'react';
import moment from 'moment';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import styles from '../ClaimView.module.scss';
import * as translations from '../intl';
import { pt_BR } from '../../../../intl/idioms';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { formatCustomDate } from '../../../../utils/date';

const LegalAndConsent = props => {
  const { claimDetails, claimType } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const type_2 = ['Cellphone_Protection', 'Bag_Protection'];
  const checkbox_accord = type_2.includes(claimType)
    ? intl.CHECKBOX_ACCORD_2
    : intl.CHECKBOX_ACCORD;

  const consented_date =
    claimDetails &&
    claimDetails.legal_consents &&
    claimDetails.legal_consents[0].consented_at;

  const consentedDate = formatCustomDate(
    consented_date,
    undefined,
    idiom,
    country
  );

  return (
    <div className={`row ${styles.sectionTitle}`}>
      <h2 className={`col-12 ${styles.sectionTitle} ${styles[themes]}`}>
        {intl.TITLE_LEGAL_CONSENT}
      </h2>

      <div className={`${styles.checkbox} ${styles[themes]}`}>
        <div className={styles.itemCheckBox}>
          <CheckBox
            type="checkbox"
            value={
              claimDetails &&
              claimDetails.legal_consents &&
              claimDetails.legal_consents[0].is_confirmed
            }
            name={'is_confirm.is_confirmed'}
            claimDetailsTestid="CheckboxAcceptTermsSubmit"
            disabled
          >
            {intl.CHECKBOX_UNDERSTAND}
          </CheckBox>
        </div>
        <div className={styles.itemCheckBox}>
          <CheckBox
            type="checkbox"
            value={
              claimDetails &&
              claimDetails.legal_consents &&
              claimDetails.legal_consents[1].is_confirmed
            }
            name={'is_authorized.is_confirmed'}
            claimDetailsTestid="CheckboxAuthorizeTermsSubmit"
            disabled
          >
            {intl.CHECKBOX_AUTHORIZE}
          </CheckBox>
        </div>
        <div className={styles.itemCheckBox}>
          <CheckBox
            type="checkbox"
            value={
              claimDetails &&
              claimDetails.legal_consents &&
              claimDetails.legal_consents[2].is_confirmed
            }
            name={'is_accord.is_confirmed'}
            claimDetailsTestid="CheckboxAccordTermsSubmit"
            disabled
          >
            {checkbox_accord}
          </CheckBox>
        </div>
      </div>

      <div className={`row ${styles.informationArea} ${styles[themes]}`}>
        <div className="col-12">
          {intl.LABEL_DATE} {consentedDate}
        </div>
        <div className="col-12">
          {intl.LABEL_PHONE}
          {claimDetails &&
            claimDetails.policy &&
            claimDetails.policy.holder &&
            claimDetails.policy.holder.phone &&
            '(' +
              claimDetails.policy.holder.phone.international_prefix +
              ') ' +
              claimDetails.policy.holder.phone.number}
        </div>
        <div className="col-12">
          {intl.LABEL_PLACE}
          {claimDetails &&
            claimDetails.policy &&
            claimDetails.policy.holder &&
            claimDetails.policy.holder.address &&
            claimDetails.policy.holder.address.locality +
              ', ' +
              claimDetails.policy.holder.address.state +
              ', ' +
              claimDetails.policy.holder.address.country}
        </div>
      </div>
    </div>
  );
};

export default LegalAndConsent;
