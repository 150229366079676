export const SUBTITLE_AREA = {
  en_US: index => `Additional passenger #${index}`,
  pt_BR: index => `Passageiro(a) adicional #${index}`,
  es_ES: index => `Pasajero(a) adicional #${index}`,
};
export const LABEL_NAME = {
  en_US: 'Name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const LABEL_GENDER = {
  en_US: 'Gender',
  pt_BR: 'Gênero',
  es_ES: 'Género',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth',
  pt_BR: 'Data de nascimento',
  es_ES: 'Fecha de nacimiento',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality',
  pt_BR: 'Nacionalidade',
  es_ES: 'Nacionalidad',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder',
  pt_BR: 'Relação com o titular do cartão',
  es_ES: 'Relación con el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain)',
  pt_BR: 'Outro (explicar)',
  es_ES: 'Otro (explicar)',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};
