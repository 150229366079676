export const TITLE_COMMUNICATION_DEVICE = {
  en_US: 'Communication devices',
  es_ES: 'Dispositivos de comunicación',
  pt_BR: 'Dispositivos de comunicação',
};

export const SUBTITLE_COMMUNICATION_DEVICE = {
  en_US:
    'Verify your registered communication devices for push notifications. To receive push notifications, make sure that in your Communication preferences the option "CUSTOMER ONGOING PROCESSES NOTIFICATIONS" is enabled.',
  es_ES:
    'Verifica tus dispositivos de comunicación registrados para notificaciones automáticas. Para recibir notificaciones push, asegúrate de que en tus Preferencias de comunicacíon está habilitada la opción "NOTIFICACIONES DE PROCESOS DE CLIENTE EN CURSO".',
  pt_BR:
    'Verifique seus dispositivos de comunicação cadastrados para o recebimento de notificações push. Para receber notificações push, certifique-se que em suas Preferências de comunicação a opção "NOTIFICAÇÕES DE PROCESSOS EM ANDAMENTO DO CLIENTE" está habilitada.',
};

export const LOADING_ALL_DEVICES = {
  en_US: 'Loading all your devices...',
  es_ES: 'Cargando todos tus dispositivos...',
  pt_BR: 'Carregando todos os seus dispositivos...',
};

export const EDIT_DEVICE = {
  en_US: 'Edit device information',
  es_ES: 'Editar información del dispositivo',
  pt_BR: 'Editar informações do dispositivo',
};

export const ADD_DEVICE = {
  en_US: 'Add a new device',
  es_ES: 'Agregar un nuevo dispositivo',
  pt_BR: 'Adicionar um novo dispositivo',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  es_ES: 'Editar',
  pt_BR: 'Editar',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const BTN_SET_MAIN = {
  en_US: 'Set as main account',
  pt_BR: 'Definir como conta principal',
  es_ES: 'Establecer como cuenta principal',
};

export const MAIN_ACCOUNT = {
  en_US: 'Main account',
  pt_BR: 'Conta principal',
  es_ES: 'Cuenta principal',
};

export const TEXT_NO_KEEP = {
  en_US: 'No, keep the device',
  pt_BR: 'Não, manter o dispositivo',
  es_ES: 'No, quédate con el dispositivo',
};

export const DELETE_DEVICE_TITLE = {
  en_US: 'Are you sure you want to remove the selected device?',
  pt_BR: 'Você está certo de que deseja remover o dispositivo selecionado?',
  es_ES: '¿Está seguro de que desea eliminar el dispositivo seleccionado?',
};

export const DELETE_DEVICE_SUBTITLE = {
  en_US: `You have accepted to receive push notifications from the Visa Benefits Portal and this permission is registered in your browser. Until this permission is removed, when browsing the Portal, your device will be re-registered with a new ID. To learn how to remove permission from your browser, click `,
  pt_BR: `Você aceitou receber notificações push do Visa Benefits Portal e essa permissão fica registrada no seu navegador. Até que haja a remoção dessa permissão, ao navegar pelo Portal, seu dispositivo será registrado novamente com uma nova identificação. Para saber como remover a permissão do seu navegador, clique `,
  es_ES: `Ha aceptado recibir notificaciones automáticas del Portal de beneficios de Visa y este permiso está registrado en tu navegador. Hasta que se elimine este permiso, al navegar por el Portal, tu dispositivo se volverá a registrar con una nueva identificación. Para saber cómo quitar el permiso de tu navegador, haga clic `,
};

export const DEVICE_REMOVED_SUCCESSFULLY = {
  en_US: 'Device removed successfully.',
  pt_BR: 'Dispositivo removido com sucesso.',
  es_ES: 'Dispositivo eliminado con éxito.',
};

export const SUBTITLE_DISABLE_BROWSER_PUSH = {
  en_US: `To learn how to remove permission to receive push notification from your browser, click `,
  pt_BR: `Para saber como remover a permissão para receber notificações push do seu navegador, clique `,
  es_ES: `Para saber cómo quitar el permiso para recibir notificaciones automáticas desde tu navegador, haga clic `,
};

export const SUBTITLE_COMPLEMENT = {
  en_US: `here.`,
  pt_BR: `aqui.`,
  es_ES: `aquí.`,
};

export const YOU_DONT_HAVE_DEVICES = {
  en_US: `You do not currently have any device.`,
  pt_BR: `Você não tem nenhum dispositivo no momento.`,
  es_ES: `Actualmente no tienes ningún dispositivo.`,
};

export const REMOVING_DEVICE = {
  en_US: `Removing the device ...`,
  pt_BR: `Removendo o dispositivo ...`,
  es_ES: `Quitar el dispositivo ...`,
};

export const SELECT_THIS_ONE = {
  en_US: `Select this one`,
  pt_BR: `Selecione este`,
  es_ES: `Selecciona este`,
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};
