import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './QuestionsArea.module.scss';
import Select from '../../../../../../../../components/Select/Select';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const QuestionsArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue('other_insurance_covering.value', '');
    setFieldValue('other_insurance_covering.currency', '');
  };

  const setEmptyOtherInsurance2 = () => {
    setFieldValue('other_insurance_covering2.value', '');
    setFieldValue('other_insurance_covering2.currency', '');
  };

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={
                values.other_insurance_covering.is_internal === 'true'
              }
              text={intl.TEXT_YES}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherCompanyCoveringYes"
              value={true}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={
                values.other_insurance_covering.is_internal === 'false'
              }
              text={intl.TEXT_NO}
              name="other_insurance_covering.is_internal"
              dataTestid="InputOtherCompanyCoveringNo"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div
        role="contentInsuranceCoveringInternal"
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
        data-testid="DivOtherCompanyValue"
      >
        <Input
          value={values.other_insurance_covering.value}
          label={intl.OTHER_INSURANCE}
          name="other_insurance_covering.value"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNt_DECIMAL}
          disabled={values.other_insurance_covering.is_internal !== 'true'}
          onChange={handleChange}
          touched={
            touched.other_insurance_covering &&
            touched.other_insurance_covering.value
          }
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.value
          }
          dataTestid="inputOtherInsurancCovering"
        />
      </div>
      <div
        role="contentInsuranceCoveringInternal2"
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.total_amount_claimed.currency}
          name="other_insurance_covering.currency"
          disabled
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering &&
            errors.other_insurance_covering.currency
          }
          dataTestid="comboTotalAmountCurrency"
        />
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.AIRLINE_COMPENSATION}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.AIRLINE_COMPENSATION}
              describe={intl.TEXT_DESCRIBE_COMPENSATION}
              defaultChecked={
                values.other_insurance_covering2.is_internal === 'true'
              }
              text={intl.TEXT_YES}
              name="other_insurance_covering2.is_internal"
              dataTestid="InputOtherCompanyCoveringYes2"
              value={true}
              onChange={e => {
                setEmptyOtherInsurance2();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.AIRLINE_COMPENSATION}
              defaultChecked={
                values.other_insurance_covering2.is_internal === 'false'
              }
              text={intl.TEXT_NO}
              name="other_insurance_covering2.is_internal"
              dataTestid="InputOtherCompanyCoveringNo2"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div
        role="contentInsuranceCoveringInternal3"
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering2.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
      >
        <Input
          value={values.other_insurance_covering2.value}
          label={intl.LABEL_AMOUNT_PAID}
          name="other_insurance_covering2.value"
          type="text"
          maskedMoney
          tooltipText={intl.TEXT_AMOUNt_DECIMAL}
          disabled={values.other_insurance_covering2.is_internal !== 'true'}
          onChange={handleChange}
          touched={
            touched.other_insurance_covering2 &&
            touched.other_insurance_covering2.value
          }
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering2 &&
            errors.other_insurance_covering2.value
          }
          dataTestid="inputOtherInsurancCovering2"
        />
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6 ${
          values.other_insurance_covering2.is_internal !== 'true'
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.total_amount_claimed.currency}
          name="other_insurance_covering2.currency"
          disabled
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          onBlur={handleBlur}
          error={
            errors.other_insurance_covering2 &&
            errors.other_insurance_covering2.currency
          }
          dataTestid="comboTotalAmountCurrency2"
        />
      </div>

      <div className="col-12">
        <Input
          value={values.travel_information.form_of_payment}
          label={intl.FORM_OF_PAYMENT}
          name="travel_information.form_of_payment"
          type="text"
          onChange={handleChange}
          touched={
            touched.travel_information &&
            touched.travel_information.form_of_payment
          }
          onBlur={handleBlur}
          error={
            errors.travel_information &&
            errors.travel_information.form_of_payment
          }
          textTooBig
          maxLength="20"
          dataTestid="inputFormOfPayment"
        />
      </div>
    </div>
  );
};

export default QuestionsArea;
