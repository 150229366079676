import { ReactNode } from 'react';
import { FlightInfoData, FlightInfoProvider } from './FlightInfoContext';
import CitiesRow from './CitiesRow';
import AirportsRow from './AirportsRow';
import DelayRow from './DelayRow';
import EmbarkRow from './EmbarkRow';
import DatesRow from './DatesRow';
import StatusHeader from './StatusHeader';
import styles from './index.module.scss';
import LabelsRow from './LabelsRow';

interface FlightInfoProps {
  flightInfo: FlightInfoData;
  header?: ReactNode;
  children: ReactNode;
}

const FlightInfo = ({ flightInfo, header, children }: FlightInfoProps) => {
  return (
    <FlightInfoProvider flightInfo={flightInfo}>
      <div className={styles.headerContainer}>{header}</div>
      {children}
    </FlightInfoProvider>
  );
};

FlightInfo.Airports = AirportsRow;
FlightInfo.Cities = CitiesRow;
FlightInfo.Dates = DatesRow;
FlightInfo.Delay = DelayRow;
FlightInfo.Embark = EmbarkRow;
FlightInfo.Label = LabelsRow;
FlightInfo.StatusHeader = StatusHeader;

export default FlightInfo;
