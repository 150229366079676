import countryVsIdiom from './countryVsIdiom';
import countryPrefix from './countryPrefix.json';
import { en_US } from './idioms.js';

export const countryToIdiom = (country, property = 'country') => {
  const row = countryVsIdiom.find(row => row[property] === country);
  const idiom = row ? row.idiom : en_US;
  return idiom;
};

export const phonePrefixToInitials = initials => {
  const row = countryPrefix.find(row => row.initials === initials);
  const prefix = row ? row.prefix : '';
  return prefix;
};

export const countryPrefixToInitials = code => {
  let prefix = code.charAt(0) === '+' ? code.substr(1) : code;
  const row = countryPrefix.find(row => row.prefix === prefix);
  const initials = row ? row.initials : '';

  return initials;
};

export const getCountryByInitials = (initials, idiom) => {
  const country = countryPrefix.find(item => item.initials === initials);
  return country ? country[idiom] : initials;
};

export const getInitialsCountry = countryCard => {
  const initial = countryPrefix.find(
    country => country.country === countryCard.country
  );
  const country = initial ? initial.initials : '';

  return country;
};
