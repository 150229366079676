/* eslint-disable no-constant-condition */
import React, { useContext } from 'react';
import styles from './ModalAddCard.module.scss';
import { Formik } from 'formik';
import { validationBin } from './validation';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';
import {
  cardsServices,
  productsAndBenefitsServices,
  tokenexService,
} from '../../services';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import ModalWithImage from '../ModalWithImage/ModalWithImage';
import ModalResult from '../ModalResult/ModalResult';
import { showModalAddCard } from './functionShowModal';
import CheckBox from '../CheckBox/CheckBox';
import {
  dataLayerCredcardEventTrack,
  CREDCARD_ADDED,
} from '../../utils/GTM_helper';
import NavHashLink from '../NavHashLink/NavHashLink';
import { useHistory } from 'react-router-dom';
import {
  formatEffectiveEndDate,
  generatePanToken,
} from '../UpdateBinTokenex/InputTokenex/utils';
import { removeNonNumeric } from '../../utils/stringUtils';
import ModalInformation from '../ModalInformation/ModalInformation';
import {
  ALWAYS_ADD_PAN,
  API_TOKENEX_ENDPOINT,
} from '../../utils/environments_variables';
import useScript from '../../hooks/useScript';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';
import { VCESValidation } from '../../utils/vces/vcesValidation';

const ModalAddCard = props => {
  const { section, bin = false, changeModal } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const { state, actions } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { cards, user } = state;
  const { cn } = user.data.userInfo;
  const intl = translate(translations);
  const initialValues = {
    cardNumber: bin ? bin : '',
    main_card: true,
    effective_end_date: '',
  };
  const validation = validationBin(cards.data, intl);
  const history = useHistory();
  const tokenexPublicKey = process.env.REACT_APP_TOKENEX_PUBLIC_KEY;

  useScript(`${API_TOKENEX_ENDPOINT}/inpage/js/TokenEx-Lite.js`);

  const tryAgainAddCard = () => {
    showModalAddCard(actions, intl, 'Header', false, 1, changeModal);
  };

  const openModalSuccess = () => {
    return actions.modal.showModal(
      false,
      false,
      <ModalWithImage
        type="success"
        title={intl.TEXT_SUCCESS}
        image={ModalCreditCard}
      >
        <ModalResult
          textConfirm={intl.OK}
          clickConfirm={() => {
            history.push('/dashboard');
            window.location.reload();
          }}
        />
      </ModalWithImage>,
      true,
      true
    );
  };

  const openModalError = (errorType = 'UNKNOWN') => {
    if (errorType === 'UNKNOWN_PAN') {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error_credit_card"
          message={intl.PAN_ERROR_OCCURRED}
          textBtn={intl.TEXT_ERROR_TRY_AGAIN}
          clickBtn={() => {
            window.location.reload();
          }}
        />,
        true,
        true
      );
    } else if (errorType === 'DUPLICATED_TOKEN' || errorType === 'WRONG_PAN') {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error_credit_card"
          message={intl.PAN_ERROR_OCCURRED}
          subtitle={intl.ALREADY_USED_ERROR_OCCURRED}
          textBtn={intl.TEXT_ERROR_TRY_AGAIN}
          clickBtn={() => {
            window.location.reload();
          }}
        />,
        true,
        true
      );
    } else if (errorType == 'ENTER_VALID_16_DIGIT') {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error_credit_card"
          message={intl.ENTER_VALID_16_DIGITS}
          textBtn={intl.TEXT_ERROR_TRY_AGAIN}
          clickBtn={() => tryAgainAddCard()}
        />,
        true,
        true
      );
    } else {
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error_credit_card"
          message={intl.TEXT_ERROR_MESSAGE}
          subtitle={intl.SUBTITLE_ERROR_MESSAGE}
          textBtn={intl.TEXT_ERROR_TRY_AGAIN}
          clickBtn={() => tryAgainAddCard()}
          contentRedirect={redirectHelpLink}
        />,
        true
      );
    }
  };

  const redirectHelpLink = (
    <NavHashLink to="/help#bin">
      <Button type="blueWithoutBorder" onClick={actions.modal.closeModal}>
        {intl.TEXT_ERROR_NEED_HELP}
      </Button>
    </NavHashLink>
  );

  const addNewCard = async values => {
    const pan = removeNonNumeric(values.cardNumber);
    const bin = pan.substring(0, 10);

    trackEventUserAction(`#### (ADD CARD MODAL) NUMBER`, {
      bin,
    });

    const encryptionKey = document.getElementById('TxEncryptionKey').value;
    const cardInfo = {
      customer_id: cn,
      external_reference: bin,
      bin,
    };
    actions.modal.showLoading(intl.LOADING_NEW_CARD);
    trackEventUserAction(`#### (ADD CARD MODAL) CARD INFO`, {
      cardInfo,
    });

    try {
      if (ALWAYS_ADD_PAN) {
        const token = await generatePanToken(
          trackEventUserAction,
          pan,
          encryptionKey
        );

        trackEventUserAction(`#### (ADD CARD MODAL) TOKEN DO PAN GERADO`, {
          token,
        });

        const effective_end_date = formatEffectiveEndDate(
          values.effective_end_date,
          trackEventUserAction
        );

        await tokenexService.addCardWithPan(
          cardInfo,
          token,
          effective_end_date,
          values.main_card
        );

        trackEventUserAction(`#### (ADD CARD MODAL) ADDED PAN`, {
          bin,
          pan_token: token,
          effective_end_date,
        });
      } else {
        trackEventUserAction(`#### (ADD CARD MODAL) TO ADD BIN`, null);
        await cardsServices.addCard(cn, cardInfo);
        trackEventUserAction(
          `#### (ADD CARD MODAL) ADDED BIN - ${cardInfo.bin}`,
          null
        );
      }

      await actions.cards.reloadLoggedUserCards(false);
      trackEventUserAction(`#### (ADD CARD MODAL) ADDED CARD - RELOAD`, null);

      actions.modal.hideLoading();

      const {
        data: { name },
      } = await productsAndBenefitsServices.getProductDetailByBinCode(
        bin,
        idiomForApi()
      );

      dataLayerCredcardEventTrack(CREDCARD_ADDED, name, section);

      const { data } = JSON.parse(sessionStorage.getItem('cards'));
      trackEventUserAction(`#### (ADD CARD MODAL) ADDED CARD - SUCCESS`, null);
      trackEventUserAction(
        `#### (ADD CARD) ADDED CARD - SUCCESS - COUNTRY ${data[0].country}`,
        bin,
        data[0].country
      );
      openModalSuccess();
    } catch (error) {
      actions.modal.hideLoading();

      trackEventUserAction('#### (ADD CARD MODAL) ERROR - ERROR ####', {
        error,
        message: error && error.message,
        response: error && error.response,
        data: error && error.response && error.response.data,
      });

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ==
          'Please enter a valid 16-digit card number'
      ) {
        openModalError('ENTER_VALID_16_DIGIT');
      }

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description.includes('VCES')
      ) {
        return VCESValidation(
          error.response.data.error_description,
          actions,
          translate
        );
      }

      if (ALWAYS_ADD_PAN) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.error_description ===
            'Duplicated Credit Card Token'
        ) {
          openModalError('DUPLICATED_TOKEN');
        } else if (error.message === '2100 : Input data not Luhn compatible') {
          openModalError('WRONG_PAN');
          trackEventUserAction(
            '#### (ADD CARD MODAL) NON VALID BIN - WRONG_PAN ####',
            error.message
          );
        } else {
          openModalError();
        }
      } else {
        openModalError();
      }
      if (error.message === 'BIN number not found') {
        trackEventUserAction('#### (ADD CARD MODAL) NON VALID BIN ####', null);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={addNewCard}
    >
      {props => {
        const { values, handleSubmit, setFieldValue } = props;

        return (
          <div className={styles.formContent}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={`row ${styles.section}`}>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className={styles.input}>
                    <Input
                      autoFocus
                      dataTestid="inputModalAddCard"
                      errorLeftAlign
                      removeBottonBarColor
                      className={styles.inputField}
                      labelFlex
                      name="cardNumber"
                      formikProps={props}
                      label={`${
                        values.cardNumber
                          ? intl.TEXT_LABEL_ADD_CARD
                          : '____.____.____.____'
                      }`}
                      mask="maskedCreditCard16Digits"
                      labelErrorClassName={styles.alertMessage}
                    />
                  </div>
                </div>
                {/*
                  * * The Business team requested to comment the effective_end_date from the form
                  * ? ISSUER:
                  * https://dev.azure.com/AASolutions/americas_visa_benefits_portal/_sprints/taskboard/americas_visa_benefits_portal%20Team/americas_visa_benefits_portal/2023/Sprint23_15?workitem=713801
                <div className="col-12 col-md-4 col-lg-4">
                  <div className={styles.input}>
                    <Input
                      className={styles.inputField}
                      type="text"
                      label={`${
                        values.effective_end_date
                          ? intl.LABEL_EXPIRATION_DATE
                          : '__/__'
                      }`}
                      removeBottonBarColor
                      name="effective_end_date"
                      formikProps={props}
                      mask="maskedExpirationDate"
                      labelErrorClassName={styles.alertMessage}
                    />
                  </div>
                </div> */}
              </div>
              <div className={`${styles.checkbox} ${styles.section}`}>
                <CheckBox
                  value={values.main_card}
                  name="main_card"
                  onClick={value => setFieldValue('main_card', value)}
                  dataTestid="MainCardCheckbox"
                >
                  {intl.TEXT_MAIN_CARD}
                </CheckBox>
              </div>
              <span className={`${styles.disclaimer} ${styles.section}`}>
                {intl.TEXT_WARNING_VISA}
              </span>

              <div className={styles.container}>
                <div className={`${styles.submitButton} ${styles.section}`}>
                  <Button type="borderBlue">{intl.BTN_ADD_CARD}</Button>
                </div>

                {changeModal && (
                  <div className={`${styles.submitButton} ${styles.section}`}>
                    <Button
                      type="blueWithoutBorder"
                      onClick={event => {
                        event.stopPropagation();
                        changeModal(2);
                      }}
                    >
                      {intl.TEXT_ADD_YOUR_CARD_BIN}
                    </Button>
                  </div>
                )}
              </div>

              <input
                id="TxEncryptionKey"
                type="hidden"
                value={tokenexPublicKey}
              />
            </form>
          </div>
        );
      }}
    </Formik>
  );
};

export default ModalAddCard;
