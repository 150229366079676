import {
  DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE,
  removeCountryFromList,
} from './countries';

export const en_countries = `
<option value="AF"> Afghanistan </option>
<option value="AL"> Albania </option>
<option value="DZ"> Algeria </option>
<option value="AS"> American Samoa </option>
<option value="AD"> Andorra </option>
<option value="AO"> Angola </option>
<option value="AI"> Anguilla </option>
<option value="AQ"> Antarctica </option>
<option value="AG"> Antigua and Barbuda </option>
<option value="AR"> Argentina </option>
<option value="AM"> Armenia </option>
<option value="AW"> Aruba </option>
<option value="AC"> Ascension </option>
<option value="AU"> Australia </option>
<option value="AT"> Austria </option>
<option value="AZ"> Azerbaijan </option>
<option value="BS"> Bahamas </option>
<option value="BH"> Bahrain </option>
<option value="BD"> Bangladesh </option>
<option value="BB"> Barbados </option>
<option value="BY"> Belarus </option>
<option value="BE"> Belgium </option>
<option value="BZ"> Belize </option>
<option value="BJ"> Benim </option>
<option value="BM"> Bermuda </option>
<option value="BT"> Bhutan </option>
<option value="GW"> Bissau Guinea </option>
<option value="BO"> Bolivia </option>
<option value="BA"> Bosnia and Herzegovina </option>
<option value="BW"> Botswana </option>
<option value="BR"> Brazil </option>
<option value="VG"> British Virgin Islands </option>
<option value="BN"> Brunei </option>
<option value="BG"> Bulgaria </option>
<option value="BF"> Burkina Faso </option>
<option value="BI"> Burundi </option>
<option value="KH"> Cambodia </option>
<option value="CM"> Cameroon </option>
<option value="CA"> Canada </option
<option value="CV"> Cape Verde Islands </option>
<option value="KY"> Cayman Islands </option>
<option value="CF"> Central African Republic </option>
<option value="TD"> Chad Republic </option>
<option value="CL"> Chile </option>
<option value="CN"> China </option>
<option value="CX"> Christmas Island </option>
<option value="CC"> Cocos Islands </option>
<option value="CO"> Colombia </option>
<option value="KM"> Comoros </option>
<option value="CG"> Congo </option>
<option value="CK"> Cook Islands </option>
<option value="CR"> Costa Rica </option>
<option value="HR"> Croatia </option>
<option value="CU"> Cuba </option>
<option value="CW"> Curacao </option>
<option value="CY"> Cyprus </option>
<option value="CZ"> Czech Republic </option>
<option value="DK"> Denmark </option>
<option value="DJ"> Djibouti </option>
<option value="DM"> Dominica </option>
<option value="DO"> Dominican Republic </option>
<option value="EI"> Easter Island </option>
<option value="EC"> Ecuador </option>
<option value="EG"> Egypt </option>
<option value="SV"> El Salvador </option>
<option value="GQ"> Equitorial Guinea </option>
<option value="ER"> Eritrea </option>
<option value="EE"> Estonia </option>
<option value="ET"> Ethiopia </option>
<option value="FK"> Falkland Islands </option>
<option value="FO"> Faroe Islands </option>
<option value="FM"> Fed States Micronesia </option>
<option value="FJ"> Fiji Islands </option>
<option value="FI"> Finland </option>
<option value="FR"> France </option>
<option value="TF"> French Antilles </option>
<option value="GF"> French Guiana </option>
<option value="PF"> French Polynesia </option>
<option value="GA"> Gabon Republic </option>
<option value="GM"> Gambia </option>
<option value="GE"> Georgia </option>
<option value="DE"> Germany </option>
<option value="GH"> Ghana </option>
<option value="GI"> Gibraltar </option>
<option value="GR"> Greece </option>
<option value="GL"> Greenland </option>
<option value="GD"> Grenada and Carriacuou </option>
<option value="GP"> Guadeloupe </option>
<option value="GU"> Guam </option>
<option value="GT"> Guatemala </option>
<option value="GY"> Guyana </option>
<option value="HT"> Haiti </option>
<option value="HN"> Honduras </option>
<option value="HK"> Hong Kong </option>
<option value="HU"> Hungary </option>
<option value="IS"> Iceland </option>
<option value="IN"> India </option>
<option value="ID"> Indonesia </option>
<option value="IQ"> Iraq </option>
<option value="IE"> Ireland </option>
<option value="IM"> Isle of Man </option>
<option value="IL"> Israel </option>
<option value="IT"> Italy </option>
<option value="CI"> Ivory Coast </option>
<option value="JM"> Jamaica </option>
<option value="JP"> Japan </option>
<option value="JO"> Jordan </option>
<option value="KZ"> Kazakhstan </option>
<option value="KE"> Kenya </option>
<option value="KI"> Kiribati </option>
<option value="XK"> Kosovo </option>
<option value="KW"> Kuwait </option>
<option value="KG"> Kyrgyzstan </option>
<option value="LA"> Laos </option>
<option value="LV"> Latvia </option>
<option value="LB"> Lebanon </option>
<option value="LS"> Lesotho </option>
<option value="LR"> Liberia </option>
<option value="LY"> Libya </option>
<option value="LI"> Liechtenstein </option>
<option value="LT"> Lithuania </option>
<option value="LU"> Luxembourg </option>
<option value="MO"> Macau </option>
<option value="MK"> Macedonia </option>
<option value="MG"> Madagascar </option>
<option value="MW"> Malawi </option>
<option value="MY"> Malaysia </option>
<option value="MV"> Maldives </option>
<option value="ML"> Mali Republic </option>
<option value="MT"> Malta </option>
<option value="MP"> Mariana Islands </option>
<option value="MH"> Marshall Islands </option>
<option value="MQ"> Martinique </option>
<option value="MR"> Mauritania </option>
<option value="MU"> Mauritius </option>
<option value="YT"> Mayotte Island </option>
<option value="MX"> Mexico </option>
<option value="UM"> Midway Islands </option>
<option value="MD"> Moldova </option>
<option value="MC"> Monaco </option>
<option value="MN"> Mongolia </option>
<option value="MS"> Montserrat </option>
<option value="MA"> Morocco </option>
<option value="MZ"> Mozambique </option>
<option value="MM"> Myanmar </option>
<option value="NA"> Namibia </option>
<option value="NR"> Nauru </option>
<option value="NP"> Nepal </option>
<option value="AN"> Neth Antilles </option>
<option value="NL"> Netherlands </option>
<option value="NC"> New Caledonia </option>
<option value="NZ"> New Zealand </option>
<option value="NI"> Nicaragua </option>
<option value="NE"> Niger Republic </option>
<option value="NG"> Nigeria </option>
<option value="NU"> Niue </option>
<option value="NF"> Norfolk Island </option>
<option value="NO"> Norway </option>
<option value="OM"> Oman </option>
<option value="PK"> Pakistan </option>
<option value="PW"> Palau </option>
<option value="PA"> Panama </option>
<option value="PG"> Papua New Guinea </option>
<option value="PY"> Paraguay </option>
<option value="PE"> Peru </option>
<option value="PH"> Philippines </option>
<option value="PL"> Poland </option>
<option value="PT"> Portugal </option>
<option value="PR"> Puerto Rico </option>
<option value="QA"> Qatar </option>
<option value="GN"> Rep Guinea </option>
<option value="RS"> Republic of Serbia </option>
<option value="RE"> Reunion Island </option>
<option value="RO"> Romania </option>
<option value="RU"> Russia </option>
<option value="RW"> Rwanda </option>
<option value="MF"> Saint Martin </option>
<option value="ST"> Saint Thomas and Prince </option>
<option value="SM"> San Marino </option>
<option value="SA"> Saudi Arabia </option>
<option value="SN"> Senegal Republic </option>
<option value="SC"> Seychelles </option>
<option value="SL"> Sierra Leone </option>
<option value="SG"> Singapore </option>
<option value="SK"> Slovakia </option>
<option value="SI"> Slovenia </option>
<option value="VN"> Soc Republic of Vietnam </option>
<option value="SB"> Solomon Islands </option>
<option value="SO"> Somalia Republic </option>
<option value="ZA"> South Africa </option>
<option value="KR"> South Korea </option>
<option value="ES"> Spain </option>
<option value="LK"> Sri Lanka </option>
<option value="SH"> St Helena </option>
<option value="KN"> St Kitts and Nevis </option>
<option value="LC"> St Lucia </option>
<option value="PM"> St Pierre et Miquelon </option>
<option value="VC"> St Vincent and the Grenadines </option>
<option value="SR"> Suriname </option>
<option value="SZ"> Swaziland </option>
<option value="SE"> Sweden </option>
<option value="CH"> Switzerland </option>
<option value="TW"> Taiwan </option>
<option value="TJ"> Tajikistan </option>
<option value="TZ"> Tanzania </option>
<option value="TH"> Thailand </option>
<option value="TG"> Togo </option>
<option value="TK"> Tokelau </option>
<option value="TO"> Tonga </option>
<option value="TT"> Trinidad and Tobago </option>
<option value="TN"> Tunisia </option>
<option value="TR"> Turkey </option>
<option value="TM"> Turkmenistan </option>
<option value="TC"> Turks and Caicos Islands </option>
<option value="TV"> Tuvalu </option>
<option value="UG"> Uganda </option>
<option value="UA"> Ukraine </option>
<option value="AE"> United Arab Emirates </option>
<option value="GB"> United Kingdom </option>
<option value="US"> United States </option>
<option value="UY"> Uruguay </option>
<option value="VI"> US Virgin Islands </option>
<option value="UZ"> Uzbekistan </option>
<option value="VU"> Vanuatu </option>
<option value="VA"> Vatican City </option>
<option value="VE"> Venezuela </option>
<option value="UM"> Wake Island </option>
<option value="WF"> Wallis and Futuna Islands </option>
<option value="SW"> Western Samoa </option>
<option value="YE"> Yemen </option>
<option value="YU"> Yugoslavia </option>
<option value="ZR"> Zaire </option>
<option value="ZM"> Zambia </option>
<option value="ZW"> Zimbabwe </option>`;

export const en_destinationCountryOptions = () =>
  removeCountryFromList(
    en_countries,
    DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE
  );
