import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const ChevronLeftIcon = props => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...handledProps}
    >
      <path
        d="M11 15 5.414 9.414a2 2 0 0 1 0-2.828L11 1"
        stroke={handledProps.color ?? colors.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ChevronLeftIcon;
