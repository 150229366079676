import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import { IntlContext } from '../../intl';
import { cardsServices } from '../../services';
import styles from './PrivateAllBinCards.module.scss';
import Cards0 from '../../assets/images/cards_visa.svg';
import * as translations from './intl';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import Consumers from './Consumers/Consumers';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';
import AllCardsIcon from '../../assets/icons/AllCardsIcon';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { isIssuerDomain } from '../../utils/banks';
import Button from '../../componentsV2/Button/Button';

function ArrowSlider(props) {
  const { style, onClick, rotation = 90, direction = 'left', intl } = props;

  const styleDirection =
    direction === 'right' ? styles.iconArrowRight : styles.iconArrowLeft;
  return (
    <Button
      type="Icon"
      leadingIcon={
        <ChevronGeneric
          rotate={rotation}
          width={16}
          height={16}
          data-testid="iconChevron"
          aria-label={intl.ICON_OPEN_MENU_COUNTRIES_LIST}
          className={styles.iconHeader}
        />
      }
      className={`${styles.iconArrow} ${styleDirection}`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
}

const changePreferredCard = async (actions, infoCard, intl) => {
  try {
    const { cn, name, external_reference } = infoCard;
    const mask_external_reference = external_reference.replace(
      new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
      '$1.****.$3**.****'
    );
    actions.loadingFullScreen.showLoadingFullScreen(
      intl.SETTING_YOUR_CARD(`${mask_external_reference} - ${name}`)
    );

    await cardsServices.changePreferredCard(cn, name, external_reference);

    await actions.cards.changeSelectedCardIndex(external_reference);
    actions.loadingFullScreen.hideLoadingFullScreen();
    window.location.reload();
  } catch (error) {
    actions.loadingFullScreen.hideLoadingFullScreen();
    actions.modal.showModal(
      false,
      true,
      <ModalInformation
        type="error"
        message={intl.UNEXPECTED_ERROR_OCCURRED}
        image={ModalCreditCard}
        textBtn={intl.TEXT_OK}
      ></ModalInformation>,
      true
    );
  }
};

const renderProducts = (products, actions, cn, dragging, intl, theme) => {
  return products.map(
    ({ name, product_id, issuer_name, external_reference, image, type }) => {
      const infoCard = {
        name,
        external_reference,
        cn,
      };

      const formatedType =
        type === 'C' ? intl.CREDIT : type === 'D' ? intl.DEBIT : intl.PREPAID;

      return (
        <button
          key={product_id}
          className={styles.sliderItem}
          onClick={() => {
            if (dragging) return;
            changePreferredCard(actions, infoCard, intl);
          }}
          style={{ width: '230px' }}
        >
          <div className={styles.sliderContent}>
            {dragging && <div className={styles.maskCard}></div>}
            <img
              title={name}
              src={image && image.length > 0 ? image : Cards0}
              alt={name}
              className={styles.boxImg}
            />
            <h6 className={`${styles.cardType} ${styles[theme]}`}>
              {formatedType}
            </h6>
            <h4
              className={`${styles[theme]} ${styles.cardName} ${styles.cardNameSlider}`}
            >
              {name}
            </h4>
            <h5
              className={`${styles[theme]} ${styles.bankName}  ${styles.bankNameSlider}`}
            >
              {issuer_name}
            </h5>
          </div>
        </button>
      );
    }
  );
};

const PrivateAllBinCards = () => {
  const { state, utils, actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const [products, setProducts] = useState([]);
  const [onlyMainCard, setOnlyMainCard] = useState(true);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const mainCard = utils.getSelectedCard();
  const cn = utils.getCn();
  const [dragging, setDragging] = useState(false);
  const theme = getGlobalTheme();
  const isBmp = isIssuerDomain();

  const mainCardType = useMemo(() => {
    switch (mainCard.type) {
      case 'C':
        return intl.CREDIT;
      case 'D':
        return intl.DEBIT;
      default:
        return intl.PREPAID;
    }
  }, [mainCard]);

  const loadProducts = async () => {
    try {
      const { data } = JSON.parse(sessionStorage.getItem('cards'));

      const removeIsPreferred = data.filter(card => !card.is_preferred);
      const newListCard = [...removeIsPreferred];
      setProducts(newListCard.length > 0 ? newListCard : data);
      setOnlyMainCard(newListCard.length > 0 ? false : true);
    } catch (error) {}
  };

  const renderCards = renderProducts(
    products,
    actions,
    cn,
    dragging,
    intl,
    theme
  );

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  useEffect(() => {
    if (state.user.data.userInfo) {
      loadProducts(state.user.data.userInfo.cn);
    }
  }, [mainCard]);

  const getNumberSlidesToShow = products => {
    if (products && products.length >= 3) {
      return 3;
    } else if (products && products.length === 2) {
      return 2;
    }
    return 1;
  };

  const showModal = () => {
    return actions.modal.showModal(
      null,
      true,
      <Consumers />,
      false,
      false,
      null,
      null,
      null
    );
  };

  const settings = {
    className: styles.slider,
    dots: false,
    arrows: true,
    infinite: products.length > 2,
    centerMode: true,
    slidesToShow: getNumberSlidesToShow(products),
    slidesToScroll: 1,
    beforeChange: () => handleBeforeChange(),
    afterChange: () => handleAfterChange(),
    focusOnChange: true,
    acessibility: true,
    nextArrow: (
      <ArrowSlider theme={theme} direction="right" rotation={270} intl={intl} />
    ),
    prevArrow: <ArrowSlider theme={theme} intl={intl} />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2.08,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {products && products.length > 0 && (
        <div className={`${styles.boxSlider} ${styles[theme]}`}>
          <div className="row">
            <div className={`${styles.boxTitleCard} col-12 col-lg-4`}>
              <h2 className={`${styles.titleCard} ${styles[theme]}`}>
                {`${intl.TEXT_HI} ${state.user.data.userInfo.firstName}`},
              </h2>

              <h6 className={`${styles.subTitleCard} ${styles[theme]}`}>
                {intl.TEXT_WELCOME_WORLD_VISA}
              </h6>

              <div className={styles.linkContent}>
                <Button
                  type="Tertiary"
                  trailingIcon="Chevron Right"
                  link
                  onClick={() => {
                    dataLayerContentMenuTrack(
                      'CTA Personal information',
                      'Personal data menu'
                    );
                  }}
                  className={`${styles.link} ${styles[theme]}`}
                  href="/profile/account"
                  id="profileInfoLink"
                  label={intl.LINK_PERSONAL_DETAILS}
                />
                <Button
                  type="Tertiary"
                  trailingIcon="Chevron Right"
                  link
                  onClick={() => {
                    dataLayerContentMenuTrack(
                      'CTA Bancary information',
                      'Personal data menu'
                    );
                  }}
                  className={`${styles.link} ${styles[theme]}`}
                  href="/profile/account#bank"
                  data-testid="accountBankLink"
                  label={intl.LINK_BANK_DETAILS}
                />
                <Button
                  type="Tertiary"
                  trailingIcon="Chevron Right"
                  link
                  onClick={() => {
                    dataLayerContentMenuTrack(
                      'CTA Dependents',
                      'Personal data menu'
                    );
                  }}
                  className={`${styles.link} ${styles[theme]}`}
                  href="/profile/account#dependents"
                  data-testid="accountDependentsLink"
                  label={intl.LINK_DEPENDENT_ACCOUNTS}
                />
              </div>
            </div>

            <div className={'col-lg-8'}>
              <div className={'row'}>
                <div className={'col-lg-4'}>
                  <div className={styles.boxMainCard}>
                    <div className={styles.mainCard}>
                      <img src={mainCard.image || Cards0} alt={intl.CARD} />
                      <h6 className={`${styles.cardType} ${styles[theme]}`}>
                        {mainCardType}
                      </h6>
                      <h4 className={`${styles.cardName} ${styles[theme]}`}>
                        {mainCard.name}
                      </h4>
                      <h5 className={`${styles.bankName} ${styles[theme]}`}>
                        {mainCard.issuer_name}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className={`col-lg-8 ${styles.listCards}`}>
                  {!isBmp && (
                    <div className={styles.seeAllProducts}>
                      <Button
                        type="Tertiary"
                        label={intl.LINK_SEE_ALL_PRODUCTS}
                        leadingIcon={<AllCardsIcon />}
                        onClick={() => showModal()}
                      />
                    </div>
                  )}
                  {!onlyMainCard && !isBmp && (
                    <Slider {...settings}>{renderCards}</Slider>
                  )}
                  <div className={styles.linkContentMobile}>
                    <Button
                      type="Tertiary"
                      trailingIcon="Chevron Right"
                      link
                      onClick={() => {
                        dataLayerContentMenuTrack(
                          'CTA Personal information',
                          'Personal data menu'
                        );
                      }}
                      className={`${styles.link} ${styles[theme]}`}
                      href="/profile/account"
                      label={intl.LINK_PERSONAL_DETAILS}
                    />
                    <Button
                      type="Tertiary"
                      trailingIcon="Chevron Right"
                      link
                      onClick={() => {
                        dataLayerContentMenuTrack(
                          'CTA Bancary information',
                          'Personal data menu'
                        );
                      }}
                      className={`${styles.link} ${styles[theme]}`}
                      href="/profile/account#bank"
                      label={intl.LINK_BANK_DETAILS}
                    />
                    <Button
                      type="Tertiary"
                      trailingIcon="Chevron Right"
                      link
                      onClick={() => {
                        dataLayerContentMenuTrack(
                          'CTA Dependents',
                          'Personal data menu'
                        );
                      }}
                      className={`${styles.link} ${styles[theme]}`}
                      href="/profile/account#dependents"
                      label={intl.LINK_DEPENDENT_ACCOUNTS}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivateAllBinCards;
