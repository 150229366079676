export const TEXT_HOME = {
  en_US: 'Home',
  es_ES: 'Home',
  pt_BR: 'Home',
};

export const TEXT_BENEFITS = {
  en_US: 'Benefits',
  es_ES: 'Beneficios',
  pt_BR: 'Benefícios',
};

export const TEXT_OFFERS = {
  en_US: 'Offers',
  es_ES: 'Ofertas',
  pt_BR: 'Ofertas',
};

export const TEXT_SPECIAL_BENEFITS = {
  en_US: 'Subscriptions',
  es_ES: 'Suscripcións',
  pt_BR: 'Assinaturas',
};

export const TEXT_CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Bilhetes',
};

export const TEXT_CLAIMS = {
  en_US: 'Claims',
  es_ES: 'Reclamos',
  pt_BR: 'Sinistros',
};

export const TEXT_HELP = {
  en_US: 'Help',
  es_ES: 'Ayuda',
  pt_BR: 'Ajuda',
};
