import React, { useContext, useState, useEffect } from 'react';

import { NavLink, withRouter } from 'react-router-dom';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import styles from './MenuProfile.module.scss';

import MakeSubmenu from './Submenu';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getMenuNameByPathname } from '../../utils/menu';
import {
  NOTIFICATIONS_ENABLE,
  REACT_APP_TOTAL_REIMBURSEMENT,
} from '../../utils/environments_variables';
import CertificateIcon from '../../assets/icons/CertificatesIcon';
import NotificationsIcon from '../../assets/icons/NotificationsIcon';
import ClaimsIcon from '../../assets/icons/ClaimsIcon';
import SettingsIcon from '../../assets/icons/SettingsIcons';
import UserIcon from '../../assets/icons/UserIcon';
import TotalReimbursedIcon from '../../assets/icons/TotalReimbursedIcon';
import VoucherIcon from '../../assets/icons/VoucherIcon';

const MenuProfile = props => {
  const { customer, hasCinepolisGold } = props;
  const [menuSelected, setMenuSelected] = useState(getMenuNameByPathname());
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    setMenuSelected(getMenuNameByPathname());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <div className={styles.menuLeft}>
      <div className={styles.menuList}>
        <ul>
          <li>
            <NavLink
              to="/profile/account#contact"
              activeClassName={styles.menuActive}
              onClick={() => setMenuSelected('account')}
              className={styles[themes]}
            >
              <span className={styles.icon}>
                <UserIcon width={25} height={25} />
              </span>
              {intl.MENU_ACCOUNT}
            </NavLink>
            {menuSelected === 'account' && (
              <MakeSubmenu
                showVoiceIdLink={
                  customer && !!customer.external_voice_profile_id
                }
                menuId="account"
              />
            )}
          </li>

          <li>
            <NavLink
              to="/profile/certificates#active"
              activeClassName={styles.menuActive}
              onClick={() => setMenuSelected('certificates')}
              className={styles[themes]}
            >
              <span className={styles.icon}>
                <CertificateIcon width={25} height={25} />
              </span>

              {intl.MENU_YOUR_CERTIFICATES}
            </NavLink>
            {menuSelected === 'certificates' && (
              <MakeSubmenu menuId="certificates" />
            )}
          </li>

          <li>
            <NavLink
              to="/profile/claims#pending"
              activeClassName={styles.menuActive}
              onClick={() => setMenuSelected('claims')}
              className={styles[themes]}
            >
              <span className={`${styles.icon} ${styles.iconClaimProfile}`}>
                <ClaimsIcon width={25} height={25} />
              </span>

              {intl.MENU_YOUR_CLAIMS}
            </NavLink>
            {menuSelected === 'claims' && <MakeSubmenu menuId="claims" />}
          </li>
          {REACT_APP_TOTAL_REIMBURSEMENT && (
            <li>
              <NavLink
                to="/profile/monetization#certificates"
                activeClassName={styles.menuActive}
                onClick={() => setMenuSelected('monetization')}
                className={styles[themes]}
              >
                <span className={`${styles.icon} ${styles.iconClaimProfile}`}>
                  <TotalReimbursedIcon width={25} height={25} />
                </span>

                {intl.SUBMENU_SAVING}
              </NavLink>
              {menuSelected === 'monetization' && (
                <MakeSubmenu menuId="monetization" />
              )}
            </li>
          )}

          {hasCinepolisGold && (
            <li>
              <NavLink
                to="/profile/vouchers#active"
                activeClassName={styles.menuActive}
                onClick={() => setMenuSelected('vouchers')}
                className={styles[themes]}
              >
                <span className={`${styles.icon} ${styles.iconClaimProfile}`}>
                  <VoucherIcon width={25} height={25} />
                </span>

                {intl.SUBMENU_VOUCHERS}
              </NavLink>
              {menuSelected === 'vouchers' && <MakeSubmenu menuId="vouchers" />}
            </li>
          )}

          {NOTIFICATIONS_ENABLE && (
            <li>
              <NavLink
                to="/profile/notifications#all"
                activeClassName={styles.menuActive}
                onClick={() => setMenuSelected('notifications')}
                className={styles[themes]}
              >
                <span className={styles.icon}>
                  <NotificationsIcon width={23} height={23} color="Duotone" />
                </span>
                {intl.MENU_NOTIFICATIONS}
              </NavLink>
              {menuSelected === 'notifications' && (
                <MakeSubmenu menuId="notifications" />
              )}
            </li>
          )}

          <li>
            <NavLink
              to="/profile/settings"
              activeClassName={styles.menuActive}
              onClick={() => setMenuSelected('settings')}
              className={styles[themes]}
            >
              <span className={styles.icon}>
                <SettingsIcon width={23} height={23} />
              </span>
              {intl.MENU_SETTINGS}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(MenuProfile);
