/* eslint-disable no-unused-vars */
import { pt_BR } from '../../../../../intl/idioms';
import moment from 'moment';

export const dependentsInitialValues = (data, idiom, isCertificate = false) => {
  let formatData = { dependents: [] };
  data &&
    data.map(value => {
      const fomartBirthDate = moment(value.person.birth_date).format(
        'YYYY/MM/DD'
      );
      const brithDate = value.person.birth_date
        ? new Date(fomartBirthDate)
        : '';

      const nationality =
        value.person &&
        value.person.nationalities &&
        value.person.nationalities[0]
          ? value.person.nationalities[0]
          : '';

      let registrations =
        value && value.registrations ? value.registrations : '';
      const indexCPF =
        registrations &&
        registrations.findIndex(
          registration => registration.registration_type === 'CPF'
        );
      const indexNotCPF =
        registrations &&
        registrations.findIndex(
          registration =>
            registration.registration_type === 'ID_CARD' ||
            registration.registration_type === 'PASSPORT'
        );

      const person_registrations =
        isCertificate && idiom === pt_BR
          ? [
              {
                registration_type:
                  registrations &&
                  indexCPF !== -1 &&
                  registrations[indexCPF] &&
                  registrations[indexCPF].registration_type &&
                  registrations[indexCPF].registration_type !== ''
                    ? registrations[indexCPF].registration_type
                    : 'CPF',
                value:
                  registrations &&
                  indexCPF !== -1 &&
                  registrations[indexCPF] &&
                  registrations[indexCPF].value
                    ? registrations[indexCPF].value
                    : '',
              },
              {
                registration_type:
                  registrations &&
                  indexNotCPF !== -1 &&
                  registrations[indexNotCPF] &&
                  registrations[indexNotCPF].registration_type &&
                  registrations[indexNotCPF].registration_type !== ''
                    ? registrations[indexNotCPF].registration_type
                    : 'PASSPORT',
                value:
                  registrations &&
                  indexNotCPF !== -1 &&
                  registrations[indexNotCPF] &&
                  registrations[indexNotCPF].value
                    ? registrations[indexNotCPF].value
                    : '',
              },
            ]
          : nationality && nationality === 'BR'
          ? [
              {
                registration_type:
                  registrations &&
                  indexCPF !== -1 &&
                  registrations[indexCPF] &&
                  registrations[indexCPF].registration_type &&
                  registrations[indexCPF].registration_type !== ''
                    ? registrations[indexCPF].registration_type
                    : 'CPF',
                value:
                  registrations &&
                  indexCPF !== -1 &&
                  registrations[indexCPF] &&
                  registrations[indexCPF].value
                    ? registrations[indexCPF].value
                    : '',
              },
            ]
          : [
              {
                registration_type:
                  registrations &&
                  indexNotCPF !== -1 &&
                  registrations[indexNotCPF] &&
                  registrations[indexNotCPF].registration_type &&
                  registrations[indexNotCPF].registration_type !== ''
                    ? registrations[indexNotCPF].registration_type
                    : 'PASSPORT',
                value:
                  registrations &&
                  indexNotCPF !== -1 &&
                  registrations[indexNotCPF] &&
                  registrations[indexNotCPF].value
                    ? registrations[indexNotCPF].value
                    : '',
              },
            ];

      return formatData.dependents.push({
        address: {
          country: '',
          locality: '',
          postal_code: '',
          state: '',
          street_address: '',
          subdivision: '',
        },
        person: {
          first_name: value && value.person && value.person.first_name,
          gender: value && value.person && value.person.gender,
          last_name: value && value.person && value.person.last_name,
          birth_date:
            value && value.person && value.person.birth_date ? brithDate : '',
        },
        person_registrations: person_registrations,
        email: value && value.email,
        dependent_id: value && value.dependent_id,
        nationalities:
          value && value.person.nationalities && value.person.nationalities[0]
            ? value.person.nationalities[0]
            : '',
        relationship: value && value.relationship ? value.relationship : '',
        isSelected: true,
      });
    });

  return formatData;
};
