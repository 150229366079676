import React from 'react';
import { safeGet } from '../../../../../../../utils/object';
import Button from '../../../../../../../componentsV2/Button/Button';
import styles from './SlotList.module.scss';

const SlotList = props => {
  const { formikProps, slots } = props;
  const selectedTime = safeGet(formikProps, 'values.time', '');

  const onPress = slot => {
    const { setFieldValue } = formikProps;
    setFieldValue('time', slot.time_ini.substr(0, 5));
  };

  const getButtonType = slot => {
    const { available } = slot;
    const isSelected = selectedTime === slot.time_ini.substr(0, 5);
    return isSelected ? 'Primary' : available ? '' : 'Secondary';
  };

  return (
    <div className={styles.container}>
      {slots.map(slot => (
        <div className={styles.itemContainer} key={slot.time_ini}>
          <Button
            type={getButtonType(slot)}
            onClick={() => onPress(slot)}
            disabled={!slot.availability}
            label={`${slot.time_ini} - ${slot.time_end}`}
          />
        </div>
      ))}
    </div>
  );
};

export default SlotList;
