export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  es_ES: 'Período de viaje',
  pt_BR: 'Período da viagem',
};

export const LABEL_DATE_THEFT = {
  en_US: 'Date / time of theft',
  es_ES: 'Fecha / hora del robo',
  pt_BR: 'Data / hora do roubo',
};

export const LABEL_PLACE_THEFT = {
  en_US: 'Place of theft',
  es_ES: 'Lugar del robo',
  pt_BR: 'Local do roubo',
};
export const LABEL_SUBMITTED_COMPLAINT = {
  en_US: 'Have you submitted a formal complaint against the hotel?',
  es_ES: '¿Ha presentado una reclamación formal contra el hotel?',
  pt_BR: 'Você apresentou uma queixa formal contra o hotel?',
};
export const LABEL_PIR = {
  en_US: 'PIR',
  es_ES: 'PIR',
  pt_BR: 'PIR',
};
export const LABEL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  es_ES: 'Importe total de la reivindicación',
  pt_BR: 'Valor total do sinistro',
};

export const TEXT_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const TEXT_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
