export const TITLE_CLAIMS_PENDING = {
  en_US: total =>
    `You have ${total} ${
      total > 1 ? 'claims' : 'claim'
    } pending your documentation.`,
  es_ES: total =>
    `Tienes ${total} ${
      total > 1 ? 'reclamos' : 'reclamo'
    } con documentación pendiente.`,
  pt_BR: total =>
    `Você tem ${total} ${
      total > 1 ? 'sinistros' : 'sinistro'
    } com documentação pendente.`,
};

export const TITLE_CLAIMS_PENDING_ABOUT_TO_EXPIRED = {
  en_US: total =>
    `You have ${total} ${
      total > 1 ? 'claims' : 'claim'
    } pending your documentation about to expire.`,
  es_ES: total =>
    `Tienes ${total} ${
      total > 1 ? 'reclamos' : 'reclamo'
    } con documentación pendiente a punto de caducar.`,
  pt_BR: total =>
    `Você tem ${total} ${
      total > 1 ? 'sinistros' : 'sinistro'
    } com documentação pendente prestes a expirar.`,
};

export const SUBTITLE_CLAIMS_PENDING = {
  en_US: `Please attach the documentation required so that we can review your claim.`,
  es_ES: `Adjunta la documentación necesaria para que podamos analizar tu reclamo.`,
  pt_BR: `Anexe a documentação necessária para que possamos analisar seu sinistro.`,
};

export const LINK_CLAIMS = {
  en_US: `Go to claims pending your documentation`,
  es_ES: `Ir a tus reclamos con documentación pendiente`,
  pt_BR: `Ir para seus sinistros com documentação pendente`,
};

export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};

export const NOTIFICATION = {
  en_US: 'notification',
  es_ES: 'notificación',
  pt_BR: 'notificação',
};
