export const productInitialValues = (isWarranty = false) => {
  const values = {
    appliance_information: {
      appliance_description: '',
      brand: '',
      model: '',
      purchase_date: '',
      cost: {
        value: '',
        currency: '',
      },
    },
  };
  if (isWarranty)
    values.extended_warranty_information = {
      original_period: '',
    };
  return values;
};
