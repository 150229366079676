import React, { useContext } from 'react';
import * as translations from './intl';
import { GrClock } from 'react-icons/gr';
import styles from './Offers.module.scss';
import { IntlContext } from '../../../intl/index';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const Offers = ({
  offer,
  isInsideCarousel = true,
  isPublicArea,
  onHandleFavorited,
}) => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const history = useHistory();
  const theme = getGlobalTheme();

  const {
    id,
    image,
    company,
    shortOffer,
    shortDescription,
    expirationDate,
    isFavorite,
  } = offer;

  function handleNavigateTo() {
    history.push(
      isPublicArea ? `/offer-details/${id}` : `/your-offer-details/${id}`
    );
  }

  return (
    <div
      aria-label={intl.CARD_OFFER}
      className={
        isInsideCarousel
          ? styles.container
          : `col-lg-4 col-md-6 col-12 no-gutters noPaddingLeftRight ${styles.container}`
      }
    >
      <div
        className={`${styles.image}`}
        style={{ background: `url(${image && image.url})` }}
        onClick={handleNavigateTo}
      >
        <div className={styles.contentSale}>
          <div className={`${styles.boxLogo} ${styles[theme]}`}>
            <img src={company.logo.url} alt={intl.COMPANY_LOGO} />
          </div>
          <span className={styles[theme]}>{`${shortOffer}`}</span>
        </div>
      </div>

      <div className={styles.contentInfo}>
        {isPublicArea ? (
          <span
            className={`${styles.company} ${styles[theme]}`}
            onClick={handleNavigateTo}
          >
            {company.name}
          </span>
        ) : (
          <div className={styles.contenCompany}>
            <span
              className={`${styles.company} ${styles[theme]}`}
              onClick={handleNavigateTo}
            >
              {company.name}
            </span>
          </div>
        )}

        <div className={styles.containerInfo} onClick={handleNavigateTo}>
          <span className={`${styles.info} ${styles[theme]}`}>
            {shortDescription}
          </span>
          <div className={styles.contentDue}>
            <GrClock className={styles.icon} />
            <span className={styles[theme]}>{`${intl.TEXT_UNTIL} ${
              expirationDate
                ? moment(expirationDate).format('MM/yyyy')
                : '25/06/2121'
            }`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Offers;
