import React, { useContext } from 'react';
import moment from 'moment';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../components/Input/Input';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import * as constants from '../consts';
import Select from '../../../../../../../components/Select/Select';
import styles from './PersonalArea.module.scss';

const PersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    disableProspectFields,
  } = props;

  let getYesterday = moment(new Date(), 'YYYY-MM-DD HH:ss').subtract(1, 'days');
  let yesterday = new Date(getYesterday);
  const disableEmailInput =
    !values.benefitiaryIsNotCardHolder || disableProspectFields;

  return (
    <div className={`row ${styles.section}`}>
      <div className="col-12 col-sm-10 col-md-4 col-lg-4">
        <Input
          value={values.person.first_name}
          label={intl.LABEL_NAME}
          name="person.first_name"
          type="text"
          dataTestid="InputClaimCHFirstName"
          onChange={handleChange}
          touched={touched.person && touched.person.first_name}
          onBlur={handleBlur}
          error={errors.person && errors.person.first_name}
          disabled={disableProspectFields}
          maxLength="40"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <Input
          value={values.person.last_name}
          label={intl.LABEL_LAST_NAME}
          name="person.last_name"
          type="text"
          dataTestid="InputClaimCHLastName"
          onChange={handleChange}
          touched={touched.person && touched.person.last_name}
          onBlur={handleBlur}
          error={errors.person && errors.person.last_name}
          disabled={disableProspectFields}
          maxLength="80"
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6 col-lg-4">
        <Calendar
          label={intl.LABEL_BIRTH_DATE}
          name="person.birth_date"
          dataTestid="InputClaimCHBirthdate"
          maxDate={yesterday}
          formikProps={props}
          setFieldTouched={setFieldTouched}
          disabled={disableProspectFields}
        />
      </div>

      <div className="col-12 col-sm-2 col-md-6">
        <Select
          label={intl.LABEL_GENDER}
          value={values.person.gender}
          name="person.gender"
          onChange={handleChange}
          dataTestid="InputClaimCHTitle"
          touched={touched.person && touched.person.gender}
          onBlur={handleBlur}
          error={errors.person && errors.person.gender}
        >
          <option value="">{intl.LABEL_GENDER}</option>
          <option value={constants.MALE_VALUE}>{intl.LABEL_MALE}</option>
          <option value={constants.FEMALE_VALUE}>{intl.LABEL_FEMALE}</option>
          <option value={constants.UNKNOWN_VALUE}>{intl.LABEL_UNKNOWN}</option>
        </Select>
      </div>

      <div className="col-12 col-sm-12 col-md-6 col-lg-6">
        <Input
          value={values.email}
          label={intl.LABEL_EMAIL}
          name="email"
          type="text"
          disabled={disableEmailInput}
          dataTestid="InputClaiCHEmail"
          onChange={handleChange}
          touched={touched.email}
          onBlur={handleBlur}
          error={errors.email}
        />
      </div>
    </div>
  );
};

export default PersonalArea;
