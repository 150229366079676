export const TEXT_COLLECTION_INFORMATION = {
  en_US: 'Collection information',
  pt_BR: 'Informação de cobrança',
  es_ES: 'Información de Recaudo',
};

export const LABEL_AGENCY_NAME = {
  en_US: 'Name of collection agency',
  pt_BR: 'Nome da agência de cobrança',
  es_ES: 'Nombre de la agencia de cobros',
};

export const TEXT_CONTACT_PERSON = {
  en_US: 'Contact person',
  pt_BR: 'Pessoa para contato',
  es_ES: 'Persona de contacto',
};

export const LABEL_FIRST_NAME = {
  en_US: 'First name',
  pt_BR: 'Nome',
  es_ES: 'Nombre',
};

export const LABEL_LAST_NAME = {
  en_US: 'Last name',
  pt_BR: 'Sobrenome',
  es_ES: 'Apellido',
};

export const LABEL_NUMBER = {
  en_US: 'Telephone number',
  pt_BR: 'Número de telefone',
  es_ES: 'Número de teléfono',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};

export const LABEL_DATE_ASSIGMENT_MADE = {
  en_US: 'Date assignment made',
  pt_BR: 'Data de atribuição feita',
  es_ES: 'Asignación de fecha hecha',
};

export const LABEL_DATE_RECEIVED_CANCELLATION = {
  en_US: 'Date received account cancellation request',
  pt_BR: 'Pedido de cancelamento da conta recebida na data',
  es_ES: 'Fecha de recepción de la solicitud de cancelación de cuenta',
};

export const LABEL_DATE_ACCOUNT_CANCELLATION = {
  en_US: 'Date of account cancellation on base I',
  pt_BR: 'Data de cancelamento da conta na base I',
  es_ES: 'Fecha de cancelación de la cuenta em la base I',
};

export const LABEL_DATE_RECEIVED_AFFIDAVIT = {
  en_US: 'Date received affidavit of waiver claim',
  pt_BR: 'Data de recebimento da declaração de renúncia à renúncia',
  es_ES: 'Fecha recebida declaración jurada de exención',
};
