/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import styles from '../../Upload.module.scss';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import { useHistory } from 'react-router-dom';
import { IntlContext } from '../../../../../intl';
import FileItem from './FileItem/FileItem';
import * as translations from '../intl';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import {
  APPEAL_ENABLE,
  NOTIFICATIONS_ENABLE,
  REACT_APP_UPLOAD_CONCLUDE_ENABLE,
} from '../../../../../utils/environments_variables';
import { NotificationContext } from '../../../../../components/NotificationContextParent/NotificationContextParent';
import {
  ExpectedDocument,
  newDocumentAttached,
} from '../../../../../@types/APIs/ExpectedDocuments';
import { ClaimInfo } from '../../../../../@types/APIs/claim';
import { Attachment } from '../../../../../@types/APIs/Customer';
import UploadItem from './FileItem/UploadItem';

const createListDocumentUpload = (
  documents: ExpectedDocument[],
  claimInfo: ClaimInfo,
  claimId: string,
  isFromProfile: boolean,
  setAlreadyAttached: Dispatch<SetStateAction<Attachment[]>>
) => {
  return documents.map(data => {
    return (
      <UploadItem
        key={data.document_type_name}
        expectedDocument={data}
        claimInfo={claimInfo}
        claimId={claimId}
        isFromProfile={isFromProfile}
        setAlreadyAttached={setAlreadyAttached}
      />
    );
  });
};

export const redirectHome = (history, actions) => {
  history.push('/dashboard');
  actions.modal.closeModal();
};

export const redirectClaims = (history, actions) => {
  history.push('/profile/claims');
  actions.modal.closeModal();
};

const sortByRequiredFirst = (a: ExpectedDocument, b: ExpectedDocument) => {
  const isRequiredA = a.is_mandatory;
  const isRequiredB = b.is_mandatory;

  if (isRequiredA && !isRequiredB) {
    return -1;
  }
  if (!isRequiredA && isRequiredB) {
    return 1;
  }
  return 0;
};

interface ListFilesProps {
  data: {
    expectedDocuments: ExpectedDocument[];
    claimInfo: ClaimInfo;
  };
  claimId: string;
  isFromProfile: boolean;
  setDisableSubmit: Dispatch<SetStateAction<boolean>>;
  concludeUpload: () => void;
  textModalSubtitle: string;
}

const UploadFiles = (props: ListFilesProps) => {
  const {
    data,
    claimId,
    isFromProfile,
    setDisableSubmit,
    concludeUpload,
    textModalSubtitle,
  } = props;
  const { expectedDocuments, claimInfo } = data;
  const { attachments, statuses } = claimInfo;

  const [showedAppealedModalOnLoad, setShowedAppealedModalOnLoad] =
    useState(false);
  const [alreadyAttached, setAlreadyAttached] = useState(attachments);

  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const intl = translate(translations);
  const history = useHistory();
  const requiredDocuments = expectedDocuments.filter(
    document => document.is_mandatory === true
  );
  const expectedDocumentsOrdered = [...expectedDocuments].sort(
    sortByRequiredFirst
  );

  const listDocumentUpload = createListDocumentUpload(
    expectedDocumentsOrdered,
    claimInfo,
    claimId,
    isFromProfile,
    setAlreadyAttached
  );

  const checkComplete = () => {
    for (const requiredDoc of requiredDocuments) {
      const find = alreadyAttached.find(
        alreadyAttach =>
          alreadyAttach.document_type_name &&
          alreadyAttach.document_type_name === requiredDoc.document_type_name
      );
      if (!find) {
        return false;
      }
    }
    return true;
  };

  const getIsAppealed = () => {
    try {
      const lastStatus = statuses[statuses.length - 1];
      const { name, description } =
        lastStatus.sub_statuses[lastStatus.sub_statuses.length - 1];
      return name === 'Claims - Case appealed' && description
        ? [true, description]
        : [false, ''];
    } catch (error) {
      return [false, ''];
    }
  };

  useEffect(() => {
    if (REACT_APP_UPLOAD_CONCLUDE_ENABLE) {
      const [isAppealed, appealReason] = getIsAppealed();
      const showAppealModal =
        APPEAL_ENABLE && isAppealed && !showedAppealedModalOnLoad;

      if (showAppealModal) {
        setShowedAppealedModalOnLoad(showAppealModal);
        actions.modal.showModal(
          false,
          false,
          <ModalInformation
            type="success"
            message={intl.TITLE_APPEALED_MODAL}
            subtitle={intl.SUBTITLE_APPEALED_MODAL(appealReason)}
            textBtn={intl.BTN_ATTACH_DOCUMENTS}
          />,
          true
        );
      }

      if (checkComplete()) {
        setDisableSubmit(false);
        if (!showAppealModal) {
          actions.modal.showModal(
            false,
            false,
            <ModalInformation
              type="success"
              message={intl.CAN_SENT}
              subtitle={intl.IMPORTANT_COMPLETE_ATTACHMENTS}
              textBtn={intl.BTN_CONCLUDE}
              clickBtn={concludeUpload}
              textBtnDeny={intl.BTN_ATTACH_DOCUMENTS}
            />,
            true
          );
        }
      } else {
        setDisableSubmit(true);
      }
    } else {
      if (checkComplete()) {
        actions.modal.showModal(
          false,
          false,
          <ModalInformation
            type="success"
            message={intl.HAS_BEEN_SENT}
            subtitle={textModalSubtitle}
            textBtn={intl.GO_HOME}
            clickBtn={() => redirectHome(history, actions)}
            textBtnDeny={intl.YOUR_CLAIMS}
            clickBtnDeny={() => redirectClaims(history, actions)}
          />,
          true
        );
        if (NOTIFICATIONS_ENABLE) {
          notificationActions.bellNotification.updateBellNotification();
        }
      }
    }
  }, [alreadyAttached]);

  return (
    <div className={styles.filerListUpload}>
      <ul>{listDocumentUpload}</ul>
    </div>
  );
};

export default UploadFiles;
