export const BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const ARROW_LEFT = {
  en_US: 'Arrow Left',
  es_ES: 'Flecha Izquierda',
  pt_BR: 'Seta para a Esquerda',
};
