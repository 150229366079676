import React, { useContext } from 'react';
import BenefitItem from './BenefitItem/BenefitItem';
import styles from './PathItem.module.scss';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';

const PathItem = props => {
  const { title, description, image, benefits, headerStyle } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <div className={styles.itemContainer}>
      {title && (
        <div className={styles.header} style={headerStyle}>
          <h3 className={styles[theme]}>{title}</h3>
          <p>{description}</p>
        </div>
      )}

      <div className={styles.content}>
        {image && (
          <div className={styles.imageArea}>
            <img src={image} alt={intl.PATH_IMAGE} />
          </div>
        )}
        <ul className={styles.benefitsArea}>
          {benefits.map(benefit => (
            <BenefitItem
              key={benefit.benefitDescription}
              benefitIcon={benefit.benefitIcon}
              benefitName={benefit.benefitName}
              benefitDescription={benefit.benefitDescription}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PathItem;
