import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const QuestionsArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const formOfPayment =
    claimDetails && claimDetails.travel_information.form_of_payment
      ? claimDetails.travel_information.form_of_payment
      : '-';

  const totalAmountClaimedValue =
    claimDetails && claimDetails.total_amount_claimed.value
      ? claimDetails.total_amount_claimed.value
      : '-';

  const totalAmountClaimedCurrency =
    claimDetails && claimDetails.total_amount_claimed.currency
      ? claimDetails.total_amount_claimed.currency
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4" data-testid="formOfPayment">
        <InfoLabelValue label={intl.FORM_OF_PAYMENT} value={formOfPayment} />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TOTAL_AMOUNT_CLAIMED}
          value={`${totalAmountClaimedValue} - ${totalAmountClaimedCurrency}`}
        />
      </div>
    </>
  );
};

export default QuestionsArea;
