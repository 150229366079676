import { userServices } from '../../../services';
import { getCHDependents } from './getCHDependents';

export const loadData = async (cn, certificateType, setData) => {
  try {
    const cardHolderPromise = userServices.getCHInfo(cn);
    if (certificateType === 'travel_insurance') {
      const dependetsPromise = getCHDependents(cn);
      const responses = await Promise.all([
        cardHolderPromise,
        dependetsPromise,
      ]);
      const data = {
        cardHolder: responses[0].data,
        dependents: responses[1],
      };
      setData(data);
    } else {
      const response = await cardHolderPromise;
      const data = {
        cardHolder: response.data,
      };
      setData(data);
    }
  } catch (error) {
    setData('ERROR');
  }
};
