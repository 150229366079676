/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

export const IemsSchema = (intl, props) => {
  let object = {};

  object.affected_person = affectedPersonShema(intl);
  object.expenses = expensesShema(intl);
  object.medical_service = medicalServiceShema(intl);
  object.attending_physician = attendingSchema(intl);
  object.existing_case = existingCaseSchema(intl);
  object.amount_estimation_damage = amountDamageSchema(intl);
  object.other_insurance_covering = otherInsuranceSchema(intl);

  return Yup.object().shape(object);
};

const otherInsuranceSchema = intl => {
  const otherInsurance = Yup.object().shape({
    is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    value: Yup.string().when(
      ['is_internal'],
      {
        is: is_internal => {
          return is_internal === 'true';
        },
        then: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      },
      ['is_internal']
    ),
  });

  return otherInsurance;
};

const amountDamageSchema = intl => {
  const amountDamage = Yup.object().shape({
    value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return amountDamage;
};

const existingCaseSchema = intl => {
  const existingCase = Yup.object().shape({
    reported_agent_phone: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    number: Yup.mixed().when(['reported_agent_phone'], {
      is: reported_agent_phone => {
        return reported_agent_phone === 'true';
      },
      then: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
  });

  return existingCase;
};

const attendingSchema = intl => {
  const attending = Yup.object().shape({
    email: Yup.string()
      .email(intl.VALIDATE_EMAIL_VALID)
      .required(intl.TEXT_FIELD_REQUIRED),
    person: Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
    phone: Yup.object().shape({
      phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      international_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    }),
  });

  return attending;
};

const medicalServiceShema = intl => {
  const medical = Yup.object().shape({
    medical_center_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    medical_service_description: Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    ),
    assistance_started_at: Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED),
    assistance_finished_at: Yup.mixed().test(
      'match',
      intl.VALIDATE_DATE_VALID,
      function (to) {
        return moment(to).isSameOrAfter(
          moment(new Date(this.parent.assistance_started_at))
        );
      }
    ),
    diagnosis: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    medical_treatment_received: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
  });

  return medical;
};

const expensesShema = intl => {
  const expenses = Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      payment_mode: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      price: Yup.object().shape({
        currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    })
  );

  return expenses;
};

const affectedPersonShema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};
    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });
    if (value.relationship !== constants.CARD_HOLDER_VALUE) {
      affectedPerson.person = Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.mixed().test(
          'match',
          intl.VALIDATE_DATE_VALID,
          function (birth_date) {
            return moment(birth_date).isSameOrBefore(new Date());
          }
        ),
        registrations: validateRegistration(intl, value),
      });
    }
    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};

const validateRegistration = (intl, value) => {
  let registration;

  registration = Yup.object().shape({});

  if (value.relationship !== constants.CARD_HOLDER_VALUE) {
    registration = Yup.object().shape({
      registration_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      value: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .validatePassportByRegistrationType(
          intl.VALIDATE_CPF,
          value.person.registrations.registration_type
        ),
    });
  }

  return registration;
};
