import React, { useContext, useEffect } from 'react';
import * as translations from '../intl';
import { IntlContext } from '../../../../../../../../intl';
import Select from '../../../../../../../../components/Select/Select';
import Input from '../../../../../../../../components/Input/Input';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import sanitize from '../../../../../../../../utils/sanitize';

const AmountArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
    props;

  const setDefaultCurrency = event => {
    let itens = [...values.purchase_protection_items];
    itens.map(item => (item.price.currency = event.target.value));

    setFieldValue('amount_claimed.currency', event.target.value);
    setFieldValue(
      'other_insurance_covering.amount.currency',
      event.target.value
    );
    setFieldValue('total_amount_claimed.currency', event.target.value);
    setFieldValue('purchase_protection_items', itens);
  };

  const setCurrency = currency => {
    const event = { target: { value: currency } };
    setDefaultCurrency(event);
  };
  useEffect(() => {
    const currency = values.amount_claimed.currency;
    const getCurrency = getCurrencyByHomeCountry();

    if (currency) setCurrency(currency);
    else if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount_claimed.currency]);

  useEffect(() => {
    let sum = 0;
    values.purchase_protection_items.map(item => {
      sum += Number(item.price.value);
    });
    const result = sum !== 0 ? sum.toFixed(2) : '0';
    setFieldValue('amount_claimed.value', result);
  }, [setFieldValue, values.purchase_protection_items]);

  return (
    <div className="row">
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Input
          value={values.amount_claimed.value}
          label={intl.LABEL_TOTAL_REIMBURSED}
          name="amount_claimed.value"
          dataTestid="InputAmoutEstimationDamageValue"
          type="text"
          disabled
          maskedMoney
          onChange={handleChange}
          touched={touched.amount_claimed && touched.amount_claimed.value}
          onBlur={handleBlur}
          error={errors.amount_claimed && errors.amount_claimed.value}
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          dataTestid="selectAmountClaimedCurrency"
          label={intl.LABEL_CURRENCY}
          value={values.amount_claimed.currency}
          name="amount_claimed.currency"
          onChange={event => {
            setDefaultCurrency(event);
            handleChange(event);
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={touched.amount_claimed && touched.amount_claimed.currency}
          onBlur={handleBlur}
          error={errors.amount_claimed && errors.amount_claimed.currency}
        />
      </div>
    </div>
  );
};

export default AmountArea;
