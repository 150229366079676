export function closeModal() {
  document.body.removeEventListener('click', this.actions.modal.clickOut);
  const modal = {
    ...this.state.modal,
    show: false,
    error: null,
  };
  this.setState({ modal });
}

export function showModal(
  title,
  close,
  modalInnerComponent,
  duo = false,
  closeAndReload = false,
  css = null,
  loading = null,
  icon = null,
  borderBottomTitle = false,
  axa = false,
  iconSVG = false
) {
  const modal = {
    ...this.state.modal,
    title: title,
    show: true,
    close: close,
    innerComponent: modalInnerComponent,
    duo: duo,
    css: css,
    loading: loading,
    closeAndReload: closeAndReload,
    icon: icon,
    borderBottomTitle,
    axa,
    iconSVG,
  };
  this.setState({ modal });
  if (modalInnerComponent.props.closable)
    document.body.addEventListener('click', this.actions.modal.clickOut);
}

export function clickOut(e) {
  const modal = this.state.modal.modalRef.current;
  const outside = !modal.contains(e.target);
  if (outside) {
    document.body.removeEventListener('click', this.actions.modal.clickOut);
    this.actions.modal.closeModal();
  }
}

export function showLoading(message) {
  const modal = {
    ...this.state.modal,
    loadingMessage: message,
    loading: true,
    show: true,
  };

  this.setState({ modal });
}

export function hideLoading() {
  const modal = {
    ...this.state.modal,
    loadingMessage: '',
    loading: false,
  };

  this.setState({ modal });
}

export function showError(errorType) {
  const modal = {
    ...this.state.modal,
    error: errorType,
    show: true,
  };

  this.setState({ modal });
}

export function hideError() {
  const modal = {
    ...this.state.modal,
    error: null,
  };

  this.setState({ modal });
}
