export const MAIN_CARD = {
  en_US: 'Main card',
  es_ES: 'Tarjeta principal',
  pt_BR: 'Cartão principal',
};

export const MAKE_CARD = {
  en_US: 'Set as main card',
  es_ES: 'Establecer como principal',
  pt_BR: 'Definir como principal',
};

export const ADD_NEW_CARD = {
  en_US: 'Add a new card',
  es_ES: 'Agregar una nueva tarjeta',
  pt_BR: 'Adicionar um novo cartão',
};

export const CARD_TYPE_CREDIT = {
  en_US: 'Credit',
  es_ES: 'Crédito',
  pt_BR: 'Crédito',
};

export const CARD_TYPE_DEBIT = {
  en_US: 'Debit',
  es_ES: 'Débito',
  pt_BR: 'Débito',
};

export const CARD_TYPE_PREPAID = {
  en_US: 'Prepaid',
  es_ES: 'Prepago',
  pt_BR: 'Pré-pago',
};

export const CARD = {
  en_US: 'Card',
  es_ES: 'Tarjeta',
  pt_BR: 'Cartão',
};

export const DELETE = {
  en_US: 'Delete',
  es_ES: 'Eliminar',
  pt_BR: 'Excluir',
};

export const PLUS = {
  en_US: 'Plus',
  es_ES: 'Agregar',
  pt_BR: 'Adicionar',
};
