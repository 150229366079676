import { useContext } from 'react';
import styles from './Tooltip.module.scss';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import { IntlContext } from '../../intl';
import * as translations from './intl';

const Tooltip = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  return (
    <div
      className={`${styles.tooltipText} ${styles[themes]} ${
        props.className || ''
      }`}
    >
      <span data-tooltip datatitle={props.text} role="tooltip">
        <TooltipIcon axa={props.axa} aria-label={intl.TOOLTIP_ICON} />
      </span>
    </div>
  );
};

export default Tooltip;
