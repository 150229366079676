import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import styles from './IssuanceArea.module.scss';
import Input from '../../../../../../../components/Input/Input';

const IssuanceArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values } = props;
  return (
    <div className={`row ${styles.isIssuanceArea}`}>
      <div className="col-12 col-md-6">
        <Input
          value={values.travel_information.emission}
          label={intl.INSSUANCE_DATE}
          type="text"
          dataTestid="InputClaimCHEmission"
          disabled={true}
        />
      </div>
      <div className="col-12 col-md-6">
        <Input
          value={values.travel_information.validity}
          label={intl.VALIDITY}
          dataTestid="InputClaimCHValidity"
          type="text"
          disabled={true}
        />
      </div>
    </div>
  );
};

export default IssuanceArea;
