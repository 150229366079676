export const TITLE_INCREASE_YOUR_TRAVEL_COVERAGE = {
  en_US: 'Plans to increase your travel coverage',
  pt_BR: 'Planos para aumentar sua cobertura de viagem',
  es_ES: 'Mis Planes de Extensión',
};

export const TITLE_MY_PURCHASES = {
  en_US: 'My Purchases',
  pt_BR: 'Minhas Compras',
  es_ES: 'Mis Compras',
};
