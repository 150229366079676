import { en_countries } from '../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../intl/es_countries';
import { pt_countries } from '../../../../../../../intl/pt_countries';

export const COUNTRY_LABEL = {
  es_ES: 'País de cita *',
  en_US: 'Appointment country *',
  pt_BR: 'País do agendamento *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TITLE_CONTACT_PHONE = {
  en_US: 'Contact phone',
  pt_BR: 'Telefone para contato',
  es_ES: 'Telefóne de contacto',
};

export const LABEL_TIMEZONE = {
  es_ES: 'Zona horária *',
  en_US: 'Time zone *',
  pt_BR: 'Fuso horário *',
};
