import { formatGeneralInformation } from './formatGeneralInformation';
import { formatTravelInformation } from './formatTravelInformation';
import { cloneDeep } from 'lodash';
import { pt_BR } from '../../../../../../intl/idioms';
import { formatCertificateExtendedWarrantyBR } from './formatCertificateExtendedWarrantyBR';
import { fomartAutoRental } from './fomartAutoRental';
import { formatAppliance } from './formatAppliance';

export const formatData = props => {
  const data = formatGeneralInformation(props);
  const { certificateType, idiom } = props;
  const isBrazil = idiom === pt_BR;
  const specificFunctions = {
    travel_insurance: formatTravelInformation,
    certificate_seguro_compra: formatAppliance,
    certificate_extended_warranty: isBrazil
      ? formatCertificateExtendedWarrantyBR
      : formatAppliance,
    billete_auto_rental_insurance: fomartAutoRental,
  };
  const cloneData = cloneDeep(data);

  return specificFunctions[certificateType](cloneData, props);
};
