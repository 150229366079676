export const TEXT_NUMBER = {
  en_US: 'No: ',
  pt_BR: 'No: ',
  es_ES: 'No: ',
};

export const VALID_UNTIL = {
  en_US: 'Valid until: ',
  pt_BR: 'Valido até: ',
  es_ES: 'Válido hasta: ',
};
