export const benefitToClaimType = {
  Auto_Rental_Insurance: 'car_rental',
  Purchase_Protection: 'purchase_protection',
  Price_Protection: 'price_protection',
  Extended_Warranty: 'extended_warranty',
  Baggage_Delay: 'baggage',
  Trip_Delay: 'trip',
  Missed_Connection: 'missed_connection',
  Trip_Cancellation: 'trip',
  EMC_Emergency_Medical_Assistance: 'medical_service',
  Baggage_Loss: 'baggage',
  Travel_Accident_Insurance: 'travel_accident',
  Injured_Journay_Accidental_D_D: 'travel_accident',
  Corporate_Liability_Waiver: 'corporate_liability',
  Assault_Cash_Death: 'atm_assault',
  Cellphone_Protection: 'purchase_protection',
  Bag_Protection: 'purchase_protection',
  Satisfaction_Guarantee: 'purchase_protection',
  Hotel_Motel_Burglary: 'baggage',
  Rideshare: 'baggage',
  Legal_Protection: 'legal_protection',
  Enhanced_Purchase_and_eCommerce_Protection: 'purchase_protection',
  Identity_Theft: 'identity_theft',
  Fraudulent_Charges: 'identity_theft',
  Protected_Vacations: 'trip',
};
