import React, { useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../../../../intl';
import Textarea from '../../../../../../../components/Textarea/Textarea';
import styles from './ReasonArea.module.scss';
const ReasonArea = props => {
  const { values, handleChange, handleBlur, errors, touched } = props;
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);
  return (
    <div className="row">
      <div className="col-12">
        <Textarea
          value={values.call_reason}
          label={intl.LABEL_REASON}
          name="call_reason"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          touched={touched && touched.call_reason}
          error={errors && errors.call_reason}
          maxLength="255"
          className={styles.textArea}
          rows={3}
        />
      </div>
    </div>
  );
};

export default ReasonArea;
