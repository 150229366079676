export const TRIP_PERIOD = {
  en_US: 'Trip period',
  pt_BR: 'Período da viagem',
  es_ES: 'Período de viaje',
};
export const PRIOR_MISSED = {
  en_US: 'Portion prior to missed connection',
  pt_BR: 'Primeiro trajeto antes da perda de conexão',
  es_ES: 'Primer trayecto antes de la pérdida de conexión',
};
export const PORTION_MISSED = {
  en_US: 'Missed connection portion',
  pt_BR: 'Trajeto referente a perda de conexão',
  es_ES: 'Trayecto perdido',
};
export const DATE_MISSED = {
  en_US: 'Date/time of missed connection',
  pt_BR: 'Data / hora da perda de conexão',
  es_ES: 'Fecha / hora de la pérdida de conexión',
};
export const DESCRIPTION = {
  en_US: 'Reasons that led the missed connection',
  pt_BR: 'Motivos que causaram a perda de conexão',
  es_ES: 'Motivos que provocaron la pérdida de conexión',
};

export const TOTAL_AMOUNT = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};
