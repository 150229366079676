/* eslint-disable no-console */
import React, { useContext, useState, useEffect } from 'react';
import { IntlContext } from '../../../../intl';
import * as translations from '../../../../components/Sections/AllBenefits/BenefitsCategories/intl';
import styles from './YourBenefitsList.module.scss';
import ListCardBenefits from '../../../../components/ListCardBenefits/ListCardBenefits';
import {
  TRAVEL_BENEFITS,
  PURCHASE_PROTECTION,
  LIFESTYLE,
  CONCIERGE,
  getIconByTheme,
} from '../../../../utils/benefitCategory';
import { certificatesServices } from '../../../../services';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { productsAndBenefitsServices } from '../../../../services/index';
import { getUrlPdf } from '../../../../utils/certificate';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';

const getCategoryName = (category, intl) => {
  let name = '';

  switch (category) {
    case TRAVEL_BENEFITS:
      name = intl.TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION;
      break;
    case PURCHASE_PROTECTION:
      name = intl.TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION;
      break;
    case LIFESTYLE:
      name = intl.TITLE_BENEFITS_CATEGORY_LIFESTYLE;
      break;
    case CONCIERGE:
      name = intl.TITLE_BENEFITS_CATEGORY_CONCIERGE;
      break;
    default:
      break;
  }

  return name;
};

const getClientTypeProduct = async (bin, idiom) => {
  try {
    const { client_type } = (
      await productsAndBenefitsServices.getProductDetailByBinCode(bin, idiom)
    ).data;
    return client_type;
  } catch (error) {}
};

const YourBenefitsList = props => {
  const { benefits, productBinOrPan } = props;
  const { translate, idiomForApi, idiom } = useContext(IntlContext);
  const { isAfluent } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);
  const binOrPan = utils.getBin(true);
  const intl = translate(translations);
  const [certificates, setCertificates] = useState([]);

  const newBenefits =
    benefits &&
    benefits.map(value => {
      return {
        ...value,
        category: getCategoryName(value.categories, intl),
        image: getIconByTheme(value.images, isAfluent()),
        description: '',
        urlPDF: '',
      };
    });

  useEffect(() => {
    const getGenerated = async () => {
      try {
        const userCertificates =
          await certificatesServices.listCertificateTypesByBinNumber(
            binOrPan,
            idiomForApi()
          );
        let clientType;
        if (idiom === 'pt_BR') {
          clientType = await getClientTypeProduct(binOrPan, idiomForApi());
        }

        const listBenefit = userCertificates.data.map(certificate => {
          return {
            ...certificate,
            urlPDF:
              idiom === 'pt_BR' ? getUrlPdf(certificate.code, clientType) : '',
          };
        });

        setCertificates(listBenefit);
      } catch (error) {
        console.log(error);
        setCertificates([]);
      }
    };
    getGenerated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binOrPan]);

  return (
    <div className={`${styles.yourBanefitsList}`}>
      <div className="row">
        <ListCardBenefits
          certificates={certificates}
          benefitToSplit={newBenefits}
          benefitList={benefits}
          type={'benefits'}
          productBinOrPan={productBinOrPan}
        />
      </div>
    </div>
  );
};

export default YourBenefitsList;
