export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPT_TERMS = {
  en_US: '',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: '',
};

export const ACCEPT_TERMS_2 = {
  en_US: '',
  es_ES: 'Acepto utilizar Cabify como proveedor de taxi externo.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const EXECUTIVE_TRANSPORTATION = {
  en_US: '',
  pt_BR: '',
  es_ES: 'Servicio de Transporte al Aeropuerto',
};

export const TEXT_TERM_AND_CONDITIONS_CONTENT = {
  en_US: '',
  es_ES:
    'Para realizar tu reserva , por favor lee y acepta las condiciones que aplican:',
  pt_BR: '',
};
