/* eslint-disable no-console */
import { useContext, useState, useEffect } from 'react';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import {
  claimsServices,
  reimbursmentService,
  userServices,
} from '../../../services';
import { benefitToClaimType } from '../Generate/ClaimFillForm/Form/submit/benefitToClaimType';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import LegalAndConsent from './LegalAndConsent/LegalAndConsent';
import IncidentInformation from './IncidentInformation/IncidentInformation';
import Button from '../../../componentsV2/Button/Button';
import Loading from '../../../components/Loading/Loading';
import DividingLine from '../../../components/DividingLine/DividingLine';
import styles from './ClaimView.module.scss';
import * as translations from './intl';
import ModalInformation from '../../../components/ModalInformation/ModalInformation';
import BankIdentification from './BankInformation/BankIdentification';

const ClaimView = props => {
  const { claimId, claimType } = props;
  const { utils, actions } = useContext(StateContext);
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const [claimDetails, setClaimDetails] = useState();
  const [bankDetails, setBankDetails] = useState();
  const cn = utils.getCn();

  const loadClaimDetails = async (claimId, idiom, setClaimDetails) => {
    try {
      const response = await claimsServices.getCompleteInfoByClaimId(
        claimId,
        benefitToClaimType[claimType],
        idiom
      );

      setClaimDetails(response.data);
    } catch (err) {
      console.error(err.message);
      actions.modal.closeModal();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true
      );
    }
  };

  const loadGeneralInfo = async (cn, setUserInfo) => {
    try {
      const { data } = await userServices.getCHInfo(cn);
      setUserInfo(data);
    } catch (err) {
      console.error(err.message);
      actions.modal.closeModal();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true
      );
    }
  };

  const loadBankData = async () => {
    try {
      const reimbursements =
        await reimbursmentService.getAllReimbursmentClaimV2(claimId);

      if (
        reimbursements &&
        reimbursements.data &&
        reimbursements.data.length > 0
      ) {
        const { data } = await reimbursmentService.getReimbursmentV2(
          claimId,
          reimbursements.data[0].reimbursement_id
        );
        setBankDetails(data.beneficiary_account);
      } else {
        setBankDetails({});
      }
    } catch (err) {
      console.error(err.message);
      actions.modal.closeModal();
      actions.modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.UNEXPECTED_ERROR_OCCURRED}
        />,
        true
      );
      setBankDetails({});
    }
  };

  useEffect(() => {
    loadClaimDetails(claimId, idiom, setClaimDetails);
  }, [claimId, idiom]);

  useEffect(() => {
    loadGeneralInfo(cn, setUserInfo);
    loadBankData(cn);
  }, [cn]);

  useEffect(() => {
    if (claimDetails && userInfo && bankDetails && claimType) setLoading(false);
  }, [claimDetails, userInfo, bankDetails, claimType]);

  return (
    <div>
      {loading && (
        <div className={styles.loadingCenter}>
          <Loading />
        </div>
      )}
      {!loading && (
        <div className={styles.container}>
          <GeneralInformation
            holderDetails={claimDetails.policy.holder}
            userInfo={userInfo}
          />
          <DividingLine />
          <IncidentInformation
            claimDetails={claimDetails}
            claimType={claimType}
          />
          <DividingLine />
          <BankIdentification payment={bankDetails} />
          <DividingLine />
          <LegalAndConsent claimDetails={claimDetails} claimType={claimType} />
          <DividingLine />

          <div className={styles.buttonContainer}>
            <Button
              type="Secondary"
              onClick={() => actions.modal.closeModal()}
              label={intl.BUTTON_CLOSE}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ClaimView;
