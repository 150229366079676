/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { cotizarService } from '../../../../../../services/index';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';
import Select from '../../../../../../components/Select/Select';
import { VISA_0 } from '../../../../../../utils/B2C/constants';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { getCurrencyByFormType } from '../../../../../../utils/B2C/currencyISOCode';

const Installments = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [visaCoutas, setVisaCoutas] = useState(null);
  const { utils } = useContext(B2B2CContext);
  const selectedPlan = utils.getSelectedPlan();
  const { AmountWithoutPromotion } = selectedPlan;
  const [price, setPrice] = useState(
    Number(AmountWithoutPromotion)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = utils.getSelectedPlan();

        const coutasPayments = await cotizarService.cotizarCoutasPayment(
          props.formType,
          data
        );

        if (
          coutasPayments &&
          coutasPayments.data &&
          coutasPayments.data.B2CPaymentChannelConfiguration &&
          coutasPayments.data.B2CPaymentChannelConfiguration
            .PaymentChannelConfiguration.length > 0
        ) {
          const b2cCoutasConfiguration =
            coutasPayments.data.B2CPaymentChannelConfiguration
              .PaymentChannelConfiguration;

          const initalInstallments =
            b2cCoutasConfiguration[0].CreditCardInstallment.length > 0
              ? b2cCoutasConfiguration[0].CreditCardInstallment[0].Installment
              : 1;

          setVisaCoutas(
            b2cCoutasConfiguration.find(couta => couta.CreditCardId === VISA_0)
          );

          props.setFieldValue(
            'payment.installments',
            Number(initalInstallments)
          );
        }
      } catch (error) {
        setVisaCoutas(null);
      }
    };
    loadData();
  }, []);

  const mappedVisaPayment =
    visaCoutas &&
    visaCoutas.CreditCardInstallment &&
    visaCoutas.CreditCardInstallment.map(couta => {
      const { Installment, InterestRate } = couta;
      const value =
        AmountWithoutPromotion / Installment +
        (AmountWithoutPromotion / Installment) * (InterestRate / 100 / 100);

      return (
        <option value={Installment} key={Installment}>
          {Installment}x - {'$'}
          {value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
        </option>
      );
    });

  if (!visaCoutas) {
    return <div data-testid="nothingToShow"></div>;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Select
          axa
          label={intl.INSTALLMENTS}
          name="payment.installments"
          onChange={event => {
            const { value: installments } = event.target;

            const optionSelected = visaCoutas.CreditCardInstallment.find(
              item => item.Installment === Number(installments)
            );
            const valueInstallment =
              AmountWithoutPromotion / optionSelected.Installment +
              (AmountWithoutPromotion / optionSelected.Installment) *
                (optionSelected.InterestRate / 100 / 100);

            setPrice(
              Number(valueInstallment * installments)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
            );

            props.setFieldValue('payment.installments', Number(installments));
          }}
          formikProps={props}
        >
          {mappedVisaPayment}
        </Select>
      </div>
      <div className="col-12 col-md-6">
        <InfoLabelValue
          label={intl.TOTAL_TO_PAY}
          value={`$${price}${getCurrencyByFormType(props.formType)}`}
          axa
        />
      </div>
    </div>
  );
};

export default Installments;
