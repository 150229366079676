export const LABEL_CARD_STATUS = {
  en_US: 'Card status',
  pt_BR: 'Situação do cartão',
  es_ES: 'Estado de la tarjeta',
};

export const CARD_STATUS_AT_INCIDENT_DATE = {
  en_US: 'Card status at incident date',
  pt_BR: 'Situação do cartão na data do acidente',
  es_ES: 'Estado de la tarjeta en la fecha del accidente',
};

export const CARD_STATUS_ACTIVE = {
  en_US: 'Active',
  pt_BR: 'Ativo',
  es_ES: 'Activo',
};

export const CARD_STATUS_INACTIVE = {
  en_US: 'Inactive',
  pt_BR: 'Inativo',
  es_ES: 'Inactivo',
};

export const CARD_OPENING_DATE = {
  en_US: 'Card opening date',
  pt_BR: 'Data de abertura do cartão',
  es_ES: 'Fecha de apertura de la tarjeta',
};

export const CARD_EXPIRATION_DATE = {
  en_US: 'Card expiration date',
  pt_BR: 'Data de validade do cartão',
  es_ES: 'Fecha de vencimiento de la tarjeta',
};
