import moment from 'moment';

export const satisfaction_guarantee_toApi = props => {
  let object = {};
  object.purchase_protection_items = itemsToApi(props);
  object.incident = incidentToApi(props);
  object.affected_person = affected_personToApi(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const itemsToApi = props => {
  const items = props.values.incident.purchase_protection_items;
  return items.map(item => {
    const retailerReturningPeriod =
      item.return_period_type === 'D'
        ? 'P' + item.return_period + item.return_period_type
        : 'P0D' + item.return_period + item.return_period_type;
    return {
      label: item.label,
      manufacturer: item.manufacturer,
      model_number: item.model_number,
      price: {
        value: item.price.value.length > 0 ? Number(item.price.value) : '',
        currency: item.price.currency,
      },
      purchase_date: moment(item.purchase_date).format('YYYY-MM-DD HH:mm:ss'),
      is_the_damage_visible: true,
      is_repairable: true,
      was_refused_by_retailer: true,
      retailer_returning_period: retailerReturningPeriod,
      retailer_refusal_reason: item.retailer_refusal_reason,
      amount_claimed: {
        value:
          item.amount_claimed.value.length > 0
            ? Number(item.amount_claimed.value)
            : '',
        currency: item.price.currency,
      },
    };
  });
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;

  const date = moment(incident.dateValue).format('YYYY-MM-DD HH:mm:ss');

  return {
    date: date,
    description: incident.description,
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const total_amount_claimedToApi = props => {
  let items = props.values.incident.purchase_protection_items;
  let amountClaimed = 0;
  items.map(item => {
    amountClaimed += Number(item.amount_claimed.value);
  });

  const currency =
    props.values.incident.purchase_protection_items[0].price.currency;

  return {
    value: amountClaimed,
    currency,
  };
};
