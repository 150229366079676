import upsellBanner from '../../../assets/images/B2B2C/banner-extend-coverage.jpg';
import styles from './BenefitBlueRetangle.module.scss';

const BenefitBlueRetangle = () => {
  return (
    <div className={styles.iemsRectangle}>
      <img src={upsellBanner} />
      <div className={styles.blueRectangle}>
        <h2>Extiende tu cobertura</h2>
        <p>
          Ahora tienes la posibilidad de aumentar tu cobertura en Servicios
          Médicos de Emergencia Internacional hasta 1M de dolares para ti y tus
          seres queridos.{' '}
        </p>
        <button>
          <a
            href="/upsell-detail/EMC_Emergency_Medical_Assistance"
            target="_blank"
          >
            Mejora tu cobertura
          </a>
        </button>
      </div>
    </div>
  );
};

export default BenefitBlueRetangle;
