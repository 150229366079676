import React, { useContext } from 'react';
import Calendar from '../../../../../../../../../../components/Calendar/Calendar';
import Input from '../../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../../components/Select/Select';
import * as translations from '../../intl';
import { IntlContext } from '../../../../../../../../../../intl';
import sanitize from '../../../../../../../../../../utils/sanitize';
import Textarea from '../../../../../../../../../../components/Textarea/Textarea';

const ProductItem = props => {
  const {
    index,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    setFieldTouched,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const sumTotal = (value, index) => {
    let total = Number(value);
    if (
      values.purchase_protection_items[index].other_insurance_covering
        .is_internal === 'true'
    ) {
      total =
        total -
        Number(
          values.purchase_protection_items[index].other_insurance_covering
            .amount.value
        );
    }
    setFieldValue(
      `purchase_protection_items[${index}].amount_claimed.value`,
      total.toFixed(2)
    );
  };
  return (
    <>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].return_period}
          label={intl.LABEL_RETURN_PERIOD}
          name={`purchase_protection_items[${index}].return_period`}
          type="text"
          mask="maskedOnlyNumber"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].return_period
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].return_period
          }
          dataTestid="return_period"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Select
          label={intl.LABEL_RETURN_PERIOD_TYPE}
          value={values.purchase_protection_items[index].return_period_type}
          name={`purchase_protection_items[${index}].return_period_type`}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_RETURN_PERIOD_TYPE}</option> ${intl.RETURN_PERIOD_OPTIONS}`
            ),
          }}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].return_period_type
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].return_period_type
          }
          dataTestid="comboBoxReturnPeriodType"
        />
      </div>

      <div className="col-12">
        <Textarea
          label={intl.TEXT_RETAILER_REFUSAL_REASON}
          value={
            values.purchase_protection_items[index].retailer_refusal_reason
          }
          name={`purchase_protection_items[${index}].retailer_refusal_reason`}
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].retailer_refusal_reason
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].retailer_refusal_reason
          }
          maxLength="2000"
          dataTestid="retailerRefusalReason"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.purchase_protection_items[index].purchase_date}
          label={intl.LABEL_PURCHASE_DATE}
          name={`purchase_protection_items[${index}].purchase_date`}
          onChange={event => {
            handleChange(event);
          }}
          maxDate={new Date()}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].purchase_date
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].purchase_date
          }
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchase_protection_items[index].label}
          label={intl.LABEL_ITEM}
          name={`purchase_protection_items[${index}].label`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].label
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].label
          }
          maxLength="100"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].manufacturer}
          label={intl.LABEL_MANUFACTURER}
          name={`purchase_protection_items[${index}].manufacturer`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].manufacturer
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].manufacturer
          }
          maxLength="100"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].model_number}
          label={intl.LABEL_MODEL}
          name={`purchase_protection_items[${index}].model_number`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].model_number
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].model_number
          }
          maxLength="50"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].price.value}
          label={intl.LABEL_PURCHASE_PRICE}
          name={`purchase_protection_items[${index}].price.value`}
          type="text"
          maskedMoney
          onChange={event => {
            sumTotal(event.target.value, index);
            handleChange(event);
          }}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].price &&
            touched.purchase_protection_items[index].price.value
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].price &&
            errors.purchase_protection_items[index].price.value
          }
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items[0].price.currency}
          name={`purchase_protection_items[${index}].price.currency`}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid={`purchaseCurrencyPrice-${index}`}
          disabled={index !== 0}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].price &&
            touched.purchase_protection_items[index].price.currency
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].price &&
            errors.purchase_protection_items[index].price.currency
          }
        />
      </div>
    </>
  );
};
export default ProductItem;
