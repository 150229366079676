import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PDFIcon = props => {
  const { axa } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme, axa);
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M4 1V19H6V3H18V15H12V21H4V23H12C16.4111 23 20 19.4111 20 15V1H4ZM14 20.65V17H17.65C17.0462 18.6999 15.7 20.0462 14 20.65Z"
        fill={config_icon.secondary}
      />
      <path d="M16 5H8V7H16V5Z" fill={config_icon.primary} />
    </svg>
  );
};

export default PDFIcon;
