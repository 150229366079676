export const ITEM_PURCHASED = {
  en_US: 'Item purchased',
  pt_BR: 'Produto comprado',
  es_ES: 'Artículo comprado',
};

export const MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const NAME_STORE_WEBSITE = {
  en_US: 'Name of store/website',
  pt_BR: 'Nome da loja/website',
  es_ES: 'Nombre de la tienda',
};

export const ADVERTISEMENT_LOCATION = {
  en_US: 'Advertisement location',
  pt_BR: 'Localização do anúncio',
  es_ES: 'Ubicación del anuncio',
};

export const ORIGINAL_PURCHASE_PRICE = {
  en_US: 'Original purchase price',
  pt_BR: 'Preço de compra original',
  es_ES: 'Precio de la compra original',
};

export const NEW_ADVERTISE_PRICE = {
  en_US: 'New advertise price',
  pt_BR: 'Novo preço anunciado',
  es_ES: 'Nuevo precio anunciado',
};

export const TOTAL_PRICE_DIFFERENCE = {
  en_US: 'Total of the price difference',
  pt_BR: 'Total da diferença de preço',
  es_ES: 'Total de la diferencia de precio',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency',
  pt_BR: 'Moeda',
  es_ES: 'Moneda',
};
