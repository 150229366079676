export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATE_HOUR_VALID = {
  en_US: 'Please enter a value between 00:00 and 23:59.',
  pt_BR: 'Por favor, entre um valor entre 00:00 e 23:59.',
  es_ES: 'Por favor, introduzca un valor entre 00:00 y 23:59.',
};

export const VALIDATE_HOUR_GREATER = {
  en_US: 'Please enter a value greater than Hour of arrival at destination.',
  pt_BR: 'Por favor, entre um valor maior que a Hora de chegada.',
  es_ES: 'Por favor, introduzca un valor mayor que la Hora de llegada.',
};

export const VALIDATE_DATE_BETWEEN_START_END_TRIP = {
  en_US: 'Please enter a date between Start date of trip and End date of trip.',
  pt_BR:
    'Por favor, insira uma data entre Data de início da viagem e Data de fim de viagem.',
  es_ES:
    'Por favor, introduzca una fecha entre la Fecha de inicio del viaje y la Fecha de finalización del viaje.',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
