import React, { useState, useContext, useEffect } from 'react';
import LoadingError from '../../../components/LoadingError/LoadingError';
import { productsAndBenefitsServices } from '../../../services';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import * as translations from './intl';
import styles from './NurseLine.module.scss';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import NurseLineFormFill from './NurseLineFormFill/NurseLineFormFill';
import LoadingCircle from '../../../components/Loading/Loading';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { findBenefitsByExternalReference } from '../../../utils/benefits';

const loadData = async (binOrPan, setclaimTypeDetails, idiom) => {
  try {
    const idiomKey = idiom.replace('_', '-');
    const benefits = await productsAndBenefitsServices.getBenefitsByProduct(
      binOrPan,
      idiomKey,
      false,
      false
    );

    const { benefit_id } = findBenefitsByExternalReference(
      'Nurse_Advice_Line',
      benefits.data
    );

    const benefit =
      await productsAndBenefitsServices.getBenefitsDetailByProduct(
        binOrPan,
        benefit_id,
        idiomKey,
        false
      );
    setclaimTypeDetails(benefit.data);
  } catch (error) {
    setclaimTypeDetails('ERROR');
  }
};

const NurseLine = () => {
  const [claimDetails, setclaimTypeDetails] = useState('LOADING');
  const [showForm, setShowForm] = useState(false);
  const [acceptAllChecked, setAcceptAllChecked] = useState(false);
  const { utils } = useContext(StateContext);
  const { idiom, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const binOrPan = utils.getBin(true);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  useEffect(() => {
    loadData(binOrPan, setclaimTypeDetails, idiom);
  }, [claimDetails]); // eslint-disable-line

  const isLoading = claimDetails === 'LOADING';
  const isError = claimDetails === 'ERROR';
  const successLoad = !isLoading && !isError;

  const isTermsAndConditions = !showForm;
  return (
    <div className={`${styles[theme]} ${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingContainer}>
          <LoadingCircle />
        </div>
      )}
      {isError && (
        <div className={styles.loadingContainer}>
          <LoadingError color="#FFF" />
        </div>
      )}
      {successLoad && (
        <SessionContainer>
          <HeadingTitle
            title={claimDetails.name}
            image={claimDetails.images.url}
            description={intl.TEXT_DESCRIPTION_BENEFITS}
            subtitle={intl.TEXT_SUBTITLE}
            backText={intl.TEXT_BACK}
            backLink={'/your-benefits'}
          />
          {isTermsAndConditions && (
            <TermsAndConditions
              claimDetails={claimDetails}
              setShowForm={setShowForm}
              setAcceptAllChecked={setAcceptAllChecked}
              claimIEMS={claimDetails}
            />
          )}
          {showForm && (
            <NurseLineFormFill
              acceptChecked={acceptAllChecked}
              setShowForm={setShowForm}
            />
          )}
        </SessionContainer>
      )}
    </div>
  );
};

export default NurseLine;
