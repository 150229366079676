import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const MobileIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16 0H8C6.3457 0 5 1.3457 5 3V21C5 22.6543 6.3457 24 8 24H16C17.6543 24 19 22.6543 19 21V3C19 1.3457 17.6543 0 16 0ZM17 21C17 21.5518 16.5518 22 16 22H8C7.44873 22 7 21.5518 7 21V20H17V21ZM17 18H7V3C7 2.44873 7.44873 2 8 2H16C16.5518 2 17 2.44873 17 3V18Z"
        fill={config_icon.primary}
      />
      <path d="M15 4H9V6H15V4Z" fill={config_icon.secondary} />
    </svg>
  );
};

export default MobileIcon;
