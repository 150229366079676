import React, { useContext } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { getRelationship } from './dependentsUtils';
import {
  formatCPF,
  mask4FirstDigits,
} from '../../../../../../utils/stringUtils';
import { pt_BR } from '../../../../../../intl/idioms';

const cleanFormatCPF = cpf => {
  return cpf.replace(/\./g, '').replace(/-/g, '');
};

const DependentInfo = props => {
  const { dependent, showRelationship = true } = props;
  const { person, person_registrations } = dependent;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const isBR = idiom === pt_BR;
  const isCPF = person_registrations[0].registration_type === 'CPF';
  const isIDCARD = person_registrations[0].registration_type === 'ID_CARD';
  const registrationType = isCPF
    ? intl.LABEL_CPF
    : isIDCARD
    ? intl.LABEL_NATIONAL_ID
    : intl.LABEL_PASSPORT;
  const personRegistrationValue = isCPF
    ? formatCPF(mask4FirstDigits(cleanFormatCPF(person_registrations[0].value)))
    : mask4FirstDigits(person_registrations[0].value);

  const indexCPF = person_registrations.findIndex(
    registration => registration.registration_type === 'CPF'
  );
  const indexNotCPF = person_registrations.findIndex(
    registration =>
      registration.registration_type === 'ID_CARD' ||
      registration.registration_type === 'PASSPORT'
  );

  return (
    <>
      <div className="row">
        {showRelationship && (
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_RELATIONSHIP}
              value={getRelationship(dependent.relationship, intl)}
            />
          </div>
        )}
        <div
          className={`${
            showRelationship ? 'col-12 col-md-4' : 'col-12 col-md-3'
          }`}
        >
          <InfoLabelValue
            label={intl.LABEL_FIRST_NAME}
            value={person.first_name}
          />
        </div>
        <div className="col-12 col-md-5">
          <InfoLabelValue
            label={intl.LABEL_LAST_NAME}
            value={person.last_name}
          />
        </div>
      </div>
      {isBR ? (
        <div className="row">
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_CPF}
              value={
                indexCPF != -1 && person_registrations[indexCPF].value !== ''
                  ? formatCPF(
                      mask4FirstDigits(
                        cleanFormatCPF(person_registrations[indexCPF].value)
                      )
                    )
                  : '-'
              }
              withSanitize={indexCPF != -1}
            />
          </div>
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={
                indexNotCPF != -1 &&
                person_registrations[indexNotCPF].registration_type ===
                  'PASSPORT'
                  ? intl.LABEL_PASSPORT
                  : intl.LABEL_NATIONAL_ID
              }
              value={
                indexNotCPF != -1 &&
                person_registrations[indexNotCPF].value !== ''
                  ? mask4FirstDigits(person_registrations[indexNotCPF].value)
                  : '-'
              }
              withSanitize={indexNotCPF != -1}
            />
          </div>
          <div className="col-12 col-md-4">
            <InfoLabelValue label={intl.LABEL_EMAIL} value={dependent.email} />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={registrationType}
              value={personRegistrationValue ? personRegistrationValue : '-'}
            />
          </div>
          <div className="col-12 col-md-4">
            <InfoLabelValue label={intl.LABEL_EMAIL} value={dependent.email} />
          </div>
        </div>
      )}
    </>
  );
};

export default DependentInfo;
