/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';

import styles from './RedBarMessage.module.scss';
import { IntlContext } from '../../intl';

import * as translations from './intl';

const RedBarMessage = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { message } = props;

  return (
    <div className={`${styles.container} `}>
      <p>{message || intl.MAINTENANCE_MSG}</p>
    </div>
  );
};

export default RedBarMessage;
