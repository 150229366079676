export { ORIGIN_TO_DESTINATION } from '../../../intl/travels';

export const MY_TRIP = {
  en_US: 'My trips',
  pt_BR: 'Minhas viagens',
  es_ES: 'Mis viajes',
};

export const RETURN = {
  en_US: 'Return',
  pt_BR: 'Retorno',
  es_ES: 'Regreso',
};
export const OUTBOUND = {
  en_US: 'Outbound',
  pt_BR: 'Saída',
  es_ES: 'Salida',
};
