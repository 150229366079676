import React, { useContext } from 'react';
import Loading from '../../Loading/Loading';
import styles from './ModalLoading.module.scss';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';

const ModalLoading = props => {
  const { axa } = props;
  const { state } = useContext(StateContext);
  const { modal } = state;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const message = modal.loadingMessage ? modal.loadingMessage : intl.LOADING;

  return (
    <div className={styles.boxLoading}>
      <div className={styles.background}></div>
      <div className={styles.boxLoading}>
        <div className={styles.loading}>
          <Loading
            scale="0.8"
            text={message}
            color={axa ? 'blueAXA' : undefined}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalLoading;
