import React, { useContext } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Select from '../../../../../components/Select/Select';
import { ISOMx } from '../../../../../utils/B2C/currencyISOCode';

const TripArea = props => {
  const { CONFIG_B2B2C } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <div className="row">
      {CONFIG_B2B2C.quote.form.country_origin && (
        <div className="col-12 col-md-4">
          <Select
            label={intl.COUNTRY_ORIGIN}
            name="Quote.CountryOfResidenceIsoCode"
            formikProps={props}
            dangerouslySetInnerHTML={{
              __html: `<option value="">${intl.COUNTRY_ORIGIN}</option>${intl.NATIONALITY_OPTIONS}`,
            }}
            axa
          />
        </div>
      )}
      {CONFIG_B2B2C.quote.form.country_destination && (
        <div className="col-12 col-md-4">
          <Select
            label={intl.COUNTRY_DESTINATION}
            name="Quote.DestinationCountryIsoCode"
            dangerouslySetInnerHTML={{
              __html: `<option value="">${intl.COUNTRY_DESTINATION}</option>${intl.NATIONALITY_OPTIONS}`,
            }}
            formikProps={props}
            axa
          />
        </div>
      )}
      {CONFIG_B2B2C.quote.form.amount_currency && (
        <div className="col-12 col-md-4">
          <Select
            label={intl.CURRENCY_LABEL}
            name="Quote.AmountCurrencyISOCode"
            disabled
            formikProps={props}
            axa
          >
            <option value={ISOMx}>{intl.CURRENCY_MEXICAN_PESO}</option>
          </Select>
        </div>
      )}
    </div>
  );
};

export default TripArea;
