import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { pt_BR } from '../../../../../../intl/idioms';
import * as translations from '../intl';
import { formatCustomDate } from '../../../../../../utils/date';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const isFullChargedOnCard =
    claimDetails && claimDetails.item_information.is_full_charged_on_card;

  const incidentDate = claimDetails.incident.datetimeValue
    ? formatCustomDate(
        claimDetails.incident.datetimeValue,
        'YYYY-MM-DD HH:mm:ss',
        idiom,
        country
      )
    : '-';

  const purchaseDate = claimDetails.item_information.purchase_date
    ? formatCustomDate(
        claimDetails.item_information.purchase_date,
        'YYYY-MM-DD',
        idiom,
        country
      )
    : '-';

  const incidentDescription =
    claimDetails.incident && claimDetails.incident.description;

  const itemPurchasedLabel =
    claimDetails.item_information && claimDetails.item_information.label;

  const itemManufacturer =
    claimDetails.item_information && claimDetails.item_information.manufacturer;

  const itemModelNumber =
    claimDetails.item_information && claimDetails.item_information.model_number;

  const getIsFullChargedOnCard = (title, intl) => {
    switch (title) {
      case true:
        return intl.TEXT_YES;
      case false:
        return intl.TEXT_NO;
      default:
        return '';
    }
  };

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_IS_FULL_CHARGED_ON_CARD}
          value={getIsFullChargedOnCard(isFullChargedOnCard, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_PURCHASE_DATE} value={purchaseDate} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_INCIDENT_DATE} value={incidentDate} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DESCRIPTION}
          value={incidentDescription}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PURCHASED_ITEM}
          value={itemPurchasedLabel}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={itemManufacturer}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_PRODUCT_MODEL}
          value={itemModelNumber}
        />
      </div>
    </>
  );
};

export default IncidentArea;
