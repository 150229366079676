import React, { useContext } from 'react';
import styles from './PlanVerification.module.scss';
import { IntlContext } from '../../../../../intl/index';
import * as translations from '../intl';
import { getCurrencyByFormType } from '../../../../../utils/B2C/currencyISOCode';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const countTravellers = (ages, older) => {
  let count = 0;
  ages.forEach(age => {
    if (older) {
      if (Number(age.value) >= 70) {
        count++;
      }
    } else {
      if (Number(age.value) < 70) {
        count++;
      }
    }
  });
  return count;
};

const PlanVerification = props => {
  const {
    oldQuote,
    newQuote,
    selectedPlan,
    planSelectedWithNewQuote,
    formType,
  } = props;
  const {
    Quote: { Ages: oldAges },
  } = oldQuote;
  const {
    Quote: { Ages: newAges },
  } = newQuote;
  const { AmountWithoutPromotion: oldAmount } = selectedPlan;
  const {
    Product: { AmountWithoutPromotion: newAmount },
  } = planSelectedWithNewQuote;
  const symbolAmount = '$';

  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();

  return (
    <>
      <div className={styles.container}>
        <table className={styles[theme]}>
          <tbody>
            <tr className={styles[theme]}>
              <th className={styles[theme]}>{intl.INITIAL_VALUE}</th>
              <th className={styles[theme]}>{intl.CURRENT_VALUE}</th>
            </tr>
            <tr className={styles[theme]}>
              <td className={`${styles.travellers} ${styles[theme]}`}>{`${
                intl.PASSENGERS_UP_69_YEARS
              }${countTravellers(oldAges, false)}`}</td>
              <td className={`${styles.travellers} ${styles[theme]}`}>{`${
                intl.PASSENGERS_UP_69_YEARS
              }${countTravellers(newAges, false)}`}</td>
            </tr>
            <tr className={styles[theme]}>
              <td className={`${styles.travellers} ${styles[theme]}`}>{`${
                intl.PASSENGERS_70_YEARS_OR_OLDER
              }${countTravellers(oldAges, true)}`}</td>
              <td className={`${styles.travellers} ${styles[theme]}`}>{`${
                intl.PASSENGERS_70_YEARS_OR_OLDER
              }${countTravellers(newAges, true)}`}</td>
            </tr>
            <tr className={styles[theme]}>
              <td className={`${styles.amount} ${styles[theme]}`}>
                {`${symbolAmount}${oldAmount}${getCurrencyByFormType(
                  formType
                )}`}
              </td>
              <td className={`${styles.amount} ${styles[theme]}`}>
                {`${symbolAmount}${newAmount}${getCurrencyByFormType(
                  formType
                )}`}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className={styles.titleVerification}>
        {intl.IMPACTED_COVERAGE_AMOUNT}
      </p>
    </>
  );
};

export default PlanVerification;
