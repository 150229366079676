export const TEXT_CLAIMS_OPENED = {
  en_US: 'Claims opened',
  es_ES: 'Reclamos creados',
  pt_BR: 'Sinistros criados',
};

export const TEXT_CLAIMS_IN_PROCESS = {
  en_US: 'Claims in process',
  es_ES: 'Reclamos en proceso',
  pt_BR: 'Sinistros em andamento',
};

export const TEXT_VIEW_YOUR_CLAIM = {
  en_US: 'View my claims',
  es_ES: 'Ver mis reclamos',
  pt_BR: 'Ver meus sinistros',
};

export const TEXT_OPEN_A_CLAIM = {
  en_US: 'Open a claim',
  es_ES: 'Crear un reclamo',
  pt_BR: 'Abrir um sinistro',
};
