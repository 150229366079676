import { SVGIconProps } from '../../@types/Icons';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

const AddIcon = (props: SVGIconProps) => {
  const newProps = { ...props };
  if (!newProps.color) newProps.color = 'Duotone';

  const colors = useIconColors(newProps);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...handledProps}
    >
      <path d="M8 1V7" stroke={colors.primary} strokeWidth={2} />
      <path d="M8 11V15" stroke={colors.secondary} strokeWidth={2} />
      <path d="M1 8H15" stroke={colors.primary} strokeWidth={2} />
    </svg>
  );
};

export default AddIcon;
