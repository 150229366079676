export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const RENTAL_COMPANY_Q_VALUE = 'Rental company';

// PHONE
export const PHONE_MOBILE = 'MOBILE';
export const PHONE_LANDLINE = 'LANDLINE';

// EXPENSE
export const EXPENSE_MEDICAL = 'MEDICAL';
export const EXPENSE_MEDICINE = 'MEDICINE';
export const EXPENSE_DENTAL = 'DENTAL';
export const EXPENSE_TRANSPORT = 'TRANSPORT';
export const EXPENSE_ACCOMMODATION = 'ACCOMMODATION';
export const EXPENSE_OTHERS = 'OTHERS';
export const EXPENSE_OBJECT = {
  type: '',
  price: {
    currency: '',
    value: '',
  },
};
