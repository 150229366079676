/* eslint-disable no-unused-vars */

export const tripInitialValues = idiom => {
  return {
    travel_information: {
      is_international_trip: true,
      emission: new Date(),
      is_one_way_travel: false,
      from: '',
      to: '',
      country_origin: '',
      city_origin: '',
      country_destination: '',
      city_destination: '',
    },
  };
};
