import React, { useContext } from 'react';
import styles from './BannerAXAMarket.module.scss';
import * as translations from './intl';
import { useHistory } from 'react-router';
import { PublicContext } from '../../../components/PublicContextParent/PublicContextParent';
import { IntlContext } from '../../../intl';
import { enableUpselling } from '../../../utils/verifyAccess';
import { enableCrosselling } from '../../../utils/verifyAccessCrosselling';
import { CREATE_CONFIG_B2B2C } from '../../B2B2C/CONFIG_B2B2C';
import backgroundB2B2C from '../../../assets/images/B2B2C/AXABanner-Home.jpg';
import Button from '../../../componentsV2/Button/Button';

const ShortBannerAXAMarket = () => {
  const { productActive } = useContext(PublicContext);
  const { country, translate } = useContext(IntlContext);
  const history = useHistory();
  const intl = translate(translations);

  const B2B2C_Type = () => {
    if (!(productActive && productActive.name)) {
      return '';
    }

    if (enableUpselling(productActive.name, country, '')) {
      return 'upsell';
    } else if (enableCrosselling(productActive.name, country, '')) {
      return 'cross-sell';
    } else {
      return '';
    }
  };

  const CONFIG_B2B2C = CREATE_CONFIG_B2B2C({ path: B2B2C_Type() });
  const config =
    CONFIG_B2B2C && CONFIG_B2B2C.homeMarket && CONFIG_B2B2C.homeMarket(intl);

  if (!config) {
    return <></>;
  }

  return (
    <div
      className={styles.shortContainer}
      style={{ backgroundImage: `url(${backgroundB2B2C})` }}
    >
      <div className={styles.content}>
        <h2 className={styles.title}>{config.title}</h2>
        <Button
          type="Primary"
          surface="Inverse"
          onClick={() => {
            history.push(config.link);
          }}
          label={config.button}
        />
      </div>
    </div>
  );
};

export default ShortBannerAXAMarket;
