import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../Rideshare.module.scss';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import sanitize from '../../../../../../../../utils/sanitize';

const TotalAmountArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const amountClaimedValue = () => {
    let result = 0;
    values.incident.concerned_items.map(item => {
      if (item.price.value) {
        result += Number(item.price.value);
      }
    });
    result -= Number(values.other_insurance_covering.value);
    result -= Number(values.other_insurance_covering.rideshare.value);
    const value = result > 0 ? result.toFixed(2) : '0.00';
    return value;
  };

  const setCurrency = currency => {
    setFieldValue('total_amount_claimed.currency', currency);
    setFieldTouched('total_amount_claimed.currency', true);
  };

  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={` ${styles.incidentPeriodArea} ${styles.fontGray12} ${styles.options} row`}
        >
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Input
              label={intl.TEXT_TOTAL_AMOUNT}
              type="text"
              maskedMoney
              name="total_amount_claimed.value"
              disabled
              tooltipText={intl.TEXT_DECIMAL_SYMBOL}
              value={amountClaimedValue()}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.total_amount_claimed &&
                touched.total_amount_claimed.value
              }
              error={
                errors.total_amount_claimed && errors.total_amount_claimed.value
              }
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              dataTestid="selectAmountCurrency"
              label={intl.LABEL_CURRENCY}
              name="total_amount_claimed.currency"
              disabled
              value={values.incident.concerned_items[0].price.currency}
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.total_amount_claimed &&
                touched.total_amount_claimed.currency
              }
              error={
                errors.total_amount_claimed &&
                errors.total_amount_claimed.currency
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalAmountArea;
