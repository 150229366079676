/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './DownloadApp.module.scss';
import BannerDownloadApp from './Components/BannerDownloadApp/BannerDownloadApp';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import VisaCoveredBenefits from './VisaBenefits/VisaCoveredbenefits';
import Path from './Path/Path';
import Footer from './Footer/Footer';
import AppMobileIcon from '../../assets/icons/AppMobileIcon';
import BlueBoxMarketing from './Components/BlueBoxMarketing/BlueBoxMarketing';
import DisclaimerDownloadApp from './Components/DisclaimerDownloadApp/DisclaimerDownloadApp';
import TitlePageMarketing from './Components/TitlePageMarketing/TitlePageMarketing';
import { checkAccessDownloadApp } from '../../utils/downloadAppAccess';
import { withRouter } from 'react-router-dom';

const DownloadApp = props => {
  const [access, setAccess] = useState(false);
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  useEffect(() => {
    const checkAccess = checkAccessDownloadApp(country);
    if (!checkAccess) {
      props.history.push('/');
      return;
    }
    setAccess(true);
  }, [country]);
  return (
    <>
      {access ? (
        <div className={styles.container}>
          <BannerDownloadApp
            title={intl.BANNER_TITLE}
            icon={<AppMobileIcon width={60} height={60} />}
            image={intl.BANNER_IMAGE}
          />
          <div className={styles.content}>
            <div className={styles.infoContent}>
              <TitlePageMarketing
                title={intl.VISA_COVERED}
                subtitle={intl.VISA_ACCOMPANIES}
              />
              <VisaCoveredBenefits />
              <Path />
              <DisclaimerDownloadApp text={intl.TEXT_DISCLAIMER_DOWNLOAD_APP} />
              <Footer />
            </div>

            <div className={styles.blueBoxContent}>
              <BlueBoxMarketing subtitle={intl.ENJOY_BENEFITS} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withRouter(DownloadApp);
