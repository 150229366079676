import React, { useContext, useEffect, useState } from 'react';
import Calendar from '../../../../../../../../../../components/Calendar/Calendar';
import Input from '../../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../../components/Select/Select';
import * as translations from '../../intl';
import { IntlContext } from '../../../../../../../../../../intl';
import ItemStatusArea from './ItemStatusArea/ItemStatusArea';
import ItemOtherCoveringArea from './ItemOtherCoveringArea/ItemOtherCoveringArea';
import sanitize from '../../../../../../../../../../utils/sanitize';
const ProductItem = props => {
  const {
    index,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    setFieldTouched,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const sumTotal = (value, index) => {
    let total = Number(value);
    if (
      values.purchase_protection_items[index].other_insurance_covering
        .is_internal === 'false'
    ) {
      total =
        total -
        Number(
          values.purchase_protection_items[index].other_insurance_covering
            .amount.value
        );
    }
    setFieldValue(
      `purchase_protection_items[${index}].amount_claimed.value`,
      total.toFixed(2)
    );
  };

  useEffect(() => {
    values.purchase_protection_items.map((item, index) => {
      setFieldValue(
        `purchase_protection_items[${index}].total_invoice_amount.currency`,
        values.purchase_protection_items[0].price.currency
      );
    });
  }, [values.purchase_protection_items, setFieldValue]);

  return (
    <>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.purchase_protection_items[index].purchase_date}
          label={intl.LABEL_PURCHASE_DATE}
          name={`purchase_protection_items[${index}].purchase_date`}
          onChange={event => {
            handleChange(event);
          }}
          maxDate={new Date()}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].purchase_date
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].purchase_date
          }
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.purchase_protection_items[index].label}
          label={intl.LABEL_ITEM}
          name={`purchase_protection_items[${index}].label`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].label
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].label
          }
          maxLength="80"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].manufacturer}
          label={intl.LABEL_MANUFACTURER}
          name={`purchase_protection_items[${index}].manufacturer`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].manufacturer
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].manufacturer
          }
          maxLength="100"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.purchase_protection_items[index].model_number}
          label={intl.LABEL_MODEL}
          name={`purchase_protection_items[${index}].model_number`}
          type="text"
          onChange={handleChange}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].model_number
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].model_number
          }
          maxLength="50"
        />
      </div>
      <div className="col-12 col-sm-10 col-md-3">
        <Input
          value={values.purchase_protection_items[index].price.value}
          label={intl.LABEL_PURCHASE_PRICE}
          name={`purchase_protection_items[${index}].price.value`}
          type="text"
          maskedMoney
          onChange={event => {
            sumTotal(event.target.value, index);
            handleChange(event);
          }}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].price &&
            touched.purchase_protection_items[index].price.value
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].price &&
            errors.purchase_protection_items[index].price.value
          }
        />
      </div>
      <div className="col-12 col-sm-10 col-md-3">
        <Input
          value={
            values.purchase_protection_items[index].total_invoice_amount.value
          }
          label={intl.LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT}
          name={`purchase_protection_items[${index}].total_invoice_amount.value`}
          type="text"
          maskedMoney
          onChange={event => {
            handleChange(event);
          }}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].total_invoice_amount &&
            touched.purchase_protection_items[index].total_invoice_amount.value
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].total_invoice_amount &&
            errors.purchase_protection_items[index].total_invoice_amount.value
          }
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.purchase_protection_items[0].price.currency}
          name={`purchase_protection_items[${index}].price.currency`}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid={`purchaseCurrencyPrice-${index}`}
          disabled={index !== 0}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].price &&
            touched.purchase_protection_items[index].price.currency
          }
          onBlur={handleBlur}
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].price &&
            errors.purchase_protection_items[index].price.currency
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.PAYMENT_METHOD}
          name={`purchase_protection_items[${index}].paymentMethod`}
          value={values.purchase_protection_items[index].paymentMethod}
          dangerouslySetInnerHTML={{
            __html: `<option value="">${intl.PAYMENT_METHOD}</option>
            <option value="cash">${intl.METHOD_CASH}</option>
            <option value="installment">${intl.METHOD_FINANCING}</option>`,
          }}
          onChange={e => {
            setFieldValue(
              `purchase_protection_items[${index}].number_of_installments`,
              '0'
            );
            setFieldTouched(
              `purchase_protection_items[${index}].number_of_installments`,
              false
            );
            handleChange(e);
          }}
          onBlur={handleBlur}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].paymentMethod
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].paymentMethod
          }
        />
      </div>
      {values.purchase_protection_items[index].paymentMethod ===
        'installment' && (
        <>
          <div className="col-12 col-sm-12 col-md-6">
            <Select
              label={intl.INSTALLMENTS_NUMBER}
              name={`purchase_protection_items[${index}].number_of_installments`}
              value={
                values.purchase_protection_items[index].number_of_installments
              }
              onChange={handleChange}
              onBlur={handleBlur}
              touched={
                touched.purchase_protection_items &&
                touched.purchase_protection_items[index] &&
                touched.purchase_protection_items[index].number_of_installments
              }
              error={
                errors.purchase_protection_items &&
                errors.purchase_protection_items[index] &&
                errors.purchase_protection_items[index].number_of_installments
              }
            >
              <option value="0">{intl.INSTALLMENTS_NUMBER}</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">{intl.SIXTEEN_OR_MORE_INSTALLMENTS}</option>
            </Select>
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.INSTALLMENTS_PRICE}
              name={`purchase_protection_items[${index}].installment_price`}
              value={
                (+values.purchase_protection_items[index]
                  .number_of_installments !== 0 &&
                  (
                    Number(
                      values.purchase_protection_items[index]
                        .total_invoice_amount.value
                    ) /
                    (Number(
                      values.purchase_protection_items[index]
                        .number_of_installments
                    ) || 1)
                  )
                    .toFixed(2)
                    .toString()) ||
                '0'
              }
              maskedMoney
              type="text"
              disabled
              touched={
                touched.purchase_protection_items &&
                touched.purchase_protection_items[index] &&
                touched.purchase_protection_items[index].installment_price
              }
              error={
                errors.purchase_protection_items &&
                errors.purchase_protection_items[index] &&
                errors.purchase_protection_items[index].installment_price
              }
            />
          </div>
        </>
      )}
      <ItemStatusArea {...props} />
      <ItemOtherCoveringArea {...props} />
    </>
  );
};
export default ProductItem;
