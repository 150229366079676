/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Loading from '../components/Loading/Loading';
import { StateContext } from '../components/StateContextParent/StateContextParent';
import MainLayout from '../layouts/MainLayout/MainLayout';
import { PrivateRoutes, PrivateRoutesBinNotActived } from './PrivateRoutes';
import { PublicRoutes, PublicRoutesBinNotActived } from './PublicRoutes';
import LayoutRoute from '../components/LayoutRoute/LayoutRoute';
import { dataLayerCrossSiteTrack } from '../utils/GTM_helper';
import BinPartnerExecption from '../pages/BinPartnerExecption/BinPartnerExecption';
import EmptyLayout from '../layouts/EmptyLayout/EmptyLayout';

import Logout from '../pages/Logout/Logout';
import RegisterStep2 from '../pages/RegisterStep2/RegisterStep2';
import UpdateBinTokenex from '../components/UpdateBinTokenex/UpdateBinTokenex';
import Help from '../pages/Help/Help';
import {
  CARD_STATUS_ACTIVE,
  CARD_STATUS_EXPIRED,
  CARD_STATUS_INVALID,
} from '../utils/consts';

const styledDivFullPage = {
  display: 'flex',
  height: `calc(100vh - 200px)`,
  justifyContent: 'center',
  alignItems: 'center',
};

const updateBinToPan = cards => {
  const firstCard = cards[0];
  const status = firstCard.status
    ? firstCard.status.toUpperCase()
    : CARD_STATUS_ACTIVE;
  if (
    !firstCard ||
    !firstCard.is_token_required ||
    firstCard.credit_card_token ||
    status !== CARD_STATUS_ACTIVE
  )
    return false;
  if (
    firstCard &&
    firstCard.is_token_required &&
    !firstCard.credit_card_token &&
    status === CARD_STATUS_ACTIVE
  )
    return true;
};

const BinNotActive = cards => {
  const firstCard = cards[0];
  if (firstCard.status) {
    const status = firstCard.status.toUpperCase();

    return status === CARD_STATUS_EXPIRED || status === CARD_STATUS_INVALID;
  }
  return false;
};

const cardIsLess10digits = cards => {
  const firstCard = cards[0];

  return firstCard.external_reference.length < 10;
};

const trackInformation = (history, isLogged = true) => {
  if (history) {
    dataLayerCrossSiteTrack(
      history.pathname === '/' ? '/home' : history.pathname || '',
      isLogged
    );
  }
};

const proccessLoggedRoutes = (cards, logged, location, isUserPartner) => {
  switch (cards.status) {
    case 'LOADING':
      return (
        <MainLayout>
          <div style={styledDivFullPage}>
            <Loading />
          </div>
        </MainLayout>
      );
    case 'OK':
      if (isUserPartner && cards.data.length == 0) {
        trackInformation(location, logged);
        return (
          <Switch>
            <LayoutRoute
              exact={true}
              path="/signout"
              key="/signout"
              component={Logout}
              layout={MainLayout}
            />
            <LayoutRoute
              component={RegisterStep2}
              layout={MainLayout}
              isUserPartner={isUserPartner}
            />
          </Switch>
        );
      }
      if (cards.data.length === 0) {
        trackInformation(location, logged);
        return (
          <Switch>
            <LayoutRoute
              exact={true}
              path="/signout"
              key="/signout"
              component={Logout}
              layout={MainLayout}
            />
            <LayoutRoute component={RegisterStep2} layout={MainLayout} />
          </Switch>
        );
      } else if (updateBinToPan(cards.data)) {
        trackInformation(location, logged);
        return (
          <Switch>
            <LayoutRoute
              exact={true}
              path="/signout"
              key="/signout"
              component={Logout}
              layout={MainLayout}
            />
            ,
            <LayoutRoute
              exact={true}
              path="/help"
              key="/help"
              component={Help}
              layout={MainLayout}
            />
            <LayoutRoute component={UpdateBinTokenex} layout={MainLayout} />
          </Switch>
        );
      } else if (BinNotActive(cards.data)) {
        return (
          <>
            <Switch>
              {PrivateRoutesBinNotActived}
              {PublicRoutesBinNotActived}
            </Switch>
          </>
        );
      } else if (cardIsLess10digits(cards.data)) {
        trackInformation(location, logged);
        return (
          <Switch>
            <LayoutRoute
              exact={true}
              path="/signout"
              key="/signout"
              component={Logout}
              layout={MainLayout}
            />
            ,
            <LayoutRoute
              exact={true}
              path="/help"
              key="/help"
              component={Help}
              layout={MainLayout}
            />
            <LayoutRoute component={BinPartnerExecption} layout={EmptyLayout} />
          </Switch>
        );
      } else {
        trackInformation(location, logged);
        return (
          <Switch>
            {PrivateRoutes}
            {PublicRoutes}
          </Switch>
        );
      }

    default:
      return (
        <Switch>
          {PrivateRoutes}
          {PublicRoutes}
        </Switch>
      );
  }
};

function Routes() {
  const { state, utils } = useContext(StateContext);
  const { logged } = state.user;
  const { cards } = state;
  const location = useLocation();
  const isUserPartner = utils.isUserPartner();

  if (logged) {
    return proccessLoggedRoutes(cards, logged, location, isUserPartner);
  }

  return (
    <Switch>
      {PrivateRoutes}
      {PublicRoutes}
    </Switch>
  );
}

export default Routes;
