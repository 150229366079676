import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';
import { getThemeIcon } from '../../utils/configIcon';

const ArchiveIcon = props => {
  const { disabled = false } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M9 12H15"
        stroke={disabled ? config_icon.disabled : config_icon.secondary}
        strokeWidth={2}
      />
      <path
        d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V8H3V5Z"
        stroke={disabled ? config_icon.disabled : config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M4 8H20V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V8Z"
        stroke={disabled ? config_icon.disabled : config_icon.primary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default ArchiveIcon;
