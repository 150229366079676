import React from 'react';
import TripDelay from './TripDelay/TripDelay';
import CarRental from './CarRental/CarRental';
import PurchaseProtection from './PurchaseProtection/PurchaseProtection';
import TripCancellation from './TripCancellation/TripCancellation';
import MissedConnection from './MissedConnection/MissedConnection';
import ExtendedWarranty from './ExtendedWarranty/ExtendedWarranty';
import BaggageLoss from './BaggageLoss/BaggageLoss';
import CellPhoneProtection from './CellPhoneProtection/CellPhoneProtection';
import BagProtection from './BagProtection/BagProtection';
import Iems from './Iems/Iems';
import PriceProtection from './PriceProtection/PriceProtection';
import TravelAccidentInsurance from './TravelAccidentInsurance/TravelAccidentInsurance';
import BaggageDelay from './BaggageDelay/BaggageDelay';
import CorporateLiabilityWaiver from './CorporateLiabilityWaiver/CorporateLiabilityWaiver';
import InjuredJournayAccidental from './InjuredJournayAccidental/InjuredJournayAccidental';
import SatisfactionGuarantee from './SatisfactionGuarantee/SatisfactionGuarantee';
import HotelBurglary from './HotelBurglary/HotelBurglary';
import ATM from './ATM/ATM';
import Rideshare from './Rideshare/Rideshare';
import EnhancedPurchaseEcommerce from './EnhancedPurchaseEcommerce/EnhancedPurchaseEcommerce';
import LegalProtection from './LegalProtection/LegalProtection';
import IdTheftProtection from './IdTheftProtection/IdTheftProtection';
import FraudulentCharges from './FraudulentCharges/FraudulentCharges';
import ProtectedVacations from './ProtectedVacations/ProtectedVacations';

const NotSet = () => {
  return <h1>Claim Type Not Set</h1>;
};

const components = {
  Trip_Delay: TripDelay,
  Auto_Rental_Insurance: CarRental,
  Purchase_Protection: PurchaseProtection,
  Trip_Cancellation: TripCancellation,
  Missed_Connection: MissedConnection,
  Extended_Warranty: ExtendedWarranty,
  Baggage_Loss: BaggageLoss,
  Cellphone_Protection: CellPhoneProtection,
  Bag_Protection: BagProtection,
  EMC_Emergency_Medical_Assistance: Iems,
  Price_Protection: PriceProtection,
  Travel_Accident_Insurance: TravelAccidentInsurance,
  Baggage_Delay: BaggageDelay,
  Corporate_Liability_Waiver: CorporateLiabilityWaiver,
  Injured_Journay_Accidental_D_D: InjuredJournayAccidental,
  Assault_Cash_Death: ATM,
  Satisfaction_Guarantee: SatisfactionGuarantee,
  Hotel_Motel_Burglary: HotelBurglary,
  Rideshare: Rideshare,
  Legal_Protection: LegalProtection,
  Enhanced_Purchase_and_eCommerce_Protection: EnhancedPurchaseEcommerce,
  Identity_Theft: IdTheftProtection,
  Fraudulent_Charges: FraudulentCharges,
  Protected_Vacations: ProtectedVacations,
};
const IncidentInformation = props => {
  const Component = components[props.claimType] || NotSet;
  return <Component {...props} />;
};

export default IncidentInformation;
