import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const TotalReimbursedIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M14.9 11.8c-1.6 0-2.8-1.2-2.8-2.8s1.3-2.8 2.8-2.8 2.8 1.2 2.8 2.8-1.3 2.8-2.8 2.8zm0-3.7c-.5 0-1 .4-1 .9s.4.9 1 .9c.5 0 1-.4 1-.9-.1-.5-.5-.9-1-.9z"
      />
      <path
        style={{
          fill: config_icon.primary,
        }}
        d="M5.8 3.2v11.7h18.1V3.2H5.8zM18.5 13h-7.4c0-.2.1-.4.1-.6 0-1.6-1.3-3-3-3-.2 0-.4 0-.6.1v-1c.2 0 .4.1.6.1 1.6 0 3-1.3 3-3 0-.2 0-.4-.1-.6h7.4c0 .2-.1.4-.1.6 0 1.6 1.3 3 3 3 .2 0 .4 0 .6-.1v1c-.2 0-.4-.1-.6-.1-1.6 0-3 1.3-3 3 .1.2.1.4.1.6zm3.6-6.5c-.1 0-.1 0 0 0-.2.2-.4.2-.6.2-.6 0-1.1-.5-1.1-1.1 0-.2.1-.4.1-.5H22v1.4zM9.2 5c.1.2.2.4.2.6 0 .6-.5 1.1-1.1 1.1-.2 0-.4-.1-.5-.1V5h1.4zm-1.5 6.5c.2-.1.4-.2.6-.2.6 0 1.1.5 1.1 1.1 0 .2-.1.4-.2.6H7.7v-1.5zM20.5 13c-.1-.2-.2-.4-.2-.6 0-.6.5-1.1 1.1-1.1.2 0 .4.1.6.2V13h-1.5zM2.9 5.4h1.9v12.2H2.9z"
      />
      <path
        style={{
          fill: config_icon.primary,
        }}
        d="M3 16.1h19.9V18H3z"
      />
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M.1 8.3H2v12.2H.1z"
      />
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M.1 19H20v1.9H.1z"
      />
    </svg>
  );
};

export default TotalReimbursedIcon;
