/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';

const ScriptTokenex = () => {
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  trackEventUserAction('#### O SCRIPT DO TOKENEX SERÁ CRIADO ####');
  useEffect(() => {
    try {
      var scriptTokenex = document.createElement('script');
      scriptTokenex.type = 'text/javascript';
      scriptTokenex.async = true;
      scriptTokenex.src = `${process.env.REACT_APP_BASE_URL_SCRIPT_TOKENEX}/Iframe/iframe-v3.min.js`;
      document.body.appendChild(scriptTokenex);
      trackEventUserAction('#### O SCRIPT DO TOKENEX SRC', scriptTokenex);
    } catch (error) {
      trackEventUserAction('#### ERRO CARREGAMENTO SCRIPT TOKENEX', error);
      if (error) {
        trackEventUserAction(
          '#### ERRO MESSAGE CARREGAMENTO SCRIPT TOKENEX ####',
          {
            error,
            message: error.message,
            stack: JSON.stringify(error.stack),
          }
        );
      }
    }
  }, []);
  return <></>;
};

export default ScriptTokenex;
