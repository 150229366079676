export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship *',
  pt_BR: 'Parentesco *',
  es_ES: 'Relación *',
};

export const LABEL_RELATIONSHIP_VALUE_SON = {
  en_US: 'Son',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_RELATIONSHIP_VALUE_DAU = {
  en_US: 'Daughter',
  pt_BR: 'Filha',
  es_ES: 'Hija',
};

export const LABEL_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const LABEL_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const VALUE_RELATIONSHIP_VALUE_SON = {
  en_US: 'Son',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const VALUE_RELATIONSHIP_VALUE_DAU = {
  en_US: 'Daughter',
  pt_BR: 'Filha',
  es_ES: 'Hija',
};

export const VALUE_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};
