import React, { useEffect, useContext, useState } from 'react';
import styles from './FakeQuote.module.scss';
import * as translations from './intl';
import { mockQuote } from './MockQuote';
import { submit } from '../FormQuote/submit/submit';
import Button from '../../../../componentsV2/Button/Button';
import ProductCard from './ProductCard/ProductCard';
import { formatPlans } from '../ListPlans/formatBenefits';
import { IntlContext } from '../../../../intl';
import Loading from '../../../../components/Loading/Loading';
import { useHistory } from 'react-router-dom';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { dataLayerB2B2C } from '../../../../utils/GTM_helper';
import { PublicContext } from '../../../../components/PublicContextParent/PublicContextParent';
import { UPSELL } from '../../CONFIG_B2B2C';

const FakeQuote = props => {
  const { benefitDetail, productType } = props;
  const [quote, setQuote] = useState();
  const [loading, setLoading] = useState(true);
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const { productActive } = useContext(PublicContext);
  const history = useHistory();
  const card = utils.isUserLogged() ? utils.getSelectedCard() : productActive;
  const intl = translate(translations);

  const loadQuote = async () => {
    try {
      setLoading(true);
      const response = await submit(mockQuote(card.name, productType));
      setQuote(response.data.QuoteVISA);
    } catch (error) {
      setQuote({ ProductVISA: [] });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadQuote();
  }, [productActive]);

  if (quote && quote.ProductVISA && quote.ProductVISA.length === 0) {
    return <></>;
  }

  return (
    <div id="mainContent" className={styles.container}>
      {loading && (
        <div className={styles.loadingContainer}>
          <Loading />
        </div>
      )}
      {!loading && quote && quote.ProductVISA && quote.ProductVISA.length > 0 && (
        <div className={styles.content}>
          <h2 className={styles.title}>
            {intl.COVERAGE_PRODUCTS_TITLE(card.name)}
          </h2>

          <div className={styles.productsArea}>
            {formatPlans(quote.ProductVISA)
              .slice(productType === UPSELL ? 1 : 0)
              .map((product, index) => (
                <ProductCard plan={product} key={index} />
              ))}
          </div>

          <div className={styles.footer}>
            <Button
              type="Primary"
              onClick={() => {
                dataLayerB2B2C('Cotizar cobertura Extendida con AXA');
                history.push(
                  productType === UPSELL
                    ? `/upsell-benefit/${benefitDetail.external_reference}`
                    : `/cross-sell-benefit/${benefitDetail.external_reference}`
                );
              }}
              axa
              label={intl.BUTTON_COTIZAR}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FakeQuote;
