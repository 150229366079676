export const LABEL_REASON = {
  es_ES: 'Motivo de la cita *',
  en_US: 'Reason for the appointment *',
  pt_BR: 'Motivo do agendamento *',
};

export const TITLE_PHOTOS = {
  es_ES: 'Fotos',
  en_US: 'Pictures',
  pt_BR: 'Fotos',
};
