export const TEXT_PHONE_AREA = {
  en_US: 'Airline phone number',
  pt_BR: 'Número de telefone da companhia aérea',
  es_ES: 'Número de teléfono de la aerolínea',
};

export const VALUE_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const VALUE_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};
