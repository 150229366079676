import React from 'react';
import ButtonSortList, {
  SORT_ASC,
} from '../../../../components/ButtonSortList/ButtonSortList';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import styles from './VoucherHeader.module.scss';

const VoucherHeader = ({
  id,
  title,
  number,
  subtitle,
  listVouchers,
  setListVouchers,
}) => {
  const sortingASC = () => {
    let listAsc = listVouchers.sort(
      (a, b) => new Date(b.redeemed_at) - new Date(a.redeemed_at)
    );
    setListVouchers([...listAsc]);
  };

  const sortingDESC = () => {
    let listDesc = listVouchers.sort(
      (a, b) => new Date(a.redeemed_at) - new Date(b.redeemed_at)
    );
    setListVouchers([...listDesc]);
  };

  return (
    <header className={styles.headerContainer} id={id}>
      <TitleSectionProfile title={title} number={number} subtitle={subtitle} />

      <ButtonSortList
        initialOrder={SORT_ASC}
        actionToSortASC={sortingASC}
        actionToSortDESC={sortingDESC}
      />
    </header>
  );
};

export default VoucherHeader;
