import React, { useContext } from 'react';
import styles from './ProductItem.module.scss';
import cardImg from '../../../../../assets/images/cards_2.svg';
import rectangle from '../../../../../assets/images/BlueRectangle.png';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { formatProductType } from '../../formatProductType';
import { IntlContext } from '../../../../../intl';
import * as translations from '../../intl';
import { dataLayerCardTrack } from '../../../../../utils/GTM_helper';

const getImage = images => {
  if (!images) return cardImg;
  let findImage = images.find(image => image.type === 'PICTURE');
  return findImage && findImage.content_url.length > 0
    ? findImage.content_url
    : rectangle;
};

const ProductItem = props => {
  const { card, selected, setCardSelected, dragging } = props;
  const image = getImage(card.images);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const cardType = formatProductType(card);

  const select = () => {
    if (dragging) return;
    setCardSelected(card);
    const type =
      cardType === 'C' ? 'Credit' : cardType === 'D' ? 'Debit' : 'Prepaid';
    dataLayerCardTrack(`${type} ${card.name}`);
  };

  return (
    <div
      aria-label={`${card.name}_${card.product_id}`}
      className={styles.containerCardItem}
      onClick={() => select()}
    >
      <img src={image} alt="card" title={`${card.name} (${card.type})`} />
      <div className={styles.cardInfo}>
        <span className={`${styles[theme]} ${styles.cardType}`}>
          {cardType === 'C'
            ? intl.CREDIT
            : cardType === 'D'
            ? intl.DEBIT
            : intl.PREPAID}
        </span>
        <span
          className={`${styles.cardName} ${
            selected
              ? `${styles[theme]} ${styles.cardSelected}`
              : `${styles[theme]}`
          }`}
        >
          {card.name}
        </span>
      </div>
    </div>
  );
};

export default ProductItem;
