import moment from 'moment';
import { cloneDeep } from 'lodash';

export const trip_delayToApi = props => {
  let object = {};

  let other_insurance_covering = [];

  object.affected_person = affected_personToApi(props);

  object.trip_information = trip_informationToApi(props);

  object.incident = incidentToApi(props);

  object.total_amount_claimed = total_amount_claimedToApi(props);

  if (props.values.incident.other_insurance_covering.is_internal === 'true') {
    other_insurance_covering.push(
      other_insurance_coveringToApi(
        props.values.incident.other_insurance_covering,
        'TRANSPORT'
      )
    );
  }

  if (props.values.incident.other_insurance_covering2.is_internal === 'true') {
    other_insurance_covering.push(
      other_insurance_coveringToApi(
        props.values.incident.other_insurance_covering2,
        'OTHER'
      )
    );
  }

  if (other_insurance_covering.length > 0) {
    object.other_insurance_covering = other_insurance_covering;
  }

  if (props.values.incident.hasAdditional === 'true') {
    const additional_passengers = cloneDeep(
      props.values.incident.additional_passengers
    );
    additional_passengers.map((value, index) => {
      const birthDate = value.birth_date;
      additional_passengers[index].nationalities = [value.nationalities];
      additional_passengers[index].birth_date =
        moment(birthDate).format('YYYY-MM-DD');
    });
    object.additional_passengers = additional_passengers;
  }

  return object;
};

const trip_informationToApi = props => {
  const trip_information = props.values.incident.trip_information;
  const departure_date = moment(
    trip_information.planed_trip.departure_date
  ).format('YYYY-MM-DD');
  const arrival_date = moment(trip_information.planed_trip.arrival_date).format(
    'YYYY-MM-DD'
  );
  const reason = trip_information.reason;

  return {
    planed_trip: {
      departure_date: departure_date,
      arrival_date: arrival_date,
    },
    type: 'TRIP_DELAY',
    reason,
  };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;
  const start_date = moment(incident.start_date).format('YYYY-MM-DD');
  return {
    start_date: start_date,
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;
  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const other_insurance_coveringToApi = (
  other_insurance_covering,
  description
) => {
  return {
    is_internal: false,
    description,
    amount: {
      value: Number(other_insurance_covering.value),
      currency: other_insurance_covering.currency,
    },
  };
};

const total_amount_claimedToApi = props => {
  return {
    value: Number(props.values.incident.total_amount_claimed.value),
    currency: props.values.incident.total_amount_claimed.currency,
  };
};
