import { useState, useRef } from 'react';
import MaskedInput from 'react-text-mask';
import styles from './Input.module.scss';
import * as masks from './masks';
import * as translations from './intl';
import { safeGet } from '../../utils/object';
import { removeNonNumeric } from '../../utils/stringUtils';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { sanitize } from 'dompurify';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import { useIntl } from '../../intl';

const removeRequiredCharLabel = data => {
  return data && data.slice(-1) === '*'
    ? data.substring(0, data.length - 1)
    : data;
};

const Input = props => {
  let {
    type,
    label,
    name,
    value,
    error,
    onChange,
    onBlur,
    touched,
    defaultValue,
    disabled,
    showMask,
    guide,
    tooltipText,
    tooltipContentClass,
    errorLeftAlign,
    removeBottonBarColor,
    labelFlex,
    formikProps,
    bottomLabel,
    onFocus,
    hideTopLabel,
    textTooBig,
    maxLength = '',
    onKeyPress = null,
    notShowValidationMessage,
    axa,
    labelErrorClassName,
    autoFocus,
    Icon,
  } = props;

  const [focusInElement, setFocusInElement] = useState(false);
  const { getGlobalTheme } = useTheme(axa);
  const { translate } = useIntl();
  const inputRef = useRef();
  const intl = translate(translations);
  const themes = getGlobalTheme();

  if (formikProps) {
    onChange = onChange ?? formikProps.handleChange;
    onBlur = onBlur || formikProps.handleBlur;
    error = safeGet(formikProps, 'errors.' + name);
    touched = safeGet(formikProps, 'touched.' + name);
    value = value ?? safeGet(formikProps, 'values.' + name);
  }
  let className = `form-control ${styles.Input}`;

  let showGuide = false;
  let showMaskValue = false;
  if (error && touched) className += ' ' + styles.error;
  if (value && !error) className += ' ' + styles.value;
  if (guide) showGuide = true;
  if (showMask) showMaskValue = true;

  const errorLabelClassName = `${styles.errorLabel} ${
    errorLeftAlign ? styles.errorLeft : ''
  }  ${labelErrorClassName ? ` ${labelErrorClassName}` : ''}`;
  let labelClassName = `${styles.label} ${labelFlex ? styles.labelFlex : ''} ${
    styles[themes]
  }`;
  if (textTooBig) labelClassName += `${styles.textTooBig}`;

  if (removeBottonBarColor) className += ' ' + styles.notColorful;
  const formikError = error && '1';
  if (props.maskedMoney) {
    const _onChange = onChange;
    onChange = e => {
      let value = e.target.value;
      value = removeNonNumeric(value);

      if (value.length >= 3) {
        const decimal = value.substr(value.length - 2);
        const integer = value.substring(0, value.length - 2);
        if (integer.length > 8) {
          return;
        }
        value = `${integer}.${decimal}`;
        value = parseFloat(value).toFixed(2);
      }

      if (value.length === 1) {
        value = '0.0' + value;
      }
      if (value.length === 2) {
        value = '0.' + value;
      }
      if (parseFloat(value) === 0 && !props.possibleZero) {
        value = '';
      }
      e.target.value = value;
      _onChange(e);
    };
  }
  const input = props.mask ? (
    <MaskedInput
      id={`input-${name}`}
      ref={inputRef}
      aria-describedby={`error-${name}`}
      autoFocus={autoFocus}
      formik-error={formikError}
      mask={masks[props.mask]}
      autoComplete="off"
      className={`${className} ${styles[themes]}`}
      type={type}
      placeholder={label}
      name={name}
      value={value}
      showMask={showMaskValue}
      guide={showGuide}
      defaultValue={defaultValue}
      onChange={event => {
        const clean = sanitize(event.target.value, {
          ALLOWED_TAGS: ['visa-tag'],
        });
        event.target.value = clean;
        onChange(event);
      }}
      onBlur={event => {
        setFocusInElement(false);
        onBlur && onBlur(event);
      }}
      onFocus={event => {
        setFocusInElement(true);
        onFocus && onFocus(event);
      }}
      disabled={disabled}
      data-testid={props.dataTestid}
    />
  ) : (
    <input
      id={`input-${name}`}
      ref={inputRef}
      aria-describedby={`error-${name}`}
      aria-invalid={error && touched && !notShowValidationMessage}
      autoFocus={autoFocus}
      formik-error={formikError}
      auto-complete="new-password"
      className={`${className} ${styles[themes]}`}
      type={type}
      placeholder={label}
      name={name}
      value={value}
      defaultValue={defaultValue}
      onChange={event => {
        const clean = sanitize(event.target.value, {
          ALLOWED_TAGS: ['visa-tag'],
        });
        event.target.value = clean;
        onChange(event);
      }}
      onFocus={event => {
        setFocusInElement(true);
        onFocus && onFocus(event);
      }}
      onBlur={event => {
        setFocusInElement(false);
        onBlur && onBlur(event);
      }}
      disabled={disabled}
      data-testid={props.dataTestid}
      maxLength={maxLength}
      onKeyPress={onKeyPress}
    />
  );

  const toltipErrorStyle =
    error && touched && !focusInElement ? styles.errorGroupText : '';
  const toltipValueStyle = !!value && !focusInElement ? styles.value : '';
  const toltipFocusStyle = focusInElement ? styles.focusToltip : '';
  const valueChecked = value === 0 ? true : value;

  return (
    <div
      className={` ${
        tooltipText || tooltipContentClass
          ? styles.inputGroup + ' input-group mb-3'
          : ''
      } ${styles.Input} ${styles[themes]}`}
    >
      {valueChecked && !hideTopLabel && (
        <label
          htmlFor={`input-${name}`}
          className={`${labelClassName} ${styles[themes]} ${
            axa ? styles.axa : ''
          }`}
        >
          {removeRequiredCharLabel(label)}
        </label>
      )}
      {input}
      {(tooltipText || tooltipContentClass) && (
        <div className="input-group-prepend">
          <span
            data-tooltip
            datatitle={tooltipText}
            className={`${styles.inputGroupText} ${toltipErrorStyle} ${toltipValueStyle} ${toltipFocusStyle} ${styles[themes]} ${tooltipContentClass}`}
          >
            <TooltipIcon axa={axa ? 1 : 0} aria-label={intl.TOOLTIP_ICON} />
          </span>
        </div>
      )}
      {Icon && (
        <div className={styles.icon} onClick={() => inputRef.current.focus()}>
          <Icon />
        </div>
      )}
      {error && touched && !notShowValidationMessage && (
        <label
          id={`error-${name}`}
          className={errorLabelClassName}
          role="alert"
        >
          {error}
        </label>
      )}
      {bottomLabel && (
        <label
          className={styles.bottomLabel}
          data-testid={`${props.dataTestid}-bottomLabel`}
        >
          {bottomLabel}
        </label>
      )}
    </div>
  );
};

export default Input;
