/* eslint-disable indent */
import moment from 'moment';
import * as constants from '../../../../Form/IncidentInformation/PurchaseProtection/consts';

export const formatPurchase_protectionToApi = props => {
  let object = {};

  object.purchase_protection_items = itemToApi(props);
  object.incident = incidentToApi(props);
  object.affected_person = affected_personToApi(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  return object;
};

const itemToApi = props => {
  const items = props.values.incident.purchase_protection_items;
  const currency =
    props.values.incident.purchase_protection_items[0].price.currency;

  return items.map(item => {
    const visible =
      item.status !== constants.DAMAGE_VALUE
        ? false
        : item.is_the_damage_visible === 'true'
        ? true
        : false;
    const repairable =
      item.status !== constants.DAMAGE_VALUE
        ? false
        : item.is_repairable === 'true'
        ? true
        : false;
    const is_internal =
      item.other_insurance_covering.is_internal === true ||
      item.other_insurance_covering.is_internal === 'true';

    item.other_insurance_covering.is_internal = is_internal;
    item.other_insurance_covering.date = moment(new Date()).format(
      'YYYY-MM-DD'
    );
    item.other_insurance_covering.description = '';

    item.price.currency = currency;
    item.amount_claimed.currency = currency;
    item.other_insurance_covering.amount.currency = currency;

    return {
      ...item,
      is_the_damage_visible: visible,
      is_repairable: repairable,
      purchase_date: moment(item.purchase_date).format('YYYY-MM-DD HH:mm:ss'),
      other_insurance_covering: !is_internal
        ? [{ ...item.other_insurance_covering }]
        : undefined,
      number_of_installments: +item.number_of_installments,
      installment_price: undefined,
      paymentMethod: undefined,
    };
  });
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;

  const date = moment(incident.dateValue).format('YYYY-MM-DD HH:mm:ss');

  return {
    dateValue: date,
    description: incident.description,
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;

  return {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
    },
  };
};

const total_amount_claimedToApi = props => {
  let items = props.values.incident.purchase_protection_items;
  let amountClaimed = 0;
  items.map(item => {
    amountClaimed += Number(item.amount_claimed.value);
  });

  const currency =
    props.values.incident.purchase_protection_items[0].price.currency;

  return {
    value: amountClaimed,
    currency,
  };
};
