import React, { useContext } from 'react';
import Calendar from '../../../../../../../../../components/Calendar/Calendar';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import * as translations from '../../intl';
import { IntlContext } from '../../../../../../../../../intl';
import sanitize from '../../../../../../../../../utils/sanitize';

const ProductItem = props => {
  const {
    index,
    values,
    errors,
    touched,
    handleChange,
    setFieldTouched,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  return (
    <>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.TEXT_ITEM_DESCRIPTION}
          value={values.incident.concerned_items[index].description}
          name={`incident.concerned_items[${index}].description`}
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.concerned_items &&
            touched.incident.concerned_items[index] &&
            touched.incident.concerned_items[index].description
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.concerned_items &&
            errors.incident.concerned_items[index] &&
            errors.incident.concerned_items[index].description
          }
          maxLength="2000"
          dataTestid="description"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.concerned_items[index].purchase_date}
          label={intl.LABEL_PURCHASE_DATE}
          name={`incident.concerned_items[${index}].purchase_date`}
          onChange={event => {
            handleChange(event);
          }}
          maxDate={new Date()}
          touched={
            touched.incident &&
            touched.incident.concerned_items &&
            touched.incident.concerned_items[index] &&
            touched.incident.concerned_items[index].purchase_date
          }
          error={
            errors.incident &&
            errors.incident.concerned_items &&
            errors.incident.concerned_items[index] &&
            errors.incident.concerned_items[index].purchase_date
          }
          setFieldTouched={setFieldTouched}
        />
      </div>

      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.incident.concerned_items[index].price.value}
          label={intl.LABEL_PURCHASE_PRICE}
          name={`incident.concerned_items[${index}].price.value`}
          type="text"
          maskedMoney
          onChange={event => {
            handleChange(event);
          }}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.incident &&
            touched.incident.concerned_items &&
            touched.incident.concerned_items[index] &&
            touched.incident.concerned_items[index].price &&
            touched.incident.concerned_items[index].price.value
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.concerned_items &&
            errors.incident.concerned_items[index] &&
            errors.incident.concerned_items[index].price &&
            errors.incident.concerned_items[index].price.value
          }
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.incident.concerned_items[0].price.currency}
          name={`incident.concerned_items[${index}].price.currency`}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          dataTestid={`purchaseCurrencyPrice-${index}`}
          disabled={index !== 0}
          touched={
            touched.incident &&
            touched.incident.concerned_items &&
            touched.incident.concerned_items[index] &&
            touched.incident.concerned_items[index].price &&
            touched.incident.concerned_items[index].price.currency
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.concerned_items &&
            errors.incident.concerned_items[index] &&
            errors.incident.concerned_items[index].price &&
            errors.incident.concerned_items[index].price.currency
          }
        />
      </div>
    </>
  );
};
export default ProductItem;
