export const TITLE = {
  en_US: product => `Explore your Visa ${product} card's benefits`,
  es_ES: product => `Explora los beneficios de tu Visa ${product}`,
  pt_BR: product => `Conheça os benefícios do seu Visa ${product}`,
};

export const SUBTITLE = {
  en_US: 'Your top benefits',
  es_ES: 'Tus principales beneficios',
  pt_BR: 'Seus principais benefícios',
};

export const TITLE_ENJOY_BENEFITS = {
  en_US: 'Discover your new benefits and enjoy',
  es_ES: 'Descubre tus nuevos beneficios y disfrútalos',
  pt_BR: 'Conheça seus novos benefícios e aproveite',
};

export const TITLE_BENEFIT_COTIZAR = {
  en_US: 'International Emergency Medical Services',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Serviços Médicos de Emergência Internacional',
};

export const TEXT_VIEW_BENEFITS = {
  en_US: 'View my benefits',
  es_ES: 'Ver mis beneficios',
  pt_BR: 'Ver meus benefícios',
};
