import React, { useContext } from 'react';
import styles from './Footer.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';

import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const Footer = () => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);

  const theme = getGlobalTheme();
  const intl = translate(translations);

  return (
    <div className={`${styles.footerContainer} ${styles[theme]}`}>
      <div className={styles.textArea}>
        <h2>{intl.EXPLORE_AND_ENJOY}</h2>
        <p>{intl.DOWNLOAD_OUR_APP}</p>
        <div className={styles.imagesInline}>
          <a
            href={process.env.REACT_APP_IOS_STORE.toString()}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`App Store - ${intl.NEW_TAB}`}
          >
            <img src={intl.IMAGE_APP_STORE} alt="App Store" />
          </a>
          <a
            href={process.env.REACT_APP_ANDROID_STORE.toString()}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`Google Play - ${intl.NEW_TAB}`}
          >
            <img src={intl.IMAGE_PLAY_STORE} alt="Google Play" />
          </a>
        </div>
      </div>

      <div className={styles.imageArea}>
        <img src={intl.CELL_PHONE_IMAGE} alt="" />
      </div>
    </div>
  );
};

export default Footer;
