import React from 'react';
import styles from './VoucherItem.module.scss';
import { formatCustomDate, isSameOrAfterDay } from '../../../../../utils/date';
import StatusChip from '../../../../../componentsV2/Chip/StatusChip/StatusChip';
import VoucherIcon from '../../../../../assets/icons/VoucherIcon';
import * as translations from './intl';
import { useIntl } from '../../../../../intl';
import { useTheme } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../../../../utils/configIcon';
import { stringCapitalize } from '../../../../../utils/stringUtils';
import { toDate } from 'date-fns-tz';

const VoucherItem = ({ voucher }) => {
  const { valid_until, benefit_name, voucher_id } = voucher;

  const { translate, idiom } = useIntl();
  const { getGlobalTheme } = useTheme();

  const theme = getGlobalTheme();
  const config_color = getThemeIcon(theme);
  const intl = translate(translations);
  const isActive = isSameOrAfterDay(toDate(voucher.valid_until), new Date());
  const status = isActive ? 'ONGOING' : 'EXPIRED';
  const benefitNameFormated = stringCapitalize(benefit_name);
  const formatedDate = formatCustomDate(valid_until, 'YYYY-MM-DD', idiom);

  return (
    <li className={styles.voucherBox}>
      <aside>
        <VoucherIcon
          width={30}
          height={30}
          colorPrimary={config_color.primary}
          colorSecundary={config_color.primary}
        />
      </aside>

      <main>
        <h4 className={`${styles.title} ${styles[theme]}`}>
          {benefitNameFormated}
        </h4>
        <p className={styles[theme]}>
          <strong>
            {intl.TEXT_NUMBER}
            {voucher_id}
          </strong>
        </p>
        <span>
          {intl.VALID_UNTIL} {formatedDate}
        </span>
      </main>

      <aside>
        <StatusChip status={status} />
      </aside>
    </li>
  );
};

export default VoucherItem;
