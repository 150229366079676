import * as Yup from 'yup';
import { parse, isValid } from 'date-fns';

const validationGeneralFormSchema = intl =>
  Yup.object().shape({
    generalFormSchema: Yup.object().shape({
      type: Yup.string()
        .oneOf(['VIDEO', 'PHONE'])
        .required(intl.TEXT_FIELD_REQUIRED),
      country: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .min(2, intl.TEXT_ERROR_TWO_DIGITS)
        .max(2, intl.TEXT_ERROR_TWO_DIGITS),
      language: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .min(2, intl.TEXT_ERROR_TWO_DIGITS)
        .max(2, intl.TEXT_ERROR_TWO_DIGITS),
      timezone: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      patient: Yup.object()
        .shape({
          phone_prefix: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          phone: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          surname: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          birth_date: Yup.date().required(intl.TEXT_FIELD_REQUIRED),
          age: Yup.number().positive().required(),
        })
        .required(intl.TEXT_FIELD_REQUIRED),
      files: Yup.array(),
      call_reason: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      date: Yup.string().when('type', {
        is: 'VIDEO',
        then: Yup.date(intl.TEXT_FIELD_REQUIRED).required(
          intl.TEXT_FIELD_REQUIRED
        ),
      }),
      time: Yup.string().when('type', {
        is: 'VIDEO',
        then: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError()
          .test('test-hour-valid', intl.INVALID_TIME, function (hour) {
            return isValid(parse(hour, 'HH:mm', new Date()));
          }),
      }),
    }),
  });

export default validationGeneralFormSchema;
