import React, { useContext } from 'react';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import styles from '../ClaimView.module.scss';
import * as translations from './intl';
import InfoLabelValue from '../../../../components/InfoLabelValue/InfoLabelValue';
import {
  ABA,
  CUIT,
  FULL_KEY,
  IBAN,
  identifyAccountCodes,
  SWIFT,
} from '../../../../utils/banks';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';

const CHECKING = 'CHECKING';
const SAVING = 'SAVING';

const BankInformation = props => {
  const { bank } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);

  const country = utils.getCountry();
  const accountCodes = identifyAccountCodes(bank, country);
  const themes = getGlobalTheme();
  const isBr = country === 'BRAZIL';

  const getAccountType = type => {
    switch (type) {
      case CHECKING:
        return intl.LABEL_ACCOUNT_TYPE_CHECKING;
      case SAVING:
        return intl.LABEL_ACCOUNT_TYPE_SAVING;
      default:
        return '-';
    }
  };

  return (
    <div className={`row ${styles.sectionTitle}`}>
      <h2 className={`col-12 ${styles.sectionTitle} ${styles[themes]}`}>
        {intl.TITLE_BANK_INFORMATION}
      </h2>
      <div className="col-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_BANK_NAME}
          value={bank && bank.bank && bank.bank.name ? bank.bank.name : '-'}
        />
      </div>
      <div className="col-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ACCOUNT_TYPE}
          value={getAccountType(bank.account_type)}
        />
      </div>
      <div className="col-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ACCOUNT_NUMBER}
          value={(bank && bank.account_number) || '-'}
        />
      </div>
      {!isBr && (
        <>
          {accountCodes.includes(SWIFT) && bank.bank_identification_number && (
            <div className="col-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_SWIFT_CODE}
                value={bank.bank_identification_number}
              />
            </div>
          )}

          {accountCodes.includes(CUIT) && bank.bank_identification_number && (
            <div className="col-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_CUIT}
                value={bank.bank_identification_number}
              />
            </div>
          )}

          {accountCodes.includes(FULL_KEY) && bank.routing_number && (
            <div className="col-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_FULL_KEY}
                value={bank.routing_number}
              />
            </div>
          )}

          {accountCodes.includes(ABA) && bank.routing_number && (
            <div className="col-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_ABA}
                value={bank.routing_number}
              />
            </div>
          )}

          {accountCodes.includes(IBAN) && bank.routing_number && (
            <div className="col-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_IBAN}
                value={bank.routing_number}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BankInformation;
