/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';

import { IntlContext } from '../../../intl';
import * as translations from './intl';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import Loading from '../../../components/Loading/Loading';
import styles from './ProfileNotifications.module.scss';
import Header from './Header/Header';
import Notifications from './Notifications/Notifications';
import { notificationsService } from '../../../services';
import { ARCHIVED, NOTIFICATION } from './const';
import DividingLine from '../../../components/DividingLine/DividingLine';
import { NotificationContext } from '../../../components/NotificationContextParent/NotificationContextParent';
import { getTotalNotifications } from './utils';
import Button from '../../../componentsV2/Button/Button';

const LOAD_MORE = 5;

const ProfileNotifications = () => {
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils, actions } = useContext(StateContext);
  const cn = utils.getCn();
  const { actions: notificationActions } = useContext(NotificationContext);

  const [loading, setLoading] = useState(true);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const [notifications, setNotifications] = useState();
  const [numberNotifications, setNumberNotifications] = useState(5);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [archived, setArchived] = useState();
  const [numberArchived, setNumberArchived] = useState(5);
  const [totalArchived, setTotalArchived] = useState(0);
  const [updateList, setUpdateList] = useState(false);

  const noNotificationSubtitle =
    notifications && notifications.length === 0
      ? intl.TEXT_EMPTY_NOTIFICATIONS
      : intl.TEXT_SUBTITLE_NOTIFICATIONS;
  const noArchivedSubtitle =
    archived && archived.length === 0
      ? intl.TEXT_EMPTY_NOTIFICATIONS_ARCHIVED
      : intl.TEXT_SUBTITLE_NOTIFICATIONS;

  useEffect(() => {
    const getGenerated = async () => {
      const userNotificationsUnarchived =
        await notificationsService.getUserNotifications(
          cn,
          idiomForApi(),
          `?was_archived=false&max_results=${numberNotifications}`
        );
      const userNotificationsArchived =
        await notificationsService.getUserNotifications(
          cn,
          idiomForApi(),
          `?was_archived=true&max_results=${numberArchived}`
        );

      setNotifications(userNotificationsUnarchived.data);

      const totalNotifications =
        getTotalNotifications(userNotificationsUnarchived) ??
        userNotificationsUnarchived.data.length;
      setTotalNotifications(totalNotifications);

      setArchived(userNotificationsArchived.data);

      const totalArchived =
        getTotalNotifications(userNotificationsArchived) ??
        userNotificationsArchived.data.length;
      setTotalArchived(totalArchived);

      setLoading(false);
    };

    getGenerated();
  }, [updateList]);

  const archiveNotification = async (id, status) => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.UPDATE_LIST_NOTIFICATION
      );
      const dataUpdate = { was_archived: status };
      await notificationsService.updateUserNotification(cn, id, dataUpdate);
      notificationActions.bellNotification.updateBellNotification();
      setUpdateList(!updateList);
      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      actions.loadingFullScreen.hideLoadingFullScreen();
    }
  };

  const readNotification = async id => {
    try {
      const dataUpdate = { was_read: true };
      await notificationsService.updateUserNotification(cn, id, dataUpdate);
      notificationActions.bellNotification.updateBellNotification();
    } catch (error) {
      console.log(error);
    }
  };

  const archiveAll = async (data, status) => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.UPDATE_LIST_NOTIFICATION
      );
      if (data && data.length > 0) {
        await Promise.all(
          data.map(async item => {
            if (item.isChecked) {
              await notificationsService.updateUserNotification(
                cn,
                item.notification_id,
                { was_archived: status }
              );
            }
          })
        );
        notificationActions.bellNotification.updateBellNotification();
        setUpdateList(!updateList);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      actions.loadingFullScreen.hideLoadingFullScreen();
    }
  };

  const deleteAll = async data => {
    try {
      actions.loadingFullScreen.showLoadingFullScreen(
        intl.UPDATE_LIST_NOTIFICATION
      );
      if (data && data.length > 0) {
        await Promise.all(
          data.map(async item => {
            if (item.isChecked) {
              await notificationsService.deleteUserNotification(
                cn,
                item.notification_id
              );
            }
          })
        );
        setUpdateList(!updateList);
        setLoading(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      actions.loadingFullScreen.hideLoadingFullScreen();
    }
  };

  const loadMore = async archived => {
    setLoadingLoadMore(true);
    try {
      const index = archived ? numberArchived : numberNotifications;

      const notificationsLoadMore =
        await notificationsService.getUserNotifications(
          cn,
          idiomForApi(),
          `?was_archived=${archived}&max_results=${LOAD_MORE}&start_index=${index}`
        );

      if (archived) {
        setNumberArchived(numberArchived + LOAD_MORE);
        setArchived(notifications => {
          const list = [...notifications, ...notificationsLoadMore.data];
          setTotalArchived(
            getTotalNotifications(notificationsLoadMore) ?? list.length
          );
          return list;
        });
      } else {
        setNumberNotifications(numberNotifications + LOAD_MORE);
        setNotifications(notifications => {
          const list = [...notifications, ...notificationsLoadMore.data];
          setTotalNotifications(
            getTotalNotifications(notificationsLoadMore) ?? list.length
          );
          return list;
        });
      }
    } catch (error) {}
    setLoadingLoadMore(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentAllNotifications}>
        <Header
          id="all"
          title={intl.TEXT_NOTIFICATIONS}
          qtdNotifications={notifications ? totalNotifications : 0}
          subtitle={noNotificationSubtitle}
          loading={loading}
          data={notifications}
          limit={numberNotifications}
          archiveSelected={archiveAll}
          setNotifications={setNotifications}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Notifications
          limit={numberNotifications}
          data={notifications}
          type={NOTIFICATION}
          updateFn={archiveNotification}
          setReadNotification={readNotification}
          setNotifications={setNotifications}
        />
      )}
      {!loading &&
        notifications &&
        notifications.length === numberNotifications && (
          <div className={styles.showMore}>
            {loadingLoadMore ? (
              <Loading />
            ) : (
              <Button
                type="Tertiary"
                label={intl.TEXT_SEE_MORE}
                trailingIcon="Chevron Down"
                onClick={() => loadMore(false)}
              />
            )}
          </div>
        )}

      <DividingLine />

      <div className={styles.contentAllNotifications}>
        <Header
          id="archived"
          title={intl.TEXT_ARCHIVED}
          qtdNotifications={archived ? totalArchived : 0}
          subtitle={noArchivedSubtitle}
          loading={loading}
          data={archived}
          limit={numberArchived}
          archiveSelected={archiveAll}
          setNotifications={setArchived}
          deleteAll={deleteAll}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <Notifications
          limit={numberArchived}
          data={archived}
          type={ARCHIVED}
          updateFn={archiveNotification}
          setNotifications={setArchived}
        />
      )}
      {!loading && archived && archived.length === numberArchived && (
        <div className={styles.showMore}>
          {loadingLoadMore ? (
            <Loading />
          ) : (
            <Button
              type="Tertiary"
              label={intl.TEXT_SEE_MORE}
              trailingIcon="Chevron Down"
              onClick={() => loadMore(true)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProfileNotifications;
