import React, { useContext } from 'react';
import styles from './BenefitItem.module.scss';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const BenefitItem = props => {
  const { benefitIcon, benefitName, benefitDescription } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <li className={`${styles.benefitItem} ${styles[theme]}`}>
      <div className={`${styles.imageContent} ${styles[theme]}`}>
        {benefitIcon()}
      </div>
      <div className={styles.content}>
        <h4 className={`${styles[theme]}`}>{benefitName}</h4>
        <p>{benefitDescription}</p>
      </div>
    </li>
  );
};

export default BenefitItem;
