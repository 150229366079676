export const TITLE_OUTBOUND = {
  en_US: 'Outbound flight',
  pt_BR: 'Voo de ida',
  es_ES: 'Vuelo de ida',
};

export const TITLE_RETURN = {
  en_US: 'Return flight',
  pt_BR: 'Voo de volta',
  es_ES: 'Vuelo de vuelta',
};

export const LABEL_BOOKING_COMPANY = {
  en_US: 'Booking company / Airline *',
  pt_BR: 'Empresa de reserva / Companhia aérea *',
  es_ES: 'Compañía de reserva / Aerolínea *',
};

export const LABEL_RECORD_LOCATOR = {
  en_US: 'Record locator *',
  pt_BR: 'Localizador de registro *',
  es_ES: 'Localizador de registro *',
};

export const BTN_CONCLUDE = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};
