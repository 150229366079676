import moment from 'moment';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../../utils/environments_variables';
import { removeNonNumeric } from '../../../../../../../../utils/stringUtils';

const CARD_HOLDER_VALUE = 'Cardholder';

export const travel_accident_insuranceToApi = props => {
  let object = {};

  object.is_travel_accident = true;

  object.is_accidental_death =
    props.values.incident.is_accidental_death === 'true';

  object.affected_person = affected_personToApi(props);

  object.incident = incidentToApi(props);

  if (props.values.incident.is_accidental_death === 'false') {
    object.policy = formatPolicyToApi(props);
    if (props.values.incident.have_people_involved === 'true') {
      object.people_involved = props.values.incident.people_involved;
    }
  }

  return object;
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;
  const address = affected_person.address;
  const person = affected_person.person;
  const person_registration = affected_person.person_registration;
  const phones = affected_person.phones;
  const family_situation = affected_person.family_situation;
  const professional_situation = affected_person.professional_situation;

  let affected_personReturn = {};

  affected_personReturn.relationship = affected_person.relationship;
  affected_personReturn.comment = affected_person.comment;
  affected_personReturn.person = {
    first_name: person.first_name,
    last_name: person.last_name,
    nationalities: [person.nationalities],
  };
  if (affected_person.relationship !== CARD_HOLDER_VALUE) {
    affected_personReturn.person_registration = [
      {
        registration_type: person_registration.registration_type,
        value: person_registration.value,
      },
    ];
    affected_personReturn.address = {
      country: address.country,
      state: address.state,
      locality: address.locality,
      postal_code: ALPHANUMERIC_COUNTRY_LIST.includes(address.country)
        ? address.postal_code
        : removeNonNumeric(address.postal_code),
      street_address: address.street_address,
      subdivision: address.subdivision,
    };
    affected_personReturn.phones = [
      {
        phone_type: phones.phone_type,
        international_prefix: phones.international_prefix,
        number: phones.number,
      },
    ];
  }

  if (props.values.incident.is_accidental_death === 'false') {
    if (affected_person.relationship !== CARD_HOLDER_VALUE) {
      affected_personReturn.email = affected_person.email;
      affected_personReturn.professional_situation = {
        occupation: professional_situation.occupation,
      };
    }
    affected_personReturn.family_situation = {
      marital_status: family_situation.marital_status,
    };
    if (family_situation.lived_in_couple_since) {
      affected_personReturn.family_situation.lived_in_couple_since = moment(
        family_situation.lived_in_couple_since
      ).format('YYYY-MM-DD');
    }
  }

  return { ...affected_personReturn };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;
  const location = incident.location;
  let incidentReturn = {};
  incidentReturn.occured_at = moment(incident.occured_at).format(
    'YYYY-MM-DD hh:mm:ss'
  );
  incidentReturn.description = incident.description;

  if (props.values.incident.is_accidental_death === 'true') {
    incidentReturn.location = {
      country: location.country,
      state: location.state,
      locality: location.locality,
      street_address: location.street_address,
    };
  }

  return { ...incidentReturn };
};

const formatPolicyToApi = props => {
  const policy = props.values.incident.policy;

  let object = {
    credit_card: {
      additional_card_intormation: {
        expiration_date: moment(
          policy.credit_card.additional_card_intormation.expiration_date
        ).format('YYYY-MM-DD'),
        card_status: policy.credit_card.additional_card_intormation.card_status,
      },
    },
  };

  if (policy.credit_card.additional_card_intormation.activation_date) {
    object.credit_card.additional_card_intormation.activation_date = moment(
      policy.credit_card.additional_card_intormation.activation_date
    ).format('YYYY-MM-DD');
  }

  return object;
};
