import { useEffect, useState } from 'react';
import { useIntl } from '../../intl';
import styles from './index.module.scss';
import { getRequiredLabel } from './utils';
import * as translations from './intl';
import { ReactNode } from 'react';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

interface ExpectedDocumentCardProps {
  description: string;
  isMandatory: boolean;
  typeName?: string;
  name?: string;
  children?: ReactNode;
}

const ExpectedDocumentCard = ({
  description,
  isMandatory,
  typeName,
  name,
  children,
}: ExpectedDocumentCardProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  const requiredLabel = getRequiredLabel(isMandatory, intl);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1100);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1100);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <li
      data-document-type-name={typeName}
      data-testid={isMandatory ? 'document-required' : 'document-optional'}
      className={styles.container}
    >
      <div className={styles.containerItem}>
        {isSmallScreen && (
          <div className={styles.upload} data-testid="requiredLabel">
            <span
              data-testid="requiredLabelText"
              className={
                isMandatory ? styles.requiredPill : styles.optionalPill
              }
            >
              {requiredLabel}
            </span>
          </div>
        )}
        <span
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        ></span>
        <div className={styles.upload} data-testid="requiredLabel">
          {name ? (
            <span data-filename={name} className={styles.name}>
              {name}
            </span>
          ) : (
            !isSmallScreen && (
              <span
                data-testid="requiredLabelText"
                className={
                  isMandatory ? styles.requiredPill : styles.optionalPill
                }
              >
                {requiredLabel}
              </span>
            )
          )}
          {children}
        </div>
      </div>
    </li>
  );
};

const Option = ({ children }: { children: ReactNode }) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <>
      <hr className={`${styles.optionDivider} ${styles[theme]}`} />
      {children}
    </>
  );
};

ExpectedDocumentCard.Option = Option;

export default ExpectedDocumentCard;
