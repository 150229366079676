import { useContext } from 'react';

import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import * as constants from '../../consts';
import styles from '../AddicionalPassagers.module.scss';
import { ThemeContext } from '../../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';

const PassangerAdd = props => {
  const { values, setFieldValue } = props;
  const { translate } = useContext(IntlContext);
  const { isAfluent } = useContext(ThemeContext);
  const intl = translate(translations);

  const addAdditional = event => {
    event.preventDefault();
    const additional = [...values.additional_passengers];
    additional.push(constants.ADDITIONAL_OBJECT);
    setFieldValue('additional_passengers', additional);
  };
  return (
    <div className="row">
      <div className={`col-12 ${styles.add}`}>
        <Button
          type="Tertiary"
          onClick={addAdditional}
          label={intl.BTN_ADD_ADDITIONAL}
          leadingIcon={<AddIcon />}
        />
      </div>
    </div>
  );
};

export default PassangerAdd;
