export const TEXT_IEMS = {
  en_US: `International Emergency Medical Service`,
  pt_BR: 'Seguro de Emergência Médica Internacional',
  es_ES: `Servicios Médicos de Emergencia Internacional
  `,
};

export const TEXT_TERMS_CONDITIONS_CHECKBOX_1_NAL = {
  en_US:
    'I understand that I can not get a diagnostic through the Nurse Advance Line benefit.',
  pt_BR:
    'Entendo que não consigo obter um diagnóstico através do benefício Nurse Advice Line.',
  es_ES:
    'Entiendo que no puedo obtener un diagnóstico a través del beneficio Nurse Advice Line.',
};

export const TEXT_TERMS_CONDITIONS_CHECKBOX_2_NAL = {
  en_US:
    'I am not experiencing a risk life symptom. If you are please call 911 immediately or contact the emergency line of your country of residence.',
  pt_BR:
    'Não estou experimentando um sintoma de risco de vida. Se estiver, ligue imediatamente para o 911 ou entre em contato com a linha de emergência do seu país de residência.',
  es_ES:
    'No estoy experimentando un síntoma de vida de riesgo. Si es así, llame inmediatamente al 911 o comuníquese con la línea de emergencia de su país de residencia.',
};

export const TEXT_TERMS_CONDITIONS_CHECKBOX_3_NAL = {
  en_US:
    'I understand that this is a benefit that provides information about general medical conditions, and  that I will not receive medical treatment through this appointment.',
  pt_BR:
    'Entendo que este é um benefício que fornece informações sobre condições médicas gerais e que não receberei tratamento médico por meio deste agendamento.',
  es_ES:
    'Entiendo que este es un beneficio que brinda información sobre condiciones médicas generales y que no recibiré tratamiento médico a través de esta cita.',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const BTN_NEXT = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Appointment',
  es_ES: 'Cita',
  pt_BR: 'Agendamento',
};
