import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const EditIconMenuProfile = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 17 17"
      style={{
        enableBackground: 'new 0 0 17 17',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: config_icon.secondary,
        }}
        d="M8.5 8.1C6.6 8.1 5 6.5 5 4.6s1.6-3.5 3.5-3.5S12 2.6 12 4.6s-1.6 3.5-3.5 3.5zm0-5c-.8 0-1.5.6-1.5 1.5s.7 1.5 1.5 1.5S10 5.4 10 4.6s-.7-1.5-1.5-1.5zM5.5 15.9V15c0-1.7 1.3-3 3-3s3 1.3 3 3v.9h2V15c0-2.8-2.2-5-5-5s-5 2.2-5 5v.9h2z"
      />
    </svg>
  );
};

export default EditIconMenuProfile;
