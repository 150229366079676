import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const UnarchiveIcon = props => {
  const { disabled = false } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 7L12 17"
        stroke={disabled ? config_icon.disabled : config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M5 10L10.5858 4.41421C11.3668 3.63316 12.6332 3.63317 13.4142 4.41422L19 10"
        stroke={disabled ? config_icon.disabled : config_icon.primary}
        strokeWidth={2}
      />
      <path
        d="M2 12V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V12"
        stroke={disabled ? config_icon.disabled : config_icon.secondary}
        strokeWidth={2}
      />
    </svg>
  );
};

export default UnarchiveIcon;
