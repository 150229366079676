import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from '../ClaimView.module.scss';
import * as translations from '../intl';
import Iems from './Iems/Iems';

import PriceProtection from './PriceProtection/PriceProtection';
import CarRental from './CarRental/CarRental';
import ExtendedWarranty from './ExtendedWarranty/ExtendedWarranty';
import PurchaseProtection from './PurchaseProtection/PurchaseProtection';
import TripDelay from './TripDelay/TripDelay';
import TripCancellation from './TripCancellation/TripCancellation';
import BaggageLoss from './BaggageLoss/BaggageLoss';
import BaggageDelay from './BaggageDelay/BaggageDelay';
import TravelAccidentInsurance from './TravelAccidentInsurance/TravelAccidentInsurance';
import MissedConnection from './MissedConnection/MissedConnection';
import InsuredJourneyAccidental from './InsuredJourneyAccidental/InsuredJourneyAccidental';
import CellphoneProtection from './CellphoneProtection/CellphoneProtection';
import BagProtection from './BagProtection/BagProtection';
import CorporateLiabilityWaiver from './CorporateLiabilityWaiver/CorporateLiabilityWaiver';
import ATM from './ATM/ATM';

const NotSet = () => {
  return <h1>Claim Type Not Set</h1>;
};

const components = {
  EMC_Emergency_Medical_Assistance: Iems,
  Price_Protection: PriceProtection,
  Extended_Warranty: ExtendedWarranty,
  Auto_Rental_Insurance: CarRental,
  Purchase_Protection: PurchaseProtection,
  Trip_Delay: TripDelay,
  Trip_Cancellation: TripCancellation,
  Baggage_Delay: BaggageDelay,
  Baggage_Loss: BaggageLoss,
  Travel_Accident_Insurance: TravelAccidentInsurance,
  Missed_Connection: MissedConnection,
  Injured_Journay_Accidental_D_D: InsuredJourneyAccidental,
  Cellphone_Protection: CellphoneProtection,
  Bag_Protection: BagProtection,
  Corporate_Liability_Waiver: CorporateLiabilityWaiver,
  Assault_Cash_Death: ATM,
};

const IncidentInformation = props => {
  const { claimType } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const Component = components[claimType] || NotSet;

  return (
    <>
      <h2 className={`${styles.sectionTitle} ${styles[themes]}`}>
        {intl.TITLE_INCIDENT_INFORMATION}
      </h2>
      <Component {...props} />
    </>
  );
};

export default IncidentInformation;
