import React, { useContext, useMemo } from 'react';
import { withRouter } from 'react-router';
import moment from 'moment';
import styles from './AppointmentInfo.module.scss';
import { AppointmenQuestionsNAL } from './AppointmenQuestions/AppointmenQuestionsNAL';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import { convertTimeUS } from '../../../../../utils/date';
import {
  removeNonNumeric,
  stringCapitalize,
} from '../../../../../utils/stringUtils';
import { countryPrefixToInitials } from '../../../../../intl/utils';
import { formatPhoneNumberByCountry } from '../../../../../utils/phone';

const AppointmentInfoNAL = ({ appointment, intl, idiom }) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const getLocaleByIdiom = idiom.split('-')[0];
  const dateByIdiom = moment(
    appointment.appointment_details.date.substring(0, 10)
  ).locale(getLocaleByIdiom);
  const dayOfWeek = stringCapitalize(dateByIdiom.format('dddd'));
  const month = stringCapitalize(dateByIdiom.format('MMMM'));
  const day = dateByIdiom.format('D');
  const year = dateByIdiom.format('yyyy');
  const time = appointment.appointment_details.date.substring(10, 16);
  const _time = idiom === 'en_US' ? convertTimeUS(time) : time;

  const typePhone =
    appointment.patient_info.phone.phone_type === 'LANDLINE'
      ? intl.TEXT_LANDLINE
      : intl.TEXT_MOBILE;

  const phoneNumber = useMemo(() => {
    const phoneObject = appointment.patient_info.phone;
    const countryByCode = countryPrefixToInitials(
      phoneObject.international_prefix
    );
    const number = formatPhoneNumberByCountry(
      removeNonNumeric(phoneObject.number),
      countryByCode
    );
    return number;
  }, [appointment]);

  return (
    <>
      <div className={styles.appointmentInfo}>
        <div className={`${styles.info} ${styles[themes]}`}>
          <p
            className={styles[themes]}
            dangerouslySetInnerHTML={{
              __html: intl.TEXT_CONFIRM_NAL(
                appointment.patient_info.first_name,
                appointment.patient_info.last_name
              ),
            }}
          ></p>
          <p className={`${styles.date} ${styles[themes]}`}>
            {intl.FORMAT_TEXT_DATE(month, day, dayOfWeek, _time, year)}
          </p>

          <div
            className={`card mx-auto ${styles.contactInfo} ${styles[themes]}`}
          >
            <div className="card-body">
              <h5 className="card-title">{intl.TEXT_CONTACT_INFO}</h5>
              <p className={`card-text ${styles[themes]}`}>
                {appointment.patient_info.email} <br />
                {typePhone} -{' '}
                {appointment.patient_info.phone.international_prefix}{' '}
                {phoneNumber}
              </p>
            </div>
          </div>
        </div>

        <AppointmenQuestionsNAL
          intl={intl}
          questions={appointment.appointment_details.initial_questions}
        />
      </div>
    </>
  );
};

export default withRouter(AppointmentInfoNAL);
