/* eslint-disable react-hooks/exhaustive-deps */
import { differenceInCalendarYears } from 'date-fns';
import { Formik } from 'formik';
import React, { useContext, useMemo, Fragment } from 'react';
import PatientItem from './PatientItem/PatientItem';
import styles from './PatientArea.module.scss';
import validationSchema from './validationSchema';
import * as translations from './../intl';
import * as translations_GeneralInfo from '../../../../Generate/ClaimFillForm/Form/intl';
import { IntlContext } from '../../../../../../intl';
import { teleconsutationFormOneInitialValues } from './../initialValues';

const PatientArea = props => {
  const {
    cardHolder,
    dependents,
    selectedIndex,
    setSelectedIndex,
    values,
    setValues,
    updateParent,
    submitRef,
  } = props;

  const { translate, twoDigitsIdiom } = useContext(IntlContext);
  const intl_teleconsultation = translate(translations);
  const intl_GeneralInfo = translate(translations_GeneralInfo);
  const intl = { ...intl_teleconsultation, ...intl_GeneralInfo };
  const language = twoDigitsIdiom();

  const validationSchemaData = useMemo(() => validationSchema(intl), [intl]);

  const initialValues = useMemo(
    () =>
      teleconsutationFormOneInitialValues({
        cardHolder,
        language,
        differenceInCalendarYears,
      }),
    [cardHolder, language]
  );

  const renderDependentsItems = (values, setValues) =>
    props.dependents.map((dependent, index) => (
      <Fragment key={dependent.dependent_id}>
        <PatientItem
          index={index + 1}
          setSelectedIndex={setSelectedIndex}
          key={index}
          patient={dependent}
          name={`${dependent.person.first_name} ${dependent.person.last_name}`}
          age={differenceInCalendarYears(
            new Date(),
            new Date(dependent.person.birth_date)
          )}
          select={selectedIndex === index + 1}
          setValues={setValues}
          values={values}
        />
        {dependents.length !== index + 1 && (
          <hr className={styles.divideFooter} />
        )}
      </Fragment>
    ));

  return (
    <div className={`${styles.container}`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaData}
        onSubmit={() => {}}
      >
        {props => {
          const { values, handleSubmit, setValues } = props;
          updateParent(values, validationSchemaData);
          return (
            <form onSubmit={handleSubmit} data-testid="FullFormCliamCarRental">
              <button
                ref={submitRef}
                type="submit"
                style={{ display: 'none' }}
                role="btnSubmit"
              ></button>
              <PatientItem
                index={0}
                setSelectedIndex={setSelectedIndex}
                patient={cardHolder}
                name={`${cardHolder.person.first_name} ${cardHolder.person.last_name}`}
                age={differenceInCalendarYears(
                  new Date(),
                  new Date(cardHolder.person.birth_date)
                )}
                select={selectedIndex === 0}
                setValues={setValues}
                values={values}
              />
              <hr className={styles.divideFooter} />

              {dependents.length > 0 &&
                renderDependentsItems(values, setValues)}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PatientArea;
