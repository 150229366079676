export const tripInitialValues = () => ({
  is_international_trip: 'true',
  from: '',
  to: '',
  country_origin: '',
  city_origin: '',
  country_destination: '',
  city_destination: '',
  rental_company: '',
  reservation_number: '',
});
