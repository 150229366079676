import React, { useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { IntlContext } from '../../intl';
import ModalInformation from '../ModalInformation/ModalInformation';
import { StateContext } from '../StateContextParent/StateContextParent';
import * as translations from './intl';

const IdleTime = () => {
  const { actions, utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);
  const isLoggedIn = utils.isUserLogged();
  const idleTimeout = Number(process.env.REACT_APP_IDLEPAGE_TIMEOUT);
  const promptTimeout = 30000;

  const onPrompt = () => {
    actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="question"
        message={intl.SESSION_WILL_END}
        textBtn={intl.I_AM_HERE}
        clickBtn={() => {
          activate();
          actions.modal.closeModal();
        }}
      />,
      true
    );
  };

  const onIdle = () => {
    actions.modal.showModal(
      false,
      false,
      <ModalInformation type="error" message={intl.IDLE_SESSION} />,
      true
    );
    window.location.href = '/signout';
  };

  const { activate } = useIdleTimer({
    disabled: !isLoggedIn,
    timeout: idleTimeout,
    onIdle,
    promptBeforeIdle: promptTimeout,
    onPrompt,
  });

  return <></>;
};

export default IdleTime;
