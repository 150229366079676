export const LABEL_RENTAL_START = {
  en_US: 'Rental period start *',
  pt_BR: 'Início do período do aluguel *',
  es_ES: 'Comienzo del período de alquiler *',
};

export const LABEL_RENTAL_END = {
  en_US: 'Rental period end *',
  pt_BR: 'Fim do período do aluguel *',
  es_ES: 'Fin del periodo de alquiler *',
};

export const TEXT_RENTAL_PERIOD = {
  en_US: 'Rental period',
  pt_BR: 'Período do aluguel',
  es_ES: 'Período de alquiler',
};

export const TEXT_PURCHASE_DATE = {
  en_US: 'Date on which the purchase is shown on the statement *',
  pt_BR: 'Data que a compra foi lançada na fatura do seu cartão *',
  es_ES: 'Fecha de compra figura en el estado de cuenta *',
};

export const TEXT_ADVERTISEMENT_DATE = {
  en_US: 'Advertisement date *',
  pt_BR: 'Data do anúncio *',
  es_ES: 'Fecha del anuncio *',
};

export const COVERS_END_AFTER_30_DAYS = {
  en_US:
    'Please, be advised that the cover ends automatically 30 (thirty) days after the purchase of the product. Please, read terms and conditions for more information.',
  pt_BR:
    'Por favor, esteja ciente de que a cobertura termina automaticamente 30 (trinta) dias após a compra do produto. Por favor, leia os termos e condições para mais informações.',
  es_ES:
    'Por favor, tenga en cuenta que la cobertura finaliza automáticamente 30 (treinta) días después de la compra del producto. Por favor, lee los términos y condiciones para más información.',
};
