import { emergency_medical_assistance } from '../../utils/claims_name';

export const NOT_FOUND_ERROR = 'NOT_FOUND';

const getUpsellDocument = (benefit, country) => {
  if (benefit === emergency_medical_assistance) {
    switch (country) {
      case 'MX':
        return 'https://prod-axa.cdn.prismic.io/prod-axa/8cffdfcd-6a06-494c-bb1d-5db9c4804b64_AXA.IEMS.T%26C.MEX_06.28.2022_V4.pdf';
      default:
        return '';
    }
  }
};

const getXSellDocument = (benefit, country) => {
  if (benefit === emergency_medical_assistance) {
    switch (country) {
      case 'MX':
        return '';
      default:
        return '';
    }
  }
};

export const matchBenefitByURL = (type, benefit, country) => {
  let url;
  switch (type) {
    case 'upsell':
      url = getUpsellDocument(benefit, country);
      break;
    // case 'cross-sell':
    //   url = getXSellDocument(benefit, country);
    //   break;
    default:
      url = '';
  }

  if (url === '' || url === undefined) {
    throw new Error(NOT_FOUND_ERROR);
  }

  return url;
};
