import {
  emptyInvolvedPerson,
  getAffectedPerson,
  getIncident,
  getPeopleInvolved,
  getPolicy,
} from '../TravelAccidentInsurance/initialValues';

export const injuredJournayFormInitialValues = (data, idiom, loadedData) => {
  const affectedPerson =
    loadedData && loadedData.affected_person ? loadedData.affected_person : {};
  const peopleInvolved =
    loadedData && loadedData.people_involved ? loadedData.people_involved : [];
  const policy = loadedData && loadedData.policy ? loadedData.policy : {};
  const incident = loadedData && loadedData.incident ? loadedData.incident : {};

  return {
    is_travel_accident: false,
    is_accidental_death:
      loadedData && loadedData.is_accidental_death
        ? String(loadedData.is_accidental_death)
        : 'false',
    have_people_involved: String(peopleInvolved.length > 0),

    affected_person: getAffectedPerson(data, affectedPerson, idiom),
    policy: getPolicy(policy),
    incident: getIncident(incident),
    people_involved:
      peopleInvolved.length > 0
        ? getPeopleInvolved(peopleInvolved)
        : [emptyInvolvedPerson],
  };
};
