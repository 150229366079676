const moduleSantander = (account, agency) => {
  const zeros = [0, 0];
  const sequenceZero = [0, 0];
  const sequenceAgency = [9, 7, 3, 1];
  const sequenceAccount = [9, 7, 1, 3, 1, 9, 7, 3];

  const sumAgency = calculateSequence(agency, sequenceAgency);
  const sumZeros = calculateSequence(zeros, sequenceZero); // lol
  const sumAccount = calculateSequence(account, sequenceAccount);
  const sumTotal = parseInt(sumAgency + sumZeros + sumAccount);
  const theTen = sumTotal.toString().slice(-1); // get unit

  const result = 10 - parseInt(theTen);

  if (parseInt(theTen) === 0) return 0;

  return result;
};

// Sum only the unit, remove the ten
const calculateSequence = (numbers, sequence) => {
  let sum = 0;
  for (var i = 0; i < numbers.length; i++) {
    var m = (parseInt(numbers[i]) * sequence[i]).toString().slice(-1);
    sum += parseInt(m);
  }

  return sum;
};

// Type number allowed
const checkTypeAccount = number => {
  const numbers = [
    '01',
    '02',
    '03',
    '05',
    '07',
    '09',
    '13',
    '27',
    '35',
    '37',
    '43',
    '45',
    '46',
    '48',
    '50',
    '53',
    '60',
    '92',
  ];

  if (numbers.indexOf(number) !== -1) {
    return true;
  }

  return false;
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 10;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx
 * Account format: xxxxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (
      code.length !== accountNumberLength() ||
      agency.length !== agencyNumberLength()
    )
      return false;

    let arrayCode = code.split('-');
    let type = arrayCode[0].substr(0, 2).toString();
    let numbers = arrayCode[0].split('');
    let numbersAgency = agency.split('');
    let dv = arrayCode[1];

    if (!checkTypeAccount(type)) return false; // Check account type
    const calculatedNumber = moduleSantander(numbers, numbersAgency);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskSantanderBranchCode';
  },
  getMaskAccountName: () => {
    return 'maskSantanderAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '00000000-0';
  },
});
