import { differenceInYears } from 'date-fns';
import { pt_BR } from '../../../../../intl/idioms';
import moment from 'moment';
import { PHONE_TYPE_MOBILE } from '../../../../../constants/phone';

const createDateDependent = birthDate => {
  const calculatedDate = differenceInYears(new Date(), new Date(birthDate));
  return calculatedDate.toString();
};

const createPassenger = (passengerBySession, idiom, dependent) => {
  const birthDate =
    dependent && dependent.person && dependent.person.birth_date;
  const registrations = passengerBySession
    ? passengerBySession.person_registrations
    : dependent
    ? dependent.registrations
    : [];
  const registration_type = idiom === pt_BR ? 'CPF' : 'PASSPORT';

  return {
    Name: passengerBySession
      ? passengerBySession.Name
      : dependent && dependent.person && dependent.person.first_name
      ? dependent.person.first_name
      : '',
    LastName: passengerBySession
      ? passengerBySession.LastName
      : dependent && dependent.person && dependent.person.last_name
      ? dependent.person.last_name
      : '',
    DateOfBirth: passengerBySession
      ? passengerBySession.DateOfBirth !== ''
        ? new Date(moment(passengerBySession.DateOfBirth).format('MM/DD/YYYY'))
        : ''
      : dependent && dependent.person && dependent.person.birth_date
      ? new Date(moment(dependent.person.birth_date).format('MM/DD/YYYY'))
      : '',
    Age: passengerBySession
      ? passengerBySession.Age
      : dependent && dependent.person && dependent.person.birth_date
      ? createDateDependent(birthDate)
      : '',
    person_registrations: {
      registration_type,
      value: passengerBySession
        ? passengerBySession.person_registrations.value
        : registrations &&
          registrations[0] &&
          registrations[0].value &&
          registrations[0].registration_type === registration_type
        ? registrations[0].value
        : '',
    },
    phone: {
      phone_type: passengerBySession
        ? passengerBySession.phone.phone_type
        : dependent && dependent.phone && dependent.phone.phone_type
        ? dependent.phone.phone_type
        : PHONE_TYPE_MOBILE,
      number: passengerBySession
        ? passengerBySession.phone.number
        : dependent && dependent.phone && dependent.phone.numberValue
        ? dependent.phone.numberValue
        : '',
      international_prefix: passengerBySession
        ? passengerBySession.phone.international_prefix
        : dependent && dependent.phone && dependent.phone.international_prefix
        ? dependent.phone.international_prefix
        : '',
    },
    IsMPH: false,
  };
};

export const getInitialValues = (
  quote,
  dependents,
  isCardHolder,
  idiom,
  passengersBySession
) => {
  let passengerList = [];
  const maxLength =
    quote.Ages && quote.Ages.length ? quote.Ages.length - 1 : dependents.length;

  for (let index = 0; index < maxLength; index++) {
    const passenger = passengersBySession ? passengersBySession[index] : null;
    if (dependents && dependents[index] && isCardHolder) {
      passengerList = [
        ...passengerList,
        createPassenger(passenger, idiom, dependents[index]),
      ];
    } else {
      passengerList = [...passengerList, createPassenger(passenger, idiom)];
    }
  }

  return { Passenger: passengerList };
};
