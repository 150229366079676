import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './OtherInsurance.module.scss';
import Select from '../../../../../../../../components/Select/Select';
import { safeGet } from '../../../../../../../../utils/object';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const OtherInsurance = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue(
      'purchase_protection_items.other_insurance_covering.amount.value',
      ''
    );
  };

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className="col-12">
        <div
          className={`${styles.elementRadio} ${styles[themes]} row`}
          style={{ marginBottom: '12px' }}
        >
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.LABEL_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={
                values.purchase_protection_items.other_insurance_covering
                  .is_internal === 'true'
              }
              text={intl.TEXT_YES}
              name="purchase_protection_items.other_insurance_covering.is_internal"
              dataTestid="InputOtherInsuranceCoveringYes"
              value={true}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.LABEL_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={
                values.purchase_protection_items.other_insurance_covering
                  .is_internal === 'false'
              }
              text={intl.TEXT_NO}
              name="purchase_protection_items.other_insurance_covering.is_internal"
              dataTestid="InputOtherInsuranceCoveringNo"
              value={false}
              onChange={e => {
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      {values.purchase_protection_items.other_insurance_covering.is_internal ===
        'true' && (
        <>
          <div
            className={`col-12 col-sm-12 col-md-6`}
            data-testid="DivOtherInsuranceCoveringField"
          >
            <Input
              value={
                values.purchase_protection_items.other_insurance_covering.amount
                  .value
              }
              label={intl.LABEL_AMOUNT_PAID}
              name="purchase_protection_items.other_insurance_covering.amount.value"
              dataTestid="InputOtherInsuranceCoveringValue"
              type="text"
              maskedMoney
              tooltipText={intl.TEXT_AMOUNt_DECIMAL}
              disabled={
                values.purchase_protection_items.other_insurance_covering
                  .is_internal !== 'true'
              }
              onChange={handleChange}
              touched={safeGet(
                touched,
                'purchase_protection_items.other_insurance_covering.amount.value'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'purchase_protection_items.other_insurance_covering.amount.value'
              )}
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6 `}>
            <Select
              label={intl.LABEL_CURRENCY}
              value={values.purchase_protection_items.price.currency}
              name="purchase_protection_items.other_insurance_covering.amount.currency"
              disabled
              dataTestid="InputOtherInsuranceCoveringCurrency"
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              onChange={handleChange}
              touched={safeGet(
                touched,
                'purchase_protection_items.other_insurance_covering.amount.currency'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'purchase_protection_items.other_insurance_covering.amount.currency'
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OtherInsurance;
