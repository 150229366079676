import { focusOnErrorElement, focusElement } from '../../../../utils/elements';

export const checkErrorsOnForm = async (
  submitRefs,
  accordeonRefs,
  schemas,
  values,
  statusAccordeon
) => {
  if (submitRefs.generalInformation && submitRefs.generalInformation.current) {
    submitRefs.generalInformation.current.click();
    try {
      await schemas.generalInformation.validate(values.generalInformation);
    } catch (error) {
      if (!statusAccordeon.generalInformation) {
        accordeonRefs.generalInformation.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }
  if (
    submitRefs.additionalTravelers &&
    submitRefs.additionalTravelers.current
  ) {
    submitRefs.additionalTravelers.current.click();
    try {
      await schemas.additionalTravelers.validate(values.additionalTravelers);
    } catch (error) {
      if (!statusAccordeon.additionalTravelers) {
        accordeonRefs.additionalTravelers.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  if (submitRefs.trip && submitRefs.trip.current) {
    submitRefs.trip.current.click();
    try {
      await schemas.trip.validate(values.trip);
    } catch (error) {
      if (!statusAccordeon.trip) {
        accordeonRefs.trip.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  return true;
};

export const checkErrorsOnSession = async (
  accordeonRefs,
  currentAcordeon,
  nextAcordeon,
  schemas,
  values,
  validation,
  submitRefs
) => {
  if (accordeonRefs[currentAcordeon]) {
    try {
      if (validation) {
        submitRefs[currentAcordeon].current.click();
        await schemas[currentAcordeon].validate(values[currentAcordeon]);
      }
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
      focusElement(nextAcordeon);
      return true;
    } catch (error) {
      focusOnErrorElement(0);
      return false;
    }
  }
};
