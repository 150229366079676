/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from 'react';
import { initialState } from './initialState';
import * as _actions from './actions';
import moment from 'moment';
import { getAmountFormat } from '../../utils/B2C/currencyISOCode';

export const B2B2CContext = createContext({
  actions: {
    formDataCotizar: {
      setQuote: () => {},
      setPlans: () => {},
      setSelectedPlan: () => {},
      setDataForm: () => {},
      setTerms: () => {},
      setMPH: () => {},
      setPassengers: () => {},
    },
    step: {
      setStep: () => {},
    },
    expire: {
      setExpire: () => {},
    },
  },
  state: initialState(),
  utils: {
    getQuote: () => {},
    getPlans: () => {},
    getSelectedPlan: () => {},
    getTerms: () => {},
    getStep: () => {},
    getExpire: () => {},
    isExpired: () => {},
    getAmountFormated: () => {},
    clearLocalStore: () => {},
    getMPH: () => {},
    getPassenger: () => {},
  },
});

const B2B2CStateParent = props => {
  const { children, initialStateMock } = props;
  const [state, setState] = useState(
    initialStateMock && initialStateMock.state
      ? initialStateMock.state
      : initialState()
  );

  const setQuote = data => {
    _actions.setForm1(data, setState);
  };
  const setPlans = data => {
    _actions.setForm2(data, setState);
  };
  const setSelectedPlan = data => {
    _actions.setForm3(data, setState);
  };
  const setTerms = terms => {
    _actions.setTerms(terms, setState);
  };
  const setMPH = data => {
    _actions.setMPH(data, setState);
  };
  const setPassengers = data => {
    _actions.setPassengers(data, setState);
  };
  const setDataForm = (form1, form2, form3, terms, travellers) => {
    _actions.setDataForm(form1, form2, form3, terms, travellers, setState);
  };
  const setStep = stepValue => {
    _actions.setStep(stepValue, setState);
  };
  const setExpire = () => {
    _actions.setExpire(setState);
  };

  function getQuote() {
    const { Quote } = state.formDataCotizar;
    return Quote;
  }

  function getPlans() {
    const { Plans } = state.formDataCotizar;
    return Plans;
  }

  function getSelectedPlan() {
    const { SelectedPlan } = state.formDataCotizar;
    return SelectedPlan;
  }

  function getTerms() {
    const { Terms } = state.formDataCotizar;
    return Terms;
  }

  function getMPH() {
    const { MPH } = state.formDataCotizar.Travellers;
    return MPH;
  }

  function getPassenger() {
    const { Passengers } = state.formDataCotizar.Travellers;
    return Passengers;
  }

  function getStep() {
    return state.step;
  }

  function getExpire() {
    return state.expire;
  }

  function isExpired() {
    const { expire } = state;
    return expire ? moment().isAfter(expire) : false;
  }

  function getAmountFormated() {
    const { SelectedPlan } = state.formDataCotizar;
    return getAmountFormat(SelectedPlan.data.amount);
  }

  function clearLocalStore() {
    localStorage.removeItem('formDataCotizar');
    localStorage.removeItem('step');
    localStorage.removeItem('expire');
  }

  useEffect(() => {
    const expire = JSON.parse(localStorage.getItem('expire')) || getExpire();
    const formDataCotizar = JSON.parse(localStorage.getItem('formDataCotizar'));

    if (expire && formDataCotizar) {
      setExpire(expire);
      if (moment().isAfter(expire)) {
        clearLocalStore();
      } else {
        const { Quote } = formDataCotizar.Quote;
        const form2data = formDataCotizar.Plans;
        const form3data = formDataCotizar.SelectedPlan;
        const terms = formDataCotizar.Terms;
        const travellers = formDataCotizar.Travellers;
        setDataForm({ Quote }, form2data, form3data, terms, travellers);
      }
    } else {
      clearLocalStore();
    }
  }, []);

  const value = {
    actions: {
      formDataCotizar: {
        setQuote,
        setPlans,
        setSelectedPlan,
        setTerms,
        setDataForm,
        setMPH,
        setPassengers,
      },
      step: {
        setStep,
      },
      expire: {
        setExpire,
      },
    },
    state,
    utils: {
      getQuote,
      getPlans,
      getSelectedPlan,
      getTerms,
      getStep,
      getExpire,
      isExpired,
      getAmountFormated,
      clearLocalStore,
      getMPH,
      getPassenger,
    },
  };

  return (
    <B2B2CContext.Provider value={value}>{children}</B2B2CContext.Provider>
  );
};

export default B2B2CStateParent;
