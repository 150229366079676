import React, { useContext, useEffect } from 'react';
import Calendar from '../../../../../../../../../../components/Calendar/Calendar';
import Input from '../../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../../components/Select/Select';
import * as translations from '../../intl';
import { IntlContext } from '../../../../../../../../../../intl';
import sanitize from '../../../../../../../../../../utils/sanitize';

const ChargeItem = props => {
  const {
    index,
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    setFieldTouched,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const sumTotal = (value, index) => {
    let total = Number(value);
    setFieldValue(`incident.expenses[${index}].amount.value`, total.toFixed(2));
  };

  return (
    <>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.incident.expenses[index].description}
          label={intl.LABEL_DESCRIPTION_CHARGE}
          name={`incident.expenses[${index}].description`}
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].description
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].description
          }
          maxLength="2000"
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.expenses[index].date}
          label={intl.LABEL_CHARGE_DATE}
          name={`incident.expenses[${index}].date`}
          onChange={event => {
            handleChange(event);
          }}
          maxDate={new Date()}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].date
          }
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].date
          }
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-10 col-md-6">
        <Input
          value={values.incident.expenses[index].amount.value}
          label={intl.LABEL_AMOUNT}
          name={`incident.expenses[${index}].amount.value`}
          type="text"
          maskedMoney
          onChange={event => {
            sumTotal(event.target.value, index);
            handleChange(event);
          }}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].amount &&
            touched.incident.expenses[index].amount.value
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].amount &&
            errors.incident.expenses[index].amount.value
          }
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          label={intl.LABEL_CURRENCY}
          value={values.incident.expenses[0].amount.currency}
          name={`incident.expenses[${index}].amount.currency`}
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          disabled={index !== 0}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].amount &&
            touched.incident.expenses[index].amount.currency
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].amount &&
            errors.incident.expenses[index].amount.currency
          }
        />
      </div>
    </>
  );
};
export default ChargeItem;
