import { focusOnErrorElement } from '../../../../../utils/elements';

export const checkErrorsOnSession = async (
  accordeonRefs,
  currentAcordeon,
  nextAcordeon,
  schemas,
  values,
  validation,
  submitRefs
) => {
  if (accordeonRefs[currentAcordeon]) {
    try {
      if (validation) {
        submitRefs[currentAcordeon].current.click();
        await schemas[currentAcordeon].validate(values[currentAcordeon]);
      }
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
      return true;
    } catch (error) {
      focusOnErrorElement(0);
      return false;
    }
  }
};

export const checkErrorsOnForm = async (
  submitRefs,
  accordeonRefs,
  schemas,
  values,
  statusAccordeon,
  trackEventUserAction
) => {
  if (submitRefs.patient) {
    submitRefs.patient.current.click();
    try {
      await schemas.patient.validate(values.patient);
    } catch (error) {
      trackEventUserAction('#### (CLAIM) VALIDANDO FORM - patient ####', error);
      if (!statusAccordeon.patient) {
        accordeonRefs.patient.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  if (submitRefs.consultation) {
    submitRefs.consultation.current.click();
    try {
      await schemas.consultation.validate(values.consultation);
    } catch (error) {
      trackEventUserAction(
        '#### (CLAIM) VALIDANDO FORM - consultation ####',
        error
      );
      if (!statusAccordeon.consultation) {
        accordeonRefs.consultation.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }
  if (submitRefs.appointment) {
    submitRefs.appointment.current.click();
    try {
      await schemas.appointment.validate(values.appointment);
    } catch (error) {
      trackEventUserAction(
        '#### (CLAIM) VALIDANDO FORM - appointment ####',
        error
      );
      if (!statusAccordeon.appointment) {
        accordeonRefs.appointment.current.click();
      }
      focusOnErrorElement(0);
      return;
    }
  }

  return true;
};
