/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { matchBenefitByURL, NOT_FOUND_ERROR } from './policies';

const RedirectToPDF = () => {
  const { type, benefit, country } = useParams();
  let history = useHistory();

  const checkAndDownloadDocument = () => {
    try {
      let url = matchBenefitByURL(type, benefit, country);
      if (url) {
        let link = document.createElement('a');
        link.href = url;
        link.dispatchEvent(new MouseEvent('click'));
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error.message === NOT_FOUND_ERROR) {
        history.push('/');
      }
    }
  };

  useEffect(() => {
    checkAndDownloadDocument();
  }, []);

  return <></>;
};

export default RedirectToPDF;
