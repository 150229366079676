import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './IncidentArea.module.scss';
import sanitize from '../../../../../../../../utils/sanitize';

const IncidentArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldTouched,
    limitDate,
    exceptionCountry,
    limitedCards,
  } = props;

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Calendar
          value={values.incident.date}
          label={intl.LABEL_INCIDENT_DATE}
          name="incident.date"
          onChange={handleChange}
          maxDate={limitedCards && !exceptionCountry ? limitDate : new Date()}
          touched={touched.incident && touched.incident.date}
          error={errors.incident && errors.incident.date}
          setFieldTouched={setFieldTouched}
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.LABEL_INCIDENT_COUNTRY}
          value={values.incident.location.country}
          name="incident.location.country"
          dataTestid="InputCarRentalIncidentCountry"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_INCIDENT_COUNTRY}</option> ${intl.COUNTRY_OPTIONS}`
            ),
          }}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.country
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.country
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.incident.location.state}
          label={intl.LABEL_INCIDENT_STATE}
          name="incident.location.state"
          dataTestid="InputCarRentalIncidentState"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.state
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.state
          }
        />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Input
          value={values.incident.location.locality}
          label={intl.LABEL_INCIDENT_CITY}
          name="incident.location.locality"
          dataTestid="InputCarRentalIncidentCity"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.locality
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.locality
          }
        />
      </div>
      <div className="col-12">
        <Input
          value={values.incident.location.place}
          label={intl.LABEL_INCIDENT_PLACE}
          name="incident.location.place"
          dataTestid="InputCarRentalIncidentPlace"
          type="text"
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.place
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.place
          }
        />
      </div>
    </div>
  );
};

export default IncidentArea;
