import { pt_countries } from '../../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../../intl/es_countries';

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const LABEL_INCIDENT_COUNTRY = {
  en_US: 'Country of incident *',
  pt_BR: 'País do incidente *',
  es_ES: 'País del incidente *',
};

export const LABEL_INCIDENT_STATE = {
  en_US: 'State of incident *',
  pt_BR: 'Estado do incidente *',
  es_ES: 'Estado del incidente *',
};

export const LABEL_INCIDENT_CITY = {
  en_US: 'City of incident *',
  pt_BR: 'Cidade do incidente *',
  es_ES: 'Ciudad incidente *',
};

export const LABEL_INCIDENT_PLACE = {
  en_US: 'Place of incident *',
  pt_BR: 'Local do incidente *',
  es_ES: 'Lugar del incidente *',
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const ITEM_PURCHASED = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const NAME_STORE_WEBSITE = {
  en_US: 'Name of store/website advertising the product *',
  pt_BR: 'Nome da loja/website que anuncia o produto *',
  es_ES: 'Nombre de la tienda que anuncia el producto *',
};

export const ADVERTISEMENT_LOCATION = {
  en_US:
    'Advertisement location (i.e. newspaper, online) and publication name *',
  pt_BR:
    'Localização do anúncio (por exemplo, loja ou website) e nome da publicação *',
  es_ES:
    'Ubicación del anuncio (es decir, periódico, en línea) y nombre de la publicación *',
};

export const ORIGINAL_PURCHASE_PRICE = {
  en_US: 'Original purchase price *',
  pt_BR: 'Preço de compra original *',
  es_ES: 'Precio de la compra original *',
};

export const NEW_ADVERTISE_PRICE = {
  en_US: 'New advertise price *',
  pt_BR: 'Novo preço anunciado *',
  es_ES: 'Nuevo precio anunciado *',
};

export const PRIOR_MISSED_CONNECTION = {
  en_US: 'Portion prior to missed connection',
  pt_BR: 'Primeiro trajeto antes da perda de conexão',
  es_ES: 'Primer trayecto antes de la pérdida de conexión',
};

export const DEPARTURE_DATE = {
  en_US: 'Departure date *',
  pt_BR: 'Data de partida *',
  es_ES: 'Fecha de salida *',
};

export const DEPARTURE_TIME = {
  en_US: 'Departure time *',
  pt_BR: 'Hora de partida *',
  es_ES: 'Hora de salida *',
};

export const ARRIVAL_DATE = {
  en_US: 'Arrival date *',
  pt_BR: 'Data de chegada *',
  es_ES: 'Fecha de llegada *',
};

export const ARRIVAL_TIME = {
  en_US: 'Arrival time *',
  pt_BR: 'Hora de chegada *',
  es_ES: 'Hora de llegada *',
};

export const MISSED_CONNECTION_PORTION = {
  en_US: 'Missed connection portion',
  pt_BR: 'Trajeto referente a perda de conexão',
  es_ES: 'Trayecto perdido',
};

export const MISSED_CONNECTION = {
  en_US: 'Missed connection',
  pt_BR: 'Perda de conexão',
  es_ES: 'Pérdida de conexión',
};

export const MISSED_CONNECTION_AIRLINE = {
  en_US: 'Missed connection airline *',
  pt_BR: 'Companhia aérea da perda de conexão *',
  es_ES: 'Aerolínea de pérdida de conexión *',
};

export const MISSED_CONNECTION_DATE = {
  en_US: 'Missed connection date *',
  pt_BR: 'Data da perda de conexão *',
  es_ES: 'Fecha de pérdida de conexión *',
};

export const MISSED_CONNECTION_TIME = {
  en_US: 'Missed connection time *',
  pt_BR: 'Hora da perda de conexão *',
  es_ES: 'Hora de pérdida de conexión *',
};

export const DESCRIBE_REASONS = {
  en_US: 'Describe the reasons that led the missed connection *',
  pt_BR: 'Descreva brevemente os motivos que causaram a perda de conexão *',
  es_ES: 'Describa los motivos que provocaron la pérdida de conexión *',
};
