export const TEXT_SELECT_CERTIFICATE = {
  en_US:
    'Please, select the travel assistance certificate to be associated to your Visa Travel Kit.',
  pt_BR:
    'Por favor, escolha o bilhete de seguro viagem que será associado ao seu Visa Travel Kit.',
  es_ES:
    'Por favor, selecciona el certificado de asistencia en viaje que deseas asociar a tu Visa Travel Kit.',
};

export const TEXT_NO_CERTIFICATE_FOUND = {
  en_US: 'No active certificate was found for this period',
  es_ES: 'No se encontró ningún certificado activo para este período.',
  pt_BR: 'Nenhum bilhete válido foi encontrado para esse período.',
};

export const LOADING_CERTIFICATE_INFORMATION = {
  en_US: 'Loading certificate information',
  pt_BR: 'Carregando informações do certificado',
  es_ES: 'Cargando información del certificado',
};

export const CONFIRM_TRAVEL_INFORMATION = {
  en_US: 'Confirm travel information',
  pt_BR: 'Confirmar informações de viagem',
  es_ES: 'Confirmar información de viaje',
};

export const LABEL_CREATE_CERTIFICATE = {
  en_US: 'Generate a new certificate',
  es_ES: 'Genera un nuevo certificado',
  pt_BR: 'Crie um novo bilhete',
};
