import moment from 'moment';
import { certificatesServices } from '../../../../../../services';

export const formatCertificateExtendedWarrantyBR = (data, props) => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- FORMAT EXTENDED WARRANTY BR',
    data
  );
  data.appliance_information = {
    purchase_date: moment(new Date()).format('YYYY-MM-DD'),
  };
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI CRIAR EXTENDED WARRANTY BR',
    true
  );
  return certificatesServices.postApplianceAnual(data, props.idiomForApi());
};
