import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import styles from './PatientItem.module.scss';
import * as translations from './intl';
import CheckBox from '../../../../../../../components/CheckBox/CheckBox';
import { differenceInCalendarYears } from 'date-fns';
import InfoLabelValue from '../../../../../../../components/InfoLabelValue/InfoLabelValue';

const PatientItem = props => {
  const {
    index,
    name,
    age,
    select = false,
    setSelectedIndex,
    patient,
    setValues,
    values,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const patientValueFormatted = () => {
    const copyValues = JSON.parse(JSON.stringify(values));
    copyValues.name = patient.person.first_name;
    copyValues.surname = patient.person.last_name;
    copyValues.birth_date = patient.person.birth_date;
    copyValues.age = differenceInCalendarYears(
      new Date(),
      new Date(patient.person.birth_date)
    );
    copyValues.phone = (patient.phone && patient.phone.number) || values.phone;
    copyValues.phone_prefix =
      (patient.phone && patient.phone.international_prefix) ||
      values.phone_prefix;
    return copyValues;
  };

  const handleClick = () => {
    setSelectedIndex(index);
    const formattedValues = patientValueFormatted();
    setValues(formattedValues);
  };

  return (
    <div
      className={`${styles.patientItem} ${
        select ? styles.patientItemSelected : ''
      }`}
      data-testid="ListCardItem"
    >
      <div className={`row `}>
        <div className={`col-12 col-md-3`}>
          <InfoLabelValue
            label={intl.LABEL_FIRST_NAME}
            value={patient.person.first_name}
          />
        </div>
        <div className={`col-12 col-md-3`}>
          <InfoLabelValue
            label={intl.LABEL_LAST_NAME}
            value={patient.person.last_name}
          />
        </div>
        <div className={`col-12 col-md-3`}>
          <InfoLabelValue
            label={intl.TEXT_AGE}
            value={`${age} ${intl.TEXT_YEARS_OLD}`}
          />
        </div>
      </div>
      <div className="row">
        <div className={`col-12 col-md-3`}>
          <CheckBox value={select} onClick={handleClick}>
            {select
              ? intl.CHECKBOX_LABEL_IS_SELECTED
              : intl.CHECKBOX_LABEL_SELECT}
          </CheckBox>
        </div>
      </div>
    </div>
  );
};

export default PatientItem;
