export const TITLE_UPSELL = {
  en_US: '',
  es_ES:
    'Mejora tu cobertura de tu Servicio de Emergencia Médica Internacional',
  pt_BR: '',
};

export const TEXT_BUTTON_UPSELL = {
  en_US: '',
  es_ES: 'Mejora tu cobertura',
  pt_BR: '',
};

export const TITLE_CROSSELL = {
  en_US: '',
  es_ES: 'Servicio de Emergencia Médica Internacional',
  pt_BR: '',
};

export const TEXT_BUTTON_CROSSELL = {
  en_US: '',
  es_ES: 'Cotizar',
  pt_BR: '',
};

export const TERMS_AND_CONDITIONS = {
  en_US: '',
  es_ES: '<i>*Consulta los <strong>términos y condiciones</strong></i>',
  pt_BR: '',
};

export const NEW_TAB = {
  en_US: '',
  pt_BR: '',
  es_ES: 'términos y condiciones - nueva pestaña',
};
