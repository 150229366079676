import { useState } from 'react';
import styles from './ButtonSortList.module.scss';
import { useIntl } from '../../intl';
import * as translations from './intl';
import Button from '../../componentsV2/Button/Button';
import OrderIcon from '../../assets/icons/OrderIcon';

export const SORT_ASC = 'ASC';
export const SORT_DESC = 'DESC';

const ButtonSortList = ({
  initialOrder,
  actionToSortASC,
  actionToSortDESC,
}) => {
  const [sortType, setSortType] = useState(initialOrder || SORT_ASC);
  const { translate } = useIntl();

  const intl = translate(translations);

  const onClick = () => {
    if (sortType === SORT_ASC) {
      actionToSortDESC();
      setSortType(SORT_DESC);
    } else {
      actionToSortASC();
      setSortType(SORT_ASC);
    }
  };

  return (
    <div className={styles.headerActions} onClick={onClick}>
      <Button
        type="Tertiary"
        label={sortType === SORT_ASC ? intl.TEXT_RECENT : intl.TEXT_LESS_RECENT}
        leadingIcon={<OrderIcon color="Duotone" />}
        trailingIcon={sortType === SORT_ASC ? 'Chevron Down' : 'Chevron Up'}
        onClick={onClick}
      />
    </div>
  );
};

export default ButtonSortList;
