import moment from 'moment';
import { getPrice } from '../PurchaseProtection/initialValues';
export const atmInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let affectedPerson =
    completeClaim && completeClaim.affected_person
      ? completeClaim.affected_person
      : {};
  let incident =
    completeClaim && completeClaim.incident ? completeClaim.incident : {};
  let otherInsuranceCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[0]
      : [{}];
  let location =
    completeClaim && completeClaim.incident && completeClaim.incident.location
      ? completeClaim.incident.location
      : {};

  const getAffectedPerson = affectedPerson => {
    if (incident && incident.kind === 'DEATH') {
      return {
        deceased_at:
          affectedPerson && affectedPerson.deceased_at
            ? new Date(moment(affectedPerson.deceased_at, 'YYYY-MM-DD'))
            : '',
        relationship: 'Cardholder',
        person: getPerson(person),
      };
    }

    return {
      relationship: 'Cardholder',
      person: getPerson(person),
    };
  };

  const getPerson = person => {
    return {
      first_name: person && person.first_name ? person.first_name : '',
      last_name: person && person.last_name ? person.last_name : '',
    };
  };

  const getIncident = incident => {
    let incidentDate =
      incident && incident.date ? incident.date.split(' ') : '';

    return {
      date: incidentDate
        ? new Date(moment(incidentDate, 'DD/MM/YYYY HH:mm'))
        : '',
      description: incident && incident.description ? incident.description : '',
      hour: incidentDate ? incidentDate[1] : '',
      kind: incident && incident.kind ? incident.kind : 'LOSS',
      location: getLocation(location),
      withdrawn_amount: {
        value:
          incident &&
          incident.withdrawn_amount &&
          incident.withdrawn_amount.value
            ? incident.withdrawn_amount.value
            : '',
        currency:
          incident &&
          incident.withdrawn_amount &&
          incident.withdrawn_amount.currency
            ? incident.withdrawn_amount.currency
            : '',
      },
      stolen_amount: {
        value:
          incident && incident.stolen_amount && incident.stolen_amount.value
            ? incident.stolen_amount.value
            : '',
        currency:
          incident && incident.stolen_amount && incident.stolen_amount.currency
            ? incident.stolen_amount.currency
            : '',
      },
    };
  };

  const getLocation = location => {
    const loc = location && location ? incident.location : {};

    return {
      country: loc && loc.country ? loc.country : '',
      locality: loc && loc.locality ? loc.locality : '',
      postal_code: loc && loc.postal_code ? loc.postal_code : '',
      state: loc && loc.state ? loc.state : '',
      street_address: loc && loc.street_address ? loc.street_address : '',
      subdivision: loc && loc.subdivision ? loc.subdivision : '',
    };
  };

  const getOtherInsuranceCovering = otherInsuranceCovering => {
    return {
      checked:
        otherInsuranceCovering &&
        otherInsuranceCovering.is_internal !== undefined
          ? String(!otherInsuranceCovering.is_internal)
          : 'false',
      amount: getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
    };
  };
  return {
    affected_person: getAffectedPerson(affectedPerson),
    incident: getIncident(incident),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
  };
};
