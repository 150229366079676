import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { getPhoneType } from '../../../../../../utils/formatInfoProfile';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const agency = claimDetails && claimDetails.car_rental.agency;

  const agencyName = agency && agency.name ? agency.name : '-';

  const phoneType =
    agency && agency.phone.phone_type ? agency.phone.phone_type : '-';

  const phoneNumberValue =
    agency && agency.phone.number ? agency.phone.number : '-';

  const phoneInternationalPrefix = agency && agency.phone.international_prefix;

  const agencyPhoneNumber = `${phoneInternationalPrefix} ${phoneNumberValue}`;

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_CAR_RENTAL_COMPANY}
          value={agencyName}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_PHONE_TYPE}
          value={getPhoneType(phoneType, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_PHONE_COMPANY}
          value={agencyPhoneNumber}
        />
      </div>
    </>
  );
};

export default PersonalArea;
