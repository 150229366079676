/* eslint-disable indent */
import React, { useContext, useState } from 'react';

import InfoLabelValue from '../../../InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { useEffect } from 'react';

import { formatCustomDate } from '../../../../utils/date';
import {
  getFlightNumber,
  getIncidentDescription,
  getIncidentPlace,
  getPrice,
  getTripPeriod,
} from '../../../../utils/claimsAPI/TripUtils';
import { getTheCorrectAmountByStatus } from '../utilsDetails';
import { formatMonetaryMask } from '../../../../utils/currency';

const TripCancellationDetail = props => {
  const { claimDetails } = props;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const [tripInformation, setTripInformation] = useState({});
  const [incident, setIncident] = useState({});
  const [agency, setAgency] = useState({});
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});

  useEffect(() => {
    const tripInformation =
      (claimDetails && claimDetails.trip_information) || {};
    setTripInformation(tripInformation);

    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const agency = (claimDetails && claimDetails.agency) || [];
    setAgency(agency);

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);
  }, [claimDetails]);

  const cancellationDate =
    tripInformation && tripInformation.interruption_date
      ? tripInformation.interruption_date
      : '-';

  const { reimbursedValue, reimbursedLabel, reimbursedCurrency } =
    getTheCorrectAmountByStatus(claimDetails, idiom);

  return (
    <div className="row">
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={getTripPeriod(tripInformation, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_CANCELLATION_DATE}
          value={formatCustomDate(cancellationDate, null, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_FLIGHT_NUMBER}
          value={getFlightNumber(agency)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_CANCELLATION_REASON}
          value={getIncidentDescription(tripInformation)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_PLACE}
          value={getIncidentPlace(incident, idiom)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getPrice(totalAmountClaimed, '-', idiomForApi())}
        />
      </div>
      {reimbursedLabel && reimbursedValue && reimbursedCurrency && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={reimbursedLabel}
            value={`${formatMonetaryMask(
              reimbursedValue,
              idiomForApi()
            )} ${reimbursedCurrency}`}
          />
        </div>
      )}
    </div>
  );
};

export default TripCancellationDetail;
