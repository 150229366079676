import { person } from '../../../../../../../../../@types/APIs/Person';

export interface PaymentDetail {
  payment_id: string;
  card: number;
  month: number;
  year: number;
  default?: boolean;
}

export interface UserDetail {
  name: {
    first: string;
    last: string;
  };
  birth_date: {
    month: number;
    year: number;
    day: number;
  };
}

export interface AccountDetailOCT {
  payments: PaymentDetail;
  user_detail: UserDetail;
  person?: person;
}

interface objectName {
  first: string;
  last: string;
}

interface objectCard {
  number: string;
  expirationDate: string;
  cvv?: string;
}

export interface ObjectOCTInitialValues {
  name: objectName;
  birth_date: string;
  card: objectCard;
  paymentIdOCT: string;
}

export const OCTInitialValues: ObjectOCTInitialValues = {
  name: {
    first: '',
    last: '',
  },
  birth_date: '',
  card: {
    number: '',
    expirationDate: '',
    cvv: '',
  },
  paymentIdOCT: '',
};

export const getInicialValuesOCT = (
  account_detail?: AccountDetailOCT,
  paymentID?: string,
  profileFlow?: boolean
): ObjectOCTInitialValues => {
  const payment_id = paymentID || account_detail?.payments?.payment_id;
  const person = account_detail?.person;

  return {
    name: {
      first: person?.first_name ? person?.first_name : '',
      last: person?.last_name ? person?.last_name : '',
    },
    birth_date: person?.birth_date ? person?.birth_date : '',
    card: {
      number:
        account_detail?.payments?.card && !profileFlow
          ? `****.****.****.${String(account_detail?.payments?.card)}`
          : '',
      expirationDate:
        account_detail?.payments?.month && !profileFlow
          ? `${account_detail?.payments?.month}/${String(
              account_detail?.payments?.year
            ).substring(2)}`
          : '',
      cvv: account_detail?.payments?.card && !profileFlow ? '***' : '',
    },
    paymentIdOCT: payment_id && !profileFlow ? payment_id : null,
  };
};
