import { cotizarService } from '../../../../../services';
import { dataLayerB2B2CQuote } from '../../../../../utils/GTM_helper';
import { formatQuoteToApi } from './format/formatQuote';
import { differenceInDays } from 'date-fns';

export const submit = async props => {
  const { formType } = props;
  const quote = formatQuoteToApi(props);
  return await cotizarService.createCotizar(quote, formType);
};

const checkObjData = data => {
  if (data && data.QuoteVISA) {
    return data.QuoteVISA.ProductVISA && data.QuoteVISA.ProductVISA.length > 0;
  }
  if (data && data.QuoteUS) {
    return data.QuoteUS.ProductUS && data.QuoteUS.ProductUS.length > 0;
  }
  return false;
};

const filterPlansByValidityDays = (quote, plans) => {
  const travelDays = differenceInDays(
    new Date(quote.TripEndDate),
    new Date(quote.TripInitialDate)
  );

  let perfectCoverageDays = plans.ProductVISA[0].Product.ValidityDays;
  plans.ProductVISA.forEach(plan => {
    let planDays = plan.Product.ValidityDays;
    if (planDays >= travelDays && planDays < perfectCoverageDays) {
      perfectCoverageDays = planDays;
    } else if (perfectCoverageDays < travelDays) {
      perfectCoverageDays = planDays;
    }
  });

  if (perfectCoverageDays >= travelDays) {
    return {
      ProductVISA: plans.ProductVISA.filter(
        plan => plan.Product.ValidityDays === perfectCoverageDays
      ),
    };
  } else {
    return {
      ProductVISA: plans.ProductVISA.filter(plan => plan.Product.Annual),
    };
  }
};

export const submitACotizar = async props => {
  const { actions, values, utils, CONFIG_B2B2C, trackEventUserAction } = props;

  try {
    const quoteResponse = await submit(props);
    trackEventUserAction('#### (B2B2C) QUOTE FEITO', quoteResponse);
    const Quote = { ...values.Quote };
    Quote.recaptcha = ''; // clear recaptcha

    const existingQuote = utils.getQuote();
    if (CONFIG_B2B2C.purchase.back_link === '/upsell-benefit') {
      Quote.TripInitialDate =
        existingQuote && existingQuote.Quote.TripInitialDate
          ? existingQuote.Quote.TripInitialDate
          : '';
      Quote.TripEndDate =
        existingQuote && existingQuote.Quote.TripEndDate
          ? existingQuote.Quote.TripEndDate
          : '';
    }

    const { data } = quoteResponse;

    const dataLayerObjQuote = {
      number_travellers: values.Quote.numberTraleversInput,
      travellers_under_69: values.Quote.Ages.filter(item => item.value < 70)
        .length,
      travellers_over_70: values.Quote.Ages.filter(item => item.value >= 70)
        .length,
    };
    dataLayerB2B2CQuote(dataLayerObjQuote);

    if (checkObjData(data)) {
      actions.expire.setExpire();
      actions.formDataCotizar.setQuote({ Quote });
      const filteredPlans = filterPlansByValidityDays(Quote, data.QuoteVISA);
      actions.formDataCotizar.setPlans(filteredPlans);
    } else {
      actions.formDataCotizar.setPlans({ isEmpty: true });
    }
  } catch (error) {
    trackEventUserAction('#### (B2B2C) ERRO NO QUOTE', {
      error,
      error_response: error.response,
    });
  }
};
