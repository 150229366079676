import React, { useContext } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from '../intl';
import styles from './Card.module.scss';
import { formatProductType } from './formatProductType';

const Card = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { product } = props;
  const cardType = formatProductType(product);

  return (
    <div className={`${styles.card}`} role="card">
      <div>
        <img src={product.images[0].content_url} alt="" />
      </div>
      <span className={styles.detail}>
        <p className={styles.title}>{product.name}</p>
        <p className={styles.subtitle}>
          {cardType === 'C'
            ? intl.CREDIT
            : cardType === 'D'
            ? intl.DEBIT
            : intl.PREPAID}
        </p>
      </span>
    </div>
  );
};

export default Card;
