import React, { useState, useRef, useContext } from 'react';
import AcordeonSession from '../../../../components/AcordeonSession/AcordeonSession';
import styles from './PaymentInformation.module.scss';
import PaymentForm from './PaymentForm/PaymentForm';
import { checkErrorsOnSession } from '../../../Claims/Generate/ClaimFillForm/Form/checkErrorsOnForm';
import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import { checkErrorsOnForms } from './checkErrosOnForms';
import PurchaseSummary from './PurchaseSummary/PurchaseSummary';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { B2B2CContext } from '../../../../components/B2B2CStateParent/B2B2CStateParent';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const PaymentInformation = props => {
  const { backStep, formType, benefitDetail, CONFIG_B2B2C } = props;
  const [listAccordeon, setListAccordeon] = useState({
    payment: true,
  });
  const [schemas] = useState({});
  const [values] = useState({});
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate } = useContext(IntlContext);
  const { utils } = useContext(B2B2CContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const selectedPlan = utils.getSelectedPlan();
  const intl = translate(translations);
  const theme = getGlobalTheme();

  const CONFIG_TEXT_COVERAGE_DATE = CONFIG_B2B2C.quote.text.text_coverage_date(
    intl,
    selectedPlan ? selectedPlan.Annual : undefined
  );

  const accordeonRefs = {
    payment: useRef(null),
  };

  const submitRefs = {
    payment: useRef(null),
  };

  const setStatusAccordeon = selected => {
    const newListAccordeon = { ...listAccordeon };
    Object.keys(newListAccordeon).map(key => {
      return (newListAccordeon[key] = false);
    });
    setListAccordeon({
      ...newListAccordeon,
      [selected]: !listAccordeon[selected],
    });
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true
  ) => {
    const checkErrorOnSession = await checkErrorsOnSession(
      accordeonRefs,
      currentAcordeon,
      targetAcordeon,
      {},
      {},
      validation,
      submitRefs
    );

    return checkErrorOnSession;
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const checkFormsValidation = () =>
    checkErrorsOnForms(
      submitRefs,
      accordeonRefs,
      schemas,
      values,
      listAccordeon,
      trackEventUserAction
    );

  return (
    <div id="mainContent" className={styles.container}>
      <AcordeonSession
        id="payment"
        title={intl.TITLE_PAYMENT}
        openButtonRef={accordeonRefs.payment}
        status={listAccordeon.payment}
        setStatus={() => setStatusAccordeon('payment')}
        back={backStep}
        next={() => submitRefs.payment.current.click()}
        txtBtnNext={intl.COMPLETE_PURCHASE}
        axa
        notOpen
      >
        <>
          <h3 className={`${styles.planTitle} ${styles[theme]}`}>
            {selectedPlan.Name}
          </h3>
          <div className="row" style={{ position: 'relative' }}>
            <div className="col-12 col-lg-6 col-xl-8">
              <PurchaseSummary />
            </div>
            <div className={`col-12 col-lg-6 col-xl-4`}>
              <PaymentForm
                submitRef={submitRefs.payment}
                formType={formType}
                benefitDetail={benefitDetail}
                updateParent={updateForm.bind(null, 'payment')}
                checkFormsValidation={checkFormsValidation}
                backLink={CONFIG_B2B2C.purchase.quote_detail}
              />
            </div>
            <div className={styles.rowAbsolute}>
              <h3 className={`${styles.subtitle} ${styles[theme]}`}>
                {CONFIG_TEXT_COVERAGE_DATE.disclaimer}
              </h3>
            </div>
          </div>
        </>
      </AcordeonSession>
    </div>
  );
};

export default PaymentInformation;
