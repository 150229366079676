/* eslint-disable no-console */
import React, { useContext, useState, useEffect } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../intl';
import styles from './CommunicationPreferences.module.scss';
import { userServices } from '../../../../services';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import NavHashLink from '../../../../components/NavHashLink/NavHashLink';
import { NotificationContext } from '../../../../components/NotificationContextParent/NotificationContextParent';

const COMMUNICATIONS = {
  MARKETING: 'MARKETING',
  PROMOTION: 'PROMOTION',
  TIPS: 'TIPS',
  CUSTOMER_NOTIFICATION: 'CUSTOMER_NOTIFICATION',
  WHATSAPP: 'WHATSAPP',
};

const CHANNEL_EMAIL = 'EMAIL';
const CHANNEL_PUSH_NOTIFICATION = 'PUSH_NOTIFICATION';
const CHANNEL_WHATSAPP = 'WHATSAPP';

const CommunicationPreferences = props => {
  const {
    communicationDevices,
    preferences = {
      MARKETING: false,
      PROMOTION: false,
      TIPS: false,
      CUSTOMER_NOTIFICATION: false,
      WHATSAPP: false,
    },
    setPreferences,
    setCheckBoxLoading,
    checkBoxLoading = {
      MARKETING: true,
      PROMOTION: true,
      CUSTOMER_NOTIFICATION: true,
      WHATSAPP: true,
    },
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    actions: {
      communicationPreferences: { updateCommunicationPrefences },
    },
  } = useContext(NotificationContext);
  const { utils, actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    updatePreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirectHelpLink = (
    <NavHashLink to="/help#notifications" onClick={actions.modal.closeModal}>
      {intl.SUBTITLE_COMPLEMENT}
    </NavHashLink>
  );

  const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const updatePreferences = async () => {
    try {
      await sleep(4000);
      const { data } = await userServices.getPreferences(utils.getCn());
      if (data.length > 0) {
        setPreferences({
          MARKETING: data.find(
            item => item.related_to === COMMUNICATIONS.MARKETING
          ),
          PROMOTION: data.find(
            item => item.related_to === COMMUNICATIONS.PROMOTION
          ),
          TIPS: data.find(item => item.related_to === COMMUNICATIONS.TIPS),
          CUSTOMER_NOTIFICATION: data.find(
            item => item.related_to === COMMUNICATIONS.CUSTOMER_NOTIFICATION
          ),
          WHATSAPP: data.find(
            item =>
              item.related_to === COMMUNICATIONS.CUSTOMER_NOTIFICATION &&
              item.channel === CHANNEL_WHATSAPP
          ),
        });
      }
      setCheckBoxLoading({
        MARKETING: false,
        PROMOTION: false,
        CUSTOMER_NOTIFICATION: false,
        WHATSAPP: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getCustomerNotificationIfIsCustom = related => {
    return related === COMMUNICATIONS.WHATSAPP
      ? COMMUNICATIONS.CUSTOMER_NOTIFICATION
      : related;
  };

  const handleChange = async (related, channel, device_ids) => {
    setCheckBoxLoading({
      ...checkBoxLoading,
      [related]: true,
    });

    !preferences[related]
      ? await optInCommunication(related, channel, device_ids)
      : await optOutCommunication(related);

    setCheckBoxLoading({
      ...checkBoxLoading,
      [related]: false,
    });

    updateCommunicationPrefences();
  };

  const optInCommunication = async (
    related,
    channel = CHANNEL_EMAIL,
    device_ids = undefined
  ) => {
    try {
      const { data } = await userServices.optInCommunication(
        utils.getCn(),
        getCustomerNotificationIfIsCustom(related),
        channel,
        device_ids
      );

      setPreferences({
        ...preferences,
        [related]: data,
      });
    } catch (err) {
      if (err && err.response) {
        if (
          err.response.data.error_description ===
          'Invalid body. communication_device_ids is required for PUSH_NOTIFICATION channel'
        ) {
          actions.modal.showModal(
            false,
            true,
            <ModalInformation
              type="error"
              message={intl.TITLE_AT_LEAST_ONE_DEVICE}
              subtitle={intl.SUBTITLE_HOW_TO_RECEIVE}
              textBtn={intl.BUTTON_CLOSE}
              content={redirectHelpLink}
            />,
            true,
            true
          );
        }
      }
    }
  };

  const optOutCommunication = async related => {
    try {
      const subscriptionId = preferences[related].communication_subscription_id;
      await userServices.optOutCommunication(utils.getCn(), subscriptionId);
      setPreferences({
        ...preferences,
        [related]: false,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <h2 className={styles[themes]}>{intl.TITLE}</h2>
      <span className={styles[themes]}>{intl.SUBTILE}</span>
      <div className={styles.checkboxes}>
        <div className={styles.checkboxItem}>
          <CheckBox
            isLoading={checkBoxLoading.MARKETING}
            name={COMMUNICATIONS.MARKETING}
            value={preferences.MARKETING}
            onClick={() => handleChange(COMMUNICATIONS.MARKETING)}
          >
            <span
              className={`${styles[themes]} ${styles.title} ${
                preferences.MARKETING ? styles.active : ''
              }`}
            >
              {intl.TEXT_EMAILS_TITLE}
            </span>
          </CheckBox>
          <div className={styles.itemDescription}>
            <span
              className={`${styles[themes]} ${
                preferences.MARKETING ? styles.active : ''
              }`}
            >
              {intl.TEXT_EMAILS_DESCRIPTION}
            </span>
          </div>
        </div>

        <div className={styles.checkboxItem}>
          <CheckBox
            isLoading={checkBoxLoading.PROMOTION}
            name={COMMUNICATIONS.PROMOTION}
            value={preferences.PROMOTION}
            onClick={() => handleChange(COMMUNICATIONS.PROMOTION)}
          >
            <span
              className={`${styles[themes]} ${styles.title} ${
                preferences.PROMOTION ? styles.active : ''
              }`}
            >
              {intl.TEXT_PROMOTIONS_TITLE}
            </span>
          </CheckBox>
          <div className={styles.itemDescription}>
            <span
              className={`${styles[themes]} ${
                preferences.PROMOTION ? styles.active : ''
              }`}
            >
              {intl.TEXT_PROMOTIONS_DESCRIPTION}
            </span>
          </div>
        </div>

        <div className={styles.checkboxItem}>
          <CheckBox
            isLoading={checkBoxLoading.CUSTOMER_NOTIFICATION}
            name={COMMUNICATIONS.CUSTOMER_NOTIFICATION}
            value={preferences.CUSTOMER_NOTIFICATION}
            onClick={() =>
              handleChange(
                COMMUNICATIONS.CUSTOMER_NOTIFICATION,
                CHANNEL_PUSH_NOTIFICATION,
                communicationDevices.map(
                  device => device.communication_device_id
                )
              )
            }
          >
            <span
              className={`${styles[themes]} ${styles.title} ${
                preferences.CUSTOMER_NOTIFICATION ? styles.active : ''
              }`}
            >
              {intl.TEXT_CUSTOMER_NOTIFICATION_TITLE}
            </span>
          </CheckBox>
          <div className={styles.itemDescription}>
            <span
              className={`${styles[themes]} ${
                preferences.CUSTOMER_NOTIFICATION ? styles.active : ''
              }`}
            >
              {intl.TEXT_CUSTOMER_NOTIFICATION_DESCRIPTION}
            </span>
          </div>
        </div>
        {/* <div className={styles.checkboxItem}>
          <CheckBox
            isLoading={checkBoxLoading.WHATSAPP}
            name={COMMUNICATIONS.WHATSAPP}
            value={preferences.WHATSAPP}
            onClick={() =>
              handleChange(COMMUNICATIONS.WHATSAPP, CHANNEL_WHATSAPP)
            }
          >
            <span
              className={`${styles[themes]} ${styles.title} ${
                preferences.WHATSAPP ? styles.active : ''
              }`}
            >
              {intl.TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_TITLE}
            </span>
          </CheckBox>
          <div className={styles.itemDescription}>
            <span
              className={`${styles[themes]} ${
                preferences.WHATSAPP ? styles.active : ''
              }`}
            >
              {intl.TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_DESCRIPTION}
            </span>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default CommunicationPreferences;
