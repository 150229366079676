export const TITLE = {
  en_US: 'The card must contain 10 or 16 digits.',
  pt_BR: 'O cartão deve conter 10 ou 16 dígitos.',
  es_ES: 'La tarjeta Visa debe contener 10 o 16 dígitos.',
};

export const DESCRIPTION = {
  en_US: `Your selected card has a format unknown to the Benefits Portal and this may compromise its correct functioning.`,
  pt_BR:
    'O seu cartão selecionado possui um formato desconhecido para o Portal de Benefícios e isso pode comprometer o correto funcionamento do mesmo.',
  es_ES:
    'La tarjeta seleccionada tiene un formato desconocido para el Portal de Beneficios y esto puede comprometer su correcto funcionamiento.',
};

export const YOUR_CARDS = {
  en_US: 'Your cards',
  pt_BR: 'Seus cartões',
  es_ES: 'Tus tarjetas',
};
