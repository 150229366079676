export const BENEFIT = {
  en_US: 'Benefit',
  pt_BR: 'Benefício',
  es_ES: 'Beneficio',
};
export const CLAIM = {
  en_US: 'Claim',
  pt_BR: 'Sinistro',
  es_ES: 'Reclamo',
};
export const MEDICAL_ASSISTANCE = {
  en_US: 'Medical Assistance',
  pt_BR: 'Assistência Médica',
  es_ES: 'Servicio Médico',
};
export const CERTIFICATE = {
  en_US: 'Certificate',
  pt_BR: 'Certificado',
  es_ES: 'Certificado',
};

export const SEARCH_PLACEHOLDER = {
  en_US: 'Search here...',
  es_ES: 'Busca aquí...',
  pt_BR: 'Pesquise aqui...',
};
