import moment from 'moment';
import { getCurrencyByFormType } from '../../../../../../utils/B2C/currencyISOCode';
import { TotalPremiumAsc } from '../../../../../../utils/B2C/sort';
import { langAr, langCo, langMx } from '../../../../../../utils/B2C/formType';

const formatQuoteEs = props => {
  const { Quote } = props.values;
  const formType = props.formType;
  const TripInitialDate = moment(Quote.TripInitialDate).format('YYYY-MM-DD');
  const TripEndDate = moment(Quote.TripEndDate).format('YYYY-MM-DD');
  const AmountCurrencyISOCode = Quote.AmountCurrencyISOCode
    ? Quote.AmountCurrencyISOCode
    : getCurrencyByFormType(formType);
  const DestinationCountryIsoCode = Quote.DestinationCountryIsoCode;
  const Sort = TotalPremiumAsc;
  const Age = Quote.Ages.map(item => item.value);
  const recaptcha = Quote.recaptcha;
  const PromotionalCode = Quote.PromotionalCode ? Quote.PromotionalCode : null;
  const ProductCode = Quote.ProductCode ? Quote.ProductCode : '';
  const ProductType = Quote.ProductType ? Quote.ProductType : '';

  let quote = {
    Quote: {
      TripInitialDate,
      TripEndDate,
      AmountCurrencyISOCode,
      IncludesEurope: false,
      Sort,
      Top: 5,
      Age,
      DestinationCountryIsoCode,
      PromotionalCode,
    },
    ProductCode,
    ProductType,
    Status: 0,
    Action: 1,
    recaptcha,
  };

  return quote;
};

export const formatQuoteToApi = props => {
  const { formType } = props;
  if (formType === langCo || formType === langMx || formType === langAr) {
    return formatQuoteEs(props);
  }

  return null;
};
