export const formatPlans = products => {
  let listPlanes = [];

  products.map(product => {
    listPlanes.push({
      ...product.Product,
      Document: product.Document,
      BenefitVISA: product.BenefitVISA,
    });
  });

  return listPlanes;
};
