import React, { useContext } from 'react';
import Input from '../../../../../../components/Input/Input';
import { IntlContext } from '../../../../../../intl';
import { getCountryFromPrefix } from '../../../../../../utils/country';
import * as translations from './intl';
import styles from './index.module.scss';

const BookingInfoArea = ({ formikProps, isReturn, certificate }) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const countryOrigin = getCountryFromPrefix(
    certificate.travel_information.country_origin
  );
  const countryReturn = getCountryFromPrefix(
    certificate.travel_information.country_destination
  );

  const getTooltipClass = () => {
    if (idiom === 'pt_BR') {
      return styles.tooltipPt;
    }
    if (idiom === 'es_ES') {
      return styles.tooltipEs;
    }
    if (idiom === 'en_US') {
      return styles.tooltipEn;
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <Input
          label={isReturn ? intl.COUNTRY_DESTINATION : intl.COUNTRY_ORIGIN}
          value={isReturn ? countryReturn[idiom] : countryOrigin[idiom]}
          maxLength={20}
          type="text"
          disabled
        />
      </div>
      <div className="col-12 col-md-6">
        <Input
          label={intl.LABEL_BOOKING_COMPANY}
          name={
            isReturn
              ? 'return.booking_company_name'
              : 'outbound.booking_company_name'
          }
          formikProps={formikProps}
          maxLength={20}
          type="text"
        />
      </div>
      <div className="col-12 col-md-6">
        <Input
          label={intl.LABEL_RECORD_LOCATOR}
          name={
            isReturn ? 'return.booking_reference' : 'outbound.booking_reference'
          }
          formikProps={formikProps}
          maxLength={20}
          type="text"
          tooltipContentClass={`${styles.tooltip} ${getTooltipClass()}`}
        />
      </div>
    </div>
  );
};

export default BookingInfoArea;
