const moduleHsbc = (account, agency) => {
  const sequenceAgency = [8, 9, 2, 3];
  const sequenceAccount = [4, 5, 6, 7, 8, 9];
  const sumAgency = calculateSequence(agency, sequenceAgency);
  const sumAccount = calculateSequence(account, sequenceAccount);
  const result = (sumAgency + sumAccount) % 11;

  if (result === 0 || result === 10) return 0;

  return result;
};

const calculateSequence = (numbers, sequence) => {
  let sum = 0;
  for (var i = 0; i < numbers.length; i++) {
    sum += parseInt(numbers[i]) * sequence[i];
  }

  return sum;
};

const agencyNumberIsValid = agencyNumber => {
  return /^[0-9]{1,5}$/.test(agencyNumber) && parseInt(agencyNumber) > 0;
};

const accountNumberLength = () => {
  return 8;
};

const agencyNumberLength = () => {
  return 4;
};

/**
 * Receive account and agency with '-'
 * Agency format: xxxx
 * Account format: xxxxxx-x
 */
export default () => ({
  account: (code, agency = null) => {
    if (
      code.length !== accountNumberLength() ||
      agency.length !== agencyNumberLength()
    )
      return false;

    let arrayCode = code.split('-');
    let numbers = arrayCode[0].split('');
    let numbersAgency = agency.split('');
    let dv = arrayCode[1];

    const calculatedNumber = moduleHsbc(numbers, numbersAgency);

    return (
      calculatedNumber.toString().toUpperCase() === dv.toString().toUpperCase()
    );
  },
  agency: code => {
    if (!code || code.length !== agencyNumberLength()) return false;
    return agencyNumberIsValid(code);
  },
  accountNumberLength: code => {
    if (!code) return false;
    return code.length === accountNumberLength();
  },
  agencyNumberLength: code => {
    if (!code) return false;
    return code.length === agencyNumberLength();
  },
  getAgencyNumberLength: () => {
    return agencyNumberLength();
  },
  getAccountNumberLength: () => {
    return accountNumberLength();
  },
  getMaskAgencyName: () => {
    return 'maskHsbcranchCode';
  },
  getMaskAccountName: () => {
    return 'maskHsbcAccountNumber';
  },
  isGenericValidation: () => {
    return false;
  },
  getFormatAccount: () => {
    return '000000-0';
  },
});
