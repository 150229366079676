import { useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const AgenciesPhoneArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { setFieldValue, prefixOptions, values } = props;

  useEffect(() => {
    if (
      !values.collection_agencies.contacts.phones.international_prefix &&
      prefixOptions.prefix
    ) {
      setFieldValue(
        'collection_agencies.contacts.phones.international_prefix',
        prefixOptions.prefix
      );
    }
  }, [
    prefixOptions.prefix,
    setFieldValue,
    values.collection_agencies.contacts.phones.international_prefix,
  ]);

  return (
    <PhoneForm
      formikProps={props}
      title={intl.CONTACT_PHONE_NUMBER}
      inputNames={{
        phoneType: 'collection_agencies.contacts.phones.phone_type',
        countryCode: 'collection_agencies.contacts.phones.international_prefix',
        phoneNumber: 'collection_agencies.contacts.phones.number',
      }}
      testIds={{ phoneType: 'selectContactPhoneNumberType' }}
    />
  );
};

export default AgenciesPhoneArea;
