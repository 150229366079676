import moment from 'moment';
import { CARD_HOLDER_Q_VALUE, RENTAL_COMPANY_Q_VALUE } from './consts.js';
import { PHONE_TYPE_MOBILE } from '../../../../../../../constants/phone';

export const carRentalFormInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let carRental = completeClaim && completeClaim.car_rental;
  let travelPurchasedDate =
    completeClaim && completeClaim.travel_purchased_date;

  let incident = completeClaim && completeClaim.incident;

  let location = completeClaim && completeClaim.location;
  let affected_person = completeClaim && completeClaim.affected_person;

  let otherInsuranceCovering =
    completeClaim &&
    completeClaim.other_insurance_covering &&
    completeClaim.other_insurance_covering[0];
  let amount_estimation_damage =
    completeClaim && completeClaim.amount_estimation_damage;

  let totalAmountClaimed = completeClaim && completeClaim.total_amount_claimed;

  const getTravelPurchaseDate = travelPurchasedDate => {
    const travelPurchasedDateReturn = travelPurchasedDate
      ? new Date(moment(travelPurchasedDate, 'YYYY-MM-DD'))
      : '';
    return travelPurchasedDateReturn;
  };
  const getCarRental = carRental => {
    return {
      from:
        carRental && carRental.from
          ? new Date(moment(carRental.from, 'YYYY-MM-DD'))
          : '',
      to:
        carRental && carRental.to
          ? new Date(moment(carRental.to, 'YYYY-MM-DD'))
          : '',
      agency: getAgency(carRental && carRental.agency),
      payment: getPayment(carRental && carRental.payment),
    };
  };

  const getPayment = carRental => {
    const payment = carRental;
    return {
      date:
        payment && payment.payment && payment.payment.date
          ? new Date(moment(payment.payment.date, 'YYYY-MM-DD'))
          : '',
      amount: getAmount(carRental && carRental.amount),
      number_of_installments:
        payment && payment.number_of_installments
          ? payment.number_of_installments
          : 1,
      paymentMethod:
        payment && payment.paymentMethod ? payment.paymentMethod : '',
      installment_price:
        payment && payment.installment_price ? payment.installment_price : 0,
      reservation_information: {
        date:
          payment &&
          payment.payment &&
          payment.payment.reservation_information &&
          payment.payment.reservation_information.date
            ? new Date(
                moment(
                  payment.payment.reservation_information.date,
                  'YYYY-MM-DD'
                )
              )
            : '',
        amount: getAmount(
          carRental &&
            carRental.payment &&
            carRental.payment.reservation_information &&
            carRental.payment.reservation_information.amount
        ),
        hasPreviousReservation:
          carRental &&
          carRental.payment &&
          carRental.payment.reservation_information &&
          carRental.payment.reservation_information.hasPreviousReservation
            ? carRental.payment.reservation_information.hasPreviousReservation
            : 'no',
        hasPreviousReservationPayment:
          carRental &&
          carRental.payment &&
          carRental.payment.reservation_information &&
          carRental.payment.reservation_information
            .hasPreviousReservationPayment
            ? carRental.payment.reservation_information
                .hasPreviousReservationPayment
            : 'no',
        paymentMethod:
          carRental &&
          carRental.payment &&
          carRental.payment.reservation_information &&
          carRental.payment.reservation_information.paymentMethod
            ? carRental.payment.reservation_information.paymentMethod
            : '',
        installment_price:
          carRental &&
          carRental.payment &&
          carRental.payment.reservation_information &&
          carRental.payment.reservation_information.installment_price
            ? carRental.payment.reservation_information.installment_price
            : 0,
        number_of_installments:
          payment &&
          payment.payment &&
          payment.payment.reservation_information &&
          payment.payment.reservation_information.number_of_installments
            ? payment.payment.reservation_information.number_of_installments
            : 1,
      },
    };
  };

  const getAgency = carRental => {
    const agency = carRental;
    return {
      name: agency && agency.name ? agency.name : '',
      address: getAddress(carRental && carRental.address),
      phone: getPhone(carRental && carRental.phone),
    };
  };

  const getAmount = carRental => {
    const amount = carRental && carRental.amount ? carRental.amount : {};
    return {
      value: amount && amount.value ? amount.value : '',
      currency: amount && amount.value ? amount.value : '',
    };
  };
  const getAddress = carRental => {
    const address = carRental && carRental.address ? carRental.address : {};
    return {
      street_address:
        address && address.street_address ? address.street_address : '',
      postal_code: address && address.postal_code ? address.postal_code : '',
      locality: address && address.locality ? address.locality : '',
      country: address && address.country ? address.country : '',
      subdivision: address && address.subdivision ? address.subdivision : '',
      state: address && address.state ? address.state : '',
      complement: address && address.complement ? address.complement : '',
    };
  };

  const getPhone = carRental => {
    const phone = carRental;
    return {
      phone_type:
        phone && phone.phone_type ? phone.phone_type : PHONE_TYPE_MOBILE,
      international_prefix:
        phone && phone.international_prefix ? phone.international_prefix : '',
      number: phone && phone.number ? phone.number : '',
    };
  };

  const getPaymentBeneficiary = incident => {
    const beneficiary = incident && incident.payment_beneficiary;
    if (
      beneficiary &&
      beneficiary.toLowerCase() === RENTAL_COMPANY_Q_VALUE.toLowerCase()
    ) {
      return RENTAL_COMPANY_Q_VALUE;
    }

    return CARD_HOLDER_Q_VALUE;
  };
  const getIncident = incident => ({
    date:
      incident && incident.date
        ? new Date(moment(incident.date, 'YYYY-MM-DD'))
        : '',
    description: incident && incident.description ? incident.description : '',
    driver: getDriver(incident && incident.driver),
    location: getLocation(incident && incident.location),
    collision_coverage:
      incident && incident.collision_coverage
        ? incident.collision_coverage
        : true,
    payment_beneficiary: getPaymentBeneficiary(incident),
  });

  const getDriver = incident => {
    const driver = incident;
    return {
      license_number:
        driver && driver.license_number ? driver.license_number : '',
      license_issued_country:
        driver && driver.license_issued_country
          ? driver.license_issued_country
          : '',
      allOf: getAllOf(incident && incident.allOf),
    };
  };

  const getLocation = location => ({
    postal_code: location && location.postal_code ? location.postal_code : '',
    locality: location && location.locality ? location.locality : '',
    country: location && location.country ? location.country : '',
    subdivision: location && location.subdivision ? location.subdivision : '',
    state: location && location.state ? location.state : '',
    place: location && location.street_address ? location.street_address : '',
  });

  const getAllOf = incident => {
    const allOf = incident;
    return {
      first_name: allOf && allOf.first_name ? allOf.first_name : '',
      last_name: allOf && allOf.last_name ? allOf.last_name : '',
    };
  };

  const getAffectedPerson = affected_person => ({
    relationship: 'Cardholder',
    relationshipCardholder: true,
    person: getPersonName(affected_person),
  });

  const getPersonName = affectedPerson => {
    if (affectedPerson && affectedPerson.person) {
      const person = affectedPerson.person;
      return {
        first_name: person.first_name ? person.first_name : '',
        last_name: person.last_name ? person.last_name : '',
      };
    } else {
      return {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      };
    }
  };

  const getOtherInsuranceCovering = otherInsuranceCovering => ({
    is_internal:
      otherInsuranceCovering && otherInsuranceCovering.is_internal !== undefined
        ? String(!otherInsuranceCovering.is_internal)
        : 'false',

    value:
      otherInsuranceCovering && otherInsuranceCovering.amount.value
        ? otherInsuranceCovering.amount.value
        : '',
    currency:
      otherInsuranceCovering && otherInsuranceCovering.amount.currency
        ? otherInsuranceCovering.amount.currency
        : '',
  });

  const getAmountEstimationDamage = amount_estimation_damage => ({
    value:
      amount_estimation_damage && amount_estimation_damage.value
        ? amount_estimation_damage.value
        : '',
    currency:
      amount_estimation_damage && amount_estimation_damage.currency
        ? amount_estimation_damage.currency
        : '',
  });

  const getPrice = price => ({
    value: price && price.value ? price.value : '',
    currency: price && price.currency ? price.currency : '',
  });

  return {
    car_rental: getCarRental(carRental),
    incident: getIncident(incident),
    location: getLocation(location),
    affected_person: getAffectedPerson(affected_person),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    amount_estimation_damage: getAmountEstimationDamage(
      amount_estimation_damage
    ),
    total_amount_claimed: getPrice(totalAmountClaimed),
    travel_purchased_date: getTravelPurchaseDate(travelPurchasedDate),
  };
};
