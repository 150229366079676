/* eslint-disable no-unused-vars */
export const satisfactionGuaranteeFormInitialValues = (data, idiom) => {
  let person = data && data.person ? data.person : '';
  return {
    purchase_protection_items: [
      {
        label: '',
        manufacturer: '',
        model_number: '',
        purchase_date: '',
        price: {
          value: '',
          currency: '',
        },
        amount_claimed: {
          value: '',
          currency: '',
        },
        other_insurance_covering: {
          is_internal: false,
          amount: {
            value: '',
            currency: '',
          },
        },
      },
    ],
    incident: {
      dateValue: '',
      description: '',
      return_period: '',
    },
    affected_person: {
      relationship: 'Cardholder',
      comment: '',
      person: {
        first_name: person && person.first_name ? person.first_name : '',
        last_name: person && person.last_name ? person.last_name : '',
      },
    },
    total_amount_claimed: {
      value: '',
      currency: '',
    },
  };
};
