import moment from 'moment';
import { cloneDeep } from 'lodash';
import { stringToBoolean } from '../../../../../../../../utils/stringUtils';

export const formatBaggageDelay_toApi = props => {
  let object = {};
  object.travel_information = travelBaggageDelay(props);
  object.incident = incidentBaggageDelay(props);
  object.formal_complaint = formalComplaint(props);
  object.affected_person = formatAffectedPerson(props);
  object.other_insurance_covering = coveringBaggageDelay(props);
  object.total_amount_claimed = total_amount_claimedToApi(props);

  if (props.values.incident.hasAdditional === 'true') {
    const additional_passengers = cloneDeep(
      props.values.incident.additional_passengers
    );
    additional_passengers.map((value, index) => {
      const birthDate = value.birth_date;
      additional_passengers[index].nationalities = [value.nationalities];
      additional_passengers[index].birth_date =
        moment(birthDate).format('YYYY-MM-DD');
    });
    object.additional_passengers = additional_passengers;
  }

  return object;
};

export const travelBaggageDelay = props => {
  const travel_information = props.values.incident.travel_information;

  const from_date = moment(travel_information.from_date).format('YYYY-MM-DD');
  const to_date = moment(travel_information.to_date).format('YYYY-MM-DD');
  const is_full_charged_on_card =
    travel_information.is_full_charged_on_card === 'true';
  return {
    ...travel_information,
    is_full_charged_on_card,
    from_date,
    to_date,
  };
};

export const incidentBaggageDelay = props => {
  const { trackEventUserAction } = props;
  const incident = props.values.incident.incident;
  trackEventUserAction(
    '#### (BAGGAGE DELAY) FORMATAÇÃO DO INCIDENT PARA API',
    props.values.incident.incident
  );
  const baggage_arrived_at =
    incident.wasBaggageReceived === 'true'
      ? moment(incident.baggage_arrived_at).format('YYYY-MM-DD')
      : null;
  const baggage_arrived_at_hour =
    incident.wasBaggageReceived === 'true'
      ? moment(
          baggage_arrived_at + ' ' + incident.hour_baggage_arrived_at
        ).format('YYYY-MM-DD HH:mm:ss')
      : null;

  trackEventUserAction('#### (BAGGAGE DELAY) BAGGAGE ARRIVED AT', {
    body: props.values.incident.incident,
    baggage_arrived_at: incident.baggage_arrived_at
      ? incident.baggage_arrived_at
      : null,
    baggage_arrived_at_hour: incident.hour_baggage_arrived_at
      ? incident.hour_baggage_arrived_at
      : null,
  });

  const discovered_at = moment(incident.discovered_at).format('YYYY-MM-DD');

  const discovered_at_hour = moment(
    discovered_at + ' ' + incident.hour_discovered_at
  ).format('YYYY-MM-DD HH:mm:ss');

  trackEventUserAction('#### (BAGGAGE DELAY) BAGGAGE DISCOVERED AT', {
    body: props.values.incident.incident,
    discovered_at: incident.discovered_at ? incident.discovered_at : null,
    discovered_at_hour: incident.hour_discovered_at
      ? incident.hour_discovered_at
      : null,
  });

  return {
    baggage_arrived_at: baggage_arrived_at_hour,
    discovered_at: discovered_at_hour,
    description: incident.description,
    kind: incident.kind,
  };
};

export const coveringBaggageDelay = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const other_company_covering = props.values.incident.other_company_covering;
  const otherInsurance = [];

  if (stringToBoolean(other_insurance_covering.checked)) {
    otherInsurance.push(otherInsuranceObject(other_insurance_covering));
  }

  if (stringToBoolean(other_company_covering.checked)) {
    let currency = stringToBoolean(other_insurance_covering.checked)
      ? other_insurance_covering.currency
      : null;
    otherInsurance.push(otherInsuranceObject(other_company_covering, currency));
  }
  return otherInsurance;
};

export const otherInsuranceObject = (props, insuranceCurrency = null) => {
  return {
    is_internal: props.is_internal,
    description: props.description,
    amount: {
      value: parseFloat(props.value),
      currency: insuranceCurrency !== null ? insuranceCurrency : props.currency,
    },
  };
};

const formalComplaint = props => {
  const formal = props.values.incident.formal_complaint;
  return {
    ...formal,
    is_submitted: formal.is_submitted === 'true',
  };
};

const formatAffectedPerson = props => {
  let affected = props.values.incident.affected_person;
  delete affected.relationshipCardholder;
  return affected;
};

const total_amount_claimedToApi = props => {
  return {
    value: Number(props.values.incident.total_amount_claimed.value),
    currency: props.values.incident.total_amount_claimed.currency,
  };
};
