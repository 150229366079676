/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import moment from 'moment';
export const baggageLossFormInitialValues = (data, idiom, completeClaim) => {
  let person = data && data.person ? data.person : '';
  let affectedPerson =
    completeClaim && completeClaim.affected_person
      ? completeClaim.affected_person
      : {};
  let incident =
    completeClaim && completeClaim.incident ? completeClaim.incident : {};
  let otherInsuranceCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[0]
      : [{}];
  let otherCompanyCovering =
    completeClaim && completeClaim.other_insurance_covering
      ? completeClaim.other_insurance_covering[1]
      : [{}];
  let totalAmountClaimed =
    completeClaim && completeClaim.total_amount_claimed
      ? completeClaim.total_amount_claimed
      : {};
  let travelInformation =
    completeClaim && completeClaim.travel_information
      ? completeClaim.travel_information
      : {};

  const getTravelInformation = travelInformation => {
    return {
      from_date:
        travelInformation && travelInformation.from_date
          ? new Date(moment(travelInformation.from_date, 'YYYY-MM-DD'))
          : '',
      to_date:
        travelInformation && travelInformation.to_date
          ? new Date(moment(travelInformation.to_date, 'YYYY-MM-DD'))
          : '',
      transportation_type:
        travelInformation && travelInformation.transportation_type
          ? travelInformation.transportation_type
          : '',
      was_the_baggage_in_the_charge_of_the_travel_company:
        travelInformation && travelInformation.was_the_baggage_in_the_charge
          ? travelInformation.was_the_baggage_in_the_charge
          : false,
    };
  };

  const getConcernedItems = incident => {
    if (incident && incident.concerned_items)
      return incident.concerned_items.map(items => ({
        description: items.description,
        price: {
          value: items.price.value,
          currency: items.price.currency || '',
        },
      }));
    else
      return [
        {
          description: '',
          price: {
            value: '',
            currency: '',
          },
        },
      ];
  };
  const getIncident = incident => {
    let discoveredAt =
      incident && incident.discovered_at
        ? incident.discovered_at.split(' ')
        : '';
    return {
      discovered_at: discoveredAt
        ? new Date(moment(discoveredAt[0], 'YYYY-MM-DD'))
        : '',
      hour_discovered_at: discoveredAt ? discoveredAt[1].substring(0, 5) : '',
      description: incident && incident.description ? incident.description : '',
      location: getLocation(incident),
      kind: 'LOSS',
      concerned_items: getConcernedItems(incident),
    };
  };

  const getLocation = incident => {
    const location = incident && incident.location ? incident.location : {};
    return {
      country: location && location.country ? location.country : '',
      locality: location && location.locality ? location.locality : '',
    };
  };
  const getAffectedPerson = affectedPerson => {
    return {
      relationship: 'Cardholder',
      relationshipCardholder: true,
      person: getPerson(person),
    };
  };
  const getPerson = person => {
    return {
      first_name: person && person.first_name ? person.first_name : '',
      last_name: person && person.last_name ? person.last_name : '',
    };
  };

  const getFormalComplaint = completeClaim => {
    return {
      is_submitted:
        completeClaim && completeClaim.formal_complaint.is_submitted
          ? String(completeClaim.formal_complaint.is_submitted)
          : '' ||
            (completeClaim &&
              completeClaim.formal_complaint.is_submitted === false)
          ? String(completeClaim.formal_complaint.is_submitted)
          : '',
      submission_reason:
        completeClaim && completeClaim.formal_complaint.submission_reason
          ? completeClaim.formal_complaint.submission_reason
          : '',
    };
  };
  const getTotalAmountClaimed = totalAmountClaimed => {
    return {
      value:
        totalAmountClaimed && totalAmountClaimed.value
          ? totalAmountClaimed.value
          : '',
      currency:
        totalAmountClaimed && totalAmountClaimed.currency
          ? totalAmountClaimed.currency
          : '',
    };
  };

  const getOtherInsuranceCovering = otherInsuranceCovering => {
    return {
      is_internal:
        otherInsuranceCovering && otherInsuranceCovering.is_internal
          ? otherInsuranceCovering.is_internal
          : false,
      checked:
        otherInsuranceCovering &&
        otherInsuranceCovering.is_internal !== undefined
          ? String(!otherInsuranceCovering.is_internal)
          : 'false',
      description:
        otherInsuranceCovering && otherInsuranceCovering.description
          ? otherInsuranceCovering.description
          : '',
      value:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.value
          ? otherInsuranceCovering.amount.value
          : '',
      currency:
        otherInsuranceCovering &&
        otherInsuranceCovering.amount &&
        otherInsuranceCovering.amount.currency
          ? otherInsuranceCovering.amount.currency
          : '',
    };
  };

  const getOtherInsuranceCovering2 = otherCompanyCovering => {
    return {
      is_internal:
        otherCompanyCovering && otherCompanyCovering.is_internal
          ? otherCompanyCovering.is_internal
          : false,
      checked:
        otherCompanyCovering && otherCompanyCovering.is_internal !== undefined
          ? String(!otherCompanyCovering.is_internal)
          : 'false',
      description:
        otherCompanyCovering && otherCompanyCovering.description
          ? otherCompanyCovering.description
          : '',
      value:
        otherCompanyCovering &&
        otherCompanyCovering.amount &&
        otherCompanyCovering.amount.value
          ? otherCompanyCovering.amount.value
          : '',
      currency:
        otherCompanyCovering &&
        otherCompanyCovering.amount &&
        otherCompanyCovering.amount.currency
          ? otherCompanyCovering.amount.currency
          : '',
    };
  };

  const getAdditional = completeClaim => {
    if (completeClaim && completeClaim.additional_passengers)
      return completeClaim.additional_passengers.map(additional => ({
        first_name: additional.first_name,
        last_name: additional.last_name,
        birth_date: new Date(moment(additional.birth_date, 'YYYY-MM-DD')),
        gender: additional.gender,
        nationalities: additional.nationalities[0],
        relationship: additional.relationship,
        comment: additional.comment === null ? '' : additional.comment,
      }));
    else
      return [
        {
          first_name: '',
          last_name: '',
          birth_date: '',
          gender: '',
          nationalities: '',
          relationship: '',
          comment: '',
        },
      ];
  };

  const getHasAdditional = completeClaim => {
    return completeClaim && completeClaim.additional_passengers
      ? 'true'
      : 'false';
  };

  return {
    travel_information: getTravelInformation(travelInformation),
    incident: getIncident(incident),
    affected_person: getAffectedPerson(affectedPerson),
    formal_complaint: getFormalComplaint(completeClaim),
    total_amount_claimed: getTotalAmountClaimed(totalAmountClaimed),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    other_company_covering: getOtherInsuranceCovering2(otherCompanyCovering),
    additional_passengers: getAdditional(completeClaim),
    hasAdditional: getHasAdditional(completeClaim),
  };
};
