import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';

const CertificateIcon = props => {
  const { enabled = true, bottombar, invert_color, darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = CONFIG_ICON[theme];
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : invert_color
    ? config_icon.secondary
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : invert_color
    ? config_icon.primary
    : config_icon.secondary;

  return (
    <svg
      width={55}
      height={67}
      viewBox="0 0 55 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M44.0384 12.0578H10.5649V16.2869H44.0384V12.0578Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
      <path
        d="M44.0384 23.2227H10.5649V27.4518H44.0384V23.2227Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
      <path
        d="M32.9158 34.3665H10.5649V38.5956H32.9158V34.3665Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
      <path
        d="M0.0766602 0.914124V66.4654H24.8593L20.6302 62.2363H4.30578V5.14324H50.2763V62.2363H31.647L27.4179 66.4654H54.5054V0.914124H0.0766602Z"
        fill={
          enabled ? (bottombar ? secondary : primary) : config_icon.inactive
        }
      />
      <path
        d="M26.1491 61.7711L16.7605 52.3825L19.742 49.401L26.1491 55.7869L40.4224 41.5137L43.4039 44.4952L26.1491 61.7711Z"
        fill={enabled ? secondary : config_icon.inactive}
      />
    </svg>
  );
};

export default CertificateIcon;
