import { useEffect, useState, useContext } from 'react';
import { useIntl } from '../../../../../intl';
import * as translations from '../intl';
import { octService } from '../../../../../services';
import Loading from '../../../../../components/Loading/Loading';
import styles from '../../ClaimView.module.scss';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import { formatCustomDate } from '../../../../../utils/date';
import { Payment } from '../../../../../@types/APIs/Palla/PaymentMethod';

const OCTInformation = ({ paymentMethod }) => {
  const { oct_information } = paymentMethod;
  const [paymentMethodDetail, setPaymentMethodDetail] = useState<Payment>();
  const [loading, setLoading] = useState(true);

  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate, idiom } = useIntl();

  const themes = getGlobalTheme();
  const intl = translate(translations);

  const loadPallaPaymentDetails = async () => {
    setLoading(true);
    try {
      const {
        data: { payments },
      } = await octService.getAccountDetailPalla();
      const paymentUsed = payments.find(
        payment =>
          payment.payment_id === oct_information.palla_payment_method_id
      );

      if (paymentUsed) {
        setPaymentMethodDetail(paymentUsed);
      } else {
        setPaymentMethodDetail(null);
      }
    } catch (error) {
      setPaymentMethodDetail(undefined);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (oct_information) {
      loadPallaPaymentDetails();
    }
  }, [oct_information]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className={`row ${styles.sectionTitle}`}>
      <h2 className={`col-12 ${styles.sectionTitle} ${styles[themes]}`}>
        {intl.TITLE_BANK_INFORMATION}
      </h2>
      {paymentMethodDetail === null ? (
        <div className="col-12">
          <p className={`${styles.emptyMessage} ${styles[themes]}`}>
            {intl.CARD_DELETED}
          </p>
        </div>
      ) : (
        <>
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={intl.CARD}
              value={
                paymentMethodDetail && paymentMethodDetail?.card
                  ? `****.****.****.${paymentMethodDetail?.card}`
                  : '-'
              }
            />
          </div>
          <div className="col-12 col-md-3">
            <InfoLabelValue
              label={intl.EXPIRATION_DATE}
              value={
                paymentMethodDetail &&
                paymentMethodDetail?.month &&
                paymentMethodDetail?.year
                  ? formatCustomDate(
                      `${paymentMethodDetail?.month}/${paymentMethodDetail?.year}`,
                      'MM/YYYY',
                      idiom
                    )
                  : '-'
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OCTInformation;
