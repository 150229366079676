import TRAVEL_KIT_BANNER_PT from '../../../assets/images/marketing/top_banner_travel_kit/phone-travelkit-pt-min.png';
import TRAVEL_KIT_BANNER_EN from '../../../assets/images/marketing/top_banner_travel_kit/phone-travelkit-eng-min.png';
import TRAVEL_KIT_BANNER_ES from '../../../assets/images/marketing/top_banner_travel_kit/phone-travelkit-esp-min.png';

export const BANNER_TITLE_TRAVEL_KIT = {
  en_US: 'Visa Travel kit',
  es_ES: 'Visa Travel kit',
  pt_BR: 'Visa Travel kit',
};

export const TITLE_PAGE_TRAVEL_KIT = {
  en_US: 'Let us take care of your travel arrangements!',
  es_ES: '¡Permítenos ocuparnos de todo por ti!',
  pt_BR: 'Tudo pronto para sua próxima viagem',
};

export const SUBTITLE_PAGE_TRAVEL_KIT = {
  en_US:
    'Activate your travel kit and receive real-time updates of your flight status. We help you to make sure that everything goes as expected on your next adventure.',
  es_ES:
    'Activa tu travel kit y recibe actualizaciones en tiempo real del estado de tu vuelo. Te ayudamos a que todo salga como esperas en tu próxima aventura.',
  pt_BR:
    'A Visa acompanha você em todos os lugares, por isso, a partir do seu novo app, Visa Benefits, você pode ativar seu <strong>Visa Travel Kit*</strong> e, assim, ter todos os documentos e informações prontos para sua próxima viagem.',
};

export const TEXT_HIGHLIGHT_1 = {
  en_US: 'Faster check-in with “ready to fly” status',
  es_ES: 'Check-in más rapido con el estado “listo para volar”',
  pt_BR: 'Check-in mais rápido com status "ready to fly"',
};

export const TEXT_HIGHLIGHT_2 = {
  en_US: 'Real-time flight tracking',
  es_ES: 'Seguimiento de vuelos en tiempo real',
  pt_BR: 'Acompanhamento de voos em tempo real',
};

export const TITLE_READY_FLY = {
  en_US: 'Ready to fly',
  es_ES: 'Ready to fly',
  pt_BR: 'Ready to fly',
};

export const TEXT_READY_FLY = {
  en_US:
    'With the QR code it is easy to show my "Ready to fly" status at the airport',
  es_ES:
    'Con el codigo QR es sencillo mostrar mi estatus "Ready to fly” en el aeropuerto',
  pt_BR:
    'Com o código QR é fácil mostrar meu status "Pronto para voar" no aeroporto',
};

export const ACTIVE_TRAVEL_KIT = {
  en_US: 'and enjoy the Visa Travel Kit',
  es_ES: 'y activa tu Visa Travel Kit',
  pt_BR: 'e ative seu Visa Travel kit',
};

export const TEXT_DISCLAIMER_TRAVEL_KIT = {
  en_US: '* Check the availability of Visa Travel Kit for your Visa card.',
  es_ES:
    '* Consulta la disponibilidad de Visa Travel Kit para tu tarjeta Visa.',
  pt_BR:
    '* Verifique a disponibilidade do Visa Travel Kit para o seu cartão Visa.',
};

export const TEXT_YOUR_DOCUMENTS = {
  en_US:
    'Get ready for your next adventure! Follow the status of your flight and receive medical alerts of your destination country, activating your travel kit.',
  es_ES:
    'Sigue el estado de tu vuelo y recibe alertas sobre la salud, sobre el destino de tu viaje activando travel kit.',
  pt_BR: 'Seus documentos e informações <br /> na palma da sua mão',
};

export const IMAGEM_TRAVEL_KIT = {
  en_US: TRAVEL_KIT_BANNER_EN,
  es_ES: TRAVEL_KIT_BANNER_ES,
  pt_BR: TRAVEL_KIT_BANNER_PT,
};
