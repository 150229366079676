export const NOTES_1 = {
  en_US: `This certificate will be valid from 12 months from its issue date, and it will only apply to products completely paid with an Eligible Card that also possess an in force original manufacture warranty at the date of issue.`,
  pt_BR: `Este bilhete é válido por 12 meses a partir da data de emissão e aplicará para os produtos pagos integralmente com o Cartão Elegível, desde que possuam garantia de fábrica vigente na data de emissão deste bilhete.`,
  es_ES: `Este certificado tiene una validez de 12 meses a partir de la fecha de emisión y se aplicará a los productos pagados en su totalidad con la Tarjeta Elegible, siempre que cuenten con una garantía de fábrica vigente en la fecha de emisión de este certificado.`,
};

export const NOTES_2 = {
  en_US: `The absence of the Insurance Certificate, its issuance with incorrect information or after the incident date will result in non-coverage from the insurance company.`,
  pt_BR: `A ausência do Bilhete de Seguro ou sua emissão com informações incorretas, ou após um incidente de sinistro, resulta na não cobertura / inexistência do seguro.`,
  es_ES: `La ausencia del Certificado de Seguro, su emisión con información incorrecta o después de la fecha del incidente dará lugar a la no cobertura por parte de la aseguradora.`,
};
