export const LABEL_ADD = {
  en_US: 'Add a new beneficiary',
  pt_BR: 'Adicionar um novo beneficiário',
  es_ES: 'Agregar un beneficiario nuevo ',
};

export const LABEL_ADD_SHORT = {
  en_US: 'Add',
  pt_BR: 'Adicionar',
  es_ES: 'Adicionar',
};

export const TITLE_ADD_DEPENDENT = {
  en_US: 'Add a new beneficiary',
  pt_BR: 'Adicionar um novo beneficiário',
  es_ES: 'Agregar un beneficiario nuevo',
};

export const BTN_CANCEL = {
  en_US: 'Cancel',
  pt_BR: 'Cancelar',
  es_ES: 'Cancelar',
};
