export const TITLE_CONNECTION_ERROR = {
  es_ES: 'Error al conectarse a la llamada.',
  en_US: 'Error connecting to call.',
  pt_BR: 'Erro ao conectar-se à chamada.',
};

export const TITLE_NO_DOCTOR = {
  es_ES:
    'Tu cita aún no se ha asociado con un médico. Por favor, espere unos minutos e intenta de nuevo.',
  en_US:
    'Your appointment is yet to be associated with a doctor. Please wait and try again later.',
  pt_BR:
    'Seu agendamento ainda não foi direcionada a um médico. Por favor, aguarde alguns instantes e tente novamente.',
};

export const TITLE_CONNECTING = {
  es_ES: 'Conectando a tu médico...',
  en_US: 'Connecting to your doctor...',
  pt_BR: 'Conectando ao seu médico...',
};

export const TITLE_WAIT_FOR_DOCTOR = {
  es_ES: 'Por favor espere. Tu médico llegará en breve.',
  en_US: 'Please wait. Your doctor will arrive soon.',
  pt_BR: 'Por favor, espere. Seu médico chegará em breve.',
};

export const TITLE_ENDING = {
  es_ES: 'Cerrando llamada...',
  en_US: 'Ending call..',
  pt_BR: 'Encerrando chamada...',
};

export const TITLE_END_CALL = {
  es_ES: '¿Quieres finalizar la llamada?',
  en_US: 'Do you want to end the call?',
  pt_BR: 'Deseja encerrar a chamada?',
};

export const LABEL_YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const LABEL_NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
