import styles from './index.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { ReactNode } from 'react';

interface TabConfig<T extends string> {
  label: string;
  id: T;
}

interface TabBarProps<T extends string> {
  tabs: TabConfig<T>[];
  selectedTab: T;
  onClickTab: (tabId: T) => void;
  children?: ReactNode;
}

const TabBar = <T extends string>({
  tabs,
  selectedTab,
  onClickTab,
  children,
}: TabBarProps<T>) => {
  const { getGlobalTheme } = useTheme();
  const themes = getGlobalTheme();

  return (
    <>
      <div className={styles.tabBar} id="tabSection">
        {tabs.map(tab => (
          <span
            key={tab.id}
            className={`${
              selectedTab === tab.id ? styles.tabSelected : styles.tab
            } ${styles[themes]}`}
            onClick={() => onClickTab(tab.id)}
          >
            {tab.label}
          </span>
        ))}
      </div>
      {children && <div>{children}</div>}
    </>
  );
};

export default TabBar;
