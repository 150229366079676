import { useContext, useEffect } from 'react';

import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const CompanyArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    prefixOptions,
  } = props;

  useEffect(() => {
    if (prefixOptions.prefix) {
      setFieldValue(
        'car_rental.agency.phone.international_prefix',
        prefixOptions.prefix
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixOptions.prefix]);

  return (
    <>
      <div className={`row`}>
        <div className="col-12">
          <Input
            value={values.car_rental.agency.name}
            label={intl.LABEL_CAR_RENTAL_COMPANY}
            name="car_rental.agency.name"
            dataTestid="InputCarRentalAgencyName"
            type="text"
            onChange={handleChange}
            touched={
              touched.car_rental &&
              touched.car_rental.agency &&
              touched.car_rental.agency.name
            }
            onBlur={handleBlur}
            error={
              errors.car_rental &&
              errors.car_rental.agency &&
              errors.car_rental.agency.name
            }
            maxLength="100"
          />
        </div>
        <div className="col-12">
          <PhoneForm
            formikProps={props}
            title={intl.TITLE_PHONE_COMPANY}
            inputNames={{
              phoneType: 'car_rental.agency.phone.phone_type',
              countryCode: 'car_rental.agency.phone.international_prefix',
              phoneNumber: 'car_rental.agency.phone.number',
            }}
            testIds={{
              phoneType: 'InputCarRentalAgencyPhoneType',
              countryCode: 'InputCarRentalAgencyPhonePrefix',
              phoneNumber: 'InputCarRentalAgencyPhoneNumber',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CompanyArea;
