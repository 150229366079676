/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useMemo } from 'react';
import styles from './Profile.module.scss';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import MenuProfile from '../../components/MenuProfile/MenuProfile';
import MenuProfileMobile from '../../components/MenuProfileMobile/MenuProfileMobile';
import ProfileRouter from './ProfileRouter/ProfileRouter';
import ProfileHeader from './ProfileHeader/ProfileHeader';
import { Link, withRouter } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { productsAndBenefitsServices, userServices } from '../../services';
import { hasCinepolis } from '../../utils/benefits';

const checkRouteProfileIsDefault = (pathName, setIsProfileDefault) => {
  const path = pathName.split('/');
  const route = path[path.length - 1];
  if (route === 'profile') {
    setIsProfileDefault(true);
  } else {
    setIsProfileDefault(false);
  }
};

const Profile = props => {
  const { history } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isProfileDefault, setIsProfileDefault] = useState(false);
  const [customer, setCustomer] = useState();
  const [benefits, setBenefits] = useState([]);
  const { utils } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate, idiomForApi } = useContext(IntlContext);

  const theme = getGlobalTheme();
  const intl = translate(translations);
  const cn = utils.getCn();
  const idiomAPI = idiomForApi();
  const binOrPan = utils.getBin(true);

  const checkWindowWidth = () => {
    if (window.screen.width > 992) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  const checkRedirectToAccount = () => {
    if (isProfileDefault && !isMobile) {
      history.push('/profile/account');
    }
  };

  const hasCinepolisGold = useMemo(() => hasCinepolis(benefits), [benefits]);

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
    checkRouteProfileIsDefault(window.location.pathname, setIsProfileDefault);
  }, [window.location.pathname]);

  useEffect(() => {
    checkRedirectToAccount();
  }, [isProfileDefault, isMobile]);

  useEffect(() => {
    const loadInfo = async () => {
      try {
        const customerPromise = userServices.getCHInfo(cn);
        const benefitsPromise =
          productsAndBenefitsServices.getBenefitsByProduct(
            binOrPan,
            idiomAPI,
            false,
            false
          );

        const resolve = await Promise.all([customerPromise, benefitsPromise]);

        setCustomer(resolve[0].data);
        setBenefits(resolve[1].data);
      } catch (error) {
        setCustomer(null);
        setBenefits([]);
      }
    };
    loadInfo();
  }, []);

  return (
    <div className={styles.container}>
      {!isProfileDefault && isMobile ? (
        <div className={styles.headerBack}>
          <Link className={styles[theme]} to="/profile">
            <span className={styles.iconLeft}>
              <ChevronGeneric
                role="chevronLeft"
                aria-label={intl.ARROW_LEFT}
                rotate={90}
                width={20}
                height={20}
              />
            </span>

            {intl.BACK}
          </Link>
        </div>
      ) : (
        <ProfileHeader />
      )}
      <div id="mainContent" className={styles.body}>
        <div className={styles.menuArea}>
          <MenuProfile
            customer={customer}
            hasCinepolisGold={hasCinepolisGold}
          />
          <MenuProfileMobile
            customer={customer}
            hiddenMenu={!isProfileDefault}
            hasCinepolisGold={hasCinepolisGold}
          />
        </div>
        <div className={styles.content}>
          <ProfileRouter />
        </div>
      </div>
    </div>
  );
};

export default withRouter(Profile);
