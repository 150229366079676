import React, { useContext, useEffect, useState } from 'react';
import styles from './OtherInsuranceArea.module.scss';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const OtherInsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [otherInsurance, setOtherInsurance] = useState(false);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setEmptyOtherInsurance = () => {
    setFieldValue('other_insurance_covering.amount.value', '');
  };

  useEffect(() => {
    setOtherInsurance(values.other_insurance_covering.is_internal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.DO_YOU_HAVE_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.DO_YOU_HAVE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={values.other_insurance_covering.is_internal}
              text={intl.YES}
              name={`other_insurance_covering.is_internal`}
              value={true}
              onChange={e => {
                setOtherInsurance(true);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.DO_YOU_HAVE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={!values.other_insurance_covering.is_internal}
              text={intl.NO}
              name={`other_insurance_covering.is_internal`}
              value={false}
              onChange={e => {
                setOtherInsurance(false);
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      {otherInsurance && (
        <>
          <div className="col-12 col-sm-10 col-md-6">
            <Input
              value={values.other_insurance_covering.amount.value}
              label={intl.INDICATE_AMOUNT_PAID_INSURANCE}
              name={`other_insurance_covering.amount.value`}
              type="text"
              maskedMoney
              onChange={handleChange}
              tooltipText={intl.TEXT_AMOUNT_DECIMAL}
              touched={
                touched &&
                touched.other_insurance_covering &&
                touched.other_insurance_covering.amount &&
                touched.other_insurance_covering.amount.value
              }
              onBlur={handleBlur}
              error={
                errors &&
                errors.other_insurance_covering &&
                errors.other_insurance_covering.amount &&
                errors.other_insurance_covering.amount.value
              }
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              dataTestid="selectOtherInsuranceCovering"
              label={intl.LABEL_CURRENCY}
              value={values.other_insurance_covering.amount.currency}
              name={`other_insurance_covering.amount.currency`}
              onChange={handleChange}
              disabled
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              touched={
                touched &&
                touched.other_insurance_covering &&
                touched.other_insurance_covering.amount &&
                touched.other_insurance_covering.amount.currency
              }
              onBlur={handleBlur}
              error={
                errors &&
                errors.other_insurance_covering &&
                errors.other_insurance_covering.amount &&
                errors.other_insurance_covering.amount.currency
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OtherInsuranceArea;
