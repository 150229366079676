export const BUTTON_UPLOAD_FILES = {
  es_ES: 'Subir archivos',
  en_US: 'Upload files',
  pt_BR: 'Enviar arquivos',
};

export const SUBTITLE_UPLOAD_FILES = {
  es_ES: 'Archivos subidos (opcional)',
  en_US: 'Uploaded files (optional)',
  pt_BR: 'Arquivos enviados (opcional)',
};

export const EMPTY_MESSAGE = {
  es_ES: 'Ningún archivo adjunto',
  en_US: 'No file attached',
  pt_BR: 'Nenhum arquivo anexado',
};

export const LOADING = {
  en_US: 'Loading...',
  pt_BR: 'Carregando...',
  es_ES: 'Cargando...',
};

export const TITLE_UPLOAD_ERROR = {
  en_US: 'An error occurred while uploading this file.',
  pt_BR: 'Ocorreu um erro ao enviar este arquivo.',
  es_ES: 'Se ha producido un error al subir este archivo.',
};

export const ERROR_MAX_FILESIZE = {
  es_ES: 'Todos los archivos deben tener menos de 1.5 MB.',
  en_US: 'All files must be below 1.5mb.',
  pt_BR: 'Todos os arquivos devem ser abaixo de 1.5mb.',
};
export const FILE_TYPE_ERROR = {
  en_US: 'Invalid file format.',
  pt_BR: 'Formato do arquivo é inválido.',
  es_ES: 'Formato de archivo inválido.',
};
