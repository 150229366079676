/* eslint-disable no-console */
import { IconColor, IconSurface } from '../../@types/Icons';
import { ButtonProps, ButtonType, Surface } from './Button.types';

export const checkForDesignErrors = ({
  type,
  leadingIcon,
  trailingIcon,
}: Partial<ButtonProps>) => {
  if (process.env.NODE_ENV !== 'development') return;

  if (type === 'Tertiary' && !leadingIcon && !trailingIcon) {
    console.error(
      'The tertiary button must have at least one icon, either a leading icon or a trailing icon'
    );
  }

  if (type === 'Icon' && !leadingIcon) {
    console.error('Icon button needs a leading Icon');
  }
};

export const getTypeClassname = (type: ButtonType) => {
  if (type === 'Tertiary') return 'tertiary';
  if (type === 'Secondary') return 'secondary';
  if (type === 'Icon') return 'icon';
  return 'primary';
};

export const getButtonIconProps = (
  type: ButtonType,
  surface: Surface
): { surface: IconSurface; color: IconColor } => ({
  surface:
    (type === 'Primary' && surface !== 'Inverse') ||
    (type !== 'Primary' && surface === 'Inverse')
      ? 'Inverse'
      : 'OnSurface',

  color: type === 'Primary' && surface !== 'Inverse' ? 'Duotone' : 'Monotone',
});
