import { useContext, useMemo } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import { Formik } from 'formik';
import PersonalArea from './PersonalArea/PersonalArea';
import PeriodBaggageLossArea from './PeriodBaggageLossArea/PeriodBaggageLossArea';
import PlaceLossArea from './PlaceLossArea/PlaceLossArea';
import TransportationArea from './TransportationArea/TransportationArea';
import InsuranceArea from './InsuranceArea/InsuranceArea';
import TotalAmountArea from './TotalAmountArea/TotalAmountArea';
import AdditionalPassengers from './AdditionalPassengers/AdditionalPassengers';
import { baggageLossFormInitialValues } from './initialValues';
import { baggageLossSchema } from './validationSchema';

const BaggageLoss = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { parentValues, updateParent, incidentSubmitRef, loadedData } = props;

  const validationSchema = useMemo(() => baggageLossSchema(intl), [intl]);
  const initialValues = useMemo(
    () => baggageLossFormInitialValues(parentValues.policy, idiom, loadedData),
    [idiom, parentValues.policy]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit} data-testid="FullFormClaimBaggageLoss">
            <div>
              <button
                ref={incidentSubmitRef}
                type="submit"
                style={{ display: 'none' }}
                data-testid="submitButton"
              ></button>
              <PersonalArea parentValues={parentValues} {...props} />
              <PeriodBaggageLossArea parentValues={parentValues} {...props} />
              <PlaceLossArea parentValues={parentValues} {...props} />
              <TransportationArea parentValues={parentValues} {...props} />
              <InsuranceArea parentValues={parentValues} {...props} />
              <AdditionalPassengers parentValues={parentValues} {...props} />
              <TotalAmountArea parentValues={parentValues} {...props} />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default BaggageLoss;
