export const LABEL_TIMEZONE = {
  es_ES: 'Zona horária *',
  en_US: 'Time zone *',
  pt_BR: 'Fuso horário *',
};

export const LABEL_DATE = {
  en_US: 'Date *',
  pt_BR: 'Data *',
  es_ES: 'Fecha *',
};

export const TITLE_PLEASE_TRY_AGAIN_LATER = {
  en_US: 'Please try again later.',
  pt_BR: 'Por favor tente novamente mais tarde.',
  es_ES: 'Por favor, inténtalo de nuevo más tarde.',
};

export const TITLE_NOT_AVAILABLE = {
  es_ES: 'Actualmente no hay horarios disponibles.',
  en_US: 'There are currently no slots available.',
  pt_BR: 'Não há vagas disponíveis no momento.',
};

export const DATE_NOT_AVAILABLE = {
  es_ES: 'No hay espacios disponibles para esta fecha.',
  en_US: 'There are no spaces available for this date.',
  pt_BR: 'Não há vagas disponíveis para essa data.',
};
