//! Commented the ARI_month property. We need return with this certificate/claim, there are many PROD problems.
//! Maybe we need return with this behavior. Waiting more definitions.
//! Bug: 671768

export default [
  {
    initials: 'VE',
    country: 'VENEZUELA',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'BR',
    country: 'BRAZIL',
    idiom: 'pt_BR',
    // ARI_month: 'JULY',
  },
  {
    initials: 'AR',
    country: 'ARGENTINA',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'BO',
    country: 'BOLIVIA',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'CL',
    country: 'CHILE',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'CO',
    country: 'COLOMBIA',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'CR',
    country: 'COSTA RICA',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'CU',
    country: 'CUBA',
    idiom: 'es_ES',
  },
  {
    initials: 'DO',
    country: 'DOMINICAN REPUBLIC',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'DO',
    country: 'REPUBLICA DOMINICANA',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'EC',
    country: 'ECUADOR',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'SV',
    country: 'EL SALVADOR',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'GF',
    country: 'FRENCH GUIANA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'GP',
    country: 'GUADALUPE',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'GT',
    country: 'GUATEMALA',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'HT',
    country: 'HAITI',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'HN',
    country: 'HONDURAS',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'MQ',
    country: 'MARTINIQUE',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'MX',
    country: 'MEXICO',
    idiom: 'es_ES',
    // ARI_month: 'APRIL',
  },
  {
    initials: 'NI',
    country: 'NICARAGUA',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'PA',
    country: 'PANAMA',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'PY',
    country: 'PARAGUAY',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'PE',
    country: 'PERU',
    idiom: 'es_ES',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'PR',
    country: 'PUERTO RICO',
    idiom: 'es_ES',
  },
  {
    initials: 'PI',
    country: 'PUERTO RICO ENGLISH',
    idiom: 'en_US',
  },
  {
    initials: 'UY',
    country: 'URUGUAY',
    idiom: 'es_ES',
    // ARI_month: 'JULY',
  },
  {
    initials: 'KY',
    country: 'CAYMAN ISLANDS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'AI',
    country: 'ANGUILLA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'AG',
    country: 'ANTIGUA AND BARBUDA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'AW',
    country: 'ARUBA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'BS',
    country: 'BAHAMAS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'BB',
    country: 'BARBADOS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'BZ',
    country: 'BELIZE',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'BM',
    country: 'BERMUDA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'BQ',
    country: 'BONAIRE',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'VG',
    country: 'BRITISH VIRGIN ISLANDS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'CW',
    country: 'CURACAO',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'DM',
    country: 'DOMINICA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'GD',
    country: 'GRENADA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'GY',
    country: 'GUYANA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'JM',
    country: 'JAMAICA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'MS',
    country: 'MONTSERRAT',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'LC',
    country: 'SAINT LUCIA',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'KN',
    country: 'ST KITTS AND NEVIS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'SX',
    country: 'SINT MAARTEN',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'VC',
    country: 'ST VINCENT AND THE GRENADINES',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'SR',
    country: 'SURINAME',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'TT',
    country: 'TRINIDAD AND TOBAGO',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'TC',
    country: 'TURKS AND CAICOS ISLANDS',
    idiom: 'en_US',
    // ARI_month: 'OCTOBER',
  },
  {
    initials: 'VI',
    country: 'US VIRGIN ISLANDS',
    idiom: 'en_US',
  },
];
