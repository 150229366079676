/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import Select from '../../../../../../components/Select/Select';
import { B2B2CContext } from '../../../../../../components/B2B2CStateParent/B2B2CStateParent';

const CountryArea = props => {
  const { CONFIG_B2B2C, intl, filterCountries, idiom, values } = props;
  const { utils, actions } = useContext(B2B2CContext);
  const quote = utils.getQuote();

  useEffect(() => {
    if (
      values.Quote.CountryOfResidenceIsoCode &&
      values.Quote.CountryOfResidenceIsoCode !== ''
    ) {
      const newQuote = {
        ...quote,
      };
      newQuote.Quote.CountryOfResidenceIsoCode =
        values.Quote.CountryOfResidenceIsoCode;
      actions.formDataCotizar.setQuote(newQuote);
    }
  }, [values.Quote.CountryOfResidenceIsoCode]);

  useEffect(() => {
    if (
      values.Quote.DestinationCountryIsoCode &&
      values.Quote.DestinationCountryIsoCode !== ''
    ) {
      const newQuote = {
        ...quote,
      };
      newQuote.Quote.DestinationCountryIsoCode =
        values.Quote.DestinationCountryIsoCode;
      actions.formDataCotizar.setQuote(newQuote);
    }
  }, [values.Quote.DestinationCountryIsoCode]);

  return (
    <>
      {CONFIG_B2B2C.purchase.form.payment.origin_country.show && (
        <div className="col-12 col-md-6">
          <Select
            label={intl.ORIGIN}
            name="Quote.CountryOfResidenceIsoCode"
            dangerouslySetInnerHTML={{
              __html: `<option value="">${
                intl.ORIGIN
              }</option>${filterCountries.map(country => {
                return `
                            <option
                              value=${country.initials}
                              key=${country.initials}
                            >
                              ${country[idiom]}
                            </option>
                          `;
              })}`,
            }}
            formikProps={props}
            axa
          />
        </div>
      )}
      {CONFIG_B2B2C.purchase.form.payment.destination_country.show && (
        <div className="col-12 col-md-6">
          <Select
            label={intl.COUNTRY_DESTINATION}
            name="Quote.DestinationCountryIsoCode"
            dangerouslySetInnerHTML={{
              __html: `<option value="">${intl.COUNTRY_DESTINATION}</option>${intl.NATIONALITY_OPTIONS}`,
            }}
            formikProps={props}
            axa
          />
        </div>
      )}
    </>
  );
};

export default CountryArea;
