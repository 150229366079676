export const DEPARTURE = {
  en_US: 'Departure',
  pt_BR: 'Partida',
  es_ES: 'Salida',
};

export const DEPARTURE_TIME = {
  en_US: 'Departure time',
  pt_BR: 'Horário de partida',
  es_ES: 'Hora de salida',
};

export const ARRIVAL = {
  en_US: 'Arrival',
  pt_BR: 'Chegada',
  es_ES: 'Llegada',
};

export const ARRIVAL_TIME = {
  en_US: 'Arrival time',
  pt_BR: 'Horário de chegada',
  es_ES: 'Hora de llegada',
};

export const LOCAL_TIME = {
  en_US: 'Local time',
  pt_BR: 'Horário local',
  es_ES: 'Hora local',
};

export const FLIGHT_LEG = {
  en_US: leg => `Flight leg ${leg}`,
  pt_BR: leg => `Trecho de voo ${leg}`,
  es_ES: leg => `Segmento de vuelo ${leg}`,
};

export const FLIGHT_NUMBER = {
  en_US: 'Flight number',
  pt_BR: 'Número do voo',
  es_ES: 'Número de vuelo',
};

export const ORIGIN_AIRPORT = {
  en_US: 'Origin airport',
  pt_BR: 'Aeroporto de origem',
  es_ES: 'Aeropuerto de origen',
};

export const DESTINATION_AIRPORT = {
  en_US: 'Destination airport',
  pt_BR: 'Aeroporto de destino',
  es_ES: 'Aeropuerto de destino',
};

export const ORIGIN_CITY = {
  en_US: 'Origin city',
  pt_BR: 'Cidade de origem',
  es_ES: 'Ciudad de origen',
};

export const DESTINATION_CITY = {
  en_US: 'Destination city',
  pt_BR: 'Cidade de destino',
  es_ES: 'Ciudad de destino',
};
