export const PENDING_SEND = {
  en_US: `Awaiting submission of pending documentation<br/>
  <span>- Attach the necessary documentation so that we can review your claim. Documentation must be submitted within 90 days of the incident.</span>`,
  pt_BR: `Aguardando envio de documentação pendente<br/>
  <span>- Anexe a documentação necessária para que possamos analisar seu sinistro. A documentação deve ser apresentada dentro de 90 dias após o incidente.</span>`,
  es_ES: `Pendiente de presentación de la documentación pendiente<br/>
  <span>- Adjunta la documentación necesaria para que podamos revisar tu reclamo. La documentación debe enviarse dentro de los 90 días posteriores al incidente.</span>`,
};

export const PENDING_CHECK_FORMS = {
  en_US: 'Awaiting verification of received documentation',
  pt_BR: 'Aguardando verificação da documentação recebida',
  es_ES: 'Esperando verificación de la documentación recibida',
};

export const WAITING_CUSTOMER_DOCUMENTATION = {
  en_US: 'Awaiting submission of requested documentation to the cardholder',
  pt_BR: 'Aguardando envio de documentação solicitada ao titular do cartão',
  es_ES:
    'En espera de envío de la documentación solicitada al titular de la tarjeta',
};

export const WAITING_CUSTOMER_DOCUMENTATION_30_DAYS = {
  en_US:
    'Awaiting submission of requested documentation to the cardholder for 30 days',
  pt_BR:
    'Aguardando 30 dias o envio da documentação solicitada ao titular do cartão',
  es_ES:
    'Esperando la presentación de la documentación solicitada al titular de la tarjeta durante 30 días',
};

export const PENDING_REVIEW_FORMS = {
  en_US: 'Awaiting verification of documentation received by email',
  pt_BR: 'Aguardando verificação da documentação recebida por e-mail',
  es_ES:
    'Esperando verificación de la documentación recibida por correo electrónico',
};

export const TRANSFERRED_TO_UNDERWRITER = {
  en_US: 'Submitted for verification by the insurer',
  pt_BR: 'Encaminhado para verificação da seguradora',
  es_ES: 'Presentado para verificación por la aseguradora',
};

export const PENDING_UNDERWRITER = {
  en_US: 'Awaiting verification from insurer',
  pt_BR: 'Aguardando verificação da seguradora',
  es_ES: 'Esperando verificación de la aseguradora',
};

export const PENDING_UNDERWRITER_REVIEW = {
  en_US: 'Awaiting verification by the responsible from insurer',
  pt_BR: 'Aguardando verificação pelo responsável da seguradora',
  es_ES:
    'En espera de verificación por parte del responsable de la compañía de seguros',
};

export const REVIEW_BY_UNDERWRITER_PENDING_APPROVAL = {
  en_US: 'Documentation verified and awaiting approval from insurer',
  pt_BR: 'Documentação verificada e aguardando homologação da seguradora',
  es_ES:
    'Documentación verificada y pendiente de aprobación de la compañía de seguros.',
};

export const REJECTED_CLAIM = {
  en_US: 'Rejected claim',
  pt_BR: 'Sinistro rejeitado',
  es_ES: 'Reclamo rechazado',
};

export const REOPENED = {
  en_US: 'Reopened claim',
  pt_BR: 'Sinistro reaberto',
  es_ES: 'Reclamo reabierto',
};

export const CASE_APPELEAD = {
  en_US: 'Appealed claim',
  pt_BR: 'Sinistro apelado',
  es_ES: 'Reclamo apelado',
};

export const CASE_CLOSED = {
  en_US: 'Closed claim',
  pt_BR: 'Sinistro encerrado',
  es_ES: 'Reclamo cerrado',
};

export const CASE_CANCELED = {
  en_US: 'Canceled claim',
  pt_BR: 'Sinistro cancelado',
  es_ES: 'Reclamo cancelado',
};
