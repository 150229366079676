import * as Yup from 'yup';

export const validationBin = intl =>
  Yup.object().shape({
    cardNumber: Yup.string()
      .required(intl.TEXT_REQUIRED_FORM)
      .test('validtion-bin', intl.TEXT_REQUIRED_FORM, function (value) {
        if (!value) return false;
        const bin = value.replace(/\./g, '');
        return bin && bin.length >= 10;
      }),
  });
