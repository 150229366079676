export const listCountriesPhones = [
  {
    initials: 'AR',
    phone: '0-800-444-0836',
    idiom: 'es_ES',
    pt_BR: 'Argentina',
    en_US: 'Argentina',
    es_ES: 'Argentina',
  },
  {
    initials: 'BS',
    phone: '1-800-455-0915',
    idiom: 'en_US',
    pt_BR: 'Bahamas',
    en_US: 'Bahamas',
    es_ES: 'Bahamas',
  },
  {
    initials: 'BB',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'Barbados',
    en_US: 'Barbados',
    es_ES: 'Barbados',
  },
  {
    initials: 'BZ',
    phone: '811+800-704-0545',
    idiom: 'en_US',
    pt_BR: 'Belize',
    en_US: 'Belize',
    es_ES: 'Belice',
  },
  {
    initials: 'BO',
    phone: '800-10-0203',
    idiom: 'es_ES',
    pt_BR: 'Bolívia',
    en_US: 'Bolivia',
    es_ES: 'Bolivia',
  },
  {
    initials: 'BR',
    phone: '0800-891-3679',
    idiom: 'pt_BR',
    pt_BR: 'Brasil',
    en_US: 'Brazil',
    es_ES: 'Brasil',
  },
  {
    initials: 'CL',
    phone: '1230-020-0624',
    idiom: 'es_ES',
    pt_BR: 'Chile',
    en_US: 'Chile',
    es_ES: 'Chile',
  },
  {
    initials: 'CO',
    phone: '01-800-912-2066',
    idiom: 'es_ES',
    pt_BR: 'Colômbia',
    en_US: 'Colombia',
    es_ES: 'Colombia',
  },
  {
    initials: 'CR',
    phone: '0-800-011-0346',
    idiom: 'es_ES',
    pt_BR: 'CostaRica',
    en_US: 'CostaRica',
    es_ES: 'CostaRica',
  },
  {
    initials: 'GT',
    phone: '1-800-999-2075',
    idiom: 'es_ES',
    pt_BR: 'Guatemala',
    en_US: 'Guatemala',
    es_ES: 'Guatemala',
  },
  {
    initials: 'HT',
    phone: '183-800-285-4316',
    idiom: 'es_ES',
    pt_BR: 'Haiti',
    en_US: 'Haiti',
    es_ES: 'Haití',
  },
  {
    initials: 'HN',
    phone: '800-0123+800-704-0545',
    idiom: 'es_ES',
    pt_BR: 'Honduras',
    en_US: 'Honduras',
    es_ES: 'Honduras',
  },
  {
    initials: 'JM',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'Jamaica',
    en_US: 'Jamaica',
    es_ES: 'Jamaica',
  },
  {
    initials: 'MX',
    phone: '001-800-396-9665',
    idiom: 'es_ES',
    pt_BR: 'México',
    en_US: 'Mexico',
    es_ES: 'México',
  },
  {
    initials: 'NI',
    phone: '1-800-0174+800-704-0545',
    idiom: 'es_ES',
    pt_BR: 'Nicarágua',
    en_US: 'Nicaragua',
    es_ES: 'Nicaragua',
  },
  {
    initials: 'PA',
    phone: '001-800-396-9665',
    idiom: 'es_ES',
    pt_BR: 'Panamá',
    en_US: 'Panama',
    es_ES: 'Panamá',
  },
  {
    initials: 'PY',
    phone: '008-11-800+800-704-0545	',
    idiom: 'es_ES',
    pt_BR: 'Paraguai',
    en_US: 'Paraguay',
    es_ES: 'Paraguay',
  },
  {
    initials: 'PE',
    phone: '0-800-51-773',
    idiom: 'es_ES',
    pt_BR: 'Peru',
    en_US: 'Peru',
    es_ES: 'Perú',
  },
  {
    initials: 'VE',
    phone: '0800-1-002974',
    idiom: 'es_ES',
    pt_BR: 'Venezuela',
    en_US: 'Venezuela',
    es_ES: 'Venezuela',
  },
  {
    initials: 'AG',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'AntíguaeBarbuda',
    en_US: 'AntiguaandBarbuda',
    es_ES: 'AntiguayBarbuda',
  },
  {
    initials: 'BM',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'Bermudas',
    en_US: 'Bermuda',
    es_ES: 'Bermudas',
  },
  {
    initials: 'CW',
    phone: '1-800-704-0545',
    idiom: 'en_US',
    pt_BR: 'Curaçao',
    en_US: 'Curacao',
    es_ES: 'Curazao',
  },
  {
    initials: 'DM',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'Dominica',
    en_US: 'Dominica',
    es_ES: 'Dominica',
  },
  {
    initials: 'EC',
    phone:
      '1-800-225-528, wait for tone then dial (espere por el tono y marque) 800 704 0545',
    idiom: 'es_ES',
    pt_BR: 'Equador',
    en_US: 'Ecuador',
    es_ES: 'Ecuador',
  },
  {
    initials: 'SV',
    phone: '800-6338',
    idiom: 'es_ES',
    pt_BR: 'ElSalvador',
    en_US: 'ElSalvador',
    es_ES: 'ElSalvador',
  },
  {
    initials: 'KY',
    phone: '1-800-396-9665',
    idiom: 'en_US',
    pt_BR: 'IlhasCayman',
    en_US: 'CaymanIslands',
    es_ES: 'IslasCaimám',
  },
  {
    initials: 'VI',
    phone: '1-800-704-0545',
    idiom: 'en_US',
    pt_BR: 'IlhasVirgensAmericanas',
    en_US: 'USVirginIslands',
    es_ES: 'IslasVírgenesdelosEstadosUnidos',
  },
  {
    initials: 'US',
    phone: '1-303-967-1098 (via international operator)',
    idiom: 'en_US',
    pt_BR: 'Outros(AméricaLatina)',
    en_US: 'Other(LatinAmerica)',
    es_ES: 'Otro(AméricaLatina)',
  },
  {
    initials: 'PR',
    phone: '1-800-704-0545',
    idiom: 'es_ES',
    pt_BR: 'PortoRico',
    en_US: 'PuertoRico',
    es_ES: 'PuertoRico',
  },
];
