import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_PLEASE_EXPLAIN = {
  en_US: 'Please explain why *',
  pt_BR: 'Por favor, explique por que *',
  es_ES: 'Por favor, explique por qué *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_INDEMNIFICATION_HOTEL = {
  en_US: 'Did you receive indemnification by the hotel? *',
  es_ES: '¿Recibiste indemnización por parte del hotel? *',
  pt_BR: 'Você recebeu indenização do hotel? *',
};

export const TEXT_AMOUNT_PAID_HOTEL = {
  en_US: 'Indicate the amount paid by the hotel *',
  es_ES: 'Indica el monto pagado por parte del hotel *',
  pt_BR: 'Indique o valor pago pelo hotel *',
};

export const TEXT_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit? *',
  es_ES: '¿Tienes otro seguro que cubra este incidente? *',
  pt_BR: 'Você tem outro seguro que cubra este incidente? *',
};

export const TEXT_AMOUNT_OTHER_INSURANCE = {
  en_US: 'Please indicate the amount paid by the insurance *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
};
