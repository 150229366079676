import { productsAndBenefitsServices } from '../../services/index';

const getInfoBenefits = async (benefits, binOrPan, idiom) => {
  try {
    const listBenefits = benefits.map(async benefit => {
      if (benefit !== null) {
        const { data } =
          await productsAndBenefitsServices.getBenefitsDetailByProduct(
            binOrPan,
            benefit,
            idiom
          );
        const imageIcon = data.images.find(image => image.type === 'ICON');
        return {
          ...data,
          _link: { url: imageIcon ? imageIcon.content_url : '' },
        };
      }
    });
    return Promise.all(listBenefits);
  } catch (error) {}
};

const verifyClaimIsDiscontinued = async (claims, benefits, binOrPan, idiom) => {
  const listExternalReferenceClaims = claims.map(claim => {
    const prop = claim.external_reference
      ? 'external_reference'
      : 'benefit_external_reference';
    return claim[prop];
  });

  const listExternalReferenceBenefits = benefits.map(
    benefit => benefit.external_reference
  );
  const discontinuedBenefits = listExternalReferenceClaims.filter(
    claim => !listExternalReferenceBenefits.includes(claim)
  );

  const benefistWithoutDuplicates = [...new Set(discontinuedBenefits)];
  return benefistWithoutDuplicates && benefistWithoutDuplicates.length > 0
    ? await getInfoBenefits(benefistWithoutDuplicates, binOrPan, idiom)
    : [];
};

export const matchClaimsAndBenefits = async (
  claims,
  benefits,
  binOrPan,
  idiom
) => {
  try {
    const claimIsDiscontinued = await verifyClaimIsDiscontinued(
      claims,
      benefits,
      binOrPan,
      idiom
    );
    return [...benefits, ...claimIsDiscontinued];
  } catch (error) {
    return [...benefits];
  }
};
