import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import Button, { ButtonType } from '../../componentsV2/Button/Button';
import styles from './Bluebox.module.scss';
import { joinClassNames } from '../../utils/classNames';

interface BlueboxLinkProps {
  label: string;
  onClick: VoidFunction;
  border?: boolean;
  margin?: boolean;
  type?: ButtonType;
  id?: string;
  icon: any;
}

const BlueboxLink = ({
  label,
  onClick,
  border = true,
  margin = true,
  type = 'Secondary',
  id,
  icon,
}: BlueboxLinkProps) => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const CSSnoBorder = `${styles.buttonLink}`;
  const CSSMargin = `${styles.marginTop}`;
  const CSSicon = `${styles.iconMargin}`;

  return (
    <Button
      type={type}
      className={joinClassNames(
        styles[theme],
        styles.linkButton,
        border ? '' : CSSnoBorder,
        margin ? CSSMargin : '',
        icon ? CSSicon : ''
      )}
      onClick={onClick}
      id={id}
      surface="Inverse"
      label={label}
      leadingIcon={icon}
    />
  );
};

export default BlueboxLink;
