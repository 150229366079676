export const TITLE_YOUR_VOICE = {
  en_US: 'Voice ID registration',
  pt_BR: 'Registro de Voice ID',
  es_ES: 'Registro de Voice ID',
};

export const VOICE_ID_CREATED = {
  en_US:
    'Your Voice ID was successfully created and is currently available as:',
  pt_BR:
    'Seu Voice ID foi criado com sucesso e está atualmente disponível como:',
  es_ES:
    'Tu Voice ID se creó correctamente y está actualmente disponible como:',
};

export const EXAMPLE_OF_USAGE_1 = {
  en_US:
    'Identification key for smarter and safer interaction if you need to contact us by phone.',
  pt_BR:
    'Chave de identificação para uma interação mais inteligente e segura caso precise entrar em contato conosco em chamadas telefônicas.',
  es_ES:
    'Clave de identificación para una interacción más inteligente y segura si necesita contactarnos por teléfono.',
};
