import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import styles from './ExpenseArea.module.scss';
import ExpenseItem from './ExpenseItem/ExpenseItem';
import ExpenseAreaTotal from './ExpenseAreaTotal/ExpenseAreaTotal';
import ExpenseItemAdd from './ExpenseItem/ExpenseItemAdd';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const ExpenseArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const mappedExpenses = values.expenses.map((currentValue, index) => {
    const _errors =
      errors.expenses && errors.expenses[index] ? errors.expenses[index] : {};
    const _touched =
      touched.expenses && touched.expenses[index]
        ? touched.expenses[index]
        : {};
    return (
      <ExpenseItem
        key={`expense-${index}`}
        _touched={_touched}
        _errors={_errors}
        index={index}
        {...props}
      />
    );
  });

  return (
    <div className={`${styles.expenseArea}`}>
      <div className="row">
        <div className="col-12">
          <h4 className={styles[themes]}>{intl.TITLE_AREA}</h4>
          <p className={styles[themes]}>{intl.EXPENSE_INFO}</p>
        </div>
      </div>
      <ExpenseItemAdd {...props} />
      {mappedExpenses}
      <ExpenseAreaTotal {...props} />
    </div>
  );
};

export default ExpenseArea;
