export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const ACCESS = {
  en_US: 'Access',
  pt_BR: 'Acessar',
  es_ES: 'Acceder',
};

export const GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  pt_BR: 'Gerar bilhete',
  es_ES: 'Generar un certificado',
};

export const VIEW_RESUME = {
  en_US: '',
  pt_BR: 'Ver resumo',
  es_ES: '',
};

export const VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const BUTTON_PURCHASE = {
  en_US: 'Purchase it',
  pt_BR: 'Compre',
  es_ES: 'Cómpralo',
};

export const ACCESS_VISA_ONLINE_MEDIC = {
  en_US: 'Access Visa Online Medic ',
  pt_BR: 'Acessar Visa Médico Online ',
  es_ES: 'Acceder Visa Médico Online ',
};

export const NAL_CONSULT_APPOINTMENT = {
  en_US: 'Consult appointments ',
  pt_BR: '',
  es_ES: 'Consultar citas ',
};

export const GENERATE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};
export const TEXT_EMPTY_BENEFITS = {
  en_US: 'No benefits available',
  pt_BR: 'Nenhum benefício disponível',
  es_ES: 'No hay benefício disponible',
};

export const OR = {
  en_US: ' or ',
  es_ES: ' o ',
  pt_BR: ' ou ',
};

export const UPDATE_BENEFIT = {
  en_US: 'Upgrade your coverage',
  pt_BR: 'Aumente sua cobertura',
  es_ES: 'Mejora tu cobertura',
};
