export const teleconsutationFormInitialValues = ({
  cardHolder,
  language,
  differenceInCalendarYears,
}) => ({
  generalFormSchema: {
    type: 'VIDEO',
    country: '',
    language,
    timezone: '',
    patient: {
      phone_prefix: cardHolder.phone.international_prefix,
      phone: cardHolder.phone.number,
      name: cardHolder.person.first_name,
      surname: cardHolder.person.last_name,
      birth_date: cardHolder.person.birth_date,
      age: differenceInCalendarYears(
        new Date(),
        new Date(cardHolder.person.birth_date)
      ),
    },
    files: [],
    call_reason: '',
    date: '',
    time: '',
  },
});

export const teleconsutationFormOneInitialValues = ({
  cardHolder,
  differenceInCalendarYears,
}) => ({
  phone_prefix: cardHolder.phone.international_prefix,
  phone: cardHolder.phone.number,
  name: cardHolder.person.first_name,
  surname: cardHolder.person.last_name,
  birth_date: cardHolder.person.birth_date,
  age: differenceInCalendarYears(
    new Date(),
    new Date(cardHolder.person.birth_date)
  ),
});

export const teleconsutationFormTwoValues = ({ cardHolder, language }) => ({
  type: 'VIDEO',
  country: '',
  language,
  timezone: '',
  phone_prefix: cardHolder.phone.international_prefix,
  phone: cardHolder.phone.number,
});

export const teleconsutationFormThreeValues = ({ type }) => ({
  type,
  call_reason: '',
  date: '',
  time: '',
  timezone: '',
  files: [],
});
