import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const IncidentAmountArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const withdrawnAmount =
    incident && incident.withdrawn_amount
      ? incident.withdrawn_amount.value
      : '-';

  const withdrawnCurrency =
    incident && incident.withdrawn_amount
      ? incident.withdrawn_amount.currency
      : '-';

  const stolenAmount =
    incident && incident.stolen_amount ? incident.stolen_amount.value : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_WITHDRAWN_AMOUNT}
          value={`${withdrawnAmount} - ${withdrawnCurrency}`}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_STOLEN_AMOUNT}
          value={`${stolenAmount} - ${withdrawnCurrency}`}
        />
      </div>
    </>
  );
};

export default IncidentAmountArea;
