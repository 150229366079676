export const LABEL_DESCRIPTION_EMERGENCY = {
  en_US: 'Describe your medical emergency',
  pt_BR: 'Descreva sua emergência médica',
  es_ES: 'Describe tu condición médica',
};

export const LABEL_ASSISTANCE_START = {
  en_US: 'Date when medical assistance started',
  pt_BR: 'Data de início da assistência médica',
  es_ES: 'Fecha en que comenzó la asistencia médica',
};

export const LABEL_ASSISTANCE_END = {
  en_US: 'Date when medical assistance finished',
  pt_BR: 'Data de fim da assistência médica',
  es_ES: 'Fecha en que finalizó la asistencia médica',
};

export const LABEL_DIAGNOSIS_DOCTOR = {
  en_US: 'Describe the diagnosis indicated by the doctor',
  pt_BR: 'Descreva o diagnóstico indicado pelo médico',
  es_ES: 'Describa el diagnóstico indicado por el médico',
};

export const LABEL_TREATMENT_DOCTOR = {
  en_US: 'Describe the treatment indicated by the doctor',
  pt_BR: 'Descreva o tratamento indicado pelo médico',
  es_ES: 'Describa el tratamiento indicado por el médico',
};

export const LABEL_CASE_NUMBER = {
  en_US: 'Existing case number',
  pt_BR: 'Número de caso existente',
  es_ES: 'Número del caso existente',
};
