import React, { useContext, useEffect } from 'react';
import styles from './ConfirmationLinkExpired.module.scss';
import { withRouter } from 'react-router-dom';
import * as translations from './intl';
import { IntlContext } from '../../intl';
import { getQueryVariable } from '../../utils/uri';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { dataLayerEmailConfirmedTrack } from '../../utils/GTM_helper';

const checkRedirectOrMessage = (actions, history, intl) => {
  const success = getQueryVariable('success');
  const message = getQueryVariable('message');

  const redirect = () => {
    actions.modal.closeModal();
    history.push('/open-app');
  };

  if (success === 'true') {
    dataLayerEmailConfirmedTrack();

    actions.modal.showModal(
      false,
      false,
      <ModalInformation
        type="success"
        message={intl.SUCCESS_TITLE}
        subtitle={intl.SUCCESS_DESCRIPTION}
        textBtn={intl.BTN_GO_PORTAL}
        clickBtn={redirect}
      />,
      true
    );
  } else {
    if (message && message.includes('used')) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.LINK_USED_EXPIRED}
          subtitle={intl.MSG_USED_EXPIRED}
          buttonConfirmType="borderBlue"
          clickBtn={redirect}
        />,
        true
      );
    } else {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.LINK_EXPIRED}
          subtitle={`${intl.LINK_INVALID_MSG_TITLE} ${intl.LINK_INVALID_MSG_TITLE_SUBTITLE}`}
          buttonConfirmType="borderBlue"
          clickBtn={redirect}
        />,
        true
      );
    }
  }
};

const ConfirmationLinkExpired = props => {
  const { history } = props;
  const { translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);

  useEffect(() => {
    checkRedirectOrMessage(actions, history, intl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={`${styles.sessionExpired} ${styles[theme]}`}></div>;
};

export default withRouter(ConfirmationLinkExpired);
