/* eslint-disable no-unused-vars */
import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { removeNonNumeric } from '../../../../../../utils/stringUtils';
import { userServices } from '../../../../../../services';
import ModalWithImage from '../../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../../components/ModalResult/ModalResult';

export const separeDependentsData = (values, dependentsData) => {
  const data = {
    dependentsCreate: {},
    dependentsUpdate: {},
    dependentsDelete: {},
  };

  data.dependentsCreate = values.dependents.filter(value => {
    return value.dependent_id === '';
  });

  data.dependentsUpdate = values.dependents.filter(value => {
    return value.dependent_id !== '';
  });

  data.dependentsDelete = dependentsData.dependents.filter(value => {
    return !values.dependents.find(dependent => {
      return dependent.dependent_id === value.dependent_id;
    });
  });

  return data;
};

export const dependentCreate = (values, cardholderInfo) => {
  const data = values.map(value => {
    // Set dependent address to same as CH
    value.address = cardholderInfo.address || '';

    // Nationalities
    value.person.nationalities = [value.nationalities];

    return {
      address: value.address,
      email: value.email,
      person: value.person,
      phone: {
        phone_type: '',
        number: '',
        international_prefix: '',
      },
      relationship: value.relationship,
      registrations: value.person_registrations.map(registration => ({
        registration_type: registration.registration_type,
        value:
          registration.registration_type === 'CPF'
            ? removeNonNumeric(registration.value)
            : registration.value,
      })),
    };
  });

  return data;
};

export const dependentsDataUpdate = (values, cardholderInfo) => {
  const data = values.map(value => {
    value.person.nationalities = [value.nationalities];

    return {
      address: cardholderInfo.address,
      email: value.email,
      person: value.person,
      phone: {
        phone_type: '',
        number: '',
        international_prefix: '',
      },
      relationship: value.relationship,
      registrations: value.person_registrations.map(registration => ({
        registration_type: registration.registration_type,
        value:
          registration.registration_type === 'CPF'
            ? removeNonNumeric(registration.value)
            : registration.value,
      })),
      dependent_id: value.dependent_id,
    };
  });

  return data;
};

export const checkDependentsUpdate = (initialValues, dependentsUpdate) => {
  let data = dependentsUpdate.filter(value => {
    let dependentChanged = initialValues.dependents.find(dependent => {
      return dependent.dependent_id === value.dependent_id;
    });

    // CPF/PASSPORT
    let isCpf = value.person_registrations[0].registration_type === 'CPF';
    let registrationValue = isCpf
      ? removeNonNumeric(value.person_registrations[0].value)
      : value.person_registrations[0].value;
    value.person_registrations[0].value = registrationValue;

    return !isEqual(value, dependentChanged);
  });

  return data;
};

export const checkDependetsChange = async (
  props,
  confirmToSave = true,
  setSubmitting = null,
  alert = null,
  intlError = '',
  state = null
) => {
  const {
    formRef: {
      initialValues: { dependents: initialValues },
    },
    values: { dependents: values, policy: policy },
    actions,
    intl = {},
    cn,
  } = props;
  if (policy && policy.benefitiaryIsNotCardHolder) return false;
  let dataToUpdate = [];
  let dataToCreate = [];
  let changed = false;

  const cloned = cloneDeep(values);
  const { dependentsUpdate, dependentsCreate, dependentsDelete } =
    separeDependentsData(cloned, initialValues);

  // Check to Update
  if (dependentsUpdate.length > 0) {
    dataToUpdate = checkDependentsUpdate(initialValues, dependentsUpdate);
    changed = dataToUpdate.length > 0 ? true : false;
  }

  // Check to Create
  if (dependentsCreate.length > 0) {
    dataToCreate = dependentCreate(dependentsCreate);
    changed = true;
  }

  // Check to Delete
  if (dependentsDelete.length > 0) {
    changed = true;
  }

  if (changed) {
    const savePromisse = new Promise((resolve, reject) => {
      const dependentChanges = async () => {
        actions.modal.closeModal();
        try {
          if (dataToUpdate.length > 0) {
            dataToUpdate = dependentsDataUpdate(dataToUpdate);
            const update = dataToUpdate.map(dependent => {
              let dependent_id = dependent.dependent_id;
              delete dependent.dependent_id;
              return userServices.updateCHDependents(
                cn,
                dependent,
                dependent_id
              );
            });
            await Promise.all(update);
          }

          if (dataToCreate.length > 0) {
            const create = dataToCreate.map(dependent => {
              return userServices.createCHDependents(cn, dependent);
            });
            await Promise.all(create);
          }

          if (dependentsDelete.length > 0) {
            const remove = dependentsDelete.map(dependent => {
              return userServices.deleteDependent(cn, dependent.dependent_id);
            });
            await Promise.all(remove);
          }

          resolve();
        } catch (e) {
          if (e.response && e.response.status === 400) {
            if (state) {
              state.isSubmitting = false;
            }
            alert.alert.showError(intlError);
            if (setSubmitting) {
              setSubmitting(false);
            }
          }
        }
      };

      if (confirmToSave) {
        actions.modal.showModal(
          false,
          false,
          <ModalWithImage
            type="question"
            title={intl.TEXT_CARD_IS_PREFERRED}
            subtitle={intl.TEXT_CARD_MAIN_ONE}
          >
            <ModalResult
              textConfirm={intl.TEXT_YES}
              textDeny={intl.TEXT_NO}
              clickConfirm={dependentChanges}
              clickDeny={() => actions.modal.closeModal()}
            />
          </ModalWithImage>
        );
      } else {
        dependentChanges();
      }
    });

    return savePromisse;
  }
};
