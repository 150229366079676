import React, { useContext } from 'react';

import moment from 'moment';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { pt_BR } from '../../../../../../intl/idioms';
import * as translations from './intl';
import { formatCustomDate } from '../../../../../../utils/date';

const IncidentPeriodArea = props => {
  const { claimDetails } = props;
  const { idiom, translate, country } = useContext(IntlContext);
  const intl = translate(translations);

  const isPTBR = idiom === pt_BR;
  const isMX = country === 'MX';
  const formatDateAndHour = date =>
    moment(date).format(
      isPTBR || isMX ? 'DD/MM/YYYY HH:mm' : 'MM/DD/YYYY HH:mm'
    );

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : {};

  const incident =
    claimDetails && claimDetails.incident ? claimDetails.incident : {};

  const fromDate =
    travelInformation && travelInformation.from_date
      ? formatCustomDate(travelInformation.from_date, null, idiom, country)
      : '-';

  const toDate =
    travelInformation && travelInformation.to_date
      ? formatCustomDate(travelInformation.to_date, null, idiom, country)
      : '-';

  const discoveredAt =
    incident && incident.discovered_at
      ? formatDateAndHour(incident.discovered_at)
      : '-';

  const baggageArrivedAt =
    incident && incident.baggage_arrived_at
      ? formatDateAndHour(incident.baggage_arrived_at)
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_TRIP_PERIOD}
          value={`${fromDate} - ${toDate}`}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_DATE_ARRIVAL_DESTINATION}
          value={discoveredAt}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_DATE_RECEIPT_BAGGAGE}
          value={baggageArrivedAt}
        />
      </div>
    </>
  );
};

export default IncidentPeriodArea;
