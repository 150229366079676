import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import {
  getCardholderRelationship,
  getAffectedPersonName,
  getAffectedPersonLastName,
  OTHER_VALUE,
  getAffectedPersonComment,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [affectedPerson, setAffectedPerson] = useState({});

  useEffect(() => {
    const affectedPerson = (claimDetails && claimDetails.affected_person) || {};
    setAffectedPerson(affectedPerson);
  }, [claimDetails]);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.PERSON_AFFECTED}
          value={getCardholderRelationship(affectedPerson, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_NAME}
          value={getAffectedPersonName(affectedPerson)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={getAffectedPersonLastName(affectedPerson)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        {affectedPerson.relationship === OTHER_VALUE && (
          <InfoLabelValue
            label={intl.LABEL_RELATIONSHIP}
            value={getAffectedPersonComment(affectedPerson)}
          />
        )}
      </div>
    </>
  );
};

export default PersonalArea;
