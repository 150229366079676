export const TITLE_BANNER = {
  en_US: 'Explore and enjoy all the benefits of your Visa cards',
  es_ES: 'Explora y disfruta de todos los beneficios de tus tarjetas Visa',
  pt_BR: 'Explore e aproveite todos os benefícios de seus cartões Visa',
};

export const TITLE_EXPLORE_WORLD = {
  en_US: 'Explore the world at ease',
  es_ES: 'Disfruta el mundo a tu gusto',
  pt_BR: 'Explore o mundo à vontade',
};

export const CONTENT_VISA_MEDIC = {
  en_US: 'Visa Online Medic',
  es_ES: 'Visa Médico Online',
  pt_BR: 'Visa Médico Online',
};

export const CONTENT_AUTO_RENTAL = {
  en_US: 'Auto Rental Insurance',
  es_ES: 'Seguro de Alquiler de Vehículos',
  pt_BR: '',
};

export const LINK_ISSUE_INSURANCE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete de viagem',
};

export const TITLE_YOU_COVERED = {
  en_US: 'We got you covered',
  es_ES: 'Te tenemos cubierto',
  pt_BR: 'Mantenha-se protegido',
};

export const LINK_OPEN_CLAIM = {
  en_US: 'Open a claim',
  es_ES: 'Crear un reclamo',
  pt_BR: 'Abrir um sinistro',
};

export const TITLE_TRACK_REQUESTS = {
  en_US: 'Track your requests in real time',
  es_ES: 'Rastrea tus solicitudes en tiempo real',
  pt_BR: 'Acompanhe suas solicitações em tempo real',
};

export const CONTENT_MAKE_CLAIMS = {
  en_US: 'Make claims and see them in process',
  es_ES: 'Generar un reclamo y consultar el estado de tus reclamos',
  pt_BR: 'Crie sinistros e acompanhe seu andamento',
};

export const LINK_TRACK_REQUEST = {
  en_US: 'Track request',
  es_ES: 'Rastrear',
  pt_BR: 'Acompanhar',
};

export const TITLE_GRAY_BOX_UPSELLING = {
  en_US: 'Improve your coverage in International Emergency Medical Services',
  es_ES: 'Mejora tu cobertura en Servicios Médicos de Emergencia Internacional',
  pt_BR:
    'Melhore sua cobertura em Serviços Médicos de Emergência Internacional',
};

export const LINK_GRAY_BOX_UPSELLING = {
  en_US: 'Quote a plan with AXA',
  es_ES: 'Cotiza un plan con AXA',
  pt_BR: 'Cote um plano com a AXA',
};

export const TITLE_GRAY_BOX_CROSSELLING = {
  en_US: 'Get coverage in International Emergency Medical Services',
  es_ES:
    'Adquira una cobertura en Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Obtenha cobertura em Serviços Médicos de Emergência Internacional',
};

export const LINK_GRAY_BOX_CROSSELLING = {
  en_US: 'Buy now with AXA',
  es_ES: 'Compra ahora con AXA',
  pt_BR: 'Adquira agora com a AXA',
};

export const SESSION_BANNER = {
  en_US: 'Session Banner',
  es_ES: 'Banner de Sesión',
  pt_BR: 'Banner de Sessão',
};

export const PLAY_OR_PAUSE_BUTTON = {
  en_US: 'Play or Pause Button',
  es_ES: 'Botón de Reproducir o Pausar',
  pt_BR: 'Botão de Reproduzir ou Pausar',
};

export const SESSION_BANNER_MARKETING_MOBILE = {
  en_US: 'Session Banner Marketing Mobile',
  es_ES: 'Banner de Sesión Marketing Móvil',
  pt_BR: 'Banner de Sessão Marketing Móvel',
};
