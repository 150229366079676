import React, { useState, useEffect, useContext, useRef } from 'react';
import styles from './BellNotification.module.scss';

import { IntlContext } from '../../intl/index';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { StateContext } from '../StateContextParent/StateContextParent';
import { claimsServices } from '../../services';
import moment from 'moment';
import { Link } from 'react-router-dom';
import * as translations from './intl';
import iconMaster from '../../assets/images/icon/master/icon-bell-master.svg';
import iconAfluent from '../../assets/images/icon/afluent/icon-bell-afluent.svg';
import ArrowRightIcon from '../../assets/icons/ArrowRight';

const STATUS_PENDING = 'Pending Send';
const ABOUT_TO_EXPIRE = 76;

const loadClaims = async (cn, bin, idiom, setClaims) => {
  try {
    const { data } = await claimsServices.getClaims(cn, bin, idiom);
    const listClaimsPending = data.filter(
      claim => claim.status === STATUS_PENDING
    );

    let listPending = [];
    let listPendingAboutToExpire = [];
    listClaimsPending.forEach(claim => {
      if (
        moment(claim.created_at)
          .add(ABOUT_TO_EXPIRE, 'days')
          .isSameOrBefore(moment(new Date()))
      ) {
        listPendingAboutToExpire.push(claim);
      } else {
        listPending.push(claim);
      }
    });

    const notifications = listPending.length + listPendingAboutToExpire.length;
    setClaims({ listPending, listPendingAboutToExpire, notifications });
  } catch (error) {}
};

const BellNotificationTemporary = () => {
  const [notifications, setNotifications] = useState(0);
  const [claims, setClaims] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  const { utils } = useContext(StateContext);
  const { idiomForApi, translate } = useContext(IntlContext);
  const { isAfluent, getGlobalTheme } = useContext(ThemeContext);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const iconBell = isAfluent() ? iconAfluent : iconMaster;
  const bin = utils.getBin();
  const cn = utils.getCn();
  const idiomApi = idiomForApi();

  useEffect(() => {
    if (claims && claims.notifications) {
      setNotifications(claims.notifications);
    }
  }, [claims]);

  useEffect(() => {
    if (cn && bin && idiomApi) {
      loadClaims(cn, bin, idiomApi, setClaims);
    }
  }, [cn, bin, idiomApi]);

  const renderMenuItem = (claims, title, subtitle, textLink, key) => {
    return (
      <li key={key} className={styles[theme]}>
        <p className={styles[theme]}>{title(claims.length)}</p>
        <p className={`${styles[theme]} ${styles.marginBottom}`}>{subtitle}</p>
        <Link className={styles[theme]} to="/profile/claims#pending">
          {textLink}
          <ArrowRightIcon
            width={20}
            height={20}
            aria-label={intl.RIGHT_ARROW}
          />
        </Link>
      </li>
    );
  };

  const openMenu = () => {
    setShowMenu(true);
    document.body.addEventListener('click', closeMenu);
  };

  const closeMenu = event => {
    event.stopPropagation();
    const containMenu =
      menuRef.current && menuRef.current.contains(event.target);
    if (!containMenu) {
      setShowMenu(false);
      document.body.removeEventListener('click', closeMenu);
    }
  };

  return (
    <div className={styles.notificationContainer}>
      <div
        id="notifications-headerOption"
        className={styles.boxIcon}
        onClick={openMenu}
      >
        <img src={iconBell} alt={intl.NOTIFICATION} />
        {notifications > 0 && (
          <span className={`${styles[theme]} ${styles.number}`}>
            {notifications}
          </span>
        )}
      </div>
      {showMenu && (
        <ul
          ref={menuRef}
          className={`${styles.menuNotifications} ${styles[theme]}`}
        >
          {claims &&
            claims.listPending &&
            claims.listPending.length > 0 &&
            renderMenuItem(
              claims.listPending,
              intl.TITLE_CLAIMS_PENDING,
              intl.SUBTITLE_CLAIMS_PENDING,
              intl.LINK_CLAIMS,
              1
            )}
          {claims &&
            claims.listPendingAboutToExpire &&
            claims.listPendingAboutToExpire.length > 0 &&
            renderMenuItem(
              claims.listPendingAboutToExpire,
              intl.TITLE_CLAIMS_PENDING_ABOUT_TO_EXPIRED,
              intl.SUBTITLE_CLAIMS_PENDING,
              intl.LINK_CLAIMS,
              2
            )}
        </ul>
      )}
    </div>
  );
};

export default BellNotificationTemporary;
