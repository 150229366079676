/* eslint-disable react/display-name */
import React from 'react';
import Link from '../../../../componentsV2/Link/Link';
export const WELCOME_MEDIC = {
  en_US: 'Welcome to Visa Online Medic!',
  pt_BR: 'Bem-vindo ao Visa Médico Online!',
  es_ES: 'Bienvenido a Visa Médico Online!',
};

export const CONDITION_ELIGIBLE = {
  en_US:
    'To continue with the assistance please be aware there are some conditions to be eligible to the service.',
  pt_BR:
    'Para ser elegível ao serviço Visa Médico Online, você deve atender, cumulativamente, aos seguintes requisitos.',
  es_ES:
    'Para ser elegible al servicio de Visa Médico Online, Ud. Debe cumplir los siguientes requisitos.',
};

export const READ_CAREFUL = {
  en_US: 'Please read carefully and mark the options that apply:',
  pt_BR: 'Leia atentamente e marque as opções que se aplicam:',
  es_ES: 'Por favor lee atentamente y marque las opciones que aplican:',
};

export const CONDIRM_OUTSIDE_MY_COUNTRY = {
  en_US:
    'I confirm to be outside my country of residence or country of card issuance;',
  pt_BR:
    'Reconheço que estou fora do meu país de residência e do país de emissão do cartão Visa;',
  es_ES:
    'Confirmo que me encuentro fuera de mi país de residencia o del país donde se emitió la tarjeta Visa;',
};

export const CONFIRM_PURCHASE = {
  en_US:
    'I confirm that I purchased at 100% my travel tickets with an eligible Visa card for the International Emergency Medical benefit;',
  pt_BR:
    'Declaro que adquiri as passagens da viagem pagando 100% com o meu cartão Visa* elegível para o benefício de Emergência Médica Internacional;',
  es_ES:
    'Confirmo que mi ticket de viaje fue pagado al 100% con mi tarjeta Visa elegible para el beneficio de Emergencia Médica Internacional;',
};

export const PURCHASE_BY_POINTS = {
  en_US:
    '* Or, if the tickets have been purchased by redeeming points of frequent traveler programs, I declare that the boarding fees and eventual taxes were paid with a Visa Card. In case there are no taxes or fees, or if tickets were purchased with reward points, I declare it was through the redemption of points obtained by the use of the Visa Card.',
  pt_BR:
    '* Ou, se as passagens tenham sido compradas mediante resgate de pontos em programas de fidelidade, declaro que as taxas de embarque e eventuais impostos devidos foram pagos com um Cartão Visa; ou, não havendo incidência de taxas de embarque ou impostos, declaro que as passagens foram compradas mediante resgate de pontos obtidos pela utilização do Cartão Visa.',
  es_ES:
    '* O, si los pasajes han sido comprados mediante redención de puntos de programas de viajeros frecuentes, declaro que las tasas de embarque y eventuales impuestos fueron pagados con una Tarjeta Visa. En caso de no haber impuestos o tarifas, o si los mismos, fueron adquiridos con puntos de recompensa, declaro que los boletos fueron comprados mediante la redención de puntos obtenidos por la utilización de la Tarjeta Visa.',
};

export const HAVE_INSURANCE_TICKET = {
  en_US: '',
  pt_BR:
    'Disponho de um Bilhete de Seguro Anual vigente relacionado ao Seguro Viagem disponibilizado pela AIG Seguros do Brasil S/A conforme termos e condições disponíveis;',
  es_ES: '',
};

export const AGREE_TERMS_AND_CONDITIONS = {
  en_US: pdfLink => (
    <span>
      I agree to have read the &nbsp;
      <Link href={pdfLink} label="terms and conditions" icon={false} />
      &nbsp; of the International Medical Emergency benefit;
    </span>
  ),
  pt_BR: pdfLink => (
    <span>
      Eu aceito os &nbsp;
      <Link href={pdfLink} label="termos e condições" icon={false} />
      &nbsp;do benefício de Emergência Médica Internacional;
    </span>
  ),
  es_ES: pdfLink => (
    <span>
      Acepto haber leído los &nbsp;
      <Link href={pdfLink} label="términos y condiciones" icon={false} />
      &nbsp; del beneficio de Emergencia Médica Internacional;
    </span>
  ),
};

export const CONFIRM_REQUIRED_CONDITIONS = {
  en_US:
    'I confirm the assistance required is one or more from the following list of medical conditions:',
  pt_BR:
    'Reconheço que a assistência que necessito envolve uma ou mais das seguintes condições médicas:',
  es_ES:
    'Confirmo que la asistencia que requiero, es alguna de las siguientes condiciones médicas que estoy indicando a continuación:',
};

export const MEDICAL_CONDITIONS_1 = {
  en_US: '- Abrasions',
  pt_BR: '- Escoriações',
  es_ES: '- Abrasiones',
};

export const MEDICAL_CONDITIONS_2 = {
  en_US: '- Allergies',
  pt_BR: '- Alergias',
  es_ES: '- Alergias',
};

export const MEDICAL_CONDITIONS_3 = {
  en_US: '- ArthriticPain',
  pt_BR: '- Dor artrítica',
  es_ES: '- Dolor artrítico',
};

export const MEDICAL_CONDITIONS_4 = {
  en_US: '- Asthma',
  pt_BR: '- Asma',
  es_ES: '- Asma',
};

export const MEDICAL_CONDITIONS_5 = {
  en_US: '- Bronchitis',
  pt_BR: '- Bronquite',
  es_ES: '- Bronquitis',
};

export const MEDICAL_CONDITIONS_6 = {
  en_US: '- Bruises',
  pt_BR: '- Contusões',
  es_ES: '- Moretones',
};

export const MEDICAL_CONDITIONS_7 = {
  en_US: '- Cold and flu',
  pt_BR: '- Frio e gripe',
  es_ES: '- Resfriado y gripe',
};

export const MEDICAL_CONDITIONS_8 = {
  en_US: '- Cold sores',
  pt_BR: '- Herpes labial',
  es_ES: '- Herpes labial',
};

export const MEDICAL_CONDITIONS_9 = {
  en_US: '- Diarrhea',
  pt_BR: '- Diarréia',
  es_ES: '- Diarrea',
};

export const MEDICAL_CONDITIONS_10 = {
  en_US: '- Fever (over age 12 months, under age 70)',
  pt_BR: '- Febre (para aqueles com idade entre 12 meses e menos que 70 anos)',
  es_ES: '- Fiebre (mayores de 12 meses, menores de 70)',
};

export const MEDICAL_CONDITIONS_11 = {
  en_US: '- Lice',
  pt_BR: '- Piolhos',
  es_ES: '- Piojos',
};
export const MEDICAL_CONDITIONS_12 = {
  en_US: '- Simple medication refills',
  pt_BR: '- Recargas simples de medicamentos (renovação de receita médica)',
  es_ES: '- Recargas simples de medicamentos',
};
export const MEDICAL_CONDITIONS_13 = {
  en_US: '- Pink eye or conjunctivitis',
  pt_BR: '- Conjuntivite',
  es_ES: '- Ojo rosado o conjuntivitis',
};
export const MEDICAL_CONDITIONS_14 = {
  en_US: '- Rushes',
  pt_BR: '- Erupção cutânea',
  es_ES: '- Salpullido',
};
export const MEDICAL_CONDITIONS_15 = {
  en_US: '- Upper respiratory infections (uncomplicated)',
  pt_BR:
    '- Infecções respiratórias superiores, tais como amigdalite, sinusite (sem complicações)',
  es_ES: '- Infecciones respiratorias superiores (sin complicaciones)',
};
export const MEDICAL_CONDITIONS_16 = {
  en_US: '- Sinusitis',
  pt_BR: '- Sinusite',
  es_ES: '- Sinusitis',
};
export const MEDICAL_CONDITIONS_17 = {
  en_US: '- Sore throats',
  pt_BR: '- Dor de garganta',
  es_ES: '- Dolor de garganta',
};
export const MEDICAL_CONDITIONS_18 = {
  en_US: '- Stye',
  pt_BR: '- Terçol',
  es_ES: '- Orzuelo',
};
export const MEDICAL_CONDITIONS_19 = {
  en_US: '- Minor sports injuries',
  pt_BR: '- Lesões desportivas menores',
  es_ES: '- Lesiones deportivas menores',
};
export const MEDICAL_CONDITIONS_20 = {
  en_US: '- Urinary tract infections (simple)',
  pt_BR: '- Infecções do trato urinário (simples)',
  es_ES: '- Infecciones del tracto urinario (simple)',
};
export const MEDICAL_CONDITIONS_21 = {
  en_US: '- Yeast infections',
  pt_BR: '- Infecções por fungo',
  es_ES: '- Infecciones de levadura',
};
export const MEDICAL_CONDITIONS_22 = {
  en_US: '- Vomiting',
  pt_BR: '- Vômito',
  es_ES: '- Vomitando',
};
export const MEDICAL_CONDITIONS_23 = {
  en_US: '- Minor infections (example: skin, sore, throat)',
  pt_BR: '- Infecções menores (exemplo: pele, feridas, garganta)',
  es_ES: '- Infecciones menores (ejemplo: piel, llagas, garganta)',
};
export const MEDICAL_CONDITIONS_24 = {
  en_US: '- Insect bites',
  pt_BR: '- Picadas de insetos',
  es_ES: '- Picaduras de insectos',
};
export const MEDICAL_CONDITIONS_25 = {
  en_US: '- Mild dehydration',
  pt_BR: '- Desidratação leve',
  es_ES: '- Deshidratación leve',
};
export const MEDICAL_CONDITIONS_26 = {
  en_US: '- Ear infections',
  pt_BR: '- Infecções de ouvido',
  es_ES: '- Infecciones de oído',
};
export const MEDICAL_CONDITIONS_27 = {
  en_US: '- Other conditions of low complexity and severity',
  pt_BR: '- Outras condições de baixas complexidades e gravidades',
  es_ES: '- Otras condiciones de baja complejidad y gravedad',
};

export const TEXT_INCLUDE_CERTIFICATE = {
  en_US: 'Continue',
  pt_BR: 'Incluir o bilhete de seguro',
  es_ES: 'Continúe',
};

export const TEXT_SELECT_CERTIFICATE = {
  en_US: '',
  pt_BR: 'Selecionar bilhete de seguro',
  es_ES: '',
};

export const SELECT = {
  en_US: '',
  pt_BR: 'Selecione',
  es_ES: '',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Beneficiary information',
  es_ES: 'Información de beneficiario',
  pt_BR: 'Informações dos beneficiários',
};
