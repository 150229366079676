import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TEXT_TICKET_AMOUNT = {
  en_US: 'Ticket amount *',
  es_ES: 'Monto del boleto *',
  pt_BR: 'Valor da passagem *',
};

export const TEXT_TICKET_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda * ',
};

export const TEXT_ACCOMMODATION = {
  en_US: 'Accommodation costs *',
  es_ES: 'Gastos de alojamiento *',
  pt_BR: 'Gastos com alojamento *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_RECEIVE_ACCOMMODATION = {
  en_US: 'Did you receive reimbursement for the accommodation? *',
  es_ES: '¿Recibiste un reembolso por el alojamiento? *',
  pt_BR: 'Você recebeu reembolso pelo alojamento? *',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_AMOUNT_PAID_ACCOMMODATION = {
  pt_BR: 'Indique o valor reembolsado pelo alojamento *',
  es_ES: 'Indica el  monto reembolsado por el alojamiento *',
  en_US: 'Indicate the amount reimbursed for the accommodation *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const DESCRIBE_RECEIVE_ACCOMMODATION = {
  en_US: 'New fields will appear for you to inform about the refund',
  es_ES: 'Aparecerán nuevos campos para informar sobre la devolución',
  pt_BR: 'Novos campos vão aparecer para você informar sobre o reembolso',
};

export const DESCRIBE_RECEIVE_ACCOMMODATION_NO = {
  en_US: 'New fields will not be needed to inform about the refund',
  es_ES: 'No se necesitarán nuevos campos para informar sobre la devolución',
  pt_BR: 'Novos campos não serão necessários para informar sobre o reembolso',
};
