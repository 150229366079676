/* eslint-disable indent */
import moment from 'moment';

export const tripCancellationFormInitialValues = (data, idiom, loadedData) => {
  const completeClaim = loadedData || {};

  let person = data && data.person ? data.person : '';
  const affectedPerson = completeClaim.affected_person || {};
  const tripInformation = completeClaim.trip_information || {};
  const agency = completeClaim.agency || {};
  const incident = completeClaim.incident || {};

  const additionalCosts = completeClaim.additional_costs || [];
  const otherInsuranceCoverings = completeClaim.other_insurance_covering || [];

  const totalAmountClaimed = completeClaim.total_amount_claimed || [];

  const getPersonName = (cardholder, affectedPerson) => {
    const person =
      affectedPerson && affectedPerson.person
        ? affectedPerson.person
        : cardholder;
    return {
      first_name: person.first_name ? person.first_name : '',
      last_name: person.last_name ? person.last_name : '',
    };
  };

  const getAffectedPerson = (cardholder, affectedPerson) => ({
    relationship: 'Cardholder',
    relationshipCardholder: true,
    person: getPersonName(cardholder, affectedPerson),
  });

  const getPlanedTrip = tripInformation => ({
    departure_date: tripInformation.departure_date
      ? new Date(moment(tripInformation.departure_date, 'YYYY-MM-DD'))
      : '',
    arrival_date: tripInformation.arrival_date
      ? new Date(moment(tripInformation.arrival_date, 'YYYY-MM-DD'))
      : '',
  });

  const getTipInformation = tripInformation => ({
    planed_trip: getPlanedTrip(tripInformation.planed_trip || {}),
    interruption_date: tripInformation.interruption_date
      ? new Date(moment(tripInformation.interruption_date, 'YYYY-MM-DD'))
      : '',
    is_cancellation: tripInformation.is_cancellation || false,
    reason: tripInformation.reason || '',
  });

  const getAgency = agency => ({
    name: agency && agency.name ? agency.name : '',
  });

  const getLocation = location => ({
    country: location.country ? location.country : '',
    state: location.state ? location.state : '',
    locality: location.locality ? location.locality : '',
  });

  const getIncident = incident => ({
    start_date: incident.start_date
      ? new Date(moment(incident.start_date, 'YYYY-MM-DD'))
      : '',
    location: getLocation(incident.location || {}),
  });

  const getPrice = price => ({
    value: price && price.value ? price.value : '',
    currencyValue: price && price.currency ? price.currency : '',
  });

  const getAdditionalCost = (additionalCost, defaultLabel) => ({
    label:
      additionalCost && additionalCost.label
        ? additionalCost.label
        : defaultLabel,
    price: getPrice(additionalCost.price || {}),
    amount_claimed:
      defaultLabel === 'ACCOMODATION'
        ? {
            ...getPrice(additionalCost.amount_claimed || {}),
            checked:
              additionalCost && additionalCost.amount_claimed
                ? 'true'
                : 'false',
          }
        : getPrice(additionalCost.amount_claimed || {}),
  });

  const handleAdditionalCosts = costs => {
    let accomodationCosts, transportCosts;
    if (costs[0] && costs[0].label && costs[0].label === 'TRANSPORT') {
      transportCosts = costs[0];
      accomodationCosts = costs[1];
    } else {
      transportCosts = costs[1];
      accomodationCosts = costs[0];
    }

    return {
      additional_costs_accomodation: getAdditionalCost(
        accomodationCosts || {},
        'ACCOMODATION'
      ),
      additional_costs_transport: getAdditionalCost(
        transportCosts || {},
        'TRANSPORT'
      ),
    };
  };

  const getOtherInsuranceCovering = (covering, defaultLabel) => ({
    description: covering.description ? covering.description : defaultLabel,
    ...getPrice(covering.amount || {}),
    is_internal: covering.is_internal ? covering.is_internal : false,
    checked:
      covering.is_internal !== undefined
        ? String(!covering.is_internal)
        : 'false',
  });

  const handleOtherInsuranceCovering = coverings => {
    let otherCovering, transportCovering;
    if (
      coverings[0] &&
      coverings[0].description &&
      coverings[0].description === 'OTHER INSURANCE'
    ) {
      otherCovering = coverings[0];
      transportCovering = coverings[1];
    } else {
      otherCovering = coverings[1];
      transportCovering = coverings[0];
    }

    return {
      other_insurance_covering: getOtherInsuranceCovering(
        otherCovering || {},
        'OTHER INSURANCE'
      ),
      other_company_covering: getOtherInsuranceCovering(
        transportCovering || {},
        'TRANSPORTATION COMPANY'
      ),
    };
  };
  const getReimbursedTransportationInsurance = values => {
    let total =
      Number(values.additional_costs_accomodation.amount_claimed.value) || 0;
    total += Number(values.other_company_covering.value) || 0;
    total += Number(values.other_insurance_covering.value) || 0;

    return {
      value: Object.keys(completeClaim).length === 0 ? '' : total.toFixed(2),
      currency: values.additional_costs_transport.price.currency,
    };
  };

  const getAdditional = completeClaim => {
    if (completeClaim && completeClaim.additional_passengers)
      return completeClaim.additional_passengers.map(additional => ({
        first_name: additional.first_name,
        last_name: additional.last_name,
        birth_date: new Date(moment(additional.birth_date, 'YYYY-MM-DD')),
        gender: additional.gender,
        nationalities: additional.nationalities[0],
        relationship: additional.relationship,
        comment: additional.comment === null ? '' : additional.comment,
      }));
    else
      return [
        {
          first_name: '',
          last_name: '',
          birth_date: '',
          gender: '',
          nationalities: '',
          relationship: '',
          comment: '',
        },
      ];
  };

  const getHasAdditional = completeClaim => {
    return completeClaim && completeClaim.additional_passengers
      ? 'true'
      : 'false';
  };

  const initialValues = {
    affected_person: getAffectedPerson(person, affectedPerson),
    trip_information: getTipInformation(tripInformation),
    agency: getAgency(agency),
    incident: getIncident(incident),
    ...handleAdditionalCosts(additionalCosts),
    ...handleOtherInsuranceCovering(otherInsuranceCoverings),
    total_amount_claimed: getPrice(totalAmountClaimed),
    additional_passengers: getAdditional(completeClaim),
    hasAdditional: getHasAdditional(completeClaim),
  };

  initialValues.reimbursed_transportation_insurance =
    getReimbursedTransportationInsurance(initialValues);

  return initialValues;
};
