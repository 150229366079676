export const TEXT_DESCRIBE_CAUSES = {
  en_US: 'Briefly describe the causes that led to delay *',
  pt_BR: 'Descreva sucintamente o incidente *',
  es_ES: 'Describa brevemente el incidente *',
};

export const TEXT_TOTAL_AMOUT_CREDIT_CARD = {
  en_US:
    'Was the total amount of the trip paid with the credit card mentioned above? *',
  es_ES:
    '¿El importe total del viaje se pagó con la tarjeta de crédito mencionada anteriormente? *',
  pt_BR:
    'O valor total da viagem foi pago com o cartão de crédito mencionado acima? *',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'no',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Description of incident *',
  pt_BR: 'Descrição do incidente *',
  es_ES: 'Descripción del incidente *',
};
