export const TITLE_BENEFITS_CATEGORY_ALL = {
  en_US: 'All benefits',
  pt_BR: 'Todos os benefícios',
  es_ES: 'Todos los beneficios',
};

export const TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION = {
  en_US: 'Travel',
  pt_BR: 'Viagem',
  es_ES: 'Viaje',
};

export const TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION = {
  en_US: 'Purchase protection',
  pt_BR: 'Proteção de compra',
  es_ES: 'Protección de compra',
};

export const TITLE_BENEFITS_CATEGORY_LIFESTYLE = {
  en_US: 'Lifestyle',
  pt_BR: 'Estilo de Vida',
  es_ES: 'Estilo de Vida',
};

export const TITLE_BENEFITS_CATEGORY_CONCIERGE = {
  en_US: 'Concierge',
  pt_BR: 'Concierge',
  es_ES: 'Concierge',
};
