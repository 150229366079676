import { TRANSFERED_TYPE_OCT } from '../../PaymentInformation/OCTFlow/oct-const';

export const formatReimbursementToOCT = values => {
  const OCTReimbursement = {
    beneficiary_account: {
      bank: {},
      holder: {
        person: values.policy.person,
        address: values.policy.address,
        phone: values.policy.phone,
        registrations: values.policy.person_registrations,
      },
      preferred_transfer_type: TRANSFERED_TYPE_OCT,
      oct_information: {
        palla_payment_method_id: values.reimbursement.paymentIdOCT,
      },
    },
    total_amount_reimbursed: {
      value: 0,
      currency: 'USD',
    },
  };

  return OCTReimbursement;
};
