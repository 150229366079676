import ErrorIcon from '../../assets/iconsV2/Chip/ErrorIcon';
import InfoIcon from '../../assets/iconsV2/Chip/InfoIcon';
import SuccessIcon from '../../assets/iconsV2/Chip/SuccessIcon';
import WarningIcon from '../../assets/iconsV2/Chip/WarningIcon';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import { ThemeColorsGeneric } from '../../themes/ThemeContextParent/themes';
import styles from './Chip.module.scss';

export const configIcon = (themeGenericColors: ThemeColorsGeneric) => ({
  POSITIVE: SuccessIcon,
  NEGATIVE: ErrorIcon,
  WARNING: WarningIcon,
  INFO: InfoIcon,
  DISABLED: () => <InfoIcon color={themeGenericColors.gray_60} />,
});

export type ChipType = keyof ReturnType<typeof configIcon>;

export interface ChipProps {
  icon?: boolean;
  label: string;
  type: ChipType;
}

const Chip = ({ icon, label, type }: ChipProps) => {
  const { getColors } = useTheme();
  const colors = getColors();
  const Icon = configIcon(colors.genericColors)[type];

  return (
    <div className={`${styles.chipContainer} ${styles[type]}`}>
      {icon && <Icon />}
      <span>{label}</span>
    </div>
  );
};

export default Chip;
