import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import styles from './PersonalDocuments.module.scss';
import * as translations from './intl';
import TitleSectionProfile from '../../../../components/TitleSectionProfile/TitleSectionProfile';
import PersonalDocumentsItem from './PersonalDocumentsItem/PersonalDocumentsItem';

const PersonalDocuments = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <div id="personalDocuments" className={styles.boxContactDetails}>
      <TitleSectionProfile
        title={intl.TITLE_PERSONAL_DOCUMENTS}
        subtitle={intl.SUBTITLE_PERSONAL_DOCUMENTS}
      />

      <PersonalDocumentsItem />

      <div className={styles.disclaimer}>* {intl.DISCLAIMER_TEXT}</div>
    </div>
  );
};

export default PersonalDocuments;
