/* eslint-disable no-console */
/* eslint-disable indent */
import React, { useEffect, useContext } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { deviceDetect } from 'react-device-detect';
import { StateContext } from '../StateContextParent/StateContextParent';
import { communicationDeviceService } from '../../services';
import { IntlContext } from '../../intl';
import { userServices } from '../../services/index';
import { AppInsightTrackContext } from '../AppInsightTrackContextParent/AppInsightTrackContextParent';

const askForPermissioToReceiveNotifications = async (
  customerId,
  idiom,
  trackEventUserAction
) => {
  try {
    const messaging = firebase.messaging();
    if (messaging) {
      let token = '';
      Notification.requestPermission()
        .then(async function (response) {
          if (response !== 'granted') return;

          token = await messaging.getToken({
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
          });
          trackEventUserAction('#### USER TOKEN ####', token);

          const deviceDetectedInfo = deviceDetect();
          let devices = (
            await communicationDeviceService.getDevices(customerId)
          ).data;

          const devicesExist = devices.find(
            device => device.external_communication_device_id === token
          );

          trackEventUserAction('#### USER  DEVICES ####', {
            deviceDetectedInfo,
            devicesExist,
          });

          if (!devicesExist && deviceDetectedInfo) {
            const {
              osVersion,
              osName,
              browserName,
              isMobile,
              vendor,
              model,
              os,
            } = deviceDetectedInfo;

            let description = '';

            if (isMobile) {
              description = `${vendor} / ${model} ${os} `;
            } else {
              description = `${browserName} / ${osName} ${osVersion}`;
            }

            const data = {
              external_communication_device_id: token,
              description,
            };

            trackEventUserAction('#### WILL CREATE DEVICE ####', null);
            await communicationDeviceService.createDevice(customerId, {
              ...data,
            });
            trackEventUserAction('#### (SAVE DEVICE) USER  ####', { ...data });

            devices = (await communicationDeviceService.getDevices(customerId))
              .data;
            trackEventUserAction('#### (GET DEVICES) USER  ####', { devices });

            const { data: data_customer_notification } =
              await userServices.getPreferences(customerId);
            const customer_notification = data_customer_notification.find(
              item => item.related_to === 'CUSTOMER_NOTIFICATION'
            );

            trackEventUserAction('#### USER PREFERENCES  ####', {
              data_customer_notification,
            });

            if (customer_notification) {
              await userServices.optOutCommunication(
                customerId,
                customer_notification.communication_subscription_id
              );
            }

            await userServices.optInCommunication(
              customerId,
              'CUSTOMER_NOTIFICATION',
              'PUSH_NOTIFICATION',
              devices.map(device => device.communication_device_id)
            );
          }

          //listening to focus page
          messaging.onMessage(payload => {
            const { notification } = payload;
            navigator.serviceWorker
              .getRegistration('/firebase-cloud-messaging-push-scope')
              .then(registration => {
                registration.showNotification(notification.title, notification);
              });
          });
        })
        .catch(function (err) {
          trackEventUserAction('#### (CATCH DEVICE) USER  ####', err);
        });

      return token;
    }
  } catch (error) {
    trackEventUserAction('#### (CATCH GENERAL DEVICE) USER  ####', error);
  }
};

const PushNotificationPermission = () => {
  const { utils } = useContext(StateContext);
  const { idiom } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const isLogged = utils && utils.isUserLogged();
  const completedRegistration = utils && utils.getBin();

  useEffect(() => {
    if (isLogged && completedRegistration) {
      const customerId = utils && utils.getCn();
      askForPermissioToReceiveNotifications(
        customerId,
        idiom,
        trackEventUserAction
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, completedRegistration]);

  return <></>;
};

export default PushNotificationPermission;
