import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../ProtectedVacations.module.scss';
import * as translations from './intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import sanitize from '../../../../../../../../utils/sanitize';
const TripArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div
          className={`${styles.incidentPeriodArea}  ${styles.fontGray12} row`}
        >
          <div className="col-12 col-md-4">
            <Calendar
              label={intl.TEXT_CANCELLATION_DATE}
              name="trip_information.interruption_date"
              onChange={handleChange}
              value={values.trip_information.interruption_date}
              touched={
                touched.trip_information &&
                touched.trip_information.interruption_date
              }
              error={
                errors.trip_information &&
                errors.trip_information.interruption_date
              }
              setFieldTouched={setFieldTouched}
              dataTestid="inputTripInterruption"
            />
          </div>

          <div className="col-12 col-md-4">
            <Calendar
              label={intl.TEXT_TRIP_START_DATE}
              name="trip_information.planed_trip.departure_date"
              onChange={handleChange}
              value={values.trip_information.planed_trip.departure_date}
              touched={
                touched.trip_information &&
                touched.trip_information.planed_trip &&
                touched.trip_information.planed_trip.departure_date
              }
              error={
                errors.trip_information &&
                errors.trip_information.planed_trip &&
                errors.trip_information.planed_trip.departure_date
              }
              setFieldTouched={setFieldTouched}
              minDate={
                values.trip_information.interruption_date
                  ? values.trip_information.interruption_date
                  : new Date()
              }
              dataTestid="inputTripPlanedDepartureDate"
            />
          </div>
          <div className="col-12 col-md-4">
            <Calendar
              label={intl.TEXT_TRIP_END_DATE}
              name="trip_information.planed_trip.arrival_date"
              onChange={handleChange}
              minDate={values.trip_information.planed_trip.departure_date}
              value={values.trip_information.planed_trip.arrival_date}
              touched={
                touched.trip_information &&
                touched.trip_information.planed_trip &&
                touched.trip_information.planed_trip.arrival_date
              }
              error={
                errors.trip_information &&
                errors.trip_information.planed_trip &&
                errors.trip_information.planed_trip.arrival_date
              }
              setFieldTouched={setFieldTouched}
              dataTestid="inputTripArrivalDate"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Calendar
              label={intl.TEXT_DATE_INCIDENT}
              name="incident.start_date"
              onChange={handleChange}
              value={values.incident.start_date}
              touched={touched.incident && touched.incident.start_date}
              error={errors.incident && errors.incident.start_date}
              setFieldTouched={setFieldTouched}
              dataTestid="inputIncidentDate"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              value={values.agency.name}
              label={intl.TEXT_FLIGHT_NUMBER}
              name="agency.name"
              type="text"
              onChange={handleChange}
              onBlur={handleBlur}
              textTooBig
              maxLength="100"
              dataTestid="inputAgencyName"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-12">
            <Select
              label={intl.TEXT_CANCELLATION_REASON}
              helpText={intl.TIP_BRIEF_DESCRIBE_INCIDENT}
              name="trip_information.reason"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.trip_information.reason}
              touched={
                touched.trip_information && touched.trip_information.reason
              }
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.TEXT_CANCELLATION_REASON}</option>${intl.CANCELLATION_REASON_OPTIONS}`
                ),
              }}
              error={errors.trip_information && errors.trip_information.reason}
              maxLength="255"
              dataTestid="inputTripReason"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripArea;
