import LOGO_VISA_MASTER from '../../assets/images/marketing/logo_visa_master.svg';
import LOGO_VISA_AFLUENT from '../../assets/images/marketing/logo_visa_afluent.svg';
import PlayStoreEN from '../../assets/images/downloadApp/stores/ENG/download-android-eng.png';
import PlayStoreES from '../../assets/images/downloadApp/stores/SP/download-android-esp.png';
import PlayStorePT from '../../assets/images/downloadApp/stores/PT/download-android-pt.png';
import AppStoreEN from '../../assets/images/downloadApp/stores/ENG/download-ios-eng.png';
import AppStoreES from '../../assets/images/downloadApp/stores/SP/download-ios-esp.png';
import AppStorePT from '../../assets/images/downloadApp/stores/PT/download-ios-pt.png';

import QRCODE_AFFLUENT_PT from '../../assets/images/marketing/banner_marketing/qrcode/affluent/qrcode-pt.png';
import QRCODE_AFFLUENT_ES from '../../assets/images/marketing/banner_marketing/qrcode/affluent/qrcode-es.png';
import QRCODE_AFFLUENT_EN from '../../assets/images/marketing/banner_marketing/qrcode/affluent/qrcode-eng.png';

import QRCODE_MASTER_PT from '../../assets/images/marketing/banner_marketing/qrcode/master/qrcode-pt.png';
import QRCODE_MASTER_ES from '../../assets/images/marketing/banner_marketing/qrcode/master/qrcode-es.png';
import QRCODE_MASTER_EN from '../../assets/images/marketing/banner_marketing/qrcode/master/qrcode-eng.png';

export const TEXT_BANNER_APP = {
  en_US: afluent =>
    `Download your new app and enjoy all <span> the <img alt="Visa" src="${
      afluent ? LOGO_VISA_AFLUENT : LOGO_VISA_MASTER
    }"/> benefits</span>`,
  es_ES: afluent =>
    `Descarga tu nueva app y disfruta  de todos <span> los beneficios <img alt="Visa" src="${
      afluent ? LOGO_VISA_AFLUENT : LOGO_VISA_MASTER
    }"/></span>`,
  pt_BR: afluent =>
    `Baixe seu novo app e aproveite  todos os <span> benefícios <img alt="Visa" src="${
      afluent ? LOGO_VISA_AFLUENT : LOGO_VISA_MASTER
    }"/>`,
};

export const IMAGE_APP_STORE = {
  en_US: AppStoreEN,
  es_ES: AppStoreES,
  pt_BR: AppStorePT,
};

export const IMAGE_PLAY_STORE = {
  en_US: PlayStoreEN,
  es_ES: PlayStoreES,
  pt_BR: PlayStorePT,
};

export const QR_CODE = {
  en_US: afluent =>
    `
      ${afluent ? QRCODE_AFFLUENT_EN : QRCODE_MASTER_EN}
    `,
  es_ES: afluent =>
    `
      ${afluent ? QRCODE_AFFLUENT_ES : QRCODE_MASTER_ES}
    `,
  pt_BR: afluent =>
    `
      ${afluent ? QRCODE_AFFLUENT_PT : QRCODE_MASTER_PT}
    `,
};

export const ALT_IMAGE_SCAN_ME = {
  en_US: 'Scan the QR Code to download the Visa Benefits app',
  es_ES: 'Escanee el código QR para descargar la aplicación Visa Benefits',
  pt_BR: 'Escanear o QR Code para baixar o aplicativo Visa Benefits',
};
