export const fileExtensions = [
  {
    Extension: '.jpeg',
    Kind_document: 'JPEG images',
    MIME_Type: 'image/jpeg',
  },
  {
    Extension: '.jpg',
    Kind_document: 'JPEG images',
    MIME_Type: 'image/jpeg',
  },
  {
    Extension: '.bmp',
    Kind_document: 'Windows OS/2 Bitmap Graphics',
    MIME_Type: 'image/bmp',
  },
  {
    Extension: '.doc',
    Kind_document: 'Microsoft Word',
    MIME_Type: 'application/msword',
  },
  {
    Extension: '.docx',
    Kind_document: 'Microsoft Word (OpenXML)',
    MIME_Type:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    Extension: '.gif',
    Kind_document: 'Graphics Interchange Format (GIF)',
    MIME_Type: 'image/gif',
  },
  {
    Extension: '.ods',
    Kind_document: 'OpenDocument spreadsheet document',
    MIME_Type: 'application/vnd.oasis.opendocument.spreadsheet',
  },
  {
    Extension: '.odt',
    Kind_document: 'OpenDocument text document',
    MIME_Type: 'application/vnd.oasis.opendocument.text',
  },
  {
    Extension: '.pdf',
    Kind_document: 'Adobe Portable Document Format (PDF)',
    MIME_Type: 'application/pdf',
  },
  {
    Extension: '.png',
    Kind_document: 'Portable Network Graphics',
    MIME_Type: 'image/png',
  },
  {
    Extension: '.ppt',
    Kind_document: 'Microsoft PowerPoint',
    MIME_Type: 'application/vnd.ms-powerpoint',
  },
  {
    Extension: '.pptx',
    Kind_document: 'Microsoft PowerPoint (OpenXML)',
    MIME_Type:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  },
  {
    Extension: '.psd',
    Kind_document: 'Photoshop Document',
    MIME_Type: 'image/vnd.adobe.photoshop',
  },
  {
    Extension: '.tif',
    Kind_document: 'Tagged Image File Format (TIFF)',
    MIME_Type: 'image/tiff',
  },
  {
    Extension: '.tiff',
    Kind_document: 'Tagged Image File Format (TIFF)',
    MIME_Type: 'image/tiff',
  },
  {
    Extension: '.txt',
    Kind_document: 'Text, (generally ASCII or ISO 8859-n)',
    MIME_Type: 'text/plain',
  },
  {
    Extension: '.xls',
    Kind_document: 'Microsoft Excel',
    MIME_Type: 'application/vnd.ms-excel',
  },
  {
    Extension: '.xlsx',
    Kind_document: 'Microsoft Excel (OpenXML)',
    MIME_Type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  {
    Extension: '.zip',
    Kind_document: 'ZIP archive',
    MIME_Type: 'application/zip',
  },
  {
    Extension: '.zip',
    Kind_document: 'ZIP archive',
    MIME_Type: 'application/x-zip',
  },
  {
    Extension: '.zip',
    Kind_document: 'ZIP archive',
    MIME_Type: 'application/x-zip-compressed',
  },
  {
    Extension: '.odg',
    Kind_document: 'OpenDocument Graphics',
    MIME_Type: 'application/vnd.oasis.opendocument.graphics',
  },
];

export const extensions = fileExtensions.map(item => item.MIME_Type);
