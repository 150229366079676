import { pt_countries } from '../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../intl/es_countries';

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const FIELD_COUNTRY = {
  en_US: 'Rent Country *',
  pt_BR: 'País de origem *',
  es_ES: 'País del Alquiler *',
};

export const VALIDATE_ORIGIN_COUNTRY = {
  en_US: 'Rental country *',
  pt_BR: 'Selecione o país de origem *',
  es_ES: 'País de alquiler *',
};

export const VALIDATE_DESTINATION_COUNTRY = {
  en_US: 'Select destination country *',
  pt_BR: 'Selecione o país de destino *',
  es_ES: 'Seleccionar país de destino *',
};

export const FIELD_CITY = {
  en_US: 'Rental city *',
  pt_BR: 'Cidade de origem *',
  es_ES: 'Ciudad de alquiler *',
};

export const VALIDATE_ORIGIN_CITY = {
  en_US: 'Select origin city *',
  pt_BR: 'Selecione a cidade de origem *',
  es_ES: 'Seleccionar cidad de origen *',
};

export const FIELD_DESTINATION_CITY = {
  en_US: 'Destination city *',
  pt_BR: 'Cidade de destino *',
  es_ES: 'Cidad de destino *',
};

export const VALIDATE_DESTINATION_CITY = {
  en_US: 'Select destination City *',
  pt_BR: 'Selecione a cidade de destino *',
  es_ES: 'Seleccionar ciudad de destino *',
};

export const BOOKING_NUMBER = {
  en_US: 'Booking number *',
  pt_BR: ' *',
  es_ES: 'Número de reserva *',
};

export const FIELD_VALIDITY_VALUE_INTERNACIONAL = {
  en_US: 'International trip',
  pt_BR: 'Viajem internacional',
  es_ES: 'Viaje internacional',
};

export const FIELD_VALIDITY_VALUE_DOMESTIC = {
  en_US: 'Domestic trip',
  pt_BR: 'Viagem nacional',
  es_ES: 'Viaje nacional',
};

export const FIELD_VALIDITY = {
  en_US: 'Validity',
  pt_BR: 'Validade',
  es_ES: 'Validez',
};

export const COVERAGE_DAYS = {
  en_US:
    'The coverage of this insurance is valid to 31 consecutive days from the initial rental date.',
  pt_BR: '',
  es_ES:
    'La cobertura del beneficio es válida por 31 días consecutivos a partir de la fecha de inicio del alquiler.',
};
