/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../components/Input/Input';
import { pt_BR } from '../../../../../../../intl/idioms';
import Select from '../../../../../../../components/Select/Select';
import sanitize from '../../../../../../../utils/sanitize';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import {
  mask4FirstDigits,
  removeRequiredCharLabel,
} from '../../../../../../../utils/stringUtils';
import styles from './RegistrationsArea.module.scss';

const RegistrationsArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { utils } = useContext(StateContext);
  const {
    values,
    initialValues,
    handleChange,
    touched,
    handleBlur,
    errors,
    setFieldValue,
    isCertificate,
    isTelemedicinaBR,
    setFieldTouched,
    isCardHolder,
    disableProspectFields,
  } = props;

  const selectedCard = utils.getSelectedCard();
  const isCTA = isCertificate && selectedCard.name.includes('CTA');
  const isBrasil = idiom === pt_BR;
  const isBrasilAndNotCTA = isBrasil && !isCTA;
  const indexRolac = isBrasil && !isTelemedicinaBR ? 1 : 0;

  const conditionBRShowFields = isBrasil ? true : values.nationalities === 'BR';

  const foreignersWithBrazilianCard = isBrasil && values.nationalities !== 'BR';

  const value_person_registration_rolac =
    values.person_registrations &&
    values.person_registrations[indexRolac] &&
    values.person_registrations[indexRolac].registration_type;

  const registrationType =
    value_person_registration_rolac === 'ID_CARD'
      ? intl.PLACEHOLDER_NATIONAL_ID
      : intl.PLACEHOLDER_PASSPORT;

  const checkSameRegistrationValue = index => {
    if (
      values.person_registrations &&
      values.person_registrations[index] &&
      values.person_registrations[index].registration_type ===
        initialValues.person_registrations[index].registration_type &&
      initialValues.person_registrations[index].value !== ''
    ) {
      return (
        values.person_registrations[index].value ===
        initialValues.person_registrations[index].value
      );
    } else {
      return false;
    }
  };

  const getInitialRegistrationValue = index => {
    if (
      values.person_registrations[index].registration_type ===
      initialValues.person_registrations[index].registration_type
    ) {
      return initialValues.person_registrations[index].value;
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (!isBrasil) {
      if (
        values.nationalities !== 'BR' &&
        values.person_registrations[0].registration_type === 'CPF'
      ) {
        setFieldValue('person_registrations[0].registration_type', 'PASSPORT');
      } else if (
        values.nationalities === 'BR' &&
        values.person_registrations[0].registration_type !== 'CPF'
      ) {
        setFieldValue('person_registrations[0].registration_type', 'CPF');
      }
    }
  }, [values.nationalities, values.person_registrations, setFieldValue]);

  useEffect(() => {
    if (
      values.person_registrations[0].registration_type ===
      initialValues.person_registrations[0].registration_type
    ) {
      setFieldValue(
        `person_registrations[${0}].value`,
        getInitialRegistrationValue(0)
      );
    } else {
      setFieldValue(`person_registrations[${0}].value`, '');
    }
  }, [values.person_registrations[0].registration_type]);

  useEffect(() => {
    if (isBrasil && !isTelemedicinaBR) {
      if (
        initialValues.person_registrations[indexRolac] &&
        initialValues.person_registrations[indexRolac].registration_type &&
        initialValues.person_registrations[indexRolac].registration_type ===
          value_person_registration_rolac
      ) {
        setFieldValue(
          `person_registrations[${indexRolac}].value`,
          getInitialRegistrationValue(indexRolac)
        );
      } else {
        setFieldValue(`person_registrations[${indexRolac}].value`, '');
      }
    }
  }, [value_person_registration_rolac]);

  const idOptionROLAC = (
    <div className="col-12 col-sm-12 col-md-6" style={{ padding: 0 }}>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-12 col-sm-12 col-md-6">
          <Select
            label={
              isBrasilAndNotCTA
                ? intl.LABEL_IDENTIFICATION
                : intl.LABEL_IDENTIFICATION + ' *'
            }
            value={value_person_registration_rolac}
            name={`person_registrations[${indexRolac}].registration_type`}
            onChange={handleChange}
            touched={
              touched.person_registrations &&
              touched.person_registrations[indexRolac] &&
              touched.person_registrations[indexRolac].registration_type
            }
            onBlur={handleBlur}
            error={
              errors.person_registrations &&
              errors.person_registrations[indexRolac] &&
              errors.person_registrations[indexRolac].registration_type
            }
            shouldBeSmaller={intl.PLACEHOLDER_NATIONAL_ID === registrationType}
            disabled={disableProspectFields}
          >
            <option value="">
              {isBrasilAndNotCTA
                ? intl.LABEL_IDENTIFICATION
                : intl.LABEL_IDENTIFICATION + ' *'}
            </option>
            <option value="PASSPORT">{intl.LABEL_PASSPORT}</option>
            <option value="ID_CARD">{intl.LABEL_NATIONAL_ID}</option>
          </Select>
        </div>
        <div className="col-12 col-sm-12 col-md-6">
          <Input
            value={
              checkSameRegistrationValue(indexRolac)
                ? mask4FirstDigits(
                    values.person_registrations[indexRolac].value
                  )
                : values.person_registrations &&
                  values.person_registrations[indexRolac] &&
                  values.person_registrations[indexRolac].value
            }
            label={
              isBrasilAndNotCTA
                ? removeRequiredCharLabel(registrationType)
                : registrationType
            }
            name={`person_registrations[${indexRolac}].value`}
            dataTestid="InputCHRegistrationValue-passport"
            type="text"
            onBlur={e => {
              handleBlur(e);
              if (e.target.value === '' && isCardHolder) {
                setFieldValue(
                  `person_registrations[${indexRolac}].value`,
                  getInitialRegistrationValue(indexRolac)
                );
                setFieldTouched(
                  `person_registrations[${indexRolac}].value`,
                  true
                );
              }
            }}
            onFocus={e => {
              if (checkSameRegistrationValue(indexRolac)) {
                setFieldValue(`person_registrations[${indexRolac}].value`, '');
                setFieldTouched(
                  `person_registrations[${indexRolac}].value`,
                  true
                );
              }
            }}
            tooltipText={
              value_person_registration_rolac === 'ID_CARD'
                ? intl.TEXT_ALERT_NATIONAL_ID
                : intl.TEXT_ALERT_PASSPORT
            }
            mask={
              checkSameRegistrationValue(indexRolac)
                ? 'maskedPassaportWithoutFirst4'
                : 'maskedPassaport'
            }
            onChange={handleChange}
            touched={
              touched.person_registrations &&
              touched.person_registrations[indexRolac] &&
              touched.person_registrations[indexRolac].value
            }
            error={
              errors.person_registrations &&
              errors.person_registrations[indexRolac] &&
              errors.person_registrations[indexRolac].value
            }
            disabled={disableProspectFields}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`row  ${styles.section}`}>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          label={intl.NATIONALITY_LABEL}
          value={values.nationalities}
          name="nationalities"
          dataTestid="InputClaimCHNationalities"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(intl.NATIONALITY_OPTIONS),
          }}
          touched={touched.nationalities}
          onBlur={handleBlur}
          error={errors.nationalities}
        />
      </div>
      {conditionBRShowFields ? (
        <>
          <div className="col-12 col-sm-12 col-md-6">
            <Input
              value={
                checkSameRegistrationValue(0)
                  ? mask4FirstDigits(values.person_registrations[0].value)
                  : values.person_registrations[0].value
              }
              label={isCTA ? intl.LABEL_CPF : intl.LABEL_CPF_TYPE}
              mask={
                checkSameRegistrationValue(0)
                  ? 'maskedCPFWithoutFirst4'
                  : 'maskedCPF'
              }
              name={`person_registrations[0].value`}
              type="text"
              dataTestid="InputCHRegistrationValue"
              onChange={handleChange}
              onBlur={e => {
                handleBlur(e);
                if (e.target.value === '' && isCardHolder) {
                  setFieldValue(
                    `person_registrations[0].value`,
                    getInitialRegistrationValue(0)
                  );
                  setFieldTouched(`person_registrations[0].value`, true);
                }
              }}
              onFocus={e => {
                if (checkSameRegistrationValue(0)) {
                  setFieldValue(`person_registrations[0].value`, '');
                  setFieldTouched(`person_registrations[0].value`, true);
                }
              }}
              tooltipText={`${intl.TEXT_ALERT_CPF} ${
                foreignersWithBrazilianCard
                  ? intl.TEXT_ALERT_CPF_FOREIGNERS
                  : ''
              }`}
              touched={
                touched.person_registrations &&
                touched.person_registrations[0] &&
                touched.person_registrations[0].value
              }
              error={
                errors.person_registrations &&
                errors.person_registrations[0] &&
                errors.person_registrations[0].value
              }
              disabled={disableProspectFields}
            />
          </div>
          {isBrasil && <>{idOptionROLAC}</>}
        </>
      ) : (
        <>{idOptionROLAC}</>
      )}
    </div>
  );
};

export default RegistrationsArea;
