export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor insira uma data válida.',
  es_ES: 'Por favor introduzca una fecha valida.',
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATE_HOUR_VALID = {
  en_US: 'Please enter a value between 00:00 and 23:59.',
  pt_BR: 'Por favor, entre um valor entre 00:00 e 23:59.',
  es_ES: 'Por favor, introduzca un valor entre 00:00 y 23:59.',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
