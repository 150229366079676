import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';

const ErrorIcon = props => {
  const { modal_error = false } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = CONFIG_ICON[theme];
  const secondaryColor = modal_error
    ? config_icon.error
    : config_icon.secondary;
  const primaryColor = modal_error ? config_icon.error : config_icon.primary;
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        style={{
          fill: 'none',
          stroke: secondaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
        d="m7 7 10 10M17 7 7 17"
      />
      <circle
        cx={12}
        cy={12}
        r={10.8}
        style={{
          fill: 'none',
          stroke: primaryColor,
          strokeWidth: 2,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  );
};

export default ErrorIcon;
