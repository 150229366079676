import { certificatesServices } from '../../../../../../services';

export const fomartAutoRental = (data, props) => {
  const { trackEventUserAction } = props;
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- FORMAT AUTO RENTAL',
    data
  );
  const {
    values: { tripInformation },
  } = props;
  data.car_rental_information = { ...tripInformation };
  if (data.car_rental_information.is_international_trip === 'true') {
    delete data.car_rental_information.city_origin;
    delete data.car_rental_information.city_destination;
    data.car_rental_information.country_destination =
      data.car_rental_information.country_origin;
  } else {
    delete data.car_rental_information.country_origin;
    delete data.car_rental_information.country_destination;
    data.car_rental_information.city_destination =
      data.car_rental_information.city_origin;
  }
  trackEventUserAction(
    '#### (CERTIFICATE) CLICOU NO SUBMIT -- VAI CRIAR AUTO RENTAL',
    true
  );
  return certificatesServices.postCarRental(data, props.idiomForApi());
};
