export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';
export const CARD_HOLDER_Q_VALUE = 'Cardholder';

export const DAMAGE_VALUE = 'Damage';
export const THEFT_VALUE = 'Theft';
export const NOT_DELIVERED = 'Undelivered';
export const DAMAGE_DELIVERY = 'DeliveryDamage';
