import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const ExpenseArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const total_amount_claimed = claimDetails.total_amount_claimed;

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_REIMBURSERD}
          value={safeLoad(total_amount_claimed.value)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_CURRENCY}
          value={getCurrencyNameByInitials(
            total_amount_claimed.currency,
            idiom
          )}
        />
      </div>
    </>
  );
};

export default ExpenseArea;
