export const TITLE_MAIN_TRAVELER = {
  en_US: 'Main traveler',
  pt_BR: 'Viajante principal',
  es_ES: 'Viajero principal',
};

export const TITLE_ADDITIONAL_TRAVELERS = {
  en_US: 'Additional travellers',
  pt_BR: 'Viajantes adicionais',
  es_ES: 'Viajeros adicionales',
};

export const TITLE_TRIP_INFORMATION = {
  en_US: 'Trip information',
  pt_BR: 'Informações de viagem',
  es_ES: 'Información de viaje',
};

export const SOMETHING_WENT_WRONG = {
  en_US: 'Something went wrong',
  pt_BR: 'Algo deu errado',
  es_ES: 'Algo salió mal',
};

export const CHANGE_AGE = {
  en_US: `Considering the quote made above, there was a change in the age of one or more passengers.
    This change may have affected the amount of coverage. Please check the values below:`,
  pt_BR: `Considerando a citação feita acima, houve alteração na idade de um ou mais passageiros.
    Essa alteração pode ter afetado o valor da cobertura. Confira os valores abaixo:`,
  es_ES: `Considerando la cotización realizada anteriormente, hubo un cambio en la edad de uno o más pasajeros.
    Este cambio puede haber afectado el monto de la cobertura. Por favor, compruebe los valores a continuación:`,
};

export const IMPACTED_COVERAGE_AMOUNT = {
  en_US: 'Do you want to continue considering the current value displayed?',
  pt_BR: 'Deseja continuar considerando o valor atual exibido?',
  es_ES: '¿Desea continuar considerando el valor actual que se muestra?',
};

export const CHANGE_VALUE_OF_THE_PLAN = {
  en_US: planSelectedWithNewQuote =>
    `Possible change in the plan "${planSelectedWithNewQuote.Product.Name}"`,
  pt_BR: planSelectedWithNewQuote =>
    `Possível mudança no plano "${planSelectedWithNewQuote.Product.Name}"`,
  es_ES: planSelectedWithNewQuote =>
    `Posible cambio de plan "${planSelectedWithNewQuote.Product.Name}"`,
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  pt_BR: 'Próximo passo',
  es_ES: 'Próximo paso',
};

export const YES_I_WANT = {
  en_US: 'Yes I want',
  pt_BR: 'Sim eu quero',
  es_ES: 'Si yo quiero',
};

export const BTN_EDIT = {
  en_US: 'Edit',
  pt_BR: 'Editar',
  es_ES: 'Editar',
};

export const SAVING_INFORMATIONS = {
  en_US: 'Saving new information',
  pt_BR: 'Salvando novas informações',
  es_ES: 'Guardar nueva información',
};

export const BACK_TO_QUOTE = {
  en_US: 'Quote again',
  pt_BR: 'Cotar novamente',
  es_ES: 'Cotizar de nuevo',
};

export const INITIAL_VALUE = {
  en_US: 'Initial value',
  pt_BR: 'Valor inicial',
  es_ES: 'Valor inicial',
};

export const CURRENT_VALUE = {
  en_US: 'Current value',
  pt_BR: 'Valor atual',
  es_ES: 'Valor actual',
};

export const PASSENGERS_UP_69_YEARS = {
  en_US: 'Passengers up to 69 years - ',
  pt_BR: 'Passageiros até 69 anos - ',
  es_ES: 'Pasajeros hasta 69 años - ',
};

export const PASSENGERS_70_YEARS_OR_OLDER = {
  en_US: 'Passengers 70 years or older - ',
  pt_BR: 'Passageiros de 70 anos ou mais - ',
  es_ES: 'Pasajeros de 70 años o más - ',
};
