import React, { useContext, useEffect, useState } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getIncidentDate,
  getIncidentDescription,
  getTotalAmountClaimed,
} from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const IncidentArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  const [incident, setIncident] = useState({});
  const [totalAmountClaimed, setTotalAmountClaimed] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);

    const totalAmountClaimed =
      (claimDetails && claimDetails.total_amount_claimed) || {};
    setTotalAmountClaimed(totalAmountClaimed);
  }, [claimDetails]);

  return (
    <>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_INCIDENT_DATE}
          value={getIncidentDate(incident, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_AMOUNT_CLAIMED}
          value={getTotalAmountClaimed(totalAmountClaimed, idiomForApi())}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DESCRIPTION}
          value={getIncidentDescription(incident)}
        />
      </div>
    </>
  );
};

export default IncidentArea;
