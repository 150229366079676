import React, { useState, useEffect } from 'react';
import MenuCards from './MenuCards/MenuCards';

import { INDIVIDUAL } from './consts';
import styles from './Consumers.module.scss';
import Cards from './Cards/Cards';
import CardsMobile from './Cards/CardsMobile';

const Consumers = () => {
  const [selected, setSelected] = useState('ALL');
  const [clientType, setClientType] = useState(INDIVIDUAL);
  const [mobile, setMobile] = useState(null);

  const checkMobileByWidth = () => {
    if (window.outerWidth > 992) {
      setMobile(false);
    } else {
      setMobile(true);
    }
  };

  useEffect(() => {
    checkMobileByWidth();
    window.addEventListener('resize', checkMobileByWidth);
    return () => {
      window.removeEventListener('resize', checkMobileByWidth);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={`row ${styles.content}`}>
        <MenuCards
          clientType={clientType}
          setClientType={setClientType}
          setSelected={setSelected}
          selected={selected}
        />
        {mobile === true && (
          <CardsMobile clientType={clientType} data-testid="cardsMobile" />
        )}
        {mobile === false && (
          <Cards
            selected={selected}
            clientType={clientType}
            data-testid="cards"
          />
        )}
      </div>
    </div>
  );
};

export default Consumers;
