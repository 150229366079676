export const TITLE = {
  en_US: 'Communication preferences',
  es_ES: 'Preferencias de comunicación',
  pt_BR: 'Preferências de Comunicação',
};

export const SUBTILE = {
  en_US: 'Choose to Opt in or Out on our communication services.',
  es_ES: 'Elija participar o no en nuestros servicios de comunicación.',
  pt_BR: 'Opte por aderir ou não aos nossos serviços de comunicação.',
};

export const TEXT_EMAILS_TITLE = {
  en_US: 'PRODUCT AND MARKETING',
  es_ES: 'MARKETING Y PRODUCTO',
  pt_BR: 'MARKETING E PRODUTO',
};

export const TEXT_EMAILS_DESCRIPTION = {
  en_US:
    'You will receive marketing emails, such as news about Visa products, events and informational emails. You may unsubscribe at any time by changing your preference. For more information on how Visa protects your data, please review our Privacy Policy.',
  es_ES:
    'Recibirás correos electrónicos de marketing, como noticias sobre productos Visa, eventos y correos electrónicos informativos. Puedes darte de baja en cualquier momento cambiando tu preferencia. Para obtener más información sobre cómo Visa protege tus datos, consulte nuestra Política de privacidad.',
  pt_BR:
    'Você receberá e-mails de marketing, como notícias sobre produtos Visa, eventos e e-mails informativos. Você pode cancelar a assinatura a qualquer momento, alterando sua preferência. Para obter mais informações sobre como a Visa protege seus dados, consulte nossa Política de Privacidade.',
};

export const TEXT_PROMOTIONS_TITLE = {
  en_US: 'OFFERS AND PROMOTIONS',
  es_ES: 'OFERTAS Y PROMOCIONES',
  pt_BR: 'OFERTAS E PROMOÇÕES',
};

export const TEXT_PROMOTIONS_DESCRIPTION = {
  en_US: `You will receive emails with Visa partner offers and promotions. You may unsubscribe at any time by changing your preference. For more information on how Visa protects your data, please review our Privacy Policy.`,
  es_ES: `Recibirás correos electrónicos con ofertas y promociones Visa y sus parteners. Puedes darte de baja en cualquier momento cambiando tu preferencia. Para obtener más información sobre cómo Visa protege tus datos, consulte nuestra Política de privacidad.`,
  pt_BR: `Você receberá e-mails com ofertas e promoções de parceiros Visa. Você pode cancelar a assinatura a qualquer momento, alterando sua preferência. Para obter mais informações sobre como a Visa protege seus dados, consulte nossa Política de Privacidade.`,
};

export const TEXT_POLICY_TITLE = {
  en_US: 'TIPS: Tips (Policy and community)',
  es_ES: 'CONSEJOS: Consejos (política y comunidad)',
  pt_BR: 'DICAS: Dicas (política e comunidade)',
};

export const TEXT_POLICY_DESCRIPTION = {
  en_US:
    'Receive updates on Credit regulations and stay informed about advocacy efforts.',
  es_ES:
    'Reciba actualizaciones sobre las regulaciones crediticias y manténgase informado sobre los esfuerzos de promoción.',
  pt_BR:
    'Receba atualizações sobre as regulamentações de crédito e mantenha-se informado sobre os esforços de advocacy.',
};

export const TEXT_CUSTOMER_NOTIFICATION_TITLE = {
  en_US: 'CUSTOMER ONGOING PROCESSES NOTIFICATIONS',
  es_ES: 'NOTIFICACIONES DE PROCESOS DE CLIENTE EN CURSO',
  pt_BR: 'NOTIFICAÇÕES DE PROCESSOS EM ANDAMENTO DO CLIENTE',
};

export const TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_TITLE = {
  en_US: 'CUSTOMER ONGOING PROCESSES NOTIFICATIONS - WHATSAPP',
  es_ES: 'NOTIFICACIONES DE PROCESOS DE CLIENTE EN CURSO - WHATSAPP',
  pt_BR: 'NOTIFICAÇÕES DE PROCESSOS EM ANDAMENTO DO CLIENTE - WHATSAPP',
};

export const TEXT_CUSTOMER_NOTIFICATION_DESCRIPTION = {
  en_US:
    'Once you have a device registered in Visa Benefits Portal, you will receive push notifications related with your certificates creation and claim status change during its whole lifecycle.',
  es_ES:
    'Una vez que tenga un dispositivo registrado en el Portal de Beneficios de Visa, recibirás notificaciones automáticas relacionadas con la creación de tus certificados y el cambio de estado de tu reclamo a lo largo de tu ciclo de vida.',
  pt_BR:
    'Uma vez que você tenha um dispositivo registrado no Portal de Benefícios Visa, você receberá notificações push relacionadas à criação de seus bilhetes e a mudança de status do seu sinistro durante todo o seu ciclo de vida.',
};

export const TEXT_WHATSAPP_CUSTOMER_NOTIFICATION_DESCRIPTION = {
  en_US:
    'Once you have Whatsapp valid number registered in Visa Benefits Portal, you will receive push notifications related with your certificates creation and claim status change during its whole lifecycle.',
  es_ES:
    'Una vez que tenga un número válido de Whatsapp registrado en el Portal de Beneficios de Visa, recibirás notificaciones automáticas relacionadas con la creación de tus certificados y el cambio de estado de tu reclamo a lo largo de tu ciclo de vida.',
  pt_BR:
    'Uma vez que você tenha um número válido do Whatsapp registrado no Portal de Benefícios Visa, você receberá notificações push relacionadas à criação de seus bilhetes e a mudança de status do seu sinistro durante todo o seu ciclo de vida.',
};

export const TITLE_AT_LEAST_ONE_DEVICE = {
  en_US: 'At least one communication device must be registered.',
  es_ES: 'Se debe registrar al menos un dispositivo de comunicación.',
  pt_BR: 'Pelo menos um dispositivo de comunicação deve estar registrado.',
};

export const SUBTITLE_HOW_TO_RECEIVE = {
  en_US:
    'To receive push notifications, make sure that your browser is correctly enabled. To learn how to manage push notifications, click ',
  es_ES:
    'Para recibir notificaciones push, asegúrate de que tu navegador esté habilitado correctamente. Para aprender cómo administrar las notificaciones push, haga clic ',
  pt_BR:
    'Para receber notificações push, certifique-se de que seu navegador está habilitado corretamente. Para saber como gerenciar notificações push, clique ',
};

export const SUBTITLE_COMPLEMENT = {
  en_US: `here.`,
  pt_BR: `aqui.`,
  es_ES: `aquí.`,
};

export const BUTTON_CLOSE = {
  en_US: 'Close',
  pt_BR: 'Fechar',
  es_ES: 'Cerrar',
};
