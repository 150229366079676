export const TITLE_PENDING = {
  en_US: 'Claims pending your documentation',
  es_ES: 'Reclamos pendientes de tu documentación',
  pt_BR: 'Sinistros pendentes de documentação',
};

export const SUBTITLE_PENDING = {
  en_US:
    'Please attach documentation needed so that we can review your claim. It must be submitted within 90 days of the incident.',
  es_ES:
    'Adjunta la documentación necesaria para que podamos revisar tu reclamo. Debe presentarse dentro de los 90 días posteriores al incidente.',
  pt_BR:
    'Anexe a documentação necessária para que possamos analisar seu sinistro. A documentação deve ser apresentada dentro de 90 dias após o incidente.',
};

export const TITLE_REVIEW = {
  en_US: 'Claims under review',
  es_ES: 'Reclamos bajo revisión',
  pt_BR: 'Sinistros em revisão',
};

export const SUBTITLE_REVIEW = {
  en_US: 'Your claim is being analyzed.',
  es_ES: 'Tu reclamo está siendo analizado.',
  pt_BR: 'Seu sinistro está sendo analisado.',
};

export const TITLE_PAID = {
  en_US: 'Claims to be paid',
  es_ES: 'Reclamos por pagar',
  pt_BR: 'Sinistros a serem pagos',
};

export const SUBTITLE_PAID = {
  en_US:
    'You will receive reimbursement for these approved claims. This can take up to 15 days.',
  es_ES:
    'Recibirás un reembolso por estas reclamaciones aprobadas. Esto puede tardar hasta 15 días.',
  pt_BR:
    'Você receberá reembolso por esses sinistros aprovados. Isso pode levar até 15 dias.',
};

export const TITLE_REJECTED = {
  en_US: 'Claims rejected',
  es_ES: 'Reclamos rechazados',
  pt_BR: 'Sinistros rejeitados',
};

export const SUBTITLE_REJECTED = {
  en_US:
    'Unfurtunately these claims have beem rejected. You can try again or ask for help.',
  es_ES:
    'Desafortunadamente, estes reclamos han sido rechazadas. Puede intentarlo de nuevo o pedir ayuda.',
  pt_BR:
    'Infelizmente, esses sinistros foram rejeitados. Você pode tentar novamente ou pedir ajuda.',
};

export const TITLE_CLOSED = {
  en_US: 'Closed claims',
  es_ES: 'Reclamos cerrados',
  pt_BR: 'Sinistros fechados',
};

export const SUBTITLE_CLOSED = {
  en_US:
    'These claims have other been paid, canceled or closed due to duplication.',
  es_ES:
    'Estes reclamos fueron pagadas, canceladas o cerradas por duplicación.',
  pt_BR: 'Esses sinistros foram pagos, cancelados ou fechados por duplicidade.',
};

export const TEXT_SEE_MORE = {
  en_US: 'See more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const TEXT_MSG_EMPTY_PENDING = {
  en_US:
    'You do not have any claim pending documentation. Everything in order!',
  es_ES: 'No tienes ningún reclamo pendiente de documentación. ¡Todo en orden!',
  pt_BR:
    'Você não tem nenhum sinistro pendente de documentação. Tudo em ordem!',
};

export const TEXT_MSG_EMPTY_REVIEW = {
  en_US: 'You do not currently have any claim under review.',
  es_ES: 'Actualmente no tienes ningún reclamo en revisión.',
  pt_BR: 'No momento, você não tem nenhum sinistro em análise.',
};

export const TEXT_MSG_EMPTY_PAID = {
  en_US: 'You do not currently have any claim to be paid.',
  es_ES: 'Actualmente no tienes ningún reclamo por pagar.',
  pt_BR: 'No momento, você não tem nenhum sinistro a ser pago.',
};

export const TEXT_MSG_EMPTY_REJECTED = {
  en_US: 'You do not currently have any claim rejected.',
  es_ES: 'Actualmente no tienes ningún reclamo rechazado.',
  pt_BR: 'No momento, você não tem nenhum sinistro rejeitado.',
};

export const TEXT_MSG_EMPTY_CLOSED = {
  en_US: 'You do not currently have any claim closed.',
  es_ES: 'Actualmente no tienes ningún reclamo cerrado.',
  pt_BR: 'No momento, você não tem nenhum sinistro fechado.',
};
