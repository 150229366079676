import { toast } from 'react-toastify';

export const showNotification = (toastId, content, delay) => {
  toastId.current = toast.info(content);
};

export const updateNotification = (toastId, content) => {
  toast.update(toastId.current, {
    render: content,
  });
};
