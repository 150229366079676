import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const UserIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.2041 11.5029L20 9.69727V8C20 3.58887 16.4111 0 12 0C7.66357 0 4 3.66357 4 8C4 9.85205 4.6748 10.9453 5.21729 11.8237C5.67188 12.5596 6 13.0908 6 14C6 14.4513 5.84027 14.9425 5.62122 15.4561L7.41114 16.3511C7.74048 15.6018 8 14.8273 8 14C8 12.5234 7.42578 11.5933 6.91895 10.773C6.42578 9.97412 6 9.28467 6 8C6 4.74756 8.74756 2 12 2C15.3086 2 18 4.69141 18 8V10.3027L19.5391 12.6123L18.1816 13.291L17.2832 16H16C14.959 16 13.8818 16 13.1055 17.5527L12.4473 18.8691L14.2363 19.7637L14.8945 18.4473C15.1182 18 15.1182 18 16 18H17.2832C18.1465 18 18.9092 17.4492 19.1816 16.6289L19.8184 14.709L20.4346 14.4014C20.9473 14.1445 21.3252 13.6787 21.4727 13.125C21.6191 12.5713 21.5215 11.98 21.2041 11.5029Z"
        fill={config_icon.secondary}
      />
      <path
        d="M4.44726 17.1054L3.55273 18.8945L13.5528 23.8944L14.4473 22.1054L4.44726 17.1054Z"
        fill={config_icon.primary}
      />
    </svg>
  );
};

export default UserIcon;
