import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const travelInformation =
    claimDetails && claimDetails.travel_information
      ? claimDetails.travel_information
      : {};

  const transportationType =
    travelInformation && travelInformation.transportation_type
      ? travelInformation.transportation_type
      : '-';

  const getTransportationType = (title, intl) => {
    switch (title) {
      case 'AIRCRAFT':
        return intl.OPTIONS_AIRLINE;
      case 'SHIP':
        return intl.OPTIONS_CRUISE_LINE;
      case 'RAILWAY':
        return intl.OPTIONS_RAILROAD;
      default:
        return '-';
    }
  };

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_BAGGAGE_CUSTODY}
          value={getTransportationType(transportationType, intl)}
        />
      </div>
    </>
  );
};

export default PersonalArea;
