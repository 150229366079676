export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date',
  es_ES: 'Fecha del incidente',
  pt_BR: 'Data do incidente',
};

export const LABEL_INCIDENT_DESCRIPTION = {
  en_US: 'Incident description',
  es_ES: 'Descripción del incidente',
  pt_BR: 'Descrição do incidente',
};

export const LABEL_ITEMS_COUNT = {
  en_US: 'Items stolen or accidentally damaged',
  es_ES: 'Artículos robados o dañado accidentalmente',
  pt_BR: 'Itens roubados ou danificados acidentalmente',
};

export const LABEL_TOTAL_AMOUNT = {
  en_US: 'Total amount to be reimbursed',
  es_ES: 'Importe total a reembolsar',
  pt_BR: 'Valor total a ser reembolsado',
};
