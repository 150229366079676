import React, { useContext, useRef, useState } from 'react';

import AcordeonSession from '../../../../components/AcordeonSession/AcordeonSession';
import { IntlContext } from '../../../../intl';
import { checkErrorsOnSession, checkErrorsOnForm } from './checkErrorsOnForm';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import AdditionalTravelers from './AdditionalTravelers/AdditionalTravelers';
import * as translations from './intl';
import { submitStep2 } from './submit';
import { B2B2CContext } from '../../../../components/B2B2CStateParent/B2B2CStateParent';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import ReviewSelectedPlan from '../PaymentInformation/ReviewSelectedPlan/ReviewSelectedPlan';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const Form = props => {
  const {
    nextStep,
    personalData,
    formType,
    setStep,
    type,
    CONFIG_B2B2C,
    backStep,
  } = props;
  const { utils: utilsB2B2C, actions: actionsB2B2C } = useContext(B2B2CContext);
  const cardHolderSelected = utilsB2B2C.getMPH();
  const [isCardHolder, setIsCardHolder] = useState(
    cardHolderSelected ? cardHolderSelected.isCardHolder : true
  );
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const intl = translate(translations);
  const quote = utilsB2B2C.getQuote();
  const Ages = quote && quote.Quote ? quote.Quote.Ages : [];

  const hasAdditionalTravelers = Ages.length > 1;

  const [schemas] = useState({});
  const [values] = useState({});
  const [prefix, setPrefix] = useState('');
  const [listAccordeon, setListAccordeon] = useState({
    generalInformation: true,
    additionalTravelers: false,
    trip: false,
  });

  const accordeonRefs = {
    generalInformation: useRef(null),
    additionalTravelers: useRef(null),
    trip: useRef(null),
  };
  const submitRefs = {
    generalInformation: useRef(null),
    additionalTravelers: useRef(null),
    trip: useRef(null),
  };

  const setStatusAccordeon = selected => {
    const newListAccordeon = { ...listAccordeon };
    Object.keys(newListAccordeon).map(key => {
      return (newListAccordeon[key] = false);
    });
    setListAccordeon({
      ...newListAccordeon,
      [selected]: !listAccordeon[selected],
    });
  };

  const openTargetAcordeon = async (
    currentAcordeon,
    targetAcordeon,
    validation = true
  ) => {
    return await checkErrorsOnSession(
      accordeonRefs,
      currentAcordeon,
      targetAcordeon,
      schemas,
      values,
      validation,
      submitRefs
    );
  };

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  const handleSubmission = async () => {
    try {
      const areFormsOk = await checkErrorsOnForm(
        submitRefs,
        accordeonRefs,
        schemas,
        values,
        listAccordeon
      );

      trackEventUserAction(
        `#### (B2B2C) INFORMAÇÕES DE VIAGEM FORM OK`,
        areFormsOk
      );

      if (areFormsOk) {
        const _props = {
          quote,
          values,
          actionsB2B2C,
          utilsB2B2C,
          actions,
          nextStep,
          selectedPlan: utilsB2B2C.getSelectedPlan(),
          intl,
          formType,
          setStep,
          openTargetAcordeon,
          trackEventUserAction,
        };
        await submitStep2(_props);
      }
    } catch (error) {
      trackEventUserAction(`#### (B2B2C) ERRO INFORMAÇÕES DE VIAGEM`, {
        error,
        error_response: error.response,
      });
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.SOMETHING_WENT_WRONG}
          buttonConfirmType="axaBlue"
        />,
        true,
        false,
        null,
        null,
        null,
        false,
        true
      );
    }
  };
  return (
    <div id="mainContent">
      <AcordeonSession
        id="generalInformation"
        title={intl.TITLE_MAIN_TRAVELER}
        openButtonRef={accordeonRefs.generalInformation}
        accordeon="generalInformation"
        status={listAccordeon.generalInformation}
        setStatus={() => setStatusAccordeon('generalInformation')}
        next={() => {
          if (hasAdditionalTravelers) {
            openTargetAcordeon(
              'generalInformation',
              'additionalTravelers',
              true
            );
          } else {
            openTargetAcordeon('generalInformation', 'trip', true);
          }
        }}
        back={backStep}
        axa
      >
        {personalData && (
          <GeneralInformation
            submitRef={submitRefs.generalInformation}
            updateParent={updateForm.bind(null, 'generalInformation')}
            user={personalData.user}
            prefixOptions={{ prefix, setPrefix }}
            setIsCardHolder={setIsCardHolder}
          />
        )}
      </AcordeonSession>
      {hasAdditionalTravelers && (
        <AcordeonSession
          id="additionalTravelers"
          title={intl.TITLE_ADDITIONAL_TRAVELERS}
          openButtonRef={accordeonRefs.additionalTravelers}
          accordeon="additionalTravelers"
          status={listAccordeon.additionalTravelers}
          setStatus={() => setStatusAccordeon('additionalTravelers')}
          next={() => openTargetAcordeon('additionalTravelers', 'trip', true)}
          back={() =>
            openTargetAcordeon(
              'additionalTravelers',
              'generalInformation',
              false
            )
          }
          axa
        >
          {personalData && (
            <AdditionalTravelers
              submitRef={submitRefs.additionalTravelers}
              updateParent={updateForm.bind(null, 'additionalTravelers')}
              dependents={personalData.dependents}
              isCardHolder={isCardHolder}
            />
          )}
        </AcordeonSession>
      )}
      <AcordeonSession
        id="trip"
        title={intl.TITLE_TRIP_INFORMATION}
        openButtonRef={accordeonRefs.trip}
        status={listAccordeon.trip}
        setStatus={() => setStatusAccordeon('trip')}
        back={() => {
          if (hasAdditionalTravelers) {
            openTargetAcordeon('trip', 'additionalTravelers', false);
          } else {
            openTargetAcordeon('trip', 'generalInformation', false);
          }
        }}
        next={handleSubmission}
        axa
      >
        <ReviewSelectedPlan
          type={type}
          submitRef={submitRefs.trip}
          formType={formType}
          CONFIG_B2B2C={CONFIG_B2B2C}
          updateParent={updateForm.bind(null, 'trip')}
          personalData={personalData}
          setStep={setStep}
        />
      </AcordeonSession>
    </div>
  );
};

export default Form;
