export const CARD_HOLDER_VALUE = 'Cardholder';
export const SPOUSE_VALUE = 'Spouse';
export const DEPENDENT_VALUE = 'Dependent child';
export const OTHER_VALUE = 'Other';

export const CARD_HOLDER_Q_VALUE = 'Cardholder';
export const RENTAL_COMPANY_Q_VALUE = 'Rental company';

export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const UNKNOWN_VALUE = 'UNKNOWN';

// PHONE
export const PHONE_MOBILE = 'MOBILE';
export const PHONE_LANDLINE = 'LANDLINE';

export const ADDITIONAL_OBJECT = {
  first_name: '',
  last_name: '',
  birth_date: '',
  gender: '',
  nationalities: [],
  relationship: 'Spouse',
  comment: '',
};
