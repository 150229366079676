import React, { useContext, useEffect, useState, useMemo } from 'react';
import { IntlContext } from '../../../../intl';
import styles from './YourDependents.module.scss';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { getCHDependents } from '../../../Certificates/Generate/getCHDependents';
import Dependents from '../../../Certificates/Generate/CertificateFillForm/Dependents/Dependents';
import { dependentsInitialValues } from '../../../Certificates/Generate/CertificateFillForm/Dependents/initialValues';

const YourDependents = () => {
  const { utils } = useContext(StateContext);
  const [dependents, setDependents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [schemas] = useState({});
  const [values] = useState({});
  const { idiom } = useContext(IntlContext);

  const cn = utils.getCn();

  async function getDependents() {
    setLoading(true);
    try {
      const data = await getCHDependents(cn);
      setDependents(data);
    } catch (error) {
      setDependents([]);
    }
    setLoading(false);
  }

  const initialValues = useMemo(
    () => dependentsInitialValues(dependents, idiom, true),
    [dependents, idiom]
  );

  useEffect(() => {
    getDependents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateForm = (index, childValues, validationSchema) => {
    if (childValues !== values[index]) {
      values[index] = childValues;
    }
    if (validationSchema !== schemas[index]) {
      schemas[index] = validationSchema;
    }
  };

  return (
    <div id="dependents" className={styles.container}>
      <Dependents
        updateParent={updateForm.bind(this, 'dependents')}
        initialValues={initialValues}
        isProfile
        isLoading={loading}
      />
    </div>
  );
};

export default YourDependents;
