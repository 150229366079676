import { ENABLE_CLAIM_APPROVED_VALUE } from '../../../utils/environments_variables';
import { LABEL_AMOUNT_TO_BE_PAYED, LABEL_AMOUNT_APPROVED } from './intl';

const STATUS_CLOSED = 'Closed';
const SUBSTATUS_APPROVED = 'Claims - Approved Claim';
const SUBSTATUS_CLOSED = 'Claims - Case Closed';

export const getTheCorrectAmountByStatus = (claimDetails, idiom) => {
  if (ENABLE_CLAIM_APPROVED_VALUE && claimDetails?.statuses) {
    const lastStatusIndex = claimDetails.statuses.length - 1;
    if (
      claimDetails.statuses[lastStatusIndex]?.name === STATUS_CLOSED &&
      claimDetails.statuses[lastStatusIndex].sub_statuses
    ) {
      const substatuses = claimDetails.statuses[lastStatusIndex].sub_statuses;
      const lastSubStatusIndex = substatuses.length - 1;

      if (substatuses[lastSubStatusIndex]?.name === SUBSTATUS_APPROVED) {
        return {
          reimbursedValue: claimDetails.total_amount_approved?.value ?? null,
          reimbursedLabel: LABEL_AMOUNT_APPROVED[idiom],
          reimbursedCurrency:
            claimDetails.total_amount_approved?.currency ?? null,
        };
      }

      if (substatuses[lastSubStatusIndex]?.name === SUBSTATUS_CLOSED) {
        return {
          reimbursedValue: claimDetails.total_amount_reimbursed?.value ?? null,
          reimbursedLabel: LABEL_AMOUNT_TO_BE_PAYED[idiom],
          reimbursedCurrency:
            claimDetails.total_amount_reimbursed?.currency ?? null,
        };
      }
    }
  }

  return {
    reimbursedValue: null,
    reimbursedLabel: null,
    reimbursedCurrency: null,
  };
};
