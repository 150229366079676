export const TITLE_CLAIM = {
  en_US: 'Submit a new claim',
  es_ES: 'Generar un nuevo reclamo',
  pt_BR: 'Abrir um novo sinistro',
};

export const TITLE_BENEFITS_CATEGORY_ALL = {
  en_US: 'All benefits',
  pt_BR: 'Todos os benefícios',
  es_ES: 'Todos los beneficios',
};

export const TITLE_BENEFITS_CATEGORY_TRAVEL_PROTECTION = {
  en_US: 'Travel',
  pt_BR: 'Viagem',
  es_ES: 'viaje',
};

export const TITLE_BENEFITS_CATEGORY_PURCHASE_PROTECTION = {
  en_US: 'Purchase protection',
  pt_BR: 'Proteção de compra',
  es_ES: 'Protección de compra',
};

export const TITLE_BENEFITS_CATEGORY_EMERGENCY_MEDICAL = {
  en_US: 'Emergency medical',
  pt_BR: 'Emergência médica',
  es_ES: 'Emergencia médica',
};

export const TITLE_BENEFITS_CATEGORY_CONCIERGE = {
  en_US: 'Concierge',
  pt_BR: 'Concierge',
  es_ES: 'Concierge',
};

export const TEXT_MAKE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};

export const BOOKING = {
  en_US: 'Booking',
  pt_BR: 'Agendar',
  es_ES: 'Cita',
};

export const TEXT_DESCRIPTION = {
  en_US:
    'To file this claim you will need to provide the following information:',
  pt_BR:
    'Para apresentar este sinistro, você precisará fornecer as seguintes informações:',
  es_ES:
    'Para presentar este reclamo, deberás proporcionar la siguiente información:',
};

export const TEXT_INFO_INCIDENT = {
  en_US: 'Information about your incident',
  pt_BR: 'Informações sobre seu incidente',
  es_ES: 'Información sobre tu incidente',
};

export const TEXT_PERSONAL_INFO = {
  en_US: 'Personal Information',
  pt_BR: 'Informação pessoal',
  es_ES: 'Informacion personal',
};

export const TEXT_VIEW_PDF = {
  en_US: 'View PDF',
  es_ES: 'Ver PDF',
  pt_BR: 'Ver resumo',
};

export const TITLE_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Resumo de benefícios para veículo de locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};

export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Resumo de benefícios de proteção de compra ampliada',
  es_ES: 'Certificado de compra',
};

export const VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const OPEN_BENEFIT_DETAILS = {
  en_US: 'Open benefit details',
  pt_BR: 'Abrir o detalhe do benefício',
  es_ES: 'Abrir detalles del beneficio',
};

export const TITLE_BENEFIT_DETAILS = {
  en_US: 'Benefit details',
  pt_BR: 'Detalhes do benefício',
  es_ES: 'Detalles del beneficio',
};

export const TEXT_OPEN_DETAIL_BENEFIT = {
  en_US: 'Show or hide certificate details',
  pt_BR: 'Mostrar ou esconder o detalhe do certificado',
  es_ES: 'Mostrar u ocultar el detalle del certificado',
};
export const MORE_DETAILS = {
  en_US: 'More Details',
  es_ES: 'Más Detalles',
  pt_BR: 'Mais Detalhes',
};
export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};

export const BENEFIT_ALT = {
  en_US: name => `Benefit icon ${name}`,
  es_ES: name => `Ícono de beneficio ${name}`,
  pt_BR: name => `Ícone de beneficio ${name}`,
};

export const LEGACY_CERTIFICATE_RESUME_EXTENDED_PURCHASE = {
  en_US: '',
  es_ES: '',
  pt_BR: `<a href='https://prod-axa.cdn.prismic.io/prod-axa/f2ccdf83-9868-4ac9-b566-5c636ae52ca5_Look_and_Feel_Compras_Consumer.pdf' target='_blank'><u><b>Ver resumo</b> para compras até 01/06/2023</u></a>`,
};

export const LEGACY_CERTIFICATE_RESUME_RENTAL_CAR = {
  en_US: '',
  es_ES: '',
  pt_BR: `<a href='https://prod-axa.cdn.prismic.io/prod-axa/59a22d10-3b91-4971-9941-bd6e2b40be03_Resumo_do_Beneficio_Seguro_de_Veiculos_de_Locadora.pdf' target='_blank'><u><b>Ver resumo</b> para aluguéis até 01/06/2023</u></a>`,
};
