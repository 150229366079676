/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import styles from './Banner.module.scss';
import ButtonLogin from '../../../components/ButtonLogin/ButtonLogin';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import Loading from '../../../components/Loading/Loading';
import { withRouter } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ButtonBenefits from '../../../components/ListCardBenefits/ListCardItem/ButtonBenefits/ButtonBenefits';
import sanitize from '../../../utils/sanitize';
import {
  getAlignBackgroundByURL,
  getNoBlackCoverByURL,
  getValignBackgroundByURL,
} from '../../../utils/stringUtils';
import LoginButton from '../../../componentsV2/LoginButton/LoginButton';

const Banner = props => {
  const {
    benefitDetail: benefit,
    loadingBanner,
    isLogged,
    cardHasVMO,
    isNewVersionDetail,
  } = props;

  const [imageAPI, setImageAPI] = useState();
  const { translate, idiom, country } = useContext(IntlContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);

  const colorTheme = isAfluent() ? '#021E4C' : '#1434CB';
  const backGround = imageAPI ? imageAPI.content_url : colorTheme;

  const getImageMobile =
    benefit &&
    benefit.images &&
    Array.isArray(benefit.images) &&
    benefit.images.find(image => image.type === 'PICTURE_MOBILE');

  const getImage =
    benefit &&
    benefit.images &&
    Array.isArray(benefit.images) &&
    benefit.images.find(image => image.type === 'PICTURE');

  const getCorrectImage = () => {
    if (window.outerWidth < 992 && getImageMobile) {
      setImageAPI(getImageMobile);
    } else if (getImage) {
      setImageAPI(getImage);
    }
  };

  useEffect(() => {
    if (benefit) {
      window.addEventListener('resize', getCorrectImage);
    }
    return () => {
      window.removeEventListener('resize', getCorrectImage);
    };
  }, [benefit]);

  useEffect(() => {
    getCorrectImage();
  }, [getImage, getImageMobile]);

  const bodyContent = (
    <>
      <h1 className={styles.benefit}>{benefit && benefit.name}</h1>
      <div
        className={styles.benefitDescription}
        dangerouslySetInnerHTML={{
          __html: benefit && sanitize(benefit.short_description),
        }}
      ></div>
      {benefit && benefit.redeem_info && (
        <div
          className={`${styles.benefitDescription} ${styles.redeemInfo}`}
          dangerouslySetInnerHTML={{
            __html: benefit && sanitize(benefit.redeem_info),
          }}
        ></div>
      )}

      {isLogged ? (
        <>
          {benefit && benefit.documents && (
            <div className={styles.buttonsLogged}>
              <ButtonBenefits
                isDetail
                benefit={benefit}
                idiom={idiom}
                country={country}
                cardHasVMO={cardHasVMO}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <p className={styles.benefitInfo}>{intl.TEXT_ACCESS_BENEFIT}</p>
          <div className={styles.divButton}>
            <LoginButton
              redirect={`/your-benefits/${benefit && benefit.benefit_id}`}
              surface="Inverse"
              label={intl.BUTTON_LOGIN}
            />
          </div>
        </>
      )}
    </>
  );

  const downloadTypeContent = (
    <div className={styles.downloadBannerContent}>
      {benefit.redeem_info &&
        benefit.redeem_info.redeem &&
        benefit.redeem_info.redeem.title && (
          <>
            <h1
              className={styles.downloadTypeTitle}
              dangerouslySetInnerHTML={{
                __html: benefit.redeem_info.redeem.title,
              }}
            ></h1>
            {benefit.redeem_info.icon && (
              <img
                alt={benefit.redeem_info.redeem.title}
                src={benefit.redeem_info.icon}
              />
            )}
          </>
        )}
    </div>
  );

  return (
    <div
      className={`${styles.bannerContainer} ${
        isNewVersionDetail ? styles.bannerDownload : ''
      }`}
    >
      <div
        className={styles.banner}
        style={
          imageAPI
            ? {
                backgroundImage: `url(${backGround})`,
                backgroundPosition: `
              ${
                imageAPI &&
                imageAPI.content_url &&
                getAlignBackgroundByURL(imageAPI.content_url)
              } ${
                  imageAPI &&
                  imageAPI.content_url &&
                  getValignBackgroundByURL(imageAPI.content_url)
                }
            `,
              }
            : { backgroundColor: `${backGround}` }
        }
      >
        {imageAPI &&
          imageAPI.content_url &&
          getNoBlackCoverByURL(imageAPI.content_url) && (
            <div className={styles.blackCover}></div>
          )}
        <div className={`${styles.content} ${styles[theme]}`}>
          {loadingBanner ? (
            <div className={styles.loadingContainer}>
              <Loading />
            </div>
          ) : (
            <>{isNewVersionDetail ? downloadTypeContent : bodyContent}</>
          )}
        </div>
      </div>
      <div className={styles.childArea}>{props.children}</div>
    </div>
  );
};

export default withRouter(Banner);
