export const TEXT_INTRODUCE_SPECIAL_BENEFITS = {
  en_US: 'Discover the new Visa subscriptions.',
  es_ES: 'Conoce las nuevas suscripciones de Visa.',
  pt_BR: 'Conheça as novas assinaturas Visa.',
};

export const TEXT_FULL_INTRODUCE_SPECIAL_BENEFITS = {
  en_US:
    'Discover the new Visa subscriptions in entertainment, services and more.',
  es_ES:
    'Conoce las nuevas suscripciones de Visa en entretenimiento, servicios y más.',
  pt_BR:
    'Conheça as novas assinaturas Visa em entretenimentos, serviços e muito mais.',
};

export const BUTTON_TEXT = {
  en_US: 'See subscriptions',
  es_ES: 'Ver suscripciones',
  pt_BR: 'Ver assinaturas',
};

export const URL_LOGIN = {
  en_US: '/',
  es_ES: '/',
  pt_BR: '/',
};
