import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../Rideshare.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const InsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const { values, handleChange, touched, handleBlur, errors } = props;

  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_INDEMNIFICATION_RIDESHARE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_INDEMNIFICATION_RIDESHARE}
              defaultChecked={false}
              text={intl.TEXT_OPTION_YES}
              name="other_insurance_covering.rideshare.rideshare_checked"
              value={true}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_INDEMNIFICATION_RIDESHARE}
              defaultChecked={true}
              text={intl.TEXT_OPTION_NO}
              name="other_insurance_covering.rideshare.rideshare_checked"
              value={false}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={`col-12 col-sm-12 col-md-6`}
        data-testid="DivRideshareIdemnificationCovering"
      >
        {values.other_insurance_covering.rideshare.rideshare_checked ===
          'true' && (
          <Input
            label={intl.TEXT_AMOUNT_PAID_RIDESHARE}
            type="text"
            maskedMoney
            name="other_insurance_covering.rideshare.value"
            tooltipText={intl.TEXT_DECIMAL_SYMBOL}
            value={values.other_insurance_covering.rideshare.value}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={
              touched.other_insurance_covering &&
              touched.other_insurance_covering.rideshare &&
              touched.other_insurance_covering.rideshare.value
            }
            error={
              errors.other_insurance_covering &&
              errors.other_insurance_covering.rideshare &&
              errors.other_insurance_covering.rideshare.value
            }
          />
        )}
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        {values.other_insurance_covering.rideshare.rideshare_checked ===
          'true' && (
          <Select
            label={intl.LABEL_CURRENCY}
            name="other_insurance_covering.rideshare.currency"
            value={values.incident.concerned_items[0].price.currency}
            onChange={handleChange}
            disabled
            onBlur={handleBlur}
            error={
              errors.other_insurance_covering &&
              errors.other_insurance_covering.rideshare &&
              errors.other_insurance_covering.rideshare.currency
            }
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
          />
        )}
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_OTHER_INSURANCE}
            </label>
          </div>

          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_OTHER_INSURANCE}
              defaultChecked={false}
              text={intl.TEXT_OPTION_YES}
              name="other_insurance_covering.checked"
              dataTestid="InputOtherInsuranceCoveringYes"
              value={true}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_OTHER_INSURANCE}
              defaultChecked={true}
              text={intl.TEXT_OPTION_NO}
              name="other_insurance_covering.checked"
              dataTestid="InputOtherInsuranceCoveringNo"
              value={false}
              onChange={e => {
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>

      <div
        className={`col-12 col-sm-12 col-md-6`}
        data-testid="DivOtherInsuranceCoveringField"
      >
        {values.other_insurance_covering.checked === 'true' && (
          <Input
            label={intl.TEXT_AMOUNT_OTHER_INSURANCE}
            type="text"
            maskedMoney
            name="other_insurance_covering.value"
            dataTestid="InputOtherInsuranceCoveringValue"
            tooltipText={intl.TEXT_DECIMAL_SYMBOL}
            value={values.other_insurance_covering.value}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={
              touched.other_insurance_covering &&
              touched.other_insurance_covering.value
            }
            error={
              errors.other_insurance_covering &&
              errors.other_insurance_covering.value
            }
          />
        )}
      </div>
      <div className={`col-12 col-sm-12 col-md-6 `}>
        {values.other_insurance_covering.checked === 'true' && (
          <Select
            label={intl.LABEL_CURRENCY}
            name="other_insurance_covering.currency"
            dataTestid="InputOtherInsuranceCoveringCurrency"
            value={values.incident.concerned_items[0].price.currency}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
            error={
              errors.other_insurance_covering &&
              errors.other_insurance_covering.currency
            }
            dangerouslySetInnerHTML={{
              __html: sanitize(
                `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
              ),
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InsuranceArea;
