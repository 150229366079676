import React, { useContext } from 'react';
import styles from './EmptyLayout.module.scss';
import Logo from '../../components/Logo/Logo';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import LinkMainContent from '../../components/LinkMainContent/LinkMainContent';

const EmptyLayout = ({ children, logoVisa = true }) => {
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const blue = isAfluent() ? '#021E4C' : '#1434CB';

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <LinkMainContent />
      {logoVisa ? (
        <header>
          <Link to="/">
            <Logo size="BIGGER" color={blue} />
          </Link>
        </header>
      ) : null}

      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default EmptyLayout;
