import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { CONFIG_ICON } from './config-icon';

const ClaimsIcon = props => {
  const { enabled = true, invertColor, darkbackground } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = CONFIG_ICON[theme];
  const primary = darkbackground
    ? config_icon.primary_darkbackground
    : invertColor
    ? config_icon.secondary
    : config_icon.primary;
  const secondary = darkbackground
    ? config_icon.secondary_darkbackground
    : invertColor
    ? config_icon.primary
    : config_icon.secondary;
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={enabled ? secondary : config_icon.inactive}
        d="M6.2.2v5l2 1.3V2.2h13.1v12.9h-6.6v6.7H6.1v2h8.6c4.7 0 8.5-3.8 8.5-8.5V.3h-17zm10.4 21.3v-4.4h4.3c-.5 2.1-2.2 3.8-4.3 4.4z"
      />
      <path
        fill={enabled ? secondary : config_icon.inactive}
        d="M8.2 19.5v-5.7l-2-1.4v7.1z"
      />
      <path
        fill={enabled ? primary : config_icon.inactive}
        d="M10.4 4.5H19v2h-8.6z"
      />
      <path
        transform="rotate(-56.317 7.382 9.617)"
        d="M6.4 2.8h2v13.6h-2z"
        fill={enabled ? primary : config_icon.inactive}
      />
    </svg>
  );
};

export default ClaimsIcon;
