import { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getName,
  getLastName,
  getPhone,
  getAgencyName,
  getAgencyDateOfAssignment,
  getAgencyDateOfAccountCancellation,
  getAgencyDateOfAffidavitReceivement,
  getAgencyDateOfRequest,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const AgencyArea = props => {
  const { claimDetails } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const [agency, setAgency] = useState({});
  const [contact, setContact] = useState({});

  useEffect(() => {
    const agency =
      (claimDetails &&
        claimDetails.collection_agencies &&
        claimDetails.collection_agencies[0]) ||
      {};
    setAgency(agency);

    const contact = (agency && agency.contacts && agency.contacts[0]) || {};
    setContact(contact);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_COLLECTION_INFORMATION} simpleLine />
      </div>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_AGENCY_NAME}
          value={getAgencyName(agency)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_ASSIGMENT_MADE}
          value={getAgencyDateOfAssignment(agency, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_RECEIVED_CANCELLATION}
          value={getAgencyDateOfRequest(agency, idiom, country)}
        />
      </div>

      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_ACCOUNT_CANCELLATION}
          value={getAgencyDateOfAccountCancellation(agency, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_DATE_RECEIVED_AFFIDAVIT}
          value={getAgencyDateOfAffidavitReceivement(agency, idiom, country)}
        />
      </div>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_CONTACT_PERSON} simpleLine />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_FIRST_NAME}
          value={getName(contact)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={getLastName(contact)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_NUMBER}
          value={getPhone(contact, intl)}
        />
      </div>
    </>
  );
};

export default AgencyArea;
