export const EDIT_DEPENDENT = {
  en_US: 'Edit',
  es_ES: 'Editar',
  pt_BR: 'Editar',
};

export const DELETE_DEPENDENT = {
  en_US: 'Delete beneficiary',
  es_ES: 'Eliminar beneficiario',
  pt_BR: 'Deletar beneficiário',
};

export const MORE_INFO = {
  en_US: 'More information',
  es_ES: 'Más información',
  pt_BR: 'Mais informações',
};
