import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppInsightTrackContext } from '../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import ModalInformation from '../../../../components/ModalInformation/ModalInformation';
import ModalResult from '../../../../components/ModalResult/ModalResult';
import ModalWithImage from '../../../../components/ModalWithImage/ModalWithImage';
import { StateContext } from '../../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../../intl';
import { amosVideocallsService } from '../../../../services';
import * as translations from './intl';
import VideoChat from './VideoChat/VideoChat';

const VideoCall = () => {
  const location = useLocation();
  const history = useHistory();
  const { actions } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { videocallId, customer } = location.state;

  const [credentials, setCredentials] = useState(null);

  const isNullish = prop => {
    if (prop) return prop;
    return '-';
  };

  const startCall = useCallback(
    async videocallId => {
      try {
        trackEventUserAction(
          '#### VMO ### Videocall - Carregando informações',
          {
            customer,
            videocallId,
          }
        );

        const {
          data: {
            data: { teladoc_auth_details },
          },
        } = await amosVideocallsService.startVideocall(customer, videocallId);
        setCredentials(teladoc_auth_details);

        trackEventUserAction(
          '#### VMO ### Videocall - Informações carregadas',
          {
            teladoc_auth_details,
          }
        );
      } catch (error) {
        trackEventUserAction(
          '#### VMO ### Videocall - Erro ao iniciar chamada',
          {
            error,
          }
        );

        actions.modal.hideLoading();
        const message = isNullish(error.response.data.data.message);

        const modalTitle = message.includes('no doctor assigned')
          ? intl.TITLE_NO_DOCTOR
          : intl.TITLE_CONNECTION_ERROR;
        actions.modal.showModal(
          '',
          false,

          <ModalInformation
            type="Error"
            message={modalTitle}
            clickBtn={() => {
              history.goBack();
              actions.modal.closeModal();
            }}
          />
        );
      }
    },
    [customer]
  );

  const handleEndVideocall = () => {
    trackEventUserAction(
      '#### VMO ### Videocall - Modal - finalizar chamada',
      true
    );
    actions.modal.showModal(
      false,
      true,
      <ModalWithImage type="error" title={intl.TITLE_END_CALL}>
        <ModalResult
          textConfirm={intl.LABEL_YES}
          textDeny={intl.LABEL_NO}
          buttonConfirmType="borderRed"
          clickConfirm={async () => {
            endVideocall();
          }}
          clickDeny={() => actions.modal.closeModal()}
        />
      </ModalWithImage>,
      true
    );
  };

  const endVideocall = async () => {
    actions.modal.showLoading(intl.TITLE_ENDING);
    try {
      trackEventUserAction('#### VMO ### Videocall - Finalizando chamada', {
        customer,
        videocallId,
      });

      await amosVideocallsService.endVideocall(customer, videocallId);
      trackEventUserAction(
        '#### VMO ### Videocall - Chamada finalizada com sucesso',
        true
      );
    } catch (error) {
      trackEventUserAction(
        '#### VMO ### Videocall - Chamada finalizada com erro',
        { error }
      );
    } finally {
      actions.modal.closeModal();
      history.goBack();
    }
  };

  useEffect(() => {
    startCall(videocallId);
  }, [videocallId, startCall]);
  return (
    <VideoChat credentials={credentials} endVideocall={handleEndVideocall} />
  );
};

export default VideoCall;
