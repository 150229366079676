import { joinClassNames } from '../../utils/classNames';
import styles from './Bluebox.module.scss';

interface BlueboxTextProps {
  text: string;
  margin?: boolean;
  center?: boolean;
  className?: string;
}

const BlueboxText = ({
  text,
  margin = true,
  center,
  className,
}: BlueboxTextProps) => {
  return (
    <p
      className={joinClassNames(
        styles.description,
        margin ? '' : styles.withoutMargin,
        center ? styles.center : '',
        className
      )}
    >
      {text}
    </p>
  );
};

export default BlueboxText;
