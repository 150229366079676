import moment from 'moment';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../../utils/environments_variables';
import {
  stringToBoolean,
  removeNonNumeric,
} from '../../../../../../../../utils/stringUtils';

export const formatAtmAssault_toApi = props => {
  let object = {};
  object.affected_person = formatAffectedPerson(props);
  object.incident = formatIncident(props);
  object.other_insurance_covering = formatOtherInsurance(props);

  return object;
};

const formatIncident = props => {
  const values = props.values.incident.incident;
  delete values.location.invalidCep;
  values.location.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
    values.location.country
  )
    ? values.location.postal_code
    : removeNonNumeric(values.location.postal_code);
  const incident_date = moment(values.date).format('YYYY-MM-DD');
  const date = moment(incident_date + ' ' + values.hour).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  const object = incidentObject(values, date);

  if (values.kind !== 'LOSS') {
    delete object.withdrawn_amount;
    delete object.stolen_amount;
  }

  delete object.hour;
  return {
    ...object,
  };
};

const incidentObject = (values, date) => {
  return {
    ...values,
    date,
    withdrawn_amount: {
      value: parseFloat(values.withdrawn_amount.value).toFixed(2),
      currency: values.withdrawn_amount.currency,
    },
    stolen_amount: {
      value: parseFloat(values.stolen_amount.value).toFixed(2),
      currency: values.withdrawn_amount.currency,
    },
  };
};

const formatAffectedPerson = props => {
  const values = props.values.incident.affected_person;
  const kind = props.values.incident.incident.kind;
  const objectAffectedPerson = {
    ...values,
    person: {
      ...values.person,
      birth_date:
        kind === 'DEATH' && values.relationship !== 'Cardholder'
          ? moment(values.person.birth_date).format('YYYY-MM-DD')
          : null,
    },
    deceased_at:
      kind === 'DEATH' && moment(values.deceased_at).isValid()
        ? moment(values.deceased_at).format('YYYY-MM-DD')
        : null,
  };

  if (kind !== 'DEATH') {
    delete objectAffectedPerson.deceased_at;
    delete objectAffectedPerson.person.birth_date;
    delete objectAffectedPerson.person.gender;
  }
  return objectAffectedPerson;
};

const formatOtherInsurance = props => {
  const values = props.values.incident.other_insurance_covering;
  const other_insurance_covering = [];
  if (stringToBoolean(values.checked)) {
    other_insurance_covering.push(otherInsuranceObject(values));
  }
  return other_insurance_covering;
};

const otherInsuranceObject = props => {
  return {
    is_internal: props.is_internal,
    description: props.description,
    amount: {
      value: parseFloat(props.amount.value).toFixed(2),
      currency: props.amount.currency,
    },
  };
};
