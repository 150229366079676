// import { useContext } from "react";
import * as Yup from 'yup';

export const productInformationValidationSchema = (isWarranty = false, intl) =>
  Yup.object().shape({
    appliance_information: Yup.object().shape({
      appliance_description: Yup.string().required(intl.REQUIRED_FIELD),
      brand: Yup.string().required(intl.REQUIRED_FIELD),
      model: Yup.string().required(intl.REQUIRED_FIELD),
      purchase_date: Yup.date()
        .typeError(intl.REQUIRED_FIELD)
        .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_INVALID_DATE)
        .required(intl.REQUIRED_FIELD),
      cost: Yup.object().shape({
        value: Yup.string().required(intl.REQUIRED_FIELD),
        currency: Yup.string().required(intl.REQUIRED_FIELD),
      }),
    }),
    extended_warranty_information:
      isWarranty &&
      Yup.object().shape({
        original_period: Yup.string().required(intl.REQUIRED_FIELD),
      }),
  });
