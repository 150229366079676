import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from '../intl';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import styles from './RelationshipArea.module.scss';
import Select from '../../../../../../../components/Select/Select';
import Input from '../../../../../../../components/Input/Input';
import { safeGet } from '../../../../../../../utils/object';
import { emergency_medical_assistance } from '../../../../../../../utils/claims_name';

const checkboxClaims = [
  'Price_Protection',
  'Purchase_Protection',
  'Extended_Warranty',
  'Auto_Rental_Insurance',
  'Baggage_Delay',
  'Baggage_Loss',
  'Trip_Cancellation',
  'Trip_Delay',
  'Missed_Connection',
];

const RelationshipArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
    claimType,
    thirdPartyPayment,
    setThirdPartyPayment,
    setDisabledReimbursement,
  } = props;
  const isIEMS = claimType === emergency_medical_assistance;
  const useCardholderCheckbox = checkboxClaims.find(item => {
    return item === claimType;
  });

  useEffect(() => {
    if (values.BankAccountHolder.relationship_2 === 'ThirdParty') {
      setThirdPartyPayment(true);
      setDisabledReimbursement(true);
    } else {
      setThirdPartyPayment(false);
      setDisabledReimbursement(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.BankAccountHolder.relationship_2]);

  return (
    <div>
      <h6 className={`${styles[theme]} ${styles.titleInformation}`}>
        <span className={styles.boldTitle}>{intl.INFORMATION}</span>
        {intl.INFORMATION_1 + intl.INFORMATION_2}
      </h6>
      <div className={`row ${styles.section}`}>
        {!useCardholderCheckbox && (
          <div className="col-12 col-md-6">
            <Select
              label={
                isIEMS
                  ? intl.FORM_TXT_ACCOUNT_HOLDER
                  : intl.FORM_TXT_ACCOUNT_HOLDER_RELATIONSHIP
              }
              name="BankAccountHolder.relationship"
              formikProps={props}
              helpText={intl.TOOLTIP_FORM_TXT_ACCOUNT_HOLDER}
            >
              <option value="Cardholder">
                {isIEMS
                  ? intl.LABEL_CARD_HOLDER
                  : intl.FORM_LABEL_RADIO_CARDHOLDER}
              </option>
              <option value="Spouse">{intl.FORM_LABEL_RADIO_SPOUSE}</option>
              <option value="Dependent child">
                {intl.FORM_LABEL_RADIO_DEPENDENT}
              </option>
              <option value="Other">{intl.FORM_LABEL_RADIO_OTHER}</option>
            </Select>
          </div>
        )}

        {values.BankAccountHolder.relationship === 'Other' && (
          <div className="col-12 col-md-6">
            <Input
              value={values.BankAccountHolder.holder_type}
              label={intl.FORM_LABEL_CARDHOLDER_RELATIONSHIP}
              name="BankAccountHolder.holder_type"
              type="text"
              mask="maskedHolderType"
              touched={safeGet(touched, 'BankAccountHolder.holder_type')}
              onChange={handleChange}
              onBlur={handleBlur}
              error={safeGet(errors, 'BankAccountHolder.holder_type')}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RelationshipArea;
