export const TITLE = {
  en_US: 'Oops!',
  pt_BR: 'Ops!',
  es_ES: '¡Ups!',
};

export const DESCRIPTION = {
  en_US: 'Something went wrong!',
  es_ES: '¡Algo salió mal!',
  pt_BR: 'Algo deu errado!',
};

export const GO_HOMEPAGE = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para Homepage',
  es_ES: 'Ir a la Homepage',
};

export const TRY_AGAIN = {
  en_US: 'Try again',
  es_ES: 'Inténtalo de nuevo',
  pt_BR: 'Tente novamente',
};

export const SESSION_EXPIRED = {
  en_US: 'Session Expired',
  es_ES: 'Sesión Caducada',
  pt_BR: 'Sessão Expirada',
};
