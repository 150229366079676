export const LABEL_DENIAL_REASON = {
  en_US: 'Rejection reason',
  es_ES: 'Motivo de rechazo',
  pt_BR: 'Motivo da recusa',
};
export const LABEL_DECISION_DETAILS = {
  en_US: 'Decision details',
  es_ES: 'Detalles de la decisión',
  pt_BR: 'Detalhes da decisão',
};

export const LABEL_APPEAL_REASON = {
  en_US:
    'Please explain the reason why you do not agree with the rejection of the claim *',
  es_ES:
    'Por favor, explique la razón por la que no está de acuerdo con el rechazo del reclamo *',
  pt_BR:
    'Por favor, explique a razão pela qual não concorda com a recusa do sinistro *',
};
