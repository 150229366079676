import * as Yup from 'yup';
import moment from 'moment';

export const fraudulentChargesSchema = intl =>
  Yup.lazy(() =>
    Yup.object().shape({
      affected_person: Yup.object().shape({
        relationshipCardholder: Yup.bool().oneOf(
          [true],
          intl.TEXT_FIELD_REQUIRED
        ),
        person: Yup.object().shape({
          first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        }),
      }),
      incident: Yup.object().shape({
        date: Yup.date()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .test(
            'test-data-valid',
            intl.VALIDATE_DATE_INVALID,
            function (value) {
              return moment(value).isSameOrBefore(new Date());
            }
          ),
        bank_declaration_date: Yup.date()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .test(
            'test-data-valid',
            intl.VALIDATE_DATE_INVALID,
            function (value) {
              return moment(value).isSameOrBefore(new Date());
            }
          ),
        description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        expenses: Yup.array().of(
          Yup.object().shape({
            date: Yup.date()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
            description: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            amount: Yup.object().shape({
              value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
              currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            }),
          })
        ),
      }),
      other_insurance_covering: Yup.object().shape({
        is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        value: Yup.string().when(
          ['is_internal'],
          {
            is: is_internal => {
              return is_internal === 'true';
            },
            then: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          },
          ['is_internal']
        ),
      }),
    })
  );
