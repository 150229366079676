import moment from 'moment';
import { phoneService } from '../../../../../../services';
import { countryPrefixToInitials } from '../../../../../../intl/utils';
import { removeNonNumeric } from '../../../../../../utils/stringUtils';
import { getCorrectTranslateByCountry } from '../../../../../../utils/country';

export const formatForm = async (values, intl, card) => {
  let form = {};
  form.pacient = await formatPacient(values.pacient);
  form.appointment = appointment(values, intl);
  form.bank = formatBank(card);
  return form;
};

export const newFormatForm = async (values, intl, cardholder, country) => {
  const countryDetails = getCorrectTranslateByCountry(country);

  const form = {
    appointment_details: getAppointmentFormated(values, intl),
    legal_consents: getConsentsFormated(values),
    patient_info: await getPatientFormated(values.pacient, countryDetails),
    user_info: getPersonFormated(cardholder, countryDetails),
  };
  return form;
};

const getPersonFormated = (cardholder, country) => {
  const { person, phone } = cardholder;
  return {
    first_name: person.first_name,
    last_name: person.last_name,
    email: cardholder.email,
    title: person.title,
    birth_date: person.birth_date,
    nationalities: person.nationalities,
    phone: {
      phone_type: phone.phone_type,
      international_prefix: phone.international_prefix,
      number: phone.number,
    },
    preferred_language: country.idiom,
  };
};

const getConsentsFormated = values => {
  return [
    {
      code: 'unable_to_diagnose_by_phone',
      has_consented: values.appointment.confirmCannotDiagnosedThroughPhone,
    },
    {
      code: 'no_urgent_symptoms',
      has_consented: values.appointment.confirmNoUrgentSymptoms,
    },
    {
      code: 'iems_not_cover_medical_expenses',
      has_consented: values.appointment.confirmIEMSDoesntCoverMedicalExpenses,
    },
  ];
};

const getAppointmentFormated = (values, intl) => {
  return {
    type: 'COVID',
    date: `${moment(values.appointment.date).format('YYYY-MM-DD')} ${
      values.appointment.time
    }`,
    timezone: values.appointment.timezone,
    location: {
      country: values.appointment.country,
      state: values.appointment.state,
      subdivision: values.appointment.city,
    },
    initial_questions: newPresetQuestions(values.questions, intl),
  };
};

const formatBank = values => {
  return {
    issuerBank: values.issuer_name,
    cardProduct: values.name,
  };
};
const getPatientFormated = async (values, country) => {
  let prefix = values.phone_international_prefix;
  const countryByCode = countryPrefixToInitials(prefix);
  let phoneNumber = values.phone_number;

  // get formated number
  if (countryByCode) {
    phoneNumber = (
      await phoneService.getFormatPhone(
        removeNonNumeric(values.phone_number),
        countryByCode
      )
    ).data.number;
  }
  return {
    first_name: values.name,
    last_name: values.last_name,
    email: values.email,
    phone: {
      international_prefix: values.phone_international_prefix,
      number: phoneNumber,
      phone_type: values.phone_type,
    },
    preferred_language: country.idiom,
  };
};
const formatPacient = async values => {
  let prefix = values.phone_international_prefix;
  const countryByCode = countryPrefixToInitials(prefix);
  let phoneNumber = values.phone_number;

  // get formated number
  if (countryByCode) {
    phoneNumber = (
      await phoneService.getFormatPhone(
        removeNonNumeric(values.phone_number),
        countryByCode
      )
    ).data.number;
  }

  return {
    first_name: values.name,
    last_name: values.last_name,
    email: values.email,
    phone: `${values.phone_type} - ${values.phone_international_prefix} ${phoneNumber}`,
    comment: values.comment,
    langvalue: values.idiom,
  };
};

const appointment = (values, intl) => {
  const questions = concatQuestions(values.questions, intl);
  return {
    date: moment(values.appointment.date).format('YYYY-MM-DD'),
    time: values.appointment.time,
    validTime: values.appointment.validTime,
    country: values.appointment.country,
    state: values.appointment.state,
    city: values.appointment.city,
    timezone: values.appointment.timezone,
    presetQuestions: questions,
    confirmCannotDiagnosedThroughPhone:
      values.appointment.confirmCannotDiagnosedThroughPhone,
    confirmNoUrgentSymptoms: values.appointment.confirmNoUrgentSymptoms,
    confirmIEMSDoesntCoverMedicalExpenses:
      values.appointment.confirmIEMSDoesntCoverMedicalExpenses,
    appointment_type: 'covid_help_line',
  };
};

const concatQuestions = (values, intl) => {
  var arrayQuestions = [];

  values.questions.map(question => {
    if (question.value) {
      if (question.name === intl.TEXT_QUESTIONS_COVID_OTHER) {
        arrayQuestions.push(values.commentQuestions);
        return;
      }
      arrayQuestions.push(question.name);
    }
  });

  return arrayQuestions.join(' \n ');
};

const newPresetQuestions = (values, intl) => {
  const questionsFormated = [];

  values.questions.forEach(question => {
    if (question.value) {
      if (question.name === intl.TEXT_QUESTIONS_COVID_OTHER) {
        questionsFormated.push(values.commentQuestions);
      }
      questionsFormated.push(question.name);
    }
  });

  return questionsFormated;
};
