import React from 'react';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const PhoneArea = props => {
  return (
    <PhoneForm
      formikProps={props}
      inputNames={{
        phoneType: 'contact_phones[0].phone_type',
        countryCode: 'contact_phones[0].international_prefix',
        phoneNumber: 'contact_phones[0].number',
      }}
      testIds={{
        phoneType: 'phoneType',
        countryCode: 'InputClaiCHPrefix',
        phoneNumber: 'InputClaiCHNumber',
      }}
    />
  );
};

export default PhoneArea;
