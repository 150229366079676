import { useContext, useEffect } from 'react';
import styles from '../IncidentArea.module.scss';
import * as translations from '../intl';
import { IntlContext } from '../../../../../../../../../intl';
import { getCurrencyByHomeCountry } from '../../../../../../../../../utils/currency';
import ChargeItem from './ChargeItem/ChargeItem';
import TrashIcon from '../../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../../assets/icons/AddIcon';
import Button from '../../../../../../../../../componentsV2/Button/Button';
import GroupDivisor from '../../../../../../../../../components/GroupDivisor';

const ChargesList = props => {
  const { values, setFieldValue, setFieldTouched } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const addCharge = event => {
    if (event) event.preventDefault();
    const listCharges = [...values.incident.expenses];
    const newCharge = {
      type: 'PURCHASE_UNAUTHORIZED',
      date: '',
      description: '',
      amount: {
        value: '',
        currency: values.incident.expenses[0].amount.currency || '',
      },
    };
    listCharges.push(newCharge);
    setFieldValue('incident.expenses', listCharges);
  };
  const removeCharge = (event, index) => {
    event.preventDefault();

    const cloneProducts = [...values.incident.expenses];
    cloneProducts.splice(index, 1);
    setFieldValue('incident.expenses', cloneProducts);
    const newTotal =
      values.total_amount_claimed.value -
      values.incident.expenses[index].amount.value;
    setFieldValue('total_amount_claimed.value', newTotal.toFixed(2));
  };
  const setCurrency = currency => {
    setFieldValue('incident.expenses[0].amount.currency', currency);
    setFieldTouched('incident.expenses[0].amount.currency', true);
  };
  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
  }, []);

  return (
    <div className={`col-12 ${styles.marginBottom}`}>
      <div className={styles.buttonArea}>
        <Button
          type="Tertiary"
          onClick={addCharge}
          label={intl.BTN_ADD}
          leadingIcon={<AddIcon />}
        />
      </div>
      {values.incident.expenses.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-12">
            <GroupDivisor title={`${intl.TEXT_ITEM}${index + 1}`} />
          </div>
          <ChargeItem {...props} index={index} />
          <div className={`col-2 ${styles.footer}`}>
            {index !== 0 && (
              <Button
                type="Tertiary"
                testId={`buttonRemove-${index}`}
                onClick={event => removeCharge(event, index)}
                label={intl.BTN_REMOVE}
                leadingIcon={<TrashIcon width={24} height={24} />}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
export default ChargesList;
