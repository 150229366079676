import React, { useContext, useState } from 'react';
import styles from './OtherInsuranceArea.module.scss';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';
import { safeGet } from '../../../../../../../../utils/object';

const OtherInsuranceArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [alreadyCompensated, setAlreadyCompensated] = useState(false);
  const [otherInsurance, setOtherInsurance] = useState(false);
  const { values, handleChange, touched, handleBlur, errors, setFieldValue } =
    props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const currency = values.incident.expenses[0].amount.currency;
  const setEmptyOtherInsurance = () => {
    setFieldValue('other_insurance_covering[1].amount.value', '');
  };

  const setEmptyAlreadyCompensated = () => {
    setFieldValue('other_insurance_covering[0].amount.value', '');
  };

  return (
    <div className={`row ${styles.questionsArea}`}>
      <div className={`col-12`}>
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TITLE_ALREADY_COMPENSATED}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TITLE_ALREADY_COMPENSATED}
              describe={intl.TEXT_DESCRIBE_COMPENSATION}
              defaultChecked={false}
              text={intl.YES}
              name={`other_insurance_covering[0].amount.checked`}
              value={true}
              onChange={e => {
                setAlreadyCompensated(true);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TITLE_ALREADY_COMPENSATED}
              defaultChecked={true}
              text={intl.NO}
              name={`other_insurance_covering[0].amount.checked`}
              value={false}
              onChange={e => {
                setAlreadyCompensated(false);
                setEmptyAlreadyCompensated();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      {alreadyCompensated && (
        <>
          <div className="col-12 col-sm-10 col-md-6">
            <Input
              value={values.other_insurance_covering[0].amount.value}
              label={intl.INDICATE_AMOUNT_PAID_INSURANCE}
              name={`other_insurance_covering[0].amount.value`}
              type="text"
              maskedMoney
              onChange={handleChange}
              tooltipText={intl.TEXT_AMOUNT_DECIMAL}
              touched={safeGet(
                touched,
                'other_insurance_covering[0].amount.value'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'other_insurance_covering[0].amount.value'
              )}
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              dataTestid="selectOtherInsuranceCovering"
              label={intl.LABEL_CURRENCY}
              value={currency}
              name={`other_insurance_covering[0].amount.currency`}
              onChange={handleChange}
              disabled
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              touched={safeGet(
                touched,
                'other_insurance_covering[0].amount.currency'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'other_insurance_covering[0].amount.currency'
              )}
            />
          </div>
        </>
      )}
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TITLE_OTHER_INSURANCE}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TITLE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_YES}
              defaultChecked={false}
              text={intl.YES}
              name={`other_insurance_covering[1].amount.checked`}
              value={true}
              onChange={e => {
                setOtherInsurance(true);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TITLE_OTHER_INSURANCE}
              describe={intl.TEXT_DESCRIBE_OPTION_NO}
              defaultChecked={true}
              text={intl.NO}
              name={`other_insurance_covering[1].amount.checked`}
              value={false}
              onChange={e => {
                setOtherInsurance(false);
                setEmptyOtherInsurance();
                handleChange(e);
              }}
            />
          </div>
        </div>
      </div>
      {otherInsurance && (
        <>
          <div className="col-12 col-sm-10 col-md-6">
            <Input
              value={values.other_insurance_covering[1].amount.value}
              label={intl.INDICATE_AMOUNT_PAID_INSURANCE}
              name={`other_insurance_covering[1].amount.value`}
              type="text"
              maskedMoney
              onChange={handleChange}
              tooltipText={intl.TEXT_AMOUNT_DECIMAL}
              touched={safeGet(
                touched,
                'other_insurance_covering[1].amount.value'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'other_insurance_covering[1].amount.value'
              )}
            />
          </div>
          <div className={`col-12 col-sm-12 col-md-6`}>
            <Select
              dataTestid="selectOtherInsuranceCovering"
              label={intl.LABEL_CURRENCY}
              value={currency}
              name={`other_insurance_covering[1].amount.currency`}
              onChange={handleChange}
              disabled
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
                ),
              }}
              touched={safeGet(
                touched,
                'other_insurance_covering[1].amount.currency'
              )}
              onBlur={handleBlur}
              error={safeGet(
                errors,
                'other_insurance_covering[1].amount.currency'
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default OtherInsuranceArea;
