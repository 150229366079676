import React from 'react';
import IncidentArea from './IncidentArea/IncidentArea';
import PersonalArea from './PersonalArea/PersonalArea';
import ItemsArea from './ItemsArea/ItemsArea';

const BagProtection = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <ItemsArea claimDetails={claimDetails} />
    </div>
  );
};

export default BagProtection;
