import React, { useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../intl';
import styles from './ProfileHeader.module.scss';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const ProfileHeader = props => {
  const { title } = props;
  const { state } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div
      className={`${styles.headerProfile} ${styles[themes]}`}
      aria-label={intl.HEADER}
      data-testid="header"
    >
      <h3 className={`${styles.buttonProfile} ${styles[themes]}`}>
        {state.user.data.userInfo.firstName &&
        state.user.data.userInfo.firstName.length > 0
          ? state.user.data.userInfo.firstName.charAt(0)
          : ''}
      </h3>

      <h1 className={styles.welcome}>
        <span className={`${styles.welcomeTitle} ${styles[themes]}`}>
          {intl.TITLE_HI} {state.user.data.userInfo.firstName},
        </span>
        <span className={`${styles.welcomeSubtitle}  ${styles[themes]}`}>
          {title ? title : intl.TITLE_WELLCOME_PROFILE}
        </span>
      </h1>
    </div>
  );
};

export default ProfileHeader;
