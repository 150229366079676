export const LABEL_COMPANY_ACCOUNTS = {
  en_US: 'Total number of valid company accounts',
  es_ES: 'Número total de cuentas de empresa válidas',
  pt_BR: 'Número total de contas de empresas válidas',
};

export const LABEL_CARDHOLDER_CREDIT_LIMIT = {
  en_US: "Cardholder's credit limit",
  es_ES: 'Límite de crédito del titular de la tarjeta',
  pt_BR: 'Limite de crédito do titular do cartão',
};

export const LABEL_IS_PAYMENT_LAST_12_MONTHS = {
  en_US:
    'Has the cardholder made any payment on this account within the last 12 months which was not collectible?',
  es_ES:
    '¿Ha hecho el Titular de la Tarjeta algún pago en esta cuenta dentro de los últimos 12 meses que no fue coleccionable?',
  pt_BR:
    'O titular do cartão fez qualquer pagamento nessa conta nos últimos 12 meses, que não foi cobrado?',
};

export const LABEL_IS_RESULT_OF_STOLEN_CARD = {
  en_US: 'Is this claim the result of a lost / stolen card?',
  es_ES: '¿Es esta reclamación el resultado de una tarjeta perdida / robada?',
  pt_BR: 'Esta reivindicação é o resultado de um cartão perdido / roubado?',
};

export const LABEL_IS_RESULT_OF_BANKRUPTCY = {
  en_US: 'Is this claim the result of a bankruptcy / insolvency?',
  es_ES: '¿Es esta reclamación el resultado de una quiebra / insolvencia?',
  pt_BR: 'Esta reivindicação é o resultado de uma falência / insolvência?',
};

export const YES = {
  en_US: 'Yes',
  es_ES: 'Sí',
  pt_BR: 'Sim',
};

export const NO = {
  en_US: 'No',
  es_ES: 'No',
  pt_BR: 'Não',
};
