export const LABEL_STATUS = {
  REVIEW: 'REVIEW',
  REVIEW_BY_UNDERWRITER: 'REVIEW_BY_UNDERWRITER',
  COMPLETED: 'COMPLETED',
  CLOSED: 'CLOSED',
  CLOSED_DUPLICATED: 'CLOSED_DUPLICATED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  PENDING_DOCUMENTATION: 'PENDING_DOCUMENTATION',
};
