import { ExpectedDocument } from '../../@types/APIs/ExpectedDocuments';
import ExpectedDocumentCard from '../ExpectedDocumentCard';

interface ExpectedDocumentsListProps {
  expectedDocuments: ExpectedDocument[];
}

const ExpectedDocumentsList = ({
  expectedDocuments,
}: ExpectedDocumentsListProps) => {
  return (
    <ol>
      {expectedDocuments.map((doc, index) => (
        <ExpectedDocumentCard
          key={doc.document_type_name + index}
          description={doc.document_description}
          isMandatory={doc.is_mandatory}
          typeName={doc.document_type_name}
        />
      ))}
    </ol>
  );
};

export default ExpectedDocumentsList;
