import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from '../intl';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const incident = claimDetails && claimDetails.incident;

  const collisionCoverage = incident && incident.collision_coverage;

  const getCollisionCoverage = (title, intl) => {
    switch (title) {
      case true:
        return intl.TEXT_YES;
      case false:
        return intl.TEXT_NO;
      default:
        return '';
    }
  };

  const paymentBeneficiary = incident && incident.payment_beneficiary;

  const getPaymentBeneficiary = (title, intl) => {
    switch (title) {
      case 'Cardholder':
        return intl.LABEL_CARD_HOLDER;
      case 'Rental Company':
        return intl.LABEL_RENTAL_COMPANY;
      default:
        return '-';
    }
  };

  const amountEstimationDamage =
    claimDetails && claimDetails.amount_estimation_damage;

  const amountEstimationDamageValue =
    amountEstimationDamage && amountEstimationDamage.value
      ? amountEstimationDamage.value
      : '-';

  const amountEstimationDamageCurrency =
    amountEstimationDamage && amountEstimationDamage.currency
      ? amountEstimationDamage.currency
      : '-';

  const otherInsuranceCovering =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering !== null &&
    claimDetails.other_insurance_covering[0]
      ? claimDetails.other_insurance_covering[0]
      : [];

  const isInternal =
    otherInsuranceCovering && otherInsuranceCovering.is_internal;

  const otherInsurancePaid =
    otherInsuranceCovering &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.value
      ? otherInsuranceCovering.amount.value
      : '-';
  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_COLISION}
          value={getCollisionCoverage(collisionCoverage, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_BENEFITS_PAYABLE}
          value={getPaymentBeneficiary(paymentBeneficiary, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.LABEL_DAMAGE}
          value={`${amountEstimationDamageValue} - ${amountEstimationDamageCurrency}`}
        />
      </div>

      {isInternal === false && (
        <div className="col-sm-12 col-md-3">
          <InfoLabelValue
            label={intl.LABEL_AMOUNT_PAID}
            value={`${otherInsurancePaid} - ${amountEstimationDamageCurrency}`}
          />
        </div>
      )}
    </>
  );
};

export default PersonalArea;
