/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { IntlContext } from '../../../intl';
import { useHistory } from 'react-router-dom';
import * as translations from './intl';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { isBinNotActived } from '../../../utils/card';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { showModalAddCard } from '../../ModalAddCard/functionShowModal';

const NotificationBinExpired = () => {
  const { idiom, translate, country } = useContext(IntlContext);
  const {
    utils: { getSelectedCard },
    actions,
  } = useContext(StateContext);
  const intl = translate(translations);
  const selectedCard = getSelectedCard();
  const { location } = useHistory();
  const toastId = useRef(null);

  const content = (
    <>
      <span
        role="status"
        dangerouslySetInnerHTML={{ __html: intl.TEXT_INSERT_PAN }}
      ></span>
      <span
        onClick={() => showModalAddCard(actions, intl)}
        dangerouslySetInnerHTML={{ __html: intl.CLICK_HERE }}
      ></span>
    </>
  );

  const showNotification = () => {
    toastId.current = toast.warn(content, {
      containerId: 'NotificationBinExpired',
      closeButton: false,
      onClose: () => {
        showNotification();
      },
    });
  };

  const hideNotification = () => {};

  useEffect(() => {
    if (
      isBinNotActived(selectedCard) &&
      !location.pathname.includes('session-expired')
    ) {
      showNotification();
    } else {
      hideNotification();
    }
  }, [idiom, country, selectedCard]);

  useEffect(() => {
    if (location.pathname.includes('session-expired')) {
      hideNotification();
    }
  }, [location.pathname]);

  return (
    <ToastContainer
      enableMultiContainer
      containerId="NotificationBinExpired"
      position={toast.POSITION.TOP_CENTER}
      autoClose={false}
      transition={Slide}
      newestOnTop={true}
      closeOnClick={false}
      draggable={false}
      theme="colored"
      limit={1}
    />
  );
};

export default NotificationBinExpired;
