import React, { useContext } from 'react';
import styles from './Step1.module.scss';
import CheckBox from '../../../../components/CheckBox/CheckBox';
import Button from '../../../../componentsV2/Button/Button';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../utils/sanitize';
import PDFIcon from '../../../../assets/icons/PDFIcon';
import Link from '../../../../componentsV2/Link/Link';

const Step1 = props => {
  const { nextStep, intl, errors, values, touched, setFieldValue } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.stepOne}>
      <h2 className={styles[themes]}>{intl.TITLE_ACCEPT_TERMS}</h2>

      <div className={styles.boxPDFs}>
        <div className={styles.pdf}>
          <PDFIcon
            width={42}
            height={54}
            aria-label="PDF"
            className={styles.pdfIcon}
          />
          <Link href={intl.TEXT_LINK_PDF} label={intl.PDF_TERMS_OF_SERVICE} />
        </div>

        <div className={styles.pdf}>
          <PDFIcon
            width={42}
            height={54}
            aria-label="PDF"
            className={styles.pdfIcon}
          />
          <Link
            href={intl.TEXT_LINK_PRIVACY_POLICY}
            label={intl.TEXT_VISA_GLOBAL_PRIVACY}
          />
        </div>

        <div className={styles.pdf}>
          <PDFIcon
            width={42}
            height={54}
            aria-label="PDF"
            className={styles.pdfIcon}
          />
          <Link
            href={intl.TEXT_LINK_PDF_SUPPLEMENTARY}
            label={intl.PDF_SUPLEMENTARY_PRIVACE_NOTES}
          />
        </div>
      </div>

      <div className={`${styles.boxCheckBoxs} ${styles[themes]}`}>
        <CheckBox
          type="checkbox"
          value={values.AcceptTermsConditions}
          error={errors.AcceptTermsConditions}
          touched={touched.AcceptTermsConditions}
          onClick={value => setFieldValue('AcceptTermsConditions', value)}
          dataTestid="checkTerms"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(intl.TEXT_ACCEPT_TERMS),
            }}
          ></span>
        </CheckBox>

        <CheckBox
          type="checkbox"
          value={values.AceptPrivacyNotice}
          error={errors.AceptPrivacyNotice}
          touched={touched.AceptPrivacyNotice}
          onClick={value => setFieldValue('AceptPrivacyNotice', value)}
          dataTestid="checkPrivacyNotice"
        >
          <span>{intl.TEXT_ACCEPT_TERMS_2} </span>
        </CheckBox>

        <CheckBox
          type="checkbox"
          value={values.AceptSupplementaryPrivacyNotice}
          error={errors.AceptSupplementaryPrivacyNotice}
          touched={touched.AceptSupplementaryPrivacyNotice}
          onClick={value =>
            setFieldValue('AceptSupplementaryPrivacyNotice', value)
          }
          dataTestid="checkSupplementaryPrivacyNotice"
        >
          <span>{intl.TEXT_ACCEPT_TERMS_3} </span>
        </CheckBox>
      </div>

      <div className={styles.footerBtn}>
        <p>{intl.TEXT_IF_NOT_ACCEPT_TERMS}</p>
        <div className={styles.buttonArea}>
          <Button
            disabled={
              !values.AcceptTermsConditions ||
              !values.AceptPrivacyNotice ||
              !values.AceptSupplementaryPrivacyNotice
            }
            onClick={e => nextStep(e, 2)}
            label={intl.BTN_NEXT}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
