export const TEXT_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete de viagem',
};

export const TEXT_CERTIFICATES_GENERATED = {
  en_US: 'Certificates generated',
  es_ES: 'Certificados generados',
  pt_BR: 'Bilhetes de seguro gerados',
};

export const TEXT_VIEW_YOUR_CERTIFICATE = {
  en_US: 'View my certificates',
  es_ES: 'Ver mis certificados',
  pt_BR: 'Ver meus bilhetes',
};

export const TEXT_BENEFITS_OFFERED = {
  en_US: 'Benefits offered',
  es_ES: 'Beneficios ofrecidos',
  pt_BR: 'Benefícios oferecidos',
};

export const TEXT_BENEFITS_ENJOYED = {
  en_US: 'Benefits enjoyed',
  es_ES: 'Beneficios disfrutados',
  pt_BR: 'Benefícios utilizados',
};

export const TEXT_VIEW_BENEFITS = {
  en_US: 'View my benefits',
  es_ES: 'Ver mis beneficios',
  pt_BR: 'Ver meus benefícios',
};

export const TEXT_RECENT_CERTIFICATES = {
  en_US: 'Recent certificates',
  es_ES: 'Certificados recientes',
  pt_BR: 'Bilhetes recentes',
};

export const ADD_CERTIFICATE = {
  en_US: 'Add Certificate',
  es_ES: 'Agregar Certificado',
  pt_BR: 'Adicionar Certificado',
};
