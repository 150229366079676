export const BTN_VIEW_CLAIM = {
  en_US: 'View claim',
  es_ES: 'Ver reclamo',
  pt_BR: 'Ver sinistro',
};

export const BTN_ARCHIVE = {
  en_US: 'Archive',
  es_ES: 'Archivar',
  pt_BR: 'Arquivar',
};

export const TEXT_EMPTY_NOTIFICATIONS = {
  en_US: 'You do not currently have any notification.',
  es_ES: 'Actualmente no tienes ninguna notificación.',
  pt_BR: 'No momento, você não tem nenhuma notificação.',
};

export const BTN_UNARCHIVE = {
  en_US: 'Unarchive',
  es_ES: 'Desarchivar',
  pt_BR: 'Desarquivar',
};

export const TITLE_CLAIM_VIEW = {
  en_US: benefitName => `View claim - ${benefitName}`,
  pt_BR: benefitName => `Visualizar sinistro - ${benefitName}`,
  es_ES: benefitName => `Ver reclamo - ${benefitName}`,
};

export const BTN_FLIGHT_DETAILS = {
  en_US: 'Flight details',
  es_ES: 'Detalles de vuelo',
  pt_BR: 'Detalhes de voo',
};

export const BTN_VIEW_FLIGHT_DETAILS = {
  en_US: 'View flight details',
  es_ES: 'Ver detalles de vuelo',
  pt_BR: 'Ver detalhes de voo',
};

export const BTN_VIEW_TRAVEL_BENEFITS = {
  en_US: 'View travel benefits',
  pt_BR: 'Ver beneficios de viaje',
  es_ES: 'Ver benefícios de viagem',
};

export const IMAGE_ARROW = {
  en_US: 'Image Arrow',
  es_ES: 'Flecha de Imagen',
  pt_BR: 'Seta de Imagem',
};
