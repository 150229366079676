import { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import styles from './index.module.scss';

interface ProgressBarProps {
  className?: string;
  progress: number; // From 0 to 100
  hasError?: boolean; // If true will show a red bar
}

const ProgressBar = ({ className, progress, hasError }: ProgressBarProps) => {
  if (hasError) progress = 100;

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const getColorClass = (isCompleted: boolean) => {
    if (hasError) return styles.alert;
    return isCompleted ? styles.green : styles.secondary;
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={`${styles.line} ${styles.secondary} ${styles[theme]}`} />
      <div
        style={{ width: `${progress.toFixed(2)}%` }}
        className={`${styles.line} ${getColorClass(true)} ${styles[theme]}`}
      />
      <div
        className={`${styles.circle} ${styles.leftCircle} ${getColorClass(
          progress > 0
        )} ${styles[theme]}`}
      />
      <div
        className={`${styles.circle} ${styles.rightCircle} ${getColorClass(
          progress >= 100
        )} ${styles[theme]}`}
      />
    </div>
  );
};

export default ProgressBar;
