export const LABEL_TRIP_PERIOD = {
  en_US: 'Trip period',
  pt_BR: 'Período da viagem',
  es_ES: 'Período de viaje',
};

export const LABEL_DATE_ARRIVAL = {
  en_US: 'Date / time of arrival at destination',
  pt_BR: 'Data / hora de chegada',
  es_ES: 'Fecha / hora de llegada',
};

export const LABEL_DATE_RECEIVED_BAGGAGE = {
  en_US: 'Date / time of receipt of delayed luggage',
  pt_BR: 'Data / hora da recepção da bagagem atrasada',
  es_ES: 'Fecha / hora de recepción de la demora de equipaje',
};

export const LABEL_NAME_AGENCY = {
  en_US: 'Name of airline / vessel',
  pt_BR: 'Nome da companhia aérea / embarcação',
  es_ES: 'Nombre de la compañia aérea / barco',
};

export const LABEL_TRANSPORTATION_ID = {
  en_US: 'Number of the airline / vessel',
  pt_BR: 'Número da companhia aérea / embarcação',
  es_ES: 'Número de la compañia aérea / barco',
};

export const LABEL_AMOUNT_CLAIMED = {
  en_US: 'Total amount claimed',
  pt_BR: 'Valor total do sinistro',
  es_ES: 'Importe total de la reivindicación',
};

export const LABEL_PHONE_TYPE_LN = {
  en_US: 'Landline',
  pt_BR: 'Fixo',
  es_ES: 'Fijo',
};

export const LABEL_PHONE_TYPE_MB = {
  en_US: 'Mobile',
  pt_BR: 'Celular',
  es_ES: 'Móvil',
};
