import { useCallback, useContext } from 'react';
import { withRouter } from 'react-router';
import { Formik } from 'formik';
import { tokenexService } from '../../../services';
import styles from './InputTokenex.module.scss';
import Button from '../../../componentsV2/Button/Button';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import Input from '../../../components/Input/Input';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../ModalInformation/ModalInformation';
import { cardsServices } from '../../../services';
import { showModalAddCard } from '../../ModalAddCard/functionShowModal';
import { useMemo } from 'react';
import getValidationSchema from './validationSchema';
import {
  formatEffectiveEndDate,
  generatePanToken,
  getCardDuplicated,
  resetCardsSessionStorage,
} from './utils';
import {
  ModalConfirm,
  ModalError,
  ModalErrorTokenDuplicated,
  ModalSuccess,
} from './modals';
import useScript from '../../../hooks/useScript';
import { NotificationContext } from '../../NotificationContextParent/NotificationContextParent';
import { API_TOKENEX_ENDPOINT } from '../../../utils/environments_variables';
import { AppInsightTrackContext } from '../../AppInsightTrackContextParent/AppInsightTrackContextParent';

const InputTokenex = ({ bin, card, history }) => {
  const { actions, utils, state } = useContext(StateContext);
  const { actions: notificationActions } = useContext(NotificationContext);
  const { translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const intl = translate(translations);
  const cn = utils.getCn();
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const infoUser = {
    customer_id: cn,
    policy_id: card.policy_id,
    external_reference: bin,
  };
  const showExpirationDate = false;

  const tokenexPublicKey = process.env.REACT_APP_TOKENEX_PUBLIC_KEY;
  useScript(`${API_TOKENEX_ENDPOINT}/inpage/js/TokenEx-Lite.js`);

  const changePreferredCard = useCallback(
    async (infoCard, mask_external_reference) => {
      try {
        actions.modal.showLoading(
          intl.CHANGING_CARD(`${mask_external_reference} - ${infoCard.name}`)
        );
        const { cn, name, external_reference } = infoCard;
        await cardsServices.changePreferredCard(cn, name, external_reference);

        await actions.cards.changeSelectedCardIndex(external_reference);
        notificationActions.toastNotification.setResetAppereanceToast();
        actions.modal.hideLoading();
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="success"
            message={intl.TEXT_CONGRATULATIONS(
              `${mask_external_reference} - ${infoCard.name}`
            )}
            clickBtn={() => {
              actions.modal.closeModal();
            }}
          />,
          true,
          true
        );
      } catch (error) {
        actions.modal.hideLoading();
        actions.modal.showModal(false, true, <ModalError intl={intl} />, true);
      }
    },
    [intl]
  );

  const modalDuplicatedCreditCard = useCallback(
    card => {
      const formattedBin = card.external_reference.replace(
        new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
        '$1.****.$3**.****'
      );

      const infoCard = {
        cn,
        name: card.name,
        external_reference: card.external_reference,
        issuer_name: card.issuer_name,
      };

      return actions.modal.showModal(
        false,
        false,
        <ModalErrorTokenDuplicated
          intl={intl}
          formattedBin={formattedBin}
          card={infoCard}
          confirmCallback={() => changePreferredCard(infoCard, formattedBin)}
        />,
        true,
        false
      );
    },
    [changePreferredCard, intl]
  );

  const submitPan = async values => {
    trackEventUserAction(`#### (PAN) SUBMIT`, true);

    try {
      actions.modal.closeModal();
      actions.loadingFullScreen.showLoadingFullScreen(intl.SAVING_YOUR_CARD);

      const token = await generatePanToken(trackEventUserAction, values.pan);
      trackEventUserAction(`#### (PAN) TOKEN GERADO`, { token });

      const isDuplicated = getCardDuplicated(state.cards, token);
      if (isDuplicated) {
        trackEventUserAction(`#### (PAN) TOKEN DUPLICADO NO USUÁRIO`, {
          token,
        });
        actions.loadingFullScreen.hideLoadingFullScreen();
        modalDuplicatedCreditCard(isDuplicated);
        return;
      }

      trackEventUserAction(`#### (PAN) PAN SERÁ ATUALIZADO`, {
        token,
      });
      const response = await tokenexService.updateBinWithTokenex(
        infoUser,
        formatEffectiveEndDate(values.effective_end_date, trackEventUserAction),
        token
      );

      await resetCardsSessionStorage(
        actions,
        <ModalSuccess intl={intl} actions={actions} history={history} />
      );

      trackEventUserAction(`#### (PAN) PAN ATUALIZADO`, response);
    } catch (error) {
      actions.loadingFullScreen.hideLoadingFullScreen();
      trackEventUserAction(`#### (PAN) ERROR TO UPDATE THE PAN`, {
        error,
        message: error.message,
        error_response: error.response,
        data: error && error.response && error.response.data,
      });

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description === 'Duplicated Credit Card Token'
      ) {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.ALREADY_USED_ERROR_OCCURRED}
          />,
          true
        );
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error_description === 'Invalid credit card token'
      ) {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation type="error" message={intl.LENGTH_16_DIGITS} />,
          true
        );
      } else if (
        error &&
        error.message === '2100 : Input data not Luhn compatible'
      ) {
        actions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.ALREADY_USED_ERROR_OCCURRED}
          />,
          true
        );
      } else {
        actions.modal.showModal(false, true, <ModalError intl={intl} />, true);
      }
    }
  };

  const handleSubmit = useCallback(
    async values => {
      actions.modal.showModal(
        false,
        false,
        <ModalConfirm intl={intl} submit={() => submitPan(values)} />,
        true,
        false
      );
    },
    [intl]
  );

  const validationSchema = useMemo(
    () => getValidationSchema(intl, showExpirationDate),
    [getValidationSchema]
  );

  return (
    <Formik
      initialValues={{
        effective_end_date: '',
        bin,
        pan: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <div className={`${styles.tokenex}`}>
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <Input
                  disabled
                  label={intl.LABEL_10_DIGITS}
                  value={card && card.external_reference}
                />
              </div>
            </div>
            <div className={`${styles.boxForm} row`}>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <Input
                  label={intl.LABEL_16_DIGITS}
                  name="pan"
                  mask="maskedCreditCard16Digits"
                  formikProps={props}
                />
              </div>
              {showExpirationDate && (
                <div className={`col-12 col-sm-6 col-md-4 col-lg-4`}>
                  <Input
                    errorLeftAlign
                    labelFlex
                    label={intl.LABEL_EXPIRATION_DATE}
                    name="effective_end_date"
                    formikProps={props}
                    mask="maskedExpirationDate"
                  />
                </div>
              )}
            </div>

            <p>{intl.TEXT_WARNING_VISA}</p>
            <div className={`${styles.btnFooter} ${styles[themes]}`}>
              <Button
                type="Secondary"
                onClick={event => {
                  event.preventDefault();
                  showModalAddCard(actions, intl);
                }}
                label={intl.TEXT_ADD_NEW_CARD}
              />
              <Button
                testId="ButtonSubmitUpdateTokenex"
                typeHtml="submit"
                label={intl.TEXT_UPDATE_BUTTON}
              />
            </div>
            <input
              id="TxEncryptionKey"
              type="hidden"
              value={tokenexPublicKey}
            />
          </form>
        </div>
      )}
    </Formik>
  );
};

export default withRouter(InputTokenex);
