import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js';

import { createBrowserHistory } from 'history';
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_INSIGHTS_INSTRUMENTATIONKEY ||
      'e4fbde9b-eff2-4a36-9081-d4f4ad47accd',
    disableExceptionTracking: true,
    extensions: [reactPlugin],
    disableFetchTracking: false,
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: createBrowserHistory(),
      },
    },
  },
});

if (process.env.NODE_ENV !== 'test') ai.loadAppInsights();

const withTracking = withAITracking;
export default Component =>
  withTracking ? withTracking(reactPlugin, Component) : Component;
export const appInsights = ai.appInsights;
export { reactPlugin };
