/* eslint-disable no-unused-vars */

import moment from 'moment';
import { safeGet } from '../../../../../../../utils/object';
import { toFixedOrDefault } from '../../../../../../../utils/numbers';

const defaultItem = {
  manufacturer: '',
  model_number: '',
  status: 'Damage',
  is_repairable: 'false',
  purchase_date: '',
  price: {
    value: '',
    currency: '',
  },
  amount_claimed: {
    value: '',
    currency: '',
  },
  other_insurance_covering: {
    is_internal: 'false',
    amount: {
      value: '',
      currency: '',
    },
  },
  was_fully_charged_on_card: 'true',
};
const getDefaultItem = () => ({ ...defaultItem });

const getPrice = price => ({
  value: price && price.value ? String(toFixedOrDefault(price.value)) : '',
  currency: price && price.currency ? price.currency : '',
});

const getPurchaseProtectionItem = item => {
  const formattedPurchaseDate = new Date(moment(item.purchase_date).format());
  return {
    purchase_date: (formattedPurchaseDate && formattedPurchaseDate) || '',
    manufacturer: item.manufacturer || '',
    model_number: item.model_number || '',
    status: item.status || 'Damage',
    is_repairable: item.is_repairable ? String(item.is_repairable) : 'false',
    was_fully_charged_on_card:
      item.was_fully_charged_on_card || item.was_fully_charged_on_card === false
        ? String(item.was_fully_charged_on_card)
        : 'true',
    price: getPrice(item && item.price),
    amount_claimed: getPrice(item && item.amount_claimed),
    other_insurance_covering: getOtherInsuranceCovering(
      (item.other_insurance_covering && item.other_insurance_covering[0]) || ''
    ),
  };
};

export const getOtherInsuranceCovering = otherInsuranceCovering => ({
  is_internal:
    otherInsuranceCovering &&
    otherInsuranceCovering.is_internal !== undefined &&
    otherInsuranceCovering.amount &&
    otherInsuranceCovering.amount.value
      ? String(!otherInsuranceCovering.is_internal)
      : 'false',
  amount: getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
});

const getIncident = incident => ({
  dateValue:
    incident && incident.date
      ? new Date(moment(incident.date, 'DD/MM/YYYY'))
      : '',
  description: incident && incident.description ? incident.description : '',
});

export const cellPhoneProtectionFormInitialValues = (
  data,
  idiom,
  loadedData
) => {
  let person = data && data.person ? data.person : '';
  const cellPhone = safeGet(loadedData, 'purchase_protection_items[0]', null);
  const affectedPerson = safeGet(loadedData, 'affected_person', null);
  const totalAmountClaimed = safeGet(loadedData, 'total_amount_claimed', null);
  const incident = safeGet(loadedData, 'incident', {});

  return {
    purchase_protection_items: cellPhone
      ? getPurchaseProtectionItem(cellPhone)
      : getDefaultItem(),
    incident: getIncident(incident),
    affected_person: getAffectedPerson(person, affectedPerson),
    total_amount_claimed: getPrice(totalAmountClaimed),
  };
};

const getAffectedPerson = (cardholder, affectedPerson) => ({
  relationship:
    affectedPerson && affectedPerson.relationship
      ? affectedPerson.relationship
      : 'Cardholder',
  comment:
    affectedPerson && affectedPerson.comment ? affectedPerson.comment : '',
  person: getPersonName(cardholder, affectedPerson),
});

const getPersonName = (cardholder, affectedPerson) => {
  const person =
    affectedPerson && affectedPerson.person
      ? affectedPerson.person
      : cardholder;
  return {
    first_name: person && person.first_name ? person.first_name : '',
    last_name: person && person.last_name ? person.last_name : '',
  };
};
