export const TEXT_SELECT_ALL = {
  en_US: 'Select All',
  es_ES: 'Seleccionar todo',
  pt_BR: 'Selecionar tudo',
};

export const TEXT_ARCHIVE_SELECTED = {
  en_US: 'Archive selected',
  es_ES: 'Archivar la selección',
  pt_BR: 'Arquivar as selecionadas',
};

export const TEXT_UNARCHIVE_SELECTED = {
  en_US: 'Unarchive selected',
  es_ES: 'Desarchivar la selección',
  pt_BR: 'Desarquivar as selecionadas',
};

export const TEXT_RECEIVED = {
  en_US: 'Received',
  es_ES: 'Recibidas',
  pt_BR: 'Recebidas',
};

export const TEXT_DELETE = {
  en_US: 'Are you sure you want to delete these notifications?',
  es_ES: '¿Está seguro de que desea eliminar estas notificaciones?',
  pt_BR: 'Tem certeza de que deseja excluir essas notificações?',
};

export const BTN_DELETE = {
  en_US: 'Delete',
  es_ES: 'Eliminar',
  pt_BR: 'Excluir',
};

export const BTN_DELETE_MODAL = {
  en_US: 'Delete',
  es_ES: 'Eliminar',
  pt_BR: 'Excluir',
};

export const BTN_CONFIRM_MODAL = {
  en_US: 'No, keep notifications',
  es_ES: 'No, mantener las notificaciones',
  pt_BR: 'Não, manter notificações',
};

export const SUBTITLE_NO_REVERSED = {
  en_US: 'This action cannot be reversed.',
  es_ES: 'Esta acción no se puede revertir.',
  pt_BR: 'Esta ação não pode ser revertida.',
};

export const UNARCHIVE = {
  en_US: 'Unarchive',
  es_ES: 'Desarchivar',
  pt_BR: 'Desarquivar',
};

export const ARCHIVE = {
  en_US: 'Archive',
  es_ES: 'Archivar',
  pt_BR: 'Arquivar',
};
