export const TEXT_INCIDENT_AREA_TITLE = {
  en_US: 'Incident date',
  pt_BR: 'Data do incidente',
  es_ES: 'Fecha del incidente',
};

export const DEPARTURE_DATE = {
  en_US: 'Departure date',
  pt_BR: 'Data de partida',
  es_ES: 'Fecha de salida',
};

export const DEPARTURE_TIME = {
  en_US: 'Departure time',
  pt_BR: 'Hora de partida',
  es_ES: 'Hora de salida',
};

export const ARRIVAL_DATE = {
  en_US: 'Arrival date',
  pt_BR: 'Data de chegada',
  es_ES: 'Fecha de llegada',
};

export const ARRIVAL_TIME = {
  en_US: 'Arrival time',
  pt_BR: 'Hora de chegada',
  es_ES: 'Hora de llegada',
};

export const MISSED_CONNECTION_PORTION = {
  en_US: 'Missed connection portion',
  pt_BR: 'Trajeto referente a perda de conexão',
  es_ES: 'Trayecto perdido',
};

export const MISSED_CONNECTION = {
  en_US: 'Missed connection',
  pt_BR: 'Perda de conexão',
  es_ES: 'Pérdida de conexión',
};

export const MISSED_CONNECTION_AIRLINE = {
  en_US: 'Missed connection airline',
  pt_BR: 'Companhia aérea da perda de conexão',
  es_ES: 'Aerolínea de pérdida de conexión',
};

export const MISSED_CONNECTION_DATE = {
  en_US: 'Missed connection date',
  pt_BR: 'Data da perda de conexão',
  es_ES: 'Fecha de pérdida de conexión',
};

export const MISSED_CONNECTION_TIME = {
  en_US: 'Missed connection time',
  pt_BR: 'Hora da perda de conexão',
  es_ES: 'Hora de pérdida de conexión',
};

export const DESCRIBE_REASONS = {
  en_US: 'Describe the reasons that led the missed connection',
  pt_BR: 'Descreva brevemente os motivos que causaram a perda de conexão',
  es_ES: 'Describa los motivos que provocaron la pérdida de conexión',
};

export const PRIOR_MISSED_CONNECTION = {
  en_US: 'Portion prior to missed connection',
  pt_BR: 'Primeiro trajeto antes da perda de conexão',
  es_ES: 'Primer trayecto antes de la pérdida de conexión',
};
