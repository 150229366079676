import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Textarea from '../../../../../../../../components/Textarea/Textarea';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from '../ATM.module.scss';
import { safeGet } from '../../../../../../../../utils/object';

import * as translations from './intl';
const IncidentPeriodArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors, setFieldTouched } =
    props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <div className={`${styles.fontGray12} ${styles.options} row`}>
          <div className="col-12 col-sm-12 col-md-12">
            <Textarea
              value={values.incident.description}
              label={intl.DESCRIPTION_INCIDENT}
              name="incident.description"
              onChange={handleChange}
              touched={safeGet(touched, 'incident.description')}
              onBlur={handleBlur}
              error={safeGet(errors, 'incident.description')}
              maxLength="255"
              dataTestid="inputDescription"
            />
          </div>

          <div className="col-lg-6 col-sm-12">
            <Calendar
              label={intl.INCIDENT_DATE}
              name="incident.date"
              maxDate={new Date()}
              formikProps={props}
              setFieldTouched={setFieldTouched}
              dataTestid="inputIncidentDate"
            />
          </div>

          <div className="col-lg-6 col-sm-12">
            <Input
              label={intl.INCIDENT_TIME}
              name="incident.hour"
              mask="maskedHour"
              formikProps={props}
              dataTestid="inputIncidentTime"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentPeriodArea;
