import { Fragment, useState } from 'react';
import FaqItem, { FaqListItem } from './FaqItem';
import styles from './index.module.scss';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { useContext } from 'react';
import { ImprovementOptions } from '../../@types/APIs/Faq';
import SearchIcon from '../../assets/icons/SearchIcon';
import Input from '../Input/Input';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { searchInclusive } from '../../utils/search';

interface FaqProps {
  items: FaqListItem[];
  showFeedback?: boolean;
  filterSelectedId?: string;
  faqImprovementOptions: ImprovementOptions[];
}

const FaqList = ({
  items,
  showFeedback,
  filterSelectedId,
  faqImprovementOptions,
}: FaqProps) => {
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchBy, setSearchBy] = useState('');
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme, getCurrentThemeColors } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const colors = getCurrentThemeColors();
  const intl = translate(translations);
  if (!items) return null;

  const uniqueCategories = new Set<string>();
  items.forEach(item => {
    if (item.category && item.question && item.answer) {
      uniqueCategories.add(item.category);
    }
  });
  const sortByCategory = (arr: FaqListItem[]) => {
    if (items) {
      return arr.sort((a: any, b: any) => {
        if (a.category < b.category) return -1;
        if (a.category > b.category) return 1;
        return 0;
      });
    } else return null;
  };
  const sortedFAQ = sortByCategory(items);
  const uniqueCategoriesArray = Array.from(uniqueCategories).sort();
  const handleCategoryClick = (category: string) => {
    if (category === categoryFilter) {
      setCategoryFilter('');
    } else setCategoryFilter(category);
    const mainContent = document.getElementById('mainContent');
    if (mainContent) {
      mainContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const translateCategory = (engCategory: string) => {
    switch (engCategory) {
      case 'BENEFIT':
        return intl.BENEFIT;
      case 'CLAIM':
        return intl.CLAIM;
      case 'CERTIFICATE':
        return intl.CERTIFICATE;
      case 'MEDICAL':
        return intl.MEDICAL_ASSISTANCE;
      default:
        return engCategory;
    }
  };

  let previousCategory = null;

  const handleInputChange = event => {
    setSearchBy(event.target.value);
  };

  const filterByCategory = item => item.category === categoryFilter;
  const filterBySearch = item => {
    return (
      searchInclusive(item.question, searchBy) ||
      searchInclusive(item.answer, searchBy)
    );
  };

  return (
    <div className={styles.faqContainer}>
      {uniqueCategoriesArray && uniqueCategoriesArray.length > 0 && (
        <aside className={styles.menuList}>
          <Input
            type="text"
            label={intl.SEARCH_PLACEHOLDER}
            value={searchBy}
            onChange={handleInputChange}
            Icon={() => (
              <SearchIcon
                aria-hidden={true}
                color={colors['secondary-secondary']}
              />
            )}
            hideTopLabel
          />

          <ul>
            {uniqueCategoriesArray.map((category: string, index: number) => (
              <li
                className={`${
                  categoryFilter === category ? styles.selected : styles.pointer
                } ${styles[theme]}`}
                key={index}
                onClick={() => handleCategoryClick(category)}
              >
                {translateCategory(category)}
              </li>
            ))}
          </ul>
        </aside>
      )}

      <div className={styles.faqDiv}>
        {categoryFilter && categoryFilter !== '' && (
          <h2>{translateCategory(categoryFilter)}</h2>
        )}
        {categoryFilter
          ? sortedFAQ
              .filter(filterByCategory)
              .filter(filterBySearch)
              .map(filteredItem => (
                <FaqItem
                  key={filteredItem.id || filteredItem.question}
                  item={filteredItem}
                  showFeedback={showFeedback}
                  filterSelectedId={filterSelectedId}
                  faqImprovementOptions={faqImprovementOptions}
                  searchBy={searchBy}
                />
              ))
          : searchBy !== ''
          ? sortedFAQ
              .filter(filterBySearch)
              .map(filteredItem => (
                <FaqItem
                  key={filteredItem.id || filteredItem.question}
                  item={filteredItem}
                  showFeedback={showFeedback}
                  filterSelectedId={filterSelectedId}
                  faqImprovementOptions={faqImprovementOptions}
                  searchBy={searchBy}
                />
              ))
          : sortedFAQ.map(item => {
              const showCategoryHeader = item.category !== previousCategory;
              previousCategory = item.category;
              return (
                <Fragment key={item.id || item.question}>
                  {showCategoryHeader && (
                    <h2>{translateCategory(item.category)}</h2>
                  )}
                  <FaqItem
                    item={item}
                    showFeedback={showFeedback}
                    filterSelectedId={filterSelectedId}
                    faqImprovementOptions={faqImprovementOptions}
                    searchBy={searchBy}
                  />
                </Fragment>
              );
            })}
      </div>
    </div>
  );
};

export default FaqList;

export type { FaqListItem };
