export const DOCUMENT_PROVIDED_300 = {
  en_US:
    'If the documentation provided is complete and correct, the indemnity amount will be USD $300 per passenger for Visa Infinite / Visa Business Infinite.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser indenizado será de USD $300 por passageiro para Visa Infinite / Visa Business Infinite.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, el monto a indemnizar será de USD $300 por pasajero afectado para Visa Infinite / Visa Business Infinite.',
};

export const DOCUMENT_PROVIDED_2000 = {
  en_US:
    'If the documentation provided is complete and correct, the amount claimed per person will be indemnified up to a maximum amount of USD $2000.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor reivindicado por pessoa será indenizado até um valor máximo de USD $2000.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, se indemnizará el monto reclamado por persona hasta un monto máximo de USD $2000.',
};
