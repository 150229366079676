import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

let from_date_generic;
let to_date_generic;
export const baggageDelaySchema = intl =>
  Yup.object().shape({
    travel_information: Yup.lazy(value => {
      from_date_generic = value.from_date;
      to_date_generic = value.to_date;
      return Yup.object().shape({
        from_date: Yup.date()
          .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        to_date: Yup.mixed()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('match', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isSameOrAfter(this.parent.from_date, 'day');
          }),
        travel_agency: Yup.object().shape({
          name: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
          phone: Yup.object().shape({
            phone_type: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
            international_prefix: Yup.string().required(
              intl.TEXT_FIELD_REQUIRED
            ),
            number: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
          }),
        }),
        is_full_charged_on_card: Yup.boolean()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }),
    incident: Yup.lazy(value => {
      let incidentYup = {};
      if (value.wasBaggageReceived === 'false') {
        incidentYup.description = Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255);
        incidentYup.baggage_arrived_at = Yup.string().nullable();
        incidentYup.discovered_at = Yup.date()
          .test(
            'match',
            intl.VALIDATE_DATE_BETWEEN_START_END_TRIP,
            function (date) {
              return (
                moment(date).isSameOrAfter(from_date_generic, 'day') &&
                moment(date).isSameOrBefore(to_date_generic, 'day')
              );
            }
          )
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID);
        incidentYup.hour_discovered_at = Yup.string()
          .required(intl.VALIDATE_HOUR_VALID)
          .typeError(intl.VALIDATE_HOUR_VALID)
          .length(5, intl.VALIDATE_HOUR_VALID)
          .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
            return moment(hour, 'hh:mm').isValid();
          });
        incidentYup.hour_baggage_arrived_at = Yup.string().nullable();
      } else {
        incidentYup.description = Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .max(255, intl.TEXT_FIELD_VALIDATION_255);
        incidentYup.baggage_arrived_at = Yup.string()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
            return (
              moment(date).isValid() &&
              moment(date).isSameOrAfter(from_date_generic, 'day')
            );
          });
        incidentYup.discovered_at = Yup.date()
          .test(
            'match',
            intl.VALIDATE_DATE_BETWEEN_START_END_TRIP,
            function (date) {
              return (
                moment(date).isSameOrAfter(from_date_generic, 'day') &&
                moment(date).isSameOrBefore(to_date_generic, 'day')
              );
            }
          )
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID);
        incidentYup.hour_discovered_at = Yup.string()
          .required(intl.VALIDATE_HOUR_VALID)
          .typeError(intl.VALIDATE_HOUR_VALID)
          .length(5, intl.VALIDATE_HOUR_VALID)
          .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
            return moment(hour, 'hh:mm').isValid();
          });
        incidentYup.hour_baggage_arrived_at = Yup.string()
          .required(intl.VALIDATE_HOUR_VALID)
          .typeError(intl.VALIDATE_HOUR_VALID)
          .length(5, intl.VALIDATE_HOUR_VALID)
          .test(
            'test-hour-greater',
            intl.VALIDATE_HOUR_GREATER,
            function (hour) {
              return (
                moment(this.parent.baggage_arrived_at).isAfter(
                  from_date_generic,
                  'day'
                ) ||
                (moment(hour, 'hh:mm').isValid() &&
                  moment(this.parent.baggage_arrived_at).isSame(
                    from_date_generic,
                    'day'
                  ))
              );
            }
          )
          .test('test-hour-valid', intl.VALIDATE_HOUR_VALID, function (hour) {
            return moment(hour, 'hh:mm').isValid();
          });
      }
      return Yup.object().shape({
        ...incidentYup,
      });
    }),
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),

    other_insurance_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
      currency: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),

    additional_passengers: additionalShema(intl),

    formal_complaint: Yup.object().shape({
      is_submitted: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      submission_reason: Yup.string().when(
        ['is_submitted'],
        {
          is: is_submitted => {
            return is_submitted === 'false' || is_submitted === 'true';
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['is_submitted']
      ),
    }),

    other_company_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
      currency: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),
  });

const additionalShema = intl => {
  const additional = Yup.array().when(['hasAdditional'], {
    is: hasAdditional => {
      return hasAdditional === 'true';
    },
    then: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .max(new Date(), intl.VALIDATE_DATE_VALID)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        nationalities: Yup.string()
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
      })
    ),
  });

  return additional;
};
