import React from 'react';
import IncidentArea from './IncidentArea/IncidentArea';
import PersonalArea from './PersonalArea/PersonalArea';
import QuestionsArea from './QuestionsArea/QuestionsArea';

const ExtendedWarranty = props => {
  const { claimDetails } = props;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      <IncidentArea claimDetails={claimDetails} />
      <QuestionsArea claimDetails={claimDetails} />
    </div>
  );
};

export default ExtendedWarranty;
