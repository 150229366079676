import { useState, useContext, useEffect } from 'react';
import styles from './ButtonProfile.module.scss';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import EditIconMenuProfile from '../../assets/icons/EditIconMenuProfile';
import ArrowLeftIcon from '../../assets/icons/ArrowLeftIcon';
import Button from '../../componentsV2/Button/Button';
import { StateContext } from '../StateContextParent/StateContextParent';

const ButtonProfile = ({ user }) => {
  const { state } = useContext(StateContext);
  const [isMobile, setIsMobile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const linkProfileAccount = isMobile ? '/profile' : '/profile/account#contact';
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const loadedCards = state.cards.data.length > 0;

  const checkWindowWidth = () => {
    if (window.screen.width > 990) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    const wrapperMenuListCard = document.getElementById(
      'buttonProfile-headerOption'
    );
    const btnListCardMenu = document.getElementById('btnOpenMenuProfile');
    wrapperMenuListCard.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setShowMenu(false);
        btnListCardMenu.focus();
      }
    });
  }, []);

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  return (
    <div
      id="buttonProfile-headerOption"
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setShowMenu(false);
        }
      }}
      className={styles.boxProfile}
    >
      <button
        className={`${styles[theme]} ${styles.buttonProfile}`}
        onClick={() => setShowMenu(!showMenu)}
        aria-expanded={showMenu}
        id="btnOpenMenuProfile"
      >
        {user && user.length > 0 ? user.charAt(0) : ''}
      </button>
      {showMenu && (
        <>
          <div
            className={styles.background}
            onClick={() => setShowMenu(false)}
          ></div>
          <ul
            className={`${styles[theme]} ${styles.subMenuEditProfile}`}
            data-testid="menu"
          >
            <li className={styles[theme]} data-testid="profile">
              {loadedCards && (
                <Button
                  type="Tertiary"
                  link
                  href={linkProfileAccount}
                  onClick={() => setShowMenu(false)}
                  label={intl.TEXT_EDIT_PROFILE}
                  leadingIcon={
                    <EditIconMenuProfile
                      width={25}
                      height={25}
                      aria-label={intl.PROFILE}
                    />
                  }
                />
              )}
            </li>
            <li
              className={styles[theme]}
              data-testid="signout"
              onClick={() => setShowMenu(false)}
            >
              <Button
                type="Tertiary"
                link
                href="/signout"
                onClick={() => setShowMenu(false)}
                label={intl.TEXT_LOG_OUT}
                leadingIcon={
                  <ArrowLeftIcon
                    width={25}
                    height={25}
                    aria-label={intl.SIGN_OUT}
                  />
                }
              />
            </li>
          </ul>
        </>
      )}
    </div>
  );
};

export default ButtonProfile;
