import { userServices } from '../../../../services';

export const getCHDependents = async cn => {
  try {
    const dependents = (await userServices.getCHDependents(cn)).data;
    const promises = dependents.map(dependent => {
      return userServices.getCHDependentsDetail(cn, dependent.dependent_id);
    });
    return (await Promise.all(promises)).map(dependent => dependent.data);
  } catch (e) {
    return [];
  }
};
