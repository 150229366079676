const message_pt_br = process.env.REACT_APP_RED_BAR_TEXT_MESSAGE_PT_BR || '';
const message_es = process.env.REACT_APP_RED_BAR_TEXT_MESSAGE_ES || '';
const message_en = process.env.REACT_APP_RED_BAR_TEXT_MESSAGE_EN || '';
export const MAINTENANCE_MSG = {
  en_US:
    'The Visa Benefits Portal may experience instabilities when trying to attach or download documents on June 20, from 15:30 CT/17:30 BR to 21:30 CT/23:30 BR. There is no need to contact support.',
  pt_BR:
    'Portal de Benefícios Visa poderá apresentar instabilidades ao tentar anexar ou baixar documentos em 20 de junho, das 17:30 BR às 23:30 BR. Não há necessidade de contatar o suporte.',
  es_ES:
    'El Portal de Beneficios de Visa puede experimentar inestabilidades al intentar adjuntar o descargar documentos el 20 de junio, de 15:30 CT/17:30 BR a 21:30 CT/23:30 BR. No es necesario ponerse en contacto con el soporte.',
};
