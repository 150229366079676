import bancoBanrisul from './bancoBanrisul';
import bancoBradesco from './bancoBradesco';
import bancodoBrasil from './bancoDoBrasil';
import bancoCaixa from './bancoCaixa';
import bancoCitibank from './bancoCitibank';
import bancoHsbc from './bancoHsbc';
import bancoItau from './bancoItau';
import bancoSantander from './bancoSantander';
import bancoGeneric from './bancoGeneric';
import bancoNubank from './bancoNubank';
import mercadoPago from './mercadoPago';
import xpInvestimentos from './xpInvestimentos';

export const bancoBanrisulValidate = bancoBanrisul();
export const bancoBradescoValidate = bancoBradesco();
export const bancoBrasilValidate = bancodoBrasil();
export const bancoCaixaValidate = bancoCaixa();
export const bancoCitibankValidate = bancoCitibank();
export const bancoHsbcValidate = bancoHsbc();
export const bancoItauValidate = bancoItau();
export const bancoStantanderValidate = bancoSantander();
export const bancoGenericValidate = bancoGeneric();
export const bancoNubankValidate = bancoNubank();
export const mercadoPagoValidate = mercadoPago();
export const xpInvestimentosValidate = xpInvestimentos();
