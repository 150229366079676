import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import RadioButtonError from '../../../../../../../../components/RadioButtonError/RadioButtonError';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from '../BaggageLoss.module.scss';
import * as translations from './intl';
import * as constants from '../consts';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const TransportationArea = props => {
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const intl = translate(translations);
  const themes = getGlobalTheme();

  const { values, handleChange, touched, errors, setFieldValue } = props;

  const setTransportationType = e => {
    setFieldValue('travel_information.transportation_type', e.target.value);
    setFieldValue(
      'travel_information.was_the_baggage_in_the_charge_of_the_travel_company',
      true
    );
  };
  const transportationType = values.travel_information.transportation_type;
  return (
    <div className={`row`}>
      <div className="col-12">
        <div className={`${styles.elementRadio} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.TEXT_BAGGAGE_CUSTODY}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_BAGGAGE_CUSTODY}
              defaultChecked={transportationType === 'AIRCRAFT'}
              text={intl.OPTIONS_AIRLINE}
              name="travel_information.transportation_type"
              dataTestid="InputTransportationTypeAirline"
              value={constants.AIRLINE}
              onChange={e => {
                setTransportationType(e);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_BAGGAGE_CUSTODY}
              defaultChecked={transportationType === 'SHIP'}
              text={intl.OPTIONS_CRUISE_LINE}
              name="travel_information.transportation_type"
              dataTestid="InputTransportationTypeCruise"
              value={constants.CRUISE_LINE}
              onChange={e => {
                setTransportationType(e);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.TEXT_BAGGAGE_CUSTODY}
              defaultChecked={transportationType === 'RAILWAY'}
              text={intl.OPTIONS_RAILROAD}
              name="travel_information.transportation_type"
              dataTestid="InputTransportationTypeRailRoad"
              value={constants.RAILROAD_COMPANY}
              onChange={e => {
                setTransportationType(e);
                handleChange(e);
              }}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            {/* <RadioButton
              defaultChecked={false}
              text={intl.OPTIONS_BUS}
              name="travel_information.transportation_type"
              dataTestid="InputTransportationTypeBus"
              value={constants.BUS_COMPANY}
              onChange={(e) => {
                setTransportationType(e);
                handleChange(e);
              }}
            /> */}
            <RadioButtonError
              touched={
                touched.travel_information &&
                touched.travel_information.transportation_type
              }
              error={
                errors.travel_information &&
                errors.travel_information.transportation_type
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransportationArea;
