import { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import * as translationsPErsonal from '../intlPersonal';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from './PeopleInvolved.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon';
import AddIcon from '../../../../../../../../assets/icons/AddIcon';
import PhoneForm from '../../../../../../../../components/PhoneForm';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';
import Button from '../../../../../../../../componentsV2/Button/Button';

export const objNew = {
  type: '',
  person: {
    first_name: '',
    last_name: '',
  },
  address: {
    country: '',
    state: '',
    locality: '',
    postal_code: '',
    street_address: '',
    subdivision: '',
  },
  phone: {
    phone_type: '',
    international_prefix: '',
    number: '',
  },
};

const PeopleInvolved = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const intlPersonal = translate(translationsPErsonal);
  const { values, setFieldValue, prefixOptions } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  let people_involved = [...values.people_involved];

  useEffect(() => {
    if (prefixOptions.prefix) {
      people_involved.map((person, index) => {
        setFieldValue(
          `people_involved[${index}].phone.international_prefix`,
          prefixOptions.prefix
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixOptions.prefix]);

  const addPeople = event => {
    event.preventDefault();
    people_involved.push(objNew);
    setFieldValue('people_involved', people_involved);
  };

  const removePeople = (event, index) => {
    event.preventDefault();

    const people_involved = [...values.people_involved];
    people_involved.splice(index, 1);
    setFieldValue('people_involved', people_involved);
  };

  return (
    <>
      <div className={styles.buttonArea}>
        <Button
          type="Tertiary"
          onClick={addPeople}
          label={intl.ADD}
          leadingIcon={<AddIcon aria-label={intl.ICON_ADD} />}
        />
      </div>

      {people_involved.map((_, index) => (
        <div className={`row ${styles.personalArea}`} key={index}>
          <div className="col-12">
            <GroupDivisor title={intl.TITLE_ITEM_INVOLVED_PERSON(index + 1)} />
          </div>

          <div className="col-12">
            <div className={`${styles.fontGray12} ${styles[themes]} row`}>
              <div className={`${styles.option} col-6`}>
                <Select
                  label={intl.TYPE}
                  name={`people_involved[${index}].type`}
                  formikProps={props}
                  dataTestid="comboboxPeopleInvolvedType"
                  helpText={intl.HELP_TYPE_PEOPLE_INVOLVED}
                >
                  <option value="">{intl.TYPE}</option>
                  <option value="WITNESS">{intl.WITNESS}</option>
                  <option value="DOCTOR">{intl.DOCTOR}</option>
                  <option value="KNOWN_DOCTOR">{intl.KNOW_DOCTOR}</option>
                </Select>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.FIRST_NAME}
              name={`people_involved[${index}].person.first_name`}
              type="text"
              formikProps={props}
              maxLength="40"
            />
          </div>

          <div className="col-12 col-sm-12 col-md-6">
            <Input
              label={intl.LAST_NAME}
              name={`people_involved[${index}].person.last_name`}
              type="text"
              formikProps={props}
              maxLength="80"
            />
          </div>
          <div className="col-12">
            <PhoneForm
              formikProps={props}
              inputNames={{
                phoneType: `people_involved[${index}].phone.phone_type`,
                countryCode: `people_involved[${index}].phone.international_prefix`,
                phoneNumber: `people_involved[${index}].phone.number`,
              }}
              testIds={{
                phoneType: 'inputPeopleInvolvedPhoneType',
              }}
            />
          </div>
          <div className="col-12">
            <div className={`${styles.fontGray12} ${styles[themes]} row`}>
              <div className="col-12">
                <GroupDivisor title={intl.TEXT_ADDRESS} />
              </div>
              <div className="col-12 col-sm-12 col-md-6  col-lg-4">
                <Select
                  label={intlPersonal.COUNTRY_LABEL}
                  name={`people_involved[${index}].address.country`}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      `<option value="">${intlPersonal.COUNTRY_LABEL}</option>${intlPersonal.COUNTRY_OPTIONS}`
                    ),
                  }}
                  formikProps={props}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-2">
                <Input
                  label={intlPersonal.LABEL_POSTAL_CODE}
                  name={`people_involved[${index}].address.postal_code`}
                  type="text"
                  mask="maskedPostalCode"
                  formikProps={props}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <Input
                  label={intlPersonal.LABEL_STATE}
                  name={`people_involved[${index}].address.state`}
                  type="text"
                  formikProps={props}
                  maxLength="100"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                <Input
                  label={intlPersonal.LABEL_CITY}
                  name={`people_involved[${index}].address.locality`}
                  type="text"
                  formikProps={props}
                  maxLength="100"
                />
              </div>

              <div className="col-12 col-lg-6">
                <Input
                  label={intlPersonal.LABEL_ADDRESS}
                  name={`people_involved[${index}].address.street_address`}
                  type="text"
                  formikProps={props}
                  maxLength="255"
                />
              </div>
              <div className="col-12 col-lg-6">
                <Input
                  label={intlPersonal.LABEL_SUBDIVISION}
                  name={`people_involved[${index}].address.subdivision`}
                  type="text"
                  formikProps={props}
                  maxLength="100"
                />
              </div>
            </div>
          </div>

          <div className={`row ${styles.footer}`}>
            {index > 0 && (
              <div className="col-12">
                <Button
                  type="Tertiary"
                  onClick={event => {
                    removePeople(event, index);
                  }}
                  label={intl.BTN_REMOVE}
                  leadingIcon={
                    <TrashIcon
                      width={24}
                      height={24}
                      aria-label={intl.ICON_DELETE}
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default PeopleInvolved;
