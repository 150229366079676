import React, { useState, useEffect } from 'react';

import ListCardItem from './ListCardItem/ListCardItem';

const getCodeByExternalReference = (benefit, certificates) => {
  let codeFind = undefined;
  let pdfFind = undefined;
  certificates.forEach(element => {
    const covered = element.covered_benefits_references.find(
      covered_reference => covered_reference === benefit.external_reference
    );
    if (covered !== undefined) {
      codeFind = element.code;
      pdfFind = element.urlPDF;
      return;
    }
  });
  return { codeFind, pdfFind };
};

const ListCardBenefits = props => {
  const {
    benefitToSplit,
    type,
    benefitsList,
    productBinOrPan = '',
    certificates,
  } = props;
  const [column, setColumn] = useState(3);
  const titleByWidthScreen = () => {
    if (window.outerWidth > 992) {
      setColumn(3);
    } else if (window.outerWidth >= 768 && window.outerWidth <= 992) {
      setColumn(2);
    } else {
      setColumn(1);
    }
  };

  useEffect(() => {
    titleByWidthScreen();
    window.addEventListener('resize', titleByWidthScreen);
    return () => {
      window.removeEventListener('resize', titleByWidthScreen);
    };
  }, [column]);

  const splitArrayColumns = array => {
    return array.reduce(
      (acc, current, index) => (
        (acc[index % column] = acc[index % column] || []).push(current), acc
      ),
      []
    );
  };

  const createNewGrid = () => {
    let newList = splitArrayColumns(benefitToSplit);
    return newList.map((itens, index) => {
      return (
        <div
          className={`col-lg-4 col-md-6  col-12 no-gutters`}
          key={`${itens.name}_${index}`}
          data-testid="listCardContent"
        >
          {itens.map((item, index) => {
            if (type === 'benefits' && certificates) {
              const { codeFind, pdfFind } = getCodeByExternalReference(
                item,
                certificates
              );
              item.code = codeFind;
              item.urlPDF = pdfFind;
            }
            return (
              <ListCardItem
                key={`${item.code}_${index}`}
                benefit={item}
                benefitsList={benefitsList}
                image={item.image}
                category={item.category}
                description={item.description}
                type={type}
                isPDF={
                  item.urlPDF && item.urlPDF.length > 0 ? item.urlPDF : false
                }
                productBinOrPan={productBinOrPan}
              />
            );
          })}
        </div>
      );
    });
  };
  return <>{createNewGrid()}</>;
};

export default ListCardBenefits;
