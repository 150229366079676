export const INSTALLMENTS = {
  en_US: 'Installments',
  pt_BR: 'Parcelas',
  es_ES: 'Cuotas',
};

export const TOTAL_TO_PAY = {
  en_US: 'Total to pay',
  pt_BR: 'Total a pagar',
  es_ES: 'Total a pagar',
};
