import React, { Component, createRef } from 'react';
import { generalInformationFormInitialValues } from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/initialValues';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import GeneralInformation from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/GeneralInformation';
import ProductInformation from '../ProductInformation/ProductInformation';
import LegalAdviceAndConsent from '../LegalAdviceAndConsent/LegalAdviceAndConsent';

export default class PurchaseProtection extends Component {
  constructor(props) {
    super(props);
    this.keys = ['policy', 'productInformation', 'legal'];
    this.submitRefs = {
      policy: createRef(),
      productInformation: createRef(),
      legal: createRef(),
    };
    this.accordeonRefs = {
      policy: createRef(),
      productInformation: createRef(),
      legal: createRef(),
    };
    this.initialValues = {
      policy: generalInformationFormInitialValues(
        props.loadedData.cardHolder,
        'selectedCard.issuer_name',
        props.idiom,
        true
      ),
    };
    this.state = {
      listAccordeon: {
        policy: true,
        productInformation: false,
        legal: false,
      },
    };
  }

  componentDidMount() {
    this.props.setInitialValuesCompare(this.initialValues);
  }

  setStatusAccordeon = name => {
    const newList = { policy: false, productInformation: false, legal: false };
    newList[name] = !this.state.listAccordeon[name];
    this.setState({ listAccordeon: newList });
  };

  render() {
    const { initialValues } = this;
    return (
      <div>
        <AcordeonSession
          id="policy"
          number={1}
          title={this.props.intl.CUSTOMER_INFORMATION}
          openButtonRef={this.accordeonRefs.policy}
          status={this.state.listAccordeon['policy']}
          setStatus={() => this.setStatusAccordeon('policy')}
          next={async () => {
            const responseOK = await this.props.openTargetAcordeon(
              'policy',
              'productInformation',
              true
            );
            if (responseOK) {
              await this.props.generalInformationCheck();
            }
          }}
          back={() =>
            this.props.history.push('/certificates/previous-certificates')
          }
        >
          <GeneralInformation
            policySubmitRef={this.submitRefs.policy}
            updateParent={this.props.updateForm.bind(this, 'policy')}
            initialValues={initialValues.policy}
            isCertificate
            productName={this.props.selectedCard.name}
            prefixOptions={this.props.prefixOptions}
          />
        </AcordeonSession>
        {!this.isBrazil && (
          <AcordeonSession
            id="productInformation"
            number={2}
            title={this.props.intl.PRODUCT_INFORMATION}
            openButtonRef={this.accordeonRefs.productInformation}
            status={this.state.listAccordeon['productInformation']}
            setStatus={() => this.setStatusAccordeon('productInformation')}
            next={() =>
              this.props.openTargetAcordeon('productInformation', 'legal', true)
            }
            back={() =>
              this.props.openTargetAcordeon(
                'productInformation',
                'policy',
                false
              )
            }
          >
            <ProductInformation
              submitRef={this.submitRefs.productInformation}
              updateParent={this.props.updateForm.bind(
                this,
                'productInformation'
              )}
            />
          </AcordeonSession>
        )}
        <AcordeonSession
          id="legal"
          title={this.props.intl.TITLE_BENEFIT_TERMS}
          openButtonRef={this.accordeonRefs.legal}
          accordeon="legal"
          status={this.state.listAccordeon['legal']}
          setStatus={() => this.setStatusAccordeon('legal')}
          txtBtnNext={this.props.intl.TEXT_SUBMIT_CERTIFICATE}
          next={this.props.submit}
          back={() =>
            this.props.openTargetAcordeon(
              'legal',
              this.isBrazil ? 'policy' : 'productInformation',
              false
            )
          }
        >
          <LegalAdviceAndConsent
            updateParent={this.props.updateForm.bind(null, 'legal')}
            legalSubmitRef={this.submitRefs.legal}
          />
        </AcordeonSession>
      </div>
    );
  }
}
