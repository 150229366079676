import React, { useContext, useEffect } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './FinancialArea.module.scss';
import { getCurrencyByHomeCountry } from '../../../../../../../../utils/currency';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import RadioButtonError from '../../../../../../../../components/RadioButtonError/RadioButtonError';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../../../../../../utils/sanitize';

const FinancialArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    values,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const setCurrency = currency => {
    setFieldValue('financial_information.currency', currency);
    setFieldTouched('financial_information.currency', true);
  };

  useEffect(() => {
    const currency = values.financial_information.currency;
    const getCurrency = getCurrencyByHomeCountry();
    if (!currency && getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.financial_information.currency]);

  return (
    <div className={`row ${styles.incidentAreal}`}>
      <div className="col-12 col-sm-12 col-md-12">
        <Input
          label={intl.TOTAL_NUMBER_VALID_ACCOUNTS}
          name="financial_information.valid_account_number"
          type="text"
          mask="maskedOnlyNumber"
          formikProps={props}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.CARDHOLDER_CREDIT_LIMIT}
          name="financial_information.credit_limit"
          type="text"
          maskedMoney
          formikProps={props}
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          dataTestid="selectFinancialInformationCurrency"
          label={intl.LABEL_CURRENCY}
          name="financial_information.currency"
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          formikProps={props}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-12">
        <Input
          label={intl.AMOUNT_CHANGES_SUBMITTED}
          name="financial_information.charge_submitted_by_company"
          type="text"
          mask="maskedOnlyNumber"
          formikProps={props}
        />
      </div>

      <div className="col-12 col-sm-12 col-md-12">
        <Input
          label={intl.AMOUNT_RECOVERED}
          name="financial_information.recovered_to_date"
          type="text"
          mask="maskedOnlyNumber"
          formikProps={props}
        />
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS}
              text={intl.YES}
              name="financial_information.is_payment_made_within_last_12_months"
              value={true}
              defaultChecked={
                values.financial_information
                  .is_payment_made_within_last_12_months === 'true'
              }
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_PAYMENT_WITHIN_LAST_12_MONTHS}
              text={intl.NO}
              name="financial_information.is_payment_made_within_last_12_months"
              value={false}
              defaultChecked={
                values.financial_information
                  .is_payment_made_within_last_12_months === 'false'
              }
              onChange={handleChange}
            />
            <RadioButtonError
              touched={
                touched.financial_information &&
                touched.financial_information
                  .is_payment_made_within_last_12_months
              }
              error={
                errors.financial_information &&
                errors.financial_information
                  .is_payment_made_within_last_12_months
              }
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]} row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.QUESTION_LOST_OR_STOLEN_CARD}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_LOST_OR_STOLEN_CARD}
              describe={intl.DESCRIBE_STOLEN_CARD}
              defaultChecked={
                values.incident.question_1_view_cardIs_lost === 'true'
              }
              text={intl.YES}
              name="incident.question_1_view_cardIs_lost"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.QUESTION_LOST_OR_STOLEN_CARD}
              defaultChecked={
                values.incident.question_1_view_cardIs_lost === 'false'
              }
              text={intl.NO}
              name="incident.question_1_view_cardIs_lost"
              value={false}
              onChange={handleChange}
            />
            <RadioButtonError
              touched={
                touched.incident && touched.incident.question_1_view_cardIs_lost
              }
              error={
                errors.incident && errors.incident.question_1_view_cardIs_lost
              }
            />
          </div>
        </div>
      </div>

      {values.incident.question_1_view_cardIs_lost === 'true' && (
        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            label={intl.DATE_REPORTED}
            name="incident.date_of_loss_or_stealth"
            maxDate={new Date()}
            setFieldTouched={setFieldTouched}
            formikProps={props}
          />
        </div>
      )}
    </div>
  );
};

export default FinancialArea;
