import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from '../intl';
import Calendar from '../../../../../../../../components/Calendar/Calendar';
import styles from './ComplementPersonalArea.module.scss';
import Select from '../../../../../../../../components/Select/Select';

const ComplementPersonalArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, setFieldTouched } = props;

  return (
    <div className={`${styles.personalArea}`}>
      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.MARITAL_STATUS}
            name="affected_person.family_situation.marital_status"
            formikProps={props}
          >
            <option value="SINGLE">{intl.STATUS_SINGLE}</option>
            <option value="MARRIED">{intl.STATUS_MARRIED}</option>
          </Select>
        </div>

        {values.affected_person.family_situation.marital_status ===
          'MARRIED' && (
          <div className="col-12 col-sm-12 col-md-6">
            <Calendar
              label={intl.DATE_LIVE_TOGETHER}
              name="affected_person.family_situation.lived_in_couple_since"
              maxDate={new Date()}
              setFieldTouched={setFieldTouched}
              formikProps={props}
            />
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          <Select
            label={intl.CARD_STATUS_AT_INCIDENT_DATE}
            name="policy.credit_card.additional_card_intormation.card_status"
            formikProps={props}
          >
            <option value="ACTIVE">{intl.CARD_STATUS_ACTIVE}</option>
            <option value="INACTIVE">{intl.CARD_STATUS_INACTIVE}</option>
          </Select>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            label={intl.CARD_OPENING_DATE}
            name="policy.credit_card.additional_card_intormation.activation_date"
            maxDate={new Date()}
            setFieldTouched={setFieldTouched}
            formikProps={props}
          />
        </div>

        <div className="col-12 col-sm-12 col-md-6">
          <Calendar
            label={intl.CARD_EXPIRATION_DATE}
            name="policy.credit_card.additional_card_intormation.expiration_date"
            minDate={
              values.policy.credit_card.additional_card_intormation
                .activation_date
            }
            setFieldTouched={setFieldTouched}
            formikProps={props}
          />
        </div>
      </div>
    </div>
  );
};

export default ComplementPersonalArea;
