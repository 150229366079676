export const BRAND_TITLE = {
  en_US: 'Buy your ticket with Visa and go to the cinema in company',
  pt_BR: 'Compre seu ingresso com Visa e vá ao cinema acompanhado',
  es_ES: 'Compra tu entrada con Visa y ve al cine en compañía',
};

export const BENEFIT_LINK = {
  en_US: 'More information',
  pt_BR: 'Mais informações',
  es_ES: 'Más información',
};
