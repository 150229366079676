import React, { useContext } from 'react';
import styles from './RadioButtonError.module.scss';
import { IntlContext } from '../../intl';
import * as translations from './intl';

const RadioButtonError = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    touched,
    error,
    dataTestid,
    messageError = intl.TEXT_FIELD_REQUIRED,
  } = props;
  const labelError =
    touched && error ? (
      <label
        data-testid={dataTestid}
        formik-error="1"
        className={styles.errorLabel}
        style={{ fontSize: '12px' }}
      >
        {messageError}
      </label>
    ) : (
      <></>
    );

  return labelError;
};

export default RadioButtonError;
