import React, { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { toFixedOrDefault } from '../../../../../../utils/numbers';
import * as translations from './intl';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [otherInsuranceCovering, setOtherInsuranceCovering] = useState([]);

  useEffect(() => {
    const otherInsuranceCovering =
      (claimDetails && claimDetails.other_insurance_covering) || [];
    setOtherInsuranceCovering(otherInsuranceCovering);
  }, [claimDetails]);

  const getPrice = covering => {
    const value =
      covering && covering.value ? toFixedOrDefault(covering.value, 2, '') : '';
    const currency = covering && covering.currency ? covering.currency : '';

    return value ? `${value}${currency && ` (${currency})`}` : '-';
  };

  const getCovering = description => {
    const covering = otherInsuranceCovering.find(
      item => item && item.description === description
    );
    return covering && covering.amount ? getPrice(covering.amount) : '-';
  };

  return (
    <>
      {getCovering('TRANSPORT') !== '-' && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_REIMBURSED_BY_TRANSPORTATION_COMPANY}
            value={getCovering('TRANSPORT')}
          />
        </div>
      )}
      {getCovering('OTHER') !== '-' && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_AMOUNT_PAID}
            value={getCovering('OTHER')}
          />
        </div>
      )}
    </>
  );
};

export default PersonalArea;
