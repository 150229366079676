export const privacyNote = {
  en_US: {},
  pt_BR: {
    title: 'Fique por dentro das mudanças em nossa Política de Privacidade',
    content: [
      {
        id: '1',
        subtitle: '',
        text: `Cliente Visa,
        <br/><br/>
        Como parte de nosso compromisso em tornar a sua experiência ainda mais segura e apropriada, atualizamos a Política de Privacidade da Visa, em conformidade com a Lei Federal nº 13.709/2018 - Lei Geral de Proteção de Dados Pessoais (“LGPD”), que dispõe sobre as regras para o tratamento de dados pessoais. Para conferir o novo conteúdo da Política de Privacidade da Visa, <a target="_blank" rel="noopener noreferrer" href="https://visa.com.br/termos-de-uso/politica-de-privacidade.html" aria-label="clique aqui - nova aba" style="text-decoration: underline;">clique aqui</a>.`,
      },
      {
        id: '2',
        subtitle: 'Você no controle',
        text: `As configurações de sua conta no Portal de Benefícios estão ainda mais completas, você pode gerenciar seus dados de acesso na seção “<a href="/profile/account#contact">Perfil</a>”.`,
      },
      {
        id: '3',
        subtitle: 'Nova Política de cookies',
        text: `Quando você interage com os sites e/ou aplicativos da Visa, nós podemos solicitar a coleta de algumas informações suas para melhorar nossos produtos e serviços e torná-los cada vez mais personalizados para você. Algumas dessas informações, também conhecidas como cookies, são essenciais para o funcionamento de nossos serviços, como o processo de autenticação e recursos de segurança.

        Em nossa Política de Cookies, você pode conferir quais são esses dados e entender como a Visa lida com eles.
        Ao acessar um dos sites e/ou aplicativos da Visa pela primeira vez em algum dispositivo, uma mensagem será apresentada solicitando sua análise sobre a coleta de cookies. Após compreender o conteúdo, recomendamos sua permissão para que possamos trazer descontos, sorteios e outros conteúdos especiais para seu Visa.`,
      },
      {
        id: '4',
        subtitle: 'Transparência do início ao fim',
        text: `As mudanças propostas já estão em vigor. Por isso, caso esteja de acordo com a atualização realizada na <a target="_blank" rel="noopener noreferrer" href="https://visa.com.br/termos-de-uso/politica-de-privacidade.html" aria-label="Política de Privacidade - nova aba" style="text-decoration: underline;">Política de Privacidade</a> da Visa, basta continuar utilizando nossos serviços normalmente.`,
      },
    ],
    footer:
      'Atenção: Por medida de segurança, a Visa nunca solicita os seus dados bancários ou a senha do seu cartão para cadastro em suas promoções. Se suspeitar de e-mails fraudulentos, não forneça seus dados. Veja mais dicas de segurança em <a target="_blank" rel="noopener noreferrer" href="https://visa.com.br/email/seguranca" aria-label="visa.com.br/email/seguranca - nova aba" style="text-decoration: underline;">visa.com.br/email/seguranca</a>. Para conferir as promoções disponíveis para seu Cartão Visa, acesse o site <a target="_blank" rel="noopener noreferrer" href="https://vaidevisa.visa.com.br" aria-label="vaidevisa.visa.com.br - nova aba" style="text-decoration: underline;">vaidevisa.visa.com.br</a>. Os termos e condições de uso do Vai de Visa estão disponíveis em <a target="_blank" rel="noopener noreferrer" href="https://vaidevisa.visa.com.br/site/termos-de-uso" aria-label="vaidevisa.visa.com.br/site/termos-de-uso - nova aba" style="text-decoration: underline;">vaidevisa.visa.com.br/site/termos-de-uso</a>. Dúvidas? Conheça nossa política de privacidade em <a target="_blank" rel="noopener noreferrer" href="https://visa.com.br/termos-de-uso/politica-de-privacidade.html" aria-label="visa.com.br/termos-de-uso/politica-de-privacidade.html" style="text-decoration: underline;">visa.com.br/termos-de-uso/politica-de-privacidade.html</a>.',
  },
  es_ES: {},
};
