export const PREVIOUS = {
  en_US: 'Previous',
  es_ES: 'Anterior',
  pt_BR: 'Anterior',
};

export const NEXT = {
  en_US: 'Next',
  es_ES: 'Siguiente',
  pt_BR: 'Próximo',
};

export const PAGE_NAVIGATION_EXAMPLE = {
  en_US: 'Page Navigation Example',
  es_ES: 'Ejemplo de Navegación de Página',
  pt_BR: 'Exemplo de Navegação de Página',
};
