import React, { useContext } from 'react';
import styles from '../../../IncidentArea.module.scss';
import * as translations from '../../../intl';
import { IntlContext } from '../../../../../../../../../../../intl';
import * as constants from '../../../../consts';
import Select from '../../../../../../../../../../../components/Select/Select';

const ItemStatusArea = props => {
  const {
    index,
    handleChange,
    values,
    setFieldValue,
    touched,
    errors,
    handleBlur,
  } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const setDamegeToNo = index => {
    setFieldValue(`purchase_protection_items[${index}].is_repairable`, false);
    setFieldValue(
      `purchase_protection_items[${index}].is_the_damage_visible`,
      false
    );
  };

  const changeTypeOfLoss = event => {
    if (event.target.value === constants.THEFT_VALUE) {
      setDamegeToNo(index);
    }
    handleChange(event);
  };

  return (
    <>
      <div className="col-12 col-md-6">
        <Select
          label={intl.LABEL_TYPE_OF_LOSS}
          name={`purchase_protection_items[${index}].status`}
          value={values.purchase_protection_items[index].status}
          onBlur={handleBlur}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].status
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].status
          }
          onChange={changeTypeOfLoss}
        >
          <option value={constants.DAMAGE_VALUE}>{intl.LABEL_DAMAGE}</option>
          <option value={constants.THEFT_VALUE}>{intl.LABEL_THEFT}</option>
        </Select>
      </div>
      <div
        role="div-none-to-statusTheft"
        className={`col-12 col-md-6 ${
          values.purchase_protection_items[index].status !==
          constants.DAMAGE_VALUE
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_DAMEGE_REPAIRED}
          name={`purchase_protection_items[${index}].is_repairable`}
          value={values.purchase_protection_items[index].is_repairable}
          onBlur={handleBlur}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].is_repairable
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].is_repairable
          }
          onChange={handleChange}
        >
          <option value={true}>{intl.TEXT_YES}</option>
          <option value={false}>{intl.TEXT_NO}</option>
        </Select>
      </div>
      <div
        role="div-none-to-statusTheft"
        className={`col-12 col-md-6 ${
          values.purchase_protection_items[index].status !==
          constants.DAMAGE_VALUE
            ? styles.none
            : ''
        }`}
      >
        <Select
          label={intl.LABEL_DAMAGE_VISIBLE}
          name={`purchase_protection_items[${index}].is_the_damage_visible`}
          value={values.purchase_protection_items[index].is_the_damage_visible}
          onBlur={handleBlur}
          touched={
            touched.purchase_protection_items &&
            touched.purchase_protection_items[index] &&
            touched.purchase_protection_items[index].is_the_damage_visible
          }
          error={
            errors.purchase_protection_items &&
            errors.purchase_protection_items[index] &&
            errors.purchase_protection_items[index].is_the_damage_visible
          }
          onChange={handleChange}
        >
          <option value={true}>{intl.TEXT_YES}</option>
          <option value={false}>{intl.TEXT_NO}</option>
        </Select>
      </div>
    </>
  );
};
export default ItemStatusArea;
