export const TEXT_ACCESS_BENEFIT = {
  en_US: 'To access this benefit, login to the Benefits Portal',
  es_ES:
    'Para acceder a este beneficio, inicia sesión en el Portal de beneficios',
  pt_BR: 'Para acessar este benefício, faça o login no Portal de Benefícios',
};

export const BUTTON_LOGIN = {
  en_US: 'Log in',
  es_ES: 'Iniciar sesión',
  pt_BR: 'Entrar',
};

export const BUTTON_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const BUTTON_MAKE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};
