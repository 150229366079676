import React, { useContext } from 'react';
import styles from './InfoTravelKitMarketing.module.scss';
import * as translations from '../intl';
import TitlePageMarketing from '../../Components/TitlePageMarketing/TitlePageMarketing';
import beforeTrip from '../../../../assets/images/downloadApp/path/BeforeTrip/Visa-before-Img.png';
import PathItem from '../../Path/PathItem/PathItem';
import ReadyHealthIcon from '../../../../assets/icons/ReadyHealthIcon';
import HeartIcon from '../../../../assets/icons/HeartIcon';
import UploadDocumentsIcon from '../../../../assets/icons/UploadDocumentsIcon';
import ReadyToFlyIcon from '../../../../assets/icons/ReadyToFlyIcon';
import { IntlContext } from '../../../../intl';

const WIDTH_HEIGHT_ICON = 30;

const InfoTravelKitMarketing = () => {
  const { translate } = useContext(IntlContext);

  const intl = translate(translations);

  const benefitsTravelKit = [
    {
      benefitIcon: () => (
        <ReadyToFlyIcon width={WIDTH_HEIGHT_ICON} height={WIDTH_HEIGHT_ICON} />
      ),
      benefitName: intl.TITLE_READY_FLY,
      benefitDescription: intl.TEXT_READY_FLY,
    },
  ];

  return (
    <div className={styles.boxInfo}>
      <TitlePageMarketing
        title={intl.TEXT_YOUR_DOCUMENTS}
        textAlign={'center'}
        fontSize={'37px'}
      />

      <div className={styles.contentInfo}>
        <div className={styles.imageInfo}>
          <img src={beforeTrip} />
        </div>

        <div className={styles.cardInfo}>
          <PathItem benefits={benefitsTravelKit} />
        </div>
      </div>
    </div>
  );
};

export default InfoTravelKitMarketing;
