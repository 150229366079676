export const TRIP_ASSISTANCE = {
  en_US: 'Visa Travel Kit',
  pt_BR: 'Visa Travel Kit',
  es_ES: 'Visa Travel Kit',
};

export const REAL_TIME_TRACKING = {
  en_US: 'Real time flight tracking',
  pt_BR: 'Rastreamento de voo em tempo real',
  es_ES: 'Seguimiento de vuelos en tiempo real',
};

export const REAL_TIME_AVAILABLE = {
  en_US: 'Real time data available',
  pt_BR: 'Dados em tempo real disponíveis',
  es_ES: 'Datos disponibles en tiempo real',
};

export const FLIGHT = {
  en_US: 'Flight',
  es_ES: 'Vuelo',
  pt_BR: 'Voo',
};

export const ORIGIN_TO_DESTINATION = {
  en_US: (country1: string, country2: string) => `${country1} to ${country2}`,
  es_ES: (country1: string, country2: string) => `${country1} a ${country2}`,
  pt_BR: (country1: string, country2: string) => `${country1} para ${country2}`,
};

export const LAST_UPDATED = {
  en_US: 'Last updated',
  pt_BR: 'Ultima atualização',
  es_ES: 'Última actualización',
};

export const DURATION = {
  en_US: 'Duration',
  pt_BR: 'Duração',
  es_ES: 'Duración',
};

export const REMAINING = {
  en_US: 'Remaining',
  pt_BR: 'Restante',
  es_ES: 'Restante',
};

export const DISTANCE = {
  en_US: 'Distance',
  pt_BR: 'Distância',
  es_ES: 'Distancia',
};

export const FLIGHT_WAS_CANCELED = {
  en_US: 'Your flight was canceled',
  pt_BR: 'Seu voo foi cancelado',
  es_ES: 'Su vuelo fue cancelado',
};

export const DELAYED = {
  en_US: 'Delayed',
  pt_BR: 'Atrasado',
  es_ES: 'Retrasado',
};

export const EARLY = {
  en_US: 'Early',
  pt_BR: 'Adiantado',
  es_ES: 'Antes de lo previsto',
};

export const TERMINAL = {
  en_US: 'Terminal',
  pt_BR: 'Terminal',
  es_ES: 'Terminal',
};

export const GATE = {
  en_US: 'Terminal',
  pt_BR: 'Portão',
  es_ES: 'Puerta',
};

export const STATUS_SCHEDULED = {
  en_US: 'Scheduled',
  pt_BR: 'Programado',
  es_ES: 'Programado',
};

export const STATUS_DEPARTED_GATE = {
  en_US: 'Departed',
  pt_BR: 'Partiu',
  es_ES: 'Salió',
};

export const STATUS_ON_ROUTE = {
  en_US: 'On route',
  pt_BR: 'Em rota',
  es_ES: 'En ruta',
};

export const STATUS_LANDED = {
  en_US: 'Landed',
  pt_BR: 'Pousou',
  es_ES: 'Aterrizado',
};

export const STATUS_ARRIVED_GATE = {
  en_US: 'Arrived',
  pt_BR: 'Chegou',
  es_ES: 'Llegó',
};

export const SHARE_MY_FLIGHT = {
  en_US: 'Share my flight',
  pt_BR: 'Compartilhar meu voo',
  es_ES: 'Compartir mi vuelo',
};

export const DEPARTURE = {
  en_US: 'Departure',
  pt_BR: 'Partida',
  es_ES: 'Salida',
};

export const ARRIVAL = {
  en_US: 'Arrival',
  pt_BR: 'Chegada',
  es_ES: 'Llegada',
};

export const FLIGHT_LEG = {
  en_US: (leg: string) => `Flight leg ${leg}`,
  pt_BR: (leg: string) => `Trecho de voo ${leg}`,
  es_ES: (leg: string) => `Segmento de vuelo ${leg}`,
};

export const TRACK_FLIGHT_REAL_TIME = {
  en_US: 'Real-time flight tracking',
  pt_BR: 'Rastrear voo em tempo real',
  es_ES: 'Seguimiento en tiempo real',
};

export const MY_TRIP_ASSISTANCES = {
  en_US: 'My trips',
  pt_BR: 'Minhas viagens',
  es_ES: 'Mis viajes',
};

export const SAVING_TRIP = {
  en_US: 'Saving trip...',
  pt_BR: 'Salvando viagem...',
  es_ES: 'Guardando viaje...',
};

export const TRIP_ASSISTANCE_ACTIVATED = {
  en_US: 'Your Travel Kit was successfully activated!',
  pt_BR: 'Seu Travel Kit foi ativado com sucesso!',
  es_ES: '¡Tu Visa Travel Kit se activó con éxito!',
};

export const RECEIVE_REAL_TIME_ALERT = {
  en_US: 'Now you will receive alerts about your flight status in real time.',
  pt_BR:
    'Agora você vai receber alertas sobre a situação do seu vôo em tempo real.',
  es_ES: 'Ahora recibirás alertas sobre el estado de tu vuelo en tiempo real.',
};

export const CREATE_TRIP_ASSISTANCE = {
  en_US: 'Register my trip',
  es_ES: 'Registrar mi viaje',
  pt_BR: 'Registrar minha viagem',
};

export const CONSULT_TRIP_ASSISTANCE = {
  en_US: 'My trips',
  pt_BR: 'Minhas viagens',
  es_ES: 'Mis viajes',
};
