import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const TripCancelationIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill={config_icon.secondary}
        d="M17.5 13c-1.7 0-3.3-.6-4.6-1.9-2.5-2.5-2.5-6.7 0-9.2C14.1.7 15.7 0 17.5 0s3.4.7 4.6 1.9c2.5 2.5 2.5 6.7 0 9.2-1.3 1.3-3 1.9-4.6 1.9zm0-11c-1.2 0-2.3.5-3.2 1.3-1.8 1.8-1.8 4.6 0 6.4 1.8 1.8 4.6 1.8 6.4 0 1.8-1.8 1.8-4.6 0-6.4-.9-.8-2-1.3-3.2-1.3z"
      />
      <path
        fill={config_icon.primary}
        d="M22.6 16.7C21.5 15.6 20 15 18.4 15h-12l-2-3.4L3 13.1 5.3 17h13.2c1 0 2 .4 2.8 1.1.2.2.2.5 0 .7-.2.1-.3.2-.4.2h-6.7l-8 5H10l4.8-3h6.1c.7 0 1.3-.3 1.8-.7.9-1 .9-2.6-.1-3.6z"
      />
      <path
        fill={config_icon.primary}
        d="M10.4 19H5s-.7 0-1.2-.6l-2.3-3.8-1.4 1.5 2.1 3.4.1.1C3.2 21 4.8 21 5 21h2.2l3.2-2zM11.8 13l-2.4-2H6.3l2.4 2z"
      />
      <path
        fill={config_icon.secondary}
        d="M15 7.5 16.4 9l1-1.1L18.5 9l1.4-1.4-1-1.1 1-1L18.5 4l-1 1.1L16.4 4 15 5.4l1 1.1z"
      />
    </svg>
  );
};

export default TripCancelationIcon;
