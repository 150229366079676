/* GENDER */
export const MALE_VALUE = 'ML';
export const FEMALE_VALUE = 'FM';
export const MALE_VALUE_ABV = 'ml';
export const FEMALE_VALUE_ABV = 'fm';

/* RELATIONSHIP */
export const VALUE_SON = 'Son';
export const VALUE_DAUGHTER = 'Daughter';
export const VALUE_SPOUSE = 'Spouse';
export const VALUE_OTHER = 'Other';

/* REGISTRATION TYPES */
export const fieldValueRegistrationCPF = 'CPF';
export const fieldValueRegistrationPassp = 'PASSPORT';

export const NOT_REQUIRED = 'NOT_REQUIRED';
//type purchase protection
export const ANY_OTHER_REASON = 'ANY_OTHER_REASON';
export const HANDBAG_OR_WALLET = 'HANDBAG_OR_WALLET';
export const MOBILE_PHONE = 'MOBILE_PHONE';

export const MSG_ERROR_CPF = 'Duplicated Customer Registration';
