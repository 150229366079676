import { Dispatch, SetStateAction, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { claimsServices, cmxService } from '../../../../../../services';
import { IntlContext } from '../../../../../../intl/index';
import * as translations from '../../intl';
import { StateContext } from '../../../../../../components/StateContextParent/StateContextParent';
import ModalInformation from '../../../../../../components/ModalInformation/ModalInformation';
import { AppInsightTrackContext } from '../../../../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import {
  ENABLE_UUP,
  REACT_APP_UPLOAD_CONCLUDE_ENABLE,
} from '../../../../../../utils/environments_variables';
import {
  dataLayerClaimDocumentEventTrack,
  CLAIM_SITE_SECTION,
  CLAIM_DOCUMENT_ATTACHED,
  CLAIM_DOCUMENT_ATTACHED_FAILED,
  PROFILE_SITE_SECTION,
} from '../../../../../../utils/GTM_helper';
import { extensions } from '../../../../../../utils/fileExtensionsValidade';
import { isBR } from '../../../../../../utils/country';
import { ExpectedDocument } from '../../../../../../@types/APIs/ExpectedDocuments';
import { Attachment } from '../../../../../../@types/APIs/Customer';
import { ClaimInfo } from '../../../../../../@types/APIs/claim';
import FileUploader from '../../../../../../componentsV2/FileUploader/FileUploader';
import { UploadedFile } from '../../../../../../componentsV2/FileUploader/FileCard/FileCard';
import { emergency_medical_assistance } from '../../../../../../utils/claims_name';
import { StatusPossibility } from '../../../../../../componentsV2/Chip/StatusChip/status';
import StatusChip from '../../../../../../componentsV2/Chip/StatusChip/StatusChip';

const formatFilesStatus = (documents: Attachment[]): Attachment[] => {
  const clone = [...documents];

  clone.forEach(document => {
    if (!document) {
      document.document_status = 'PENDING';
    } else if (document.document_status) {
      document.document_status = document.document_status as StatusPossibility;
    } else {
      document.document_status = 'PENDING';
    }
  });

  return clone;
};

const getDocumentsName = (documents: Attachment[]): UploadedFile[] =>
  documents.map(
    document =>
      ({
        name: document.document_name,
        id: document.attachment_id,
      } as UploadedFile)
  );

export interface FiteItemProps {
  expectedDocument: ExpectedDocument;
  claimInfo: ClaimInfo;
  claimId: string;
  isFromProfile: boolean;
  setAlreadyAttached: Dispatch<SetStateAction<Attachment[]>>;
}

const UploadItem = (props: FiteItemProps) => {
  const {
    expectedDocument: {
      document_description,
      document_type_name,
      is_mandatory,
      is_multiple_upload_allowed,
    },
    claimInfo: { claim_type_name, attachments, benefit_external_reference },
    claimId,
    isFromProfile,
    setAlreadyAttached,
  } = props;

  const { translate, idiomForApi, country } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { actions, utils } = useContext(StateContext);

  const attachmentSelected = attachments.filter(
    file => file.document_type_name === document_type_name
  );

  const [submittedFiles, setSubmittedFiles] = useState<Attachment[]>(
    formatFilesStatus(attachmentSelected)
  );
  const [initialUploadedFilesList] = useState(getDocumentsName(submittedFiles));

  const LIMIT_ADDITIONAL_DOCUMENT =
    benefit_external_reference === emergency_medical_assistance ? 10 : 5;
  const intl = translate(translations);
  const cn = utils.getCn();
  const { modal } = actions;
  const isUUPDocument =
    document_type_name == 'National ID' ||
    (isBR(country) && document_type_name == 'Proof of Residency');

  const upload = async (file: File) => {
    trackEventUserAction(
      '#### (UPLOAD) ABRIU A JANELA PARA SELECIONAR DOCUMENTOS PARA O UPLOAD ####',
      true
    );
    const { name: document_name, type: content_type, size } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    await new Promise<void>(resolve => {
      reader.onload = () => resolve();
    });
    const content_data = String(reader.result).substring(
      String(reader.result).indexOf(',') + 1
    );

    const sizeKbFile = parseFloat(String(size / 1024)).toFixed(2);
    const sizeMBFile = parseFloat(String(Number(sizeKbFile) / 1024)).toFixed(2);
    //check size minor or equall to 1.5MB
    trackEventUserAction(
      '#### (UPLOAD) VERIFICANDO O TAMANHO DO ARQUIVO SELECIONADO PARA UPLOAD ####',
      { sizeKbFile, file }
    );
    if (Number(sizeKbFile) > 1500) {
      trackEventUserAction(
        '#### (UPLOAD) TAMANHO DO ARQUIVO SELECIONADO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####',
        { sizeKbFile, file }
      );
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED_FAILED,
        claim_type_name,
        null,
        document_name
      );

      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_SIZE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_SIZE_ERROR');
    }

    trackEventUserAction(
      '#### (UPLOAD) VERIFICANDO A EXTENSÃO DO ARQUIVO SELECIONADO PARA UPLOAD ####',
      { content_type, file }
    );
    if (!content_type || extensions.indexOf(content_type) < 0) {
      trackEventUserAction(
        '#### (UPLOAD) EXTENSÃO DO ARQUIVO SELECIONADO PARA UPLOAD NÃO É PERMITIDA ####',
        { content_type, file }
      );

      modal.showModal(
        false,
        true,
        <ModalInformation
          type="error"
          message={intl.FILE_TYPE_ERROR}
          subtitle={`${document_name} (${sizeMBFile} MB)`}
        />
      );
      throw new Error('FILE_TYPE_ERROR');
    }

    const data = {
      content_type,
      document_name,
      document_type_name: document_type_name,
      content_data,
      complete: !REACT_APP_UPLOAD_CONCLUDE_ENABLE,
    };

    try {
      let resp;
      trackEventUserAction(
        '#### (UPLOAD) FAZENDO O UPLOAD DO ARQUIVO SELECIONADO ####',
        { claimId, data }
      );

      if (
        (document_type_name == 'National ID' ||
          (document_type_name == 'Proof of Residency' && isBR(country))) &&
        ENABLE_UUP
      ) {
        const fileData = {
          content_data,
          content_type,
        };
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO SERÁ ENVIADO PARA O CMX ####',
          { fileData }
        );
        const responseCMX = await cmxService.uploadDocument(fileData);
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO CMX ####',
          {
            response_cmx:
              responseCMX && responseCMX.data ? responseCMX.data : null,
          }
        );
        const data = {
          document_type_name,
          document_name,
          document_description: document_type_name,
          external_attachment_id: responseCMX.data,
          content_type: content_type,
        };
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO CMX E SERÁ ENVIADO PARA O SALESFORCE ####',
          {
            data,
          }
        );
        const {
          data: { attachment_id },
        } = await cmxService.saveIdCMX(cn, data, idiomForApi());
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO FOI SALVO NO SALESFORCE COM SUCESSO ####',
          {
            attachment_id,
          }
        );
        resp = {
          data: {
            attachment_id,
          },
        };
      } else {
        trackEventUserAction(
          '#### (UPLOAD) ARQUIVO NÃO PRECISA DE CMX, VAI DIRETO PARA SALESFORCE ####',
          {
            claimId,
            data,
          }
        );
        resp = await claimsServices.postAttachment(claimId, data);
        trackEventUserAction(
          '#### (UPLOAD) ARQUIVO SALVO NO SALESFORCE COM SUCESSO ####',
          {
            resp,
          }
        );
      }
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED,
        claim_type_name,
        isFromProfile ? PROFILE_SITE_SECTION : CLAIM_SITE_SECTION,
        document_name
      );

      trackEventUserAction(
        '#### (UPLOAD) UPLOAD REALIZADO COM SUCESSO ####',
        resp
      );

      const newFile = {
        attachment_id: resp.data.attachment_id,
        document_name: document_name,
        document_status: 'PENDING',
        content_type: data.content_type,
        document_type_name: data.document_type_name,
      } as Attachment;

      setAlreadyAttached(attachments => {
        return [...attachments, newFile];
      });
      setSubmittedFiles(files => {
        return [...files, newFile];
      });
    } catch (error) {
      trackEventUserAction('#### (UPLOAD) ERRO AO REALIZAR O UPLOAD ####', {
        error,
        error_response: error.response,
      });
      dataLayerClaimDocumentEventTrack(
        CLAIM_DOCUMENT_ATTACHED_FAILED,
        claim_type_name,
        null,
        document_name
      );
      trackEventUserAction(
        '#### (UPLOAD) ERRO AO REALIZAR O UPLOAD - VAI CAIR NAS CONDICIONAIS ####',
        {
          error,
          error_response: error.response,
        }
      );
      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description &&
        error.response.data.error_description.startsWith(
          'ContentType_is_invalid_Please_verify'
        )
      ) {
        trackEventUserAction(
          '#### (UPLOAD) A EXTENSÃO DO ARQUIVO INSERIDO PARA UPLOAD NÃO É PERMITIDO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_TYPE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else if (error.response && error.response.status === 413) {
        trackEventUserAction(
          '#### (UPLOAD) O TAMANHO DO ARQUIVO INSERIDO PARA UPLOAD É MAIOR DO QUE O PERMITIDO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.FILE_SIZE_ERROR}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      } else {
        trackEventUserAction(
          '#### (UPLOAD) OCORREU UM ERRO GÉNERICO NO UPLOAD DO ARQUIVO ####',
          error
        );
        modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.UNEXPECTED_ERROR_OCCURRED}
            subtitle={`${document_name} (${sizeMBFile} MB)`}
          />
        );
      }
      throw error;
    }
  };

  const modalAlertUUP = async (userAction: () => Promise<void | boolean>) => {
    trackEventUserAction(
      '#### (UUP FLOW - CLAIM) MODAL INFORMANDO QUE OS DOCUMENTOS SERÃO MODIFICADOS ####',
      true
    );
    return new Promise<boolean>((resolve, reject) => {
      modal.showModal(
        false,
        false,
        <ModalInformation
          type="question"
          message={intl.TEXT_YOUR_PERSONAL_DOCUMENTS_MODIFIED}
          textBtn={intl.BTN_YES_UPDATE}
          textBtnDeny={intl.BTN_NOT_UPDATE}
          clickBtn={async () => {
            modal.closeModal();
            await userAction();
            resolve(true);
          }}
          clickBtnDeny={() => {
            modal.closeModal();
            reject();
          }}
        />,
        true
      );
    });
  };

  const uploadFile = async (file: File) => {
    if (isUUPDocument && ENABLE_UUP) {
      await modalAlertUUP(() => upload(file));
    } else {
      await upload(file);
    }
  };

  const removeFile = async (index: number) => {
    try {
      trackEventUserAction(
        '#### (UPLOAD) O USUÁRIO ESTÁ REMOVENDO O ARQUIVO ANEXADO ####',
        document_type_name
      );

      if (
        (document_type_name == 'National ID' ||
          (document_type_name == 'Proof of Residency' && isBR(country))) &&
        ENABLE_UUP
      ) {
        trackEventUserAction(
          '#### (UUP FLOW - CLAIM) O ARQUIVO DO UUP SERÁ DELETADO ####',
          true
        );
        await cmxService.deleteDocument(
          cn,
          submittedFiles[index].attachment_id,
          idiomForApi()
        );
      } else {
        await claimsServices.deleteAttachment(
          claimId,
          submittedFiles[index].attachment_id
        );
      }

      setAlreadyAttached(attachments => {
        const clone = [...attachments];
        const indexOf = clone
          .map(attachment => attachment.attachment_id)
          .indexOf(submittedFiles[index].attachment_id);
        clone.splice(indexOf, 1);
        return clone;
      });
      setSubmittedFiles(files => {
        const clone = [...files];
        clone.splice(index, 1);
        return clone;
      });
      trackEventUserAction(
        '#### (UPLOAD) O USUÁRIO REMOVEU O ARQUIVO ANEXADO COM SUCESSO ####',
        document_type_name
      );
      return true;
    } catch (error) {
      trackEventUserAction(
        '#### (UPLOAD) ERRO AO REMOVER O ARQUIVO ANEXADO ####',
        error
      );

      const error_description =
        error.response &&
        error.response.data &&
        error.response.data.error_description;
      modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={error_description} />
      );
      return false;
    }
  };

  const checkFileToRemove = (index: number) => {
    if (isUUPDocument && ENABLE_UUP) {
      return modalAlertUUP(() => removeFile(index));
    }
    return removeFile(index);
  };

  const CustomChips = () =>
    submittedFiles.map(file => (
      <StatusChip
        context="CLAIMS"
        status={file.document_status as StatusPossibility}
        key={file.attachment_id}
      />
    ));

  return (
    <li className={styles.uploadContainer}>
      <FileUploader
        title=""
        description={document_description}
        callbackAddFile={uploadFile}
        callbackDeleteFile={checkFileToRemove}
        multiple={is_multiple_upload_allowed}
        required={is_mandatory}
        hasLabel
        initialUploadedFilesList={initialUploadedFilesList}
        CustomChip={CustomChips}
        maxFiles={LIMIT_ADDITIONAL_DOCUMENT}
      />
    </li>
  );
};

export default UploadItem;
