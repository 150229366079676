import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../../intl';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import * as translations from '../intl';
import sanitize from '../../../../../../../../../utils/sanitize';

const ExpensesItem = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { index, values, handleChange, touched, handleBlur, errors } = props;

  return (
    <>
      <div className="col-12">
        <Input
          label={intl.TEXT_DESCRIPTION}
          value={values.incident.expenses[index].description}
          name={`incident.expenses[${index}].description`}
          onChange={handleChange}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].description
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].description
          }
          maxLength="1000"
          dataTestid="incidentDescription"
        />
      </div>

      {/* <div className="col-12 col-md-6">
        <Calendar
          label={intl.FIELD_LEGAL_EXPENSES_DATE}
          name={`incident.expenses[${index}].expenses_date`}
          onChange={(event) => {
            handleChange(event);
          }}
          value={values.incident.expenses[index].expenses_date}
          maxDate={new Date()}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].expenses_date
          }
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].expenses_date
          }
          setFieldTouched={setFieldTouched}
        />
      </div> */}

      <div className="col-12 col-md-6">
        <Input
          value={values.incident.expenses[index].amount.value}
          label={intl.FIELD_LEGAL_EXPENSES_VALUE}
          name={`incident.expenses[${index}].amount.value`}
          type="text"
          maskedMoney
          onChange={handleChange}
          tooltipText={intl.TEXT_AMOUNT_DECIMAL}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[index] &&
            touched.incident.expenses[index].amount &&
            touched.incident.expenses[index].amount.value
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[index] &&
            errors.incident.expenses[index].amount &&
            errors.incident.expenses[index].amount.value
          }
        />
      </div>
      <div className={`col-12 col-md-6`}>
        <Select
          dataTestid="selectOtherInsuranceCovering"
          label={intl.LABEL_CURRENCY}
          value={values.incident.expenses[0].amount.currency}
          name={`incident.expenses[${index}].amount.currency`}
          onChange={handleChange}
          disabled={index !== 0}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={
            touched.incident &&
            touched.incident.expenses &&
            touched.incident.expenses[0] &&
            touched.incident.expenses[0].amount &&
            touched.incident.expenses[0].amount.currency
          }
          onBlur={handleBlur}
          error={
            errors.incident &&
            errors.incident.expenses &&
            errors.incident.expenses[0] &&
            errors.incident.expenses[0].amount &&
            errors.incident.expenses[0].amount.currency
          }
        />
      </div>
    </>
  );
};

export default ExpensesItem;
