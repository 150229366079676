export const LABEL_FLIGHT_LEG = {
  en_US: leg => `Flight leg ${leg}`,
  pt_BR: leg => `Trecho de voo ${leg}`,
  es_ES: leg => `Segmento de vuelo ${leg}`,
};

export const LABEL_REMOVE_LEG = {
  en_US: 'Remove flight leg',
  pt_BR: 'Remover trecho de voo',
  es_ES: 'Eliminar segmento de vuelo',
};

export const LABEL_AIRLINE_COMPANY = {
  en_US: hasValue =>
    hasValue ? 'Airline company code *' : 'Airline company code (ie. AB) *',
  pt_BR: hasValue =>
    hasValue
      ? 'Código da companhia aérea *'
      : 'Código da companhia aérea (Ex. AB) *',
  es_ES: hasValue =>
    hasValue ? 'Código de la aerolinea *' : 'Código de la aerolinea (ej. AB) *',
};

export const LABEL_FLIGHT_NUMBER = {
  en_US: hasValue =>
    hasValue ? 'Flight number *' : 'Flight number (ie. 1234) *',
  pt_BR: hasValue =>
    hasValue ? 'Número do vôo *' : 'Número do vôo (Ex. 1234) *',
  es_ES: hasValue =>
    hasValue ? 'Número de vuelo *' : 'Número de vuelo (ej. 1234) *',
};

export const LABEL_FLIGHT_DATE = {
  en_US: 'Flight date *',
  pt_BR: 'Data do voo *',
  es_ES: 'Fecha del vuelo *',
};

export const TEXT_ERROR_FINDING_FLIGHT = {
  en_US:
    'An error occurred while searching you flight. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao procurar o seu voo. Por favor, tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al buscar tu vuelo. Por favor, inténtelo de nuevo más tarde.',
};

export const TEXT_ERROR_FLIGHT_NUMBER_NOT_NUMBER = {
  en_US: 'The flight number must be a positive number.',
  pt_BR: 'O número do voo deve ser um número positivo.',
  es_ES: 'El número de vuelo debe ser un número positivo.',
};
