import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const TIME_OFF_EXPENSES = {
  en_US: 'Did you take time off work in order to resolve the situation? *',
  es_ES: '¿Se tomó un tiempo libre en el trabajo para resolver la situación? *',
  pt_BR: 'Você se afastou do trabalho para resolver a situação? *',
};

export const AMOUNT_PAID = {
  en_US: 'Indicate the amount lost with your leave *',
  es_ES: 'Indica el monto perdido con tu licencia *',
  pt_BR: 'Indique o valor perdido com seu afastamento *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal. ',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};
