/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';
import { stringToBoolean } from '../../../../../../../utils/stringUtils';

export const idTheftSchema = (intl, isBrazil) => {
  let object = {};

  object.incident = incidentSchema(intl, isBrazil);
  object.affected_person = affectedPersonSchema(intl);
  object.amount_paid = amountPaidSchema(intl);
  object.time_off = timeOffSchema(intl);
  object.falsely_opened = falselyOpenedSchema(intl);
  object.other_expenses = otherExpensesSchema(intl);

  return Yup.object().shape(object);
};

const incidentSchema = (intl, isBrazil) => {
  return Yup.lazy(value => {
    let incident = {};

    incident.description = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255);

    incident.dateValue = Yup.string()
      .required(intl.VALIDATE_DATE_VALID)
      .typeError(intl.VALIDATE_DATE_VALID)
      .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
        const _date = moment(date).format('YYYY-MM-DD');
        const _dateCurrent = moment(new Date()).format('YYYY-MM-DD');
        return moment(_date).isSameOrBefore(_dateCurrent);
      });

    return Yup.object().shape({
      ...incident,
    });
  });
};

const affectedPersonSchema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};
    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });
    affectedPerson.person = Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    });

    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};

const amountPaidSchema = intl => {
  return Yup.lazy(value => {
    let amount_paid = {};

    if (stringToBoolean(value.checked)) {
      amount_paid.amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...amount_paid,
    });
  });
};

const timeOffSchema = intl => {
  return Yup.lazy(value => {
    let time_off = {};

    if (stringToBoolean(value.checked)) {
      time_off.amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...time_off,
    });
  });
};

const falselyOpenedSchema = intl => {
  return Yup.lazy(value => {
    let falsely_opened = {};

    if (stringToBoolean(value.checked)) {
      falsely_opened.amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...falsely_opened,
    });
  });
};

const otherExpensesSchema = intl => {
  return Yup.lazy(value => {
    let other_expenses = {};

    if (stringToBoolean(value.checked)) {
      other_expenses.amount = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currency: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
      });
    }

    return Yup.object().shape({
      ...other_expenses,
    });
  });
};
