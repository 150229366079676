export default (env, axios, errorHandler) => {
  const customerToAmosUser = customer => {
    const formatGender = person => {
      if (person.gender === 'ML') return 'male';
      else if (person.gender === 'FM') return 'female';
      else return '';
    };

    return {
      client_code: customer.customer_id,
      name: customer.person.first_name,
      surname: customer.person.last_name,
      gender: formatGender(customer.person),
      birth_date: customer.person.birth_date,
      country: customer.address.country,
      email: customer.email,
      phone_prefix: customer.phone.international_prefix,
      phone: customer.phone.number,
    };
  };

  const videoCallBodyFormated = (customer, patient) => {
    return {
      customer,
      patient,
    };
  };

  return {
    create: (customer, requestBody) => {
      const url = `/amos/telehealth/videocalls/create`;
      const customerFormated = customerToAmosUser(customer);
      const body = videoCallBodyFormated(customerFormated, requestBody);

      return errorHandler(axios.post(url, body), 'createVideocall', {
        body,
      });
    },
    getAppointmentListVideoCalls: (customer, params) => {
      const url = `/amos/telehealth/videocalls`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated, { params }),
        'getAppointmentListVideoCalls',
        customerFormated
      );
    },
    getAvailabilityDates: (
      date_from,
      date_to,
      language,
      time_zone,
      country
    ) => {
      let url = `/amos/telehealth/videocalls/availability?date_from=${date_from}&date_to=${date_to}&language=${language}&time_zone=${encodeURIComponent(
        time_zone
      )}`;

      if (country) {
        url += `&country=${country}`;
      }

      return errorHandler(axios.get(url), 'getAvailabilityDates');
    },
    cancelAppointmentVideoCall: (customer, videoCallId) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/cancel`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated),
        'cancelAppointmentVideoCall',
        customerFormated
      );
    },
    getVideoCallById: (customer, videoCallId, files) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated, {
          params: { files },
        }),
        'getAppointmentListVideoCalls',
        customerFormated
      );
    },
    startVideocall: (customer, videoCallId) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/start`;
      const customerFormated = customerToAmosUser(customer);

      // Testing code, use Vonege credentials to create a videocall: https://tokbox.com/developer/quickstart/
      // return {
      //   data: {
      //     data: {
      //       teladoc_auth_details: {
      //         api_key: '',
      //         session_id: '',
      //         token: '',
      //         video_provider: 'tokbox',
      //       },
      //       teladoc_attendee_id: 3,
      //       teladoc_id: 1,
      //       teladoc_jwt: 'eyJhbGciOiJsInR5cCI6IkpXVCJ9.eyJhiOiJBdHRlbmRh',
      //     },
      //   },
      // };

      return errorHandler(
        axios.post(url, customerFormated),
        'startVideocall',
        customerFormated
      );
    },
    endVideocall: (customer, videoCallId) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/end`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, customerFormated),
        'endVideocall',
        customerFormated
      );
    },
    removeFile: (customer, videoCallId, fileId) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/remove-files`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, { customerFormated, fileId }),
        'removeFile',
        customerFormated
      );
    },
    sendFile: (customer, videoCallId, uploadId) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/attach-files`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, { customerFormated, uploadId }),
        'sendFile',
        customerFormated
      );
    },
    rescheduleDate: (customer, videoCallId, timezone, time, date) => {
      const url = `/amos/telehealth/videocalls/${videoCallId}/reschedule`;
      const customerFormated = customerToAmosUser(customer);

      return errorHandler(
        axios.post(url, { customerFormated, timezone, time, date }),
        'rescheduleDate',
        customerFormated
      );
    },
    uploadFile: (customer, fileFormatted) => {
      const url = `/amos/uploads`;
      const customerFormated = customerToAmosUser(customer);
      return errorHandler(
        axios.post(url, { customerFormated, fileFormatted }),
        'uploadFile',
        customerFormated
      );
    },
  };
};
