export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date',
  pt_BR: 'Data da compra',
  es_ES: 'Fecha de compra',
};

export const LABEL_ADVERTISEMENT_DATE = {
  en_US: 'Advertisement date',
  pt_BR: 'Data do anúncio',
  es_ES: 'Fecha del anuncio',
};

export const LABEL_ITEM_PURCHASED = {
  en_US: 'Item purchased',
  pt_BR: 'Produto comprado',
  es_ES: 'Artículo comprado',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer',
  pt_BR: 'Fabricante',
  es_ES: 'Fabricante',
};

export const LABEL_MODEL = {
  en_US: 'Model',
  pt_BR: 'Modelo',
  es_ES: 'Modelo',
};

export const LABEL_ADVERTISEMENT_STORE = {
  en_US: 'Name of store/website advertising the product',
  pt_BR: 'Nome da loja/website que anuncia o produto',
  es_ES: 'Nombre de la tienda que anuncia el producto',
};

export const LABEL_TOTAL_PRICE_DIFFERENCE = {
  en_US: 'Total of the price difference',
  pt_BR: 'Total da diferença de preço',
  es_ES: 'Total de la diferencia de precio',
};
