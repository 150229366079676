import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const ExpenseArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const medical_service = claimDetails.medical_service;
  const amount_estimation_damage = claimDetails.amount_estimation_damage;

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  const expenseType = type => {
    switch (type) {
      case 'MEDICAL':
        return intl.OPTION_VALUE_MEDICAL;
      case 'MEDICINE':
        return intl.OPTION_VALUE_MEDICINE;
      case 'DENTAL':
        return intl.OPTION_VALUE_DENTAL;
      case 'TRANSPORT':
        return intl.OPTION_VALUE_TRANSPORT;
      case 'ACCOMMODATION':
        return intl.OPTION_VALUE_ACCOMMODATION;
      case 'OTHERS':
        return intl.OPTION_VALUE_OTHERS;
      default:
        return '-';
    }
  };

  const paymentType = type => {
    switch (type) {
      case 'CREDIT_CARD':
        return intl.PAYMENT_CREDIT_CARD;
      case 'DEBIT_CARD':
        return intl.PAYMENT_DEBIT_CARD;
      case 'ELECTRONIC_TRANSFER':
        return intl.PAYMENT_ELECTRONIC_TRANSFER;
      case 'VIRTUAL_CREDIT_CARD':
        return intl.PAYMENT_VIRTUAL_CREDIT_CARD;
      case 'CASH':
        return intl.PAYMENT_CASH;
      case 'CHEQUE':
        return intl.PAYMENT_CHECK;
      default:
        return '-';
    }
  };

  return (
    <>
      {medical_service.expenses.map((expense, index) => (
        <React.Fragment key={index}>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_TYPE}
              value={expenseType(expense.type)}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_AMOUNT}
              value={safeLoad(expense.price.value)}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_CURRENCY}
              value={getCurrencyNameByInitials(expense.price.currency, idiom)}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_FORM_PAYMENT}
              value={paymentType(expense.payment_mode)}
            />
          </div>
        </React.Fragment>
      ))}

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TOTAL_REIMBURSED}
          value={safeLoad(amount_estimation_damage.value)}
        />
      </div>

      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_CURRENCY}
          value={getCurrencyNameByInitials(
            amount_estimation_damage.currency,
            idiom
          )}
        />
      </div>
    </>
  );
};

export default ExpenseArea;
