export const TEXT_TITLE = {
  en_US: 'Discover our subscriptions with top brands and experiences',
  es_ES:
    'Descubre suscripciones exclusivas con las mejores marcas y experiencias únicas',
  pt_BR: 'Descubra nossas assinaturas com as melhores marcas e experiências',
};

export const TEXT_SUBTITLE = {
  en_US:
    'Visa gives you with once-in-a-lifetime experiences, in addition to exclusive subscriptions.',
  es_ES:
    'Visa te brinda experiencias únicas en la vida, además de suscripciones exclusivas.',
  pt_BR:
    'A Visa lhe proporciona experiências únicas, além de assinaturas exclusivas.',
};

export const TEXT_TITLE_PRIVATE = {
  en_US: product => `Explore your Visa ${product} card's subscriptions`,
  es_ES: product => `Explora las suscripciones de tu Visa ${product}`,
  pt_BR: product => `Conheça as assinaturas do seu Visa ${product}`,
};

export const TEXT_SUBTITLE_PRIVATE = {
  en_US:
    'Your access to subscriptions with top brands and experiences is only a click away.',
  es_ES:
    'Tu acceso a suscripciones con las mejores marcas y experiencias está a solo un clic de distancia.',
  pt_BR:
    'Seu acesso a assinaturas com as melhores marcas e experiências está a apenas um clique de distância.',
};

export const TEXT_LOGIN = {
  en_US: 'Log in to use the Visa Benefits Portal',
  es_ES: 'Inicia sesión para utilizar el Portal de Beneficios Visa',
  pt_BR: 'Inicie sessão e utilize o Portal de Benefícios Visa',
};

export const TEXT_SPECIAL_BENEFITS = {
  en_US: product => `Your Visa ${product} card's subscriptions`,
  es_ES: product => `Suscripciones de tu Visa ${product}`,
  pt_BR: product => `Assinaturas do seu Visa ${product}`,
};

export const TEXT_EMPTY_SPECIAL_BENEFITS = {
  en_US:
    'Unfortunately there are no subscription benefit associated with the card currently selected',
  es_ES:
    'Lamentablemente, no tienes ningún beneficio de suscripción asociado con la tarjeta actualmente seleccionada',
  pt_BR:
    'Infelizmente não há nenhum benefício de assinatura associado ao cartão selecionado no momento',
};

export const TEXT_EMPTY_SPECIAL_BENEFITS_PRIVATE = {
  en_US:
    'Unfortunately you do not have any subscription benefit associated with the card currently selected. Try selecting another card from your list of cards or do an Upgrade.',
  es_ES:
    'Lamentablemente, no tienes ningún beneficio de suscripción asociado con la tarjeta actualmente seleccionada. Intenta seleccionar otra tarjeta de tu lista de tarjetas o realiza un Upgrade.',
  pt_BR:
    'Infelizmente você não tem nenhum benefício de assinatura associado ao cartão selecionado no momento. Experimente selecionar outro cartão da sua lista de cartões ou faça um Upgrade.',
};

export const TEXT_LOAD_MORE = {
  en_US: 'Load more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};
