import { getQueryVariable } from '../utils/uri';

export const countryByBrowser = () => {
  let country;
  const language = getQueryVariable('lang');

  let userLang = language || navigator.language || navigator.userLanguage;
  userLang = userLang.substring(0, 2);

  switch (userLang) {
    case 'pt':
      country = 'BR';
      break;
    case 'en':
      country = 'BS';
      break;
    case 'es':
      country = 'MX';
      break;
    default:
      country = null;
      break;
  }
  return country;
};
