import React, { useContext } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Calendar from '../../../../../components/Calendar/Calendar';
import moment from 'moment';

const PeriodArea = props => {
  const { CONFIG_B2B2C } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange } = props;

  return (
    <div className="row">
      {CONFIG_B2B2C.quote.form.trip_start && (
        <div className="col-12 col-md-6">
          <Calendar
            label={intl.TRIP_START}
            name="Quote.TripInitialDate"
            onChange={handleChange}
            minDate={new Date()}
            formikProps={props}
            axa
          />
        </div>
      )}
      {CONFIG_B2B2C.quote.form.trip_end && (
        <div className="col-12 col-md-6">
          <Calendar
            label={intl.TRIP_END}
            name="Quote.TripEndDate"
            onChange={handleChange}
            minDate={
              values && values.Quote && values.Quote.TripInitialDate
                ? new Date(moment(values.Quote.TripInitialDate).add(1, 'day'))
                : new Date(moment().add(1, 'day'))
            }
            maxDate={
              values && values.Quote && values.Quote.TripInitialDate
                ? new Date(moment(values.Quote.TripInitialDate).add(60, 'day'))
                : new Date(moment().add(1, 'day'))
            }
            formikProps={props}
            axa
          />
        </div>
      )}
    </div>
  );
};

export default PeriodArea;
