import React, { useContext } from 'react';
import { IntlContext } from '../../../../intl';
import styles from './BenefitsList.module.scss';
import * as translations from './intl';
import medicalIcon from '../../../../assets/images/B2B2C/Icon_Medical.svg';
import baggageIcon from '../../../../assets/images/B2B2C/Icon_Baggage.svg';
import tripIcon from '../../../../assets/images/B2B2C/Icon_Trip.svg';
import infoIcon from '../../../../assets/images/B2B2C/Icon_Info.svg';
import requirementsIcon from '../../../../assets/images/B2B2C/Icon_Requirements.svg';

const BenefitsList = () => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const benefits = [
    {
      icon: medicalIcon,
      description: intl.BENEFIT_MEDICAL_EMERGENCY,
      id: 'benefitMedicalEmerncy',
    },
    {
      icon: baggageIcon,
      description: intl.BAGGAGE_PROTECTION,
      id: 'baggageProtection',
    },
    {
      icon: tripIcon,
      description: intl.TRIP_CANCELATION,
      id: 'tripCancelation',
    },
    {
      icon: infoIcon,
      description: intl.DESTINATION_INFORMATION,
      id: 'destinationInformation',
    },
    {
      icon: requirementsIcon,
      description: intl.HEALTH_REQUIREMENTS,
      id: 'healthRequirements',
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.textArea}>
        <h2>{intl.TITLE}</h2>
        <p>{intl.DESCRIPTION}</p>
      </div>
      <div className={styles.iconArea}>
        <ul>
          {benefits.map(benefit => (
            <li key={benefit.id}>
              <div className={styles.content}>
                <img src={benefit.icon} />
                <h3>{benefit.description}</h3>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BenefitsList;
