import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const ClaimsPlus = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3 20H5.5V22H13.3V20Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M7.5 2H19.5V13.7H21.5V0H5.5V5.4L7.5 6.7V2Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M7.5 18V13.9L5.5 12.6V18H7.5Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M17.5 4H9.5V6H17.5V4Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M1.6 5.20001L0.5 6.80001L11 13.8L12.1 12.1L1.6 5.20001Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
      <path
        d="M21.4 18H19.4V16H17.4V18H15.4V20H17.4V22H19.4V20H21.4V18Z"
        fill={props.lightBackground ? config_icon.primary : config_icon.white}
      />
    </svg>
  );
};

export default ClaimsPlus;
