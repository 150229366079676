import React, { useContext } from 'react';

import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import Textarea from '../../../../../components/Textarea/Textarea';
import InfoLabelValue from '../../../../../components/InfoLabelValue/InfoLabelValue';
import styles from './ReasonArea.module.scss';

const ReasonArea = ({ formikProps, dataTestId }) => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange, touched, handleBlur, errors } = formikProps;

  return (
    <div className="row" data-testid={dataTestId}>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_DENIAL_REASON}
          value={values.reason}
        />
      </div>
      <div className="col-12">
        <InfoLabelValue
          label={intl.LABEL_DECISION_DETAILS}
          value={values.description}
        />
      </div>
      <div className="col-12">
        <Textarea
          value={values.comment}
          label={intl.LABEL_APPEAL_REASON}
          name="comment"
          type="text"
          onChange={handleChange}
          touched={touched.comment}
          onBlur={handleBlur}
          error={errors.comment}
          maxLength="2000"
          className={styles.textArea}
          rows={8}
        />
      </div>
    </div>
  );
};

export default ReasonArea;
