import { SVGProps } from 'react';
import { useIconColors } from '../../hooks/useIconColors';
import { removeCustomIconProps } from '../../utils/icons';

interface CheckboxIconProps extends SVGProps<SVGSVGElement> {
  axa?: boolean;
  checked?: boolean;
  color: never;
}

const CheckboxIcon = (props: CheckboxIconProps) => {
  const colors = useIconColors(props);
  const handledProps = removeCustomIconProps(props);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      {...handledProps}
    >
      <path
        fill={props.checked ? colors.secondary : undefined}
        stroke={props.checked ? colors.secondary : colors.primary}
        d="M.5.5h14v14H.5z"
      />
    </svg>
  );
};

export default CheckboxIcon;
