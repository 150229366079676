/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import * as constants from './consts';

let is_accidental_death = false;

export const cellPhoneProtectionFormValidation = intl => {
  let object = {};
  object.affected_person = affectedPersonSchema(intl);
  object.incident = incidentSchema(intl);
  object.purchase_protection_items = purchaseProtectionItemsSchema(intl);

  return Yup.object().shape(object);
};

const affectedPersonSchema = intl => {
  return Yup.lazy(value => {
    let affectedPerson = {};

    affectedPerson.relationship = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );

    affectedPerson.comment = Yup.string().when(['relationship'], {
      is: relationship => {
        return relationship === constants.OTHER_VALUE;
      },
      then: Yup.string()
        .required(intl.TEXT_FIELD_REQUIRED)
        .max(255, intl.TEXT_FIELD_VALIDATION_255),
    });
    affectedPerson.person = Yup.object().shape({
      first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    });

    return Yup.object().shape({
      ...affectedPerson,
    });
  });
};

const incidentSchema = intl => {
  return Yup.lazy(value => {
    let incident = {};

    // incident.occured_at = Yup.date()
    //   .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
    //   .required(intl.TEXT_FIELD_REQUIRED)
    //   .typeError(intl.TEXT_FIELD_REQUIRED);

    incident.dateValue = Yup.date()
      .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED);

    incident.description = Yup.string()
      .required(intl.TEXT_FIELD_REQUIRED)
      .max(255, intl.TEXT_FIELD_VALIDATION_255);
    return Yup.object().shape({
      ...incident,
    });
  });
};

const purchaseProtectionItemsSchema = intl => {
  return Yup.lazy(value => {
    let purchase_protection_items = {};
    purchase_protection_items.manufacturer = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    purchase_protection_items.model_number = Yup.string().required(
      intl.TEXT_FIELD_REQUIRED
    );
    purchase_protection_items.price = Yup.object().shape({
      value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      currency: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
    });
    if (value.is_repairable === 'true') {
      purchase_protection_items.amount_claimed = Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      });
    }

    purchase_protection_items.purchase_date = Yup.date()
      .validateDateIsSameOrBeforeCurrentDate(intl.VALIDATE_DATE_VALID)
      .required(intl.TEXT_FIELD_REQUIRED)
      .typeError(intl.TEXT_FIELD_REQUIRED);

    purchase_protection_items.other_insurance_covering = Yup.object().shape({
      is_internal: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      amount: Yup.object().shape({
        value: Yup.string().when(
          ['is_internal'],
          {
            is: is_internal => {
              return value.other_insurance_covering.is_internal === 'true';
            },
            then: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          },
          ['is_internal']
        ),
      }),
    });

    return Yup.object().shape({
      ...purchase_protection_items,
    });
  });
};
