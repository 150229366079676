import React, { useContext } from 'react';
import * as translations from './intl';
import { IntlContext } from '../../../../../intl';
import styles from '../PurchaseProtectionDetail.module.scss';
import InfoLabelValue from '../../../../InfoLabelValue/InfoLabelValue';
import {
  getItemManufacturer,
  getItemModel,
  getItemName,
  getItemPurchaseDate,
  getItemPurchasePrice,
  getItemTypeOfLoss,
  getItemPurchaseTotalInvoiceAmount,
  getItemPaymentMethod,
  getItemInstallment,
  getItemInstallmentPrice,
} from '../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import GroupDivisor from '../../../../GroupDivisor';

const PurchasedItem = props => {
  const { item, itemNumber } = props;
  const { translate, idiom, country, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);

  return (
    <>
      <div className="col-12">
        <GroupDivisor
          title={`${intl.TEXT_ITEM}${itemNumber}`}
          className={styles.itemSeparator}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_DATE}
          value={getItemPurchaseDate(item, idiom, country)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_TYPE_OF_LOSS}
          value={getItemTypeOfLoss(item, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_ITEM_PURCHASED}
          value={getItemName(item)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={getItemManufacturer(item)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue label={intl.LABEL_MODEL} value={getItemModel(item)} />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_PRICE}
          value={getItemPurchasePrice(item, idiomForApi())}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_PURCHASE_TOTAL_INVOICE_AMOUNT}
          value={getItemPurchaseTotalInvoiceAmount(item, idiomForApi())}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.PAYMENT_METHOD}
          value={getItemPaymentMethod(item, intl)}
        />
      </div>
      {item && item.number_of_installments > 0 && (
        <>
          <div className="col-sm-12 col-md-6">
            <InfoLabelValue
              label={intl.INSTALLMENTS_NUMBER}
              value={getItemInstallment(item)}
            />
          </div>
          <div className="col-sm-12 col-md-6">
            <InfoLabelValue
              label={intl.INSTALLMENTS_PRICE}
              value={getItemInstallmentPrice(item, idiomForApi())}
            />
          </div>
        </>
      )}
    </>
  );
};

export default PurchasedItem;
