export const CONSUMER = {
  en_US: 'Consumers',
  es_ES: 'Consumidores',
  pt_BR: 'Consumidores',
};

export const CORPORATE = {
  en_US: 'Commercial ',
  es_ES: 'Comerciales',
  pt_BR: 'Comercial',
};

export const ALL = {
  en_US: 'All ',
  es_ES: 'Todos',
  pt_BR: 'Todos',
};
