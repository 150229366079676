const footerLinks = intl => {
  return [
    {
      order: 0,
      href: '/benefits',
      text: intl.TEXT_BENEFITS,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'first',
    },
    // commented because offers is not ready for production
    {
      order: 1,
      href: '/offers',
      text: intl.TEXT_OFFERS_PROMOTIONS,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'second',
    },
    {
      order: 1,
      href: '/subscription-benefits',
      text: intl.TEXT_ESPECIAL_BENEFITS,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'third',
    },
    {
      order: 2,
      href: '/certificates',
      text: intl.TEXT_CERTIFICATES,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'fourth',
    },
    {
      order: 3,
      href: '/claims',
      text: intl.TEXT_CLAIMS,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'fifth',
    },
    {
      order: 4,
      href: '/help',
      text: intl.TEXT_HELP,
      target: '_SELF',
      position: 'left',
      idiom: [],
      id: 'sixth',
    },
    {
      order: 5,
      href: intl.LINK_AGENT_CTA,
      text: intl.TEXT_AGENT_CTA,
      target: '_BLANK',
      position: 'right',
      idiom: ['pt_BR'],
      id: 'seventh',
    },
    {
      order: 6,
      href: intl.LINK_VISA_BRAZIL,
      text: intl.TEXT_VISA_BRAZIL,
      target: '_BLANK',
      position: 'right',
      idiom: ['pt_BR'],
      id: 'eighth',
    },
    {
      order: 7,
      href: intl.LINK_TERMS_SERVICES,
      text: intl.TEXT_TERMS_SERVICES,
      target: '_BLANK',
      position: 'right',
      idiom: [],
      id: 'ninth',
    },
    {
      order: 8,
      href: intl.LINK_PRIVACY_POLICY,
      text: intl.TEXT_PRIVACY_POLICY,
      target: '_BLANK',
      position: 'right',
      idiom: [],
      id: 'tenth',
    },
    {
      order: 9,
      href: intl.LINK_PRIVACY_POLICY_AIG,
      text: intl.TEXT_PRIVACY_POLICY_AIG,
      target: '_BLANK',
      position: 'right',
      idiom: [],
      id: 'eleventh',
    },
    {
      order: 10,
      href: intl.LINK_COOKIE_POLICY,
      text: intl.TEXT_COOKIE_POLICY,
      target: '_BLANK',
      position: 'right',
      idiom: [],
      id: 'twelfth',
    },
  ];
};

export const filterByPosition = (intl, position, lang) => {
  const data = footerLinks(intl);
  const links = data.filter(value => {
    let idiom = true;

    if (value.idiom.length > 0 && value.idiom.indexOf(lang) === -1) {
      idiom = false;
    }

    return value.position === position && idiom;
  });

  return links;
};
