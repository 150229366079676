export {
  MY_TRIP_ASSISTANCES,
  SAVING_TRIP,
  TRIP_ASSISTANCE_ACTIVATED,
  RECEIVE_REAL_TIME_ALERT,
} from '../../../../intl/travels';
export { GO_TO_HOMEPAGE } from '../../../../intl/navigation';

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const TEXT_INVALID_DATE = {
  en_US: 'Invalid date',
  pt_BR: 'Data inválida',
  es_ES: 'Fecha invalida',
};

export const TEXT_FLIGHT_NUMBER = {
  en_US: 'Invalid flight information.',
  pt_BR: 'Informações de voo inválidas.',
  es_ES: 'Información de vuelo no válida.',
};

export const TEXT_ERROR_FLIGHT_NUMBER_NOT_NUMBER = {
  en_US: 'The flight number must be a positive number.',
  pt_BR: 'O número do voo deve ser um número positivo.',
  es_ES: 'El número de vuelo debe ser un número positivo.',
};

export const TEXT_ERROR_ORIGIN_DONT_CORRESPOND = {
  en_US:
    'The origin country does not correspond to the country stated on the travel certificate.',
  pt_BR:
    'O país de origem não corresponde ao país informado no bilhete de viagem.',
  es_ES:
    'El país de origen no corresponde al país indicado en el certificado de viaje.',
};

export const MAXIMUM_CHARACTERS = {
  en_US: characters => `Please enter a maximum of ${characters} characters.`,
  pt_BR: characters => `Por favor, insira no máximo ${characters} caracteres.`,
  es_ES: characters =>
    `Por favor, introduzca un máximo de ${characters} caracteres.`,
};

export const MINIMUM_CHARACTERS = {
  en_US: characters => `Please enter a minimum of ${characters} characters.`,
  pt_BR: characters => `Por favor, insira no mínimo ${characters} caracteres.`,
  es_ES: characters =>
    `Por favor, introduzca un mínimo de ${characters} caracteres.`,
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while saving you flight details. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao salvar seus detalhes de voo. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al guardar los detalles de vuelo. Por favor, inténtalo de nuevo más tarde.',
};
