import { en_currency } from '../../../../../../../../intl/currency/en_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';

export const LABEL_TOTAL_REIMBURSED = {
  en_US: 'Total amount to be reimbursed *',
  pt_BR: 'Total a ser reembolsado *',
  es_ES: 'Importe total a reembolsar *',
};

export const TOOLTIP_TOTAL_REIMBURSED = {
  en_US: 'Amount susceptible to analysis.',
  es_ES: 'Importe susceptible de análisis.',
  pt_BR: 'Valor suscetível a análise.',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};
