import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import Input from '../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../components/Select/Select';
import styles from '../HotelBurglary.module.scss';
import * as translations from './intl';
import sanitize from '../../../../../../../../utils/sanitize';
import GroupDivisor from '../../../../../../../../components/GroupDivisor';

const PlaceLossArea = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const { values, handleChange, touched, handleBlur, errors } = props;
  return (
    <div className={`row ${styles.incidentArea}`}>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_PLACE_OF_LOSS} />
      </div>
      <div className="col-12 col-sm-12 col-md-6">
        <Select
          dataTestid="selectPlaceOfLoss"
          label={intl.TEXT_PLACE_OF_LOSS}
          value={values.incident.location.country}
          name="incident.location.country"
          onChange={handleChange}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.COUNTRY_LABEL}</option>${intl.COUNTRY_OPTIONS}`
            ),
          }}
          onBlur={handleBlur}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.country
          }
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.country
          }
        />
      </div>

      <div className="col-12 col-sm-12 col-md-6">
        <Input
          label={intl.TEXT_CITY_INCIDENT}
          name="incident.location.locality"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.incident.location.locality}
          touched={
            touched.incident &&
            touched.incident.location &&
            touched.incident.location.locality
          }
          error={
            errors.incident &&
            errors.incident.location &&
            errors.incident.location.locality
          }
          maxLength="100"
        />
      </div>
    </div>
  );
};

export default PlaceLossArea;
