export const NORTH_AMERICA = 'NORTH_AMERICA';
export const SOUTH_AMERICA = 'SOUTH_AMERICA';
export const CENTRAL_AMERICA = 'CENTRAL_AMERICA';
export const CARIBBEAN = 'CARIBBEAN';

export const listCountries = [
  {
    prioritary: 'true',
    initials: 'AI',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Anguila',
    en_US: 'Anguilla',
    es_ES: 'Anguila',
    continent: CARIBBEAN,
    order: 1,
  },
  {
    prioritary: 'true',
    initials: 'AR',
    idiom: 'es_ES',
    currency: 'ARS',
    pt_BR: 'Argentina',
    en_US: 'Argentina',
    es_ES: 'Argentina',
    continent: SOUTH_AMERICA,
    order: 1,
  },
  {
    prioritary: 'true',
    initials: 'BS',
    idiom: 'en_US',
    currency: 'BSD',
    pt_BR: 'Bahamas',
    en_US: 'Bahamas',
    es_ES: 'Bahamas',
    continent: CARIBBEAN,
    order: 2,
  },
  {
    prioritary: 'true',
    initials: 'BB',
    idiom: 'en_US',
    currency: 'BBD',
    pt_BR: 'Barbados',
    en_US: 'Barbados',
    es_ES: 'Barbados',
    continent: CARIBBEAN,
    order: 3,
  },
  {
    prioritary: 'true',
    initials: 'BZ',
    idiom: 'en_US',
    currency: 'BZD',
    pt_BR: 'Belize',
    en_US: 'Belize',
    es_ES: 'Belice',
    continent: CENTRAL_AMERICA,
    order: 7,
  },
  {
    prioritary: 'true',
    initials: 'BO',
    idiom: 'es_ES',
    currency: 'BOB',
    pt_BR: 'Bolívia',
    en_US: 'Bolivia',
    es_ES: 'Bolivia',
    continent: SOUTH_AMERICA,
    order: 2,
  },
  {
    prioritary: 'true',
    initials: 'BR',
    idiom: 'pt_BR',
    currency: 'BRL',
    pt_BR: 'Brasil',
    en_US: 'Brazil',
    es_ES: 'Brasil',
    continent: SOUTH_AMERICA,
    order: 3,
  },
  {
    prioritary: 'true',
    initials: 'CL',
    idiom: 'es_ES',
    currency: 'CLP',
    pt_BR: 'Chile',
    en_US: 'Chile',
    es_ES: 'Chile',
    continent: SOUTH_AMERICA,
    order: 4,
  },
  {
    prioritary: 'true',
    initials: 'CO',
    idiom: 'es_ES',
    currency: 'COP',
    pt_BR: 'Colômbia',
    en_US: 'Colombia',
    es_ES: 'Colombia',
    continent: SOUTH_AMERICA,
    order: 5,
  },
  {
    prioritary: 'true',
    initials: 'CR',
    idiom: 'es_ES',
    currency: 'CRC',
    pt_BR: 'Costa Rica',
    en_US: 'Costa Rica',
    es_ES: 'Costa Rica',
    continent: CENTRAL_AMERICA,
    order: 1,
  },
  {
    prioritary: 'true',
    initials: 'GT',
    idiom: 'es_ES',
    currency: 'GTQ',
    pt_BR: 'Guatemala',
    en_US: 'Guatemala',
    es_ES: 'Guatemala',
    continent: CENTRAL_AMERICA,
    order: 2,
  },
  {
    prioritary: 'true',
    initials: 'GY',
    idiom: 'en_US',
    currency: 'GYD',
    pt_BR: 'Guiana',
    en_US: 'Guyana',
    es_ES: 'Guyana',
    continent: SOUTH_AMERICA,
    order: 6,
  },
  {
    prioritary: 'true',
    initials: 'HT',
    idiom: 'es_ES',
    currency: 'HTG',
    pt_BR: 'Haiti',
    en_US: 'Haiti',
    es_ES: 'Haití',
    continent: CARIBBEAN,
    order: 4,
  },
  {
    prioritary: 'true',
    initials: 'HN',
    idiom: 'es_ES',
    currency: 'LST',
    pt_BR: 'Honduras',
    en_US: 'Honduras',
    es_ES: 'Honduras',
    continent: CENTRAL_AMERICA,
    order: 3,
  },
  {
    prioritary: 'true',
    initials: 'JM',
    idiom: 'en_US',
    currency: 'JMD',
    pt_BR: 'Jamaica',
    en_US: 'Jamaica',
    es_ES: 'Jamaica',
    continent: CARIBBEAN,
    order: 5,
  },
  {
    prioritary: 'true',
    initials: 'MX',
    idiom: 'es_ES',
    currency: 'MXN',
    pt_BR: 'México',
    en_US: 'Mexico',
    es_ES: 'México',
    continent: NORTH_AMERICA,
    order: 1,
  },
  {
    prioritary: 'true',
    initials: 'NI',
    idiom: 'es_ES',
    currency: 'NIO',
    pt_BR: 'Nicarágua',
    en_US: 'Nicaragua',
    es_ES: 'Nicaragua',
    continent: CENTRAL_AMERICA,
    order: 5,
  },
  {
    prioritary: 'true',
    initials: 'PA',
    idiom: 'es_ES',
    currency: 'PAB',
    pt_BR: 'Panamá',
    en_US: 'Panama',
    es_ES: 'Panamá',
    continent: CENTRAL_AMERICA,
    order: 4,
  },
  {
    prioritary: 'true',
    initials: 'PY',
    idiom: 'es_ES',
    currency: 'PYG',
    pt_BR: 'Paraguai',
    en_US: 'Paraguay',
    es_ES: 'Paraguay',
    continent: SOUTH_AMERICA,
    order: 7,
  },
  {
    prioritary: 'true',
    initials: 'PE',
    idiom: 'es_ES',
    currency: 'PEN',
    pt_BR: 'Peru',
    en_US: 'Peru',
    es_ES: 'Perú',
    continent: SOUTH_AMERICA,
    order: 8,
  },
  {
    prioritary: 'true',
    initials: 'DO',
    idiom: 'es_ES',
    currency: 'DOP',
    pt_BR: 'República Dominicana',
    en_US: 'Dominican Republic',
    es_ES: 'República Dominicana',
    continent: CARIBBEAN,
    order: 6,
  },
  {
    prioritary: 'true',
    initials: 'SR',
    idiom: 'en_US',
    currency: 'DTH',
    pt_BR: 'Suriname',
    en_US: 'Suriname',
    es_ES: 'Suriname',
    continent: SOUTH_AMERICA,
    order: 9,
  },
  {
    prioritary: 'true',
    initials: 'TT',
    idiom: 'en_US',
    currency: 'TTD',
    pt_BR: 'Trindade e Tobago',
    en_US: 'Trinidad and Tobago',
    es_ES: 'Trinidad y Tobago',
    continent: CARIBBEAN,
    order: 7,
  },
  {
    prioritary: 'true',
    initials: 'UY',
    idiom: 'es_ES',
    currency: 'UYU',
    pt_BR: 'Uruguai',
    en_US: 'Uruguay',
    es_ES: 'Uruguay',
    continent: SOUTH_AMERICA,
    order: 10,
  },
  {
    prioritary: 'true',
    initials: 'VE',
    idiom: 'es_ES',
    currency: 'VEF',
    pt_BR: 'Venezuela',
    en_US: 'Venezuela',
    es_ES: 'Venezuela',
    continent: SOUTH_AMERICA,
    order: 11,
  },
  {
    prioritary: 'false',
    initials: 'AG',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Antígua e Barbuda',
    en_US: 'Antigua and Barbuda',
    es_ES: 'Antigua y Barbuda',
    continent: CARIBBEAN,
    order: 8,
  },
  {
    prioritary: 'false',
    initials: 'AW',
    idiom: 'es_ES',
    currency: 'AWG',
    pt_BR: 'Aruba',
    en_US: 'Aruba',
    es_ES: 'Aruba',
    continent: CARIBBEAN,
    order: 9,
  },
  {
    prioritary: 'false',
    initials: 'BM',
    idiom: 'en_US',
    currency: 'BMD',
    pt_BR: 'Bermudas',
    en_US: 'Bermuda',
    es_ES: 'Bermudas',
    continent: CARIBBEAN,
    order: 10,
  },
  {
    prioritary: 'false',
    initials: 'BQ',
    idiom: 'en_US',
    currency: 'USD',
    pt_BR: 'Bonaire',
    en_US: 'Bonaire',
    es_ES: 'Bonaire',
    continent: CARIBBEAN,
    order: 11,
  },
  {
    prioritary: 'false',
    initials: 'CW',
    idiom: 'en_US',
    currency: 'ANG',
    pt_BR: 'Curaçao',
    en_US: 'Curacao',
    es_ES: 'Curazao',
    continent: CARIBBEAN,
    order: 12,
  },
  {
    prioritary: 'false',
    initials: 'DM',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Dominica',
    en_US: 'Dominica',
    es_ES: 'Dominica',
    continent: CARIBBEAN,
    order: 13,
  },
  {
    prioritary: 'false',
    initials: 'EC',
    idiom: 'es_ES',
    currency: 'USD',
    pt_BR: 'Equador',
    en_US: 'Ecuador',
    es_ES: 'Ecuador',
    continent: SOUTH_AMERICA,
    order: 12,
  },
  {
    prioritary: 'false',
    initials: 'SV',
    idiom: 'es_ES',
    currency: 'SVC',
    pt_BR: 'El Salvador',
    en_US: 'El Salvador',
    es_ES: 'El Salvador',
    continent: CENTRAL_AMERICA,
    order: 6,
  },
  {
    prioritary: 'false',
    initials: 'GD',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Granada',
    en_US: 'Grenada',
    es_ES: 'Granada',
    continent: CARIBBEAN,
    order: 14,
  },
  {
    prioritary: 'false',
    initials: 'GP',
    idiom: 'en_US',
    currency: 'EUR',
    pt_BR: 'Guadalupe',
    en_US: 'Guadalupe',
    es_ES: 'Guadalupe',
    continent: CARIBBEAN,
    order: 15,
  },
  {
    prioritary: 'false',
    initials: 'GF',
    idiom: 'en_US',
    currency: 'EUR',
    pt_BR: 'Guiana Francesa',
    en_US: 'French Guyana',
    es_ES: 'Guayana Francesa',
    continent: SOUTH_AMERICA,
    order: 13,
  },
  {
    prioritary: 'false',
    initials: 'KY',
    idiom: 'en_US',
    currency: 'KYD',
    pt_BR: 'Ilhas Cayman',
    en_US: 'Cayman Islands',
    es_ES: 'Islas Caimám',
    continent: CARIBBEAN,
    order: 16,
  },
  {
    prioritary: 'false',
    initials: 'TC',
    idiom: 'en_US',
    currency: 'USD',
    pt_BR: 'Ilhas Turks e Caicos',
    en_US: 'Turks and Caicos Islands',
    es_ES: 'Islas Turcas y Caicos',
    continent: CARIBBEAN,
    order: 17,
  },
  {
    prioritary: 'false',
    initials: 'VG',
    idiom: 'en_US',
    currency: 'USD',
    pt_BR: 'Ilhas Virgens Britânicas',
    en_US: 'British Virgin Islands',
    es_ES: 'Islas Vírgenes Británicas',
    continent: CARIBBEAN,
    order: 18,
  },
  {
    prioritary: 'false',
    initials: 'VI',
    idiom: 'en_US',
    currency: 'USD',
    pt_BR: 'Ilhas Virgens Americanas',
    en_US: 'US Virgin Islands',
    es_ES: 'Islas Vírgenes de los Estados Unidos',
    continent: CARIBBEAN,
    order: 19,
  },
  {
    prioritary: 'false',
    initials: 'MQ',
    idiom: 'en_US',
    currency: 'EUR',
    pt_BR: 'Martinica',
    en_US: 'Martinique',
    es_ES: 'Martinica',
    continent: CARIBBEAN,
    order: 20,
  },
  {
    prioritary: 'false',
    initials: 'MS',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Montserrat',
    en_US: 'Montserrat',
    es_ES: 'Montserrat',
    continent: CARIBBEAN,
    order: 21,
  },
  {
    prioritary: 'false',
    initials: 'PR',
    idiom: 'es_ES',
    currency: 'USD',
    pt_BR: 'Porto Rico – Espanhol',
    en_US: 'Puerto Rico – Spanish',
    es_ES: 'Puerto Rico – Español',
    continent: CARIBBEAN,
    order: 22,
  },
  {
    prioritary: 'false',
    initials: 'PI',
    idiom: 'en_US',
    currency: 'USD',
    pt_BR: 'Porto Rico – Inglês',
    en_US: 'Puerto Rico – English',
    es_ES: 'Puerto Rico – Inglés',
    continent: CARIBBEAN,
    order: 23,
  },
  {
    prioritary: 'false',
    initials: 'KN',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'São Cristóvão e Nevis',
    en_US: 'St Kitts and Nevis',
    es_ES: 'San Cristóbal y Nieves',
    continent: CARIBBEAN,
    order: 24,
  },
  {
    prioritary: 'false',
    initials: 'VC',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'São Vicente e Granadinas',
    en_US: 'St Vincent and the Grenadines',
    es_ES: 'San Vicente y las Granadinas',
    continent: CARIBBEAN,
    order: 25,
  },
  {
    prioritary: 'false',
    initials: 'LC',
    idiom: 'en_US',
    currency: 'XCD',
    pt_BR: 'Santa Lúcia',
    en_US: 'Saint Lucia',
    es_ES: 'Santa Lucía',
    continent: CARIBBEAN,
    order: 26,
  },
  {
    prioritary: 'false',
    initials: 'MF',
    idiom: 'en_US',
    currency: 'ANG',
    en_US: 'Saint-Martin (French)',
    pt_BR: 'São Martinho (Francês)',
    es_ES: 'San Martín (Francés)',
    continent: CARIBBEAN,
    order: 27,
  },
  {
    prioritary: 'false',
    initials: 'SX',
    idiom: 'en_US',
    currency: 'ANG',
    en_US: 'Sint Maarten (Dutch)',
    pt_BR: 'São Martinho (Holandês)',
    es_ES: 'Sint Maarten (Holandés)',
    continent: CARIBBEAN,
    order: 28,
  },
];

export const DESTINATION_COUNTRIES_NOT_COVERED_TRAVEL_CERTIFICATE = [
  {
    label: 'Cuba',
    value: 'CU',
  },
  {
    label: 'Venezuela',
    value: 'VE',
  },
];
export const removeCountryFromList = (countryList, countriesToRemove) => {
  const indexesToRemove = [];
  let countriesCloneList = JSON.parse(JSON.stringify(countryList));

  countriesToRemove.forEach(country => {
    const { label, value } = country;
    if (countryList.includes(`<option value="${value}"> ${label} </option>`)) {
      indexesToRemove.push(
        countryList.indexOf(`<option value="${value}"> ${label} </option>`)
      );
    }
  });

  indexesToRemove.forEach((countryIndex, index) => {
    const _index = countryIndex - index;

    countriesCloneList =
      countriesCloneList.slice(0, _index) +
      countriesCloneList.slice(_index + 1);
  });

  return countriesCloneList;
};
