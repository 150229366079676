/* eslint-disable indent */
export const getMaskedBinOrPan = (credit_card_token, external_reference) => {
  const masked =
    credit_card_token && credit_card_token.length === 16
      ? credit_card_token.replace(
          new RegExp('(\\d{4})(\\d{2})(\\w{6})(\\d{2})(\\d{2})', 'g'),
          '$1.$2**.****.**$5'
        )
      : external_reference &&
        external_reference.replace(
          new RegExp('(\\d{4})(\\d{4})(\\d{2})', 'g'),
          '$1.****.$3**.****'
        );

  return masked;
};

export const isBinNotActived = card => {
  return (
    (card && card.status && card.status.toUpperCase() === 'INVALID') ||
    (card && card.status && card.status.toUpperCase() === 'EXPIRED')
  );
};

export const CheckIsVisaNumber = pan => {
  const visaCard = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  return visaCard.test(pan);
};
