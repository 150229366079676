import { useContext, useState, useEffect } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getName,
  getLastName,
  getPhone,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const ContactPersonArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const [contact, setContact] = useState({});

  useEffect(() => {
    const contact =
      (claimDetails && claimDetails.contacts && claimDetails.contacts[0]) || {};
    setContact(contact);
  }, [claimDetails]);

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_CONTACT_PERSON} simpleLine />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue label={intl.LABEL_NAME} value={getName(contact)} />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_LAST_NAME}
          value={getLastName(contact)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_NUMBER}
          value={getPhone(contact, intl)}
        />
      </div>
    </>
  );
};

export default ContactPersonArea;
