import * as Yup from 'yup';

export const legalAdviceFormInitialValues = terms => {
  return {
    accept_terms_1: (terms && terms.accept_terms_1) || false,
    accept_terms_2: (terms && terms.accept_terms_2) || false,
    accept_terms_3: (terms && terms.accept_terms_3) || false,
  };
};

export const validationSchema = intl =>
  Yup.object().shape({
    accept_terms_1: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
    accept_terms_2: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
    accept_terms_3: Yup.bool().oneOf([true], intl.TEXT_FIELD_REQUIRED),
  });
