import React, { useMemo, useContext } from 'react';
import { Formik } from 'formik';
import { productInformationValidationSchema } from './validationSchema';
import { productInitialValues } from './initialValues';
import DatesArea from './DatesArea/DatesArea';
import ProductArea from './ProductArea/ProductArea';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import WarrantyArea from './WarrantyArea/WarrantyArea';
import { useTheme } from '../../../../../themes/ThemeContextParent/ThemeContextParent';

const ProductInformation = props => {
  const { updateParent, submitRef, isWarranty } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getCurrentThemeColors } = useTheme();
  const colors = getCurrentThemeColors();

  const validationSchema = useMemo(
    () => productInformationValidationSchema(isWarranty, intl),
    [isWarranty]
  );

  const initialValues = useMemo(
    () => productInitialValues(isWarranty),
    [isWarranty]
  );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {props => {
        const { values, handleSubmit } = props;
        updateParent(values, validationSchema);
        return (
          <form onSubmit={handleSubmit}>
            <button
              data-testid="submitButton"
              ref={submitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            <DatesArea {...props} intl={intl}></DatesArea>
            <ProductArea {...props} intl={intl}></ProductArea>
            {isWarranty && <WarrantyArea {...props} intl={intl}></WarrantyArea>}
            <p
              style={{
                color: colors['text-title'],
                fontSize: '14px',
              }}
            >
              {intl.MAXIMUM_PERIOD}
            </p>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProductInformation;
