import { Flight } from '../../../../../@types/APIs/AEROAPI/Flight';
import { Segment } from '../../../../../@types/APIs/Travels';
import { FlightInfoData } from '../../../../../components/FlightInfo/FlightInfoContext';

export const formatFlightInfo = (segment: Segment): FlightInfoData => ({
  flightNumber: segment.flight_number,
  startAt: segment.actual_start_at || segment.start_at,
  airportOrigin: segment.airport_origin,
  cityOrigin: segment.city_origin,
  countryOrigin: segment.country_origin,
  endAt: segment.actual_end_at || segment.end_at,
  airportDestination: segment.airport_destination,
  cityDestination: segment.city_destination,
  countryDestination: segment.country_destination,
});
