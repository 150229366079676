import { useState, useContext, useEffect } from 'react';
import styles from './B2b2c.module.scss';
import HeadingTitleAXA from '../../components/HeadingTitleAXA/HeadingTitleAXA';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import {
  productsAndBenefitsServices,
  userServices,
} from '../../services/index';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { B2B2CContext } from '../../components/B2B2CStateParent/B2B2CStateParent';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';
import SessionContainer from '../../components/SessionContainer/SessionContainer';
import StepClaim from '../Claims/StepClaim/StepClaim';
import FormQuote from './Quote/FormQuote/FormQuote';
import ListPlans from './Quote/ListPlans/ListPlans';
import { CREATE_CONFIG_B2B2C, getType } from './CONFIG_B2B2C';
import { checkAccess } from './utils';
import TermsAndConditions from './PurchasePlan/TermsAndConditions/TermsAndConditions';
import Form from './PurchasePlan/Form/Form';
import PaymentInformation from './PurchasePlan/PaymentInformation/PaymentInformation';
import { AppInsightTrackContext } from '../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

const B2B2C = ({ match, history }) => {
  const { params } = match;
  const { benefit_id } = params;
  const [step, setStep] = useState(1);
  const [benefitDetail, setBenefitDetail] = useState(null);
  const [personalData, setPersonalData] = useState(null);

  const { translate, idiomForApi } = useContext(IntlContext);
  const { utils: utilsB2B2C, actions: actionsB2B2C } = useContext(B2B2CContext);
  const { getGlobalTheme } = useTheme(true);
  const { utils } = useContext(StateContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const CONFIG_B2B2C = CREATE_CONFIG_B2B2C(match);
  const card = utils.getSelectedCard();
  const themes = getGlobalTheme();
  const formType = 'es_mx';
  const intl = translate(translations);
  const getbackUrl = `/upsell-detail/${benefit_id}`;
  const cn = utils.getCn();
  const type = getType(match.path);

  const step1 = step === 1;
  const step2 = step === 2;
  const step3 = step === 3;
  const step4 = step === 4;
  const step5 = step === 5;

  const stepNames = [
    intl.TEXT_STEP_1,
    intl.TEXT_STEP_2,
    intl.TEXT_STEP_3,
    intl.TEXT_STEP_4,
    intl.TEXT_STEP_5,
  ];

  const loadBenefitDetail = async () => {
    try {
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          utils.getBin(true),
          benefit_id,
          idiomForApi(),
          false
        );
      setBenefitDetail(response.data);
    } catch (error) {
      trackEventUserAction(
        `#### (B2B2C) ERRO AO CARREGAR O DETALHE DO BENEFÍCIO`,
        {
          error,
          error_response: error.response,
        }
      );
      setBenefitDetail(null);
    }
  };

  const loadPersonalData = async () => {
    try {
      const user = await userServices.getCHInfo(cn);
      const dependents = await userServices.getCHDependents(cn);
      const dependentsDetail = [];

      for (let index = 0; index < dependents.data.length; index++) {
        let detail = await userServices.getCHDependentsDetail(
          cn,
          dependents.data[index].dependent_id
        );
        dependentsDetail.push(detail.data);
      }

      const data = {
        user: user.data,
        dependents: dependentsDetail,
      };
      setPersonalData(data);
    } catch (error) {
      trackEventUserAction(
        `#### (B2B2C) ERRO AO CARREGAR AS INFORMAÇÔES PESSOAIS`,
        {
          error,
          error_response: error.response,
        }
      );
      setPersonalData({});
    }
  };

  useEffect(() => {
    loadBenefitDetail();
  }, []);

  useEffect(() => {
    loadPersonalData();
  }, []);

  useEffect(() => {
    checkAccess(card, CONFIG_B2B2C, benefit_id, history, utils.getEmail());

    return () => {
      actionsB2B2C.formDataCotizar.setDataForm();
      utilsB2B2C.clearLocalStore();
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const nextStep = () => {
    setStep(step => step + 1);
  };

  const prevStep = () => {
    setStep(step => step - 1);
  };
  return (
    <>
      <HeadingTitleAXA
        title={benefitDetail ? benefitDetail.name : ''}
        subtitle={intl.QUOTE_UPSELL_TITLE}
        description={intl.MODAL_QUOTE_IEMS}
      />
      <div className={`${styles.container} ${styles[themes]}`}>
        <SessionContainer>
          <StepClaim axa currentStep={step} stepNames={stepNames} />
          {step1 && (
            <div className={styles.content}>
              <div className={`${styles.box} ${styles[themes]}`}>
                <FormQuote
                  formType={formType}
                  backUrl={getbackUrl}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  setStep={setStep}
                />
              </div>
            </div>
          )}
          {step2 && (
            <div className={styles.content}>
              <div className={`${styles.box} ${styles[themes]}`}>
                <label
                  id="mainContent"
                  className={`${styles.title} ${styles[themes]}`}
                >
                  {intl.TITLE_INCREASE_COVERAGE}
                </label>
                <ListPlans
                  formType={formType}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  nextStep={nextStep}
                  prevStep={prevStep}
                />
              </div>
            </div>
          )}

          {benefitDetail && (
            <>
              {step3 && (
                <TermsAndConditions
                  nextStep={nextStep}
                  backStep={prevStep}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                  type={type}
                  axa
                />
              )}
              {step4 && (
                <Form
                  nextStep={nextStep}
                  backStep={prevStep}
                  setStep={setStep}
                  personalData={personalData}
                  formType={formType}
                  type={type}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                />
              )}
              {step5 && (
                <PaymentInformation
                  backStep={prevStep}
                  formType={formType}
                  benefitDetail={benefitDetail}
                  CONFIG_B2B2C={CONFIG_B2B2C}
                />
              )}
            </>
          )}
        </SessionContainer>
      </div>
    </>
  );
};

export default B2B2C;
