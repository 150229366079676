import React, { useContext } from 'react';
import styles from './legend.module.scss';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';

const LegendAppointment = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div className={styles.legend}>
      <div>
        <span
          className={`${styles.legendIcon} ${styles.legendAvailable} ${styles[themes]}`}
        />
        {intl.AVAILABLE}
      </div>
      <div>
        <span
          className={`${styles.legendIcon} ${styles.legendUnavailable} ${styles[themes]}`}
        />
        {intl.UNAVAILABLE}
      </div>
      <div>
        <span
          className={`${styles.legendIcon} ${styles.legendSelected} ${styles[themes]}`}
        />
        {intl.SELECTED}
      </div>
    </div>
  );
};

export default LegendAppointment;
