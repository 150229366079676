import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const BenefitsIcon = props => {
  const { enabled = true, bottombar } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);

  return (
    <svg
      width={64}
      height={67}
      viewBox="0 0 64 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7482 52.8961H14.4653V43.6132H10.2362V52.8961H0.932129V57.1253H10.2362V66.4082H14.4653V57.1253H23.7482V52.8961Z"
        fill={
          enabled
            ? bottombar
              ? config_icon.secondary
              : config_icon.primary
            : config_icon.inactive
        }
      />
      <path
        d="M63.65 38.644H51.5124V26.5064H47.2833V38.644H35.1458V42.8731H47.2833V55.0107H51.5124V42.8731H63.65V38.644Z"
        fill={
          enabled
            ? bottombar
              ? config_icon.secondary
              : config_icon.primary
            : config_icon.inactive
        }
      />
      <path
        d="M40.834 15.8491H25.8629V0.856842H21.6338V15.8491H6.6416V20.0782H21.6338V35.0493H25.8629V20.0782H40.834V15.8491Z"
        fill={enabled ? config_icon.secondary : config_icon.inactive}
      />
    </svg>
  );
};

export default BenefitsIcon;
