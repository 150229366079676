import { useContext, useState, useRef, useEffect } from 'react';
import styles from './ListCards.module.scss';
import { StateContext } from '../StateContextParent/StateContextParent';
import CardItem from './CardItem/CardItem';

import Cards from '../../assets/images/cards_2.svg';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import AddCardItem from './AddCardItem/AddCardItem';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';

const sortCards = cards => {
  const selectedIndex = cards.selectedCardIndex;
  const endList = cards.data.filter(card => {
    return card.policy_id !== cards.data[selectedIndex].policy_id;
  });
  const listFinal = [cards.data[selectedIndex], ...endList];
  return listFinal;
};

const renderCards = (listCards, setOpenSubMenu, noReload) => {
  return listCards.map((card, index) => {
    return (
      <CardItem
        key={`${card.policy_id}-${index}`}
        card={card}
        setOpenSubMenu={setOpenSubMenu}
        index={index}
        noReload={noReload}
      />
    );
  });
};
const ListCards = props => {
  const { noReload } = props;
  const { state, utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const sortListCards = sortCards(state.cards, setOpenSubMenu);
  const list = renderCards(sortListCards, setOpenSubMenu, noReload);
  const nameCardSelected = utils.getSelectedCard();
  const image =
    nameCardSelected.image && nameCardSelected.image.length > 0
      ? nameCardSelected.image
      : Cards;

  const openMenu = e => {
    e.stopPropagation();
    setOpenSubMenu(true);
  };

  const closeMenu = event => {
    const containMenu =
      menuRef.current && menuRef.current.contains(event.target);
    const containButton =
      buttonRef.current && buttonRef.current.contains(event.target);
    if (!containMenu) {
      if (containButton) {
        event.stopPropagation();
      }
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    if (openSubMenu) document.body.addEventListener('click', closeMenu);
    else document.body.removeEventListener('click', closeMenu);

    return () => {
      document.body.removeEventListener('click', closeMenu);
    };
  }, [openSubMenu]);

  useEffect(() => {
    const wrapperMenuListCard = document.getElementById('menuListCard');
    const btnListCardMenu = document.getElementById('btnListCardMenu');
    wrapperMenuListCard.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setOpenSubMenu(false);
        btnListCardMenu.focus();
      }
    });
  }, []);

  return (
    <div className={styles.boxListCards}>
      <div
        className={styles.openListCards}
        onBlur={event => {
          if (!event.currentTarget.contains(event.relatedTarget)) {
            setOpenSubMenu(false);
          }
        }}
        id="menuListCard"
      >
        <button
          aria-expanded={openSubMenu}
          id="btnListCardMenu"
          ref={buttonRef}
          onClick={openSubMenu ? closeMenu : openMenu}
          type="button"
          className={`${styles[theme]} ${styles.buttonCards}`}
          aria-label={intl.TEXT_MY_CARDS}
          tabIndex={0}
        >
          <img src={image} alt="" />
          {window.screen.width > 992 && (
            <span lang="pt-BR">{nameCardSelected.name}</span>
          )}
          <span className={styles.icon}>
            <ChevronGeneric
              rotate={!openSubMenu ? 0 : 180}
              width={20}
              height={20}
              data-testid="iconChevron"
              alt=""
            />
          </span>
        </button>
        {openSubMenu && (
          <div
            ref={menuRef}
            aria-label={intl.LIST_CARDS}
            className={`${styles[theme]} ${styles.listCards}`}
            role="dialog"
          >
            <ul>
              <AddCardItem />
              {list}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListCards;
