import styles from './Bluebox.module.scss';
import { joinClassNames } from '../../utils/classNames';
import Link, { Surface } from '../../componentsV2/Link/Link';

interface BlueboxAnchorProps {
  label: string;
  button?: boolean;
  onClick: VoidFunction;
  surface: Surface;
  id?: string;
  icon?: boolean;
  href?: string;
  internal?: boolean;
  className?: string;
}

const BlueboxAnchor = ({
  label,
  button,
  onClick,
  surface = 'Inverse',
  id,
  icon = false,
  href,
  internal,
  className,
}: BlueboxAnchorProps) => {
  return (
    <Link
      className={joinClassNames(styles.linkButton, className)}
      button={button}
      onClick={onClick}
      id={id}
      surface={surface}
      label={label}
      icon={icon}
      href={href}
      internal={internal}
    />
  );
};

export default BlueboxAnchor;
