import { useContext, useEffect, useState } from 'react';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import {
  getDateOfBankrupcy,
  getIsCardholderResponsible,
  getIsCompanyResponsible,
  getIsResultOfBankrupcy,
} from '../../../../../../utils/claimsAPI/CorporateLiabilityUtils';
import * as translations from './intl';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const FinancialArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [incident, setIncident] = useState({});

  useEffect(() => {
    const incident = (claimDetails && claimDetails.incident) || {};
    setIncident(incident);
  }, [claimDetails]);

  const isResultOfBankrupcy = incident && incident.bankruptcy_declaration_date;

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_INCIDENT} simpleLine />
      </div>
      <div className={`col-sm-12 col-md-${isResultOfBankrupcy ? '6' : '12'}`}>
        <InfoLabelValue
          label={intl.LABEL_IS_RESULT_BANKRUPCY_OR_INSOLVENCY}
          value={getIsResultOfBankrupcy(incident, intl)}
        />
      </div>
      {isResultOfBankrupcy && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_DATE_DECLARED}
            value={getDateOfBankrupcy(incident, idiom)}
          />
        </div>
      )}
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_IS_COMPANY_RESPONSIBLE}
          value={getIsCompanyResponsible(incident, intl)}
        />
      </div>
      <div className="col-sm-12 col-md-6">
        <InfoLabelValue
          label={intl.LABEL_IS_CARDHOLDER_RESPONSIBLE}
          value={getIsCardholderResponsible(incident, intl)}
        />
      </div>
    </>
  );
};

export default FinancialArea;
