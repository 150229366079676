import React from 'react';
import { useIntl } from '../../intl';
import styles from './Bluebox.module.scss';
import * as translations from './intl';

interface BlueboxImageLinkProps {
  imageUrl: string;
  altImage?: string;
  href: string;
  onClick?: VoidFunction;
}

const BlueboxImageLink = ({
  imageUrl,
  altImage,
  href,
  onClick,
}: BlueboxImageLinkProps) => {
  const { translate } = useIntl();
  const intl = translate(translations);

  return (
    <a
      className={styles.imageLink}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onClick}
      aria-label={`${altImage} - ${intl.NEW_TAB}`}
    >
      <img className={styles.link} src={imageUrl} alt={altImage} />
    </a>
  );
};

export default BlueboxImageLink;
