export { TRIP_ASSISTANCE } from '../../../../intl/travels';

export const ACCEPTING_TERMS_AND_CONDITIONS = {
  en_US: 'Accepting terms and conditions',
  es_ES: 'Aceptación  de términos y condiciones',
  pt_BR: 'Aceitação dos termos e condições',
};

export const TERMS_AND_CONDITIONS = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const ACCEPT_TERMS = {
  en_US: 'I have read and accept the terms and conditions.',
  es_ES: 'He leido y acepto los terminos y condiciones.',
  pt_BR: 'Eu li e aceito os termos e condições.',
};

export const BTN_NEXT_STEP = {
  en_US: 'Next step',
  es_ES: 'Próximo paso',
  pt_BR: 'Próximo passo',
};

export const TEXT_TERM_AND_CONDITIONS_CONTENT = {
  en_US: 'Carefully read the terms of use before activating your Travel Kit.',
  es_ES:
    'Lee atentamente los términos de uso antes de activar tu Visa Travel Kit.',
  pt_BR: 'Leia atentamente os termos de uso antes de ativar o seu Travel Kit.',
};
