import React, { useContext, useEffect } from 'react';
import styles from './ModalError.module.scss';
import { IntlContext } from '../../../intl';
import { StateContext } from '../../StateContextParent/StateContextParent';
import * as translations from './intl';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import iconHeartMaster from '../../../assets/images/icon/master/icon-heart-master.svg';
import iconHeartAfluent from '../../../assets/images/icon/afluent/icon-heart-afluent.svg';
import Button from '../../Button/Button';
import { sanitize } from 'dompurify';

const ModalError = props => {
  const { error } = props;
  const { translate } = useContext(IntlContext);
  const { actions } = useContext(StateContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const icon = isAfluent() ? iconHeartAfluent : iconHeartMaster;
  const text_error = intl[error]();
  const reload = () => {
    window.location.reload();
  };

  const content = (
    <div className={styles.boxError}>
      <div className={styles.background}></div>
      <div className={styles.boxError}>
        <div className={styles.error}>
          <h2
            className={styles[theme]}
            dangerouslySetInnerHTML={{
              __html: sanitize(text_error),
            }}
          ></h2>
          <div className={styles.footer}>
            <Button type="blueWithoutBorder" onClick={reload}>
              {intl.TRY_AGAIN}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    actions.modal.showModal(
      null,
      true,
      content,
      false,
      false,
      null,
      null,
      icon
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ModalError;
