import React, { useState, useContext, useEffect } from 'react';
import styles from './SpecialBenefitsDetail.module.scss';
import '../BenefitsDetail/BenefitContent/PrismicContent.scss';
import { withRouter } from 'react-router-dom';
import { productsAndBenefitsServices } from '../../services/index';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { IntlContext } from '../../intl';
import Banner from './banner/Banner';
import BlueBox from './blueBox/BlueBox';
import Loading from '../../components/Loading/Loading';
import * as translations from './intl';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../utils/sanitize';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import NavigateBenefits from '../BenefitsDetail/navigateBenefits/NavigateBenefits';
import topCards from '../../assets/images/cards/topCards.png';
import goldCard from '../../assets/images/cards/goldCard.png';
import Logo from '../../components/Logo/Logo';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import TooltipIcon from '../../assets/icons/TooltipIcon';
import LockIcon from '../../assets/icons/LockIcon';

const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';

const createLink = (isLogged, benefit) => {
  return isLogged
    ? `/your-subscription-benefits/${benefit.external_reference}`
    : `/subscription-benefits/${benefit.external_reference}`;
};

const getPrevBenefit = ({ benefits, index, checkMatchBenefits }, isLogged) => {
  if (!benefits.length || !checkMatchBenefits) return '/';

  let benefit = {};

  if (index === 0) {
    benefit = benefits[benefits.length - 1];
  } else {
    benefit = benefits[index - 1];
  }
  return createLink(isLogged, benefit);
};

const getNextBenefit = ({ benefits, index, checkMatchBenefits }, isLogged) => {
  if (!benefits.length || !checkMatchBenefits) return '/';

  let benefit = {};
  if (index === benefits.length - 1) {
    benefit = benefits[0];
  } else {
    benefit = benefits[index + 1];
  }

  return createLink(isLogged, benefit);
};

const getSpecialBenefits = (benefits, benefit_id) => {
  const listBenefits = benefits.filter(
    benefit => benefit.categories === 'special_benefits'
  );
  const matchBenefit = listBenefits.find(benefit =>
    String(benefit.benefit_id)
      .toLowerCase()
      .includes(String(benefit_id).toLowerCase())
  );
  const indexBenefit = listBenefits.findIndex(benefit =>
    String(benefit.benefit_id)
      .toLowerCase()
      .includes(String(benefit_id).toLowerCase())
  );

  return {
    index: indexBenefit,
    benefits: listBenefits,
    checkMatchBenefits: matchBenefit ? true : false,
  };
};

const SpecialBenefitsDetail = props => {
  const { match, history } = props;
  const { benefit_id } = match.params;
  const [benefitDetail, setBenefitDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [specialBenefits, setSpecialListBenefits] = useState({
    benefits: [],
    index: 0,
    checkMatchBenefits: true,
  });

  const { utils, actions } = useContext(StateContext);
  const isLogged = utils.isUserLogged();
  const binOrPan = utils.getBin(true);
  const { productActive } = useContext(PublicContext);

  const { translate, idiomForApi } = useContext(IntlContext);
  const { getGlobalTheme, isAfluent } = useContext(ThemeContext);

  const intl = translate(translations);
  const theme = getGlobalTheme();
  const cardImage = isAfluent() ? topCards : goldCard;

  let benefitIcon =
    benefitDetail &&
    benefitDetail.images &&
    benefitDetail.images.find(image => image.type === 'ICON');
  benefitIcon = benefitIcon ? benefitIcon.content_url.split(';') : [null, null];

  const disclaimer =
    benefitDetail &&
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.redeem &&
    benefitDetail.redeem_info.redeem.disclaimer;

  const loadDetail = async () => {
    setLoading(true);
    try {
      const productBinOrPan = isLogged ? binOrPan : productActive.product_id;
      if (!productBinOrPan) {
        throw new Error('Product undefined');
      }

      const responsebenefits =
        await productsAndBenefitsServices.getBenefitsByProduct(
          productBinOrPan,
          idiomForApi(),
          false,
          false
        );
      const listSpecialBenfits = getSpecialBenefits(
        responsebenefits.data,
        benefit_id
      );

      setSpecialListBenefits({
        ...specialBenefits,
        benefits: listSpecialBenfits.benefits,
        index: listSpecialBenfits.index,
        checkMatchBenefits: listSpecialBenfits.checkMatchBenefits,
      });
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          productBinOrPan,
          listSpecialBenfits.benefits[listSpecialBenfits.index].benefit_id,
          idiomForApi()
        );
      setBenefitDetail(response.data);
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        setBenefitDetail(ERROR_NOT_FOUND);
      } else {
        setBenefitDetail({});
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    loadDetail();
  }, [benefit_id, productActive]);

  useEffect(() => {
    if (
      benefitDetail === ERROR_NOT_FOUND ||
      !specialBenefits.checkMatchBenefits
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.MESSAGE_MODAL_BENEFIT_NOT_ELEGIBILITY(benefitDetail)}
          image={ModalCreditCard}
          textBtn={intl.BTN_VIEW_BENEFITS}
          clickBtn={() => {
            actions.modal.closeModal();
            if (isLogged) {
              history.push('/your-subscription-benefits');
            } else {
              history.push('/subscription-benefits');
            }
          }}
          textBtnDeny={intl.BTN_VIEW_CARDS}
          clickBtnDeny={() => {
            actions.modal.closeModal();
            if (isLogged) {
              history.push('/profile/account#cards');
            } else {
              history.push('/benefits');
            }
          }}
          subtitle={intl.SUBTITLE_MODAL_NOT_ELEGIBILITY}
        />,
        true
      );
    }
  }, [benefitDetail]);

  const checkWindowWidth = () => {
    if (window.screen.width > 1280) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    checkWindowWidth();
    window.addEventListener('resize', checkWindowWidth);
    return () => {
      window.removeEventListener('resize', checkWindowWidth);
    };
  }, []);

  const Bluebox = <BlueBox benefitDetail={benefitDetail} />;
  const prismicContent =
    benefitDetail &&
    benefitDetail.description &&
    benefitDetail.description.split(`<div class="bluebox"></div>`);

  return (
    <div className={styles.containerBenefitsDetail}>
      <Banner benefitDetail={benefitDetail} loading={loading} />

      <div className={styles.boxContainer}>
        {benefitDetail && !isMobile && Bluebox}
        <div className={`${styles.infoContainer} ${styles[theme]}`}>
          {loading ? (
            <div className={styles.loadingContainer} role="loadingInfo">
              <Loading />
            </div>
          ) : (
            <>
              <div
                id="mainContent"
                className={`${styles.insidePrismic} ${styles[theme]}`}
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    Array.isArray(prismicContent) && prismicContent[0]
                  ),
                }}
              ></div>
              {Array.isArray(prismicContent) && prismicContent.length > 1 && (
                <>
                  {isMobile && Bluebox}
                  <div
                    className={`${styles.insidePrismic} ${styles[theme]}`}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(prismicContent && prismicContent[1]),
                    }}
                  ></div>
                </>
              )}
            </>
          )}

          <div className={styles.notAdditional}>
            <LockIcon color="Duotone" />
            <p>{intl.NOT_ADDITIONAL_CHARGES_ONE_LINE}</p>
          </div>
          <div className={styles.toRedeem}>
            {disclaimer && (
              <>
                <TooltipIcon />
                <p>{disclaimer}</p>
              </>
            )}
          </div>

          <div className={styles.cardImageArea}>
            <img alt="" src={cardImage} />
            {isAfluent() ? (
              <p>{intl.UNIQUE_CARDS_LIKE_YOU}</p>
            ) : (
              <p>{intl.UNIQUE_BENEFITS_LIKE_YOU}</p>
            )}
          </div>

          <div className={styles.logoArea}>
            <Logo color={'#1434CB'} size="MEDIUM" />
            <img
              alt=""
              src={benefitIcon[0]}
              className={`${styles.benefitLogo} ${styles[theme]}`}
            />
          </div>
        </div>
      </div>

      {specialBenefits.benefits.length > 1 && (
        <div className={styles.navigation}>
          <NavigateBenefits
            previousLink={() => getPrevBenefit(specialBenefits, isLogged)}
            nextLink={() => getNextBenefit(specialBenefits, isLogged)}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(SpecialBenefitsDetail);
