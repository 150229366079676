/* eslint-disable no-unused-vars */

import moment from 'moment';
import { safeGet } from '../../../../../../../utils/object';
import { getPrice } from '../PurchaseProtection/initialValues';

const getEmptyItems = () => [
  {
    label: '',
    price: {
      value: '',
      currency: '',
    },
  },
];

const getItems = items =>
  items.map(item => ({
    label: item.label || '',
    price: getPrice(item.price),
  }));

const getIncident = incident => ({
  dateValue:
    incident && incident.date
      ? new Date(moment(incident.date, 'DD/MM/YYYY'))
      : '',
  description: incident && incident.description ? incident.description : '',
});

const getOtherInsuranceCovering = otherInsuranceCovering => ({
  is_internal:
    otherInsuranceCovering && otherInsuranceCovering.is_internal !== undefined
      ? !otherInsuranceCovering.is_internal
      : false,
  amount: getPrice(otherInsuranceCovering && otherInsuranceCovering.amount),
});

export default (data, idiom, loadedData) => {
  let person = data && data.person ? data.person : '';
  const items = safeGet(loadedData, 'purchase_protection_items');
  const affectedPerson = safeGet(loadedData, 'affected_person');
  const otherInsuranceCovering = safeGet(
    loadedData,
    'purchase_protection_items[0].other_insurance_covering[0]'
  );
  const incident = safeGet(loadedData, 'incident');
  const totalAmountClaimed = safeGet(loadedData, 'total_amount_claimed');

  return {
    purchase_protection_items: items ? getItems(items) : getEmptyItems(),
    other_insurance_covering: getOtherInsuranceCovering(otherInsuranceCovering),
    amount_claimed: {
      value: '',
      currency: safeGet(loadedData, 'total_amount_claimed.currency') || '',
    },
    incident: getIncident(incident),
    affected_person: getAffectedPerson(person, affectedPerson),
    total_amount_claimed: getPrice(totalAmountClaimed),
  };
};

const getAffectedPerson = (cardholder, affectedPerson) => ({
  relationship:
    affectedPerson && affectedPerson.relationship
      ? affectedPerson.relationship
      : 'Cardholder',
  comment:
    affectedPerson && affectedPerson.comment ? affectedPerson.comment : '',
  person: getPersonName(cardholder, affectedPerson),
});

const getPersonName = (cardholder, affectedPerson) => {
  const person =
    affectedPerson && affectedPerson.person
      ? affectedPerson.person
      : cardholder;
  return {
    first_name: person && person.first_name ? person.first_name : '',
    last_name: person && person.last_name ? person.last_name : '',
  };
};
