export const WELCOME_PERSONAL_DOCUMENTS = {
  en_US: 'Welcome to your world of Visa benefits!',
  es_ES: 'Bienvenido a tu mundo de beneficios Visa!',
  pt_BR: 'Bem-vindo ao seu mundo de benefícios Visa!',
};

export const CLAIM_WELCOME_PERSONAL_DOCUMENTS = {
  en_US: 'Attach documents',
  es_ES: 'Adjuntar Documentos',
  pt_BR: 'Anexar documentos',
};

export const WARNING_PERSONAL_DOCUMENTS = {
  en_US:
    'Now, you can attach your documents to your profile, simplifying the start of claims. How about updating your profile?',
  es_ES:
    'Ahora puedes adjuntar tus documentos a tu perfil, simplificando el inicio de reclamos. ¿Qué tal si actualizas tu perfil?',
  pt_BR:
    'Agora você pode anexar seus documentos ao seu perfil, simplificando o início de sinistros. Que tal atualizar seu perfil?',
};

export const CLAIM_WARNING_PERSONAL_DOCUMENTS = {
  en_US:
    'We noticed that your personal documents are out of date. You must update your profile before starting to attach documents.',
  es_ES:
    'Notamos que tus documentos personales están desactualizados. Debe actualizar tu perfil antes de poder comenzar a adjuntar documentos.',
  pt_BR:
    'Notamos que seus documentos pessoais estão desatualizados. Você deve atualizar seu perfil antes de começar a anexar documentos.',
};

export const TEXT_BUTTON_PERSONAL_DOCUMENTS = {
  en_US: 'Update personal documents',
  es_ES: 'Actualizar documentos personales',
  pt_BR: 'Atualizar documentos pessoais',
};

export const TEXT_BUTTON_UPDATE_LATER = {
  en_US: 'Update later',
  es_ES: 'Actualizar más tarde',
  pt_BR: 'Atualizar mais tarde',
};

export const TITLE_EXPIRED_DOCUMENTS = {
  en_US: 'One of your documents expired',
  es_ES: 'Uno de tus documentos venció',
  pt_BR: 'Um dos seus documentos expirou',
};

export const WARNING_EXPIRED_DOCUMENTS = {
  en_US: 'You have some documents to review. Please check your profile.',
  es_ES:
    'Tienes algunos documentos que revisar. Por favor comprueba tu perfil.',
  pt_BR:
    'Você tem documentos pendentes de revisão. Por favor verifique o seu perfil.',
};
