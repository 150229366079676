export const TEXT_START_DATE_TRIP = {
  en_US: 'Start date of trip *',
  pt_BR: 'Data de início da viagem *',
  es_ES: 'Fecha de inicio del viaje *',
};

export const TEXT_END_DATE_TRIP = {
  en_US: 'End date of trip *',
  pt_BR: 'Data de fim da viagem *',
  es_ES: 'Fecha de finalización del viaje *',
};

export const TEXT_DATE_ARRIVAL_DESTINATION = {
  en_US: 'Date of arrival at destination *',
  es_ES: 'Fecha de llegada *',
  pt_BR: 'Data de chegada *',
};

export const TEXT_HOUR_ARRIVAL_DESTINATION = {
  en_US: 'Hour of arrival at destination *',
  es_ES: 'Hora de llegada *',
  pt_BR: 'Hora de chegada *',
};

export const TEXT_DATE_RECEIPT_BAGGAGE = {
  en_US: 'Date of receipt of delayed luggage *',
  es_ES: 'Fecha de recepción de la demora de equipaje *',
  pt_BR: 'Data da recepção da bagagem atrasada *',
};

export const TEXT_HOUR_RECEIPT_BAGGAGE = {
  en_US: 'Hour of receipt of delayed luggage *',
  es_ES: 'Hora de recepción de la demora de equipaje *',
  pt_BR: 'Hora da recepção da bagagem atrasada *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal.',
};

export const WAS_BAGGAGE_RECEIVED = {
  en_US: 'Has the luggage been delivered?',
  es_ES: '¿Se ha entregado el equipaje?',
  pt_BR: 'A bagagem foi entregue?',
};

export const TEXT_OPTION_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_OPTION_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const DESCRIBE_YES_BAGGAGE_RECEIVED = {
  en_US:
    'Your luggage has been recovered and you must inform us when this happened',
  pt_BR:
    'Sua bagagem foi recuperada e você deverá informar o momento que isso aconteceu',
  es_ES:
    'Su equipaje ha sido recuperado y debe informarnos cuando esto haya sucedido',
};

export const DESCRIBE_NO_BAGGAGE_RECEIVED = {
  en_US: 'Your luggage has not been recovered',
  pt_BR: 'Sua bagagem não foi recuperada',
  es_ES: 'Su equipaje no ha sido recuperado',
};
