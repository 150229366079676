export const CLAIM_DOCUMENTATION = {
  en_US: 'Claim documentation',
  pt_BR: 'Documentação do sinistro',
  es_ES: 'Documentación del reclamo',
};

export const DATE_UPLOAD_REQUESTED = {
  en_US: date => `90 days ${date ? ` (${date})` : ''}.`,
  pt_BR: date => `90 dias ${date ? ` (${date})` : ''}.`,
  es_ES: date => `90 días ${date ? ` (${date})` : ''}.`,
};

export const TEXT_UPLOAD_REQUESTED = {
  en_US:
    'Attention! Only after sending all the required documents will we start the analysis process. You must send the required documentation within ',

  pt_BR:
    'Atenção! Somente após o envio de todos os documentos requeridos é que o processo de análise será iniciado. Você deve enviar a documentação requerida até ',
  es_ES:
    '¡Atención! Solo después de enviar todos los documentos requeridos comenzaremos el proceso de análisis. Debe enviar la documentación requerida dentro de los ',
};

export const TEXT_UPLOAD_DISCLAIMER = {
  en_US:
    'New documents may be requested if the submitted documents do not meet the minimum requirements to prove your eligibility.',

  pt_BR:
    'Novos documentos podem ser solicitados caso os documentos enviados não atendam aos requisitos mínimos para comprovar sua elegibilidade.',
  es_ES:
    'Se pueden solicitar nuevos documentos si los documentos presentados no cumplen con los requisitos mínimos para demostrar su elegibilidad.',
};

const BOLD_DAYS_REMEMBER_DOCUMENTS_OTHERS = {
  en_US: '11 business days',
  pt_BR: '11 dias úteis',
  es_ES: '11 días hábiles',
};

export const TEXT_REMEMBER_DOCUMENTS_OTHERS = {
  en_US: [
    'Remember:',
    `- Once you have added all the required documents, we will process your claim on the following ${BOLD_DAYS_REMEMBER_DOCUMENTS_OTHERS.en_US.bold()}.`,
    '- You must add one file at a time, prioritizing the optional documents, if any, and then the required documents.',
    '- Once there are multiple documents associated with the same type, compress them into a ZIP file or generate a PDF file containing all these documents. You must add this generated ZIP or PDF file.',
    '- All documents added must not contain your full credit card number. Please, if you do, you must hide the last six digits of your card.',
    '- You must review all added documents.',
  ],
  pt_BR: [
    'Lembre-se:',
    `- Uma vez que você adicione todos os documentos requeridos, processaremos seu sinistro nos próximos ${BOLD_DAYS_REMEMBER_DOCUMENTS_OTHERS.pt_BR.bold()}.`,
    '- Você deve adicionar um arquivo por vez priorizando os documentos opcionais, caso existam, e depois os documentos requeridos.',
    '- Uma vez que hajam vários documentos associados ao mesmo tipo,  comprima-os em um arquivo ZIP ou gere um arquivo PDF contendo todos esses documentos. Você deve adicionar esse arquivo ZIP ou PDF gerado.',
    '- Todos os documentos adicionados não devem conter o número completo do seu cartão de crédito. Por favor, caso tenha, você deve ocultar os últimos seis dígitos do seu cartão.',
    '- Você deve revisar todos documentos adicionados.',
  ],
  es_ES: [
    'Recuerda:',
    `- Una vez que haya agregado todos los documentos requeridos, procesaremos tu reclamo en un plazo de hasta ${BOLD_DAYS_REMEMBER_DOCUMENTS_OTHERS.es_ES.bold()}.`,
    '- Debe agregar un archivo a la vez priorizando los documentos opcionales, si los hubiera, y luego los documentos requeridos.',
    '- Una vez que haya varios documentos asociados con el mismo tipo, comprímalos en un archivo ZIP o genera un archivo PDF que contenga todos estos documentos. Debe agregar este archivo ZIP o PDF generado.',
    '- Todos los documentos agregados no deben contener tu número de tarjeta de crédito completo. Por favor, si lo hace, debe ocultar los últimos seis dígitos de tu tarjeta.',
    '- Debe revisar todos los documentos agregados.',
  ],
};

const BOLD_DAYS_REMEMBER_DOCUMENTS_IEMS = {
  en_US: 'fifteen (15) business days',
  pt_BR: 'quinze (15) dias úteis',
  es_ES: 'quince (15) días hábiles',
};

export const TEXT_REMEMBER_DOCUMENTS_IEMS = {
  en_US: [
    'Remember:',
    `- Once you have added all the required documents, we will process your claim on the following ${BOLD_DAYS_REMEMBER_DOCUMENTS_IEMS.en_US.bold()}.`,
    '- You must add one file at a time, prioritizing the optional documents, if any, and then the required documents.',
    '- Once there are multiple documents associated with the same type, compress them into a ZIP file or generate a PDF file containing all these documents. You must add this generated ZIP or PDF file.',
    '- All documents added must not contain your full credit card number. Please, if you do, you must hide the last six digits of your card.',
    '- You must review all added documents.',
  ],
  pt_BR: [
    'Lembre-se:',
    `- Uma vez que você adicione todos os documentos requeridos, processaremos seu sinistro nos próximos ${BOLD_DAYS_REMEMBER_DOCUMENTS_IEMS.pt_BR.bold()}.`,
    '- Você deve adicionar um arquivo por vez priorizando os documentos opcionais, caso existam, e depois os documentos requeridos.',
    '- Uma vez que hajam vários documentos associados ao mesmo tipo,  comprima-os em um arquivo ZIP ou gere um arquivo PDF contendo todos esses documentos. Você deve adicionar esse arquivo ZIP ou PDF gerado.',
    '- Todos os documentos adicionados não devem conter o número completo do seu cartão de crédito. Por favor, caso tenha, você deve ocultar os últimos seis dígitos do seu cartão.',
    '- Você deve revisar todos documentos adicionados.',
  ],
  es_ES: [
    'Recuerda:',
    `- Una vez que haya agregado todos los documentos requeridos, procesaremos tu reclamo en un plazo de hasta ${BOLD_DAYS_REMEMBER_DOCUMENTS_IEMS.es_ES.bold()}.`,
    '- Debe agregar un archivo a la vez priorizando los documentos opcionales, si los hubiera, y luego los documentos requeridos.',
    '- Una vez que haya varios documentos asociados con el mismo tipo, comprímalos en un archivo ZIP o genera un archivo PDF que contenga todos estos documentos. Debe agregar este archivo ZIP o PDF generado.',
    '- Todos los documentos agregados no deben contener tu número de tarjeta de crédito completo. Por favor, si lo hace, debe ocultar los últimos seis dígitos de tu tarjeta.',
    '- Debe revisar todos los documentos agregados.',
  ],
};

export const ADD = {
  en_US: 'Add',
  pt_BR: 'Adicionar',
  es_ES: 'Agregar',
};

export const REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};

export const REMOVING = {
  en_US: 'Removing',
  pt_BR: 'Removendo',
  es_ES: 'Quitando',
};

export const PENDING = {
  en_US: 'Pending file',
  pt_BR: 'Arquivo pendente',
  es_ES: 'Archivo pendiente',
};

export const LOADING = {
  en_US: 'Loading',
  pt_BR: 'Carregando',
  es_ES: 'Cargando',
};

export const YOUR_CLAIMS = {
  en_US: 'Your claims',
  pt_BR: 'Seus sinistros',
  es_ES: 'Tus reclamos',
};

export const GO_HOME = {
  en_US: 'Go to Homepage',
  pt_BR: 'Ir para a Página principal',
  es_ES: 'Ir a la Página de inicio',
};

export const CAN_SENT = {
  en_US: `All mandatory documents have been attached. If you need to submit additional documents, please use the Optional Field.`,
  pt_BR: `Todos os documentos obrigatórios foram anexados. Caso precise enviar documentos adicionais, utilize o Campo Opcional.`,
  es_ES: `Todos los documentos obligatorios han sido adjuntados. En caso de que necesite enviar documentos adicionales, utilice el Campo Opcional.`,
};

export const COMPLETING_YOUR_CLAIM = {
  en_US: 'Completing your claim...',
  pt_BR: 'Concluindo seu sinistro...',
  es_ES: 'Completando tu reclamo...',
};

export const IMPORTANT_COMPLETE_ATTACHMENTS = {
  en_US: `Click “Attach Documents” to proceed or “Conclude” to submit your claim to analysis without an additional document.`,
  pt_BR: `Clique em “Anexar Documentos” para continuar ou em “Concluir” para enviar o seu sinistro para a análise sem documentos adicionais.`,
  es_ES: `Haga clic en “Adjuntar Documentos” para continuar o en “Concluir” para enviar su reclamo a análisis sin documentos adicionales.`,
};

export const BTN_CONCLUDE = {
  en_US: 'Conclude',
  pt_BR: 'Concluir',
  es_ES: 'Concluir',
};

export const BTN_ATTACH_DOCUMENTS = {
  en_US: 'Attach documents',
  pt_BR: 'Anexar documentos',
  es_ES: 'Adjuntar documentos',
};

export const HAS_BEEN_SENT = {
  en_US: 'Congratulations! Your claim was successfully sent to analysis.',
  pt_BR: 'Parabéns! Seu sinistro foi enviado para análise com sucesso.',
  es_ES: '¡Felicidades! Tu reclamo fue enviado exitosamente a análisis.',
};

export const BEEN_SENT_SUBTITLE = {
  en_US: 'It takes up to 11 (eleven) business days to complete the analysis.',
  pt_BR: 'São necessários até 11 (onze) dias úteis para concluir a análise.',
  es_ES:
    'Se necesitan hasta 11 (once) días hábiles para completar el análisis.',
};

export const BEEN_SENT_SUBTITLE_IEMS = {
  en_US: 'It takes up to 15 (fifteen) business days to complete the analysis.',
  pt_BR: 'São necessários até 15 (quinze) dias úteis para concluir a análise.',
  es_ES:
    'Se necesitan hasta 15 (quince) días hábiles para completar el análisis.',
};

export const BEEN_SENT_SUBTITLE_INCONVENIENCE = {
  en_US:
    'Dear, we are experiencing a high volume of claims, so there may be a delay in evaluating it. We are working to improve this experience. We regret the inconvenience.',
  pt_BR:
    'Prezado, estamos enfrentando um grande volume de sinistros, portanto, poderá haver algum atraso na resposta de avaliação do mesmo. Estamos trabalhando para melhorar esta experiência e pedimos desculpas pelo transtorno.',
  es_ES:
    'Estimado, estamos experimentado un alto volumen de reclamos, por lo que puede haber un atraso en la evaluación del mismo. Estamos trabajando para mejorar esta experiencia. Lamentamos el inconveniente.',
};

export const TEXT_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

export const OPEN_CLAIM = {
  en_US: 'Create Claim',
  pt_BR: 'Abrir Sinistro',
  es_ES: 'Abrir Reclamo',
};

export const TEXT_UPLOAD_DOCUMENT = {
  en_US: 'Attach documents',
  pt_BR: 'Anexar documentos',
  es_ES: 'Adjuntar documentos',
};

export const FILE_SIZE_ERROR = {
  en_US: 'File size larger than allowed (maximum size 1.5MB).',
  pt_BR: 'Arquivo de tamanho maior que o permitido (tamanho máximo 1.5MB).',
  es_ES: 'Archivo de tamaño mayor que el permitido (tamaño máximo 1.5MB).',
};

export const FILE_TYPE_ERROR = {
  en_US: 'Invalid file format.',
  pt_BR: 'Formato do arquivo é inválido.',
  es_ES: 'Formato de archivo inválido.',
};

export const DAYS_TO_COMPLETE_ANALYSIS_IEMS = {
  en_US:
    'Once all your required documents are uploaded, it will take up to fifteen (15) calendar days in order to complete the analysis.',
  pt_BR:
    'Após o upload de todos os documentos necessários, serão necessários até quinze (15) dias corridos para concluir a análise dos mesmos.',
  es_ES:
    'Una vez que se carguen todos los documentos requeridos, tomará quince (15) días naturales para completar el análisis.',
};

export const DAYS_TO_COMPLETE_ANALYSIS_OTHERS = {
  en_US:
    'Once all your required documents are uploaded, it will take up to five (5) calendar days in order to complete the analysis.',
  pt_BR:
    'Após o upload de todos os documentos necessários, serão necessários até cinco (5) dias corridos para concluir a análise dos mesmos.',
  es_ES:
    'Una vez que se carguen todos los documentos requeridos, tomará cinco (5) días naturales completar el análisis.',
};

export const TEXT_OPTIONAL = {
  en_US:
    'If you consider having additional documents to support your claim, please add them here',
  pt_BR:
    'Se você considerar ter documentos adicionais para apoiar sua reivindicação, adicione-os aqui.',
  es_ES:
    'Si considera tener documentos adicionales para respaldar tu reclamo, por favor agréguelos aquí.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_STEP_1 = {
  en_US: 'Terms and conditions',
  es_ES: 'Términos y condiciones',
  pt_BR: 'Termos e condições',
};

export const TEXT_STEP_2 = {
  en_US: 'Claim form',
  es_ES: 'Formulario de reclamo',
  pt_BR: 'Formulário do sinistro',
};

export const TEXT_STEP_3 = {
  en_US: 'Sending documents',
  es_ES: 'Envío de documentos',
  pt_BR: 'Envio de documentos',
};

export const TITLE_APPEALED_MODAL = {
  en_US: 'Please review the required documentation of your claim.',
  es_ES: 'Por favor, revisa la documentación requerida de tu reclamo.',
  pt_BR: 'Por favor, revise a documentação requerida do seu sinistro.',
};

export const SUBTITLE_APPEALED_MODAL = {
  en_US: reason =>
    `Only after review the required documents we will start the analysis process.</br>Rejection reason: <strong>${reason}</strong>`,
  es_ES: reason =>
    `Solo después de revisar los documentos requeridos comenzaremos el proceso de análisis.</br>Motivo de rechazo: <strong>${reason}</strong>`,
  pt_BR: reason =>
    `Somente após a revisão dos documentos requeridos é que iniciaremos o processo de análise do mesmo.</br>Motivo da rejeição: <strong>${reason}</strong>`,
};

export const TEXT_YOUR_PERSONAL_DOCUMENTS_MODIFIED = {
  en_US: 'Your personal documents will be modified. Do you wish to continue?',
  pt_BR: 'Seus documentos pessoais serão modificados. Deseja continuar?',
  es_ES: 'Tus documentos personales serán modificados. ¿Desea continuar?',
};

export const BTN_YES_UPDATE = {
  en_US: 'Yes, I want to',
  pt_BR: 'Sim, eu quero',
  es_ES: 'Sí, quiero',
};

export const BTN_NOT_UPDATE = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_TITLE_PERSONAL_DOCUMENTS_ALERT = {
  en_US: 'Outdated personal documents ',
  pt_BR: 'Documentos pessoais desatualizados',
  es_ES: 'Documentos personales obsoletos ',
};

export const BTN_TEXT_SUBTITLE_PERSONAL_DOCUMENTS = {
  en_US:
    'We noticed that your personal documents are out of date. If you keep your profile up to date, it will be much easier to fill in your certificates and claims forms. ',
  pt_BR:
    'Notamos que seus documentos pessoais estão desatualizados. Se você mantiver seu perfil atualizado será muito mais simples o preenchimento dos formulários dos seus certificados e sinistros.',
  es_ES:
    'Notamos que tus documentos personales están desactualizados. Si mantienes tu perfil actualizado, te resultará mucho más fácil cumplimentar tus certificados y formularios de reclamos. ',
};

export const BTN_YES_ALERT_UPDATE = {
  en_US: 'Update personal documents',
  pt_BR: 'Atualizar documentos pessoais',
  es_ES: 'Actualizar documentos personales',
};

export const BTN_NOT_ALERT_UPDATE = {
  en_US: 'Attach documents',
  pt_BR: 'Anexar documentos',
  es_ES: 'Adjuntar documentos',
};
