import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const PurchaseIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <g>
          <path
            fill={config_icon.primary}
            d="M2,20V7h1V5H0v17h13c-0.3-0.6-0.6-1.3-0.8-2H2z"
          />
          <rect x={9} y={5} className="st0" width={2} height={2} />
          <polygon
            fill={config_icon.primary}
            points="17,5 17,7 18,7 18,10.2 19,9.9 20,10.2 20,5  "
          />
        </g>
        <g>
          <path
            fill={config_icon.primary}
            d="M15,10V5c0-2.8-2.2-5-5-5S5,2.2,5,5v5h2V5c0-1.7,1.3-3,3-3s3,1.3,3,3v5H15z"
          />
          <path
            fill={config_icon.secondary}
            d="M19,12l-5,1.2V18c0,3.7,2.8,5.5,4.3,6h1.3h0.1c1.5-0.4,4.3-2.2,4.3-6v-4.8L19,12z M22,18c0,2.8-2.2,3.8-2.7,4 h-0.6c-0.5-0.2-2.7-1.2-2.7-4v-3.2l3-0.8l3,0.8V18z"
          />
        </g>
      </g>
    </svg>
  );
};

export default PurchaseIcon;
