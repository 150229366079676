import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import RadioButton from '../../../../../../../components/RadioButton/RadioButton';
import styles from './IsCardHolderArea.module.scss';
import { generalInformationFormInitialValues } from '../initialValues';
import { ThemeContext } from '../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const IsCardHolderArea = props => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const {
    setValues,
    initialValues,
    changeCardHolder,
    isCertificate,
    setIsCardHolder,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const blankForm = () => {
    const newValues = generalInformationFormInitialValues(
      {},
      '',
      idiom,
      isCertificate
    );
    newValues.benefitiaryIsNotCardHolder = true;
    newValues.idiom = idiom;
    newValues.address = initialValues.address;
    setValues(newValues);
    setIsCardHolder(false);
    if (changeCardHolder) changeCardHolder(false);
  };

  const resetForm = () => {
    const newValues = initialValues;
    setValues(newValues);
    setIsCardHolder(true);
    if (changeCardHolder) changeCardHolder(true);
  };
  return (
    <div
      className={`row ${styles.isCardHolder}`}
      data-testid="InputClaimCHIsCHArea"
    >
      <div
        className={`${styles.fontGray12} ${styles.options} ${styles[themes]} row`}
      >
        <div className={`col-lg-12 col-sm-12`}>
          <label className={`${styles.labelTitle} ${styles[themes]}`}>
            {intl.LABEL_BENEFICIARY_IS_CH}
          </label>
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_BENEFICIARY_IS_CH}
            describe={intl.TEXT_DESCRIBE_YES}
            defaultChecked={true}
            text={intl.TEXT_YES}
            name="isCardHolder"
            dataTestid="IscardHolderYes"
            value={true}
            onChange={resetForm}
          />
        </div>
        <div className={`${styles.option} col-12`}>
          <RadioButton
            label={intl.LABEL_BENEFICIARY_IS_CH}
            describe={intl.TEXT_DESCRIBE_NO}
            defaultChecked={false}
            text={intl.TEXT_NO}
            name="isCardHolder"
            value={false}
            onChange={blankForm}
            dataTestid="IscardHolderNo"
          />
        </div>
      </div>
    </div>
  );
};

export default IsCardHolderArea;
