import PlayStoreEN from '../../assets/images/downloadApp/stores/ENG/download-android-eng.png';
import PlayStoreES from '../../assets/images/downloadApp/stores/SP/download-android-esp.png';
import PlayStorePT from '../../assets/images/downloadApp/stores/PT/download-android-pt.png';
import AppStoreEN from '../../assets/images/downloadApp/stores/ENG/download-ios-eng.png';
import AppStoreES from '../../assets/images/downloadApp/stores/SP/download-ios-esp.png';
import AppStorePT from '../../assets/images/downloadApp/stores/PT/download-ios-pt.png';
import CELL_PHONE_IMAGE_EN from '../../assets/images/marketing/banner_footer/mobile_app-mkt-V2-ENG.png';
import CELL_PHONE_IMAGE_ES from '../../assets/images/marketing/banner_footer/mobile_app-mkt-V2-SPA.png';
import CELL_PHONE_IMAGE_PT from '../../assets/images/marketing/banner_footer/mobile_app-mkt-V2-PT.png';
import BANNER_DOWNLOAD_APP_EN from '../../assets/images/downloadApp/banner/visa-mobile-app-banner-eng.png';
import BANNER_DOWNLOAD_APP_ES from '../../assets/images/downloadApp/banner/visa-mobile-app-banner-esp.png';
import BANNER_DOWNLOAD_APP_PT from '../../assets/images/downloadApp/banner/visa-mobile-app-banner-pt.png';

//BANNER
export const BANNER_TITLE = {
  en_US: 'Visa Benefits <br/> Mobile App',
  es_ES: 'Visa Benefits <br/> Mobile App',
  pt_BR: 'Aplicativo de <br/> Benefícios Visa',
};

export const BANNER_IMAGE = {
  en_US: BANNER_DOWNLOAD_APP_EN,
  es_ES: BANNER_DOWNLOAD_APP_ES,
  pt_BR: BANNER_DOWNLOAD_APP_PT,
};

//blue box
export const DOWNLOAD_APP = {
  en_US: 'Download your Visa Benefits App',
  es_ES: 'Descarga tu nueva App Visa Benefits',
  pt_BR: 'Baixe seu App Visa Benefits',
};

export const ENJOY_BENEFITS = {
  en_US: 'and enjoy the benefits',
  es_ES: 'Y disfruta de los beneficios',
  pt_BR: 'E disfrute dos benefícios',
};

export const AVAILABLE_IN_STORES = {
  en_US: 'Available for free on the Google Play and App Store.',
  es_ES: 'Disponible gratis en las tiendas Google Play y App Store.',
  pt_BR: 'Disponível grátis nas lojas Google Play e App Store. ',
};

export const IMAGE_APP_STORE = {
  en_US: AppStoreEN,
  es_ES: AppStoreES,
  pt_BR: AppStorePT,
};

export const IMAGE_PLAY_STORE = {
  en_US: PlayStoreEN,
  es_ES: PlayStoreES,
  pt_BR: PlayStorePT,
};

//covered benefits
export const VISA_COVERED = {
  en_US: 'With Visa we have you covered everywhere',
  es_ES: 'Con Visa te tenemos cubierto en todas partes',
  pt_BR: 'Com a Visa, cobrimos você em todos os lugares',
};

export const VISA_ACCOMPANIES = {
  en_US: `Visa accompanies you everywhere, that's why with your new app, Visa Benefits, you can enjoy the benefits of your Visa card anytime, anywhere, from the palm of your hand.`,
  es_ES: `Visa te acompaña a todos lados, es por eso que con tu nueva app, Visa Benefits, puedes disfrutar de los benedicios de tu tarjeta Visa a cualquier hora y en cualquier lugar, desde la palma de tu mano.`,
  pt_BR:
    'A Visa acompanha você em todos os lugares, por isso com seu novo app, Benefícios Visa, você pode usufruir dos benefícios do seu cartão Visa a qualquer hora, em qualquer lugar, na palma da sua mão.',
};

export const DISCOVER_BENEFITS = {
  en_US: 'Discover all you can do from our <br /> Visa Benefits App',
  es_ES: 'Descubre lo que puedes hacer desde nuestra <br /> Visa Benefits App',
  pt_BR: 'Descubra o que você pode fazer em nosso <br /> Visa Benefits App',
};

export const BENEFIT_INFO = {
  en_US: 'Benefits Information*',
  es_ES: 'Información de beneficios*',
  pt_BR: 'Informações sobre benefícios*',
};

export const BENEFIT_CONCIERGE = {
  en_US: 'Visa Digital Concierge*',
  es_ES: 'Visa Digital Concierge*',
  pt_BR: 'Visa Digital Concierge*',
};

export const BENEFIT_CERTIFICATES = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Certificados',
};

export const VIRTUAL_AGENT = {
  en_US: 'Virtual Agent',
  es_ES: 'Agente Virtual',
  pt_BR: 'Agente Virtual',
};

export const BENEFIT_AIRPORT_COMPANION = {
  en_US: 'Visa Airport Companion*',
  es_ES: 'Visa Airport Companion*',
  pt_BR: 'Visa Airport Companion*',
};

export const BENEFIT_MEDIC = {
  en_US: 'Visa Online Medic*',
  es_ES: 'Visa Médico Online*',
  pt_BR: 'Visa Médico Online*',
};

export const BENEFIT_TRAVEL_KIT = {
  en_US: 'Travel Kit*',
  es_ES: 'Travel Kit*',
  pt_BR: 'Travel Kit*',
};

//FOOTER
export const EXPLORE_AND_ENJOY = {
  en_US: 'Explore and enjoy all the Visa benefits',
  es_ES: 'Explora y disfruta de todos los beneficios Visa',
  pt_BR: 'Explore e aproveite todos os benefícios da Visa',
};

export const DOWNLOAD_OUR_APP = {
  en_US: 'Download your new app for free on the App Store and Google Play',
  es_ES: 'Descarga gratis tu nueva app en App Store y Google Play',
  pt_BR: 'Baixe seu novo app gratuitamente na App Store e Google Play',
};

export const TEXT_DISCLAIMER_DOWNLOAD_APP = {
  en_US: '* Check the availability of benefits for your Visa card.',
  es_ES: '* Consulta la disponibilidad de beneficios para tu tarjeta Visa.',
  pt_BR: '* Verifique a disponibilidade dos benefícios para o seu cartão Visa.',
};

export const CELL_PHONE_IMAGE = {
  en_US: CELL_PHONE_IMAGE_EN,
  es_ES: CELL_PHONE_IMAGE_ES,
  pt_BR: CELL_PHONE_IMAGE_PT,
};

export const NEW_TAB = {
  en_US: 'new tab',
  pt_BR: 'nova aba',
  es_ES: 'nueva pestaña',
};
