import { pt_nationalities } from '../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../intl/es_nationalities';
import { pt_countries } from '../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../intl/es_countries';

export const TEXT_TITLE = {
  en_US: 'Travel assistance certificate',
  pt_BR: 'Bilhete Anual de Seguro Viagem / Schengen',
  es_ES: 'Certificado de Asistencia en Viaje',
};

export const TEXT_SUBTITLE = {
  en_US: 'GENERATE',
  pt_BR: 'GERAR',
  es_ES: 'GENERAR',
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_SUBIMT = {
  en_US: 'Send',
  pt_BR: 'Enviar',
  es_ES: 'Enviar',
};

export const TEXT_TERMS = {
  en_US: 'I have read and accept the terms.',
  pt_BR: 'Eu li e aceito os termos.',
  es_ES: 'He leído y acepto los términos.',
};

export const LABEL_ADD = {
  en_US: 'Add',
  pt_BR: 'Adicionar',
  es_ES: 'Adicionar',
};

export const TEXT_DEPENDENT_CHANGE = {
  en_US: 'Beneficiary information has been changed. Do you want to save them?',
  pt_BR: 'A informação do beneficiário foi alterada. Você quer salvá-los?',
  es_ES: 'La información beneficiario ha sido modificada. ¿Quieres salvarlos?',
};

export const TEXT_CH_CHANGE = {
  en_US: 'Your personal details has been changed. Do you want to save it?',
  pt_BR: 'Suas informações pessoais foram modificadas. Deseja salvá-las?',
  es_ES:
    'Tu información personal ha sido modificada. ¿Deseas guardar los cambios?',
};

export const TEXT_DATA_UPDATE = {
  en_US: 'Data update',
  pt_BR: 'Atualização de dados',
  es_ES: 'Actualización de datos',
};

export const BTN_BACK = {
  en_US: 'Back',
  pt_BR: 'Voltar',
  es_ES: 'Volver',
};

/*
 INITIAL PAGE FORM
*/
export const TEXT_LOADING = {
  en_US: 'Loading data',
  pt_BR: 'Carregando dados',
  es_ES: 'Cargando datos',
};

/*
 FORM CARD HOLDER / DEPENDENT
*/

export const LABEL_TITLE = {
  en_US: 'Title *',
  pt_BR: 'Título *',
  es_ES: 'Título *',
};

export const LABEL_NAME = {
  en_US: 'Name *',
  pt_BR: 'Nome *',
  es_ES: 'Nombre *',
};

export const LABEL_OCCUPATION = {
  en_US: 'Profession *',
  pt_BR: 'Profissão *',
  es_ES: 'Profesión *',
};
export const LABEL_LAST_NAME = {
  en_US: 'Last name *',
  pt_BR: 'Sobrenome *',
  es_ES: 'Apellido *',
};

export const LABEL_BIRTH_DATE = {
  en_US: 'Date of birth *',
  pt_BR: 'Data de nascimento *',
  es_ES: 'Fecha de nacimiento *',
};

export const LABEL_GENDER = {
  en_US: 'Gender *',
  pt_BR: 'Gênero *',
  es_ES: 'Género *',
};

export const LABEL_MISTER = {
  en_US: 'Mister',
  pt_BR: 'Senhor',
  es_ES: 'Señor',
};

export const LABEL_MS = {
  en_US: 'Ms',
  pt_BR: 'Senhora',
  es_ES: 'Señora',
};

export const LABEL_MISS = {
  en_US: 'Miss',
  pt_BR: 'Senhorita',
  es_ES: 'Señorita',
};

export const LABEL_DR = {
  en_US: 'Doctor',
  pt_BR: 'Doutor',
  es_ES: 'Doctor',
};

export const LABEL_PR = {
  en_US: 'Professor',
  pt_BR: 'Professor',
  es_ES: 'Maestra',
};

export const LABEL_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const LABEL_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const LABEL_UNKNOWN = {
  en_US: 'Prefer not to inform',
  es_ES: 'Prefiero no informar',
  pt_BR: 'Prefiro não informar',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const PLACEHOLDER_PASSPORT = {
  en_US: 'Passport *',
  pt_BR: 'Passaporte *',
  es_ES: 'Pasaporte *',
};

export const PLACEHOLDER_NATIONAL_ID = {
  en_US: 'National ID *',
  pt_BR: 'Identidade Nacional *',
  es_ES: 'Documento Nacional de Identidad *',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};

export const LABEL_STATE = {
  en_US: 'State *',
  pt_BR: 'Estado *',
  es_ES: 'Estado *',
};

export const LABEL_CITY = {
  en_US: 'City *',
  pt_BR: 'Cidade *',
  es_ES: 'Ciudad *',
};

export const TOOLTIP_CITY = {
  en_US:
    'This field must only contain 30 characters. If needed, use abbreviations.',
  pt_BR:
    'Este campo deve conter apenas 30 caracteres. Se necessário, use abreviaturas.',
  es_ES:
    'Este campo debe contener solo 30 caracteres. Si es necesario, use abreviaturas.',
};

export const LABEL_POSTAL_CODE = {
  en_US: 'Postal code *',
  pt_BR: 'CEP *',
  es_ES: 'Código postal *',
};

export const LABEL_ADDRESS = {
  en_US: 'Address *',
  pt_BR: 'Rua *',
  es_ES: 'Calle *',
};

export const LABEL_SUBDIVISION = {
  en_US: 'Neighborhood',
  pt_BR: 'Bairro ',
  es_ES: 'Barrio/Colonia',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};

export const LABEL_RELATIONSHIP = {
  en_US: 'Relationship *',
  pt_BR: 'Parentesco *',
  es_ES: 'Relación *',
};

export const LABEL_RELATIONSHIP_VALUE_SON = {
  en_US: 'Son',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_RELATIONSHIP_VALUE_DAU = {
  en_US: 'Daughter',
  pt_BR: 'Filha',
  es_ES: 'Hija',
};

export const LABEL_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const LABEL_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};

export const LABEL_CPF_TYPE = {
  en_US: 'CPF *',
  pt_BR: 'CPF *',
  es_ES: 'CPF *',
};

export const LABEL_BANK = {
  en_US: 'Issuer name *',
  pt_BR: 'Nome do emissor *',
  es_ES: 'Nombre del emisor *',
};

export const LABEL_BANK_4_DIGITS = {
  en_US: 'Please, enter the last 4 numbers of your credit card *',
  pt_BR: '4 últimos números do cartão *',
  es_ES:
    'Por favor, introduzca los últimos 4 números de tu tarjeta de crédito *',
};

export const VALUE_REGISTRATIONS_TYPE = {
  en_US: 'PASSPORT',
  pt_BR: 'CPF',
  es_ES: 'PASSPORT',
};

export const TEXT_POLITICALLY_EXPOSED = {
  en_US: 'Considera-se uma pessoa politicamente exposta?',
  pt_BR: 'Considera-se uma pessoa politicamente exposta?',
  es_ES: 'Considera-se uma pessoa politicamente exposta?',
};

export const TEXT_FIELD_REQUIRED = {
  en_US: 'Required field.',
  pt_BR: 'Campo obrigatório.',
  es_ES: 'Campo obligatorio.',
};

export const VALIDATE_EMAIL_VALID = {
  en_US: 'Please enter a valid e-mail.',
  pt_BR: 'Por favor, insira um e-mail válido.',
  es_ES: 'Por favor, introduzca un e-mail válido.',
};

export const VALIDATE_CPF = {
  en_US: 'Please enter a valid CPF.',
  pt_BR: 'Por favor, insira um CPF válido.',
  es_ES: 'Por favor, introduzca un CPF válido',
};

/*
 NATIONALITIES AND COUTRIES
*/
export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const NATIONALITY_DEPENDENT_LABEL = {
  en_US: 'Nationality of beneficiary *',
  pt_BR: 'Nacionalidade do beneficiário *',
  es_ES: 'Nacionalidad del beneficiario *',
};

export const COUNTRY_LABEL = {
  en_US: 'Residence country *',
  pt_BR: 'País de residência *',
  es_ES: 'País de residencia *',
};

export const TEXT_CREATED_CERTIFICATE = {
  en_US:
    "The Insurance Ticket and the Complement were sent to the registered e-email address. In case you haven't received them, please check your spam folder.",
  pt_BR:
    'O Bilhete de Seguro e o Complemento foram enviados para o endereço de e-mail registrado. Caso não as tenha recebido, verifique sua pasta de spam.',
  es_ES:
    'El Billete de Seguro y el Complemento se enviaron a la dirección de correo electrónico registrada. En caso de que no los haya recibido, consulta tu carpeta spam.',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com o passaporte de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const TEXT_ALERT_NATIONAL_ID = {
  en_US:
    'For minors, please complete with the national ID of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com a identidade nacional de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el Documento Nacional de Identidad de uno de los padres/tutores.',
};

export const TEXT_ALERT_CPF = {
  en_US: 'For minors, please complete with the CPF of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el CPF de uno de los padres/tutores.',
};
export const TEXT_ALERT_CPF_FOREIGNERS = {
  en_US:
    'CPF is mandatory for cards issued in Brazil, please refer to the T&C.',
  pt_BR: 'CPF é obrigatório para cartões emitidos no Brasil, consulte T&C.',
  es_ES:
    'CPF es obligatorio para tarjetas emitidas en Brasil, consulte los T&C.',
};

export const TEXT_ALERT_4_DIGITS = {
  en_US:
    'Make sure card selected on the right top is the one you want to use to open this claim.',
  pt_BR:
    'Verifique se o cartão selecionado no canto superior direito corresponde ao cartão que você deseja usar para abrir a reivindicação.',
  es_ES:
    'Asegúrate que la tarjeta seleccionada en la esquina superior derecha corresponde a la tarjeta que quiere utilizar para abrir el reclamo.',
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingresa un código postal válido.',
};

export const VALIDATING_CEP = {
  en_US: 'Validating postal Code...',
  pt_BR: 'Validando CEP...',
  es_ES: 'Validar código postal...',
};

export const VALIDATE_4_NUMBERS = {
  en_US: 'Please enter at least 4 characters.',
  pt_BR: 'Por favor, insira pelo menos 4 caracteres.',
  es_ES: 'Por favor, introduzca al menos 4 caracteres.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor, insira uma data válida.',
  es_ES: 'Por favor, introduzca una fecha válida.',
};

export const VALIDATION_NAME_LAST_NAME = {
  en_US: 'Please enter at least one letter [A-Za-z].',
  pt_BR: 'Por favor, insira pelo menos uma letra [A-Za-z].',
  es_ES: 'Por favor, introduzca al menos una letra [A-Za-z].',
};

export const TEXT_ADDRESS = {
  en_US: 'Address',
  pt_BR: 'Endereço',
  es_ES: 'Dirección',
};

export const TEXT_BANK = {
  en_US: 'Informações bancárias',
  pt_BR: 'Informações bancárias',
  es_ES: 'Informações bancárias',
};

export const TEXT_FIELD_VALIDATION_30 = {
  en_US: 'Please enter a maximum of 30 characters.',
  pt_BR: 'Por favor, insira no máximo 30 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 30 caracteres.',
};

export const TEXT_FIELD_VALIDATION_255 = {
  en_US: 'Please enter a maximum of 255 characters.',
  pt_BR: 'Por favor, insira no máximo 255 caracteres.',
  es_ES: 'Por favor, introduzca un máximo de 255 caracteres.',
};
