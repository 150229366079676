export const DOCUMENTATION_PROVIDED_INFINITE = {
  en_US:
    'If the documentation provided is complete and correct, the amount claimed per person will be indemnified up to a maximum amount of USD $3.000.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, se indemnizará el monto reclamado por persona hasta un monto máximo de USD $3.000.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor reivindicado por pessoa será indenizado até um valor máximo de USD $3.000.',
};

export const DOCUMENTATION_PROVIDED_SIGNATURE = {
  en_US:
    'If the documentation provided is complete and correct, the amount claimed per person will be indemnified up to a maximum amount of USD $1.000.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, se indemnizará el monto reclamado por persona hasta un monto máximo de USD $1.000.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor reivindicado por pessoa será indenizado até um valor máximo de USD $1.000.',
};

export const DOCUMENTATION_PROVIDED_PLATINUM = {
  en_US:
    'If the documentation provided is complete and correct, the indemnity amount will be USD $750 for Visa CTA Air 250 / Visa Platinum Corporate.',
  es_ES:
    'Si la documentación proporcionada está completa y correcta, el monto a indemnizar será de USD $750 para Visa CTA Air 250 / Visa Platinum Corporate.',
  pt_BR:
    'Se a documentação fornecida estiver completa e correta, o valor a ser indenizado será de USD $750 para Visa CTA Air 250 / Visa Platinum Corporate.',
};

export const EXEMPLE = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_RELATIONSHIP_CARD_HOLDER = {
  en_US: 'Relationship to cardholder *',
  pt_BR: 'Relação com o titular do cartão *',
  es_ES: 'Relación con el titular de la tarjeta *',
};

export const PERSON_AFFECTED = {
  en_US: 'Person affected',
  pt_BR: 'Pessoa afetada',
  es_ES: 'Persona afectada',
};

export const TEXT_CH = {
  en_US: 'Cardholder',
  es_ES: 'Titular de la tarjeta',
  pt_BR: 'Titular do cartão',
};

export const TEXT_IM_CH = {
  en_US: 'I am the cardholder',
  pt_BR: 'Eu sou o titular do cartão',
  es_ES: 'Yo soy el titular de la tarjeta',
};

export const TEXT_RELATIONSHIP_VALUE_SPOUSE = {
  en_US: 'Spouse',
  pt_BR: 'Cônjuge',
  es_ES: 'Cónyuge',
};

export const TEXT_RELATIONSHIP_VALUE_OTHER = {
  en_US: 'Other',
  pt_BR: 'Outro',
  es_ES: 'Otro',
};

export const TEXT_RELATIONSHIP_DEPENDENT_CHILD = {
  en_US: 'Beneficiary child',
  pt_BR: 'Filho',
  es_ES: 'Hijo',
};

export const LABEL_NAME_AFFECTED = {
  en_US: 'Affected person name *',
  pt_BR: 'Nome da pessoa afetada *',
  es_ES: 'Nombre de la persona afectada *',
};

export const LABEL_LAST_NAME_AFFECTED = {
  en_US: 'Affected person last name *',
  pt_BR: 'Sobrenome da pessoa afetada *',
  es_ES: 'Apellido de la persona afectada *',
};

export const TEXT_OTHER = {
  en_US: 'Other (explain) *',
  pt_BR: 'Outro (explicar) *',
  es_ES: 'Otro (explicar) *',
};

export const TEXT_GENDER = {
  en_US: 'Gender *',
  pt_BR: 'Gênero *',
  es_ES: 'Género *',
};

export const TEXT_GENDER_MALE = {
  en_US: 'Male',
  pt_BR: 'Masculino',
  es_ES: 'Masculino',
};

export const TEXT_GENDER_FEMALE = {
  en_US: 'Female',
  pt_BR: 'Feminino',
  es_ES: 'Femenino',
};

export const FIELD_ADDRESS_EMAIL = {
  en_US: 'E-mail *',
  es_ES: 'Correo eletrónico *',
  pt_BR: 'E-mail *',
};
