export const TITLE_GET_MORE_BENEFITS = {
  en_US: 'Get More Benefits',
  es_ES: 'Obtenga más beneficios',
  pt_BR: 'Obtenha mais benefícios',
};

//IEMS
export const IEMS_NAME = {
  en_US: 'International Medical Emergency Medical',
  es_ES: 'Servicios Médicos de Emergencia Internacional',
  pt_BR: 'Seguro de Emergência Médica Internacional',
};
