import { pt_nationalities } from '../../../../../../../intl/pt_nationalities';
import { en_nationalities } from '../../../../../../../intl/en_nationalities';
import { es_nationalities } from '../../../../../../../intl/es_nationalities';
import { pt_countries } from '../../../../../../../intl/pt_countries';
import { en_countries } from '../../../../../../../intl/en_countries';
import { es_countries } from '../../../../../../../intl/es_countries';

export const LABEL_REGISTRATIONS_TYPE_PASSPORT = {
  en_US: 'Passport *',
  pt_BR: 'Passaporte *',
  es_ES: 'Pasaporte *',
};

export const LABEL_REGISTRATIONS_TYPE_CPF = {
  en_US: 'CPF *',
  pt_BR: 'CPF *',
  es_ES: 'CPF *',
};

export const LABEL_NATIONAL_ID = {
  en_US: 'National ID',
  pt_BR: 'Identidade Nacional',
  es_ES: 'Documento Nacional de Identidad',
};

export const LABEL_IDENTIFICATION = {
  en_US: 'Identification',
  pt_BR: 'Identificação',
  es_ES: 'Identificación',
};

export const LABEL_CPF = {
  en_US: 'CPF',
  pt_BR: 'CPF',
  es_ES: 'CPF',
};

export const LABEL_PASSPORT = {
  en_US: 'Passport',
  pt_BR: 'Passaporte',
  es_ES: 'Pasaporte',
};

export const LABEL_STATE = {
  en_US: 'State *',
  pt_BR: 'Estado *',
  es_ES: 'Estado *',
};

export const LABEL_CITY = {
  en_US: 'City *',
  pt_BR: 'Cidade *',
  es_ES: 'Ciudad *',
};

export const LABEL_POSTAL_CODE = {
  en_US: 'Postal code *',
  pt_BR: 'CEP *',
  es_ES: 'Código postal *',
};

export const LABEL_ADDRESS = {
  en_US: 'Address *',
  pt_BR: 'Rua *',
  es_ES: 'Calle *',
};

export const LABEL_SUBDIVISION = {
  en_US: 'Neighborhood *',
  pt_BR: 'Bairro *',
  es_ES: 'Barrio/Colonia *',
};

export const LABEL_REGISTRATIONS_TYPE = {
  en_US: 'Passport *',
  pt_BR: 'CPF *',
  es_ES: 'Pasaporte *',
};

/*
 NATIONALITIES AND COUTRIES
*/
export const NATIONALITY_OPTIONS = {
  en_US: en_nationalities,
  pt_BR: pt_nationalities,
  es_ES: es_nationalities,
};

export const COUNTRY_OPTIONS = {
  en_US: en_countries,
  pt_BR: pt_countries,
  es_ES: es_countries,
};

export const NATIONALITY_LABEL = {
  en_US: 'Nationality *',
  pt_BR: 'Nacionalidade *',
  es_ES: 'Nacionalidad *',
};

export const NATIONALITY_DEPENDENT_LABEL = {
  en_US: 'Nationality of beneficiary *',
  pt_BR: 'Nacionalidade do beneficiário *',
  es_ES: 'Nacionalidad del beneficiario *',
};

export const COUNTRY_LABEL = {
  en_US: 'Country *',
  pt_BR: 'País *',
  es_ES: 'País *',
};

export const TEXT_CREATED_CERTIFICATE = {
  en_US:
    "The Insurance Ticket and the Complement were sent to the registered e-email address. In case you haven't received them, please check your spam folder.",
  pt_BR:
    'O Bilhete de Seguro e o Complemento foram enviados para o endereço de e-mail registrado. Caso não as tenha recebido, verifique sua pasta de spam.',
  es_ES:
    'El Billete de Seguro y el Complemento se enviaron a la dirección de correo electrónico registrada. En caso de que no los haya recibido, consulta tu carpeta spam.',
};

export const TEXT_ALERT_PASSPORT = {
  en_US: 'For minors, please complete with the passport of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com o passport de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el pasaporte de uno de los padres/tutores.',
};

export const TEXT_ALERT_NATIONAL_ID = {
  en_US:
    'For minors, please complete with the national ID of one of the parents.',
  pt_BR:
    'Para menores de idade, por favor completar com a identidade nacional de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el Documento Nacional de Identidad de uno de los padres/tutores.',
};

export const TEXT_ALERT_CPF = {
  en_US: 'For minors, please complete with the CPF of one of the parents.',
  pt_BR: 'Para menores de idade, por favor completar com o CPF de um dos pais.',
  es_ES:
    'Para menores de edad, por favor completar con el CPF de uno de los padres/tutores.',
};

export const TEXT_ALERT_PREFIX = {
  en_US: 'Include your international country code.',
  pt_BR: 'Inclua seu código internacional do país.',
  es_ES: 'Incluye tu código de país internacional.',
};

export const TEXT_ALERT_4_DIGITS = {
  en_US:
    'Make sure card selected on the right top is the one you want to use to open this claim.',
  pt_BR:
    'Verifique se o cartão selecionado no canto superior direito corresponde ao cartão que você deseja usar para abrir a reivindicação.',
  es_ES:
    'Asegúrate que la tarjeta seleccionada en la esquina superior derecha corresponde a la tarjeta que quiere utilizar para abrir el reclamo.',
};

export const VALIDATE_CEP = {
  en_US: 'Please enter a valid postal code.',
  pt_BR: 'Por favor, insira um CEP válido.',
  es_ES: 'Por favor ingrese un código postal válido.',
};

export const VALIDATE_4_NUMBERS = {
  en_US: 'Please enter at least 4 characters.',
  pt_BR: 'Por favor, insira pelo menos 4 caracteres.',
  es_ES: 'Por favor, introduzca al menos 4 caracteres.',
};

export const VALIDATE_DATE_VALID = {
  en_US: 'Please enter a valid date.',
  pt_BR: 'Por favor insira uma data válida.',
  es_ES: 'Por favor introduzca una fecha valida.',
};

export const LABEL_EMAIL = {
  en_US: 'E-mail *',
  pt_BR: 'E-mail *',
  es_ES: 'Correo eletrónico *',
};
