import { useEffect } from 'react';
import PhoneForm from '../../../../../../../../components/PhoneForm';

const PhoneArea = props => {
  const { setFieldValue, prefixOptions } = props;

  useEffect(() => {
    if (prefixOptions.prefix) {
      setFieldValue(
        'affected_person.phones.international_prefix',
        prefixOptions.prefix
      );
    }
  }, [prefixOptions.prefix, setFieldValue]);

  return (
    <div className="col-12">
      <PhoneForm
        formikProps={props}
        inputNames={{
          phoneType: `affected_person.phones.phone_type`,
          countryCode: `affected_person.phones.international_prefix`,
          phoneNumber: `affected_person.phones.number`,
        }}
        testIds={{
          phoneType: 'comboboxPhoneType',
        }}
      />
    </div>
  );
};

export default PhoneArea;
