import React, { useContext } from 'react';
import moment from 'moment';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import styles from '../ClaimView.module.scss';
import * as translations from '../intl';
import InfoLabelValue from '../../../../components/InfoLabelValue/InfoLabelValue';
import {
  getGender,
  getPhoneType,
  getPoliticallyExposed,
  getTitle,
} from '../../../../utils/formatInfoProfile';
import { pt_BR } from '../../../../intl/idioms';
import {
  formatCPF,
  formatPostalCodeBR,
  mask4FirstDigits,
} from '../../../../utils/stringUtils';
import { formatCustomDate } from '../../../../utils/date';

const GeneralInformation = props => {
  const { holderDetails, userInfo } = props;
  const { translate, idiom, country } = useContext(IntlContext);
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const isPTBR = idiom === pt_BR;
  const birthDate =
    holderDetails && holderDetails.person.birth_date
      ? formatCustomDate(
          holderDetails.person.birth_date,
          'YYYY-MM-DD',
          idiom,
          country
        )
      : '-';
  const isCPF =
    holderDetails &&
    holderDetails.registrations &&
    holderDetails.registrations[0] &&
    holderDetails.registrations[0].registration_type === 'CPF';
  const registrationValue = isCPF
    ? holderDetails &&
      holderDetails.registrations &&
      holderDetails.registrations[0].value &&
      formatCPF(mask4FirstDigits(holderDetails.registrations[0].value))
    : holderDetails &&
      holderDetails.registrations &&
      holderDetails.registrations[0] &&
      holderDetails.registrations[0].value &&
      mask4FirstDigits(holderDetails.registrations[0].value);

  const registrationType =
    holderDetails.person &&
    holderDetails.person.nationalities &&
    holderDetails.person.nationalities[0] === 'BR'
      ? intl.LABEL_CPF
      : holderDetails.registrations &&
        holderDetails.registrations[0] &&
        holderDetails.registrations[0].registration_type === 'ID_CARD'
      ? intl.LABEL_NATIONAL_ID
      : intl.LABEL_PASSPORT;

  const getAddress = prop => {
    if (!prop.country) return '-';

    return `
        ${holderDetails.address.street_address} <br/>
        ${holderDetails.address.subdivision} <br/>
        ${holderDetails.address.locality} -
        ${holderDetails.address.state} -
        ${holderDetails.address.country}
        (${
          isPTBR
            ? formatPostalCodeBR(holderDetails.address.postal_code)
            : holderDetails.address.postal_code
        })`;
  };

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  return (
    <div className={`row ${styles.sectionTitle}`}>
      <h2 className={`col-12 ${styles.sectionTitle} ${styles[themes]}`}>
        {intl.TITLE_GENERAL_INFORMATION}
      </h2>
      <>
        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_NAME}
            value={safeLoad(holderDetails.person.first_name)}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_LAST_NAME}
            value={safeLoad(holderDetails.person.last_name)}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_GENDER}
            value={safeLoad(getGender(holderDetails.person.gender, intl))}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue label={intl.LABEL_BIRTH_DATE} value={birthDate} />
        </div>

        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_EMAIL}
            value={holderDetails.email}
          />
        </div>

        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={registrationType}
            value={registrationValue ? registrationValue : '-'}
          />
        </div>

        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_NUMBER}
            value={`
                ${getPhoneType(holderDetails.phone.phone_type, intl)}
                ${holderDetails.phone.international_prefix}
                ${holderDetails.phone.number}
              `}
          />
        </div>

        <div className="col-12 col-sm-12 col-md-4">
          <InfoLabelValue
            label={intl.LABEL_ADDRESS}
            value={getAddress(userInfo.address)}
          />
        </div>

        {isPTBR && (
          <>
            <div className="col-12 col-md-8">
              <InfoLabelValue
                label={intl.LABEL_POLITICALLY_EXPOSED}
                value={`
                ${getPoliticallyExposed(
                  holderDetails.is_politically_exposed,
                  intl
                )}`}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default GeneralInformation;
