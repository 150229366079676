export const CERTIFICATE_NOT_FOUND = {
  en_US: 'No certificate was found to start the download.',
  es_ES: 'No se encontró certificado para iniciar la descarga.',
  pt_BR: 'Nenhum certificado foi encontrado para iniciar o download.',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_OK = {
  en_US: 'Ok',
  es_ES: 'Ok',
  pt_BR: 'Ok',
};

export const LABEL_DELETE = {
  en_US: 'Remove file',
  es_ES: 'Eliminar archivo',
  pt_BR: 'Excluir arquivo',
};

export const LABEL_DOWNLOAD = {
  en_US: 'Download file',
  es_ES: 'Descargar archivo',
  pt_BR: 'Baixar arquivo',
};
