import moment from 'moment';
import { ALPHANUMERIC_COUNTRY_LIST } from '../../../../../../../../utils/environments_variables';
import { removeNonNumeric } from '../../../../../../../../utils/stringUtils';

export const corporate_liability_waiverToApi = props => {
  let object = {};
  object.affected_person = affected_personToApi(props);
  object.contacts = contactsToApi(props);
  object.financial_information = financial_informationToApi(props);
  object.incident = incidentToApi(props);
  object.collection_agencies = collection_agenciesToApi(props);
  object.certification = certificationToApi(props);

  return object;
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;
  const address = affected_person.address;
  const person = affected_person.person;

  let affected_personReturn = {};

  affected_personReturn.relationship = affected_person.relationship;
  affected_personReturn.comment = affected_person.comment;
  affected_personReturn.person = {
    first_name: person.first_name,
    last_name: person.last_name,
  };
  affected_personReturn.address = {
    country: address.country,
    state: address.state,
    locality: address.locality,
    postal_code: ALPHANUMERIC_COUNTRY_LIST.includes(address.country)
      ? address.postal_code
      : removeNonNumeric(address.postal_code),
    street_address: address.street_address,
    subdivision: address.subdivision,
  };

  return { ...affected_personReturn };
};

const contactsToApi = props => {
  const contact = props.values.incident.contacts;
  const contacts = {};

  contacts.person = {
    first_name: contact.person.first_name,
    last_name: contact.person.last_name,
  };
  contacts.phones = [
    {
      phone_type: contact.phones.phone_type,
      international_prefix: contact.phones.international_prefix,
      number: contact.phones.number,
    },
  ];

  return [{ ...contacts }];
};

const financial_informationToApi = props => {
  const financial_information = props.values.incident.financial_information;

  return {
    valid_account_number: financial_information.valid_account_number,
    credit_limit: financial_information.credit_limit,
    currency: financial_information.currency,
    charge_submitted_by_company:
      financial_information.charge_submitted_by_company,
    recovered_to_date: financial_information.recovered_to_date,
    is_payment_made_within_last_12_months:
      financial_information.is_payment_made_within_last_12_months === 'true',
  };
};

const incidentToApi = props => {
  const incident = props.values.incident.incident;

  let object = {};
  if (incident.question_1_view_cardIs_lost === 'true') {
    object.date_of_loss_or_stealth = moment(
      incident.date_of_loss_or_stealth
    ).format('YYYY-MM-DD');
  }
  if (incident.question_2_view_is_dead === 'true') {
    object.bankruptcy_declaration_date = moment(
      incident.bankruptcy_declaration_date
    ).format('YYYY-MM-DD');
  }
  object.is_the_company_responsible =
    incident.is_the_company_responsible === 'true';
  object.is_the_cardholder_responsible =
    incident.is_the_cardholder_responsible === 'true';

  return { ...object };
};

const collection_agenciesToApi = props => {
  const collection_agencies = props.values.incident.collection_agencies;
  const contacts = collection_agencies.contacts;
  let object = {};

  collection_agencies.address.postal_code = ALPHANUMERIC_COUNTRY_LIST.includes(
    collection_agencies.address.country
  )
    ? collection_agencies.address.postal_code
    : removeNonNumeric(collection_agencies.address.postal_code);

  object.name = collection_agencies.name;
  object.address = collection_agencies.address;
  object.contacts = [
    {
      person: contacts.person,
      phones: [contacts.phones],
    },
  ];
  object.assignment_date = moment(collection_agencies.assignment_date).format(
    'YYYY-MM-DD'
  );
  object.received_affidavit_date = moment(
    collection_agencies.received_affidavit_date
  ).format('YYYY-MM-DD');
  object.account_cancellation_date = moment(
    collection_agencies.account_cancellation_date
  ).format('YYYY-MM-DD');
  object.received_request_date = moment(
    collection_agencies.received_request_date
  ).format('YYYY-MM-DD');

  return [{ ...object }];
};

const certificationToApi = props => {
  const certification = props.values.incident.certification;
  let object = {};

  object.is_amount_due_certified = certification.is_amount_due_certified;
  object.financial_institution = certification.financial_institution;
  object.is_information_certified = certification.is_information_certified;
  object.recovery_date = moment(certification.recovery_date).format(
    'YYYY-MM-DD'
  );

  return { ...object };
};
