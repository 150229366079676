export const pendingSend = [
  'Claims - Waiting Customer Documentation',
  'Claims - Waiting Customer Documentation 30 Days',
  'Claims - Waiting Customer Documentation 60 Days',
  'Claims - Waiting Customer Documentation 90 Days',
];

export const under_review = [
  'Claims - Pending Check forms received',
  'Claims - Pending Review forms and docs',
  'Claims - Customer documentation reviewed',
  'Claims - Pending to request additional doc',
  'Claims - Waiting to review upload docs',
  'Claims - Proof of Payment Process',
  'Claims - Case appealed',
];

export const under_review_by_underwriter = [
  'Claims - Pending Underwriter Review',
  'Claims - Documentation reviewed by Underwriter. Pending for approval',
  'Claims – Pending Underwriter',
];
export const review = [...under_review, ...under_review_by_underwriter];

export const payment = [
  'Claims - Pending to check payment',
  'Claims - Approved Claim',
];

export const rejected = [
  'Claims - Rejected Claim',
  'Claims - Rejected claim by underwriter. Waiting reassessment',
];

export const case_closed = ['Claims - Case Closed', 'Claims - Cancelled'];

export const case_closed_duplicated = ['Claims - Duplicated Claim'];
export const closed = [...case_closed, ...case_closed_duplicated];

export const appealed = ['Claims - Case appealed'];
