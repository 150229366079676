import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../../../intl';
import * as translations from './intl';
import RadioButton from '../../../../../../../../components/RadioButton/RadioButton';
import styles from './QuestionPeopleInvolved.module.scss';
import { ThemeContext } from '../../../../../../../../themes/ThemeContextParent/ThemeContextParent';

const QuestionPeopleInvolved = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, handleChange } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  return (
    <div
      className={`row ${styles.personalArea}`}
      style={{ marginBottom: '12px' }}
    >
      <div className="col-12">
        <div className={`${styles.elementRadio} ${styles[themes]}  row`}>
          <div className={`col-lg-12 col-sm-12`}>
            <label className={`${styles.labelTitle} ${styles[themes]}`}>
              {intl.INVOLVED_PEOPLE_INCIDENT}
            </label>
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.INVOLVED_PEOPLE_INCIDENT}
              defaultChecked={values.have_people_involved === 'true'}
              text={intl.TEXT_YES}
              name="have_people_involved"
              value={true}
              onChange={handleChange}
            />
          </div>
          <div className={`${styles.option} col-12`}>
            <RadioButton
              label={intl.INVOLVED_PEOPLE_INCIDENT}
              defaultChecked={values.have_people_involved === 'false'}
              text={intl.TEXT_NO}
              name="have_people_involved"
              value={false}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionPeopleInvolved;
