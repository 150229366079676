import { useState, useEffect } from 'react';
import styles from './MenuCountryList.module.scss';
import SubMenuCountry from './SubMenuCountry/SubMenuCountry';
import { useIntl } from '../../intl';
import { dataLayerContentMenuTrack } from '../../utils/GTM_helper';
import * as translations from './intl';
import Button from '../../componentsV2/Button/Button';

const MenuCountryList = ({ color }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { countryFullName, translate } = useIntl();
  const intl = translate(translations);

  useEffect(() => {
    setOpenSubMenu(false);
  }, [countryFullName]);

  useEffect(() => {
    const wrapperMenuCountryList = document.getElementById(
      'wrapperMenuCountryList'
    );
    const btnMenuCountryList = document.getElementById('btnCountryList');
    wrapperMenuCountryList.addEventListener('keyup', function (event) {
      if (event.key === 'Escape') {
        setOpenSubMenu(false);
        btnMenuCountryList.focus();
      }
    });
  }, []);

  return (
    <div
      className={`${styles.menuCountry}  hideOnMobile`}
      onClick={() => dataLayerContentMenuTrack('CTA Country')}
      onBlur={event => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpenSubMenu(false);
        }
      }}
      id="wrapperMenuCountryList"
    >
      <Button
        ariaExpanded={openSubMenu}
        id="btnCountryList"
        type="Tertiary"
        className={styles.buttonCountry}
        onClick={() => {
          setOpenSubMenu(!openSubMenu);
        }}
        surface={color === '#FFFFFF' ? 'Inverse' : 'OnSurface'}
        trailingIcon={!openSubMenu ? 'Chevron Down' : 'Chevron Up'}
        ariaLabel={intl.OPEN_MENU_COUNTRIES_LIST(countryFullName)}
        label={countryFullName}
      />
      {openSubMenu && <SubMenuCountry />}
    </div>
  );
};

export default MenuCountryList;
