import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};

export const TEXT_YES = {
  en_US: 'Yes',
  pt_BR: 'Sim',
  es_ES: 'Sí',
};

export const TEXT_NO = {
  en_US: 'No',
  pt_BR: 'Não',
  es_ES: 'No',
};

export const TEXT_AREA = {
  en_US:
    'The maximum reimbursement under the purchase protection benefit is the coverage limit set by your issuing Bank.',
  pt_BR:
    'O reembolso máximo sob a benefício de proteção de compras é o cobertura estabelecida pelo seu Emissor.',
  es_ES:
    'El reembolso máximo en virtud de la cobertura de protección de compra es el valor establecido por tu Banco.',
};

export const LABEL_INCIDENT_DATE = {
  en_US: 'Incident date *',
  pt_BR: 'Data do incidente *',
  es_ES: 'Fecha del incidente *',
};

export const TEXT_DESCRIPTION = {
  en_US:
    'Please explain the reasons of your dissatisfaction and why the store will not process the refund',
  pt_BR:
    'Explique os motivos da sua insatisfação e porque a loja não processará o reembolso',
  es_ES:
    'Por favor explique los motivos de tu insatisfacción y las razones por las cuales la tiena no procesará la devolución',
};

export const LABEL_DESCRIPTION = {
  en_US: 'Incident description *',
  pt_BR: 'Descreva o incidente *',
  es_ES: 'Describa el incidente *',
};

export const LABEL_ITEM = {
  en_US: 'Item purchased *',
  pt_BR: 'Produto comprado *',
  es_ES: 'Artículo comprado *',
};

export const LABEL_MANUFACTURER = {
  en_US: 'Manufacturer *',
  pt_BR: 'Fabricante *',
  es_ES: 'Fabricante *',
};

export const LABEL_MODEL = {
  en_US: 'Model *',
  pt_BR: 'Modelo *',
  es_ES: 'Modelo *',
};

export const LABEL_PURCHASE_DATE = {
  en_US: 'Purchase date *',
  pt_BR: 'Data da compra *',
  es_ES: 'Fecha de compra *',
};

export const LABEL_PURCHASE_PRICE = {
  en_US: 'Purchase price (including tax) *',
  pt_BR: 'Preço de compra (incluindo impostos) *',
  es_ES: 'Precio de compra (incluyendo impuestos) *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  pt_BR: 'Moeda *',
  es_ES: 'Moneda *',
};

export const LABEL_RETURN_PERIOD = {
  en_US: 'Return period offered by the store [Days, Months]',
  pt_BR: 'Período de devolução oferecido pela loja [Dias, Meses]',
  es_ES: 'Periodo de devolución ofrecido por la tienda [Días, Meses]',
};

export const LABEL_DAMAGE = {
  en_US: 'Accidental damage',
  pt_BR: 'Dano acidental',
  es_ES: 'Daño accidental',
};

export const LABEL_THEFT = {
  en_US: 'Theft or Robbery',
  pt_BR: 'Roubo ou Furto',
  es_ES: 'Robo o hurto',
};

export const LABEL_TYPE_OF_LOSS = {
  en_US: 'Type of loss: *',
  pt_BR: 'Tipo de perda: *',
  es_ES: 'Tipo de pérdida: *',
};

export const LABEL_ADDITIONAL_ITEMS = {
  en_US: 'Would you like to include additional items on this claim? *',
  pt_BR: 'Deseja incluir mais items neste mesmo sinistro? *',
  es_ES: 'Deseas incluir más un producto en este reclamo? *',
};

export const LABEL_DAMEGE_REPAIRED = {
  en_US: 'Can the damage be repaired?',
  pt_BR: 'O dano pode ser reparado?',
  es_ES: '¿El daño puede ser reparado?',
};

export const LABEL_DAMAGE_VISIBLE = {
  en_US: 'Is the damage to the item visible?',
  pt_BR: 'O dano causado ao artigo é visível?',
  es_ES: '¿El daño en el material es visible?',
};

export const LABEL_TOTAL_AMOUNT_CLAIM = {
  en_US: 'Total amount of claim *',
  pt_BR: 'Valor total do sinistro *',
  es_ES: 'Importe total de la reivindicación *',
};

export const TEXT_AMOUNT_DECIMAL = {
  en_US: 'Use . as decimal symbol.',
  pt_BR: 'Usar . como símbolo decimal.',
  es_ES: 'Utilice . como símbolo decimal.',
};

export const TEXT_ITEM = {
  en_US: 'Item #',
  pt_BR: 'Produto #',
  es_ES: 'Artículo #',
};

export const LABEL_OTHER_INSURANCE = {
  en_US: 'Do you have other insurance that applies to the claimed benefit?',
  pt_BR: 'Você tem outro seguro que cubra este incidente?',
  es_ES: '¿Tienes otro seguro que cubra este incidente?',
};

export const LABEL_AMOUNT_PAID = {
  en_US: 'Please indicate the amount paid by the insurance *',
  pt_BR: 'Indique o valor pago pela companhia de seguros *',
  es_ES: 'Indica el monto pagado por la compañía de seguros *',
};

export const BTN_ADD = {
  en_US: 'Add item',
  pt_BR: 'Adicionar produto',
  es_ES: 'Agregar artículo',
};

export const LABEL_RETURN_PERIOD_TYPE = {
  en_US: 'Return period type',
  pt_BR: 'Tipo de prazo de devolução',
  es_ES: 'Tipo de plazo de devolución',
};

export const RETURN_PERIOD_OPTIONS = {
  en_US: `<option value="D"> Days </option>
          <option value="M"> Months </option>`,
  pt_BR: `<option value="D"> Dias </option>
          <option value="M"> Meses </option>`,
  es_ES: `<option value="D"> Dias </option>
          <option value="M"> Meses </option>`,
};

export const TEXT_RETAILER_REFUSAL_REASON = {
  en_US: 'Store refuse reason',
  pt_BR: 'Motivo de recusa da loja',
  es_ES: 'Razón de rechazo de la tienda',
};

export const BTN_REMOVE = {
  en_US: 'Remove',
  pt_BR: 'Remover',
  es_ES: 'Eliminar',
};
