import React, { useContext } from 'react';

import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';

const PersonalArea = props => {
  const { claimDetails } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);

  const totalAmountClaimed =
    claimDetails && claimDetails.total_amount_claimed
      ? claimDetails.total_amount_claimed
      : {};

  const totalValue =
    totalAmountClaimed && totalAmountClaimed.value
      ? totalAmountClaimed.value
      : '-';

  const valueCurrency =
    totalAmountClaimed && totalAmountClaimed.currency
      ? totalAmountClaimed.currency
      : '-';

  return (
    <>
      <div className="col-sm-12 col-md-4">
        <InfoLabelValue
          label={intl.TEXT_TOTAL_AMOUNT}
          value={`${totalValue} - ${valueCurrency}`}
        />
      </div>
    </>
  );
};

export default PersonalArea;
