import React from 'react';
import Calendar from '../../../../../../components/Calendar/Calendar';

const today = new Date();

const DatesArea = props => {
  const { intl } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-md-6">
          <Calendar
            value={today}
            label={intl.INSSUANCE_DATE}
            name=""
            disabled={true}
          />
        </div>
        <div className="col-12 col-md-6">
          <Calendar
            label={intl.PURCHASE_DATE}
            name="appliance_information.purchase_date"
            type="text"
            formikProps={props}
            maxDate={today}
          />
        </div>
      </div>
    </>
  );
};

export default DatesArea;
