import { PropsWithChildren } from 'react';
import styles from './DashedContainer.module.scss';
import { useTheme } from '../../themes/ThemeContextParent/ThemeContextParent';

interface Props extends PropsWithChildren {
  dashed?: boolean;
}

const DashedContainer = ({ children, dashed = true }: Props) => {
  const { getGlobalTheme } = useTheme();
  const theme = getGlobalTheme();

  return (
    <div className={`${styles[theme]} ${dashed ? styles.container : ''}`}>
      {children}
    </div>
  );
};

export default DashedContainer;
