import { useContext, useEffect, useState } from 'react';
import HorizontalHalfBlueLayout from '../../../layouts/HorizontalHalfBlueLayout';
import SessionContainer from '../../../components/SessionContainer/SessionContainer';
import HeadingTitle from '../../../components/HeadingTitle/HeadingTitle';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { LoadingStatus } from '../../../@types/LoadingStatus';
import { Travel } from '../../../@types/APIs/Travels';
import { AppInsightTrackContext } from '../../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';
import { travelService } from '../../../services';
import { parseISO } from 'date-fns';
import TripAssistanceCardItem from './TripAssistanceCardItem/TripAssistanceCardItem';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import styles from './index.module.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';

const renderListCertificate = (travels: Travel[]) => {
  return travels.map(travel => (
    <TripAssistanceCardItem key={travel.certificate_id} travel={travel} />
  ));
};

const ListingTripAssistance = () => {
  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.LOADING);
  const [travels, setTravels] = useState<Travel[]>([]);

  const customer_id = utils.getCn();
  const intl = translate(translations);

  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  const loadTravels = async () => {
    try {
      setStatus(LoadingStatus.LOADING);

      trackEventUserAction(
        `#### Trip Assistance - Listing ### Loading Travels`,
        true
      );

      const { data } = await travelService.getTravels(customer_id);

      const sortedTravels = data.sort(
        (a, b) =>
          parseISO(b.outward_start_at).getTime() -
          parseISO(a.outward_start_at).getTime()
      );

      setTravels(sortedTravels);
      setStatus(LoadingStatus.LOADED);

      trackEventUserAction(
        `#### Trip Assistance - Listing ### Travels loaded`,
        { travels: data }
      );
    } catch (error) {
      setStatus(LoadingStatus.ERROR);
      trackEventUserAction(
        `#### Trip Assistance - Listing ### Error loading Travels`,
        { data: error?.response?.data || error }
      );
    }
  };

  useEffect(() => {
    loadTravels();
  }, []);

  return (
    <HorizontalHalfBlueLayout status={status}>
      <SessionContainer>
        <HeadingTitle
          title={intl.MY_TRIP_ASSISTANCES}
          subtitle={travels.length > 0 ? intl.SUBTITLE_TRIP_ASSISTANCE : ' '}
          backLink="/your-benefits"
        />
        {travels.length > 0 ? (
          <div id="mainContent" className={styles.content}>
            {renderListCertificate(travels)}
          </div>
        ) : (
          <p className={`${styles.subtitle} ${styles[theme]}`}>
            {intl.DONT_HAVE_TRIP_ASSISTANCES}
          </p>
        )}
      </SessionContainer>
    </HorizontalHalfBlueLayout>
  );
};

export default ListingTripAssistance;
