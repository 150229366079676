import React from 'react';
import Slider from 'react-slick';

const Carousel = props => {
  const { settings } = props;

  return (
    <div style={{ overflow: 'hidden' }} data-testid="carousel">
      <Slider {...settings} {...props}>
        {props.children}
      </Slider>
    </div>
  );
};

export default Carousel;
