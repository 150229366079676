import { useContext, useState } from 'react';

import * as translations from './intl';
import { IntlContext } from '../../../../../../intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { ThemeContext } from '../../../../../../themes/ThemeContextParent/ThemeContextParent';
import * as PurchaseProtectionUtils from '../../../../../../utils/claimsAPI/PurchaseProtectionUtils';
import { safeGet } from '../../../../../../utils/object';
import GroupDivisor from '../../../../../../components/GroupDivisor';

const PurchasedItem = props => {
  const { cellphone } = props;
  const { translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  const [covering, setCovering] = useState({});

  useState(() => {
    const covering = safeGet(cellphone, 'other_insurance_covering[0]', {});
    setCovering(covering || {});
  });

  return (
    <>
      <div className="col-12">
        <GroupDivisor title={intl.TEXT_CELLPHONE} simpleLine />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_MANUFACTURER}
          value={PurchaseProtectionUtils.getItemManufacturer(cellphone)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_MODEL}
          value={PurchaseProtectionUtils.getItemModel(cellphone)}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_ESTIMATED_CELL_PHONE_VALUE}
          value={PurchaseProtectionUtils.getItemPurchasePrice(
            cellphone,
            idiomForApi()
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_FULL_CARD}
          value={PurchaseProtectionUtils.getIsFullyChargedOnCard(
            cellphone,
            intl
          )}
        />
      </div>
      <div className="col-sm-12 col-md-3">
        <InfoLabelValue
          label={intl.LABEL_TYPE_OF_LOSS}
          value={PurchaseProtectionUtils.getItemTypeOfLoss(cellphone, intl)}
        />
      </div>
      {cellphone && cellphone.status === 'Damage' && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_CELL_PHONE_REPAIRABLE}
              value={PurchaseProtectionUtils.getIsItemReparable(
                cellphone,
                intl
              )}
            />
          </div>
          {cellphone && cellphone.is_repairable && (
            <div className="col-sm-12 col-md-3">
              <InfoLabelValue
                label={intl.LABEL_REPAIR_VALUE}
                value={PurchaseProtectionUtils.getItemAmountClaimed(
                  cellphone,
                  idiomForApi()
                )}
              />
            </div>
          )}
        </>
      )}
      {covering.amount && covering.amount.value && (
        <div className="col-sm-12 col-md-6">
          <InfoLabelValue
            label={intl.LABEL_AMOUNT_PAID}
            value={PurchaseProtectionUtils.getOtherInsuranceCovering(
              covering,
              idiomForApi()
            )}
          />
        </div>
      )}
    </>
  );
};

export default PurchasedItem;
