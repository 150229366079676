import React, { useContext } from 'react';
import { IntlContext } from '../../../../../../intl';
import * as translations from './intl';
import InfoLabelValue from '../../../../../../components/InfoLabelValue/InfoLabelValue';
import { getCurrencyNameByInitials } from '../../../../../../utils/currency';

const OtherInsuranceArea = props => {
  const { claimDetails } = props;
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);
  const isInternal =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering[0] &&
    claimDetails.other_insurance_covering[0].is_internal;
  const other_insurance_covering =
    claimDetails &&
    claimDetails.other_insurance_covering &&
    claimDetails.other_insurance_covering[0];

  const safeLoad = prop => {
    return prop ? prop : '-';
  };

  return (
    <>
      {isInternal && (
        <>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_OTHER_INSURANCE}
              value={`${safeLoad(other_insurance_covering.amount.value)}`}
            />
          </div>
          <div className="col-sm-12 col-md-3">
            <InfoLabelValue
              label={intl.LABEL_CURRENCY}
              value={`${getCurrencyNameByInitials(
                other_insurance_covering.amount.currency,
                idiom
              )}`}
            />
          </div>
        </>
      )}
    </>
  );
};

export default OtherInsuranceArea;
