import momentTimezone from 'moment-timezone';
import moment from 'moment';
import { minimumDateNAL } from './validationDateMinMaxNAL';
import { listTimeZones } from '../../../../../intl/timezones';

export const NALFormInitialValues = (
  idiom,
  country,
  acceptChecked,
  cardHolder,
  intl,
  configAppointment
) => {
  const date = new Date();
  const minDate = minimumDateNAL(date, configAppointment);
  const limitDate = new Date(
    `${moment().add(1, 'years').format('YYYY-MM-DD')}T00:00:00`
  );
  const currentLocation = momentTimezone.tz.guess();
  const findTimezone = listTimeZones.find(
    timezone => timezone.timezone === currentLocation
  );

  return {
    pacient: {
      name: cardHolder && cardHolder.person && cardHolder.person.first_name,
      last_name: cardHolder && cardHolder.person && cardHolder.person.last_name,
      email: cardHolder && cardHolder.email,
      phone_type: cardHolder && cardHolder.phone && cardHolder.phone.phone_type,
      phone_international_prefix:
        cardHolder && cardHolder.phone && cardHolder.phone.international_prefix,
      phone_number: cardHolder && cardHolder.phone && cardHolder.phone.number,
      comment: '',
      idiom: idiom,
    },
    appointment: {
      date:
        configAppointment && configAppointment.schedule_rules
          ? limitDate > minDate
            ? minDate
            : ''
          : date,
      timezone: findTimezone ? currentLocation : '',
      time: '',
      validTime: '',
      confirmCannotDiagnosedThroughPhone: acceptChecked,
      confirmNoUrgentSymptoms: acceptChecked,
      confirmIEMSDoesntCoverMedicalExpenses: acceptChecked,
      country: cardHolder && cardHolder.address && cardHolder.address.country,
      state: cardHolder && cardHolder.address && cardHolder.address.state,
      city: cardHolder && cardHolder.address && cardHolder.address.locality,
    },
    questions: {
      questionsCheckeds: [],
      questions: [
        {
          value: false,
          name: intl.TEXT_QUESTIONS_NAL_1,
        },
        {
          value: false,
          name: intl.TEXT_QUESTIONS_NAL_2,
        },
        {
          value: false,
          name: intl.TEXT_QUESTIONS_NAL_3,
        },
        {
          value: false,
          name: intl.TEXT_QUESTIONS_NAL_4,
        },
      ],
      questionCommentChecked: false,
      commentQuestions: '',
    },
  };
};
