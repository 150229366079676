export const TITLE_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const TITLE_MAKE_CLAIM = {
  en_US: 'Open a claim',
  es_ES: 'Crear un reclamo',
  pt_BR: 'Abrir um sinistro',
};

export const TITLE_BUTTON_BENEFIT = {
  en_US: 'View more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const LINK_NAME_UPSELLING = {
  en_US: 'Upgrade your coverage',
  es_ES: 'Mejora tu cobertura',
  pt_BR: 'Aumente sua cobertura',
};

export const LINK_NAME_CROSSELLING = {
  en_US: 'Buy now',
  es_ES: 'Compra ahora',
  pt_BR: 'Adquirir agora',
};

export const RIGHT_ARROW = {
  en_US: 'right arrow',
  es_ES: 'flecha hacia la derecha',
  pt_BR: 'seta para a direita',
};
