import React from 'react';

import PersonalArea from './PersonalArea/PersonalArea';
import CardArea from './CardArea/CardArea';
import IncidentArea from './IncidentArea/IncidentArea';
import PeopleInvolvedArea from './PeopleInvolvedArea/PeopleInvolvedArea';

const InsuredJourneyAccidental = props => {
  const { claimDetails } = props;
  const isAccidentalDeath = claimDetails && claimDetails.is_accidental_death;
  const arePeopleInvolved =
    claimDetails &&
    claimDetails.people_involved &&
    claimDetails.people_involved.length > 0;

  return (
    <div className="row">
      <PersonalArea claimDetails={claimDetails} />
      {!isAccidentalDeath && <CardArea claimDetails={claimDetails} />}
      <IncidentArea
        claimDetails={claimDetails}
        isAccidentalDeath={isAccidentalDeath}
      />
      {!isAccidentalDeath && arePeopleInvolved && (
        <PeopleInvolvedArea claimDetails={claimDetails} />
      )}
    </div>
  );
};

export default InsuredJourneyAccidental;
