export const TITLE_HI = {
  en_US: 'Hi',
  es_ES: 'Hola',
  pt_BR: 'Oi',
};

export const TITLE_WELLCOME_PROFILE = {
  en_US: 'welcome to your profile',
  es_ES: 'bienvenido a tu perfil',
  pt_BR: 'bem vindo ao seu perfil',
};

export const HEADER = {
  en_US: 'Header',
  es_ES: 'Encabezado',
  pt_BR: 'Cabeçalho',
};
