import React, { useContext, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import styles from './signup.module.scss';
import Form from './Form/Form';
import { IntlContext, countryNameToInitials } from '../../intl';
import * as translations from './intl';
import {
  userServices,
  cardsServices,
  productsAndBenefitsServices,
  tokenexService,
} from '../../services';
import {
  removeNonNumeric,
  formatLinkWithIdiomAndCountry,
} from '../../utils/stringUtils';
import { getCookie, setCookie } from '../../utils/cookies';
import { countryToIdiom } from '../../intl/utils';
import SessionContainer from '../../components/SessionContainer/SessionContainer';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import HeadingTitle from '../../components/HeadingTitle/HeadingTitle';
import { dataLayerSignUpTrack } from '../../utils/GTM_helper';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import Loading from '../../components/Loading/Loading';
import StepClaim from '../Claims/StepClaim/StepClaim';
import { NOTIFICATIONS_ENABLE } from '../../utils/environments_variables';
import {
  formatEffectiveEndDate,
  generatePanToken,
} from '../../components/UpdateBinTokenex/InputTokenex/utils';
import { AppInsightTrackContext } from '../../components/AppInsightTrackContextParent/AppInsightTrackContextParent';

export const formDataToApi = formData => {
  const isCPF = formData.person_registrations.registration_type === 'CPF';

  const apiData = {
    partner_customer_id: '0001',
    email: formData.email,
    person: {
      title: formData.title,
      birth_date: moment(formData.birth_date).format('YYYY-MM-DD'),
      first_name: formData.name,
      last_name: formData.lastName,
      nationalities: Array.isArray(formData.nationalities)
        ? [formData.nationalities[0]]
        : [formData.nationalities],
    },
    address: {},
    phone: {
      phone_type: formData.phone_type,
      number: formData.number,
      international_prefix: removeNonNumeric(formData.international_prefix),
    },
    registrations: [
      {
        registration_type: formData.person_registrations.registration_type,
        value: isCPF
          ? removeNonNumeric(formData.person_registrations.value)
          : formData.person_registrations.value,
      },
    ],
  };
  return apiData;
};

export const onSubmit = async (
  idiom,
  values,
  { setSubmitting },
  history,
  contextActions,
  customer_id,
  intl,
  existingUser,
  setExistingUser,
  submitButtonRef,
  trackEventUserAction,
  _userServices = userServices,
  _cardsServices = cardsServices,
  isUserPartner,
  stepCard
) => {
  const pan_number = removeNonNumeric(values.creditCard);
  const bin_number = pan_number.substring(0, 10);
  contextActions.loadingFullScreen.showLoadingFullScreen(
    intl.COMPLETING_YOUR_REGISTRATION
  );
  if (!isUserPartner) {
    try {
      trackEventUserAction(
        '#### (COMPLETE SEU CADASTRO) USUÁRIO CLICOU NO SUBMIT',
        {
          body: values,
        }
      );

      const data = formDataToApi(values);
      trackEventUserAction(
        '#### (COMPLETE SEU CADASTRO) USUÁRIO CLICOU NO SUBMIT - FORMATANDO PARA API',
        {
          body: data,
        }
      );

      if (!existingUser) {
        trackEventUserAction(
          '#### (COMPLETE SEU CADASTRO) USUÁRIO NÃO EXISTE - SERÁ CRIADO UM NOVO USUÁRIO',
          {
            body: data,
          }
        );
        await _userServices.createCHInfo(customer_id, data, idiom);
        if (NOTIFICATIONS_ENABLE && values.marketing_notification) {
          await _userServices.optInCommunication(
            customer_id,
            'MARKETING',
            'EMAIL'
          );
        }
        trackEventUserAction(
          '#### (COMPLETE SEU CADASTRO) USUÁRIO CRIADO COM SUCESSO',
          {
            body: data,
          }
        );
        setExistingUser(true);
      } else {
        let newData = { ...data };
        newData.is_politically_exposed = false;
        newData.monthly_income = {};
        newData.profession = '';
        trackEventUserAction(
          '#### (COMPLETE SEU CADASTRO) USUÁRIO JÁ EXISTE ',
          {
            body: newData,
          }
        );
        await _userServices.updateCHInfo(customer_id, newData, idiom);
      }
    } catch (err) {
      trackEventUserAction(
        '#### (COMPLETE SEU CADASTRO) ERRO AO CRIAR/ATUALIZAR USUÁRIO ',
        {
          error: err,
          error_response: err.response,
        }
      );
      if (
        err.response.data.error_description ===
        'Duplicated Customer Registration'
      ) {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.ERROR_CUSTOMER_DUPLICATED}
          />,
          true
        );
        contextActions.loadingFullScreen.hideLoadingFullScreen();
        return;
      } else if (
        err.response.data.error_description === 'Customer already exists'
      ) {
        setExistingUser(true);
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="success"
            message={intl.ERROR_ALREADY_EXISTS}
          />,
          true
        );
        contextActions.loadingFullScreen.hideLoadingFullScreen();
        return;
      } else {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_ERROR_CUSTOMER}
            textBtn={intl.TRY_AGAIN}
            clickBtn={() => {
              contextActions.modal.closeModal();
              submitButtonRef.current.click();
            }}
            textBtnDeny={intl.CANCEL}
            clickBtnDeny={() => {
              contextActions.modal.closeModal();
            }}
          />,
          true
        );
        if (
          err.response &&
          err.response.data &&
          err.response.data.error_description === 'BIN number not found'
        ) {
          trackEventUserAction(
            '#### (COMPLETE REGISTER NON VALID BIN) USER  ####',
            {
              error: err,
              response: err && err.response,
              data: err && err.response && err.response.data,
            }
          );
        }

        contextActions.loadingFullScreen.hideLoadingFullScreen();
        return;
      }
    }
  }

  let policy_id;
  if (stepCard === 'P') {
    try {
      trackEventUserAction(
        `#### (REGISTRATION) VAI TENTAR GERAR O TOKEN DO PAN`,
        { stepCard }
      );
      const token = await generatePanToken(trackEventUserAction, pan_number);
      trackEventUserAction(`#### (REGISTRATION) TOKEN DO PAN GERADO`, {
        token,
      });

      const cardInfo = {
        customer_id,
        external_reference: bin_number,
      };

      const effective_end_date = formatEffectiveEndDate(
        values.effective_end_date,
        trackEventUserAction
      );
      await tokenexService.addCardWithPan(
        cardInfo,
        token,
        effective_end_date,
        true
      );
      trackEventUserAction(`#### (REGISTRATION) UPDATED PAN`, {
        bin_number,
        pan_token: token,
        effective_end_date,
      });
      const cardsAfterUserComplet = (
        await cardsServices.getLoggedUserCards(customer_id)
      ).data;
      const selectedCard = cardsAfterUserComplet[0];
      trackEventUserAction(
        `#### (ADD CARD) ADDED CARD - SUCCESS - COUNTRY ${selectedCard.country}`,
        selectedCard.country
      );
      contextActions.loadingFullScreen.hideLoadingFullScreen();
    } catch (error) {
      trackEventUserAction(`#### (REGISTRATION) ERROR IN UPDATE PAN`, {
        error,
        message: error.message,
        error_response: error.response,
        data: error && error.response && error.response.data,
      });

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description === 'Duplicated Credit Card Token'
      ) {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.PAN_ERROR_OCCURRED}
            subtitle={intl.ALREADY_USED_ERROR_OCCURRED}
            textBtn={intl.TRY_AGAIN}
            clickBtn={() => {
              window.location.reload();
            }}
          />,
          true,
          true
        );
      } else if (
        error &&
        error.message === '2100 : Input data not Luhn compatible'
      ) {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.PAN_ERROR_OCCURRED}
            subtitle={intl.ALREADY_USED_ERROR_OCCURRED}
            textBtn={intl.TRY_AGAIN}
            clickBtn={() => {
              window.location.reload();
            }}
          />,
          true,
          true
        );
      } else {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_ERROR_BIN}
            textBtn={intl.TRY_AGAIN}
            clickBtn={() => {
              window.location.reload();
            }}
          />,
          true,
          true
        );
      }

      contextActions.loadingFullScreen.hideLoadingFullScreen();
      return;
    }
  } else {
    try {
      const bin = {
        bin: bin_number,
        is_preferred: true,
      };

      trackEventUserAction(`#### (REGISTRATION) VAI TENTAR INSERIR O BIN`, {
        stepCard,
        bin,
      });

      const { data } = await _cardsServices.addCard(customer_id, bin);
      const { policy_id: newPolicyId } = data;
      policy_id = newPolicyId;

      trackEventUserAction(
        `#### (REGISTRATION) COMPLETE REGISTRATION WITH CORRECT BIN - ${bin_number}`,
        null
      );
      const cardsAfterUserComplet = (
        await cardsServices.getLoggedUserCards(customer_id)
      ).data;
      const selectedCard = cardsAfterUserComplet[0];
      trackEventUserAction(
        `#### (ADD CARD) ADDED CARD - SUCCESS - COUNTRY ${selectedCard.country}`,
        selectedCard.country
      );
    } catch (error) {
      trackEventUserAction(
        `#### (REGISTRATION) COMPLETE REGISTRATION ERROR - INCOMPLETE# BIN - ${bin_number}`,
        { customer_id, bin_number, error }
      );

      if (
        error.response &&
        error.response.data &&
        error.response.data.error_description ==
          'Please enter a valid 16-digit card number'
      ) {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error_credit_card"
            message={intl.ENTER_VALID_16_DIGITS}
            textBtn={intl.TEXT_ERROR_TRY_AGAIN}
            clickBtn={() => contextActions.modal.closeModal()}
          />,
          true,
          true
        );
        contextActions.loadingFullScreen.hideLoadingFullScreen();
        return;
      }

      if (error.response && error.response.data) {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_CARD_NOT_ELIGIBLE}
          />,
          true
        );
      } else {
        contextActions.modal.showModal(
          false,
          true,
          <ModalInformation
            type="error"
            message={intl.TEXT_ERROR_BIN}
            textBtn={intl.TRY_AGAIN}
            clickBtn={() => {
              contextActions.modal.closeModal();
              submitButtonRef.current.click();
            }}
            textBtnDeny={intl.CANCEL}
            clickBtnDeny={() => {
              contextActions.modal.closeModal();
            }}
          />,
          true
        );
      }
      contextActions.loadingFullScreen.hideLoadingFullScreen();
      return;
    }
  }

  try {
    const partner_id = getCookie('partner_id');
    if (partner_id && pan_number) {
      const cardsAfterUserComplet = (
        await cardsServices.getLoggedUserCards(customer_id)
      ).data;
      try {
        const response =
          await productsAndBenefitsServices.getBenefitsDetailByProduct(
            pan_number,
            partner_id,
            idiom
          );
        setCookie('partner_id', '');
        if (response && response.status === 200) {
          const selectedCard = cardsAfterUserComplet[0];
          const country =
            selectedCard && countryNameToInitials(selectedCard.country);
          const idiom = countryToIdiom(country);
          let redeem = formatLinkWithIdiomAndCountry(
            response.data.redeem_info.redeem.url,
            idiom,
            country
          );
          if (redeem.length > 0) {
            window.location.href = redeem;
          } else {
            contextActions.cards.reloadLoggedUserCards();
          }
        } else {
          contextActions.cards.reloadLoggedUserCards();
        }
        trackEventUserAction(`#### (REGISTRATION) LOADED CARDS`, null);
      } catch (error) {
        trackEventUserAction(`#### (REGISTRATION) ERROR IN LOADED CARDS`, {
          error,
          error_response: error.response,
        });
        setCookie('partner_id', '');
        contextActions.cards.reloadLoggedUserCards();
      }
    } else {
      contextActions.cards.reloadLoggedUserCards();
    }
  } catch (error) {
    trackEventUserAction(`#### (REGISTRATION) ERROR IN LOGGED CARDS`, {
      error,
      error_response: error.response,
    });
    if (error.response && error.response) {
      contextActions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={error.response.data} />,
        true
      );
    } else {
      contextActions.modal.showModal(
        false,
        true,
        <ModalInformation type="error" message={intl.TEXT_ERROR} />,
        true
      );
    }
    contextActions.loadingFullScreen.hideLoadingFullScreen();
  }
  contextActions.loadingFullScreen.hideLoadingFullScreen();
  dataLayerSignUpTrack(customer_id);

  contextActions.modal.showModal(
    false,
    true,
    <ModalInformation
      type="success"
      message={intl.CONGRATULATIONS}
      textBtn={intl.BTN_ENJOY}
      clickBtn={() => {
        let redirect = sessionStorage.getItem('redirectAfterRegister');
        contextActions.modal.closeModal();
        if (redirect) {
          sessionStorage.removeItem('redirectAfterRegister');
          history.push(redirect);
        }
      }}
    />,
    true
  );
};

const RegisterStep2 = ({ isUserPartner }) => {
  const stepCompleteRegistration = isUserPartner ? 3 : 1;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [step, setStep] = useState(stepCompleteRegistration);
  const [loading, setLoading] = useState(true);
  const { utils } = useContext(StateContext);
  const cn = utils.getCn();
  const [chInfo, setChInfo] = useState();
  const [existingUser, setExistingUser] = useState(false);
  const submitButtonRef = useRef(null);
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();
  const { trackEventUserAction } = useContext(AppInsightTrackContext);

  useEffect(() => {
    const redirectAfterLogin = sessionStorage.getItem('redirectAfterLogin');

    if (!redirectAfterLogin) {
      sessionStorage.setItem('redirectAfterRegister', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    trackEventUserAction(`#### (REGISTRATION) CURRENT STEP`, { step });
  }, [step]);

  const searchForExistingChInfo = async cn => {
    try {
      setLoading(true);
      const { data } = await userServices.getCHInfo(cn);
      setChInfo(data);
      setExistingUser(true);
      setLoading(false);
    } catch (error) {
      trackEventUserAction(
        `#### (REGISTRATION) COMPLETE REGISTRATION ERROR IN EXISTING CHINFO`,
        { error, error_response: error.response }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    searchForExistingChInfo(cn);
  }, [cn]);

  return (
    <div className={`${styles.container} ${styles[themes]}`}>
      <SessionContainer>
        {!isUserPartner && (
          <>
            <HeadingTitle
              title={intl.TITLE_PAGE}
              subtitle={intl.TEXT_TITLE_REGISTER}
              noBack
            />
            <StepClaim currentStep={step} />
          </>
        )}

        {(loading || isUserPartner) && (
          <div className={styles.loadingContainer}>
            <Loading />
          </div>
        )}

        {!loading && !isUserPartner && (
          <div
            id="mainContent"
            className={`${styles.formWrapper} ${styles[themes]}`}
          >
            <Form
              isUserPartner={isUserPartner}
              onSubmit={onSubmit}
              setStep={setStep}
              currentStep={step}
              chInfo={chInfo}
              existingUser={existingUser}
              setExistingUser={setExistingUser}
              submitButtonRef={submitButtonRef}
            />
          </div>
        )}
      </SessionContainer>
    </div>
  );
};

export default RegisterStep2;
