export const TITLE_PERSONAL_DOCUMENTS = {
  en_US: 'Personal documents',
  es_ES: 'Documentos personales',
  pt_BR: 'Documentos pessoais',
};

export const SUBTITLE_PERSONAL_DOCUMENTS = {
  en_US:
    'Keep your profile up to date by sending your personal documents, so filling out your claims will be much simpler.',
  es_ES:
    'Mantén tu perfil actualizado enviando tus documentos personales, así llenar tus reclamos será mucho más sencillo.',
  pt_BR:
    'Mantenha seu perfil atualizado enviando seus documentos pessoais, assim ficará bem mais simples o preenchimento dos seus sinistros.',
};

export const DISCLAIMER_TEXT = {
  en_US: `Documents subject to analysis in the claims process.`,
  pt_BR: `Documentos sujeitos à análise durante o processo de sinistro.`,
  es_ES: `Documentos sujetos a análisis durante el proceso de reclamo.`,
};
