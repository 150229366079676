import { pt_currency } from '../../../../../../../../intl/currency/pt_currency';
import { es_currency } from '../../../../../../../../intl/currency/es_currency';
import { en_currency } from '../../../../../../../../intl/currency/en_currency';

export const LABEL_WITHDRAWN_AMOUNT = {
  en_US: 'Withdrawn amount *',
  es_ES: 'Cantidad retirada *',
  pt_BR: 'Valor retirado *',
};

export const LABEL_CURRENCY = {
  en_US: 'Currency *',
  es_ES: 'Moneda *',
  pt_BR: 'Moeda *',
};

export const LABEL_STOLEN_AMOUNT = {
  en_US: 'Stolen amount *',
  es_ES: 'Cantidad robada *',
  pt_BR: 'Valor roubado *',
};

export const TEXT_DECIMAL_SYMBOL = {
  en_US: 'Use . as decimal symbol.',
  es_ES: 'Utilice . como símbolo decimal.',
  pt_BR: 'Usar . como símbolo decimal.',
};

export const OPTIONS_CURRENCY = {
  en_US: en_currency,
  pt_BR: pt_currency,
  es_ES: es_currency,
};
