export const TEXT_NOTIFICATIONS = {
  en_US: 'Notifications',
  es_ES: 'Notificaciones',
  pt_BR: 'Notificações',
};

export const TEXT_SUBTITLE_NOTIFICATIONS = {
  en_US:
    'Please check your unread notifications for exciting surprises and updates.',
  es_ES:
    'Revisa tus notificaciones no leídas para ver si hay novedades y sorpresas emocionantes.',
  pt_BR:
    'Verifique suas notificações não lidas para novidades e surpresas emocionantes.',
};

export const TEXT_ARCHIVED = {
  en_US: 'Archived',
  es_ES: 'Archivadas',
  pt_BR: 'Arquivadas',
};

export const TEXT_EMPTY_NOTIFICATIONS = {
  en_US: 'You do not currently have any notification.',
  es_ES: 'Actualmente no tienes ninguna notificación.',
  pt_BR: 'No momento, você não tem nenhuma notificação.',
};

export const TEXT_EMPTY_NOTIFICATIONS_ARCHIVED = {
  en_US: 'You do not currently have any archived notification.',
  es_ES: 'Actualmente no tienes ninguna notificación archivada.',
  pt_BR: 'No momento, você não tem nenhuma notificação arquivada.',
};

export const TEXT_SEE_MORE = {
  en_US: 'See more',
  es_ES: 'Ver más',
  pt_BR: 'Ver mais',
};

export const UPDATE_LIST_NOTIFICATION = {
  en_US: 'Updating notification list',
  es_ES: 'Actualizando la lista de notificaciones',
  pt_BR: 'Atualizando lista de notificações',
};
