export const es_mx_nationalities = `
<option value="AF">Afganistán</option>
 <option value="AL">Albania</option>
 <option value="DE">Alemania</option>
 <option value="AD">Andorra</option>
 <option value="AO">Angola</option>
 <option value="AI">Anguila</option>
 <option value="AQ">Antártida</option>
 <option value="AG">Antigua Barbuda</option>
 <option value="SA">Arabia Saudita</option>
 <option value="DZ">Argelia</option>
 <option value="AR">Argentina</option>
 <option value="AM">Armenia</option>
 <option value="AW">Aruba</option>
 <option value="AU">Australia</option>
 <option value="AT">Austria</option>
 <option value="AZ">Azerbaiyán</option>
 <option value="BS">Bahamas</option>
 <option value="BH">Bahrein</option>
 <option value="BD">Bangladesh</option>
 <option value="BB">Barbados</option>
 <option value="BE">Bélgica</option>
 <option value="BZ">Belice</option>
 <option value="BJ">Benin</option>
 <option value="BY">Bielorrusia</option>
 <option value="BO">Bolivia</option>
 <option value="BA">Bosnia y Herzegovina</option>
 <option value="BW">Botswana</option>
 <option value="BR">Brasil</option>
 <option value="BN">Brunei Darussalam</option>
 <option value="BG">Bulgaria</option>
 <option value="BF">Burkina Faso</option>
 <option value="BI">Burundi</option>
 <option value="BT">Bután</option>
 <option value="CV">Cabo verde</option>
 <option value="KH">Camboya</option>
 <option value="CM">Camerún</option>
 <option value="CA">Canadá</option>
 <option value="TD">Chad</option>
 <option value="CL">Chile</option>
 <option value="CN">China</option>
 <option value="CY">Chipre</option>
 <option value="VA">Ciudad del Vaticano</option>
 <option value="CO">Colombia</option>
 <option value="KM">Comoros</option>
 <option value="CG">Congo</option>
 <option value="KR">Corea</option>
 <option value="CI">Costa de Marfil</option>
 <option value="CR">Costa Rica</option>
 <option value="HR">Croacia</option>
 <option value="CW">Curazao</option>
 <option value="DK">Dinamarca</option>
 <option value="DJ">Djibouti</option>
 <option value="DM">Dominica</option>
 <option value="EC">Ecuador</option>
 <option value="EG">Egipto</option>
 <option value="SV">El Salvador</option>
 <option value="AE">Emiratos Árabes Unidos</option>
 <option value="ER">Eritrea</option>
 <option value="SK">Eslovaquia</option>
 <option value="SI">Eslovenia</option>
 <option value="ES">España</option>
 <option value="US">Estados Unidos</option>
 <option value="EE">Estonia</option>
 <option value="ET">Etiopía</option>
 <option value="FJ">Fiji</option>
 <option value="PH">Filipinas</option>
 <option value="FI">Finlandia</option>
 <option value="FR">Francia</option>
 <option value="GA">Gabón</option>
 <option value="GM">Gambia</option>
 <option value="GE">Georgia</option>
 <option value="GS">Georgia del Sur e islas Sandwich</option>
 <option value="GH">Ghana</option>
 <option value="GI">Gibraltar</option>
 <option value="GD">Granada</option>
 <option value="GR">Grecia</option>
 <option value="GL">Groenlandia</option>
 <option value="GP">Guadeloupe</option>
 <option value="GU">Guam</option>
 <option value="GT">Guatemala</option>
 <option value="GG">Guernsey</option>
 <option value="GN">Guinea</option>
 <option value="GQ">Guinea Ecuatorial</option>
 <option value="GW">Guinea-Bissau</option>
 <option value="GY">Guyana</option>
 <option value="GF">Guyana Francesa</option>
 <option value="HT">Haití</option>
 <option value="HN">Honduras</option>
 <option value="HK">Hong Kong</option>
 <option value="HU">Hungría</option>
 <option value="IN">India</option>
 <option value="ID">Indonesia</option>
 <option value="IQ">Irak</option>
 <option value="IE">Irlanda</option>
 <option value="BV">Isla Bouvet</option>
 <option value="IM">Isla del hombre</option>
 <option value="NF">Isla Norfolok</option>
 <option value="IS">Islandia</option>
 <option value="SB">Islas Salomón</option>
 <option value="AX">Islas Aland</option>
 <option value="BM">Islas Bermudas</option>
 <option value="KY">Islas Caimán</option>
 <option value="CC">Islas Coco</option>
 <option value="CK">Islas cook</option>
 <option value="FO">Islas Faroe</option>
 <option value="HM">Islas Heard y Mcdonald</option>
 <option value="FK">Islas Malvinas</option>
 <option value="MP">Islas Marianas del Norte</option>
 <option value="MH">Islas Marshall</option>
 <option value="CX">Islas Navidad</option>
 <option value="TC">Islas Turcas y Caicos</option>
 <option value="VI">Islas Vírgenes</option>
 <option value="VG">Islas Vírgenes Británicas</option>
 <option value="IL">Israel</option>
 <option value="IT">Italia</option>
 <option value="JM">Jamaica</option>
 <option value="JP">Japón</option>
 <option value="JE">Jersey</option>
 <option value="JO">Jordán</option>
 <option value="QA">Katar</option>
 <option value="KZ">Kazajstán</option>
 <option value="KE">Kenia</option>
 <option value="KG">Kirguistán</option>
 <option value="KI">Kiribati</option>
 <option value="KW">Kuwait</option>
 <option value="LS">Lesoto</option>
 <option value="LV">Letonia</option>
 <option value="LB">Líbano</option>
 <option value="LR">Liberia</option>
 <option value="LY">Libia</option>
 <option value="LI">Lienchtenstein</option>
 <option value="LT">Lituania</option>
 <option value="LU">Luxemburgo</option>
 <option value="MO">Macao</option>
 <option value="MG">Madagascar</option>
 <option value="MY">Malasia</option>
 <option value="MW">Malawi</option>
 <option value="MV">Maldivas</option>
 <option value="ML">Malí</option>
 <option value="MT">Malta</option>
 <option value="MA">Marruecos</option>
 <option value="MQ">Martinica</option>
 <option value="MU">Mauricio</option>
 <option value="MR">Mauritania</option>
 <option value="YT">Mayotte</option>
 <option value="MX">México</option>
 <option value="FM">Micronesia</option>
 <option value="MD">Moldavia</option>
 <option value="MC">Mónaco</option>
 <option value="MN">Mongolia</option>
 <option value="ME">Montenegro</option>
 <option value="MS">Montserrat</option>
 <option value="MZ">Mozambique</option>
 <option value="MM">Myanmar</option>
 <option value="NA">Namibia</option>
 <option value="NR">Nauru</option>
 <option value="NP">Nepal</option>
 <option value="NI">Nicaragua</option>
 <option value="NE">Níger</option>
 <option value="NG">Nigeria</option>
 <option value="NU">Niue</option>
 <option value="NO">Noruega</option>
 <option value="NC">Nueva Caledonia</option>
 <option value="NZ">Nueva zelanda</option>
 <option value="OM">Omán</option>
 <option value="NL">Países bajos</option>
 <option value="PK">Pakistán</option>
 <option value="PW">Palau</option>
 <option value="PS">Palestino</option>
 <option value="PA">Panamá</option>
 <option value="PG">Papúa Nueva Guinea</option>
 <option value="PY">Paraguay</option>
 <option value="PE">Perú</option>
 <option value="PN">Pitcarin</option>
 <option value="PF">Polinesia Francesa</option>
 <option value="PL">Polonia</option>
 <option value="PT">Portugal</option>
 <option value="PR">Puerto Rico</option>
 <option value="CF">Republica centroafricana</option>
 <option value="CZ">República Checa</option>
 <option value="MK">República de Macedonia</option>
 <option value="CD">República democrática del congo</option>
 <option value="LA">República democrática Popular de Lao</option>
 <option value="DO">República Dominicana</option>
 <option value="GB">Reunio Unido</option>
 <option value="RW">Ruanda</option>
 <option value="RO">Rumania</option>
 <option value="RU">Rusia</option>
 <option value="EH">Sahara Occidental</option>
 <option value="AS">Samoa Americana</option>
 <option value="BL">San Bartolomé</option>
 <option value="BQ">San Eustaquio</option>
 <option value="KN">San Cristobal y Nieves</option>
 <option value="SM">San Marino</option>
 <option value="MF">San Martín</option>
 <option value="PM">San Pedro y Miquelón</option>
 <option value="VC">San Vicente y las Granadinas</option>
 <option value="SH">Santa Helena, Asención y Tristán da Cunha</option>
 <option value="LC">Santa Lucía</option>
 <option value="ST">Santo Tomé y Principe</option>
 <option value="SN">Senegal</option>
 <option value="RS">Serbia</option>
 <option value="SC">Seychelles</option>
 <option value="SL">Sierra Leona</option>
 <option value="SG">Singapour</option>
 <option value="SX">Sint Maarten</option>
 <option value="SO">Somalia</option>
 <option value="LK">Sri Lanka</option>
 <option value="SZ">Suazilandia</option>
 <option value="ZA">Sudáfrica</option>
 <option value="SE">Suecia</option>
 <option value="CH">Suiza</option>
 <option value="SR">Surinam</option>
 <option value="SJ">Svalbard y Jan Mayen</option>
 <option value="TH">Tailandia</option>
 <option value="TW">Taiwan</option>
 <option value="TJ">Tajikstán</option>
 <option value="TZ">Tanzania</option>
 <option value="IO">Territorio Británico del océano Índico</option>
 <option value="TF">Territorios Franceses del Sur</option>
 <option value="TL">Timor-Leste</option>
 <option value="TG">Togo</option>
 <option value="TK">Tokelau</option>
 <option value="TO">Tonga</option>
 <option value="TT">Trinidad y Tobago</option>
 <option value="TN">Túnez</option>
 <option value="TM">Turkmenistán</option>
 <option value="TR">Turquía</option>
 <option value="TV">Tuvalu</option>
 <option value="UA">Ucrania</option>
 <option value="UG">Uganda</option>
 <option value="UY">Uruguay</option>
 <option value="UZ">Uzbekistán</option>
 <option value="VU">Vanuatu</option>
 <option value="VN">Vietnam</option>
 <option value="WF">Wallis y Futuna</option>
 <option value="YE">Yemen</option>
 <option value="ZM">Zambia</option>
 <option value="ZW">Zimbabue</option>`;
