import React, { useContext, useEffect } from 'react';
import styles from '../ExpenseArea.module.scss';
import { IntlContext } from '../../../../../../../../../intl';
import * as translations from '../intl';
import Input from '../../../../../../../../../components/Input/Input';
import Select from '../../../../../../../../../components/Select/Select';
import { getCurrencyByHomeCountry } from '../../../../../../../../../utils/currency';
import sanitize from '../../../../../../../../../utils/sanitize';

const ExpenseAreaTotal = props => {
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { values, touched, errors, setFieldValue, handleChange, handleBlur } =
    props;
  let damegaValue = values.amount_estimation_damage.value;

  const setDefaultCurrency = event => {
    let expenses = [...values.expenses];
    expenses.map(expense => (expense.price.currency = event.target.value));

    setFieldValue('expense_currency', event.target.value);
    setFieldValue('amount_estimation_damage.currency', event.target.value);
    setFieldValue('expenses', expenses);
  };

  useEffect(() => {
    const getCurrency = getCurrencyByHomeCountry();
    if (getCurrency) setCurrency(getCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCurrency = currency => {
    const event = { target: { value: currency } };
    setDefaultCurrency(event);
  };

  return (
    <div className={`row ${styles.totalArea}`}>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Input
          value={damegaValue ? damegaValue.toFixed(2) : damegaValue}
          label={intl.LABEL_TOTAL_REIMBURSED}
          name="amount_estimation_damage.value"
          dataTestid="InputAmoutEstimationDamageValue"
          type="text"
          disabled
          maskedMoney
          onChange={handleChange}
          touched={
            touched.amount_estimation_damage &&
            touched.amount_estimation_damage.value
          }
          onBlur={handleBlur}
          error={
            errors.amount_estimation_damage &&
            errors.amount_estimation_damage.value
          }
        />
      </div>
      <div className={`col-12 col-sm-12 col-md-6`}>
        <Select
          dataTestid="selectAmoutEstimationDamageCurrency"
          label={intl.LABEL_CURRENCY}
          value={values.amount_estimation_damage.currency}
          name="amount_estimation_damage.currency"
          onChange={event => {
            setDefaultCurrency(event);
            handleChange(event);
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              `<option value="">${intl.LABEL_CURRENCY}</option> ${intl.OPTIONS_CURRENCY}`
            ),
          }}
          touched={
            touched.amount_estimation_damage &&
            touched.amount_estimation_damage.currency
          }
          onBlur={handleBlur}
          error={
            errors.amount_estimation_damage &&
            errors.amount_estimation_damage.currency
          }
        />
      </div>
    </div>
  );
};

export default ExpenseAreaTotal;
