import { useState, useContext } from 'react';
import { productsAndBenefitsServices } from '../../../../services';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import styles from './ListCrossItem.module.scss';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Loading from '../../../Loading/Loading';
import ButtonBenefits from './ButtonBenefits/ButtonBenefits';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { sanitize } from 'dompurify';
import ChevronGeneric from '../../../../assets/icons/ChevronGeneric';

const ListCardItem = props => {
  const { idiomForApi, idiom, country, translate } = useContext(IntlContext);
  const intl = translate(translations);
  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [benefitDetails, setBenefitDetails] = useState([]);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const { benefit, name, category, images, type, productBinOrPan = '' } = props;
  const image = images.find(image => image.type === 'ICON');

  useEffect(() => {
    setShowContent(false);
  }, [benefit]);

  const setVisualization = async status => {
    setShowContent(status);
  };

  const showDescriptionBenefits = async () => {
    try {
      setVisualization(true);
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          productBinOrPan,
          benefit.benefit_id,
          idiomForApi(),
          false
        );
      setBenefitDetails(htmlContent(response.data));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const htmlContent = data => {
    return (
      <>
        <p
          dangerouslySetInnerHTML={{ __html: sanitize(data.short_description) }}
        />
        <span className={styles.chevronWrapper}>
          <button type="button" onClick={() => setVisualization(false)}>
            <ChevronGeneric
              rotate={180}
              aria-label={intl.IMAGE_ARROW}
              width={20}
              height={20}
            />
          </button>
        </span>
        <div className={`${styles[theme]} ${styles.actions}`}>
          <ButtonBenefits benefit={data} idiom={idiom} country={country} />
        </div>
      </>
    );
  };

  return (
    <div className={`col-12 ${styles.benefitItem}`} data-testid="ListCardItem">
      <div
        className={`${styles[theme]} ${styles.item} ${
          showContent ? styles.absolute : ''
        }`}
      >
        <div className={styles.top}>
          <div className={`${styles[theme]} ${styles.category}`}>
            {category}
          </div>
          <div className={styles.Icon}>
            <img
              src={image.content_url}
              width={50}
              alt={intl.BENEFIT_ALT(benefit && benefit.name)}
            />
          </div>
        </div>
        <div className={styles.center}>
          <h6 className={styles[theme]}>{name}</h6>
        </div>
        <div
          aria-label={intl.BENEFIT_DETAILS}
          className={`${styles.content} ${!showContent ? styles.none : ''}`}
        >
          {type === 'benefits' && <>{loading ? <Loading /> : benefitDetails}</>}
        </div>
        <div className={`${styles.bottom} ${showContent ? styles.none : ''}`}>
          <div className={`${styles[theme]} ${styles.actions}`}>
            <button
              type="button"
              onClick={() => {
                if (type === 'benefits') {
                  showDescriptionBenefits();
                } else {
                  setVisualization(true);
                }
              }}
              aria-label={intl.MORE_DETAILS}
              data-testid="showmore"
            >
              <span className={styles.iconChevron}>
                <ChevronGeneric
                  rotate={0}
                  aria-label={intl.IMAGE_ARROW}
                  width={20}
                  height={20}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ListCardItem);
