export const SUMMARY = {
  en_US: 'Summary',
  pt_BR: 'Resumo',
  es_ES: 'Resumen',
};

export const PRODUCT = {
  en_US: 'Product',
  pt_BR: 'Produto',
  es_ES: 'Producto',
};

export const TOTAL_TO_PAY = {
  en_US: 'Total to pay',
  pt_BR: 'Total a pagar',
  es_ES: 'Total a pagar',
};

export const VALIDITY = {
  en_US: 'Validity',
  pt_BR: 'Vigência',
  es_ES: 'Validez',
};

export const PASSENGERS_NUMBER = {
  en_US: 'Passengers number',
  pt_BR: 'Número de passageiros',
  es_ES: 'Número de pasajeros',
};

export const TEXT_VALUE_IS_FOR_ALL = {
  en_US: 'This value is for all passengers.',
  pt_BR: 'Este valor é para todos os passageiros.',
  es_ES: 'Este precio cubre a todos los pasajeros.',
};
