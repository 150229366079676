import React, { useContext, useEffect, useState } from 'react';
import styles from './PublicListCards.module.scss';
import CardItem from './CardItem/CardItem';
import cardImg from '../../assets/images/cards_2.svg';
import { IntlContext } from '../../intl';
import * as translations from './intl';
import { productsAndBenefitsServices } from '../../services';
import Loading from '../Loading/Loading';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import ChevronGeneric from '../../assets/icons/ChevronGeneric';
import { ENABLE_CROSS_SELLING } from '../../utils/environments_variables';
import { ELIGIBLE_CARDS_UPSELLING } from '../../utils/verifyAccess';

function ListCards({ cardSelected, setCardSelected }) {
  const { country, translate, idiomForApi } = useContext(IntlContext);
  const intl = translate(translations);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [cardImage, setCardImage] = useState(cardImg);
  const [cardName, setCardName] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  async function handleLoadProducts() {
    try {
      setLoading(true);

      const { data } = await productsAndBenefitsServices.getProductsByCountry(
        country,
        idiomForApi()
      );

      let cardFilter = data.filter(card => card.is_default === 1);

      if (
        window.location.pathname.includes('upsell') &&
        !ENABLE_CROSS_SELLING
      ) {
        cardFilter = cardFilter.filter(card =>
          ELIGIBLE_CARDS_UPSELLING.includes(card.name.toUpperCase())
        );
      }

      const findProduct = cardFilter.find(
        product => product.product_id === cardSelected.product_id
      );

      let imageCard;
      if (cardSelected && cardSelected.product_id && findProduct) {
        setCardName(cardSelected.name);
        imageCard = findProduct.images.find(image => image.type === 'PICTURE');
      } else {
        let newCardSelected = cardFilter[0];
        if (window.location.pathname.includes('cross-sell')) {
          newCardSelected = cardFilter.find(card => card.name === 'Gold');
        }

        setCardSelected(newCardSelected || {});
        setCardName(newCardSelected.name);
        imageCard = newCardSelected.images.find(
          image => image.type === 'PICTURE'
        );
      }

      setCardImage(imageCard.content_url);
      setProducts(cardFilter);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setCardSelected({});
    }
  }

  function handleCardSelected(index) {
    const selectedCard = products[index];
    const imageCard = selectedCard.images.find(
      image => image.type === 'PICTURE'
    );

    setOpenSubMenu(false);
    setCardSelected(selectedCard);
    setCardName(selectedCard.name);
    setCardImage(imageCard.content_url);
  }

  function handleRenderCards() {
    return products.map((card, index) => {
      return (
        <CardItem
          key={`${card.policy_id}-${index}`}
          index={index}
          card={card}
          isSelected={card.name === cardSelected.name}
          onCardSelected={handleCardSelected}
        />
      );
    });
  }

  function handleMakeCards() {
    return (
      <div className={styles.openListCards}>
        <button
          onClick={() => {
            setOpenSubMenu(!openSubMenu);
          }}
          type="button"
          className={`${styles.buttonCards} ${styles[theme]}`}
          data-testid="btnCards"
          aria-label={intl.TEXT_MY_CARDS}
          tabIndex={0}
        >
          <img src={cardImage} alt={intl.TEXT_MY_CARDS} />
          <span className={styles.name}>{cardName}</span>
          <span className={styles.icon}>
            <ChevronGeneric
              rotate={!openSubMenu ? 0 : 180}
              width={20}
              height={20}
              data-testid="iconChevron"
              aria-label={intl.ICON_OPEN_MENU_COUNTRIES_LIST}
            />
          </span>
        </button>
        {openSubMenu && (
          <div
            aria-label={intl.LIST_CARDS}
            className={`${styles[theme]} ${styles.listCards}`}
            role="dialog"
          >
            <ul>{products && products.length > 0 && handleRenderCards()}</ul>
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    handleLoadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  return (
    <div className={styles.boxListCards}>
      {loading ? <Loading /> : handleMakeCards()}
    </div>
  );
}

export default ListCards;
