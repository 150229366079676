import React, { useContext } from 'react';

import { IntlContext } from '../../../intl';
import * as translations from './intl';
import styles from './ExploreBenefits.module.scss';
import concierge from '../../../assets/images/home/Concierge.png';
import lifestyle from '../../../assets/images/home/Lifestyle.png';
import protection from '../../../assets/images/home/Purchase.png';
import travel from '../../../assets/images/home/Travel.jpg';
import { Link } from 'react-router-dom';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { dataLayerContentMenuTrack } from '../../../utils/GTM_helper';

const ExploreBenefits = props => {
  const { productActive } = props;
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const benefitLink = `/benefits/${productActive && productActive.product_id}`;
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();

  return (
    <section className={`${styles.exploreBenefits} ${styles[theme]}`}>
      <div className={`container-fluid ${styles.exploreBenefits}`}>
        <div className={styles.center}>
          <div className="row">
            <div className={`col-sm-4 col-12 ${styles.left}`}>
              <span className={styles[theme]}>{intl.TEXT_BENEFITS}</span>
              <h2 className={styles[theme]}>{intl.TEXT_EXPLORE}</h2>
            </div>
            <div className={`col-sm-8 col-12 ${styles.right}`}>
              <div className={`row no-gutters ${styles.liveDesktop}`}>
                <div className={`col col-sm-6 ${styles.linkContainer}`}>
                  <Link
                    to={`${benefitLink}#travel_benefits`}
                    onClick={() =>
                      dataLayerContentMenuTrack(
                        'CTA Travel',
                        'Home Benefits Card'
                      )
                    }
                  >
                    <img
                      className={`img-fluid ${styles.image}`}
                      src={travel}
                      alt={intl.TITLE_TRAVEL}
                      loading="lazy"
                    />
                    <span className={styles[theme]}>{intl.TITLE_TRAVEL}</span>
                    <div className={styles.blackCover}></div>
                  </Link>
                </div>
                <div className={`col col-sm-6 ${styles.linkContainer}`}>
                  <Link
                    to={`${benefitLink}#purchase_protection`}
                    onClick={() =>
                      dataLayerContentMenuTrack(
                        'CTA Purchase',
                        'Home Benefits Card'
                      )
                    }
                  >
                    <img
                      className={`img-fluid ${styles.image}`}
                      src={protection}
                      alt={intl.TITLE_PURCHASE_PROTECTION}
                      loading="lazy"
                    />
                    <span className={styles[theme]}>
                      {intl.TITLE_PURCHASE_PROTECTION}
                    </span>
                    <div className={styles.blackCover}></div>
                  </Link>
                </div>
                <div className={`col col-sm-6 ${styles.linkContainer}`}>
                  <Link
                    to={`${benefitLink}#lifestyle`}
                    onClick={() =>
                      dataLayerContentMenuTrack(
                        'CTA Emergency',
                        'Home Benefits Card'
                      )
                    }
                  >
                    <img
                      className={`img-fluid ${styles.image}`}
                      src={lifestyle}
                      alt={intl.TITLE_LIFESTYLE}
                      loading="lazy"
                    />
                    <span className={styles[theme]}>
                      {intl.TITLE_LIFESTYLE}
                    </span>
                    <div className={styles.blackCover}></div>
                  </Link>
                </div>
                <div className={`col col-sm-6 ${styles.linkContainer}`}>
                  <Link
                    to={`${benefitLink}#concierge`}
                    onClick={() =>
                      dataLayerContentMenuTrack(
                        'CTA Concierge',
                        'Home Benefits Card'
                      )
                    }
                  >
                    <img
                      className={`img-fluid ${styles.image}`}
                      src={concierge}
                      alt={intl.TITLE_LIFESTYLE}
                      loading="lazy"
                    />
                    <span className={styles[theme]}>
                      {intl.TITLE_CONCIERGE}
                    </span>
                    <div className={styles.blackCover}></div>
                  </Link>
                </div>
              </div>

              <div className={styles.liveMobile}>
                <div className="row flex-nowrap">
                  <div className={`col-9 ${styles.scrollBox}`}>
                    <Link
                      to={`${benefitLink}#travel_benefits`}
                      onClick={() =>
                        dataLayerContentMenuTrack(
                          'CTA Travel',
                          'Home Benefits Card'
                        )
                      }
                    >
                      <img
                        className="img-fluid"
                        src={travel}
                        alt={intl.TITLE_TRAVEL}
                        loading="lazy"
                      />
                      <span className={styles[theme]}>{intl.TITLE_TRAVEL}</span>
                      <div className={styles.blackCover}></div>
                    </Link>
                  </div>
                  <div className={`col-9 ${styles.scrollBox}`}>
                    <Link
                      to={`${benefitLink}#lifestyle`}
                      onClick={() =>
                        dataLayerContentMenuTrack(
                          'CTA Emergency',
                          'Home Benefits Card'
                        )
                      }
                    >
                      <img
                        className="img-fluid"
                        src={lifestyle}
                        alt={intl.TITLE_LIFESTYLE}
                        loading="lazy"
                      />
                      <span className={styles[theme]}>
                        {intl.TITLE_LIFESTYLE}
                      </span>
                      <div className={styles.blackCover}></div>
                    </Link>
                  </div>
                  <div className={`col-9 ${styles.scrollBox}`}>
                    <Link
                      to={`${benefitLink}#purchase_protection`}
                      onClick={() =>
                        dataLayerContentMenuTrack(
                          'CTA Purchase',
                          'Home Benefits Card'
                        )
                      }
                    >
                      <img
                        className="img-fluid"
                        src={protection}
                        alt={intl.TITLE_PURCHASE_PROTECTION}
                        loading="lazy"
                      />
                      <span className={styles[theme]}>
                        {intl.TITLE_PURCHASE_PROTECTION}
                      </span>
                      <div className={styles.blackCover}></div>
                    </Link>
                  </div>
                  <div className={`col-9 ${styles.scrollBox}`}>
                    <Link
                      to={`${benefitLink}#concierge`}
                      onClick={() =>
                        dataLayerContentMenuTrack(
                          'CTA Concierge',
                          'Home Benefits Card'
                        )
                      }
                    >
                      <img
                        className="img-fluid"
                        src={concierge}
                        alt={intl.TITLE_CONCIERGE}
                        loading="lazy"
                      />
                      <span className={styles[theme]}>
                        {intl.TITLE_CONCIERGE}
                      </span>
                      <div className={styles.blackCover}></div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExploreBenefits;
