export const formatReimbursmentDataV2 = data => {
  let account_codes = getWireCodes(data.beneficiary_account);

  // Intermediary Bank
  let IntermediaryBankAccount = {};
  if (data.intermediary_account) {
    let account_codes_inter = getWireCodes(data.intermediary_account);
    const interAccount = data.intermediary_account;

    IntermediaryBankAccount = {
      name: interAccount.bank.name,
      branch_code: '',
      bank_code: '',
      bank_account_number: interAccount.account_number,
      account_type: interAccount.account_type || 'CHECKING', // TODO missing field
      address: interAccount.bank.address ? interAccount.bank.address : {},
    };

    /*if (
      interAccount.preferred_transfer_type === 'CLABE' ||
      interAccount.preferred_transfer_type === 'FULL_KEY'
    ) {
      account_codes_inter.push({
        wire_code_type: 'FULL_KEY',
        value: interAccount.routing_number
      });
    }

    if (
      interAccount.preferred_transfer_type === 'ABA' ||
      interAccount.preferred_transfer_type === 'ABA_RTN'
    ) {
      account_codes_inter.push({
        wire_code_type: 'ABA',
        value: interAccount.routing_number
      });
    }

    if (interAccount.preferred_transfer_type === 'IBAN') {
      account_codes_inter.push({
        wire_code_type: 'IBAN',
        value: interAccount.routing_number
      });
    }

    if (interAccount.bank_identification_number) {
      account_codes_inter.push({
        wire_code_type: 'SWIFT',
        value: interAccount.bank_identification_number
      });
    }*/

    IntermediaryBankAccount.account_codes = account_codes_inter;
    IntermediaryBankAccount.bankIntermediaryView = 'yes';
  }

  const bank = {
    name: data.beneficiary_account.bank.name,
    branch_code: data.beneficiary_account.bank.branch_code,
    bank_code: data.beneficiary_account.bank.bank_code,
    bank_account_number: data.beneficiary_account.account_number,
    address: data.beneficiary_account.bank.address,
  };

  const BankAccountHolder = data.beneficiary_account.holder
    ? data.beneficiary_account.holder
    : {};

  return {
    bank,
    is_current_account: data.beneficiary_account.is_current_account,
    account_type: data.beneficiary_account.account_type,
    account_codes,
    IntermediaryBankAccount,
    BankAccountHolder,
    //bank_account_id: reimbursement_id
  };
};

const getWireCodes = data => {
  let account_codes = [];

  if (
    data.preferred_transfer_type === 'CLABE' ||
    data.preferred_transfer_type === 'FULL_KEY'
  ) {
    account_codes.push({
      wire_code_type: 'FULL_KEY',
      value: data.routing_number,
    });
  }

  if (
    data.preferred_transfer_type === 'ABA' ||
    data.preferred_transfer_type === 'ABA_RTN'
  ) {
    account_codes.push({
      wire_code_type: 'ABA',
      value: data.routing_number,
    });
  }

  if (data.preferred_transfer_type === 'IBAN') {
    account_codes.push({
      wire_code_type: 'IBAN',
      value: data.routing_number,
    });
  }

  if (data.bank_identification_number) {
    account_codes.push({
      wire_code_type: 'SWIFT',
      value: data.bank_identification_number,
    });
  }

  return account_codes;
};
