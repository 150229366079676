export const pairsFromUri = () => {
  const queryString = window.location.search;
  if (queryString.length === 0) return;
  const args = queryString.substring(1);
  const pairs = {};
  args.split('&').map(arg => {
    const splited = arg.split('=');
    pairs[splited[0]] = splited[1];
  });
  return pairs;
};

export const getQueryVariable = variable => {
  if (window && window.location && window.location.search) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] === variable) {
        return pair[1];
      }
    }
  }
  return false;
};
