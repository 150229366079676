import React, { useContext, useEffect, useState } from 'react';

import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import { productsAndBenefitsServices } from '../../../../services';
import styles from './Cards.module.scss';
import Card from './Card/Card';
import Loading from '../../../../components/Loading/Loading';

const Cards = props => {
  const { idiomForApi, country } = useContext(IntlContext);
  const { translate } = useContext(IntlContext);
  const intl = translate(translations);
  const { selected, clientType } = props;
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);

  const getProducts = async () => {
    try {
      const res = await productsAndBenefitsServices.getProductsByCountry(
        country,
        idiomForApi(),
        false
      );
      if (res.data.length > 0) {
        setProducts(res.data);
      }
    } catch (error) {
      setProducts('ERROR');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idiomForApi, country]);

  const mapCards = products => {
    if (products.length === 0) {
      return (
        <h2 className={styles.emptyProducts}>{intl.TEXT_EMPTY_PRODUCTS}</h2>
      );
    }

    return products.map((card, index) => {
      return <Card product={card} key={`card_${index}`} />;
    });
  };

  const filterCards = (type, client_type) => {
    return products.filter(card => {
      return (
        (type === 'ALL' && card.client_type === client_type) ||
        (card.type === type && card.client_type === client_type)
      );
    });
  };

  return (
    <div className={`col-12 col-lg-9 ${styles.cards}`} data-testid="cards">
      <div className={`row ${styles.list}`}>
        {loading && (
          <div className={styles.loadingContainer} data-testid="cardsLoading">
            <Loading />
          </div>
        )}
        {!loading &&
          products.length > 0 &&
          mapCards(filterCards(selected, clientType))}
      </div>
    </div>
  );
};

export default Cards;
