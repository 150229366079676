export const TEXT_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete',
};

export const TEXT_OPEN_A_CLAIM = {
  en_US: 'Open a claim',
  es_ES: 'Crear un reclamo',
  pt_BR: 'Abrir um sinistro',
};

export const TEXT_CREATION_DATE = {
  en_US: 'Creation date',
  es_ES: 'Fecha de creación',
  pt_BR: 'Data de criação',
};

export const TEXT_MY_DASHBOARD = {
  en_US: 'My dashboard',
  es_ES: 'Mi panel',
  pt_BR: 'Meu painel',
};

export const ADD_CERTIFICATE = {
  en_US: 'Add Certificate',
  es_ES: 'Agregar Certificado',
  pt_BR: 'Adicionar Certificado',
};
