/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import moment from 'moment';
import * as constants from './consts';

export const tripCancellationSchema = intl => {
  return Yup.object().shape({
    affected_person: Yup.object().shape({
      relationshipCardholder: Yup.bool().oneOf(
        [true],
        intl.TEXT_FIELD_REQUIRED
      ),
      person: Yup.object().shape({
        first_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),

    trip_information: Yup.lazy(value => {
      return Yup.object().shape({
        planed_trip: Yup.object().shape({
          departure_date: Yup.string()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID)
            .test(
              'test interruption date',
              intl.VALIDATE_DATE_BEFORE_DEPARTURE_DATE,
              function (date) {
                return value.interruption_date
                  ? moment(date).isSameOrAfter(value.interruption_date)
                  : true;
              }
            ),
          arrival_date: Yup.mixed()
            .required(intl.VALIDATE_DATE_VALID)
            .typeError(intl.VALIDATE_DATE_VALID)
            .test('match', intl.VALIDATE_DATE_VALID, function (date) {
              return moment(date).isSameOrAfter(this.parent.departure_date);
            }),
        }),
        interruption_date: Yup.string()
          .required(intl.VALIDATE_DATE_VALID)
          .typeError(intl.VALIDATE_DATE_VALID)
          .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
            return moment(date).isValid();
          }),
        reason: Yup.string().max(255, intl.TEXT_FIELD_VALIDATION_255),
      });
    }),

    incident: Yup.object().shape({
      start_date: Yup.string()
        .required(intl.VALIDATE_DATE_VALID)
        .typeError(intl.VALIDATE_DATE_VALID)
        .test('test-data-valid', intl.VALIDATE_DATE_VALID, function (date) {
          return moment(date).isValid();
        }),
    }),
    additional_costs_transport: Yup.object().shape({
      price: Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        currencyValue: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
    }),

    additional_costs_accomodation: Yup.object().shape({
      price: Yup.object().shape({
        value: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
      }),
      amount_claimed: Yup.object().shape({
        checked: Yup.boolean(),
        value: Yup.string().when(
          ['checked'],
          {
            is: true,
            then: Yup.string()
              .required(intl.TEXT_FIELD_REQUIRED)
              .typeError(intl.TEXT_FIELD_REQUIRED),
          },
          ['checked']
        ),
      }),
    }),

    other_company_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),

    other_insurance_covering: Yup.object().shape({
      checked: Yup.boolean(),
      value: Yup.string().when(
        ['checked'],
        {
          is: true,
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .typeError(intl.TEXT_FIELD_REQUIRED),
        },
        ['checked']
      ),
    }),

    additional_passengers: additionalShema(intl),

    reimbursed_transportation_insurance: Yup.object().shape({
      value: Yup.string().test(
        'test-total-value',
        intl.VALIDATE_DATE_VALID,
        function (value) {
          return true;
        }
      ),
    }),

    total_amount_claimed: Yup.object().shape({
      value: Yup.number()
        .test('>= 0', intl.VALIDATE_NUMBER_VALUE, function (value) {
          return Number(value) >= 0;
        })
        .required(intl.TEXT_FIELD_REQUIRED),
    }),
  });
};

const additionalShema = intl => {
  const additional = Yup.array().when(['hasAdditional'], {
    is: hasAdditional => {
      return hasAdditional === 'true';
    },
    then: Yup.array().of(
      Yup.object().shape({
        first_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        last_name: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        birth_date: Yup.date()
          .max(new Date(), intl.VALIDATE_DATE_VALID)
          .typeError(intl.TEXT_FIELD_REQUIRED)
          .required(intl.TEXT_FIELD_REQUIRED),
        gender: Yup.string()
          .required(intl.TEXT_FIELD_REQUIRED)
          .typeError(intl.TEXT_FIELD_REQUIRED),
        nationalities: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        relationship: Yup.string().required(intl.TEXT_FIELD_REQUIRED),
        comment: Yup.string().when(['relationship'], {
          is: relationship => {
            return relationship === constants.OTHER_VALUE;
          },
          then: Yup.string()
            .required(intl.TEXT_FIELD_REQUIRED)
            .max(255, intl.TEXT_FIELD_VALIDATION_255),
        }),
      })
    ),
  });

  return additional;
};
