export const BUY_NOW = {
  en_US: 'Select this option',
  pt_BR: 'Selecione esta opção',
  es_ES: 'Selecciona esta opción',
};

export const TEXT_VIEW_MORE = {
  en_US: 'View more',
  pt_BR: 'Ver mais',
  es_ES: 'Ver más',
};

export const TEXT_VALUE_IS_FOR_ALL = {
  en_US: 'This value is for all passengers.',
  pt_BR: 'Este valor é para todos os passageiros.',
  es_ES: 'Este precio cubre a todos los pasajeros.',
};
