/* eslint-disable no-unused-vars */
import { focusOnErrorElement, focusElement } from '../../../../utils/elements';

export const checkErrorsOnForm = async (
  values,
  schemas,
  componentRef,
  trackEventUserAction
) => {
  const { submitRefs, accordeonRefs, keys } = componentRef;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    submitRefs[key].current.click();
    try {
      schemas[key] && (await schemas[key].validate(values[key]));
    } catch (error) {
      trackEventUserAction(
        '#### (CERTIFICATE) CLICOU NO SUBMIT -- FORM OK FALSE',
        error
      );
      focusOnErrorElement(0);
      return;
    }
  }
  return true;
};

export const checkErrorsOnSession = async (
  currentAcordeon,
  nextAcordeon,
  schemas,
  values,
  validation,
  componentRef
) => {
  const { submitRefs, accordeonRefs } = componentRef;
  if (accordeonRefs[currentAcordeon]) {
    try {
      if (validation && schemas[currentAcordeon]) {
        submitRefs[currentAcordeon].current.click();
        await schemas[currentAcordeon].validate(values[currentAcordeon]);
      }
      accordeonRefs[currentAcordeon].current.click();
      accordeonRefs[nextAcordeon].current.click();
      focusElement(nextAcordeon);
      return true;
    } catch (error) {
      focusOnErrorElement(0);
      return false;
    }
  }
};
