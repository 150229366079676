import * as Yup from 'yup';
import moment from 'moment';

export const tripInformationSchema = (
  intl,
  limitDate,
  exceptionCountry,
  limitedCards
) =>
  Yup.object().shape({
    is_international_trip: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
    from: Yup.string()
      .required(intl.TEXT_REQUIRED_FIELD)
      .typeError(intl.TEXT_REQUIRED_FIELD)
      .test(
        'test-data-after-today',
        intl.VALIDATE_DATE_VALID,
        function (value) {
          return moment(
            moment(value).format('YYYY-MM-DD 00:00:00')
          ).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD 00:00:00'));
        }
      )
      .test(
        'limited data for country',
        intl.VALIDATE_DATE_FOR_COUNTRIES,
        function (value) {
          return limitedCards && !exceptionCountry
            ? moment(value).isSameOrBefore(moment(limitDate))
            : true;
        }
      ),
    to: Yup.string()
      .required(intl.TEXT_REQUIRED_FIELD)
      .typeError(intl.TEXT_REQUIRED_FIELD)
      .test(
        'test-data-after-today',
        intl.VALIDATE_DATE_VALID,
        function (value) {
          return (
            moment(moment(value).format('YYYY-MM-DD 00:00:00')).isSameOrAfter(
              moment(this.parent.from).format('YYYY-MM-DD 00:00:00')
            ) &&
            moment(moment(value).format('YYYY-MM-DD 00:00:00')).isSameOrBefore(
              moment(this.parent.from)
                .add(31, 'days')
                .format('YYYY-MM-DD 00:00:00')
            )
          );
        }
      ),
    country_origin: Yup.string().when(['is_international_trip'], {
      is: is_international_trip => {
        return is_international_trip === 'true';
      },
      then: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
    }),
    city_origin: Yup.string().when(['is_international_trip'], {
      is: is_international_trip => {
        return is_international_trip === 'false';
      },
      then: Yup.string().required(intl.TEXT_REQUIRED_FIELD),
    }),
    rental_company: Yup.string()
      .required(intl.TEXT_REQUIRED_FIELD)
      .typeError(intl.TEXT_REQUIRED_FIELD),
    reservation_number: Yup.string()
      .required(intl.TEXT_REQUIRED_FIELD)
      .typeError(intl.TEXT_REQUIRED_FIELD),
  });
