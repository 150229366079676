import React, { useContext } from 'react';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { getThemeIcon } from '../../utils/configIcon';

const IEMSIcon = props => {
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const config_icon = getThemeIcon(theme);
  return (
    <svg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{
        enableBackground: 'new 0 0 24 24',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          fill={config_icon.primary}
          d="M20.3,17.7c-1.3,0-2.5,0.9-2.9,2.1H9.9c-0.6,0-1-0.5-1-1v-3.1h1.2h0.1c0.2-0.1,4.9-1.3,4.9-8.2H13 c0,4.8-2.7,6-3.3,6.2H5.9c-0.6-0.2-3.3-1.4-3.3-6.2h-2c0,6.9,4.7,8.2,4.9,8.3h1.3v3.1c0,1.7,1.4,3.1,3.1,3.1h7.4 c0.4,1.2,1.6,2.1,2.9,2.1c1.7,0,3.1-1.4,3.1-3.1C23.4,19.1,22,17.7,20.3,17.7z M20.3,21.8c-0.6,0-1-0.5-1-1s0.5-1,1-1 c0.6,0,1,0.5,1,1S20.8,21.8,20.3,21.8z"
        />
        <g>
          <polygon
            fill={config_icon.primary}
            points="0.6,5.3 2.7,5.3 2.7,2.2 3.7,2.2 3.7,0.1 0.6,0.1  "
          />
          <polygon
            fill={config_icon.primary}
            points="12,0.1 12,2.2 13,2.2 13,5.3 15.1,5.3 15.1,0.1  "
          />
          <polygon
            fill={config_icon.secondary}
            points="8.9,3.2 6.8,3.2 6.8,5.3 4.8,5.3 4.8,7.3 6.8,7.3 6.8,9.4 8.9,9.4 8.9,7.3 11,7.3 11,5.3 8.9,5.3  "
          />
        </g>
      </g>
    </svg>
  );
};

export default IEMSIcon;
