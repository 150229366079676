import { useContext } from 'react';
import styles from './ListPlans.module.scss';
import { B2B2CContext } from '../../../../components/B2B2CStateParent/B2B2CStateParent';
import { formatPlans } from './formatBenefits';
import Plans from './Plan/Plans';
import { ThemeContext } from '../../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../../componentsV2/Button/Button';
import { dataLayerB2B2CSelectPlan } from '../../../../utils/GTM_helper';

const createSliderPlans = (
  listPlanes,
  formType,
  CONFIG_B2B2C,
  selectedPlan
) => {
  if (!listPlanes) return <></>;
  const firstBenefit = listPlanes[0];
  return listPlanes.map((list, index) => (
    <Plans
      key={index}
      plan={list}
      formType={formType}
      currentPlan={
        CONFIG_B2B2C.quote.form.styleFirstItem && firstBenefit.Id == list.Id
      }
      CONFIG_B2B2C={CONFIG_B2B2C}
      selected={selectedPlan && selectedPlan.Id === list.Id}
    />
  ));
};

const ListPlans = props => {
  const { formType, CONFIG_B2B2C, nextStep, prevStep } = props;
  const { utils } = useContext(B2B2CContext);
  const { translate } = useContext(IntlContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const theme = getGlobalTheme();
  const intl = translate(translations);
  const plans = utils.getPlans();
  const quote = utils.getQuote();
  const selectedPlan = utils.getSelectedPlan();
  const listPlanes = formatPlans(plans ? plans.ProductVISA : []);
  const CONFIG_TEXT_COVERAGE_DATE = CONFIG_B2B2C.quote.text.text_coverage_date(
    intl,
    selectedPlan ? selectedPlan.Annual : undefined
  );

  const next = () => {
    const dataLayerObjPlan = {
      currency: quote.Quote.AmountCurrencyISOCode,
      items: [
        {
          item_name: selectedPlan.Name,
          item_id: selectedPlan.Id,
          item_brand: 'AXA',
          item_category: 'Travel Insurance',
        },
      ],
    };

    dataLayerB2B2CSelectPlan(dataLayerObjPlan);
    nextStep();
  };

  const renderPlans = createSliderPlans(
    listPlanes,
    formType,
    CONFIG_B2B2C,
    selectedPlan
  );

  return (
    <>
      <div className={`${styles.boxPlans} ${styles.plansMobile}`}>
        <ul className={`row ${styles.list}`}>{renderPlans}</ul>
        <h3 className={`${styles.subtitle} ${styles[theme]}`}>
          {CONFIG_TEXT_COVERAGE_DATE.disclaimer}
        </h3>
      </div>

      {(!plans || plans.isEmpty) && (
        <div className={`${styles.productNotFound}  ${styles[theme]}`}>
          {intl.PRODUCT_IS_EMPTY}
        </div>
      )}

      <div className={`${styles.footer} ${styles[theme]}`}>
        <div className={styles.divButton}>
          <Button
            type="Secondary"
            axa
            onClick={prevStep}
            label={intl.BTN_BACK}
          />
        </div>
        <div className={styles.divButton}>
          <Button
            type="Primary"
            axa
            onClick={next}
            disabled={!selectedPlan}
            label={intl.BTN_NEXT}
          />
        </div>
      </div>
    </>
  );
};

export default ListPlans;
