import { useContext, useEffect, useState } from 'react';
import GroupDivisor from '../../../../../../../components/GroupDivisor';
import { IntlContext } from '../../../../../../../intl';
import * as translations from './intl';
import TrashIcon from '../../../../../../../assets/icons/TrashIcon';
import styles from './index.module.scss';
import {
  SearchData,
  formatSegment,
  getFlightError,
  getFlightTouched,
  loadFlights,
} from './utils';
import Input from '../../../../../../../components/Input/Input';
import { cloneDeep, isEqual } from 'lodash';
import Calendar from '../../../../../../../components/Calendar/Calendar';
import { parseISO } from 'date-fns';
import FlightListItem from './FlightList/FlightListItem';
import FlightList from './FlightList';
import { FormikProps } from 'formik';
import { FlightDetailsSchema } from '../../../validationSchema';
import { FullTravelInsuranceCertificateDetail } from '../../../../../../../@types/APIs/Certificate';
import { ScheduleWithAirportInfo } from '../../../../../utils';
import Button from '../../../../../../../componentsV2/Button/Button';

interface FlightsAreaProps {
  legIndex: number;
  formikProps: FormikProps<FlightDetailsSchema>;
  isReturn?: boolean;
  name: string;
  certificate: FullTravelInsuranceCertificateDetail;
}

const FlightLeg = ({
  legIndex,
  formikProps,
  isReturn,
  name,
  certificate,
}: FlightsAreaProps) => {
  const { translate, idiom } = useContext(IntlContext);
  const intl = translate(translations);

  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [flightSchedules, setFlightSchedules] =
    useState<ScheduleWithAirportInfo[]>(null);
  const [selectedFlight, setSelectedFLight] =
    useState<ScheduleWithAirportInfo>(null);
  const [searchData, setSearchData] = useState<SearchData>();

  const {
    values,
    setFieldValue,
    errors,
    touched: formikTouched,
    setFieldError,
    setFieldTouched,
  } = formikProps;

  const travelInformation = certificate.travel_information;
  const outwardDate = parseISO(travelInformation.from);
  const returnDate = parseISO(travelInformation.to);

  const flightsKey = isReturn ? 'return' : 'outbound';
  const segments = values[flightsKey].segments;
  const segment = segments[legIndex];

  const error = getFlightError(legIndex, isReturn, errors);
  const touched = getFlightTouched(formikTouched, flightsKey, legIndex);

  const showRemoveButton = legIndex === segments.length - 1 && legIndex !== 0;

  const removeLastLeg = () => {
    const value = [...segments];
    value.splice(-1);
    setFieldValue(`${flightsKey}.segments`, value);
  };

  const fillFields = async (
    flightIndex: number,
    flightInfo: ScheduleWithAirportInfo
  ) => {
    setFlightSchedules(null);

    const currentValue = values[flightsKey].segments[flightIndex];
    const newSegment = formatSegment(currentValue, flightInfo);

    const valueKey = `${flightsKey}.segments[${flightIndex}]`;
    setFieldValue(valueKey, newSegment);
    setFieldTouched(`${valueKey}.isValid`, false);
    if (errors[flightsKey] && Array.isArray(errors[flightsKey].segments))
      setFieldError(`${valueKey}.isValid`, null);

    setSelectedFLight(flightInfo);
    setIsLoading(false);
  };

  const handleFlightNumberBlur = async (index: number, flightDate?: Date) => {
    const { airline, flight_number, flight_date } =
      values[flightsKey].segments[index];
    const capitalizedAirline = airline && airline.toUpperCase();
    const handledDate = flightDate || flight_date;

    const valueKey = `${flightsKey}.segments[${index}]`;

    if (flightDate) setFieldValue(`${valueKey}.flight_date`, flightDate);

    setApiError(null);
    setFlightSchedules(null);
    setSelectedFLight(null);

    setFieldValue(`${valueKey}.isValid`, false);
    setFieldTouched(`${valueKey}.isValid`, false);
    if (errors[flightsKey] && Array.isArray(errors[flightsKey].segments))
      setFieldError(`${valueKey}.isValid`, null);

    if (!capitalizedAirline || !flight_number || !handledDate) return;

    setSearchData({
      airline,
      flightNumber: flight_number,
      flightDate: handledDate,
    });
  };

  const fetchFlights = async () => {
    if (!searchData) return;

    setIsLoading(true);
    const currentSearchData = cloneDeep(searchData);

    try {
      const schedules = await loadFlights(searchData);

      if (!schedules || !isEqual(currentSearchData, searchData)) return;

      if (schedules.length === 1) {
        fillFields(legIndex, schedules[0]);
      } else {
        setFlightSchedules(schedules);
      }
    } catch (error) {
      const isFlightNumberValid = !error?.response?.data?.find?.(
        error => error?.message === 'flight_number must be a positive number'
      );
      if (!isFlightNumberValid) {
        setApiError(intl.TEXT_ERROR_FLIGHT_NUMBER_NOT_NUMBER);
      } else {
        setApiError(intl.TEXT_ERROR_FINDING_FLIGHT);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getTooltipClass = () => {
    if (idiom === 'pt_BR') {
      return styles.tooltipPt;
    }
    if (idiom === 'es_ES') {
      return styles.tooltipEs;
    }
    if (idiom === 'en_US') {
      return styles.tooltipEn;
    }
  };

  useEffect(() => {
    fetchFlights();
  }, [searchData]);

  return (
    <>
      <div className={`col-12 ${styles.separator}`}>
        <GroupDivisor title={intl.LABEL_FLIGHT_LEG(legIndex + 1)} />
        {showRemoveButton && (
          <Button
            type="Tertiary"
            onClick={removeLastLeg}
            label={intl.LABEL_REMOVE_LEG}
            leadingIcon={<TrashIcon />}
          />
        )}
      </div>
      {error && error.length > 0 && touched && (
        <label className={`${styles.invalidFlightAlert} col-12`}>{error}</label>
      )}
      <div className="col-12 col-md-4">
        <Input
          formikProps={formikProps}
          label={intl.LABEL_AIRLINE_COMPANY(segment.airline)}
          name={`${name}.airline`}
          maxLength={3}
          mask="maskedAirlineCode"
          onBlur={() => handleFlightNumberBlur(legIndex)}
          type="text"
          tooltipContentClass={`${styles.tooltip} ${getTooltipClass()}`}
        />
      </div>
      <div className="col-12 col-md-4">
        <Input
          formikProps={formikProps}
          label={intl.LABEL_FLIGHT_NUMBER(segment.flight_number)}
          name={`${name}.flight_number`}
          maxLength={4}
          mask="maskedFlightNumber"
          onBlur={() => handleFlightNumberBlur(legIndex)}
          type="text"
          tooltipContentClass={`${styles.tooltip} ${getTooltipClass()}`}
        />
      </div>
      <div className="col-12 col-md-4">
        <Calendar
          formikProps={formikProps}
          label={intl.LABEL_FLIGHT_DATE}
          name={`${name}.flight_date`}
          onChange={event => {
            handleFlightNumberBlur(legIndex, event.target.value);
          }}
          minDate={isReturn ? returnDate : outwardDate}
          maxDate={isReturn ? null : returnDate}
        />
      </div>
      <div className="col-12">
        {selectedFlight ? (
          <FlightListItem schedule={selectedFlight} disabled />
        ) : (
          <>
            <FlightList
              flights={flightSchedules}
              legIndex={legIndex}
              isLoading={isLoading}
              fillFields={fillFields}
            />
            {apiError && (
              <label className={`${styles.invalidFlightAlert} col-12`}>
                {apiError}
              </label>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FlightLeg;
