export const TEXT_HI = {
  en_US: 'Hi',
  es_ES: 'Hola',
  pt_BR: 'Oi',
};

export const TEXT_WELCOME_WORLD_VISA = {
  en_US: 'Welcome to the Visa world!',
  es_ES: '¡Te damos la bienvenida al mundo Visa!',
  pt_BR: 'Seja bem-vindo ao mundo Visa!',
};

export const LINK_SEE_ALL_PRODUCTS = {
  en_US: 'View all cards',
  es_ES: 'Ver todas las tarjetas',
  pt_BR: 'Ver todos os cartões',
};

export const LINK_PERSONAL_DETAILS = {
  en_US: 'Personal details',
  es_ES: 'Información personal',
  pt_BR: 'Informações pessoais',
};
export const LINK_BANK_DETAILS = {
  en_US: 'Bank information',
  es_ES: 'Información bancaria',
  pt_BR: 'Informações bancárias',
};
export const LINK_DEPENDENT_ACCOUNTS = {
  en_US: 'Beneficiaries',
  es_ES: 'Beneficiarios',
  pt_BR: 'Beneficiários',
};

export const UNEXPECTED_ERROR_OCCURRED = {
  en_US:
    'An error occurred while performing this operation. Please try again later.',
  pt_BR:
    'Ocorreu um erro ao efetuar esta operação. Por favor tente novamente mais tarde.',
  es_ES:
    'Se ha producido un error al realizar esta operación. Por favor, inténtalo de nuevo más tarde.',
};

export const TEXT_OK = {
  en_US: 'Ok',
  pt_BR: 'Ok',
  es_ES: 'Ok',
};

export const CREDIT = {
  en_US: 'Credit',
  es_ES: 'Crédito',
  pt_BR: 'Crédito',
};

export const DEBIT = {
  en_US: 'Debit',
  es_ES: 'Débito',
  pt_BR: 'Débito',
};

export const PREPAID = {
  en_US: 'Prepaid',
  es_ES: 'Prepago',
  pt_BR: 'Pré-pago',
};

export const SETTING_YOUR_CARD = {
  en_US: card => `Setting your card (${card}) as the main one...`,
  es_ES: card => `Estableciendo tu tarjeta (${card}) como principal...`,
  pt_BR: card => `Definindo seu cartão (${card}) como principal...`,
};

export const ICON_OPEN_MENU_COUNTRIES_LIST = {
  en_US: 'Icon Open Menu Countries List',
  es_ES: 'Ícono para Abrir el Menú de Lista de Países',
  pt_BR: 'Ícone para Abrir o Menu da Lista de Países',
};

export const CARD = {
  en_US: 'card',
  es_ES: 'tarjeta',
  pt_BR: 'cartão',
};
