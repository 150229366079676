import moment from 'moment';
import { removeNonNumeric } from '../../../../../../../../utils/stringUtils';
const CARD_HOLDER_VALUE = 'Cardholder';
export const formatIems_toApi = props => {
  let object = {};
  object.medical_service = medical_serviceToApi(props);
  object.attending_physician = attending_physicianToApi(props);
  object.existing_case = existing_caseToApi(props);
  object.affected_person = affected_personToApi(props);
  object.other_insurance_covering = otherInsuranceToApi(props);
  object.amount_estimation_damage = estimationDamageToApi(props);
  object.total_amount_claimed = amountClaimedToApi(props);

  return object;
};

const amountClaimedToApi = props => {
  const estimation_damage =
    props.values.incident.amount_estimation_damage.value;
  const isInternal =
    props.values.incident.other_insurance_covering.is_internal === 'true';
  let total = estimation_damage;

  if (isInternal)
    total =
      parseFloat(estimation_damage) -
      parseFloat(props.values.incident.other_insurance_covering.value);

  return {
    value: parseFloat(total.toFixed(2)),
    currency: props.values.incident.expense_currency,
  };
};

const estimationDamageToApi = props => {
  const estimation_damage = props.values.incident.amount_estimation_damage;

  return {
    value: parseFloat(estimation_damage.value.toFixed(2)),
    currency: props.values.incident.expense_currency,
  };
};

const attending_physicianToApi = props => {
  const attending_physician = props.values.incident.attending_physician;
  attending_physician.phone.international_prefix = removeNonNumeric(
    attending_physician.phone.international_prefix
  );

  return attending_physician;
};

const existing_caseToApi = props => {
  const existing_case = props.values.incident.existing_case;
  let number =
    existing_case.reported_agent_phone === 'true' ? existing_case.number : '';
  let date = moment(existing_case.date).format('YYYY-MM-DD HH:mm:ss');

  return {
    number: number,
    date: date,
  };
};

const medical_serviceToApi = props => {
  const medical = props.values.incident.medical_service;

  const formatmStart = moment(medical.assistance_started_at).format(
    'YYYY-MM-DD HH:mm:ss'
  );
  const formatEnd = moment(medical.assistance_finished_at).format(
    'YYYY-MM-DD HH:mm:ss'
  );

  return {
    assistance_started_at: formatmStart,
    assistance_finished_at: formatEnd,
    diagnosis: medical.diagnosis,
    medical_treatment_received: medical.medical_treatment_received,
    medical_service_description: medical.medical_service_description,
    medical_center_name: medical.medical_center_name,
    expenses: props.values.incident.expenses,
  };
};

const affected_personToApi = props => {
  const affected_person = props.values.incident.affected_person;
  const affectedPersonFormat = {
    relationship: affected_person.relationship,
    comment: affected_person.comment,
    person: {
      first_name: affected_person.person.first_name,
      last_name: affected_person.person.last_name,
      birth_date: affected_person.person.birth_date,
    },
    registrations: registrationByRelationship(
      affected_person.relationship,
      props
    ),
  };
  return affectedPersonFormat;
};

const registrationByRelationship = (isCardHolder, props) => {
  return isCardHolder === CARD_HOLDER_VALUE
    ? createObjectRegistration(props.values.policy.person_registrations[0])
    : createObjectRegistration(
        props.values.incident.affected_person.person.registrations
      );
};

const createObjectRegistration = value => {
  let isCpf = value.registration_type === 'CPF';
  return [
    {
      registration_type: value.registration_type,
      value: isCpf ? removeNonNumeric(value.value) : value.value,
    },
  ];
};

export const otherInsuranceToApi = props => {
  const other_insurance_covering =
    props.values.incident.other_insurance_covering;
  const isInternal =
    other_insurance_covering.is_internal === 'true' ? true : false;

  if (isInternal === false) return [{ is_internal: false }];

  return [
    {
      is_internal: isInternal,
      date: isInternal ? moment(new Date()).format('YYYY-MM-DD') : '',
      description: '',
      amount: {
        value: isInternal ? parseFloat(other_insurance_covering.value) : '',
        currency: isInternal ? props.values.incident.expense_currency : '',
      },
    },
  ];
};
